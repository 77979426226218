<div class="m-layout-w-actions-top m-fixed-height m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top"
         *ngIf="deleteButton">
        <button *ngIf="canDeleteVehicleData && deleteButton && !measuringPoint?.readonly"
                class="m-btn m-btn-secondary"
                (click)="openDeleteDialog()"
                id="delete_button"
                [disabled]="isDownloadingFile">

            <app-svg-icon icon="delete">
            </app-svg-icon>

            {{"form.delete" | translate}}
        </button>
    </div>

    <p-table id="measuring_point_validation_table"
             [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-layout-default m-border-top"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             [loading]="loading"
             [totalRecords]="total"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [lazy]="isLazy"
             (onLazyLoad)="loadTableRows($event)"
             dataKey="date"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer">
            <ng-container *ngIf="total && !paginator">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="headergrouped"
                     *ngIf="headers"
                     style="display:table">

            <tr style="display:table-row">
                <ng-container *ngFor="let column of beginColumns; trackBy: columnTrackByFn">
                    <th [ngStyle]="column.ngStyle"
                        [pSortableColumn]="column.field"
                        [pSortableColumnDisabled]="!sortable || !column.sortable"
                        rowspan="2"
                        [ngSwitch]="column.field"
                        style="display:table-cell">

                        <ng-container *ngSwitchCase="'isError'">
                            <app-svg-icon icon="close"
                                          tooltip="{{column.header | translate}}"></app-svg-icon>
                        </ng-container>

                        <ng-container *ngSwitchCase="'isException'">
                            <app-svg-icon icon="alert"
                                          tooltip="{{column.header | translate}}"></app-svg-icon>
                        </ng-container>

                        <ng-container *ngSwitchCase="'isPredicted'">
                            <app-svg-icon icon="prediction"
                                          tooltip="{{column.header | translate}}"></app-svg-icon>
                        </ng-container>

                        <ng-container *ngSwitchCase="'auditOrLog'">
                            <app-svg-icon icon="history"
                                          tooltip="{{column.header | translate}}"></app-svg-icon>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <div class="m-column-header">
                                <div class="m-column-header-wrapper">
                                    <span class="m-column-title">
                                        {{column.header | translate}}
                                    </span>

                                    <p-sortIcon *ngIf="sortable && column.sortable"
                                                [field]="column.field"></p-sortIcon>

                                    <app-table-filter *ngIf="filter"
                                                      class="m-column-filter"
                                                      [column]="column"
                                                      [table]="table">
                                    </app-table-filter>
                                </div>

                                <app-table-filter-preview [column]="column"></app-table-filter-preview>
                            </div>
                        </ng-container>
                    </th>

                </ng-container>

                <th *ngFor="let column of vehicleCategoryColumns; trackBy: columnTrackByFn"
                    [ngStyle]="getVehicleCategoryHeaderColumnStyle(column)"
                    colspan="2"
                    style="display:table-cell">

                    <app-domain-data-field [enumKey]="column?.field"
                                           domainDataType="vehicleCategory">
                    </app-domain-data-field>

                </th>

                <ng-container *ngFor="let column of endColumns; trackBy: columnTrackByFn">

                    <th [ngStyle]="column.ngStyle"
                        [pSortableColumn]="column.field"
                        [pSortableColumnDisabled]="!sortable || !column.sortable"
                        rowspan="2"
                        [ngSwitch]="column.field"
                        style="display:table-cell">

                        <ng-container *ngSwitchCase="'isPredicted'">
                            <app-svg-icon icon="prediction"
                                          tooltip="{{column.header | translate}}"></app-svg-icon>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <div class="m-column-header">
                                <div class="m-column-header-wrapper">
                                    <span class="m-column-title">
                                        {{column.header | translate}}
                                    </span>

                                    <p-sortIcon *ngIf="sortable && column.sortable"
                                                [field]="column.field"></p-sortIcon>

                                    <app-table-filter *ngIf="filter"
                                                      class="m-column-filter"
                                                      [column]="column"
                                                      [table]="table">
                                    </app-table-filter>
                                </div>

                                <app-table-filter-preview [column]="column"></app-table-filter-preview>
                            </div>
                        </ng-container>
                    </th>

                </ng-container>
            </tr>

            <tr style="display:table-row">
                <th *ngFor="let column of relevantColumns | slice:beginColumns.length:beginColumns.length + vehicleCategoryColumns.length * 2; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable"
                    style="display:table-cell">

                    <app-svg-icon [icon]="column.reverse ? 'arrow-left' : 'arrow-right'"
                                  tooltip="{{(column.reverse ? 'measuringPoints.reverseDirection' : 'measuringPoints.primaryDirection') | translate}}">
                    </app-svg-icon>

                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-vehicleRow
                     let-rowIndex="rowIndex"
                     let-expanded="expanded">
            <tr [pSelectableRow]="vehicleRow"
                [ngClass]="{'row-error': vehicleRow.isError, 'row-warning': vehicleRow.isException, 'row-predicted': vehicleRow.isPredicted, 'validation-expanded-row': expanded}">

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngSwitch]="column.field"
                    [ngStyle]="column.ngStyle">

                    <ng-container *ngSwitchCase="'save'">
                        <ng-template #expandTemplate>
                            <div style="width: 100%;">
                                <a *ngIf="vehicleRow.journals + vehicleRow.audits > 0"
                                   [pRowToggler]="vehicleRow">
                                    <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                                </a>
                            </div>
                        </ng-template>

                        <ng-container *ngIf="vehicleRow.isSaving || vehicleRow.isDirty; else expandTemplate">
                            <app-loading-spinner *ngIf="vehicleRow.isSaving"></app-loading-spinner>
                            <app-icon-button *ngIf="vehicleRow.isDirty && !vehicleRow.isSaving"
                                             [style]="{'margin-left': '-16px', 'margin-top': '-1px', 'min-height': '20px', 'max-height': '20px'}"
                                             icon="save"
                                             fill="blue"
                                             (click)="saveRow(vehicleRow)"></app-icon-button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'auditOrLog'">
                        <div class="flex justify-center"
                             style="width: 100%;">
                            <app-svg-icon *ngIf="vehicleRow.journals + vehicleRow.audits > 0"
                                          [icon]="vehicleRow.journals > vehicleRow.audits ? 'history' : 'log'"
                                          fill="orange"
                                          [tooltip]="(vehicleRow.journals > vehicleRow.audits ? 'journal.title' : 'auditTrails.title') | translate">
                            </app-svg-icon>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'date'">
                        {{ vehicleRow.date | momentDate:true:translateService.currentLang }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'isError'">
                        <div (click)="isErrorInput.click(); $event.stopPropagation();"
                             style="width: 100%;">
                            <input type="checkbox"
                                   [(ngModel)]="vehicleRow.isError"
                                   (click)="$event.stopPropagation()"
                                   (change)="handleRowIsError(vehicleRow)"
                                   [disabled]="measuringPoint.readonly || vehicleRow.isSaving"
                                   #isErrorInput />
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isException'">
                        <div (click)="isExceptionInput.click(); $event.stopPropagation();"
                             style="width: 100%;">
                            <input type="checkbox"
                                   [(ngModel)]="vehicleRow.isException"
                                   (click)="$event.stopPropagation()"
                                   (change)="handleRowIsException(vehicleRow)"
                                   [disabled]="measuringPoint.readonly"
                                   #isExceptionInput />
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'exceptionReason'">
                        <input type="text"
                               class="p-inputtext"
                               [(ngModel)]="vehicleRow.exceptionReason"
                               (appDebounce)="handleRowEdit(vehicleRow)"
                               [disabled]="measuringPoint.readonly">
                    </ng-container>

                    <ng-container *ngSwitchCase="'isPredicted'">
                        <div style="width: 100%;">
                            <input type="checkbox"
                                   [ngModel]="vehicleRow.isPredicted"
                                   disabled />
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <div style="width: 100%;">
                            <ng-container *ngIf="!column.reverse; else reverse">
                                {{vehicleRow.data[column.field].forwardCount | thousandSeperator}}
                            </ng-container>

                            <ng-template #reverse>
                                {{vehicleRow.data[column.field].reverseCount | thousandSeperator}}
                            </ng-template>
                        </div>
                    </ng-container>

                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion"
                     let-vehicleRow
                     let-columns="columns">

            <tr class="validation-collapse-row">
                <td [attr.colspan]="columns.length"
                    class="validation-collapse-row-td">
                    <app-measuring-point-day-change-overview [measuringPoint]="measuringPoint"
                                                             [date]="vehicleRow.date">
                    </app-measuring-point-day-change-overview>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-measuring-point-data-delete-dialog></app-measuring-point-data-delete-dialog>