import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, OnInit } from "@angular/core";
import { AssignmentCreator, AssignmentStatus, IAssignment, IAssignmentPriority, IAssignmentStatusHistory } from "src/app/models/assignment";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { ITaskRule, ISignMaterialInfo } from "src/app/models/task-rule";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { MultiNoDuplicateValidator } from "src/app/validators/multi-no-duplicate.validator";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IProject, ProjectRoles } from "src/app/models/project";
import { FormValidationService } from "src/app/services/form-validation.service";
import { ServiceRequestOptions } from "src/app/models/search";
import { SubscriptionManager, FormUtils, OrganizationUtils } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { MapDataService } from "src/app/services/map-data.service";
import { AssignmentApi } from "src/app/resource/assignment.api";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ToastService } from "src/app/services/toast.service";
import { SignMaterial } from "src/app/models/task";
import { Subject, zip } from "rxjs";
import { TaskRuleApi } from "src/app/resource/task-rule.api";
import { ProjectApi } from "src/app/resource/project.api";
import { SelectItem } from "primeng/api";
import { Roles } from "src/app/models/user";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { ErrorApi } from "src/app/resource/error.api";
import { ProjectsService } from "src/app/services/projects.service";

@Component({
    selector: "app-assignment-detail",
    templateUrl: "./assignment-detail.component.html",
    styleUrls: ["./assignment-detail.component.css"],
})
export class AssignmentDetailComponent implements OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard, OnInit {
    submitting = false;

    selectMaterial = true;
    hasMultipleOrganizations: boolean;
    organizations: SelectItem[];
    projects: SelectItem[] = new Array<SelectItem>();
    supervisors: SelectItem[];
    signMaterials: SelectItem[];
    taskTypes: SelectItem[];
    selectIsMachineWork: boolean;
    project: IProject;
    projectUsers: SelectItem[];
    generatedName: string;

    priorities: ViewModelEnum[];
    statuses: ViewModelEnum[];

    assignmentForm: SigncoFormGroup;
    currentStatus: AssignmentStatus;
    calendarSettings: CalendarSettings;

    canCreateAssignment: boolean;

    get isReadonly(): boolean {
        return !this.canCreateAssignment;
    }

    @Input() assignment: IAssignment;
    @Output() save = new EventEmitter<IAssignment>();

    private dropdownValuesFetched: Subject<boolean> = new Subject<boolean>();
    private subscriptionManager = new SubscriptionManager();
    private allSignMaterials: SelectItem[];
    private currentTaskRule: ITaskRule;
    private taskRules: ITaskRule[];
    private taskRuleIsSet: boolean;
    private readonly mapDataKey: string;
    private showTaskTypeAndETC = true;
    private isShowingSubAssignment = false;


    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly navigationService: NavigationService,
        private readonly assignmentApi: AssignmentApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainDataService: DomainDataService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly mapDataService: MapDataService,
        private readonly projectApi: ProjectApi,
        private readonly taskRulesApi: TaskRuleApi,
        private readonly impersonationService: ImpersonationService,
        private readonly errorApi: ErrorApi,
        private readonly projectsService: ProjectsService
    ) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");

        this.initDropdowns();

        const authorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo) => {
            if (!authorizationInfo) return;
            this.hasMultipleOrganizations = this.globalEventsService.hasMultipleOrganizations();
        });
        this.subscriptionManager.add("authorizationInfo", authorizationInfoSubscription);

        const calendarSettingsSubscription = this.primeComponentService
            .calendarSettings()
            .subscribe((calendarSettings) => {
                this.calendarSettings = calendarSettings;
            });

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(
            this.mapDataKey,
            (organizations) => {
                this.organizations = this.primeComponentService.createDropdownList(
                    OrganizationUtils.addLevel(organizations),
                    (x) => x.id,
                    (x) => x.name,
                    false,
                    "",
                    OrganizationUtils.getStyleClass
                );
                this.refreshOrganizationRelatedDropdowns();
            }
        );

        this.subscriptionManager.add(
            "calendarSettings",
            calendarSettingsSubscription
        );
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateAssignment = rights?.hasBackendRight(BackendRights.CreateAssignment);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.impersonationService.subscribeToRoleImpersonation(this.mapDataKey, () => {
            this.reset();
        });
    }

    ngOnInit() {
        const dropdownsReadySubcription = this.dropdownValuesFetched.subscribe(
            (dropdownsAreReady) => {
                if (dropdownsAreReady && !this.taskRuleIsSet && this.assignment) {
                    this.applyTaskRule(this.assignment);
                }
            }
        );
        this.subscriptionManager.add("dropdownsReady", dropdownsReadySubcription);
    }

    ngOnChanges(changes: SimpleChanges) {
        const assignmentChange = changes["assignment"];
        if (assignmentChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        if (this.isFaultyAssignment) return true;

        return (
            !this.assignmentForm ||
            (this.assignmentForm.pristine && !this.isCreatingNew())
        );
    }

    get isFaultyAssignment(): boolean {
        return this.assignment && (this.assignment.parentAssignment ? !this.assignment.parentAssignment.location.coordinate : !this.assignment.location.coordinate);
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setAssignment(assignment: IAssignment) {
        this.assignment = assignment;
        this.isShowingSubAssignment = !!this.assignment.parentAssignment;

        if (this.isFaultyAssignment) {
            setTimeout(() => {
                this.navigationService.toAssignments();
            });
            return;
        }

        this.generatedName = assignment.name;
        this.initialize();
    }

    private async initialize() {
        if (!this.assignment) return;

        const administrativeResponsibleIdControl = this.formBuilder.control(null);
        const executiveResponsibleIdControl = this.formBuilder.control(null);
        const responsibleClientIdControl = this.formBuilder.control(null);
        const responsibleIdControls = [administrativeResponsibleIdControl, executiveResponsibleIdControl, responsibleClientIdControl];

        this.assignmentForm = this.formBuilder.group({
            name: ["", Validators.required],
            organizationId: "",
            projectId: this.assignment.parentAssignment ? "" : ["", Validators.required],
            priorityId: "",
            description: "",
            receivalDate: [new Date(), Validators.required],
            currentStatusTimestamp: null,
            start: undefined,
            end: undefined,
            currentStatusId: AssignmentStatus.NotStarted,
            needParkingBans: false,
            isMachineWork: false,
            signMaterialId: null,
            taskTypeId: null,
            administrativeResponsibleId: administrativeResponsibleIdControl,
            executiveResponsibleId: executiveResponsibleIdControl,
            responsibleClientId: responsibleClientIdControl,
            parentAssignmentId: ""
        }) as SigncoFormGroup;

        const multiNoDuplicateValidator = MultiNoDuplicateValidator.create(responsibleIdControls, false, false);
        for (const responsibleIdControl of responsibleIdControls) {
            responsibleClientIdControl.valueChanges.subscribe(() => {
                this.assignmentForm.updateValueAndValidity({ onlySelf: false, emitEvent: false });
            });
            FormUtils.addValidator(responsibleIdControl, multiNoDuplicateValidator);
        }

        this.resetTaskRule();
        this.assignmentForm.patchValue(this.assignment);

        this.applyTaskRule(this.assignment);

        if (this.projectsService.selectedProjectId) {
            const project = await this.fetchProject(+this.projectsService.selectedProjectId);
            this.setProject(project);
            this.setOrganization(project);
        } else {
            const rootAssignment = this.getRootAssignment();
            this.setOrganization(rootAssignment?.project);
            this.setProject(rootAssignment?.project);
        }
        this.setPriority(this.assignment.priority);
        this.setCurrentStatus(this.assignment.currentStatus);
        if (!this.isCreatingNew() || (this.assignment.project && this.assignment.project.id)) {
            this.assignmentForm.get("organizationId").disable();
            this.assignmentForm.get("projectId").disable();
        }

        this.assignmentForm.markAsPristine();

        this.refreshOrganizationRelatedDropdowns();
        this.refreshProjectRelatedDropdowns();
        this.setSupervisors(this.assignment);

        if (this.assignment.subAssignments && this.assignment.subAssignments.length > 0) {
            this.showTaskTypeAndETC = this.isShowingSubAssignment;
        } else {
            this.showTaskTypeAndETC = true;
        }

        if (!this.assignment.id && this.assignment.parentAssignment) {
            // creating new sub-assignment
            // so set some inherited properties
            this.assignmentForm.get("start").setValue(this.assignment.parentAssignment.start);
            this.assignmentForm.get("end").setValue(this.assignment.parentAssignment.end);
            this.assignmentForm.get("receivalDate").setValue(this.assignment.parentAssignment.receivalDate);
        }

        if (this.assignment && this.assignment.currentStatus && this.assignment.currentStatus.statusId === AssignmentStatus.Finished) {
            this.assignmentForm.get("currentStatusTimestamp").setValue(this.assignment.currentStatus.timestamp);
        }

        // TODO: There's something fucky going on with angular form bindings when enabling / disabling
        // https://github.com/angular/angular/issues/22556
        setTimeout(() => {
            if (this.isReadonly) {
                this.assignmentForm.disable();
            } else {
                // This is needed when swapping back from User impersonation back to admin
                // Has to do with above issue, shouldn't be required (because we re-create the form), yet it is
                this.assignmentForm.enable();
            }
        }, 50);
    }

    private getRootAssignment() {
        if (!this.assignment) return null;

        let rootAssignment = this.assignment;
        while (rootAssignment.parentAssignment != null) {
            rootAssignment = rootAssignment.parentAssignment;
        }

        return rootAssignment;
    }

    reset() {
        if (this.isCreatingNew()) {
            this.navigationService.toAssignments();
            return;
        }

        this.initialize();
    }

    async submit() {
        if (this.isReadonly) return;

        const isValid = await this.formValidationService.checkValidity(
            this.assignmentForm
        );
        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNewAssignment();
        } else {
            this.saveAssignment();
        }
    }

    private createNewAssignment() {
        const onSuccess = async (newAssignment: IAssignment) => {
            this.onSaveSuccess(newAssignment);
        };

        const assignmentCreator = Object.assign(
            new AssignmentCreator(),
            this.assignmentForm.value
        ) as AssignmentCreator;
        assignmentCreator.coordinate = this.assignment.location.coordinate;

        this.submitting = true;
        this.assignmentApi
            .create$(assignmentCreator)
            .subscribe({
                next: onSuccess,
                error: this.onSaveError.bind(this)
            });
    }

    private saveAssignment() {
        const onSuccess = async (savedAssignment: IAssignment) =>
            this.onSaveSuccess(savedAssignment);

        const onError = async () => {
            this.onSaveError();
        };

        const updatedAssignment = Object.assign(
            {},
            this.assignment,
            this.assignmentForm.value
        ) as AssignmentCreator;

        if (this.assignment && this.assignment.currentStatus?.statusId === AssignmentStatus.Finished
            && this.assignmentForm.get("currentStatusId").value !== AssignmentStatus.Finished) {
            updatedAssignment.currentStatusTimestamp = null;
        }

        this.submitting = true;

        this.assignmentApi.update$(updatedAssignment).subscribe({
            next: onSuccess,
            error: onError
        });
    }

    async onSaveSuccess(savedAssignment: IAssignment) {
        this.assignment = savedAssignment;
        this.assignmentForm.markAsPristine();
        this.toastService.saveSuccess();
        this.submitting = false;
        this.save.emit(savedAssignment);
    }

    async onSaveError() {
        this.submitting = false;
    }

    isCreatingNew(): boolean {
        return this.assignment && !this.assignment.id; // check if id is falsy
    }

    private setOrganization(project: IProject) {
        // make sure it's a number so the dropdown binds the value correctly
        const value = project && project.organizationId ? +project.organizationId : undefined;
        this.assignmentForm.get("organizationId").patchValue(value);
    }

    private async setProject(project: IProject) {
        // make sure it's a number so the dropdown binds the value correctly
        const value = project ? +project.id : undefined;
        this.assignmentForm.get("projectId").patchValue(value);

        this.getReferencePeople();
    }

    private setPriority(priority: IAssignmentPriority) {
        const value = priority ? priority.priorityId : undefined;
        this.assignmentForm.get("priorityId").patchValue(value);
    }

    private setCurrentStatus(status: IAssignmentStatusHistory) {
        if (status) {
            this.assignmentForm.get("currentStatusId").patchValue(status.statusId);
        }
    }

    private setSupervisorsFromProject(project: IProject) {
        if (!project?.users) {
            this.assignmentForm.patchValue({
                responsibleClientId: null,
                administrativeResponsibleId: null,
                executiveResponsibleId: null
            });
            return;
        }

        const responsibleClient = project.users.find(x => x.projectRoles.contains(ProjectRoles.CustomerResponsible));
        if (responsibleClient) {
            this.assignmentForm.get("responsibleClientId").patchValue(responsibleClient.userId);
        } else {
            this.assignmentForm.get("responsibleClientId").patchValue(null);
        }

        const administrativeResponsible = project.users.find(x => x.projectRoles.contains(ProjectRoles.AdministrativeResponsible));
        if (administrativeResponsible) {
            this.assignmentForm.get("administrativeResponsibleId").patchValue(administrativeResponsible.userId);
        } else {
            this.assignmentForm.get("administrativeResponsibleId").patchValue(null);
        }

        const executiveResponsible = project.users.find(x => x.projectRoles.contains(ProjectRoles.ProjectResponsible));
        if (executiveResponsible) {
            this.assignmentForm.get("executiveResponsibleId").patchValue(executiveResponsible.userId);
        } else {
            this.assignmentForm.get("executiveResponsibleId").patchValue(null);
        }
    }

    private setSupervisors(assignment: IAssignment) {
        if (assignment.responsibleClient) {
            this.assignmentForm.get("responsibleClientId").patchValue(assignment.responsibleClient.id);
        }
        if (assignment.administrativeResponsible) {
            this.assignmentForm.get("administrativeResponsibleId").patchValue(assignment.administrativeResponsible.id);
        }
        if (assignment.executiveResponsible) {
            this.assignmentForm.get("executiveResponsibleId").patchValue(assignment.executiveResponsible.id);
        }
    }

    private setAssignmentName() {
        if (this.assignmentForm && this.isCreatingNew()) {
            this.generatedName = "";
            if (this.project && this.project.name && this.project.automaticAssignmentNumbering) {
                this.generatedName = this.project.name + "_";
                this.generatedName += this.project.assignments && this.project.assignments.length > 0
                    ? (this.project.assignments.length + 1).toString() : "1";
            }

            this.assignmentForm.get("name").setValue(this.generatedName);
        }
    }

    private initDropdowns() {
        zip(
            this.domainDataService.get(DomainData.AssignmentPriorityValue),
            this.domainDataService.get(DomainData.SignMaterial),
            this.domainDataService.get(DomainData.TaskType),
            this.domainDataService.get(DomainData.AssignmentStatus),
            this.taskRulesApi.getAll$()
        ).subscribe(
            (
                result: [
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ITaskRule[]
                ]
            ) => {
                this.priorities = result[0];
                this.allSignMaterials = result[1];
                this.taskTypes = result[2];
                this.statuses = result[3];
                this.taskRules = result[4];

                this.dropdownValuesFetched.next(true);
            }
        );
    }

    public refreshOrganizationRelatedDropdowns() {
        const latestHasMultipleOrganizations = this.globalEventsService.hasMultipleOrganizations();

        let orgId = null;
        if (latestHasMultipleOrganizations) {
            orgId = this.assignmentForm
                ? this.assignmentForm.get("organizationId").value
                : null;
        } else {
            const org = this.globalEventsService.getDefaultOrganization();
            if (!org) {
                // log authorizationInfo - can we see more here?
                this.errorApi.create$(JSON.stringify(this.globalEventsService.getAuthorizationInfo())).subscribe(() => { }, () => { });
            }
            orgId = this.globalEventsService.getDefaultOrganization()?.id;
        }
        this.domainModelFilterService.getProjects$(orgId, false, this.isCreatingNew()).then((projects) => {
            this.projects = projects;
            this.project = null;
        });
    }

    public async refreshProjectRelatedDropdowns() {
        this.getReferencePeople();
        this.domainModelFilterService
            .getUsers$(null, [Roles.DomainAdministrator, Roles.AssignmentsManager])
            .then((users) => {
                this.supervisors = users;
            });
    }

    async getReferencePeople() {
        let projectId = null;
        projectId = this.assignmentForm.get("projectId").value;
        if (projectId === null || projectId === undefined) return;
        this.project = await this.fetchProject(+projectId);
        if (this.project) {
            this.projectUsers = await this.domainModelFilterService.getUsers$(this.project.organizationId);
        }
    }

    private async fetchProject(projectId: number): Promise<IProject> {
        return this.projectApi
            .get$(projectId, null, this.getServiceRequestOptions())
            .toPromise();
    }

    private getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("project", "users");
        options.includes.add("project", "assignments");
        return options;
    }

    async onChangeStatusDropdown(event: { value: string | number }) {
        // if status is set to Reopend, clear end date
        if (event.value === AssignmentStatus.Reopened) {
            this.assignmentForm.get("end").setValue(null);
        }

    }

    async onChangeProjectDropdown(event: { value: string | number }) {
        this.project = await this.fetchProject(+event.value);

        if (this.project) {
            this.setOrganization(this.project);
            this.refreshOrganizationRelatedDropdowns();
            this.refreshProjectRelatedDropdowns();
            this.setAssignmentName();


            this.setSupervisorsFromProject(this.project);
        }
    }

    // Depending on the selected material, set isMachineWork visible
    handleMaterialChange(event) {
        const currentMaterial = this.getMaterialInfo(event.value);
        this.setSelectIsMachineWork(currentMaterial);

        this.resetFormControlValues(
            "isMachineWork"
        );
    }
    private resetFormControlValues(...formControlNames: string[]) {
        formControlNames.forEach((controlName) => {
            this.assignmentForm.get(controlName).setValue(null);
        });
    }
    private setSelectIsMachineWork(material: ISignMaterialInfo) {
        this.selectIsMachineWork = material && material.canBeMachineWork;
    }

    private getMaterialInfo(selectedMaterial: SignMaterial) {
        if (!this.currentTaskRule || !this.currentTaskRule.signMaterialInfo) {
            return;
        }

        return this.currentTaskRule.signMaterialInfo.find(
            (m) => m.signMaterialId === selectedMaterial
        );

    }
    private resetTaskRule() {
        this.currentTaskRule = undefined;
        this.taskRuleIsSet = false;
        this.selectMaterial = false;
        this.selectIsMachineWork = false;
        this.signMaterials = [];
        return;
    }

    private applyTaskRule(assignment: IAssignment) {
        if (
            !assignment.taskTypeId ||
            !this.taskTypes ||
            this.taskTypes.length === 0
        ) {
            this.resetTaskRule();
            return;
        }

        const rule = this.taskRules
            ? this.taskRules.find((r) => r.taskTypeId === assignment.taskTypeId)
            : undefined;
        if (!rule) {
            this.resetTaskRule();
            return;
        }

        this.refreshTaskRule(rule);
        const currentMaterial = this.getMaterialInfo(assignment.signMaterialId);
        this.setSelectIsMachineWork(currentMaterial);
        this.patchTaskRuleFormValues(assignment);

        this.taskRuleIsSet = true;
    }
    private setMaterialValues(rule: ITaskRule) {
        this.signMaterials =
            rule && rule.signMaterialInfo
                ? this.allSignMaterials.filter(
                    (m) =>
                        rule.signMaterialInfo.findIndex(
                            (smi) => smi.signMaterialId === m.value
                        ) > -1
                )
                : [];
    }

    private refreshTaskRule(rule: ITaskRule) {
        this.currentTaskRule = rule;

        this.setMaterialValues(rule);
        this.setSelectMaterial(rule);
        this.setSelectIsMachineWork(undefined);
    }

    private setSelectMaterial(rule: ITaskRule) {
        this.selectMaterial =
            !!rule && !!rule.signMaterialInfo && rule.signMaterialInfo.length > 0;
    }

    private patchTaskRuleFormValues(assignment: IAssignment) {
        this.assignmentForm.get("taskTypeId").patchValue(
            assignment.taskTypeId
        );
        this.assignmentForm.get("signMaterialId").patchValue(
            assignment.signMaterialId
        );
    }

    // Depending on the selected type,
    // display corresponding material
    handleTaskTypeChange(event) {
        const rule = this.taskRules
            ? this.taskRules.find((r) => r.taskTypeId === event.value)
            : undefined;
        if (!rule) return;

        this.refreshTaskRule(rule);

        this.resetFormControlValues(
            "signMaterialId",
            "isMachineWork",
        );
        this.refreshRequiredValidator("signMaterialId", () => this.selectMaterial);

    }
    private refreshRequiredValidator(
        controlName: string,
        condition: () => boolean
    ) {
        if (condition()) {
            this.assignmentForm.get(controlName).setValidators([
                Validators.required,
            ]);
        } else {
            this.assignmentForm.get(controlName).clearValidators();
        }
        this.assignmentForm.get(controlName).updateValueAndValidity();
    }
}
