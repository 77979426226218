import { IMeasuringPointNavigator } from "./measuring-point";
import { IServiceModelBase } from "./servicemodelbase";
import { IOrganization } from "./user";

export interface ISharedKey extends IServiceModelBase {
    key: string;
    allowValidation: boolean;
    shareAllMeasuringPoints: boolean;
    measuringPoints: IMeasuringPointNavigator[];
    importedBy: IOrganization[];
    owner: IOrganization;
}

export class SharedKeyCreator {
    measuringPointIds: number[];
    allowValidation: boolean;
    shareAllMeasuringPoints: boolean;
    ownerId: number;
}

export class SharedKeyUpdater extends SharedKeyCreator {
    constructor(existingSharedKey: ISharedKey) {
        super();

        this.id = existingSharedKey.id;
        this.allowValidation = existingSharedKey.allowValidation;
        this.shareAllMeasuringPoints = existingSharedKey.shareAllMeasuringPoints;

        if (!this.shareAllMeasuringPoints && existingSharedKey.measuringPoints) {
            this.measuringPointIds = existingSharedKey.measuringPoints.map(x => x.id);
        }
    }

    id: number;
}