<form [formGroup]="tmsRadarConfigurationForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      *ngIf="tmsRadarConfigurationForm"
      autocomplete="off">

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': tmsRadarConfigurationForm.get('port').showErrors }">

        <label class="col-4"
               for="text">
            {{"tmsRadarConfiguration.port" | translate}}
        </label>

        <div class="col-8">
            <input type="text"
                   class="p-inputtext"
                   id="port"
                   formControlName="port"
                   #portInput />

            <app-form-validation [control]="tmsRadarConfigurationForm.get('port')"
                                 [input]="portInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': tmsRadarConfigurationForm.get('angle').showErrors }">

        <label class="col-4"
               for="text">
            {{"tmsRadarConfiguration.angle" | translate}}
        </label>

        <div class="col-8">
            <input type="number"
                   class="p-inputtext"
                   id="angle"
                   min="0"
                   max="90"
                   formControlName="angle"
                   #angleInput />

            <app-form-validation [control]="tmsRadarConfigurationForm.get('angle')"
                                 [input]="angleInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': tmsRadarConfigurationForm.get('delayBetweenMeasures').showErrors }">

        <label class="col-4"
               for="text">
            {{"tmsRadarConfiguration.delayBetweenMeasures" | translate}}

            <app-svg-icon icon="info"
                          class="ml-8 m-btn-baseline"
                          tooltip="{{'tmsRadarConfiguration.delayBetweenMeasuresInfo' | translate}}">
            </app-svg-icon>
        </label>

        <div class="col-8">
            <input type="number"
                   class="p-inputtext"
                   id="delayBetweenMeasures"
                   min="0"
                   max="255"
                   formControlName="delayBetweenMeasures"
                   #delayBetweenMeasuresInput />

            <app-form-validation [control]="tmsRadarConfigurationForm.get('delayBetweenMeasures')"
                                 [input]="delayBetweenMeasuresInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': tmsRadarConfigurationForm.get('detectionZoneLengthInMeters').showErrors }">

        <label class="col-4"
               for="text">
            {{"tmsRadarConfiguration.detectionZoneLengthInMeters" | translate}}
        </label>

        <div class="col-8">
            <div class="m-input-group">
                <input type="number"
                       class="p-inputtext"
                       id="detectionZoneLengthInMeters"
                       min="0"
                       formControlName="detectionZoneLengthInMeters"
                       #detectionZoneLengthInMetersInput />
                <div class="m-input-group-addon">{{"measurements.meter" | translate}}
                </div>
            </div>

            <app-form-validation [control]="tmsRadarConfigurationForm.get('detectionZoneLengthInMeters')"
                                 [input]="detectionZoneLengthInMetersInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': tmsRadarConfigurationForm.get('speedDeltaInKmh').showErrors }">

        <label class="col-4"
               for="text">
            {{"tmsRadarConfiguration.speedDeltaInKmh" | translate}}
        </label>

        <div class="col-8">
            <div class="m-input-group">
                <input type="number"
                       class="p-inputtext"
                       id="speedDeltaInKmh"
                       min="0"
                       formControlName="speedDeltaInKmh"
                       #speedDeltaInKmhInput />
                <div class="m-input-group-addon">{{"measurements.kmh" | translate}}
                </div>
            </div>

            <app-form-validation [control]="tmsRadarConfigurationForm.get('speedDeltaInKmh')"
                                 [input]="speedDeltaInKmhInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row">
        <div class="col-4"></div>
        <div class="col-8">
            <label class="m-input-checkbox">
                <input formControlName="analyzeForwardDirection"
                       name="name"
                       type="checkbox"> {{"tmsRadarConfiguration.analyzeForwardDirection" | translate}}
            </label>
        </div>
    </div>

    <div class="m-form-group row">
        <div class="col-4"></div>
        <div class="col-8">
            <label class="m-input-checkbox">
                <input formControlName="analyzeReverseDirection"
                       name="name"
                       type="checkbox"> {{"tmsRadarConfiguration.analyzeReverseDirection" | translate}}
            </label>
        </div>
    </div>

</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>