import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { AboutComponent } from "./components/about/about.component";
import { AboutRoutingModule } from "./about-routing.module";

@NgModule({
    imports: [
        SharedModule,
        AboutRoutingModule,
    ],
    declarations: [
        AboutComponent,
    ]
})
export class AboutModule { }