import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from "@angular/core";
import { DomainDataService, ViewModelEnumOptions, DomainData } from "src/app/services/domain-data.service";
import { IDevice, DeviceCreator, DeviceUpdater } from "src/app/models/device";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager, OrganizationUtils } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { ExistsValidator } from "src/app/validators/exists.validator";
import { MapDataService } from "src/app/services/map-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { DeviceApi } from "src/app/resource/device.api";
import { ExistsValue } from "../../../../models/search";
import { AuthorizationInfo } from "src/app/models/user";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-detail",
    templateUrl: "./device-detail.component.html"
})
export class DeviceDetailComponent implements OnInit, OnDestroy, OnChanges, IComponentCanDeactivate, IChangeGuard {
    codeInput: ElementRef<HTMLInputElement>;
    @ViewChild("codeInput", { static: false }) set setCodeInput(codeInput: ElementRef<HTMLInputElement>) {
        this.codeInput = codeInput;
    }

    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    featuresForm: SigncoFormGroup;
    deviceForm: SigncoFormGroup;
    deviceTypes: ViewModelEnum[];

    organizations: SelectItem[];
    batteryDischargeTables: SelectItem[];

    groupForm: SigncoFormGroup;

    private readonly mapDataKey: string;
    rights: Rights;
    authorizationInfo: AuthorizationInfo;
    private readonly subscriptionManager = new SubscriptionManager();
    isReadOnly = false;

    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly domainDataService: DomainDataService,
        private readonly mapDataService: MapDataService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly navigationService: NavigationService,
        private readonly domainModelFilterService: DomainModelFilterService) {

        this.mapDataKey = this.mapDataService.createKey();

        elementRef.nativeElement.classList.add("m-layout-area-body");

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
            this.initialize();
        });

        this.domainModelFilterService.getBatteryDischargeTables$().then(batteryDischargeTables => {
            this.batteryDischargeTables = batteryDischargeTables;
        });

        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
        const authorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo: AuthorizationInfo) => {
            this.authorizationInfo = authorizationInfo;
        });
        this.subscriptionManager.add("authorizationInfoSubscription", authorizationInfoSubscription);


    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.deviceForm || (this.deviceForm.pristine && !this.isCreatingNew());
    }

    onDeactivate() {
        if (this.isCreatingNew()) {
            this.device = null;
        }
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    private createForm() {
        const shouldTriggerCodeCheck = () => {
            // Only trigger code check if either the device is being newly created, or has been changed from the initial value
            return this.isCreatingNew() || this.deviceForm.get("code").value !== this.device.code;
        };


        this.featuresForm = this.formBuilder.group({
            zigbee: false,
            gpio: false,
            otaUpdate: false,
            realtimeAnalyzer: false,
            vms: false,
            qLiteDisplay: false,
            dekimoDisplay: false,
            tmsRadar: false,
            safetyCrossing: false,
            simulateData: false
        }) as SigncoFormGroup;


        const deviceId = this.device.id;

        this.deviceForm = this.formBuilder.group({
            code: ["", Validators.required, ExistsValidator.create(
                (value: ExistsValue) => this.deviceApi.exists$(value),
                () => {
                    if (!deviceId) return null;

                    return { id: deviceId };
                },
                shouldTriggerCodeCheck)
            ],
            description: "",
            manufacturer: "",
            imei: "",
            qrCode: "",
            typeId: [null, Validators.required],
            batteryDischargeTableId: null,
            features: this.featuresForm,
            useRealtimeUpdates: false,
            isOffline: false
        }) as SigncoFormGroup;
        if (!this.isCreatingNew()) {
            this.deviceForm.get("imei").disable();
            this.deviceForm.get("qrCode").disable();
        }

        if (!this.rights?.hasBackendRight(BackendRights.EditDevice)) {
            this.deviceForm.get("useRealtimeUpdates").disable();
        }

        if (!this.rights?.hasBackendRight(BackendRights.EditDevice)) {
            this.deviceForm.get("isOffline").disable();
        }

        if (this.rights?.hasBackendRight(BackendRights.EditDevice) && this.globalEventsService.hasMultipleOrganizations()) {
            this.deviceForm.addControl("ownerId", this.formBuilder.control(null, Validators.required));
        }
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device || !this.organizations) return;

        const enumOptions = new ViewModelEnumOptions();
        enumOptions.includeEmpty = this.isCreatingNew();
        enumOptions.emptyLabel = "manageDevice.selectDeviceType";
        this.deviceTypes = await this.domainDataService.get(DomainData.DeviceType, enumOptions);

        this.createForm();
        this.deviceForm.patchValue(this.device);

        const ownerIdControl = this.deviceForm.get("ownerId");
        if (ownerIdControl) {
            const organizationId = this.device.ownerId || this.globalEventsService.getDefaultOrganization().id;
            ownerIdControl.patchValue(organizationId);
        }

        this.deviceForm.markAsPristine();

        // Can't change existing device if you don't have proper rights
        if (!this.rights?.hasBackendRight(BackendRights.EditDevice)) {
            this.deviceForm.disable();
            this.isReadOnly = true;
        } else {
            if (this.codeInput) {
                this.codeInput.nativeElement.focus();
            }
            this.isReadOnly = false;
        }
    }

    async reset() {
        if (!this.isCreatingNew()) {
            this.initialize();
        } else {
            this.navigationService.toDevices();
        }
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.deviceForm);
        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNewDevice();
        } else {
            this.saveDevice();
        }
    }

    private createNewDevice() {
        const deviceCreator = Object.assign(new DeviceCreator(), this.deviceForm.value) as DeviceCreator;

        const onSuccess = async (newDevice: IDevice) => {
            this.toastService.saveSuccess();

            this.device = newDevice;
            this.deviceForm.markAsPristine();

            this.submitting = false;
            this.save.emit(newDevice);
        };

        const onError = () => {
            this.submitting = false;
        };

        this.submitting = true;
        this.deviceApi.create$(deviceCreator).subscribe(onSuccess, onError);
    }

    private saveDevice() {
        const onSuccess = async (savedDevice: IDevice) => {
            this.deviceForm.markAsPristine();
            this.toastService.saveSuccess();
            this.submitting = false;

            this.save.emit(savedDevice);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);
        Object.assign(deviceUpdater, this.deviceForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }

    isCreatingNew(): boolean {
        return this.device && !this.device.id; // check if id is falsy
    }
}