import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AlarmSeverity } from "src/app/models/alarm";
import { AlertLevel } from "src/app/models/alert";
import { SigncoFormGroup } from "src/app/models/form";
import { IMapIcon, MapIconCreator, MapIconSize, MapIconType } from "src/app/models/map-icon";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { MapIconApi } from "src/app/resource/map-icon.api";
import { DomainDataService } from "src/app/services/domain-data.service";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { FormValidationService } from "src/app/services/form-validation.service";

@Component({
    selector: "app-manage-map-icon-dialog",
    templateUrl: "./manage-map-icon.dialog.component.html",
})
export class ManageMapIconDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IMapIcon) => void;
    manageMapIconForm: SigncoFormGroup;

    existingMapIcon: IMapIcon;

    newPreview = false;
    newPreviewNormal: string;
    newPreviewLow: string;
    newPreviewMedium: string;
    newPreviewHigh: string;
    newIconFile: File;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly mapIconApi: MapIconApi,
        private readonly formValidationService: FormValidationService,
        private readonly domainDataService: DomainDataService,
        private readonly domainModelFilterService: DomainModelFilterService
    ) {
        super();
    }

    create(callback?: (res: IMapIcon) => void) {
        this.callback = callback;
        this.existingMapIcon = null;

        this.openDialog();
    }

    edit(existingMapIcon: IMapIcon, callback?: (res: IMapIcon) => void) {
        this.callback = callback;
        this.existingMapIcon = existingMapIcon;

        this.openDialog();
    }

    protected async onOpen() {
        const translationFormGroup = await this.domainDataService.createTranslationFormGroup(this.existingMapIcon ? this.existingMapIcon.codeStringResourceId : null, true);

        this.manageMapIconForm = this.formBuilder.group({
            codeStringResource: translationFormGroup,
            description: "",
            labelColor: ["#FFFFFF", Validators.required],
        }) as SigncoFormGroup;

        if (this.existingMapIcon) {
            this.manageMapIconForm.patchValue(this.existingMapIcon);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageMapIconForm = null;
        this.newPreview = false;
        this.newPreviewNormal = null;
        this.newPreviewLow = null;
        this.newPreviewHigh = null;
        this.newPreviewMedium = null;
        this.newIconFile = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageMapIconForm);
        if (!isValid) return;

        const onSuccess = (savedMapIcon: IMapIcon) => {
            // Reload translations
            this.domainDataService.reload();

            if (this.existingMapIcon) {
                Object.assign(this.existingMapIcon, savedMapIcon);
            }

            if (this.callback) {
                this.callback(savedMapIcon);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };


        this.submitting = true;
        if (!this.existingMapIcon) {
            if (!this.newIconFile) return;

            const mapIconCreator = Object.assign({} as MapIconCreator, this.manageMapIconForm.value) as MapIconCreator;
            mapIconCreator.filename = this.newIconFile.name;
            mapIconCreator.mapIconTypeId = MapIconType.CustomIcon;
            mapIconCreator.labelColor = mapIconCreator.labelColor.toUpperCase(); // to surpress validation error on BE
            mapIconCreator.size = MapIconSize.Normal;

            this.mapIconApi.postWithFormData$(mapIconCreator, this.newIconFile).subscribe({
                next: onSuccess,
                error: onError
            });
        } else {
            const mapIconUpdater = Object.assign(new MapIconCreator(this.existingMapIcon), this.manageMapIconForm.value) as MapIconCreator;
            mapIconUpdater.filename = this.newIconFile ? this.newIconFile.name : mapIconUpdater.filename;
            mapIconUpdater.labelColor = mapIconUpdater.labelColor.toUpperCase(); // to surpress validation error on BE
            mapIconUpdater.mapIconTypeId = MapIconType.CustomIcon;

            let file: File;
            if (this.newIconFile) {
                file = this.newIconFile;
            }

            this.mapIconApi.putWithFormData$(this.existingMapIcon.id, mapIconUpdater, file).subscribe({
                next: onSuccess,
                error: onError
            });
        }
    }

    async setImage(files: FileList) {
        if (!files || files.length !== 1) {
            return;
        }


        const file = files.item(0);
        this.newIconFile = file;
        this.newPreview = true;

        const normalPreviewPromise = this.mapIconApi.getMapIconStatePreview$(file);
        const lowPreviewPromise = this.mapIconApi.getMapIconStatePreview$(file, AlertLevel.info);
        const highPreviewPromise = this.mapIconApi.getMapIconStatePreview$(file, AlertLevel.error);
        const mediumPreviewPromise = this.mapIconApi.getMapIconStatePreview$(file, AlertLevel.warning);

        const previews = await Promise.all([normalPreviewPromise, lowPreviewPromise, highPreviewPromise, mediumPreviewPromise]);

        const readAsDataUrl = (blob: Blob) => {
            return new Promise<string | ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(blob);
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.onerror = () => reject;
            });
        };

        this.newPreviewNormal = (await readAsDataUrl(previews[0].blob)) as string;
        this.newPreviewLow = (await readAsDataUrl(previews[1].blob)) as string;
        this.newPreviewHigh = (await readAsDataUrl(previews[2].blob)) as string;
        this.newPreviewMedium = (await readAsDataUrl(previews[3].blob)) as string;
    }
}
