import { Injectable } from "@angular/core";
import { ISafetyQuestion, SafetyQuestionCreator } from "../models/safety-question";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class SafetyQuestionApi extends ApiService<ISafetyQuestion, SafetyQuestionCreator, SafetyQuestionCreator> {

    getRoute(): string {
        return "SafetyQuestions";
    }

}