<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [customSort]="selectionBox"
         (sortFunction)="customSort($event)"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         sortField="name"
         dataKey="id"
         #tableRef>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [ngSwitch]="column.field">
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>

            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-signScan>
        <tr #row
            [pSelectableRow]="signScan"
            [pSelectableRowDisabled]="!canSelectRow(signScan)"
            [attr.id]="signScan[table.dataKey]">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [ngClass]="resizable ? 'ui-resizable-column' : ''">

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(signScan)"
                                             *ngIf="command.rowValidFunc(signScan)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'location'">
                    {{ signScan.location?.address | address}}
                </ng-container>
                <ng-container *ngSwitchCase="'checkIn'">
                    {{signScan.checkIn| momentDate:false:translateService.currentLang }}
                </ng-container>
                <ng-container *ngSwitchCase="'checkOut'">
                    {{signScan.checkOut | momentDate:false:translateService.currentLang }}
                </ng-container>
                <ng-container *ngSwitchCase="'lost'">
                    {{signScan.lost | momentDate:false:translateService.currentLang }}
                </ng-container>
                <ng-container *ngSwitchCase="'photo'">
                    <img [attr.src]="signScan.photoPreview | safeHtml"
                         *ngIf="signScan.photoPreview"
                         style="max-width: 112px; max-height: 80px;" />
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{signScan[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<app-manage-sign-scan-dialog></app-manage-sign-scan-dialog>