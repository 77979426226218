<div [formGroup]="parentFormGroup">

    <div *ngIf="!initialized && !measuringPointId">
        {{"uploadDetailsDialog.fillInMeasuringPoint" | translate}}
    </div>

    <app-loading-spinner *ngIf="!initialized && measuringPointId"></app-loading-spinner>

    <ng-container>
        <app-device-link-tube-configuration [tubeOptions]="tubeOptions"
                                            [analyzerConfiguration]="analyzerConfiguration"
                                            [enabled]="enabled"
                                            (showOffsetPopup)="showOffsetPopup($event)"> </app-device-link-tube-configuration>

        <app-device-link-tube-xpos-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                 [tubeOptions]="tubeOptions"
                                                 [tubeOptionsWithEmpty]="tubeOptionsWithEmpty"
                                                 [enabled]="enabled"></app-device-link-tube-xpos-configuration>

        <app-device-link-bike-xpos-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                 [tubeOptions]="tubeOptions"
                                                 [tubeOptionsWithEmpty]="tubeOptionsWithEmpty"
                                                 [enabled]="enabled"
                                                 (showOffsetPopup)="showOffsetPopup($event)"></app-device-link-bike-xpos-configuration>

        <app-device-link-car-configuration [analyzerConfiguration]="analyzerConfiguration"
                                           [enabled]="enabled"></app-device-link-car-configuration>

        <app-device-link-car-and-bike-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                    [enabled]="enabled"></app-device-link-car-and-bike-configuration>

        <app-device-link-pedestrian-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                  [enabled]="enabled"></app-device-link-pedestrian-configuration>

        <app-device-link-bike-configuration [analyzerConfiguration]="analyzerConfiguration"
                                            [enabled]="enabled"></app-device-link-bike-configuration>

        <app-device-link-filter-configuration [analyzerConfiguration]="analyzerConfiguration"
                                              [enabled]="enabled"></app-device-link-filter-configuration>

        <app-device-link-camera-configuration [analyzerConfiguration]="analyzerConfiguration"
                                              [enabled]="enabled"></app-device-link-camera-configuration>

        <app-device-link-car-xpos-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                [enabled]="enabled"></app-device-link-car-xpos-configuration>

        <app-device-link-external-input-to-gpio-link [analyzerConfiguration]="analyzerConfiguration"
                                                     [measuringPointId]="measuringPointId"
                                                     [enabled]="enabled"></app-device-link-external-input-to-gpio-link>

        <app-device-link-mode-to-gpio-link [analyzerConfiguration]="analyzerConfiguration"
                                           [measuringPointId]="measuringPointId"
                                           [enabled]="enabled"></app-device-link-mode-to-gpio-link>

        <app-device-link-collect-r-configuration [analyzerConfiguration]="analyzerConfiguration"
                                                 [enabled]="enabled"></app-device-link-collect-r-configuration>

        <app-device-link-dekimo-display-link [analyzerConfiguration]="analyzerConfiguration"
                                             [measuringPointId]="measuringPointId"
                                             [enabled]="enabled"></app-device-link-dekimo-display-link>

        <app-device-link-traffic-light-link [analyzerConfiguration]="analyzerConfiguration"
                                            [enabled]="enabled"></app-device-link-traffic-light-link>
    </ng-container>
</div>

<app-tube-offset-dialog></app-tube-offset-dialog>