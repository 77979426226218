<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingParkingBanException ? "manageParkingBanException.edit" : "manageParkingBanException.create") | translate}}
    </p-header>

    <form *ngIf="manageParkingBanExceptionForm"
          class="p-16"
          [formGroup]="manageParkingBanExceptionForm"
          novalidate>
        <!--licensePlate-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageParkingBanExceptionForm.get('licensePlate').showErrors }">
            <label class="col-4"
                   for="licensePlate">
                {{"parkingBanException.licensePlate" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       placeholder="{{'parkingBanException.licensePlate' | translate}}"
                       id="licensePlate"
                       class="p-inputtext"
                       formControlName="licensePlate"
                       appAutofocus
                       #nameInput />
                <app-form-validation [control]="manageParkingBanExceptionForm.get('licensePlate')"
                                     [input]="licensePlateInput">
                </app-form-validation>
            </div>
        </div>
        <!--address-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageParkingBanExceptionForm.get('address').showErrors }">
            <label class="col-4"
                   for="address">
                {{"general.address" | translate}}
            </label>

            <div class="col-8">
                <div class="row">
                    <div class="col-11">
                        <input type="text"
                               placeholder="{{'general.address' | translate}}"
                               id="address"
                               class="p-inputtext"
                               formControlName="address"
                               [readonly]="true"
                               #addressInput />
                    </div>

                    <div class="col-1">
                        <app-icon-button text="manageLocation.edit"
                                         icon="edit"
                                         (click)="editLocation()"
                                         [style]="{'min-height': '24px'}">
                        </app-icon-button>
                    </div>
                </div>
                <app-form-validation [control]="manageParkingBanExceptionForm.get('address')"
                                     [input]="addressInput">
                </app-form-validation>
            </div>
        </div>
        <!--timestamp-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageParkingBanExceptionForm.get('timestamp').showErrors }">
            <label class="col-4"
                   for="timestamp">
                {{"parkingBanException.timestamp" | translate}}
            </label>
            <div class="col-8">
                <p-calendar formControlName="timestamp"
                            appendTo="body"
                            id="timestamp"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [showTime]="true"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #startInput></p-calendar>

                <app-form-validation [control]="manageParkingBanExceptionForm.get('timestamp')"
                                     [input]="timestampInput">
                </app-form-validation>
            </div>
        </div>
        <!-- photo -->
        <div class="m-form-group">
            <app-photo-input [attachment]="photo"
                             [labelColSize]="4"
                             [colSize]="8"
                             [submitted]="manageParkingBanExceptionForm.submitted"
                             [popup]="true"
                             [deleteImageMessageToTranslate]="'manageParkingBanException.deleteImageConfirmation'"></app-photo-input>
        </div>
    </form>
    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>

<app-location-dialog></app-location-dialog>