import { Component, OnChanges, SimpleChanges, Input, HostListener, OnDestroy } from "@angular/core";
import { IDevice, DeviceUpdater, IDeviceIgnoredRule } from "src/app/models/device";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { SearchParameters, FilterDescriptor, FilterOperator } from "src/app/models/search";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { SubscriptionManager } from "src/app/utilities";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";
import { RuleApi } from "src/app/resource/rule.api";
import { IRule } from "src/app/models/alarm";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

class IgnoredRule {
    constructor(public rule: IRule, public ignored = false, public until: Date = null, public comment: string = null) { }
}

@Component({
    selector: "app-device-ignore-rules",
    templateUrl: "./device-ignore-rules.component.html"
})
export class DeviceIgnoreRulesComponent implements OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;
    readonly: boolean;

    loaded: boolean;
    submitting: boolean;
    rules: IRule[];
    ignoredRules: IgnoredRule[];
    allEnabled = false;
    hasChanges: boolean;

    calendarSettings: CalendarSettings;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly toastService: ToastService,
        private readonly ruleApi: RuleApi,
        private readonly deviceApi: DeviceApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly globalEventsService: GlobalEventsService) {

        this.changeGuardService.setComponent(this);

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.readonly = !rights?.hasBackendRight(BackendRights.EditRule);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.hasChanges;
    }

    onDeactivate() {
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    trackByFn(index: number, item: IRule) {
        return item.id;
    }

    load(useCache = true) {
        if (!this.device) return;

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("deviceTypes", this.device.typeId, FilterOperator.in)];

        this.ruleApi.search$(searchParameters, null, useCache).subscribe(result => {
            this.rules = result.data;

            this.loadIgnoredRules();

            this.loaded = true;
        });
    }

    loadIgnoredRules() {
        const ignoredRules = new Array<IgnoredRule>();
        const now = new Date().getTime();

        for (const rule of this.rules) {
            const ignoredRule = this.device.ignoredRules.find(x => x.ruleId === rule.id);
            const isIgnored = !!ignoredRule && (!ignoredRule.until || ignoredRule.until.getTime() > now);
            const until = ignoredRule ? ignoredRule.until : null;
            const comment = ignoredRule ? ignoredRule.comment : null;
            ignoredRules.push(new IgnoredRule(rule, isIgnored, until, comment));
        }

        this.ignoredRules = ignoredRules;
        this.updateAllEnabled();
    }

    setAllEnabled(allEnabled: boolean = null) {
        if (allEnabled !== null) {
            this.allEnabled = allEnabled;
        }

        this.ignoredRules.forEach(ignoredRule => ignoredRule.ignored = !this.allEnabled);
        this.hasChanges = true;
    }

    updateAllEnabled() {
        this.allEnabled = !this.ignoredRules.find(x => x.ignored);
    }

    onChange(ignoredRule: IgnoredRule = null, checked: boolean = false) {
        if (ignoredRule) {
            ignoredRule.ignored = !checked;
            this.updateAllEnabled();
        }

        this.hasChanges = true;
    }

    reset() {
        this.hasChanges = false;
        this.loadIgnoredRules();
    }

    submit() {
        this.submitting = true;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();

            Object.assign(this.device, savedDevice);

            this.reset();
            this.submitting = false;
        };

        const onError = () => {
            this.submitting = false;
        };

        const deviceUpdater = new DeviceUpdater(this.device);
        deviceUpdater.ignoredRules = this.ignoredRules.filter(x => x.ignored || x.comment || x.until).map(x => {
            return {
                ruleId: x.rule.id,
                until: x.until,
                comment: x.comment
            } as IDeviceIgnoredRule;
        });

        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}