<form [formGroup]="realtimeAnalyzerConfigurationForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      *ngIf="realtimeAnalyzerConfigurationForm"
      autocomplete="off">
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': realtimeAnalyzerConfigurationForm.get('maxWindowTime').showErrors }">
        <label class="col-4"
               for="maxWindowTime">
            {{"deviceDisplay.maxWindowTime" | translate}}
        </label>

        <div class="col-8">
            <div class="m-input-group">
                <input type="number"
                       formControlName="maxWindowTime"
                       class="p-inputtext"
                       id="maxWindowTime"
                       #maxWindowTimeInput>
                <div class="m-input-group-addon">{{"measurements.ms" | translate}}</div>
            </div>

            <app-form-validation [control]="realtimeAnalyzerConfigurationForm.get('maxWindowTime')"
                                 [input]="maxWindowTimeInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': realtimeAnalyzerConfigurationForm.get('minTimeBetweenWheelies').showErrors }">
        <label class="col-4"
               for="minTimeBetweenWheelies">
            {{"deviceDisplay.minTimeBetweenWheelies" | translate}}
        </label>

        <div class="col-8">
            <div class="m-input-group">
                <input type="number"
                       formControlName="minTimeBetweenWheelies"
                       id="minTimeBetweenWheelies"
                       class="p-inputtext"
                       #minTimeBetweenWheeliesInput>
                <div class="m-input-group-addon">{{"measurements.ms" | translate}}</div>
            </div>

            <app-form-validation [control]="realtimeAnalyzerConfigurationForm.get('minTimeBetweenWheelies')"
                                 [input]="minTimeBetweenWheeliesInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': realtimeAnalyzerConfigurationForm.get('simulateVehicleOnEveryPulse').showErrors }">
        <label class="col-4"
               for="simulateVehicleOnEveryPulse">
            {{"deviceDisplay.simulateVehicleOnEveryPulse" | translate}}
        </label>

        <div class="col-8">
            <p-inputSwitch id="simulateVehicleOnEveryPulse"
                           formControlName="simulateVehicleOnEveryPulse"
                           #simulateVehicleOnEveryPulseInput>
            </p-inputSwitch>
        </div>
    </div>

</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">

    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}

    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>