import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { Component, OnDestroy } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { UploadsArchiveApi } from "src/app/resource/upload-archive.api";
import { SigncoFormGroup } from "src/app/models/form";
import { DownloadedFile } from "src/app/services/download-file.service";
import { UntypedFormBuilder } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { IDevice } from "src/app/models/device";
import { IMeasuringPoint } from "src/app/models/measuring-point";

@Component({
    selector: "app-download-archive-dialog",
    templateUrl: "./download-archive.dialog.html"
})
export class DownloadArchiveDialogComponent extends DialogComponentBase implements OnDestroy {
    measuringPoint: IMeasuringPoint;
    device: IDevice;
    submitting: boolean;
    downloadArchiveForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;

    constructor(
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly uploadsArchiveApi: UploadsArchiveApi) {

        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    open(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.device = null;
        this.openDialog();
    }

    openForDevice(device: IDevice) {
        this.measuringPoint = null;
        this.device = device;
        this.openDialog();
    }

    protected onOpen() {
        this.downloadArchiveForm = this.formBuilder.group({
            from: null,
            to: null
        }) as SigncoFormGroup;

        this.submitting = false;
    }

    protected onClose() {
        this.downloadArchiveForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.downloadArchiveForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (downloadedFile: DownloadedFile) => {
            this.submitting = false;
            this.close();
            downloadedFile.save();
        };

        const onError = () => {
            this.submitting = false;
        };

        const routeParams: { [key: string]: string } = {};

        const from = this.downloadArchiveForm.get("from").value as Date;
        if (from) {
            routeParams.from = from.toISOString();
        }

        const until = this.downloadArchiveForm.get("to").value as Date;
        if (until) {
            routeParams.until = until.toISOString();
        }

        if (this.measuringPoint) {
            routeParams.measuringPointId = this.measuringPoint.id.toString();
        }

        if (this.device) {
            routeParams.deviceId = this.device.id.toString();
        }

        const params = new HttpParams(
            {
                fromObject: routeParams
            });

        this.uploadsArchiveApi.download$(params).then(onSuccess, onError);
    }
}