import { Component, inject, Input } from "@angular/core";
import { ILocationStatusSummary } from "src/app/models/web";
import { ViewStateService } from "../../../services/view-state.service";

@Component({
    selector: "app-status-header",
    templateUrl: "./status-header.component.html",
    styleUrls: ["./status-header.component.scss", "../location-popup.component.scss"],
})
export class StatusHeaderComponent {
    @Input() locationStatus: ILocationStatusSummary;
    private readonly viewStateService = inject(ViewStateService);

    protected toggleShowDeviceDetails() {
        this.viewStateService.showDeviceDetails = !this.viewStateService.showDeviceDetails;
    }

    protected showDeviceDetails(): boolean {
        return this.viewStateService.showDeviceDetails;
    }
}