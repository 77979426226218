import { Component, EventEmitter, OnDestroy, Output, inject } from "@angular/core";
import { SearchResultViewModel } from "../search-result/search-result-view-model";
import { MeasuringPointSearchParameters } from "src/app/models/web";
import { LocationWebApi } from "src/app/resource/web";
import { MapIconService } from "src/app/services/map-icon-service";
import { LocationRealtimeService } from "src/app/services/realtime/location-realtime.service";
import { SubscriptionManager } from "src/app/utilities";
import { ILocationChangedArguments } from "src/app/models/location-changed-arguments";

@Component({
    selector: "app-result-container",
    templateUrl: "./result-container.component.html",
    styleUrls: ["./result-container.component.scss"],
})
export class ResultContainerComponent implements OnDestroy {

    @Output() public locationSelected: EventEmitter<number> = new EventEmitter();

    private readonly locationWebApi = inject(LocationWebApi);
    private readonly mapIconService = inject(MapIconService);
    private readonly locationRealtimeService = inject(LocationRealtimeService);

    protected isLoading = false;
    protected isLoadingInfiniteData = false;

    protected viewModels: SearchResultViewModel[] = [];
    private selectedViewModel: SearchResultViewModel;
    private clonedSearchParameters: MeasuringPointSearchParameters;
    private subscriptionManager = new SubscriptionManager();

    constructor() {
        const realtimeSub = this.locationRealtimeService.subscribe(args => this.onRealtimeLocationChanged(args));
        this.subscriptionManager.add("realtimeSub", realtimeSub);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    public async loadResults(searchParameters: MeasuringPointSearchParameters): Promise<void> {
        this.isLoading = true;

        // The searchparameters that we get don't use paging, because they are also used for the map.
        // But in this component we want paging. We don't want to change the original searchparameters, so we clone and apply paging.
        this.clonedSearchParameters = searchParameters.shallowClone();
        this.clonedSearchParameters.take = 50;
        this.clonedSearchParameters.includeAdditionalData = true;

        if (!this.clonedSearchParameters.skip) {
            this.viewModels = []; // Immediately remove the previous results
        }

        const locations = await this.locationWebApi.getLocationsWithMeasuringPoints(this.clonedSearchParameters);

        const newViewModels = locations.data.map(location => new SearchResultViewModel(location));

        await this.mapIconService.waitUntilInitialized(); // Make sure icons are loaded before we will display them

        if (!this.clonedSearchParameters.skip) {
            this.viewModels = newViewModels;
        } else {
            this.viewModels = this.viewModels.concat(newViewModels);
        }

        this.isLoading = false;
    }

    protected async handleScroll(event: Event) {
        const target = event.target as HTMLDivElement;
        if (!target) return;

        // No scroll, no behavior, sanity checking
        if (target.scrollHeight <= target.offsetHeight) return;

        // 85 is height of record, if we're at final 5, load more
        // Using a percentage calculation is not advised as we'll compound more and more records at the bottom
        if (target.scrollTop > (target.scrollHeight - target.offsetHeight) - (85 * 5)) {
            await this.triggerInfiniteScroll();
        }
    }

    private async triggerInfiniteScroll() {
        if (!this.clonedSearchParameters || this.isLoadingInfiniteData || this.isLoading) return;

        this.isLoadingInfiniteData = true;

        if (!this.clonedSearchParameters.skip) {
            this.clonedSearchParameters.skip = 0;
        }

        this.clonedSearchParameters.skip += this.clonedSearchParameters.take;

        // Prevent going nuts when everything is loaded and scrolled to bottom
        await this.loadResults(this.clonedSearchParameters);

        this.isLoadingInfiniteData = false;
    }

    protected onSelect(item: SearchResultViewModel) {
        if (this.selectedViewModel) this.selectedViewModel.isSelected = false;
        if (!item) return;
        item.isSelected = true;
        this.selectedViewModel = item;
        this.locationSelected.emit(item.location.id);
    }

    /**
     * Is invoked when the user clicks on the map
     */
    public deselect() {
        this.onSelect(null);
    }

    public selectId(locationId: number) {
        // Note that the viewModel might not be found, the map always shows all locations, but the searchresults uses paging
        const item = this.viewModels.find(x => x.location.id === locationId);
        this.onSelect(item);
    }

    protected trackLocation(index: number, item: SearchResultViewModel) {
        return item.location.id;
    }

    private async onRealtimeLocationChanged(args: ILocationChangedArguments) {
        for (const viewModel of this.viewModels) {
            const arg = args.data.find(x => x.id === viewModel.location.id);
            if (!arg) continue;

            // Both icon and code could have changed
            viewModel.location.iconStateId = arg.iconStateId;
            viewModel.location.iconStateIds = arg.iconStateIds;
            viewModel.location.code = arg.code;
        }
    }
}