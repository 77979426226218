import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { SigncoFormGroup } from "src/app/models/form";
import { SigncoFormArray } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { IExternalInput } from "src/app/models/external-input-configuration";
import { DownloadFileService } from "src/app/services/download-file.service";
import { FileUtils, SubscriptionManager } from "src/app/utilities";
import { TranslateService } from "@ngx-translate/core";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";

class FileElement {
    trueValueFile: File;
    trueValueFilePreview: string;

    falseValueFile: File;
    falseValueFilePreview: string;
}

@Component({
    selector: "app-measuring-point-external-input-configuration",
    templateUrl: "./measuring-point-external-input-configuration.component.html",
    host: { class: "m-layout-area-body m-layout-default" }
})
export class MeasuringPointExternalInputConfigurationComponent implements OnDestroy, OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;

    @Output() save = new EventEmitter<IMeasuringPoint>();

    submitting = false;
    inputForms: SigncoFormArray;
    externalInputConfigurationForm: SigncoFormGroup;
    inputOptions: SelectItem[];
    externalInputTypes: ViewModelEnum[];

    files: FileElement[] = [];

    gridStyle = {
        "grid-template-columns": "125px auto 220px 220px"
    };

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly domainDataService: DomainDataService,
        private readonly downloadFileService: DownloadFileService,
        private readonly translateService: TranslateService) {
    }
    ngOnDestroy() {
        this.subscriptionManager.clear();
    }
    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.externalInputConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint?.externalInputConfiguration) return;

        this.externalInputTypes = await this.domainDataService.get(DomainData.ExternalInputType);

        this.files = [];
        this.inputForms = this.formBuilder.array([]) as SigncoFormArray;

        this.externalInputConfigurationForm = this.formBuilder.group({
            type: this.measuringPoint.externalInputConfiguration.type,
            inputs: this.inputForms
        }) as SigncoFormGroup;

        if (this.measuringPoint.externalInputConfiguration) {
            for (const _ of this.measuringPoint.externalInputConfiguration.inputs) {
                await this.addInput(_);
            }
        }

        this.externalInputConfigurationForm.patchValue(this.measuringPoint.externalInputConfiguration);

        this.updateInputOptions();

        this.externalInputConfigurationForm.markAsPristine();
    }

    async reset() {
        this.files = [];

        this.initialize();
    }

    //#region Inputs

    async addInput(externalInput: IExternalInput = null) {
        const sensorFormGroup = this.formBuilder.group({
            id: [null, Validators.required],
            description: null
        });

        this.inputForms.push(sensorFormGroup);

        const fileElement = new FileElement();

        if (externalInput?.trueIconUrl) {
            const file = await this.downloadFileService.downloadBlob(externalInput.trueIconUrl);
            fileElement.trueValueFile = file.file;
            fileElement.trueValueFilePreview = await FileUtils.toBase64(file.file);
            externalInput.trueIconPreview = fileElement.trueValueFilePreview;
        } else {
            fileElement.trueValueFile = null;
            fileElement.trueValueFilePreview = null;
        }

        if (externalInput?.falseIconUrl) {
            const file = await this.downloadFileService.downloadBlob(externalInput.falseIconUrl);
            fileElement.falseValueFile = file.file;
            fileElement.falseValueFilePreview = await FileUtils.toBase64(file.file);
            externalInput.falseIconPreview = fileElement.falseValueFilePreview;
        } else {
            fileElement.falseValueFile = null;
            fileElement.falseValueFilePreview = null;
        }

        this.files.push(fileElement);
    }

    deleteInput(index: number) {
        this.inputForms.removeAt(index);
        this.files.splice(index, 1);
    }

    updateInputOptions() {
        if (!this.inputForms) return;

        const ids = this.inputForms.controls.map(x => x.get("id").value as string).filter(x => !!x);
        this.inputOptions = this.primeComponentService.createDropdownList(ids, x => x, x => x, false);
    }

    //#endregion Inputs

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.externalInputConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.files = [];
            this.initialize();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        Object.assign(measuringPointUpdater.externalInputConfiguration, this.externalInputConfigurationForm.value);


        const files: { name: string, file: File }[] = [];
        if (measuringPointUpdater.externalInputConfiguration?.inputs) {
            for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].trueValueFile) {
                    // Form data parameter name should be in this format (handling on BE)
                    files.push({ name: `${measuringPointUpdater.externalInputConfiguration.inputs[i].id}_true`, file: this.files[i].trueValueFile });
                }

                if (this.files[i].falseValueFile) {
                    // Form data parameter name should be in this format (handling on BE)
                    files.push({ name: `${measuringPointUpdater.externalInputConfiguration.inputs[i].id}_false`, file: this.files[i].falseValueFile });
                }
            }
        }

        this.submitting = true;
        this.measuringPointApi.updateWithFormData$(measuringPointUpdater, files).subscribe(onSuccess, onError);
    }

    async setFile(event: { files: FileList }, modeIndex: number, isTrueStatus: boolean) {
        if (!event || event.files.length <= 0) return;

        if (event.files[0].size > 2097152) {
            this.toastService.warning(this.translateService.instant("general.fileTooBig", { fileSize: "2MB" }));
            return;
        }

        if (isTrueStatus) {
            this.files[modeIndex].trueValueFile = event.files[0];
            this.files[modeIndex].trueValueFilePreview = await FileUtils.toBase64(event.files[0]);
        } else {
            this.files[modeIndex].falseValueFile = event.files[0];
            this.files[modeIndex].falseValueFilePreview = await FileUtils.toBase64(event.files[0]);
        }
    }

    setPhotoDeleted(index: number, isTrueStatus: boolean, input: HTMLInputElement) {
        // clear input field so same image can be selected again..
        input.value = null;
        if (isTrueStatus) {
            this.files[index].trueValueFile = null;
            this.files[index].trueValueFilePreview = null;
        } else {
            this.files[index].falseValueFile = null;
            this.files[index].falseValueFilePreview = null;
        }
    }
}