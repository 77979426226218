import { LazyTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ViewChild, OnInit, ElementRef } from "@angular/core";
import { ManageAttachmentTypeDialogComponent } from "../manage-attachment-type-dialog/manage-attachment-type.dialog";
import { AttachmentTypeApi } from "src/app/resource/attachment-type.api";
import { TranslateService } from "@ngx-translate/core";
import { IAttachmentType } from "src/app/models/attachment";
import { ModalService } from "src/app/services/modal.service";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-attachment-types",
    templateUrl: "./attachment-types.component.html"
})
export class AttachmentTypesComponent extends LazyTableComponent<IAttachmentType> implements OnInit {
    @ViewChild(ManageAttachmentTypeDialogComponent, { static: true }) manageAttachmentTypeDialog: ManageAttachmentTypeDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly attachmentTypeApi: AttachmentTypeApi,
        private readonly translate: TranslateService) {

        super("attachment-types.component", elementRef, attachmentTypeApi, tableService);

        this.stretchHeight = true;

        this.selectionMode = "";
        this.includeObsolete = true;

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "attachmentTypes.edit",
            icon: "edit",
            click: (attachmentType) => this.edit(attachmentType),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (attachmentType) => this.delete(attachmentType),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (attachmentType) => !attachmentType.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (attachmentType) => this.restore(attachmentType),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (attachmentType) => attachmentType.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    edit(attachmentType: IAttachmentType) {
        this.manageAttachmentTypeDialog.edit(attachmentType);
    }

    delete(attachmentType: IAttachmentType) {
        const onDeleteSuccess = () => {
            attachmentType.isObsolete = true;
        };

        const onDelete = () => {
            this.attachmentTypeApi.delete$(attachmentType.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("attachmentTypes.deleteConfirmation", { code: attachmentType.code });
        this.modalService.delete(modalBody, onDelete);
    }

    restore(attachmentType: IAttachmentType) {
        const onRestoreSuccess = () => {
            attachmentType.isObsolete = false;
        };

        const restoredAttachmentType = Object.assign({}, attachmentType);
        restoredAttachmentType.isObsolete = false;

        this.attachmentTypeApi.update$(restoredAttachmentType).subscribe(onRestoreSuccess);
    }
}