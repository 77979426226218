import { Component, Input } from "@angular/core";
import { IMessageAndLevel } from "src/app/models/measuring-point-status";
import { MessageLevel } from "src/app/models/pinned-data";

@Component({
    selector: "app-user-message",
    templateUrl: "./user-message.component.html",
    styleUrls: ["./user-message.component.scss", "../../../location-popup.component.scss"],
})
export class UserMessageComponent {
    @Input() data: IMessageAndLevel;
}