import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { ISymbolSet, SymbolSetCreator } from "../models/symbol-set";

@Injectable({
    providedIn: "root"
})
export class SymbolSetApi extends ApiService<ISymbolSet, SymbolSetCreator, SymbolSetCreator> {

    getRoute(): string {
        return "SymbolSets";
    }
}