import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { SigncoFormControl, SigncoFormGroup } from "src/app/models/form";
import { AnalysisType, IMeasuringPoint } from "src/app/models/measuring-point";
import { BasicMapComponent } from "src/app/modules/map-basic/components/basic-map/basic-map.component";
import { DataDaysService } from "src/app/services/data-days.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { CalendarSettings, DateFormControl, PrimeComponentService } from "src/app/services/prime-component.service";
import { SubscriptionManager, MapUtils } from "src/app/utilities";
import { MinMaxValidator } from "src/app/validators/min-max.validator";
import { LocationLogViewModel, ViewStates } from "./LocationLogViewModel";
import { MeasuringPointLocationHistoryApi } from "src/app/resource/measuring-point-location-history.api";
import { ILocationLog } from "src/app/models/measuring-point-location-history";

@Component({
    selector: "app-measuring-point-status",
    templateUrl: "./measuring-point-status.component.html",
    styleUrls: ["./measuring-point-status.component.scss"],
    host: { class: "m-layout-area-body m-layout-w-actions-bottom" }
})
export class MeasuringPointStatusComponent implements IComponentCanDeactivate, OnDestroy {

    @Input() measuringPoint: IMeasuringPoint;
    statusForm: SigncoFormGroup;
    rangeFromDateControl: DateFormControl;
    rangeUntilDateControl: DateFormControl;
    snapCoordinatesControl: SigncoFormControl;
    calendarSettings: CalendarSettings;
    private subscriptionManager = new SubscriptionManager();
    maxFromDate: Date = new Date();

    isTableLoading = false;
    locationHistory: LocationLogViewModel[] = [];

    map: BasicMapComponent;

    constructor(
        private readonly measuringPointLocationHistoryApi: MeasuringPointLocationHistoryApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly zone: NgZone,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        readonly dataDaysService: DataDaysService,
    ) {
        this.dataDaysService.clear();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.rangeFromDateControl = new DateFormControl(null, new Date().addDays(-14));
        this.rangeFromDateControl.setDateSelectionMode("single");
        this.rangeFromDateControl.setValidators(Validators.required);

        this.rangeUntilDateControl = new DateFormControl(null, new Date());
        this.rangeUntilDateControl.setDateSelectionMode("single");

        this.snapCoordinatesControl = new SigncoFormControl(false);

        MinMaxValidator.create(this.rangeFromDateControl, this.rangeUntilDateControl);
        this.statusForm = this.formBuilder.group({
            from: this.rangeFromDateControl,
            until: this.rangeUntilDateControl,
            snapCoordinates: this.snapCoordinatesControl
        }) as SigncoFormGroup;
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    canDeactivate() {
        return true;
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        // We don't do anything yet, we need to wait for the map to be initialized
    }

    async submit(navigateToResult = true) {
        const isValid = await this.formValidationService.checkValidity(this.statusForm);
        if (!isValid) {
            return;
        }

        this.loadLocationHistory(navigateToResult);
    }

    private initialize() {
        this.loadLocationHistory();
    }

    handleMapReady(basicMap: BasicMapComponent) {
        this.map = basicMap;
        this.initialize();
    }

    private loadLocationHistory(navigateToResult = true): void {
        this.isTableLoading = true;
        const onSuccess = (result: ILocationLog[]) => {
            this.isTableLoading = false;

            // For perf, maps are updated outside of angular
            this.zone.runOutsideAngular(() => {
                this.removeMapContent();

                const previousSelected = this.locationHistory.find(x => x.isSelected);

                this.locationHistory.length = 0;
                for (const log of result) {
                    const vm = new LocationLogViewModel(this.map.map, log);
                    const copy = vm; // capture continuation variable
                    vm.addListener("click", () => this.onClicked(copy));
                    this.locationHistory.push(vm);
                }

                if (previousSelected) {
                    const newSelected = this.locationHistory.find(x => x.from.getTime() == previousSelected.from.getTime() && x.distance == previousSelected.distance);
                    if (newSelected) {
                        setTimeout(() => {
                            this.onClicked(newSelected);
                        }, 1)
                    }
                }

                if (navigateToResult) {
                    this.centerOnMap();
                }
            });
        };
        const onError = () => {
            this.isTableLoading = false;
        };

        this.measuringPointLocationHistoryApi.getSummary$(this.measuringPoint.id, this.rangeFromDateControl.value, this.rangeUntilDateControl.value, this.snapCoordinatesControl.value)
            .subscribe({ next: onSuccess, error: onError });
    }

    private removeMapContent() {
        for (const vm of this.locationHistory) {
            vm.dispose();
        }
    }

    private centerOnMap() {
        if (this.locationHistory.length === 0) return;
        const bounds = new google.maps.LatLngBounds();
        for (const log of this.locationHistory) {
            for (const record of log.model.records) {
                if (!record.coordinate) continue;
                bounds.extend(MapUtils.toLatLng(record.coordinate));
            }
        }
        this.map.fitBounds(bounds);
    }

    private onClicked(vm: LocationLogViewModel) {
        this.onViewModelClick(vm);
    }

    onViewModelClick(vm: LocationLogViewModel) {
        // Scroll into view
        document.getElementById("record_" + vm.id).scrollIntoView({ behavior: "smooth", block: "nearest" });

        // For perf, maps are updated outside of angular
        this.zone.runOutsideAngular(() => {

            if (vm.isSelected) {
                // Deselected --> all logs go to normal
                for (const other of this.locationHistory) {
                    other.setViewState(ViewStates.Normal);
                }
                return;
            }

            // deselect all the others
            for (const other of this.locationHistory) {
                if (other !== vm) other.setViewState(ViewStates.Hidden);
            }

            vm.setViewState(ViewStates.Selected);
        });
    }

    protected get showArrowBoardTimeline(): boolean {
        return false; //this.measuringPoint?.analysisTypeId === AnalysisType.ArrowBoard;
    }

    protected get ramuddenYellow(): string {
        return getComputedStyle(document.documentElement).getPropertyValue('--ramuddenYellow');
    }
}
