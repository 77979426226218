<nav class="m-vms-designer-nav">
    <ul class="m-nav-list">

        <li class="m-nav-list-item">
            <a (click)="setTool(0)"
               [ngClass]="{'is-active': currentTool == 0}"
               aria-hidden="true"
               class="m-nav-link">
                <app-svg-icon icon="arrow-pointer"
                              divClass="icon-16">
                </app-svg-icon>
            </a>
        </li>
        <ng-container *ngIf="!loading">
            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(1)"
                   [ngClass]="{'is-active': currentTool == 1}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="text"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.text' | translate">
                    </app-svg-icon>
                </a>
            </li>

            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(2)"
                   [ngClass]="{'is-active': currentTool == 2}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="square"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.square' | translate">
                    </app-svg-icon>
                </a>
            </li>
            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(3)"
                   [ngClass]="{'is-active': currentTool == 3}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="circle"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.circle' | translate">
                    </app-svg-icon>
                </a>
            </li>

            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(7)"
                   [ngClass]="{'is-active': currentTool ==7}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="triangle"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.triangle' | translate">
                    </app-svg-icon>
                </a>
            </li>

            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(5)"
                   [ngClass]="{'is-active': currentTool == 5}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="pipe"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.line' | translate">
                    </app-svg-icon>
                </a>
            </li>

            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="setTool(6)"
                   [ngClass]="{'is-active': currentTool == 6}"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="arrow-up-right"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.arrow' | translate">
                    </app-svg-icon>
                </a>
            </li>

            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="openSymbolSelectorDialog()"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="square-dashed-circle-plus"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.symbol' | translate">
                    </app-svg-icon>
                </a>
            </li>
            <li class="m-nav-list-item"
                *ngIf="showCanvasTool()">
                <a (click)="openCopyFromDialog()"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="copy-fa"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.copyFromTitle' | translate">
                    </app-svg-icon>
                </a>
            </li>
            <li class="m-nav-list-item">
                <a (click)="openUploadDialog()"
                   aria-hidden="true"
                   class="m-nav-link">
                    <app-svg-icon icon="upload-fa"
                                  divClass="icon-16"
                                  [tooltip]="'vmsImageEditor.uploadTitle' | translate">
                    </app-svg-icon>
                </a>
            </li>
        </ng-container>
    </ul>
</nav>

<app-symbol-selector-dialog></app-symbol-selector-dialog>
<app-vms-image-editor-copyfrom-dialog></app-vms-image-editor-copyfrom-dialog>
<app-upload-vms-image-dialog></app-upload-vms-image-dialog>