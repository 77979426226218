import { Component, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { FilterDescriptor, SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";
import { DomainData, DomainDataService, ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { DeviceHardwareConfigurationDialogComponent } from "../device-hardware-configuration-dialog/device-hardware-configuration.dialog";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { AllOrNothingRequiredValidator } from "src/app/validators/all-or-nothing-required.validator";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { SubscriptionManager } from "src/app/utilities";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { ReleaseApi } from "src/app/resource/release.api";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-ota-configuration",
    templateUrl: "./device-ota-configuration.component.html"
})
export class DeviceOtaConfigurationComponent implements OnChanges, IComponentCanDeactivate, OnDestroy {
    @ViewChild(DeviceHardwareConfigurationDialogComponent, { static: true }) hardwareConfigurationDialog: DeviceHardwareConfigurationDialogComponent;

    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;

    dekimoGuiConfigurationForm: SigncoFormGroup;
    dekimoTrackerConfigurationForm: SigncoFormGroup;
    otaConfigurationForm: SigncoFormGroup;
    releaseConfigurationForms: SigncoFormArray;
    releases: { [key: string]: SelectItem[] } = {};
    releaseChannels: ViewModelEnum[];
    packages: ViewModelEnum[];
    originalHardwareConfiguration: string; // Needed for reset functionality

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly releaseApi: ReleaseApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainDataService: DomainDataService,
        private readonly primeComponentService: PrimeComponentService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        if (this.originalHardwareConfiguration) {
            if (this.originalHardwareConfiguration != this.device.hardwareConfiguration) return false;
        }

        if (!this.otaConfigurationForm) return true;

        return this.otaConfigurationForm.pristine;
    }

    onDeactivate() {
        this.device.hardwareConfiguration = this.originalHardwareConfiguration;
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        this.originalHardwareConfiguration = this.device.hardwareConfiguration;

        const enumOptions = new ViewModelEnumOptions();
        enumOptions.includeEmpty = false;
        this.packages = await this.domainDataService.get(DomainData.Package, enumOptions);

        enumOptions.emptyLabel = "manageDevice.selectReleaseChannel";
        enumOptions.includeEmpty = false;
        enumOptions.orderBy = null;
        this.releaseChannels = await this.domainDataService.get(DomainData.ReleaseChannel, enumOptions);

        this.dekimoGuiConfigurationForm = this.formBuilder.group({
            password: null
        }) as SigncoFormGroup;

        this.dekimoTrackerConfigurationForm = this.formBuilder.group({
            password: null
        }) as SigncoFormGroup;

        this.releaseConfigurationForms = this.formBuilder.array([]) as SigncoFormArray;

        this.otaConfigurationForm = this.formBuilder.group({
            releaseConfigurations: this.releaseConfigurationForms,
            dekimoGuiConfiguration: this.dekimoGuiConfigurationForm,
            dekimoTrackerConfiguration: this.dekimoTrackerConfigurationForm
        }) as SigncoFormGroup;

        this.updateDekimoForms();

        if (this.device && this.device.releaseConfigurations) {
            for (const _ of this.device.releaseConfigurations) {
                this.addReleaseConfiguration();
            }
        }

        this.otaConfigurationForm.patchValue(this.device);
        this.otaConfigurationForm.markAsPristine();

        if (this.releaseConfigurationForms.controls.length) {
            for (const releaseConfigurationForm of this.releaseConfigurationForms.controls) {
                this.onPackageUpdate(releaseConfigurationForm as SigncoFormGroup, true);
            }
        }
    }

    onPackageUpdate(releaseConfigForm: SigncoFormGroup, updateValueAndValidity = false) {
        const packageIdControl = releaseConfigForm.get("packageId");
        const packageId = packageIdControl.value;

        const releaseChannelIdControl = releaseConfigForm.get("releaseChannelId");
        if (packageId) {
            releaseChannelIdControl.enable();
        } else {
            packageIdControl.setValue(null, { emitEvent: updateValueAndValidity, onlySelf: true });
            releaseChannelIdControl.disable();
        }

        if (updateValueAndValidity) {
            packageIdControl.updateValueAndValidity();
        }

        this.updateReleases(releaseConfigForm);
    }

    private updateDekimoForms() {
        if (this.device.dekimoGuiConfiguration) {
            this.dekimoGuiConfigurationForm.enable();
        } else {
            this.dekimoGuiConfigurationForm.disable();
        }

        if (this.device.dekimoTrackerConfiguration) {
            this.dekimoTrackerConfigurationForm.enable();
        } else {
            this.dekimoTrackerConfigurationForm.disable();
        }
    }

    async updateReleases(releaseConfigForm: SigncoFormGroup) {
        const packageIdControl = releaseConfigForm.get("packageId");

        if (packageIdControl.value) {
            const searchParameters = new SearchParameters();
            searchParameters.filter = [new FilterDescriptor("packageId", packageIdControl.value)];
            searchParameters.sort = [new SortDescriptor(SortDirection.descending, "id")];

            this.releaseApi.getAll$(searchParameters).subscribe((releases) => {
                // If it's a device-specific package, add it to list of options
                if (this.device.activeReleases) {
                    const activeReleaseForThisPackage = this.device.activeReleases.find(x => x.packageId === packageIdControl.value);
                    if (activeReleaseForThisPackage) {
                        const isReleaseInAvailableReleasesList = releases.find(x => x.id === activeReleaseForThisPackage.id);

                        if (!isReleaseInAvailableReleasesList) {
                            releases = [activeReleaseForThisPackage].concat(releases);
                        }
                    }
                }

                this.releases[packageIdControl.value] = this.primeComponentService.createDropdownList(releases, x => x.id, x => x.version, false);
            }, (error) => {
                console.error(error);
            });
        }
    }

    async reset() {
        this.device.hardwareConfiguration = this.originalHardwareConfiguration;

        this.initialize();
    }

    addReleaseConfiguration() {
        const specificReleaseIdControl = this.formBuilder.control(null);
        const packageIdControl = this.formBuilder.control(null);
        const releaseChannelIdControl = this.formBuilder.control(null);

        const releaseConfigurationForm = this.formBuilder.group({
            packageId: packageIdControl,
            releaseChannelId: releaseChannelIdControl,
            specificReleaseId: specificReleaseIdControl
        }) as SigncoFormGroup;

        const packageAndReleaseChannelAllOrNothingValidator = AllOrNothingRequiredValidator.create(
            [
                packageIdControl,
                releaseChannelIdControl
            ]
        );

        packageIdControl.setValidators([packageAndReleaseChannelAllOrNothingValidator, Validators.required]);
        releaseChannelIdControl.setValidators([packageAndReleaseChannelAllOrNothingValidator, Validators.required]);

        this.releaseConfigurationForms.push(releaseConfigurationForm);
    }

    deleteReleaseConfiguration(index: number) {
        this.releaseConfigurationForms.removeAt(index);
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.otaConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);
        Object.assign(deviceUpdater, this.otaConfigurationForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }

    openHardwareConfigurationDialog() {
        this.hardwareConfigurationDialog.open(this.device);
    }
}
