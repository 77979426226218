import { Component, Input, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { AssignmentSubtasksComponent } from "src/app/modules/shared/components/assignment-subtasks/assignment-subtasks.component";
import { LocationMarker } from "src/app/modules/map-advanced/classes/location-marker";
import { AngularDraggableDirective } from "angular2-draggable";
import { MeasuringPointsComponent } from "./../measuring-points/measuring-points.component";
import { SplitMapComponentBase } from "../split-map/split-map.component";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { SubscriptionManager } from "src/app/utilities";
import { IAssignmentSubtask } from "src/app/models/assignment";
import { NavigationService } from "src/app/services/navigation.service";
import { DevicesComponent } from "../devices/devices.component";
import { UsersComponent } from "../users/users.component";
import { IDeviceSummary } from "src/app/models/device";
import { Subscription } from "rxjs";
import { MapDetail } from "src/app/services/map-detail.service";
import * as lodash from "lodash";
import { MarkerContext } from "src/app/modules/map-advanced/classes/marker-context";
import { LiveTilesService } from "src/app/modules/live-tiles/services/live-tiles.service";
import { AuthorizationInfo } from "src/app/models/user";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { ILocationSummary } from "src/app/models/web";
import { IMeasuringPointSummary } from "src/app/models/web";

class PopupLocation {
    top: string;
    left: string;
    width: string;
}

@Component({
    selector: "app-map-detail-popup",
    templateUrl: "./map-detail-popup.component.html"
})
export class MapDetailPopupComponent implements AfterViewInit, OnDestroy {
    //#region Properties
    @ViewChild(AngularDraggableDirective, { static: true }) draggable: AngularDraggableDirective;

    private measuringPointsComponent: MeasuringPointsComponent;
    @ViewChild(MeasuringPointsComponent, { static: false }) set measuringPointsComponentSetter(measuringPointsComponent: MeasuringPointsComponent) {
        if (!measuringPointsComponent) return;

        this.measuringPointsComponent = measuringPointsComponent;
        this.updateSelection();
    }

    private devicesComponent: DevicesComponent;
    @ViewChild(DevicesComponent, { static: false }) set devicesComponentSetter(devicesComponent: DevicesComponent) {
        if (!devicesComponent) return;

        this.devicesComponent = devicesComponent;
        this.updateSelection();
    }

    private usersComponent: UsersComponent;
    @ViewChild(UsersComponent, { static: false }) set usersComponentSetter(usersComponent: UsersComponent) {
        if (!usersComponent) return;

        this.usersComponent = usersComponent;
        this.updateSelection();
    }

    private assignmentSubtasksComponent: AssignmentSubtasksComponent;
    @ViewChild(AssignmentSubtasksComponent, { static: false }) set assignmentSubtasksComponentSetter(assignmentSubtasksComponent: AssignmentSubtasksComponent) {

        if (!assignmentSubtasksComponent) return;

        this.assignmentSubtasksComponent = assignmentSubtasksComponent;
        this.updateSelection();
    }

    @Input() splitMap: SplitMapComponentBase;
    @Input() hideOrganizationPopup = false;

    show = false;
    locationPopupCss: PopupLocation;

    locationMarker: LocationMarker;
    context: MarkerContext;
    canCreateOnContext = false;
    canEditContext = false;
    canViewContext = false;
    canNavigateToProjects = false;
    canNavigateToJournal = false;
    label: string;

    filterSubscription: Subscription;

    private readonly subscriptionManager = new SubscriptionManager();
    private destroyed = false;
    rights: Rights;
    authorizationInfo: AuthorizationInfo;
    //#endregion Properties

    //#region Lifecycle

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly selectionService: MapSelectionService,
        private readonly navigationService: NavigationService,
        private readonly cd: ChangeDetectorRef,
        liveTilesService: LiveTilesService) {

        liveTilesService.subscribeToLiveTiles(this.subscriptionManager, x => {
            this.close();
        });
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
        const currentAuthorizationInfo = this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo: AuthorizationInfo) => {
            this.authorizationInfo = authorizationInfo;
        });
        this.subscriptionManager.add("currentAuthorizationInfo", currentAuthorizationInfo);
    }

    ngAfterViewInit() {
        const onLocationMarkerSelect = (locationMarker: LocationMarker) => {
            if (this.locationMarker === locationMarker) return;

            this.locationMarker = locationMarker;
            this.context = locationMarker ? locationMarker.context : null;
            this.clearData();
            this.positionClickedContextOverlay();
        };

        this.selectionService.subscribeToSelectedLocationMarker(this.subscriptionManager, onLocationMarkerSelect);

        const onSelectionUpdate = () => {
            this.updateSelection();
        };

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, onSelectionUpdate, onSelectionUpdate);
        this.selectionService.subscribeToDevices(this.subscriptionManager, onSelectionUpdate, onSelectionUpdate);
        this.selectionService.subscribeToAssignments(this.subscriptionManager, onSelectionUpdate, onSelectionUpdate);

        const filterSynchronizationChangedSubscription = this.splitMap.filterSynchronizationChanged.subscribe(() => {
            this.refreshPopup();
        });
        this.subscriptionManager.add("filterSynchronizationChanged", filterSynchronizationChangedSubscription);
    }

    ngOnDestroy() {
        this.destroyed = true;
        this.subscriptionManager.clear();
    }

    //#endregion Lifecycle

    //#region Popup flags

    private updateFlags() {
        this.canCreateOnContext = this.getCanCreateOnContext();
        this.canEditContext = this.getCanEditContext();
        this.canViewContext = this.getCanViewContext();
        this.canNavigateToProjects = this.getCanNavigateToProjects();
        this.canNavigateToJournal = this.getCanNavigateToJournal();
    }

    private CanEditMeasuringPoint(measuringPointLocation: ILocationSummary) {
        return measuringPointLocation && this.rights.hasBackendRight(BackendRights.EditMeasuringPoint) &&
            (
                this.authorizationInfo.isDomainAdministrator ||
                this.globalEventsService.assertAccess(measuringPointLocation.ownerId)
            );
    }

    private getCanCreateOnContext(): boolean {
        if (this.splitMap.readonly) return;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return this.CanEditMeasuringPoint(this.context.locationSummary as ILocationSummary);
        }

        if (currentMapDetail === MapDetail.Assignments) {
            return true;
        }

        return false;
    }

    private getCanViewContext(): boolean {
        if (this.splitMap.readonly) return false;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return this.rights.hasBackendRight(BackendRights.ViewMeasuringPoint) && !this.CanEditMeasuringPoint(this.context.locationSummary as ILocationSummary);
        }

        if (currentMapDetail === MapDetail.Organizations) {
            return this.rights.hasBackendRight(BackendRights.ViewOrganization) && !this.rights.hasBackendRight(BackendRights.EditOrganization);
        }

        if (currentMapDetail === MapDetail.Assignments) {
            return this.rights.hasBackendRight(BackendRights.ViewAssignment) && !this.rights.hasBackendRight(BackendRights.EditAssignment);
        }

        return false;
    }
    private getCanEditContext(): boolean {
        if (this.splitMap.readonly) return false;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return this.CanEditMeasuringPoint(this.context.locationSummary as ILocationSummary);
        }

        if (currentMapDetail === MapDetail.Organizations) {
            return this.rights.hasBackendRight(BackendRights.EditOrganization);
        }

        if (currentMapDetail === MapDetail.Assignments) {
            return this.rights.hasBackendRight(BackendRights.EditAssignment);
        }

        return false;
    }

    private getCanNavigateToProjects() {
        return false;
    }

    toProjects() {
        this.navigationService.toOrganization(this.context.organization.id, "projects");
    }

    toJournal() {
        this.navigationService.toOrganization(this.context.organization.id, "journal");
    }

    private getCanNavigateToJournal() {
        if (this.splitMap.readonly) return false;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();

        return currentMapDetail === MapDetail.Organizations;
    }

    //#endregion Popup flags

    close() {
        if (!this.show) return;

        this.show = false;

        this.locationMarker = null;
        this.context = null;
        this.splitMap.gmap.clearSelected();

        this.clearData();

        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
            this.filterSubscription = null;
        }

        this.detectChanges();
    }

    private clearData() {
        if (this.popupTableComponent) {
            this.popupTableComponent.clearData();
        }
    }

    private open() {
        if (this.show) return;

        if (this.destroyed) {
            this.close();
            return;
        }

        this.show = true;

        if (this.popupTableComponent) {
            this.popupTableComponent.reload();
        }

        this.positionClickedContextOverlay();

        this.detectChanges();
    }

    private detectChanges() {
        if (this.destroyed) return;

        this.cd.detectChanges();
    }

    private updateVisibility() {
        const shouldBeVisible = !!this.context && this.splitMap.mapSplitSize > 20 && (this.splitMap.getCurrentMapDetail() !== MapDetail.Organizations || !this.hideOrganizationPopup);

        if (!shouldBeVisible) this.close();
        else this.open();
    }

    handleNavigation(locationSummary: IMeasuringPointSummary | IDeviceSummary | IAssignmentSubtask): boolean {
        if (this.splitMap.disableNavigationOnSelect) return false;

        if (this.measuringPointsComponent) {
            this.navigationService.toMeasuringPoint(locationSummary as IMeasuringPointSummary);
        }

        if (this.devicesComponent) {
            this.navigationService.toDevice((locationSummary as IDeviceSummary).id);
        }

        if (this.assignmentSubtasksComponent) {
            this.assignmentSubtasksComponent.navigateToDetail(locationSummary as IAssignmentSubtask);
        }

        return true;
    }

    isAllSelected(): boolean {
        if (!this.show) return false;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();
        if (currentMapDetail === MapDetail.MeasuringPoints && this.measuringPointsComponent) {
            return this.measuringPointsComponent.isAllSelected();
        }
    }

    toggleSelectAll() {
        const currentMapDetail = this.splitMap.getCurrentMapDetail();
        if (currentMapDetail === MapDetail.MeasuringPoints) {
            this.measuringPointsComponent.toggleSelectAll();
        }
    }

    handleSelect(selected: IMeasuringPointSummary[] | IDeviceSummary[] | IAssignmentSubtask[]) {
        const rows = selected.toList<IMeasuringPointSummary | IDeviceSummary | IAssignmentSubtask>();
        if (!rows.length) return;

        const last = rows.takeLastOrDefault();
        if (this.handleNavigation(last)) return;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();
        if (currentMapDetail === MapDetail.MeasuringPoints) {
            this.splitMap.measuringPointsComponent.selectRows(rows as IMeasuringPointSummary[]);
        }

        if (currentMapDetail === MapDetail.Devices) {
            this.splitMap.devicesComponent.selectRows(rows as IDeviceSummary[]);
        }
    }

    handleDeselect(deselected: IMeasuringPointSummary[] | IDeviceSummary[] | IAssignmentSubtask[]) {
        const rows = deselected.toList<IMeasuringPointSummary | IDeviceSummary | IAssignmentSubtask>();
        if (!rows.length) return;

        const last = rows.takeLastOrDefault();

        // Deselecting a selected row counts as a click => navigate
        if (this.handleNavigation(last)) return;

        const currentMapDetail = this.splitMap.getCurrentMapDetail();
        if (currentMapDetail === MapDetail.MeasuringPoints) {
            this.splitMap.measuringPointsComponent.deselectRows(rows as IMeasuringPointSummary[]);
        }

        if (currentMapDetail === MapDetail.Devices) {
            this.splitMap.devicesComponent.deselectRows(rows as IDeviceSummary[]);
        }

        this.updateVisibility();
    }

    private updateSelection() {
        const mapDetail = this.splitMap.getCurrentMapDetail();

        if (mapDetail === MapDetail.MeasuringPoints && this.measuringPointsComponent) {
            this.measuringPointsComponent.setSelection(this.selectionService.getSelectedMeasuringPoints(), false);
        }

        if (mapDetail === MapDetail.Devices && this.devicesComponent) {
            // this.devicesComponent.table.filters = this.splitMap.devicesComponent.table.filters; // TODO after filter redesign
            this.devicesComponent.setSelection(this.selectionService.getSelectedDevices(), false);
        }

        if (mapDetail === MapDetail.Assignments && this.assignmentSubtasksComponent) {
            this.assignmentSubtasksComponent.setAssignmentSelection(this.selectionService.getSelectedAssignments());
        }

        this.updateVisibility();
    }

    onDataSet() {
        this.detectChanges();
        this.scrollToContext();
        this.cd.detectChanges();
    }

    scrollToContext() {
        if (!this.context) return;

        if (this.splitMap.searchbutton.selectedPlace && this.splitMap.searchbutton.selectedPlace.id) {
            const row = (this.popupTableComponent.data as any[]).find((x: any) => x.id === this.splitMap.gmap.searchbutton.selectedPlace.id);

            if (row) {
                this.splitMap.searchbutton.selectedPlace = null;
                this.popupTableComponent.scrollTo(row);
                return;
            }
        }

        // If gmap.selectedPlace is filled in, the user just selected a place
        // We don't use selectionService because we don't select it, but we show it
        if (this.splitMap.getCurrentMapDetail() === MapDetail.MeasuringPoints) {
            const selected = /* this.splitMap.gmap.selectedPlace as IMeasuringPointSummary || */ this.selectionService.getSelectedMeasuringPoints().takeFirstOrDefault();
            this.measuringPointsComponent.scrollTo(selected);
        }

        if (this.splitMap.getCurrentMapDetail() === MapDetail.Devices) {
            const selected = /* this.splitMap.gmap.selectedPlace as IDevice || */ this.selectionService.getSelectedDevices().takeFirstOrDefault();
            this.devicesComponent.scrollTo(selected);
        }
    }

    refreshPopup() {
        if (!this.popupTableComponent) return;
        this.popupTableComponent.reload();
    }

    private get popupTableComponent() {
        return this.measuringPointsComponent || this.devicesComponent || this.usersComponent;
    }

    private async positionClickedContextOverlay() {
        this.locationPopupCss = null;
        this.updateVisibility();

        if (!this.show) return;

        this.updateFlags();

        const getLatLng = () => {
            return this.context.latLng;
        };

        // The map might still be panning to the location, so we wait for it to finish
        await new Promise(res => setTimeout(res, 200));

        if (!this.show) return;

        const calculateDimensions = () => {
            const markerLocationInPx = this.splitMap.gmap.getPixelFromLatLng(getLatLng());
            if (!markerLocationInPx) return null;

            const offSet = 0;

            let left = markerLocationInPx.x;
            const top = markerLocationInPx.y + offSet;
            const maxWidth = 900;

            let width = 70;
            if (this.splitMap.getCurrentMapDetail() === MapDetail.Devices) {
                width = 50;
            }

            const mapWidth = this.splitMap.gmap.map.getDiv().clientWidth;
            const popupWidth = Math.min(maxWidth * (width / 100), mapWidth);

            if ((left + popupWidth) > mapWidth) {
                left -= (left + popupWidth) - mapWidth;
            }

            return {
                left: left + this.splitMap.getPopupLeftOffset(),
                top: top + this.splitMap.getPopupTopOffset(),
                width: popupWidth * 1.05,
            };
        };

        const dimensions = calculateDimensions();
        if (!dimensions) {
            this.locationPopupCss = null;
            return;
        }

        try {
            this.draggable.resetPosition();
        } catch (e) {
            // Ignore, sometimes errors because oldTrans is null
        }

        const newLocationPopupCss = {
            top: dimensions.top + "px",
            left: dimensions.left + "px",
            width: dimensions.width + "px"
        } as PopupLocation;

        if (!lodash.isEqual(newLocationPopupCss, this.locationPopupCss)) {
            this.locationPopupCss = newLocationPopupCss;
            this.detectChanges();
        }
    }
}
