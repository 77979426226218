import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { Component } from "@angular/core";
import { CountingZoneAccessStatus, ICountingZoneStatus } from "src/app/models/pinned-data";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { AddVehicleData, CountingZoneApi, ForceAccessData, RemoveVehicleData } from "src/app/resource/counting-zone.api";
import { Vehicle, VehicleCategory } from "src/app/models/vehicle";

@Component({
    selector: "app-counting-zone-manage-dialog",
    templateUrl: "./counting-zone-manage-dialog.component.html"
})
export class CountingZoneManageDialogComponent extends DialogComponentBase {

    vehicleCategories: ViewModelEnum[];
    selectedVehicleCategory: string;

    accessStatuses: ViewModelEnum[];
    selectedAccessStatus: string;

    measuringPointId: number;
    countingZone: ICountingZoneStatus;

    submitting = false;

    constructor(
        private readonly domainDataService: DomainDataService,
        private readonly countingZoneApi: CountingZoneApi) {
        super();

        this.domainDataService.get(DomainData.VehicleCategory).then(vehicleCategories => {
            this.vehicleCategories = vehicleCategories;
        });

        this.domainDataService.get(DomainData.CountingZoneAccessStatus).then(accessStatuses => {
            this.accessStatuses = accessStatuses;
        });
    }

    open(measuringPointId: number, countingZone: ICountingZoneStatus) {
        this.measuringPointId = measuringPointId;
        this.countingZone = countingZone;
        this.visible = true;
        this.selectedAccessStatus = countingZone.accessStatus;
    }

    async forceAccessStatus() {
        const data = new ForceAccessData();
        data.accessStatus = <CountingZoneAccessStatus>this.selectedAccessStatus;

        this.countingZoneApi.forceAccess(this.measuringPointId, data).subscribe(() => {
            this.close();
        });
    }

    async addVehicle() {
        const data = new AddVehicleData();
        const vehicle = new Vehicle;
        vehicle.timestamp = new Date();
        vehicle.category = <VehicleCategory>this.selectedVehicleCategory;

        const str = vehicle.serializeToSignco();
        data.vehicles.push(str);

        this.countingZoneApi.addVehicles(this.measuringPointId, data).subscribe(() => {
            this.close();
        });
    }

    async removeVehicle(vehicle: string) {
        const data = new RemoveVehicleData();
        data.vehicles.push(vehicle);

        this.countingZoneApi.removeVehicles(this.measuringPointId, data).subscribe(() => {
            this.close();
        });
    }

}