<div class="m-card m-layout-w-actions-bottom m-fixed-height">
    <div class="m-layout-area-body m-layout-w-actions-top">
        <div class="m-overflow-auto m-layout-area-body m-layout-default">
            <p-tree [value]="nodes"
                    [filter]="true"
                    [loading]="loading"
                    selectionMode="checkbox"
                    [(selection)]="selectedNodes"
                    (onNodeExpand)="lazyLoadNode($event.node)"
                    (onNodeSelect)="checkProject($event.node)"
                    (onNodeUnselect)="uncheckProject($event.node)"
                    class="m-layout-area-body">

                <ng-template let-node
                             pTemplate="default">

                    <span class="mr-8"
                          [ngStyle]="getCustomStyle(node)">
                        {{node.label | truncate:100:true}}
                    </span>

                </ng-template>
            </p-tree>
        </div>
    </div>
</div>
