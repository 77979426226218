<ng-template #navigatorContent>
    <span #content>
        <ng-content></ng-content>
    </span>
    @if (!content.hasChildNodes()) {
    {{ text }}
    }
</ng-template>

<ng-template #navigatorBody>
    @if (showIcon && icon) {
    <div class="row"
         style="align-items: center;">
        <app-svg-icon [icon]="icon"></app-svg-icon>
        <ng-template [ngTemplateOutlet]="navigatorContent"></ng-template>
    </div>
    } @else {
    <ng-template [ngTemplateOutlet]="navigatorContent"></ng-template>
    }
</ng-template>

@if (enabled && (canNavigate || !targetNotReachable)) {
<a [routerLink]="navigationUrl"
   [queryParams]="queryParams"
   [target]="newTab ? '_blank' : null"
   class="m-navigator"
   [ngStyle]="ngStyle"
   [ngClass]="{'is-inactive': isInactive, 'm-map-filters-chip': showIcon && icon}"
   style="background-color: initial;">
    <ng-template [ngTemplateOutlet]="navigatorBody"></ng-template>
</a>
} @else {
<span class="m-navigator is-disabled"
      [ngClass]="{'m-map-filters-chip': showIcon && icon}"
      [ngStyle]="ngStyle"
      style="background-color: initial;">
    <ng-template [ngTemplateOutlet]="navigatorBody"></ng-template>
</span>
}