import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { IAssignment } from "src/app/models/assignment";
import { ILocation } from "src/app/models/location";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { NavigationService } from "src/app/services/navigation.service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-parking-bans-container",
    templateUrl: "./parking-bans-container.component.html",
    styleUrls: []
})
export class ParkingBansContainerComponent implements OnDestroy {
    @Input() assignment: IAssignment;

    @Output() lastParkingBanRemoved = new EventEmitter<void>();

    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;
    canCreateParkingBan = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        private readonly navigationService: NavigationService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateParkingBan = rights?.hasBackendRight(BackendRights.EditParkingBan);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    setAssignment(assignment: IAssignment) {
        this.assignment = assignment;
    }

    create() {
        // no navigation after creation, just close the dialog
        this.manageLocationComponent.navigateAfterCreation = false;

        const onSuccessfullySelectedLocation = async (newLocation: ILocation) => {
            if (!newLocation) {
                return;
            }

            this.navigationService.newParkingBanCoordinate = newLocation.coordinate;
            this.navigationService.newParkingBanAssignment = this.assignment;
            await this.navigationService.createNewParkingBan();
        };

        // we are going to create a new location
        this.manageLocationComponent.create(this.assignment?.location?.coordinate ?? this.assignment?.parentAssignment?.location?.coordinate, onSuccessfullySelectedLocation.bind(this));
    }

    handleLastParkingBanDeleted() {
        this.lastParkingBanRemoved.next();
    }
}
