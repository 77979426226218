import { Injectable } from "@angular/core";
import { AvsApiService } from "./avs-api";
import { TrackerData } from "src/app/models/avs/tracker-data";

@Injectable({
    providedIn: "root"
})
export class TrackerDataAvsApi extends AvsApiService<TrackerData> {
    getRoute(): string {
        return "trackerData";
    }
}