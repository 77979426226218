<div class="m-page-content-wrapper p-16">
    <div class="row m-fixed-height"
         [hidden]="!location">
        <div class="col-3"
             style="height:100%; display:grid; grid-template-rows: fit-content(75%) minmax(200px, 1fr); gap:16px;">
            <div class="m-card"
                 style="position: relative; min-height: 0; overflow-y: auto;">
                <a (click)="toMap()"
                   class="m-btn m-btn-back m-btn-back-map"
                   title="Back"
                   id="backToMapButton">
                    <app-svg-icon icon="chevron-left"
                                  class="mr-4"></app-svg-icon>
                    {{"general.returnToMap" | translate}}
                </a>

                <app-location-detail *ngIf="location"
                                     [measuringPoint]="measuringPoint"
                                     [location]="location"
                                     [editCommand]="!!location?.id && !measuringPoint?.readonly"
                                     (save)="handleLocationEdit($event)">
                </app-location-detail>
            </div>

            <div class="m-card"
                 style="min-height: 0; display:grid; grid-template-rows: auto 1fr auto;">
                <div class="p-16 m-border-bottom">
                    <h3>{{"measuringPoints.functionsTitle" | translate}}</h3>
                </div>

                <app-measuring-points style="min-height: 0;"
                                      [locationId]="location?.id"
                                      [requiresInput]="true"
                                      [headers]="false"
                                      [paginator]="false"
                                      [footer]="false"
                                      [mini]="true"
                                      [locationNavigation]="false"
                                      [deleteCommand]="!measuringPoint?.readonly"
                                      (selected)="handleOnMeasuringPointSelect($event)"
                                      (dataSet)="onDataSet()"></app-measuring-points>

                <div class="m-btn-group m-btn-group-right m-border-top">
                    <button class="m-btn m-btn-secondary"
                            (click)="createNewMeasuringPoint()"
                            [disabled]="!measuringPoint?.id"
                            *ngIf="canCreateMeasuringPoint && location && !measuringPoint?.readonly">
                        <app-svg-icon icon="add">
                        </app-svg-icon>
                        {{"manageMeasuringPoint.create" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height">
            <app-measuring-point-detail-container class="m-fixed-height"
                                                  [measuringPoint]="measuringPoint"
                                                  (save)="handleOnMeasuringPointSaved($event)"
                                                  [projects]="projects"
                                                  [loading]="loading">
            </app-measuring-point-detail-container>
        </div>
    </div>
</div>