<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="tubeXPosConfiguration">
    <div class="p-16 m-border-top m-border-bottom">
        <h3>{{"uploadDetailsDialog.tubeXPosConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('tube3').showErrors }">

            <label class="col-2"
                   for="tube3">
                {{"uploadDetailsDialog.connectionTubeRight"| translate}}
            </label>

            <div class="col-4">
                <p-dropdown [options]="tubeOptionsWithEmpty"
                            id="tube3"
                            [formControl]="form.get('tube3')"
                            [panelStyle]="{'width':'100%'}"
                            #tube3Input></p-dropdown>

                <app-form-validation [control]="form.get('tube3')"
                                     [input]="tube3Input">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('tube4').showErrors }">

            <label class="col-2"
                   for="tube4">
                {{"uploadDetailsDialog.connectionTubeLeft" | translate}}
            </label>

            <div class="col-4">
                <p-dropdown [options]="tubeOptionsWithEmpty"
                            id="tube4"
                            [formControl]="form.get('tube4')"
                            [panelStyle]="{'width':'100%'}"
                            #tube4Input></p-dropdown>

                <app-form-validation [control]="form.get('tube4')"
                                     [input]="tube4Input">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('drivingLaneWidthCm').showErrors }">

            <label class="col-2"
                   for="drivingLaneWidthCm">
                {{"uploadDetailsDialog.drivingLaneWidthCm" | translate}}
            </label>

            <div class="col-4">
                <div class="m-input-group">
                    <input name="drivingLaneWidthCm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('drivingLaneWidthCm')"
                           id="drivingLaneWidthCm"
                           #drivingLaneWidthCmInput>
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('drivingLaneWidthCm')"
                                     [input]="drivingLaneWidthCmInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube3XOffsetCm').showErrors }">

                    <label class="col-4"
                           for="tube3XOffsetCm">
                        {{"uploadDetailsDialog.tubeRightXOffsetCm" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube3XOffsetCm"
                                   class="p-inputtext"
                                   type="number"
                                   [formControl]="form.get('tube3XOffsetCm')"
                                   id="tube3XOffsetCm"
                                   #tube3XOffsetCmInput>
                            <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube3XOffsetCm')"
                                             [input]="tube3XOffsetCmInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube4XOffsetCm').showErrors }">

                    <label class="col-4"
                           for="tube4XOffsetCm">
                        {{"uploadDetailsDialog.tubeLeftXOffsetCm" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube4XOffsetCm"
                                   class="p-inputtext"
                                   type="number"
                                   [formControl]="form.get('tube4XOffsetCm')"
                                   id="tube4XOffsetCm"
                                   #tube4XOffsetCmInput>
                            <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube4XOffsetCm')"
                                             [input]="tube4XOffsetCmInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube3YOffsetCm').showErrors }">

                    <label class="col-4"
                           for="tube3YOffsetCm">
                        {{"uploadDetailsDialog.tubeRightYOffsetCm" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube3YOffsetCm"
                                   class="p-inputtext"
                                   type="number"
                                   [formControl]="form.get('tube3YOffsetCm')"
                                   id="tube3YOffsetCm"
                                   #tube3YOffsetCmInput>
                            <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube3YOffsetCm')"
                                             [input]="tube3YOffsetCmInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube4YOffsetCm').showErrors }">

                    <label class="col-4"
                           for="tube4YOffsetCm">
                        {{"uploadDetailsDialog.tubeLeftYOffsetCm" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube4YOffsetCm"
                                   type="number"
                                   class="p-inputtext"
                                   [formControl]="form.get('tube4YOffsetCm')"
                                   id="tube4YOffsetCm"
                                   #tube4YOffsetCmInput>
                            <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube4YOffsetCm')"
                                             [input]="tube4YOffsetCmInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube3AngleDegree').showErrors }">

                    <label class="col-4"
                           for="tube3AngleDegree">
                        {{"uploadDetailsDialog.tubeRightAngleDegree" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube3AngleDegree"
                                   class="p-inputtext"
                                   type="number"
                                   [formControl]="form.get('tube3AngleDegree')"
                                   id="tube3AngleDegree"
                                   #tube3AngleDegreeInput>
                            <div class="m-input-group-addon">{{"measurements.degrees" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube3AngleDegree')"
                                             [input]="tube3AngleDegreeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': form.get('tube4AngleDegree').showErrors }">

                    <label class="col-4"
                           for="tube4AngleDegree">
                        {{"uploadDetailsDialog.tubeLeftAngleDegree" | translate}}
                    </label>

                    <div class="col-8">
                        <div class="m-input-group">
                            <input name="tube4AngleDegree"
                                   class="p-inputtext"
                                   type="number"
                                   [formControl]="form.get('tube4AngleDegree')"
                                   id="tube4AngleDegree"
                                   #tube4AngleDegreeInput>
                            <div class="m-input-group-addon">{{"measurements.degrees" | translate}}</div>
                        </div>

                        <app-form-validation [control]="form.get('tube4AngleDegree')"
                                             [input]="tube4AngleDegreeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>