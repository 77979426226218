<app-basic-map [style]="style"
               [showCoordinates]="showCoordinates"
               [isLoading]="isLoading"
               [saveState]="saveState"
               [showMapTypeSelector]="showMapTypeSelector"
               [showStreetViewControl]="showStreetViewControl"
               (resize)="handleResize()"
               (mapReady)="handleMapReady($event)"
               (mapClick)="handleMapClick($event)"
               (mapRightClick)="handleMapRightClick($event)"
               (mapDragStart)="handleMapDragStart($event)"
               (mapDragEnd)="handleMapDragEnd($event)"
               (zoomChanged)="handleZoomChanged()"></app-basic-map>