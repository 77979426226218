<div class="search-panel__header">
    <app-search-box (onCreateFilterClicked)="handleCreateFilterClicked($event)"></app-search-box>

    <button id="buttonCloseSearchPanel"
            class="m-btn m-btn-square"
            (click)="close()">
        <app-svg-icon icon="chevron-left"></app-svg-icon>
    </button>
</div>

<div class="search-panel__filters">
    <app-filter-container (searchBoxShouldFocus)="updateSearchBoxFocus()"></app-filter-container>
</div>

<div class="search-panel__body">
    <app-result-container></app-result-container>
</div>