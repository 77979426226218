import { LocalStorageService } from "src/app/services/storage.service";
import { Component } from "@angular/core";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-cookie-notification",
    templateUrl: "./cookie-notification.component.html"
})
export class CookieNotificationComponent {

    visible = false;

    constructor(
        private readonly localStorage: LocalStorageService) {

        this.visible = !this.localStorage.getItem(Constants.cookieNotificationStorageKey);
    }

    acceptCookies() {
        this.localStorage.setItem(Constants.cookieNotificationStorageKey, "true");
        this.visible = false;
    }
}