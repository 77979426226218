<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [stateKey]="stateKey"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngSwitch]="column.field"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <ng-container *ngSwitchCase="'selectionBox'">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </ng-container>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-predictionRequestValidation>
        <tr [pSelectableRow]="predictionRequestValidation">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngClass]="getCellClass(predictionRequestValidation, column)"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'selectionBox'">
                    <input type="checkbox"
                           [checked]="isSelected(predictionRequestValidation)"
                           (click)="handleSelectionBoxClick(predictionRequestValidation, $event)">
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint'">
                    <app-navigator [measuringPoint]="predictionRequestValidation.measuringPoint"
                                   [newTab]="true"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'warning'">
                    <app-icon-button *ngIf="predictionRequestValidation.warning"
                                     icon="info"
                                     fill="black"
                                     [text]="predictionRequestValidation.warning">
                    </app-icon-button>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(predictionRequestValidation)"
                                             *ngIf="command.rowValidFunc(predictionRequestValidation)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{predictionRequestValidation[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>