import { Component, ElementRef, Input, Output, EventEmitter, NgZone, ChangeDetectorRef, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, inject } from "@angular/core";
import { interval } from "rxjs";
import { Constants } from "src/app/constants/constants";
import { GoogleMapsLoaderService } from "src/app/services/google-maps-loader.service";
import { LocalStorageService } from "src/app/services/storage.service";
import { TouchService } from "src/app/services/touch.service";
import { SubscriptionManager } from "src/app/utilities";
import { MapTypeProvider, MapTypeIds, MapTypeData } from "../../services/map-type-provider";
import { MapTypeSelectorComponent } from "../map-type-selector/map-type-selector.component";
import { GeolocationService } from "../../services/geolocation.service";
import { MarkerCollection } from "../../services/marker-collection";
import { IBasicMarker } from "../../classes/basic-marker";
import { MapContextMenuComponent } from "../map-context-menu/map-context-menu.component";
import { MapContextMenuItem } from "../..";

export class BasicMarkerEvent {
    constructor(marker: google.maps.marker.AdvancedMarkerElement, event: google.maps.MapMouseEvent) {
        this.marker = marker as IBasicMarker;
        this.event = event;
    }
    marker: IBasicMarker;
    event: google.maps.MapMouseEvent;
}

/**
 * This is the state as it is persisted
 */
class MapState {
    showCrosshair: boolean;
    zoom: number;
    center: google.maps.LatLngLiteral;
    mapTypeId: string;
    showTrafficLayer: boolean;
}

/**
 * A basic map that wraps the Google Maps API and provides additional functionality, such as:
 * * Saving the map state in the local storage
 * * Showing the coordinates of the map center (on the bottom right)
 * * Tracking the user's location (used by the technicians when they are on the road)
 * * Choosing the map type (through MapTypeSelectorComponent)
 * * Supporting additional map types (OpenStreetMap, OpenCycleMap, OpenTransportMap) through MapTypeProvider
 * * Showing a context menu when right-clicking on the map
 *
 * This does not depend on specific data being displayed on the map, such as devices, organizations, etc.
 */
@Component({
    selector: "app-basic-map",
    templateUrl: "./basic-map.component.html",
    styleUrl: "./basic-map.component.scss",
    providers: [MarkerCollection] // Configuring the service like this means every BasicMap gets its own instance of the service, which is what we want
})
export class BasicMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() style: any = { width: "100%", height: "100%" };
    @Input() styleClass: string;
    @Input() showCoordinates = false;
    @Input() showMapTypeSelector = false;
    @Input() showStreetViewControl = false;
    @Input() isLoading = false;
    @Input() saveState = false; // If true, the map state will be saved in the local storage

    @Output() mapReady: EventEmitter<BasicMapComponent> = new EventEmitter();
    @Output() mapClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter();
    @Output() mapRightClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter();
    @Output() mapDragEnd: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter();
    @Output() mapDrag: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter();
    @Output() mapDragStart: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter();
    @Output() resize: EventEmitter<void> = new EventEmitter();

    @Output() markerClick: EventEmitter<BasicMarkerEvent> = new EventEmitter();
    @Output() markerRightClick: EventEmitter<BasicMarkerEvent> = new EventEmitter();
    @Output() markerDragStart: EventEmitter<BasicMarkerEvent> = new EventEmitter();
    @Output() markerDragEnd: EventEmitter<BasicMarkerEvent> = new EventEmitter();

    @Output() zoomChanged: EventEmitter<void> = new EventEmitter();

    @Output() streetViewToggle: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MapTypeSelectorComponent) private mapTypeSelector: MapTypeSelectorComponent;
    @ViewChild(MapContextMenuComponent, { static: true }) private contextMenu: MapContextMenuComponent;

    public map: google.maps.Map;
    private options: google.maps.MapOptions;

    protected state: MapState;
    private trafficLayer: google.maps.TrafficLayer;

    protected isInStreetView: boolean;
    protected trackingGeoLocation = false;

    private readonly subscriptionManager = new SubscriptionManager();

    private readonly googleMapsLoader = inject(GoogleMapsLoaderService);
    private readonly mapTypeProvider = inject(MapTypeProvider);
    private readonly el = inject(ElementRef<HTMLElement>);
    private readonly zone = inject(NgZone);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly touchService = inject(TouchService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly geolocationService = inject(GeolocationService);
    public readonly markers = inject(MarkerCollection);

    constructor() {
        // Google Maps needs at least the zoom and center to be set, otherwise nothing will be displayed.
        this.options = {
            zoom: 9,
            center: { lat: 50.850346, lng: 4.351721 }, // Brussels
            mapTypeId: MapTypeIds.openStreetMapTypeId,
            gestureHandling: "greedy",
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            streetViewControl: false,
            disableDoubleClickZoom: true,
            mapId: "e1ad0500d0a22f4d"
        } as google.maps.MapOptions;
    }

    private initializeMap() {
        if (this.map || !this.el.nativeElement.children.length) return;

        this.syncStreetViewState();

        this.zone.runOutsideAngular(() => {
            if (!this.state) this.loadMapState(); // ngInit has not been called yet
            this.map = new google.maps.Map(this.el.nativeElement.children[0] as HTMLElement, this.options); // Most of the time this.options is still undefined. But setOptions() will be called again in ngOnChanges()

            this.trafficLayer = this.state.showTrafficLayer ? new google.maps.TrafficLayer() : null;
            if (this.trafficLayer) {
                this.trafficLayer.setMap(this.map);
            }

            this.mapTypeProvider.initialize(this.map);
            this.markers.initialize(this);

            window.addEventListener("resize", () => {
                this.resize.emit();
            }, { passive: true });

            this.map.addListener("click", (event: google.maps.MapMouseEvent) => {
                if (this.touchService.isLongTouch()) {
                    this.mapRightClick.emit(event);
                } else {
                    this.mapClick.emit(event);
                }
            });

            this.map.addListener("rightclick", (event: google.maps.MapMouseEvent) => {
                this.mapRightClick.emit(event);
            });

            this.map.addListener("mousedown", (event: google.maps.MapMouseEvent) => {
                this.touchService.notifyMouseDown();
            });

            this.map.addListener("dragstart", (event: google.maps.MapMouseEvent) => {
                this.mapDragStart.emit(event);
                this.zone.run(() => {
                    this.trackingGeoLocation = false;
                    this.handleGeolocation();
                });
            });

            this.map.addListener("dragend", (event: google.maps.MapMouseEvent) => {
                this.saveMapState();
                this.mapDragEnd.emit(event);
            });

            this.map.addListener("center_changed", () => {
                this.updateCenter();
                this.saveMapState();
            });

            this.map.addListener("zoom_changed", () => {
                // this.zone.run(() => {
                const zoom = this.map.getZoom();
                if (zoom) this.state.zoom = zoom;
                this.saveMapState();
                this.zoomChanged.emit();
                // });
            });

            this.map.addListener("maptypeid_changed", () => {
                this.state.mapTypeId = this.map.getMapTypeId();
                this.saveMapState();
            });

            this.map.getStreetView().addListener("visible_changed", () => {
                this.zone.run(() => {
                    this.streetViewToggle.emit(this.map.getStreetView().getVisible());
                    this.isInStreetView = this.map.getStreetView().getVisible();
                    this.cdr.markForCheck();
                });
            });

            // When showing traffic, refresh the map to show the latest traffic data
            const updateLayerSubscription = interval(60 * 1000).subscribe(() => {
                if (this.trafficLayer && this.trafficLayer instanceof google.maps.TrafficLayer) {
                    this.trafficLayer.setMap(null);
                    this.trafficLayer.setMap(this.map);
                }
            });

            this.subscriptionManager.add("updateLayerSubscription", updateLayerSubscription);
        });

        this.zone.run(() => {
            this.mapReady.emit(this);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["showStreetViewControl"]) {
            this.syncStreetViewState();
        }
    }

    private syncStreetViewState() {
        if (this.showStreetViewControl) {
            this.options.streetViewControl = true;
            this.options.streetViewControlOptions = {
                position: 7.0
            };
        } else {
            this.options.streetViewControl = false;
        }

        if (this.map) {
            // Sometimes the map is not initialized yet
            this.map.setOptions(this.options);
        }
    }

    private updateCenter(): void {
        const center = this.map?.getCenter();
        if (!center) return;
        this.state.center = center.toJSON();
        this.cdr.markForCheck(); // This updates the coordinates
    }

    ngOnInit(): void {
        this.loadMapState();
    }

    ngAfterViewInit(): void {
        // Viewchilds are only available after init, so not yet in ngOnInit

        // We only initialize the maps after our own initialization is done. That way we know that the map is ready to be used.
        this.googleMapsLoader.load().then(() => {
            this.initializeMap();
            this.mapTypeSelector?.initialize();
            this.mapTypeSelector?.updateSelectedMapTypeId(this.state.mapTypeId);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
        this.markers.dispose();
    }

    private saveMapState() {
        if (!this.saveState) return;
        this.localStorageService.setItem(Constants.mapState, JSON.stringify(this.state));
    }

    private loadMapState() {
        // Note that we always load the state, even if this.saveState is false

        const data = this.localStorageService.getItem(Constants.mapState) ?? "{}";
        const mapState = JSON.parse(data) as MapState;

        // Data from storage might be incomplete, we fix it here
        if (!mapState.center) mapState.center = { lat: 50.850346, lng: 4.351721 }; // Default to Brussels
        if (!mapState.zoom) mapState.zoom = 9; // Default zoom level
        if (!mapState.mapTypeId) mapState.mapTypeId = MapTypeIds.openStreetMapTypeId;
        if (!mapState.showTrafficLayer) mapState.showTrafficLayer = false;

        this.state = mapState;

        this.options.center = this.state.center;
        this.options.zoom = this.state.zoom;
        this.options.mapTypeId = this.state.mapTypeId;

        this.map?.setOptions(this.options); // Can be null, if not initialized yet
        this.mapTypeSelector?.updateSelectedMapTypeId(this.state.mapTypeId); // Can be null, if not shown
    }

    protected toggleCrosshair(): void {
        this.state.showCrosshair = !this.state.showCrosshair;
        this.saveMapState();
    }

    protected handleMapTypeChanged(data: MapTypeData) {
        // Traffic layer
        this.state.showTrafficLayer = data.showTrafficLayer;
        this.saveMapState();

        if (this.trafficLayer) {
            this.trafficLayer.setMap(null);
            this.trafficLayer = null;
        }

        this.trafficLayer = data.showTrafficLayer ? new google.maps.TrafficLayer() : null;

        if (this.trafficLayer) {
            this.trafficLayer.setMap(this.map);
        }

        // Map type
        this.zone.runOutsideAngular(() => {
            this.map.setMapTypeId(data.mapTypeId);
        });
    }

    public setZoom(zoom: number) {
        this.zone.runOutsideAngular(() => {
            this.map.setZoom(zoom);
        });
    }

    zoomToNoCluster() {
        if (this.map.getZoom() < Constants.noClusterZoom) {
            this.map.setZoom(Constants.noClusterZoom);
        }
    }

    /**
     * Moves the map without animation
     */
    public setCenter(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {
        this.zone.runOutsideAngular(() => {
            this.map.setCenter(latLng);
        });
    }

    public centerOnLocations(latLngs: google.maps.LatLng[] | google.maps.LatLngLiteral[]) {
        if (!latLngs || latLngs.length === 0) return;

        const bounds = new google.maps.LatLngBounds();

        for (const latLng of latLngs) {
            try {
                bounds.extend(latLng);
            } catch {
                // ignored
                // 0 lat/lng seems to happen, filter out client side
            }
        }

        this.fitBounds(bounds);
    }

    public centerOnGeometry(geometry: google.maps.places.PlaceGeometry) {
        if (!geometry) return;
        if (geometry.viewport) {
            // Sometimes we have a viewport, e.g. for a large city this makes sure that the whole city is displayed
            this.fitBounds(geometry.viewport);
        } else if (geometry.location) {
            // Sometimes we only have the location
            this.setCenter(geometry.location);
        }
    }

    /**
     * Moves the map with animation
     */
    public panTo(lanLng: google.maps.LatLng | google.maps.LatLngLiteral) {
        this.zone.runOutsideAngular(() => {
            this.map.panTo(lanLng);
        });
    }

    public fitBounds(bounds: google.maps.LatLngBounds) {
        this.zone.runOutsideAngular(() => {
            this.map.fitBounds(bounds);
            this.map.setCenter(bounds.getCenter());
        });
    }

    private getBounds(scale = 1): google.maps.LatLngBounds {
        const bounds = this.map.getBounds();
        if (scale === 1) return bounds;

        const sw = bounds.getSouthWest();
        const scaledSw = new google.maps.LatLng(sw.lat() * scale, sw.lng() * scale);

        const ne = bounds.getNorthEast();
        const scaledNe = new google.maps.LatLng(ne.lat() * scale, ne.lat() * scale);

        return new google.maps.LatLngBounds(scaledSw, scaledNe);
    }

    public isInBounds(latLng: google.maps.LatLng): boolean {
        return this.getBounds()?.contains(latLng);
    }

    // #region tracking the user

    protected get isGeolocationSupported(): boolean {
        return this.geolocationService.isSupported;
    }

    protected async toggleGeolocation() {
        this.trackingGeoLocation = !this.trackingGeoLocation;
        await this.handleGeolocation();
    }

    private async handleGeolocation() {
        if (!this.trackingGeoLocation) {
            this.geolocationService.unsubscribe();
            return;
        }

        const location = await this.geolocationService.getLocation();
        if (!location) return;
        this.setZoom(15);
        this.setCenter(new google.maps.LatLng(location.latitude, location.longitude));

        this.geolocationService.subscribeToPosition((location) => {
            // If the user moves, we update
            this.setCenter(new google.maps.LatLng(location.latitude, location.longitude));
        });
    }

    // #endregion

    public openContextMenu(latLng: google.maps.LatLng, contextMenuItems: MapContextMenuItem[]) {
        this.contextMenu.openContextMenu(this.map, latLng, contextMenuItems);
    }

    public closeContextMenu() {
        this.contextMenu.close();
    }
}
