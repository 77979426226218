<div class="m-layout-area-body m-overflow-auto">
    <form [formGroup]="avsConfigurationForm"
          class="p-16 m-border-top m-layout-area-top"
          *ngIf="device"
          autocomplete="off">

        <div class="m-form-group row">

            <label class="col-4"
                   for="showImage">
                {{"deviceAvs.showImage" | translate}}
            </label>

            <div class="col-8">
                <p-inputSwitch id="showImage"
                               formControlName="showImage">
                </p-inputSwitch>
            </div>
        </div>
    </form>
</div>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>