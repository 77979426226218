import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ITask } from "src/app/models/task";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { TaskApi } from "src/app/resource/task.api";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { ModalService } from "src/app/services/modal.service";
import { NavigationService } from "src/app/services/navigation.service";
import { IAssignment } from "src/app/models/assignment";
import { TaskService } from "src/app/services/task.service";
import { AddressPipe } from "../../pipes/address.pipe";
import { ILocation } from "src/app/models/location";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";


const isCompletedColumnFullWidth = 100;

@Component({
    selector: "app-tasks",
    templateUrl: "./tasks.component.html"
})
export class TasksComponent extends LazyTableComponent<ITask> implements OnInit, OnChanges, IComponentCanDeactivate {
    @Input() assignmentId: number;
    @Input() assignment: IAssignment;
    @Input() locationId: number;
    @Input() taskIdSelected: number;
    @Input() displayAssignmentColumn = false;
    @Input() requiresInput = false;

    @Input() editCommand = false;
    @Input() deleteCommand = false;
    @Input() duplicateCommand = false;

    @Output() duplicateCreated = new EventEmitter<ITask>();
    @Output() lastRowDeleted = new EventEmitter();
    @Output() selectionChanged = new EventEmitter<ITask[]>(); // returning selected items

    private assignmentColumn: TableColumn;
    private isCompletedColumn: TableColumn;
    private taskStatusColumn: TableColumn;

    selectedTasks: ITask[];

    private addressPipe = new AddressPipe();
    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        public readonly taskApi: TaskApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService,
        private readonly navigationService: NavigationService,
        private readonly taskService: TaskService) {

        super("tasks.component", elementRef, taskApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.isCompletedColumn = new TableColumn("isCompleted", "task.isCompleted", { sortable: true, visibility: ColumnVisibility.NeverHide, width: isCompletedColumnFullWidth, resizable: false });
        this.isCompletedColumn.ngStyle["justify-content"] = "center";
        this.addColumn(this.isCompletedColumn);

        this.taskStatusColumn = new TableColumn("currentStatus.taskStatusId", "task.taskStatus", { domainDataType: "taskStatus", filterType: FilterType.MultiSelect, visibility: ColumnVisibility.HideMini, sortable: false });
        this.addColumn(this.taskStatusColumn);

        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));

        this.assignmentColumn = new TableColumn("assignment", "general.assignment", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.NeverHide });
        this.addColumn(new TableColumn("location", "general.location", { customExcelSerializeMethod: (value: ILocation) => this.addressPipe.transform(value.address), filterType: FilterType.Text, sortable: false, visibility: ColumnVisibility.HideMini }));

        const taskTypeColumn = new TableColumn("currentStatus.taskTypeId", "task.taskType", { domainDataType: "taskType", filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.NeverHide });
        this.addColumn(taskTypeColumn);

        const signMaterialColumn = new TableColumn("currentStatus.signMaterialId", "task.signMaterial", { domainDataType: "signMaterial", filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(signMaterialColumn);

        const signCategoryColumn = new TableColumn("currentStatus.signCategoryId", "task.signCategory", { domainDataType: "signCategoryValue", filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(signCategoryColumn);

        const availableFromColumn = new TableColumn("currentStatus.availableFrom", "task.availableFrom", { filterType: FilterType.Date, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(availableFromColumn);
        const teamColumn = new TableColumn("currentStatus.team", "task.responsibleWorkers", { filterType: FilterType.None, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(teamColumn);


        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (task) => this.navigateToDetail(task),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditTask),
        });

        this.registerCommand({
            text: "form.view",
            icon: "eye-solid",
            click: (task) => this.navigateToDetail(task),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.ViewTask) && !this.rights?.hasBackendRight(BackendRights.EditTask),
        });

        this.registerCommand({
            text: "general.duplicate",
            icon: "duplicate",
            click: (task) => this.duplicate(task),
            validFunc: () =>
                this.duplicateCommand &&
                this.rights?.hasBackendRight(BackendRights.EditTask),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (task) => this.delete(task),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteTask),
        });

        this.domainDataService.get(DomainData.TaskType).then(types => {
            taskTypeColumn.filterOptions = types;
        });

        this.domainDataService.get(DomainData.SignMaterial).then(materials => {
            signMaterialColumn.filterOptions = materials;
        });

        this.domainDataService.get(DomainData.SignCategoryValue).then(categories => {
            signCategoryColumn.filterOptions = categories;
        });

        this.domainDataService.get(DomainData.TaskStatus).then(taskStatuses => {
            this.taskStatusColumn.filterOptions = taskStatuses;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const assignmentIdChange = changes["assignmentId"];
        const locationIdChange = changes["locationId"];
        const displayAssignmentColumnChange = changes["displayAssignmentColumn"];
        const miniChange = changes["mini"];

        const inputChange = changes["requiresInput"] || assignmentIdChange || locationIdChange || displayAssignmentColumnChange;

        if (displayAssignmentColumnChange) {
            this.refreshAssignmentColumnVisibility();
        }

        if (inputChange) {
            this.reload();
        }

        if (miniChange) {
            if (this.mini) {
                this.isCompletedColumn.setWidth(50);
                if (!this.columns.contains(this.isCompletedColumn)) {
                    this.addColumn(this.isCompletedColumn);
                }
            } else {
                this.isCompletedColumn.setWidth(isCompletedColumnFullWidth);
                if (this.columns.contains(this.isCompletedColumn)) {
                    this.removeColumn(this.isCompletedColumn);
                }
            }
        }

        super.ngOnChanges(changes);
    }
    onSetData() {
        if (this.taskIdSelected) {
            this.setSelection(Array<ITask>({ id: this.taskIdSelected } as ITask));
        }
    }
    setAssignment(assignment: IAssignment) {
        this.assignment = assignment;
        this.assignmentId = assignment.id;

        this.reload();
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.locationId || !!this.assignmentId;
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.assignmentId) {
            searchParameters.filter.push({ field: "assignmentId", value: this.assignmentId, operator: FilterOperator.equals });
        }

        if (this.locationId) {
            searchParameters.filter.push({ field: "locationId", value: this.locationId, operator: FilterOperator.equals });
        }

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("task", "assignment");
        serviceRequestOptions.includes.add("task", "location");
        serviceRequestOptions.includes.add("task", "currentStatus");
        serviceRequestOptions.includes.add("task", "copiedTask");
        serviceRequestOptions.includes.add("taskStatusHistory", "workerTasks");
        serviceRequestOptions.includes.add("workerTask", "worker");
        return serviceRequestOptions;
    }

    public navigateToDetail(task: ITask) {
        this.navigationService.toTask(task.id);
    }

    private refreshAssignmentColumnVisibility() {
        const colIndex = this.relevantColumns.findIndex(c => c.field === this.assignmentColumn.field);

        if (this.displayAssignmentColumn && colIndex < 0) {
            this.addColumn(this.assignmentColumn);
        } else if (!this.displayAssignmentColumn && colIndex > -1) {
            this.removeColumn(this.assignmentColumn);
        }

        this.updateRelevantColumns();
    }

    private delete(task: ITask) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteTask)) return;

        const modalBody = this.translateService.instant("task.deleteConfirmation", { name: task.name }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(task));
    }

    private handleDelete(task: ITask) {
        const onDeleteSuccess = () => {
            if (this.data.length === 1) {
                this.lastRowDeleted.emit();
            }
            this.reload();
        };

        this.taskApi.delete$(task.id).subscribe(onDeleteSuccess, () => { });
    }

    private duplicate(task: ITask) {
        const copyName = `${task.name} - ${this.translateService.instant("words.copy")}`;

        this.taskApi.create$(this.taskService.getCreatorFromServiceModel(task, copyName)).subscribe((created: ITask) => {
            this.duplicateCreated.next(created);
            this.reload();
        });
    }

    override onSelectionChange(): void {
        this.selectionChanged.emit(this.selectedTasks);
    }
}