import { ChartConfiguration, ChartOptions, ChartData, LineControllerDatasetOptions, ChartDataset, Chart, ScaleOptionsByType, TooltipItem, Tick, ScriptableScaleContext, Plugin, ScatterDataPoint, ScriptableTooltipContext } from "chart.js";
import { Component, OnInit, ElementRef, HostListener, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from "@angular/core";
import { VehicleDayOverview, VehicleDayOverviewVehicleCategory, VehicleDayOverviewHour } from "src/app/models/vehicle-overview";
import { TableColumn, CustomTableComponent, FilterType, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { ChartClickEvent, ChartComponent } from "src/app/modules/shared/components/signco-chart/signco-chart.component";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ServiceRequestOptions } from "src/app/models/search";
import { ActivatedRoute, Data } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { ValidationContext } from "src/app/models/validation-context";
import { ValidationService } from "src/app/services/validation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "src/app/services/locale.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { TitleService } from "src/app/services/title.service";
import { PredictionApi } from "src/app/resource/prediction.api";
import { IProgress, IProgressCreated } from "src/app/models/progress";
import { IPredictDaysData } from "src/app/models/prediction";
import { ProgressAction } from "src/app/services/progress.service";
import { ProgressApi } from "src/app/resource/progress.api";
import { ErrorService } from "src/app/services/error.service";
import { Constants } from "src/app/constants/constants";
import { VehicleCategory } from "src/app/models/vehicle";
import { AnyObject } from "chart.js/dist/types/basic";
import * as moment from "moment";
import { IMeasuringPointSummary } from "src/app/models/web";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { MeasuringPointExceptionalDayUpdater } from "src/app/models/measuring-point-exceptional-day";

@Component({
    selector: "app-measuring-point-validation",
    templateUrl: "./measuring-point-validation.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasuringPointValidationComponent extends CustomTableComponent<VehicleDayOverview> implements OnInit, IComponentCanDeactivate, IChangeGuard {
    @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;

    chartConfiguration: ChartConfiguration;
    measuringPointSummary: IMeasuringPointSummary;
    measuringPoint: IMeasuringPoint;
    context: ValidationContext;
    vehicleCategoryViewModels: ViewModelEnum[];
    private failedLoad = false;

    previousClicks = new Array<{ row: VehicleDayOverview, shift: boolean }>();
    handlingMultiEditBehavior: boolean;
    hasSelection: boolean;
    selectionIsPredicted: boolean;
    selectionIsError: boolean;
    selectionIsException: boolean;
    selectionExceptionReason: string;

    showDaysWithoutData = true;
    isDirtyCount: number;
    isDirty: boolean;
    isSaving: boolean;
    nextMeasuringPoint: IMeasuringPointSummary;
    previousMeasuringPoint: IMeasuringPointSummary;

    progress: number;
    showProgressBar = false;
    progressMessageTranslationResourceId: string;

    private analysisTypes: ViewModelEnum[];

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly validationService: ValidationService,
        readonly translateService: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private readonly zone: NgZone,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly navigationService: NavigationService,
        private readonly route: ActivatedRoute,
        private readonly titleService: TitleService,
        private readonly localeService: LocaleService,
        private readonly domainDataService: DomainDataService,
        private readonly predictionApi: PredictionApi,
        private readonly progressApi: ProgressApi,
        private readonly errorService: ErrorService) {

        super("measuring-point-validation", elementRef, tableService);

        this.enableSigncoMultiSelectBehavior = true;
        this.selectionMode = "multiple";
        this.selectionBox = true;
        this.enableInitialData = true;
        this.expandOnSelect = false;
        this.paginator = false;
        this.footer = false;
        this.stretchHeight = true;

        this.updateSelectionBoxColumn();

        const dateColumn = new TableColumn("date", "general.date", { filterType: FilterType.Date, width: null, resizable: false });
        this.addColumn(dateColumn);

        const predictionColumn = new TableColumn("isPredicted", "hourlyPreview.columnPredicted", { type: ColumnType.Checkbox, width: 40 });
        predictionColumn.ngStyle.textAlign = "center";
        this.addColumn(predictionColumn);

        // Saving pixels for German translation
        const errorColumn = new TableColumn("isError", "dataValidation.columnError", { type: ColumnType.Checkbox, width: 40 });
        errorColumn.ngStyle.textAlign = "center";
        this.addColumn(errorColumn);

        const exceptionColumn = new TableColumn("isException", "hourlyPreview.columnException", { type: ColumnType.Checkbox, width: 40 });
        // exceptionColumn.ngStyle.textAlign = "center";
        this.addColumn(exceptionColumn);

        const exceptionReasonColumn = new TableColumn("exceptionReason", "hourlyPreview.exceptionReason", { type: ColumnType.Checkbox, width: 40, resizable: false });
        this.addColumn(exceptionReasonColumn);

        const averageVehicleLengthColumn = new TableColumn("averageVehicleLength", "dataValidation.averageVehicleLength", { width: 50, resizable: true });
        this.addColumn(averageVehicleLengthColumn);

        const t16Column = new TableColumn("t16", "dataValidation.t16", { width: null, resizable: false });
        this.addColumn(t16Column);

        const t24Column = new TableColumn("t24", "dataValidation.t24", { width: null, resizable: false });
        this.addColumn(t24Column);

        const t16AverageDayDeviationPercentageColumn = new TableColumn("t16AverageDayDeviationPercentage", "dataValidation.t16AverageDayDeviationPercentage", { width: 50, resizable: false });
        this.addColumn(t16AverageDayDeviationPercentageColumn);

        for (let hour = 0; hour < 24; hour++) {
            const hourColumn = new TableColumn(`${hour}`, `${hour}`, { width: null, resizable: false });
            // hourColumn.ngStyle.padding = "1px";
            hourColumn.ngStyle["text-align"] = "center";
            hourColumn.headerStyle["text-align"] = "center";
            hourColumn.headerStyle["width"] = "100%";
            this.addColumn(hourColumn);
        }

        this.domainDataService.get(DomainData.VehicleCategory, { orderBy: null }).then(vehicleCategoryViewModels => {
            this.vehicleCategoryViewModels = vehicleCategoryViewModels;
            if (this.failedLoad) this.attemptLoad();
        });

        this.domainDataService.get(DomainData.AnalysisType).then(analysisTypes => {
            this.analysisTypes = analysisTypes;
        });
    }

    ngOnInit() {
        super.ngOnInit();

        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            // Attempt navigation to what the params specified
            await this.setMeasuringPointFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    private markForCheck() {
        if (this.destroyed) return;

        this.cd.markForCheck();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    canDeactivateCheck(): boolean {
        return !this.isDirty;
    }

    onDeactivate() {
    }

    private async setMeasuringPointFromParams(params: { [key: string]: any }) {
        const measuringPointId = Number.parseInt(params["measuringPointId"], 10);

        this.measuringPointSummary = this.validationService.getMeasuringPoint(measuringPointId);
        if (!this.measuringPointSummary) {
            this.back();
            return;
        }

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("measuringPoint", "location");

        this.measuringPoint = await this.measuringPointApi.get$(measuringPointId, null, serviceRequestOptions).toPromise();

        if (!this.measuringPoint) {
            this.back();
            return;
        }

        this.setTitle();

        this.context = await this.validationService.getContext();
        this.nextMeasuringPoint = this.validationService.getNextMeasuringPoint(this.measuringPointSummary);
        this.previousMeasuringPoint = this.validationService.getPreviousMeasuringPoint(this.measuringPointSummary);

        this.clearSelection();
        this.attemptLoad();
    }

    private attemptLoad() {
        if (!this.canLoad()) return;

        this.clearChart();
        this.clearData();
        this.setLoading();

        setTimeout(() => {
            this.reload(false);
            this.markForCheck();
        });
    }

    canLoad(): boolean {
        if (!this.vehicleCategoryViewModels) {
            this.failedLoad = true;
            return false;
        }

        return !!this.measuringPoint;
    }

    loadTableRows() {
        const onSuccess = async (dayOverviews: VehicleDayOverview[]) => {

            const currentSelection = this.getSelection();
            const newSelection = new Array<VehicleDayOverview>();

            for (const dayOverview of dayOverviews) {
                dayOverview.dateString = dayOverview.date.toString();

                dayOverview.shouldBePredicted = false;
                dayOverview.shouldBeReverted = false;
                dayOverview.initialIsPredicted = dayOverview.isPredicted;
                dayOverview.initialIsException = dayOverview.isException;
                dayOverview.initialIsError = dayOverview.isError;

                dayOverview.eventsGroupedBy24Hours = new Array(24).fill(0);

                if (dayOverview.events) {
                    for (const event of dayOverview.events) {
                        const eventHour = event.dateTime.getHours();
                        dayOverview.eventsGroupedBy24Hours[eventHour]++;
                    }
                }

                const isSelected = currentSelection.find(x => x.dateString === dayOverview.dateString);
                if (isSelected) {
                    newSelection.push(dayOverview);
                }

                dayOverview.vehicleCategories = [];

                for (const vehicleCategory of this.vehicleCategoryViewModels) {
                    dayOverview.vehicleCategories.push({
                        viewModelEnum: vehicleCategory,
                        value: 0
                    } as VehicleDayOverviewVehicleCategory);
                }

                if (dayOverview.vehicleCategory) {
                    for (const vehicleCategory in dayOverview.vehicleCategory) {
                        if (dayOverview.vehicleCategory.hasOwnProperty(vehicleCategory)) {
                            const existing = dayOverview.vehicleCategories.find(x => x.viewModelEnum.value === vehicleCategory);
                            if (!existing) continue;

                            existing.value += dayOverview.vehicleCategory[vehicleCategory];
                        }
                    }

                    const totalVehicles = dayOverview.vehicleCategories.map(x => x.value).sum();
                    const highestVehicles = Math.max.apply(Math, dayOverview.vehicleCategories.map(x => x.value));

                    for (const highestNumberCategory of dayOverview.vehicleCategories) {
                        highestNumberCategory.percentage = highestNumberCategory.value / totalVehicles;
                        highestNumberCategory.isHighest = highestNumberCategory.value === highestVehicles;
                    }

                    const order = this.vehicleCategoryViewModels.map(x => x.value);
                    dayOverview.vehicleCategories = dayOverview.vehicleCategories.sort((a: VehicleDayOverviewVehicleCategory, b: VehicleDayOverviewVehicleCategory) => {
                        const aOrder = order.indexOf(a.viewModelEnum.value);
                        const bOrder = order.indexOf(b.viewModelEnum.value);

                        return aOrder < bOrder ? -1 : 1;
                    });
                }

                // No data
                if (dayOverview.hourValues.filter(x => x === undefined || x === null).length === 24) {
                    continue;
                }

                const maxHourValue = Math.max.apply(Math, dayOverview.hourValues.map(x => x.total));
                if (maxHourValue === undefined || maxHourValue === null) continue;

                dayOverview.hasData = true;
                dayOverview.maxValue = maxHourValue;
            }

            this.clearSelection();
            this.setData(dayOverviews);
            this.setSelection(newSelection);
        };

        this.validationService.load(this.measuringPointSummary, true, false).subscribe(onSuccess);
    }

    onTableSet() {
        this.updateFilteredData();
        this.attemptLoad();
    }

    onSetData() {
        this.isDirty = false;
        this.updateChart();
        this.markForCheck();
    }

    toggleShowDaysWithoutData() {
        this.showDaysWithoutData = !this.showDaysWithoutData;
        this.updateFilteredData();
        this.markForCheck();
    }

    onFilter() {
        if (!this.data || !this.data.length) return;

        this.setLoading(false);
        this.markForCheck();
    }

    private updateFilteredData() {
        if (!this.table || this.destroyed) return;

        const hasFilter = !!this.table.filters["hasData"];
        const shouldApplyFilter = hasFilter ? this.showDaysWithoutData : !this.showDaysWithoutData; // XOR
        if (!shouldApplyFilter) return;

        this.setLoading();

        const filterValue = this.showDaysWithoutData ? null : true;

        if (this.data && this.data.length) {
            this.table.filter(filterValue, "hasData", "equals");
        } else {
            this.table.filters["hasData"] = { value: filterValue, matchMode: "equals" };
        }
    }

    private clearChart() {
        this.chart.clear();
    }

    private updateChart() {
        const hourInMs = 1000 * 60 * 60;
        const dayInMs = hourInMs * 24; // 1 day

        const chartData = {} as ChartData;

        const labels = new Array<string>();
        for (const date of this.validationService.getHours()) {
            labels.push(date.toISOString());
        }

        chartData.labels = labels;

        const minTime = this.context.from.getTime();
        const maxTime = this.context.to.addDays(1).getTime();

        chartData.datasets = [];

        const setPerformanceSettingsAccordingToDaysShown = (visibleDays: number) => {
            const dataIsManageable = visibleDays <= 7;
            // dataSet.lineTension = dataIsManageable ? 0.4 : 0;

            for (const chartDataset of chartData.datasets) {
                (chartDataset as LineControllerDatasetOptions).pointRadius = dataIsManageable ? 2 : 0;
            }
        };

        const hourValues = this.data.selectMany<VehicleDayOverview, {
            date: Date;
            vehicleCategory: {
                [key: string]: number;
            };
        }
        >(x => {
            return x.hourValues.map((y, index) => {
                return {
                    date: new Date(x.date.getFullYear(), x.date.getMonth(), x.date.getDate(), 0 + index, 0, 0, 0),
                    vehicleCategory: y?.vehicleCategory
                };
            });
        });

        // Record per date
        let relevantVehicleCategories = hourValues.filter(x => !!x).selectMany<VehicleDayOverviewHour, string>(x => x.vehicleCategory != null ? Object.keys(x.vehicleCategory) : [""]).distinct();
        relevantVehicleCategories = relevantVehicleCategories.remove("");
        const isVehicleMeasurement = relevantVehicleCategories.hasAny(
            [
                VehicleCategory.Light,
                VehicleCategory.Medium,
                VehicleCategory.Heavy,
                VehicleCategory.Bike,
                VehicleCategory.Moped
            ]
        );

        const isOnlyBike = relevantVehicleCategories.length === 1 && relevantVehicleCategories[0] === VehicleCategory.Bike;
        // const daysShown = (maxTime - minTime) / dayInMs;

        // if (relevantVehicleCategories.length > 1) {
        //     // "Total" line
        //     const hourValuesChartDataSet = {} as ChartDataSets;
        //     hourValuesChartDataSet.label = this.translateService.instant("table.total");
        //     hourValuesChartDataSet.type = "bar";
        //     hourValuesChartDataSet.order = 1;
        //     hourValuesChartDataSet.lineTension = 0; // Disable bezier curves (performance)
        //     // hourValuesChartDataSet.backgroundColor = "rgba(26, 115, 232, 0.3)";
        //     // hourValuesChartDataSet.borderColor = "rgba(26, 115, 232, 0.5)";
        //     hourValuesChartDataSet.backgroundColor = "rgba(20, 20, 20, 0.0)";
        //     hourValuesChartDataSet.borderColor = "rgba(20, 20, 20, 0.5)";
        //     hourValuesChartDataSet.data = hourValues.map(x => x ? x.total : 0);
        //     setPerformanceSettingsAccordingToDaysShown(hourValuesChartDataSet, daysShown);
        //     chartData.datasets.push(hourValuesChartDataSet);
        // }

        // Preserve viewmodel ordering
        for (const vehicleCategory of relevantVehicleCategories) {
            const vehicleCategoryChartDataSet = {} as ChartDataset<"line">;
            vehicleCategoryChartDataSet.type = "line";
            vehicleCategoryChartDataSet.label = this.vehicleCategoryViewModels.find(x => x.value === vehicleCategory).label;
            vehicleCategoryChartDataSet.yAxisID = "vehicles";
            vehicleCategoryChartDataSet.stack = "default";
            vehicleCategoryChartDataSet.fill = true;
            vehicleCategoryChartDataSet.pointRadius = 0;
            // vehicleCategoryChartDataSet.minBarLength = 1;
            // vehicleCategoryChartDataSet.barThickness = "flex";
            // vehicleCategoryChartDataSet.categoryPercentage = 1.0;
            // vehicleCategoryChartDataSet.barPercentage = 1.0;
            vehicleCategoryChartDataSet.order = this.vehicleCategoryViewModels.map(x => x.value).indexOf(vehicleCategory);
            vehicleCategoryChartDataSet.borderColor = Constants.colorPerVehicleCategory[vehicleCategory];
            vehicleCategoryChartDataSet.backgroundColor = vehicleCategoryChartDataSet.borderColor; // vehicleCategoryChartDataSet.borderColor.replace("0.5", "0.3");
            vehicleCategoryChartDataSet.parsing = false; // decimation plugin associated setting

            vehicleCategoryChartDataSet.data = hourValues.map(x => (x && x.vehicleCategory) ? { x: x.date.getTime(), y: (x.vehicleCategory[vehicleCategory] || 0) } : { x: x.date.getTime(), y: null });
            chartData.datasets.push(vehicleCategoryChartDataSet);
        }
        // meteoBikeFactorsDataSet
        const meteoBikeFactorsDataSet = {} as ChartDataset<"line">;
        meteoBikeFactorsDataSet.type = "line";
        meteoBikeFactorsDataSet.label = this.translateService.instant("dataValidation.bikeMeteo");
        meteoBikeFactorsDataSet.yAxisID = "averageVehicleLengthAndMeteoFactors";
        meteoBikeFactorsDataSet.stack = "";
        meteoBikeFactorsDataSet.borderWidth = 1;
        meteoBikeFactorsDataSet.tension = 0;
        meteoBikeFactorsDataSet.pointRadius = 0;
        meteoBikeFactorsDataSet.order = 0;
        meteoBikeFactorsDataSet.borderColor = "rgba(0, 255, 0, 1.0)";
        meteoBikeFactorsDataSet.fill = false;
        meteoBikeFactorsDataSet.backgroundColor = "rgba(0, 255, 0, 1.0)";
        meteoBikeFactorsDataSet.parsing = false; // decimation plugin associated setting


        const allHourValues = this.data.selectMany<VehicleDayOverview, {
            date: Date;
            total: number;
            carMeteo: number;
            bikeMeteo: number;
        }
        >(x => {
            return x.hourValues.map((y, index) => {
                return {
                    date: new Date(x.date.getFullYear(), x.date.getMonth(), x.date.getDate(), 0 + index, 0, 0, 0),
                    total: y?.total,
                    carMeteo: y?.carMeteo,
                    bikeMeteo: y?.bikeMeteo
                };
            });
        });

        meteoBikeFactorsDataSet.data = allHourValues.map(x => x != null ? { x: x.date.getTime(), y: x.bikeMeteo } : { x: x.date.getTime(), y: null });
        chartData.datasets.push(meteoBikeFactorsDataSet);

        // meteoCarFactorsDataSet
        const meteoCarFactorsDataSet = {} as ChartDataset<"line">;
        meteoCarFactorsDataSet.type = "line";
        meteoCarFactorsDataSet.label = this.translateService.instant("dataValidation.carMeteo");
        meteoCarFactorsDataSet.yAxisID = "averageVehicleLengthAndMeteoFactors";
        meteoCarFactorsDataSet.stack = "";
        meteoCarFactorsDataSet.borderWidth = 1;
        meteoCarFactorsDataSet.tension = 0;
        meteoCarFactorsDataSet.pointRadius = 0;
        meteoCarFactorsDataSet.order = 0;
        meteoCarFactorsDataSet.borderColor = "rgba(255, 0, 0, 1.0)";
        meteoCarFactorsDataSet.fill = false;
        meteoCarFactorsDataSet.backgroundColor = "rgba(255, 0, 0, 1.0)";
        meteoCarFactorsDataSet.parsing = false; // decimation plugin associated setting


        meteoCarFactorsDataSet.data = allHourValues.map(x => x != null ? { x: x.date.getTime(), y: x.carMeteo } : { x: x.date.getTime(), y: null });
        chartData.datasets.push(meteoCarFactorsDataSet);

        // averageVehicleLengthDataSet
        const meterTranslation = this.translateService.instant("measurements.meter");
        const averageVehicleLengthDataSet = {} as ChartDataset<"line">;
        averageVehicleLengthDataSet.type = "line";
        averageVehicleLengthDataSet.label = this.translateService.instant("dataValidation.averageVehicleLength");
        averageVehicleLengthDataSet.yAxisID = "averageVehicleLengthAndMeteoFactors";
        averageVehicleLengthDataSet.stack = "";
        averageVehicleLengthDataSet.borderWidth = 1;
        averageVehicleLengthDataSet.tension = 0;
        averageVehicleLengthDataSet.pointRadius = 0;
        averageVehicleLengthDataSet.order = 0;
        averageVehicleLengthDataSet.borderColor = "rgba(0, 0, 255, 1.0)";
        averageVehicleLengthDataSet.fill = false;
        averageVehicleLengthDataSet.backgroundColor = "rgba(0, 0, 255, 1.0)";
        averageVehicleLengthDataSet.parsing = false;

        const vehicleLengthArray = this.data.selectMany<VehicleDayOverview, { value: number, date: Date }>(x => {
            const vehicleLengthDateArray: { value: number, date: Date }[] = [];
            for (let i = 0; i < 24; i++) {
                vehicleLengthDateArray[i] = {} as { value: number, date: Date };
                vehicleLengthDateArray[i].date = new Date(x.date.getFullYear(), x.date.getMonth(), x.date.getDate(), 0 + i, 0, 0, 0);
                vehicleLengthDateArray[i].value = x.averageVehicleLength;
            }
            return vehicleLengthDateArray;
        });

        averageVehicleLengthDataSet.data = vehicleLengthArray.map(x => x != null ? { x: x.date.getTime(), y: x.value } : { x: x.date.getTime(), y: null });
        chartData.datasets.push(averageVehicleLengthDataSet);

        // var
        // meteoFactorsDataSet.data = this.data.selectMany<VehicleDayOverview>
        // chartData.datasets.push(meteoBikeFactorsDataSet);

        // const eventChartDataSet = {} as ChartDataSets;
        // eventChartDataSet.type = "bar";
        // eventChartDataSet.order = 2;
        // eventChartDataSet.minBarLength = 1;
        // eventChartDataSet.backgroundColor = "rgba(115, 26, 150, 0.7)";
        // eventChartDataSet.borderColor = "rgba(115, 26, 150, 1.0)";
        // eventChartDataSet.data = this.data.selectMany<VehicleDayOverview, number>(x => x.eventsGroupedBy24Hours);
        // chartData.datasets.push(eventChartDataSet);

        const chartOptions = {} as ChartOptions<"line">;
        chartOptions.showLine = true;
        chartOptions.indexAxis = "x"; // decimation plugin associated setting

        chartOptions.plugins = {};

        // Chart plugin management
        chartOptions.plugins.zoom = {
            zoom: {
                wheel: {
                    enabled: true
                },
                drag: {
                    enabled: false
                },
                pinch: {
                    enabled: true
                },
                mode: "x",
                // onZoom: function ({ chart }: { chart: Chart }) {
                // updateOptionsAccordingToData(chart);
                // }
            },
            pan: {
                enabled: true,
                mode: "x"
                // onPan: function () { console.log("I was panned!!!"); }
            },
            limits: {
                x: {
                    min: minTime,
                    max: maxTime
                }
            }
        };

        chartOptions.plugins.legend = {};
        chartOptions.plugins.legend.display = false;

        chartOptions.scales = {};
        // chartOptions.scales.ticks = {};
        // chartOptions.scales.ticks.autoSkip = false;
        // chartOptions.scales.ticks.maxTicksLimit = 7;

        chartOptions.plugins.tooltip = {};
        chartOptions.plugins.tooltip.enabled = true;
        chartOptions.plugins.tooltip.mode = "index";
        chartOptions.plugins.tooltip.intersect = false;
        chartOptions.plugins.tooltip.usePointStyle = true;
        const defaultTitleColor = "#fff";

        chartOptions.plugins.tooltip.titleColor = (scriptableContext: ScriptableTooltipContext<"line">, anyObject: AnyObject) => {
            const date: Date = new Date(scriptableContext.tooltipItems[0].parsed.x);
            const returnedForDate = this.data.find(x => x.date.getDate() === date.getDate() && x.date.getMonth() === date.getMonth() && x.date.getFullYear() === date.getFullYear());
            if (returnedForDate.isSpecialDay) {
                return "rgb(255,128,0)";
            } else {
                return defaultTitleColor;
            }
        };
        chartOptions.plugins.tooltip.callbacks = {
            title: (tooltipItems: TooltipItem<"line">[]) => {
                const date: Date = new Date(tooltipItems[0].parsed.x);
                const returnedForDate = this.data.find(x => x.date.getDate() === date.getDate() && x.date.getMonth() === date.getMonth() && x.date.getFullYear() === date.getFullYear());

                return tooltipItems[0].label + (returnedForDate.isSpecialDay ? `\n${returnedForDate.datePeriodName}` : "");
            },
            label: (tooltipItem: TooltipItem<"line">) => {
                const locale = this.localeService.getLocale();
                const isAverageVehicleLength = tooltipItem.datasetIndex === chartData.datasets.length - 1;

                if (!isAverageVehicleLength) {
                    return `${chartData.datasets[tooltipItem.datasetIndex].label}: ${(<ScatterDataPoint>chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]).y.toLocaleString(locale)}`;
                }

                const meterValue = ((<ScatterDataPoint>chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]).y as number).toPrecision(3);
                return `${chartData.datasets[tooltipItem.datasetIndex].label}: ${meterValue}${meterTranslation}`;
            },
            labelPointStyle: (tooltipItem: TooltipItem<"line">) => {
                if (tooltipItem.dataset.yAxisID === "averageVehicleLengthAndMeteoFactors") {
                    return {
                        pointStyle: "line",
                        rotation: 0
                    };
                } else {
                    return {
                        pointStyle: "rectRounded",
                        rotation: 0
                    };
                }
            },
        };

        // Disable zoom animation so rendering will be faster
        chartOptions.transitions = {};
        chartOptions.transitions.zoom = {};
        chartOptions.transitions.zoom.animation = {};
        chartOptions.transitions.zoom.animation.duration = 0;

        chartOptions.plugins.decimation = {};
        chartOptions.plugins.decimation.enabled = true;
        chartOptions.plugins.decimation.algorithm = "min-max";


        const vehicleYAxes = {} as ScaleOptionsByType<"linear">;
        vehicleYAxes.type = "linear";
        vehicleYAxes.position = "left";
        vehicleYAxes.stacked = true;
        vehicleYAxes.beginAtZero = true;
        vehicleYAxes.ticks = {} as any;
        vehicleYAxes.ticks.minRotation = 0;
        vehicleYAxes.ticks.maxRotation = 0;
        vehicleYAxes.ticks.precision = 0;
        vehicleYAxes.ticks.callback = (label: number) => {
            return label.toLocaleString(this.localeService.getLocale());
        };

        chartOptions.scales.vehicles = vehicleYAxes;

        // averageVehicleLengthDataSet

        const averageVehicleLengthAndMeteoFactorsDataSetYAxes = {} as ScaleOptionsByType<"linear">;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.type = "linear";
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.position = "right";
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.stacked = false;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.max = isOnlyBike ? 10 : isVehicleMeasurement ? 10 : undefined;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.beginAtZero = true;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks = {} as any;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks.stepSize = 1;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks.minRotation = 0;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks.maxRotation = 0;
        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks.precision = 3;

        averageVehicleLengthAndMeteoFactorsDataSetYAxes.ticks.callback = (label: number) => {
            return `${label}`;
        };

        averageVehicleLengthAndMeteoFactorsDataSetYAxes.grid = {
            display: false
        } as any;

        chartOptions.scales.averageVehicleLengthAndMeteoFactors = averageVehicleLengthAndMeteoFactorsDataSetYAxes;

        const xAxes = {} as ScaleOptionsByType<"time">;
        xAxes.type = "time";
        xAxes.stacked = true;
        xAxes.min = minTime;
        xAxes.max = maxTime;
        // xAxes.bounds = "data";
        xAxes.time = {} as any;
        xAxes.time.unit = "day";
        xAxes.time.tooltipFormat = this.translateService.currentLang === "nl" ? "ddd DD/MM/YYYY HH:mm" : "ddd MM/DD/YYYY HH:mm";
        xAxes.time.displayFormats = {};
        xAxes.time.displayFormats.minute = "MMM D, H";
        xAxes.time.displayFormats.hour = "MMM D, H";
        xAxes.time.displayFormats.day = "MMM D, H";

        xAxes.ticks = {} as any;
        xAxes.ticks.stepSize = 1;
        xAxes.ticks.maxRotation = 0;
        xAxes.ticks.autoSkip = true;
        xAxes.ticks.autoSkipPadding = 50;
        xAxes.ticks.minRotation = 0;
        xAxes.ticks.maxRotation = 0;
        xAxes.ticks.major = {
            enabled: true
        };

        xAxes.ticks.color = ((ctx: ScriptableScaleContext, options: AnyObject) => {
            const currentDate: Date = new Date(ctx.tick.value);
            const returnedForDate = this.data.find(x => x.date.getDate() === currentDate.getDate() && x.date.getMonth() === currentDate.getMonth() && x.date.getFullYear() === currentDate.getFullYear());
            if (returnedForDate?.isSpecialDay) {
                return "rgb(255,128,0)";
            }
        });

        xAxes.ticks.sampleSize = 5;
        // xAxes.ticks.maxTicksLimit = 30;

        const hourNotation = this.translateService.instant("measurements.hour");

        xAxes.ticks.callback = (label: string, index: number, ticks: Tick[]) => {
            return moment(label).format("MMM D");
        };

        chartOptions.scales.x = xAxes;

        // Disable animations
        chartOptions.animation = {};
        chartOptions.animation = false;

        chartOptions.hover = {};
        chartOptions.hover.mode = "index";
        chartOptions.hover.intersect = false;

        // Chart plugin management
        (chartOptions as any).zoom = {
            enabled: true,
            drag: false,
            mode: "x",
            rangeMin: {
                x: minTime
            },
            rangeMax: {
                x: maxTime
            },
            // onZoom: function ({ chart }: { chart: Chart }) {
            // updateOptionsAccordingToData(chart);
            // }
        };

        (chartOptions as any).pan = {
            enabled: true,
            mode: "x",
            rangeMin: {
                x: minTime
            },
            rangeMax: {
                x: maxTime
            },
            // onPan: function () { console.log("I was panned!!!"); }
        };

        const days = this.data.filter(x => x.isPredicted || x.isException || x.isError);
        let dates = this.validationService.getDates();
        dates = dates.filter(x => days.find(y => y.date.getDate() === x.getDate() && y.date.getMonth() === x.getMonth() && y.date.getFullYear() === x.getFullYear()));

        const fillBackground = {
            id: "fillBackground",
            beforeDraw: (chart: Chart) => {
                const ctx = chart.ctx;
                const area = chart.chartArea;

                const left = area.left;
                const top = area.top;
                const width = area.right - area.left;
                const height = area.bottom - area.top;


                const minDate = this.context.from.getTime();
                const maxDate = this.context.to.getTime();

                const currentMin = chart.scales.x.min;
                const currentMax = chart.scales.x.max;
                const xScaleTotal = currentMax - currentMin;

                // Calculate hours we need to cut off because of zoom / pan
                const hoursToCutInFrontInMs = currentMin - minDate;
                const hoursToCutAtBackInMs = currentMax - maxDate;
                const hoursToCutInFront = Math.round(hoursToCutInFrontInMs / hourInMs);
                const hoursToCutInEnd = Math.round(hoursToCutAtBackInMs / hourInMs);

                if (!chartData.datasets || chartData.datasets.length === 0) return;

                const dataSet = (chartData.datasets[0].data as number[]);
                const data = dataSet.slice(hoursToCutInFront, dataSet.length - hoursToCutInEnd);
                // const zoomFactor = dataSet.length / data.length;

                const columnCount = data.length;
                const columnWidth = width / columnCount; // width for 1 hour

                const selection = this.getSelection();

                ctx.save();

                for (let day = new Date(minDate); day.getTime() <= maxDate; day = day.addDays(1)) {
                    const dayTime = day.getTime();
                    if (dayTime + dayInMs < currentMin || dayTime > currentMax) continue; // Not in the area -> skip

                    const row = this.data.find(x => x.date.getDate() === day.getDate() && x.date.getMonth() === day.getMonth() && x.date.getFullYear() === day.getFullYear());
                    if (!row) {
                        console.log("Couldn't find row", day);
                        continue;
                    }

                    let bgColor: string = null;

                    const isSelected = selection.contains(row);

                    if (row.isError) {
                        bgColor = `rgba(251, 85, 85, ${isSelected ? "0.5" : "0.3"})`;
                    } else if (row.isException) {
                        bgColor = `rgba(254, 195, 45, ${isSelected ? "0.5" : "0.3"})`;
                    } else if (row.isPredicted) {
                        bgColor = `rgba(69, 61, 85, ${isSelected ? "0.5" : "0.3"})`;
                    } else if (isSelected) {
                        bgColor = "rgba(150, 150, 150, 0.3)";
                    }

                    if (bgColor) {

                        // Negative numbers go left outside chart frame
                        const dayXStart = (dayTime - currentMin) / xScaleTotal * width;
                        const dayXEnd = (dayTime + dayInMs - currentMin) / xScaleTotal * width;

                        // Numbers above xScaleMax go right outside chart frame
                        const dayWidth = Math.min(width, dayXEnd) - Math.max(0, dayXStart);

                        if (dayWidth > 0) {
                            ctx.fillStyle = bgColor;
                            ctx.fillRect(left + Math.max(0, dayXStart), top, dayWidth, height);
                        }
                    }
                }

                ctx.restore();
            }
        } as Plugin<"line">;

        this.chartConfiguration = {
            type: "line",
            data: chartData,
            options: chartOptions,
            plugins: [fillBackground]
        } as ChartConfiguration;
    }

    handleChartClick(event: ChartClickEvent) {
    }

    goToMeasuringPoint(measuringPointSummary: IMeasuringPointSummary) {
        this.navigationService.toMeasuringPointValidation(measuringPointSummary);
        this.markForCheck();
    }

    goToPreviousMeasuringPoint() {
        this.goToMeasuringPoint(this.previousMeasuringPoint);
    }

    goToNextMeasuringPoint() {
        this.goToMeasuringPoint(this.nextMeasuringPoint);
    }

    back() {
        this.navigationService.toValidation();
    }

    isMaxValue(field: string, row: VehicleDayOverview): boolean {
        const hourValue = row.hourValues[field];
        return hourValue && row.maxValue === hourValue.total;
    }

    addClick(row: VehicleDayOverview, e: MouseEvent) {
        this.previousClicks.push({
            row: row,
            shift: this.previousClicks.length > 0 ? e.shiftKey : false // First click is always without shift, else double shift-click simply single selects
        });
    }

    private handleMultiEditBehavior(func: (x: VehicleDayOverview) => void, shiftBehavior = true, selectionBehavior = false) {
        if (this.handlingMultiEditBehavior) return;

        this.handlingMultiEditBehavior = true;

        this.zone.runOutsideAngular(() => {

            if (shiftBehavior) {
                this.handleShiftBehavior(func);
            }

            if (selectionBehavior) {
                for (const selectedRow of this.getSelection()) {
                    func(selectedRow);
                }
            }
        });

        this.handlingMultiEditBehavior = false;
    }

    private handleShiftBehavior(func: (x: VehicleDayOverview) => void) {
        if (this.previousClicks.length < 2) return;

        const previousClick = this.previousClicks.shift();
        const lastClick = this.previousClicks[this.previousClicks.length - 1];
        if (previousClick.shift) {
            this.selectClicks.push(lastClick);
            return;
        }

        if (!lastClick.shift) {
            this.selectClicks.push(lastClick);
            return;
        }

        // Re-add the initial click that started it all
        // This way we can chain multiple shift-clicks
        this.selectClicks.push(previousClick);

        const currentData = this.getCurrentData();
        const indexPrevious = currentData.indexOf(previousClick.row);
        const indexLast = currentData.indexOf(lastClick.row);
        const startIndex = Math.min(indexPrevious, indexLast);
        const endIndex = Math.max(indexPrevious, indexLast);
        if (startIndex === -1) return; // Previous data no longer in view, disregard it

        for (let index = startIndex + 1; index < endIndex; index++) {
            const row = currentData[index];
            func(row);
        }
    }

    onSelectionChange() {
        const selection = this.getSelection();
        this.hasSelection = selection.length > 0;

        if (!this.hasSelection) {
            this.selectionIsError = false;
            this.selectionIsPredicted = false;
            this.selectionIsException = false;
            this.selectionExceptionReason = "";
        } else {
            this.selectionIsError = !selection.find(x => !x.isError);
            this.selectionIsPredicted = !selection.find(x => !x.isPredicted);
            this.selectionIsException = !selection.find(x => !x.isException);

            const firstReason = selection[0].exceptionReason;
            const hasOtherReasons = selection.find(x => x.exceptionReason !== firstReason);
            this.selectionExceptionReason = hasOtherReasons ? "" : firstReason;
        }

        this.chart.refresh();
    }

    handleSelectionIsPredicted() {
        this.handleMultiEditBehavior(selected => {
            if ((!selected.initialIsError && !selected.initialIsException && !selected.initialIsPredicted && selected.hasData)
                || selected.isPredicted === this.selectionIsPredicted) return;

            selected.isPredicted = this.selectionIsPredicted;
            this.handleRowIsPredicted(selected);
        }, false, true);

        this.updateIsDirty();
        this.chart.refresh();
    }

    handleSelectionIsError() {
        this.handleMultiEditBehavior(selected => {
            if (selected.isError === this.selectionIsError) return;

            selected.isError = this.selectionIsError;
            this.handleRowIsError(selected);
        }, false, true);

        this.updateIsDirty();
        this.chart.refresh();
    }

    handleSelectionIsException() {
        this.handleMultiEditBehavior(selected => {
            if (selected.isException === this.selectionIsException) return;

            selected.isException = this.selectionIsException;
            this.handleRowIsException(selected);
        }, false, true);

        this.updateIsDirty();
        this.chart.refresh();
    }

    handleSelectionExceptionReason() {
        this.handleMultiEditBehavior(selected => {
            if (selected.exceptionReason === this.selectionExceptionReason) return;

            selected.exceptionReason = this.selectionExceptionReason;
            this.handleRowEdit(selected);
        }, false, true);

        this.updateIsDirty();
        this.chart.refresh();
    }

    handleRowIsError(row: VehicleDayOverview) {
        if (row.isError && row.isException) {
            row.isException = false;
        }

        this.handleMultiEditBehavior((x: VehicleDayOverview) => {
            if (x.isError === row.isError) return;

            x.isError = row.isError;
            this.handleRowIsError(x);
        });

        this.handleRowEdit(row);
    }

    handleRowIsException(row: VehicleDayOverview) {
        if (row.isError && row.isException) {
            row.isError = false;
        }

        this.handleMultiEditBehavior((x: VehicleDayOverview) => {
            if (x.isException === row.isException) return;

            x.isException = row.isException;
            this.handleRowIsException(x);
        });

        this.handleRowEdit(row);
    }

    handleRowIsPredicted(row: VehicleDayOverview) {
        if (row.initialIsPredicted) {
            if (row.isPredicted) row.shouldBeReverted = false;
            else row.shouldBeReverted = true;
        } else {
            if (row.isPredicted) row.shouldBePredicted = true;
            else row.shouldBePredicted = false;
        }

        this.handleMultiEditBehavior((x: VehicleDayOverview) => {
            // first condition is when checkbox is disabled
            if ((!x.initialIsError && !x.initialIsException && !x.initialIsPredicted && x.hasData)
                || x.isPredicted === row.isPredicted) return;

            x.isPredicted = row.isPredicted;
            this.handleRowIsPredicted(x);
        });

        this.handleRowEdit(row);
    }

    handleRowEdit(row: VehicleDayOverview) {
        // It's undefined when we get it from server, so delete empty strings for comparison
        if (!row.exceptionReason) {
            delete row.exceptionReason;
        }

        this.zone.runOutsideAngular(() => {
            if (!row.isDirty) {
                row.isDirty = true;
            } else if (this.isInitialDataRow(row)) {
                row.isDirty = false;
            }
        });

        if (!this.handlingMultiEditBehavior) {
            this.updateIsDirty();
            this.chart.refresh();
        }
    }

    private updateIsDirty() {
        this.isDirtyCount = this.data.filter(x => x.isDirty).length;
        this.isDirty = this.isDirtyCount > 0;
    }

    save() {
        if (!this.isDirty || this.isSaving) return;

        this.isSaving = true;

        const rowsToSave = this.data.filter(x => x.isDirty && !x.shouldBePredicted);
        let divider = 0;
        const predictingRequired = this.data.filter(x => x.isDirty && x.shouldBePredicted).length;
        const revertingRequired = this.data.filter(x => x.isDirty && x.shouldBeReverted).length;
        divider += predictingRequired > 0 ? 1 : 0;
        divider += revertingRequired ? 1 : 0;

        const onSuccess = () => { };
        const onError = () => {
            this.isSaving = false; // Allow the user to try again
            this.showProgressBar = false;
            this.progressMessageTranslationResourceId = null;
            this.reload();
            this.updateIsDirty();
        };

        const onComplete = async () => {
            this.progress = 0;
            this.showProgressBar = true;
            this.markForCheck();

            if (revertingRequired) {
                try {
                    this.progressMessageTranslationResourceId = "dataValidation.revertingPredictedDays";
                    this.markForCheck();
                    await this.revertPredictedDays$(this.data.filter(x => x.shouldBeReverted && x.isDirty), divider);
                } catch (error) {
                    onError();
                    return;
                }
            }

            if (predictingRequired) {
                try {
                    this.progressMessageTranslationResourceId = "dataValidation.predictingDays";
                    this.markForCheck();
                    await this.predictDays$(this.data.filter(x => x.shouldBePredicted && x.isDirty), divider);
                } catch (error) {
                    onError();
                    return;
                }
            }

            this.isSaving = false;
            this.progressMessageTranslationResourceId = null;
            this.showProgressBar = false;
            this.progress = null;

            this.reload();
            this.updateIsDirty();
            this.markForCheck();
        };

        const observables = new Array<Observable<void>>();

        if (rowsToSave.length > 0) {

            const updaters = new Array<MeasuringPointExceptionalDayUpdater>();
            for (const row of rowsToSave) {
                const updater = new MeasuringPointExceptionalDayUpdater();
                updater.date = row.date;
                updater.isError = row.isError;
                updater.isException = row.isException;
                updater.reason = row.exceptionReason;
                updater.isPredicted = row.shouldBeReverted ? true : row.isPredicted; // rows which should be reverted will be marked as 'not predicted' when reverting

                updaters.push(updater);
            }

            observables.push(this.measuringPointApi.updateExceptionalDays$(this.measuringPoint.id, updaters));
        }

        forkJoin(observables).subscribe(onSuccess, onError, onComplete);
    }

    private setTitle() {
        if (!this.measuringPoint) return;

        const routeSubscription = this.route.data.subscribe((data: Data) => {
            this.translateService.get(data.name).subscribe((baseTitle: string) => {
                this.titleService.setTitle(`${baseTitle} - ${this.measuringPoint.code}`);
            });
        });

        this.subscriptionManager.add("routeSubscription", routeSubscription);
    }

    vehicleCategoryTrackByFn(index: number, item: VehicleDayOverviewVehicleCategory) {
        return item.viewModelEnum.value;
    }

    getMeasuringPointAnalysisTypeTranslation(analysisTypeId: string): string {
        if (!this.analysisTypes) return null;

        const analysisType = this.analysisTypes.find(x => x.value === analysisTypeId);
        return analysisType ? analysisType.label : "";
    }

    private predictDays$(rows: VehicleDayOverview[], divider: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const onSuccess = (createdProgress: IProgressCreated) => {
                this.addProgressCreated(createdProgress, (action: ProgressAction) => resolve(), (error) => reject(), divider);
            };

            const onError = () => {
                reject();
            };

            const requests = new Array<IPredictDaysData>();
            requests.push({
                from: new Date(rows.map(x => x.date).min()),
                until: new Date(rows.map(x => x.date).max()),
                measuringPointId: this.measuringPoint.id,
                datesToBePredicted: rows.map(x => x.date),
                predictExceptionalDays: true,
                predictPredictedDays: false
            });

            this.predictionApi.createModelsAndPredictDaysWithProgress$(requests).subscribe(onSuccess, onError);
        });
    }

    private revertPredictedDays$(rows: VehicleDayOverview[], divider: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const onSuccess = (createdProgress: IProgressCreated) => {
                this.addProgressCreated(createdProgress, (action: ProgressAction) => resolve(), (error) => reject(), divider);
            };

            const onError = () => {
                reject();
            };
            this.predictionApi.revertPredictedDaysWithProgress$(this.measuringPoint.id, rows.map(x => x.date)).subscribe(onSuccess, onError);
        });
    }

    private addProgressCreated(progressCreated: IProgressCreated, onComplete: (action: ProgressAction) => void, onError: (error: Response) => void, divider: number) {
        const action = new ProgressAction(progressCreated.progressId, progressCreated.name);
        action.onComplete = onComplete;
        action.onError = onError;

        this.startPolling(action, divider);
    }

    private startPolling(action: ProgressAction, divider: number) {
        const onProgress = (progress: IProgress) => {
            this.progress = this.progress + (progress.progress / divider - (action.lastProgress?.progress ?? 0) / divider);
            action.lastProgress = progress;
            this.markForCheck();
        };

        const onComplete = () => {
            if (action.onComplete) {
                action.onComplete(action);
            }
        };

        const onError = (error: Response) => {
            if (action.onError) {
                action.onError(error);
            }

            this.errorService.handleError(error);
        };

        this.progressApi.pollProgress(action.id, onProgress).then(onComplete, onError);
    }
}
