import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { IRelayConfiguration } from "src/app/models/device-relay";
import { SigncoFormGroup } from "src/app/models/form";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-relay-configuration",
    templateUrl: "./device-relay-configuration.component.html"
})
export class DeviceRelayConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    relayConfigurationForm: SigncoFormGroup;
    relayTypes: ViewModelEnum[];

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainDataService: DomainDataService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");

        this.domainDataService.get(DomainData.RelayType).then(relayTypes => {
            this.relayTypes = relayTypes;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.relayConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }
    async initialize() {
        if (!this.device) return;

        this.relayConfigurationForm = this.formBuilder.group({
            pin: null,
            typeId: [null, Validators.required]
        }) as SigncoFormGroup;

        if (this.device.relayConfiguration) {
            this.relayConfigurationForm.patchValue(this.device.relayConfiguration);
        }

        this.relayConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.relayConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);

        if (!deviceUpdater.relayConfiguration) {
            deviceUpdater.relayConfiguration = {} as IRelayConfiguration;
        }

        Object.assign(deviceUpdater.relayConfiguration, this.relayConfigurationForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}