import { Component, Input } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { SigncoFormArray, SigncoFormGroup, SigncoFormUtils } from "src/app/models/form";
import { ILightMode } from "src/app/models/mode-configuration";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-mode-to-gpio-link",
    templateUrl: "./device-link-mode-to-gpio-link.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkModeToGpioLinkComponent extends DeviceLinkFormGroupComponent {
    @Input() measuringPointId: number;

    modeToPinStatesForms: SigncoFormArray;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly fgd: FormGroupDirective
    ) {
        super();
        this.modeToPinStatesForms = this.formBuilder.array([]) as SigncoFormArray;
    }

    configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        this.modeToPinStatesForms = this.formBuilder.array([]) as SigncoFormArray;

        return new Promise<boolean>((resolve, reject) => {
            if (analyzerConfiguration?.modeToGpioLink) {
                this.measuringPointApi.get$(this.measuringPointId, null, null, false).subscribe((mp) => {
                    if (this.fgd.form.get("modeToGpioLink")) this.fgd.form.removeControl("modeToGpioLink");
                    this.modeToPinStatesForms = this.formBuilder.array([]) as SigncoFormArray;

                    if (mp.lightModeConfiguration) {
                        this.addModes(mp.lightModeConfiguration.modes);
                    }

                    if (this.analyzerConfiguration) {
                        const getModeForm = (formArray: SigncoFormArray, modeId: string) => {
                            return formArray.controls.find(x => x.get("modeId").value === modeId) as SigncoFormGroup;
                        };

                        const modeToGpioLink = this.analyzerConfiguration.modeToGpioLink;
                        if (modeToGpioLink && modeToGpioLink.modes) {
                            for (const modeToPinState of modeToGpioLink.modes) {
                                const modeToPinStatesForm = getModeForm(this.modeToPinStatesForms, modeToPinState.modeId);

                                for (const pinState of modeToPinState.pinStates) {
                                    this.addGpioPinState(modeToPinStatesForm);
                                }
                            }
                        }
                    }

                    this.form = this.formBuilder.group({
                        modes: this.modeToPinStatesForms
                    }) as SigncoFormGroup;
                    this.form.patchValue(analyzerConfiguration.modeToGpioLink);

                    this.fgd.form.addControl("modeToGpioLink", this.form);
                    resolve(true);
                });
                return;
            }

            if (this.fgd.form.get("modeToGpioLink")) this.fgd.form.removeControl("modeToGpioLink");
            this.form = null;
            resolve(false);
        });
    }

    private addModes(modes: ILightMode[]) {
        if (!modes || !modes.length) {
            this.modeToPinStatesForms.clear();
            return;
        }

        for (const mode of modes) {
            const modeLabel = mode.id + (mode.description ? ` - ${mode.description}` : "");
            if (this.modeToPinStatesForms.controls.find(x => x.get("modeId").value === mode.id)) continue;

            this.modeToPinStatesForms.push(this.formBuilder.group({
                modeId: mode.id,
                modeLabel: modeLabel,
                pinStates: this.formBuilder.array([]) as SigncoFormArray
            }));
        }
    }

    //#region GpioPinStates

    addGpioPinState(modeToPinStatesForm: SigncoFormGroup) {
        const pinStateForm = this.formBuilder.group({
            pinId: [null, Validators.required],
            state: false
        });

        const pinStatesFormArray = SigncoFormUtils.getFormArray(modeToPinStatesForm, "pinStates");
        pinStatesFormArray.push(pinStateForm);
    }

    deleteGpioPinState(modeToPinStatesForm: SigncoFormGroup, gpioPinStateIndex: number) {
        const pinStatesFormArray = SigncoFormUtils.getFormArray(modeToPinStatesForm, "pinStates");
        pinStatesFormArray.removeAt(gpioPinStateIndex);
    }

    //#endregion GpioPinStates
}