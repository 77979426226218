import { CustomTableComponent, TableColumn, TableService, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnInit, ElementRef } from "@angular/core";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";

@Component({
    selector: "app-admin-journal-categories",
    templateUrl: "./admin-journal-categories.component.html"
})
export class AdminJournalCategoriesComponent extends CustomTableComponent<ViewModelEnum> implements OnInit {
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly domainDataService: DomainDataService) {

        super("admin-journal-categories.component", elementRef, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("label", "general.code"));
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    loadTableRows() {
        this.domainDataService.get(DomainData.ContextType).then(contextTypeViewModels => {
            this.setData(contextTypeViewModels);
        });
    }
}