import { Component, Output, EventEmitter, HostListener, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList } from "@angular/core";
import { ITextFieldConfiguration, ITextFieldContent, ITextFrame, ITotemDisplayConfiguration, ITotemDisplayMode } from "src/app/models/totem-display-configuration";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ToastService } from "src/app/services/toast.service";
import * as lodash from "lodash";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { TotemDisplayFieldsDialogComponent } from "../totem-display-fields-dialog/totem-display-fields.dialog";
import { createPopper } from "@popperjs/core";
import { TextFieldAnimationComponent } from "src/app/modules/shared/components/totem-display-preview/text-field-animation/text-field-animation.component";
import { NgModel } from "@angular/forms";

@Component({
    selector: "app-totem-display-configuration",
    templateUrl: "./totem-display-configuration.component.html",
    host: { class: "m-layout-area-body m-layout-w-actions-bottom" },
    styleUrls: ["./totem-display-configuration.component.scss"],
})
export class TotemDisplayConfigurationComponent implements IComponentCanDeactivate, IChangeGuard {
    @ViewChild(TotemDisplayFieldsDialogComponent, { static: true }) fieldsDialog: TotemDisplayFieldsDialogComponent;
    @ViewChildren(TextFieldAnimationComponent) textFields: QueryList<TextFieldAnimationComponent>;
    @ViewChildren("input") inputs: QueryList<NgModel>;

    //#region context menu
    @ViewChild("contextMenu", { static: true }) contextMenu: ElementRef<HTMLDivElement>;

    contextMenuShown = false;
    private _popper: any;
    private currentTextFieldContent: ITextFieldContent;
    private currentFrame: ITextFrame; // The frame whose context menu is opened
    private currentTextArea: ElementRef;
    //#endregion context menu

    measuringPoint: IMeasuringPoint;

    @Output() save = new EventEmitter<IMeasuringPoint>();

    hasChanged = false;
    submitting = false;
    totemDisplayConfiguration: ITotemDisplayConfiguration;

    constructor(
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private renderer: Renderer2
    ) {}

    getContent(modeId: string, fieldId: number) {
        const mode = this.totemDisplayConfiguration.modeConfiguration.modes.find(m => m.id == modeId);
        return mode.fields.find(f => f.fieldId == fieldId);
    }

    getConfiguration(fieldContent: ITextFieldContent): ITextFieldConfiguration {
        return this.totemDisplayConfiguration.fields.find(f => f.id === fieldContent.fieldId)
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.hasChanged;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint) return;

        // We bind on a clone, so we can always reset to original
        this.totemDisplayConfiguration = lodash.cloneDeep(this.measuringPoint.totemDisplayConfiguration);
        this.hasChanged = false;
    }

    async reset() {
        this.initialize();
    }

    get submitDisabled() {
        let hasErrors = false;
        if (this.inputs) {
            this.inputs.forEach(input => {
                if (input.invalid) {
                    hasErrors = true;
                }
            });
        }
        return hasErrors
    }

    async submit() {
        if (this.submitDisabled) {
            this.toastService.warning("Please correct the errors before saving.");
            return;
        }
        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);
        Object.assign(measuringPointUpdater.totemDisplayConfiguration, this.totemDisplayConfiguration);

        this.textFields.forEach(textField => {
            textField.configuration = this.getConfiguration(textField.content);
            textField.loadViewModel(textField.content.frames);
        });

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }

    configureFields() {
        const onSubmit = (savedMeasuringPoint: IMeasuringPoint) => {
            this.textFields.forEach(textField => {
                textField.configuration = this.getConfiguration(textField.content);
                textField.loadViewModel(textField.content.frames);
            });
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.initialize();
        }

        this.fieldsDialog.create(this.measuringPoint, onSubmit);
    }

    getModeOptions(): string[] {
        return this.totemDisplayConfiguration?.modeConfiguration?.modes?.map(x => x.id);
    }

    getField(id: number): ITextFieldConfiguration {
        return this.totemDisplayConfiguration?.fields?.find(x => x.id === id);
    }

    getMode(id: string): ITotemDisplayMode {
        return this.totemDisplayConfiguration?.modeConfiguration?.modes?.find(x => x.id === id);
    }

    getFieldHeight(id: number): number {
        const field = this.getField(id);
        return field.lineCount;
    }

    addMode() {
        function createDefaultTextFieldContent(fieldId: number): ITextFieldContent {
            return {
                fieldId,
                frames: [{ text: "text", displayTimeMs: 1000 }]
            } as ITextFieldContent;
        }

        const newMode = {
            id: "id",
            description: "description",
            fields: this.totemDisplayConfiguration.fields.map(f => createDefaultTextFieldContent(f.id)),
        } as ITotemDisplayMode;

        this.totemDisplayConfiguration.modeConfiguration.modes.push(newMode);
        this.hasChanged = true;
    }

    removeMode(mode: ITotemDisplayMode) {
        this.totemDisplayConfiguration.modeConfiguration.modes = this.totemDisplayConfiguration.modeConfiguration.modes.remove(mode);
        this.hasChanged = true;
    }

    openContextMenu(event: MouseEvent, fieldContent: ITextFieldContent, frame: ITextFrame, textarea: ElementRef) {
        if (this.contextMenuShown) return;
        this.currentTextFieldContent = fieldContent;
        this.currentFrame = frame;
        this.currentTextArea = textarea;

        const element = event.target as Element;
        this._popper = createPopper(element, this.contextMenu.nativeElement, { placement: "bottom-start" });
        this.renderer.setStyle(this.contextMenu.nativeElement, "display", "block");
        this.contextMenuShown = true;
        event.stopPropagation(); // This prevents the onDocumentClick to trigger immediately and close the menu
    }

    closeContextmenu() {
        if (!this.contextMenuShown) return;
        this._popper?.destroy();
        this.renderer.setStyle(this.contextMenu.nativeElement, "display", "none");
        this.contextMenuShown = false;
    }

    // Close context menu when user clicks outside
    @HostListener("document:click", ["$event"])
    onDocumentClick(event: MouseEvent) {
        if (!this.contextMenuShown) {
            return;
        }

        const clickedInside = this.contextMenu.nativeElement.contains(event.target as Node);
        if (!clickedInside) {
            this.closeContextmenu();
        }
    }

    insertInTextContent(text: string) {
        const element = this.currentTextArea as any;
        const currentPosition = element.selectionStart;

        console.log(element.selectionStart + " " + element.selectionEnd);

        this.currentFrame.text =
            this.currentFrame.text.substring(0, currentPosition) +
            text +
            this.currentFrame.text.substring(element.selectionEnd);

    }

    addDate() {
        if (!this.currentFrame) return;
        this.insertInTextContent("{{date}}");
        this.closeContextmenu();
    }

    addTime() {
        if (!this.currentFrame) return;
        this.insertInTextContent("{{time}}");
        this.closeContextmenu();
    }

    addTemperature() {
        if (!this.currentFrame) return;
        this.insertInTextContent("{{temp}}");
        this.closeContextmenu();
    }

    addDayCounter() {
        if (!this.currentFrame) return;

        this.insertInTextContent(`{{dayCounter,${this.getField(this.currentTextFieldContent.fieldId).lineLength}}}`);
        this.closeContextmenu();
    }

    addMonthCounter() {
        if (!this.currentFrame) return;

        this.insertInTextContent(`{{monthCounter,${this.getField(this.currentTextFieldContent.fieldId).lineLength}}}`);
        this.closeContextmenu();
    }

    addYearCounter() {
        if (!this.currentFrame) return;

        this.insertInTextContent(`{{yearCounter,${this.getField(this.currentTextFieldContent.fieldId).lineLength}}}`);
        this.closeContextmenu();
    }

    async inputChanged(modeId: string, fieldContent: ITextFieldContent, appTextFieldAnimation: TextFieldAnimationComponent) {
        this.hasChanged = true;

        const content = this.getContent(modeId, fieldContent.fieldId);
        await appTextFieldAnimation.loadViewModel(content.frames);
    }

    async addFrame(fieldContent: ITextFieldContent, appTextFieldAnimation: TextFieldAnimationComponent, modeId: string) {
        const frame = {
            text: "text",
            displayTimeMs: 1000
        } as ITextFrame;
        fieldContent.frames.push(frame);
        this.hasChanged = true;

        const content = this.getContent(modeId, fieldContent.fieldId);
        await appTextFieldAnimation.loadViewModel(content.frames);
    }

    async removeFrame(fieldContent: ITextFieldContent, frame: ITextFrame, appTextFieldAnimation: TextFieldAnimationComponent, modeId: string) {
        fieldContent.frames = fieldContent.frames.remove(frame);
        this.hasChanged = true;

        const content = this.getContent(modeId, fieldContent.fieldId);
        await appTextFieldAnimation.loadViewModel(content.frames);
    }
}
