<div class="m-layout-w-actions-top m-fixed-height m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="canEditWorker"
                id="createWorkerButton">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"worker.create" | translate}}
        </button>
        <p-inputSwitch inputId="includeOutOfServiceSwitch"
                       class="m-switch"
                       [(ngModel)]="workersComponent.showOutOfService"
                       (onChange)="updateComponent()"></p-inputSwitch>
        <label class="m-switch-label"
               for="includeOutOfServiceSwitch">{{"worker.showOutOfService" | translate}}</label>
    </div>

    <app-workers [paginator]="true"
                 class="m-layout-area-body m-border-top"
                 [deleteCommand]="true"
                 [editCommand]="true"></app-workers>
</div>

<app-manage-worker-dialog></app-manage-worker-dialog>