<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          id="scheduleOnHoldTasksDialog">

    <p-header>
        {{"recurringTasks.scheduleTasks" | translate}}
    </p-header>

    <form [formGroup]="scheduledTaskForm"
          class="m-layout-area-body m-overflow-auto p-16"
          *ngIf="scheduledTaskForm"
          autocomplete="off">
        <div class="m-layout-area-body p-16">
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': scheduledTaskForm.get('availableFrom').showErrors }">
                <label class="col-4"
                       style="align-self:center">
                    {{"recurringTasks.availableFrom" | translate}}
                </label>

                <div class="col-8">
                    <p-calendar id="availableFrom"
                                formControlName="availableFrom"
                                selectionMode="single"
                                autoWidth="false"
                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                [dateFormat]="calendarSettings.dateFormat"
                                [yearRange]="primeComponentService.calendarYearRange"
                                [showIcon]="true"
                                [monthNavigator]="true"
                                [yearNavigator]="true"
                                [numberOfMonths]="1"
                                [minDate]="currentDate"
                                showButtonBar="true"
                                appendTo="body"
                                #availableFromInput>
                    </p-calendar>

                    <app-form-validation [control]="scheduledTaskForm.get('availableFrom')"
                                         [input]="availableFromInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': scheduledTaskForm.get('workerIds').showErrors }">
                <label class="col-4"
                       style="align-self:center">
                    {{"task.workerTasks" | translate}}
                </label>

                <div class="col-8">
                    <p-multiSelect appendTo="body"
                                   id="workerIds"
                                   placeholder="{{'recurringTasks.selectResponsibleWorkers' | translate}}"
                                   [style]="{'width':'100%'}"
                                   [options]="workers"
                                   [filter]="true"
                                   [showHeader]="true"
                                   [showToggleAll]="false"
                                   formControlName="workerIds"
                                   #workerIdsInput>
                    </p-multiSelect>

                    <app-form-validation [control]="scheduledTaskForm.get('workerIds')"
                                         [input]="workerIdsInput">
                    </app-form-validation>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>