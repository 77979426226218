<div class="row"
     [ngClass]="{ 'has-error': submitted && !image && required }">
    <label class="col-{{labelColSize}}">
        {{"signScan.photo" | translate}}
    </label>

    <div class="col-{{colSize}}">
        <label for="fileInput"
               class="p-inputtext m-input-file m-input-group-w-icon">
            <span class="label mr-16">
                <ng-container *ngIf="image">
                    {{image.name}}
                </ng-container>
                <ng-container *ngIf="!image">
                    {{"general.selectFile" | translate}}
                </ng-container>
            </span>

            <app-svg-icon icon="upload"
                          class="ml-auto">
            </app-svg-icon>

            <input type="file"
                   id="fileInput"
                   accept="image/*"
                   #file
                   onclick="value = null"
                   (change)="setImage(file)" />
        </label>

        <div class="m-preview m-preview-sm mt-8"
             *ngIf="imagePreview"
             style="display: inline-flex;">
            <img [attr.src]="imagePreview | safeHtml"
                 (click)="popup && onImageClick()"
                 [ngStyle]="{'cursor': popup ? 'pointer' : 'unset'}" />

            <app-icon-button *ngIf="(attachment || submitted) && imagePreview"
                             icon="delete"
                             (click)="setPhotoDeleted()">
            </app-icon-button>
        </div>

        <div *ngIf="submitted && !image && required"
             class="error">
            {{"validation.required" | translate}}
        </div>
    </div>
</div>
<app-image-dialog></app-image-dialog>