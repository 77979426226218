import { IRelease, ReleaseCreator, ReleaseUpdater } from "src/app/models/release";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { ReleaseApi } from "src/app/resource/release.api";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-release-dialog",
    templateUrl: "./manage-release.dialog.html"
})
export class ManageReleaseDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IRelease) => void;
    manageReleaseForm: SigncoFormGroup;
    existingRelease: IRelease;
    private packageId: string;
    file: File;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly releaseApi: ReleaseApi) {

        super();
    }

    create(packageId: string, callback?: (res: IRelease) => void) {
        this.packageId = packageId;
        this.callback = callback;

        this.openDialog();
    }

    edit(existing: IRelease, callback?: (res: IRelease) => void) {
        this.packageId = existing.packageId;
        this.existingRelease = existing;
        this.callback = callback;

        this.openDialog();
    }

    protected onOpen() {
        this.manageReleaseForm = this.formBuilder.group({
            version: ["", Validators.required],
            description: ["", Validators.required]
        }) as SigncoFormGroup;

        if (this.existingRelease) {
            this.manageReleaseForm.patchValue(this.existingRelease);
        }
    }

    protected onClose() {
        this.manageReleaseForm = null;
        this.file = null;
        this.submitting = false;
    }

    setFile(event: { files: FileList }) {
        if (!event || event.files.length < 0) return;
        this.file = event.files[0];
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageReleaseForm);
        if (!isValid) return;

        // If not updating and no file attached, it's not valid
        if (!this.existingRelease && !this.file) return;

        this.submitting = true;

        const onSuccess = (savedRelease: IRelease) => {
            if (this.existingRelease) {
                Object.assign(this.existingRelease, savedRelease);
            }

            if (this.callback) {
                this.callback(savedRelease);
            }

            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingRelease) {
            const releaseCreator = Object.assign(new ReleaseCreator(), this.manageReleaseForm.value);
            releaseCreator.packageId = this.packageId;
            this.releaseApi.upload$(releaseCreator, this.file).subscribe(onSuccess, onError);
        } else {
            const releaseUpdater = Object.assign(new ReleaseUpdater(this.existingRelease), this.manageReleaseForm.value);
            this.releaseApi.update$(releaseUpdater).subscribe(onSuccess, onError);
        }
    }
}