import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { AttachmentContext } from "src/app/models/attachment";
import { AttachmentsComponent } from "src/app/modules/attachments/components/attachments/attachments.component";
import { GalleriaPreviewer, IGalleriaImage } from "src/app/modules/shared/components/galleria/galleria.component";
import { GalleriaService } from "src/app/services/galleria.service";
import { FileUtils } from "src/app/utilities";

@Component({
    selector: "app-assignment-attachments",
    templateUrl: "./assignment-attachments.component.html"
})
export class AssignmentAttachmentsComponent {

    @ViewChild(AttachmentsComponent, { static: false }) attachmentsComponent: AttachmentsComponent;

    @Input() attachmentContext: AttachmentContext;

    images: IGalleriaImage[];

    constructor(
        elementRef: ElementRef,
        private galleriaService: GalleriaService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
    }

    openPhotoGallery() {
        this.galleriaService.open(this.images, 0);
    }

    onDataSet() {
        if (!this.attachmentsComponent || !this.attachmentsComponent.data) {
            this.images = [];
            return;
        }

        this.images = this.attachmentsComponent.data
            .filter(p => FileUtils.isPhotoUrl(p.url))
            .map(a =>
                ({ source: a.url, previewImageSrc: a.preview || a.url, title: a.name, alt: a.name, previewer: GalleriaPreviewer.Photo }) as IGalleriaImage);
    }
}
