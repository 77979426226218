<div class="m-border-top">
    <div class="mt-8">
        <span>{{"scenarios.scenario" | translate}}:
            <ng-container *ngFor="let scenario of scenarios">
                <a class="m-navigator"
                   [routerLink]="['/scenarios/scenarios', scenario.id]"
                   target="_blank">{{scenario.code}}</a>
            </ng-container>
        </span>
    </div>
</div>