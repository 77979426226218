import { NgModule } from "@angular/core";
import { MapBasicModule } from "../map-basic/map-basic.module";
import { LocationFormComponent } from "./components/location-form/location-form.component";
import { LocationDialogContentComponent } from "./components/location-dialog/location-dialog-content/location-dialog-content.component";
import { SharedModule } from "../shared/shared.module";
import { LocationDialogComponent } from "./components/location-dialog/location.dialog";
import { LocationDetailComponent } from "./components/location-detail/location-detail.component";

@NgModule({
    imports: [
        MapBasicModule,
        SharedModule,
    ],
    providers: [
    ],
    declarations: [
        LocationFormComponent,
        LocationDialogContentComponent,
        LocationDialogComponent,
        LocationDetailComponent,
    ],
    exports: [
        LocationFormComponent,
        LocationDialogComponent,
        LocationDetailComponent,
    ],
})
export class LocationSharedModule {
}