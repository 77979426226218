import { Injectable } from "@angular/core";
import { ApiServiceBase } from "./api";
import { Observable } from "rxjs";
import { CountingZoneAccessStatus } from "../models/pinned-data";

export class AddVehicleData {
    vehicles: string[] = [];
}

export class RemoveVehicleData {
    vehicles: string[] = [];
}

export class ForceAccessData {
    accessStatus: CountingZoneAccessStatus;
}

@Injectable({
    providedIn: "root"
})
export class CountingZoneApi extends ApiServiceBase {

    getRoute(): string {
        return "CountingZones";
    }

    addVehicles(id: number, data: AddVehicleData): Observable<void> {
        const url = `${super.getUrl()}/${id}/AddVehicles`;
        return this.http.post<void>(url, data);
    }

    removeVehicles(id: number, data: RemoveVehicleData): Observable<void> {
        const url = `${super.getUrl()}/${id}/RemoveVehicles`;
        return this.http.post<void>(url, data);
    }

    forceAccess(id: number, data: ForceAccessData): Observable<void> {
        const url = `${super.getUrl()}/${id}/ForceAccess`;
        return this.http.post<void>(url, data);
    }
}