import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { WebsiteService } from "src/app/services/website.service";


@Component({
    selector: "app-landing-page",
    templateUrl: "./landing-page.component.html",
    styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
    private readonly globalEventService = inject(GlobalEventsService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly router = inject(Router);
    protected readonly websiteService = inject(WebsiteService);

    protected isLoading = true;

    ngOnInit(): void {
        this.globalEventService.isAuthenticated$.subscribe(isAuthenticated => {
            if (isAuthenticated === null) return; // We always get an initial null value

            if (isAuthenticated === true) {
                this.router.navigate(["locations"]);
            }
            else {
                // This will show the login message
                this.isLoading = false;
            }
        });
    }

    protected login() {
        this.authenticationService.login();
    }
}
