<div class="m-livetile"
     ngDraggable
     [handle]="headerElement"
     [position]="viewModel.displayOptions.position"
     [preventDefaultEvent]="true"
     (stopped)="onDragStop()"
     (movingOffset)="onMove()"
     [ngStyle]="ngStyle"
     #pinDivElement>
    <header #headerElement
            class="m-livetile-header"
            style="display:grid; grid-template-columns: auto min-content;"
            [pTooltip]="!viewModel.displayOptions.isExpanded ? viewModel.measuringPoint.code : null">

        <div style="min-width: 16px; position: relative;"
             #titleElement>
            <h4 *ngIf="!viewModel.displayOptions.isExpanded"
                style="position: absolute; top:-6px; left:0px; white-space:nowrap;">{{shortTitle}}</h4>
            <h4 *ngIf="viewModel.displayOptions.isExpanded">{{viewModel.measuringPoint.code}}</h4>
        </div>

        <div style="display:flex;">
            <app-icon-button [icon]="viewModel.displayOptions.isExpanded ? 'minimize' : 'maximize'"
                             (click)="toggleExpand(); stop($event);"></app-icon-button>

            <app-icon-button *ngIf="!overlay"
                             icon="pin"
                             (click)="toOverlay(); stop($event);"></app-icon-button>

            <app-icon-button *ngIf="overlay"
                             icon="pin"
                             (click)="toFloating(); stop($event);"></app-icon-button>

            <app-icon-button icon="close"
                             (click)="close(); stop($event);"></app-icon-button>

        </div>

    </header>

    <div class="m-livetile-body"
         style="padding: 0px 8px 8px 8px;"
         id="dialogContentDiv"
         [ngClass]="showCamera || pinnedDataService.isHistoricalDataAvailable(viewModel?.pinnedDataConfiguration) ? 'm-livetile-body-resizable' : ''"
         #dialogContentDiv>

        <app-loading-spinner *ngIf="loading"></app-loading-spinner>

        <!-- External Inputs -->
        <ng-container *ngIf="liveData?.current?.externalInput">
            <app-external-input-pinned-data [externalInput]="liveData.current.externalInput"
                                            [measuringPointId]="viewModel?.measuringPoint.id"
                                            [isExpanded]="viewModel.displayOptions.isExpanded">
            </app-external-input-pinned-data>
        </ng-container>

        <!-- TotemDisplay -->
        <ng-container *ngIf="liveData?.current?.totemDisplay">
            <app-totem-display-pinned-data [totemDisplay]="liveData.current.totemDisplay"
                                           [measuringPointId]="viewModel?.measuringPoint.id"
                                           [isExpanded]="viewModel.displayOptions.isExpanded">
            </app-totem-display-pinned-data>
        </ng-container>

        <!-- LightAction -->
        <ng-container *ngIf="liveData?.current?.light">
            <app-light-pinned-data [measuringPointId]="viewModel?.measuringPoint.id"
                                   [data]="liveData.current.light"
                                   [isExpanded]="viewModel.displayOptions.isExpanded">
            </app-light-pinned-data>
        </ng-container>

        <!-- VMS -->
        <ng-container *ngIf="liveData?.current?.vms">
            <app-vms-pinned-data [measuringPointId]="viewModel?.measuringPoint.id"
                                 [data]="liveData.current.vms"
                                 [vmsStatus]="liveData.current.vms?.status"
                                 [isExpanded]="viewModel.displayOptions.isExpanded">
            </app-vms-pinned-data>
        </ng-container>

        <!-- Counting zone -->
        <ng-container *ngIf="liveData?.current?.countingZone">
            <app-counting-zone-pinned-data [countingZone]="liveData.current.countingZone"
                                           [measuringPointId]="viewModel.measuringPoint.id">
            </app-counting-zone-pinned-data>
        </ng-container>

        <!-- Workzone -->
        <ng-container *ngIf="liveData?.current?.workzone">
            <app-workzone-pinned-data [workzone]="liveData.current.workzone"
                                      [measuringPointId]="viewModel.measuringPoint.id">
            </app-workzone-pinned-data>
        </ng-container>

        <!-- Traffic light -->
        <ng-container *ngIf="liveData?.current?.trafficLight">
            <app-traffic-light-pinned-data [status]="liveData.current.trafficLight"
                                           [measuringPointId]="viewModel.measuringPoint.id"></app-traffic-light-pinned-data>
        </ng-container>

        <!-- live and historical data -->
        <ng-container *ngIf="pinnedDataService.isHistoricalDataAvailable(viewModel?.pinnedDataConfiguration)">
            <app-measured-data-pinned-data #measuredData
                                           (onResize)="onResize($event)"
                                           (liveDataUpdate)="onLiveDataFetched($event)"
                                           [viewModel]="viewModel">
            </app-measured-data-pinned-data>
        </ng-container>

        <!-- Camera -->
        <div *ngIf="showCamera"
             (mousedown)="$event.stop()"
             (doubleclick)="$event.stop()">
            <app-camera-controls [measuringPointId]="viewModel.measuringPoint.id"
                                 [liveData]="liveData"
                                 [frames]="5"></app-camera-controls>
        </div>

        <div *ngIf="isCctv && !hasCctvRights"
             class="m-livetile-camera-error">
            {{'liveTiles.measuredData.noCctvRights' | translate}}
        </div>

        <!-- Scenarios -->
        <ng-container *ngIf="viewModel.displayOptions.isExpanded && liveData?.current?.linkedScenarios?.length > 0">
            <app-scenarios-pinned-data [scenarios]="liveData.current.linkedScenarios">
            </app-scenarios-pinned-data>
        </ng-container>

        <!-- User messages -->
        <ng-container *ngIf="viewModel.displayOptions.isExpanded && liveData?.current?.userMessages?.length > 0">
            <app-user-messages-pinned-data [userMessages]="liveData.current.userMessages">
            </app-user-messages-pinned-data>
        </ng-container>
    </div>

    <!-- minHeight is required so resize handle can be put correctly in the bottom right corner -->
    <div class="m-livetile-footer flex align-center pl-4 pr-16 py-4"
         [ngStyle]="showCamera ? {'minHeight':'32px'} : {}"
         style="gap:4px;">
        <div class="flex align-center mr-4"
             *ngIf="liveData?.current?.power">

            <app-svg-icon icon="battery"></app-svg-icon>

            <ng-container *ngIf="liveData?.current.power?.chargePercentage; else voltTemplate">
                <span class="ml-4">{{liveData.current.power.chargePercentage}}%</span>
            </ng-container>

            <ng-template #voltTemplate>
                <ng-container *ngIf="liveData?.current.power?.batteryVoltage">
                    <span class="ml-4">{{liveData.current.power.batteryVoltage | number:'1.1-1'}}{{"measurements.volt" | translate}}</span>
                </ng-container>
            </ng-template>
        </div>

        <p *ngIf="liveData?.lastUpdate && viewModel.displayOptions.isExpanded"
           pTooltip="{{'liveTiles.measuredData.lastUpdate' | translate}}">
            {{liveData.lastUpdate | momentDateTime:false:false:translateService.currentLang}} </p>
        <p *ngIf="liveData?.lastUpdate && !viewModel.displayOptions.isExpanded"
           pTooltip="{{'liveTiles.measuredData.lastUpdate' | translate}}">
            {{liveData.lastUpdate | shortDateTime:false:translateService.currentLang}} </p>

        <div class="m-btn-group ml-auto">
            <app-icon-button icon="cameraBis"
                             *ngIf="pinnedDataService.isHistoricalDataAvailable(viewModel?.pinnedDataConfiguration)"
                             (click)="takeScreenshot()"
                             text="liveTiles.measuredData.screenshot"></app-icon-button>

            <app-icon-button *ngIf="showRefreshCameraImage"
                             icon="refresh"
                             (click)="refreshCameraImage(); stop($event);"></app-icon-button>
        </div>

        <ng-container *ngIf="liveData?.current?.isSimulated">
            <div class="warning ml-8">
                <app-svg-icon icon="error"
                              fill="darkorange"
                              [tooltip]="'liveTiles.measuredData.simulationWarning' | translate"></app-svg-icon>
            </div>
        </ng-container>
    </div>
</div>