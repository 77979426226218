import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ArrowBoardDirection, ArrowBoardPosition } from "src/app/models/device";
import { AnalysisType, IMeasuringPoint } from "src/app/models/measuring-point";
import { IMeasuringPointArrowBoardHistory } from "src/app/models/measuring-point-arrow-board-history";
import { FilterDescriptor, FilterOperator, SearchParameters } from "src/app/models/search";
import { TimelineConfig, TimelineData } from "src/app/modules/shared/components/timeline-activity/timeline-activity.component";
import { MeasuringPointArrowBoardHistoryApi } from "src/app/resource/measuring-point-arrow-board-history.api";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-measuring-point-arrow-board-timeline",
    templateUrl: "./measuring-point-arrow-board-timeline.component.html"
})
export class MeasuringPointArrowBoardTimelineComponent implements OnChanges {
    @Input() measuringPoint: IMeasuringPoint;
    @Input() from: Date;
    @Input() until: Date;

    protected timelineConfig: TimelineConfig;
    protected measuringPointArrowBoardHistory: IMeasuringPointArrowBoardHistory[];

    constructor(
        private readonly domainDataService: DomainDataService,
        private readonly measuringPointArrowBoardHistoryApi: MeasuringPointArrowBoardHistoryApi) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        const fromChange = changes["from"];
        const untilChange = changes["until"];
        if (measuringPointChange || fromChange || untilChange) {
            this.loadData();
        }
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.loadData();
    }

    async canLoad(): Promise<boolean> {
        return !!this.measuringPoint && this.measuringPoint.analysisTypeId === AnalysisType.ArrowBoard;
    }

    private getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {};
        searchParameters.filter = [];

        if (this.measuringPoint) {
            searchParameters.queryParams["measuringPointId"] = this.measuringPoint.id;
        }

        if (this.from) {
            searchParameters.filter.push(new FilterDescriptor("timestamp", this.from, FilterOperator.greaterThanOrEqualTo));
        }

        if (this.until) {
            searchParameters.filter.push(new FilterDescriptor("timestamp", this.until, FilterOperator.lessThanOrEqualTo));
        }

        return searchParameters;
    }

    getRouteParams(): { [index: string]: string } {
        if (this.measuringPoint) return { measuringPointId: this.measuringPoint.id.toString() };
        return null;
    }

    private async loadData() {
        if (!this.canLoad()) return;

        this.measuringPointArrowBoardHistory = null;
        this.timelineConfig = null;

        const measuringPointArrowBoardHistorySearchResult = await firstValueFrom(this.measuringPointArrowBoardHistoryApi.search$(this.getSearchParameters(), null, null, this.getRouteParams()));
        this.measuringPointArrowBoardHistory = measuringPointArrowBoardHistorySearchResult.data;
        this.createTimeline();
    }

    private createTimeline() {
        if (!this.measuringPoint) return;
        if (!this.measuringPointArrowBoardHistory?.length) return;

        const timelineConfig = new TimelineConfig();
        timelineConfig.data = [];

        const getDescription = (measuringPointArrowBoardHistory: IMeasuringPointArrowBoardHistory): string => {
            // If it's open and has a valid direction (not 'none'), it's open
            // Else it's closed
            if (measuringPointArrowBoardHistory.data.position === ArrowBoardPosition.Open &&
                measuringPointArrowBoardHistory.data.direction !== ArrowBoardDirection.None) {
                return this.domainDataService.translateEnum("arrowBoardDirection", measuringPointArrowBoardHistory.data.direction)
            }

            // Force closed, sometimes we get an "open" with direction "none", this is still closed
            // Anything that gets here, is closed
            return this.domainDataService.translateEnum("arrowBoardPosition", ArrowBoardPosition.Closed /*measuringPointArrowBoardHistory.data.position*/);
        };

        const createTimelineData = (measuringPointArrowBoardHistory: IMeasuringPointArrowBoardHistory): TimelineData => {
            const timelineData = new TimelineData();
            timelineData.description = getDescription(measuringPointArrowBoardHistory);
            // timelineData.groupField = "ouiouimonsieur"; // They all show on same line
            timelineData.startDate = measuringPointArrowBoardHistory.timestamp;
            // timelineData.color = ???;

            return timelineData;
        };

        let timelineData: TimelineData = null;
        for (const sortedHistory of this.measuringPointArrowBoardHistory.sortBy(x => x.timestamp.getTime())) {
            if (timelineData) {
                if (timelineData.description === getDescription(sortedHistory)) continue; // keep going
                // "close" the previous on start of new
                timelineData.endDate = sortedHistory.timestamp;
                timelineConfig.data.push(timelineData);
            }

            timelineData = createTimelineData(sortedHistory);
        }

        // "close" current active one, it's "until now" or until search range
        if (timelineData) {
            timelineData.endDate = (this.until.toMidnight().getTime() === new Date().toMidnight().getTime()) ? new Date() : this.until.addDays(1).toMidnight();
            timelineConfig.data.push(timelineData);
        }

        this.timelineConfig = timelineConfig;
    }
}