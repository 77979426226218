import { TableService, LazyTableComponent, TableColumn, FilterType, ColumnType, ColumnVisibility } from "src/app/modules/shared/components/table/table.component";
import { Component, OnInit, OnChanges, ElementRef, Input, SimpleChanges, OnDestroy } from "@angular/core";
import { DomainDataService, DomainData, ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { TranslateService } from "@ngx-translate/core";
import { AuditTrailsApi } from "src/app/resource/audit-trails-api";
import { IOrganization } from "src/app/models/user";
import { ISearchResult } from "src/app/models/search";
import { IAssignment } from "src/app/models/assignment";
import { AuditTrailExportOptions, AuditTrailRelatedObjectType, IAuditTrail } from "src/app/models/audit-trail";
import { IProject } from "src/app/models/project";
import { IDevice } from "src/app/models/device";
import { DownloadedFile } from "src/app/services/download-file.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-audit-trails",
    templateUrl: "./audit-trails.component.html"
})
export class AuditTrailsComponent extends LazyTableComponent<IAuditTrail> implements OnInit, OnChanges, OnDestroy {
    @Input() measuringPoint: IMeasuringPoint;
    @Input() device: IDevice;
    @Input() organization: IOrganization;
    @Input() project: IProject;
    @Input() assignment: IAssignment;
    @Input() allowWithoutInput = false;
    @Input() markAsAdminCommand = false;

    isExportInProgress = false;
    canViewAdminOnlyAuditTrail = false;

    constructor(
        elementRef: ElementRef,
        auditTrailsApi: AuditTrailsApi,
        tableService: TableService,
        readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService,
        private readonly globalEventsService: GlobalEventsService) {

        super("audit-trails.component", elementRef, auditTrailsApi, tableService);

        this.stretchHeight = true;

        this.updateSelectionMode();

    }

    ngOnInit() {
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canViewAdminOnlyAuditTrail = rights?.hasBackendRight(BackendRights.ViewAdminOnlyAuditTrail);
            this.createColumns();
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    private potentialRelatedObjects = [
        "device",
        "location",
        "measuringPoint",
        "organization",
        "user",
    ];

    getRelatedObject(auditTrail: IAuditTrail) {
        return this.potentialRelatedObjects.find(potentialRelatedObject => auditTrail[potentialRelatedObject] !== undefined);
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        const organizationChange = changes["organization"];
        if (organizationChange) {
            this.setOrganization(this.organization);
        }

        const projectChange = changes["project"];
        if (projectChange) {
            this.setProject(this.project);
        }

        const assignmentChange = changes["assignment"];
        if (assignmentChange) {
            this.setAssignment(this.assignment);
        }

        const markAsAdminCommandChange = changes["markAsAdminCommand"];
        if (markAsAdminCommandChange) {
            this.updateSelectionMode();
        }

        super.ngOnChanges(changes);
    }

    private updateSelectionMode() {
        this.selectionMode = this.markAsAdminCommand && this.canViewAdminOnlyAuditTrail ? "multiple" : "";
    }

    private clear() {
        this.measuringPoint = null;
        this.device = null;
        this.organization = null;
        this.project = null;
        this.assignment = null;
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.clear();
        this.measuringPoint = measuringPoint;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setDevice(device: IDevice) {
        this.clear();
        this.device = device;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setOrganization(organization: IOrganization) {
        this.clear();
        this.organization = organization;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setProject(project: IProject) {
        this.clear();
        this.project = project;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setAssignment(assignment: IAssignment) {
        this.clear();
        this.assignment = assignment;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    private createColumns() {
        this.clearColumns();

        // Filter out the organization and relatedObject columns when we're handling a specific context
        const filterFunc = (): boolean => {
            return (!this.device && !this.measuringPoint && !this.organization);
        };

        const eventTypeColumn = new TableColumn("eventType", "auditTrails.eventType", { sortable: false, displayDropdownFilter: true, filterType: FilterType.MultiSelect });
        eventTypeColumn.filterMatchMode = "in";

        this.domainDataService.get(DomainData.AuditTrailEventType).then(result => {
            eventTypeColumn.filterOptions = result;
        });

        const organizationColumn = new TableColumn("organization", "general.organization", { sortable: false, visibility: ColumnVisibility.HideCompact, width: 250, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), filterFunc: filterFunc });
        organizationColumn.displayDropdownFilter = true;

        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        const relatedObjectTypeColumn = new TableColumn("relatedObjectType", "auditTrails.relatedObjectType");
        const relatedObjectTypeOptions = { includeEmpty: true, emptyLabel: "general.emptyFilterValue", addEmptyOptionAtEnd: true, emptyOptionValue: "" } as ViewModelEnumOptions;
        this.domainDataService.get(DomainData.AuditTrailRelatedObjectType, relatedObjectTypeOptions).then(auditTrailRelatedObjectTypes => {
            relatedObjectTypeColumn.filterOptions = auditTrailRelatedObjectTypes;
        });

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("userId", "general.user", { visibility: ColumnVisibility.HideCompact, sortable: false }));
        this.addColumn(organizationColumn);
        this.addColumn(relatedObjectTypeColumn);
        this.addColumn(new TableColumn("relatedObjectId", "auditTrails.relatedObjectId"));
        this.addColumn(new TableColumn("timeStamp", "auditTrails.timestamp", { sortable: false, filterType: FilterType.Date, width: 190, resizable: false }));
        this.addColumn(eventTypeColumn);
        this.addColumn(new TableColumn("summary", "words.summary", { filterType: FilterType.None, sortable: false }));
    }

    getRouteParams(): { [index: string]: string } {
        if (this.device) return { type: "devices", id: this.device.id.toString() };
        if (this.measuringPoint) return { type: "measuringPoints", id: this.measuringPoint.id.toString() };
        if (this.organization) return { type: "organizations", id: this.organization.id.toString() };
        if (this.project) return { type: "projects", id: this.project.id.toString() };
        if (this.assignment) return { type: "assignments", id: this.assignment.id.toString() };
        return null;
    }

    canLoad(): boolean {
        return this.allowWithoutInput || !!this.measuringPoint || !!this.device || !!this.organization || !!this.project || !!this.assignment;
    }

    exportToExcel(): void {
        const onDownload = (downloadedFile: DownloadedFile) => {
            this.isExportInProgress = false;
            downloadedFile.save();
        };

        const onError = () => {
            this.isExportInProgress = false;
        };

        this.isExportInProgress = true;
        (<AuditTrailsApi>this.api).getAuditTrailsExportToExcel(this.getAuditTrailsExportOptions()).then(onDownload, onError);
    }

    private getAuditTrailsExportOptions(): AuditTrailExportOptions {
        if (this.device) {
            return {
                objectId: this.device.id,
                type: AuditTrailRelatedObjectType.Device
            };
        }

        if (this.measuringPoint) {
            return {
                objectId: this.measuringPoint.id,
                type: AuditTrailRelatedObjectType.MeasuringPoint
            };
        }

        if (this.organization) {
            return {
                objectId: this.organization.id,
                type: AuditTrailRelatedObjectType.Organization
            };
        }

        if (this.project) {
            return {
                objectId: this.project.id,
                type: AuditTrailRelatedObjectType.Project
            };
        }

        if (this.assignment) {
            return {
                objectId: this.assignment.id,
                type: AuditTrailRelatedObjectType.Assignment
            };
        }

        return null;
    }

    protected async onSuccess(result: ISearchResult<IAuditTrail>) {
        await super.onSuccess(result);

        // onSuccess() is invoked after a successful search; make sure all previously expanded rows are collapsed now
        this.collapseAllRows();
    }


}
