<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                *ngIf="canCreateDeviceLink"
                (click)="startMeasurement()"
                id="start_measurement_button">
            <app-svg-icon icon="links">
            </app-svg-icon>
            {{'manageLink.startMeasurement' | translate}}
        </button>

        <button class="m-btn m-btn-secondary"
                *ngIf="canCreateDeviceLink && canMoveToDepot"
                (click)="moveToDepot()">
            <app-svg-icon icon="depot">
            </app-svg-icon>
            {{'manageLink.deviceToDepot' | translate}}
        </button>

        <button class="m-btn m-btn-secondary"
                *ngIf="canCreateDeviceLink && canReplaceDevice"
                (click)="replaceDevice()">
            <app-svg-icon icon="device-">
            </app-svg-icon>
            {{'manageLink.replaceDevice' | translate}}
        </button>
    </div>

    <app-links [measuringPoint]="measuringPoint"
               class="m-border-top"></app-links>
</div>