import { Component, inject, Input } from "@angular/core";
import { ArrowBoardDirection, ArrowBoardPosition } from "src/app/models/device";
import { MapIconActivityState } from "src/app/models/map-icon";
import { ILocationStatusSummary, IMeasuringPointStatusSummary } from "src/app/models/web";
import { MapIconService } from "src/app/services/map-icon-service";

@Component({
    selector: "app-arrow-board-tile",
    templateUrl: "./arrow-board-tile.component.html",
    styleUrls: ["./arrow-board-tile.component.scss", "../../location-popup.component.scss"],
})
export class ArrowBoardTileComponent {
    @Input() locationStatusSummary: ILocationStatusSummary;
    @Input() data: IMeasuringPointStatusSummary;

    private readonly mapIconService = inject(MapIconService);

    protected getIconUrl(direction: ArrowBoardDirection, position: ArrowBoardPosition): string {
        if (!direction || !position) return;

        if (this.data.mapIconStateId) {
            const icon = this.mapIconService.getIconState(this.data.mapIconStateId);
            return icon.defaultIcon.url;
        } else {
            const icon = this.mapIconService.getArrowBoardIcon(direction, position);
            return icon.states.filter(x => x.alarmSeverityId == null && x.alertLevelId == null
                && x.activityStateId == MapIconActivityState.Active).takeFirstOrDefault()?.default?.url;
        }
    }

}