import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective } from "@angular/forms";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-traffic-light-link",
    templateUrl: "./device-link-traffic-light-link.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkTrafficLightLinkComponent extends DeviceLinkFormGroupComponent {

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.trafficLightLink) {
            if (this.fgd.form.get("trafficLightLink")) this.fgd.form.removeControl("trafficLightLink");

            this.form = this.formBuilder.group({
                id: null,
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.trafficLightLink);
            this.fgd.form.addControl("trafficLightLink", this.form);
            return true;
        }

        if (this.fgd.form.get("trafficLightLink")) this.fgd.form.removeControl("trafficLightLink");
        this.form = null;
        return false;
    }
}
