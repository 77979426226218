import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { IParkingBan } from "src/app/models/parking-ban";
import { ManageParkingBanImageDialogComponent } from "../manage-parking-ban-image-dialog/manage-parking-ban-image-dialog.component";
import { ParkingBanImagesComponent } from "../parking-ban-images/parking-ban-images.component";
import { SubscriptionManager } from "src/app/utilities";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-parking-ban-images-container",
    templateUrl: "./parking-ban-images-container.component.html",
    styleUrls: []
})
export class ParkingBanImagesContainerComponent implements OnDestroy {

    @Input() parkingBan: IParkingBan;
    @ViewChild(ManageParkingBanImageDialogComponent, { static: true }) manageParkingBanImageDialogComponent: ManageParkingBanImageDialogComponent;
    @ViewChild(ParkingBanImagesComponent, { static: false }) parkingBanImagesComponent: ParkingBanImagesComponent;

    canCreateParkingBan = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly globalEventsService: GlobalEventsService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateParkingBan = rights?.hasBackendRight(BackendRights.EditParkingBan);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);


    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
    create() {
        if (!this.parkingBan) return;

        const callback = () => this.parkingBanImagesComponent.reload();
        this.manageParkingBanImageDialogComponent.create(this.parkingBan, callback);
    }

    setParkingBan(parkingBan: IParkingBan) {
        this.parkingBan = parkingBan;
    }
}
