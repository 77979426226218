interface Date {
    asUTC(): Date;
    toMetanousDateString(): string;
    addYears(years: number): Date;
    addMonths(months: number): Date;
    addDays(days: number): Date;
    addHours(hours: number): Date;
    addMinutes(minutes: number): Date;
    addMilliseconds(milliseconds: number): Date;
    toMidnight(): Date;
    toEndOfDay(): Date;
    isTheSameDateString(date: Date): boolean;
    isTheSameDateTime(date: Date): boolean;
}

Date.prototype.asUTC = function () {
    const utcDate = new Date();
    utcDate.setUTCFullYear(this.getFullYear(), this.getMonth(), this.getDate());
    utcDate.setUTCHours(this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds());
    return utcDate;
};

Date.prototype.toMetanousDateString = function () {
    return this.asUTC().toISOString().split("T")[0].replaceAll("-", "/");
};

Date.prototype.toISOString = function () {
    const d = (this as Date).asUTC();
    // 2018-06-05T00:00:00.000Z

    const pad = (number: number, targetLength: number) => {
        let output = number + "";
        while (output.length < targetLength) {
            output = "0" + output;
        }
        return output;
    };

    return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1, 2)}-${pad(d.getUTCDate(), 2)}T${pad(d.getUTCHours(), 2)}:${pad(d.getUTCMinutes(), 2)}:${pad(d.getUTCSeconds(), 2)}.${pad(d.getUTCMilliseconds(), 3)}`;
};

Date.prototype.addYears = function (years: number) {
    const returnDate = new Date(this);
    returnDate.setFullYear(returnDate.getFullYear() + years);
    return returnDate;
};

Date.prototype.addMonths = function (months: number) {
    const returnDate = new Date(this);
    returnDate.setMonth(returnDate.getMonth() + months);
    return returnDate;
};

Date.prototype.addDays = function (days: number) {
    const returnDate = new Date(this);
    returnDate.setDate(returnDate.getDate() + days);
    return returnDate;
};

Date.prototype.addHours = function (hours: number) {
    const returnDate = new Date(this);
    returnDate.setHours(returnDate.getHours() + hours);
    return returnDate;
};

Date.prototype.addMinutes = function (minutes: number) {
    const returnDate = new Date(this);
    returnDate.setMinutes(returnDate.getMinutes() + minutes);
    return returnDate;
};

Date.prototype.addMilliseconds = function (milliseconds: number) {
    const returnDate = new Date(this);
    returnDate.setMilliseconds(returnDate.getMilliseconds() + milliseconds);
    return returnDate;
};

Date.prototype.toMidnight = function () {
    const returnDate = new Date(this);
    returnDate.setHours(0);
    returnDate.setMinutes(0);
    returnDate.setSeconds(0);
    returnDate.setMilliseconds(0);
    return returnDate;
};

Date.prototype.toEndOfDay = function () {
    const returnDate = new Date(this);
    returnDate.setHours(23);
    returnDate.setMinutes(59);
    returnDate.setSeconds(59);
    returnDate.setMilliseconds(999);
    return returnDate;
};


Date.prototype.isTheSameDateString = function (date: Date) {
    return this.toDateString() === date.toDateString();
};

Date.prototype.isTheSameDateTime = function (date: Date) {
    return this.toUTCString() === date.toUTCString();
};
