import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewChild } from "@angular/core";
import { LazyTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { TextDialogComponent, TextDisplayType } from "src/app/modules/shared/components/text-dialog/text.dialog";
import { IDevice, IDeviceStatus } from "src/app/models/device";
import { TranslateService } from "@ngx-translate/core";
import { DeviceStatusApi } from "src/app/resource/device-status.api";
import { NumberUtils } from "src/app/utilities";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-status",
    templateUrl: "./device-status.component.html"
})
export class DeviceStatusComponent extends LazyTableComponent<IDeviceStatus> implements OnChanges, OnChanges {
    @ViewChild(TextDialogComponent, { static: true }) textDialog: TextDialogComponent;

    @Input() device: IDevice;
    @Input() downloadCommand = false;
    @Input() seeDeviceStatusJsonCommand = false;

    constructor(
        elementRef: ElementRef,
        deviceStatusApi: DeviceStatusApi,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        readonly translateService: TranslateService) {

        super("device-status.component", elementRef, deviceStatusApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";

        this.createColumns(null);

        this.registerCommand({
            text: "general.json",
            icon: "info",
            click: (status) => this.openRawJson(status),
            validFunc: () => this.seeDeviceStatusJsonCommand && this.rights?.hasBackendRight(BackendRights.ViewDeviceStatus)
        });

        this.registerCommand({
            text: "general.download",
            icon: "download",
            click: (status) => this.download(status),
            validFunc: () => this.downloadCommand,
            rowValidFunc: (status) => !!status.url
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(deviceChange.currentValue);
        }

        super.ngOnChanges(changes);
    }

    async processLoadedData(data: IDeviceStatus[]): Promise<IDeviceStatus[]> {
        const statusWithLotsOfData = {} as IDeviceStatus;
        for (const status of data) {
            if (status.controller && !status.controller.softwareVersion) {
                delete status.controller.softwareVersion; // string reference appears to always be null, throw it out or Object.assign will overwrite
            }

            Object.assign(statusWithLotsOfData, status);
        }

        this.createColumns(statusWithLotsOfData);
        return data;
    }

    private createColumns(status: IDeviceStatus) {
        this.clearColumns();

        this.addColumn(new TableColumn("timestamp", "general.date", { filterType: FilterType.Date, sortable: true, width: 205, resizable: false }));

        if (!status) return;
        if (status.counterStatus) {
            this.addColumn(new TableColumn("counterStatus.dayCounter", "deviceStatus.dayCounter", { resizable: true, width: 116 }));
            this.addColumn(new TableColumn("counterStatus.monthCounter", "deviceStatus.monthCounter", { resizable: true, width: 116 }));
            this.addColumn(new TableColumn("counterStatus.yearCounter", "deviceStatus.yearCounter", { resizable: true, width: 116 }));
        }

        if (status.powerStatus?.batteryVoltage) this.addColumn(new TableColumn("powerStatus.batteryVoltage", "deviceStatus.batteryVoltage", { resizable: true, width: 116 }));
        if (status.powerStatus?.chargePercentage) this.addColumn(new TableColumn("powerStatus.chargePercentage", "deviceStatus.chargePercentage", { resizable: true, width: 116 }));


        if (status.networkStatus?.signalStrength) {
            this.addColumn(new TableColumn("networkStatus.signalStrength", "deviceStatus.signalStrength", { resizable: true, width: 116 }));
        }

        if (status.controller?.versions) {
            this.addColumn(new TableColumn("controller.versions", "deviceStatus.softwareVersion", { resizable: true, width: 160 }));
        }
    }

    isValidNumber(number: number): boolean {
        return NumberUtils.isValid(number);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.loadTableRows();
    }

    openRawJson(status: IDeviceStatus) {
        this.textDialog.open("general.json", status, TextDisplayType.Json, null);
    }

    download(status: IDeviceStatus) {
        window.location.href = status.url;
    }

    canLoad(): boolean {
        return !!this.device;
    }

    getRouteParams(): { [index: string]: string } {
        return { deviceId: this.device.id.toString() };
    }
}