import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

@Component({
    selector: "app-tube-offset-dialog",
    templateUrl: "./tube-offset.dialog.html"
})
export class TubeOffsetDialogComponent extends DialogComponentBase {
    tubeOffsetForm: SigncoFormGroup;
    offset = 0;
    callback?: (offset: number) => void;

    constructor(
        private readonly formBuilder: UntypedFormBuilder) {
        super();
    }

    open(currentOffset: number = 0, onClose?: (offset: number) => void) {
        this.offset = currentOffset;
        this.callback = onClose;
        this.openDialog();
    }

    protected onOpen() {
        this.tubeOffsetForm = this.formBuilder.group({
            difference: Math.round(this.offset * 34.30)
        }) as SigncoFormGroup;
    }

    updateOffset() {
        const differenceInCm = this.tubeOffsetForm.get("difference").value as number || 0;
        this.offset = Math.round((differenceInCm / 34.30) * 1000) / 1000;
    }

    submit() {
        if (this.callback) {
            this.callback(this.offset);
        }

        this.close();
    }
}