<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingAttachment ? "manageParkingBanPhotos.edit" : "manageParkingBanPhotos.add") | translate}}
    </p-header>
    <form *ngIf="manageAttachmentForm"
          class="p-16"
          [formGroup]="manageAttachmentForm"
          novalidate>
        <!--description-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageAttachmentForm.get('description').showErrors }">
            <label class="col-4"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-8">
                <textarea placeholder="{{'general.description' | translate}}"
                          rows="2"
                          id="description"
                          class="p-inputtextarea"
                          formControlName="description"
                          appAutofocus
                          #descriptionInput>
                </textarea>
                <app-form-validation [control]="manageAttachmentForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>
        <!-- photo -->
        <div class="m-form-group">
            <app-photo-input [attachment]="photo"
                             [labelColSize]="4"
                             [colSize]="8"
                             [submitted]="manageAttachmentForm.submitted"
                             [popup]="true"
                             [required]="true"
                             [deleteImageMessageToTranslate]="'parkingBan.deletePhotoConfirmation'"></app-photo-input>
        </div>
    </form>
    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>