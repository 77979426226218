<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="carAndBikeConfiguration">
    <div class="p-16 m-border-bottom m-border-top">
        <h3>{{"uploadDetailsDialog.carAndBikeConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row">
            <div class="col-12">
                <label class="m-input-checkbox-block"
                       style="height:100%;">
                    <input [formControl]="form.get('hasCars')"
                           name="name"
                           type="checkbox"
                           id="cars_checkbox"
                           (change)="onCarAndBikeDetailsChanged()"> {{"uploadDetailsDialog.hasCars" | translate}}
                </label>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-12">
                <label class="m-input-checkbox-block"
                       style="height:100%;">
                    <input [formControl]="form.get('hasBikes')"
                           name="name"
                           type="checkbox"
                           id="bikes_checkbox"
                           (change)="onCarAndBikeDetailsChanged()"> {{"uploadDetailsDialog.hasBikes" | translate}}
                    <span *ngIf="carAndBikeRemark"
                          style="text-transform:lowercase;">&nbsp; <i>({{"uploadDetailsDialog.processingParameters" | translate}}: {{carAndBikeRemark}})</i></span>
                </label>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('legalSpeed').showErrors }">
            <label class="col-2 flex align-center"
                   for="legalSpeed">
                {{"uploadDetailsDialog.speedLimit" | translate}}
            </label>

            <div class="col-4">
                <div class="m-input-group">
                    <input [formControl]="form.get('legalSpeed')"
                           name="legalSpeed"
                           id="legalSpeed"
                           type="number"
                           class="p-inputtext"
                           step="0.1"
                           #legalSpeedInput>

                    <span class="m-input-group-addon">{{"measurements.kmh" | translate}}</span>
                </div>

                <app-form-validation [control]="form.get('legalSpeed')"
                                     [input]="legalSpeedInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-12">
                <label class="m-input-checkbox-block"
                       style="height:100%;">
                    <input [formControl]="form.get('hasSignificantTrucks')"
                           name="name"
                           type="checkbox"
                           id="significant_amount_trucks"
                           (change)="onCarAndBikeDetailsChanged()"> {{"uploadDetailsDialog.hasSignificantTrucks" | translate}}
                </label>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-12">
                <label class="m-input-checkbox-block"
                       style="height:100%;">
                    <input [formControl]="form.get('hasSignificantPedestrians')"
                           name="name"
                           type="checkbox"
                           id="significant_amount_pedestrians"
                           (change)="onCarAndBikeDetailsChanged()"> {{"uploadDetailsDialog.hasSignificantPedestrians" | translate}}
                </label>
            </div>
        </div>
    </div>
</div>