import { IDeviceDisplayEvent, DeviceDisplayEventCreator, DeviceDisplayEventUpdater } from "../models/device-display-configuration";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DeviceDisplayEventApi extends ApiService<IDeviceDisplayEvent, DeviceDisplayEventCreator, DeviceDisplayEventUpdater> {

    getRoute(): string {
        return "DisplayEvents";
    }
}