import { Component, Input } from "@angular/core";
import { ITextFieldConfiguration, ITotemDisplayMode } from "src/app/models/totem-display-configuration";
import { TextFieldAnimationComponent } from "src/app/modules/shared/components/totem-display-preview/text-field-animation/text-field-animation.component";
import { NgForOf, NgIf } from "@angular/common";

@Component({
    selector: "app-totem-display-preview",
    templateUrl: "./totem-display-preview.component.html",
    imports: [
        TextFieldAnimationComponent,
        NgForOf,
        NgIf
    ],
    standalone: true
})
export class TotemDisplayPreviewComponent {

    @Input() mode: ITotemDisplayMode;
    @Input() configuration: ITextFieldConfiguration[];
    @Input() isExpanded: boolean;
}
