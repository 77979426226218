import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";
import { ManageLinkComponent } from "./components/manage-link/manage-link.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    // Create
    { path: "new/mp/:mpId", component: ManageLinkComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "manageLink.title" } },
    { path: "new/devices/:deviceId", component: ManageLinkComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "manageLink.title" } },

    // replace
    { path: "replace/:replaceLinkId", component: ManageLinkComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "manageLink.title" } },

    // break
    { path: "break/:breakDeviceId", component: ManageLinkComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "manageLink.title" } },

    // Edit
    { path: ":editLinkId", component: ManageLinkComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "manageLink.title" } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LinkRoutingModule { }
