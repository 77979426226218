import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { ViewModelEnum } from "src/app/models/domain-data";
import { SigncoFormControl, SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";


class CarCategoryControl {
    name: string;
    label: string;
    control: SigncoFormControl;
}

@Component({
    selector: "app-device-link-car-xpos-configuration",
    templateUrl: "./device-link-car-xpos-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkCarXPosConfigurationComponent extends DeviceLinkFormGroupComponent {
    wheelWidthCmForm: SigncoFormGroup;
    carCategoryControls: Array<CarCategoryControl>;
    vehicleCategories: ViewModelEnum[];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly domainDataService: DomainDataService,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (analyzerConfiguration?.carXPosConfiguration) {
                this.domainDataService.get(DomainData.VehicleCategory).then(vehicleCategories => {
                    if (this.fgd.form.get("carXPosConfiguration")) this.fgd.form.removeControl("carXPosConfiguration");

                    this.vehicleCategories = vehicleCategories;

                    // Dynamically create controls on the WheelWidthCmForm per car category
                    this.wheelWidthCmForm = this.formBuilder.group({}) as SigncoFormGroup;
                    const carCategoryControls = new Array<CarCategoryControl>();

                    for (const vehicleCategory of this.vehicleCategories) {
                        const carCategoryControl = {
                            name: vehicleCategory.value,
                            label: vehicleCategory.label,
                            control: this.formBuilder.control(0, [Validators.required, Validators.min(0)])
                        } as CarCategoryControl;

                        this.wheelWidthCmForm.addControl(carCategoryControl.name, carCategoryControl.control);
                        carCategoryControls.push(carCategoryControl);
                    }
                    this.carCategoryControls = carCategoryControls;

                    this.form = this.formBuilder.group({
                        wheelWidthCm: this.wheelWidthCmForm
                    }) as SigncoFormGroup;

                    this.form.patchValue(analyzerConfiguration.carXPosConfiguration);
                    this.fgd.form.addControl("carXPosConfiguration", this.form);
                    resolve(true);
                });
                return;
            }

            if (this.fgd.form.get("carXPosConfiguration")) this.fgd.form.removeControl("carXPosConfiguration");
            this.form = null;
            return resolve(false);
        });
    }

    carCategoryControlTrackByFn(index: number, item: CarCategoryControl) {
        return item.name;
    }
}
