import { Component, OnChanges, SimpleChanges, Input, ElementRef, ViewChild, HostListener } from "@angular/core";
import { LazyTableComponent, TableColumn, FilterType, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { MeasuringPointDataDeleteDialogComponent } from "src/app/modules/shared/components/measuring-point-data-delete-dialog/measuring-point-data-delete.dialog";
import { VehicleApi, VehicleSearchParameters } from "src/app/resource/vehicle.api";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { FilterDescriptor, FilterOperator } from "src/app/models/search";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { TranslateService } from "@ngx-translate/core";
import { SearchParameters } from "src/app/models/search";
import { IVehicleRow } from "src/app/models/vehicle-overview";
import { IUpload } from "src/app/models/upload";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { MeasuringPointExceptionalDayUpdater } from "src/app/models/measuring-point-exceptional-day";
import { BackendRights } from "src/app/models/backend-rights";

export class MeasuringPointValidationColumn extends TableColumn {
    constructor(
        field: string,
        public reverse: boolean) {

        super(field, "", { width: 35, resizable: false });
        this.ngStyle.padding = "0";
        this.ngStyle.whiteSpace = "nowrap";
        this.ngStyle["text-align"] = "center";
    }
}

@Component({
    selector: "app-measuring-point-validation",
    templateUrl: "./measuring-point-validation.component.html"
})
export class MeasuringPointValidationComponent extends LazyTableComponent<IVehicleRow> implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @ViewChild(MeasuringPointDataDeleteDialogComponent, { static: false }) measuringPointDataDeleteDialog: MeasuringPointDataDeleteDialogComponent;

    @Input() measuringPoint: IMeasuringPoint;
    @Input() deleteButton = true;
    @Input() minDate: Date;
    @Input() maxDate: Date;

    uploads: IUpload[];
    isDownloadingFile: boolean;

    baseColumns = new Array<TableColumn>();
    beginColumns = new Array<TableColumn>();
    endColumns = new Array<TableColumn>();
    vehicleCategoryColumns = new Array<TableColumn>();

    canDeleteVehicleData = false;

    constructor(
        elementRef: ElementRef,
        vehicleApi: VehicleApi,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        readonly translateService: TranslateService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly measuringPointApi: MeasuringPointApi) {

        super("measuring-point-validation.component", elementRef, vehicleApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");

        this.canDeleteVehicleData = this.rights?.hasBackendRight(BackendRights.DeleteVehicle);
        this.stretchHeight = true;
        this.enableInitialData = true;
        this.selectionMode = "";
        this.footer = false;

        const saveColumn = new TableColumn("save", "", { type: ColumnType.Icon, width: 30 });
        this.addColumn(saveColumn);

        const dateColumn = new TableColumn("date", "general.date", { filterType: FilterType.Date, sortable: true, width: 130, resizable: false });
        this.addColumn(dateColumn);

        const errorColumn = new TableColumn("isError", "dataValidation.columnError", { type: ColumnType.Checkbox });
        errorColumn.ngStyle.textAlign = "center";
        this.addColumn(errorColumn);

        const exceptionColumn = new TableColumn("isException", "hourlyPreview.columnException", { type: ColumnType.Checkbox });
        exceptionColumn.ngStyle.textAlign = "center";
        this.addColumn(exceptionColumn);

        const auditOrLogColumn = new TableColumn("auditOrLog", "", { type: ColumnType.Icon, width: 66 });
        this.addColumn(auditOrLogColumn);

        const exceptionReasonColumn = new TableColumn("exceptionReason", "hourlyPreview.exceptionReason", { width: null });
        this.addColumn(exceptionReasonColumn);

        const predictedColumn = new TableColumn("isPredicted", "hourlyPreview.columnPredicted", { type: ColumnType.Checkbox });
        predictedColumn.ngStyle.textAlign = "center";
        this.addColumn(predictedColumn);

        this.baseColumns = this.columns.clone();
        this.beginColumns = this.baseColumns.filter(x => x.field !== "exceptionReason" && x.field !== "isPredicted");
        this.endColumns = this.columns.filter(x => !this.beginColumns.contains(x));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.reload(false);
        }

        const dateChange = changes["minDate"] || changes["maxDate"];
        if (dateChange) {
            this.reload(false);
        }

        super.ngOnChanges(changes);
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    canDeactivateCheck(): boolean {
        return !this.data.find(x => x.isDirty);
    }

    onDeactivate() {
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.reload(false);
    }

    canLoad(): boolean {
        return !!this.measuringPoint;
    }

    getSearchParameters(): SearchParameters {
        const parameters = {
            measuringPointId: this.measuringPoint.id,
            group: "1D",
            orderDescending: !(this.minDate || this.maxDate) // Don't order descending when restricting date, feels unnatural
        } as VehicleSearchParameters;

        if (this.minDate || this.maxDate) {
            parameters.filter = [];

            if (this.minDate) {
                const minDateFilter = new FilterDescriptor("date", this.minDate, FilterOperator.greaterThanOrEqualTo);
                parameters.filter.push(minDateFilter);
            }

            if (this.maxDate) {
                const maxDateFilter = new FilterDescriptor("date", this.maxDate, FilterOperator.lessThan);
                parameters.filter.push(maxDateFilter);
            }
        }

        return parameters;
    }

    async processLoadedData(data: IVehicleRow[]): Promise<IVehicleRow[]> {
        const vehicleCategoryColumns = new Array<TableColumn>();

        this.columns = this.baseColumns.clone();
        this.updateRelevantColumns();

        // Create primary / reverse column per category according to what the data sent us
        let index = this.columns.length - 2;
        const row = data.takeFirstOrDefault();
        if (row) {
            for (const vehicleCategory in row.data) {
                if (!row.data.hasOwnProperty(vehicleCategory)) continue;

                this.addColumn(new MeasuringPointValidationColumn(vehicleCategory, false), index++);
                this.addColumn(new MeasuringPointValidationColumn(vehicleCategory, true), index++);

                const groupHeaderColumn = new TableColumn(vehicleCategory, "", { width: 70, resizable: false });
                groupHeaderColumn.ngStyle["text-align"] = "center";
                vehicleCategoryColumns.push(groupHeaderColumn);
            }
        }

        this.vehicleCategoryColumns = vehicleCategoryColumns;

        return data;
    }

    openDeleteDialog() {
        const onDelete = () => {
            this.reload();
        };

        this.measuringPointDataDeleteDialog.open(this.measuringPoint, null, null, onDelete);
    }

    getVehicleCategoryHeaderColumnStyle(column: TableColumn): { [key: string]: any } {
        return Object.assign({}, column.ngStyle, { padding: undefined });
    }

    handleRowIsError(row: IVehicleRow) {
        if (row.isError && row.isException) {
            row.isException = false;
        }

        this.handleRowEdit(row);
    }

    handleRowIsException(row: IVehicleRow) {
        if (row.isError && row.isException) {
            row.isError = false;
        }

        this.handleRowEdit(row);
    }

    handleRowEdit(rowData: IVehicleRow) {
        // It's undefined when we get it from server, so delete empty strings for comparison
        if (!rowData.exceptionReason) {
            delete rowData.exceptionReason;
        }

        rowData.isDirty = !this.isInitialDataRow(rowData);
    }

    saveRow(rowData: IVehicleRow) {
        if (rowData.isSaving) return;

        rowData.isSaving = true;

        const onSuccess = () => {
            delete rowData.isSaving;
            rowData.isDirty = false;

            this.replaceInitialData(rowData);
        };
        const onError = () => { };

        const updater = new MeasuringPointExceptionalDayUpdater();
        updater.date = rowData.date;
        updater.isError = rowData.isError;
        updater.isException = rowData.isException;
        updater.isPredicted = rowData.isPredicted;
        updater.reason = rowData.exceptionReason;

        this.measuringPointApi.updateExceptionalDays$(this.measuringPoint.id, [updater]).subscribe(onSuccess, onError);
    }
}