<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          id="createRecurringTasksDialog">

    <p-header>
        {{"recurringTasks.createFromSelection" | translate}}
    </p-header>

    <div class="p-16">
        <div cdkScrollable>
            <div class="m-form-group row">
                <div class="col-3">
                    <h3 style="align-self:center">
                        {{"recurringTasks.availableFrom" | translate}}
                    </h3>
                </div>

                <div class="col-6">
                    <div class="m-form-group row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <p-calendar [required]="!isValid"
                                                id="availableFromDate"
                                                selectionMode="single"
                                                autoWidth="false"
                                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                [dateFormat]="calendarSettings.dateFormat"
                                                [yearRange]="primeComponentService.calendarYearRange"
                                                [showIcon]="true"
                                                [monthNavigator]="true"
                                                [yearNavigator]="true"
                                                [numberOfMonths]="1"
                                                [(ngModel)]="availableFromDate"
                                                [minDate]="currentDate"
                                                [style]="{'width':'100%'}"
                                                showButtonBar="true"
                                                appendTo="body"
                                                #availableFromDateInput>
                                    </p-calendar>
                                </div>

                                <div class="col-6">
                                    <p-multiSelect appendTo="body"
                                                   id="workers"
                                                   placeholder="{{'recurringTasks.selectResponsibleWorkers' | translate}}"
                                                   [options]="workers"
                                                   [filter]="true"
                                                   [showHeader]="true"
                                                   [showToggleAll]="false"
                                                   [style]="{'width':'100%'}"
                                                   [(ngModel)]="workerIds"
                                                   #selectedWorkersInput>
                                    </p-multiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-form-group mt-8">
            <div *ngIf="isScheduledTasksListEmpty">
                <span style="color: #93a0a6">{{"recurringTasks.dragAndDropScheduledTasksMessage" | translate}}</span>
            </div>
            <div cdkDropList
                 style="min-height:100px"
                 [cdkDropListData]="scheduledTasks"
                 id="list-scheduled"
                 [cdkDropListConnectedTo]="['list-on-hold']"
                 (cdkDropListDropped)="drop($event)">
                <div *ngFor="let scheduledTask of scheduledTasks; trackBy: trackByFn"
                     cdkDrag
                     [cdkDragData]="taskOnHold"
                     style="cursor: move;">
                    <app-recurring-task-preview [task]="scheduledTask"></app-recurring-task-preview>
                </div>
            </div>
        </div>

        <div class="m-form-group"
             cdkScrollable>
            <div class="row mb-8">
                <h3 class="m-8">
                    {{"recurringTasks.onHold" | translate}}
                </h3>
            </div>
            <div *ngIf="isOnHoldTasksListEmpty">
                <span style="color: #93a0a6">{{"recurringTasks.dragAndDropOnHoldTasksMessage" | translate}}</span>
            </div>
            <div cdkDropList
                 [cdkDropListData]="onHoldTasks"
                 id="list-on-hold"
                 [cdkDropListConnectedTo]="['list-scheduled']"
                 (cdkDropListDropped)="drop($event)"
                 style="min-height:100px">
                <div *ngFor="let taskOnHold of onHoldTasks; trackBy: trackByFn"
                     cdkDrag
                     [cdkDragData]="taskOnHold"
                     style="cursor: move;">
                    <app-recurring-task-preview [task]="taskOnHold"></app-recurring-task-preview>
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>