import { NgModule } from "@angular/core";
import { ExternalInputPinnedDataComponent } from "./components/external-input-pinned-data/external-input-pinned-data.component";
import { LightPinnedDataComponent } from "./components/light-pinned-data/light-pinned-data.component";
import { MeasuredDataPinnedDataComponent } from "./components/measured-data-pinned-data/measured-data-pinned-data.component";
import { LiveTileComponent } from "./components/live-tile/live-tile.component";
import { VmsPinnedDataComponent } from "./components/vms-pinned-data/vms-pinned-data.component";
import { TotemDisplayPinnedDataComponent } from "./components/totem-display/totem-display-pinned-data/totem-display-pinned-data.component";
import { SharedModule } from "../shared/shared.module";
import { CountingZonePinnedDataComponent } from "./components/counting-zone-pinned-data/counting-zone-pinned-data.component";
import { CountingZoneManageDialogComponent } from "./components/counting-zone-manage-dialog/counting-zone-manage-dialog.component";
import { ScenariosPinnedDataComponent } from "./components/scenarios-pinned-data/scenarios-pinned-data.component";
import { ExternalInputManageDialogComponent } from "./components/external-input-manage-dialog/external-input-manage-dialog.component";
import { VmsManageDialogComponent } from "./components/vms-manage-dialog/vms-manage-dialog.component";
import { MeasuredDataManageDialogComponent } from "./components/measured-data-manage-dialog/measured-data-manage-dialog.component";
import { LightManageDialogComponent } from "./components/light-manage-dialog/light-manage-dialog.component";
import { WorkzonePinnedDataComponent } from "./components/workzone-pinned-data/workzone-pinned-data.component";
import { TotemDisplayManageDialogComponent } from "./components/totem-display/totem-display-manage-dialog/totem-display-manage-dialog.component";
import { TrafficLightPinnedDataComponent } from "./components/traffic-light-pinned-data/traffic-light-pinned-data.component";
import { UserMessagesPinnedDataComponent } from "./components/user-messages-pinned-data/user-messages-pinned-data.component";
import { TotemDisplayPreviewComponent } from "src/app/modules/shared/components/totem-display-preview/totem-display-preview/totem-display-preview.component";

@NgModule({
    declarations: [
        CountingZoneManageDialogComponent,
        CountingZonePinnedDataComponent,
        ExternalInputManageDialogComponent,
        ExternalInputPinnedDataComponent,
        LightManageDialogComponent,
        LightPinnedDataComponent,
        LiveTileComponent,
        MeasuredDataManageDialogComponent,
        MeasuredDataPinnedDataComponent,
        ScenariosPinnedDataComponent,
        TotemDisplayManageDialogComponent,
        TotemDisplayPinnedDataComponent,
        TrafficLightPinnedDataComponent,
        VmsManageDialogComponent,
        VmsPinnedDataComponent,
        WorkzonePinnedDataComponent,
        UserMessagesPinnedDataComponent,
    ],
    imports: [
        SharedModule,
        TotemDisplayPreviewComponent
    ],
    exports: [
        LiveTileComponent
    ]
})
export class LiveTilesModule { }
