import { HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { DurationUtils } from "./duration-utils";
import * as Flatted from "flatted";
import * as moment from "moment";

const xssiPrefix = /^\)\]\}',?\n/;
export const isoDateTimeRegEx = /^(\d{4})-(\d{2})-(\d{2})(?:[T ](\d{2}):(\d{2})(?::(\d{2})(?:\.(\d*))?)?)?(?:[Z+-]?(-?\d{0,2})?:?(-?\d{0,2}))?$/;
export const dateRegex = /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;
const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
const timeSpanRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9].\d+$/; // 00:00:04.8201154
const durationStringRegex = /^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/; // PT0.8S

export class JsonUtils {

    /**
   * Parse the json body using custom revivers.
   * @private
   * @param {HttpResponse<string>} res
   * @returns{HttpResponse<any>}
   * @memberof JsonUtils
   */
    static processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
        let body = res.body;
        if (typeof body === "string") {
            const originalBody = body;
            body = body.replace(xssiPrefix, "");
            try {
                body = body !== "" ? this.parse<any>(body) : null;
            } catch (error) {
                // match https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L221
                throw new HttpErrorResponse({
                    error: { error, text: originalBody },
                    headers: res.headers,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url || undefined,
                });
            }
        }
        return res.clone({ body });
    }

    private static canBeTime(key: string): boolean {
        return key.startsWith("minTime") || key.startsWith("maxTime") || key.endsWith("Time") || key.endsWith("updateInterval") || key === "duration";
    }

    static encode(key: string, value: any): string {
        // TODO (r) - This is in no way the proper way
        // But we can't overwrite Duration.toISOString
        if (this.canBeTime(key)) {

            if (typeof value === "string") {
                let match = durationStringRegex.exec(value);
                if (match) {
                    value = moment.duration(value);
                }

                match = isoDateTimeRegEx.exec(value);
                if (match) {
                    value = new Date(value);
                }
            }

            if (moment.isDuration(value)) {
                value = DurationUtils.toString(value);
            }

            if (value instanceof Date) {
                value = DurationUtils.toString(value);
            }
        } else {
            // Dates as dateTimeOffset
            const match = isoDateTimeRegEx.exec(value);
            if (match) {
                value = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
            }
        }

        return value;
    }

    /**
       * Detect a date string and convert it to a date object.
       * @private
       * @param {*} key json property key.
       * @param {*} value json property value.
       * @returns {*} original value or the parsed date.
       * @memberof JsonUtils
       */
    static decode(key: string, value: any): any {
        if (typeof value !== "string") {
            return value;
        }

        if (value === "0001-01-01T00:00:00") {
            return null;
        }

        // Browser specific property, don't manipulate
        // if (key === "maxWindowTime") {
        //     return value;
        // }

        let match = isoDateTimeRegEx.exec(value);
        if (match) {
            return new Date(value);
        }

        match = dateRegex.exec(value);
        if (match) {
            const splitDate = value.split("/").map(x => Number.parseInt(x, 10));
            return new Date(splitDate[0], splitDate[1] - 1, splitDate[2], 0, 0, 0, 0);
        }

        if (this.canBeTime(key)) {
            match = timeRegex.exec(value);
            if (match) {
                const split = value.split(":").map(x => Number.parseInt(x, 10));
                const hours = split[0];
                const minutes = split[1];
                const seconds = split[2];
                const milliseconds = value.contains(".") ? value.split(".")[1] : null;
                const duration = moment.duration().add(hours, "hours").add(minutes, "minutes").add(seconds, "seconds").add(milliseconds, "milliseconds");
                return duration;
            }
        }

        match = timeSpanRegex.exec(value);
        if (match) {
            const split = value.split(":").map(x => Number.parseInt(x, 10));
            const hours = split[0];
            const minutes = split[1];
            const seconds = split[2];
            const milliseconds = value.contains(".") ? Number.parseInt(value.split(".")[1].substr(0, 3), 10) : null;
            const duration = moment.duration().add(hours, "hours").add(minutes, "minutes").add(seconds, "seconds").add(milliseconds, "milliseconds");
            return duration;
        }

        match = durationStringRegex.exec(value);
        if (match) {
            const duration = moment.duration(value);
            return duration;
        }

        return value;
    }

    static stringify(object: any): string {
        return JSON.stringify(object, (key: string, value: any) => this.encode(key, value));
    }

    static parse<T>(text: string): T {
        return JSON.parse(text, (key: string, value: any) => this.decode(key, value));
    }

    static deepClone<T>(object: T): T {
        const stringified = Flatted.stringify(object, (key: string, value: any) => this.encode(key, value));
        const clonedObject = Flatted.parse(stringified, (key: string, value: any) => this.decode(key, value));
        return clonedObject;
    }
}