import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IVmsType } from "src/app/models/measuring-point";
import { IVmsImage } from "src/app/models/vms-image";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";

@Injectable()
export class VmsImageEditorService {

    private selectedObjectChangeSubject$: Subject<fabric.Object> = new Subject<fabric.Object>();
    public selectedObjectChange$: Observable<fabric.Object> = this.selectedObjectChangeSubject$.asObservable();

    private renderPreviewRequestSubject$: Subject<void> = new Subject<void>();
    public renderPreviewRequest$: Observable<void> = this.renderPreviewRequestSubject$.asObservable();

    private currentCanvasSubject$: BehaviorSubject<fabric.Canvas> = new BehaviorSubject<fabric.Canvas>(null);
    public currentCanvas$: Observable<fabric.Canvas> = this.currentCanvasSubject$.asObservable();

    private vmsImageVariantsSubject$: BehaviorSubject<IVmsImageVariant[]> = new BehaviorSubject<IVmsImageVariant[]>([]);
    public vmsImageVariants$: Observable<IVmsImageVariant[]> = this.vmsImageVariantsSubject$.asObservable();

    private selectedVmsImageSubject$: BehaviorSubject<IVmsImage> = new BehaviorSubject<IVmsImage>(null);
    public selectedVmsImage$: Observable<IVmsImage> = this.selectedVmsImageSubject$.asObservable();

    private selectedVmsImageVariantSubject$: BehaviorSubject<IVmsImageVariant> = new BehaviorSubject<IVmsImageVariant>(null);
    public selectedVmsImageVariant$: Observable<IVmsImageVariant> = this.selectedVmsImageVariantSubject$.asObservable();

    private copyFromVmsTypeIdSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public copyFromVmsTypeId$: Observable<string> = this.copyFromVmsTypeIdSubject$.asObservable();

    private currentVmsTypeSubject$: BehaviorSubject<IVmsType> = new BehaviorSubject<IVmsType>(null);
    public currentVmsType$: Observable<IVmsType> = this.currentVmsTypeSubject$.asObservable();

    private uploadFileSubject$: Subject<File> = new Subject<File>();
    public uploadFile$: Observable<File> = this.uploadFileSubject$.asObservable();

    private isCurrentVmsImageChangedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    clear() {
        this.selectedObjectChangeSubject$.next(null);
        this.vmsImageVariantsSubject$.next([]);
        this.selectedVmsImageSubject$.next(null);
        this.copyFromVmsTypeIdSubject$.next(null);
        this.currentVmsTypeSubject$.next(null);
        this.uploadFileSubject$.next(null);
        this.selectedVmsImageVariantSubject$.next(null);
        this.isCurrentVmsImageChangedSubject$.next(false);
    }

    setIsCurrentVmsImageChanged(isChanged: boolean) {
        this.isCurrentVmsImageChangedSubject$.next(isChanged);
    }

    getIsCurrentVmsImageChanged() {
        return this.isCurrentVmsImageChangedSubject$.value;
    }


    notifySelectedObjectChanged(object: fabric.Object) {
        this.selectedObjectChangeSubject$.next(object);
    }
    requestRenderPreview() {
        this.renderPreviewRequestSubject$.next();
    }

    setCurrentCanvas(canvas: fabric.Canvas) {
        this.currentCanvasSubject$.next(canvas);
    }
    getCurrentCanvas() {
        return this.currentCanvasSubject$.value;
    }

    setVmsImageVariants(vmsImageVariants: IVmsImageVariant[]) {
        this.vmsImageVariantsSubject$.next(vmsImageVariants);
    }
    getVmsImageVariants() {
        return this.vmsImageVariantsSubject$.value;
    }
    setSelectedVmsImage(vmsImage: IVmsImage) {
        this.selectedVmsImageSubject$.next(vmsImage);
    }
    getSelectedVmsImage() {
        return this.selectedVmsImageSubject$.value;
    }

    setSelectedVmsImageVariant(vmsImageVariant: IVmsImageVariant) {
        this.selectedVmsImageVariantSubject$.next(vmsImageVariant);
    }
    getSelectedVmsImageVariant() {
        return this.selectedVmsImageVariantSubject$.value;
    }
    setCopyFromVmsTypeId(vmsTypeId: string) {
        this.copyFromVmsTypeIdSubject$.next(vmsTypeId);
    }
    getCopyFromVmsTypeId() {
        return this.copyFromVmsTypeIdSubject$.value;
    }
    setCurrentVmsType(vmsType: IVmsType) {
        this.currentVmsTypeSubject$.next(vmsType);
    }
    getCurrentVmsType() {
        return this.currentVmsTypeSubject$.value;
    }
    setUploadFile(file: File) {
        this.uploadFileSubject$.next(file);
    }
    deleteActiveObjects() {
        const canvas = this.getCurrentCanvas();
        if (canvas) {
            const activeObj = canvas.getActiveObjects();
            // don't delete if it's i-text and it's editing
            if (activeObj.length == 1 && activeObj[0].type == "i-text") {
                const textObj = activeObj[0] as fabric.IText;
                if (textObj.isEditing) {
                    return;
                }
            }
            if (activeObj) {

                canvas.remove(...activeObj);
                this.requestRenderPreview();
                this.notifySelectedObjectChanged(null);
                this.setIsCurrentVmsImageChanged(true);
            }
        }
    }

    addAndSetActiveObject(object: fabric.Object) {
        // get current canvas
        const canvas = this.getCurrentCanvas();
        object.setCoords();
        canvas.add(object);
        canvas.setActiveObject(object);
        this.notifySelectedObjectChanged(canvas.getActiveObject());
        canvas.requestRenderAll();
        this.setIsCurrentVmsImageChanged(true);
    }

    setControlsVisibility(object: fabric.Object) {
        object.setControlsVisibility({
            mt: true,
            mb: true,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: true,
        });
    }

    setTextControlsVisibility(object: fabric.Object) {
        object.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false,
        });
    }

    setSymbolControlsVisibility(object: fabric.Object) {
        object.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: true,
            br: true,
            tl: true,
            tr: true,
            mtr: false,
        });
    }

    moveActiveObject(key: string) {
        const canvas = this.getCurrentCanvas();
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                const moveBy = 1;
                switch (key) {
                    case "ArrowLeft":
                        activeObject.left -= moveBy;
                        break;
                    case "ArrowRight":
                        activeObject.left += moveBy;
                        break;
                    case "ArrowUp":
                        activeObject.top -= moveBy;
                        break;
                    case "ArrowDown":
                        activeObject.top += moveBy;
                        break;
                }
                activeObject.setCoords();
                canvas.renderAll();
                canvas.fire("object:modified", { target: activeObject });
            }
        }
    }

}