<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         class="m-layout-area-body  m-border-top"
         [styleClass]="styleClass"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [customSort]="selectionBox"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         dataKey="id"
         #tableRef>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [ngSwitch]="column.field">
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-assignmentStatus>
        <tr #row
            [pSelectableRow]="assignmentStatus"
            [pSelectableRowDisabled]="!canSelectRow(assignmentStatus)"
            [attr.id]="assignmentStatus[table.dataKey]">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [ngClass]="resizable ? 'ui-resizable-column' : ''">

                <ng-container *ngSwitchCase="'timestamp'">
                    {{ assignmentStatus.timestamp | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'creator'">
                    {{assignmentStatus.worker ? assignmentStatus.worker.firstName + " "+assignmentStatus.worker.lastName : (assignmentStatus.creator ? assignmentStatus.creator.firstName + " "+assignmentStatus.creator.lastName : "general.unknown" | translate)}}
                </ng-container>

                <ng-container *ngSwitchCase="'status'">
                    <app-domain-data-field [enumKey]="assignmentStatus.statusId.toString()"
                                           domainDataType="assignmentStatus">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{assignmentStatus[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>