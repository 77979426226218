import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { ISymbol, SymbolCreator } from "../models/symbol";

@Injectable({
    providedIn: "root"
})
export class SymbolApi extends ApiService<ISymbol, SymbolCreator, SymbolCreator> {

    getRoute(): string {
        return "Symbols";
    }
}