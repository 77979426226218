import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import * as lodash from "lodash";
import { SelectItem } from "primeng/api";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { DeviceUpdater, IDevice } from "src/app/models/device";
import { IDekimoDisplayConfiguration } from "src/app/models/device-display-configuration";
import { SigncoFormGroup, SigncoFormArray } from "src/app/models/form";
import { DisplaySide } from "src/app/models/upload";
import { DeviceApi } from "src/app/resource/device.api";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
    selector: "app-device-dekimo-display-configuration",
    templateUrl: "./device-dekimo-display-configuration.component.html",
    host: { class: "m-layout-area-body m-layout-w-actions-bottom" }
})
export class DeviceDekimoDisplayConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Output() save = new EventEmitter<IDevice>();

    device: IDevice;
    dekimoDisplayConfiguration: IDekimoDisplayConfiguration;
    hasChanged = false;
    submitting = false;

    constructor(
        private readonly deviceApi: DeviceApi,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    canDeactivateCheck(): boolean {
        return !this.hasChanged;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    async initialize() {
        if (!this.device) return;

        // We bind on a clone, so we can always reset to original
        this.dekimoDisplayConfiguration = lodash.cloneDeep(this.device.dekimoDisplayConfiguration);
        this.hasChanged = false;
    }

    async reset() {
        await this.initialize();
    }

    async submit() {
        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            this.device = savedDevice;
            this.submitting = false;
            this.initialize();
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);
        Object.assign(deviceUpdater.dekimoDisplayConfiguration, this.dekimoDisplayConfiguration);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}