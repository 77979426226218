import { Pipe, PipeTransform } from "@angular/core";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";
import { DomainDataService } from "src/app/services/domain-data.service";

@Pipe({
    name: "translateVmsTypeVariants"
})
export class TranslateVmsTypeVariantsPipe implements PipeTransform {
    constructor(private domainDataService: DomainDataService) { }

    transform(variants: IVmsImageVariant[], language: string): string {
        if (!variants || variants.length === 0) return "";

        const translations: string[] = [];
        for (const variant of variants) {
            translations.push(this.domainDataService.translateEnum("vmsTypeValue", variant.vmsType.typeId));
        }
        const result = translations.sort((a, b) => a.localeCompare(b)).join(", ");
        return result;
    }
}