<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="filterConfiguration">

    <div class="p-16 m-border-top m-border-bottom">
        <h3>{{"uploadDetailsDialog.filterConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group"
             *ngIf="this.showCarFilter">
            <h4>{{"uploadDetailsDialog.car" | translate }}</h4>
            <div class="row">
                <div class="col-2">
                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeCarForward').value"
                               (click)="invertCheckboxInputValue(form.get('excludeCarForward'), $event)"
                               (change)="onFilterChanged()"
                               [disabled]="form.disabled"
                               id="exclude_car_forward"
                               type="checkbox"> {{"measuringPoints.primaryDirection" | translate}}
                    </label>

                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeCarReverse').value"
                               (click)="invertCheckboxInputValue(form.get('excludeCarReverse'), $event)"
                               (change)="onFilterChanged()"
                               id="exclude_car_reverse"
                               [disabled]="form.disabled"
                               type="checkbox"> {{"measuringPoints.reverseDirection" | translate}}
                    </label>
                </div>

                <div class="col-4"
                     *ngIf="showCarFilterDetails">
                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeLight').value"
                               (click)="invertCheckboxInputValue(form.get('excludeLight'), $event)"
                               [disabled]="form.disabled"
                               id="exclude_car_light"
                               type="checkbox"> {{"light" | translateEnum:"vehicleCategory" }}
                    </label>

                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeMedium').value"
                               (click)="invertCheckboxInputValue(form.get('excludeMedium'), $event)"
                               [disabled]="form.disabled"
                               id="exclude_car_medium"
                               type="checkbox"> {{"medium" | translateEnum:"vehicleCategory" }}
                    </label>

                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeHeavy').value"
                               (click)="invertCheckboxInputValue(form.get('excludeHeavy'), $event)"
                               [disabled]="form.disabled"
                               id="exclude_car_heavy"
                               type="checkbox"> {{"heavy" | translateEnum:"vehicleCategory" }}
                    </label>
                </div>
            </div>
        </div>

        <div class="m-form-group"
             *ngIf="this.showBikeFilter">
            <h4>{{"uploadDetailsDialog.bike" | translate }}</h4>
            <div class="row">
                <div class="col-2">
                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeBikeForward').value"
                               (click)="invertCheckboxInputValue(form.get('excludeBikeForward'), $event)"
                               (change)="onFilterChanged()"
                               id="exclude_bike_forward"
                               [disabled]="form.disabled"
                               type="checkbox"> {{"measuringPoints.primaryDirection" | translate}}
                    </label>

                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeBikeReverse').value"
                               (click)="invertCheckboxInputValue(form.get('excludeBikeReverse'), $event)"
                               (change)="onFilterChanged()"
                               id="exclude_bike_reverse"
                               [disabled]="form.disabled"
                               type="checkbox"> {{"measuringPoints.reverseDirection" | translate}}
                    </label>
                </div>

                <div class="col-4"
                     *ngIf="showBikeFilterDetails">
                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeBike').value"
                               (click)="invertCheckboxInputValue(form.get('excludeBike'), $event)"
                               [disabled]="form.disabled"
                               id="exclude_bike"
                               type="checkbox"> {{"bike" | translateEnum:"vehicleCategory" }}
                    </label>

                    <label class="m-input-checkbox-block">
                        <input [checked]="!form.get('excludeMoped').value"
                               (click)="invertCheckboxInputValue(form.get('excludeMoped'), $event)"
                               [disabled]="form.disabled"
                               id="exclude_moped"
                               type="checkbox"> {{"moped" | translateEnum:"vehicleCategory" }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>