import { Component, ElementRef, ViewChild, inject, output } from "@angular/core";
import { FilterType } from "src/app/models/map-filter";
import { TypesFilterDialogComponent } from "../types-filter-dialog/types-filter-dialog.component";
import { StatusFilterDialogComponent } from "../status-filter-dialog/status-filter-dialog.component";
import { IOrganization, IUser } from "src/app/models/user";
import { IProjectSummary } from "src/app/models/web";
import { ProjectFilterOutput, ProjectsFilterDialogComponent } from "../projects-filter-dialog/projects-filter-dialog.component";
import { AnalysisType } from "src/app/models/measuring-point";
import { FilterService } from "src/app/modules/locations/services/filter.service";
import { FilterState } from "src/app/modules/locations/services/filter-state";
import { StatusFilter } from "../status-filter-dialog/status-model";
import { AlertLevel } from "src/app/models/alert";

@Component({
    selector: "app-filter-container",
    templateUrl: "./filter-container.component.html",
    styleUrls: ["./filter-container.component.scss"]
})
export class FilterContainerComponent {
    searchBoxShouldFocus = output<void>();
    @ViewChild("moreFiltersPopup") protected moreFiltersPopup: ElementRef;
    private readonly filterService = inject(FilterService);

    protected filterTypes = [FilterType.Project, FilterType.Type, FilterType.Status]; // All the types of filters that we support

    // Provide easy access from the template
    protected get filterState(): FilterState {
        return this.filterService.filterState;
    }

    protected openFilterDialog(filterType: any) {
        switch (filterType) {
            case FilterType.Project:
                this.projectsFilterDialog.openFilterDialog();
                break;
            case FilterType.Type:
                this.typeFilterDialog.openFilterDialog(this.filterService.filterState.types);
                break;
            case FilterType.Status:
                this.statusFilterDialog.openFilterDialog(this.filterService.filterState.alertLevels, this.filterService.filterState.linkStatus);
                break;
            default:
                break;
        }

        this.closeMoreFiltersPopup();
    }

    private closeMoreFiltersPopup() {
        if (this.moreFiltersPopup) {
            this.moreFiltersPopup.nativeElement.open = false;
        }
    }

    public clearFilters() {
        this.filterService.filterState.clear();
        this.filterService.notifyFilterStateChanged();
    }

    //#region Filter on projects

    @ViewChild(ProjectsFilterDialogComponent, { static: true }) private projectsFilterDialog: ProjectsFilterDialogComponent;

    protected handleProjectsFilterChanged(event: ProjectFilterOutput[]) {
        this.filterService.filterState.projects = [];
        this.filterService.filterState.organizations = [];
        this.filterService.filterState.projectsForUserId = null;

        if (event.some(eventObject => eventObject.key === "my-projects")) {
            this.filterService.filterState.projects = [];
            this.filterService.filterState.organizations = [];
            this.filterService.filterState.projectsForUserId = event.find(eventObject => eventObject.key === "my-projects").data as IUser;
        } else {
            event.forEach((eventObject) => {
                switch (eventObject.key) {
                    case "project":
                        this.filterService.filterState.projects.push(eventObject.data as IProjectSummary);
                        break;
                    case "organization":
                        this.filterService.filterState.organizations.push(eventObject.data as IOrganization);
                        break;
                    default:
                        break;
                }
            });
            this.filterService.filterState.projectsForUserId = null;
        }
        this.filterService.notifyFilterStateChanged();
        this.searchBoxShouldFocus.emit();
    }

    protected removeProjectFilter(project: IProjectSummary) {
        this.filterService.filterState.projects = this.filterState.projects.remove(project);
        this.filterService.notifyFilterStateChanged();
    }

    //#endregion Filter on projects

    //#region filter on types

    @ViewChild(TypesFilterDialogComponent, { static: true }) private typeFilterDialog: TypesFilterDialogComponent;

    protected handleTypesFilterChanged(selected: AnalysisType[]) {
        this.filterService.filterState.types = selected;
        this.filterService.notifyFilterStateChanged();
        this.searchBoxShouldFocus.emit();
    }

    protected removeTypeFilter(type: AnalysisType) {
        this.filterService.filterState.types = this.filterState.types.remove(type);
        this.filterService.notifyFilterStateChanged();
    }
    //#endregion filter on types

    //#region filter on link status

    @ViewChild(StatusFilterDialogComponent, { static: true }) private statusFilterDialog: StatusFilterDialogComponent;

    protected handleStatusFilterChanged(selection: StatusFilter) {
        this.filterService.filterState.linkStatus = selection.linkedStatus;
        this.filterService.filterState.alertLevels = selection.alertLevels;
        this.filterService.notifyFilterStateChanged();
        this.searchBoxShouldFocus.emit();
    }

    protected removeLinkedStatusFilter(): void {
        this.filterService.filterState.linkStatus = null;
        this.filterService.notifyFilterStateChanged();
    }
    protected removeAlertLevelFilter(alertLevel: AlertLevel) {
        this.filterService.filterState.alertLevels = this.filterState.alertLevels.remove(alertLevel);
        this.filterService.notifyFilterStateChanged();
    }
    //#endregion filter on link status

    //#region filter on a string

    public setSearchTermFilter(searchTerm: string) {
        this.filterService.filterState.searchTerm = searchTerm;
        this.filterService.notifyFilterStateChanged();
    }

    protected removeSearchTermFilter() {
        this.filterService.filterState.searchTerm = null;
        this.filterService.notifyFilterStateChanged();
    }

    //#endregion filter on a string

    protected removeOrganizationFilter(organization: IOrganization) {
        this.filterService.filterState.organizations = this.filterState.organizations.remove(organization);
        this.filterService.notifyFilterStateChanged();
    }

    protected removeProjectsForUserIdFilter() {
        this.filterService.filterState.projectsForUserId = null;
        this.filterService.notifyFilterStateChanged();
    }
}
