import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IAssignment } from "src/app/models/assignment";
import { BackendRights } from "src/app/models/backend-rights";
import { LocationCreator } from "src/app/models/location";
import { IParkingBan, ParkingBanCreator } from "src/app/models/parking-ban";
import { SearchParameters, FilterOperator, ServiceRequestOptions } from "src/app/models/search";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { ParkingBanApi } from "src/app/resource/parking-ban.api";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { ModalService } from "src/app/services/modal.service";
import { NavigationService } from "src/app/services/navigation.service";

const isCompletedColumnFullWidth = 150;

@Component({
    selector: "app-parking-bans",
    templateUrl: "./parking-bans.component.html"
})
export class ParkingBansComponent extends LazyTableComponent<IParkingBan> implements OnChanges, IComponentCanDeactivate {

    @Input() assignmentId: number;
    @Input() assignment: IAssignment;
    @Input() parkingBanIdSelected: number;
    @Input() locationId: number;
    @Input() editCommand = false;
    @Input() deleteCommand = false;
    @Input() requiresInput = false;
    @Input() duplicateCommand = false;

    @Output() duplicateCreated = new EventEmitter<IParkingBan>();
    @Output() lastRowDeleted = new EventEmitter();

    private isCompletedColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        public readonly parkingBanApi: ParkingBanApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly navigationService: NavigationService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService) {

        super("parkingBans.component", elementRef, parkingBanApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.isCompletedColumn = new TableColumn("isCompleted", "parkingBan.isCompleted", { sortable: true, visibility: ColumnVisibility.NeverHide, width: isCompletedColumnFullWidth, resizable: false });
        this.isCompletedColumn.ngStyle["justify-content"] = "center";
        this.addColumn(this.isCompletedColumn);
        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideMini }));

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (parkingBan) => this.navigateToDetail(parkingBan),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditParkingBan),
        });

        this.registerCommand({
            text: "general.duplicate",
            icon: "duplicate",
            click: (parkingBan) => this.duplicate(parkingBan),
            validFunc: () =>
                this.duplicateCommand &&
                this.rights?.hasBackendRight(BackendRights.EditParkingBan),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (parkingBan) => this.delete(parkingBan),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteParkingBan),
        });
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    ngOnChanges(changes: SimpleChanges) {
        const assignmentIdChange = changes["assignmentId"];
        const locationIdChange = changes["locationId"];
        const miniChange = changes["mini"];
        const parkingBanIdToBeExcludedChange = changes["parkingBanIdToExclude"];

        const inputChange = changes["requiresInput"] || assignmentIdChange || locationIdChange || parkingBanIdToBeExcludedChange;

        if (inputChange) {
            this.reload();
        }

        if (miniChange) {
            if (this.mini) {
                this.isCompletedColumn.setWidth(50);
            } else {
                this.isCompletedColumn.setWidth(isCompletedColumnFullWidth);
            }
        }

        super.ngOnChanges(changes);
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.locationId || !!this.assignmentId;
    }

    onSetData() {
        if (this.parkingBanIdSelected) {
            this.setSelection(Array<IParkingBan>({ id: this.parkingBanIdSelected } as IParkingBan));
        }
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();

        searchParameters.filter = [];
        if (this.assignmentId) {
            searchParameters.filter.push({ field: "assignmentId", value: this.assignmentId, operator: FilterOperator.equals });
        }

        if (this.locationId) {
            searchParameters.filter.push({ field: "locationId", value: this.locationId, operator: FilterOperator.equals });
        }

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("parkingBan", "assignment");
        serviceRequestOptions.includes.add("parkingBan", "location");
        return serviceRequestOptions;
    }

    public navigateToDetail(parkingBan: IParkingBan) {
        this.navigationService.toParkingBan(parkingBan.id);
    }


    private delete(parkingBan: IParkingBan) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteParkingBan)) return;

        const modalBody = this.translateService.instant("parkingBan.deleteConfirmation", { name: parkingBan.name }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(parkingBan));
    }

    private handleDelete(parkingBan: IParkingBan) {
        const onDeleteSuccess = () => {
            if (this.data.length === 1) {
                this.lastRowDeleted.emit();
            }
            this.reload();
        };

        this.parkingBanApi.delete$(parkingBan.id).subscribe(onDeleteSuccess, () => { });
    }

    private duplicate(parkingBan: IParkingBan) {
        if (!this.rights?.hasBackendRight(BackendRights.EditParkingBan)) return;

        parkingBan.id = undefined;
        const parkingBanCreator = Object.assign(new ParkingBanCreator(), parkingBan) as ParkingBanCreator;
        parkingBanCreator.assignmentId = parkingBan.assignment.id;
        parkingBanCreator.locationId = undefined;
        parkingBanCreator.location = Object.assign(new LocationCreator(), parkingBan.location) as LocationCreator;

        const copyName = `${parkingBan.name} - ${this.translateService.instant("words.copy")}`;
        parkingBanCreator.name = copyName;
        parkingBanCreator.location.code = copyName;

        this.parkingBanApi.create$(parkingBanCreator).subscribe((created: IParkingBan) => {
            this.duplicateCreated.next(created);
            this.reload();
        });
    }
}
