import { Component, ElementRef } from "@angular/core";

@Component({
    selector: "app-admin-releases",
    templateUrl: "./admin-releases.component.html"
})
export class AdminReleasesComponent {

    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }
}