import { CustomTableComponent, TableColumn, FilterType, TableService, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { MeasuringPointUtils } from "src/app/utilities";
import { ILinkMeasurement } from "src/app/models/device";
import { ViewModelEnum } from "src/app/models/domain-data";
import { IMeasuringPointSummary } from "src/app/models/web";

@Component({
    selector: "app-select-link-measurements",
    templateUrl: "./select-link-measurements.component.html"
})
export class SelectLinkMeasurementsComponent extends CustomTableComponent<ILinkMeasurement> {
    @Input() readonly = false;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    // We don't want to receive `add` events when initializing
    @Input() sendOutput = false;

    @Output() add = new EventEmitter<ILinkMeasurement[]>();
    @Output() delete = new EventEmitter<ILinkMeasurement[]>();

    analysisTypes: ViewModelEnum[];

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly domainDataService: DomainDataService,
        private readonly selectionService: MapSelectionService,
        private readonly measuringPointWebApi: MeasuringPointWebApi) {

        super("select-link-measurements.component", elementRef, tableService);

        this.selectionMode = "single";
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = false;

        this.addColumn(new TableColumn("analyzerConfiguration.type", "measuringPoints.analysisType", { type: ColumnType.Icon, filterType: FilterType.None, sortable: false, width: 36 }));
        this.addColumn(new TableColumn("measuringPoint.code", "measuringPoints.code", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("tubes", "general.tubes", { filterType: FilterType.None, sortable: false }));

        const removeMeasurement = (removedMeasurement: ILinkMeasurement) => {
            const measuringPoint = removedMeasurement.measuringPoint;
            this.selectionService.removeMeasuringPointId(measuringPoint.id);

            if (this.sendOutput) {
                this.delete.next([removedMeasurement]);
            }

            if (removedMeasurement === this.singleSelected) {
                this.clearSelection();
            }
        };

        const addData = (measuringPointSummaries: IMeasuringPointSummary[]) => {
            if (this.readonly) return;

            this.addMeasuringPoints(measuringPointSummaries);
        };

        const removeData = (measuringPointSummaries: IMeasuringPointSummary[]) => {
            if (this.readonly) return;

            const removedMeasuringPointIds = measuringPointSummaries.map(x => x.id);
            const removedLinks = this.data.filter(x => removedMeasuringPointIds.contains(x.measuringPoint.id));
            this.removeData(removedLinks);

            if (this.sendOutput) {
                this.delete.next(removedLinks);

                if (removedLinks.contains(this.singleSelected)) {
                    this.clearSelection(true);
                }
            }
        };

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, addData, removeData);

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (measurement) => removeMeasurement(measurement),
            validFunc: () => this.deleteCommand
        });

        this.domainDataService.get(DomainData.AnalysisType).then(analysisTypes => {
            this.analysisTypes = analysisTypes;
        });
    }

    async onSetData() {
        if (!this.data || this.readonly) return;

        // Fill in measuringPointSummary for the links, we get passed navigators
        for (const measurement of this.data) {
            if (measurement.measuringPointSummary) continue;

            measurement.measuringPointSummary = await this.measuringPointWebApi.get(measurement.measuringPoint.id);
        }

        const measuringPoints = this.data.map(x => x.measuringPointSummary);
        this.selectionService.setMeasuringPoints(measuringPoints);
    }

    getAnalysisTypeTranslation(key: string) {
        if (!this.analysisTypes) return "";

        return this.analysisTypes.find(x => x.value === key).label;
    }

    addMeasuringPoints(measuringPoints: IMeasuringPointSummary[]) {
        if (!measuringPoints) return;

        const links = measuringPoints.map(x => {
            return {
                measuringPoint: MeasuringPointUtils.toNavigator(x),
                measuringPointSummary: x,
                analyzerConfiguration: {
                    type: x.analysisTypeId
                }
            } as ILinkMeasurement;
        });
        this.addData(links);

        if (this.sendOutput) {
            this.add.next(links);
            this.selectRow(links.takeLastOrDefault());
        }
    }
}