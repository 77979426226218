import { NgModule } from "@angular/core";
import { LocationMapComponent } from "./components/location-map/location-map.component";
import { LocationsRoutingModule } from "./locations-routing.module";
import { MapBasicModule } from "../map-basic/map-basic.module";
import { SharedModule } from "../shared/shared.module";
import { SearchPanelComponent } from "./components/search-panel/search-panel.component";
import { ResultContainerComponent } from "./components/search-panel/results/result-container/result-container.component";
import { FilterContainerComponent } from "./components/search-panel/filters/filter-container/filter-container.component";
import { SearchResultComponent } from "./components/search-panel/results/search-result/search-result.component";
import { LocationPopupComponent } from "./components/location-popup/location-popup.component";
import { GenericTileComponent } from "./components/location-popup/tiles/generic-tile/generic-tile.component";
import { TrafficLightTileComponent } from "./components/location-popup/tiles/traffic-light-tile/traffic-light-tile.component";
import { DeviceStatusComponent } from "./components/location-popup/tiles/device-status/device-status.component";
import { TypesFilterDialogComponent } from "./components/search-panel/filters/types-filter-dialog/types-filter-dialog.component";
import { StatusFilterDialogComponent } from "./components/search-panel/filters/status-filter-dialog/status-filter-dialog.component";
import { ProjectsFilterDialogComponent } from "./components/search-panel/filters/projects-filter-dialog/projects-filter-dialog.component";
import { ProjectsFilterComponent } from "./components/search-panel/filters/projects-filter-dialog/projects-filter/projects-filter.component";
import { SearchBoxComponent } from "./components/search-panel/search-box/search-box.component";
import { LocationSharedModule } from "../location-shared";
import { UploadKmlDialogComponent } from "./components/upload-kml-dialog/upload-kml.dialog";
import { KmlResultComponent } from "./components/upload-kml-dialog/kml-result/kml-result.component";
import { FilterService } from "./services/filter.service";
import { ViewStateService } from "./services/view-state.service";
import { MeasuringPointCctvConfigurationComponent } from "./components/details/measuring-point-cctv-configuration/measuring-point-cctv-configuration.component";
import { MeasuringPointCountingZoneConfigurationComponent } from "./components/details/measuring-point-counting-zone-configuration/measuring-point-counting-zone-configuration.component";
import { MeasuringPointDetailContainerComponent } from "./components/details/measuring-point-detail-container/measuring-point-detail-container.component";
import { MeasuringPointDetailComponent } from "./components/details/measuring-point-detail/measuring-point-detail.component";
import { MeasuringPointFloatingCarComponent } from "./components/details/measuring-point-floating-car/measuring-point-floating-car.component";
import { MeasuringPointLightModeConfigurationComponent } from "./components/details/measuring-point-light-mode-configuration/measuring-point-light-mode-configuration.component";
import { MeasuringPointLinksComponent } from "./components/details/measuring-point-links/measuring-point-links.component";
import { MeasuringPointLocationComponent } from "./components/details/measuring-point-location/measuring-point-location.component";
import { MeasuringPointParkingComponent } from "./components/details/measuring-point-parking/measuring-point-parking.component";
import { MeasuringPointStatusComponent } from "./components/details/measuring-point-status/measuring-point-status.component";
import { TotemDisplayConfigurationComponent } from "./components/details/measuring-point-totem-display/totem-display-configuration/totem-display-configuration.component";
import { TotemDisplayFieldsDialogComponent } from "./components/details/measuring-point-totem-display/totem-display-fields-dialog/totem-display-fields.dialog";
import { MeasuringPointUploadContainerComponent } from "./components/details/measuring-point-upload-container/measuring-point-upload-container.component";
import { MeasuringPointUploadComponent } from "./components/details/measuring-point-upload/measuring-point-upload.component";
import { MeasuringPointValidationComponent } from "./components/details/measuring-point-validation/measuring-point-validation.component";
import { MeasuringPointVmsModeConfigurationComponent } from "./components/details/measuring-point-vms-mode-configuration/measuring-point-vms-mode-configuration.component";
import { VmsImageEditorDialogComponent } from "./components/details/vms-image-editor-dialog/vms-image-editor.dialog";
import { VmsImageLibrarySelectorDialogComponent } from "./components/details/vms-image-library-selector-dialog/vms-image-library-selector.dialog";
import { vmsImageEditorModule } from "../vms-image-editor/vms-image-editor.module";
import { LiveTilesModule } from "../live-tiles/live-tiles.module";
import { TrackerTileComponent } from "./components/location-popup/tiles/tracker-tile/tracker-tile.component";
import { StatusHeaderComponent } from "./components/location-popup/status-header/status-header.component";
import { DeviceStatusHeaderComponent } from "./components/location-popup/status-header/device-status-header/device-status-header.component";
import { AlertBadgeComponent } from "./components/location-popup/tiles/shared/alert-badge/alert-badge.component";
import { UserMessageComponent } from "./components/location-popup/tiles/shared/user-message/user-message.component";
import { TextFieldAnimationComponent } from "src/app/modules/shared/components/totem-display-preview/text-field-animation/text-field-animation.component";
import { AnalysisTypeViewComponent } from "src/app/modules/shared/components/analysis-type-view/analysis-type-view.component";
import { MeasuringPointArrowBoardConfigurationComponent } from "./components/details/measuring-point-arrow-board-configuration/measuring-point-arrow-board-configuration.component";
import { MeasuringPointExternalInputConfigurationComponent } from "./components/details/measuring-point-external-input-configuration/measuring-point-external-input-configuration.component";
import { ArrowBoardTileComponent } from "./components/location-popup/tiles/arrow-board-tile/arrow-board-tile.component";
import { MeasuringPointVehicleConfigurationComponent } from "./components/details/measuring-point-vehicle-configuration/measuring-point-vehicle-configuration.component";
import { MeasuringPointArrowBoardTimelineComponent } from "./components/details/measuring-point-arrow-board-timeline/measuring-point-arrow-board-timeline.component";
import { VehicleTileComponent } from "./components/location-popup/tiles/vehicle-tile/vehicle-tile.component";

@NgModule({
    imports: [
        LocationsRoutingModule,
        MapBasicModule,
        SharedModule,
        LocationSharedModule,
        vmsImageEditorModule,
        LiveTilesModule,
        TextFieldAnimationComponent,
        AnalysisTypeViewComponent,
    ],
    providers: [
        FilterService,
        ViewStateService
    ],
    declarations: [
        LocationMapComponent,
        LocationPopupComponent,
        SearchPanelComponent,
        SearchBoxComponent,
        ResultContainerComponent,
        SearchResultComponent,
        FilterContainerComponent,
        GenericTileComponent,
        TrafficLightTileComponent,
        TrackerTileComponent,
        ArrowBoardTileComponent,
        VehicleTileComponent,
        DeviceStatusComponent,
        TypesFilterDialogComponent,
        StatusFilterDialogComponent,
        ProjectsFilterDialogComponent,
        ProjectsFilterComponent,
        UploadKmlDialogComponent,
        KmlResultComponent,
        MeasuringPointCountingZoneConfigurationComponent,
        MeasuringPointArrowBoardConfigurationComponent,
        MeasuringPointArrowBoardTimelineComponent,
        VmsImageLibrarySelectorDialogComponent,
        VmsImageEditorDialogComponent,
        MeasuringPointExternalInputConfigurationComponent,
        MeasuringPointVmsModeConfigurationComponent,
        MeasuringPointCctvConfigurationComponent,
        MeasuringPointVehicleConfigurationComponent,
        MeasuringPointFloatingCarComponent,
        MeasuringPointUploadComponent,
        MeasuringPointUploadContainerComponent,
        MeasuringPointValidationComponent,
        MeasuringPointLinksComponent,
        MeasuringPointParkingComponent,
        MeasuringPointLightModeConfigurationComponent,
        MeasuringPointStatusComponent,
        TotemDisplayConfigurationComponent,
        TotemDisplayFieldsDialogComponent,
        MeasuringPointDetailComponent,
        MeasuringPointDetailContainerComponent,
        MeasuringPointLocationComponent,
        StatusHeaderComponent,
        DeviceStatusHeaderComponent,
        AlertBadgeComponent,
        UserMessageComponent,
    ],
    exports: [
        LocationMapComponent,
    ],
})
export class LocationsModule {
}
