import { Constants } from "src/app/constants/constants";

export interface IBasicMarkerOptions extends google.maps.marker.AdvancedMarkerElementOptions {
    zIndex?: number | null;
    position: google.maps.LatLng;
    draggable?: boolean;
    icon?: google.maps.Icon;
    enableLabel?: boolean;
    labelContent?: string;
    labelAnchor?: google.maps.Point;
    labelClass?: string;
    labelColor?: string;
    labelVisible?: boolean;
    textColor?: string;
    disableCluster?: boolean;
    disableUnload?: boolean;
}

export class BasicMarkerOptions implements IBasicMarkerOptions {
    constructor(position: google.maps.LatLng) {
        this.position = position;
        this.draggable = false;
        this.enableLabel = true;
        this.labelAnchor = new google.maps.Point(0, 0);
        this.labelClass = "m-map-marker-label";
        this.labelColor = Constants.markerLabelColorDefault;
        this.labelVisible = true;
        this.textColor = "#fff";
        // this.content = new google.maps.marker.PinElement({
        //     background: "red"
        // }).element;
    }

    zIndex?: number;
    position: google.maps.LatLng;
    draggable?: boolean;
    icon?: google.maps.Icon;
    enableLabel?: boolean;
    labelContent?: string;
    labelAnchor?: google.maps.Point;
    labelClass?: string;
    labelColor?: string;
    labelVisible?: boolean;
    textColor?: string;
    disableCluster?: boolean;
    disableUnload?: boolean;
    collisionBehavior?: google.maps.CollisionBehavior;
    content?: Node;
    gmpClickable?: boolean;
    gmpDraggable?: boolean;
    map?: google.maps.Map;
    title?: string;
}