import { Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DialogComponentBase } from "../dialog/dialog.component";
import QrScanner from "qr-scanner";
import { ToastService } from "src/app/services/toast.service";
import { TranslateService } from "@ngx-translate/core";

export enum ScanMode {
    ScanDevice = 0
}

@Component({
    selector: "app-scanner-dialog",
    templateUrl: "./scanner-dialog.component.html",
})
export class ScannerDialogComponent extends DialogComponentBase implements OnInit, OnDestroy {
    @ViewChild("qrVideo") videoElem: any;
    result: QrScanner.ScanResult | undefined;
    mode: ScanMode;
    onSuccess: ((result: string) => void) | undefined;

    qrScanner: QrScanner | undefined;
    constructor(private ngZone: NgZone,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService) {
        super();
    }
    ngOnDestroy(): void {
        this.qrScanner?.destroy();
    }
    protected afterShow(): void {
        if (!this.qrScanner) {
            this.qrScanner = new QrScanner(this.videoElem.nativeElement, result => {
                this.result = result;
                if (this.result && this.result.data) {
                    this.qrScanner?.stop();
                    this.ngZone.run(() => {
                        if (this.mode === ScanMode.ScanDevice) {
                            this.handleScanDeviceMode(this.result.data);
                        }
                        this.close();

                    });
                }
            }, {
                onDecodeError: (error: any) => { },
                highlightScanRegion: true,
                highlightCodeOutline: true
            });
        }
        this.qrScanner.start();
    }
    show(mode: ScanMode, onSuccess?: (result: string) => void) {
        this.mode = mode;
        this.onSuccess = onSuccess;
        this.openDialog();
    }
    ngOnInit(): void {
    }

    cancel() {
        this.qrScanner.stop();
        this.close();
    }
    protected onClose(): void {
        this.qrScanner?.stop();
    }

    onHide(): void {
        this.qrScanner?.stop();
    }

    handleScanDeviceMode(qrCode: string) {
        const match = /https:\/\/signcoserv.be\/qr\/(.*)\//.exec(qrCode);
        if (match) {
            const code = match[1];
            this.onSuccess?.(code);
        } else {
            // toast error
            this.toastService.error(this.translateService.instant("scanner.codeNotRecognized"));

        }

    }

}