import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager, MeasuringPointUtils, MapUtils } from "src/app/utilities";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { IBasicMarker, ArrowMarker, BasicMapComponent } from "src/app/modules/map-basic";

@Component({
    selector: "app-measuring-point-expand",
    templateUrl: "./measuring-point-expand.component.html"
})
export class MeasuringPointExpandComponent implements OnChanges, OnDestroy {
    @Input() measuringPoint: IMeasuringPoint;
    @Input() editCommand: boolean;

    gmap: BasicMapComponent;
    locationMarker: IBasicMarker;

    canCreateMeasuringPointForOthers = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly changeDetectionRef: ChangeDetectorRef) {
        const authorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe(() => {
            this.canCreateMeasuringPointForOthers = this.globalEventsService.hasMultipleOrganizations();
        });
        this.subscriptionManager.add("currentRightsSubscription", authorizationInfoSubscription);


    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    handleMapReady(gmap: BasicMapComponent) {
        this.gmap = gmap;
        this.initialize();
    }

    initialize() {
        if (!this.measuringPoint || !this.gmap) return;

        const coordinate = MapUtils.toLatLng(this.measuringPoint.location.coordinate);
        this.gmap.map.setOptions({
            zoom: 18,
            disableDoubleClickZoom: true,
            clickableIcons: false,
            gestureHandling: "none",
            keyboardShortcuts: false,
            scrollwheel: false,
            center: coordinate
        });

        if (this.locationMarker) {
            this.locationMarker.setMapInternal(null);
        }

        const analysisTypeId = this.measuringPoint.analysisTypeId;
        const supportRotation = MeasuringPointUtils.analysisTypeSupportsDirectionalRotation(analysisTypeId);
        const heading = this.measuringPoint.heading as number;
        this.locationMarker = ArrowMarker.create(coordinate, heading, supportRotation);

        this.locationMarker.setMapInternal(this.gmap.map);

        this.changeDetectionRef.detectChanges();
    }
}