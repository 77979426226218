<div #container
     class="panels"
     [class.is-vertical]="isVertical"
     [class.is-horizontal]="!isVertical">
    <div #firstPanel
         class="panel">
        <ng-content select="[first-panel]"></ng-content>
    </div>

    <div #divider
         class="divider"
         (mousedown)="onDragStart($event)">

        <div class="divider__handle">
            <app-svg-icon icon="grip-dots"
                          [divStyle]="{'width': '16px', 'height': '16px'}"></app-svg-icon>
        </div>
    </div>

    <div #secondPanel
         class="panel">
        <ng-content select="[second-panel]"></ng-content>
    </div>
</div>