import { ManageUserDialogComponent } from "src/app/modules/shared/components/manage-user-dialog/manage-user.dialog";
import { Component, ElementRef, input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { UsersComponent } from "src/app/modules/shared/components/users/users.component";
import { IOrganization } from "src/app/models/user";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-user-management",
    templateUrl: "./user-management.component.html"
})
export class UserManagementComponent implements OnDestroy, OnChanges {
    @ViewChild(ManageUserDialogComponent, { static: true }) manageUserDialog: ManageUserDialogComponent;
    @ViewChild(UsersComponent, { static: true }) usersComponent: UsersComponent;

    canCreateUser = false;
    canDeleteUser = false;
    organizationInput = input<IOrganization>();
    organization: IOrganization;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService
    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");

        if (this.organizationInput()) {
            this.organization = this.organizationInput();
        }

        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateUser = rights?.hasBackendRight(BackendRights.EditUser);
            this.canDeleteUser = rights?.hasBackendRight(BackendRights.DeleteUser);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["organizationInput"]) {
            this.organization = changes["organizationInput"].currentValue;
            this.usersComponent.organizationId = this.organization?.id;
        }
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
    create() {
        this.manageUserDialog.create(() => {
            this.usersComponent.reload();
        }, this.organization?.id);
    }
    updateComponent() {
        this.usersComponent.reload();
    }

    setOrganization(organization: IOrganization) {
        this.organization = organization;
    }
}
