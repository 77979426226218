<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingMapIcon ? "mapIcons.edit" : "mapIcons.create") | translate}}
    </p-header>

    <form *ngIf="manageMapIconForm"
          class="p-16"
          [formGroup]="manageMapIconForm"
          autocomplete="off"
          novalidate>

        <div class="mt-16 mb-16"
             *ngIf="manageMapIconForm.get('codeStringResource')">
            <app-model-translation-form [parentFormGroup]="manageMapIconForm"
                                        formGroupName="codeStringResource"
                                        controlLabel="general.code"
                                        [multiline]="false"></app-model-translation-form>
        </div>

        <hr />

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageMapIconForm.get('labelColor').showErrors }">

            <label class="col-4"
                   for="code">
                {{"mapIcons.labelColor" | translate}}
            </label>

            <div class="col-8">
                <p-colorPicker formControlName="labelColor"
                               #labelColorInput></p-colorPicker>

                <app-form-validation [control]="manageMapIconForm.get('labelColor')"
                                     [input]="labelColorInput">
                </app-form-validation>

            </div>
        </div>

        <div class="m-form-group row mt-8"
             [ngClass]="{ 'has-error': manageMapIconForm.get('description').showErrors }">

            <label class="col-4"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-8">
                <textarea rows="3"
                          placeholder="{{'general.description' | translate}}"
                          id="description"
                          class="p-inputtextarea"
                          formControlName="description"
                          #descriptionInput></textarea>

                <app-form-validation [control]="manageMapIconForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row mt-8">

            <label class="col-4"
                   for="icon">
                {{"mapIcons.icon" | translate}}
            </label>

            <div class="col-8">
                <div class="col-12"
                     style="display: flex;flex-direction: row; padding-left: 0px;">
                    <label for="fileInput"
                           class="p-inputtext m-input-file m-input-group-w-icon"
                           style="padding-left: 0px;">
                        <app-svg-icon icon="upload"
                                      class="ml-auto">
                        </app-svg-icon>

                        <input type="file"
                               id="fileInput"
                               accept="image/svg+xml"
                               #file
                               onclick="value = null"
                               (change)="setImage($event.target.files)" />
                    </label>

                </div>

                <!-- preview -->
                <ng-container *ngIf="!newPreview && !!existingMapIcon?.states;else newPreviewTemplate">
                    <div class="row mt-8">
                        <ng-container *ngFor="let state of existingMapIcon.states">
                            <img *ngIf="state.default"
                                 [src]="state.default.url | safeHtml"
                                 [style.width]="state.default.width"
                                 [style.height]="state.default.height"
                                 class="ml-8" />
                        </ng-container>
                    </div>
                </ng-container>

                <!-- preview if different icon is selected (preview re-fetched from BE) -->
                <ng-template #newPreviewTemplate>
                    <div class="row mt-8">
                        <img *ngIf="newPreviewNormal"
                             [src]="newPreviewNormal"
                             class="ml-8" />

                        <img *ngIf="newPreviewLow"
                             [src]="newPreviewLow"
                             class="ml-8" />
                        <img *ngIf="newPreviewMedium"
                             [src]="newPreviewMedium"
                             class="ml-8" />
                        <img *ngIf="newPreviewHigh"
                             [src]="newPreviewHigh"
                             class="ml-8" />
                    </div>
                </ng-template>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>