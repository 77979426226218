<!-- action buttons -->
<!-- <div class="container"
     *ngIf="Command">
    <div class="m-btn-group m-btn-group-right">
    </div>
</div> -->

<form *ngIf="alertHistoryForm"
      class="p-16"
      [formGroup]="alertHistoryForm"
      autocomplete="off"
      novalidate>

    <div class="row">
        <div class="col-6">
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': alertHistoryForm.get('from')?.showErrors }">

                <label class="col-4"
                       for="from">
                    {{"general.from" | translate}}
                </label>

                <div class="col-8">
                    <p-calendar id="from"
                                appendTo="body"
                                formControlName="from"
                                [style]="{'width':'100%'}"
                                autoWidth="false"
                                [showIcon]="true"
                                [maxDate]="alertHistoryForm.get('to')?.value"
                                [hourFormat]="calendarSettings.hourFormat"
                                [dateFormat]="calendarSettings.dateFormat"
                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                [showTime]="true"
                                [monthNavigator]="false"
                                [yearNavigator]="false"
                                [yearRange]="primeComponentService.calendarYearRange"
                                #fromInput></p-calendar>

                    <app-form-validation [control]="alertHistoryForm.get('from')"
                                         [input]="fromInput">
                    </app-form-validation>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': alertHistoryForm.get('to')?.showErrors }">

                <label class="col-4"
                       for="to">
                    {{"general.until" | translate}}
                </label>

                <div class="col-8">
                    <p-calendar id="to"
                                appendTo="body"
                                formControlName="to"
                                [style]="{'width':'100%'}"
                                autoWidth="false"
                                [showIcon]="true"
                                [minDate]="alertHistoryForm.get('from')?.value"
                                [hourFormat]="calendarSettings.hourFormat"
                                [dateFormat]="calendarSettings.dateFormat"
                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                [showTime]="true"
                                [monthNavigator]="false"
                                [yearNavigator]="false"
                                [yearRange]="primeComponentService.calendarYearRange"
                                #toInput></p-calendar>

                    <app-form-validation [control]="alertHistoryForm.get('to')"
                                         [input]="toInput">
                    </app-form-validation>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            @if(timelineConfig){
            <app-timeline-activity [config]="timelineConfig"></app-timeline-activity>
            }@else{
            @if(!alertHistory){
            <app-loading-spinner></app-loading-spinner>
            }@else{
            <!-- TODO empty, no data, show something to notify the user of the good news -->
            <app-svg-icon style="display: inline-block"
                          icon="checkmark"
                          fill="green"></app-svg-icon>
            {{"reports.alarms.noAlarm" | translate}}
            }
            }
        </div>
    </div>
</form>