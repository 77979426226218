import { Component, Input, OnChanges, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { IAuditTrail } from "src/app/models/audit-trail";
import { IJournal } from "src/app/models/journal";
import { IDevice } from "src/app/models/device";
import { IMeasuringPoint, IMeasuringPointNavigator } from "src/app/models/measuring-point";

class DayChangeOverview {
    id: number;
    journal: IJournal;
    auditTrail: IAuditTrail;
    measuringPoint: IMeasuringPointNavigator;
    organizationId: number;
    device: IDevice;

    constructor(readonly timestamp: Date) {

    }

    static fromJournal(journal: IJournal): DayChangeOverview {
        const dayChangeOverview = new DayChangeOverview(journal.timestamp);
        dayChangeOverview.id = journal.id;
        dayChangeOverview.journal = journal;
        dayChangeOverview.device = journal.device;
        dayChangeOverview.measuringPoint = journal.measuringPoint;
        dayChangeOverview.organizationId = journal.organizationId;
        return dayChangeOverview;
    }

    static fromAuditTrail(auditTrail: IAuditTrail): DayChangeOverview {
        const dayChangeOverview = new DayChangeOverview(auditTrail.timeStamp);
        // Avoid conflicts between `journal.id` and `auditTrail.id`
        dayChangeOverview.id = 0 - auditTrail.id;
        dayChangeOverview.auditTrail = auditTrail;
        dayChangeOverview.device = auditTrail.device;
        dayChangeOverview.measuringPoint = auditTrail.measuringPoint;
        dayChangeOverview.organizationId = auditTrail.ownerId;
        return dayChangeOverview;
    }
}

@Component({
    selector: "app-measuring-point-day-change-overview",
    templateUrl: "./measuring-point-day-change-overview.component.html"
})
export class MeasuringPointDayChangeOverviewComponent implements OnChanges, AfterViewInit {
    @Input() measuringPoint: IMeasuringPoint;
    @Input() date: Date;

    overviews: DayChangeOverview[];
    private journals: IJournal[];
    private auditTrails: IAuditTrail[];
    private initialized = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly measuringPointApi: MeasuringPointApi) {
    }

    ngOnChanges() {
        if (!this.initialized) return;

        this.fetchData();
    }

    ngAfterViewInit() {
        this.initialized = true;

        this.fetchData();
    }

    overviewTrackByFn(index: number, item: DayChangeOverview) {
        return item.id;
    }

    private async fetchData() {
        if (!this.measuringPoint || !this.date) return;

        this.journals = null;
        this.auditTrails = null;

        this.measuringPointApi.getEventOverview$(this.measuringPoint.id, this.date, new Date(this.date).addDays(1)).subscribe(result => {
            this.auditTrails = result.audits;
            this.journals = result.journals;
            this.bundleData();
        });
    }

    private async bundleData() {
        const journalDayOverviews = (this.journals || []).map(x => DayChangeOverview.fromJournal(x));
        const auditTrailDayOverviews = (this.auditTrails || []).map(x => DayChangeOverview.fromAuditTrail(x));

        this.overviews = journalDayOverviews.concat(auditTrailDayOverviews).sortBy(x => x.timestamp.getTime());
        this.cd.detectChanges();
    }
}