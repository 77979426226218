import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective, UntypedFormBuilder, Validators } from "@angular/forms";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";
import { AnalyzerConfiguration, DisplaySide } from "src/app/models/upload";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { SelectItem } from "primeng/api";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ITotemDisplayConfiguration } from "src/app/models/totem-display-configuration";

@Component({
    selector: "app-device-link-dekimo-display-link",
    templateUrl: "./device-link-dekimo-display-link.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkDekimoDisplayLinkComponent extends DeviceLinkFormGroupComponent implements OnInit {
    @Input() measuringPointId: number;

    fieldFormArray: SigncoFormArray;
    displaySides: ViewModelEnum[];
    totemDisplayConfiguration: ITotemDisplayConfiguration;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly domainDataService: DomainDataService
    ) {
        super();
    }

    async ngOnInit() {
        this.displaySides = await this.domainDataService.get(DomainData.DisplaySide);
    }

    getFieldConfiguration(id: number) {
        return this.totemDisplayConfiguration.fields.filter(x => x.id === id)[0];
    }


    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (!analyzerConfiguration.dekimoDisplayLink) {
            // We don't have anything to display
            if (this.fgd.form.get("dekimoDisplayLink")) this.fgd.form.removeControl("dekimoDisplayLink");
            this.form = null;
            return false;
        }

        // We retrieve the mp so we can show the name of the fields, instead of only the ids.
        const mp = await this.measuringPointApi.get$(this.measuringPointId).toPromise();
        this.totemDisplayConfiguration = mp.totemDisplayConfiguration;

        this.fieldFormArray = this.formBuilder.array([]) as SigncoFormArray;

        for (const fieldLink of this.analyzerConfiguration.dekimoDisplayLink.fieldLinks) {
            this.fieldFormArray.push(this.formBuilder.group({
                totemFieldId: fieldLink.totemFieldId,
                displaySide: [fieldLink.displaySide, Validators.required],
                startTile: [fieldLink.startTile, Validators.required]
            }));
        }

        this.form = this.formBuilder.group({
            fieldLinks: this.fieldFormArray,
        }) as SigncoFormGroup;

        this.fgd.form.addControl("dekimoDisplayLink", this.form);

        return true;
    }
}
