import { Injectable } from "@angular/core";
import { ISignScan, SignScanCreator, SignScanUpdater } from "../models/sign-scan";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class SignScanApi extends ApiService<ISignScan, SignScanCreator, SignScanUpdater> {

    getRoute(): string {
        return "SignScans";
    }
}