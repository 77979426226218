import { Injectable } from "@angular/core";
import { ISignCategory, SignCategoryCreator } from "../models/signcategory";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class SignCategoryApi extends ApiService<ISignCategory, SignCategoryCreator, SignCategoryCreator> {

    getRoute(): string {
        return "SignCategories";
    }
}