import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileUtils } from "src/app/utilities";
import { saveAs } from "file-saver";

export class DownloadedFile {
    file: File;
    save: () => void;
    toResourceUrl: () => string;

    constructor(public blob: Blob, name: string) {
        this.file = new File([blob], name, { type: FileUtils.isPhotoUrl(name) ? `image/${FileUtils.getExtension(name)}` : blob?.type });

        this.save = () => {
            if (!this.file.name) {
                console.error("Cannot download file without filename", this);
                return;
            }

            saveAs(this.blob, this.file.name);
        };

        this.toResourceUrl = () => {
            if (!this.blob || !this.blob.size) return null;
            return window.URL.createObjectURL(this.blob);
        };
    }
}

@Injectable({
    providedIn: "root"
})
export class DownloadFileService {

    constructor(
        private readonly httpClient: HttpClient) {
    }

    downloadBlob(
        url: string,
        postData?: {},
        headers?: HttpHeaders,
        params?: HttpParams): Promise<DownloadedFile> {

        return new Promise<DownloadedFile>((resolve, reject) => {

            const onSuccess = (result: HttpResponse<Blob>) => {

                let filename: string = null;
                const disposition = result.headers.get("Content-Disposition");

                if (disposition) {
                    filename = disposition.contains("\"")
                        ? disposition.match(/filename="(.+)";/)[1]
                        : disposition.match(/filename=(.+);/)[1];
                } else {
                    const lastPartOfPath = url.split("/").takeLastOrDefault();
                    filename = lastPartOfPath.contains(".") ? lastPartOfPath : null;
                }

                const downloadedFile = new DownloadedFile(result.body, filename);
                resolve(downloadedFile);
            };

            const onError = (e: Response) => {
                reject(e);
            };

            if (!postData) {
                this.httpClient.get(url,
                    {
                        headers: headers,
                        params: params,
                        observe: "response",
                        responseType: "blob"
                    }).subscribe(onSuccess, onError);
            } else {
                this.httpClient.post(url, postData,
                    {
                        headers: headers,
                        params: params,
                        observe: "response",
                        responseType: "blob"
                    }).subscribe(onSuccess, onError);
            }
        });
    }
}
