import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, NgZone, Injectable, ChangeDetectorRef, Injector, ViewChildren, QueryList, Directive } from "@angular/core";
import { FilterMetadata, LazyLoadEvent, SelectItem, SortEvent, FilterService } from "primeng/api";
import { ISearchResult, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { ApiServiceBase, CacheOptions } from "src/app/resource/api";
import { BehaviorSubject, Observable } from "rxjs";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { DocumentEventService } from "src/app/services/document-event.service";
import { SubscriptionManager, NumberUtils, JsonUtils } from "src/app/utilities";
import { DomainDataService } from "src/app/services/domain-data.service";
import { IServiceModelBase } from "src/app/models/servicemodelbase";
import { TranslateService } from "@ngx-translate/core";
import { MapDataService } from "src/app/services/map-data.service";
import { ResizeService } from "src/app/services/resize.service";
import { MultiSelect } from "primeng/multiselect";
import { Dropdown } from "primeng/dropdown";
import { Calendar } from "primeng/calendar";
import { saveAs } from "file-saver";
import { Table } from "primeng/table";
import * as Flatted from "flatted";
import * as lodash from "lodash";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthorizationInfo } from "src/app/models/user";
import { Rights } from "src/app/models/rights";

export enum FilterType {
    None,
    Text,
    Number,
    Date,
    Dropdown,
    MultiSelect,
    Boolean
}

export enum ColumnType {
    Data,
    Icon,
    Date,
    Minimal,
    Command,
    Checkbox,
    BoolCheckbox
}

export enum ColumnVisibility {
    NeverHide = 0,
    HideMini = 1,
    HideCompact = 2
}

@Injectable({
    providedIn: "root"
})
export class TableService {
    constructor(
        readonly globalEventsService: GlobalEventsService,
        readonly primeComponentService: PrimeComponentService,
        readonly mapDataService: MapDataService,
        readonly domainDataService: DomainDataService,
        readonly translateService: TranslateService,
        readonly impersonationService: ImpersonationService,
        readonly filterService: FilterService,
        readonly resizeService: ResizeService,
        readonly zone: NgZone,
        readonly injector: Injector
    ) {

    }
}

export class TableCommand<T> {
    text: string;
    icon: string;
    click: (rowData: T) => void;
    disabledFunc?: () => boolean;
    validFunc?: () => boolean; // Static valid func
    rowValidFunc?: (rowData: T) => boolean; // Per-row valid check
    ignoreSpace?: boolean; // When 2 commands exchange one another, ex delete / restore, we want to count the space of 1
}

export class ColumnOptions {
    filterType?: FilterType;
    displayDropdownFilter?: boolean;
    sortable?: boolean;
    visibility?: ColumnVisibility;
    type?: ColumnType;
    width?: number;
    resizable?: boolean;
    adminOnly?: boolean;
    filterFunc?: () => boolean;
    customExcelSerializeMethod?: (value: any) => string; // when exporting to excel some complex objects, we can pass function with which we can define how values belonging to that column can be serialized
    tooltip?: string;
    hidden?: boolean;
    domainDataType?: string;

    stringResourcePropertyName?: string; // for model localization table we need name of property which contains stringResourceId (for properties which are translatable)
}

export class TableColumn extends ColumnOptions {

    filterSet = new BehaviorSubject<TableFilterComponent>(null);

    table: TableComponentBase<any>;
    hidden?: boolean;
    filterMatchMode?: string;
    filterMin?: number;
    filterMax?: number;
    step?: number;
    ngStyle?: { [key: string]: any } = {};
    headerStyle?: { [key: string]: any } = {};
    filterComponent: TableFilterComponent;
    order: number;

    private _filterOptions?: SelectItem[];
    set filterOptions(filterOptions: SelectItem[]) {
        this._filterOptions = filterOptions;

        if (this.filterComponent) {
            this.filterComponent.updateCurrentFilterString();
        }
    }

    get filterOptions(): SelectItem[] {
        return this._filterOptions;
    }

    private readonly defaultOptions: ColumnOptions = {
        filterType: FilterType.None,
        displayDropdownFilter: false,
        sortable: false,
        visibility: ColumnVisibility.NeverHide,
        type: ColumnType.Data,
        width: null,
        resizable: null,
        adminOnly: false
    };

    constructor(
        public field: string,
        public header: string,
        options: ColumnOptions = {}) {

        super();

        Object.assign(this, this.defaultOptions, options);

        let suggestedWidth: number;
        let suggestedResizable: boolean;

        if (!this.type) {
            if (this.filterType === FilterType.Date) {
                this.type = ColumnType.Date;
            } else if (this.filterType === FilterType.Boolean) {
                this.type = ColumnType.Checkbox;
            } else {
                this.type = ColumnType.Data;
            }
        }

        if (this.type === ColumnType.Data) {
            suggestedResizable = true;
        }

        if (this.filterType === FilterType.Date) {
            this.filterMatchMode = "inside";
        }

        if (this.filterType === FilterType.MultiSelect) {
            this.filterMatchMode = "in";
        }

        if (this.type === ColumnType.Icon) {
            suggestedResizable = false;
            suggestedWidth = 80;
            this.ngStyle.textAlign = "center";
        }

        if (this.type === ColumnType.Date) {
            this.ngStyle.textAlign = "left";
            suggestedResizable = false;
            suggestedWidth = 160;
        }

        if (this.type === ColumnType.Minimal) {
            this.ngStyle.padding = "0";
            suggestedResizable = false;
            suggestedWidth = 32;
        }

        if (this.type === ColumnType.Command) {
            suggestedResizable = false;
            suggestedWidth = 166;
            this.order = -1; // Number.MAX_VALUE;
        }

        if (this.type === ColumnType.Checkbox || this.type === ColumnType.BoolCheckbox) {
            suggestedResizable = false;
            suggestedWidth = 66;
        }

        if (!this.resizable && this.resizable !== false) {
            this.resizable = suggestedResizable;
        }

        if (this.resizable === null || this.resizable === undefined) {
            this.resizable = true;
        }

        if (!NumberUtils.isValid(this.order)) {
            this.order = 0;
        }

        this.setWidth(this.width || suggestedWidth);

        // this.ngStyle.wordWrap = "break-word";
        this.ngStyle.whiteSpace = "wrap";
        this.ngStyle.overflow = "hidden";
        this.ngStyle.overflowX = "auto";
        this.ngStyle.textOverflow = "ellipsis";

        if (this.width < suggestedWidth && [ColumnType.Icon].includes(this.type)) {
            this.ngStyle.padding = "0";
        }
    }

    setWidth(width: number) {
        this.width = width;

        this.resetWidth();
    }

    resetWidth() {
        if (!this.width && this.width !== 0) {
            delete this.ngStyle.minWidth;
            delete this.ngStyle.maxWidth;
            this.ngStyle.width = "auto";
            return;
        }

        this.ngStyle.minWidth = this.width + "px";

        // if (!this.resizable) {
        this.ngStyle.width = this.ngStyle.maxWidth = this.ngStyle.minWidth;
        // } else {
        // this.ngStyle.width = "auto";
        // }
    }

    setFilterComponent(filterComponent: TableFilterComponent) {
        this.filterComponent = filterComponent;
        this.filterSet.next(this.filterComponent);
    }

    setTable(table: TableComponentBase<any>) {
        this.table = table;
        this.ngStyle.userSelect = "none";
    }
}

@Directive()
export abstract class TableComponentBase<T> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    table: Table;

    @ViewChild("tableRef", { static: true }) set setTable(table: Table) {
        if (this.table === table) return;

        this.table = table;

        if (this.table && !this._destroyed) {
            this.applyDefaults();
            this.onTableSet();
        }
    }

    rowElements: QueryList<ElementRef>;
    @ViewChildren("row", { read: ElementRef }) set setRowElements(rowElements: QueryList<ElementRef>) {
        this.rowElements = rowElements;

        if (this.scrollToFailure) {
            this.scrollTo(this.scrollToFailure);
        }
    }

    private scrollToFailure: T;

    @Input() headers = true;
    @Input() footer = true;
    @Input() emptyMessage = true;
    @Input() paginator = true;
    @Input() compact = false;
    @Input() mini = false;
    @Input() sortable = true;
    @Input() filter = true;
    @Input() stateKey: string;
    @Input() componentStyle: string;
    @Input() protected rowCount = 50;
    @Input() scrollPageToTop = false;
    @Input() selectionMode: "" | "single" | "multiple" = "single";
    @Input() selectionMax?: number;
    @Input() resizable = false;
    @Input() columnResizeMode: "fit" | "expand" = "expand";
    @Input() tableStyle: string;
    @Input() focusMainFilter = false;
    @Input() selectionBox = false;
    @Input() scrollHeight = "flex";
    @Input() delayedStart = false;
    @Input() enableInitialData = false;
    @Input() expandOnSelect = true;
    @Input() enableSigncoMultiSelectBehavior = false;
    @Input() scrollable = true;
    @Input() reorderable = false;
    @Input() stretchHeight = false;
    @Input() hidden = false;

    @Output() selected = new EventEmitter<T | T[]>();
    @Output() deselected = new EventEmitter<T | T[]>();
    @Output() filtered = new EventEmitter<void>();
    @Output() dataSet = new EventEmitter<void>();
    @Output() startedSearch = new EventEmitter<void>();

    private _destroyed = false;
    get destroyed(): boolean { return this._destroyed; }
    protected lastRequestTime: Date;

    get total(): number {
        return this.table ? this.table._totalRecords : 0;
    }

    lastSearchParameters: SearchParameters;
    protected readonly requestedActionsBeforeStart = new Array<() => void>();

    mainColumn: TableColumn;
    protected readonly selectionBoxColumn: TableColumn;
    protected readonly commandsColumn: TableColumn;
    protected readonly reorderColumn: TableColumn;

    protected commands = new Array<TableCommand<T>>();
    relevantCommands = new Array<TableCommand<T>>();

    columns = new Array<TableColumn>();
    relevantColumns = new Array<TableColumn>();

    pageLinks = 10;
    data = new Array<T>();
    initialData: T[] = new Array<T>();
    internalRowCount = this.rowCount;
    includeObsolete = false;

    started: boolean;
    loading = false;
    styleClass = "";
    singleSelected: T;

    selectClicks = new Array<{ row: T, shift: boolean }>();
    handlingMultiSelectBehavior: boolean;

    protected rights: Rights;

    protected calendarSettings: CalendarSettings;
    protected readonly mapDataServiceKey: string;
    protected readonly mapDataServiceFilterKey: string;
    protected subscriptionManager = new SubscriptionManager();

    constructor(
        protected readonly key: string,
        protected readonly elementRef: ElementRef<HTMLElement>,
        protected readonly services: TableService) {

        const calendarSettingsSubscription = this.services.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.mapDataServiceKey = this.services.mapDataService.createKey();

        // If filters and data retrieval use same key, it conflicts
        // For instance: users.component.ts
        //      That component gets organizations for filters
        //      But also gets organizations for data
        //      Using the same key, these conflict, and only 1 would get data
        this.mapDataServiceFilterKey = this.services.mapDataService.createKey();

        this.selectionBoxColumn = new TableColumn("selectionBox", "", { type: ColumnType.Checkbox, width: 40, sortable: true });
        this.commandsColumn = new TableColumn("commands", "", { type: ColumnType.Command });
        this.reorderColumn = new TableColumn("reorder", "", { type: ColumnType.Icon, width: 30 });

        this.services.impersonationService.subscribeToRoleImpersonation(this.key, () => {
            this.updateRelevantColumns();
        });

        this.services.globalEventsService.authorizationInfo$.subscribe((authorizationInfo: AuthorizationInfo) => {
            this.updateRelevantColumns();
        });
        const currentRightsSubscription = this.services.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    ngOnInit() {
        this.setStyle();

        this.updateSelectionBoxColumn();
        this.updateReorderableColumn();
        this.updateCommandColumn();

        this.startedSearch.next();
    }

    ngAfterViewInit() {
        if (!this.delayedStart) {
            this.start();
        }

        this.updateVariableColumnWidths();
    }

    ngOnDestroy() {
        this._destroyed = true;
        this.services.mapDataService.unsubscribe(this.mapDataServiceKey);
        this.services.mapDataService.unsubscribe(this.mapDataServiceFilterKey);
        this.services.impersonationService.unsubscribe(this.key);

        this.subscriptionManager.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        const tableSizeChange = changes["compact"] || changes["mini"];
        if (tableSizeChange) {
            this.updateRelevantColumns();
            this.setStyle();
            this.updateVariableColumnWidths();
        }

        const rowCountChange = changes["rowCount"];
        if (rowCountChange) {
            this.internalRowCount = this.rowCount;
            // this.reload();
        }

        const headersChange = changes["headers"];
        if (headersChange) {
            this.setStyle();
        }

        const styleChange = changes["componentStyle"];
        if (styleChange) {
            this.setComponentStyle();
        }

        const tableStyleChange = changes["tableStyle"];
        if (tableStyleChange) {
            this.setStyle();
        }

        const focusMainFilterChange = changes["focusMainFilter"];
        if (focusMainFilterChange) {
            this.subscribeToMainColumn();
        }

        const selectionModeChange = changes["selectionMode"];
        if (selectionModeChange) {
            this.onSelectionModeChange();
        }

        const selectionBoxChange = changes["selectionBox"];
        if (selectionBoxChange) {
            this.updateSelectionBoxColumn();
        }

        const reorderableChange = changes["reorderable"];
        if (reorderableChange) {
            this.updateReorderableColumn();
        }

        const filterChange = changes["filter"];
        if (filterChange) {
            this.updateVariableColumnWidths();
        }

        for (const key in changes) {
            if (key.endsWith("Command")) {
                this.updateCommandColumn();
                break;
            }
        }
    }

    columnTrackByFn(index: number, item: TableColumn) {
        return item.field;
    }

    commandTrackByFn(index: number, item: TableCommand<any>) {
        return item.text;
    }

    serviceModelTrackByFn(index: number, item: IServiceModelBase) {
        if (!item || !item.id) {
            console.error("[serviceModelTrackByFn]: No ID", item);
        }

        return item.id;
    }

    selectItemTrackByFn(index: number, item: SelectItem) {
        if (!item.value && !item.label) {
            console.error("[selectItemTrackByFn]: Not a SelectItem", item);
        }

        return item.value;
    }

    setRowCount(rowCount: number) {
        if (this.rowCount === rowCount) return;

        this.rowCount = rowCount;
        this.internalRowCount = this.rowCount;
    }

    start() {
        if (this.started) return;

        Promise.resolve().then(() => {
            if (this.started) {
                console.warn(`start(): ${this.key} was already started`);
                return;
            }

            this.started = true;

            this.onStart();

            for (const action of this.requestedActionsBeforeStart) {
                action.apply(this);
            }

            this.clearRequestedActions();
        });
    }

    addRequestedActionBeforeStart(func: () => void) {
        const existing = this.requestedActionsBeforeStart.find(x => x.toString() === func.toString());
        if (existing) {
            this.requestedActionsBeforeStart[this.requestedActionsBeforeStart.indexOf(existing)] = func;
        } else {
            this.requestedActionsBeforeStart.push(func);
        }
    }

    clearRequestedActions() {
        this.requestedActionsBeforeStart.length = 0;
    }

    setLoading(loading = true) {
        this.loading = loading;
    }

    onStart() { }

    reset() {
        if (!this.table) return;

        this.table.reset();
    }

    removeColumns(func: (x: TableColumn) => boolean) {
        this.columns = this.columns.filter(x => !func(x));
        this.updateRelevantColumns();
    }

    clearColumns() {
        this.columns = [];
        this.updateRelevantColumns();
    }

    addColumn(newColumn: TableColumn, index?: number) {
        if (this.columns.contains(newColumn)) return;

        newColumn.table = this;

        if (!NumberUtils.isValid(index)) {
            this.columns.push(newColumn);
        } else {
            this.columns.insert(newColumn, index);
        }

        this.setStyle();

        this.updateRelevantColumns();
    }

    removeColumn(column: TableColumn) {
        if (!this.columns.contains(column)) return;

        column.table = null;
        this.columns = this.columns.remove(column);
        this.setStyle();
        this.updateRelevantColumns();
    }

    updateRelevantColumns() {
        this.updateCommandColumn();

        let relevantColumns = this.columns.filter(x => !x.hidden);

        if (this.compact) {
            relevantColumns = relevantColumns.filter(x => x.visibility < ColumnVisibility.HideCompact);
        }

        if (this.mini) {
            relevantColumns = relevantColumns.filter(x => x.visibility < ColumnVisibility.HideMini);
        }

        if (!this.services.globalEventsService.getAuthorizationInfo()?.isDomainAdministrator) {
            relevantColumns = relevantColumns.filter(x => !x.adminOnly);
        }

        relevantColumns = relevantColumns.filter(x => !x.filterFunc || x.filterFunc());

        this.relevantColumns = relevantColumns.filter(x => !NumberUtils.isValid(x.order)).concat(relevantColumns.filter(x => NumberUtils.isValid(x.order)).sortBy(x => x.order));
    }

    setMainColumn(column: TableColumn) {
        if (this.mainColumn) {
            this.mainColumn.filterSet.unsubscribe();
        }

        this.mainColumn = column;

        this.subscribeToMainColumn();
    }

    subscribeToMainColumn() {
        if (!this.mainColumn) return;

        this.mainColumn.filterSet.subscribe(filterComponent => {
            if (!filterComponent || !this.focusMainFilter) return;

            filterComponent.open();
        });
    }

    updateSelectionBoxColumn() {
        if (this.selectionBox) {
            this.addColumn(this.selectionBoxColumn, this.reorderable ? 1 : 0);
        } else {
            this.removeColumn(this.selectionBoxColumn);
        }
    }

    registerCommand(command: TableCommand<T>) {
        if (!command.disabledFunc) {
            command.disabledFunc = () => false;
        }

        if (!command.validFunc) {
            command.validFunc = () => true;
        }

        if (!command.rowValidFunc) {
            command.rowValidFunc = () => true;
        }

        this.commands.push(command);

        if (this.started) {
            this.updateCommandColumn();
        }
    }

    updateReorderableColumn() {
        if (this.reorderable) {
            this.addColumn(this.reorderColumn, this.selectionBox ? 1 : 0);
        } else {
            this.removeColumn(this.reorderColumn);
        }
    }

    updateCommandColumn() {
        const relevantCommands = new Array<TableCommand<T>>();
        for (const command of this.commands) {
            if (command.validFunc()) {
                relevantCommands.push(command);
            }
        }

        this.relevantCommands = relevantCommands;

        const commandCount = this.relevantCommands.filter(x => !x.ignoreSpace).length;

        if (commandCount > 0) {
            this.commandsColumn.setWidth((commandCount * 36) + 17);
            const commandColumnIndex = +this.selectionBox + +this.reorderable;
            this.addColumn(this.commandsColumn, commandColumnIndex);
        } else {
            this.removeColumn(this.commandsColumn);
        }
    }

    updateVariableColumnWidths() {

    }

    getObsoleteClass(row: { isObsolete: boolean }): string {
        return row.isObsolete ? "row-obsolete" : null;
    }

    canLoad(): boolean {
        return true;
    }

    getCurrentData(): T[] {
        if (!this.table) return new Array<T>();
        return this.table.filteredValue || this.data;
    }

    getRouteParams(): { [key: string]: string } {
        return null;
    }

    getSearchParameters(): SearchParameters {
        return null;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        return null;
    }

    reload(forceReload = true) {
        return;
    }

    private onSetDataInternal() {
        const getSelection = this.getSelection();
        for (const selected of getSelection) {
            const selectedKey = this.resolveFieldData(selected);
            const dataRecord = this.data.find(x => this.resolveFieldData(x) === selectedKey);
            if (dataRecord) {
                this.table.selection = this.isSingleSelectionMode() ? dataRecord : getSelection.replace(dataRecord, selected);
            }
        }

        this.onSetData();
        this.dataSet.emit();
        this.table.alwaysShowPaginator = this.data && this.data.length > 0;
    }

    onSetData() {

    }

    onTableSet() {

    }

    onSelectionChange() {

    }

    onFilter() {

    }

    refreshSorting() {
        // this.table.sortOrder = this.table.sortOrder * -1;
        if (this.table.sortMode === "single") {
            this.table.sortSingle();
        } else {
            this.table.sortMultiple();
        }
    }

    customSort(event: SortEvent) {
        const selection = this.getSelection();

        if (event.field === "selectionBox" && !selection.length && this.mainColumn) {
            event.field = this.mainColumn.field;
        }

        if (event.field === "selectionBox") {

            event.data.sort((a: T, b: T) => {
                let result = 0;

                const aIsSelected = selection.contains(a);
                const bIsSelected = selection.contains(b);

                if (!aIsSelected && bIsSelected) {
                    result = 1;
                } else if (aIsSelected && !bIsSelected) {
                    result = -1;
                } else {
                    result = 0;
                }

                return event.order * result;
            });

            return;
        }

        // Fallback default sort
        this.table.customSort = false;
        this.table._sortOrder = this.table._sortOrder * -1; // PrimeNG already reversed this, we need to reverse it back :roll_eyes:
        this.table.sort(event);
        this.table.customSort = true;
        // this.table.sortField = originalField;
    }

    lazyLoadStatic(event: LazyLoadEvent) {
        if (!this.data) return;
        if (event.first > this.data.length) {
            console.error(`lazyLoadStatic going beyond data length - ${this.key}`);
            return;
        }

        this.table.value = this.data.slice(event.first, Math.min(this.data.length, event.first + event.rows));
    }

    protected onError(error: Response) {
        this.setLoading(false);
        this.handleError(error);
    }

    handleError(error: Response) { }

    insertData(data: T, index: number) {
        if (this.data.contains(data)) return;

        this.data.insert(data, index);
        this.data = [...this.data];
        this.onSetDataInternal();
    }

    addData(dataToAdd: T[]) {
        for (const newData of dataToAdd.clone()) {
            const newDataKey = this.resolveFieldData(newData);
            if (this.data.find(x => this.resolveFieldData(x) === newDataKey)) {
                dataToAdd = dataToAdd.remove(newData);
            }
        }

        if (!dataToAdd.length) return;

        this.data = this.data.concat(dataToAdd);
        this.onSetDataInternal();
    }

    removeData(dataToRemove: T[]) {
        for (const removedData of dataToRemove.clone()) {
            const removedDataKey = this.resolveFieldData(removedData);
            if (!this.data.find(x => this.resolveFieldData(x) === removedDataKey)) {
                dataToRemove = dataToRemove.remove(removedData);
            }
        }

        if (!dataToRemove.length) return;

        this.data = this.data.filter(x => !dataToRemove.contains(x));
        this.onSetDataInternal();
    }

    setData(data: T[]) {
        if (data) {
            this.initialData = this.enableInitialData ? lodash.cloneDeep(data) : null;
            this.data = data;
            this.setLoading(false);
            this.onSetDataInternal();
        } else {
            this.clearData();
        }
    }

    clearData() {
        if (this.data && this.data.length) {
            this.data = [];
            this.initialData = null;
            this.clearSelection();
            this.onSetDataInternal();
        }

        this.setLoading(false);
    }

    resetToInitialData() {
        if (!this.enableInitialData) {
            console.error(`enableInitialData is false - ${this.key}`);
            return;
        }

        this.data = this.initialData;
    }

    private assertInitialData(): boolean {
        if (!this.enableInitialData) {
            console.error(`enableInitialData is false - ${this.key}`);
            return false;
        }

        if (!this.table.dataKey) {
            console.error(`initialData requires dataKey - ${this.key}`);
            return false;
        }

        if (!this.initialData) return false;

        return true;
    }

    isInitialData(): boolean {
        if (!this.assertInitialData) return false;

        return lodash.isEqual(Flatted.stringify(this.data), Flatted.stringify(this.initialData));
    }

    isInitialDataRow(data: T & { isDirty: boolean }): boolean {
        if (!this.assertInitialData) return false;

        data = lodash.cloneDeep(data);

        const toComparableForm = (obj: any) => {
            // Remove isDirty property if it exists on T,
            delete obj.isDirty;

            // I've seen inputs fill in `""` when using ngModel if it was undefined
            // If a property if "" or undefined shouldn't matter when comparing
            for (const key in obj) {
                if (obj[key] === "") {
                    delete obj[key];
                }
            }

            return Flatted.stringify(obj);
        };

        const dataId = this.resolveFieldData(data);
        const initial = this.initialData.find(x => lodash.isEqual(this.resolveFieldData(x), dataId));

        return initial && lodash.isEqual(toComparableForm(initial), toComparableForm(data));
    }

    replaceInitialData(data: T & { isDirty: boolean }) {
        if (!this.assertInitialData) return false;

        data = lodash.cloneDeep(data);
        delete data.isDirty;

        const dataId = this.resolveFieldData(data);
        const initial = this.initialData.find(x => lodash.isEqual(this.resolveFieldData(x), dataId));

        this.initialData = this.initialData.remove(initial).concat([data]);
    }

    onSelectionModeChange() {
        // normalize selection
        if (!this.table.selection) return;

        if (this.isSingleSelectionMode()) {
            if (Array.isArray(this.table.selection)) {
                this.table.selection = this.table.selection.takeFirstOrDefault();
            }
        } else {
            if (!Array.isArray(this.table.selection)) {
                this.table.selection = [this.table.selection];
            }
        }

        this.table.updateSelectionKeys();
    }

    isSingleSelectionMode(): boolean {
        return this.selectionMode === "single";
    }

    getSelection(filterOnDataKey = true): T[] {
        if (!this.table || !this.table.selection) return [];

        const array = this.table.selection.toList();

        if (filterOnDataKey && this.table.dataKey) {
            // selectionKeys can be trusted more than selection
            // Don't ask me why ;_;
            return array.filter(x => this.table.selectionKeys[this.resolveFieldData(x)] === 1);
        }

        return array;
    }

    getSelectionFromKeys(): T[] {
        if (!this.table || !this.table.selectionKeys || !this.started) return [];

        if (!this.table.dataKey) {
            return [];
        }

        return this.data.filter(x => this.table.selectionKeys[this.resolveFieldData(x)] === 1);
    }

    resolveFieldData(data: T, field?: string): any {
        if (!field) {
            field = this.table.dataKey;
        }

        if (data && field) {
            if (field.indexOf(".") === -1) {
                return data[field];
            } else {
                const fields: string[] = field.split(".");
                let value = data;
                for (let i = 0, len = fields.length; i < len; ++i) {
                    value = value[fields[i]];
                }
                return value;
            }
        }

        return data;
    }

    isSelected(rowData: T) {
        return this.table.isSelected(rowData);
    }

    setSelection(selection: T | T[], emit = true) {
        let newSelection: T[];

        if (!selection) {
            selection = [];
        }

        if (selection && !Array.isArray(selection) && !this.isSingleSelectionMode()) {
            newSelection = [selection];
        } else {
            newSelection = selection as T[];
        }

        if (newSelection.length > 1 && this.isSingleSelectionMode()) {
            newSelection.length = 1;
        }

        const currentSelection = this.getSelectionFromKeys();

        const currentSelectionKeys = currentSelection.map(x => this.resolveFieldData(x));
        const newSelectionKeys = newSelection.map(x => this.resolveFieldData(x));
        const rowsToDeselect = currentSelection.filter(x => !newSelectionKeys.contains(this.resolveFieldData(x)));
        const rowsToSelect = newSelection.filter(x => !currentSelectionKeys.contains(this.resolveFieldData(x)));

        this.deselectRows(rowsToDeselect, emit);
        this.selectRows(rowsToSelect, emit);
    }

    selectionIsEmpty(): boolean {
        let selectionLength = -1;

        if (this.table) {

            if (this.selectionMode === "multiple") {
                if (this.table.selection && Array.isArray(this.table.selection)) {
                    selectionLength = this.table.selection.length;
                }
            } else {
                selectionLength = this.table.selection ? 1 : 0;
            }
        }

        return selectionLength <= 0;
    }

    handleSelectionBoxClick(rowData: T, e: MouseEvent) {
        this.addSelectClick(rowData, e);
        this.toggleSelection(rowData);
        this.handleShiftSelectBehavior();
    }

    toggleSelection(rowData: T, emit = true) {
        if (this.isSelected(rowData)) {
            this.deselectRow(rowData, emit);
        } else {
            this.selectRow(rowData, emit);
        }
    }

    private addSelectClick(row: T, e: MouseEvent) {
        this.selectClicks.push({
            row: row,
            shift: this.selectClicks.length > 0 ? e.shiftKey : false // First click is always without shift, else double shift-click simply single selects
        });
    }

    private handleShiftSelectBehavior(deselect = false) {
        if (!this.enableSigncoMultiSelectBehavior || this.handlingMultiSelectBehavior) return;

        this.handlingMultiSelectBehavior = true;

        const handleFunc = () => {
            if (this.selectClicks.length < 2) return;

            const previousClick = this.selectClicks.shift();
            const lastClick = this.selectClicks.pop();

            if (previousClick.shift) {
                this.selectClicks.push(lastClick);
                return;
            }

            if (!lastClick.shift) {
                this.selectClicks.push(lastClick);
                return;
            }

            // Re-add the initial click that started it all
            // This way we can chain multiple shift-clicks
            this.selectClicks.push(previousClick);

            const currentData = this.getCurrentData();
            const indexPrevious = currentData.indexOf(previousClick.row);
            const indexLast = currentData.indexOf(lastClick.row);
            const startIndex = Math.min(indexPrevious, indexLast);
            const endIndex = Math.max(indexPrevious, indexLast);
            if (startIndex === -1) return; // Previous data no longer in view, disregard it

            let selection = this.getSelection();

            for (let index = startIndex; index < endIndex; index++) {
                const row = currentData[index];

                if (deselect) {
                    selection = selection.remove(row);
                } else if (!selection.contains(row)) {
                    selection.push(row);
                }
            }

            this.setSelection(selection);
        };

        handleFunc();

        this.handlingMultiSelectBehavior = false;
    }

    onRowSelect(e: { originalEvent: MouseEvent, data: T }) {
        this.addSelectClick(e.data, e.originalEvent);

        this.selectRow(e.data, true, true);

        this.handleShiftSelectBehavior();
    }

    canSelect(): boolean {
        return this.selectionMax ? this.getSelection().length < this.selectionMax : true;
    }

    canSelectRow(rowData: T) {
        return this.canSelect() || this.isSelected(rowData);
    }

    isAllSelected(): boolean {
        if (!this.data || !this.data.length || !this.table || !this.table.dataKey) return false;

        const selection = this.getSelectionFromKeys();
        return selection.length === this.data.length;
    }

    toggleSelectAll() {
        if (this.isAllSelected()) {
            this.clearSelection();
        } else {
            this.setSelection(this.data);
        }
    }

    selectRow(row: T, emit = true, emitOnNoAction = false) {
        if (!row) return;
        this.selectRows([row], emit, emitOnNoAction);
    }

    selectRows(rows: T[], emit = true, emitOnNoAction = false) {
        const selectedRows = new Array<T>();

        for (const row of rows) {
            let isSelected = this.isSelected(row);
            const doSelectAction = !isSelected && this.canSelect();

            if (doSelectAction) {
                if (this.isSingleSelectionMode()) {

                    // single selections don't fire deselect for previous selected
                    // so we do it manually
                    if (emit) {
                        // We can still find the previous selected in the selectionKeys
                        const currentSelected = this.getSelection();
                        if (currentSelected.length) {
                            this.deselected.emit(currentSelected);
                        }
                    }

                    this.singleSelected = row;
                    this.table.selection = row;
                } else {
                    this.singleSelected = null;
                    this.table.selection = [row].concat(this.getSelection());
                }

                isSelected = true;
            }

            if (isSelected) {
                if (this.table.dataKey && // dataKey must be defined to use row expansion
                    this.expandOnSelect &&
                    !this.table.isRowExpanded(row)) {

                    this.table.toggleRow(row);
                }

                if (emitOnNoAction || doSelectAction) {
                    selectedRows.push(row);
                }
            }

            this.updateSelectionKeys();
        }

        if (selectedRows.length) {

            if (emit) {
                if (this.isSingleSelectionMode()) {
                    this.selected.emit(this.table.selection);
                } else {
                    this.selected.emit(selectedRows);
                }
            }

            this.onSelectionChange();
        }

        // console.log("Selected", this.table.selection.length, rows);
    }

    collapseAllRows() {
        // by clearing the expandedRowKeys property of the table, all expanded rows are collapsed;
        this.table.expandedRowKeys = {};
    }

    onRowDeselect(e: { originalEvent: MouseEvent, data: T }) {
        this.addSelectClick(e.data, e.originalEvent);

        this.deselectRow(e.data, true, true);

        this.handleShiftSelectBehavior(true);
    }

    deselectRow(row: T, emit = true, emitOnNoAction = false) {
        this.deselectRows([row], emit, emitOnNoAction);
    }

    deselectRows(rows: T[], emit = true, emitOnNoAction = false) {
        const deselectedRows = new Array<T>();

        for (const row of rows) {
            const rowKey = this.resolveFieldData(row);

            const doDeselectAction = this.isSelected(row);

            if (doDeselectAction) {
                this.table.selection = this.isSingleSelectionMode() ? null : this.getSelection().filter(x => this.resolveFieldData(x) !== rowKey);
            }

            if (this.expandOnSelect && this.table.isRowExpanded(row)) {
                this.table.toggleRow(row);
            }

            if (emitOnNoAction || doDeselectAction) {
                deselectedRows.push(row);
            }

            this.updateSelectionKeys();
        }

        if (!this.getSelection().length) {
            this.singleSelected = null;
        }

        if (deselectedRows.length) {

            if (emit) {
                this.deselected.emit(deselectedRows);
            }

            this.onSelectionChange();
        }

        // console.log("Deselected", this.table.selection.length, rows);
    }

    clearSelection(forceClearAll = false) {
        this.deselectRows(forceClearAll ? this.getSelection() : this.data);
    }

    private updateSelectionKeys() {
        if (!this.table.selection) {
            this.table.selectionKeys = {};
        } else {
            this.table.updateSelectionKeys();
        }

        this.table.tableService.onSelectionChange();
    }

    onPage(event: { first: number, rows: number }) {
        this.scrollToTop();

        if (this.scrollPageToTop) {
            document.body.scrollTop = document.documentElement.scrollTop = 0;

            const containerDetails = document.getElementsByClassName("container-detail");
            if (containerDetails.length > 0) containerDetails[0].scrollTop = 0;
        }
    }

    scrollTo(row: T) {
        if (!row) return;

        if (!this.table.dataKey) {
            console.error(`[${this.key}] Can't scrollTo() without dataKey`);
            return;
        }

        const el = this.rowElements.find(r => r.nativeElement.getAttribute("id") === row[this.table.dataKey] + "");

        if (el) {
            // ui-table-scrollable-body
            el.nativeElement.parentNode.parentNode.parentNode.scrollTop = el.nativeElement.offsetTop;

            // This shifts the entire page :(
            // el.nativeElement.scrollIntoView({ behavior: "instant", inline: "start", block: "nearest" });

            this.scrollToFailure = null;
        } else {
            this.scrollToFailure = row;
        }
    }

    scrollToTop() {
        const scrollableBody = this.getScrollableBody();
        if (!scrollableBody) return;

        scrollableBody.scrollTop = 0;
    }

    //#region State

    // This method solely exists because ngOnChanges only triggers if passed from DOM
    setSaveState(stateKey: string) {
        this.stateKey = stateKey;

        // PrimeNG does not load state on stateKey change
        if (this.table) {
            this.table.stateKey = stateKey;
            this.table.restoreState();
        }
    }

    private applyDefaults() {
        if (!this.table) return;

        // Set is to false first so it doesn't show while loading
        this.table.alwaysShowPaginator = false;

        // https://github.com/primefaces/primeng/issues/8123
        this.services.filterService.register("listContains", (value: any[], filter: any | any[]) => {
            if (!filter) {
                return true;
            }

            if (!value || value.length === 0) {
                return filter.find((x: any) => x === "" || x === null) !== undefined;
            }

            if (Array.isArray(filter)) {
                return value.hasAny(filter);
            } else {
                return value.contains(filter);
            }
        });

        this.services.filterService.register("inside", (value: Date, filter: Date | Date[]) => {
            if (!filter) {
                return true;
            }

            if (!(value instanceof Date)) return true;

            if (!Array.isArray(filter)) {
                filter = [filter];
            }

            const from = filter[0];
            const to = (filter.length > 1 ? filter[1] : null) || new Date(2500, 1, 1);

            return from <= value && value < to;
        });

        // Override PrimeNG saveState
        // When serializing selection, it errors on circular json structure
        this.table.saveState = () => {
            if (!this.table.stateKey) {
                return;
            }

            const storage = this.table.getStorage();
            const state = {} as any;
            if (this.paginator) {
                state.first = this.table.first;
                state.rows = this.table.rows;
            }
            if (this.table.sortField) {
                state.sortField = this.table.sortField;
                state.sortOrder = this.table.sortOrder;
            }
            if (this.table.multiSortMeta) {
                state.multiSortMeta = this.table.multiSortMeta;
            }
            if (this.table.hasFilter()) {
                state.filters = this.table.filters;
            }
            // if (this.table.resizableColumns) {
            //     this.table.saveColumnWidths(state);
            // }
            // if (this.table.reorderableColumns) {
            //     this.table.saveColumnOrder(state);
            // }
            // if (this.table.selection) {
            //     state.selection = this.table.selection;
            // }
            if (Object.keys(this.table.expandedRowKeys).length) {
                state.expandedRowKeys = this.table.expandedRowKeys;
            }
            if (Object.keys(state).length) {
                storage.setItem(this.table.stateKey, JSON.stringify(state));
            }
        };

        const primeFilterFunction = this.table._filter;
        this.table._filter = () => {
            if (this.destroyed) return;

            primeFilterFunction.call(this.table);
        };

        const selectionChangeSubscription = this.table.selectionChange.subscribe(() => {
            this.onSelectionChange();
        });
        this.subscriptionManager.add("selectionChangeSubscription", selectionChangeSubscription);

        const onFilterSubscription = this.table.onFilter.subscribe(() => {
            this.onFilter();

            setTimeout(() => {
                this.filtered.emit();
            });
        });
        this.subscriptionManager.add("onFilterSubscription", onFilterSubscription);
    }

    getScrollableBody(): HTMLElement {
        const tableNodes = this.elementRef.nativeElement.querySelectorAll(".p-datatable-scrollable-body");
        return tableNodes.length > 0 ? tableNodes[0] as HTMLElement : null;
    }
    //#endregion State

    //#region Style

    protected setStyle() {
        const classes = new Array<string>();

        if (this.tableStyle) {
            this.tableStyle.split(" ").forEach(tableStyle => classes.push(tableStyle));
        }

        if (this.compact || this.mini) {
            classes.push("p-datatable-compact");
        }

        this.styleClass = classes.join(" ");
    }

    setComponentStyle(componentStyle?: string) {
        if (componentStyle !== undefined) {
            this.componentStyle = componentStyle;
        }

        if (!this.componentStyle) {
            this.elementRef.nativeElement.className = "";
            return;
        }

        this.elementRef.nativeElement.classList.add(this.componentStyle);
    }

    //#endregion

    protected stopEvent(event: Event) {
        if (!event) return;

        if (event.stopPropagation) {
            event.stopPropagation();
        }
    }
}

@Directive()
export abstract class CustomTableComponent<T> extends TableComponentBase<T> {
    private load(forceReload = false) {
        this.setLoading();

        if (!this.started) {
            this.addRequestedActionBeforeStart(() => this.load(forceReload));
            return;
        }

        if (!this.canLoad()) {
            this.clearData();
            return;
        }

        this.updateRelevantColumns();

        this.loadTableRows(forceReload);
    }

    loadTableRows(forceReload = true) {
        throw Error("loadTableRows() has not been implemented");
    }

    reload(forceReload = true) {
        return this.load(forceReload);
    }
}

@Directive()
export abstract class TableComponentApiBase<T> extends TableComponentBase<T> implements OnDestroy {
    @Input() loadOnStart = true;

    lastSearchResult: ISearchResult<T>;
    isLazy: boolean;
    totalRecords: number;

    get total(): number {
        return this.totalRecords;
    }

    constructor(
        key: string,
        elementRef: ElementRef,
        protected readonly api: ApiServiceBase & {
            search$: (
                searchParameters: SearchParameters,
                serviceRequestOptions: ServiceRequestOptions,
                useCache: boolean | CacheOptions,
                routeParams: { [key: string]: string },
                lazyLoadEvent: LazyLoadEvent
            ) => Observable<ISearchResult<T>>;
        },
        tableService: TableService) {

        super(key, elementRef, tableService);

        this.services.impersonationService.subscribeToOrganizationImpersonation(this.key, () => {
            this.reload();
        });
    }

    protected async onSuccess(result: ISearchResult<T>) {
        if (this.destroyed) return;

        this.lastSearchResult = result;
        const initialRows = result.data.length;
        const data = await this.processLoadedData(result.data);
        const dataLengthChangedAfterProcess = initialRows !== data.length;
        this.setData(data);
        this.totalRecords = result.total;

        // When we manipulated the data in processLoadedData
        // it's possible the length has changed
        // we don't want to enable paging, we want to show it all
        // so change internalRowCount

        if (!this.isLazy && dataLengthChangedAfterProcess) {
            this.internalRowCount = data.length > this.rowCount ? data.length : this.rowCount;
        } else {
            this.internalRowCount = this.rowCount;
        }
    }

    async processLoadedData(data: T[]): Promise<T[]> {
        return data;
    }

    clearData() {
        super.clearData();
        this.totalRecords = null;
    }

    protected getSearchParametersInternal(): SearchParameters {
        const searchParameters = this.getSearchParameters() || new SearchParameters();

        if (this.includeObsolete) {
            searchParameters.includeObsolete = this.includeObsolete;
        }

        return searchParameters;
    }
    exportExcel(fileName: string, filterFunction: (row: T) => boolean = null) {
        import("xlsx").then(xlsx => {
            this.searchDataForExport().subscribe(async (result) => {
                if (filterFunction) {
                    result.data = result.data.filter(filterFunction);
                }

                const exportObject = [];
                // Loop entire resultset
                for (let index = 0; index < result.data.length; index++) {
                    const rowObject = {};
                    // Loop columns
                    for (let columnIndex = 0; columnIndex < this.table._columns.length; columnIndex++) {
                        if (this.table._columns[columnIndex].field === "commands") {
                            continue;
                        }

                        // Translate column header & add corresponding value
                        let property;
                        if ((<string>this.table._columns[columnIndex].field).contains(".")) { // e.g. property.property.property... (to access nested objects)

                            let startingValue = result.data[index];
                            const nestedProperties = (<string>this.table._columns[columnIndex].field).split(".");

                            for (const nestedProperty of nestedProperties) {
                                startingValue = startingValue[nestedProperty];
                            }

                            property = startingValue;
                        } else {
                            property = result.data[index][this.table._columns[columnIndex].field];
                        }

                        if (this.table._columns[columnIndex].customExcelSerializeMethod) {
                            property = this.table._columns[columnIndex].customExcelSerializeMethod(property);
                        }

                        if (this.table._columns[columnIndex].domainDataType && property) {

                            if (!(property instanceof String)) {
                                const properties = Object.keys(property);
                                const enumProperty = properties.find(x => x.contains("Id")); // e.g. IAssignment->priority-priority is object with 2 properties priorityId and color

                                if (enumProperty) {
                                    property = property[enumProperty];
                                }
                            }

                            property = (await this.services.domainDataService.getViewModelEnum(this.table._columns[columnIndex].domainDataType, property)).label;
                        }

                        rowObject[this.services.translateService.instant(this.table._columns[columnIndex].header)] = property;
                    }
                    exportObject.push(rowObject);
                }
                const worksheet = xlsx.utils.json_to_sheet(exportObject);
                const workbook = { Sheets: { "data": worksheet }, SheetNames: ["data"] };
                const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
                this.saveAsExcelFile(excelBuffer, fileName);
            });

        });
    }
    saveAsExcelFile(buffer: any, fileName: string): void {
        const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        saveAs(data, fileName + "_" + new Date().toJSON().slice(0, 10) + EXCEL_EXTENSION);
    }
    searchDataForExport(allData: boolean = true): Observable<ISearchResult<T>> {
        const allSearchParameters = lodash.cloneDeep(this.lastSearchParameters);
        if (allData) {
            allSearchParameters.skip = 0;
            allSearchParameters.take = this.table.totalRecords;
        }
        return this.api.search$(
            allSearchParameters,
            this.getServiceRequestOptions(),
            false,
            this.getRouteParams(),
            null
        );
    }

    search$(useCache = true, event: LazyLoadEvent = null): Observable<ISearchResult<T>> {
        const searchParameters = this.getSearchParametersInternal();
        this.lastSearchParameters = lodash.cloneDeep(searchParameters);

        if (event && event.filters) {
            const eventSearchParameters = this.api.lazyLoadToSearchParameters(event);

            if (eventSearchParameters && eventSearchParameters.filter) {
                if (!this.lastSearchParameters) {
                    this.lastSearchParameters = eventSearchParameters;
                } else {
                    if (!this.lastSearchParameters.filter) {
                        this.lastSearchParameters.filter = [];
                    }

                    const relevantFilters = eventSearchParameters.filter.filter(x => !this.lastSearchParameters.filter.find(f => f.field === x.field));
                    this.lastSearchParameters.filter = this.lastSearchParameters.filter.concat(relevantFilters);
                }
            }
        }

        this.startedSearch.next();

        return this.api.search$(
            this.lastSearchParameters,
            this.getServiceRequestOptions(),
            useCache,
            this.getRouteParams(),
            event
        );
    }
}

@Directive()
export abstract class ApiTableComponent<T> extends TableComponentApiBase<T> {
    reload(forceReload = true) {
        this.loadTableRows(forceReload);
    }

    onStart() {
        if (this.loadOnStart && !this.requestedActionsBeforeStart.find(x => x.toString().contains("loadTableRows"))) {
            this.loadTableRows();
        }
    }

    loadTableRows(forceReload = false) {
        this.setLoading();

        if (!this.started) {
            this.addRequestedActionBeforeStart(() => this.loadTableRows(forceReload));
            return;
        }

        if (!this.canLoad()) {
            this.clearData();
            return;
        }

        this.updateRelevantColumns();

        const requestTime = new Date();
        this.lastRequestTime = requestTime;

        const useCache = !forceReload;
        this.search$(useCache).subscribe(
            (result) => {
                if (requestTime === this.lastRequestTime) {
                    this.onSuccess(result);
                }
            },
            (error) => this.onError(error)
        );
    }
}

@Directive()
export abstract class ModelLocalizationTable<T> extends ApiTableComponent<T> {
    constructor(
        key: string,
        elementRef: ElementRef,
        protected readonly api: ApiServiceBase & {
            search$: (
                searchParameters: SearchParameters,
                serviceRequestOptions: ServiceRequestOptions,
                useCache: boolean | CacheOptions,
                routeParams: { [key: string]: string },
                lazyLoadEvent: LazyLoadEvent
            ) => Observable<ISearchResult<T>>;
        },
        tableService: TableService,
        protected readonly domainDataService: DomainDataService,
        protected readonly translateService: TranslateService) {
        super(key, elementRef, api, tableService);

        const domainDataReloaded = this.domainDataService.uponReload.subscribe(async () => {
            this.setData(await this.translateTranslatableProperties(this.data));
        });
        this.subscriptionManager.add("modelLocalizationTableDomainDataReloaded", domainDataReloaded);

        const modelLocalizationTableLangChange = this.translateService.onLangChange.subscribe(async () => {
            this.setData(await this.translateTranslatableProperties(this.data));
        });
        this.subscriptionManager.add("modelLocalizationTableLangChange", modelLocalizationTableLangChange);
    }

    async processLoadedData(data: T[]): Promise<T[]> {
        return await this.translateTranslatableProperties(data);
    }

    private async translateTranslatableProperties(data: T[]): Promise<T[]> {
        const columnsWithReferenceToStringResourceIdProperty = this.columns.filter(x => x.stringResourcePropertyName);

        for (const column of columnsWithReferenceToStringResourceIdProperty) {
            for (const obj of data) {
                if (!obj[column.stringResourcePropertyName]) {
                    continue; // stringResourceId property does not exists
                }

                // we do this so in memory filtering/sorting can work on translatable properties
                obj[column.field] = this.domainDataService.translate(obj[column.stringResourcePropertyName], null);
            }
        }

        return data;
    }
}

@Directive()
export abstract class LazyTableComponent<T> extends TableComponentApiBase<T> {
    isLazy = true;
    lastLazyLoadEvent: LazyLoadEvent;
    lastRouteParams: { [index: string]: string };

    onStart() {
        // Can't sort on selectionBox in lazy tables, not supported on server
        this.selectionBoxColumn.sortable = false;

        // if (this.loadOnStart && !this.requestedActionsBeforeStart.find(x => x.toString().contains("loadTableRows"))) {
        //     this.loadTableRows();
        // }
    }

    reload(forceReload = true) {
        this.loadTableRows(this.lastLazyLoadEvent, forceReload);
    }

    loadTableRows(event: LazyLoadEvent = null, forceReload = false) {
        if (!this.started) {
            this.addRequestedActionBeforeStart(() => this.loadTableRows(event, forceReload));
            return;
        }

        Promise.resolve().then(() => {
            if (this.destroyed) return;

            this.setLoading();

            if (!this.canLoad()) {
                this.clearData();
                return;
            }

            if (!event) {
                // default LazyLoadEvent
                // This one also gets created when a table lazy table gets instantiated and calls onLazyLoad
                // so isEqual will consider it equal
                event = {
                    // filters: this.table.filters || {}, todo Robin
                    // first: 0, todo Robin
                    globalFilter: null,
                    // multiSortMeta: undefined,
                    sortField: this.table.sortField,
                    sortOrder: this.table.sortOrder || 1
                };
            }

            if (this.table.filters && (!event.filters || Object.keys(event.filters).length !== Object.keys(this.table.filters).length)) {
                // event.filters = this.table.filters; todo Robin
            }

            // Don't allow non-visible columns to apply filtering
            // Was an issue with the users table,
            // you could filter on Organization in general users screen,
            // then go to a different organization's users table, which would be empty (still filtering on org)
            for (const filterKey in event.filters) {
                if (!event.filters.hasOwnProperty(filterKey)) continue;

                const column = this.columns.find(x => x.field === filterKey);
                if (!column || !this.relevantColumns.contains(column)) {
                    delete event.filters[filterKey];
                }
            }

            event.rows = this.rowCount;

            // We break references later on using JSON.stringify, which strips away undefined fields
            // Lodash fails isEqual compare because one object has fields with undefined value, and the other does not
            // This is correct behavior on Lodash's part.
            // Prime shouldn't be sending us undefined fields, but null fields.
            if (event.multiSortMeta === undefined) delete event.multiSortMeta;
            if (event.sortField === undefined) delete event.sortField;

            // Also use params when trying comparing previous call
            const routeParams = this.getRouteParams();

            if (!forceReload) {
                if (this.lastLazyLoadEvent && lodash.isEqual(this.lastLazyLoadEvent, event)) {

                    const searchParameters = this.getSearchParametersInternal();
                    if (lodash.isEqual(this.lastSearchParameters, searchParameters) && lodash.isEqual(this.lastRouteParams, routeParams)) {
                        this.setLoading(false);
                        return;
                    }
                }
            }

            this.lastLazyLoadEvent = JsonUtils.deepClone(event);
            this.lastRouteParams = routeParams ? JsonUtils.deepClone(routeParams) : null;

            const requestTime = new Date();
            this.lastRequestTime = requestTime;

            const useCache = !forceReload;
            this.search$(useCache, event).subscribe(
                (result) => {
                    if (requestTime === this.lastRequestTime && !this.destroyed) {
                        this.onSuccess(result);
                    }
                },
                (error) => this.onError(error)
            );
        });
    }
}

@Directive()
export abstract class MapTableComponent<T> extends TableComponentBase<T> {
    constructor(
        key: string,
        elementRef: ElementRef,
        protected readonly mapDataCall:
            (
                key: string,
                callback?: (res: T[]) => void,
                errorCallback?: (res: Response) => void,
                forceUpdate?: boolean
            ) => void,
        tableService: TableService) {

        super(key, elementRef, tableService);
    }

    onStart() {
        if (!this.requestedActionsBeforeStart.find(x => x.toString().contains("loadTableRows"))) {
            this.loadTableRows();
        }
    }

    protected async onSuccess(data: T[]) {
        if (this.destroyed) return;

        data = await this.processLoadedData(data);
        this.setData(data);

        // TODO (r) - logic is valid, but isLoading can still be loading mps even when group / devices are finished
        // No callback means setLoading(false) never happens
        // In new system, this shouldn't happen anymore, and we can check for (!data.length)
        if (!data && this.services.mapDataService.isLoading) {
            this.setLoading(true);
        }
    }

    async processLoadedData(data: T[]): Promise<T[]> {
        return data;
    }

    reload(forceReload = false) {
        this.loadTableRows(forceReload);
    }

    loadTableRows(forceReload = false) {
        this.setLoading();

        if (!this.started) {
            this.addRequestedActionBeforeStart(() => this.loadTableRows(forceReload));
            return;
        }

        if (!this.canLoad()) {
            this.clearData();
            return;
        }

        this.updateRelevantColumns();

        this.mapDataCall.call(
            this.services.mapDataService,
            this.mapDataServiceKey,
            (result: T[]) => this.onSuccess(result),
            (error: Response) => this.onError(error),
            forceReload);
    }
}

@Component({
    selector: "app-table-filter-preview",
    templateUrl: "./table-filter-preview.component.html"
})
export class TableFilterPreviewComponent {
    @Input() column: TableColumn;
}

@Component({
    selector: "app-table-filter",
    templateUrl: "./table-filter.component.html"
})
export class TableFilterComponent implements AfterViewInit, OnDestroy {
    @ViewChild(Dropdown, { static: false }) dropdown: Dropdown;
    @ViewChild(MultiSelect, { static: false }) multiSelect: MultiSelect;
    @ViewChild(Calendar, { static: false }) calendar: Calendar;

    private textInput: HTMLInputElement;
    @ViewChild("textInput", { static: false }) set setTextInput(textInputElementRef: ElementRef) {
        this.textInput = textInputElementRef ? textInputElementRef.nativeElement : null;
    }

    @Input() column: TableColumn;
    @Input() table: Table;
    @Input() isOutsideTable = false;

    recentlyOpenedOrClosed: boolean;
    expanded: boolean;
    currentFilter: any;
    currentFilterString: string;

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        readonly primeComponentService: PrimeComponentService,
        private readonly elementRef: ElementRef,
        private readonly documentEventService: DocumentEventService,
        private readonly cd: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.loadFilterFromColumn();
        this.column.setFilterComponent(this);
        this.cd.detectChanges();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(() => {
            this.updateCurrentFilterString();
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    private loadFilterFromColumn() {
        const loadedFilter = this.table.filters[this.column.field];
        if (loadedFilter) {
            const filter = loadedFilter.toList<FilterMetadata>().takeFirstOrDefault();
            if (filter) {
                this.setCurrentFilter(filter.value);
            }
        }
    }

    private setCurrentFilter(value: any) {

        if (this.column.filterType === FilterType.Date) {
            if (typeof value === "string") {
                value = new Date(value);
            }

            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    if (typeof value[i] === "string") {
                        value[i] = new Date(value[i]);
                    }
                }
            }
        }

        this.currentFilter = value;

        if (!this.currentFilter || (Array.isArray(this.currentFilter) && !this.currentFilter.length)) {
            this.elementRef.nativeElement.classList.remove("ui-state-active");
        } else {
            this.elementRef.nativeElement.classList.add("ui-state-active");
        }

        this.updateCurrentFilterString();
    }

    stop(event: Event) {
        if (!event) return;

        event.stop();
    }

    open(event: Event = null) {
        this.stop(event);

        if (!this.expanded) {
            this.toggleExpanded();

            if (this.textInput) {
                setTimeout(() => {
                    this.textInput.focus();
                });
            }
        }

        this.setRecentlyOpenedOrClosed();
    }

    setRecentlyOpenedOrClosed() {
        this.recentlyOpenedOrClosed = true;
        setTimeout(() => {
            this.recentlyOpenedOrClosed = false;
        }, 100);
    }

    closeIfTargetIsNotListItem(event: MouseEvent) {
        // Multiselect event fixer
        // Their "click" event gets broadcast on open
        // But we also use it to close the component
        // So we have to safeguard => first close event doesn't count

        const isMainItem = event.target instanceof HTMLElement && event.target.className.contains("ui-multiselect");
        if (isMainItem) {
            this.toggleExpanded();
        }

        this.stop(event);
    }

    close(event: Event = null) {
        this.stop(event);

        if (this.expanded) {
            this.toggleExpanded();
        }
    }

    validateKeypress(event: KeyboardEvent) {
        // spacebar also triggers interactions / buttons
        // the th is clickable and sorts the column
        // typing space without this also triggers the sorting
        if (event.code === "Space") {
            this.currentFilter += " ";
            event.stop();
        }
    }

    toggleExpanded(event: Event = null) {
        if (event) {
            this.stop(event);
        }

        if (this.recentlyOpenedOrClosed) {
            return;
        }

        const documentServiceKey = `close${this.column.field}Filter`;

        this.expanded = !this.expanded;

        if (!this.expanded) {
            if (this.dropdown) this.dropdown.hide();
            if (this.multiSelect) this.multiSelect.hide();
            if (this.calendar) this.calendar.overlayVisible = false;

            this.documentEventService.removeOnClick(documentServiceKey);
        } else {
            if (this.dropdown) this.dropdown.show();
            if (this.multiSelect) this.multiSelect.show();
            if (this.calendar) this.calendar.showOverlay();
            if (this.textInput) this.textInput.focus();

            this.documentEventService.addOnClick(documentServiceKey, this.elementRef.nativeElement, () => this.close());
        }

        this.setRecentlyOpenedOrClosed();
    }

    clearFilter(e?: MouseEvent) {
        if (this.column.table.destroyed) return;

        this.setCurrentFilter(null);
        this.close();
        this.table.filter(null, this.column.field, this.column.filterMatchMode);

        if (e) {
            e.stopPropagation();
        }
    }

    filterNumber(value: number) {
        this.currentFilter = value;
        this.filter(value, "equals");
    }

    filterDate(value: Date[]) {
        this.filter(value, "inside");
    }

    filterBoolean(value: boolean | null) {
        this.filter(value, "equals");
    }

    filter(filter: any, filterMatchMode?: string) {
        if (this.column.table.destroyed) return;
        this.setCurrentFilter(filter);
        this.table.filter(this.currentFilter, this.column.field, this.column.filterMatchMode || filterMatchMode);
        this.column.table.onFilter();
    }

    updateCurrentFilterString() {
        const getOptionLabel = (option: any) => {
            return option.name || option.label || option.code || option;
        };

        const getLabel = (key: any) => {
            if (this.column.filterOptions) {
                const selectedOption = this.column.filterOptions.find(x => x.value === key || (x.value && key && x.value.id && key.id && x.value.id === key.id));
                return selectedOption ? selectedOption.label : getOptionLabel(key);
            } else {
                return getOptionLabel(key);
            }
        };

        const filterFunction = (x: any) => NumberUtils.isValid(x);

        if (!this.currentFilter) {
            this.currentFilterString = null;

            // } else if (this.dropdown && this.dropdown.selectedOption && this.dropdown.selectedOption.label) {
            //     this.currentFilterString = this.dropdown.selectedOption.label;

        } else if (this.column.filterType === FilterType.Date) {

            let dateFilter = this.currentFilter;
            if (!Array.isArray(dateFilter)) {
                dateFilter = [dateFilter];
            }
            this.currentFilterString = (dateFilter as Date[]).filter(filterFunction).map(x => x.toLocaleDateString(this.primeComponentService.locale)).join(" - ");
        } else if (Array.isArray(this.currentFilter)) {
            this.currentFilterString = this.currentFilter.filter(filterFunction).map(x => getLabel(x)).join(", ");
        } else {
            this.currentFilterString = getLabel(this.currentFilter);
        }
    }
}
