<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{height: '80vw'}">

    <p-header>
        {{"hardwareConfiguration.title" | translate}}
    </p-header>


    <div style="display: grid; height: 100%; grid-template-rows: auto minmax(0px, 1fr);">
        <div>
            <form *ngIf="dekimoCloudConfigurationForm"
                  class="p-16"
                  [formGroup]="dekimoCloudConfigurationForm"
                  autocomplete="off">

                <ng-container formGroupName="dekExtraboardConfig">

                    <ng-container formGroupName="cloudSyncSettings">
                        <div class="m-form-group">
                            <div class="row">
                                <label class="col-4"
                                       for="">
                                    {{"hardwareConfiguration.clockSyncTimes" | translate}}

                                    <button class="m-btn m-btn-secondary"
                                            style="top: 5px;"
                                            [disabled]="dekimoCloudConfigurationForm.disabled"
                                            (click)="addCloudSyncTime()">
                                        <app-svg-icon icon="add">
                                        </app-svg-icon>
                                    </button>
                                </label>

                                <div class="col-8"
                                     formGroupName="clockSyncTimes">
                                    <div class="mb-auto"
                                         *ngFor="let cloudSyncTime of cloudSyncTimesFormArray.controls; let i = index; let first = first">

                                        <!-- step 1 enables seconds -->
                                        <input type="time"
                                               step="1"
                                               formControlName="{{i}}">

                                        <app-icon-button icon="delete"
                                                         text="form.delete"
                                                         [style]="{'top': '5px'}"
                                                         [disabled]="dekimoCloudConfigurationForm.disabled"
                                                         (click)="deleteCloudSyncTime(i)">
                                        </app-icon-button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-form-group">
                            <div class="row">
                                <label class="col-4"
                                       for="modemAlwaysOn">
                                    {{"hardwareConfiguration.modemAlwaysOn" | translate}}
                                </label>

                                <div class="col-8">
                                    <p-inputSwitch id="modemAlwaysOn"
                                                   formControlName="modemAlwaysOn">
                                    </p-inputSwitch>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="m-form-group"
                         formGroupName="metalyzerShell">
                        <div class="row">
                            <label class="col-4"
                                   for="modemAlwaysOn">
                                {{"hardwareConfiguration.verbosity" | translate}}
                            </label>

                            <div class="col-8">
                                <input type="number"
                                       formControlName="verbosity">
                            </div>
                        </div>
                    </div>

                    <div class="m-form-group">
                        <div class="row">
                            <label class="col-4"
                                   for="totemCase">
                                {{"hardwareConfiguration.totemCase" | translate}}
                            </label>

                            <div class="col-8">
                                <p-inputSwitch id="totemCase"
                                               formControlName="totemCase">
                                </p-inputSwitch>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </form>
        </div>

        <!--<div style="display: grid;">-->
        <div class="m-border-top">
            <textarea [ngModel]="hardwareConfiguration"
                      [ngModelOptions]="{ updateOn: 'blur' }"
                      (ngModelChange)="setHardwareConfigurationFromGuiJson($event)"
                      style="width: 100%; height: calc(100% - 8px); resize: none; border:none;">
        </textarea>
        </div>
    </div>

    <p-footer>
        <span class="form-validation-error"
              *ngIf="jsonError">
            <app-svg-icon icon="error"
                          fill="red"></app-svg-icon>
            {{"hardwareConfiguration.invalidJson" | translate}}
        </span>

        <button class="m-btn m-btn-link"
                (click)="close()"
                type="button">
            {{"form.close" | translate}}
        </button>

        <button class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            {{"form.ok" | translate}}
        </button>
    </p-footer>
</p-dialog>