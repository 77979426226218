<div class="m-dropdown m-dropdown-expand m-map-type-dropdown m-map-type-dropdown-compact"
     (clickOutside)="closeMapTypeDropdown()"
     #mapTypeDropdown>
    <a class="m-dropdown-toggle"
       (click)="toggleMapTypeDropdown($event)">
        <ng-container *ngIf="selectedMapType">
            <span class="icon"
                  [ngClass]="selectedMapType.iconClass"></span>
            <span class="label"
                  style="text-decoration: none;">{{selectedMapType.label | translate}}</span>
        </ng-container>
    </a>

    <ul class="m-dropdown-menu">
        <li class="m-dropdown-menu-item"
            *ngFor="let mapType of mapTypes; trackBy: mapTypesTrackByFn">
            <button type="button"
                    (click)="setSelectedMapType(mapType)">
                <span class="icon"
                      [ngClass]="mapType.iconClass"></span>
                <span class="label">{{mapType.label | translate}}</span>
            </button>
        </li>
    </ul>
</div>