import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ElementRef,
    OnDestroy
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";

import { SigncoFormGroup } from "src/app/models/form";
import { LocationCreator } from "src/app/models/location";
import {
    IParkingBan,
    ParkingBanCreator,
    ParkingBanUpdater,
} from "src/app/models/parking-ban";
import { MomentDateAndTimePipe } from "src/app/modules/shared/pipes/datetime.pipe";
import { ParkingBanApi } from "src/app/resource/parking-ban.api";
import {
    ChangeGuardService,
    IChangeGuard,
} from "src/app/services/change-guard.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { NavigationService } from "src/app/services/navigation.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { SubscriptionManager } from "src/app/utilities";
import { ParkingBanDataService } from "../../services/parking-ban-data.service";

@Component({
    selector: "app-parking-ban-detail",
    templateUrl: "./parking-ban-detail.component.html",
    styleUrls: ["./parking-ban-detail.component.css"],
})
export class ParkingBanDetailComponent implements OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() parkingBan: IParkingBan;
    @Output() save = new EventEmitter<IParkingBan>();

    submitting: boolean;
    callback: (res: IParkingBan) => void;
    parkingBanForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;

    generatedName: string;
    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly parkingBanApi: ParkingBanApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        public momentDateAndTimePipe: MomentDateAndTimePipe,
        private readonly navigationService: NavigationService,
        private readonly parkingBanDataService: ParkingBanDataService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");


        const calendarSettingsSubscription = this.primeComponentService
            .calendarSettings()
            .subscribe((calendarSettings) => {
                this.calendarSettings = calendarSettings;
            });

        this.subscriptionManager.add(
            "calendarSettings",
            calendarSettingsSubscription
        );
    }
    ngOnDestroy() {
        this.subscriptionManager.clear();
    }
    ngOnChanges(changes: SimpleChanges) {
        const parkingBanChange = changes["parkingBan"];
        if (parkingBanChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    canDeactivateCheck(): boolean {
        return (
            !this.parkingBanForm ||
            (this.parkingBanForm.pristine && !this.isCreatingNew())
        );
    }

    onDeactivate() { }

    isCreatingNew(): boolean {
        return this.parkingBan && !this.parkingBan.id; // check if id is falsy
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(
            this.parkingBanForm
        );
        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNew();
        } else {
            this.update();
        }
    }

    setParkingBan(parkingBan: IParkingBan) {
        this.parkingBan = parkingBan;
        this.generatedName = parkingBan.name;
        this.initialize();
    }

    reset() {
        if (this.isCreatingNewParkingBan()) {
            if (this.parkingBan?.assignment) {
                this.navigationService.toAssignment(this.parkingBan.assignment);
            } else {
                this.navigationService.toAssignments();
            }

            return;
        }

        this.initialize();
    }

    isCreatingNewParkingBan(): boolean {
        return this.parkingBan && !this.parkingBan.id; // check if id is falsy
    }

    private async initialize() {
        if (!this.parkingBan) return;

        this.parkingBanForm = this.formBuilder.group({
            assignmentId: [null, Validators.required],
            name: ["", Validators.required],
            description: "",
            isCompleted: "",
            start: "",
            end: ""
        }) as SigncoFormGroup;

        this.parkingBanForm.patchValue(this.parkingBan);

        const assignmentId = this.parkingBan.assignment
            ? this.parkingBan.assignment.id
            : undefined;
        this.parkingBanForm.controls["assignmentId"].patchValue(assignmentId);

        if (assignmentId) {
            this.parkingBanForm.get("assignmentId").disable();
        }

        if (this.isCreatingNew()) {
            this.generateName();
        }

        this.parkingBanForm.markAsPristine();
    }

    private createNew() {
        const parkingBanCreator = Object.assign(
            new ParkingBanCreator(),
            this.parkingBanForm.getRawValue()
        ) as ParkingBanCreator;

        parkingBanCreator.location = {
            coordinate: this.parkingBan.location.coordinate
        } as LocationCreator;

        const onSuccess = async (newParkingBan: IParkingBan) =>
            this.onSaveSuccess(newParkingBan);

        this.submitting = true;
        this.parkingBanApi
            .create$(parkingBanCreator)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    private update() {
        const updatedParkingBan = Object.assign(
            new ParkingBanUpdater(),
            this.parkingBan,
            this.parkingBanForm.getRawValue()
        );
        updatedParkingBan.locationId = this.parkingBan.location.id;

        const onSuccess = async (savedParkingBan: IParkingBan) =>
            this.onSaveSuccess(savedParkingBan);

        this.parkingBanApi
            .update$(updatedParkingBan)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    async onSaveSuccess(savedParkingBan: IParkingBan) {
        this.parkingBan = savedParkingBan;
        this.parkingBanForm.markAsPristine();
        this.toastService.saveSuccess();
        this.submitting = false;
        this.save.emit(savedParkingBan);
    }

    async onSaveError() {
        this.submitting = false;
    }

    public generateName() {
        this.generatedName = this.momentDateAndTimePipe.transform(new Date());
        this.parkingBanForm.controls["name"].setValue(this.generatedName);
    }
}
