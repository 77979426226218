<div class="m-page-content-wrapper p-16">
    <div class="m-card m-fixed-height m-overflow-auto p-16">
        <ng-container *ngIf="websiteService.isBerghausConnect()">
            <h1 class="mb-8">{{ websiteService.getCompanyName()}}</h1>
            <p>
                Herrenhöhe 6<br>
                51515 Kürten-Herweg
            </p>
            <p>Tel. +49 2207 96 77-0</p>
            <p>Fax. +49 2207 96 77-80</p>
            <p>
                <a href="mailto:mail@berghaus-verkehrstechnik.de"
                   class="m-navigator"
                   title="mail@berghaus-verkehrstechnik.de">mail&commat;berghaus-verkehrstechnik.de</a>
            </p>
        </ng-container>
        <ng-container *ngIf="!websiteService.isBerghausConnect()">

            <h1 class="mb-8">{{websiteService.getCompanyName()}}</h1>
            <p>
                Jozef de Blockstraat 100<br>
                2830 Willebroek
            </p>
            <p>Tel. +32 3 860 67 60</p>
            <p>Fax. +32 3 860 67 61</p>
            <p>
                <a href="mailto:info@signco.be"
                   class="m-navigator"
                   title="info@signco.be">info&commat;signco.be</a>
            </p>
        </ng-container>

        <h2 class="mt-16 mb-8">{{"about.softwareVersion" | translate}}</h2>
        <p [hidden]="!apiVersion">API v{{apiVersion}}</p>
        <p [hidden]="!guiVersion">GUI v{{guiVersion}}</p>

        <h2 class="mt-16 mb-8">{{"about.apiTitle" | translate}}</h2>
        <p class="mb-8">
            {{"about.introduction" | translate}}
        </p>

        <div *ngIf="this.user">
            <p>
                <a class="m-navigator"
                   href="assets/documents/Signco Serv API documentation.pdf"
                   target="_blank">{{"about.linkOverview" | translate}}</a>
            </p>
            <p>
                <a [href]="apiUrl"
                   class="m-navigator"
                   target="_blank">{{"about.linkTechnical" | translate}}</a>
            </p>
        </div>

        <h2 class="mt-16 mb-8">{{"about.copyright" | translate}}</h2>
        <p *ngIf="websiteService.isBerghausConnect()"><a href="https://berghaus-verkehrstechnik.de/"
               title="{{websiteService.getCompanyName()}}">&copy; {{websiteService.getCompanyName()}}</a></p>
        <p *ngIf="!websiteService.isBerghausConnect()"><a href="https://www.signco.be/"
               title="Signco">&copy; Signco</a></p>
        <p><a href="http://www.openstreetmap.org/copyright"
               title="OpenStreetMap">&copy; OpenStreetMap contributors</a></p>
    </div>
</div>