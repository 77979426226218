import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { IWorker, WorkerCreator } from "src/app/models/worker";

@Injectable({
    providedIn: "root"
})
export class WorkerApi extends ApiService<IWorker, WorkerCreator, WorkerCreator> {

    getRoute(): string {
        return "Workers";
    }
}