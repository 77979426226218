import { Injectable } from "@angular/core";
import { ITaskRule } from "../models/task-rule";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class TaskRuleApi extends ApiService<ITaskRule, ITaskRule, ITaskRule> {

    getRoute(): string {
        return "TaskRules";
    }
}