import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit, OnInit, HostListener } from "@angular/core";
import { ISharedKey, SharedKeyCreator, SharedKeyUpdater } from "src/app/models/shared-key";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { FormValidationService } from "src/app/services/form-validation.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { SubscriptionManager, OrganizationUtils } from "src/app/utilities";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { MapDataService } from "src/app/services/map-data.service";
import { ActivatedRoute } from "@angular/router";
import { ResizeService } from "src/app/services/resize.service";
import { SharedKeyApi } from "src/app/resource/shared-key.api";
import { SelectItem } from "primeng/api";
import { MapDetail } from "src/app/services/map-detail.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { ResourceSelectorComponent } from "src/app/modules/shared/components/resource-selector/resource-selector.component";
import { IMeasuringPointSummary } from "src/app/models/web";
import { IGroup, IGroupMeasuringPoint } from "src/app/models/group";

@Component({
    selector: "app-manage-shared-key",
    templateUrl: "./manage-shared-key.component.html"
})
export class ManageSharedKeyComponent implements OnInit, AfterViewInit, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @ViewChild("column1", { static: true }) column1: ElementRef<HTMLDivElement>;

    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        this.map = mapSelector;
    }

    @ViewChild(ResourceSelectorComponent, { static: true }) resourceSelectorComponent: ResourceSelectorComponent;

    loading = true;
    mapHeight: number;
    mapPolygonOffset = 0;
    mapOpen: boolean;
    mapDetail: MapDetail;

    sharedKeyForm: SigncoFormGroup;
    existingSharedKey: ISharedKey;
    submitting = false;

    organizations: SelectItem[];
    ownerId: number;

    private subscriptionManager = new SubscriptionManager();
    private readonly mapDataKey: string;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly navigationService: NavigationService,
        private readonly route: ActivatedRoute,
        private readonly resizeService: ResizeService,
        private readonly selectionService: MapSelectionService,
        private readonly mapDataService: MapDataService,
        private readonly sharedKeyApi: SharedKeyApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly globalEventsService: GlobalEventsService) {

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
        });

        this.createForm();

        const resizeSubscription = this.resizeService.onResize.subscribe(() => {
            this.updateScrollHeight();
        });
        this.subscriptionManager.add("resize", resizeSubscription);
    }

    ngOnInit() {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            await this.setSharedKeyFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    ngAfterViewInit() {
        this.updateScrollHeight();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        if (this.submitting) {
            // We're here because we've submitted => allow it
            return true;
        }

        return this.existingSharedKey && this.sharedKeyForm.pristine && this.resourceSelectorComponent?.measuringPointsComponent.isInitialData() && this.resourceSelectorComponent?.groupsComponent.isInitialData();
    }

    onDeactivate() {

    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    private async setSharedKeyFromParams(params: { [key: string]: any }) {
        if (!params) return;

        this.loading = true;

        const key = decodeURI(params["key"]);
        if (!key) {
            this.back();
            return;
        }

        let sharedKey: ISharedKey = null;

        if (key !== "new") {
            const searchParameters = new SearchParameters();
            searchParameters.filter = [
                new FilterDescriptor("key", key)
            ];

            const searchResult = await this.sharedKeyApi.search$(searchParameters, null, false).toPromise();
            if (searchResult.itemsCount !== 1) {
                this.back();
                return;
            }

            sharedKey = searchResult.data[0];
        }

        await this.setSharedKey(sharedKey);

        this.loading = false;
    }

    async setSharedKey(sharedKey: ISharedKey) {
        this.existingSharedKey = sharedKey;

        this.sharedKeyForm.patchValue({
            allowValidation: this.existingSharedKey ? this.existingSharedKey.allowValidation : false,
            shareAllMeasuringPoints: this.existingSharedKey ? this.existingSharedKey.shareAllMeasuringPoints : false
        });

        if (this.existingSharedKey) {
            this.resourceSelectorComponent.measuringPointsComponent.setData(this.existingSharedKey.measuringPoints as IMeasuringPointSummary[]);
        } else {
            this.resourceSelectorComponent.measuringPointsComponent.clearData();
        }

        const ownerIdControl = this.sharedKeyForm.get("ownerId");
        if (ownerIdControl) {
            const organizationId = this.existingSharedKey ? this.existingSharedKey.owner.id : this.globalEventsService.getDefaultOrganization().id;
            ownerIdControl.setValue(organizationId);

            if (this.existingSharedKey) {
                ownerIdControl.disable();
            } else {
                ownerIdControl.enable();
            }

            this.updateOwnerId();

            this.subscriptionManager.add("ownerIdValueChange", ownerIdControl.valueChanges.subscribe(() => {
                this.selectionService.clearDevices();
                this.updateOwnerId();
            }));
        }
    }

    private async updateOwnerId() {
        const ownerIdControl = this.sharedKeyForm.get("ownerId");
        if (!ownerIdControl || !ownerIdControl.value) {
            this.ownerId = this.globalEventsService.getDefaultOrganization().id;
            return;
        }

        const organization = await this.mapDataService.getOrganization$(x => x.id === ownerIdControl.value);
        this.ownerId = organization.id;
    }

    back() {
        this.navigationService.toSharedKeys();
    }

    private createForm() {
        this.sharedKeyForm = this.formBuilder.group({
            allowValidation: false,
            shareAllMeasuringPoints: false
        }) as SigncoFormGroup;

        if (this.globalEventsService.hasMultipleOrganizations()) {
            this.sharedKeyForm.addControl("ownerId", this.formBuilder.control(null, Validators.required));
            this.updateOwnerId();
        }
    }

    private updateScrollHeight() {
        if (!this.column1) return;

        // We have 2 grids. Groups and measuring-points
        const toDivide = 2;

        setTimeout(() => {
            this.updateMapHeight();
        });
    }

    //#region Map

    isMapOpen() {
        return this.mapOpen;
    }

    toggleMap(mapDetail: MapDetail) {
        if (mapDetail === null || mapDetail === undefined) return;

        if (mapDetail === MapDetail.MeasuringPointGroups) this.toggleAddGroups();
        else this.toggleAddMeasuringPoints();
    }

    private openMap(detail: MapDetail) {
        this.updateMapHeight();
        this.mapDetail = detail;
        this.mapOpen = true;
    }

    closeMap() {
        this.mapOpen = false;
    }

    private updateMapHeight() {
        this.mapHeight = this.column1.nativeElement.offsetHeight - 44;
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.map.measuringPointsComponent.selectionMode = "multiple";
        }

        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.map.groupsComponent.selectionMode = "multiple";
        }
    }

    //#endregion Map

    //#region Measuring Point

    toggleAddMeasuringPoints() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPoints) {
            this.closeMap();
            return;
        }

        this.openMap(MapDetail.MeasuringPoints);
    }

    //#endregion Measuring Point

    //#region Groups

    toggleAddGroups() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.closeMap();
            return;
        }

        this.openMap(MapDetail.MeasuringPointGroups);
    }

    //#endregion Groups

    //#region Error handling

    isInputInError(): boolean {
        if (!this.sharedKeyForm.submitted) return false;

        const shareAllMeasuringPoints = this.sharedKeyForm.get("shareAllMeasuringPoints").value as boolean;
        if (shareAllMeasuringPoints) return false;

        return this.resourceSelectorComponent.isInputInError(this.sharedKeyForm.submitted);
    }

    //#endregion Error handling

    //#region Submit

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.sharedKeyForm);
        if (!isValid) return;

        // Can't be checked by default form validity, goes beyond the normal scope
        // So we check it properly here
        if (this.isInputInError()) return;

        this.submitting = true;

        const creator = this.existingSharedKey ? new SharedKeyUpdater(this.existingSharedKey) : new SharedKeyCreator();
        creator.allowValidation = this.sharedKeyForm.get("allowValidation").value as boolean;
        creator.shareAllMeasuringPoints = this.sharedKeyForm.get("shareAllMeasuringPoints").value as boolean;

        const ownerIdControl = this.sharedKeyForm.get("ownerId");
        if (ownerIdControl) {
            creator.ownerId = ownerIdControl.value as number;
        }

        if (!creator.shareAllMeasuringPoints) {
            const measuringPointIds = this.selectionService.getSelectedMeasuringPoints().map(x => x.id);
            const groupMeasuringPointIds = this.selectionService.getSelectedGroups().selectMany<IGroup, IGroupMeasuringPoint>(x => x.measuringPoints).map(x => x.measuringPoint.id);
            creator.measuringPointIds = measuringPointIds.concat(groupMeasuringPointIds).distinct();
        }

        const onSuccess = () => {
            this.back();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingSharedKey) {
            this.sharedKeyApi.create$(creator).subscribe(onSuccess, onError);
        } else {
            this.sharedKeyApi.update$(creator as SharedKeyUpdater).subscribe(onSuccess, onError);
        }

    }

    //#endregion Submit
}