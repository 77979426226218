import { Component, inject, NgZone, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, OnInit } from "@angular/core";
import { BasicMarkerOptions, createBasicMarker, BasicMapComponent, BasicMarkerEvent, IBasicMarker } from "src/app/modules/map-basic";
import { LocationWebApi } from "src/app/resource/web";
import { MapIconService } from "src/app/services/map-icon-service";
import { ColorUtils, MapUtils, SubscriptionManager } from "src/app/utilities";
import { SearchPanelComponent } from "../search-panel/search-panel.component";
import { LocationPopupComponent } from "../location-popup/location-popup.component";
import { ILocationSummary } from "src/app/models/web";
import { MapContextMenuItem } from "src/app/modules/map-basic";
import { BackendRights } from "src/app/models/backend-rights";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { TranslateService } from "@ngx-translate/core";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { UploadKmlDialogComponent } from "../upload-kml-dialog/upload-kml.dialog";
import { FilterService } from "../../services/filter.service";
import { ViewStateService } from "../../services/view-state.service";
import { LiveTilesService, LiveTileViewModel } from "src/app/modules/live-tiles/services/live-tiles.service";
import { LocationRealtimeService } from "src/app/services/realtime/location-realtime.service";
import { ILocationChangedArguments } from "src/app/models/location-changed-arguments";

/**
 * The new map that focuses on locations, not on individual measuring points.
 * Responsibilities:
 * * Filter locations (through the search panel)
 * * Show the location popup
 * * Show live tiles on the map
 * * Handle realtime (SignalR) updates
 */
@Component({
    selector: "app-location-map",
    templateUrl: "./location-map.component.html",
    styleUrls: ["./location-map.component.scss"],
})
export class LocationMapComponent implements OnInit, OnDestroy {
    protected basicMap: BasicMapComponent;

    @ViewChild(SearchPanelComponent) private searchPanel: SearchPanelComponent;
    @ViewChild(LocationPopupComponent) protected locationPopup: LocationPopupComponent;
    @ViewChild("floatingPanel", { static: false }) floatingPanel!: ElementRef;
    @ViewChild(LocationDialogComponent, { static: false }) locationDialog: LocationDialogComponent;
    @ViewChild(UploadKmlDialogComponent, { static: false }) uploadKeyholeMarkupDialog: UploadKmlDialogComponent;

    private readonly locationWebApi = inject(LocationWebApi);
    private readonly mapIconService = inject(MapIconService);
    private readonly zone = inject(NgZone);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly translateService = inject(TranslateService);
    protected readonly filterService = inject(FilterService);
    private readonly liveTilesService = inject(LiveTilesService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly locationRealtimeService = inject(LocationRealtimeService);

    protected readonly viewStateService = inject(ViewStateService);
    private selectedMarker: IBasicMarker;
    private subscriptionManager = new SubscriptionManager();

    ngOnInit(): void {
        this.filterService.setQueryParametersInUrl()
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    handleMapReady(basicMap: BasicMapComponent) {
        // There is a particular initializaton order:
        // * First the maps needs to be initialized (which means Google Maps is loaded)
        // * Then the MapIconService needs to be initialized (which means the icons are loaded)
        // * Then the rest of the initialization can happen
        // Doing it in this order avoids a lot of null checks

        this.basicMap = basicMap;
        this.searchPanel.searchBox.initializeMap(basicMap);

        this.mapIconService.onInitialized.subscribe(async () => {
            this.initializeSubscriptions();

            // Now that everything is initialized, we load the data and return to the previous viewstate
            // This uses separate actions which we run in parallel

            const loadDataAction = async () => {
                await this.loadData();
                if (this.viewStateService.selectedLocation) this.selectMarker(this.getMarker(this.viewStateService.selectedLocation.id));
            };

            const loadResultsAction = async () => {
                await this.searchPanel.loadData();
                if (this.viewStateService.selectedLocation) this.searchPanel.resultContainer.selectId(this.viewStateService.selectedLocation?.id);
            };

            await Promise.all([loadDataAction(), loadResultsAction()]);
        });
    }

    private initializeSubscriptions() {
        const locationSelectedSub = this.searchPanel.resultContainer.locationSelected.subscribe(locationId => this.handleResultClick(locationId));
        this.subscriptionManager.add("locationSelected", locationSelectedSub);

        const locationClickedSub = this.searchPanel.searchBox.onLocationClicked.subscribe(location => this.handleResultClick(location.id));
        this.subscriptionManager.add("locationClicked", locationClickedSub);

        const placeClickedSub = this.searchPanel.searchBox.onPlaceClicked.subscribe(geometry => this.basicMap.centerOnGeometry(geometry));
        this.subscriptionManager.add("placeClicked", placeClickedSub);

        const filterStateChangedSub = this.filterService.onFilterStateChanged$.subscribe(async () => await this.loadData());
        this.subscriptionManager.add("filterStateChanged", filterStateChangedSub);

        this.liveTilesService.subscribeToLiveTiles(this.subscriptionManager, () => this.cdr.detectChanges());

        const realtimeSub = this.locationRealtimeService.subscribe(args => this.onRealtimeLocationChanged(args));
        this.subscriptionManager.add("realtimeSub", realtimeSub);
    }

    private async loadData() {
        if (this.filterService.parsingFromQueryParams) return;

        const searchParameters = this.filterService.getSearchParameters();
        this.basicMap.markers.clear();
        this.basicMap.isLoading = true;
        const searchResult = await this.locationWebApi.getLocationsWithMeasuringPoints(searchParameters);
        this.basicMap.isLoading = false;

        this.zone.runOutsideAngular(async () => {

            if (this.filterService.parsingFromQueryParams) return;
            // Race condition when this method is called multiple times in quick succession.
            // Each invocation clears markers, then waits for the server, and adds the markers which are now duplicates.
            // Therefore we clear again. This is a quick fix, a better solution is to prevent the multiple invocations.
            this.basicMap.markers.clear();
            for (const viewModel of searchResult.data) {
                this.addLocation(viewModel);
            }

            this.basicMap.markers.repaintCluster();

            if (this.filterService.nextLoadShouldZoomToNewFilter) {
                this.filterService.nextLoadShouldZoomToNewFilter = false;
                const latLngs = searchResult.data.map(x => MapUtils.toLatLng(x));

                setTimeout(() => {
                    this.basicMap.centerOnLocations(latLngs);
                }, 100); // Anything lower than a 100 and it doesn't consistently work. Yup.
            }

            if (!this.locationPopup.isOpen && this.viewStateService.selectedLocation && searchResult.data.find(x => x.id === this.viewStateService.selectedLocation.id)) {
                await this.locationPopup.open(this.viewStateService.selectedLocation);
            }
        });
    }

    private addLocation(location: ILocationSummary) {
        const options = new BasicMarkerOptions(MapUtils.toLatLng(location));
        options.labelContent = location.code;

        const iconModel = this.mapIconService.getIconState(location.iconStateId);
        if (iconModel) {
            const yAnchor = 20 + iconModel.defaultIcon.anchor?.y; // 20 is the height of the label
            options.labelAnchor = new google.maps.Point(-6, yAnchor);
            options.icon = iconModel.defaultIcon;
            options.labelColor = iconModel.configuration.default.labelColor;
        }

        if (options.labelColor) options.textColor = ColorUtils.getMarkerTextColorHex(options.labelColor);

        const marker = createBasicMarker(options);
        this.addLocationToMarker(marker, location);
        this.basicMap.markers.add(marker, false);
    }

    private addLocationToMarker(marker: IBasicMarker, location: ILocationSummary) {
        marker.setLocation(location);
    }

    private getLocationFromMarker(marker: IBasicMarker): ILocationSummary {
        const data = marker.getLocation();
        return data as ILocationSummary;
    }

    protected handleMarkerClick(event: BasicMarkerEvent) {
        this.selectMarker(event.marker);

        const location = this.getLocationFromMarker(event.marker);
        this.searchPanel.resultContainer.selectId(location.id);
    }

    protected handleResultClick(locationId: number) {
        const marker = this.getMarker(locationId);
        if (!marker) {
            // This seems to happen sometimes. Maybe the results are loaded before the markers are created? I'm not sure.
            console.warn("A location was selected but the marker was not found.");
            return;
        }
        const location = this.getLocationFromMarker(marker);

        this.ensureVisible(MapUtils.toLatLng(location));
        this.selectMarker(marker);
    }

    // make sure that the location is visible and not occluded by the popups
    private ensureVisible(latlng: google.maps.LatLng) {
        this.zone.runOutsideAngular(() => {
            this.basicMap.zoomToNoCluster();

            if (this.getVisibleMapBounds().contains(latlng)) {
                // If the location is visible, we won't recenter the map. Users don't like it when the map moves around
                return;
            }

            const center = this.getVisibleMapCenterX();
            const offsetX = center.x - (window.innerWidth / 2);

            const newCenter = MapUtils.offset(this.basicMap.map, latlng, offsetX, 0);
            this.basicMap.panTo(newCenter);
        });
    }

    /**
     * Our map is partially occluded by the floating panel. This method calculates the center (in pixels) of the visible map.
     */
    private getVisibleMapCenterX(): google.maps.Point {
        const floatingPanelWidth = this.floatingPanel.nativeElement.offsetWidth;
        const mapWidth = window.innerWidth;
        const visibleMapWidth = mapWidth - floatingPanelWidth;
        const centerX = floatingPanelWidth + visibleMapWidth / 3; // 1/3 of the visible map width, this looks nicer than the absolute center

        const centerY = window.innerHeight / 2;
        return new google.maps.Point(centerX, centerY);
    }

    /**
     * Our map is partially occluded by the floating panel. This method calculates the bounds of the part of the map that is visible
     */
    private getVisibleMapBounds(): google.maps.LatLngBounds {
        const offsetScale = this.floatingPanel.nativeElement.offsetWidth / window.innerWidth;
        const totalBounds = this.basicMap.map.getBounds();
        const ne = totalBounds.getNorthEast();
        const sw = totalBounds.getSouthWest();

        const offsetX = (ne.lng() - sw.lng()) * offsetScale;

        // We offset the bounds by some pixels, we don't want the selected marker to be too close to the edge
        const marginX = (window.innerWidth - this.floatingPanel.nativeElement.offsetWidth) * 0.05;
        const marginY = window.innerHeight * 0.05;
        const result = new google.maps.LatLngBounds(
            MapUtils.offset(this.basicMap.map, new google.maps.LatLng(sw.lat(), sw.lng() + offsetX), -marginX, marginY),
            MapUtils.offset(this.basicMap.map, new google.maps.LatLng(ne.lat(), ne.lng()), marginX, -marginY)
        );
        return result;
    }

    private getMarker(locationId: number): IBasicMarker {
        for (const marker of this.basicMap.markers.getMarkers()) {
            const location = this.getLocationFromMarker(marker);
            if (location.id === locationId) {
                return marker;
            }
        }
    }

    private async selectMarker(marker: IBasicMarker) {
        if (marker === this.selectedMarker) return; // Some users click a marker more than once to select it. We only load the data once.

        if (this.selectedMarker) {
            // Deselect previous
            this.selectedMarker.setFocusState(false);
            this.selectedMarker = null;
        }

        if (!marker) {
            this.locationPopup.close();
            this.searchPanel.resultContainer.deselect();
        } else {

            const location = this.getLocationFromMarker(marker);
            this.selectedMarker = marker;
            this.selectedMarker.setFocusState(true);

            await this.locationPopup.open(location);
        }

        // Since focused markers don't cluster and always show, repaint
        this.basicMap.markers.repaintCluster();
    }

    protected openSearchPanel() {
        this.viewStateService.isSearchPanelOpen = true;
    }

    protected closeSearchPanel() {
        this.viewStateService.isSearchPanelOpen = false;
    }

    protected handleMapClick() {
        // If the user clicks on an empty space on the map, we deselect the marker
        this.clearSelectedMarker();
    }

    protected handleMapRightClick(event: google.maps.MapMouseEvent) {
        const buttons = new Array<MapContextMenuItem>();

        if (this.globalEventsService.currentRights.value?.hasBackendRight(BackendRights.EditMeasuringPoint)) {
            buttons.push(new MapContextMenuItem(this.translateService.instant("manageMeasuringPoint.create"), "add", () => {
                const coordinate = MapUtils.mouseEventToCoordinate(event);
                this.locationDialog.create(coordinate);
            }));

            buttons.push(new MapContextMenuItem(this.translateService.instant("uploadKeyholeMarkup.title"), "add", async () => {
                await this.uploadKeyholeMarkupFile();
            }));

            if (this.liveTiles && this.liveTiles.length > 0) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("liveTiles.closeAllLiveTiles"), "delete", () => {
                    if (this.liveTiles && this.liveTiles.length > 0) {
                        for (const liveTile of this.liveTiles) {
                            this.liveTilesService.removeLiveTileId(liveTile.measuringPoint.id);
                        }
                    }
                }));
            }
        }

        this.basicMap.openContextMenu(event.latLng, buttons);
    }

    protected clearSelectedMarker() {
        this.selectMarker(null);
    }

    private async uploadKeyholeMarkupFile() {
        const onNewLocationsCreated = async (locations: ILocationSummary[]) => {
            const latLngs = locations.map(x => MapUtils.toLatLng(x));
            this.basicMap.centerOnLocations(latLngs);

            this.filterService.filterState.clear(); // Otherwise the new locations may not be visible
            this.filterService.notifyFilterStateChanged();
        };

        this.uploadKeyholeMarkupDialog.open(onNewLocationsCreated);
    }

    // Provide easy access in the template
    protected get liveTiles(): LiveTileViewModel[] {
        return this.liveTilesService.getLiveTileViewModels();
    }

    private onRealtimeLocationChanged(args: ILocationChangedArguments) {
        this.zone.runOutsideAngular(() => {
            for (const details of args.data) {
                const marker = this.getMarker(details.id);
                if (!marker) return;

                // Several properties of the location could have changed, we handle them one by one

                // coordinate
                this.animateMarkerToNewPosition(marker, details.lat, details.lng);

                // icon
                const icon = this.mapIconService.getLocationIcon(details);
                if (icon) { // icon can be null in an unlikely situation: a new icon was added, but we don't have it yet
                    marker.setIcon(icon.defaultIcon);
                }

                // code
                marker.setLabelContent(details.code);
            }
        });
    }

    private animateMarkerToNewPosition(marker: IBasicMarker, latitude: number, longitude: number) {
        // We provide a smooth animation that is less confusing for the user
        const animationDuration = 1000;
        const startLatitude = marker.getPosition().lat();
        const startLongitude = marker.getPosition().lng();

        const deltaLatitude = latitude - startLatitude;
        const deltaLongitude = longitude - startLongitude;
        let animationStartTime: number | null = null;

        // The function that will be executed for every frame of the animation
        function animateMarker(timestamp: number) {
            if (!animationStartTime) {
                animationStartTime = timestamp;
            }
            const elapsedTime = timestamp - animationStartTime;
            const progress = Math.min(elapsedTime / animationDuration, 1);

            marker.setPosition(
                new google.maps.LatLng(
                    startLatitude + progress * deltaLatitude,
                    startLongitude + progress * deltaLongitude
                )
            );

            if (progress < 1) {
                // Request the next frame to continue the animation
                requestAnimationFrame(animateMarker);
            }
        }

        // Start the animation
        requestAnimationFrame(animateMarker);
    }

    protected handleStreetViewToggle(isVisible: boolean) {
        this.viewStateService.isInStreetView = isVisible;
    }
}
