import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ServiceStatusComponent } from "./components/service-status/service-status.component";
import { AuthGuard } from "./guards/auth.guard";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";

const routes: Routes = [
    {
        // Remain compatible with the old /map/measuringpoints route
        path: "map/measuringpoint/:locationId/:mpId/:tab",
        redirectTo: "locations/:locationId/:mpId/:tab"
    },
    { path: "", component: LandingPageComponent, data: { name: "landingPage.title" } },
    {
        path: "about",
        loadChildren: () => import("./modules/about/about.module").then(m => m.AboutModule),
        data: { name: "about.title" }
    },
    {
        path: "notifications",
        loadChildren: () => import("./modules/notifications/notifications.module").then(m => m.NotificationsModule),
        canActivate: [AuthGuard],
        data: { name: "notifications.title" }
    },
    {
        path: "qr",
        loadChildren: () => import("./modules/qr/qr.module").then(m => m.QrModule),
        data: { name: "qr.title" }
    },
    { path: "status", component: ServiceStatusComponent, data: { name: "serviceStatus.title" } },
    {
        path: "data",
        loadChildren: () => import("./modules/data/data.module").then(m => m.DataModule),
        canActivate: [AuthGuard],
        data: { name: "reports.title" }
    },
    {
        path: "reports",
        loadChildren: () => import("./ui/pages/reports/reporting-routing.module").then(m => m.ReportingRoutingModule),
        canActivate: [AuthGuard],
        data: { name: "reports.title" }
    },
    {
        path: "scenarios",
        loadChildren: () => import("./ui/pages/scenarios/scenarios-routing.module").then(m => m.ScenariosRoutingModule),
        canActivate: [AuthGuard],
        data: { name: "scenarios.title" }
    },
    {
        path: "link",
        loadChildren: () => import("./modules/link/link.module").then(m => m.LinkModule),
        canActivate: [AuthGuard],
        data: { name: "manageLink.title" }
    },
    {
        path: "share",
        loadChildren: () => import("./modules/share-data/share-data.module").then(m => m.ShareDataModule),
        canActivate: [AuthGuard],
        data: { name: "share-data.title" }
    },
    {
        path: "management",
        loadChildren: () => import("./modules/management/management.module").then(m => m.ManagementModule),
        data: { name: "management.title" }
    },
    {
        path: "map",
        loadChildren: () => import("./modules/map-advanced/map-advanced.module").then(m => m.MapAdvancedModule),
        data: { name: "map.title" }
    },
    {
        path: "locations",
        loadChildren: () => import("./modules/locations/locations.module").then(m => m.LocationsModule),
        canActivate: [AuthGuard],
        data: { name: "locations.title" }
    },
    {
        path: "documentation",
        loadChildren: () => import("./modules/documentation/documentation.module").then(m => m.DocumentationModule),
        canActivate: [AuthGuard],
        data: { name: "documentation.title" }
    },

    {
        path: "history",
        loadChildren: () => import("./ui/pages/history/history.routing").then(m => m.HistoryRouting),
        canActivate: [AuthGuard],
        data: { name: "history.title" }
    },
    {
        path: "administration",
        loadChildren: () => import("./ui/pages/administration/administration.routing").then(m => m.AdministrationRouting),
        canActivate: [AuthGuard],
        data: { name: "organizationOverview.title" }
    },
    {
        path: "planning",
        loadChildren: () => import("./ui/pages/planning/assignment-planning.routing").then(m => m.PlanningRouting),
        canActivate: [AuthGuard],
        data: { name: "assignmentPlanning.title" }
    },
    {
        path: "worklists",
        loadChildren: () => import("./ui/pages/worklists/worklists.routing").then(m => m.WorklistsRouting),
        canActivate: [AuthGuard],
        data: { name: "worklists.title" }
    },
    {
        path: "scenarios",
        loadChildren: () => import("./ui/pages/scenarios/scenarios-routing.module").then(m => m.ScenariosRoutingModule),
        canActivate: [AuthGuard],
        data: { name: "scenarios.title" }
    },
    {
        path: "reports",
        loadChildren: () => import("./ui/pages/reports/reporting-routing.module").then(m => m.ReportingRoutingModule),
        canActivate: [AuthGuard],
        data: { name: "reports.title" }
    },
    { path: "**", redirectTo: "locations" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: "ignore" })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
