import { Injectable } from "@angular/core";
import { AvsApiService } from "./avs-api";
import { TrafficLightData } from "src/app/models/avs/traffic-light-data";

@Injectable({
    providedIn: "root"
})
export class TrafficLightDataAvsApi extends AvsApiService<TrafficLightData> {
    getRoute(): string {
        return "trafficLightData";
    }
}