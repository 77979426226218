import { CustomTableComponent, TableColumn, FilterType, TableService, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { IMeasuringPointFilter, ReportDirection } from "src/app/models/report-type";
import { forkJoin } from "rxjs";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { IMeasuringPointSummary } from "src/app/models/web";
import { IGroup, IGroupMeasuringPoint } from "src/app/models/group";

@Component({
    selector: "app-select-measuring-points",
    templateUrl: "./select-measuring-points.component.html"
})
export class SelectMeasuringPointsComponent extends CustomTableComponent<IMeasuringPointSummary> implements OnChanges {
    @Input() requiresInput: boolean;
    @Input() group: IGroup;
    @Input() readonly = false;
    @Input() deleteCommand = true;
    @Input() hideDirectionFilters = true;
    @Input() allowedDirections: ReportDirection[];

    isAllForwardSelected: boolean;
    isAllReverseSelected: boolean;
    isAllSumSelected: boolean;

    includeForwardDirectionColumn: TableColumn;
    includeReverseDirectionColumn: TableColumn;
    includeSumColumn: TableColumn;

    useDefaultSort: boolean;

    isDirty = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService,
        private readonly measuringPointWebApi: MeasuringPointWebApi) {

        super("select-measuring-points.component", elementRef, tableService);

        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = true;
        this.useDefaultSort = false;


        this.addColumn(new TableColumn("code", "measuringPoints.code", { filterType: FilterType.Text, sortable: false }));
        this.includeForwardDirectionColumn = new TableColumn("includeForwardDirection", "", { sortable: true, type: ColumnType.Icon, width: 60 });
        this.includeReverseDirectionColumn = new TableColumn("includeReverseDirection", "", { sortable: true, type: ColumnType.Icon, width: 60 });
        this.includeSumColumn = new TableColumn("includeSum", "", { sortable: true, type: ColumnType.Icon, width: 60 });
        this.updateDirectionColumnsVisibility();

        const addData = (x: IMeasuringPointSummary[]) => {
            if (this.readonly) return;
            this.isDirty = true;
            this.addData(x);
        };

        const removeData = (x: IMeasuringPointSummary[]) => {
            if (this.readonly) return;
            this.isDirty = true;
            this.removeData(x);
        };

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, addData, removeData);

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (measuringPoint) => this.removeData([measuringPoint]),
            validFunc: () => this.deleteCommand
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const inputChange = changes["group"] || changes["requiresInput"];
        if (inputChange) {
            this.loadTableRows();
        }

        const hideDirectionFiltersChange = changes["hideDirectionFilters"];
        if (hideDirectionFiltersChange) {
            this.updateDirectionColumnsVisibility();
        }

        const allowedDirectionsFiltersChange = changes["allowedDirections"];
        if (allowedDirectionsFiltersChange) {
            this.updateAllowedDirectionsColumns();
        }

        super.ngOnChanges(changes);
    }

    reorder() {
        this.isDirty = true;
        this.table.value.forEach((mp, index) => {
            mp.sortOrder = index + 1;
        });
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.group;
    }

    loadTableRows() {
        if (!this.group) return;

        this.addGroupMeasuringPoints(this.group.measuringPoints);
    }

    onSetData() {
        if (!this.readonly) {
            this.selectionService.setMeasuringPoints(this.data);
        }
    }

    private addGroupMeasuringPoints(groupMeasuringPoints: IGroupMeasuringPoint[]) {
        // SIGNCO-1717: When displaying group measuring points apply the predefined sort order
        this.applyGroupSorting(groupMeasuringPoints);
        this.addData(groupMeasuringPoints.map(x => {
            x.measuringPoint.includeForwardDirection = x.includeForwardDirection;
            x.measuringPoint.includeReverseDirection = x.includeReverseDirection;
            x.measuringPoint.includeSum = x.includeSum;

            return x.measuringPoint;
        }));
    }

    private applyGroupSorting(groupMeasuringPoints: IGroupMeasuringPoint[]) {
        this.useDefaultSort = false;
        // We rely on the sorting of the server
    }

    alphabeticalSorting() {
        this.table.sortField = "code";
        this.table.sortOrder = 1;
        this.table.sortSingle();
        this.table.sortField = undefined;
        this.table.sortOrder = 0;
    }

    //#region Header Checkboxes

    updateHeaderCheckboxes() {
        this.updateIsAllForwardSelected();
        this.updateIsAllReverseSelected();
        this.updateIsAllSumSelected();
    }

    updateIsAllForwardSelected() {
        this.isAllForwardSelected = this.getCurrentData().length && !this.getCurrentData().find(x => !x.includeForwardDirection);
    }

    updateIsAllReverseSelected() {
        this.isAllReverseSelected = this.getCurrentData().length && !this.getCurrentData().find(x => !x.includeReverseDirection);
    }

    updateIsAllSumSelected() {
        this.isAllSumSelected = this.getCurrentData().length && !this.getCurrentData().find(x => !x.includeSum);
    }

    bulkSelectChanged(direction: string) {
        if (direction === ReportDirection.Forward) this.handleIsAllForwardChanged();
        if (direction === ReportDirection.Reverse) this.handleIsAllReverseChanged();
        if (direction === ReportDirection.Sum) this.handleIsAllSumChanged();
    }

    handleIsAllForwardChanged() {
        this.getCurrentData().forEach(x => {
            if (x.includeForwardDirection === this.isAllForwardSelected) return;

            x.includeForwardDirection = this.isAllForwardSelected;
        });
    }

    handleIsAllReverseChanged() {
        this.getCurrentData().forEach(x => {
            if (x.includeReverseDirection === this.isAllReverseSelected) return;

            x.includeReverseDirection = this.isAllReverseSelected;
        });
    }

    handleIsAllSumChanged() {
        this.getCurrentData().forEach(x => {
            if (x.includeSum === this.isAllSumSelected) return;

            x.includeSum = this.isAllSumSelected;
        });
    }

    disableReorderingIfReadonly() {
        this.reorderable = this.readonly ? false : this.reorderable;
        this.updateReorderableColumn();
    }

    updateDirectionColumnsVisibility() {
        if (this.hideDirectionFilters) {
            this.removeColumn(this.includeForwardDirectionColumn);
            this.removeColumn(this.includeReverseDirectionColumn);
            this.removeColumn(this.includeSumColumn);
        } else {
            this.addColumn(this.includeForwardDirectionColumn);
            this.addColumn(this.includeReverseDirectionColumn);
            this.addColumn(this.includeSumColumn);
        }
    }

    updateAllowedDirectionsColumns() {
        if (!this.allowedDirections || this.hideDirectionFilters) return;

        this.removeColumn(this.includeForwardDirectionColumn);
        this.removeColumn(this.includeReverseDirectionColumn);
        this.removeColumn(this.includeSumColumn);

        if (this.allowedDirections.contains(ReportDirection.Forward)) this.addColumn(this.includeForwardDirectionColumn);
        if (this.allowedDirections.contains(ReportDirection.Reverse)) this.addColumn(this.includeReverseDirectionColumn);
        if (this.allowedDirections.contains(ReportDirection.Sum)) this.addColumn(this.includeSumColumn);
    }
    //#endregion Header Checkboxes


    //#region Reporting
    async addMeasuringPointFilters(measuringPointFilters: IMeasuringPointFilter[]) {
        if (!measuringPointFilters || !measuringPointFilters.length) return;

        const measuringPointsWithDirection = (await forkJoin(
            measuringPointFilters.map(async measuringPointFilter => {
                if (!measuringPointFilter.measuringPoint) {
                    measuringPointFilter.measuringPoint = await this.measuringPointWebApi.get(measuringPointFilter.id);
                }

                measuringPointFilter.measuringPoint.includeForwardDirection = measuringPointFilter.includeForwardDirection;
                measuringPointFilter.measuringPoint.includeReverseDirection = measuringPointFilter.includeReverseDirection;
                measuringPointFilter.measuringPoint.includeSum = measuringPointFilter.includeSum;

                return measuringPointFilter.measuringPoint;
            })
        ).toPromise());

        this.addData(measuringPointsWithDirection);
    }


    getMeasuringPointFilters(): IMeasuringPointFilter[] {
        const retArray: IMeasuringPointFilter[] = new Array();

        this.table.value.forEach((el) => {
            const objectToAdd = this.data.find(x => x.id == el.id);
            retArray.push({
                id: objectToAdd.id,
                includeForwardDirection: this.hideDirectionFilters ? undefined : objectToAdd.includeForwardDirection,
                includeReverseDirection: this.hideDirectionFilters ? undefined : objectToAdd.includeReverseDirection,
                includeSum: this.hideDirectionFilters ? undefined : objectToAdd.includeSum
            } as IMeasuringPointFilter);
        });

        return retArray;
    }


    getMeasuringPoints(): IMeasuringPointSummary[] {
        return this.data.map(x => x);
    }

    //#endregion Reporting
}
