import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-car-and-bike-configuration",
    templateUrl: "./device-link-car-and-bike-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkCarAndBikeConfigurationComponent extends DeviceLinkFormGroupComponent {
    carAndBikeRemark: string;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly translateService: TranslateService,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (this.analyzerConfiguration?.carAndBikeConfiguration) {
            if (this.fgd.form.get("carAndBikeConfiguration")) this.fgd.form.removeControl("carAndBikeConfiguration");

            this.form = this.formBuilder.group({
                hasCars: [true],
                hasBikes: [true],
                hasSignificantPedestrians: [false],
                hasSignificantTrucks: [false],
                legalSpeed: [null, [Validators.required, Validators.min(1)]]
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.carAndBikeConfiguration);
            this.fgd.form.addControl("carAndBikeConfiguration", this.form);
            this.onCarAndBikeDetailsChanged();
            return true;
        }


        if (this.fgd.form.get("carAndBikeConfiguration")) this.fgd.form.removeControl("carAndBikeConfiguration");
        this.form = null;
        return false;
    }

    onCarAndBikeDetailsChanged() {
        /*
        hasCars: [true],
            hasBikes: [true],
            hasSignificantPedestrians: [false],
            hasSignificantTrucks: [false],
        */
        if (this.form.get("hasBikes").value === false) {
            this.carAndBikeRemark = null;
        } else {
            if (this.form.get("hasCars").value === false) {
                this.carAndBikeRemark = this.translateService.instant("uploadDetailsDialog.bikeParameterFree");
            } else {
                if (this.form.get("hasSignificantTrucks").value === true) {
                    this.carAndBikeRemark = this.translateService.instant("uploadDetailsDialog.bikeParameterTrucks");
                } else {
                    this.carAndBikeRemark = this.translateService.instant("uploadDetailsDialog.bikeParameterNoTrucks");
                }
            }
        }
    }
}