<div class="location-map">
    <app-basic-map [showCoordinates]="true"
                   [showMapTypeSelector]="true"
                   [saveState]="true"
                   [showStreetViewControl]="true"
                   (mapReady)="handleMapReady($event)"
                   (markerClick)="handleMarkerClick($event)"
                   (mapClick)="handleMapClick()"
                   id="locationsMap"
                   (mapRightClick)="handleMapRightClick($event)"
                   (streetViewToggle)="handleStreetViewToggle($event)"
    >
    </app-basic-map>

    <div *ngIf="liveTiles">
        <div *ngFor="let liveTile of liveTiles">
            <app-live-tile [gmap]="basicMap"
                           [viewModel]="liveTile">
            </app-live-tile>
        </div>
    </div>

    <div #floatingPanel
         class="location-map__floating">
        <button *ngIf="!viewStateService.isSearchPanelOpen"
                (click)="openSearchPanel()"
                id="buttonOpenSearchPanel"
                class="location-map__btn-open-search-panel">
            <app-svg-icon icon="search"
                          [divStyle]="{width: '100%'}" />

            <div *ngIf="!filterService.filterState.isEmpty()"
                 class="m-alert-badge">
                <small>
                    {{ filterService.filterState.count() }}
                </small>
            </div>
        </button>

        <app-search-panel [hidden]="!viewStateService.isSearchPanelOpen || viewStateService.isInStreetView"
                          (hiddenSet)="viewStateService.isSearchPanelOpen = !$event"
                          id="searchPanel"
                          class="location-map__search-panel"
                          (onClose)="closeSearchPanel()">
        </app-search-panel>

        <app-location-popup class="location-map__location-popup-container"
                            id="locationPopup"
                            (onClose)="clearSelectedMarker()">
        </app-location-popup>
    </div>

    <app-location-dialog></app-location-dialog>

    <app-upload-kml-dialog></app-upload-kml-dialog>
</div>
