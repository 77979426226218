<button class="m-btn m-btn-secondary"
        style="position: absolute; right:0px; z-index: 10; padding: 2px; min-height: 0px; background: #fff9;"
        (click)="openManageDialog()">
    <app-svg-icon icon="edit"></app-svg-icon>
</button>


<ng-container *ngIf="liveData">
    <h3 *ngIf="!viewModel.pinnedDataConfiguration?.defaultLiveDataRangeOption">{{"liveTiles.measuredData.liveData" | translate}} <small>({{liveData.from | momentTime}} - {{liveData.until | momentTime}})</small></h3>
    <div class="flex gap-8">
        <div *ngFor="let lastValuesGroup of liveData.lastValuesGroups; trackBy: trackByFunctionForLastValuesGroup"
             style="width: auto;">
            <h4 *ngIf="viewModel.pinnedDataConfiguration.supportedOptions && viewModel.pinnedDataConfiguration.supportedOptions.length>1"
                class="mb-4">{{lastValuesGroup.option | translateEnum:"pinnedDataOption" }}</h4>

            <table *ngIf="lastValuesGroup?.lastValues && lastValuesGroup?.lastValues.length>0"
                   class="p-datatable"
                   style="width:auto;">
                <tbody class="p-datatable-tbody">
                    <tr *ngFor="let lastValue of lastValuesGroup.lastValues; trackBy: trackByFunctionForLastValue"
                        style="display: table-row">
                        <ng-container *ngIf="(lastValue.dataSetType | dataSetName:translateService.currentLang:lastValuesGroup.option | async) as dataSetTypeName;">
                            <td style="display: table-cell">
                                <div class="flex align-center last-value-datasetname-div">
                                    <span style="border-radius: 50%; width: 10px; height: 10px;"
                                          class="block mr-4"
                                          [style.backgroundColor]="getColorPerDataSetTypeAndVehicleCategory(lastValuesGroup, lastValue)">
                                    </span>
                                    {{dataSetTypeName}}
                                </div>
                            </td>
                            <td style="display: table-cell">
                                <div class="last-value-div">
                                    {{lastValue.value ?? '-'}}
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<hr *ngIf="liveData && historicalData">

<ng-container *ngIf="historicalData">
    <div ngResizable
         rzMinWidth="260"
         rzMinHeight="120"
         rzHandles="se"
         #resizableDiv>
        <div class="m-livetile-chart"
             [hidden]="!chartConfiguration">
            <app-chart *ngIf="chartConfiguration"
                       [configuration]="chartConfiguration"></app-chart>
        </div>
    </div>
</ng-container>

<app-loading-spinner *ngIf="liveDataLoading || historicalDataLoading"></app-loading-spinner>

<app-measured-data-manage-dialog></app-measured-data-manage-dialog>