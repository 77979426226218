import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-image-dialog",
    templateUrl: "./image-dialog.component.html"
})
export class ImageDialogComponent extends DialogComponentBase {
    @Input() imageClass: string = '';

    imageName: string;
    imagePreview: string;

    create(name: string, imagePreview: string): void {
        this.imageName = name;
        this.imagePreview = imagePreview;
        this.openDialog();
    }
}
