<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="carConfiguration">
    <div class="p-16 m-border-bottom m-border-top">
        <h3>{{"uploadDetailsDialog.carConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row">
            <div class="col-2"></div>
            <div class="col-4">
                <label class="m-input-checkbox-block">
                    <input [formControl]="form.get('analyzeForwardDirection')"
                           name="name"
                           type="checkbox"> {{"uploadDetailsDialog.includeTraffic" | translate}}
                </label>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-2"></div>
            <div class="col-4">
                <label class="m-input-checkbox-block">
                    <input [formControl]="form.get('analyzeReverseDirection')"
                           name="name"
                           type="checkbox"> {{"uploadDetailsDialog.includeOncomingTraffic" | translate}}
                </label>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('legalSpeed').showErrors }">
            <label class="col-2"
                   for="legalSpeed">
                {{"uploadDetailsDialog.speedLimit" | translate}}
            </label>

            <div class="col-4">
                <div class="m-input-group">
                    <input [formControl]="form.get('legalSpeed')"
                           name="legalSpeed"
                           id="legalSpeed"
                           type="number"
                           class="p-inputtext"
                           step="0.1"
                           #legalSpeedInput>

                    <span class="m-input-group-addon">{{"measurements.kmh" | translate}}</span>
                </div>

                <app-form-validation [control]="form.get('legalSpeed')"
                                     [input]="legalSpeedInput">
                </app-form-validation>
            </div>
        </div>
    </div>
</div>