import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { SigncoFormGroup } from "src/app/models/form";
import { ICoordinate, ILocation, LocationCreator, LocationUpdater } from "src/app/models/location";
import { IMapIcon } from "src/app/models/map-icon";
import { IOrganization } from "src/app/models/user";
import { LocationApi } from "src/app/resource/location.api";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { MapDataService } from "src/app/services/map-data.service";
import { MapIconService } from "src/app/services/map-icon-service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { OrganizationUtils, TimeZoneUtils } from "src/app/utilities";

@Component({
    selector: "app-location-dialog-content",
    templateUrl: "./location-dialog-content.component.html"
})
export class LocationDialogContentComponent implements OnDestroy, OnChanges {
    @Input() onlyShowMap = false;
    @Input() readonly = false;
    @Input() initialCoordinate: ICoordinate;
    @Input() initialCode: string;
    @Input() isCodeRequired = true;
    @Input() readonlyCode: boolean;
    @Input() existingLocation: ILocation;
    @Input() manageOrganization: boolean;
    @Input() requiresInput: boolean;

    @Output() created: EventEmitter<LocationCreator> = new EventEmitter<LocationCreator>();
    @Output() updated: EventEmitter<ILocation> = new EventEmitter<ILocation>();
    @Output() error: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    submitting: boolean;
    typeId: string;
    locationForm: SigncoFormGroup;
    allOrganizations: IOrganization[];
    organizations: SelectItem[];
    datePeriodSets: SelectItem[];
    timeZones: SelectItem[];
    mapIcons: IMapIcon[];

    private readonly mapDataKey: string;

    constructor(
        elementRef: ElementRef,
        readonly cd: ChangeDetectorRef,
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly toast: ToastService,
        private readonly mapDataService: MapDataService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly primeComponentService: PrimeComponentService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly locationApi: LocationApi,
        private readonly mapIconService: MapIconService) {

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.allOrganizations = organizations;
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id, x => x.name, false, "",
                OrganizationUtils.getStyleClass);
        });

        this.domainModelFilterService.getDatePeriodSets$().then(datePeriodSets => {
            this.datePeriodSets = datePeriodSets;
        });

        this.timeZones = TimeZoneUtils.getSelectItems();

        this.mapIcons = this.mapIconService.getCustomIcons();

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges) {
        const locationChange = changes["existingLocation"];
        const initialCoordinateChange = changes["initialCoordinate"];
        const manageOrganizationChange = changes["manageOrganization"];
        const requiresInputChange = changes["requiresInput"];
        const isCodeRequiredChange = changes["isCodeRequired"];
        const readonlyChange = changes["readonly"];

        if (!requiresInputChange || (locationChange || initialCoordinateChange || manageOrganizationChange) || isCodeRequiredChange) {
            this.intialize();
        }

        if (readonlyChange) {
            this.intialize();
        }
    }

    ngOnDestroy() {
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    intialize() {
        if (this.existingLocation) {
            this.initialCoordinate = this.existingLocation.coordinate;
        }

        const defaultOrganization = this.globalEventsService.getDefaultOrganization();
        this.locationForm = this.formBuilder.group({
            code: [this.initialCode ? this.initialCode : "", this.isCodeRequired ? Validators.required : null],
            description: "",
            datePeriodSetId: null,
            timeZone: ["Europe/Brussels", Validators.required],
            mapIconId: null,
            isLinkedToGps: false,
        }) as SigncoFormGroup;

        if (this.manageOrganization) {
            this.locationForm.addControl("ownerId", this.formBuilder.control(defaultOrganization ? defaultOrganization.id : null, Validators.required));
        }

        if (this.existingLocation) {
            const organization = this.organizations.find(x => x.value === this.existingLocation.ownerId);

            this.locationForm.patchValue({
                code: this.existingLocation.code,
                description: this.existingLocation.description,
                datePeriodSetId: this.existingLocation.datePeriodSetId,
                ownerId: organization.value,
                timeZone: this.existingLocation.timeZone,
                mapIconId: this.existingLocation.mapIconId,
                isLinkedToGps: this.existingLocation.isLinkedToGps
            });

            // It's a "home" location for an organization
            if (this.existingLocation.organizationId) {
                this.locationForm.get("code").disable();

                const ownerIdControl = this.locationForm.get("ownerId");
                if (ownerIdControl) ownerIdControl.disable();
            }
        }

        this.ownerIdChanged();

        this.submitting = false;

        // TODO: There's something fucky going on with angular form bindings when enabling / disabling
        // https://github.com/angular/angular/issues/22556
        setTimeout(() => {
            if (this.readonly) {
                this.locationForm.disable();
            } else {
                this.locationForm.enable();

                if (this.existingLocation) {
                    // It's a "home" location for an organization
                    if (this.existingLocation.organizationId) {
                        this.locationForm.get("code").disable();

                        const ownerIdControl = this.locationForm.get("ownerId");
                        if (ownerIdControl) ownerIdControl.disable();
                    }
                }
            }
        }, 50);
    }

    get selectedPeriodSetId(): number {
        return this.locationForm.get("datePeriodSetId").value;
    }

    get selectedPeriodSetName(): string {
        const periodSetId = this.selectedPeriodSetId;
        return this.datePeriodSets?.find(x => x.value === periodSetId)?.label;
    }

    get defaultPeriodSetId(): number {
        const ownerIdControl = this.locationForm.get("ownerId");
        const defaultOrganization = this.globalEventsService.getDefaultOrganization();
        const selectedOrganizationId = ownerIdControl?.value as number ?? defaultOrganization.id;
        const selectedOrganization = this.allOrganizations.find(x => x.id === selectedOrganizationId);
        const datePeriodSetId = selectedOrganization?.datePeriodSet?.id;

        return datePeriodSetId;
    }

    ownerIdChanged() {
        const datePeriodSetIdControl = this.locationForm.get("datePeriodSetId");
        datePeriodSetIdControl.patchValue(this.defaultPeriodSetId);
        datePeriodSetIdControl.markAsPristine();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.locationForm);
        if (!isValid) return;

        if (!this.existingLocation) {
            this.createNew();
            return;
        }

        this.updateExisting();
    }

    private cleanCreator(creator: LocationCreator) {
        if (creator.datePeriodSetId === this.defaultPeriodSetId) {
            delete creator.datePeriodSetId;
        }
    }

    private createNew() {
        // When creating a new location, we don't do an api call. We don't like empty locations.
        // So we pass back the "created" location so we can continue making a measuringpoint or something else
        const locationCreator = Object.assign(new LocationCreator(), this.locationForm.value) as LocationCreator;
        this.cleanCreator(locationCreator);
        const location = locationCreator as ILocation;
        location.measuringPoints = [];
        location.ownerId = locationCreator.ownerId || this.globalEventsService.getDefaultOrganization().id;
        this.locationForm.markAsPristine();
        this.created.next(location);
    }

    private updateExisting() {
        this.submitting = true;

        const onSuccess = async (updatedLocation: ILocation) => {
            this.toast.saveSuccess();
            this.submitting = false;
            this.locationForm.markAsPristine();

            if (!updatedLocation.mapIconId) updatedLocation.mapIconId = null; // Otherwise it would be filled in by the next line.
            Object.assign(this.existingLocation, updatedLocation); // Why do we do this? Copy data from the old to updated?
            this.updated.next(this.existingLocation);
        };

        const onError = () => {
            this.submitting = false;
            this.error.next();
        };

        const locationUpdater = Object.assign(new LocationUpdater(this.existingLocation), this.locationForm.value);
        this.cleanCreator(locationUpdater);
        this.locationApi.update$(locationUpdater).subscribe(onSuccess, onError);
    }

    onTimeZoneChanged(timeZone: string) {
        this.locationForm.get("timeZone").setValue(timeZone);
        this.cd.detectChanges();
    }
}
