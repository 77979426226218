import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AnalyzerConfiguration, UploadParameters } from "src/app/models/upload";
import { SigncoFormGroup, SigncoFormControl } from "src/app/models/form";
import { ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { TubeOffsetDialogComponent } from "../tube-offset-dialog/tube-offset.dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { AnalysisType } from "src/app/models/measuring-point";
import { SelectItem } from "primeng/api";
import { DeviceLinkBikeXPosConfigurationComponent } from "../device-link-bike-xpos-configuration/device-link-bike-xpos-configuration.component";
import { DeviceLinkTubeConfigurationComponent } from "../device-link-tube-configuration/device-link-tube-configuration.component";
import { DeviceLinkTubeXPosConfigurationComponent } from "../device-link-tube-xpos-configuration/device-link-tube-xpos-configuration.component";
import { MultiNoDuplicateValidator } from "src/app/validators/multi-no-duplicate.validator";
import { FormUtils, SubscriptionManager } from "src/app/utilities";
import { zip } from "rxjs";

@Component({
    selector: "app-device-link-detail",
    templateUrl: "./device-link-detail.component.html"
})
export class DeviceLinkDetailComponent implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(TubeOffsetDialogComponent, { static: false }) tubeOffsetDialog: TubeOffsetDialogComponent;

    @ViewChild(DeviceLinkBikeXPosConfigurationComponent) bikeXPosConfigurationComponent: DeviceLinkBikeXPosConfigurationComponent;
    @ViewChild(DeviceLinkTubeConfigurationComponent) tubeConfigurationComponent: DeviceLinkTubeConfigurationComponent;
    @ViewChild(DeviceLinkTubeXPosConfigurationComponent) tubeXPosConfigurationComponent: DeviceLinkTubeXPosConfigurationComponent;

    @Input() parentFormGroup: SigncoFormGroup;
    @Input() analyzerConfiguration?: AnalyzerConfiguration;
    @Input() measuringPointId: number;
    @Input() deviceId: number;
    @Input() createNew = false;
    @Input() enabled = true;

    @Output() tubesChanged = new EventEmitter<string[]>();

    analysisTypeControl: SigncoFormControl;
    initialized = false;
    private initializing = false;

    readonly maxTubes = 16;
    tubeOptions: SelectItem[];
    tubeOptionsWithEmpty: SelectItem[];

    analysisTypes = new Array<string>();

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly primeComponentService: PrimeComponentService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly cd: ChangeDetectorRef) {
        this.tubeOptions = [];
        for (let i = 1; i <= this.maxTubes; i++) {
            this.tubeOptions.push({ value: i.toString(), label: i.toString() });
        }

        this.tubeOptionsWithEmpty = this.tubeOptions.clone();
        this.tubeOptionsWithEmpty.push(this.primeComponentService.createEmptyOption("uploadDetailsDialog.emptyTubeOption"));

        const includeEmptyEnumOptions = new ViewModelEnumOptions();
        includeEmptyEnumOptions.includeEmpty = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        const enabledChange = changes["enabled"];
        if (!(enabledChange && this.initialized)) {
            this.initialize();
        }

        const measuringPointIdChange = changes["measuringPointId"];
        const analyzerConfigurationChange = changes["analyzerConfiguration"];
        if (measuringPointIdChange || analyzerConfigurationChange) {
            this.initializeForms();
        }

        const deviceIdChange = changes["deviceId"];
        if (deviceIdChange) {
            this.initializeForms();
        }

        const parentFormGroupChange = changes["parentFormGroup"];
        if (parentFormGroupChange) {
            this.initialize();
        }
    }

    ngAfterViewInit(): void {
        const subscription =
            zip(this.bikeXPosConfigurationComponent.configure$, this.tubeConfigurationComponent.configure$, this.tubeXPosConfigurationComponent.configure$)
                .subscribe((values) => {
                    this.configureMultiNoDuplicateValidators();
                });

        this.subscriptionManager.add("childComponentsLoaded", subscription);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    private initialize() {
        this.createFormsAndControls();

        if (!this.parentFormGroup) {
            this.initialized = false;
            return;
        }

        this.addControlsToParentFormGroup();
        this.initializeForms();
    }

    initializeForms() {
        if (this.initializing) return;

        this.initializing = true;

        const patchForm = (analyzerConfiguration: AnalyzerConfiguration) => {
            this.analyzerConfiguration = (analyzerConfiguration as AnalyzerConfiguration);
            this.analysisTypes = [analyzerConfiguration.type];
            this.setAnalysisType(analyzerConfiguration.type);

            this.initialized = true;
            this.initializing = false;
        };

        if (this.createNew) {
            if (!this.measuringPointId) {
                this.initialized = false;
                this.initializing = false;
                return;
            }

            const onDefaultReceived = (uploadParameters: UploadParameters) => {
                patchForm(uploadParameters.analyzerConfiguration);
            };

            // We get the default values from the server
            this.measuringPointApi.getDefaultUploadParameters$(this.measuringPointId).subscribe(onDefaultReceived);
        } else {
            patchForm(this.analyzerConfiguration);
        }
    }

    setAnalysisType(analysisType: AnalysisType) {
        this.analysisTypeControl.patchValue(analysisType);
    }

    private addControlsToParentFormGroup() {
        this.parentFormGroup.removeControl("type");
        this.parentFormGroup.addControl("type", this.analysisTypeControl);
    }

    async createFormsAndControls() {
        if (this.analysisTypeControl) return;

        this.analysisTypeControl = this.formBuilder.control("", Validators.required) as SigncoFormControl;
    }

    showOffsetPopup(tubeId: string) {
        let tubeOffsetControl = this.parentFormGroup.get("tubeConfiguration").get(`tube${tubeId}OffsetInMilliseconds`);
        if (!tubeOffsetControl) {
            tubeOffsetControl = this.parentFormGroup.get("bikeXPosConfiguration").get(`tube${tubeId}OffsetInMilliseconds`);
        }

        this.tubeOffsetDialog.open(tubeOffsetControl.value, (offset) => {
            tubeOffsetControl.setValue(offset);
        });
    }

    configureMultiNoDuplicateValidators() {
        const tubeControls = [
            this.parentFormGroup.get("tubeConfiguration")?.get("tube1"),
            this.parentFormGroup.get("tubeConfiguration")?.get("tube2"),
            this.parentFormGroup.get("tubeXPosConfiguration")?.get("tube3"),
            this.parentFormGroup.get("tubeXPosConfiguration")?.get("tube4"),
            this.parentFormGroup.get("bikeXPosConfiguration")?.get("tube3")
        ].filter(x => !!x);
        if (!tubeControls || tubeControls.length === 0) return;

        const tubes = tubeControls.filter(x => x.enabled).map(x => x.value as string);
        this.tubesChanged.next(tubes);

        const multiNoDuplicateValidator = MultiNoDuplicateValidator.create(tubeControls);
        for (const tubeControl of tubeControls) {
            FormUtils.addValidator(tubeControl, multiNoDuplicateValidator);

            tubeControl.valueChanges.subscribe(() => {
                const tubes = tubeControls.filter(x => x.enabled).map(x => x.value as string);
                this.tubesChanged.next(tubes);
            });
        }
    }
}
