import { Component, Input, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { IParkingBan } from "src/app/models/parking-ban";
import { ManageParkingBanExceptionDialogComponent } from "../manage-parking-ban-exception-dialog/manage-parking-ban-exception-dialog.component";
import { ParkingBanExceptionsComponent } from "../parking-ban-exceptions/parking-ban-exceptions.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-parking-ban-exceptions-container",
    templateUrl: "./parking-ban-exceptions-container.component.html"
})
export class ParkingBanExceptionsContainerComponent implements OnDestroy {

    @Input() parkingBan: IParkingBan;

    @ViewChild(ManageParkingBanExceptionDialogComponent, { static: true }) manageParkingBanExcDialog: ManageParkingBanExceptionDialogComponent;
    @ViewChild(ParkingBanExceptionsComponent, { static: false }) parkingBanExceptionsComponent: ParkingBanExceptionsComponent;

    canCreateParkingBanException = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly globalEventsService: GlobalEventsService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateParkingBanException = rights?.hasBackendRight(BackendRights.EditParkingBanException);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    create() {
        if (!this.parkingBan) return;

        const callback = () => this.parkingBanExceptionsComponent.reload();
        this.manageParkingBanExcDialog.create(this.parkingBan, callback);
    }

    setParkingBan(parkingBan: IParkingBan) {
        this.parkingBan = parkingBan;
    }
}
