import { Component, EventEmitter, inject, Output, ViewChild } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { IProjectSummary } from "src/app/models/web";
import { ProjectWebApi } from "src/app/resource/web";
import { ProjectsFilterComponent } from "./projects-filter/projects-filter.component";
import { OrganizationApi } from "src/app/resource/organization.api";
import { IOrganization, IUser } from "src/app/models/user";
import { firstValueFrom } from "rxjs";
import { UserApi } from "src/app/resource/user.api";
import { FilterService } from "src/app/modules/locations/services/filter.service";

export interface ProjectFilterOutput {
    data: IProjectSummary | IOrganization | IUser;
    key: "my-projects" | "project" | "organization";
}

@Component({
    selector: "app-projects-filter-dialog",
    templateUrl: "./projects-filter-dialog.component.html"
})
export class ProjectsFilterDialogComponent extends DialogComponentBase {

    @Output() onSaved: EventEmitter<ProjectFilterOutput[]> = new EventEmitter<ProjectFilterOutput[]>();
    @Output() onClosed: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild(ProjectsFilterComponent, { static: true }) private projectFilter: ProjectsFilterComponent;

    private readonly filterService = inject(FilterService);
    private readonly projectWebApi = inject(ProjectWebApi);
    private readonly organizationApi = inject(OrganizationApi);
    private readonly userApi = inject(UserApi);
    protected currentUser: IUser;

    constructor() {
        super();
    }

    async openFilterDialog() {
        this.currentUser = await firstValueFrom(this.userApi.getSelf$());
        this.visible = true;
        this.projectFilter.refreshProjectsTree();
    }

    async filterOnMyProjects() {
        this.onSaved.emit([{ key: "my-projects", data: this.currentUser }]);
        this.close();
    }

    async saveSelection() {
        const data: ProjectFilterOutput[] = [];
        for (const selected of this.projectFilter.selected) {
            if (selected.type === "project") {
                data.push({ key: "project", data: await this.projectWebApi.get(selected.id) });
            } else {
                data.push({key: "organization", data: await firstValueFrom(this.organizationApi.get$(selected.id))});
            }
        }
        this.onSaved.emit(data);
        this.close();
    }

    protected onClose(): void {
        this.projectFilter.searchText = "";
        this.projectFilter.refreshProjectsTree();
        this.onClosed.emit();
    }
}
