import { LazyTableComponent, TableColumn, FilterType, ColumnType, ColumnVisibility, TableService } from "../table/table.component";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TextDialogComponent, TextDisplayType } from "../text-dialog/text.dialog";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IReportHistory, ReportStatus } from "src/app/models/reports";
import { ServiceRequestOptions } from "src/app/models/search";
import { TranslateService } from "@ngx-translate/core";
import { DownloadedFile } from "src/app/services/download-file.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ReportApi } from "src/app/resource/reports.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html"
})
export class ReportsComponent extends LazyTableComponent<IReportHistory> {
    @ViewChild(TextDialogComponent, { static: true }) textDialog: TextDialogComponent;

    @Input() downloadCommand = true;
    @Input() organizationNavigation = true;

    showParametersCommand: boolean;

    reportTypes: ViewModelEnum[];

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        readonly translateService: TranslateService,
        private readonly reportApi: ReportApi,
        private readonly domainDataService: DomainDataService) {

        super("reports.component", elementRef, reportApi, tableService);

        const authorizationInfo = this.globalEventsService.getAuthorizationInfo();
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";

        const reportTypeColumn = new TableColumn("typeId", "general.type", { filterType: FilterType.Dropdown });
        this.addColumn(reportTypeColumn);

        this.domainDataService.get(DomainData.ReportTypes).then(reportTypeViewModels => {
            this.reportTypes = reportTypeViewModels;
            reportTypeColumn.filterOptions = reportTypeViewModels;
        });

        const processStatusColumn = new TableColumn("statusId", "general.status", { filterType: FilterType.Dropdown, type: ColumnType.Icon });
        this.addColumn(processStatusColumn);

        this.domainDataService.get(DomainData.ReportStatus).then(reportStatuses => {
            processStatusColumn.filterOptions = reportStatuses;
        });

        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideCompact, width: 220 }));
        this.addColumn(new TableColumn("duration", "reports.duration", { resizable: false, width: 100 }));
        this.addColumn(new TableColumn("fileSize", "general.fileSize", { resizable: false, width: 100 }));

        const organizationColumn = new TableColumn("organizationId", "general.organization", { filterType: FilterType.Dropdown, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true }); this.addColumn(organizationColumn);

        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.registerCommand({
            text: "general.download",
            icon: "download",
            click: (report) => this.download(report),
            validFunc: () => this.downloadCommand,
            rowValidFunc: (report) => report.statusId === ReportStatus.Complete
        });

        this.registerCommand({
            text: "general.json",
            icon: "info",
            click: (report) => this.showParameters(report),
            validFunc: () => authorizationInfo?.isDomainAdministrator
        });
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("ReportHistory", "Organization");

        return options;
    }

    getLabel(reportHistory: IReportHistory): string {
        if (!this.reportTypes) return "";

        const reportType = this.reportTypes.find(x => x.value === reportHistory.parameters.reportTypeId);
        if (!reportType) return "";

        return reportType.label;
    }

    getReportStatusColor(reportHistory: IReportHistory) {
        if (reportHistory.statusId === ReportStatus.Failed || reportHistory.statusId === ReportStatus.Canceled) return "red";
        if (reportHistory.statusId === ReportStatus.Complete) return "green";
        return "inherit";
    }

    getReportStatusIcon(reportHistory: IReportHistory) {
        if (reportHistory.statusId === ReportStatus.Failed) return "error";
        if (reportHistory.statusId === ReportStatus.Complete) return reportHistory.favorite ? "envelop" : "checkmark";
        if (reportHistory.statusId === ReportStatus.Canceled) return "delete";
        return null;
    }

    showParameters(reportHistory: IReportHistory) {
        this.textDialog.open("general.json", reportHistory, TextDisplayType.Json, null);
    }

    download(reportHistory: IReportHistory) {
        if (reportHistory.statusId !== ReportStatus.Complete) return;

        const onDownload = (downloadedFile: DownloadedFile) => {
            downloadedFile.save();
        };

        const onError = () => { };

        this.reportApi.download$(reportHistory.id).then(onDownload, onError);
    }
}