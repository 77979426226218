import { Component, Input, ElementRef } from "@angular/core";
import { AttachmentContext } from "src/app/models/attachment";
import { ITask } from "src/app/models/task";

@Component({
    selector: "app-task-attachments",
    templateUrl: "./task-attachments.component.html"
})
export class TaskAttachmentsComponent {

    @Input() task: ITask;

    attachmentDestination: AttachmentContext;

    constructor(
        elementRef: ElementRef) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    setTask(task: ITask) {
        this.task = task;
        this.attachmentDestination = this.task ? new AttachmentContext("task", this.task.id) : undefined;
    }
}
