import { Component, ElementRef } from "@angular/core";

@Component({
    selector: "app-report-history",
    templateUrl: "./report-history.component.html"
})
export class ReportHistoryComponent {
    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }
}