<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{height:'90%'}">
    <p-header>{{"projects.title" | translate}}</p-header>

    <app-projects-filter></app-projects-filter>

    <p-footer>
        <button class="m-btn"
                id="buttonFilterOnMyProjects"
                pTooltip="{{'projects.filterOnMyProjectsTooltip' | translate}}"
                (click)="filterOnMyProjects()">
            {{'projects.filterOnMyProjects' | translate}}
        </button>
        <button (click)="saveSelection()"
                id="buttonOk"
                class="m-btn m-btn-primary">
            {{"form.ok" | translate}}
        </button>
    </p-footer>
</p-dialog>
