import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { RulesComponent } from "../rules/rules.component";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";
import { SubscriptionManager } from "src/app/utilities";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";

@Component({
    selector: "app-admin-rules",
    templateUrl: "./admin-rules.component.html"
})
export class AdminRulesComponent implements OnDestroy {
    @ViewChild(RulesComponent, { static: false }) rulesComponent: RulesComponent;

    isUpdatingAlarms: boolean;
    rights: Rights;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        private readonly toastService: ToastService,
        private readonly deviceApi: DeviceApi) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);


    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
    updateAlarms() {
        this.isUpdatingAlarms = true;

        const onSuccess = () => {
            this.isUpdatingAlarms = false;
            this.toastService.success();
        };

        const onError = () => {
            this.isUpdatingAlarms = false;
        };

        this.deviceApi.updateAlarms$().subscribe(onSuccess, onError);
    }

    create() {
        this.rulesComponent.create();
    }
}