import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, Type } from "@angular/core";
import { ITask } from "src/app/models/task";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { MapDataService } from "src/app/services/map-data.service";
import { TaskDetailComponent } from "../task-detail/task-detail.component";
import { TaskAttachmentsComponent } from "../task-attachments/task-attachments.component";
import { TasksHistoryComponent } from "../task-history/tasks-history.component";
import { BackendRights } from "src/app/models/backend-rights";

type TaskDetailContainerComponents =
    TaskDetailComponent |
    TaskAttachmentsComponent |
    TasksHistoryComponent;

@Component({
    selector: "app-task-detail-container",
    templateUrl: "./task-detail-container.component.html"
})
export class TaskDetailContainerComponent extends TabBase<TaskDetailContainerComponents> implements OnChanges {
    @Input() task: ITask;

    @Output() save = new EventEmitter<ITask>();

    loading: boolean;

    constructor(
        cd: ChangeDetectorRef,
        elementRef: ElementRef,
        tabService: TabService) {
        super(tabService, cd);

        this.addTab({ component: TaskDetailComponent, url: "details", translatePath: "taskDetailTab.title", icon: "info" });
        this.addTab({ component: TaskAttachmentsComponent, url: "attachments", translatePath: "attachments.title", icon: "attachment" });
        this.addTab({ component: TasksHistoryComponent, url: "history", translatePath: "taskStatusHistory.title", icon: "modifications-history" });

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.filterTabs();
        this.updateActiveComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisabled();
        this.filterTabs();
        this.updateActiveComponent();

        const taskChange = changes["task"];
        if (taskChange) {
            this.onSelectedTabChange();
            this.updateTitle();
        }
    }

    protected async onSelectedTabChange() {
        if (!this.activeComponent) return;

        this.subscriptionManager.remove("save");
        await this.activeComponent.setTask(this.task);
        this.subscribeToTaskDetailChanges();
        this.updateDisabled();
    }

    private subscribeToTaskDetailChanges() {
        if (this.activeComponent instanceof TaskDetailComponent) {
            const saveSubscription = this.activeComponent.save.subscribe((task: ITask) => {
                this.task = task;
                this.filterTabs();
                this.updateTitle();
                this.save.emit(task);
            });

            this.subscriptionManager.add("save", saveSubscription);
        }
    }

    private updateDisabled() {
        const disabledExpression = (type: Type<TaskDetailContainerComponents>) => {
            if (type instanceof TaskDetailComponent) return false;

            // Can't navigate to other tabs when creating a new assignment
            return !this.task || !this.task.id;
        };

        this.setTabsDisabledState(disabledExpression);
    }

    filterTabs() {
        if (!this.task) return;

        const tabsToFilter = new Array<Type<TaskDetailContainerComponents>>();

        const rights = this.globalEventsService.getCurrentRights();
        if (!rights.hasBackendRight(BackendRights.EditTask)) {
            tabsToFilter.push(TasksHistoryComponent);
        }

        this.hideTabs(tabsToFilter);
    }
}
