import { IDeviceCommand, DeviceCommandCreator, DeviceCommandStatus } from "../models/device-command";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DeviceCommandApi extends ApiService<IDeviceCommand, DeviceCommandCreator, DeviceCommandCreator> {

    getRoute(): string {
        return "Devices/{deviceId}/Commands";
    }

    setStatus$(deviceCommand: IDeviceCommand, status: DeviceCommandStatus): Observable<IDeviceCommand> {
        const url = `${super.getUrl()}/${deviceCommand.id}`;
        return this.http.post<IDeviceCommand>(url, status);
    }
}