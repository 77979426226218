/// <reference types="@types/google.maps" />

import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { AdvancedMapComponent } from "./components/advanced-map/advanced-map.component";
import { GroupMarkerComponent } from "./components/group-marker/group-marker.component";
import { MapSelectorComponent } from "./components/map-selector/map-selector.component";
import { MapSearchButtonComponent } from "./components/map-searchbutton/map-searchbutton.component";
import { ManageGroupComponent } from "./components/manage-group/manage-group.component";
import { MapBasicModule } from "../map-basic/map-basic.module";
import { MainScreenMapComponent } from "./components/main-screen-map/main-screen-map.component";
import { MapAdvancedRoutingModule } from "./map-advanced-routing.module";
import { LiveTilesModule } from "../live-tiles/live-tiles.module";
import { LocationSharedModule } from "../location-shared";

@NgModule({
    imports: [
        SharedModule,
        MapBasicModule,
        MapAdvancedRoutingModule,
        LiveTilesModule,
        LocationSharedModule,
    ],
    exports: [
        AdvancedMapComponent,
        GroupMarkerComponent,
        MapSelectorComponent,
        MapSearchButtonComponent,
        ManageGroupComponent,
        MainScreenMapComponent,
    ],
    declarations: [
        AdvancedMapComponent,
        GroupMarkerComponent,
        MapSelectorComponent,
        MapSearchButtonComponent,
        ManageGroupComponent,
        MainScreenMapComponent,
    ]
})
export class MapAdvancedModule { }
