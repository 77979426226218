import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef } from "@angular/core";
import { DeviceDisplayEventsComponent } from "src/app/modules/shared/components/device-display-events/device-display-events.component";
import { DeviceDisplayComponent } from "../device-display/device-display.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { IDevice } from "src/app/models/device";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

type DeviceDisplayComponents =
    DeviceDisplayComponent |
    DeviceDisplayEventsComponent;

@Component({
    selector: "app-device-display-container",
    templateUrl: "./device-display-container.component.html"
})
export class DeviceDisplayContainerComponent extends TabBase<DeviceDisplayComponents> {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    ngClass: string;

    constructor(
        elementRef: ElementRef,
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");


        const rights = this.globalEventsService.getCurrentRights();

        this.addTab({ component: DeviceDisplayComponent, url: "configuration", translatePath: "deviceDisplay.title", icon: "hardware" });
        if (rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)) {
            this.addTab({ component: DeviceDisplayEventsComponent, url: "events", translatePath: "scenarios.title", icon: "calendar" });
        }

        this.filterTabs();
    }

    setDevice(device: IDevice) {
        this.device = device;
    }

    protected onSelectedTabChange() {
        this.activeComponent.setDevice(this.device);

        if (this.activeComponent instanceof DeviceDisplayEventsComponent) {
            this.activeComponent.shortcutToManagementScreen = true;
            this.activeComponent.createCommand = false;
            this.activeComponent.editCommand = false;
            this.activeComponent.cloneCommand = false;
            this.activeComponent.deleteCommand = false;
            this.activeComponent.updateCommandColumn();
            this.activeComponent.setRowCount(20);
        }

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";
    }

    updateTitle() {
        if (!this.device || !this.selectedTab) return;
        this.titleService.setTitle(`${this.device.code} - ${this.selectedTab.label}`);
    }
}