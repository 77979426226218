import { NgModule } from "@angular/core";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { SharedModule } from "../shared/shared.module";
import { AttachmentDialogComponent } from "./components/attachment-dialog/attachment.dialog";
import { AttachmentsComponent } from "./components/attachments/attachments.component";
import { LocationSharedModule } from "../location-shared";

@NgModule({
    imports: [
        SharedModule,
        MapAdvancedModule,
        LocationSharedModule,
    ],
    declarations: [
        AttachmentsComponent,
        AttachmentDialogComponent,
    ],
    exports: [
        AttachmentsComponent,
        AttachmentDialogComponent,
    ],
})
export class AttachmentsModule { }
