<p-table id="uploads_table"
         [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [totalRecords]="total"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [lazy]="isLazy"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         (onLazyLoad)="loadTableRows($event)"
         [stateKey]="stateKey"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-upload>
        <tr [pSelectableRow]="upload">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngClass]="getCellClass(upload, column)"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'statusId'">
                    <div class="flex justify-center"
                         style="width: 100%;">
                        <app-svg-icon *ngIf="getUploadStatusIcon(upload)"
                                      [icon]="getUploadStatusIcon(upload)"
                                      [fill]="getUploadStatusColor(upload)">
                        </app-svg-icon>

                        <app-loading-spinner *ngIf="!getUploadStatusIcon(upload)">
                        </app-loading-spinner>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'createDate'">
                    {{ upload.createDate | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'creator'">
                    <app-navigator [user]="upload.creator"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint'">
                    <app-navigator [measuringPoint]="upload.measuringPoint">
                    </app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'device'">
                    <app-navigator [device]="upload.device">
                    </app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'firstData'">
                    {{ upload.firstData | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'lastData'">
                    {{ upload.lastData | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'pulseCount'">
                    {{ upload.pulseCount | thousandSeperator }}
                </ng-container>

                <ng-container *ngSwitchCase="'vehicleCount'">
                    {{ upload.vehicleCount | thousandSeperator }}
                </ng-container>

                <ng-container *ngSwitchCase="'pulseConversionRate'">
                    {{ upload.pulseConversionRate | percent }}
                </ng-container>

                <ng-container *ngSwitchCase="'tube1PulseRatio'">
                    {{ upload.tube1PulseRatio | percent }} / {{ (1 - upload.tube1PulseRatio) | percent }}
                </ng-container>

                <ng-container *ngSwitchCase="'skippedPulseRatio'">
                    {{upload.skippedPulseRatio | percent}}
                </ng-container>

                <ng-container *ngSwitchCase="'firstVehicle'">
                    {{ upload.firstVehicle | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'lastVehicle'">
                    {{ upload.lastVehicle | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'filename'">
                    {{upload.filename}}
                </ng-container>

                <ng-container *ngSwitchCase="'fileSize'">
                    {{upload.fileSize | byte}}
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(upload)"
                                             *ngIf="command.rowValidFunc(upload)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{upload[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>

<app-upload-details-dialog></app-upload-details-dialog>
<app-measuring-point-data-delete-dialog></app-measuring-point-data-delete-dialog>