import { Component, ElementRef, Input } from "@angular/core";
import { IOrganizationsFilter } from "src/app/models/report-type";
import { IOrganization } from "src/app/models/user";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { CustomTableComponent, FilterType, TableColumn, TableService } from "../table/table.component";

@Component({
    selector: "app-select-organizations",
    templateUrl: "./select-organizations.component.html"
})
export class SelectOrganizationsComponent extends CustomTableComponent<IOrganization> {
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService) {

        super("select-organizations.component", elementRef, tableService);


        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = false;
        this.stretchHeight = true;

        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));

        this.selectionService.subscribeToOrganizations(this.subscriptionManager, x => this.addData(x), x => this.removeData(x));

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (organization) => this.removeData([organization]),
            validFunc: () => this.deleteCommand
        });
    }


    onSetData() {
        this.selectionService.setOrganizations(this.data);
    }

    //#region Reporting
    async setFromFilter(organizationFilters: IOrganizationsFilter) {
        const organizations = new Array<IOrganization>();

        for (const organizationId of organizationFilters.ids) {
            const organization = await this.services.mapDataService.getOrganization$(x => x.id === organizationId);
            if (!organization) continue;

            organizations.push(organization);
        }

        this.setData(organizations);
    }
    //#endregion Reporting
}
