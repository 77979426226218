import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, ViewChild } from "@angular/core";
import { AttachmentCreator, IAttachment } from "src/app/models/attachment";
import { FilterOperator, SearchParameters } from "src/app/models/search";
import { AttachmentTypeApi } from "src/app/resource/attachment-type.api";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { DownloadedFile, DownloadFileService } from "src/app/services/download-file.service";
import { FileUtils } from "src/app/utilities";
import { ImageDialogComponent } from "src/app/modules/shared/components/image-dialog/image-dialog.component";
import { ModalService } from "src/app/services/modal.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-photo-input",
    templateUrl: "./photo-input.component.html"
})
export class PhotoInputComponent implements OnInit, OnChanges {

    image: File;
    imagePreview: string;

    @ViewChild(ImageDialogComponent, { static: true }) imageDialogComponent: ImageDialogComponent;

    @Input() attachment: IAttachment;
    @Input() submitted: IAttachment;
    @Input() labelColSize = 2;
    @Input() colSize = 10;
    @Input() photoColSize = 17;
    @Input() required = true;
    @Input() popup = false;
    @Input() deleteImageMessageToTranslate: string;

    @Output() photoDeleted = new EventEmitter<void>();

    private attachmentTypePhotoId: number;
    private existingPhoto: DownloadedFile;

    constructor(
        private readonly attachmentApi: AttachmentApi,
        private readonly attachmentTypeApi: AttachmentTypeApi,
        private readonly downloadFileService: DownloadFileService,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService) {
    }

    ngOnInit() {
        const photoSp = new SearchParameters();
        photoSp.filter = [{ field: "code", value: "Foto", operator: FilterOperator.equals }];

        this.attachmentTypeApi.search$(photoSp).subscribe(result => {
            this.attachmentTypePhotoId = result.data && result.data.length > 0 ? result.data[0].id : undefined;
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        const attachmentChange = changes["attachment"];
        if (attachmentChange) {
            this.imagePreview = undefined;
            await this.handleAttachmentChange();
        }
    }

    setPhotoColSize() {
        const styles = {
            "margin-left": this.photoColSize + "%",
        };
        return styles;
    }

    async handleAttachmentChange() {
        let photo;

        if (this.attachment) {
            photo = await this.downloadFileService.downloadBlob(this.attachment.url);
        }

        this.existingPhoto = photo;
        await this.setImageDirectly(photo);
    }

    async setImage(event: { files: FileList }) {
        if (!event || event.files.length < 0) return;
        await this.setImageDirectly(event.files[0]);
    }

    async setImageDirectly(file: File) {
        this.image = file;
        this.imagePreview = this.image ? await FileUtils.toBase64(this.image) : null;
    }

    async uploadPhoto(creator: AttachmentCreator): Promise<IAttachment> {
        if (!this.image) return;

        creator.typeId = this.attachmentTypePhotoId;
        return await this.attachmentApi.replace$(this.attachment, this.existingPhoto, this.imagePreview, creator, this.image);
    }

    setPhotoDeleted() {
        const onDelete = () => {
            this.imagePreview = undefined;
            this.image = undefined;
            this.photoDeleted.emit();
        };

        const modalBody = this.translate.instant(this.deleteImageMessageToTranslate, { fileName: this.image["file"]?.name ?? this.image.name });
        this.modalService.delete(modalBody, onDelete);
    }

    async deletePhoto() {
        if (!this.attachment) return;
        await this.attachmentApi.delete$(this.attachment.id).toPromise();
    }

    onImageClick() {
        this.imageDialogComponent.create(this.image["file"]?.name ?? this.image.name, this.imagePreview);
    }
}
