import { NgModule } from "@angular/core";

import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { SharedModule } from "../shared/shared.module";

import { QrComponent } from "./components/qr/qr.component";
import { QrRoutingModule } from "./qr-routing.module";

@NgModule({
    imports: [
        SharedModule,
        QrRoutingModule,
        MapAdvancedModule
    ],
    declarations: [
        QrComponent
    ]
})
export class QrModule { }