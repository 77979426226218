<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingDatePeriodSet ? "datePeriodSet.edit" : "datePeriodSet.create") | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm"
          autocomplete="off"
          novalidate>

        <div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': dialogForm.get('name').showErrors }">

                <label class="col-4"
                       for="name">
                    {{"general.name" | translate}}
                </label>

                <div class="col-8">
                    <input type="text"
                           id="name"
                           class="p-inputtext"
                           formControlName="name"
                           appAutofocus
                           #nameInput />

                    <app-form-validation [control]="dialogForm.get('name')"
                                         [input]="nameInput">
                    </app-form-validation>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>