import { TableColumn, FilterType, CustomTableComponent, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, OnChanges, SimpleChanges, Input, ElementRef } from "@angular/core";
import { LocationKmlResult } from "src/app/models/kml";

@Component({
    selector: "app-kml-result",
    templateUrl: "./kml-result.component.html"
})
export class KmlResultComponent extends CustomTableComponent<LocationKmlResult> implements OnChanges {
    @Input() kmlResult: LocationKmlResult[];

    constructor(
        elementRef: ElementRef,
        tableService: TableService) {

        super("links.component", elementRef, tableService);

        this.selectionMode = "";

        this.addColumn(new TableColumn("locationCode", "general.location", { filterType: FilterType.Text, sortable: true }));
        // this.addColumn(new TableColumn("measuringPointCode", "general.measuringPoint", { filterType: FilterType.Text, sortable: true }));

        this.addColumn(new TableColumn("coordinate", "general.coordinates"));
        this.addColumn(new TableColumn("failureMessage", "", { sortable: true }));
    }

    ngOnChanges(changes: SimpleChanges) {
        const kmlResultChange = changes["kmlResult"];
        if (kmlResultChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    canLoad(): boolean {
        return !!this.kmlResult;
    }

    loadTableRows() {
        this.setData(this.kmlResult);
    }
}