<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"vmsImageEditor.uploadTitle" | translate}}
    </p-header>

    <div class="p-16">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': submitted && !image }">

            <label class="col-2">
                {{"general.file" | translate}}
            </label>

            <div class="col-10">
                <label for="fileInput"
                       class="p-inputtext m-input-file m-input-group-w-icon">

                    <span class="label mr-16">
                        <ng-container *ngIf="image">
                            {{image.name}}
                        </ng-container>

                        <ng-container *ngIf="!image">
                            {{"general.selectFile" | translate}}
                        </ng-container>
                    </span>

                    <app-svg-icon icon="upload"
                                  class="ml-auto">
                    </app-svg-icon>

                    <input type="file"
                           id="fileInput"
                           #file
                           (change)="setImage(file)" />
                </label>

                <div *ngIf="submitted && !image"
                     class="form-validation-error">
                    {{"validation.required" | translate}}
                </div>

                <div *ngIf="imagePreview"
                     class="m-preview m-preview-sm mt-8">
                    <img [attr.src]="imagePreview | safeHtml" />
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <button type="button"
                class="m-btn m-btn-link"
                (click)="close()">
            {{"form.cancel" | translate}}
        </button>

        <button tabindex="99"
                [disabled]="!image"
                (click)="submit()"
                class="m-btn m-btn-primary"
                type="submit">
            {{ "form.upload" | translate}}
        </button>
    </p-footer>
</p-dialog>