import { Component, inject, Input } from "@angular/core";
import { ILocationStatusSummary, IMeasuringPointStatusSummary } from "src/app/models/web";
import { MapIconService } from "src/app/services/map-icon-service";

@Component({
    selector: "app-vehicle-tile",
    templateUrl: "./vehicle-tile.component.html",
    styleUrls: ["./vehicle-tile.component.scss", "../../location-popup.component.scss"],
})
export class VehicleTileComponent {
    @Input() locationStatusSummary: ILocationStatusSummary;
    @Input() data: IMeasuringPointStatusSummary;

    private readonly mapIconService = inject(MapIconService);

    protected getIconUrl(): string {

        if (this.data.mapIconStateId) {
            const icon = this.mapIconService.getIconState(this.data.mapIconStateId);
            return icon.defaultIcon.url;
        }
    }

}