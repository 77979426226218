import {
    ViewChild,
    ElementRef,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    Input,
    ChangeDetectorRef,
    Directive,
} from "@angular/core";
import {
    AdvancedMapComponent,
    MapEvent,
} from "src/app/modules/map-advanced/components/advanced-map/advanced-map.component";
import { MeasuringPointsComponent } from "../measuring-points/measuring-points.component";
import { Router } from "@angular/router";
import { AssignmentsComponent } from "src/app/modules/assignments/components/assignments/assignments.component";
import { LocalStorageService } from "src/app/services/storage.service";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { MapUtils, SubscriptionManager } from "src/app/utilities";
import { TableComponentBase } from "../table/table.component";
import { NavigationService } from "src/app/services/navigation.service";
import { DevicesComponent } from "../devices/devices.component";
import { TranslateService } from "@ngx-translate/core";
import { GroupsComponent } from "../groups/groups.component";
import { SplitComponent } from "angular-split";
import { MapDataService } from "src/app/services/map-data.service";
import { IDeviceSummary } from "src/app/models/device";
import { IOrganization } from "src/app/models/user";
import { ResizeService } from "src/app/services/resize.service";
import { Subscription } from "rxjs";
import { IAssignment } from "src/app/models/assignment";
import { Constants } from "src/app/constants/constants";
import { MapDetail } from "src/app/services/map-detail.service";
import { GroupApi } from "src/app/resource/group.api";
import { MarkerContext } from "src/app/modules/map-advanced/classes/marker-context";
import { MapSearchButtonComponent } from "src/app/modules/map-advanced/components/map-searchbutton/map-searchbutton.component";
import { SearchParameters } from "src/app/models/search";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IMeasuringPointSummary } from "src/app/models/web";
import { ILocationSummary, ILocationWithAssignmentsSummary } from "src/app/models/web";
import { IGroup, IGroupMeasuringPoint } from "src/app/models/group";
import { OrganizationApi } from "src/app/resource/organization.api";
import { IGroupSummary } from "src/app/models/web";

@Directive()
export abstract class SplitMapComponentBase implements OnInit, OnDestroy {
    //#region Properties
    gmap: AdvancedMapComponent;

    protected rowCount = 30;
    protected enableTableCommands = true;
    protected clearSelectionOnDetailChange = true;

    @Input() readonly: boolean = null;
    @Input() zoomOnSelect = false; // TODO can we remove this? The users don't like if the map zooms automatically
    @Input() zoomOnSelectValue = 15;
    @Input() disableNavigationOnSelect = false;
    @Input() showSelectedIcons = false;

    @Input() useMeasuringPointsWithDirection = false;
    @Input() ownerId: string;

    @Output() detailComponentSet = new EventEmitter<void>();
    @Output() mapReady = new EventEmitter<void>();
    @Output() filterSynchronizationChanged = new EventEmitter<void>();

    // Split
    splitComponent: SplitComponent;
    @ViewChild(SplitComponent, { static: false }) set setSplitComponent(
        splitComponent: SplitComponent
    ) {
        this.splitComponent = splitComponent;
    }

    searchbutton: MapSearchButtonComponent;
    @ViewChild(MapSearchButtonComponent, { static: false }) set setMapSearchbuttonComponent(
        mapSearchbuttonComponent: MapSearchButtonComponent
    ) {
        this.searchbutton = mapSearchbuttonComponent;
    }

    mapSplitSize = 60;
    gridSplitSize = 100 - this.mapSplitSize;
    rememberSplitState = true;

    measuringPointsComponent: MeasuringPointsComponent;
    devicesComponent: DevicesComponent;
    assignmentsComponent: AssignmentsComponent;
    groupsComponent: GroupsComponent;

    // Config
    loaded: boolean; // set after map is fully loaded and functional

    rememberTableState = true;
    restoreServiceInitialSelection = false;

    protected searchParameters: SearchParameters;
    protected measuringPointSearchParameters: SearchParameters;

    // Utility
    protected readonly subscriptionManager = new SubscriptionManager();
    private detailComponentSubscriptions = new Array<Subscription>();
    private queriedGroupCache = new Array<IGroup>();
    protected readonly mapDataServiceKey: string;

    //#endregion Properties

    //#region Lifecycle

    constructor(
        readonly cd: ChangeDetectorRef,
        readonly elementRef: ElementRef<HTMLElement>,
        readonly globalEventsService: GlobalEventsService,
        readonly authenticationService: AuthenticationService,
        protected readonly router: Router,
        protected readonly navigationService: NavigationService,
        protected readonly resizeService: ResizeService,
        protected readonly localStorageService: LocalStorageService,
        protected readonly selectionService: MapSelectionService,
        protected readonly groupApi: GroupApi,
        protected readonly mapDataService: MapDataService,
        protected readonly translateService: TranslateService,
        protected readonly organizationApi: OrganizationApi
    ) {
        this.elementRef.nativeElement.classList.add("map-container");

        this.mapDataServiceKey = this.mapDataService.createKey();
        this.mapDataService.subscribeToEditGroupUpdate(
            this.mapDataServiceKey,
            () => {
                if (
                    !this.mapDataService.editGroup &&
                    this.searchbutton.manageGroupComponent &&
                    this.searchbutton.manageGroupComponent.creatingOrEditing
                ) {
                    this.searchbutton.manageGroupComponent.cancel();
                }
            }
        );

        this.registerSelectionService();
        this.loadSplitState();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        try {
            this.subscriptionManager.clear();
        } catch (e) {
        }
    }

    private pollIsLoaded() {
        if (this.loaded) return;

        // Poll until map is fully functional
        // Then we set loaded to true
        new Promise<void>(async (resolve) => {
            while (!this.gmap || !this.gmap.getPixelFromLatLng(new google.maps.LatLng(1, 1))) {
                await new Promise((res) => setTimeout(res, 100));
            }
            resolve();
        }).then(() => {
            this.loaded = true;
            this.mapReady.emit();
            this.onMapReady(); // this is used to notifiy derived classes, it's simpler than having the use the subscription
        });
    }

    protected onMapReady(): void { }

    private registerSelectionService() {
        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, x => this.handleContextsSelect(x), x => this.handleContextsDeselect(x));
        this.selectionService.subscribeToDevices(this.subscriptionManager, x => this.handleContextsSelect(x), x => this.handleContextsDeselect(x));
        this.selectionService.subscribeToOrganizations(this.subscriptionManager, x => this.handleContextsSelect(x), x => this.handleContextsDeselect(x));
        this.selectionService.subscribeToGroups(this.subscriptionManager, x => this.handleGroupSelect(x), x => this.handleGroupDeselect(x));
        this.selectionService.subscribeToGroupMeasuringPoints(this.subscriptionManager, x => this.handleContextsSelect(x), x => this.handleContextsDeselect(x));
        this.selectionService.subscribeToAssignments(this.subscriptionManager, x => this.handleContextsSelect(x), x => this.handleContextsDeselect(x));
        this.setServiceInitialSelection();
    }

    private setServiceInitialSelection() {
        if (!this.restoreServiceInitialSelection) return;

        if (this.measuringPointsComponent) {
            this.measuringPointsComponent.setSelection(this.selectionService.getSelectedMeasuringPoints());
        }

        if (this.groupsComponent) {
            this.groupsComponent.setSelection(this.selectionService.getSelectedGroups());
        }

        if (this.devicesComponent) {
            this.devicesComponent.setSelection(this.selectionService.getSelectedDevices());
        }

        if (this.assignmentsComponent) {
            this.assignmentsComponent.setSelection(this.selectionService.getSelectedAssignments());
        }
    }

    //#endregion Lifecycle

    //#region Map

    handleMapReady(gmap: AdvancedMapComponent) {
        this.gmap = gmap;

        this.gmap.setMapDetail(this.getCurrentMapDetail());
        this.gmap.showStreetViewControl = true;
        this.pollIsLoaded();
        this.filterMap(this.getDetailComponent());
    }

    handleMapClick(event: google.maps.MapMouseEvent) {
        // Close Context menu on map click
        this.clearSelected();
    }

    handleMapRightClick(event: google.maps.MapMouseEvent) {
        this.clearSelected();
        this.createMapContextMenu(event);
    }

    handleMapDragStart(event: google.maps.MapMouseEvent) {
    }

    clearSelected() {
        this.gmap.clearSelected();
    }

    async handleLocationClick(clickEvent: MapEvent) {
        const locationMarker = clickEvent.locationMarker;
        this.selectionService.setSelected(locationMarker);
    }

    handleLocationRightClick(clickEvent: MapEvent) {
        this.clearSelected();
        this.createMarkerContextMenu(clickEvent);
    }

    private isSelected(context: MarkerContext): boolean {
        const currentMapDetail = this.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return this.selectionService.containsMeasuringPointLocation(context.id);
        }

        if (currentMapDetail === MapDetail.Devices) {
            return this.selectionService.containsDeviceLocation(context.id);
        }

        if (currentMapDetail === MapDetail.Organizations && context.organization) {
            return this.selectionService.containsOrganization(context.organization.id);
        }

        if (currentMapDetail === MapDetail.Assignments) {
            return this.selectionService.containsAssignment(context.id);
        }

        return false;
    }

    handleDataLoadedOnMap() {
        this.updateMapSelection();
    }

    private updateMapSelection() {
        if (!this.gmap || !this.gmap.locationMarkers) return;

        for (const locationMarker of this.gmap.locationMarkers) {
            const isSelected = this.isSelected(locationMarker.context);
            locationMarker.setSelected(isSelected);
        }
    }

    //#endregion Map

    //#region Detail

    protected hideDetailComponents() {
        this.clearSelectedMeasuringPoints();
        this.selectionService.clearSelected();

        for (const detailComponentSubscription of this.detailComponentSubscriptions) {
            detailComponentSubscription?.unsubscribe();
        }

        this.detailComponentSubscriptions.length = 0;

        // Angular first places the new <app-router> component in the tree, THEN deletes the old one
        // This makes it so that, for an instant (not visible), 2 grids are underneath eachother
        // During this time, our PrimeNG tables will try and detect if there's enough vertical space to show content
        // and whether or not it should show a scroll-bar
        // So with vertical space divided in half (because of 2 grid components),
        // it wrongfully (but technically correct) adjusted header positioning for scroll-bar (17px margin)
        // Setting old component to "hidden" eliminates this issue
        if (this.groupsComponent) {
            if (this.clearSelectionOnDetailChange) {
                this.selectionService.clearGroups();
                this.selectionService.clearGroupMeasuringPoints();
            }

            this.groupsComponent.setComponentStyle("hidden");
            this.groupsComponent = null;
        }

        if (this.devicesComponent) {
            if (this.clearSelectionOnDetailChange) {
                this.selectionService.clearDevices();
            }

            this.devicesComponent.setComponentStyle("hidden");
            this.devicesComponent = null;
        }

        if (this.assignmentsComponent) {
            if (this.clearSelectionOnDetailChange) {
                this.selectionService.clearAssignments();
            }
            this.assignmentsComponent.setComponentStyle("hidden");
            this.assignmentsComponent = null;
        }
    }


    protected setMeasuringPointsComponent(
        measuringPointsComponent: MeasuringPointsComponent
    ) {
        if (this.measuringPointsComponent === measuringPointsComponent) return;

        this.hideDetailComponents();

        this.measuringPointsComponent = measuringPointsComponent;

        this.measuringPointsComponent.setComponentStyle("m-layout-area-body");
        this.measuringPointsComponent.setComponentStyle("m-layout-default");
        this.measuringPointsComponent.setRowCount(this.rowCount);
        this.measuringPointsComponent.deleteCommand = this.enableTableCommands;
        this.measuringPointsComponent.locationNavigation = false;
        this.measuringPointsComponent.delayedStart = true;
        this.measuringPointsComponent.updateCommandColumn();

        this.detailComponentSubscriptions.push(
            this.measuringPointsComponent.selected.subscribe(
                (e: IMeasuringPointSummary | IMeasuringPointSummary[]) => {
                    if (this.useMeasuringPointsWithDirection) {
                        if (Array.isArray(e)) {
                            for (const summary of e) {
                                summary.includeForwardDirection = true;
                                summary.includeReverseDirection = false;
                                summary.includeSum = false;
                            }
                        } else {
                            e.includeForwardDirection = true;
                            e.includeReverseDirection = false;
                            e.includeSum = false;
                        }

                        this.selectionService.addMeasuringPoints(e);
                    }

                    this.selectionService.addMeasuringPoints(e);
                }
            )
        );

        this.detailComponentSubscriptions.push(
            this.measuringPointsComponent.deselected.subscribe(
                (e: IMeasuringPointSummary | IMeasuringPointSummary[]) => {
                    this.selectContextIfNothingSelected(e);
                    this.selectionService.removeMeasuringPoints(e);
                }
            )
        );

        this.detailComponentSubscriptions.push(
            this.measuringPointsComponent.startedSearch.subscribe(() =>
                this.filterMap(this.measuringPointsComponent)
            )
        );

        this.detailComponentSubscriptions.push(
            this.measuringPointsComponent.dataSet.subscribe(() => {
                this.updateMapSelection();
            })
        );

        if (this.rememberTableState) {
            this.measuringPointsComponent.setSaveState("measuring-points split-map.component.ts");
        }

        this.measuringPointsComponent.start();

        this.detailComponentSet.emit();

        if (this.gmap) {
            this.gmap.setMapDetail(MapDetail.MeasuringPoints);
        }

        this.setServiceInitialSelection();
    }

    protected setGroupsComponent(groupsComponent: GroupsComponent) {
        if (this.groupsComponent === groupsComponent) return;

        this.hideDetailComponents();

        this.groupsComponent = groupsComponent;
        this.groupsComponent.setComponentStyle("m-layout-area-body");
        this.groupsComponent.setComponentStyle("m-layout-default");
        this.groupsComponent.setRowCount(this.rowCount);
        this.groupsComponent.delayedStart = true;

        if (this.rememberTableState) {
            this.groupsComponent.setSaveState("groups split-map.component.ts");
        }

        this.groupsComponent.sortable = true;
        this.groupsComponent.deleteCommand = this.enableTableCommands;
        this.groupsComponent.updateCommandColumn();

        this.detailComponentSubscriptions.push(this.groupsComponent.selected.subscribe((e: IGroup | IGroup[]) => this.selectionService.addGroups(e)));
        this.detailComponentSubscriptions.push(this.groupsComponent.deselected.subscribe((e: IGroup | IGroup[]) => this.selectionService.removeGroups(e)));
        this.detailComponentSubscriptions.push(this.groupsComponent.startedSearch.subscribe((e: { filteredValue: IGroup[] }) => { this.filterMap(this.groupsComponent); }));
        this.detailComponentSubscriptions.push(this.groupsComponent.dataSet.subscribe(() => { this.updateMapSelection(); }));
        this.detailComponentSubscriptions.push(this.groupsComponent.measuringPointsComponent.selected.subscribe((e: IGroupMeasuringPoint | IGroupMeasuringPoint[]) => {
            const array = e.toList<IGroupMeasuringPoint>();
            this.selectionService.addMeasuringPoints(array.map(x => x.measuringPoint));
        }));
        this.detailComponentSubscriptions.push(this.groupsComponent.measuringPointsComponent.deselected.subscribe((e: IGroupMeasuringPoint | IGroupMeasuringPoint[]) => {
            const array = e.toList<IGroupMeasuringPoint>();
            this.selectionService.removeMeasuringPoints(array.map(x => x.measuringPoint));
        }));

        this.groupsComponent.start();

        this.detailComponentSet.emit();

        if (this.gmap) {
            this.gmap.setMapDetail(MapDetail.MeasuringPointGroups);
        }

        this.setServiceInitialSelection();
    }

    protected setDevicesComponent(devicesComponent: DevicesComponent) {
        if (this.devicesComponent === devicesComponent) return;

        this.hideDetailComponents();

        this.devicesComponent = devicesComponent;
        this.devicesComponent.editCommand = true;
        this.devicesComponent.setComponentStyle("m-layout-area-body");
        this.devicesComponent.setComponentStyle("m-layout-default");
        this.devicesComponent.setRowCount(this.rowCount);
        this.devicesComponent.navigation = false;
        this.devicesComponent.delayedStart = true;

        if (this.rememberTableState) {
            this.devicesComponent.setSaveState("devices split-map.component.ts");
        }

        this.devicesComponent.sortable = true;
        this.devicesComponent.updateCommandColumn();

        this.detailComponentSubscriptions.push(this.devicesComponent.selected.subscribe((e: IDeviceSummary | IDeviceSummary[]) => this.selectionService.addDevices(e)));
        this.detailComponentSubscriptions.push(this.devicesComponent.deselected.subscribe((e: IDeviceSummary | IDeviceSummary[]) => {
            this.selectContextIfNothingSelected(e);
            this.selectionService.removeDevices(e);
        }));
        this.detailComponentSubscriptions.push(this.devicesComponent.startedSearch.subscribe(() => this.filterMap(this.devicesComponent)));
        this.detailComponentSubscriptions.push(this.devicesComponent.dataSet.subscribe(() => { this.updateMapSelection(); }));

        this.devicesComponent.start();

        this.detailComponentSet.emit();

        if (this.gmap) {
            this.gmap.setMapDetail(MapDetail.Devices);
        }

        this.setServiceInitialSelection();
    }

    protected setAssignmentsComponent(assignmentsComponent: AssignmentsComponent) {
        if (this.assignmentsComponent === assignmentsComponent) return;

        this.hideDetailComponents();

        this.assignmentsComponent = assignmentsComponent;
        this.assignmentsComponent.setRowCount(this.rowCount);
        this.assignmentsComponent.delayedStart = true;

        if (this.rememberTableState) {
            this.assignmentsComponent.setSaveState("assignments split-map.component.ts");
        }

        this.assignmentsComponent.sortable = true;
        this.assignmentsComponent.editCommand = this.enableTableCommands;
        this.assignmentsComponent.deleteCommand = this.enableTableCommands;
        this.assignmentsComponent.updateCommandColumn();

        this.detailComponentSubscriptions.push(this.assignmentsComponent.selected.subscribe((e: IAssignment | IAssignment[]) => {
            this.selectionService.clearAssignments();
            this.selectionService.addAssignments(e);
        }));

        this.detailComponentSubscriptions.push(this.assignmentsComponent.deselected.subscribe((e: IAssignment | IAssignment[]) => this.selectionService.removeAssignments(e)));
        this.detailComponentSubscriptions.push(this.assignmentsComponent.startedSearch.subscribe(() => this.filterMap(this.assignmentsComponent)));
        this.detailComponentSubscriptions.push(this.assignmentsComponent.dataSet.subscribe(() => { this.updateMapSelection(); }));

        this.assignmentsComponent.start();

        this.detailComponentSet.emit();

        if (this.gmap) {
            this.gmap.setMapDetail(MapDetail.Assignments);
        }

        this.setServiceInitialSelection();
    }

    protected getDetailComponent(): TableComponentBase<any> {
        const mapDetail = this.getCurrentMapDetail();
        if (mapDetail === MapDetail.MeasuringPoints) return this.measuringPointsComponent;
        if (mapDetail === MapDetail.MeasuringPointGroups) return this.groupsComponent;
        if (mapDetail === MapDetail.Devices) return this.devicesComponent;
        if (mapDetail === MapDetail.Assignments) return this.assignmentsComponent;
        return null;
    }

    //#endregion Detail

    //#region Detail popup

    // Override by maps that don't take in the entire screen
    getPopupTopOffset(): number {
        return this.elementRef.nativeElement.offsetTop;
    }

    getPopupLeftOffset(): number {
        return this.elementRef.nativeElement.offsetLeft;
    }

    private handleContextsSelect(contexts: IMeasuringPointSummary | IDeviceSummary | IOrganization | IGroupMeasuringPoint | IAssignment | IMeasuringPointSummary[] | IDeviceSummary[] | IOrganization[] | IGroupMeasuringPoint[] | IAssignment[]) {
        // We only care about the last selected one
        // We are simply panning to it anyway
        const context = Array.isArray(contexts) ? contexts.takeLastOrDefault() : contexts;

        if (!this.loaded) {
            const mapReadySubscription = this.mapReady.subscribe(() => {
                this.handleContextsSelect(contexts);
            });

            this.subscriptionManager.add(`mapReadySubscription${contexts}`, mapReadySubscription);

            return;
        }

        const currentMapDetail = this.getCurrentMapDetail();
        let updateLocationMarkerIcon = false;

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            this.handleMeasuringPointLocationSelect((context as IMeasuringPointSummary).locationId);
            updateLocationMarkerIcon = true;
        }

        if (currentMapDetail === MapDetail.MeasuringPointGroups && (context as IGroupMeasuringPoint).measuringPoint) {
            this.handleGroupMeasuringPointSelect(context as IGroupMeasuringPoint);
        }

        if (currentMapDetail === MapDetail.Devices) {
            this.handleDeviceLocationSelect(context as IDeviceSummary);
            updateLocationMarkerIcon = true;
        }

        if (currentMapDetail === MapDetail.Organizations) {
            this.handleOrganizationSelect(context as IOrganization);
            updateLocationMarkerIcon = true;
        }

        if (currentMapDetail === MapDetail.Assignments) {
            this.handleAssignmentSelect(context as IAssignment);
            updateLocationMarkerIcon = true;
        }

        if (this.showSelectedIcons && updateLocationMarkerIcon) {
            for (const markerContext of contexts.toList<MarkerContext>()) {
                const locationMarker = this.gmap.getLocationMarker(markerContext);
                if (locationMarker) {
                    locationMarker.setSelected(true);
                }
            }
        }
    }

    private handleContextsDeselect(contexts: IMeasuringPointSummary | IDeviceSummary | IOrganization | IGroupMeasuringPoint | IAssignment | IMeasuringPointSummary[] | IDeviceSummary[] | IOrganization[] | IGroupMeasuringPoint[] | IAssignment[]) {

        const contextsArray = contexts.toList<IMeasuringPointSummary | IDeviceSummary | IOrganization | IGroupMeasuringPoint | IAssignment>();

        const currentMapDetail = this.getCurrentMapDetail();
        let updateLocationMarkerIcon = false;

        if (currentMapDetail === MapDetail.MeasuringPoints && this.measuringPointsComponent) {
            this.measuringPointsComponent.deselectRows(contextsArray as IMeasuringPointSummary[]);
            updateLocationMarkerIcon = true;
        }

        if (currentMapDetail === MapDetail.MeasuringPointGroups && this.groupsComponent && this.groupsComponent.measuringPointsComponent) {
            this.gmap.closeGroupMarkers();
            this.groupsComponent.measuringPointsComponent.clearSelection();
        }

        if (currentMapDetail === MapDetail.Devices && this.devicesComponent) {
            this.devicesComponent.deselectRows(contextsArray as IDeviceSummary[]);
            updateLocationMarkerIcon = true;
        }

        if (currentMapDetail === MapDetail.Assignments && this.assignmentsComponent) {
            this.assignmentsComponent.deselectRows(contextsArray as IAssignment[]);
            this.handleAssignmentDeselect();
        }

        // this.refreshSorting();

        if (this.showSelectedIcons && updateLocationMarkerIcon) {
            for (const markerContext of contexts.toList<MarkerContext>()) {
                const locationMarker = this.gmap.getLocationMarker(markerContext);

                if (locationMarker) {
                    locationMarker.setSelected(false);
                }
            }
        }
    }

    private refreshSorting() {
        const detailComponent = this.getDetailComponent();
        if (detailComponent && detailComponent.selectionBox) {
            // detailComponent.table.sortField = "selectionBox"; // TODO (r) - feasible?
            setTimeout(() => {
                detailComponent.refreshSorting();
            });
        }
    }

    zoomOnContext(context: MarkerContext) {
        this.gmap.basicMap.setZoom(17);
        this.gmap.centerOnContext(context);
        this.selectionService.setSelected(this.gmap.getLocationMarker(context));
    }

    isMeasurementMap() {
        const currentMapDetail = this.getCurrentMapDetail();
        return currentMapDetail === MapDetail.MeasuringPoints;
    }

    toAssignmentDetail(context: MarkerContext) {
        const assignmentLocationSummary = context.locationSummary as ILocationWithAssignmentsSummary;
        if (assignmentLocationSummary.assignmentIds?.length) {
            const assignmentId = assignmentLocationSummary.assignmentIds.takeFirstOrDefault();
            this.navigationService.toAssignmentDetails(assignmentId);
        }
    }

    createOnContext(context: MarkerContext) {
        this.createMeasuringPoint(context);
    }

    editContext(context: MarkerContext) {
        const currentMapDetail = this.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            this.editLocation(context);
        }

        if (currentMapDetail === MapDetail.Organizations) {
            this.editOrganization(context.organization.id);
        }

        if (currentMapDetail === MapDetail.Assignments) {
            this.editLocation(context);
        }
    }
    getViewContextTooltip(): string {
        const currentMapDetail = this.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return "manageLocation.view";
        }

        if (currentMapDetail === MapDetail.MeasuringPointGroups) {
            return "manageGroup.view";
        }

        if (currentMapDetail === MapDetail.Devices) {
            return "manageDevice.view";
        }

        if (currentMapDetail === MapDetail.Organizations) {
            return "manageOrganization.view";
        }

        return "form.view";
    }


    getEditContextTooltip(): string {
        const currentMapDetail = this.getCurrentMapDetail();

        if (currentMapDetail === MapDetail.MeasuringPoints) {
            return "manageLocation.edit";
        }

        if (currentMapDetail === MapDetail.MeasuringPointGroups) {
            return "manageGroup.edit";
        }

        if (currentMapDetail === MapDetail.Devices) {
            return "manageDevice.edit";
        }

        if (currentMapDetail === MapDetail.Organizations) {
            return "manageOrganization.edit";
        }

        return "form.edit";
    }

    //#endregion

    //#region Filter

    filterMap(detail: TableComponentBase<any>) {
        if (!this.gmap || !detail) return;

        this.gmap.setSearchParameters(detail.lastSearchParameters, this.getCurrentMapDetail());
    }

    //#endregion Filter

    //#region Functionality
    onDragEnd({ sizes }: { sizes: Array<number> }) {
        this.mapSplitSize = sizes[0];
        this.gridSplitSize = sizes[1];

        this.saveSplitState();
    }

    saveSplitState() {
        if (this.splitComponent && this.rememberSplitState) {
            this.localStorageService.setItem(Constants.mapSplitSize, this.mapSplitSize.toString());
        }
    }

    protected loadSplitState() {
        const mapSplitSize = this.localStorageService.getItem(Constants.mapSplitSize);
        if (mapSplitSize && this.rememberSplitState) {
            this.mapSplitSize = Number.parseFloat(mapSplitSize);
            this.gridSplitSize = 100 - this.mapSplitSize;
        }
    }

    // This method allows us to select and pan to something
    // if the initial action is unselecting it from a grid
    // otherwise you'd need to click it twice (unselect -> select)
    private selectContextIfNothingSelected(e: IMeasuringPointSummary | IMeasuringPointSummary[] | IDeviceSummary | IDeviceSummary[]) {
        // const list = e.toList<IMeasuringPoint | IDevice>();
        // if (list.length === 1 && !this.selectionService.getSelected()) {
        //     this.handleContextsSelect(list[0]);
        // }
    }

    private selectContext(id: number) {
        if (this.zoomOnSelect && this.gmap.map.getZoom() < this.zoomOnSelectValue) {
            this.gmap.basicMap.setZoom(this.zoomOnSelectValue);
        }

        const marker = this.gmap.getLocationMarkerById(id);
        if (!marker) return;

        // If it's not neatly inside our bounds, pan
        if (!this.gmap.basicMap.isInBounds(marker.context.latLng)) {
            this.gmap.centerOnContext(marker.context);
        }

        this.gmap.selectContextById(id);
    }

    //#endregion Functionality

    //#region Measuring Points

    protected createMeasuringPoint(context: MarkerContext) {
        if (this.readonly) return;

        this.gmap.clearSelected();
        this.navigationService.createNewMeasuringPoint(context.id);
    }

    private handleMeasuringPointLocationSelect(locationId: number) {
        this.selectContext(locationId);
    }

    clearSelectedMeasuringPoints() {
        this.gmap?.locationMarkers?.forEach((m) => {
            m.setSelected(false);
        });
    }

    //#endregion Measuring Points

    //#region Groups

    private handleGroupMeasuringPointSelect(groupMeasuringPoint: IGroupMeasuringPoint) {
        const measuringPoint = groupMeasuringPoint.measuringPoint;
        this.gmap.closeGroupMarkers();
        this.handleMeasuringPointLocationSelect(measuringPoint.locationId);

        this.groupsComponent.measuringPointsComponent.setSelection(groupMeasuringPoint);
    }

    private async getGroup(groupId: number): Promise<IGroup> {
        let group = this.queriedGroupCache.find(x => x.id === groupId) || this.groupsComponent.data.find(x => x.id === groupId);

        if (!group) {
            // Fetch from API
            group = await this.groupApi.get$(groupId, null, this.groupsComponent.getServiceRequestOptions()).toPromise();
        }

        if (!group) {
            throw Error(`Couldn't find group ${groupId}`);
        }

        if (!this.queriedGroupCache.contains(group)) {
            this.queriedGroupCache.push(group);
        }

        return group;
    }

    async handleGroupSummarySelect(groupSummary: IGroupSummary) {
        if (!this.groupsComponent) return;

        // Get group from grid, if not present fetch from api
        const group = await this.getGroup(groupSummary.id);
        this.handleGroupSelect(group);
    }

    handleGroupSelect(groups: IGroup | IGroup[]) {
        if (!this.groupsComponent) return;

        const group = Array.isArray(groups) ? groups.takeLastOrDefault() : groups;

        this.groupsComponent.selectRow(group);

        if (this.groupsComponent.showMeasuringPointsOnSelect) {
            this.searchbutton.editGroup(group);
        } else {
            if (this.gmap.addFocusGroup(group.id, this.zoomOnSelect)) {
                // this.refreshSorting();
            }
        }
    }

    async handleGroupSummaryDeselect(groupSummary: IGroupSummary) {
        if (!this.groupsComponent) return;

        const group = await this.getGroup(groupSummary.id);
        this.handleGroupDeselect(group);
    }

    handleGroupDeselect(groups: IGroup | IGroup[]) {
        if (!this.groupsComponent) return;

        const group = Array.isArray(groups) ? groups.takeLastOrDefault() : groups;

        if (this.groupsComponent.showMeasuringPointsOnSelect) {

            // If deselected through groupsComponent, stop editing
            if (!this.groupsComponent.isSelected(group)) {
                this.gmap.setEditGroup(null);

                if (this.searchbutton.manageGroupComponent) {
                    this.searchbutton.manageGroupComponent.cancel();
                }
            }

        } else {
            this.groupsComponent.deselectRow(group);
            if (this.gmap.removeFocusGroup(group.id)) {
                // this.refreshSorting();
            }
        }
    }

    removeFromCacheGroup(group: IGroup) {
        this.queriedGroupCache = this.queriedGroupCache.filter(x => x.id !== group.id);
    }

    handleGroupMarkerClose(measuringPointLocationSummary: ILocationSummary) {
        // TODO Special situation, deselecting MPs on location groupmarker close
        // this.handleContextsDeselect(null);
        // this.handleContextsDeselect(measuringPointLocationSummary);
    }

    //#endregion Groups

    //#region Device

    deleteDevice(device: IDeviceSummary) {
        this.devicesComponent.delete(device);
    }

    private handleDeviceLocationSelect(device: IDeviceSummary) {
        this.selectContext(device.currentLocationId);
    }

    //#endregion Device

    //#region Organization

    createOrganization(mouseClick: google.maps.MapMouseEvent) {
        if (this.readonly) return;

        const coordinate = MapUtils.mouseEventToCoordinate(mouseClick);
        this.navigationService.createNewOrganization(coordinate);
    }

    editOrganization(organizationId: number) {
        if (this.readonly) return;

        this.navigationService.toOrganization(organizationId);
    }

    private handleOrganizationSelect(organization: IOrganization) {
        this.selectContext(organization.location.id);
    }

    //#endregion Organization

    // #region Assignments
    private handleAssignmentSelect(assignment: IAssignment) {
        this.selectContext(assignment.locationId);
    }

    private handleAssignmentDeselect() {
        this.gmap.locationMarkers.forEach(m => m.setSelected(false));
    }

    // #endregion Assignments
    //#region Required implementations (abstract)

    // We need this because the main map uses this.mapDetailService which is based on route
    // Other maps don't use this, and manage their own detail views
    abstract getCurrentMapDetail(): MapDetail;

    protected createMapContextMenu(event: google.maps.MapMouseEvent) { }

    protected createMarkerContextMenu(locationClickEvent: MapEvent) { }

    protected editLocation(context: MarkerContext) { }

    //#endregion Required implementations (abstract)

    //#region Helpers

    protected locationClickToLtnLng(locationClickEvent: MapEvent): google.maps.LatLng {
        // Fucking Google API's right click event
        // always returns latLng that is === marker.getPosition()
        // instead of the actual right click location
        // so we attempt to position it correctly by offsetting by the icon's anchor
        let latLng = locationClickEvent.locationMarker.marker.getPosition();
        const icon = locationClickEvent.locationMarker.marker.getIcon();
        if (icon && icon.anchor instanceof google.maps.Point) {
            const pixel = this.gmap.getPixelFromLatLng(locationClickEvent.locationMarker.marker.getPosition());
            latLng = this.gmap.getLatLngFromPixel(
                new google.maps.Point(
                    pixel.x + icon.anchor.x,
                    pixel.y + icon.anchor.y)
            );

        }
        return latLng;
    }
    //#endregion Helpers
}
