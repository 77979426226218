<div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top"
     *ngIf="canCreateAttachment && createCommand && !readonly">
    <button class="m-btn m-btn-secondary"
            (click)="create()">
        <app-svg-icon icon="upload">
        </app-svg-icon>
        {{'attachments.create' | translate}}
    </button>
</div>

<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default m-border-top"
         [loading]="loading"
         [totalRecords]="total"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-attachment>
        <tr [pSelectableRow]="attachment">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'createDate'">
                    {{attachment.createDate | momentDate:false }}
                </ng-container>

                <ng-container *ngSwitchCase="'creatorId'">
                    <app-navigator [userId]="attachment.creatorId"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'type'">
                    {{attachment.type?.code}}
                </ng-container>

                <ng-container *ngSwitchCase="'location'">
                    <span *ngIf="attachment?.location">
                        {{ attachment.location.address | address}}
                        <app-icon-button text="manageLocation.edit"
                                         icon="edit"
                                         (click)="editLocation(attachment.location)"
                                         *ngIf="canCreateTask">
                        </app-icon-button>
                    </span>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(attachment)"
                                             *ngIf="command.rowValidFunc(attachment)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{attachment[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>

<app-attachment-dialog (newUpload)="reload()"></app-attachment-dialog>
<app-location-dialog></app-location-dialog>