import { ILocationSummary } from "src/app/models/web";

export class SearchResultViewModel {

    public constructor(location: ILocationSummary) {
        this.location = location;
    }

    public location: ILocationSummary;
    public isSelected = false;
}