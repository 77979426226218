<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="pedestrianConfiguration">
    <div class="p-16 m-border-top m-border-bottom">
        <h3>{{"uploadDetailsDialog.pedestrianConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row">
            <div class="col-2"></div>
            <div class="col-4">
                <div class="m-form-group">
                    <label class="m-input-checkbox-block">
                        <input [formControl]="form.get('analyzeForwardDirection')"
                               name="name"
                               type="checkbox"> {{"uploadDetailsDialog.includeTraffic" | translate}}
                    </label>
                </div>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-2"></div>
            <div class="col-4">
                <div class="m-form-group">
                    <label class="m-input-checkbox-block">
                        <input [formControl]="form.get('analyzeReverseDirection')"
                               name="name"
                               type="checkbox"> {{"uploadDetailsDialog.includeOncomingTraffic" | translate}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>