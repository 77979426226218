import { IAuditTrail, AuditTrailCreator, AuditTrailExportOptions } from "../models/audit-trail";
import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { DownloadedFile } from "../services/download-file.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class AuditTrailsApi extends ApiService<IAuditTrail, AuditTrailCreator, AuditTrailCreator> {

    getRoute(): string {
        return "{?type}/{?id}/AuditTrails/";
    }

    public getAuditTrailsExportToExcel(auditTrailExportOptions: AuditTrailExportOptions): Promise<DownloadedFile> {
        const url = `${super.getUrl()}ExportToExcel`;

        const routeParams: { [key: string]: string } = {};
        routeParams.objectId = auditTrailExportOptions.objectId.toString();
        routeParams.type = auditTrailExportOptions.type.toString();

        const httpParams = new HttpParams({ fromObject: routeParams });
        return this.downloadFileService.downloadBlob(url, null, null, httpParams);
    }
}
