<form [formGroup]="cctvConfigurationForm"
      class="m-layout-area-body m-layout-w-actions-bottom m-border-top"
      *ngIf="measuringPoint"
      autocomplete="off">
    <div class="p-16 m-layout-area-body m-overflow-auto">

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cctvConfigurationForm.get('retentionNumberOfDays').showErrors }">

            <label class="col-4"
                   for="text">
                {{"cctvConfiguration.keepImagesForDays" | translate}}
            </label>

            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       id="retentionNumberOfDays"
                       formControlName="retentionNumberOfDays"
                       #retentionNumberOfDaysInput>

                <app-form-validation [control]="cctvConfigurationForm.get('retentionNumberOfDays')"
                                     [input]="retentionNumberOfDaysInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cctvConfigurationForm.get('retentionNumberOfImages').showErrors }">

            <label class="col-4"
                   for="text">
                {{"cctvConfiguration.keepSpecifiedNumberOfImages" | translate}}
            </label>

            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       id="retentionNumberOfImages"
                       formControlName="retentionNumberOfImages"
                       #retentionNumberOfImagesInput>

                <app-form-validation [control]="cctvConfigurationForm.get('retentionNumberOfImages')"
                                     [input]="retentionNumberOfImagesInput">
                </app-form-validation>
            </div>
        </div>
    </div>

    <div class="m-btn-group m-btn-group-right m-border-top m-layout-area-bottom px-16 py-8">
        <button class="m-btn m-btn-link"
                (click)="reset()"
                type="button"
                id="cctv_tab_reset_button">
            {{"form.reset" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="cctv_tab_submit_button">
            <app-loading-spinner id="cctv_tab_spinner"
                                 *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </div>

</form>