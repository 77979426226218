import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit, OnInit } from "@angular/core";
import { DateFormControl, PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { IPredictDaysData, IPredictionRequestValidation } from "src/app/models/prediction";
import { PredictionRequestValidationsComponent } from "../prediction-request-validations/prediction-request-validations.component";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { IProgress, IProgressCreated } from "src/app/models/progress";
import { NavigationStart, Router } from "@angular/router";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { SubscriptionManager, NumberUtils } from "src/app/utilities";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { ValidationContext } from "src/app/models/validation-context";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ValidationService } from "src/app/services/validation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { DataDaysService } from "src/app/services/data-days.service";
import { ProgressAction } from "src/app/services/progress.service";
import { PredictionApi } from "src/app/resource/prediction.api";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ErrorService } from "src/app/services/error.service";
import { UntypedFormBuilder } from "@angular/forms";
import { ProgressApi } from "src/app/resource/progress.api";
import { MapDetail } from "src/app/services/map-detail.service";
import { Calendar } from "primeng/calendar";
import { filter } from "rxjs/operators";
import { ResourceSelectorComponent } from "src/app/modules/shared/components/resource-selector/resource-selector.component";
import { ToastService } from "src/app/services/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-prediction",
    templateUrl: "./prediction.component.html"
})
export class PredictionComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(PredictionRequestValidationsComponent, { static: false }) predictionRequestValidationsComponent: PredictionRequestValidationsComponent;

    @ViewChild("rangeFromCalendar", { static: false }) set setRangeFromCalendar(calendar: Calendar) {
        this.rangeFromDateControl.setCalendar(calendar);
    }

    @ViewChild("rangeToCalendar", { static: false }) set setRangeToCalendar(calendar: Calendar) {
        this.rangeToDateControl.setCalendar(calendar);
    }

    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        this.map = mapSelector;
    }

    @ViewChild(ResourceSelectorComponent, { static: true }) resourceSelectorComponent: ResourceSelectorComponent;

    loading = true;
    mapOpen: boolean;
    mapDetail: MapDetail;
    maxDate: Date;

    projectSelectionOpen = false;

    progressCounter: number;
    applyCounter: number;
    applyMessage: string;

    predictionForm: SigncoFormGroup;

    rangeFromDateControl: DateFormControl;
    rangeToDateControl: DateFormControl;
    calendarSettings: CalendarSettings;
    userErrors: ViewModelEnum[];

    progressActive: boolean;
    currentTaskId: string;

    private previousContext: ValidationContext;
    private previousPredictDaysData: IPredictDaysData;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly dataDaysService: DataDaysService,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly router: Router,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly validationService: ValidationService,
        private readonly selectionService: MapSelectionService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly predictionApi: PredictionApi,
        private readonly domainDataService: DomainDataService,
        private readonly errorService: ErrorService,
        private readonly progressApi: ProgressApi,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.dataDaysService.clear();
        const updateDataDaysMeasuringPoints = () => {
            this.dataDaysService.setMeasuringPoints(this.selectionService.getSelectedMeasuringPoints().map(x => x.id));
        };

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, updateDataDaysMeasuringPoints, updateDataDaysMeasuringPoints);
        this.selectionService.subscribeToGroups(this.subscriptionManager, x => this.dataDaysService.addGroups(x), x => this.dataDaysService.removeGroups(x));

        this.createForm();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        const navigationEndSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.saveContext();
            });
        this.subscriptionManager.add("navigationEnd", navigationEndSubscription);
    }

    ngOnInit(): void {
        this.domainDataService.get(DomainData.UserError).then((retList: ViewModelEnum[]) => this.userErrors = retList);
    }

    ngAfterViewInit() {
        this.restoreContext();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();

        // stop the background task on BE if it is still calculating
        if (this.progressActive) {
            this.progressApi.cancel$(this.currentTaskId).subscribe(
                () => {

                },
                () => {

                });
        }
        this.progressActive = false;
    }

    private createForm() {
        const fromDate = new Date().addDays(-8);
        this.maxDate = new Date().addDays(-1);

        this.rangeFromDateControl = new DateFormControl(null, fromDate);
        this.rangeFromDateControl.setDateSelectionMode("single");

        this.rangeToDateControl = new DateFormControl(null, this.maxDate);
        this.rangeToDateControl.setDateSelectionMode("single");

        this.predictionForm = this.formBuilder.group({
            from: this.rangeFromDateControl,
            until: this.rangeToDateControl,
            predictExceptionalDays: false,
            predictPredictedDays: false
        }) as SigncoFormGroup;
    }

    private async restoreContext() {
        this.loading = true;

        const context = await this.validationService.getContext();
        if (context) {
            this.predictionForm.patchValue({
                from: context.from > this.maxDate ? this.maxDate : context.from,
                until: context.to > this.maxDate ? this.maxDate : context.to
            });

            this.selectionService.setGroups(context.groups);
            this.selectionService.setMeasuringPoints(context.measuringPoints);
        }

        this.loading = false;
    }

    private saveContext() {
        const groups = this.resourceSelectorComponent.groupsComponent.data;
        const measuringPoints = this.resourceSelectorComponent.measuringPointsComponent.data;
        const projects = this.resourceSelectorComponent.projectsComponent.data;

        this.validationService.setContext(new ValidationContext(
            this.rangeFromDateControl.value as Date,
            (this.rangeToDateControl.value as Date),
            groups,
            measuringPoints,
            projects
        ));
    }

    get showPredictionModelQualities(): boolean {
        if (this.mapOpen) return false;
        if (!this.predictionRequestValidationsComponent) return false;

        return (this.predictionRequestValidationsComponent.data || []).length > 0;
    }

    get showProgressBar(): boolean {
        return NumberUtils.isValid(this.progressCounter);
    }

    get submitting(): boolean {
        return this.showApplyProgressBar;
    }

    get showApplyProgressBar(): boolean {
        return NumberUtils.isValid(this.applyCounter);
    }

    get selectionLength(): number {
        if (!this.predictionRequestValidationsComponent) return 0;

        return this.predictionRequestValidationsComponent.getSelection().length;
    }

    //#region Map

    isMapOpen() {
        return this.mapOpen || this.projectSelectionOpen;
    }

    private openMap(detail: MapDetail) {
        this.mapDetail = detail;
        this.mapOpen = true;
    }

    closeMap() {
        this.mapOpen = false;
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.map.measuringPointsComponent.selectionMode = "multiple";
        }

        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.map.groupsComponent.selectionMode = "multiple";
        }
    }

    toggleMap(mapDetail: MapDetail) {
        if (mapDetail === null || mapDetail === undefined) return;

        if (mapDetail === MapDetail.MeasuringPointGroups) this.toggleAddGroups();
        else if (mapDetail === MapDetail.Projects) this.toggleAddProjects();
        else this.toggleAddMeasuringPoints();
    }

    //#endregion Map

    toggleAddProjects() {
        if (this.projectSelectionOpen) {
            this.closeProjectSelection();
            return;
        }

        this.closeMap();
        this.projectSelectionOpen = true;
    }

    closeProjectSelection() {
        this.projectSelectionOpen = false;
    }

    //#endregion Projects

    //#region Measuring Point

    toggleAddMeasuringPoints() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPoints) {
            this.closeMap();
            return;
        }

        this.closeProjectSelection();
        this.openMap(MapDetail.MeasuringPoints);
    }

    //#endregion Measuring Point

    //#region Groups

    toggleAddGroups() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.closeMap();
            return;
        }

        this.closeProjectSelection();
        this.openMap(MapDetail.MeasuringPointGroups);
    }

    //#endregion Groups

    //#region Error handling

    isDatesInError(): boolean {
        if (!this.predictionForm.submitted) return false;

        return !this.rangeFromDateControl.value || !this.rangeToDateControl.value;
    }

    //#endregion Error handling

    //#region Submit

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.predictionForm);
        if (!isValid) return;

        // Can't be checked by default form validity, goes beyond the normal scope
        // So we check it properly here
        if (this.isDatesInError() || this.resourceSelectorComponent.isInputInError(this.predictionForm.submitted)) return;

        this.saveContext();

        this.previousContext = await this.validationService.getContext();
        if (!this.previousContext.allMeasuringPoints.takeFirstOrDefault()) {
            this.toastService.warning(this.translateService.instant("dataValidation.noMeasuringPointsSelected"));
            return;
        }

        this.previousPredictDaysData = this.predictionForm.value as IPredictDaysData;
        await this.loadValidations();
    }

    private async loadValidations() {
        this.predictionRequestValidationsComponent.setLoading();
        this.closeMap();

        const progressMax = this.previousContext.allMeasuringPoints.length;
        this.progressCounter = 0;
        let subscriptionCounter = 0;

        const predictionModelQualities = new Array<IPredictionRequestValidation>();

        const replaceAt = function (index: number, replacement: string, source: string): string {
            if (index >= source.length) return source;
            return source.substring(0, index) + replacement + source.substring(index + 1);
        };

        for (const measuringPoint of this.previousContext.allMeasuringPoints) {
            await new Promise<void>((resolve, reject) => {

                const onSuccess = (predictionModelQuality: IPredictionRequestValidation) => {
                    predictionModelQuality.measuringPoint = measuringPoint;
                    predictionModelQuality.id = measuringPoint.id; // Table dataKey

                    if (predictionModelQuality.warning) {
                        predictionModelQuality.warning = replaceAt(0, predictionModelQuality.warning.charAt(0).toUpperCase(), predictionModelQuality.warning);
                        predictionModelQuality.warning = this.userErrors.find(x => x.jsonValue == predictionModelQuality.warning)?.label;
                    }


                    predictionModelQualities.push(predictionModelQuality);

                    resolve();
                };

                const onError = () => {
                    resolve();
                };

                this.measuringPointApi.validatePrediction$(measuringPoint.id, this.previousPredictDaysData).subscribe(onSuccess, onError);
            });

            this.progressCounter = Number((++subscriptionCounter / progressMax * 100).toFixed(0));
        }

        this.predictionRequestValidationsComponent.setData(predictionModelQualities.orderBy(x => x.measuringPoint.code));
        this.progressCounter = null;

        this.predictionRequestValidationsComponent.setLoading(false);
    }

    async applyPredictions() {
        if (this.showApplyProgressBar) return;

        this.applyMessage = null;

        this.predictionRequestValidationsComponent.setLoading();

        const selection = this.predictionRequestValidationsComponent.getSelection();

        this.applyCounter = 0;

        const onError = (error: Response) => {
            this.progressActive = false;
            this.applyCounter = null;
            this.predictionRequestValidationsComponent.setLoading(false);
        };

        const onComplete = (progressAction: ProgressAction) => {
            this.progressActive = false;
            this.applyCounter = null;
            this.loadValidations();
        };

        const predictDaysDataArray: IPredictDaysData[] = new Array<IPredictDaysData>();
        for (const validation of selection) {
            predictDaysDataArray.push({
                from: this.previousPredictDaysData.from,
                until: this.previousPredictDaysData.until,
                measuringPointId: validation.measuringPoint.id,
                predictExceptionalDays: this.previousPredictDaysData.predictExceptionalDays,
                predictPredictedDays: this.previousPredictDaysData.predictPredictedDays
            });
        }

        const onProgressCreated = (progressCreated: IProgressCreated) => {
            this.progressActive = true;
            this.currentTaskId = progressCreated.progressId;
            this.addProgressCreated(progressCreated, onComplete, onError);
        };

        const handleError = (error: Response) => {
            this.errorService.handleError(error);
            this.progressActive = false;
            onError(error);
        };

        this.predictionApi.createModelsAndPredictDaysWithProgress$(predictDaysDataArray).subscribe(onProgressCreated, handleError);
    }

    private addProgressCreated(progressCreated: IProgressCreated, onComplete: (action: ProgressAction) => void, onError: (error: Response) => void) {
        const action = new ProgressAction(progressCreated.progressId, progressCreated.name);
        action.onComplete = onComplete;
        action.onError = onError;

        this.startPolling(action);
    }

    private startPolling(action: ProgressAction) {
        const onProgress = (progress: IProgress) => {
            action.lastProgress = progress;
            this.applyCounter = action.lastProgress.progress;
            this.applyMessage = action.lastProgress.message; // Only override if we have a new message
        };

        const onComplete = () => {
            if (action.onComplete) {
                action.onComplete(action);
            }
        };

        const onError = (error: Response) => {
            if (action.onError) {
                action.onError(error);
            }

            this.errorService.handleError(error);
        };

        this.progressApi.pollProgress(action.id, onProgress).then(onComplete, onError);
    }

    async onFirstLevelLoaded() {
        const context = await this.validationService.getContext();
        if (context) {
            // only measurement project -> there can be a situation that MaaS projects are cached from reporting e.g
            this.selectionService.setProjects(context.projects?.filter(x => x.isMeasurementProject) ?? []);
        }
    }

    //#endregion Submit
}
