<p-table id="links_table"
         [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body"
         [loading]="loading"
         [totalRecords]="total"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         scrollHeight="flex"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-link>
        <tr [pSelectableRow]="link"
            [pSelectableRowDisabled]="link.gapfiller">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'creator'">
                    <app-navigator [user]="link.creator"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'validFrom'">
                    {{link.validFrom | momentDateTime:true:false:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'validUntil'">
                    {{link.validUntil | momentDateTime:true:false:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'device'">
                    <ng-container *ngIf="link.device">
                        <app-navigator [device]="link.device">
                        </app-navigator>
                    </ng-container>

                    <ng-container *ngIf="!link.device">
                        {{"links.noLink" | translate}}
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'measurements'">
                    <div>
                        <ng-container *ngIf="link.depot">
                            <div class="flex align-center">
                                <div>
                                    <app-svg-icon icon="depot"></app-svg-icon>
                                </div>

                                <div class="ml-8">
                                    {{link.depot.code}}
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="link.measurements">
                            <div class="flex"
                                 *ngFor="let measurement of link.measurements">
                                <div class="mr-8"
                                     style="margin-top:2px; margin-bottom:2px;">
                                    <app-svg-icon [icon]="measurement.analyzerConfiguration.type"></app-svg-icon>
                                </div>

                                <div class="mr-8"
                                     style="align-self:center;">
                                    <app-navigator [measuringPoint]="measurement.measuringPoint"></app-navigator>
                                </div>

                                <span *ngIf="measurement.tubes.length > 0"
                                      style="align-self:center;">
                                    ({{measurement.tubes.join(" - ")}})
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(link)"
                                             *ngIf="command.rowValidFunc(link)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{link[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>