<div class="m-form-group p-16"
     *ngIf="formVisible && form"
     formGroupName="dekimoDisplayLink">

    <div formGroupName="fieldLinks">
        <table style="border-spacing: 8px;">
            <thead>
                <tr>
                    <th>{{"totemDisplayConfiguration.field" | translate}}</th>
                    <th>{{"totemDisplayConfiguration.displaySide" | translate}}</th>
                    <th>{{"totemDisplayConfiguration.startTile" | translate}}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let fieldControl of fieldFormArray.controls">
                    <td>
                        <div>
                            {{getFieldConfiguration(fieldControl.value.totemFieldId).name}}
                        </div>
                        <small>
                            ({{getFieldConfiguration(fieldControl.value.totemFieldId).lineCount}} x {{getFieldConfiguration(fieldControl.value.totemFieldId).lineLength}})
                        </small>
                    </td>
                    <td>
                        <p-dropdown [options]="displaySides"
                                    [panelStyle]="{'width':'100%'}"
                                    [formControl]="fieldControl.get('displaySide')"
                                    [autoDisplayFirst]="true"></p-dropdown>
                    </td>

                    <td>
                        <input type="number"
                               class="p-inputtext"
                               style="width:70px;min-width:70px"
                               [formControl]="fieldControl.get('startTile')" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>