import { ApiServiceBase } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IProjectsTreeOrganization } from "../models/projects-tree-organization";

@Injectable({
    providedIn: "root"
})
export class ProjectsTreeApi extends ApiServiceBase {

    getRoute(): string {
        return "ProjectsTree";
    }

    get(onlyActiveProjects = false, onlyMeasurementProjects = false, onlyMaasProjects = false): Observable<IProjectsTreeOrganization[]> {
        const url = `${super.getUrl()}?onlyActiveProjects=${onlyActiveProjects}&onlyMeasurementProjects=${onlyMeasurementProjects}&onlyMaasProjects=${onlyMaasProjects}`;

        return this.http.get<IProjectsTreeOrganization[]>(url);
    }
}