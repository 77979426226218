import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { MeasuringPointUtils } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPointSummary } from "src/app/models/web";
import { BackendRights } from "src/app/models/backend-rights";
import { LinksComponent } from "src/app/modules/shared/components/links/links.component";

@Component({
    selector: "app-measuring-point-links",
    templateUrl: "./measuring-point-links.component.html",
    host: { class: "m-layout-area-body m-layout-default" }
})
export class MeasuringPointLinksComponent implements OnInit {
    @ViewChild(LinksComponent, { static: true }) linksComponent: LinksComponent;

    @Input() measuringPoint: IMeasuringPoint;

    // This holds a "device" property to current link
    // Let's fetch it and use it
    private measuringPointSummary: IMeasuringPointSummary;
    canCreateDeviceLink: boolean;

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly navigationService: NavigationService,
        private readonly measuringPointWebApi: MeasuringPointWebApi) {
    }
    ngOnInit(): void {
        const rights = this.globalEventsService.getCurrentRights();
        this.canCreateDeviceLink = rights?.hasBackendRight(BackendRights.EditDeviceLink);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.linksComponent.setMeasuringPoint(measuringPoint);

        if (this.measuringPoint) {
            this.measuringPointWebApi.get(measuringPoint.id).then(measuringPointSummary => {
                this.measuringPointSummary = measuringPointSummary;
            });
        } else {
            this.measuringPointSummary = null;
        }
    }

    startMeasurement() {
        this.navigationService.createLinkForMeasuringPoint(this.measuringPoint);
    }

    get canMoveToDepot(): boolean {
        return this.measuringPointSummary && !!this.measuringPointSummary.device;
    }

    moveToDepot() {
        if (!this.canMoveToDepot) return;

        this.navigationService.breakDeviceLink(this.measuringPointSummary.device.id, MeasuringPointUtils.toSummary(this.measuringPoint));
    }

    get canReplaceDevice(): boolean {
        return this.measuringPointSummary && !!this.measuringPointSummary.device;
    }

    replaceDevice() {
        if (!this.canReplaceDevice) return;

        this.navigationService.replaceLinkDevice(this.measuringPointSummary.deviceLinkId, this.measuringPoint);
    }
}