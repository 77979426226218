import { Component, EventEmitter, Output } from "@angular/core";
import { MeasuringPointLinkStatus } from "src/app/models/map-filter";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { StatusFilter } from "./status-model";
import { AlertLevel } from "src/app/models/alert";

@Component({
    selector: "app-status-filter-dialog",
    templateUrl: "./status-filter-dialog.component.html",
})
export class StatusFilterDialogComponent extends DialogComponentBase {
    @Output() onClosed: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSaved: EventEmitter<StatusFilter> = new EventEmitter<StatusFilter>();

    statusLinked = MeasuringPointLinkStatus.Linked; // Allow easy binding in HTML
    statusNotLinked = MeasuringPointLinkStatus.NotLinked; // Allow easy binding in HTML

    selectedLinkStatus: MeasuringPointLinkStatus;
    selectedAlertLevels: AlertLevel[] = [];

    AlertLevel = AlertLevel;

    constructor() {
        super();
    }

    openFilterDialog(activeAlertLevelFilters: AlertLevel[], activeLinkStatusFilter?: MeasuringPointLinkStatus) {
        this.visible = true;
        this.selectedLinkStatus = activeLinkStatusFilter;
        this.selectedAlertLevels = [...activeAlertLevelFilters];
    }

    isSelected(option: MeasuringPointLinkStatus): boolean {
        return this.selectedLinkStatus === option;
    }

    toggleSelection(option: MeasuringPointLinkStatus) {
        if (Object.values(MeasuringPointLinkStatus).includes(option)) {
            this.selectedLinkStatus = option;
        }
    }

    isAlertLevelSelected(option: AlertLevel): boolean {
        return this.selectedAlertLevels?.some((opt) => opt === option);
    }

    toggleAlertLevelSelection(option: AlertLevel) {
        const index = this.selectedAlertLevels.findIndex(
            (opt) => opt === option
        );

        if (index !== -1) {
            this.selectedAlertLevels.splice(index, 1);
        } else {
            this.selectedAlertLevels.push(option);
        }
    }

    getDeviceFilterIcon(status: MeasuringPointLinkStatus): string {
        switch (status) {
            case this.statusLinked:
                return "signal-stream";
            case this.statusNotLinked:
                return "signal-stream-slash";
            default:
                return "";
        }
    }

    getAlertLevelFilterIcon(level: AlertLevel): string {
        switch (level) {
            case AlertLevel.error:
                return "circle-xmark";
            case AlertLevel.warning:
                return "circle-exclamation";
            case AlertLevel.info:
                return "circle-info";
            default:
                return "";
        }
    }

    saveSelection() {
        this.onSaved.emit(new StatusFilter(this.selectedLinkStatus, this.selectedAlertLevels));
        this.close();
    }

    protected onClose(): void {
        this.onClosed.emit();
    }
}
