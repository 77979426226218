<div class="m-btn-group m-btn-group-right">
    <button class="m-btn m-btn-secondary"
            (click)="create()">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"categories.create" | translate}}
    </button>
</div>

<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [loading]="loading"
         [totalRecords]="total"
         [selectionMode]="selectionMode"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-category
                 let-expanded="expanded">
        <tr [ngClass]="getObsoleteClass(category)"
            [pSelectableRow]="category">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'expand'">
                    <a [pRowToggler]="category">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </ng-container>

                <ng-container *ngSwitchCase="'code'">
                    {{category.code}}
                </ng-container>

                <ng-container *ngSwitchCase="'description'">
                    {{ category.description }}
                </ng-container>

                <ng-container *ngSwitchCase="'isMaas'">
                    <input type="checkbox"
                           [ngModel]="category.isMaas"
                           [disabled]="true" />
                </ng-container>

                <ng-container *ngSwitchCase="'isObsolete'">
                    <input type="checkbox"
                           [ngModel]="category.isObsolete"
                           [disabled]="true" />
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(category)"
                                             *ngIf="command.rowValidFunc(category)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>
            </td>
        </tr>

    </ng-template>

    <!-- Row Details -->
    <ng-template pTemplate="rowexpansion"
                 let-category
                 let-columns="columns">

        <tr>
            <td [attr.colspan]="columns.length"
                style="padding-top: 10px; padding-bottom: 10px; padding-left: 56px">

                <app-admin-journal-sub-categories [parentCategory]="category"></app-admin-journal-sub-categories>
            </td>
        </tr>
    </ng-template>
</p-table>

<app-manage-journal-category-dialog></app-manage-journal-category-dialog>