<div style="display: grid; gap:8px;">
    <div style="display:grid; grid-template-columns: 1fr auto;">
        <h4>{{data.analysisType | translateEnum:"analysisType"}}</h4>
        <div style="display:flex; flex-wrap: wrap; gap: 4px; justify-content: end;">
            <app-alert-badge *ngFor="let alert of data.alerts"
                             [locationStatusSummary]="locationStatusSummary"
                             [measuringPointStatusSummary]="data"
                             [data]="alert"></app-alert-badge>
        </div>
    </div>
    <div style="display: grid; grid-template-columns: auto 1fr ;gap:16px;">
        <div class="location-popup__tile-icon">
            <img [src]="getIconUrl()" />
        </div>
        <div style="display:grid; grid-template-columns: 1fr 1fr; gap:8px;">
            @if(data?.licensePlate){
            <dl class="location-popup__description-list">
                <dt>{{"arrowBoardConfigurationTab.licensePlate" | translate}}</dt>
                <dd>{{data?.licensePlate }}</dd>
            </dl>
            }
            @if(data?.vehicleIdentificationNumber){
            <dl class="location-popup__description-list">
                <dt>{{"arrowBoardConfigurationTab.vehicleIdentificationNumber" | translate}}</dt>
                <dd>{{data?.vehicleIdentificationNumber}}</dd>
            </dl>
            }

            @if(data?.vehicleType){
            <dl class="location-popup__description-list">
                <dt>{{"general.type" | translate}}</dt>
                <dd>{{data?.vehicleType | translateEnum:"analysisVehicleType"}}</dd>
            </dl> }


            @if(data?.vehicleColor){
            <dl class="location-popup__description-list">
                <dt>{{"general.color" | translate}}</dt>
                <dd>{{data?.vehicleColor | translateEnum:"vehicleColor"}}</dd>
            </dl>
            }



            <dl class="location-popup__description-list">
                <dt>{{"liveTiles.project" | translate}}</dt>
                <dd *ngFor="let project of data.projects">
                    <app-navigator [project]="project"
                                   [newTab]="true"></app-navigator>
                </dd>
            </dl>

            <dl class="location-popup__description-list"
                style="grid-column: 1 / span 2;">
                <dt>{{"liveTiles.installation" | translate}}</dt>
                <dd>{{ {from:data.lastDeviceLinkValidFrom, until:data.lastDeviceLinkValidUntil} | dateRange }}</dd>
            </dl>
        </div>
    </div>
    <div>
        <app-user-message *ngFor="let userMessage of data.currentStatus?.data?.userMessages"
                          [data]="userMessage"></app-user-message>
    </div>
</div>