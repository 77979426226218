import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges, TemplateRef, Type, ChangeDetectorRef, Injectable, ViewChild, AfterViewInit, ElementRef, Directive } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { SubscriptionManager, StringUtils } from "src/app/utilities";
import { TranslateService } from "@ngx-translate/core";
import { ResizeService } from "src/app/services/resize.service";
import { TitleService } from "src/app/services/title.service";
import { GlobalEventsService } from "src/app/services/global-events-service";

export class Tab<T> {
    component?: Type<T>;
    label?: string;
    translatePath?: string;
    icon?: string;
    url?: string;
    disabled?: boolean;
    routerLink?: string;
}

@Injectable({
    providedIn: "root"
})
export class TabService {
    constructor(
        readonly globalEventsService: GlobalEventsService,
        readonly impersonationService: ImpersonationService,
        readonly titleService: TitleService
    ) {

    }
}

@Component({
    selector: "app-tabs",
    templateUrl: "./tabs.component.html"
})
export class TabsComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild("scrollElement", { static: true }) scrollElement: ElementRef<HTMLElement>;

    @Input() titleTemplate: TemplateRef<any>;
    @Input() tabs: Tab<any>[];
    @Input() selectedTab: Tab<any>;
    @Input() useRouting = true;
    @Input() relativeRouting = false;
    @Input() hidden = false;
    @Input() divClass: string;

    @Output() selectedTabChange = new EventEmitter<Tab<any>>();

    public showChevronStart = false;
    public showChevronEnd = false;

    private readonly subscriptionManager = new SubscriptionManager();
    private relativeUrl: string;

    constructor(
        readonly translateService: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private readonly resizeService: ResizeService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router) {

        this.updateRelativeUrl();

        this.subscriptionManager.add("onLangChange", this.translateService.onLangChange.subscribe(() => {
            this.loadTranslations();
        }));

        this.subscriptionManager.add("onRouterEvent", this.router.events.subscribe(() => {
            this.updateRelativeUrl();
            this.updateRouterLinks();
        }));

        this.subscriptionManager.add("onResize", this.resizeService.onResize.subscribe(() => {
            this.updateChevrons();
        }));
    }

    ngAfterViewInit() {
        this.updateChevrons();
    }

    ngOnChanges(changes: SimpleChanges) {
        const tabChange = changes["tabs"];
        if (tabChange) {
            if (!this.tabs) {
                this.selectedTab = null;
            } else if (this.selectedTab && this.tabs.indexOf(this.selectedTab) === -1) {
                this.selectedTab = this.tabs ? this.tabs.takeFirstOrDefault() : null;
            }

            this.updateRouterLinks();
            this.loadTranslations();
        }

        const selectedTabChange = changes["selectedTab"];
        if (selectedTabChange && selectedTabChange.previousValue !== selectedTabChange.currentValue) {
            this.handleSelect(this.selectedTab || this.tabs.find(x => !x.disabled));
        }

        const hiddenChange = changes["hidden"];
        if (hiddenChange) {
            Promise.resolve().then(() => {
                this.updateChevrons();
            });
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    public updateRelativeUrl() {
        const currentUrl = decodeURI(this.router.routerState.snapshot.url);

        if (this.activatedRoute.snapshot.url.length > 0) {
            const currentRouteUrlEnd = this.activatedRoute.snapshot.url.map(x => x.path).join("/");
            const indexToSub = currentUrl.indexOf(currentRouteUrlEnd) + currentRouteUrlEnd.length;

            this.relativeUrl = currentUrl.substring(0, indexToSub);
        } else {
            const lastIndex = currentUrl.lastIndexOf("/");
            this.relativeUrl = lastIndex > 0 ? currentUrl.substring(0, lastIndex) : currentUrl;
        }
    }

    async handleSelect(tab: Tab<any>, event?: MouseEvent) {
        if (event) {
            event.stop();
        }

        if (!tab || tab.disabled) return;

        const selectTab = () => {
            this.selectedTab = tab;
            this.selectedTabChange.emit(tab);
        };

        if (this.useRouting) {
            if (this.relativeRouting) {
                const success = await this.router.navigate([tab.url], { relativeTo: this.activatedRoute } as NavigationExtras);
                if (success) selectTab();
            } else {
                const success = await this.router.navigate([tab.url]);
                if (success) selectTab();
            }

            this.cd.detectChanges();
        } else {
            selectTab();
        }
    }

    private loadTranslations() {
        if (!this.tabs) return;

        for (const tab of this.tabs) {
            if (!tab.translatePath) continue;

            const subscription = this.translateService.get(tab.translatePath).subscribe(label => {
                tab.label = label;
            });

            this.subscriptionManager.add(tab.translatePath, subscription);
        }
    }

    updateRouterLinks() {
        if (!this.tabs) return;

        for (const tab of this.tabs) {
            if (this.relativeRouting) {
                tab.routerLink = `${this.relativeUrl}/${tab.url}`;
            } else {
                tab.routerLink = tab.url;
            }
        }
    }

    tabTrackByFn(index: number, item: Tab<any>) {
        return item.url;
    }

    updateChevrons() {
        if (!this.scrollElement) return;
        const nativeElement = this.scrollElement.nativeElement;
        const isScrollable = nativeElement.scrollWidth > nativeElement.clientWidth;

        this.showChevronStart = isScrollable && nativeElement.scrollLeft > 0;
        this.showChevronEnd = isScrollable && nativeElement.scrollLeft < (nativeElement.scrollWidth - nativeElement.clientWidth);
    }

    scrollToStart() {
        if (!this.scrollElement) return;
        const nativeElement = this.scrollElement.nativeElement;
        nativeElement.scrollLeft = 0;
    }

    scrollToEnd() {
        if (!this.scrollElement) return;
        const nativeElement = this.scrollElement.nativeElement;

        const max = nativeElement.scrollWidth - nativeElement.clientWidth;
        nativeElement.scrollLeft = Math.min(nativeElement.scrollLeft + nativeElement.clientWidth, max);
    }
}

@Directive()
export abstract class TabBase<T> implements OnDestroy {
    private _tabs = new Array<Tab<T>>();
    tabs = new Array<Tab<T>>();
    tabFilter: Type<T>[];
    selectedTab: Tab<T>;
    activeComponent: T;

    readonly key = StringUtils.createKey();

    readonly impersonationService: ImpersonationService;
    readonly titleService: TitleService;
    readonly globalEventsService: GlobalEventsService;

    protected readonly subscriptionManager = new SubscriptionManager();

    constructor(
        tabService: TabService,
        protected readonly cd: ChangeDetectorRef) {

        this.globalEventsService = tabService.globalEventsService;
        this.impersonationService = tabService.impersonationService;
        this.titleService = tabService.titleService;

        this.impersonationService.subscribeToRoleImpersonation(this.key, () => {
            this.filterTabs();
        });

        this.subscriptionManager.add("uponLogin", this.globalEventsService.authorizationInfo$.subscribe(() => {
            this.filterTabs();
        }));
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.impersonationService.unsubscribe(this.key);
    }

    protected addTab(tab: Tab<T>) {
        if (this._tabs.contains(tab)) return;

        this._tabs.push(tab);

        this.reloadFilter();
    }

    protected removeTab(type: Type<T>) {
        const tab = this._tabs.find(x => x.component === type);
        if (!tab) return;

        this._tabs.remove(tab);

        this.fixSelectedTab();
    }

    onActivate(component: T) {
        this.selectedTab = null;
        this.activeComponent = component;
        this.updateActiveComponent();
    }

    navigateTo(tab: Tab<T>) {
        if (!tab) return;

        this.selectedTab = tab;
    }

    protected updateActiveComponent() {
        if (!this.tabs || !this.activeComponent) return;

        const newTab = this.tabs.find(x => this.activeComponent instanceof x.component) || null;

        if (newTab !== this.selectedTab) {
            this.selectedTab = newTab;

            if (this.selectedTab) {
                this.onSelectedTabChange();
            }

            this.updateTitle();
        }

        this.cd.detectChanges();
    }

    protected onSelectedTabChange() {

    }

    updateTitle() {
        if (!this.selectedTab) return;

        this.titleService.setTitle(this.selectedTab.translatePath);
    }

    filterTabs() {

    }

    setTabsDisabledState(expression: (x: Type<T>) => boolean) {
        for (const tab of this.tabs) {
            tab.disabled = expression(tab.component);
        }

        this.fixSelectedTab();
    }

    hideTabs(typesToFilter: Type<T>[]) {
        this.tabFilter = typesToFilter;

        this.reloadFilter();
    }

    private reloadFilter() {
        const filteredTabs = new Array<Tab<T>>();

        for (const tab of this._tabs) {
            if (this.tabFilter && this.tabFilter.contains(tab.component)) continue;

            filteredTabs.push(tab);
        }

        this.tabs = filteredTabs;

        this.fixSelectedTab();
    }

    clearFilter() {
        this.tabFilter = null;
        this.tabs = this._tabs;
    }

    private fixSelectedTab(updateActiveComponent = true) {
        if (!this.selectedTab) {
            if (updateActiveComponent) {
                this.updateActiveComponent();
            }

            if (!this.selectedTab) return;
        }

        if (this.selectedTab.disabled || !this.tabs.contains(this.selectedTab)) {
            this.navigateTo(this.tabs.find(x => !x.disabled) || this.tabs.takeFirstOrDefault());
        }
    }
}
