import { TableColumn, FilterType, TableService, CustomTableComponent } from "src/app/modules/shared/components/table/table.component";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "app-files",
    templateUrl: "./files.component.html"
})
export class FilesComponent extends CustomTableComponent<File> {
    @ViewChild("fileInput", { static: false }) fileInput: ElementRef<HTMLInputElement>;

    @Input() multiple = true;
    @Input() accept = "";
    @Input() deleteCommand = true;

    @Output() changed = new EventEmitter<void>();

    constructor(
        elementRef: ElementRef,
        tableService: TableService) {

        super("files.component", elementRef, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.selectionMode = "";
        this.paginator = false;

        this.addColumn(new TableColumn("name", "general.file", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("size", "general.fileSize"));

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (file) => this.removeData([file]),
            validFunc: () => this.deleteCommand
        });
    }

    openFileDialog() {
        this.fileInput.nativeElement.click();
    }

    setFiles(event: { files: FileList }) {
        if (!event) return;

        const files = this.data ? this.data.clone() : new Array<File>();
        for (let i = 0; i < event.files.length; i++) {
            const file = event.files.item(i);

            if (!(this.data || []).find(x => x.name === file.name)) {
                files.push(file);
            }
        }

        this.setData(files);
        this.fileInput.nativeElement.value = "";
        this.changed.next();
    }
}