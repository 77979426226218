import { DownloadFileService } from "src/app/services/download-file.service";
import { Component, Input } from "@angular/core";
import { GalleriaService } from "src/app/services/galleria.service";
import { IGalleriaImage } from "../galleria/galleria.component";
import { IAttachment } from "src/app/models/attachment";
import { FileUtils } from "src/app/utilities";

@Component({
    selector: "app-attachment-overview",
    templateUrl: "./attachment-overview.component.html"
})
export class AttachmentOverviewComponent {
    @Input() context: { attachments: IAttachment[] };

    constructor(
        private readonly galleriaService: GalleriaService,
        private readonly downloadFileService: DownloadFileService) {

    }

    downloadAttachment(attachment: IAttachment) {
        this.downloadFileService.downloadBlob(attachment.url).then(downloadedFile => {
            downloadedFile.save();
        });
    }

    openGallery(attachmentId: number) {
        const attachment = this.context.attachments.find(x => x.id === attachmentId);
        const attachments = this.context.attachments.filter(x => FileUtils.isPreviewableInGalleria(x.url)).sortBy(x => x.id);
        const attachmentIndex = attachments.indexOf(attachment);

        const images = attachments.map(x => ({
            source: x.url,
            previewImageSrc: x.url,
            title: x.name,
            alt: x.description
        } as IGalleriaImage));

        this.galleriaService.open(images, attachmentIndex);
        return;
    }
}