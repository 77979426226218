<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingSafetyQuestion ? "safetyQuestion.edit" : "safetyQuestion.create") | translate}}
    </p-header>

    <form [formGroup]="safetyQuestionForm"
          class="m-layout-area-body p-16 m-border-top m-overflow-auto"
          *ngIf="safetyQuestionForm"
          autocomplete="off">

        <!--question-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': safetyQuestionForm.get('question').showErrors }">

            <label class="col-4"
                   for="question">
                {{"safetyQuestion.question" | translate}}
            </label>
            <div class="col-8">
                <input type="text"
                       id="question"
                       class="p-inputtext"
                       formControlName="question"
                       #questionInput />

                <app-form-validation [control]="safetyQuestionForm.get('question')"
                                     [input]="questionInput">
                </app-form-validation>
            </div>
        </div>

        <!-- valid-from -->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': safetyQuestionForm.get('validFrom').showErrors }">
            <label class="col-4"
                   for="validFrom">
                {{"safetyQuestion.validFrom" | translate}}
            </label>
            <div class="col-8">
                <p-calendar formControlName="validFrom"
                            id="validFrom"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [showTime]="false"
                            [minDate]="minDateValue"
                            [maxDate]="safetyQuestionForm.get('validTo')?.value"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            appendTo="body"
                            #validFromInput></p-calendar>

                <app-form-validation [control]="safetyQuestionForm.get('validFrom')"
                                     [input]="validFromInput">
                </app-form-validation>
            </div>
        </div>

        <!-- valid-to -->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': safetyQuestionForm.get('validTo').showErrors }">
            <label class="col-4"
                   for="validTo">
                {{"safetyQuestion.validTo" | translate}}
            </label>
            <div class="col-8">
                <p-calendar formControlName="validTo"
                            id="validTo"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [showTime]="false"
                            [minDate]="safetyQuestionForm.get('validFrom').value ? safetyQuestionForm.get('validFrom').value : minDateValue"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            appendTo="body"
                            #validToInput></p-calendar>

                <app-form-validation [control]="safetyQuestionForm.get('validTo')"
                                     [input]="validToInput">
                </app-form-validation>
            </div>
        </div>

        <!-- owner -->
        <div class="m-form-group row"
             *ngIf="hasMultipleOrganizations"
             [ngClass]="{ 'has-error': safetyQuestionForm.get('ownerId').showErrors }">

            <label class="col-4"
                   for="owner">
                {{"safetyQuestion.owner" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown id="ownerId"
                            [options]="owners"
                            formControlName="ownerId"
                            [panelStyle]="{'width':'100%'}"
                            [autoDisplayFirst]="false"
                            placeholder="{{'safetyQuestion.selectOwners' | translate }}"
                            appendTo="body"
                            #ownerIdInput>
                    <ng-template let-organization
                                 pTemplate="item">
                        <span [ngClass]="organization.styleClass">{{organization.label}}</span>
                    </ng-template>
                </p-dropdown>

                <app-form-validation [control]="safetyQuestionForm.get('ownerId')"
                                     [input]="ownerIdInput">
                </app-form-validation>
            </div>
        </div>
    </form>


    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancelButton">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="saveButton">
            <app-loading-spinner *ngIf="submitting"
                                 id="manage_safetyQuestion_spinner">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>