import { Component, EventEmitter, Output, Input, inject, ViewChild } from "@angular/core";
import { AnalysisType } from "src/app/models/measuring-point";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { AnalysisTypeView, AnalysisTypeViewComponent } from "src/app/modules/shared/components/analysis-type-view/analysis-type-view.component";
import { FilterService } from "src/app/modules/locations/services/filter.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IOrganization } from "src/app/models/user";

@Component({
    selector: "app-types-filter-dialog",
    templateUrl: "./types-filter-dialog.component.html",
})
export class TypesFilterDialogComponent extends DialogComponentBase {
    @ViewChild("analysisTypeViewComponent", {static: false}) analysisTypeViewComponent: AnalysisTypeViewComponent;

    // Inputs
    @Input() multiSelection = true;
    @Input() analysisTypeView = AnalysisTypeView.InUseAnalysisTypes;

    // Outputs
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();
    @Output() saved: EventEmitter<AnalysisType[]> = new EventEmitter<AnalysisType[]>();

    // Variables
    protected currentOrganization: IOrganization
    protected activeFilterTypes: AnalysisType[] = [];

    // Injects
    private readonly filterService = inject(FilterService);
    private readonly globalEventsService = inject(GlobalEventsService);

    constructor() {
        super();
    }

    openFilterDialog(activeFilterTypes: AnalysisType[]): void {
        this.activeFilterTypes = activeFilterTypes.length > 0 ? activeFilterTypes : this.filterService.filterState.types;
        this.currentOrganization = this.globalEventsService.getDefaultOrganization();
        this.visible = true;
    }

    saveSelection() {
        this.saved.emit(this.analysisTypeViewComponent.selectedDialogTypes);
        this.close();
    }

    protected onClose(): void {
        this.closed.emit();
    }
}
