import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IPinnedDataConfigurationPerAnalysisType } from "src/app/models/pinned-data";
import { MeasuringPointPinnedDataWebApi } from "src/app/resource/web";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { LocalStorageService } from "src/app/services/storage.service";
import { SubscriptionManager } from "src/app/utilities";
import { LiveTileComponent } from "../components/live-tile/live-tile.component";
import { MeasuredDataDisplayOptions } from "../components/measured-data-pinned-data/measured-data-pinned-data.component";
import { IMeasuringPointSummary } from "src/app/models/web";

export class LiveTileViewModel {
    public measuringPoint: IMeasuringPointSummary;
    public pinnedDataConfiguration: IPinnedDataConfigurationPerAnalysisType;
    public displayOptions: LiveTileDisplayOptions;
}

export class LiveTileDisplayOptions {
    public position: Position;

    // True means the live tile is an overlay of the map, meaning it moves together with the map.
    // False means the live tile is floating, and does not move when the map moves
    public overlay = true;

    public isExpanded = true;

    public measuredData: MeasuredDataDisplayOptions;
}

export class Position {
    constructor(public x: number, public y: number) { }
}

@Injectable({
    providedIn: "root"
})
export class LiveTilesService {
    constructor(
        private readonly measuringPointPinnedDataApi: MeasuringPointPinnedDataWebApi,
        private readonly measuringPointWebApi: MeasuringPointWebApi,
        private readonly localStorageService: LocalStorageService) {
    }

    private onLiveTilesAddSubject = new Subject<LiveTileViewModel>();
    private readonly onLiveTilesAdd = this.onLiveTilesAddSubject.asObservable();

    private onLiveTilesRemovedSubject = new Subject<LiveTileViewModel>();
    private readonly onLiveTilesRemoved = this.onLiveTilesRemovedSubject.asObservable();

    private liveTileViewModels = new Array<LiveTileViewModel>();
    private liveTileComponents = new Array<LiveTileComponent>();

    subscribeToLiveTiles(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: LiveTileViewModel) => any,
        onRemove: (x: LiveTileViewModel) => any = null) {

        this.unsubscribeFromLiveTiles(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onLiveTilesAdd.subscribe(onAdd);
            subscriptionManager.add("liveTilesAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onLiveTilesRemoved.subscribe(onRemove);
            subscriptionManager.add("liveTilesRemove", selectionRemoveSubscription);
        }
    }

    private unsubscribeFromLiveTiles(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("liveTilesAdd");
        subscriptionManager.remove("liveTilesRemove");
    }

    public addComponent(tile: LiveTileComponent) {
        this.liveTileComponents.push(tile);
        this.recalculateFloatingPositions();
    }

    public removeComponent(toRemove: LiveTileComponent) {
        this.liveTileComponents = this.liveTileComponents.remove(toRemove);
        this.recalculateFloatingPositions();
    }

    public getLiveTileViewModels(): LiveTileViewModel[] {
        return this.liveTileViewModels;
    }

    public async addLiveTileFromId(measuringPointId: number) {
        if (this.containsLiveTile(measuringPointId)) return;

        const measuringPoint = await this.measuringPointWebApi.get(measuringPointId);
        if (!measuringPoint) return;

        this.addLiveTileFromSummary(measuringPoint);
    }

    public addLiveTileFromSummary(data: IMeasuringPointSummary): void {
        if (this.containsLiveTile(data.id)) return;

        this.measuringPointPinnedDataApi.getConfiguration$(data.id).subscribe(
            {
                next: result => {
                    const vm = new LiveTileViewModel();
                    vm.measuringPoint = data;
                    vm.pinnedDataConfiguration = result;
                    vm.displayOptions = this.getFromLocalStorage(data.id);

                    this.liveTileViewModels.push(vm);
                    this.onLiveTilesAddSubject.next(vm);
                }
            }
        );
    }

    public removeLiveTileId(id: number) {
        const vm = this.liveTileViewModels.find(x => x.measuringPoint.id === id);
        if (!vm) return;

        this.removeLiveTile(vm);
    }

    private removeLiveTile(vm: LiveTileViewModel) {
        this.liveTileViewModels = this.liveTileViewModels.remove(vm);
        this.onLiveTilesRemovedSubject.next(vm);

        this.saveToLocalStorage();
    }

    // Arranges the floating tiles in a grid at the right of the screen
    public recalculateFloatingPositions() {
        const maxPerRow = 3;
        let index = 0;

        const columnTiles = new Array<LiveTileComponent>();

        for (const tileComponent of this.liveTileComponents) {
            if (tileComponent.viewModel.displayOptions.overlay) continue;
            if (tileComponent.positionModified || tileComponent.overlay) continue;
            const row = index % maxPerRow;
            const column = Math.floor(index / maxPerRow);

            if (row === 0) {
                columnTiles.length = 0;
            }

            const topOffset = columnTiles.map(x => x.divElement.nativeElement.offsetHeight).sum();

            tileComponent.ngStyle = {
                "top": (80 + (row * 10) + topOffset) + "px",
                "right": (10 + (column * 255)) + "px"
            };

            columnTiles.push(tileComponent);

            index++;
        }
    }

    private containsLiveTile(id: number): boolean {
        return !!this.liveTileViewModels.find(x => x.measuringPoint.id === id);
    }

    public saveToLocalStorage() {
        // Remove live tiles that are not displayed anymore
        for (const data of this.localStorageService.getAll()) {
            if (data.key.startsWith("tileOptions-")) this.localStorageService.removeItem(data.key);
        }

        for (const vm of this.liveTileViewModels) {
            this.localStorageService.setItem(`tileOptions-${vm.measuringPoint.id}`, JSON.stringify(vm.displayOptions));
        }
    }

    private getFromLocalStorage(measuringPointId: number): LiveTileDisplayOptions {
        const data = this.localStorageService.getItem(`tileOptions-${measuringPointId}`);
        if (data) {
            const result = JSON.parse(data) as LiveTileDisplayOptions;
            return result;
        }

        // We have no data
        const result = new LiveTileDisplayOptions();
        result.position = new Position(0, 0);
        return result;
    }

    public loadFromLocalStorage() {
        for (const data of this.localStorageService.getAll()) {
            if (!data.key.startsWith("tileOptions-")) continue;

            const measuringPointId = parseInt(data.key.split("-")[1]);

            this.addLiveTileFromId(measuringPointId);
        }
    }
}
