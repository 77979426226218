import { IJournalCategory, JournalCategorySaver } from "src/app/models/journal";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { JournalCategoryApi } from "src/app/resource/journal-category.api";
import { SigncoFormGroup } from "src/app/models/form";
import { Component } from "@angular/core";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-manage-journal-category-dialog",
    templateUrl: "./manage-journal-category-dialog.component.html"
})
export class ManageJournalCategoryDialogComponent extends DialogComponentBase {

    submitting: boolean;
    contextTypeId: string;
    callback: (res: IJournalCategory) => void;
    manageCategoryForm: SigncoFormGroup;
    existingCategory: IJournalCategory;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly journalCategoryApi: JournalCategoryApi,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly domainDataService: DomainDataService) {

        super();
    }

    create(contextTypeId: string, callback?: (result: IJournalCategory) => void) {
        this.contextTypeId = contextTypeId;
        this.callback = callback;
        this.existingCategory = null;

        this.openDialog();
    }

    edit(existingCategory: IJournalCategory, callback?: (result: IJournalCategory) => void) {
        this.callback = callback;
        this.existingCategory = existingCategory;

        this.openDialog();
    }

    protected async onOpen() {
        const translationFormGroup = await this.domainDataService.createTranslationFormGroup(this.existingCategory ? this.existingCategory.codeStringResourceId : null, true);

        this.manageCategoryForm = this.formBuilder.group({
            codeStringResource: translationFormGroup,
            description: [""],
            contextTypeId: this.existingCategory ? this.existingCategory.contextTypeId : this.contextTypeId,
            isMaas: false
        }) as SigncoFormGroup;

        if (this.existingCategory) {
            this.manageCategoryForm.patchValue(this.existingCategory);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageCategoryForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageCategoryForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedCategory: IJournalCategory) => {
            // Reload translations
            this.domainDataService.reload();

            if (this.existingCategory) {
                Object.assign(this.existingCategory, savedCategory);
            }

            if (this.callback) {
                this.callback(savedCategory);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingCategory) {
            const newCategory = Object.assign(new JournalCategorySaver(), this.manageCategoryForm.value);
            this.journalCategoryApi.create$(newCategory).subscribe(onSuccess, onError);
        } else {
            const updatedCategory = Object.assign(this.existingCategory, this.manageCategoryForm.value);
            this.journalCategoryApi.update$(updatedCategory).subscribe(onSuccess, onError);
        }
    }
}
