import { ApiTableComponent, TableColumn, FilterType, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, FilterDescriptor, SortDescriptor, SortDirection } from "src/app/models/search";
import { DomainDataService, DomainData, ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { Component, Input, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ManageReleaseDialogComponent } from "../manage-release-dialog/manage-release.dialog";
import { IRelease, ReleaseActivator } from "src/app/models/release";
import { TranslateService } from "@ngx-translate/core";
import { DownloadedFile } from "src/app/services/download-file.service";
import { ModalService } from "src/app/services/modal.service";
import { ReleaseApi } from "src/app/resource/release.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-releases",
    templateUrl: "./releases.component.html"
})
export class ReleasesComponent extends ApiTableComponent<IRelease> implements OnInit {
    @ViewChild(ManageReleaseDialogComponent, { static: true }) manageReleaseDialog: ManageReleaseDialogComponent;

    @Input() packageId: string;
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() downloadCommand = true;

    canCreateRelease = false;

    releaseChannelColumns: TableColumn[];

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly releaseApi: ReleaseApi,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly domainDataService: DomainDataService) {

        super("releases.component", elementRef, releaseApi, tableService);

        this.selectionMode = "";
        this.stretchHeight = true;

        this.canCreateRelease = this.rights?.hasBackendRight(BackendRights.EditRelease);

        this.addColumn(new TableColumn("version", "releases.version", { filterType: FilterType.Text, sortable: true, width: 150, resizable: false }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("fileSize", "general.fileSize", { width: 80, resizable: false }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true, width: 150 }));

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (release) => this.edit(release),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "general.download",
            icon: "download",
            click: (release) => this.download(release),
            validFunc: () => this.downloadCommand
        });
    }

    async ngOnInit() {
        super.ngOnInit();

        await this.createReleaseChannelColumns();

        this.loadTableRows();
    }

    private async createReleaseChannelColumns() {
        const enumOptions = new ViewModelEnumOptions();
        enumOptions.orderBy = null;
        const releaseChannelViewModels = await this.domainDataService.get(DomainData.ReleaseChannel, enumOptions);

        for (const releaseChannelViewModel of releaseChannelViewModels) {
            const column = new TableColumn(releaseChannelViewModel.value, releaseChannelViewModel.label, { sortable: false, type: ColumnType.Checkbox, width: 70 });
            column.ngStyle["text-align"] = "center";
            this.addColumn(column, 3);
        }
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.includeObsolete = false;

        if (this.packageId) {
            searchParameters.filter = [
                new FilterDescriptor("PackageId", this.packageId),
                new FilterDescriptor("IsSpecificRelease", false),
            ];
        }

        searchParameters.sort = [
            new SortDescriptor(SortDirection.descending, "CreateDate")
        ];

        return searchParameters;
    }

    create() {
        this.manageReleaseDialog.create(this.packageId, () => {
            this.reload();
        });
    }

    edit(existing: IRelease) {
        this.manageReleaseDialog.edit(existing, () => {
            this.reload();
        });
    }

    activate(release: IRelease, releaseChannelId: string): boolean {
        const onActivateSuccess = () => {
            this.reload();
        };

        const onActivate = () => {
            release.channelStatus[releaseChannelId] = !release.channelStatus[releaseChannelId];
            const releaseActivator = new ReleaseActivator(release, releaseChannelId);

            this.releaseApi.activate$(releaseActivator).subscribe(onActivateSuccess, () => { });
        };

        const modalBody = this.translate.instant("releases.activateConfirmation", { version: release.version });
        this.modalService.confirm(modalBody, onActivate);
        return false; // We have to return false to stop the checkbox from enabling
    }

    download(release: IRelease) {
        const onDownload = (downloadedFile: DownloadedFile) => {
            downloadedFile.save();
        };

        const onError = () => { };

        this.releaseApi.download$(release.id).then(onDownload, onError);
    }
}