<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"totemDisplayConfiguration.configureFieldsTitle" | translate}}
    </p-header>

    <div *ngIf="visible"
         class="p-16"
         style="display: grid; gap:16px;">
        <div>
            {{"totemDisplayConfiguration.configureFieldsDescription" | translate}}
        </div>
        <div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{{"general.name" | translate}}</th>
                        <th>{{"totemDisplayConfiguration.lineLength" | translate}}</th>
                        <th>{{"totemDisplayConfiguration.lineCount" | translate}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let field of totemDisplayConfiguration.fields; trackBy: trackTextFieldFn;">
                        <td>
                            <btn class="m-btn m-btn-secondary"
                                 (click)="removeField(field)"><app-svg-icon icon="delete"></app-svg-icon></btn>
                        </td>
                        <td><input [(ngModel)]="field.name"
                                   class="p-inputtext"
                                   type="text"
                                   (change)="hasChanged = true" /></td>
                        <td><input [(ngModel)]="field.lineLength"
                                   class="p-inputtext"
                                   style="width:50px;"
                                   type="number"
                                   min="1"
                                   (change)="hasChanged = true" /></td>
                        <td><input [(ngModel)]="field.lineCount"
                                   class="p-inputtext"
                                   style="width:50px;"
                                   type="number"
                                   min="1"
                                   (change)="hasChanged = true" /></td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td> <button class="m-btn m-btn-secondary"
                                    (click)="addField()">
                                <app-svg-icon icon="add"></app-svg-icon>
                                {{"totemDisplayConfiguration.addField" | translate}}
                            </button></td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancel_button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="save_button">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>