import { Component, ChangeDetectorRef } from "@angular/core";
import { ImportedKeysComponent } from "../imported-keys/imported-keys.component";
import { SharedKeysComponent } from "../shared-keys/shared-keys.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";

type SharePageComponents =
    SharedKeysComponent |
    ImportedKeysComponent;

@Component({
    selector: "app-share-page",
    templateUrl: "./share-page.component.html"
})
export class SharePageComponent extends TabBase<SharePageComponents> {

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        this.addTab({ component: SharedKeysComponent, url: "keys", translatePath: "sharedKeys.title", icon: "key" });
        this.addTab({ component: ImportedKeysComponent, url: "imported", translatePath: "importedSharedKeys.title", icon: "share" });

        this.updateActiveComponent();
    }
}