import { Component, inject, Input, OnInit } from "@angular/core";
import { ITextFieldConfiguration, ITextFieldContent, ITextFrame } from "src/app/models/totem-display-configuration";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { firstValueFrom } from "rxjs";

class AnimatedFieldViewModel {
    fieldConfiguration: ITextFieldConfiguration;
    frames: FrameViewModel[];
}

class FrameViewModel {
    text: string;
    displayTimeMs: number;
}

@Component({
    selector: "app-text-field-animation",
    styleUrls: ["./text-field-animation.component.scss"],
    templateUrl: "./text-field-animation.component.html",
    imports: [
        SharedModule
    ],
    standalone: true
})
export class TextFieldAnimationComponent implements OnInit {
    @Input() content: ITextFieldContent;
    @Input() configuration: ITextFieldConfiguration;
    @Input() isExpanded: boolean;

    viewModel: AnimatedFieldViewModel;
    currentIndex: number = 0;
    timeout: NodeJS.Timeout;
    isLoading: boolean = true;

    private readonly measuringPointsApi = inject(MeasuringPointApi);

    async ngOnInit() {
        await this.loadViewModel(this.content.frames);
    }

    async loadViewModel(frames: ITextFrame[]) {
        this.isLoading = true;

        this.viewModel = new AnimatedFieldViewModel();
        this.viewModel.fieldConfiguration = this.configuration
        this.viewModel.frames = [];

        for (const frame of frames) {
            const frameVm = new FrameViewModel();
            frameVm.text = await this.formatString(frame.text);
            frameVm.displayTimeMs = frame.displayTimeMs;
            this.viewModel.frames.push(frameVm);
        }

        if (this.viewModel.frames.length > 1) {
            clearTimeout(this.timeout);
            this.nextFrame(0);
        } else {
            this.currentIndex = 0;
        }

        this.isLoading = false;
    }

    nextFrame(index: number) {
        if (typeof index !== "number") index = 0;
        if (this.viewModel.frames.length === 0) return;
        this.currentIndex = index;

        const frame = this.viewModel.frames[index];
        if (!frame) {
            this.nextFrame(index);
            return;
        }

        this.timeout = setTimeout(() => {
            index = (index + 1) % this.viewModel.frames.length;
            this.nextFrame(index);
        }, frame.displayTimeMs < 100 ? 100 : frame.displayTimeMs);
    }

    moveLeft() {
        clearTimeout(this.timeout);
        const index = (this.currentIndex - 1 + this.viewModel.frames.length) % this.viewModel.frames.length;
        this.nextFrame(index);
    }

    moveRight() {
        clearTimeout(this.timeout);
        const index = (this.currentIndex + 1) % this.viewModel.frames.length;
        this.nextFrame(index);
    }

    async formatString(input: string) {
        const response = await firstValueFrom(this.measuringPointsApi.previewTotemDisplay$(input, this.viewModel.fieldConfiguration.lineCount, this.viewModel.fieldConfiguration.lineLength))
            .catch(() => ({text: "N/A"}));
        return response.text ?? "N/A";
    }

    formatHtml(text: string) {
        return text.replace(/\r\n/g, "<br>");
    }
}
