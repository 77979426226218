<div class="m-btn-group m-btn-group-right">
    <button class="m-btn m-btn-secondary"
            (click)="create()"
            *ngIf="createCommand">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"datePeriod.create" | translate}}
    </button>
</div>

<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [customSort]="selectionBox"
         (sortFunction)="customSort($event)"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-datePeriod>
        <tr [pSelectableRow]="datePeriod">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'typeId'">
                    <app-domain-data-field [enumKey]="datePeriod[column.field]"
                                           domainDataType="datePeriodType">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'dates'">
                    <ng-container *ngFor="let date of datePeriod.dates; let last = last; let i = index;">
                        <span style="font-size: x-small;">
                            {{date | momentDate:false }}
                        </span>

                        <ng-container *ngIf="i + 1 !== datePeriod.dates.length">, </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(datePeriod)"
                                             *ngIf="command.rowValidFunc(datePeriod)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{datePeriod[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>

<app-manage-date-period-dialog></app-manage-date-period-dialog>