import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { CacheService } from "../services/cache.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(
        private readonly cacheService: CacheService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method !== "GET") return next.handle(request);

        const onSuccess = (response: any) => {
            if (response instanceof HttpResponse && response.status === 200 && !request.url.startsWith("./asset")) {
                this.cacheService.set(request, response);
            }

            return response;
        };

        return next.handle(request).pipe(tap(onSuccess));
    }
}