<details class="m-dropdown"
         (clickOutside)="closeNotificationDropdown()"
         #notificationsdropdown>
    <summary class="m-dropdown-summary"
             (click)="markNotificationsAsRead()">
        <span class="m-account-item">
            <app-svg-icon icon="bell" />
            <ng-container *ngIf="(unreadNotificationsCount + unreadStickyNotificationsCount) > 0">
                <span class="m-alert-badge">
                    <small>{{unreadNotificationsCount + unreadStickyNotificationsCount}}</small>
                </span>
            </ng-container>
        </span>
    </summary>

    <div class="m-dropdown-content"
         style="right: 0px;">
        <div class="m-card m-shadow m-layout-w-actions-bottom"
             style="min-width: 240px; max-height: 320px;">
            <div class="m-layout-area-body m-overflow-auto">
                <ng-container *ngFor="let stickyNotification of stickyNotifications; let last = last">
                    <div class="m-alert p-16"
                         [ngClass]="{'is-unread': !stickyNotification.seenOn}">
                        <div class="m-alert-level">
                            <app-svg-icon icon="circle-info"
                                          divClass="icon-16"
                                          *ngIf="stickyNotification.levelId === 'information'"
                                          [ngStyle]="{'fill': '#1387F1'}" />
                            <app-svg-icon icon="circle-exclamation"
                                          divClass="icon-16"
                                          *ngIf="stickyNotification.levelId === 'warning'"
                                          [ngStyle]="{'fill': '#F1C013'}" />
                            <app-svg-icon icon="circle-xmark"
                                          divClass="icon-16"
                                          *ngIf="stickyNotification.levelId === 'error'"
                                          [ngStyle]="{'fill': '#EE3535'}" />
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{stickyNotification.createDate | momentDateTime:true:false:translate.currentLang}}</small>
                            </p>

                            <ng-container *ngIf="stickyNotification.message">
                                <p class="m-alert-message"
                                   [innerHtml]="stickyNotification.message| translateMessage:stickyNotification.categoryId">
                                </p>

                                <p>
                                    <a class="m-navigator"
                                       [routerLink]="[stickyNotification.message.relativeUrl]"
                                       [queryParams]="addExtraQueryParameters(stickyNotification.message.parsedQueryParameters)">
                                        <small>{{"notifications.viewOnMap"|translate}}</small>
                                    </a>
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    <hr class="my-0"
                        *ngIf="!last" />
                </ng-container>
                <ng-container *ngFor="let notification of notifications; let last = last">
                    <div class="m-alert p-16"
                         [ngClass]="{'is-unread': !notification.seenOn}">
                        <div class="m-alert-level">
                            <app-svg-icon icon="circle-info"
                                          divClass="icon-16"
                                          *ngIf="notification.levelId === 'information'"
                                          [ngStyle]="{'fill': '#1387F1'}" />
                            <app-svg-icon icon="circle-exclamation"
                                          divClass="icon-16"
                                          *ngIf="notification.levelId === 'warning'"
                                          [ngStyle]="{'fill': '#F1C013'}" />
                            <app-svg-icon icon="circle-x"
                                          divClass="icon-16"
                                          *ngIf="notification.levelId === 'error'"
                                          [ngStyle]="{'fill': '#EE3535'}" />
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{notification.createDate | momentDateTime:true:false:translate.currentLang}}</small>
                            </p>

                            <ng-container *ngIf="notification.message">
                                <p class="m-alert-message"
                                   [innerHtml]="notification.message| translateMessage">
                                </p>

                                <p>
                                    <a class="m-navigator"
                                       [routerLink]="[notification.message.relativeUrl]"
                                       [queryParams]="addExtraQueryParameters(notification.message.parsedQueryParameters)">
                                        <small>{{"notifications.readMore"|translate}}</small>
                                    </a>
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    <hr class="my-0"
                        *ngIf="!last" />
                </ng-container>
            </div>

            <div class="m-layout-area-bottom">
                <a [routerLink]="['/notifications']"
                   (click)="closeNotificationDropdown()"
                   class="m-alert-showall m-navigator">
                    {{"notifications.viewAll"|translate}}</a>

                <a *ngIf="showMock"
                   (click)="createMockNotification()"
                   class="m-alert-showall m-navigator">
                    Create Mock Notification
                </a>
            </div>
        </div>
    </div>
</details>
