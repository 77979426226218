import { ApiServiceBase } from "../api";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DownloadFileService } from "src/app/services/download-file.service";
import { UploadFileService } from "src/app/services/upload-file.service";
import { CacheService } from "src/app/services/cache.service";
import { ErrorService } from "src/app/services/error.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IKnowledgeBaseElement } from "src/app/models/article";

@Injectable({
    providedIn: "root"
})
export class DocumentationApiService extends ApiServiceBase {

    constructor(
        http: HttpClient,
        downloadFileService: DownloadFileService,
        uploadFileService: UploadFileService,
        cacheService: CacheService,
        errorService: ErrorService,
        configurationService: ConfigurationService) {

        super(http, downloadFileService, uploadFileService, cacheService, errorService, configurationService);
    }

    get$(article: string): Observable<any> {
        const url = this.configurationService.configuration.url + "/documentation/articles/" + article;

        return this.http.get(url, { responseType: "text" });
    }

    getTree$(): Observable<IKnowledgeBaseElement[]> {
        const url = this.configurationService.configuration.url + "/documentation/articles/tree";
        return this.http.get<IKnowledgeBaseElement[]>(url);
    }
}