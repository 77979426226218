import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { DataRoutingModule } from "./data-routing.module";
import { DataTabComponent } from "./components/data-tab.component";
import { ValidationModule } from "../validation/validation.module";

@NgModule({
    imports: [
        DataRoutingModule,
        SharedModule,
        ValidationModule,
    ],
    declarations: [
        DataTabComponent
    ]
})
export class DataModule { }