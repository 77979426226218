import { LazyTableComponent, TableService, TableColumn, FilterType, ColumnVisibility, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, ElementRef, Input, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { FilterDescriptor, ISearchResult, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { JournalDialogComponent } from "./../journal-dialog/journal.dialog";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { JournalApiService } from "src/app/resource/journal.api";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { GalleriaService } from "src/app/services/galleria.service";
import { AttachmentUtils, FileUtils } from "src/app/utilities";
import { ActivatedRoute } from "@angular/router";
import { IGalleriaImage } from "../galleria/galleria.component";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { IOrganization } from "src/app/models/user";
import { ToastService } from "src/app/services/toast.service";
import { ModalService } from "src/app/services/modal.service";
import { IAssignment } from "src/app/models/assignment";
import { IAttachment } from "src/app/models/attachment";
import { IProject } from "src/app/models/project";
import { IJournal } from "src/app/models/journal";
import { IDevice } from "src/app/models/device";
import { DomainDataService } from "src/app/services/domain-data.service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-journal",
    templateUrl: "./journal.component.html"
})
export class JournalComponent extends LazyTableComponent<IJournal> implements OnInit, OnChanges {
    @ViewChild(JournalDialogComponent, { static: false }) journalDialog: JournalDialogComponent;

    @Input() measuringPoint: IMeasuringPoint;
    @Input() device: IDevice;
    @Input() organization: IOrganization;
    @Input() project: IProject;
    @Input() assignment: IAssignment;
    @Input() createCommand = true;
    @Input() shareCommand = true;
    @Input() downloadCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    readonly = false;
    canEdit = false;
    canDelete = false;
    canManageAdminJournal = false;
    timestampColumn: TableColumn;
    categoriesColumn: TableColumn;
    projectColumn: TableColumn;
    includeAllColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly journalApi: JournalApiService,
        readonly translateService: TranslateService,
        private readonly route: ActivatedRoute,
        private readonly modalService: ModalService,
        private readonly toastService: ToastService,
        private readonly attachmentApi: AttachmentApi,
        private readonly galleriaService: GalleriaService,
        private readonly navigationService: NavigationService,
        private readonly accessibilityService: AccessibilityService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly domainDataService: DomainDataService) {

        super("journal.component", elementRef, journalApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;
        this.footer = false;

        this.canManageAdminJournal = this.rights.hasBackendRight(BackendRights.ManageAdminJournal);

        this.timestampColumn = new TableColumn("timestamp", "journal.timestamp", { sortable: false, filterType: FilterType.Date, width: 160, resizable: false });
        this.addColumn(this.timestampColumn);
        this.addColumn(new TableColumn("creator", "general.creator", { visibility: ColumnVisibility.HideCompact, filterType: FilterType.None, sortable: false }));

        // categories
        this.categoriesColumn = new TableColumn("journalCategory", "journal.category", { sortable: false, filterType: FilterType.MultiSelect });
        this.addColumn(this.categoriesColumn);

        // subCategories
        const subCategoriesColumn = new TableColumn("journalSubCategory", "journal.subCategory", { sortable: false, filterType: FilterType.MultiSelect });
        this.domainModelFilterService.getJournalSubCategories$().then(journalSubCategories => {
            subCategoriesColumn.filterOptions = journalSubCategories;
        });
        this.addColumn(subCategoriesColumn);

        this.projectColumn = new TableColumn("project", "general.project", { sortable: true, filterType: FilterType.MultiSelect, displayDropdownFilter: true });
        this.domainModelFilterService.getProjects$().then(projects => {
            this.projectColumn.filterOptions = projects;
        });
        this.addColumn(this.projectColumn);

        this.includeAllColumn = new TableColumn("includeAll", "journal.includeAll", { sortable: false, filterType: FilterType.Boolean, type: ColumnType.BoolCheckbox });
        this.addColumn(this.includeAllColumn);

        this.addColumn(new TableColumn("remarks", "journal.remarks", { sortable: false, filterType: FilterType.Text, resizable: true }));


        if (this.canManageAdminJournal) {
            this.addColumn(new TableColumn("adminRemarks", "journal.adminRemarks", { sortable: false, visibility: ColumnVisibility.HideCompact, filterType: FilterType.Text, resizable: true }));
            this.addColumn(new TableColumn("isAdminOnly", "auditTrails.isAdminOnlyColumnHeader", { sortable: false, visibility: ColumnVisibility.HideCompact, tooltip: "auditTrails.isAdminOnlyColumnHeaderTooltip", filterType: FilterType.Boolean, width: 80 }));
        }
        this.addColumn(new TableColumn("attachments", "attachments.title", { sortable: false, filterType: FilterType.None, width: 100, resizable: false }));

        this.registerCommand({
            text: "shareData.title",
            icon: "share",
            click: (journal) => this.share(journal),
            validFunc: () => this.shareCommand && !this.readonly
        });

        this.registerCommand({
            text: "categories.edit",
            icon: "edit",
            click: (journal) => this.edit(journal),
            validFunc: () => this.editCommand && !this.readonly && this.canEdit,
            rowValidFunc: (journal) => this.services.globalEventsService.assertAccessToUser(journal.creator)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (journal) => this.delete(journal),
            validFunc: () => this.deleteCommand && !this.readonly && this.canDelete,
            rowValidFunc: (journal) => this.services.globalEventsService.assertAccessToUser(journal.creator)
        });

        this.subscriptionManager.add("onSelect", this.selected.subscribe((journal: IJournal) => {
            this.show(journal);
        }));
    }

    ngOnInit() {
        super.ngOnInit();

        const sub = this.route
            .queryParams
            .subscribe(params => {
                const journalIdToOpen = params["id"];
                if (!journalIdToOpen) return;

                const onJournalLoad = (journal: IJournal) => {
                    this.show(journal);
                };

                const onJournalError = () => {
                    this.toastService.error("journal.unableToLoad");
                };

                this.journalApi.get$(journalIdToOpen, null, this.getServiceRequestOptions()).subscribe(onJournalLoad, onJournalError);
            });

        this.subscriptionManager.add("queryParamsSub", sub);
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        const organizationChange = changes["organization"];
        if (organizationChange) {
            this.setOrganization(this.organization);
        }

        const projectChange = changes["project"];
        if (projectChange) {
            this.setProject(this.project);
        }

        const assignmentChange = changes["assignment"];
        if (assignmentChange) {
            this.setAssignment(this.assignment);
        }

        super.ngOnChanges(changes);
    }

    private clear() {
        this.device = null;
        this.organization = null;
        this.measuringPoint = null;
        this.project = null;
        this.assignment = null;
    }
    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.clear();
        this.measuringPoint = measuringPoint;
        this.readonly = this.measuringPoint && this.measuringPoint.readonly;
        this.canEdit = this.rights.hasBackendRight(BackendRights.EditInstallationJournal);
        this.canDelete = this.rights.hasBackendRight(BackendRights.DeleteInstallationJournal);

        if (this.measuringPoint) {
            (this.measuringPoint as any).isMeasuringPoint = true;
        }

        this.loadCategories();
        this.loadTableRows();
    }

    setDevice(device: IDevice) {
        this.clear();
        this.device = device;
        this.readonly = false;
        this.canEdit = this.rights.hasBackendRight(BackendRights.EditDeviceJournal);
        this.canDelete = this.rights.hasBackendRight(BackendRights.DeleteDeviceJournal);

        if (this.device) {
            (this.device as any).isDevice = true;
        }

        this.loadCategories();
        this.loadTableRows();
    }

    setOrganization(organization: IOrganization) {
        this.clear();
        this.organization = organization;
        this.canEdit = this.rights.hasBackendRight(BackendRights.EditOrganizationJournal);
        this.canDelete = this.rights.hasBackendRight(BackendRights.DeleteOrganizationJournal);

        if (this.organization) {
            (this.organization as any).isOrganization = true;
        }

        this.loadCategories();
        this.loadTableRows();
    }

    setProject(project: IProject) {
        this.clear();
        this.project = project;

        this.canEdit = this.rights.hasBackendRight(BackendRights.EditProjectJournal);
        this.canDelete = this.rights.hasBackendRight(BackendRights.DeleteProjectJournal);

        if (this.project) {
            (this.project as any).isProject = true;
        }

        this.loadCategories();
        this.loadTableRows();
    }

    setAssignment(assignment: IAssignment) {
        this.clear();
        this.assignment = assignment;
        this.canEdit = this.rights.hasBackendRight(BackendRights.EditAssignmentJournal);
        this.canDelete = this.rights.hasBackendRight(BackendRights.DeleteAssignmentJournal);

        if (this.assignment) {
            (this.assignment as any).isAssignment = true;
        }

        this.loadCategories();
        this.loadTableRows();
    }

    private loadCategories() {
        if (!this.canLoad()) return;

        this.projectColumn.hidden = !!this.project;
        this.updateRelevantColumns();

        const type = this.device ? "device" :
            this.measuringPoint ? "measuringPoint" :
                this.organization ? "organization" :
                    this.assignment ? "assignment" : "project";
        this.domainModelFilterService.getJournalCategories$(type).then(journalCategories => {
            this.categoriesColumn.filterOptions = journalCategories;
        });
    }

    canLoad(): boolean {
        return !!this.measuringPoint || !!this.device || !!this.organization || !!this.assignment || !!this.project;
    }

    getRouteParams(): { [index: string]: string } {
        if (this.measuringPoint) return { type: "measuringPoint", id: this.measuringPoint.id.toString() };
        if (this.device) return { type: "device", id: this.device.id.toString() };
        if (this.project) return { type: "project", id: this.project.id.toString() };
        if (this.assignment) return { type: "assignment", id: this.assignment.id.toString() };
        return { type: "organization", id: this.organization.id.toString() };
    }

    get canSetProject(): boolean {
        return !this.project && !this.assignment;
    }

    private get isMaas(): boolean {
        return !!this.assignment || (this.project?.isMaasProject && !this.project?.isMeasurementProject);
    }

    private get canSetSubCategory(): boolean {
        return !this.project && !this.assignment;
    }

    addNew() {
        this.journalDialog.create(this.measuringPoint || this.device || this.organization || this.project || this.assignment, () => this.reload(), this.canSetProject, this.canSetSubCategory, this.isMaas);
    }

    show(journal: IJournal) {
        const onClose = () => {
            this.clearSelection();
        };

        this.journalDialog.show(journal, onClose, this.canSetProject, this.canSetSubCategory);
    }

    edit(journal: IJournal) {
        this.journalDialog.edit(journal, this.measuringPoint || this.device || this.organization || this.project || this.assignment, () => this.reload(), this.canSetProject, this.canSetSubCategory, this.isMaas);
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("Journal", "Attachments");
        return options;
    }

    async processLoadedData(journals: IJournal[]): Promise<IJournal[]> {
        for (const journal of journals) {
            // Used for navigation to journal
            if (this.device) {
                journal.device = this.device;
            }

            AttachmentUtils.processLoadedAttachments(journal.attachments);
        }

        return journals;
    }

    openGallery(journal: IJournal) {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("journalId", journal.id)];

        const onSuccess = (attachments: ISearchResult<IAttachment>) => {
            const images = attachments.data.filter(x => FileUtils.isPreviewableInGalleria(x.url)).sortBy(x => x.id).map(x => ({
                source: x.url,
                previewImageSrc: x.url,
                title: x.name,
                alt: x.description
            } as IGalleriaImage));

            this.galleriaService.open(images);
        };

        const onError = () => { };

        this.attachmentApi.search$(searchParameters).subscribe(onSuccess, onError);
    }

    share(journal: IJournal) {
        const url = this.navigationService.getJournalUrl(journal);
        this.accessibilityService.copyToClipboard(url);
    }

    async delete(journal: IJournal) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.journalApi.delete$(journal.id, this.getRouteParams()).subscribe(onDeleteSuccess);
        };

        const modalBody = this.translateService.instant("journal.deleteConfirmation",
            { category: this.domainDataService.translate(journal.category?.codeStringResourceId), subCategory: this.domainDataService.translate(journal.subCategory?.codeStringResourceId || "") });
        this.modalService.delete(modalBody, onDelete);
    }

    onFileDropped(fileList: FileList) {
        this.journalDialog.create(this.measuringPoint || this.device || this.organization || this.project || this.assignment, () => this.reload(), this.canSetProject, this.canSetSubCategory, this.isMaas, fileList);
    }
}