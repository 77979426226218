<details class="m-dropdown"
         #popup
         (clickOutside)="closePopup()">

    <summary class="m-dropdown-summary">
        <div class="m-map-search-input">
            <input type="text"
                   id="inputSearchBox"
                   [(ngModel)]="searchTerm"
                   (ngModelChange)="handleSearchTermChange($event)"
                   (click)="openPopup()"
                   placeholder="Search"
                   #inputElement />

            <button *ngIf="searchTerm"
                    id="buttonClearSearchBox"
                    class="m-map-search-icon"
                    (click)="clearSearchterm()">
                <i class="pi pi-times"></i>
            </button>

            <span *ngIf="!searchTerm"
                  class="m-map-search-icon">
                <i class="pi pi-search"></i>
            </span>
        </div>
    </summary>

    <div class="m-dropdown-content"
         style="left: 0px; top: calc(100% - 1px)">

        <div class="m-card m-shadow search-box p-16">
            <div class="search-box__locations">
                <h4 class="mb-8">{{"mapSearch.locations" | translate}}</h4>

                <div class="search-box__results">
                    <div *ngFor="let location of locations; trackBy: locationTrackBy"
                         (click)="handleLocationClicked(location)"
                         class="search-box__result">
                        <img [src]="getIconUrl(location)" />
                        <div>{{location.code}}</div>
                    </div>
                </div>

                <app-loading-spinner *ngIf="isLoadingLocations"></app-loading-spinner>
            </div>

            <div class="search-box__places">
                <h4 class="mb-8">{{"mapSearch.places" | translate}}</h4>

                <div class="search-box__results">
                    <div *ngFor="let place of places; trackBy: locationTrackBy"
                         (click)="handlePlaceClicked(place)"
                         class="search-box__result">
                        <app-svg-icon icon="marker"
                                      fill="gray"></app-svg-icon>
                        <div>{{place.description}}</div>
                    </div>
                </div>

                <app-loading-spinner *ngIf="isLoadingPlaces"></app-loading-spinner>
            </div>

            <div class="search-box__addtofilter">
                <h4 class="mb-8">{{"mapSearch.locationCodeFilter" | translate}}</h4>

                <button class="m-map-filters-chip gap-2 pr-0"
                        id="buttonFilterLocationCode"
                        (click)="handleCreateFilterClicked()">
                    {{searchTerm}}
                    <app-svg-icon icon="add"></app-svg-icon>
                </button>
            </div>
        </div>
    </div>
</details>