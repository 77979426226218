<!--TODO: cleanup CSS-->
<style>
    .context-menu {
        position: absolute;
        background-color: white;
        z-index: 1000;
    }
</style>

<div [ngClass]="{'m-page-content-wrapper': !inPopup}"
     [ngStyle]="inPopup && {'height': '100%'}"
     class="m-vms-designer">
    <!-- flex navbar-->
    <app-vms-image-editor-toolbar class="m-vms-designer-navbar"></app-vms-image-editor-toolbar>
    <!--CSS Grid-->
    <!--Id necessary!-->
    <div class="m-vms-designer-canvas p-16">
        <div class="m-vms-designer-canvas-wrapper"
             id="canvasContainerBody">

            <!-- Add the context menu to the HTML template -->
            <div class="context-menu"
                 *ngIf="contextMenuVisible"
                 [style.left.px]="contextMenuX"
                 [style.top.px]="contextMenuY">
                <app-icon-button icon="delete"
                                 (click)="this.vmsImageEditorService.deleteActiveObjects()"></app-icon-button>
            </div>

            <canvas id="imageEditorCanvas"
                    #canvas></canvas>

            <div *ngIf="currentVmsVariant && currentVmsVariant.url && !currentVmsVariant.editorData
             && !currentVmsVariant.file"> <!--style="height:100%"-->
                <img [src]="currentVmsVariant.url" />
            </div>

            <div *ngIf="currentVmsVariant && currentVmsVariant.file"> <!--style="height:100%"-->
                <img [attr.src]="filePreview | safeHtml" />
            </div>
        </div>
    </div>

    <div class="m-vms-designer-sidebar">

        <div class="m-vms-designer-device">
            <ng-container *ngIf="!inPopup">
                <div class="pr-16 pl-16">
                    <div *ngIf="displayOrganizations">
                        <h3>{{"general.organization" | translate}}</h3>

                        <div class="mt-8">
                            <p-dropdown id="organizationId"
                                        [options]="organizations"
                                        [(ngModel)]="selectedOrganization"
                                        [autoDisplayFirst]="false"
                                        [filter]="true"
                                        [panelStyle]="{'width':'100%'}"
                                        placeholder="{{'general.organization' | translate}}"
                                        appendTo="body"
                                        #organizationIdInput>
                                <ng-template let-organization
                                             pTemplate="item">
                                    <span [ngClass]="organization.styleClass">{{organization.label}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>

                    <h3 class="mt-8">{{"vmsImageEditor.code" | translate}}</h3>

                    <div class="mt-8">
                        <input type="text"
                               id="code"
                               required
                               class="p-inputtext"
                               [(ngModel)]="code"
                               #codeInput />
                    </div>
                </div>
                <p-dropdown id="vmsTypeValues"
                            [options]="vmsTypeValues"
                            [(ngModel)]="selectedVmsTypeValue"
                            (onChange)="onChangeVmsTypeValue($event)"
                            (onClick)="onVmsTypeValuesClick($event)"
                            [style]="{'width': '100%'}"
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            [autoDisplayFirst]="false"
                            [showClear]="false"
                            appendTo="body"
                            class="vms-type"
                            #vmsTypeValuesDropdown>
                    <ng-template let-vmsTypeValue
                                 pTemplate="item">
                        <div>
                            <app-svg-icon *ngIf="hasImageVariant(vmsTypeValue.value)"
                                          icon="checkmark"></app-svg-icon> {{vmsTypeValue.label}}
                        </div>
                    </ng-template>
                </p-dropdown>
            </ng-container>
            <h3 *ngIf="inPopup"
                class="py-4"
                style="text-align:center;">{{selectedVmsTypeValue | translateEnum:"vmsTypeValue" }}</h3>

        </div>

        <div class="m-vms-designer-preview">
            <img id="previewImage"
                 class="m-vms-designer-preview-image p-8" />

            <button class="m-vms-designer-preview-btn m-btn m-btn-default m-btn-square"
                    (click)="onImageClick()">
                <app-svg-icon icon="arrows-maximize"
                              divClass="icon-16">
                </app-svg-icon>
            </button>
        </div>

        <app-vms-image-editor-properties *ngIf="currentVmsVariant  
            && ( (currentVmsVariant.editorData && currentVmsVariant.url)
                 || (!currentVmsVariant.url && !currentVmsVariant.file))"
                                         class="m-vms-designer-properties"></app-vms-image-editor-properties>

        <div class="m-vms-designer-properties"
             *ngIf="currentVmsVariant && ((currentVmsVariant.url && !currentVmsVariant.editorData) || currentVmsVariant.file)">
            <div class="m-vms-designer-property p-16">
                <h3>{{"vmsImageEditor.nonEditableWarningTitle" | translate}}</h3>
                <p>{{"vmsImageEditor.nonEditableWarning" | translate}}</p>
                <button class="m-btn m-btn-tertiary mt-16"
                        (click)="replaceImage()">
                    {{"vmsImageEditor.replace" | translate}}</button>
            </div>
        </div>
        <div class="m-vms-designer-actions m-btn-group m-btn-group-right m-border-top py-8 px-16"
             style="gap: 8px;">
            <button *ngIf="inPopup"
                    class="m-btn m-btn-secondary"
                    (click)="cancel()">
                {{"form.cancel" | translate}}
            </button>
            <button *ngIf="!inPopup"
                    class="m-btn m-btn-secondary"
                    (click)="close()">
                {{"form.close" | translate}}
            </button>
            <button class="m-btn m-btn-primary"
                    [disabled]="submitting || (!inPopup && (!code || code.length == 0))"
                    (click)="save()">
                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>
                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>
<app-image-dialog imageClass="m-vms-designer-preview-image p-16"></app-image-dialog>