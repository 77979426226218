<form [formGroup]="bulkUploadForm"
      autocomplete="off"
      novalidate>

    <div class="row m-fixed-height">
        <div class="col-4 m-fixed-height">
            <div class="m-card m-fixed-height m-layout-w-actions-bottom"
                 #column1>

                <div class="m-layout-area-body m-layout-w-actions-top">
                    <div class="px-16 py-8 flex align-center m-layout-area-top m-border-bottom">
                        <h3 class="mr-16">{{"general.selectFiles" | translate}}</h3>

                        <app-icon-button icon="add"
                                         class="ml-auto"
                                         text="general.selectFiles"
                                         [fill]="filesInError ? 'red' : ''"
                                         [disabled]="submitting"
                                         (click)="addFiles()">
                        </app-icon-button>
                    </div>

                    <div class="m-layout-area-body m-layout-w-actions-bottom">
                        <app-files [emptyMessage]="false"
                                   scrollHeight="flex"
                                   [deleteCommand]="!submitting"
                                   (changed)="fixLayout()"
                                   accept="*"
                                   class="m-layout-area-body m-layout-default">
                        </app-files>

                        <div class="m-layout-area-bottom m-border-top py-8"
                             *ngIf="submitting || uploadResults?.length">
                            <div class="row"
                                 *ngIf="submitting">
                                <div class="col-12">
                                    <div class="p-16">
                                        <p-progressBar [value]="progress"></p-progressBar>
                                    </div>
                                </div>
                            </div>

                            <div class="row"
                                 *ngIf="uploadResults?.length">
                                <div class="col-4">
                                    <div class="p-8 flex justify-center">
                                        <app-svg-icon icon="checkmark"
                                                      fill="green">
                                        </app-svg-icon>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="p-8 flex justify-center">
                                        <app-svg-icon icon="error"
                                                      fill="orange">
                                        </app-svg-icon>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="p-8 flex justify-center">
                                        <app-svg-icon icon="error"
                                                      fill="red">
                                        </app-svg-icon>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="flex justify-center px-8 pb-8"
                                         style="color: green;">{{successes}}</div>
                                </div>

                                <div class="col-4">
                                    <div class="flex justify-center px-8 pb-8"
                                         style="color: orange;">{{warnings}}</div>
                                </div>

                                <div class="col-4">
                                    <div class="flex justify-center px-8 pb-8"
                                         style="color: red;">{{errors}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-btn-group m-btn-group-right px-16 py-8 m-layout-area-bottom m-border-top">
                    <button [disabled]="submitting"
                            class="m-btn m-btn-primary"
                            (click)="submit()"
                            type="submit">
                        <app-loading-spinner *ngIf="!!submitting">
                        </app-loading-spinner>

                        <span [innerHTML]="(files.length === 1 ? 'bulkUpload.uploadFile' : 'bulkUpload.uploadFiles') | translate:{count: files.length}"></span>
                    </button>
                </div>
            </div>
        </div>

        <div [hidden]="!uploadResults?.length"
             class="col-8 m-fixed-height">
            <div class="m-card m-fixed-height m-layout-default">
                <app-bulk-upload-result [results]="uploadResults"></app-bulk-upload-result>
            </div>
        </div>
    </div>
</form>