import { Injectable } from "@angular/core";

// TODO needed until Angular TypeScript 4.1
// https://stackoverflow.com/a/66005573
interface GeolocationCoordinates {
    readonly accuracy: number;
    readonly altitude: number | null;
    readonly altitudeAccuracy: number | null;
    readonly heading: number | null;
    readonly latitude: number;
    readonly longitude: number;
    readonly speed: number | null;
}

declare var GeolocationCoordinates: {
    prototype: GeolocationCoordinates;
    new(): GeolocationCoordinates;
};

interface GeolocationPosition {
    readonly coords: GeolocationCoordinates;
    readonly timestamp: number;
}

@Injectable()
export class GeolocationService {
    private hadError = false;
    private watchIds = new Array<number>();

    get isSupported(): boolean {
        return !!navigator.geolocation && !this.hadError;
    }

    subscribeToPosition(callback: (coords: GeolocationCoordinates) => void, onErrorCallback: () => void = null) {
        let id = -1;

        const onError = () => {
            this.hadError = true;
            if (id >= 0) {
                this.unsubscribe(id);
            }

            if (onErrorCallback) {
                onErrorCallback();
            }
        };

        if (!this.isSupported) {
            onError();
            return;
        }

        const onSuccess = (position: GeolocationPosition) => {
            callback(position.coords);
        };

        id = navigator.geolocation.watchPosition(onSuccess, onError);
        this.watchIds.push(id);
    }

    unsubscribe(id: number = null) {
        if (id) {
            this.watchIds = this.watchIds.remove(id);
            navigator.geolocation.clearWatch(id);
        } else {
            for (const watchId of this.watchIds) {
                navigator.geolocation.clearWatch(watchId);
            }
            this.watchIds.length = 0;
        }
    }

    getLocation(): Promise<GeolocationCoordinates> {
        return new Promise<GeolocationCoordinates>((resolve, reject) => {
            const onError = () => {
                this.hadError = true;
                resolve(null);
            };

            if (!this.isSupported) {
                onError();
                return;
            }

            const onSuccess = (position: GeolocationPosition) => {
                resolve(position.coords);
            };

            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        });
    }
}