import { FormValidationService } from "src/app/services/form-validation.service";
import { Component } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";
import { VmsImageEditorService } from "../../services/vms-image-editor.service";
import { ToastService } from "src/app/services/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-upload-vms-image-dialog",
    templateUrl: "./upload-vms-image.dialog.html"
})
export class UploadVmsImageDialogComponent extends DialogComponentBase {
    callback: (res: IVmsImageVariant) => void;
    image: File;
    imagePreview: string;
    submitted: boolean;
    acceptedHeight: number;
    acceptedWidth: number;
    sizeError: boolean;



    constructor(
        readonly formValidationService: FormValidationService,
        readonly vmsImageEditorService: VmsImageEditorService,
        readonly toastService: ToastService,
        readonly translateService: TranslateService) {
        super();
    }


    create(height: number, width: number) {
        this.acceptedHeight = height;
        this.acceptedWidth = width;
        this.openDialog();
    }


    protected async onOpen() {
        this.submitted = false;
    }

    protected onClose() {
        this.setImageDirectly(null);
    }

    async setImage(event: { files: FileList }) {
        if (!event || event.files.length < 0) return;
        await this.setImageDirectly(event.files[0]);
    }

    async setImageDirectly(file: File) {
        if (file === null) {
            this.image = null;
            this.imagePreview = null;
            this.sizeError = false;
            return;
        }
        // get height and width of file
        this.image = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => this.onReaderLoad(event);
    }

    onReaderLoad(event: ProgressEvent<FileReader>) {
        const img = new Image();
        img.src = event.target.result as string;
        img.onload = () => this.onImageLoad(img);
    }

    onImageLoad(img: HTMLImageElement) {
        if (img.height === this.acceptedHeight && img.width === this.acceptedWidth) {
            this.imagePreview = img.src;
            this.sizeError = false;
        } else {
            this.image = null;
            this.sizeError = true;
            this.toastService.warning(this.translateService.instant("vmsImageEditor.uploadDimensionError",
                { width: this.acceptedWidth, height: this.acceptedHeight }));

        }
    }

    async submit() {
        this.submitted = true;

        if (!this.image) return;
        this.vmsImageEditorService.setUploadFile(this.image);
        this.submitted = false;
        this.close();
    }
}