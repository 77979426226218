import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IAssignment } from "src/app/models/assignment";
import { BackendRights } from "src/app/models/backend-rights";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { AssignmentApi } from "src/app/resource/assignment.api";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { ModalService } from "src/app/services/modal.service";
import { NavigationService } from "src/app/services/navigation.service";

@Component({
    selector: "app-sub-assignments",
    templateUrl: "./sub-assignments.component.html",
    styleUrls: []
})
export class SubAssignmentsComponent extends LazyTableComponent<IAssignment> implements OnInit, OnChanges, IComponentCanDeactivate {
    @Input() assignmentId: number;
    @Input() parentAssignmentId: number;
    @Input() locationId: number;
    @Input() displayAssignmentColumn = false;
    @Input() requiresInput = false;
    @Input() editCommand = false;
    @Input() deleteCommand = false;

    @Output() lastRowDeleted = new EventEmitter();

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        public readonly assignmentApi: AssignmentApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly domainDataService: DomainDataService,
        private readonly navigationService: NavigationService,
        private readonly translateService: TranslateService,
        private readonly modalService: ModalService) {

        super("subassignments.component", elementRef, assignmentApi, tableService);

        this.stretchHeight = true;
        this.addColumn(new TableColumn("isCompleted", "assignment.isCompleted", { sortable: true, visibility: ColumnVisibility.NeverHide, width: 41, resizable: false }));
        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));

        const taskTypeColumn = new TableColumn("taskTypeId", "assignment.taskType", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.NeverHide });
        this.addColumn(taskTypeColumn);

        const signMaterialColumn = new TableColumn("signMaterialId", "assignment.signMaterial", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(signMaterialColumn);

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (assignment) => this.navigateToDetail(assignment),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditAssignment),
        });
        this.registerCommand({
            text: "form.view",
            icon: "eye-solid",
            click: (assignment) => this.navigateToDetail(assignment),
            validFunc: () =>
                this.editCommand && !this.rights?.hasBackendRight(BackendRights.EditAssignment) && this.rights?.hasBackendRight(BackendRights.ViewAssignment),
        });
        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (subAssignment) => this.delete(subAssignment),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteAssignment),
        });

        this.domainDataService.get(DomainData.TaskType).then(types => {
            taskTypeColumn.filterOptions = types;
        });

        this.domainDataService.get(DomainData.SignMaterial).then(materials => {
            signMaterialColumn.filterOptions = materials;
        });
    }
    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.locationId || !!this.assignmentId || !!this.parentAssignmentId;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        const inputChange = changes["requiresInput"] || changes["assignmentId"] || changes["locationId"] || changes["parentAssignmentId"];

        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("assignment", "location");
        serviceRequestOptions.includes.add("assignment", "parentAssignment");
        serviceRequestOptions.includes.add("assignment", "currentStatus");
        return serviceRequestOptions;
    }
    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (!this.parentAssignmentId) {
            if (this.assignmentId) {
                // not use that when creating new sub-assignment
                searchParameters.filter.push({ field: "parentAssignmentId", value: this.assignmentId, operator: FilterOperator.equals });
            }
        } else {
            searchParameters.filter.push({ field: "parentAssignmentId", value: this.parentAssignmentId, operator: FilterOperator.equals });
            if (this.assignmentId) {
                // not use that when creating new sub-assignment
                searchParameters.filter.push({ field: "id", value: this.assignmentId, operator: FilterOperator.notEquals });
            }
        }

        return searchParameters;
    }

    private navigateToDetail(subAssignment: IAssignment) {
        this.navigationService.toAssignment(subAssignment);
    }

    private delete(subAssignment: IAssignment) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteAssignment)) return;

        const modalBody = this.translateService.instant("subAssignment.deleteConfirmation", { name: subAssignment.name }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(subAssignment));
    }

    private handleDelete(subAssignment: IAssignment) {
        const onDeleteSuccess = () => {
            if (this.data.length === 1) {
                this.lastRowDeleted.emit();
            }
            this.reload();
        };

        this.assignmentApi.delete$(subAssignment.id).subscribe({ next: onDeleteSuccess, error: () => { } });
    }
}
