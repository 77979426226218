import { Direction } from "./direction";
import { IServiceModelBase } from "./servicemodelbase";
import { IMeasuringPointSummary } from "./web/measuring-point-summary";

export interface IMeasuringPointAggregation extends IServiceModelBase {
    sourceMeasuringPoint: IMeasuringPointSummary;
    sourceMeasuringPointDirectionId: Direction;
    factor: number;
    aggregatedMeasuringPointDirectionId: string;
}

export class MeasuringPointAggregationCreator {
    constructor(existing: IMeasuringPointAggregation) {
        if (existing) {
            this.sourceMeasuringPointId = existing.sourceMeasuringPoint.id;
            this.sourceMeasuringPointDirectionId = existing.sourceMeasuringPointDirectionId;
            this.factor = existing.factor;
            this.aggregatedMeasuringPointDirectionId = existing.aggregatedMeasuringPointDirectionId;
        }
    }

    sourceMeasuringPointId: number;
    sourceMeasuringPointDirectionId: string;
    factor: number;
    aggregatedMeasuringPointDirectionId: string;
}