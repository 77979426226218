import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { ILocation, ICoordinate, IAddress, IAddressWithTimeZone } from "src/app/models/location";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SigncoFormGroup } from "src/app/models/form";
import { LocationApi } from "src/app/resource/location.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AnalysisType } from "src/app/models/measuring-point";
import { BasicMapComponent, BasicMarkerOptions, IBasicMarker, BasicMarkerEvent, createBasicMarker } from "src/app/modules/map-basic";
import { MapUtils } from "src/app/utilities";

@Component({
    selector: "app-location-form",
    templateUrl: "./location-form.component.html"
})
export class LocationFormComponent implements OnInit, OnChanges {
    @Input() parentFormGroup: SigncoFormGroup;
    @Input() coordinate: ICoordinate;
    @Input() location: ILocation;
    @Input() isDisabled: boolean;
    @Input() markerLabel?: string;
    @Input() typeId?: AnalysisType; // For marker color

    @Output() timeZone = new EventEmitter<string>();

    gmap: BasicMapComponent;
    locationMarkerPreview: IBasicMarker;
    coordinateForm: SigncoFormGroup;
    addressForm: SigncoFormGroup;
    officialIdForm: SigncoFormGroup;

    updatingAddressForCoordinate: ICoordinate;

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly locationApi: LocationApi) {

        // We don't set the address as required. Sometimes a location might be in the middle of nowhere. As long as we got latitude and longitude, we know enough
        this.addressForm = this.formBuilder.group({
            line1: [""],
            zipCode: [""],
            city: [""],
            country: [""],
            countryCode: ["", [Validators.minLength(2), Validators.maxLength(2)]],
        }) as SigncoFormGroup;

        this.officialIdForm = this.formBuilder.group({
            type: "",
            number: "",
            milestone: [null]
        }) as SigncoFormGroup;

        this.coordinateForm = this.formBuilder.group({
            latitude: [null, Validators.required],
            longitude: [null, Validators.required]
        }) as SigncoFormGroup;
    }

    private getDefaultLocation(): ILocation {
        return this.location ||
            this.coordinate ?
            { coordinate: this.coordinate } as ILocation :
            this.globalEventsService.getDefaultOrganization().location;
    }

    private initializeAll() {
        if ((!this.coordinate && !this.location) || !this.parentFormGroup) return;

        this.initializeCoordinate();
        this.initializeForms();
        this.initializeLocationMarker();
        this.initializeLocation();
    }

    private initializeCoordinate() {
        if (!this.coordinate) {
            const location = this.getDefaultLocation();
            if (location) {
                this.coordinate = location.coordinate;
            }
        }

        if (this.coordinate) {
            this.coordinateForm.patchValue(this.coordinate);
        }
    }

    private initializeForms() {
        if (!this.parentFormGroup) return;

        this.parentFormGroup.addControl("address", this.addressForm);
        this.parentFormGroup.addControl("officialId", this.officialIdForm);
        this.parentFormGroup.addControl("coordinate", this.coordinateForm);

        if (this.isDisabled) {
            // Newly added controls don't inherit "disabled" from parent
            // They even make the parent control "invalid" instead of "disabled"
            // because the newly added element is valid and thus it's no longer strictly disabled (seriously)
            // so we set it again
            this.parentFormGroup.disable();
        }
    }

    private initializeLocationMarker() {
        if (!this.gmap) return;
        const location = this.getDefaultLocation();

        const markerOptions = new BasicMarkerOptions(MapUtils.toLatLng(location.coordinate));
        markerOptions.draggable = !this.isDisabled;
        markerOptions.labelVisible = false;

        // Only set when markerLabel is filled in
        // Or the null value will overwrite in Object.assign
        if (this.markerLabel) {
            markerOptions.labelContent = this.markerLabel;
        }

        if (this.locationMarkerPreview && this.gmap) {
            this.gmap.markers.clear();
        }

        this.locationMarkerPreview = createBasicMarker(markerOptions);

        if (this.gmap) {
            this.gmap.markers.add(this.locationMarkerPreview);
            this.gmap.setCenter(MapUtils.toLatLng(location.coordinate));
        }
    }

    private updateMarkerLabel() {
        if (this.locationMarkerPreview) {
            this.locationMarkerPreview.setLabelContent(this.markerLabel);
        } else {
            this.initializeLocationMarker();
        }
    }

    private initializeLocation() {
        // If location already exists and passed through input, fill in and don't query for address
        if (this.location) {
            this.addressForm.patchValue(this.location.address);
            this.coordinateForm.patchValue(this.location.coordinate);
            this.officialIdForm.patchValue(this.location.officialId);
        } else {
            this.updateAddress();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const parentFormGroupChange = changes["parentFormGroup"];
        if (parentFormGroupChange) {
            this.initializeForms();
        }

        const coordinateChange = changes["coordinate"];
        if (coordinateChange) {
            this.initializeCoordinate();
            this.initializeLocation();
            // this.updateAddress(); // We only do this when the user actually clicks the button to do so
            this.initializeLocationMarker();
        }

        const locationChange = changes["location"];
        if (locationChange) {
            this.initializeLocation();
            this.initializeLocationMarker();
        }

        const isDisabledChange = changes["isDisabled"];
        if (isDisabledChange) {
            this.initializeForms();
            this.initializeLocationMarker();
        }

        const markerTypeChange = changes["markerType"];
        if (markerTypeChange) {
            this.initializeLocationMarker();
        }

        const markerLabelChange = changes["markerLabel"];
        if (markerLabelChange) {
            this.updateMarkerLabel();
        }
    }

    ngOnInit() {
        this.initializeAll();
    }

    handleMapReady(gmap: BasicMapComponent) {
        this.gmap = gmap;
        this.gmap.map.setOptions({
            zoom: 18,
            clickableIcons: false,
        });
        if (!this.locationMarkerPreview) {
            this.initializeLocationMarker();
            if (this.coordinate) {
                this.updateAddress();
            }
        }
        this.gmap.markers.add(this.locationMarkerPreview);
        this.gmap.setCenter(MapUtils.toLatLng(this.coordinate));
    }

    handleMarkerDragEnd(dragEvent: BasicMarkerEvent) {
        const newCoordinate = {
            latitude: dragEvent.event.latLng.lat(),
            longitude: dragEvent.event.latLng.lng()
        };

        // Update form
        this.coordinateForm.patchValue(newCoordinate);

        this.updateCoordinate(newCoordinate);
    }

    handleCoordinateUpdate() { // Manual input
        const newCoordinate = {
            latitude: this.coordinateForm.get("latitude").value,
            longitude: this.coordinateForm.get("longitude").value
        } as ICoordinate;

        if (!newCoordinate.latitude || !newCoordinate.longitude) return;

        // Update marker
        const newLatLng = MapUtils.toLatLng(newCoordinate);
        this.locationMarkerPreview.setPosition(newLatLng);

        // Center map on marker
        this.gmap.setCenter(MapUtils.toLatLng(newCoordinate));

        this.updateCoordinate(newCoordinate);
    }

    private updateCoordinate(newCoordinate: ICoordinate) {
        this.coordinate = newCoordinate;
        this.locationMarkerPreview.setPosition(MapUtils.toLatLng(this.coordinate));
    }

    updateAddress() {
        if (!this.locationMarkerPreview) return;

        const newCoordinate = this.coordinate;

        if (this.updatingAddressForCoordinate &&
            this.updatingAddressForCoordinate.latitude === newCoordinate.latitude &&
            this.updatingAddressForCoordinate.longitude === newCoordinate.longitude) return;

        // Using this to not send double requests
        this.updatingAddressForCoordinate = newCoordinate;

        const onSuccess = (addressWithTimeZoneInfo: IAddressWithTimeZone) => {
            if (this.updatingAddressForCoordinate !== newCoordinate) return;

            this.updatingAddressForCoordinate = null;
            if (!addressWithTimeZoneInfo?.address) return;

            if (this.location?.address) { // Note that some locations don't have an address filled in, e.g. locations that are updated by a tracker.
                this.location.timeZone = addressWithTimeZoneInfo?.timeZone;
                Object.assign(this.location.address, addressWithTimeZoneInfo?.address);
            }

            this.addressForm.patchValue(addressWithTimeZoneInfo?.address);
            this.timeZone.emit(addressWithTimeZoneInfo?.timeZone);
        };

        this.locationApi.getAddressFromCoordinates$(this.updatingAddressForCoordinate).subscribe(onSuccess);
    }

    updateCoordinatesFromAddress() {
        const address = this.addressForm.value as IAddress;

        const onSuccess = (coordinates: ICoordinate) => {
            if (!coordinates || !coordinates.latitude) return;
            this.coordinateForm.patchValue(coordinates);
            this.handleCoordinateUpdate();
        };

        this.locationApi.getCoordinatesFromAddress$(address).subscribe(onSuccess);
    }
}
