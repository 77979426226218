import { IJournal, JournalCreator } from "src/app/models/journal";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class JournalApiService extends ApiService<IJournal, JournalCreator, JournalCreator> {
    getRoute() {
        return "Journal/{?type}/{?id}";
    }
}