import { LazyTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ViewChild, OnInit, ElementRef } from "@angular/core";
import { ILoginNotification, LoginNotificationUpdater } from "src/app/models/login-notification";
import { ManageLoginNotificationDialogComponent } from "../manage-login-notification-dialog/manage-login-notification.dialog";
import { LoginNotificationApi } from "src/app/resource/login-notification.api";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-login-notifications",
    templateUrl: "./login-notifications.component.html"
})
export class LoginNotificationsComponent extends LazyTableComponent<ILoginNotification> implements OnInit {
    @ViewChild(ManageLoginNotificationDialogComponent, { static: true }) manageLoginNotificationDialog: ManageLoginNotificationDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;

    update: Date;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService,
        private readonly modalService: ModalService,
        private readonly loginNotificationApi: LoginNotificationApi) {

        super("login-notifications.component", elementRef, loginNotificationApi, tableService);

        this.stretchHeight = true;

        const uponReloadSubscription = this.domainDataService.uponReload.subscribe(() => {
            this.update = new Date();
        });
        this.subscriptionManager.add("uponReload", uponReloadSubscription);

        this.selectionMode = "";
        this.includeObsolete = true;

        this.addColumn(new TableColumn("messageId", "loginNotifications.message", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "loginNotifications.edit",
            icon: "edit",
            click: (loginNotification) => this.edit(loginNotification),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (loginNotification) => this.delete(loginNotification),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (loginNotification) => !loginNotification.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (loginNotification) => this.restore(loginNotification),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (loginNotification) => loginNotification.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    edit(loginNotification: ILoginNotification) {
        this.manageLoginNotificationDialog.edit(loginNotification);
    }

    delete(loginNotification: ILoginNotification) {
        const onDeleteSuccess = () => {
            loginNotification.isObsolete = true;
        };

        const onDelete = () => {
            this.loginNotificationApi.delete$(loginNotification.id).subscribe(onDeleteSuccess, () => { });
        };

        this.modalService.delete("loginNotifications.deleteConfirmation", onDelete);
    }

    restore(loginNotification: ILoginNotification) {
        const onRestoreSuccess = () => {
            loginNotification.isObsolete = false;
        };

        const restoredLoginNotification = new LoginNotificationUpdater(loginNotification);
        restoredLoginNotification.isObsolete = false;

        this.loginNotificationApi.update$(restoredLoginNotification).subscribe(onRestoreSuccess);
    }
}