import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ICctvConfiguration } from "src/app/models/cctv-configuration";
import { SigncoFormGroup } from "src/app/models/form";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { ToastService } from "src/app/services/toast.service";
import { SubscriptionManager } from "src/app/utilities";

@Component({
    selector: "app-measuring-point-cctv-configuration",
    templateUrl: "./measuring-point-cctv-configuration.component.html"
})
export class MeasuringPointCctvConfigurationComponent implements OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;

    @Output() save = new EventEmitter<IMeasuringPoint>();

    cctvConfigurationForm: SigncoFormGroup;
    submitting = false;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        private readonly changeGuardService: ChangeGuardService,
        private readonly toastService: ToastService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi
    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const mpChange = changes["measuringPoint"];
        if (mpChange) {
            this.initialize();
        }
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.cctvConfigurationForm.pristine;
    }

    onDeactivate() {
        this.toastService.suppressErrors = false;
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    private initialize() {
        if (!this.measuringPoint) return;

        this.cctvConfigurationForm = this.formBuilder.group({
            retentionNumberOfImages: [null],
            retentionNumberOfDays: [null],
        }) as SigncoFormGroup;

        const sub = this.cctvConfigurationForm.valueChanges.subscribe(() => {
            this.onValueChanges();
        });
        this.subscriptionManager.add("cctv-form", sub);

        if (this.measuringPoint.cctvConfiguration) {
            this.cctvConfigurationForm.patchValue(this.measuringPoint.cctvConfiguration);
        }

        this.cctvConfigurationForm.markAsPristine();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.cctvConfigurationForm);
        if (!isValid) return;

        this.cctvConfigurationForm.disable();

        const onSuccess = async (savedMp: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMp);
            this.submitting = false;
            this.cctvConfigurationForm.enable();
            this.initialize();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
            this.cctvConfigurationForm.enable();
        };

        // Merge existing mp with form
        const mpUpdater = new MeasuringPointUpdater(this.measuringPoint);

        if (!mpUpdater.cctvConfiguration) mpUpdater.cctvConfiguration = {} as ICctvConfiguration;
        Object.assign(mpUpdater.cctvConfiguration, this.cctvConfigurationForm.value);

        if (!this.cctvConfigurationForm.get("retentionNumberOfImages").value) delete mpUpdater.cctvConfiguration.retentionNumberOfImages;
        if (!this.cctvConfigurationForm.get("retentionNumberOfDays").value) delete mpUpdater.cctvConfiguration.retentionNumberOfDays;

        this.submitting = true;
        this.measuringPointApi.update$(mpUpdater).subscribe({
            next: onSuccess,
            error: onError
        });
    }

    reset() {
        this.initialize();
    }

    private onValueChanges() {
        this.cctvConfigurationForm.get("retentionNumberOfImages").removeValidators(Validators.required);
        this.cctvConfigurationForm.get("retentionNumberOfDays").removeValidators(Validators.required);

        this.cctvConfigurationForm.get("retentionNumberOfImages").updateValueAndValidity({ emitEvent: false });
        this.cctvConfigurationForm.get("retentionNumberOfDays").updateValueAndValidity({ emitEvent: false });

        if (!!this.cctvConfigurationForm.get("retentionNumberOfImages").value) {
            this.cctvConfigurationForm.get("retentionNumberOfImages").setValidators([Validators.required]);
            this.cctvConfigurationForm.get("retentionNumberOfImages").updateValueAndValidity({ emitEvent: false });
        } else if (!!this.cctvConfigurationForm.get("retentionNumberOfDays").value) {
            this.cctvConfigurationForm.get("retentionNumberOfDays").setValidators([Validators.required]);
            this.cctvConfigurationForm.get("retentionNumberOfDays").updateValueAndValidity({ emitEvent: false });
        } else {
            this.cctvConfigurationForm.get("retentionNumberOfImages").setValidators([Validators.required]);
            this.cctvConfigurationForm.get("retentionNumberOfImages").updateValueAndValidity({ emitEvent: false });
            this.cctvConfigurationForm.get("retentionNumberOfDays").setValidators([Validators.required]);
            this.cctvConfigurationForm.get("retentionNumberOfDays").updateValueAndValidity({ emitEvent: false });
        }
    }
}
