<div class="m-card m-layout-w-actions-top m-fixed-height"
     style="position: relative;"
     [ngClass]="ngClass">
    <ng-template #titleTemplate>
        <span class="m-circle m-circle-primary mr-8">
            <app-svg-icon [icon]="measuringPoint?.analysisTypeId">
            </app-svg-icon>
        </span>
        <div class="m-tab-header-title-text">
            <span class="m-tab-header-title-text-label">{{measuringPoint?.analysisTypeId | translateEnum:"analysisType" }}</span>
            <span>{{measuringPoint?.code}}</span>
        </div>
    </ng-template>

    @if(loading){
    <app-loading-spinner>
    </app-loading-spinner>
    }@else if(measuringPoint){
    <app-tabs [tabs]="tabs"
              class="m-layout-area-top"
              [selectedTab]="selectedTab"
              [relativeRouting]="true"
              [titleTemplate]="titleTemplate"
              (selectedTabChange)="navigateTo($event);">
    </app-tabs>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
    }
</div>