import { Component, ChangeDetectorRef, Type } from "@angular/core";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { ValidationComponent } from "src/app/modules/validation/components/validation/validation.component";
import { PredictionComponent } from "src/app/modules/prediction/components/prediction/prediction.component";
import { BulkUploadComponent } from "src/app/modules/bulk-upload/components/bulk-upload/bulk-upload.component";
import { SharedKeysComponent } from "../../share-data/components/shared-keys/shared-keys.component";
import { ImportedKeysComponent } from "../../share-data/components/imported-keys/imported-keys.component";
import { BackendRights } from "src/app/models/backend-rights";

type DataTabComponents =
    ValidationComponent |
    PredictionComponent |
    SharedKeysComponent |
    ImportedKeysComponent |
    BulkUploadComponent;

@Component({
    selector: "app-data-tab",
    templateUrl: "./data-tab.component.html",
    styleUrls: ["./data-tab.component.scss"],
})
export class DataTabComponent extends TabBase<DataTabComponents> {
    ngClass: string;

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        this.addTab({ component: ValidationComponent, url: "validation", translatePath: "dataValidation.title", icon: "data" });
        this.addTab({ component: PredictionComponent, url: "prediction", translatePath: "prediction.title", icon: "prediction" });
        this.addTab({ component: BulkUploadComponent, url: "bulkupload", translatePath: "bulkUpload.title", icon: "upload" });
        this.addTab({ component: SharedKeysComponent, url: "keys", translatePath: "sharedKeys.title", icon: "key" });
        this.addTab({ component: ImportedKeysComponent, url: "imported", translatePath: "importedSharedKeys.title", icon: "share" });

        this.filterTabs();
        this.updateActiveComponent();
    }

    filterTabs() {
        const tabsToFilter = new Array<Type<DataTabComponents>>();

        const rights = this.globalEventsService.getCurrentRights();
        if (!rights?.hasBackendRight(BackendRights.ViewVehicleDayOverviews)) {
            tabsToFilter.push(ValidationComponent);
        }

        if (!rights?.hasBackendRight(BackendRights.ViewFillInData)) {
            tabsToFilter.push(PredictionComponent);
        }

        if (!rights?.hasBackendRight(BackendRights.ViewBulkUpload)) {
            tabsToFilter.push(BulkUploadComponent);
        }
        if (!rights?.hasBackendRight(BackendRights.ViewSharedKey)) {
            tabsToFilter.push(SharedKeysComponent);
        }
        if (!rights?.hasBackendRight(BackendRights.ViewImportedSharedKey)) {
            tabsToFilter.push(ImportedKeysComponent);
        }
        this.hideTabs(tabsToFilter);
    }

    protected onSelectedTabChange() {
        this.ngClass = this.selectedTab ? this.selectedTab.url : "";
    }
}
