import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { PredictionComponent } from "./components/prediction/prediction.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    { path: "", component: PredictionComponent, canActivate: [AuthGuard], data: { name: "dataPrediction.title" } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PredictionRoutingModule { }
