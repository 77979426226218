<div style="display:grid; grid-template-columns: auto min-content; gap:8px;">
    <div style="display:inline-grid; grid-template-columns: max-content min-content; grid-auto-rows: 1fr; gap:8px; align-items: center;">
        <ng-container *ngFor="let externalInput of externalInput.inputs">
            <div>
                {{externalInput.description}}
            </div>
            <div>
                <strong *ngIf="externalInput.value === undefined">{{"general.unknown" | translate | uppercase}}</strong>

                <ng-container *ngIf="(externalInput.value === true && externalInput.trueIconPreview) || (externalInput.value === false && externalInput.falseIconPreview); else defaultPreview">
                    <img [attr.src]="externalInput.value === true ? (externalInput.trueIconPreview | safeHtml) : (externalInput.falseIconPreview | safeHtml)"
                         style="width:25px;height:25px;" />
                </ng-container>
                <ng-template #defaultPreview>
                    <strong *ngIf="externalInput.value === true">{{"form.yes" | translate | uppercase}}</strong>
                    <strong *ngIf="externalInput.value === false">{{"form.no" | translate | uppercase}}</strong>
                </ng-template>
            </div>
        </ng-container>
    </div>
    <div *ngIf="isExpanded">
        <button class="m-btn m-btn-secondary"
                style="min-height:0px; padding: 0px;"
                (click)="openExternalInputManageDialog($event)">
            <app-svg-icon icon="edit"></app-svg-icon>
        </button>
    </div>
</div>

<app-external-input-manage-dialog></app-external-input-manage-dialog>
