import { Component, ElementRef } from "@angular/core";

@Component({
    selector: "app-admin-date-period-sets",
    templateUrl: "./admin-date-period-sets.component.html"
})
export class AdminDatePeriodSetsComponent {
    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }
}