import { AlertLevel } from "src/app/models/alert";
import { MeasuringPointLinkStatus } from "src/app/models/map-filter";

export class StatusFilter {

    constructor(linkedStatus: MeasuringPointLinkStatus, alertLevels: AlertLevel[]) {
        this.linkedStatus = linkedStatus;
        this.alertLevels = alertLevels;
    }
    linkedStatus: MeasuringPointLinkStatus;
    alertLevels: AlertLevel[];
}