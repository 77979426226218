import { Component, ViewChild, ElementRef, NgZone, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FilterDescriptor, SearchParameters } from "src/app/models/search";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { AdvancedMapComponent } from "../advanced-map/advanced-map.component";
import { MapDataService } from "src/app/services/map-data.service";
import { CacheOptions } from "src/app/resource/api";
import { EventService } from "src/app/services/event.service";
import { ColorUtils, MapUtils } from "src/app/utilities";
import { Constants } from "src/app/constants/constants";
import { MapIconService } from "src/app/services/map-icon-service";
import { ReportDirection } from "src/app/models/report-type";
import { ILocationSummary } from "src/app/models/web";
import { IGroup, IGroupMeasuringPoint } from "src/app/models/group";
import { IBasicMarker, BasicMarkerOptions } from "src/app/modules/map-basic";

class GroupMarkerStyle {
    left: string;
    top: string;
    zIndex: number | string;
    marginTop: string;
    marginLeft: string;
}

@Component({
    selector: "app-group-marker",
    templateUrl: "./group-marker.component.html"
})
export class GroupMarkerComponent implements OnDestroy {
    @ViewChild("div", { static: true }) div: ElementRef<HTMLDivElement>;

    locationSummary: ILocationSummary;
    gmap: AdvancedMapComponent;
    readonly overlay: google.maps.OverlayView;
    marker: IBasicMarker;

    hovered: boolean;
    style: GroupMarkerStyle;

    groupMeasuringPoints: IGroupMeasuringPoint[];
    isAllForwardSelected = false;
    isAllReverseSelected = false;
    isAllSumSelected = false;

    isInGroup = false;

    private destroyed = false;
    private readonly mapDataServiceKey: string;

    constructor(
        private readonly measuringPointWebApi: MeasuringPointWebApi,
        private readonly mapDataService: MapDataService,
        private readonly eventService: EventService,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
        private readonly mapIconService: MapIconService) {

        this.mapDataServiceKey = this.mapDataService.createKey();
        this.overlay = new google.maps.OverlayView();

        this.overlay.onAdd = () => {
            const pane = this.overlay.getPanes().floatPane;
            pane.appendChild(this.div.nativeElement);
        };

        this.overlay.draw = () => {
            this.zone.run(() => {
                this.updateStyle();
            });
        };

        this.overlay.onRemove = () => {
            if (this.div.nativeElement.parentNode) {
                this.div.nativeElement.parentNode.removeChild(this.div.nativeElement);
            }
        };

        this.mapDataService.subscribeToEditGroupUpdate(this.mapDataServiceKey, _ => {
            if (!this.groupMeasuringPoints) return;
            this.updateMeasuringPointsSelection(_);
            this.calculateInGroup(false, false, true);
            this.updateColor();
        });
    }

    ngOnDestroy() {
        this.destroyed = true;
        this.hide();
        this.updateColor(true);
        this.mapDataService.unsubscribe(this.mapDataServiceKey);
    }

    trackByFn(index: number, item: IGroupMeasuringPoint) {
        return item.group.id + item.measuringPoint.id;
    }

    async setLocation(location: ILocationSummary) {
        this.locationSummary = location;

        const options = this.getDefaultMarkerOptions();

        options.labelContent = location.code;

        this.zone.runOutsideAngular(() => {
            this.marker.setOptions(options);
        });

        await this.refreshGroupMeasuringPoints();

        this.calculateInGroup();
    }

    private getDefaultMarkerOptions(): BasicMarkerOptions {
        const position = !this.locationSummary ? null : MapUtils.toLatLng(this.locationSummary);

        const icon = this.mapIconService.getIconState(this.locationSummary.iconStateId);

        const options = {
            position: position,
            draggable: false,
            labelColor: icon.configuration.default.labelColor
        } as BasicMarkerOptions;

        options.textColor = ColorUtils.getMarkerTextColorHex(options.labelColor);

        return options;
    }

    private async refreshGroupMeasuringPoints() {
        const stopOnCacheFound = !!this.groupMeasuringPoints;
        const locationId = this.locationSummary.id;

        const groupMeasuringPoints = new Array<IGroupMeasuringPoint>();

        let editLocationGroupMeasuringPoints: IGroupMeasuringPoint[];

        if (this.mapDataService.isLocationInGroup(locationId)) {
            editLocationGroupMeasuringPoints = this.mapDataService.editGroup.measuringPoints.filter(x => x.measuringPoint.locationId === locationId);
        }

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("locationId", locationId)];

        const cacheOptions = new CacheOptions();
        cacheOptions.stopOnCacheFound = stopOnCacheFound;

        const measuringPoints = await this.measuringPointWebApi.search$(searchParameters, null, cacheOptions).toPromise();

        for (const measuringPoint of measuringPoints.data) {
            if (editLocationGroupMeasuringPoints) {
                const existing = editLocationGroupMeasuringPoints.find(x => x.measuringPoint.id === measuringPoint.id);
                if (existing) {
                    groupMeasuringPoints.push(existing);
                    continue;
                }
            }

            groupMeasuringPoints.push({
                group: this.mapDataService.editGroup,
                measuringPoint: measuringPoint
            } as IGroupMeasuringPoint);
        }

        for (const groupMeasuringPoint of groupMeasuringPoints) {
            groupMeasuringPoint.group = this.mapDataService.editGroup;
        }

        this.groupMeasuringPoints = groupMeasuringPoints;
    }

    private calculateInGroup(showWhenInGroup = false, hideWhenNotInGroup = false, refreshMapOnChange = false) {
        if (!this.locationSummary) return;

        const isInGroup = this.mapDataService.isLocationInGroup(this.locationSummary.id);

        // const isInGroup = !!this.groupMeasuringPoints.find(x => x.includeForwardDirection || x.includeReverseDirection || x.includeSum);

        this.marker.setDisableCluster(isInGroup);

        this.updateHeaderCheckboxes();

        if (isInGroup === this.isInGroup) {
            this.detectChanges();
            return;
        }

        this.isInGroup = isInGroup;

        this.updateColor();

        if (showWhenInGroup && isInGroup) {
            this.show();
        }

        if (hideWhenNotInGroup && !isInGroup) {
            this.hide();
        }

        if (refreshMapOnChange) {
            this.gmap.cleanIrrelevantGroupMarkers();
        }

        this.detectChanges();
    }

    private detectChanges() {
        if (this.destroyed) return;

        this.cd.detectChanges();
    }

    private updateColor(resetColors = false) {
        this.zone.runOutsideAngular(() => {
            const iconModel = this.mapIconService.getIconState(this.locationSummary.iconStateId);
            let labelColor: string = null;
            if (this.isInGroup && !resetColors) {
                this.marker.setIcon(iconModel.selectedIcon);
                labelColor = this.mapDataService.editGroup.color;
            } else {
                this.marker.setIcon(iconModel.defaultIcon);
                labelColor = iconModel.configuration.default.labelColor;
            }

            const textColor = ColorUtils.isDarkHex(labelColor) ? Constants.markerTextColorDark : Constants.markerTextColorLight;
            this.marker.setColors(labelColor, textColor);
        });
    }

    private updateStyle() {
        const overlayProjection = this.overlay.getProjection();

        const latLng = MapUtils.toLatLng(this.locationSummary);
        const markerLocationInPx = overlayProjection.fromLatLngToDivPixel(latLng);

        const newStyle = new GroupMarkerStyle();
        newStyle.left = markerLocationInPx.x + "px";
        newStyle.top = markerLocationInPx.y + "px";
        newStyle.zIndex = this.hovered ? "999999" : "inherit";
        newStyle.marginTop = "0px";
        newStyle.marginLeft = "20px";

        this.style = newStyle;
    }

    updateMeasuringPointInGroup(direction?: string) {
        if (direction === ReportDirection.Forward) this.updateIsAllForwardSelected();
        if (direction === ReportDirection.Reverse) this.updateIsAllReverseSelected();
        if (direction === ReportDirection.Sum) this.updateIsAllSumSelected();

        if (!this.locationSummary) return;

        this.mapDataService.updateGroupMeasuringPointsInEditGroup(this.groupMeasuringPoints);
        this.cd.detectChanges();
    }

    onMouseEnter() {
        // Required, there's apparently no way of stopping the double click through the div triggering the map zoom
        this.gmap.map.set("disableDoubleClickZoom", true);
        this.hovered = true;
        this.updateStyle();
    }

    onMouseLeave() {
        this.gmap.map.set("disableDoubleClickZoom", false);
        this.hovered = false;
        this.updateStyle();
    }

    stopEvent(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.cancelBubble = true;
    }

    show() {
        if (this.overlay.getMap() === this.gmap.map) return;
        this.overlay.setMap(this.gmap.map);
    }

    close() {
        this.hide();
        this.gmap.groupMarkerClose.emit(this.locationSummary);
    }

    hide() {
        this.overlay.setMap(null);
    }

    isVisible(): boolean {
        return !!this.overlay.getMap();
    }

    updateHeaderCheckboxes() {
        this.updateIsAllForwardSelected();
        this.updateIsAllReverseSelected();
        this.updateIsAllSumSelected();
    }

    updateIsAllForwardSelected() {
        this.isAllForwardSelected = this.groupMeasuringPoints && !this.groupMeasuringPoints.find(x => !x.includeForwardDirection);
    }

    updateIsAllReverseSelected() {
        this.isAllReverseSelected = this.groupMeasuringPoints && !this.groupMeasuringPoints.find(x => !x.includeReverseDirection);
    }

    updateIsAllSumSelected() {
        this.isAllSumSelected = this.groupMeasuringPoints && !this.groupMeasuringPoints.find(x => !x.includeSum);
    }

    bulkSelectChanged(direction: string) {
        if (this.eventService.isBulkUpdating()) return;

        this.eventService.setIsBulkUpdating(true);

        if (direction === ReportDirection.Forward) this.handleIsAllForwardChanged();
        if (direction === ReportDirection.Reverse) this.handleIsAllReverseChanged();
        if (direction === ReportDirection.Sum) this.handleIsAllSumChanged();

        this.eventService.setIsBulkUpdating(false);
    }

    handleIsAllForwardChanged() {
        this.groupMeasuringPoints.forEach(x => {
            if (x.includeForwardDirection === this.isAllForwardSelected) return;

            this.zone.run(() => {
                x.includeForwardDirection = this.isAllForwardSelected;
            });
        });

        this.updateMeasuringPointInGroup();
    }

    handleIsAllReverseChanged() {
        this.groupMeasuringPoints.forEach(x => {
            if (x.includeReverseDirection === this.isAllReverseSelected) return;

            this.zone.run(() => {
                x.includeReverseDirection = this.isAllReverseSelected;
            });
        });

        this.updateMeasuringPointInGroup();
    }

    handleIsAllSumChanged() {
        this.groupMeasuringPoints.forEach(x => {
            if (x.includeSum === this.isAllSumSelected) return;

            this.zone.run(() => {
                x.includeSum = this.isAllSumSelected;
            });
        });

        this.updateMeasuringPointInGroup();
    }

    updateMeasuringPointsSelection(group: IGroup) {
        if (!this.groupMeasuringPoints || this.groupMeasuringPoints.length === 0 || !group || !group.measuringPoints) {
            return;
        }

        for (const groupMeasuringPoint of this.groupMeasuringPoints) {
            const existing = group.measuringPoints.find(x => x.measuringPoint.id === groupMeasuringPoint.measuringPoint.id);

            if (existing) {
                groupMeasuringPoint.includeForwardDirection = existing.includeForwardDirection;
                groupMeasuringPoint.includeReverseDirection = existing.includeReverseDirection;
                groupMeasuringPoint.includeSum = existing.includeSum;
            } else {
                // removed completely from table bellow
                groupMeasuringPoint.includeForwardDirection = false;
                groupMeasuringPoint.includeReverseDirection = false;
                groupMeasuringPoint.includeSum = false;
            }
        }
    }
}