<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [totalRecords]="data?.length"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         (onRowReorder)="reorder()"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         sortField="sortOrder"
         sortOrder="1"
         dataKey="measuringPoint.id"
         class="m-layout-area-body m-layout-default"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn;let i = index"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                [ngSwitch]="column.field">

                <div class="m-column-header">

                    <span class="m-column-title"
                          style="text-align: center;"
                          *ngSwitchCase="'includeForwardDirection'">

                        <app-svg-icon icon="arrow-right"
                                      tooltip="{{'measuringPoints.primaryDirection' | translate}}">
                        </app-svg-icon>

                        <input type="checkbox"
                               [disabled]="!data?.length"
                               [(ngModel)]="isAllForwardSelected"
                               (click)="stopEvent($event)"
                               (change)="bulkSelectChanged('forward')" />
                    </span>


                    <span class="m-column-title"
                          style="text-align: center;"
                          *ngSwitchCase="'includeReverseDirection'">

                        <app-svg-icon icon="arrow-left"
                                      tooltip="{{'measuringPoints.reverseDirection' | translate}}">
                        </app-svg-icon>

                        <input type="checkbox"
                               [disabled]="!data?.length"
                               [(ngModel)]="isAllReverseSelected"
                               (click)="stopEvent($event)"
                               (change)="bulkSelectChanged('reverse')" />
                    </span>

                    <span class="m-column-title"
                          style="text-align: center;"
                          *ngSwitchCase="'includeSum'">

                        <app-svg-icon icon="arrow-sum"
                                      tooltip="{{'measuringPoints.sumDirection' | translate}}">
                        </app-svg-icon>

                        <input type="checkbox"
                               [disabled]="!data?.length"
                               [(ngModel)]="isAllSumSelected"
                               (click)="stopEvent($event)"
                               (change)="bulkSelectChanged('sum')" />
                    </span>

                    <ng-container *ngSwitchDefault>
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <span *ngIf="i==0"
                                  class="m-column-title"
                                  style="text-align: center;">
                                <a (click)="alphabeticalSorting()">
                                    <app-svg-icon icon="a-z-sortIcon"
                                                  tooltip="{{'measuringPoints.a-zSorting' | translate}}">
                                    </app-svg-icon>
                                </a>
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </ng-container>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-groupMeasuringPoint
                 let-index="rowIndex">
        <tr [pSelectableRow]="groupMeasuringPoint"
            [pReorderableRow]="index">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">
                <ng-container *ngSwitchCase="'reorder'">
                    <div class="flex justify-center"
                         style="width: 100%;">
                        <i class="fa fa-bars"
                           pReorderableRowHandle></i>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'sortOrder'">
                    {{groupMeasuringPoint.sortOrder}}
                </ng-container>
                <ng-container *ngSwitchCase="'includeForwardDirection'">
                    <input type="checkbox"
                           [(ngModel)]="groupMeasuringPoint.includeForwardDirection"
                           (change)="updateInGroup(groupMeasuringPoint, 'forward')" />
                </ng-container>

                <ng-container *ngSwitchCase="'includeReverseDirection'">
                    <input type="checkbox"
                           [(ngModel)]="groupMeasuringPoint.includeReverseDirection"
                           (change)="updateInGroup(groupMeasuringPoint, 'reverse')" />
                </ng-container>

                <ng-container *ngSwitchCase="'includeSum'">
                    <input type="checkbox"
                           [(ngModel)]="groupMeasuringPoint.includeSum"
                           (change)="updateInGroup(groupMeasuringPoint, 'sum')" />
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.analysisTypeId'">
                    <span class="m-circle m-circle-grey center">
                        <app-svg-icon [icon]="groupMeasuringPoint.measuringPoint.analysisTypeId"
                                      [tooltip]="getMeasuringPointAnalysisTypeTranslation(groupMeasuringPoint.measuringPoint.analysisTypeId)">
                        </app-svg-icon>
                    </span>
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.location.code'">
                    {{groupMeasuringPoint.measuringPoint.location.code}}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.code'">
                    {{groupMeasuringPoint.measuringPoint.code}}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.description'">
                    {{groupMeasuringPoint.measuringPoint.description}}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.fromTo'">
                    {{groupMeasuringPoint.measuringPoint.from}}
                    <span class="m-arrow-right"
                          *ngIf="groupMeasuringPoint.measuringPoint.from || groupMeasuringPoint.measuringPoint.to"></span>
                    {{groupMeasuringPoint.measuringPoint.to}}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.location.owner'">
                    <app-navigator [organizationId]="groupMeasuringPoint.measuringPoint.ownerId"
                                   [enabled]="navigation"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{groupMeasuringPoint[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>