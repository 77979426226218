<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         class="m-layout-area-body  m-border-top"
         [styleClass]="styleClass"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [customSort]="selectionBox"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         sortField="timestamp"
         dataKey="id"
         #tableRef>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [ngSwitch]="column.field">
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-taskStatusComparison
                 let-expanded="expanded">
        <tr #row
            [pSelectableRow]="taskStatusComparison"
            [pSelectableRowDisabled]="!canSelectRow(taskStatusComparison)"
            [attr.id]="taskStatusComparison[table.dataKey]">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [ngClass]="resizable ? 'ui-resizable-column' : ''">

                <ng-container *ngSwitchCase="'expand'">
                    <a *ngIf="taskStatusComparison.statusType === 'taskModification' && taskStatusComparison.modifications.length>0"
                       [pRowToggler]="taskStatusComparison">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'timestamp'">
                    {{ taskStatusComparison.timestamp | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>
                <ng-container *ngSwitchCase="'creator'">
                    {{taskStatusComparison.worker ? taskStatusComparison.worker.firstName + " "+taskStatusComparison.worker.lastName : (taskStatusComparison.user ? taskStatusComparison.user.firstName + " "+taskStatusComparison.user.lastName : "general.unknown" | translate)}}
                </ng-container>
                <ng-container *ngSwitchCase="'organization'">
                    <app-navigator [organizationId]="taskStatusComparison.task?.assignment?.project?.organization?.id"
                                   [enabled]="true"></app-navigator>
                </ng-container>
                <ng-container *ngSwitchCase="'statusType'">
                    <app-domain-data-field [enumKey]="taskStatusComparison.statusType.toString()"
                                           domainDataType="taskStatusType">
                    </app-domain-data-field>
                </ng-container>
                <ng-container *ngSwitchCase="'assignment'">
                    <app-navigator [assignment]="taskStatusComparison.task?.assignment"
                                   [enabled]="true"></app-navigator>
                </ng-container>
                <ng-container *ngSwitchCase="'task'">
                    <app-navigator [task]="taskStatusComparison.task"
                                   [enabled]="true"></app-navigator>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{taskStatusComparison[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>

    <!-- Row Details -->
    <ng-template pTemplate="rowexpansion"
                 let-taskStatusComparison
                 let-columns="columns">
        <app-task-status-differences [modifications]="taskStatusComparison.modifications"
                                     class="flex flex-column p-16 m-border-top-dashed m-border-bottom-dashed"></app-task-status-differences>
    </ng-template>
</p-table>