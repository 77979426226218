import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MainScreenMapComponent } from "./components/main-screen-map/main-screen-map.component";
import { DevicesComponent } from "../shared/components/devices/devices.component";
import { GroupsComponent } from "../shared/components/groups/groups.component";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";
import { AuthGuard } from "src/app/guards/auth.guard";
import { MultipleOrganizationsGuard } from "src/app/guards/multiple-organizations.guard";

import { DeviceAlarmOverviewComponent } from "../devices/components/device-alarm-overview/device-alarm-overview.component";
import { DeviceLocationComponent } from "../devices/components/device-location/device-location.component";
import { DeviceAnprComponent } from "../devices/components/device-anpr/device-anpr.component";
import { DeviceCameraComponent } from "../devices/components/device-camera/device-camera.component";
import { DeviceCommandsComponent } from "../devices/components/device-commands/device-commands.component";
import { DeviceDetailComponent } from "../devices/components/device-detail/device-detail.component";
import { DeviceDisplayContainerComponent } from "../devices/components/device-display-container/device-display-container.component";
import { DeviceDisplayComponent } from "../devices/components/device-display/device-display.component";
import { DeviceLinksComponent } from "../devices/components/device-links/device-links.component";
import { DeviceOtaConfigurationComponent } from "../devices/components/device-ota-configuration/device-ota-configuration.component";
import { DeviceRealtimeAnalyzerConfigurationComponent } from "../devices/components/device-realtime-analyzer-configuration/device-realtime-analyzer-configuration.component";
import { DeviceRelayConfigurationComponent } from "../devices/components/device-relay-configuration/device-relay-configuration.component";
import { DeviceStatusContainerComponent } from "../devices/components/device-status-container/device-status-container.component";
import { DeviceStatusOverviewComponent } from "../devices/components/device-status-overview/device-status-overview.component";
import { DeviceStatusComponent } from "../devices/components/device-status/device-status.component";
import { DeviceTmsRadarConfigurationComponent } from "../devices/components/device-tms-radar-configuration/device-tms-radar-configuration.component";
import { DeviceUploadContainerComponent } from "../devices/components/device-upload-container/device-upload-container.component";
import { DeviceUploadComponent } from "../devices/components/device-upload/device-upload.component";
import { DeviceZigbeeConfigurationComponent } from "../devices/components/device-zigbee-configuration/device-zigbee-configuration.component";
import { DeviceDisplayEventsComponent } from "../shared/components/device-display-events/device-display-events.component";

import { RootRedirectGuard } from "src/app/guards/root-redirect.guard";
import { AssignmentDetailComponent } from "../assignments/components/assignment-detail/assignment-detail.component";
import { AssignmentLocationComponent } from "../assignments/components/assignment-location/assignment-location.component";
import { AssignmentsHistoryComponent } from "../assignments/components/assignments-history/assignments-history.component";
import { AssignmentsComponent } from "../assignments/components/assignments/assignments.component";
import { ParkingBanDetailComponent } from "../assignments/components/parking-ban-detail/parking-ban-detail.component";
import { ParkingBanExceptionsContainerComponent } from "../assignments/components/parking-ban-exceptions-container/parking-ban-exceptions-container.component";
import { ParkingBanImagesContainerComponent } from "../assignments/components/parking-ban-images-container/parking-ban-images-container.component";
import { ParkingBanLocationComponent } from "../assignments/components/parking-ban-location/parking-ban-location.component";
import { ParkingBansContainerComponent } from "../assignments/components/parking-bans-container/parking-bans-container.component";
import { SignScansContainerComponent } from "../assignments/components/sign-scans-container/sign-scans-container.component";
import { TaskAttachmentsComponent } from "../assignments/components/task-attachments/task-attachments.component";
import { TaskDetailComponent } from "../assignments/components/task-detail/task-detail.component";
import { TasksHistoryComponent } from "../assignments/components/task-history/tasks-history.component";
import { TaskLocationComponent } from "../assignments/components/task-location/task-location.component";
import { TasksContainerComponent } from "../assignments/components/tasks-container/tasks-container.component";
import { DeviceAvsConfigurationComponent } from "../devices/components/device-avs-configuration/device-avs-configuration.component";
import { DeviceDekimoDisplayConfigurationComponent } from "../devices/components/device-dekimo-display-configuration/device-dekimo-display-configuration.component";
import { DeviceVehicleConfigurationComponent } from "../devices/components/device-vehicle-configuration/device-vehicle-configuration.component";
import { AuditTrailsComponent } from "../shared/components/audit-trails/audit-trails.component";
import { JournalComponent } from "../shared/components/journal/journal.component";
import { UploadMetricComponent } from "src/app/modules/shared/components/upload-metric/upload-metric.component";

/**
 * Several modules have a route that starts with `/map`. The routing of all these modules is handled here.
 * TODO The routing should be handled in the modules themselves. However, I can't get this to work.
 */
const routes: Routes = [
    {
        path: "", component: MainScreenMapComponent, data: { name: "map.title" }, children: [
            { path: "", redirectTo: "devices", pathMatch: "full" },
            { path: "groups", component: GroupsComponent, data: { name: "groups.title" }, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
            { path: "devices", component: DevicesComponent, data: { name: "devices.title" }, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
            { path: "assignments", component: AssignmentsComponent, data: { name: "assignment.title" }, canActivate: [RootRedirectGuard], canDeactivate: [PendingChangesGuard] },
        ]
    },
    {
        path: "devices/:deviceId", canActivate: [AuthGuard], component: DeviceLocationComponent, children: [
            { path: "", redirectTo: "details", pathMatch: "full" },
            { path: "details", component: DeviceDetailComponent, canDeactivate: [PendingChangesGuard] },
            { path: "alarms", component: DeviceAlarmOverviewComponent, canDeactivate: [PendingChangesGuard] },
            {
                path: "status", component: DeviceStatusContainerComponent, children: [
                    { path: "", redirectTo: "overview", pathMatch: "full" },
                    { path: "overview", component: DeviceStatusOverviewComponent },
                    { path: "status", component: DeviceStatusComponent }
                ]
            },
            {
                path: "upload", component: DeviceUploadContainerComponent, children: [
                    { path: "", redirectTo: "metric", pathMatch: "full" },
                    { path: "metric", component: UploadMetricComponent },
                    { path: "uploads", component: DeviceUploadComponent }
                ]
            },
            { path: "links", component: DeviceLinksComponent },
            { path: "anpr", component: DeviceAnprComponent, canDeactivate: [PendingChangesGuard] },
            { path: "realtimeanalyzer", component: DeviceRealtimeAnalyzerConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "vehicle", component: DeviceVehicleConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "camera", component: DeviceCameraComponent, canDeactivate: [PendingChangesGuard] },
            { path: "ota", component: DeviceOtaConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "avs", component: DeviceAvsConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "commands", component: DeviceCommandsComponent },
            { path: "relay", component: DeviceRelayConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "zigbee", component: DeviceZigbeeConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "tmsradar", component: DeviceTmsRadarConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            {
                path: "display", component: DeviceDisplayContainerComponent, children: [
                    { path: "", redirectTo: "configuration", pathMatch: "full" },
                    { path: "configuration", component: DeviceDisplayComponent },
                    { path: "events", component: DeviceDisplayEventsComponent }
                ]
            },
            { path: "dekimodisplay", component: DeviceDekimoDisplayConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "journal", component: JournalComponent },
            { path: "audit", component: AuditTrailsComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: "assignments/:assignmentId", canActivate: [AuthGuard], component: AssignmentLocationComponent, children: [
            { path: "", redirectTo: "details", pathMatch: "full" },
            { path: "details", component: AssignmentDetailComponent, canDeactivate: [PendingChangesGuard] },
            { path: "tasks", component: TasksContainerComponent },
            { path: "parkingbans", component: ParkingBansContainerComponent },
            { path: "journal", component: JournalComponent },
            { path: "audit", component: AuditTrailsComponent, canActivate: [AuthGuard] },
            { path: "workHistory", component: TasksHistoryComponent, canActivate: [AuthGuard] },
            { path: "history", component: AssignmentsHistoryComponent, canActivate: [AuthGuard] },

            { path: "task/:taskId", redirectTo: "/map/task/:taskId/details" },

            { path: "parkingban/:parkingBanId", redirectTo: "/map/parkingban/:parkingBanId/details" }
        ],
    },
    {
        path: "task/:taskId", canActivate: [AuthGuard], component: TaskLocationComponent, children: [
            { path: "", redirectTo: "details", pathMatch: "full" },
            { path: "details", component: TaskDetailComponent, canDeactivate: [PendingChangesGuard] },
            { path: "attachments", component: TaskAttachmentsComponent },
            { path: "history", component: TasksHistoryComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: "parkingban/:parkingBanId", component: ParkingBanLocationComponent, canActivate: [AuthGuard], children: [
            { path: "", redirectTo: "details", pathMatch: "full" },
            { path: "details", component: ParkingBanDetailComponent, canDeactivate: [PendingChangesGuard], canActivate: [AuthGuard] },
            { path: "photos", component: ParkingBanImagesContainerComponent, canActivate: [AuthGuard] },
            { path: "signscans", component: SignScansContainerComponent, canActivate: [AuthGuard] },
            { path: "parkingbanexceptions", component: ParkingBanExceptionsContainerComponent, canActivate: [AuthGuard] },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MapAdvancedRoutingModule { }
