import { Component, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { AttachmentCreator, IAttachment } from "src/app/models/attachment";
import { ViewModelEnum } from "src/app/models/domain-data";
import { SigncoFormGroup } from "src/app/models/form";
import { IWorker, WorkerCreator } from "src/app/models/worker";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { PhotoInputComponent } from "src/app/modules/shared/components/photo-input/photo-input.component";
import { WorkerApi } from "src/app/resource/worker.api";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { MapDataService } from "src/app/services/map-data.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { JsonUtils, OrganizationUtils } from "src/app/utilities";
@Component({
    selector: "app-manage-worker-dialog",
    templateUrl: "./manage-worker-dialog.component.html"
})
export class ManageWorkerDialogComponent extends DialogComponentBase implements OnDestroy {
    @ViewChild(PhotoInputComponent, { static: false }) photoInput: PhotoInputComponent;
    callback: (res: IWorker) => void;
    submitting: boolean;
    displayOrganizations: boolean;
    workerForm: SigncoFormGroup;
    existingWorker: IWorker;
    photo: IAttachment;

    calendarSettings: CalendarSettings;
    contractTypes: ViewModelEnum[];
    organizations: SelectItem[];

    private readonly mapDataKey: string;

    constructor(
        readonly primeComponentService: PrimeComponentService,
        private readonly domainDataService: DomainDataService,
        private readonly mapDataService: MapDataService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly globalEventsService: GlobalEventsService,
        private readonly workerApi: WorkerApi,
        private readonly formValidationService: FormValidationService,
        private readonly toastService: ToastService
    ) {
        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe((calendarSettings) => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.domainDataService.get(DomainData.WorkerContractType).then(contractTypes => {
            this.contractTypes = contractTypes;
        });

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    create(callback?: (res: IWorker) => void) {
        this.callback = callback;
        this.existingWorker = { organizationId: this.globalEventsService.getDefaultOrganization()?.id } as IWorker;

        this.openDialog();
    }

    edit(existingWorker: IWorker, callback?: (res: IWorker) => void) {
        this.callback = callback;
        this.existingWorker = existingWorker;

        this.openDialog();
    }

    protected onOpen() {
        this.initialize();
    }

    protected onClose() {
        this.workerForm = null;
    }

    private initialize() {
        if (!this.existingWorker) return;

        this.workerForm = this.formBuilder.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            organizationId: null,
            email: "",
            phoneNumberHome: "",
            phoneNumberWork: "",
            address: "",
            nationalNumber: "",
            inServiceFrom: [null, Validators.required],
            outOfServiceFrom: null,
            driverLicenseNumber: "",
            hasCode95: null,
            code95StartDate: null,
            code95EndDate: null,
            hasADRCertificate: null,
            adrStartDate: null,
            adrEndDate: null,
            hasVCACertificate: null,
            vcaStartDate: null,
            vcaEndDate: null,
            medicalFitnessChecked: null,
            medicalFitnessStartDate: null,
            medicalFitnessEndDate: null,
            canDoControl: false
        }) as SigncoFormGroup;

        if (this.existingWorker) {
            this.workerForm.patchValue(this.existingWorker);
            this.photo = this.hasPhoto(this.existingWorker) ?
                JsonUtils.deepClone(this.existingWorker.photos[0]) :
                undefined;
            this.workerForm.markAsPristine();
        }

        this.displayOrganizations = this.globalEventsService.hasMultipleOrganizations();
    }

    private hasPhoto(worker: IWorker) {
        return worker.photos && worker.photos.length > 0;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.workerForm);
        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNewWorker();
        } else {
            this.saveWorker();
        }
    }

    isCreatingNew(): boolean {
        return this.existingWorker && !this.existingWorker.id; // check if id is falsy
    }

    private createNewWorker() {
        const onSuccess = async (newWorker: IWorker) => this.onSaveSuccess(newWorker);
        const workerCreator = Object.assign(new WorkerCreator(), this.workerForm.value) as WorkerCreator;

        this.submitting = true;
        this.workerApi
            .create$(workerCreator)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    private async saveWorker() {
        const onSuccess = async (savedWorker: IWorker) => this.onSaveSuccess(savedWorker);
        const updatedWorker = Object.assign({}, this.existingWorker, this.workerForm.value);

        this.submitting = true;
        this.workerApi
            .update$(updatedWorker)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    async onSaveSuccess(savedWorker: IWorker) {
        if (!this.photoInput.image) {
            // if there is no loaded image then there is nothing to upload
            await this.deletePhoto();

            // because first we do PUT then delete for image
            // response from PUT will return image because at that moment it isn't deleted yet
            savedWorker.photos = [];
        } else {
            // this call is doing replace as well (if image for worker is replaced with new one)
            const newAttachment = await this.uploadPhoto(savedWorker.id);
            savedWorker.photos = [];
            savedWorker.photos.push(newAttachment);
        }

        this.existingWorker = savedWorker;
        this.workerForm.markAsPristine();
        this.toastService.saveSuccess();
        this.submitting = false;
        this.close();
        if (this.callback) {
            this.callback(savedWorker);
        }
    }

    async onSaveError() {
        this.submitting = false;
    }

    private async uploadPhoto(workerId: number): Promise<IAttachment> {
        const creator = new AttachmentCreator();
        creator.name = `worker_${this.workerForm.controls["firstName"].value}`;
        creator.workerId = workerId;
        return this.photoInput.uploadPhoto(creator);
    }

    private async deletePhoto() {
        await this.photoInput.deletePhoto();
    }

    reloadAfterPhotoDeleted() {
        this.existingWorker.photos = undefined;
    }

    hasCode95Changed(event) {
        if (!this.workerForm.get("hasCode95").value) {
            this.workerForm.get("code95StartDate").setValue(null);
            this.workerForm.get("code95EndDate").setValue(null);
        }
    }

    hasVCACertificateChanged(event) {
        if (!this.workerForm.get("hasVCACertificate").value) {
            this.workerForm.get("vcaStartDate").setValue(null);
            this.workerForm.get("vcaEndDate").setValue(null);
        }
    }

    hasADRCertificateChanged(event) {
        if (!this.workerForm.get("hasADRCertificate").value) {
            this.workerForm.get("adrStartDate").setValue(null);
            this.workerForm.get("adrEndDate").setValue(null);
        }
    }

    hasMedicalFitnessChanged(event) {
        if (!this.workerForm.get("medicalFitnessChecked").value) {
            this.workerForm.get("medicalFitnessStartDate").setValue(null);
            this.workerForm.get("medicalFitnessEndDate").setValue(null);
        }
    }
}