<div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top">
    <button class="m-btn m-btn-secondary"
            (click)="addReleaseConfiguration()"
            id="releases_button">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"releases.title" | translate}}
    </button>
</div>

<div class="m-layout-area-body m-layout-w-actions-bottom m-border-top">
    <form [formGroup]="otaConfigurationForm"
          class="m-layout-area-body m-overflow-auto"
          *ngIf="otaConfigurationForm"
          autocomplete="off">

        <ng-container formGroupName="releaseConfigurations">

            <div *ngFor="let releaseConfigurationForm of releaseConfigurationForms.controls; let i = index; let first = first"
                 formGroupName="{{i}}">

                <div class="flex align-center px-16 py-8">
                    <div class="mr-16">
                        <h3>{{"deviceFeatures.otaUpdate" | translate}} {{i+1}}</h3>
                    </div>

                    <div class="ml-auto">
                        <app-icon-button *ngIf="releaseConfigurationForms.controls.length > 0"
                                         icon="delete"
                                         text="form.delete"
                                         (click)="deleteReleaseConfiguration(i)">
                        </app-icon-button>
                    </div>
                </div>

                <div class="px-16">
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': releaseConfigurationForm.get('packageId').showErrors }">

                        <label class="col-4"
                               for="packageId">
                            {{"releases.package" | translate}}
                        </label>

                        <div class="col-8">
                            <p-dropdown id="packageId{{i}}"
                                        [options]="packages"
                                        [panelStyle]="{'width':'100%'}"
                                        [autoDisplayFirst]="false"
                                        (onChange)="onPackageUpdate(releaseConfigurationForm)"
                                        placeholder="{{'releases.package' | translate}}"
                                        formControlName="packageId"
                                        #packageIdInput></p-dropdown>

                            <app-form-validation [control]="releaseConfigurationForm.get('packageId')"
                                                 [input]="packageIdInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': releaseConfigurationForm.get('releaseChannelId').showErrors }">

                        <label class="col-4"
                               for="releaseChannelId">
                            {{"releases.channel" | translate}}
                        </label>

                        <div class="col-8">
                            <p-dropdown id="releaseChannelId{{i}}"
                                        [options]="releaseChannels"
                                        [panelStyle]="{'width':'100%'}"
                                        [autoDisplayFirst]="false"
                                        (onChange)="onPackageUpdate(releaseConfigurationForm)"
                                        placeholder="{{'releases.channel' | translate}}"
                                        formControlName="releaseChannelId"
                                        #releaseChannelIdInput></p-dropdown>

                            <app-form-validation [control]="releaseConfigurationForm.get('releaseChannelId')"
                                                 [input]="releaseChannelIdInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row">

                        <label class="col-4"
                               for="specificReleaseId">
                            {{"releases.version" | translate}}
                        </label>

                        <div class="col-8">
                            <div class="row">
                                <div class="col-12">
                                    <p-dropdown id="specificReleaseId{{i}}"
                                                [options]="releases[releaseConfigurationForm.get('packageId').value]"
                                                [panelStyle]="{'width':'100%'}"
                                                [autoDisplayFirst]="true"
                                                placeholder="{{'applications.rights.release' | translate}}"
                                                [showClear]="true"
                                                formControlName="specificReleaseId"
                                                #specificReleaseIdInput></p-dropdown>

                                    <app-form-validation [control]="releaseConfigurationForm.get('specificReleaseId')"
                                                         [input]="specificReleaseIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="dekimoGuiConfigurationForm.enabled || dekimoTrackerConfigurationForm.enabled">
            <div class="p-16 ">
                <h3>{{"devices.dekimoTitle" | translate}}</h3>
            </div>

            <div class="px-16">
                <div *ngIf="dekimoGuiConfigurationForm.enabled"
                     formGroupName="dekimoGuiConfiguration"
                     class="m-form-group row">

                    <label class="col-4"
                           for="password">
                        {{"devices.dekimoGuiPassword" | translate}}
                    </label>

                    <div class="col-8">
                        <input id="guiPassword"
                               type="text"
                               class="p-inputtext"
                               formControlName="password"
                               #guiPasswordInput>

                        <app-form-validation [control]="dekimoGuiConfigurationForm.get('password')"
                                             [input]="guiPasswordInput">
                        </app-form-validation>
                    </div>
                </div>

                <div *ngIf="dekimoTrackerConfigurationForm.enabled"
                     formGroupName="dekimoTrackerConfiguration"
                     class="m-form-group row">

                    <label class="col-4"
                           for="password">
                        {{"devices.dekimoTrackerPassword" | translate}}
                    </label>

                    <div class="col-8">
                        <input id="password"
                               type="text"
                               class="p-inputtext"
                               formControlName="password"
                               #trackerPasswordInput>

                        <app-form-validation [control]="dekimoTrackerConfigurationForm.get('password')"
                                             [input]="trackerPasswordInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row">
                    <div class="col-12">
                        <button class="m-btn m-btn-secondary"
                                style="padding:0"
                                (click)="openHardwareConfigurationDialog()">
                            <app-svg-icon icon="info"></app-svg-icon>
                            {{"hardwareConfiguration.open" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>

    <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">
                {{"form.reset" | translate}}
            </button>

            <button [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit">
                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>
                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>

<app-device-hardware-configuration-dialog></app-device-hardware-configuration-dialog>