import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-tube-xpos-configuration",
    templateUrl: "./device-link-tube-xpos-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkTubeXPosConfigurationComponent extends DeviceLinkFormGroupComponent {
    @Input() tubeOptions: SelectItem[];
    @Input() tubeOptionsWithEmpty: SelectItem[];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.tubeXPosConfiguration) {
            if (this.fgd.form.get("tubeXPosConfiguration")) this.fgd.form.removeControl("tubeXPosConfiguration");

            this.form = this.formBuilder.group({
                tube3: this.tubeOptions[2].value,
                tube4: this.tubeOptions[3].value,
                tube3XOffsetCm: null,
                tube3YOffsetCm: null,
                tube3AngleDegree: null,
                tube4XOffsetCm: null,
                tube4YOffsetCm: null,
                tube4AngleDegree: null,
                drivingLaneWidthCm: [null, [Validators.required, Validators.min(1)]]
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.tubeXPosConfiguration);
            this.fgd.form.addControl("tubeXPosConfiguration", this.form);
            return true;
        }

        if (this.fgd.form.get("tubeXPosConfiguration")) this.fgd.form.removeControl("tubeXPosConfiguration");
        this.form = null;
        return false;
    }
}
