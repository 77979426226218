import { IPredictionModel, IPredictDaysData } from "src/app/models/prediction";
import { ApiServiceBase } from "src/app/resource/api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IProgressCreated } from "../models/progress";

@Injectable({
    providedIn: "root"
})
export class PredictionApi extends ApiServiceBase {

    getRoute(): string {
        return "PredictionModels";
    }

    predictDays$(predictionModel: IPredictionModel, predictDaysData: IPredictDaysData): Observable<void> {
        const url = `${super.getUrl()}/${predictionModel.id}/PredictDays`;
        return this.http.post<void>(url, predictDaysData);
    }

    createModelsAndPredictDaysWithProgress$(predictDaysData: IPredictDaysData[]): Observable<IProgressCreated> {
        const url = `${super.getUrl()}/CreateModelsAndPredictDaysWithProgress`;
        return this.http.post<IProgressCreated>(url, predictDaysData);
    }

    revertPredictedDaysWithProgress$(measuringPointId: number, datesToBeReverted: Date[]): Observable<IProgressCreated> {
        const url = `${super.getUrl()}/${measuringPointId}/RevertPredictedDaysWithProgress`;
        return this.http.post<IProgressCreated>(url, datesToBeReverted);
    }
}
