import { IServiceModelBase } from "src/app/models/servicemodelbase";
import { IAssignment } from "./assignment";
import { ILocation } from "./location";
import { IOrganization, IUser, IUserSummary } from "./user";
import { IGroup } from "./group";
import { IMeasuringPoint } from "./measuring-point";
import { IUserProject } from "./user-project";

export interface IProjectNavigator {
    id: number;
    name: string;
    organizationId: number;
    isObsolete?: boolean;
    isActive: boolean;
}

export interface IProject extends IServiceModelBase {
    name: string;
    description: string;
    logoUrl: string;
    email: string;
    address: string;
    website: string;
    from: Date;
    until: Date;
    organizationId: number;
    hasSubProjects: boolean;
    hasParentProject: boolean;
    checkInAtWorkEnabled: boolean;
    checkInAtWorkNumber: string;
    photoPreview: string; // GUI-only

    contractTypeId: ProjectContractType;
    parentProject: IProject;
    subProjects: IProject[];
    currentStatus: IProjectStatusHistory;
    organization: IOrganization;
    users: IUserProject[];
    isContextOfAssignment: boolean;
    automaticAssignmentNumbering: boolean;
    isMaasProject: boolean;
    isMeasurementProject: boolean;
    isActive: boolean;

    assignments: IAssignment[];
    location: ILocation;

    projectGroups: IProjectGroup[];
    projectMeasuringPoints: IProjectMeasuringPoint[];
}

export interface IProjectGroup {
    projectId: number;
    project: IProject;

    groupId: number;
    group: IGroup;
}

export interface IProjectMeasuringPoint {
    projectId: number;
    project: IProject;

    measuringPointId: number;
    measuringPoint: IMeasuringPoint;

    sortOrder?: number;
}

export class ProjectCreator {
    name: string;
    description: string;
    logoUrl: string;
    email: string;
    location: ILocation;
    website: string;
    from: Date;
    until: Date;
    checkInAtWorkEnabled: boolean;
    checkInAtWorkNumber: string;

    contractTypeId?: ProjectContractType;
    organizationId?: number;
    parentProjectId?: number;
    currentStatusId: ProjectStatus;
    isContextOfAssignment: boolean;
    automaticAssignmentNumbering: boolean;
    isMaasProject: boolean;
    isMeasurementProject: boolean;

    measuringPointsWithOrder: IProjectMeasuringPoint[];
    groupIds: number[];
}

export class ProjectUpdater extends ProjectCreator {
    constructor(existing: IProject) {
        super();
        this.id = existing.id;
        this.name = existing.name;
        this.email = existing.email;
        this.location = existing.location;
        this.website = existing.website;
        this.contractTypeId = existing.contractTypeId;
        this.description = existing.description;
        this.from = existing.from;
        this.until = existing.until;
        this.checkInAtWorkEnabled = existing.checkInAtWorkEnabled;
        this.checkInAtWorkNumber = existing.checkInAtWorkNumber;
        this.organizationId = existing.organizationId;
        this.parentProjectId = existing.parentProject
            ? existing.parentProject.id
            : undefined;
        this.currentStatusId = existing.currentStatus
            ? existing.currentStatus.statusId
            : undefined;
        this.isContextOfAssignment = existing.isContextOfAssignment;
        this.automaticAssignmentNumbering = existing.automaticAssignmentNumbering;
        this.isMaasProject = existing.isMaasProject;
        this.isMeasurementProject = existing.isMeasurementProject;

        if (existing.projectGroups) {
            this.groupIds = existing.projectGroups.map(x => x.groupId);
        }

        this.measuringPointsWithOrder = existing.projectMeasuringPoints;
    }

    id: number;
}

export interface IProjectStatusHistory extends IServiceModelBase {
    timestamp: Date;
    assignment: IProject;
    statusId: ProjectStatus;
}

export enum ProjectStatus {
    Active = "active",
    NotStarted = "notStarted",
    Finished = "finished",
    InProgress = "inProgress",
    Discontinued = "discontinued"
}

export enum ProjectContractType {
    Subcontractor = "subcontractor",
    GovernmentAssociate = "governmentAssociate",
}

export enum ProjectRoles {
    AdministrativeResponsible = "administrativeResponsible",
    CustomerResponsible = "customerResponsible",
    ProjectResponsible = "projectResponsible",
}

export interface IProjectRole extends IServiceModelBase {
    name: ProjectRoles;
}
