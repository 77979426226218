<nav #contextMenuDiv
     [ngStyle]="style"
     class="m-nav-context-menu m-card"
     (clickOutside)="close()">
    <ul class="m-nav-list">
        <li class="m-nav-list-item"
            *ngFor="let menuItem of contextMenuItems; let index = index; trackBy: trackByFn">
            <button id="{{'mapContextMenuItem' + index}}"
                    type="button"
                    class="m-btn m-btn-secondary"
                    (click)="onContextMenuItemClick(menuItem, $event)">
                <app-svg-icon *ngIf="menuItem.icon"
                              [icon]="menuItem.icon">
                </app-svg-icon>
                {{menuItem.text}}
            </button>
        </li>
    </ul>
</nav>