import { AsyncValidatorFn, AbstractControl } from "@angular/forms";
import { ExistsResult, ExistsValue } from "../models/search";
import { switchMap, map } from "rxjs/operators";
import { Observable, timer } from "rxjs";

export class ExistsValidator {
    static create(existsCall: (existsValue: ExistsValue) => Observable<ExistsResult>, mergeObject?: () => {}, shouldTrigger?: (value: string) => boolean): AsyncValidatorFn {
        return (c: AbstractControl) => {
            if (!c.value) return Promise.resolve(null);

            let existsValue = new ExistsValue(c.value);
            if (mergeObject) {
                const mergeObjectResult = mergeObject();
                existsValue = Object.assign(existsValue, mergeObjectResult);
            }

            if (shouldTrigger) {
                if (!shouldTrigger(c.value)) return Promise.resolve(null);
            }

            return timer(750).pipe(switchMap(() => {
                return existsCall(existsValue)
                    .pipe(
                        map((existsResult: ExistsResult) => {
                            return existsResult.result ? { exists: true } : null;
                        })
                    );
            }));
        };
    }
}