import { GalleriaComponent, IGalleriaImage } from "src/app/modules/shared/components/galleria/galleria.component";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class GalleriaService {

    private galleria: GalleriaComponent;

    open(images: IGalleriaImage[], indexStart = 0) {
        if (!this.galleria) {
            console.error("Attemped to show galleria before component was registered");
            return;
        }

        this.galleria.show(images, indexStart);
    }

    register(dialog: GalleriaComponent) {
        this.galleria = dialog;
    }
}