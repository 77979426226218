import { ICoordinate } from "./location";

export interface ILocationRecord {
    timestamp: Date;
    coordinate: ICoordinate;
}

export enum LocationLogType {
    Stop = "stop",
    Trip = "trip",
    Unknown = "unknown"
}

export interface ILocationLog {
    type: LocationLogType;
    records: ILocationRecord[];
    distance: number;
    snappedCoordinates: ICoordinate[];
}