import { Injectable, NgZone, inject } from "@angular/core";
import { BasicMapComponent, BasicMarkerEvent } from "../components/basic-map/basic-map.component";
import { IBasicMarker, IMarkerClusterer, createMapClusterer } from "..";
import { Constants } from "src/app/constants/constants";
import { TouchService } from "src/app/services/touch.service";
import { SubscriptionManager } from "src/app/utilities";

/**
 * Handles large amounts of markers on the map. Takes care of:
 * * Clustering (through the IMarkerClusterer)
 * * Subscribing to marker events (and unsubscribing when markers are removed)
 */
@Injectable()
export class MarkerCollection {
    private basicMap: BasicMapComponent;
    private markerClusterer: IMarkerClusterer;

    private readonly zone = inject(NgZone);
    private readonly touchService = inject(TouchService);

    private readonly LONG_TOUCH_TIME = 500;
    private touchStartTime: Date;

    private subscriptionManager = new SubscriptionManager();

    initialize(basicMap: BasicMapComponent) {
        this.basicMap = basicMap;

        const clusterOptions = {
            imagePath: "/assets/img/markers/blue",
            maxZoom: Constants.noClusterZoom - 1, // -1, 16 noClusterZoom means 15 is the last zoom on which it should cluster,
            unloadOutOfRangeMarkers: true
        };

        this.markerClusterer = createMapClusterer(this.basicMap.map, null, clusterOptions);
        this.markerClusterer.setGridSize(60);
    }

    public repaintCluster() {
        if (!this.markerClusterer) return;
        this.markerClusterer.requestRepaint();
    }

    public add(marker: IBasicMarker, addToMap = true) {
        if (addToMap) {
            marker.setMapInternal(this.basicMap.map);
        }

        this.bindMarkerEvents(marker);
        this.markerClusterer.addMarker(marker);
    }

    public getMarkers(): IBasicMarker[] {
        const result = this.markerClusterer.getMarkers();
        return result;
    }

    public clear() {
        if (this.markerClusterer) {
            const markers = this.markerClusterer.getMarkers();
            if (markers) {
                for (const marker of this.markerClusterer.getMarkers()) {
                    marker.disposeSignco();
                    google.maps.event.clearInstanceListeners(marker);
                }
            }

            this.markerClusterer.clear();
        }
    }

    private isLongTouch(): boolean {
        return this.touchStartTime && (new Date().getTime() - this.touchStartTime.getTime()) > this.LONG_TOUCH_TIME;
    }

    private bindMarkerEvents(marker: google.maps.marker.AdvancedMarkerElement) {
        if ((marker as any).boundMarkerCollectionEvents) return;
        (marker as any).boundMarkerCollectionEvents = true;

        this.zone.runOutsideAngular(() => {
            // below line is evil
            // we're also binding events inside basic-marker
            // this decouples *everything* and stops click events
            // if the marker is ever not clickable / hoverable, look here
            // google.maps.event.clearInstanceListeners(marker);

            const onClick = (event: google.maps.MapMouseEvent) => {
                if (this.touchService.isTouchDevice() && this.isLongTouch()) {

                    this.basicMap.markerRightClick.emit(new BasicMarkerEvent(marker, event));
                } else {

                    this.basicMap.markerClick.emit(new BasicMarkerEvent(marker, event));
                }

                this.touchStartTime = null;
            };

            // Some other map events (mouse move, mouse up, drag start, etc) which will happen "at the same time"
            // as a click event will trigger a change detection. As a side effect, the marker click event will also be detected in a change detection cycle.
            // On the other hand, a label is not a part of the map stack, it belongs to a separate layer and that's why its click is handled differently.
            marker.addListener("click", (event: google.maps.MapMouseEvent) => {
                onClick(event);
            });

            // Explicitly run onClick event in the zone to make sure that change detection will be triggered.
            marker.addListener("labelclick", (event: google.maps.MapMouseEvent) => {
                this.zone.run(() => {
                    onClick(event);
                });
            });

            marker.addListener("mousedown", (event: google.maps.MapMouseEvent) => {
                this.touchStartTime = new Date();
            });

            marker.addListener("rightclick", (event: google.maps.MapMouseEvent) => {
                this.basicMap.markerRightClick.emit(new BasicMarkerEvent(marker, event));
            });

            if (marker.draggable) {
                marker.addListener("dragstart", (event: google.maps.MapMouseEvent) => {
                    this.basicMap.markerDragStart.emit(new BasicMarkerEvent(marker, event));
                });

                marker.addListener("dragend", (event: google.maps.MapMouseEvent) => {
                    this.basicMap.markerDragEnd.emit(new BasicMarkerEvent(marker, event));
                });
            }
        });
    }

    public dispose() {
        if (!this.basicMap) return; // We were not intialized yet
        this.clear();
        this.markerClusterer.dispose();
        this.subscriptionManager.clear();
    }
}