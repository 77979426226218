import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";

import { ShareDataRoutingModule } from "./share-data-routing.module";
import { SharePageComponent } from "./components/share-page/share-page.component";

import { SharedKeysComponent } from "./components/shared-keys/shared-keys.component";
import { ImportedKeysComponent } from "./components/imported-keys/imported-keys.component";
import { ManageSharedKeyComponent } from "./components/manage-shared-key/manage-shared-key.component";

@NgModule({
    imports: [
        ShareDataRoutingModule,
        SharedModule,
        MapAdvancedModule
    ],
    declarations: [
        SharePageComponent,
        SharedKeysComponent,
        ImportedKeysComponent,
        ManageSharedKeyComponent
    ]
})
export class ShareDataModule { }