<div style="display: inline-grid;">
    @if (isExpanded) {
        <h4>{{viewModel.fieldConfiguration.name}}</h4>
    }
    <div class="m-vms-animation-wrapper is-compact" style="place-self: center">
        @if (isExpanded && viewModel.frames.length > 1) {
            <button (click)="moveLeft()"
                    class="m-vms-animation-button m-vms-animation-prev">
                <app-svg-icon icon="chevron-left"
                              class="m-vms-animation-button-icon"
                              divClass="icon-12">
                </app-svg-icon>
            </button>
        }

        <div style="display: inline-grid; grid-auto-flow: column; gap:8px;">
            @if (isLoading) {
                <app-loading-spinner />
            }
            @else {
                <div class="totem-display-frame" [innerHTML]="formatHtml(viewModel.frames[currentIndex]?.text)"></div>
            }
        </div>
        @if (isExpanded && viewModel.frames.length > 1) {
            <button (click)="moveRight()"
                    class="m-vms-animation-button m-vms-animation-next">
                <app-svg-icon icon="chevron-right"
                              class="m-vms-animation-button-icon"
                              divClass="icon-12">
                </app-svg-icon>
            </button>
        }
    </div>
    @if (viewModel.frames.length > 1 && !isLoading) {
        <div>
            {{ currentIndex + 1 }} / {{ viewModel.frames.length }} ({{ viewModel.frames[currentIndex]?.displayTimeMs }} ms)
        </div>
    }
</div>
