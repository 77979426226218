<div class="m-layout-area-body m-layout-w-actions-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top">
        <button class="m-btn m-btn-secondary"
                *ngIf="sendCommand"
                (click)="openDeviceCommandDialog()"
                id="send_command_button">
            <app-svg-icon icon="settings">
            </app-svg-icon>
            {{'deviceCommands.create' | translate}}
        </button>
    </div>

    <p-table id="commands_table"
             [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-layout-default"
             [loading]="loading"
             [totalRecords]="total"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             [lazy]="isLazy"
             (onLazyLoad)="loadTableRows($event)"
             dataKey="id"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer && !paginator">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-command>
            <tr [pSelectableRow]="command">

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <div *ngSwitchCase="'statusId'">
                        <div class="flex justify-center"
                             style="width: 100%;">
                            <app-svg-icon [icon]="getCommandStatusIcon(command)"
                                          [fill]="getCommandStatusColor(command)">
                            </app-svg-icon>
                        </div>
                    </div>

                    <ng-container *ngSwitchCase="'creator'">
                        <app-navigator [user]="command.creator"></app-navigator>
                    </ng-container>

                    <ng-container *ngSwitchCase="'createDate'">
                        {{command[column.field] | momentDateTime:true:true:translateService.currentLang }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'typeId'">
                        <app-domain-data-field [enumKey]="command.typeId"
                                               domainDataType="deviceCommandType">
                        </app-domain-data-field>
                    </ng-container>

                    <ng-container *ngSwitchCase="'command'">
                        <app-icon-button icon="info"
                                         text="deviceCommands.showCommand"
                                         (click)="openCommandJson(command)">
                        </app-icon-button>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{command[column.field]}}
                    </ng-container>
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>

<app-manage-device-command-dialog></app-manage-device-command-dialog>
<app-text-dialog></app-text-dialog>