import { Subscription } from "rxjs";

export class SubscriptionManager {
    subscriptions: { [key: string]: Subscription } = {};
    mapSubscriptions: { [key: string]: google.maps.MapsEventListener } = {};

    // Key is required in case of overwrites / duplicate adds
    // Subscriptions cannot be properly compared
    add(key: string, subscription: Subscription) {
        this.remove(key);
        this.subscriptions[key] = subscription;
    }

    remove(key: string) {
        if (!this.subscriptions[key]) return;

        this.subscriptions[key].unsubscribe();
        delete this.subscriptions[key];
    }

    addMaps(key: string, subscription: google.maps.MapsEventListener) {
        this.removeMaps(key);
        this.mapSubscriptions[key] = subscription;
    }

    removeMaps(key: string) {
        if (!this.mapSubscriptions[key]) return;

        this.mapSubscriptions[key].remove();
        delete this.mapSubscriptions[key];
    }

    has(key: string): boolean {
        return !!this.subscriptions[key];
    }

    clear() {
        const subscriptions = this.subscriptions;
        for (const key in subscriptions) {
            if (subscriptions.hasOwnProperty(key)) {
                this.remove(key);
            }
        }

        const mapSubscriptions = this.mapSubscriptions;
        for (const key in mapSubscriptions) {
            if (subscriptions.hasOwnProperty(key)) {
                this.removeMaps(key);
            }
        }
    }
}