import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { NotificationsRoutingModule } from "./notifications-routing.module";
import { NotificationBubbleComponent } from "./components/notification-bubble/notification-bubble.component";


@NgModule({
    imports: [
        SharedModule,
        NotificationsRoutingModule,
    ],
    declarations: [
        NotificationsComponent,
        NotificationBubbleComponent
    ],
    exports: [
        NotificationBubbleComponent
    ]
})
export class NotificationsModule { }