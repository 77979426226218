import { TableColumn, FilterType, TableService, ModelLocalizationTable } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ViewChild, OnInit, ElementRef } from "@angular/core";
import { ManageDrivingLaneDialogComponent } from "../manage-driving-lane-dialog/manage-driving-lane.dialog";
import { IDrivingLane } from "src/app/models/driving-lane";
import { TranslateService } from "@ngx-translate/core";
import { DrivingLaneApi } from "src/app/resource/driving-lane.api";
import { ModalService } from "src/app/services/modal.service";
import { DomainDataService } from "src/app/services/domain-data.service";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-driving-lanes",
    templateUrl: "./driving-lanes.component.html"
})
export class DrivingLanesComponent extends ModelLocalizationTable<IDrivingLane> implements OnInit {
    @ViewChild(ManageDrivingLaneDialogComponent, { static: true }) manageDrivingLaneDialog: ManageDrivingLaneDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        translate: TranslateService,
        domainDataService: DomainDataService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly drivingLaneApi: DrivingLaneApi) {

        super("driving-lanes.component", elementRef, drivingLaneApi, tableService, domainDataService, translate);

        this.stretchHeight = true;

        this.selectionMode = "";
        this.includeObsolete = true;

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, stringResourcePropertyName: "codeStringResourceId" }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "drivingLanes.edit",
            icon: "edit",
            click: (drivingLane) => this.edit(drivingLane),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (drivingLane) => this.delete(drivingLane),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (drivingLane) => !drivingLane.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (drivingLane) => this.restore(drivingLane),
            validFunc: () => this.deleteCommand,
            rowValidFunc: (drivingLane) => drivingLane.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    edit(drivingLane: IDrivingLane) {
        this.manageDrivingLaneDialog.edit(drivingLane);
    }

    async delete(drivingLane: IDrivingLane) {
        const onDeleteSuccess = () => {
            drivingLane.isObsolete = true;
        };

        const onDelete = () => {
            this.drivingLaneApi.delete$(drivingLane.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translateService.instant("drivingLanes.deleteConfirmation", { code: this.domainDataService.translate(drivingLane.codeStringResourceId) });
        this.modalService.delete(modalBody, onDelete);
    }

    restore(drivingLane: IDrivingLane) {
        const onRestoreSuccess = () => {
            drivingLane.isObsolete = false;
        };

        this.drivingLaneApi.restore$(drivingLane.id).subscribe(onRestoreSuccess);
    }
}