import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { ManageSafetyQuestionDialogComponent } from "../manage-safety-question-dialog/manage-safety-question-dialog.component";
import { SafetyQuestionsComponent } from "../safety-questions/safety-questions.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-safety-question-management",
    templateUrl: "./safety-question-management.component.html"
})
export class SafetyQuestionManagementComponent implements OnDestroy {
    @ViewChild(ManageSafetyQuestionDialogComponent, { static: true }) manageSafetyQuestionDialogComponent: ManageSafetyQuestionDialogComponent;
    @ViewChild(SafetyQuestionsComponent, { static: false }) safetyQuestionsComponent: SafetyQuestionsComponent;

    canEditSafetyQuestion = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef<HTMLElement>,
        private readonly globalEventsService: GlobalEventsService,

    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canEditSafetyQuestion = rights?.hasBackendRight(BackendRights.EditSafetyQuestion);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);


    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    create() {
        this.manageSafetyQuestionDialogComponent.create((safetyQuestion) => {
            this.safetyQuestionsComponent.reload();
        });
    }
}