<ng-container *ngIf="!assignmentForm">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<form [formGroup]="assignmentForm"
      class="m-layout-area-body p-16 m-overflow-auto m-border-top"
      *ngIf="assignment && assignmentForm"
      autocomplete="off">
    <!--organization-->
    <div class="m-form-group row"
         *ngIf="hasMultipleOrganizations && !isShowingSubAssignment"
         [ngClass]="{ 'has-error': assignmentForm.get('organizationId').showErrors }">
        <label class="col-4"
               for="organizationId">
            {{"general.organization" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown id="organizationId"
                        [options]="organizations"
                        formControlName="organizationId"
                        [autoDisplayFirst]="false"
                        (onChange)="refreshOrganizationRelatedDropdowns()"
                        [filter]="true"
                        [panelStyle]="{'width':'100%'}"
                        placeholder="{{'assignment.selectOrganization' | translate }}"
                        #organizationIdInput>
                <ng-template let-organization
                             pTemplate="item">
                    <span [ngClass]="organization.styleClass">{{organization.label}}</span>
                </ng-template>
            </p-dropdown>
            <app-form-validation [control]="assignmentForm.get('organizationId')"
                                 [input]="organizationIdInput">
            </app-form-validation>
        </div>
    </div>
    <!--project-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('projectId').showErrors }"
         *ngIf="!isShowingSubAssignment">
        <label class="col-4"
               for="projectId">
            {{"general.project" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown id="projectId"
                        [options]="projects"
                        formControlName="projectId"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        placeholder="{{projects.length>0 ? ('assignment.selectProject' | translate) : ('assignment.noProjects' | translate) }}"
                        (onChange)="onChangeProjectDropdown($event)"
                        [panelStyle]="{'width':'100%'}"
                        #projectIdInput>
                <ng-template let-project
                             pTemplate="item">
                    <span [ngClass]="project.styleClass">{{project.label}}</span>
                </ng-template>
            </p-dropdown>
            <app-form-validation [control]="assignmentForm.get('projectId')"
                                 [input]="projectIdInput">
            </app-form-validation>
        </div>
    </div>
    <!--name-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('name').showErrors }">
        <label class="col-4"
               for="name">
            {{"general.name" | translate}}
        </label>
        <div class="col-8">
            <input type="text"
                   placeholder="{{'general.assignment' | translate}}"
                   class="p-inputtext"
                   id="name"
                   formControlName="name"
                   appAutofocus
                   #nameInput />
            <app-form-validation [control]="assignmentForm.get('name')"
                                 [input]="nameInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('receivalDate').showErrors }">
        <label class="col-4"
               for="receivalDate">
            {{"assignment.receivalDate" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="receivalDate"
                        id="receivalDate"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [showIcon]="true"
                        [showTime]="false"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.receivalDate' | translate}}"
                        #receivalDateInput></p-calendar>

            <app-form-validation [control]="assignmentForm.get('receivalDate')"
                                 [input]="receivalDateInput">
            </app-form-validation>
        </div>
    </div>
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('start').showErrors }">
        <label class="col-4"
               for="start">
            {{"assignment.start" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="start"
                        id="start"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [showIcon]="true"
                        [showTime]="false"
                        [maxDate]="assignmentForm.get('end')?.value"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.start' | translate}}"
                        #startInput></p-calendar>

            <app-form-validation [control]="assignmentForm.get('start')"
                                 [input]="startInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('end').showErrors }">
        <label class="col-4"
               for="end">
            {{"assignment.end" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="end"
                        id="end"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [minDate]="assignmentForm.get('start')?.value"
                        [showIcon]="true"
                        [showTime]="false"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.end' | translate}}"
                        #endInput></p-calendar>

            <app-form-validation [control]="assignmentForm.get('end')"
                                 [input]="endInput">
            </app-form-validation>
        </div>
    </div>

    <!--priority-->
    <div class="m-form-group row"
         *ngIf="!isShowingSubAssignment"
         [ngClass]="{ 'has-error': assignmentForm.get('priorityId').showErrors }">
        <label class="col-4"
               for="priorityId">
            {{"assignment.priority" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown id="priorityId"
                        [options]="priorities"
                        formControlName="priorityId"
                        [autoDisplayFirst]="false"
                        placeholder="{{'assignment.selectPriority' | translate }}"
                        [panelStyle]="{'width':'100%'}"
                        #priorityIdInput></p-dropdown>
            <app-form-validation [control]="assignmentForm.get('priorityId')"
                                 [input]="priorityIdInput">
            </app-form-validation>
        </div>
    </div>

    <!-- status -->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('currentStatusId').showErrors }">
        <label class="col-4"
               for="name">
            {{"general.status" | translate}}
        </label>

        <div class="col-8">
            <p-dropdown id="currentStatusId"
                        [options]="statuses"
                        [panelStyle]="{'width':'100%'}"
                        formControlName="currentStatusId"
                        [autoDisplayFirst]="false"
                        (onChange)="onChangeStatusDropdown($event)"
                        placeholder="{{'assignment.selectStatus' | translate }}"
                        #currentStatusIdInput></p-dropdown>

            <app-form-validation [control]="assignmentForm.get('currentStatusId')"
                                 [input]="currentStatusIdInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('currentStatusTimestamp').showErrors }"
         *ngIf="assignment?.currentStatus?.statusId === 'finished' && assignmentForm.get('currentStatusId').value === 'finished'">
        <label class="col-4"
               for="currentStatusTimestamp">
            {{"assignment.statusCreatedOn" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="currentStatusTimestamp"
                        id="currentStatusTimestamp"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [showIcon]="true"
                        [showTime]="true"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.currentStatusTimestamp' | translate}}"
                        #currentStatusTimestampInput></p-calendar>

            <app-form-validation [control]="assignmentForm.get('currentStatusTimestamp')"
                                 [input]="currentStatusTimestampInput">
            </app-form-validation>
        </div>
    </div>

    <!--description-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('description').showErrors }">
        <label class="col-4"
               for="description">
            {{"general.description" | translate}}
        </label>
        <div class="col-8">
            <textarea id="description"
                      placeholder="{{'general.description' | translate}}"
                      rows="2"
                      formControlName="description"
                      class="p-inputtextarea"
                      #descriptionInput>
                    </textarea>
            <app-form-validation [control]="assignmentForm.get('description')"
                                 [input]="descriptionInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         *ngIf="!isShowingSubAssignment">
        <label class="col-4"
               for="needParkingBans">
            {{"assignment.needParkingBans" | translate}}
        </label>
        <div class="col-8">
            <input class="group-checkbox"
                   type="checkbox"
                   formControlName="needParkingBans" />
        </div>
    </div>
    <!--type-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('taskTypeId').showErrors }"
         *ngIf="showTaskTypeAndETC">
        <div class="col-4">
            <label class="mr-16"
                   for="taskTypeId">
                {{"assignment.taskType" | translate}}
            </label>
        </div>

        <div class="col-8">
            <p-dropdown formControlName="taskTypeId"
                        id="taskTypeId"
                        [options]="taskTypes"
                        [autoDisplayFirst]="false"
                        (onChange)="handleTaskTypeChange($event)"
                        [panelStyle]="{'width':'100%'}"
                        placeholder="{{'assignment.selectTaskType' | translate }}"
                        #taskTypeIdInput>
            </p-dropdown>

            <app-form-validation [control]="assignmentForm.get('taskTypeId')"
                                 [input]="taskTypeIdInput">
            </app-form-validation>
        </div>
    </div>
    <!--sign material-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': assignmentForm.get('signMaterialId').showErrors }"
         *ngIf="selectMaterial && showTaskTypeAndETC">
        <label class="col-4"
               for="signMaterialId">
            {{"assignment.signMaterial" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown formControlName="signMaterialId"
                        id="signMaterialId"
                        [options]="signMaterials"
                        [autoDisplayFirst]="false"
                        (onChange)="handleMaterialChange($event)"
                        [panelStyle]="{'width':'100%'}"
                        placeholder="{{'assignment.selectSignMaterial' | translate }}"
                        #signMaterialIdInput>
            </p-dropdown>

            <app-form-validation [control]="assignmentForm.get('signMaterialId')"
                                 [input]="signMaterialIdInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         *ngIf="selectIsMachineWork && showTaskTypeAndETC">
        <label class="col-4"
               for="isMachineWork">
            {{"assignment.isMachineWork" | translate}}
        </label>
        <div class="col-8">
            <input class="group-checkbox"
                   type="checkbox"
                   formControlName="isMachineWork" />
        </div>
    </div>

    <div class="m-form-group row"
         *ngIf="!isShowingSubAssignment">
        <label class="col-4"
               for="administrativeResponsible">
            {{"assignment.administrativeResponsible" | translate}}
        </label>
        <div class="col-8"
             *ngIf="!isReadonly">
            <p-dropdown id="administrativeResponsibleId"
                        [options]="supervisors"
                        formControlName="administrativeResponsibleId"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        placeholder="{{'assignment.selectAdministrativeResponsible' | translate }}"
                        [panelStyle]="{'width':'100%'}"
                        #administrativeResponsibleInput></p-dropdown>
            <app-form-validation [control]="assignmentForm.get('administrativeResponsibleId')"
                                 [input]="administrativeResponsibleInput">
            </app-form-validation>
        </div>
        <div class="col-8"
             *ngIf="isReadonly">
            <span *ngIf="assignment.administrativeResponsible"
                  class="m-navigator is-disabled">{{assignment.administrativeResponsible.firstName}} {{assignment.administrativeResponsible.lastName}} - {{assignment.administrativeResponsible.email}} </span>
        </div>
    </div>
    <div class="m-form-group row"
         *ngIf="!isShowingSubAssignment">
        <label class="col-4"
               for="executiveResponsible">
            {{"assignment.executiveResponsible" | translate}}
        </label>
        <div class="col-8"
             *ngIf="!isReadonly">
            <p-dropdown id="executiveResponsibleId"
                        [options]="supervisors"
                        formControlName="executiveResponsibleId"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        placeholder="{{'assignment.selectExecutiveResponsible' | translate }}"
                        [panelStyle]="{'width':'100%'}"
                        #executiveResponsibleInput></p-dropdown>
            <app-form-validation [control]="assignmentForm.get('executiveResponsibleId')"
                                 [input]="executiveResponsibleInput">
            </app-form-validation>
        </div>
        <div class="col-8"
             *ngIf="isReadonly">
            <span *ngIf="assignment.executiveResponsible"
                  class="m-navigator is-disabled">{{assignment.executiveResponsible.firstName}} {{assignment.executiveResponsible.lastName}} - {{assignment.executiveResponsible.email}} </span>
        </div>
    </div>
    <div class="m-form-group row"
         *ngIf="!isShowingSubAssignment">
        <label class="col-4"
               for="responsibleClient">
            {{"assignment.responsibleClient" | translate}}
        </label>
        <div class="col-8"
             *ngIf="!isReadonly">
            <p-dropdown id="responsibleClientId"
                        [options]="projectUsers"
                        formControlName="responsibleClientId"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        placeholder="{{'assignment.selectResponsibleClient' | translate }}"
                        [panelStyle]="{'width':'100%'}"
                        #responsibleClientInput></p-dropdown>
            <app-form-validation [control]="assignmentForm.get('responsibleClientId')"
                                 [input]="responsibleClientInput">
            </app-form-validation>
        </div>
        <div class="col-8"
             *ngIf="isReadonly">
            <span *ngIf="assignment.responsibleClient"
                  class="m-navigator is-disabled">{{assignment.responsibleClient.firstName}} {{assignment.responsibleClient.lastName}} - {{assignment.responsibleClient.email}} </span>
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right m-border-top py-8 px-16 care-area-bottom"
     *ngIf="!isReadonly">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        <ng-container *ngIf="!isCreatingNew(); else cancelText">
            {{"form.reset" | translate}}
        </ng-container>
        <ng-template #cancelText>
            {{"form.cancel" | translate}}
        </ng-template>
    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>