<!-- <div style="width: 100%;" [style.height]="componentHeight + 'px'"> -->

<as-split direction="vertical"
          class="m-fixed-height"
          (dragEnd)="onDragEnd($event)">
    <as-split-area [(size)]="mapSplitSize">
        <div class="m-map">
            <app-advanced-map [searchControl]="true"
                              [showCoordinates]="true"
                              [showMapTypeSelector]="true"
                              [searchbutton]="searchbutton"
                              (mapReady)="handleMapReady($event)"
                              (mapClick)="handleMapClick($event)"
                              (mapRightClick)="handleMapRightClick($event)"
                              (locationClick)="handleLocationClick($event)"
                              (locationRightClick)="handleLocationRightClick($event)"
                              (dataLoaded)="handleDataLoadedOnMap()"
                              (mapDragStart)="handleMapDragStart($event)"
                              (groupSelect)="handleGroupSummarySelect($event)"
                              (groupDeselect)="handleGroupSummaryDeselect($event)"
                              #gmap>
            </app-advanced-map>

            <app-map-searchbutton [loaded]="loaded"
                                  [readonly]="readonly"
                                  [splitMap]="this"
                                  #searchbutton></app-map-searchbutton>
        </div>

    </as-split-area>

    <as-split-area class="m-layout-default"
                   [(size)]="gridSplitSize">

        <app-measuring-points *ngIf="mapDetail === 0"
                              [selectionBox]="true"
                              [delayedStart]="true"
                              [locationNavigation]="false"
                              [groupNavigation]="false"
                              [organizationNavigation]="false"
                              [deviceNavigation]="false"
                              [compact]="compact"
                              [mini]="mini"
                              (selected)="handleSelect($event)"
                              (deselected)="handleDeselect($event)"></app-measuring-points>
        <app-devices *ngIf="mapDetail === 1"
                     [selectionBox]="true"
                     [delayedStart]="true"
                     [navigation]="false"
                     [compact]="compact"
                     [mini]="mini"
                     [typeId]="deviceTypeId"
                     (selected)="handleSelect($event)"
                     (deselected)="handleDeselect($event)"></app-devices>

        <app-organizations *ngIf="mapDetail === 2"
                           [selectionBox]="true"
                           [delayedStart]="true"
                           [compact]="compact"
                           [mini]="mini"
                           [editCommand]="false"
                           [deleteCommand]="false"
                           (selected)="handleSelect($event)"
                           (deselected)="handleDeselect($event)"></app-organizations>

        <app-groups *ngIf="mapDetail === 3"
                    [selectionBox]="true"
                    [delayedStart]="true"
                    [showMeasuringPointsOnSelect]="false"
                    [navigation]="false"
                    [compact]="compact"
                    [mini]="mini"
                    (selected)="handleSelect($event)"
                    (deselected)="handleDeselect($event)">
        </app-groups>
    </as-split-area>
</as-split>

<app-map-detail-popup [splitMap]="this"
                      [hideOrganizationPopup]="true"></app-map-detail-popup>