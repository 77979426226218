<div class="location-popup">
    <header class="location-popup__header">
        <div class="location-popup__header__info"
             (mouseup)="$event?.button === 1 ? navigateToMeasuringPoint($event) : null"
             (click)="navigateToMeasuringPoint($event)">
            <h3>{{ location?.code }}</h3>

            <small *ngIf="locationStatus"
                   class="location-popup__header__status">
                <p>
                    <ng-container *ngIf="locationStatus.location?.address">
                        {{ locationStatus.location.address | address }}<br>
                    </ng-container>

                    <a [href]="getGoogleMapsUrl()"
                       target="_blank"
                       (click)="$event.stopImmediatePropagation();"
                       style="display: inline-flex;"
                       class="flex align-center mt-4">
                        <app-svg-icon icon="external-link"
                                      divClass="icon-12"
                                      class="mr-4"></app-svg-icon>
                        {{ locationStatus?.location?.coordinate | coordinate }}
                    </a>
                </p>
            </small>
        </div>

        <div class="location-popup__header__actions">
            <button class="m-btn m-btn-square"
                    (click)="close()">
                <app-svg-icon icon="close"></app-svg-icon>
            </button>

            <!-- <ng-container *ngIf="locationStatus">
                <app-navigator id="buttonLocationPopupDetails"
                               [measuringPoint]="measuringPointNavigator">
                    {{'general.details' | translate}}
                </app-navigator>
            </ng-container> -->
        </div>
    </header>

    <app-status-header *ngIf="shouldShowStatusHeader()"
                       [locationStatus]="locationStatus"></app-status-header>

    <div class="location-popup__body">

        <div class="location-popup__tile-container">
            @if (!locationStatus) {
            <div class="location-popup__tile-container">
                <app-loading-spinner />
            </div>
            } @else {
            @for (item of locationStatus.devices; track item.imei) {
            @if (showDeviceDetails) {
            <div class="location-popup__tile-container__item-nb">
                <app-device-status [data]="item" />
            </div>
            }
            }

            <div class="location-popup__tile-container__item">
                @for (item of locationStatus.measuringPoints; track item.measuringPointId) {
                <div class="location-popup__tile-container-mp">
                    @switch (item.analysisType) {
                    @case (AnalysisType.TrafficLight) {
                    <app-traffic-light-tile [locationStatusSummary]="locationStatus"
                                            [data]="item" />
                    }
                    @case (AnalysisType.Tracker) {
                    <app-tracker-tile [locationStatusSummary]="locationStatus"
                                      [data]="item" />
                    }
                    @case (AnalysisType.ArrowBoard) {
                    <app-arrow-board-tile [locationStatusSummary]="locationStatus"
                                          [data]="item" />
                    }
                    @case (AnalysisType.Vehicle) {
                    <app-vehicle-tile [locationStatusSummary]="locationStatus"
                                      [data]="item" />
                    }
                    @default {
                    <app-generic-tile [locationStatusSummary]="locationStatus"
                                      [data]="item" />
                    }
                    }
                </div>
                }
            </div>
            @if (locationStatus.forecasts.length > 0) {
            <div class="location-popup__tile-container__item">
                <div class="location-popup__tile-container-weather">
                    @for (item of locationStatus.forecasts; track item.date) {
                    <div>
                        <div class="weather-icon">
                            <app-svg-icon [tooltip]="getWeatherTranslation(item.weatherConditionTypeId)"
                                          [icon]="getWeatherIcon(item.weatherConditionTypeId)"
                                          divClass="icon-50" />
                        </div>
                        {{ getDateStringFormat(item.date) | translate }}
                    </div>
                    }
                </div>
            </div>
            }
            }
        </div>
    </div>
</div>