import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, HostListener, OnChanges, SimpleChanges } from "@angular/core";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { TranslateService } from "@ngx-translate/core";
import { SigncoFormGroup } from "src/app/models/form";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";
import { WorkerApi } from "src/app/resource/worker.api";
import { map } from "rxjs";
import { IWorker } from "src/app/models/worker";
import { SelectItem } from "primeng/api";
import { GeoDynamicsApi } from "src/app/resource/geo-dynamics.api";
import { IVehicleConfiguration } from "src/app/models/vehicle-configuration";
import { IAttachment } from "src/app/models/attachment";
import { JsonUtils } from "src/app/utilities";
import { PhotoInputComponent } from "src/app/modules/shared/components/photo-input/photo-input.component";
import { WebfleetApi } from "src/app/resource/webfleet.api";
@Component({
    selector: "app-device-vehicle-configuration",
    templateUrl: "./device-vehicle-configuration.component.html"
})
export class DeviceVehicleConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @ViewChild(PhotoInputComponent, { static: false }) photoInput: PhotoInputComponent;

    @Input() device: IDevice;
    @Output() save = new EventEmitter<IDevice>();
    submitting = false;

    deviceVehicleForm: SigncoFormGroup;

    workers: SelectItem[];
    geoDynamicsVehicles: SelectItem[];
    webfleetVehicles: SelectItem[];
    photo: IAttachment;

    constructor(
        elementRef: ElementRef,
        private readonly deviceApi: DeviceApi,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        readonly formValidationService: FormValidationService,
        private readonly translateService: TranslateService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly workerApi: WorkerApi,
        private readonly geoDynamicsApi: GeoDynamicsApi,
        private readonly webfleetApi: WebfleetApi) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.initializeWorkers();
        this.initializeGeoDynamicsVehicles();
        this.initializeWebfleetVehicles();
    }
    ngOnChanges(changes: SimpleChanges): void {
        const deviceChanged = changes["device"];

        if (deviceChanged) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.deviceVehicleForm || this.deviceVehicleForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    initialize() {
        this.deviceVehicleForm = this.formBuilder.group({
            workerDriverId: [null],
            isGeoDynamics: [null],
            isWebfleet: [null],
        }) as SigncoFormGroup;

        if (this.device.attachment) {
            this.photo = JsonUtils.deepClone(this.device.attachment);
        } else {
            this.photo = null;
        }

        this.patchFormValue(this.device.vehicleConfiguration);

        this.deviceVehicleForm.markAsPristine();
    }

    private patchFormValue(vehicleConfiguration: IVehicleConfiguration) {
        this.deviceVehicleForm.get("isGeoDynamics").setValue(!!vehicleConfiguration.id, { emitEvent: false });
        this.deviceVehicleForm.get("isWebfleet").setValue(!!vehicleConfiguration.webfleetId, { emitEvent: false });

        this.adjustControls(!!vehicleConfiguration.id, !!vehicleConfiguration.webfleetId);
        this.deviceVehicleForm.patchValue(vehicleConfiguration);
        this.deviceVehicleForm.get("workerDriverId").setValue(this.device.workerDriver?.id);
    }

    private adjustControls(isGeoDynamics: boolean, isWebfleet: boolean) {
        this.adjustGeoDynamics(isGeoDynamics);
        this.adjustWebfleet(isWebfleet);

    }
    private adjustWebfleet(isWebfleet: boolean) {
        if (isWebfleet) {
            this.deviceVehicleForm.get("isGeoDynamics").setValue(false, { emitEvent: false });

            if (!this.deviceVehicleForm.contains("webfleetId")) {
                this.deviceVehicleForm.addControl("webfleetId", new UntypedFormControl(null, [Validators.required]));
            } else {
                this.deviceVehicleForm["webfleetId"] = new UntypedFormControl(null, [Validators.required]);
            }
            if (this.deviceVehicleForm.contains("id")) {
                this.deviceVehicleForm.get("id").clearValidators();
                this.deviceVehicleForm.get("id").reset();
            }
        }
        else {
            if (this.deviceVehicleForm.contains("webfleetId")) {
                this.deviceVehicleForm.get("webfleetId").clearValidators();
                this.deviceVehicleForm.get("webfleetId").reset();
            }
        }
    }
    private adjustGeoDynamics(isGeoDynamics: boolean) {
        if (isGeoDynamics) {
            this.deviceVehicleForm.get("isWebfleet").setValue(false, { emitEvent: false });
            if (!this.deviceVehicleForm.contains("id")) {
                this.deviceVehicleForm.addControl("id", new UntypedFormControl(null, [Validators.required]));
            } else {
                this.deviceVehicleForm["id"] = new UntypedFormControl(null, [Validators.required]);
            }
            if (this.deviceVehicleForm.contains("webfleetId")) {
                this.deviceVehicleForm.get("webfleetId").clearValidators();
                this.deviceVehicleForm.get("webfleetId").reset();
            }

        }
        else {
            if (this.deviceVehicleForm.contains("id")) {
                this.deviceVehicleForm.get("id").clearValidators();
                this.deviceVehicleForm.get("id").reset();
            }
        }
    }

    async reset() {
        this.initialize();
        if (!this.photo) {
            await this.photoInput.handleAttachmentChange();
        }
    }

    async submit() {
        if (!this.formValidationService.checkValidity(this.deviceVehicleForm)) {
            return;
        }

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.device.attachment = savedDevice.attachment;
            this.submitting = false;
            this.save.emit(this.device);
            this.initialize();
        };

        const onError = () => {
            this.submitting = false;
        };


        const deviceUpdater = new DeviceUpdater(this.device);
        deviceUpdater.vehicleConfiguration = {};

        if (this.deviceVehicleForm.get("isGeoDynamics").value) {
            deviceUpdater.vehicleConfiguration.id = this.deviceVehicleForm.get("id").value;
        }
        else if (this.deviceVehicleForm.get("isWebfleet").value) {
            deviceUpdater.vehicleConfiguration.webfleetId = this.deviceVehicleForm.get("webfleetId").value;
        }

        deviceUpdater.workerDriverId = this.deviceVehicleForm.get("workerDriverId").value;

        this.submitting = true;
        this.deviceApi.updateWithFormData$(deviceUpdater, this.photoInput.image ? { name: "image", file: this.photoInput.image } : null).subscribe(onSuccess, onError);
    }

    private initializeWorkers(): void {
        const searchParameters = new SearchParameters();
        searchParameters.sort = [];
        searchParameters.sort.push(new SortDescriptor(SortDirection.ascending, "FirstName"));

        this.workerApi.getAll$(searchParameters).pipe(map((workers: IWorker[], index: number) => {
            return workers.map((worker) => {
                return {
                    label: `${worker.firstName} ${worker.lastName}`,
                    value: worker.id
                } as SelectItem;
            });
        })).subscribe({
            next: (workers) => {
                this.workers = workers;
            },
            error: (error) => {
                this.workers = [];
            }
        });
    }

    private initializeGeoDynamicsVehicles(): void {
        this.geoDynamicsApi.getAllVehicles$().subscribe({
            next: (vehicles) => {
                this.geoDynamicsVehicles = vehicles.map((vehicle) => {
                    return {
                        value: vehicle.id,
                        label: `[${vehicle.name}]` + (vehicle.licensePlate ? ` - [${vehicle.licensePlate}]` : "")
                    } as SelectItem;
                });
                // sort by label
                this.geoDynamicsVehicles.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            },
        });
    }

    private initializeWebfleetVehicles(): void {
        this.webfleetApi.getAllVehicles$().subscribe({
            next: (vehicles) => {
                this.webfleetVehicles = vehicles.map((vehicle) => {
                    return {
                        value: vehicle.objectuid,
                        label: (vehicle.licenseplatenumber ? `[${vehicle.licenseplatenumber}]` : `[${vehicle.objectname}]`)
                    } as SelectItem;
                });
                // sort by label
                this.webfleetVehicles.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            },
        });
    }
}