<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="true"
          [minX]="minX"
          [minY]="minY"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">
    <p-header class="m-map-filter-heading">{{"mapSearch.status" | translate}}</p-header>

    <div class="m-map-filters-dialog-body p-16">
        <div>
            <p class="mb-4">{{"mapSearch.installations" | translate}}</p>

            <div class="m-map-filters-chips">
                <button [ngClass]="{ 'is-selected': isSelected(statusLinked)}"
                        id="buttonLinked"
                        class="m-map-filters-chip"
                        (click)="toggleSelection(statusLinked)">
                    <app-svg-icon icon="signal-stream" />
                    {{ "mapFilters.linked" | translate }}
                </button>

                <button [ngClass]="{ 'is-selected': isSelected(statusNotLinked)}"
                        class="m-map-filters-chip"
                        id="buttonNotLinked"
                        (click)="toggleSelection(statusNotLinked)">
                    <app-svg-icon icon="signal-stream-slash" />
                    {{ "mapFilters.notLinked" | translate }}
                </button>
            </div>
        </div>

        <div>
            <h4 class="mb-4">{{"manageAlertConfiguration.alertLevel" | translate}}</h4>

            <div class="m-map-filters-chips">
                <button [ngClass]="{ 'is-selected': isAlertLevelSelected(AlertLevel.info)}"
                        class="m-map-filters-chip"
                        (click)="toggleAlertLevelSelection(AlertLevel.info)">
                    <app-svg-icon icon="circle-info" />
                    {{AlertLevel.info | translateEnum:"alertLevel"}}
                </button>

                <button [ngClass]="{ 'is-selected': isAlertLevelSelected(AlertLevel.warning)}"
                        class="m-map-filters-chip"
                        (click)="toggleAlertLevelSelection(AlertLevel.warning)">
                    <app-svg-icon icon="circle-exclamation" />
                    {{AlertLevel.warning | translateEnum:"alertLevel"}}
                </button>
                <button [ngClass]="{ 'is-selected': isAlertLevelSelected(AlertLevel.error)}"
                        class="m-map-filters-chip"
                        (click)="toggleAlertLevelSelection(AlertLevel.error)">
                    <app-svg-icon icon="circle-xmark" />
                    {{AlertLevel.error| translateEnum:"alertLevel"}}
                </button>
            </div>
        </div>
    </div>
    <p-footer>
        <button (click)="saveSelection()"
                id="buttonOk"
                class="m-btn m-btn-primary">{{"form.ok" | translate}}</button>
    </p-footer>
</p-dialog>
