<div *ngIf="parentFormGroup"
     [formGroup]="parentFormGroup">
    <div class="m-map m-map-teaser mb-16">
        <app-basic-map [showMapTypeSelector]="true"
                       (mapReady)="handleMapReady($event)"
                       (markerDragEnd)="handleMarkerDragEnd($event)"></app-basic-map>
    </div>

    <ng-container *ngIf="addressForm"
                  formGroupName="address">

        <div class="m-form-group row">
            <div class="col-3">
                <div>
                    <label class="mr-16"
                           for="line1">
                        {{"general.address" | translate}}
                    </label>
                </div>
                <div class="flex">
                    <app-icon-button text="manageLocation.updateAddress"
                                     [disabled]="!!updatingAddressForCoordinate"
                                     icon="magic-wand"
                                     class="ml-auto m-btn-baseline"
                                     (click)="updateAddress()">
                    </app-icon-button>
                    <app-icon-button text="manageLocation.updateCoordinates"
                                     icon="marker"
                                     class="ml-auto m-btn-baseline"
                                     (click)="updateCoordinatesFromAddress()">
                    </app-icon-button>
                </div>
            </div>

            <div class="col-9">
                <div class="m-form-group flex align-center">
                    <input type="text"
                           id="line1"
                           class="p-inputtext"
                           [ngClass]="{ 'has-error': addressForm.get('line1').showErrors }"
                           placeholder="{{'address.line1' | translate}}"
                           formControlName="line1"
                           #line1Input />

                    <app-form-validation [control]="addressForm.get('line1')"
                                         [input]="line1Input">
                    </app-form-validation>
                </div>

                <div class="m-form-group row">
                    <div class="col-4">
                        <input type="text"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': addressForm.get('zipCode').showErrors }"
                               placeholder="{{'address.zipCode' | translate}}"
                               formControlName="zipCode"
                               id="zip_code"
                               #zipCodeInput />

                        <app-form-validation [control]="addressForm.get('zipCode')"
                                             [input]="zipCodeInput">
                        </app-form-validation>
                    </div>

                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': addressForm.get('city').showErrors }"
                               placeholder="{{'address.city' | translate}}"
                               formControlName="city"
                               id="city"
                               #cityInput />

                        <app-form-validation [control]="addressForm.get('city')"
                                             [input]="cityInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row">
                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': addressForm.get('country').showErrors }"
                               placeholder="{{'address.country' | translate}}"
                               formControlName="country"
                               id="country"
                               #countryInput />

                        <app-form-validation [control]="addressForm.get('country')"
                                             [input]="countryInput">
                        </app-form-validation>
                    </div>

                    <div class="col-4">
                        <input type="text"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': addressForm.get('countryCode').showErrors }"
                               placeholder="{{'address.countryCode' | translate}}"
                               formControlName="countryCode"
                               id="countryCode"
                               #countryCodeInput />

                        <app-form-validation [control]="addressForm.get('countryCode')"
                                             [input]="countryCodeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="coordinateForm"
                  formGroupName="coordinate">

        <div class="m-form-group row">
            <label class="col-3"
                   for="latitude">
                {{"general.coordinates" | translate}}
            </label>

            <div class="col-9">
                <div class="m-form-group row">
                    <div class="col-6">
                        <input type="number"
                               class="p-inputtext"
                               id="latitude"
                               [ngClass]="{ 'has-error': coordinateForm.get('latitude').showErrors }"
                               placeholder="{{'general.latitude' | translate}}"
                               formControlName="latitude"
                               (change)="handleCoordinateUpdate()"
                               #latitudeInput />

                        <app-form-validation [control]="coordinateForm.get('latitude')"
                                             [input]="latitudeInput">
                        </app-form-validation>
                    </div>

                    <div class="col-6">
                        <input type="number"
                               class="p-inputtext"
                               id="longitude"
                               [ngClass]="{ 'has-error': coordinateForm.get('longitude').showErrors }"
                               placeholder="{{'general.longitude' | translate}}"
                               formControlName="longitude"
                               (change)="handleCoordinateUpdate()"
                               #longitudeInput />

                        <app-form-validation [control]="coordinateForm.get('longitude')"
                                             [input]="longitudeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="officialIdForm"
                  formGroupName="officialId">

        <div class="m-form-group row">
            <label class="col-3"
                   for="officialIdType">
                {{"officialId.title" | translate}}
            </label>

            <div class="col-9">
                <div class="row">
                    <div class="col-4">
                        <input type="text"
                               id="officialIdType"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': officialIdForm.get('type').showErrors }"
                               placeholder="{{'officialId.type' | translate}}"
                               formControlName="type"
                               #officialIdTypeInput />

                        <app-form-validation [control]="officialIdForm.get('type')"
                                             [input]="officialIdTypeInput">
                        </app-form-validation>
                    </div>

                    <div class="col-4">
                        <input type="text"
                               id="officialIdNumber"
                               class="p-inputtext"
                               [ngClass]="{ 'has-error': officialIdForm.get('number').showErrors }"
                               placeholder="{{'officialId.number' | translate}}"
                               formControlName="number"
                               #officialIdNumberInput />

                        <app-form-validation [control]="officialIdForm.get('number')"
                                             [input]="officialIdNumberInput">
                        </app-form-validation>
                    </div>

                    <div class="col-4">
                        <input type="number"
                               class="p-inputtext"
                               id="officialIdMilestone"
                               [ngClass]="{ 'has-error': officialIdForm.get('milestone').showErrors }"
                               placeholder="{{'officialId.milestone' | translate}}"
                               formControlName="milestone"
                               #officialIdMilestoneInput />

                        <app-form-validation [control]="officialIdForm.get('milestone')"
                                             [input]="officialIdMilestoneInput">
                        </app-form-validation>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>
</div>