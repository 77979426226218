<div class="m-page-content-wrapper p-16"
     [hidden]="!measuringPoint">
    <div class="m-fixed-height m-layout-w-actions-top">
        <div class="m-layout-area-top">
            <div class="m-card m-layout-area-top p-8 mb-16">
                <div class="flex align-center">
                    <div class="mr-16">
                        <a (click)="back()"
                           class="m-btn m-btn-back"
                           title="Back">
                            <app-svg-icon icon="chevron-left"
                                          class="mr-4"></app-svg-icon>
                            {{"dataValidation.return" | translate}}
                        </a>
                    </div>

                    <div class="mx-auto flex align-center">
                        <app-icon-button *ngIf="previousMeasuringPoint"
                                         icon="arrow-left"
                                         class="mr-16"
                                         label="{{'words.previous' | translate}}"
                                         (click)="goToPreviousMeasuringPoint()">
                        </app-icon-button>

                        <p-dropdown [options]="context?.allMeasuringPoints"
                                    [ngModel]="measuringPointSummary"
                                    (onChange)="goToMeasuringPoint($event.value)"
                                    optionLabel="code">
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="measuringPointSummary"
                                     style="display: inline-block;">
                                    <span class="m-circle m-circle-grey center"
                                          style="display: inline-flex;">
                                        <app-svg-icon [icon]="measuringPointSummary.analysisTypeId"
                                                      [tooltip]="getMeasuringPointAnalysisTypeTranslation(measuringPointSummary.analysisTypeId)">
                                        </app-svg-icon>
                                    </span>
                                    {{measuringPointSummary.code}}
                                </div>
                            </ng-template>
                            <ng-template let-measuringPoint
                                         pTemplate="item">
                                <span class="m-circle m-circle-grey center"
                                      style="display: inline-flex;">
                                    <app-svg-icon [icon]="measuringPoint.analysisTypeId"
                                                  [tooltip]="getMeasuringPointAnalysisTypeTranslation(measuringPoint.analysisTypeId)">
                                    </app-svg-icon>
                                </span>
                                {{measuringPoint.code}}
                            </ng-template>
                        </p-dropdown>

                        <app-icon-button *ngIf="nextMeasuringPoint"
                                         icon="arrow-right"
                                         class="ml-16"
                                         label="{{'words.next' | translate}}"
                                         labelPosition="left"
                                         (click)="goToNextMeasuringPoint()">
                        </app-icon-button>
                    </div>

                    <div class="ml-16 flex align-center">
                        <app-expando [target]="infoExpandDiv"
                                     iconCollapsed="info"
                                     iconExpanded="info"></app-expando>
                        <span class="ml-8">{{"dataValidation.moreInfo" | translate}}</span>
                    </div>

                    <div class="ml-16 mr-8">
                        <label class="show-days-data">
                            <input type="checkbox"
                                   (click)="toggleShowDaysWithoutData()"
                                   [ngModel]="showDaysWithoutData">
                            {{"dataValidation.showDaysWithoutData" | translate}}
                        </label>
                    </div>
                </div>
            </div>

            <div #infoExpandDiv
                 class="mb-16">
                <app-measuring-point-expand [measuringPoint]="measuringPoint">
                </app-measuring-point-expand>
            </div>
        </div>

        <div class="m-card m-layout-area-body m-layout-w-actions-top">
            <div class="m-layout-area-top">
                <app-chart class="p-8"
                           [configuration]="chartConfiguration"
                           [height]="150"
                           (chartClick)="handleChartClick($event)">
                </app-chart>

                <div class="p-8 flex align-center m-border-top">
                    <label [hidden]="!hasSelection"
                           class="m-input-checkbox mr-16">
                        <input type="checkbox"
                               [disabled]="!hasSelection"
                               [(ngModel)]="selectionIsPredicted"
                               (change)="handleSelectionIsPredicted()">

                        <app-svg-icon icon="prediction"></app-svg-icon>
                        {{'hourlyPreview.columnPredicted' | translate}}
                    </label>

                    <label [hidden]="!hasSelection"
                           class="m-input-checkbox mr-16">
                        <input type="checkbox"
                               [disabled]="!hasSelection"
                               [(ngModel)]="selectionIsError"
                               (change)="handleSelectionIsError()">

                        <app-svg-icon icon="close"></app-svg-icon>
                        {{'dataValidation.columnError' | translate}}
                    </label>

                    <label [hidden]="!hasSelection"
                           class="m-input-checkbox mr-16">
                        <input type="checkbox"
                               [disabled]="!hasSelection"
                               [(ngModel)]="selectionIsException"
                               (change)="handleSelectionIsException()">

                        <app-svg-icon icon="alert"></app-svg-icon>
                        {{'hourlyPreview.columnException' | translate}}
                    </label>

                    <div [hidden]="!hasSelection"
                         class="mr-16">
                        <span>
                            {{'hourlyPreview.exceptionReason' | translate}}
                        </span>

                        <input type="text"
                               class="p-inputtext"
                               [disabled]="!hasSelection"
                               [(ngModel)]="selectionExceptionReason"
                               (appDebounce)="handleSelectionExceptionReason()">
                    </div>

                    <div class="ml-auto"
                         style="width:300px">
                        <div *ngIf="progressMessageTranslationResourceId">
                            <app-svg-icon icon="info"
                                          class="m-btn-baseline mr-8"></app-svg-icon>
                            <span>{{progressMessageTranslationResourceId | translate}}</span>
                        </div>
                        <div class="mr-16"
                             *ngIf="showProgressBar">
                            <div class="pb-8 pt-8">
                                <p-progressBar [value]="progress"></p-progressBar>
                            </div>
                        </div>
                        <div class="mr-16"
                             [ngStyle]="showProgressBar ? {textAlign: 'center'} : {float: 'right'}">
                            <app-loading-spinner class="mr-8"
                                                 *ngIf="isSaving && !showProgressBar">
                            </app-loading-spinner>
                            <button *ngIf="!showProgressBar"
                                    [disabled]="!isDirty"
                                    class="m-btn m-btn-primary ml-auto"
                                    type="button"
                                    (click)="save()">
                                {{'form.save' | translate}} <ng-container *ngIf="isDirty">({{isDirtyCount}})
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <p-table id="validation_table"
                     class="m-layout-area-body m-layout-default"
                     [value]="data"
                     [columns]="relevantColumns"
                     [rows]="internalRowCount"
                     [styleClass]="styleClass"
                     [loading]="loading"
                     [selectionMode]="selectionMode"
                     (onRowSelect)="onRowSelect($event)"
                     (onRowUnselect)="onRowDeselect($event)"
                     (onPage)="onPage($event)"
                     [paginator]="paginator"
                     [pageLinks]="pageLinks"
                     [scrollable]="scrollable"
                     [scrollHeight]="scrollHeight"
                     [filterDelay]="0"
                     dataKey="date"
                     #tableRef>

                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="!loading && emptyMessage">
                        <td [attr.colspan]="relevantColumns.length"
                            class="m-table-empty-message">
                            {{"table.empty" | translate}}
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="paginatorleft">
                    <ng-container *ngIf="total">
                        {{"table.total" | translate}}: {{total}}
                    </ng-container>
                </ng-template>

                <ng-template pTemplate="summary"
                             *ngIf="footer">
                    <ng-container *ngIf="total && !paginator">
                        {{"table.total" | translate}}: {{total}}
                    </ng-container>
                </ng-template>

                <ng-template pTemplate="header"
                             *ngIf="headers">
                    <tr>
                        <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                            [ngStyle]="column.ngStyle"
                            [ngSwitch]="column.field"
                            [pSortableColumn]="column.field"
                            [pSortableColumnDisabled]="!sortable || !column.sortable"
                            style="height: 100%; vertical-align: middle;">

                            <ng-container *ngSwitchCase="'selectionBox'">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isError'">
                                <app-svg-icon icon="close"
                                              tooltip="{{column.header | translate}}"></app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isException'">
                                <app-svg-icon icon="alert"
                                              tooltip="{{column.header | translate}}"></app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isPredicted'">
                                <app-svg-icon icon="prediction"
                                              tooltip="{{column.header | translate}}">
                                </app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchCase="'exceptionReason'">
                                <app-svg-icon icon="file"
                                              tooltip="{{column.header | translate}}">
                                </app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <div class="m-column-header">
                                    <div class="m-column-header-wrapper">
                                        <span class="m-column-title"
                                              [ngStyle]="column.headerStyle">
                                            {{column.header | translate}}
                                        </span>

                                        <p-sortIcon *ngIf="sortable && column.sortable"
                                                    [field]="column.field">
                                        </p-sortIcon>

                                        <app-table-filter *ngIf="filter"
                                                          class="m-column-filter"
                                                          [column]="column"
                                                          [table]="table">
                                        </app-table-filter>
                                    </div>

                                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                                </div>
                            </ng-container>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body"
                             let-expanded="expanded"
                             let-vehicleRow>
                    <tr [pSelectableRow]="vehicleRow"
                        [ngClass]="vehicleRow.isException ? 'row-warning' : vehicleRow.isError ? 'row-error' : vehicleRow.isPredicted ? 'row-predicted' : ''">

                        <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                            [ngSwitch]="column.field"
                            [ngStyle]="column.ngStyle">

                            <ng-container *ngSwitchCase="'selectionBox'">
                                <a [pRowToggler]="vehicleRow">
                                    <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                                </a>
                            </ng-container>

                            <ng-container *ngSwitchCase="'date'">
                                <span [ngStyle]="{color:(vehicleRow.isSpecialDay)?'rgb(255,128,0)':null,lineHeight:2}">
                                    {{ vehicleRow.date | momentDate:true:translateService.currentLang }}
                                </span>
                                <app-svg-icon *ngIf="vehicleRow.isSpecialDay"
                                              [ngStyle]="{'float': 'right','fill':'rgb(255,128,0)'}"
                                              icon="info"
                                              tooltip="{{vehicleRow.datePeriodName}}">
                                </app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isError'">
                                <div style="cursor: default;"
                                     (click)="isErrorInput.click(); $event.stopPropagation();">
                                    <input type="checkbox"
                                           style="margin-top: 6px; margin-right: 0;"
                                           [(ngModel)]="vehicleRow.isError"
                                           (click)="addClick(vehicleRow, $event); $event.stopPropagation()"
                                           (change)="handleRowIsError(vehicleRow)"
                                           #isErrorInput />
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isException'">
                                <div style="cursor: default;"
                                     (click)="isExceptionInput.click(); $event.stopPropagation();">
                                    <input type="checkbox"
                                           style="margin-top: 6px; margin-right: 0;"
                                           [(ngModel)]="vehicleRow.isException"
                                           (click)="addClick(vehicleRow, $event); $event.stopPropagation()"
                                           (change)="handleRowIsException(vehicleRow)"
                                           #isExceptionInput />
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'exceptionReason'">
                                <app-svg-icon *ngIf="vehicleRow.exceptionReason"
                                              icon="document"
                                              (click)="table.toggleRow(vehicleRow);"
                                              [tooltip]="vehicleRow.exceptionReason">
                                </app-svg-icon>
                            </ng-container>

                            <ng-container *ngSwitchCase="'averageVehicleLength'">
                                <ng-container *ngIf="vehicleRow.averageVehicleLength">
                                    {{vehicleRow[column.field] | number:'1.0-2'}}{{"measurements.meter" |
                                    translate}}
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="'isPredicted'">
                                <!-- won't be available until day is marked as predicted or day is without data or day is marked as exceptional/error  -->
                                <input type="checkbox"
                                       style="margin-top: 6px; margin-right: 0;"
                                       [(ngModel)]="vehicleRow.isPredicted"
                                       (click)="addClick(vehicleRow, $event); $event.stopPropagation()"
                                       (change)="handleRowIsPredicted(vehicleRow)"
                                       [disabled]="!vehicleRow.initialIsError && !vehicleRow.initialIsException && !vehicleRow.initialIsPredicted && vehicleRow.hasData" />
                            </ng-container>

                            <ng-container *ngSwitchCase="'t16'">
                                {{vehicleRow[column.field] | thousandSeperator}}
                            </ng-container>

                            <ng-container *ngSwitchCase="'t24'">
                                {{vehicleRow[column.field] | thousandSeperator}}
                            </ng-container>

                            <ng-container *ngSwitchCase="'t16AverageDayDeviationPercentage'">
                                <span *ngIf="vehicleRow.t16AverageDayDeviationPercentage || vehicleRow.t16AverageDayDeviationPercentage === 0"
                                      style="width: 100%;"
                                      [style.color]="vehicleRow.t16AverageDayDeviationPercentage > 0 ? 'green' : 'red'">
                                    {{vehicleRow.t16AverageDayDeviationPercentage}}%
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <span style="width: 100%;"
                                      [style.font-weight]="isMaxValue(column.field, vehicleRow) ? 'bold' : 'inherit'">
                                    {{vehicleRow.hourValues[column.field]?.total | thousandSeperator}}
                                </span>
                            </ng-container>

                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="rowexpansion"
                             let-vehicleRow
                             let-columns="columns">
                    <tr class="validation-vehicle-categories">
                        <td [attr.colspan]="columns.length">
                            <div class="row">
                                <div class="col-4">
                                    <textarea rows="2"
                                              class="p-inputtext"
                                              placeholder="{{'hourlyPreview.exceptionReason' | translate}}"
                                              [(ngModel)]="vehicleRow.exceptionReason"
                                              (change)="handleRowEdit(vehicleRow)"></textarea>
                                </div>

                                <div class="col-4">
                                    <table>
                                        <thead>
                                            <th *ngFor="let vehicleCategory of vehicleRow.vehicleCategories; trackBy: vehicleCategoryTrackByFn">
                                                {{vehicleCategory.viewModelEnum.label}}
                                            </th>
                                        </thead>

                                        <tr>
                                            <td *ngFor="let vehicleCategory of vehicleRow.vehicleCategories; trackBy: vehicleCategoryTrackByFn">
                                                <ng-container *ngIf="vehicleCategory.isHighest; else vehicleCategoryNilTemplate">
                                                    <b>{{vehicleCategory.percentage | percent}}</b>
                                                </ng-container>

                                                <ng-template #vehicleCategoryNilTemplate>
                                                    {{vehicleCategory.percentage | percent}}
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="col-4">
                                    <app-measuring-point-day-change-overview [measuringPoint]="measuringPoint"
                                                                             [date]="vehicleRow.date">
                                    </app-measuring-point-day-change-overview>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>
</div>