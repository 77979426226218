import { AvailableReports } from "src/app/ui/pages/administration/administration-container/organization/organization-available-reports/organization-available-reports.component";
import { IOrganization, OrganizationCreator } from "../models/user";
import { ExistsResult, ExistsValue } from "../models/search";
import { ApiService } from "./api";
import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AnalysisType } from "src/app/models/measuring-point";

@Injectable({
    providedIn: "root"
})
export class OrganizationApi extends ApiService<IOrganization, OrganizationCreator, IOrganization> {

    getRoute(): string {
        return "Organizations";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;
        return this.http.get<ExistsResult>(url, { params: { code: existsValue.value, parentOrganizationId: existsValue.parentOrganizationId.toString() } });
    }

    uploadLogo$(
        organization: IOrganization,
        file: File): Observable<IOrganization> {

        const url = `${super.getUrl()}/${organization.id}/UploadLogo`;
        const formData = new FormData();

        if (file) {
            formData.append("icon", file);
        }

        return this.http.post<IOrganization>(url, formData);
    }

    getChildren$(organizationId: number): Observable<IOrganization[]> {
        const url = `${super.getUrl()}/${organizationId}/children`;
        return this.http.get<IOrganization[]>(url);
    }

    getAllowedAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/AllowedAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    getInUseAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/InUseAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    addAnalysisTypes$(organizationId: number, analysisTypes: AnalysisType[]): Observable<void> {
        const url = `${super.getUrl()}/${organizationId}/AddAnalysisTypes`;
        return this.http.post<void>(url, analysisTypes);
    }

    getAvailableReports$(organizationId: number): Observable<string[]> {
        const url = `${super.getUrl()}/${organizationId}/ReportTypes`;
        return this.http.get<AvailableReports[]>(url).pipe(
            map((reports) => reports.map((report) => report.toCamelCase()))
        );
    }

    changeAvailableReports(organizationId: number, reports: string[]): Observable<void> {
        const url = `${super.getUrl()}/${organizationId}/ReportTypes`;
        return this.http.post<void>(url, reports);
    }
}
