<div class="status-summary__header">
    <div style="display:grid; grid-auto-flow: rows; align-items: center;">
        <div *ngFor="let item of locationStatus.devices">
            <app-device-status-header [data]="item"></app-device-status-header>
        </div>
    </div>

    <button (click)="toggleShowDeviceDetails()"
            class="m-btn m-btn-square"
            style="padding:0;">
        <app-svg-icon [icon]="showDeviceDetails() ? 'chevron-up' : 'chevron-down'"
                      [divStyle]="{'width': '16px', 'height': '16px'}"></app-svg-icon>
    </button>
</div>