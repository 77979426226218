<div class="m-layout-w-actions-top m-border-top">
    <div class="m-layout-area-top"
         style="display:flex; justify-content: space-between;">
        <div style="width:25%;"
             class="mt-4 mb-4 ml-16 m-input-group">
            <input type="text"
                   (appDebounce)="onSearch($event)"
                   placeholder="{{'vmsImages.search' | translate}}"
                   [(ngModel)]="searchCodeOrOrganisation"
                   #searchField
                   [disabled]="loading"
                   id="search_field"
                   style="background-color:white;"
                   class="p-inputtext" />
            <div class="m-input-group-addon">
                <i class="fa fa-search"
                   *ngIf="!searchCodeOrOrganisation"
                   aria-hidden="true"></i>
            </div>
        </div>
        <div class="">

            <button class="m-btn m-btn-secondary"
                    (click)="createFromEditor()"
                    *ngIf="createCommand && canCreateVmsImage">
                <app-svg-icon icon="add">
                </app-svg-icon>
                {{"vmsImages.create" | translate}}
            </button>
        </div>
    </div>


    <p-table [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             [loading]="loading"
             [totalRecords]="total"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             [(selection)]="selectedVmsImage"
             [lazy]="isLazy"
             (onLazyLoad)="loadTableRows($event)"
             dataKey="id"
             class="m-layout-area-body m-border-top"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer && !paginator">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngIf="selectCommand"
                    style="max-width: 50px">

                </th>

                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-vmsImage
                     let-expanded="expanded">
            <tr [pSelectableRow]="vmsImage">
                <td *ngIf="selectCommand"
                    style="max-width: 50px">
                    <p-tableRadioButton [value]="vmsImage"></p-tableRadioButton>
                </td>

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <ng-container *ngSwitchCase="'expand'">
                        <a [pRowToggler]="vmsImage">
                            <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ownerId'">
                        <app-navigator [organizationId]="vmsImage.ownerId"></app-navigator>
                    </ng-container>
                    <ng-container *ngSwitchCase="'code'">
                        {{ vmsImage.code }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'variants'">
                        {{ vmsImage.variants | translateVmsTypeVariants }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'image'">
                        <img *ngIf="vmsImage.variants.length > 0"
                             [src]="getVariantImage(vmsImage)"
                             style="max-width: 100px; max-height: 71px" />
                    </ng-container>
                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(vmsImage)"
                                                 *ngIf="command.rowValidFunc(vmsImage)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>
                </td>
            </tr>

        </ng-template>

        <!-- Row Details -->
        <ng-template pTemplate="rowexpansion"
                     let-vmsImage
                     let-columns="columns">

            <tr>
                <td [attr.colspan]="columns.length"
                    style="padding-top: 10px; padding-bottom: 10px; padding-left: 56px">
                    <app-vms-image-variants [vmsImage]="vmsImage"></app-vms-image-variants>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>