import { Injectable } from "@angular/core";
import { AssignmentStatusHistoryCreator, IAssignmentStatusHistory } from "../models/assignment";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class AssignmentStatusHistoryApi extends ApiService<IAssignmentStatusHistory, AssignmentStatusHistoryCreator, AssignmentStatusHistoryCreator> {

    getRoute(): string {
        return "AssignmentStatusesHistory";
    }
}