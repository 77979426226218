import { Component, Input } from "@angular/core";
import { ILocalizedUserMessage } from "src/app/models/pinned-data";
import { IScenario } from "src/app/models/scenario";

@Component({
    selector: "app-user-messages-pinned-data",
    templateUrl: "./user-messages-pinned-data.component.html",
})
export class UserMessagesPinnedDataComponent {

    @Input() userMessages: ILocalizedUserMessage[];

    constructor() { }
}
