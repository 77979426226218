// Load html in Angular
// In content.html use:
// <div [innerHTML]="post.content.rendered | safeHtml " class="entry-body"></div>
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FileUtils } from "src/app/utilities";

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {

    constructor(private readonly sanitizer: DomSanitizer) {
    }

    transform(value: string) {
        if (!value) return value;

        if (value.contains("blob:") || value.contains("data:") || FileUtils.isPhotoUrl(value)) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }

        if (value.contains("http:") || value.contains("https:")) {
            return this.sanitizer.bypassSecurityTrustHtml(value);
        }

        return value;
    }
}