import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpResponse, HttpEventType } from "@angular/common/http";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { TranslateService } from "@ngx-translate/core";
import { ErrorService } from "../services/error.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

    constructor(
        private readonly errorService: ErrorService,
        private readonly translateService: TranslateService,
        private readonly impersonationService: ImpersonationService,
        private readonly authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers: { [key: string]: string } = {};
        headers["Timezone-Offset"] = new Date().getTimezoneOffset().toString();

        const language = this.translateService.currentLang;
        if (language) {
            headers["Accept-Language"] = language;
        }

        const impersonatedOrganizationId = this.impersonationService.organizationId;
        if (impersonatedOrganizationId) {
            headers["Impersonation"] = impersonatedOrganizationId.toString();
        }

        if (this.impersonationService.role) {
            headers["ImpersonationRole"] = this.impersonationService.role;
        }

        if (request.method === "GET") {
            let params = new HttpParams({
                fromString: request.params.toString()
            });

            params = params.append("ts", `${Date.now()}`);

            request = request.clone({
                params: params
            });
        }

        request = request.clone({
            setHeaders: headers
        });

        const handleError = (response: HttpResponse<any>) => {
            if (response.status !== undefined && (response.status < 200 || response.status >= 300)) {
                if (response.status === 401) {
                    this.authenticationService.login();

                } else if (response.status !== 304 && response.status !== 403) {
                    this.errorService.handleError(response);
                }
            }

            return response;
        };

        const onSuccess = (response: HttpResponse<any>) => {
            if (response.type === HttpEventType.Response) {
                return handleError(response);
            }

            return response;
        };

        return next.handle(request).pipe(tap(onSuccess, handleError));
    }
}