import { ApiTableComponent, FilterType, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, OnChanges, Input, ElementRef, ViewChild } from "@angular/core";
import { ManageBatteryDischargeTableDialogComponent } from "../manage-battery-discharge-table-dialog/manage-battery-discharge-table.dialog";
import { BatteryDischargeTableApi } from "src/app/resource/battery-discharge-table.api";
import { IBatteryDischargeTable } from "src/app/models/battery-discharge-table";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";

@Component({
    selector: "app-admin-battery-discharge-tables",
    templateUrl: "./admin-battery-discharge-tables.component.html"
})
export class AdminBatteryDischargeTablesComponent extends ApiTableComponent<IBatteryDischargeTable> implements OnChanges {
    @ViewChild(ManageBatteryDischargeTableDialogComponent, { static: true }) manageBatteryDischargeTableDialog: ManageBatteryDischargeTableDialogComponent;

    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly batteryDischargeTableApi: BatteryDischargeTableApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService) {

        super("admin-battery-discharge-tables.component", elementRef, batteryDischargeTableApi, tableService);
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";

        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));

        this.registerCommand({
            text: "batteryDischargeTables.edit",
            icon: "edit",
            click: (batteryDischargeTable) => this.edit(batteryDischargeTable),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (batteryDischargeTable) => this.delete(batteryDischargeTable),
            validFunc: () => this.deleteCommand
        });
    }

    create() {
        const onCreate = () => {
            this.reload();
        };

        this.manageBatteryDischargeTableDialog.create(onCreate);
    }

    edit(batteryDischargeTable: IBatteryDischargeTable) {
        const onEdit = () => {
            this.reload();
        };

        this.manageBatteryDischargeTableDialog.edit(batteryDischargeTable, onEdit);
    }

    delete(batteryDischargeTable: IBatteryDischargeTable) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.batteryDischargeTableApi.delete$(batteryDischargeTable.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("batteryDischargeTables.deleteConfirmation", batteryDischargeTable);
        this.modalService.delete(modalBody, onDelete);
    }
}