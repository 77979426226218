import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { ConfigurationService } from "../configuration.service";
import * as signalR from "@microsoft/signalr";
import * as signalRMessagePack from "@microsoft/signalr-protocol-msgpack";
import { environment } from "src/environments/environment";
import { RealtimeConnection } from "./realtime-connection";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

// Take care of the technicalities of setting up a SignalR connection
@Injectable({
    providedIn: "root"
})
export class SignalRService {

    constructor(private authenticationService: AuthenticationService,
        private readonly configurationService: ConfigurationService,
        private readonly msalService: MsalService,
        private readonly router: Router) {
    }

    public CreateConnection(contextId: number, endpoint: string): RealtimeConnection {
        const url = `${this.configurationService.configuration.url}${endpoint}`;

        const connectionOptions = {
            accessTokenFactory: () => {
                return this.msalService.initialize().toPromise().then(result => this.authenticationService.acquireTokenSilent$().toPromise()
                    .then(result => result.accessToken));
            }
        } as signalR.IHttpConnectionOptions;

        const hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(environment.production ? signalR.LogLevel.None : signalR.LogLevel.Information)
            .withHubProtocol(new signalRMessagePack.MessagePackHubProtocol())
            .withAutomaticReconnect()
            .withUrl(url, connectionOptions)
            .build();

        const connection = new RealtimeConnection(contextId, hubConnection);

        return connection;
    }

    public startConnection(connection: RealtimeConnection) {
        if (connection.connection.state !== signalR.HubConnectionState.Disconnected) return;

        const onSuccess = async () => {
            const result = await connection.connection.invoke("subscribe", connection.contextId);

            // Without messagePack: 200
            // With: "OK"
            // Let's just prepare for both
            if (result !== 200 && result !== "OK") {
                if (result === 401 || result === "Unauthorized") {
                    // there can be a next situation
                    // 1. SignalR ws connection established
                    // 2. Token expired
                    // 3. Connection lost
                    // 4. Reconnection start but all the time 401 will returned
                    this.authenticationService.login(); // we simply reload current location to refresh everything
                }

                this.stopConnection(connection);
            } else {
                connection.onDetachEvents(connection);
                connection.onRegisterEvents(connection);
            }
        };

        const onReconnect = async () => {
            onSuccess();
        };

        const onError = () => {
            setTimeout(() => {
                this.startConnection(connection);
            }, 6000);
        };

        connection.connection.onreconnected(onReconnect);
        connection.connection.onclose(onError);

        connection.connection
            .start()
            .then(onSuccess, onError)
            .catch(onError);
    }

    public stopConnection(connection: RealtimeConnection) {
        connection.onDetachEvents(connection);
        if (connection.connection.state !== signalR.HubConnectionState.Connected) return;

        connection.connection.stop();
    }
}