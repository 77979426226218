import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LocationMapComponent } from "./components/location-map/location-map.component";
import { MeasuringPointLocationComponent } from "./components/details/measuring-point-location/measuring-point-location.component";
import { AuthGuard } from "src/app/guards/auth.guard";
import { MeasuringPointDetailComponent } from "./components/details/measuring-point-detail/measuring-point-detail.component";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";
import { MeasuringPointValidationComponent } from "./components/details/measuring-point-validation/measuring-point-validation.component";
import { MeasuringPointUploadContainerComponent } from "./components/details/measuring-point-upload-container/measuring-point-upload-container.component";
import { UploadMetricComponent } from "../shared/components/upload-metric/upload-metric.component";
import { MeasuringPointUploadComponent } from "./components/details/measuring-point-upload/measuring-point-upload.component";
import { MeasuringPointLinksComponent } from "./components/details/measuring-point-links/measuring-point-links.component";
import { MeasuringPointCctvConfigurationComponent } from "./components/details/measuring-point-cctv-configuration/measuring-point-cctv-configuration.component";
import { MeasuringPointVmsModeConfigurationComponent } from "./components/details/measuring-point-vms-mode-configuration/measuring-point-vms-mode-configuration.component";
import { MeasuringPointLightModeConfigurationComponent } from "./components/details/measuring-point-light-mode-configuration/measuring-point-light-mode-configuration.component";
import { MeasuringPointExternalInputConfigurationComponent } from "./components/details/measuring-point-external-input-configuration/measuring-point-external-input-configuration.component";
import { MeasuringPointCountingZoneConfigurationComponent } from "./components/details/measuring-point-counting-zone-configuration/measuring-point-counting-zone-configuration.component";
import { MeasuringPointFloatingCarComponent } from "./components/details/measuring-point-floating-car/measuring-point-floating-car.component";
import { MeasuringPointParkingComponent } from "./components/details/measuring-point-parking/measuring-point-parking.component";
import { TotemDisplayConfigurationComponent } from "./components/details/measuring-point-totem-display/totem-display-configuration/totem-display-configuration.component";
import { MeasuringPointStatusComponent } from "./components/details/measuring-point-status/measuring-point-status.component";
import { JournalComponent } from "../shared/components/journal/journal.component";
import { AuditTrailsComponent } from "../shared/components/audit-trails/audit-trails.component";
import { AlertHistoryComponent } from "../shared/components/alert-history/alert-history.component";
import { MeasuringPointArrowBoardConfigurationComponent } from "./components/details/measuring-point-arrow-board-configuration/measuring-point-arrow-board-configuration.component";
import { MeasuringPointVehicleConfigurationComponent } from "./components/details/measuring-point-vehicle-configuration/measuring-point-vehicle-configuration.component";

const routes: Routes = [
    { path: "", component: LocationMapComponent, data: { name: "location-map.title" } },
    {
        path: ":locationId/:measuringPointId", component: MeasuringPointLocationComponent, data: { name: "projects.title" }, canActivate: [AuthGuard], children: [
            { path: "", redirectTo: "details", pathMatch: "full" },
            { path: "details", component: MeasuringPointDetailComponent, canDeactivate: [PendingChangesGuard] },
            { path: "validation", component: MeasuringPointValidationComponent, canDeactivate: [PendingChangesGuard] },
            {
                path: "upload", component: MeasuringPointUploadContainerComponent, children: [
                    { path: "", redirectTo: "metric", pathMatch: "full" },
                    { path: "metric", component: UploadMetricComponent },
                    { path: "uploads", component: MeasuringPointUploadComponent }
                ]
            },
            { path: "links", component: MeasuringPointLinksComponent },
            { path: "cctv", component: MeasuringPointCctvConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "vehicle", component: MeasuringPointVehicleConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "aggregation", loadChildren: () => import("src/app/modules/measuring-point-aggregation/measuring-point-aggregation.module").then(m => m.MeasuringPointAggregationModule), canDeactivate: [PendingChangesGuard] },
            { path: "vms", component: MeasuringPointVmsModeConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "light", component: MeasuringPointLightModeConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "externalinput", component: MeasuringPointExternalInputConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "countingzone", component: MeasuringPointCountingZoneConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "arrowboard", component: MeasuringPointArrowBoardConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "floatingcar", component: MeasuringPointFloatingCarComponent, canDeactivate: [PendingChangesGuard] },
            { path: "parking", component: MeasuringPointParkingComponent, canDeactivate: [PendingChangesGuard] },
            { path: "totemdisplay", component: TotemDisplayConfigurationComponent, canDeactivate: [PendingChangesGuard] },
            { path: "status", component: MeasuringPointStatusComponent, canDeactivate: [PendingChangesGuard] },
            { path: "alertHistory", component: AlertHistoryComponent },
            { path: "journal", component: JournalComponent },
            { path: "audit", component: AuditTrailsComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LocationsRoutingModule { }