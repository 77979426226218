<div class="m-form-group">
    <h4>{{ "general.changes" | translate }}</h4>
</div>

<div *ngFor="let modification of modifications"
     class="m-form-group row">
    <label class="col-2">
        {{(preparePropertyNameForTranslation(modification.propertyName)) | translate}}
    </label>

    <ng-container *ngIf="!modification.isEnumValue; else enumTranslationBlock">
        <div class="col-4">
            <s>{{isBoolean(modification.previousValue) ? (modification.previousValue ? '✓' : '✗') : (isDate(modification.previousValue) ? (modification.previousValue | momentDate:false:translateService.currentLang) : modification.previousValue)}}</s>
        </div>

        <div class="col-2 text-center">
            &rarr;
        </div>

        <div class="col-4">
            {{isBoolean(modification.currentValue) ? (modification.currentValue ? '✓' : '✗') : (isDate(modification.currentValue) ? (modification.currentValue | momentDate:false:translateService.currentLang) : modification.currentValue)}}
        </div>
    </ng-container>

    <ng-template #enumTranslationBlock>
        <div class="col-4">
            <app-domain-data-field *ngIf="modification.previousValue"
                                   [enumKey]="modification.previousValue.toString().toCamelCase()"
                                   [ngStyle]="{textDecoration:'line-through'}"
                                   [domainDataType]="modification.enumName.toCamelCase()"> </app-domain-data-field>
        </div>

        <div class="col-2 text-center">
            &rarr;
        </div>

        <div class="col-4">
            <app-domain-data-field *ngIf="modification.currentValue"
                                   [enumKey]="modification.currentValue.toString().toCamelCase()"
                                   [domainDataType]="modification.enumName.toCamelCase()"> </app-domain-data-field>
        </div>
    </ng-template>
</div>