import { TableService, TableColumn, FilterType, ColumnType, ModelLocalizationTable } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ManageJournalCategoryDialogComponent } from "./../manage-journal-category-dialog/manage-journal-category-dialog.component";
import { JournalCategoryApi } from "src/app/resource/journal-category.api";
import { IJournalCategory } from "src/app/models/journal";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-journal-categories",
    templateUrl: "./journal-categories.component.html"
})
export class JournalCategoriesComponent extends ModelLocalizationTable<IJournalCategory> implements OnInit {
    @ViewChild(ManageJournalCategoryDialogComponent, { static: true }) manageJournalCategoryDialog: ManageJournalCategoryDialogComponent;

    @Input() contextTypeId: string;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        translateService: TranslateService,
        domainDataService: DomainDataService,
        readonly journalCategoryApi: JournalCategoryApi,
        private readonly modalService: ModalService) {

        super("journal-categories.component", elementRef, journalCategoryApi, tableService, domainDataService, translateService);

        this.selectionMode = "";
        this.includeObsolete = true;

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, stringResourcePropertyName: "codeStringResourceId" }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isMaas", "general.maas", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));

        this.registerCommand({
            text: "categories.edit",
            icon: "edit",
            click: (category) => this.edit(category)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (category) => this.delete(category),
            rowValidFunc: (category) => !category.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (category) => this.restore(category),
            rowValidFunc: (category) => category.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.includeObsolete = false;

        if (this.contextTypeId) {
            searchParameters.filter = [
                new FilterDescriptor("ContextTypeId", this.contextTypeId)
            ];
        }

        return searchParameters;
    }
    create() {
        this.manageJournalCategoryDialog.create(this.contextTypeId, () => {
            this.reload();
        });
    }

    edit(category: IJournalCategory) {
        this.manageJournalCategoryDialog.edit(category);
    }

    async delete(category: IJournalCategory) {

        const onDeleteSuccess = () => {
            category.isObsolete = true;
        };

        const onDelete = () => {
            this.journalCategoryApi.delete$(category.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translateService.instant("categories.deleteConfirmation", { code: this.domainDataService.translate(category.codeStringResourceId) });
        this.modalService.delete(modalBody, onDelete);
    }

    // restore means: undelete
    async restore(category: IJournalCategory) {
        const onRestoreSuccess = () => {
            category.isObsolete = false;
        };

        this.journalCategoryApi.restore$(category.id).subscribe(onRestoreSuccess);
    }
}
