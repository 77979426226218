import { Component } from "@angular/core";
import * as lodash from "lodash";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { ITextFieldConfiguration, ITotemDisplayConfiguration } from "src/app/models/totem-display-configuration";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ToastService } from "src/app/services/toast.service";

@Component({
    selector: "app-totem-display-fields-dialog",
    templateUrl: "./totem-display-fields.dialog.html"
})
export class TotemDisplayFieldsDialogComponent extends DialogComponentBase {
    measuringPoint: IMeasuringPoint;
    totemDisplayConfiguration: ITotemDisplayConfiguration;
    submitCallback: (IMeasuringPoint) => void;
    submitting = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly measuringPointApi: MeasuringPointApi
    ) {
        super();
    }

    trackTextFieldFn(index: number, field: ITextFieldConfiguration): number {
        return field.id;
    }

    create(measuringPoint: IMeasuringPoint, submitCallback: (IMeasuringPoint) => void) {
        this.measuringPoint = measuringPoint;
        // We bind on a clone, so we can always reset to original
        this.totemDisplayConfiguration = lodash.cloneDeep(measuringPoint.totemDisplayConfiguration);
        this.submitCallback = submitCallback;
        this.openDialog();
    }

    addField() {
        const maxId = this.totemDisplayConfiguration.fields.map(x => x.id).max() + 1;
        const newField = {
            id: maxId,
            lineCount: 1,
            lineLength: 10,
        } as ITextFieldConfiguration;
        this.totemDisplayConfiguration.fields.push(newField);
    }

    removeField(field: ITextFieldConfiguration) {
        this.totemDisplayConfiguration.fields = this.totemDisplayConfiguration.fields.remove(field);
    }

    async submit() {
        this.submitting = true;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.close();
            this.submitCallback(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        Object.assign(measuringPointUpdater.totemDisplayConfiguration, this.totemDisplayConfiguration);

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }
}