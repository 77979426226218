import { IMeasuringPointAggregation, MeasuringPointAggregationCreator } from "../models/measuring-point-aggregation";
import { ApiServiceBase } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IProgressCreated } from "../models/progress";
import { IMeasuringPoint } from "../models/measuring-point";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointAggregationApi extends ApiServiceBase {

    getRoute(): string {
        return "MeasuringPoints/{id}/Aggregations";
    }

    get$(measuringPoint: IMeasuringPoint) {
        const routeParams = this.getRouteParams(measuringPoint);
        return this.getOther$<IMeasuringPointAggregation[]>(null, null, null, true, routeParams);
    }

    updateWithProgress$(measuringPoint: IMeasuringPoint, updaters: MeasuringPointAggregationCreator[]): Observable<IProgressCreated> {
        const url = super.getUrl(this.getRouteParams(measuringPoint));
        return this.http.post<IProgressCreated>(url + "/UpdateWithProgress", updaters);
    }

    private getRouteParams(measuringPoint: IMeasuringPoint): { id: string } {
        return {
            id: measuringPoint.id.toString()
        };
    }
}