import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IAssignment } from "src/app/models/assignment";
import { FilterDescriptor, FilterOperator, ISearchResult, SearchParameters } from "src/app/models/search";
import { ITask } from "src/app/models/task";
import { ITaskStatusComparison } from "src/app/models/task-status-comparison";
import { ColumnType, ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { TasksStatusesComparisonApi } from "src/app/resource/tasks-statuses-comparison.api";

@Component({
    selector: "app-tasks-history",
    templateUrl: "./tasks-history.component.html",
    styleUrls: ["./tasks-history.component.css"]
})
export class TasksHistoryComponent
    extends LazyTableComponent<ITaskStatusComparison>
    implements OnChanges {

    @Input() assignment: IAssignment;
    @Input() task: ITask;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly taskStatusApi: TasksStatusesComparisonApi,
        readonly translateService: TranslateService) {

        super("tasksHistory.component", elementRef, taskStatusApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("timestamp", "auditTrails.timestamp", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("creator", "taskStatusHistory.creator", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("organization", "general.organization", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("statusType", "taskStatusHistory.modificationType", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("assignment", "general.assignment", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("task", "general.task", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["task"]) {
            this.setTask(this.task);
        }

        if (changes["assignment"]) {
            this.setAssignment(this.assignment);
        }

        super.ngOnChanges(changes);
    }

    setAssignment(assignment: IAssignment) {
        this.clear();
        this.assignment = assignment;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setTask(task: ITask) {
        this.clear();
        this.task = task;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    clear(): void {
        this.task = null;
        this.assignment = null;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.assignment) {
            searchParameters.filter.push(new FilterDescriptor("assignmentId", this.assignment.id, FilterOperator.equals));
        } else {
            searchParameters.filter.push(new FilterDescriptor("taskId", this.task.id, FilterOperator.equals));
        }

        return searchParameters;
    }

    protected async onSuccess(result: ISearchResult<ITaskStatusComparison>) {
        await super.onSuccess(result);

        // onSuccess() is invoked after a successful search; make sure all previously expanded rows are collapsed now
        this.collapseAllRows();
    }
}
