<ng-container *ngIf="showComponent">
    <ng-template #googleMapComponent>
        <div class="m-map-search-container m-fixed-height"
             *ngIf="showSearchbutton && getCurrentMapDetail() === 0">
            <app-location-dialog></app-location-dialog>
        </div>

        <app-advanced-map [searchControl]="true"
                          [showCoordinates]="true"
                          [showMapTypeSelector]="true"
                          [enableLabels]="!readonly"
                          [forceUpdate]="true"
                          [searchbutton]="searchbutton"
                          [saveState]="true"
                          (mapReady)="handleMapReady($event)"
                          (mapClick)="handleMapClick($event)"
                          (mapRightClick)="handleMapRightClick($event)"
                          (locationClick)="handleLocationClick($event)"
                          (locationRightClick)="handleLocationRightClick($event)"
                          (mapDragStart)="handleMapDragStart($event)"
                          (groupSelect)="handleGroupSummarySelect($event)"
                          (groupDeselect)="handleGroupSummaryDeselect($event)"
                          (groupMarkerClose)="handleGroupMarkerClose($event)"
                          id="mainGoogleMap"
                          #gmap>
        </app-advanced-map>

        <app-map-searchbutton [loaded]="loaded"
                              [readonly]="readonly"
                              [splitMap]="this"
                              #searchbutton></app-map-searchbutton>

    </ng-template>

    <ng-template #readonlyView>
        <div class="m-map">
            <ng-container *ngTemplateOutlet="googleMapComponent"></ng-container>

            <div class="m-map-info-panel m-card m-card-brand m-shadow p-16"
                 *ngIf="showInfoPanel && readonly !== null">

                <app-icon-button icon="close"
                                 class="m-map-info-panel-close"
                                 (click)="closeInfoPanel()"></app-icon-button>

                <div class="flex flex-column align-center">
                    <img alt="logo"
                         src="/assets/img/brand-icon.png?v=20240726?v=20240725"
                         class="mb-16">

                    <h2>{{websiteService.getProductName()}}</h2>
                </div>

                <div class="mt-16">
                    <p [innerHTML]="'map.infoPanel1' | translate"></p>

                    <ul class="m-list-inside mt-8">
                        <li>
                            {{'map.infoPanel2' | translate}}
                        </li>
                        <li>
                            {{'map.infoPanel3' | translate}}
                        </li>
                        <li>
                            {{'map.infoPanel4' | translate}}
                        </li>
                    </ul>

                    <p class="mt-8">
                        {{"map.registration" | translate}}
                        <a href="mailto:stijn.goossens@signco.be"
                           class="m-navigator">{{"map.contactUs" | translate}}</a>
                    </p>
                </div>
            </div>

        </div>
    </ng-template>

    <ng-container *ngIf="!readonly; else readonlyView">
        <div class="m-page-content-wrapper">
            <as-split direction="vertical"
                      (dragEnd)="onDragEnd($event)">

                <as-split-area [(size)]="mapSplitSize">
                    <div class="m-map">
                        <ng-container *ngTemplateOutlet="googleMapComponent"></ng-container>

                        <app-map-detail-popup [splitMap]="this"></app-map-detail-popup>

                        <div *ngIf="liveTiles">
                            <div *ngFor="let liveTile of liveTiles">
                                <app-live-tile [gmap]="gmap"
                                               [viewModel]="liveTile">
                                </app-live-tile>
                            </div>
                        </div>


                    </div>
                </as-split-area>

                <as-split-area *ngIf="getCurrentMapDetail() !== 0"
                               [(size)]="gridSplitSize"
                               class="m-layout-default">
                    <router-outlet (activate)="onRouterActivate($event)"></router-outlet>
                    <app-location-dialog></app-location-dialog>
                </as-split-area>
            </as-split>
        </div>

    </ng-container>
</ng-container>

<app-location-dialog></app-location-dialog>