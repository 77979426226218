import { MeasuringPointUtils } from "../utilities/measuring-point-utils";
import { IGroup, IGroupMeasuringPoint } from "./group";
import { IProject } from "./project";
import { IMeasuringPointSummary } from "./web/measuring-point-summary";

export class ValidationContext {
    allMeasuringPoints: IMeasuringPointSummary[];

    constructor(
        readonly from: Date,
        readonly to: Date,
        readonly groups: IGroup[],
        readonly measuringPoints: IMeasuringPointSummary[],
        readonly projects: IProject[]) {

        if (!this.groups) this.groups = [];
        if (!this.measuringPoints) this.measuringPoints = [];
        if (!this.projects) this.projects = [];

        const groupMeasuringPoints = this.groups.selectMany<IGroup, IGroupMeasuringPoint>(x => x.measuringPoints).map(x => x.measuringPoint);
        this.allMeasuringPoints = this.measuringPoints.concat(groupMeasuringPoints);

        for (const project of this.projects) {
            if (project.projectMeasuringPoints && project.projectMeasuringPoints.length > 0) {
                this.allMeasuringPoints = this.allMeasuringPoints.concat(project.projectMeasuringPoints.map(x => MeasuringPointUtils.toSummary(x.measuringPoint)));
            }

            if (project.projectGroups && project.projectGroups.length > 0) {
                const mps = project.projectGroups.map(x => x.group)
                    .selectMany<IGroup, IGroupMeasuringPoint>(x => x.measuringPoints)
                    .map(x => x.measuringPoint);

                this.allMeasuringPoints = this.allMeasuringPoints.concat(mps);
            }
        }
    }
}