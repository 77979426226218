import { HttpClient, HttpBackend } from "@angular/common/http";
import { IEnvironment } from "src/app/models/environment";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ConfigurationService {

    private httpClient: HttpClient;

    public configuration: IEnvironment;

    constructor(handler: HttpBackend) {
        // avoid interceptors
        this.httpClient = new HttpClient(handler);
    }

    public async loadConfiguration(): Promise<void> {
        this.configuration = await firstValueFrom(this.httpClient.get<IEnvironment>(environment.configUrl));
    }

    get isLocal(): boolean {
        return this.configuration && this.configuration.title === "Local";
    }
}