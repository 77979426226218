import { MapDataService } from "src/app/services/map-data.service";
import { MapUtils } from "src/app/utilities";
import { NgZone } from "@angular/core";
import { IGroupSummary } from "src/app/models/web";

export class GroupPolygon {

    static blurredOptions: google.maps.PolygonOptions = {
        strokeOpacity: 0.4,
        fillOpacity: 0.1
    };

    static highlightedOptions: google.maps.PolygonOptions = {
        strokeOpacity: 0.65,
        fillOpacity: 0.25
    };

    static focusedOptions: google.maps.PolygonOptions = {
        strokeOpacity: 0.75,
        fillOpacity: 0.4
    };

    polygon: google.maps.Polygon;
    polyline: google.maps.Polyline;
    isFocused: boolean;

    private readonly mapDataServiceKey: string;

    // This sadly doesn't work with bindings
    // So we pass a group object here that will be altered later on
    // Have to subscribe to events in case group updates externally
    constructor(
        public gmap: any, // AdvancedMapComponent,
        public mapDataService: MapDataService,
        public group: IGroupSummary,
        private readonly zone: NgZone,
        private readonly onClick?: (groupPolygon: GroupPolygon) => void) {

        this.mapDataServiceKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToEditGroupUpdate(this.mapDataServiceKey, updatedGroup => {
            if (updatedGroup && updatedGroup.id !== this.group.id) return;

            if (updatedGroup && this.group) {
                this.group.color = updatedGroup.color;
            }

            this.updateArea();
            this.updateColor();
        });

        const color = this.group.color || "#FF0000";

        this.zone.runOutsideAngular(() => {

            this.polygon = new google.maps.Polygon(Object.assign({
                strokeColor: color,
                strokeWeight: 5,
                fillColor: color,
                title: this.group.code
            }, GroupPolygon.blurredOptions));

        });

        // ReSharper disable once InconsistentNaming
        this.polygon.addListener("click", (e: { Ia: MouseEvent }) => {
            this.zone.run(() => {
                if (this.onClick) {
                    this.onClick(this);
                }

                if (e && e.Ia) {
                    e.Ia.stop();
                }
            });
        });

        this.polygon.addListener("mouseover", () => {
            this.gmap.map.set("disableDoubleClickZoom", true);

            if (!this.isFocused) {
                this.polygon.setOptions(GroupPolygon.highlightedOptions);
            }
        });

        this.polygon.addListener("mouseout", () => {
            this.gmap.map.set("disableDoubleClickZoom", false);

            if (!this.isFocused) {
                this.polygon.setOptions(GroupPolygon.blurredOptions);
            }
        });
    }

    updateArea() {
        if (!this.gmap) return;

        const path = this.getLatLngLiteral();
        this.polygon.setPath(path);

        const area = google.maps.geometry.spherical.computeArea(path);
        this.polygon.set("zIndex", -area);
    }

    private getLatLngLiteral(): google.maps.LatLng[] {
        // When editing this current one, we calculate it locally
        if (this.mapDataService.editGroup && this.mapDataService.editGroup.id === this.group.id) {
            const measuringPointCoords: google.maps.LatLng[] = [];

            for (const mp of this.mapDataService.editGroup.measuringPoints) {
                measuringPointCoords.push(MapUtils.toLatLng(mp.measuringPoint.location));
            }

            const mvcArray = MapUtils.convexHull(measuringPointCoords);
            return mvcArray.getArray();
        }

        if (!this.group.coordinates) return [];

        return this.group.coordinates.map(x => {
            return MapUtils.toLatLng(x);
        });
    }

    updateColor() {
        this.zone.runOutsideAngular(() => {
            if (this.group.color !== this.polygon.get("strokeColor")) {
                this.polygon.setOptions({
                    strokeColor: this.group.color,
                    fillColor: this.group.color
                });
            }
        });
    }

    focus(drawDashedPolyline: boolean = true) {
        this.isFocused = true;
        this.polygon.setOptions(GroupPolygon.focusedOptions);

        if (!drawDashedPolyline) return;

        const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 1.5,
            strokeWeight: 8,
        };

        const path = this.getLatLngLiteral();
        if (path && path.length > 0) {
            if (!this.polyline) {
                this.zone.runOutsideAngular(() => {
                    this.polyline = new google.maps.Polyline({
                        path: [...path, path[0]],
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: lineSymbol,
                                offset: "0",
                                repeat: "15px",
                            },
                        ],
                        map: this.gmap.map,
                    });
                });
            } else if (!this.polyline.getMap()) {
                this.polyline.setMap(this.gmap.map);
            }
        }
    }

    center() {
        this.gmap.basicMap.centerOnLocations(this.getLatLngLiteral());
    }

    blur() {
        this.isFocused = false;
        this.polygon.setOptions(GroupPolygon.blurredOptions);
        if (this.polyline) this.hidePolyline();
    }

    show() {
        if (this.polygon.getMap()) return;

        this.updateArea();
        this.polygon.setMap(this.gmap.map);
    }

    hide() {
        this.polygon.setMap(null);
        if (this.polyline) this.hidePolyline();
    }

    private hidePolyline() {
        this.polyline.setMap(null);
    }

    destroy() {
        this.hide();
        this.mapDataService.unsubscribe(this.mapDataServiceKey);
    }
}
