<p-dialog [(visible)]="visible"
          id="typesFilterDialog"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">
    <p-header>{{"mapSearch.types" | translate}}</p-header>

    @if (currentOrganization) {
    <div class="p-16">
        <app-analysis-type-view #analysisTypeViewComponent
                                [analysisTypeView]="analysisTypeView"
                                [organizationId]="currentOrganization.id"
                                [activeFilterTypes]="activeFilterTypes"
                                [multiSelection]="multiSelection" />
    </div>
    }

    <p-footer>
        <button (click)="saveSelection()"
                id="buttonOk"
                class="m-btn m-btn-primary">{{"form.ok" | translate}}</button>
    </p-footer>
</p-dialog>