<form [formGroup]="zigbeeConfigurationForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      *ngIf="zigbeeConfigurationForm"
      autocomplete="off">

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': zigbeeConfigurationForm.get('port').showErrors }">

        <label class="col-4"
               for="text">
            {{"zigbeeConfiguration.port" | translate}}
        </label>

        <div class="col-8">
            <input type="text"
                   class="p-inputtext"
                   id="port"
                   formControlName="port"
                   #portInput />

            <app-form-validation [control]="zigbeeConfigurationForm.get('port')"
                                 [input]="portInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': zigbeeConfigurationForm.get('roleId').showErrors }">

        <label class="col-4"
               for="roleId">
            {{"general.role" | translate}}
        </label>

        <div class="col-8">
            <p-dropdown id="roleId"
                        [options]="zigbeeRoles"
                        [panelStyle]="{'width':'100%'}"
                        formControlName="roleId"
                        #roleIdInput></p-dropdown>

            <app-form-validation [control]="zigbeeConfigurationForm.get('roleId')"
                                 [input]="roleIdInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': zigbeeConfigurationForm.get('channel').showErrors }">

        <label class="col-4"
               for="text">
            {{"zigbeeConfiguration.channel" | translate}}
        </label>

        <div class="col-8">
            <input type="number"
                   class="p-inputtext"
                   id="channel"
                   formControlName="channel"
                   #channelInput />

            <app-form-validation [control]="zigbeeConfigurationForm.get('channel')"
                                 [input]="channelInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row"
         [ngClass]="{ 'has-error': zigbeeConfigurationForm.get('resetPin').showErrors }">

        <label class="col-4"
               for="text">
            {{"zigbeeConfiguration.resetPin" | translate}}
        </label>

        <div class="col-8">
            <input type="number"
                   class="p-inputtext"
                   id="resetPin"
                   formControlName="resetPin"
                   #resetPinInput />

            <app-form-validation [control]="zigbeeConfigurationForm.get('resetPin')"
                                 [input]="resetPinInput">
            </app-form-validation>
        </div>
    </div>

</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>