import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ReportHistoryComponent } from "./components/report-history/report-history.component";
import { HistoryPageComponent } from "./components/history-page/history-page.component";
import { UploadComponent } from "./components/upload/upload.component";
import { AuditTrailsComponent } from "../shared/components/audit-trails/audit-trails.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    {
        path: "", component: HistoryPageComponent, canActivate: [AuthGuard], children: [
            { path: "", redirectTo: "uploads", pathMatch: "full" },
            { path: "uploads", component: UploadComponent, data: { name: "uploads.title" } },
            { path: "reports", component: ReportHistoryComponent, data: { name: "reports.title" } },
            { path: "audit", component: AuditTrailsComponent, data: { name: "auditTrails.title" } },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HistoryRoutingModule { }
