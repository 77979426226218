<div class="m-page-content-wrapper">
    <div class="m-fixed-height m-layout-w-actions-top">
        <div class="m-nav-secondary m-layout-area-top">
            <app-tabs [tabs]="tabs"
                      [selectedTab]="selectedTab"
                      [relativeRouting]="true"
                      (selectedTabChange)="navigateTo($event);"
                      divStyle="m-card">
            </app-tabs>
        </div>

        <div class="p-16 m-layout-area-body m-layout-default"
             [ngClass]="ngClass">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>