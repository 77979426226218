<div style="display: grid; gap:4px;">
    <div style="display:grid; grid-template-columns: auto 1fr auto; gap: 8px;">
        <span class="m-circle"
              style="background-color: #f4f4f4;">
            <app-svg-icon [icon]="data.analysisType"
                          [tooltip]="data.analysisType | translateEnum:'analysisType'">
            </app-svg-icon>
        </span>
        <div>
            <h4>{{ data.code }}</h4>
            <small>{{data.description}}</small>
        </div>
        <button class="m-btn m-btn-square"
                (click)="openLiveTile()"
                pTooltip="{{'mapSearch.pin' | translate}}">
            <app-svg-icon icon="thumbtack"
                          divClass="icon-20">
            </app-svg-icon>
        </button>
    </div>

    <div class="flex align-center">
        <span class="m-circle mr-8"
              style="background-color: #f4f4f4;">
            <i class="pi pi-arrow-up"
               [style.transform]="'rotate(' + data.heading + 'deg)'">
            </i>
        </span>

        <small>
            {{data.from}}
            <i *ngIf="data.from || data.to"
               class="pi pi-arrow-right"
               style="font-size: 0.5rem; color: lightslategray; margin:3px;"></i>
            {{data.to}}
        </small>
    </div>

    <div class="flex align-center">
        <span class="m-circle mr-8"
              style="background-color: #f4f4f4;"
              *ngIf="data.lastDeviceLinkValidFrom">
            <app-svg-icon icon="signal-stream"
                          divClass="icon-16"
                          tooltip="{{'mapSearch.linked' | translate}}">
            </app-svg-icon>
        </span>

        <span class="m-circle mr-8"
              style="background-color: #f4f4f4;"
              *ngIf="!data.lastDeviceLinkValidFrom">
            <app-svg-icon icon="signal-stream-slash"
                          divClass="icon-16"
                          tooltip="{{'mapSearch.notLinked' | translate}}">
            </app-svg-icon>
        </span>

        <small>{{ {from:data.lastDeviceLinkValidFrom, until:data.lastDeviceLinkValidUntil} | dateRange }}</small>
    </div>
</div>