import { ILocation, ICoordinate } from "src/app/models/location";
import { Component, OnDestroy, ChangeDetectorRef, ViewChild, Input } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { LocationDialogContentComponent } from "./location-dialog-content/location-dialog-content.component";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { NavigationService } from "src/app/services/navigation.service";
import { MapDataService } from "src/app/services/map-data.service";
import { LocationApi } from "src/app/resource/location.api";
import { MarkerContext } from "src/app/modules/map-advanced/classes/marker-context";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-location-dialog",
    templateUrl: "./location.dialog.html"
})
export class LocationDialogComponent extends DialogComponentBase implements OnDestroy, IChangeGuard {
    // sometimes we're using this dialog to create a new assignment from projects screen
    // or to create sub-assignments from parent assignment screen so code is not required for those situations (automatically filled in on BE)
    @Input() isCodeRequired = true;
    @Input() onlyShowMap = false;

    manageOrganization: boolean;
    initialCoordinate: ICoordinate;
    existingLocation: ILocation;
    callback: (res: ILocation) => void;
    navigateAfterCreation = true;

    private readonly mapDataKey: string;
    rights: Rights;

    @ViewChild(LocationDialogContentComponent, { static: false }) locationPickerComponent: LocationDialogContentComponent;

    constructor(
        readonly cd: ChangeDetectorRef,
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly mapDataService: MapDataService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly locationApi: LocationApi,
        private readonly navigationService: NavigationService) {
        super();
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }

    ngOnDestroy() {
        this.mapDataService.unsubscribe(this.mapDataKey);
        this.subscriptionManager.clear();
    }

    canDeactivateCheck(): boolean {
        return !this.locationPickerComponent || this.locationPickerComponent.locationForm.pristine;
    }

    onDeactivate() {
    }

    protected canOpen(): boolean {
        return this.rights?.hasBackendRight(BackendRights.EditLocation);
    }

    protected onOpen() {
        this.changeGuardService.setComponent(this);
        // If user has the right to create measuringPoints for others
        // create the ownerId field
        this.manageOrganization = this.globalEventsService.hasMultipleOrganizations();
    }

    protected onClose() {
        this.changeGuardService.clearComponent();
        if (this.locationPickerComponent) {
            this.locationPickerComponent.locationForm = null;
        }
    }

    edit(location: ILocation, callback?: (res: ILocation) => void) {
        this.existingLocation = location;
        this.initialCoordinate = location.coordinate;
        this.callback = callback;
        this.openDialog();
    }

    editContext(context: MarkerContext, callback?: (res: ILocation) => void) {
        const onSuccess = (location: ILocation) => {
            this.edit(location, callback);
            this.cd.detectChanges();
        };

        this.locationApi.get$(context.id).subscribe(onSuccess);
    }

    create(coordinate: ICoordinate, callback?: (res: ILocation) => void) {
        this.existingLocation = null;
        this.initialCoordinate = coordinate;

        this.callback = callback;
        this.openDialog();
    }

    handleCreated(creator: ILocation) {
        if (this.callback) {
            this.callback(creator);
        }
        this.close();

        if (this.navigateAfterCreation) {
            this.navigate(creator);
        }
    }

    handleUpdated(existingLocation: ILocation) {
        if (this.callback) {
            this.callback(existingLocation);
        }
        this.close();
    }

    private navigate(location: ILocation) {
        this.navigationService.createNewMeasuringPointLocation(location);
    }
}
