<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="trafficLightLink">
    <div class="p-16">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('id').showErrors }">

            <label class="col-2"
                   for="#idInput">
                id
            </label>

            <div class="col-4">
                <input class="p-inputtext"
                       [formControl]="form.get('id')"
                       #idInput />

                <app-form-validation [control]="form.get('id')"
                                     [input]="idInput">
                </app-form-validation>
            </div>
        </div>
    </div>
</div>