<div style="display:grid; gap:8px;">
    <ng-container *ngIf="isExpanded">
        <button class="m-btn m-btn-mode-select"
                (click)="openSetModeDialog($event)">
            Mode {{data.currentMode?.id}}: {{data.currentMode?.description}}
            <app-svg-icon icon="edit"
                          divClass="icon-16"
                          class="ml-4"></app-svg-icon>
        </button>
    </ng-container>

    <ng-container *ngIf="vmsStatus?.images.length > 0">
        <app-vms-animation-compact [images]="vmsStatus?.images"
                                   imageStyle=""
                                   [tooltipDisabled]="isExpanded"
                                   [pTooltip]="data.currentMode?.description"
                                   [isExpanded]="isExpanded"></app-vms-animation-compact>
    </ng-container>

    <div *ngIf="data.isDeviceNotSynchronized"
         class="warning">
        {{'liveTiles.vms.deviceNotSynchronized' | translate}}
    </div>
</div>

<app-vms-manage-dialog></app-vms-manage-dialog>