import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, Type, ChangeDetectorRef, SimpleChanges } from "@angular/core";
import { DeviceRealtimeAnalyzerConfigurationComponent } from "../device-realtime-analyzer-configuration/device-realtime-analyzer-configuration.component";
import { DeviceTmsRadarConfigurationComponent } from "../device-tms-radar-configuration/device-tms-radar-configuration.component";
import { DeviceZigbeeConfigurationComponent } from "../device-zigbee-configuration/device-zigbee-configuration.component";
import { DeviceRelayConfigurationComponent } from "../device-relay-configuration/device-relay-configuration.component";
import { DeviceDisplayContainerComponent } from "../device-display-container/device-display-container.component";
import { DeviceOtaConfigurationComponent } from "../device-ota-configuration/device-ota-configuration.component";
import { DeviceUploadContainerComponent } from "../device-upload-container/device-upload-container.component";
import { DeviceStatusContainerComponent } from "../device-status-container/device-status-container.component";
import { DeviceAlarmOverviewComponent } from "../device-alarm-overview/device-alarm-overview.component";
import { DeviceCommandsComponent } from "../device-commands/device-commands.component";
import { DeviceDetailComponent } from "../device-detail/device-detail.component";
import { DeviceCameraComponent } from "../device-camera/device-camera.component";
import { AuditTrailsComponent } from "src/app/modules/shared/components/audit-trails/audit-trails.component";
import { DeviceLinksComponent } from "../device-links/device-links.component";
import { DeviceType, IDevice } from "src/app/models/device";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { DeviceAnprComponent } from "../device-anpr/device-anpr.component";
import { JournalComponent } from "src/app/modules/shared/components/journal/journal.component";
import { TranslateService } from "@ngx-translate/core";
import { DeviceVehicleConfigurationComponent } from "../device-vehicle-configuration/device-vehicle-configuration.component";
import { DeviceAvsConfigurationComponent } from "../device-avs-configuration/device-avs-configuration.component";
import { DeviceDekimoDisplayConfigurationComponent } from "../device-dekimo-display-configuration/device-dekimo-display-configuration.component";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

type DeviceDetailComponents =
    DeviceCommandsComponent |
    DeviceDetailComponent |
    DeviceDisplayContainerComponent |
    DeviceDekimoDisplayConfigurationComponent |
    DeviceUploadContainerComponent |
    DeviceStatusContainerComponent |
    DeviceAnprComponent |
    DeviceAlarmOverviewComponent |
    DeviceLinksComponent |
    DeviceCameraComponent |
    DeviceRelayConfigurationComponent |
    DeviceAvsConfigurationComponent |
    DeviceZigbeeConfigurationComponent |
    DeviceTmsRadarConfigurationComponent |
    DeviceOtaConfigurationComponent |
    DeviceRealtimeAnalyzerConfigurationComponent |
    DeviceVehicleConfigurationComponent |
    AuditTrailsComponent |
    JournalComponent;

@Component({
    selector: "app-device-detail-container",
    templateUrl: "./device-detail-container.component.html"
})
export class DeviceDetailContainerComponent extends TabBase<DeviceDetailComponents> implements OnChanges {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    ngClass: string;

    private readonly mapDataKey: string;

    rights: Rights;

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService,
        private readonly translateService: TranslateService) {

        super(tabService, cd);

        this.addTab({ component: DeviceDetailComponent, url: "details", translatePath: "deviceDetail.title", icon: "info" });
        this.addTab({ component: DeviceAnprComponent, url: "anpr", translatePath: "deviceAnpr.title", icon: "hardware" });
        this.addTab({ component: DeviceAvsConfigurationComponent, url: "avs", translatePath: "deviceAvs.title", icon: "hardware" });
        this.addTab({ component: DeviceDisplayContainerComponent, url: "display", translatePath: "deviceDisplay.title", icon: "hardware" });
        this.addTab({ component: DeviceDekimoDisplayConfigurationComponent, url: "dekimodisplay", translatePath: "deviceDisplay.title", icon: "hardware" });
        this.addTab({ component: DeviceRealtimeAnalyzerConfigurationComponent, url: "realtimeanalyzer", translatePath: "deviceFeatures.realtimeAnalyzer", icon: "device-realtime-analyzer" });
        this.addTab({ component: DeviceCameraComponent, url: "camera", translatePath: "cameraConfiguration.title", icon: "device-camera" });
        this.addTab({ component: DeviceVehicleConfigurationComponent, url: "vehicle", translatePath: "vehicleConfiguration.title", icon: "floatingCar" });
        this.addTab({ component: DeviceOtaConfigurationComponent, url: "ota", translatePath: "deviceFeatures.otaUpdate", icon: "ota" });
        this.addTab({ component: DeviceRelayConfigurationComponent, url: "relay", translatePath: "deviceRelay.title", icon: "device-relay" });
        this.addTab({ component: DeviceZigbeeConfigurationComponent, url: "zigbee", translatePath: "zigbeeConfiguration.title", icon: "zigbee" });
        this.addTab({ component: DeviceTmsRadarConfigurationComponent, url: "tmsradar", translatePath: "tmsRadarConfiguration.title", icon: "device-camera" });
        this.addTab({ component: DeviceCommandsComponent, url: "commands", translatePath: "deviceCommands.title", icon: "crosshair" });
        this.addTab({ component: DeviceStatusContainerComponent, url: "status", translatePath: "deviceStatus.title", icon: "error" });
        this.addTab({ component: DeviceAlarmOverviewComponent, url: "alarms", translatePath: "deviceAlarms.title", icon: "alarm" });
        this.addTab({ component: DeviceUploadContainerComponent, url: "upload", translatePath: "deviceUpload.title", icon: "uploads" });
        this.addTab({ component: DeviceLinksComponent, url: "links", translatePath: "links.title", icon: "links" });
        this.addTab({ component: JournalComponent, url: "journal", translatePath: "journal.title", icon: "history" });
        this.addTab({ component: AuditTrailsComponent, url: "audit", translatePath: "auditTrails.title", icon: "log" });

        this.filterTabs();
        this.updateActiveComponent();

        const rightsSubscription = this.globalEventsService.currentRights$.subscribe(rights => {
            this.rights = rights;
        });
        this.subscriptionManager.add("rights", rightsSubscription);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filterTabs();
        this.updateActiveComponent();

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.onSelectedTabChange();
            this.updateTitle();
        }
    }

    filterTabs() {
        if (!this.device) return;

        let tabsToFilter = new Array<Type<DeviceDetailComponents>>();
        const rights = this.globalEventsService.getCurrentRights();

        if (!this.device.anprConfiguration) {
            tabsToFilter.push(DeviceAnprComponent);
        }

        if (!this.device.displayConfiguration) {
            tabsToFilter.push(DeviceDisplayContainerComponent);
        }

        if (!this.device.dekimoDisplayConfiguration) {
            tabsToFilter.push(DeviceDekimoDisplayConfigurationComponent);
        }

        if (this.device.typeId !== DeviceType.BikeCounter &&
            this.device.typeId !== DeviceType.DekimoController &&
            this.device.typeId !== DeviceType.GenericLinuxController) {
            tabsToFilter.push(DeviceCommandsComponent);
        }
        if (this.device.typeId !== DeviceType.Avs) {
            tabsToFilter.push(DeviceAvsConfigurationComponent);
        }
        if (this.device.typeId !== DeviceType.Camera) {
            tabsToFilter.push(DeviceCameraComponent);
        }

        if (this.device.typeId === DeviceType.ControlPanel) {
            tabsToFilter.push(DeviceUploadContainerComponent);
        }

        if (this.device.typeId !== DeviceType.Relay) {
            tabsToFilter.push(DeviceRelayConfigurationComponent);
        } else {
            tabsToFilter.push(DeviceUploadContainerComponent);
        }

        if (!this.device.features.zigbee) {
            tabsToFilter.push(DeviceZigbeeConfigurationComponent);
        }

        if (!this.device.features.tmsRadar) {
            tabsToFilter.push(DeviceTmsRadarConfigurationComponent);
        }

        if (!this.device.features.realtimeAnalyzer) {
            tabsToFilter.push(DeviceRealtimeAnalyzerConfigurationComponent);
        }

        if (!this.device.features.otaUpdate) {
            tabsToFilter.push(DeviceOtaConfigurationComponent);
        }

        if (this.device.features.gpio) {
            tabsToFilter = tabsToFilter.remove(DeviceRelayConfigurationComponent);
        } else {
            tabsToFilter.push(DeviceRelayConfigurationComponent);
        }

        if (this.device.typeId === DeviceType.SigncoTablet) {
            tabsToFilter.push(DeviceUploadContainerComponent);
        }

        if (!rights.hasBackendRight(BackendRights.ViewDeviceAuditTrail)) {
            tabsToFilter.push(AuditTrailsComponent);
        }

        if (this.device.typeId !== DeviceType.Vehicle) {
            tabsToFilter.push(DeviceVehicleConfigurationComponent);
        }

        if (!this.rights.hasBackendRight(BackendRights.ViewDeviceCommand)) {
            tabsToFilter.push(DeviceCommandsComponent);
        }

        if (!this.rights.hasBackendRight(BackendRights.ViewRule)) {
            tabsToFilter.push(DeviceAlarmOverviewComponent);
        }

        if (!this.rights.hasBackendRight(BackendRights.ManageDeviceTechnicalConfiguration)) {
            tabsToFilter.push(DeviceAnprComponent);
            tabsToFilter.push(DeviceAvsConfigurationComponent);
            tabsToFilter.push(DeviceRelayConfigurationComponent);
            tabsToFilter.push(DeviceZigbeeConfigurationComponent);
            tabsToFilter.push(DeviceTmsRadarConfigurationComponent);
            tabsToFilter.push(DeviceRealtimeAnalyzerConfigurationComponent);
            tabsToFilter.push(DeviceOtaConfigurationComponent);
            tabsToFilter.push(DeviceVehicleConfigurationComponent);
        }

        this.hideTabs(tabsToFilter);
    }

    private updateDisabled() {
        const disabledExpression = (type: Type<DeviceDetailComponents>) => {
            if (type instanceof DeviceDetailComponent) return false;

            // Can't navigate to other tabs when creating a new Device
            return !this.device || !this.device.id;
        };

        this.setTabsDisabledState(disabledExpression);
    }

    protected onSelectedTabChange() {
        if (!this.activeComponent) return;

        this.activeComponent.setDevice(this.device);

        if (this.activeComponent instanceof DeviceDetailComponent) {
            this.activeComponent.save = this.save;
        }

        if (this.activeComponent instanceof DeviceCommandsComponent) {
            this.activeComponent.sendCommand = this.rights.hasBackendRight(BackendRights.EditDeviceCommand);
            this.activeComponent.setRowCount(20);
        }

        if (this.activeComponent instanceof AuditTrailsComponent) {
            this.activeComponent.setRowCount(50);
        }

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";

        this.updateDisabled();
    }

    updateTitle() {
        if (!this.device || !this.selectedTab) return;
        this.titleService.setTitle(`${this.device.code || this.translateService.instant("manageDevice.create")} - ${this.selectedTab.label}`);
    }
}
