import { Component, ElementRef, EventEmitter, HostListener, inject, OnDestroy, Output, ViewChild } from "@angular/core";
import { debounceTime, Subject } from "rxjs";
import { SubscriptionManager } from "src/app/utilities";
import { FilterService } from "../../../services/filter.service";
import { LocationWebApi } from "src/app/resource/web";
import { ILocationSummary } from "src/app/models/web";
import { BasicMapComponent } from "src/app/modules/map-basic";
import { MapIconService } from "src/app/services/map-icon-service";

type NewType = ILocationSummary;

/**
 * Search for Signco locations or Google Places. Clicking a result will navigate to it.
 * The user input is debounced
 */
@Component({
    selector: "app-search-box",
    templateUrl: "./search-box.component.html",
    styleUrls: ["./search-box.component.scss"],
})
export class SearchBoxComponent implements OnDestroy {
    @ViewChild("inputElement") inputElement: ElementRef<HTMLInputElement>;
    @ViewChild("popup") popup: ElementRef<HTMLDetailsElement>;

    @Output() onLocationClicked: EventEmitter<ILocationSummary> = new EventEmitter<ILocationSummary>();
    @Output() onPlaceClicked: EventEmitter<google.maps.places.PlaceGeometry> = new EventEmitter<google.maps.places.PlaceGeometry>();
    @Output() onCreateFilterClicked: EventEmitter<string> = new EventEmitter<string>();

    private readonly filterService = inject(FilterService);
    private readonly locationWebApi = inject(LocationWebApi);
    private readonly mapIconService = inject(MapIconService);

    protected isLoadingLocations = false;
    protected locations: ILocationSummary[];

    protected isLoadingPlaces = false;
    protected places: google.maps.places.AutocompletePrediction[];

    public searchTerm = "";
    private searchTermSubject = new Subject<string>();
    private subscriptionManager = new SubscriptionManager();
    private map: BasicMapComponent;

    constructor() {
        const subscription = this.searchTermSubject.pipe(debounceTime(1000))
            .subscribe(async value => await this.loadData(value));
        this.subscriptionManager.add("searchTermSubject", subscription);
    }

    // When the user presses space, the default behavior of <details> is to close the dropdown.
    // We prevent that here.
    @HostListener("keydown", ["$event"])
    onKeydown(event: KeyboardEvent) {
        if (event.key === " " || event.keyCode === 32) {
            event.preventDefault();

            // We insert the space manually, at the cursor posiition
            const nativeElement = this.inputElement.nativeElement;
            const start = nativeElement.selectionStart;
            const end = nativeElement.selectionEnd;

            if (start !== null && end !== null) {
                const value = nativeElement.value;
                nativeElement.value = value.substring(0, start) + " " + value.substring(end);
                nativeElement.selectionStart = start + 1;
                nativeElement.selectionEnd = nativeElement.selectionStart;
                this.handleSearchTermChange(nativeElement.value); // Handles debouncing etc...
            }
        }
    }

    /**
     * This component needs access to the map to search for Google Places
     */
    public initializeMap(map: BasicMapComponent) {
        this.map = map;
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    // Invoked from the template, will emit onChanged
    protected clearSearchterm() {
        this.clear();
        this.searchTermSubject.next(this.searchTerm);
        this.inputElement.nativeElement.focus();
    }

    // Invoked from other componets, onChanged is not emitted
    public clear() {
        this.searchTerm = "";
        this.locations = [];
        this.places = [];
        this.closePopup();
    }

    protected handleSearchTermChange(searchTerm: string): void {
        if (searchTerm.length > 0) {
            this.openPopup();
        } else {
            this.closePopup();
        }

        // We clear the results immediately
        this.locations = [];
        this.places = [];

        // We use rxjs to debounce the input and do less server requests
        this.searchTermSubject.next(searchTerm);
    }

    protected closePopup() {
        this.popup.nativeElement.open = false;
    }

    protected openPopup() {
        if (this.searchTerm.length === 0) return;
        this.popup.nativeElement.open = true;
    }

    private async loadData(searchTerm: string) {
        if (searchTerm.length === 0) return;

        // Note that we only get here after debouncing the input

        // We don't await because we want to run both in parallel
        this.loadLocations(searchTerm);
        this.loadPlaces(searchTerm);
    }

    private async loadLocations(searchTerm: string) {
        // We want to search within the filter settings
        const searchParameters = this.filterService.getSearchParameters();
        const clone = searchParameters.shallowClone(); // We don't want to change the original object
        clone.search = searchTerm;
        clone.take = 30;
        clone.includeAdditionalData = true;

        this.isLoadingLocations = true;
        this.locations = [];
        const results = await this.locationWebApi.getLocationsWithMeasuringPoints(clone);
        this.isLoadingLocations = false;
        this.locations = results.data;
    }

    protected locationTrackBy(index: number, location: ILocationSummary) {
        return location.id;
    }

    protected handleLocationClicked(location: ILocationSummary) {
        this.onLocationClicked.emit(location);
        this.closePopup();
    }

    protected getIconUrl(location: ILocationSummary): string {
        const iconState = this.mapIconService.getIconState(location.iconStateId);
        return iconState.defaultIcon.url;
    }

    private async loadPlaces(searchTerm: string) {
        const callback = (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
            this.isLoadingPlaces = false;
            this.places = predictions;
        };

        this.isLoadingPlaces = true;
        this.places = [];
        const autocompleteService = new google.maps.places.AutocompleteService();
        const requestOptions = {
            input: searchTerm,
            bounds: this.map.map.getBounds(), // This makes sure that we get relevant results based on the current location
        } as google.maps.places.AutocompletionRequest;
        autocompleteService.getPlacePredictions(requestOptions, callback);
    }

    protected placeTrackBy(index: number, place: google.maps.places.AutocompletePrediction) {
        return place.place_id;
    }

    protected handlePlaceClicked(place: google.maps.places.AutocompletePrediction) {
        const callback = (place: google.maps.places.PlaceResult) => {
            this.onPlaceClicked.emit(place.geometry);
            this.closePopup();
        };

        const placesService = new google.maps.places.PlacesService(this.map.map);
        placesService.getDetails({ placeId: place.place_id }, callback);
    }

    protected handleCreateFilterClicked() {
        this.onCreateFilterClicked.emit(this.searchTerm);
        this.closePopup();
    }
}