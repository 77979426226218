import { CustomTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { IPredictionRequestValidation } from "src/app/models/prediction";
import { Component, ElementRef } from "@angular/core";

@Component({
    selector: "app-prediction-request-validations",
    templateUrl: "./prediction-request-validations.component.html"
})
export class PredictionRequestValidationsComponent extends CustomTableComponent<IPredictionRequestValidation> {

    constructor(
        elementRef: ElementRef,
        tableService: TableService) {

        super("prediction-request-validations.component", elementRef, tableService);

        this.enableSigncoMultiSelectBehavior = true;
        this.selectionMode = "multiple";
        this.selectionBox = true;
        this.paginator = false;
        this.stretchHeight = true;
        this.footer = false;

        this.addColumn(new TableColumn("warning", "prediction.notEnoughMeasuredDays", { filterType: FilterType.None, width: 120, resizable: false }));
        this.addColumn(new TableColumn("measuringPoint", "general.measuringPoint", { filterType: FilterType.None }));
        this.addColumn(new TableColumn("daysToPredict", "prediction.daysToPredict", { filterType: FilterType.None }));
        this.addColumn(new TableColumn("daysMeasuredCorrectly", "prediction.daysMeasuredCorrectly", { filterType: FilterType.None }));
    }

    loadTableRows(forceReload = false) {
        return false;
    }

    onSetData() {
        this.clearSelection(true);
        this.setSelection(this.data.filter(x => !x.notEnoughMeasuredDays));
    }

    getCellClass(predictionRequestValidation: IPredictionRequestValidation, column: TableColumn): string {
        if (column.field === "notEnoughMeasuredDays") {
            return predictionRequestValidation.notEnoughMeasuredDays ? "cell-error" : "cell-valid";
        }

        return null;
    }
}
