<div style="display:grid; grid-template-columns: min-content 1fr; align-items: center;">
    <ng-container *ngFor="let message of userMessages">
        <div>
            <app-svg-icon *ngIf="message.level === 'error'"
                          icon="error"
                          fill="red"></app-svg-icon>
            <app-svg-icon *ngIf="message.level === 'warning'"
                          icon="error"
                          fill="#F3A925"></app-svg-icon>
            <app-svg-icon *ngIf="message.level === 'information'"
                          icon="info"
                          fill="black"></app-svg-icon>
        </div>
        <span>{{message.message}}</span>
    </ng-container>
</div>