import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { DeviceApi } from "src/app/resource/device.api";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

enum QrMode {
    Report = 0,
    Register = 1
}

@Component({
    selector: "app-qr",
    templateUrl: "./qr.component.html"
})
export class QrComponent implements OnInit {
    @ViewChild("contentDiv", { static: true }) contentDiv: ElementRef<HTMLDivElement>;

    code: string;
    mode: QrMode;

    reportForm: SigncoFormGroup;
    registerForm: SigncoFormGroup;
    submitting = false;
    multipleModes = false;

    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        this.map = mapSelector;

        if (this.map) {
            this.map.elementRef.nativeElement.className = "";
        }
    }
    mapHeight: number;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly navigationService: NavigationService,
        private readonly selectionService: MapSelectionService,
        private readonly formValidationService: FormValidationService,
        private readonly globalEventsService: GlobalEventsService) {

    }

    ngOnInit() {
        const routeSubscription = this.route.params.subscribe((params: Params) => {
            this.initialize(params["code"]);
        });

        this.subscriptionManager.add("routeSubscription", routeSubscription);
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        this.map.devicesComponent.selectionMode = "single";
    }

    async initialize(code: string) {
        if (!code) {
            this.navigationService.toHome();
            return;
        }

        this.code = code;
        const rights = this.globalEventsService.getCurrentRights();

        if (rights && rights.hasBackendRight(BackendRights.EditDevice)) {
            this.multipleModes = true;
            this.mode = QrMode.Register;
        } else {
            this.mode = QrMode.Report;
        }

        this.mapHeight = this.contentDiv.nativeElement.offsetHeight - 100;

        this.reportForm = this.formBuilder.group({
            message: [null, Validators.required],
            contact: null,
            reCaptcha: null
        }) as SigncoFormGroup;

        this.registerForm = this.formBuilder.group({
            code: this.code
        }) as SigncoFormGroup;
    }

    swapMode() {
        // I take great pleasure in this.
        this.mode = +!(+this.mode);
    }

    async submit() {
        const isRegistering = this.mode === QrMode.Register;
        const form = isRegistering ? this.registerForm : this.reportForm;

        const isValid = await this.formValidationService.checkValidity(form);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = () => {
            this.submitting = false;

            if (isRegistering) {
                this.navigationService.toHome();
                return;
            }

            this.initialize(this.code);
        };

        const onError = () => {
            this.submitting = false;
            this.navigationService.toHome();
        };

        if (isRegistering) {
            const selectedDevice = this.selectionService.getSelectedDevices().takeFirstOrDefault();
            if (!selectedDevice) return;

            this.deviceApi.registerQr$(selectedDevice.id, form.value).subscribe(onSuccess, onError);
        } else {
            this.deviceApi.reportIssue$(this.code, form.value).subscribe(onSuccess, onError);
        }
    }
}