import { DomainDataService } from "src/app/services/domain-data.service";
import { Component, Input } from "@angular/core";
import { SigncoFormGroup } from "src/app/models/form";

@Component({
    selector: "app-model-translation-form",
    templateUrl: "./model-translation-form.component.html"
})
export class ModelTranslationFormComponent {
    @Input() parentFormGroup: SigncoFormGroup;
    @Input() formGroupName: string;
    @Input() controlLabel: string;
    @Input() autofocus = false;
    @Input() multiline = false;

    languages: string[];

    constructor(
        private readonly domainDataService: DomainDataService) {

        this.languages = this.domainDataService.getLanguages();
    }
}
