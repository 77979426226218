<div class="m-card m-layout-w-actions-top m-fixed-height"
     [ngClass]="ngClass">
    <ng-template #titleTemplate>
        <span class="m-circle m-circle-primary mr-8">
            <app-svg-icon [icon]="'device-' + device?.typeId">
            </app-svg-icon>
        </span>

        <div class="m-tab-header-title-text">
            <span class="m-tab-header-title-text-label">{{"general.device" | translate}}</span>
            <span>{{device?.code}}</span>
        </div>
    </ng-template>

    <app-tabs [tabs]="tabs"
              class="m-layout-area-top"
              [selectedTab]="selectedTab"
              [relativeRouting]="true"
              [titleTemplate]="titleTemplate"
              [hidden]="!device"
              (selectedTabChange)="navigateTo($event);">
    </app-tabs>


    <router-outlet *ngIf="device"
                   (activate)="onActivate($event)"></router-outlet>
</div>