<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         scrollHeight="flex"
         [customSort]="selectionBox"
         (sortFunction)="customSort($event)"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         sortField="code"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'selectionBox'">
                    <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                </ng-container>

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-device>
        <tr #row
            [pSelectableRow]="device"
            [attr.id]="device[table.dataKey]">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [ngClass]="resizable ? 'ui-resizable-column' : ''">

                <ng-container *ngSwitchCase="'selectionBox'">
                    <!-- https://github.com/primefaces/primeng/issues/6552 -->
                    <!-- <p-tableCheckbox [value]="measuringPoint"></p-tableCheckbox> -->

                    <input type="checkbox"
                           [checked]="isSelected(device)"
                           (click)="handleSelectionBoxClick(device, $event)">
                </ng-container>

                <ng-container *ngSwitchCase="'alarmLevel'">
                    <div class="flex align-center"
                         style="width: 100%;">
                        <span class="m-circle m-circle-grey">
                            <app-svg-icon [icon]="(device.alarmsLow?.length || device.alarmsHigh?.length) ? 'alarm' : 'checkmark'"
                                          [fill]="getDeviceTextColor(device)">
                            </app-svg-icon>
                        </span>

                        <ng-container *ngIf="device.alarms && !mini && !compact">
                            <div class="ml-8">
                                <ul>
                                    <li *ngFor="let alarm of device.alarms | slice:0:4; let last = last; let i = index; trackBy: alarmTrackByFn">

                                        <small [ngStyle]="{'color': getAlarmTextColor(alarm)}">
                                            {{alarm.rule.code}}
                                        </small>

                                        <small *ngIf="device.alarms.length > 4 && i === 3"> ...</small>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'ownerId'">
                    <app-navigator [organizationId]="device.ownerId"
                                   [enabled]="navigation"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'typeId'">
                    <div class="flex justify-center"
                         style="width: 100%;">
                        <app-svg-icon [icon]="'device-' + device.typeId"
                                      [tooltip]="getDeviceTypeTranslation(device.typeId)">
                        </app-svg-icon>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'image'">
                    <!-- vms -->
                    <ng-container *ngIf="device?.currentStatus?.imageDisplay?.images.length > 0">
                        <app-image-slideshow [images]="device.currentStatus.imageDisplay.images"
                                             imageStyle="max-width: 80px; max-height: 96px"></app-image-slideshow>
                    </ng-container>


                    <!-- camera -->
                    <img *ngIf="device?.cameraConfigurationImage"
                         style="max-width: 80px"
                         [src]="device.cameraConfigurationImage | safeHtml"
                         alt="camera">
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoints'">
                    <ng-container *ngIf="device.depot">
                        <div class="flex align-center"
                             style="width: 100%;">
                            <app-svg-icon icon="depot"></app-svg-icon>

                            <span class="ml-8">
                                {{device.depot.code}}
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="device.measuringPoints">
                        <ul>
                            <li *ngFor="let measuringPoint of device.measuringPoints; trackBy: serviceModelTrackByFn">
                                <app-navigator [measuringPoint]="measuringPoint"
                                               [enabled]="navigation">
                                </app-navigator>
                            </li>
                        </ul>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(device)"
                                             *ngIf="command.rowValidFunc(device)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{device[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>