import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { MeasuringPointUploadComponent } from "../measuring-point-upload/measuring-point-upload.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { UploadMetricComponent } from "src/app/modules/shared/components/upload-metric/upload-metric.component";

type MeasuringPointUploadComponents =
    MeasuringPointUploadComponent |
    UploadMetricComponent;

@Component({
    selector: "app-measuring-point-upload-container",
    templateUrl: "./measuring-point-upload-container.component.html"
})
export class MeasuringPointUploadContainerComponent extends TabBase<MeasuringPointUploadComponents> implements OnChanges {
    @Input() measuringPoint: IMeasuringPoint;

    @Output() save = new EventEmitter<IMeasuringPoint>();

    ngClass: string;

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService,
        elementRef: ElementRef) {

        super(tabService, cd);

        elementRef.nativeElement.classList.add("m-layout-area-body");

        this.addTab({ component: UploadMetricComponent, url: "metric", translatePath: "uploadMetric.title", icon: "calendar" });
        this.addTab({ component: MeasuringPointUploadComponent, url: "uploads", translatePath: "uploads.title", icon: "uploads" });

        this.filterTabs();
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;

        if (this.activeComponent) {
            this.activeComponent.setMeasuringPoint(this.measuringPoint);
        }
    }

    protected onSelectedTabChange() {
        this.activeComponent.setMeasuringPoint(this.measuringPoint);

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";
    }

    updateTitle() {
        if (!this.measuringPoint || !this.selectedTab) return;
        this.titleService.setTitle(`${this.measuringPoint.code} - ${this.selectedTab.label}`);
    }
}