import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-car-configuration",
    templateUrl: "./device-link-car-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkCarConfigurationComponent extends DeviceLinkFormGroupComponent {
    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.carConfiguration) {
            if (this.fgd.form.get("carConfiguration")) this.fgd.form.removeControl("carConfiguration");

            this.form = this.formBuilder.group({
                analyzeForwardDirection: [true],
                analyzeReverseDirection: [false],
                legalSpeed: [null, [Validators.required, Validators.min(1)]]
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.carConfiguration);
            this.fgd.form.addControl("carConfiguration", this.form);
            return true;
        }

        if (this.fgd.form.get("carConfiguration")) this.fgd.form.removeControl("carConfiguration");
        this.form = null;
        return false;
    }
}