<div class="filter-container m-border-bottom"
     style="display:grid; gap:8px;">
    <div class="m-map-filters-header">
        <div class="flex justify-between">
            <h4 class="m-map-filters-header-title">{{"mapFilters.filter" | translate}}</h4>
            <button style="min-height: 0; padding: 0;"
                    class="m-btn m-btn-link"
                    id="buttonClearFilters"
                    (click)="clearFilters()">
                {{"mapSearch.clearFilters" | translate}}
            </button>
        </div>
        <div class="m-map-filters-container"
             style="position: relative;">
            <div class="m-map-filters-chips">
                <ng-template ngFor
                             let-filterType
                             [ngForOf]="filterTypes.slice(0, 3)">
                    <button class="m-map-filters-chip gap-2 pr-0"
                            [id]="'buttonFilter' + filterType"
                            (click)="openFilterDialog(filterType)">
                        {{filterType | translateFilterType}}
                        <app-svg-icon icon="add"></app-svg-icon>
                    </button>
                </ng-template>
            </div>

            <details *ngIf="filterTypes.length > 3"
                     class="m-dropdown"
                     #moreFiltersPopup
                     (clickOutside)="closeMoreFiltersPopup()">
                <summary class="m-dropdown-summary"
                         title="More">
                    <div class="p-4">
                        <app-svg-icon icon="ellipsis-vertical"
                                      divClass="icon-20"></app-svg-icon>
                    </div>
                </summary>

                <div style="right: 0;"
                     class="m-dropdown-content">
                    <div class="m-card m-shadow p-16"
                         style="min-width: 240px;">
                        <h4 class="mb-8">{{"mapFilters.moreFilters" | translate}}</h4>

                        <div class="m-map-filters-chips">
                            <ng-template ngFor
                                         let-filterType
                                         [ngForOf]="filterTypes.slice(3, filterTypes.length)">
                                <button class="m-map-filters-chip gap-2 pr-0"
                                        (click)="openFilterDialog(filterType)">
                                    {{filterType | translateFilterType}}
                                    <app-svg-icon icon="add"></app-svg-icon>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </details>
        </div>
    </div>

    <div class="m-map-filters-chips">

        <ng-container *ngIf="filterState.organizations.length > 0">
            <ng-container *ngFor="let organization of filterState.organizations">
                <span class="m-map-filters-chip is-selected"
                      tooltip="{{organization.name}}">
                    <app-svg-icon icon="organizations">
                    </app-svg-icon>
                    {{ organization.name | truncate:30:true }}
                    <button class="m-map-filters-chip-remove"
                            (click)="removeOrganizationFilter(organization)">
                        <app-svg-icon icon="close"></app-svg-icon>
                    </button>
                </span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterState.projects.length > 0">
            <ng-container *ngFor="let project of filterState.projects">
                <span class="m-map-filters-chip is-selected"
                      tooltip="{{project.name}}">
                    <app-svg-icon icon="projects"></app-svg-icon>
                    {{ project?.name | truncate:30:true }}
                    <button class="m-map-filters-chip-remove"
                            (click)="removeProjectFilter(project)">
                        <app-svg-icon icon="close"></app-svg-icon>
                    </button>
                </span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterState.types.length > 0">
            <ng-container *ngFor="let type of filterState.types">
                <span class="m-map-filters-chip is-selected">
                    <app-svg-icon [icon]="type">
                    </app-svg-icon>
                    {{ type | translateEnum:"analysisType" }}
                    <button class="m-map-filters-chip-remove"
                            (click)="removeTypeFilter(type)">
                        <app-svg-icon icon="close"></app-svg-icon>
                    </button>
                </span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterState.linkStatus">
            <span class="m-map-filters-chip is-selected">
                <app-svg-icon [icon]="statusFilterDialog.getDeviceFilterIcon(filterState.linkStatus)" />
                {{ filterState.linkStatus | translateMeasuringPointStatus | truncate:30:true }}
                <button class="m-map-filters-chip-remove"
                        (click)="removeLinkedStatusFilter()">
                    <app-svg-icon icon="close"></app-svg-icon>
                </button>
            </span>
        </ng-container>

        <ng-container *ngIf="filterState.alertLevels.length > 0">
            <ng-container *ngFor="let alertLevel of filterState.alertLevels">
                <span class="m-map-filters-chip is-selected">
                    <app-svg-icon [icon]="statusFilterDialog.getAlertLevelFilterIcon(alertLevel)" />
                    {{ alertLevel | translateEnum:"alertLevel" }}
                    <button class="m-map-filters-chip-remove"
                            (click)="removeAlertLevelFilter(alertLevel)">
                        <app-svg-icon icon="close"></app-svg-icon>
                    </button>
                </span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="filterState.searchTerm">
            <span class="m-map-filters-chip is-selected">
                <app-svg-icon icon="marker" />
                {{ filterState.searchTerm }}
                <button class="m-map-filters-chip-remove"
                        (click)="removeSearchTermFilter()">
                    <app-svg-icon icon="close"></app-svg-icon>
                </button>
            </span>
        </ng-container>

        @if (filterState.projectsForUserId) {
        <span class="m-map-filters-chip is-selected">
            <app-svg-icon icon="projects" />
            {{ "projects.myProjects" | translate }}
            <button class="m-map-filters-chip-remove"
                    (click)="removeProjectsForUserIdFilter()">
                <app-svg-icon icon="close"></app-svg-icon>
            </button>
        </span>
        }
    </div>
</div>

<app-projects-filter-dialog (onSaved)="handleProjectsFilterChanged($event)"
                            (onClosed)="searchBoxShouldFocus.emit()"></app-projects-filter-dialog>
<app-types-filter-dialog (saved)="handleTypesFilterChanged($event)"
                         (closed)="searchBoxShouldFocus.emit()"></app-types-filter-dialog>
<app-status-filter-dialog (onSaved)="handleStatusFilterChanged($event)"
                          (onClosed)="searchBoxShouldFocus.emit()"></app-status-filter-dialog>
