<div style="display:grid; grid-template-rows: auto min-content;">
    <ng-container *ngIf="countingZone?.accessStatus">

        <div>Beveiliging: {{countingZone?.accessStatus | translateEnum:"countingZoneAccessStatus" }}</div>
    </ng-container>
    <ng-container *ngIf="!countingZone?.accessStatus">
        <div>Beveiliging: geen</div>

    </ng-container>
    <ul style="max-height: 100px; overflow: auto;">
        <li *ngFor="let vehicle of countingZone?.vehicles">
            {{vehicle | vehicle}}
        </li>
    </ul>
    <div *ngIf="!countingZone?.vehicles?.length">(geen voertuigen)</div>
    <div style="display:grid; justify-items: end;">
        <button class="m-btn m-btn-secondary"
                (click)="manageVehicles()"
                style="padding:0px; min-height: unset;">
            <app-svg-icon icon="edit"></app-svg-icon>
            {{"form.edit" | translate}}
        </button>
    </div>
</div>

<app-counting-zone-manage-dialog></app-counting-zone-manage-dialog>