import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiServiceBase } from "../api";
import { SearchParameters } from "src/app/models/search";
import { DataSetType, IHistoricalData, ILiveData, IPinnedDataConfigurationPerAnalysisType, LiveDataRangeOption, PinnedDataOption } from "src/app/models/pinned-data";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointPinnedDataWebApi extends ApiServiceBase {

    protected getApiVersion(): string {
        return "web";
    }

    getRoute(): string {
        return "MeasuringPointPinnedData";
    }

    getConfiguration$(id: number): Observable<IPinnedDataConfigurationPerAnalysisType> {
        const url = `${super.getUrl()}/${id}/Configuration`;
        return this.http.get<IPinnedDataConfigurationPerAnalysisType>(url);
    }

    getHistoricalData$(id: number, searchParameters: MeasuringPointPinnedHistoryDataSearchParameters): Observable<IHistoricalData> {
        const parameters = new SearchParameters();
        parameters.queryParams = searchParameters;

        return this.getOther$<IHistoricalData>(`/${id}/HistoricalData`, parameters);
    }

    getLiveData$(id: number, searchParameters: MeasuringPointPinnedLiveDataSearchParameters, useCache: boolean): Observable<ILiveData> {
        const parameters = new SearchParameters();
        parameters.queryParams = searchParameters;

        return this.getOther$<ILiveData>(`/${id}/LiveData`, parameters, null, useCache);
    }
}

abstract class MeasuringPointPinnedDataSearchParametersBase {
    selectedOptions: SelectedDataSetType[];
}

export class SelectedDataSetType {
    option: PinnedDataOption;
    dataSetType: DataSetType;
}

export class MeasuringPointPinnedHistoryDataSearchParameters extends MeasuringPointPinnedDataSearchParametersBase {
    lastDaysCount?: number;
    lastHoursCount?: number;
    timeAggregationType: string;
}

export class MeasuringPointPinnedLiveDataSearchParameters extends MeasuringPointPinnedDataSearchParametersBase {
    range: LiveDataRangeOption;
    // when there is neither LiveData neither HistoricalData available for measuring point type (e.g. external input, camera... etc)
    onlyCurrentValue: boolean;
}