import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild } from "@angular/core";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { SigncoFormGroup, SigncoFormUtils } from "src/app/models/form";
import { SigncoFormArray } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { IVmsMode, IVmsFrame } from "src/app/models/mode-configuration";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { DownloadedVmsImage, IVmsImage } from "src/app/models/vms-image";
import { VmsImageApi } from "src/app/resource/vms-image.api";
import { FilterDescriptor, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { NavigationService } from "src/app/services/navigation.service";
import { Router } from "@angular/router";
import { VmsAnimationDialogComponent } from "src/app/modules/shared/components/vms-animation-dialog/vms-animation.dialog.component";
import { IVmsDisplayedImage } from "src/app/models/device";
import { VmsImageLibrarySelectorDialogComponent } from "../vms-image-library-selector-dialog/vms-image-library-selector.dialog";
import { VmsImageEditorDialogComponent } from "../vms-image-editor-dialog/vms-image-editor.dialog";

@Component({
    selector: "app-measuring-point-vms-mode-configuration",
    templateUrl: "./measuring-point-vms-mode-configuration.component.html"
})
export class MeasuringPointVmsModeConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;
    @ViewChild(VmsImageLibrarySelectorDialogComponent, { static: false }) imageLibrarySelectorDialog: VmsImageLibrarySelectorDialogComponent;
    @ViewChild(VmsImageEditorDialogComponent, { static: false }) VmsImageEditorDialogComponent: VmsImageEditorDialogComponent;
    @ViewChild(VmsAnimationDialogComponent, { static: false }) vmsAnimationDialog: VmsAnimationDialogComponent;
    @Output() save = new EventEmitter<IMeasuringPoint>();


    submitting = false;
    vmsTypeChanged = false;
    modeForms: SigncoFormArray;
    modeConfigurationForm: SigncoFormGroup;
    modeOptions: SelectItem[];
    vmsTypes: SelectItem[];

    targetButtonId: string;

    images: DownloadedVmsImage[];
    imageIds: number[] = [];

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly domainDataService: DomainDataService,
        private readonly vmsImageApi: VmsImageApi,
        private readonly navigationService: NavigationService,
        private readonly router: Router) {

        this.initDropdowns();
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {

            this.initialize();
        }
    }

    private initDropdowns() {
        this.domainDataService.get(DomainData.VmsTypeValue).then(vmsTypes => {
            this.vmsTypes = vmsTypes;
        });
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.modeConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        // only update this.measuringpoint if it's not already set or if it's a different one
        if (!this.measuringPoint || this.measuringPoint.id !== measuringPoint.id) {
            this.measuringPoint = measuringPoint;
            this.initialize();
        }
    }
    get isVmsTypeSelected() {
        return this.modeConfigurationForm.get("vmsTypeId").value != null;

    }
    async onChangeVmsTypeDropdown(vmsTypeId: string) {
        if (!this.measuringPoint.vmsType) this.vmsTypeChanged = false;
        else {
            this.vmsTypeChanged = vmsTypeId !== this.measuringPoint.vmsType?.typeId;
        }
        // update all frames with correct variant
        if (!this.modeForms) return;
        for (const modeForm of this.modeForms.controls) {
            const framesFormArray = SigncoFormUtils.getFormArray(modeForm as SigncoFormGroup, "frames");
            for (const frameForm of framesFormArray.controls) {
                const vmsImageId = frameForm.get("vmsImageId").value;
                const vmsImageVariant = this.getVmsImageVariantWithType(vmsImageId, vmsTypeId);
                frameForm.patchValue({
                    vmsImageVariantId: vmsImageVariant?.id
                });

            }
        }
    }

    async loadVmsImages() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("id", this.imageIds.join("|"), "in")];
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("vmsImage", "variants");
        const result = await this.vmsImageApi.search$(searchParameters, serviceRequestOptions, false).toPromise();

        const images = new Array<DownloadedVmsImage>();

        for (const vmsImage of result.data) {
            const downloadedVmsImage = {
                vmsImage: vmsImage
            } as DownloadedVmsImage;

            images.push(downloadedVmsImage);
        }
        this.images = images;
    }

    async initialize() {
        if (!this.measuringPoint) return;

        this.modeForms = this.formBuilder.array([]) as SigncoFormArray;

        this.modeConfigurationForm = this.formBuilder.group({
            defaultModeId: null,
            vmsTypeId: null,
            modes: this.modeForms
        }) as SigncoFormGroup;

        if (this.measuringPoint.vmsModeConfiguration) {
            for (const _ of this.measuringPoint.vmsModeConfiguration.modes) {
                await this.addMode(_);
                if (_.frames) {
                    this.imageIds = this.imageIds.concat(_.frames.map(x => x.vmsImageId));
                }
            }
        }
        if (this.imageIds.length > 0) {
            await this.loadVmsImages();
        }

        this.modeConfigurationForm.patchValue(this.measuringPoint.vmsModeConfiguration);
        this.modeConfigurationForm.get("vmsTypeId").patchValue(this.measuringPoint.vmsType?.typeId);
        this.updateModeOptions();
        this.setInitialFrames();

        this.modeConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    //#region Modes

    async addMode(mode: IVmsMode = null) {
        const modeFormGroup = this.formBuilder.group({
            id: [null, Validators.required],
            description: null,
            frames: this.formBuilder.array([]) as SigncoFormArray,
        });

        this.modeForms.push(modeFormGroup);
    }

    deleteMode(index: number) {
        this.modeForms.removeAt(index);
    }
    openPreviewDialog(modeForm: SigncoFormGroup) {
        // Get all the images for this mode
        const displayImages = new Array<IVmsDisplayedImage>();
        const framesFormArray = SigncoFormUtils.getFormArray(modeForm, "frames");
        for (const frameForm of framesFormArray.controls) {
            const vmsImageId = frameForm.get("vmsImageId").value;
            const vmsImageVariantId = frameForm.get("vmsImageVariantId").value;
            const displayTimeMs = frameForm.get("displayTimeMs").value;
            const image = this.images.find(x => x.vmsImage.id === vmsImageId);

            if (image) {
                // get the variant
                const variant = image.vmsImage.variants.find(x => x.id === vmsImageVariantId);
                if (variant) {
                    displayImages.push({
                        uri: variant.url,
                        displayTime: displayTimeMs
                    });
                }
            }
        }

        this.vmsAnimationDialog.create(displayImages);
    }

    updateModeOptions() {
        if (!this.modeForms) return;

        const ids = this.modeForms.controls.map(x => x.get("id").value as string).filter(x => !!x);
        this.modeOptions = this.primeComponentService.createDropdownList(ids, x => x, x => x, false);
    }

    //#endregion Modes

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.modeConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.initialize();
            this.modeConfigurationForm.markAsPristine();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        Object.assign(measuringPointUpdater.vmsModeConfiguration, this.modeConfigurationForm.value);
        measuringPointUpdater.vmsTypeId = this.modeConfigurationForm.get("vmsTypeId").value;

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);

    }

    //#region VmsFrame

    private setInitialFrames() {
        if (!this.images || !this.measuringPoint) return;

        const getModeForm = (formArray: SigncoFormArray, modeId: string) => {
            return formArray.controls.find(x => x.get("id").value === modeId) as SigncoFormGroup;
        };

        if (this.measuringPoint.vmsModeConfiguration && this.measuringPoint.vmsModeConfiguration.modes) {
            for (const mode of this.measuringPoint.vmsModeConfiguration.modes) {
                const modeForm = getModeForm(this.modeForms, mode.id);
                if (!modeForm) continue;

                for (const frame of mode.frames) {

                    // If it's a valid image, add the frame
                    if (this.images.find(x => x.vmsImage.id === frame.vmsImageId)) {
                        this.addVmsFrame(modeForm, frame);
                    }
                }
            }
            this.modeConfigurationForm.patchValue(this.measuringPoint.vmsModeConfiguration);
        }
    }

    addVmsFrame(modeForm: SigncoFormGroup, frame: IVmsFrame = null) {
        const image = frame != null ? this.images.find(x => x.vmsImage.id === frame.vmsImageId) : null;
        const displayTimeMs = frame != null ? frame.displayTimeMs : 1000;
        const imageId = image != null ? image.vmsImage.id : null;
        const imageVariant = image != null ? image.vmsImage.variants.find(x => x.vmsType.typeId === this.modeConfigurationForm.get("vmsTypeId").value) : null;
        const imageVariantId = imageVariant != null ? imageVariant.id : null;

        const frameForm = this.formBuilder.group({
            displayTimeMs: [displayTimeMs, [Validators.required, Validators.min(0)]],
            vmsImageId: [imageId, Validators.required],
            vmsImageVariantId: [imageVariantId, Validators.required]
        });

        const framesFormArray = SigncoFormUtils.getFormArray(modeForm, "frames");
        framesFormArray.push(frameForm);
    }

    deleteVmsFrame(modeForm: SigncoFormGroup, vmsFrameIndex: number) {
        const framesFormArray = SigncoFormUtils.getFormArray(modeForm, "frames");
        framesFormArray.removeAt(vmsFrameIndex);
    }

    getVmsImageVariantWithType(vmsImageId: number, vmsTypeId: string) {
        if (!this.images) return null;
        const image = this.images.find(x => x.vmsImage.id === vmsImageId);
        // now find the variant
        if (image) {
            return image.vmsImage.variants.find(x => x.vmsType.typeId === vmsTypeId);
        }
    }

    getVmsImageVariant(id: number, variantId: number) {
        if (!this.images) return null;
        const image = this.images.find(x => x.vmsImage.id === id);
        // now find the variant
        if (image) {
            return image.vmsImage.variants.find(x => x.id === variantId);
        } else {
            return null;
        }
    }

    getVmsImageVariantFromModeForm(modeForm: SigncoFormGroup) {
        const vmsImageId = modeForm.get("vmsImageId").value;
        const vmsImageVariantId = modeForm.get("vmsImageVariantId").value;
        return this.getVmsImageVariant(vmsImageId, vmsImageVariantId);
    }

    hasVmsImage(modeForm: SigncoFormGroup) {
        return modeForm.get("vmsImageId").value != null;
    }
    isEditableVmsImage(modeForm: SigncoFormGroup) {
        // Editable: not null and not from library
        if (!this.images) return false;
        const vmsImageId = modeForm.get("vmsImageId").value;
        return this.images.find(x => x.vmsImage.id === vmsImageId && !x.vmsImage.inLibrary) != null;
    }

    removeVmsImage(modeForm: SigncoFormGroup) {
        modeForm.patchValue({
            vmsImageId: null,
            vmsImageVariantId: null
        });
    }

    selectVmsImageFromLibrary(frameForm: SigncoFormGroup) {

        const onSubmit = async (vmsImage: IVmsImage) => {
            // if vmsImage.id isn't already in the list of images, add it
            if (!this.imageIds.includes(vmsImage.id)) {
                this.imageIds.push(vmsImage.id);
                await this.loadVmsImages();
            }
            const variant = this.getVmsImageVariantWithType(vmsImage.id, this.modeConfigurationForm.get("vmsTypeId").value);
            frameForm.patchValue({
                vmsImageId: vmsImage.id,
                vmsImageVariantId: variant?.id
            });

        };

        const frameVmsImageId = frameForm.get("vmsImageId")?.value;

        this.imageLibrarySelectorDialog.create(onSubmit, frameVmsImageId);
    }

    navigateToVmsEditor(frameForm: SigncoFormGroup) {
        const onSubmit = async (vmsImage: IVmsImage) => {
            // if vmsImage.id isn't already in the list of images, add it
            if (!this.imageIds.includes(vmsImage.id)) {
                this.imageIds.push(vmsImage.id);
            }
            await this.loadVmsImages();

            const variant = this.getVmsImageVariantWithType(vmsImage.id, this.modeConfigurationForm.get("vmsTypeId").value);
            frameForm.patchValue({
                vmsImageId: vmsImage.id,
                vmsImageVariantId: variant?.id
            });

        };


        const frameVmsImageId = frameForm.get("vmsImageId")?.value;
        // get the image from this.images
        if (frameVmsImageId && this.images) {
            const image = this.images.find(x => x.vmsImage.id === frameVmsImageId);
        }

        this.VmsImageEditorDialogComponent.create(onSubmit, this.modeConfigurationForm.get("vmsTypeId").value,
            this.measuringPoint.ownerId, frameVmsImageId);
    }
    //#endregion VmsFrame
}