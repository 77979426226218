import { ISearchResult, SearchParameters, ServiceRequestOptions, FilterDescriptor, SortDescriptor, FilterOperator, SortDirection } from "../models/search";
import { JsonUtils, isoDateTimeRegEx, dateRegex } from "../utilities/json-utils";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of, from, defer } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DownloadFileService } from "../services/download-file.service";
import { UploadFileService } from "../services/upload-file.service";
import { IProgressCreated } from "../models/progress";
import { LazyLoadEvent } from "primeng/api";
import { CacheService } from "../services/cache.service";
import { ErrorService } from "../services/error.service";
import { Injectable } from "@angular/core";
import { mergeAll } from "rxjs/operators";
import * as moment from "moment";
import { NumberUtils } from "../utilities/number-utils";

export class HttpOptions {
    headers: HttpHeaders;
    params: HttpParams;
    responseType: any;
}

export class CacheOptions {
    pushCacheResult = true;
    pushCacheResultOnNotModified = true;
    stopOnCacheFound = false;
}

@Injectable()
export class ApiServiceBase {

    constructor(
        public http: HttpClient,
        protected readonly downloadFileService: DownloadFileService,
        protected readonly uploadFileService: UploadFileService,
        protected readonly cacheService: CacheService,
        protected readonly errorService: ErrorService,
        protected readonly configurationService: ConfigurationService) {

    }

    getUrl(params: { [key: string]: string } = null): string {
        let route = this.getRoute();

        if (params) {
            for (const param in params) {
                if (!params.hasOwnProperty(param)) continue;
                route = route
                    .replace(`{?${param}}`, params[param])
                    .replace(`{${param}}`, params[param]);
            }
        }

        // Strip optional route params
        // ex: path/{?id}/
        // without params:  path/
        // with params:     path/id/
        while (route.contains("{?")) {
            let beginIndex = route.indexOf("{?");
            const endIndex = route.indexOf("}", beginIndex) + 1;

            // Also cut off beginning "/"
            if (beginIndex > 0 && route[beginIndex - 1] === "/") {
                beginIndex -= 1;
            }

            route = route.substring(0, beginIndex) + route.substring(endIndex);
        }

        if (route.startsWith("/")) {
            route = route.substr(1);
        }

        // Optional parameters can have it happen that an url contains //
        // while (route.contains("//")) {
        //     route = route.replace("//", "/");
        // }

        return `${this.configurationService.configuration.url}/${this.getApiVersion()}/${route}`;
    }

    protected getApiVersion(): string {
        return "v1";
    }

    protected getRoute(): string {
        return "";
    }

    protected getDefaultHeaders(): HttpHeaders {
        return null;
    }

    protected createOptions(serviceRequestOptions?: ServiceRequestOptions, parameters: SearchParameters = null, lazyLoadEvent: LazyLoadEvent = null): HttpOptions {
        if (!serviceRequestOptions) {
            serviceRequestOptions = new ServiceRequestOptions();
        }

        if (!parameters) {
            parameters = new SearchParameters();
        } else {
            parameters = JsonUtils.deepClone(parameters);
        }

        let httpHeaders = new HttpHeaders();
        const headers = serviceRequestOptions.getHeaders();

        headers.forEach(element => {
            httpHeaders = httpHeaders.append(element.key, element.value);
        });

        const defaultHeaders = this.getDefaultHeaders();
        if (defaultHeaders) {
            defaultHeaders.keys().forEach(key => {
                httpHeaders = httpHeaders.append(key, defaultHeaders.get(key));
            });
        }

        if (lazyLoadEvent) {
            const lazyLoadSearchParams = this.lazyLoadToSearchParameters(lazyLoadEvent);

            if (parameters.filter && lazyLoadSearchParams.filter) {
                parameters.filter = parameters.filter.concat(lazyLoadSearchParams.filter);
                delete lazyLoadSearchParams.filter;
            }

            Object.assign(parameters, lazyLoadSearchParams);
        }

        // "Hack" to uppercase the enums we send to the back-end
        if (parameters.filter) {
            for (const filter of parameters.filter) {
                if (filter.value && (filter.field.endsWith("Id") && (filter.value as string).length > 0)) {
                    if (Array.isArray(filter.value) && filter.value[0] instanceof String) {
                        filter.value = (filter.value as string[]).map(x => x.toPascalCase());
                    }

                    if (filter.value instanceof String) {
                        filter.value = filter.value.toPascalCase();
                    }
                }
            }
        }

        return {
            headers: httpHeaders,
            params: ApiServiceBase.searchParametersToHttpParams(parameters)
        } as HttpOptions;
    }

    lazyLoadToSearchParameters(event: LazyLoadEvent): SearchParameters {
        if (event == null) return { skip: 0 };

        const searchParameters = {
            skip: event.first || 0,
            take: event.rows,
            filter: event.globalFilter
        } as SearchParameters;

        if (event.filters) {
            for (const filterField in event.filters) {
                if (event.filters.hasOwnProperty(filterField)) {

                    const filterMeta = event.filters[filterField];
                    let filterValue = filterMeta.value;

                    // Stonks
                    if (filterValue.id) {
                        filterValue = filterValue.id;
                    }

                    const filterDescriptor = new FilterDescriptor(
                        filterField,
                        filterValue,
                        new FilterOperator()[filterMeta.matchMode]
                    );

                    if (!searchParameters.filter) {
                        searchParameters.filter = new Array<FilterDescriptor>();
                    }

                    searchParameters.filter.push(filterDescriptor);
                }
            }
        }

        if (event.sortField) {
            searchParameters.sort = new Array<SortDescriptor>();

            const sortDescriptor = {
                field: event.sortField,
                dir: event.sortOrder === 1 ? SortDirection.ascending : SortDirection.descending
            } as SortDescriptor;

            searchParameters.sort.push(sortDescriptor);
        }

        if (!searchParameters.filter) {
            delete searchParameters.filter;
        }

        return searchParameters;
    }

    private static searchParametersToHttpParams(obj: SearchParameters): HttpParams {
        let params = new HttpParams();

        for (const key in obj) {
            if (!obj.hasOwnProperty(key)) continue;

            if (key === "queryParams") continue;

            const value = (obj as any)[key];
            params = this.appendParams(params, key, value);
        }

        if (obj.queryParams) {
            for (const key in obj.queryParams) {
                if (!obj.queryParams.hasOwnProperty(key)) continue;

                params = this.appendParams(params, key, obj.queryParams[key], true);
            }
        }

        return params;
    }

    private static dateToDateTimeOffset(date: Date, encode = false): string {
        let momentDate = moment(date);

        if (encode) {
            momentDate = momentDate.utc(false);
        }

        let formatted = momentDate.format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");

        if (encode) {
            formatted = formatted.replace("+00:00", "Z");
        }

        return formatted;
    }

    private static appendParams(params: HttpParams, key: string, value: any, encode = false): HttpParams {
        if (value === null || value === undefined) return params;

        // We use [] notation in our append params system, they cannot be present in a string value
        if (typeof value === "string") {
            const invalidValues = ["[", "]"];

            for (const invalidValue of invalidValues) {
                value = (value as string).replace(invalidValue, "");
            }
        }

        // Convert dates to ISO format string
        if (value instanceof Date) {
            return params.append(key, this.dateToDateTimeOffset(value, encode));
        }

        // Convert object and arrays to query params
        if (typeof value === "object") {

            const filterDescriptor = !!(value as FilterDescriptor).field ? value as FilterDescriptor : null;
            if (filterDescriptor) {
                if (Array.isArray(filterDescriptor.value)) {

                    if (typeof filterDescriptor.value[0] === "string" &&
                        (
                            !!isoDateTimeRegEx.exec(filterDescriptor.value[0] as string) ||
                            !!dateRegex.exec(filterDescriptor.value[0] as string)
                        )) {
                        try {
                            filterDescriptor.value[0] = new Date(filterDescriptor.value[0]);
                        } finally { }
                    }

                    if (filterDescriptor.value[0] instanceof Date) {
                        // Automatically set upper bound to date if not filled in
                        if (!filterDescriptor.value[1]) {
                            const valueDate = filterDescriptor.value[0] as Date;
                            const tomorrowDate = new Date(valueDate).toMidnight();
                            tomorrowDate.setUTCDate(tomorrowDate.getUTCDate());
                            filterDescriptor.value = [valueDate, tomorrowDate]; // Break reference
                        }
                    }

                    filterDescriptor.value = (filterDescriptor.value as any[]).map(x => x instanceof Date ? this.dateToDateTimeOffset(x) : x?.toString ? x.toString() : x).join("|");
                }
            }

            for (const k in value) {
                if (value.hasOwnProperty(k)) {
                    const isIndex = NumberUtils.isValidNumberString(k);
                    const subKey = isIndex ? `${key}[${k}]` : `${key}.${k}`;
                    params = this.appendParams(params, subKey, value[k]);
                }
            }
            return params;
        }

        return params.append(key, value);
    }

    protected handleCaching<T>(url: string, options: HttpOptions = null, useCache: CacheOptions | boolean = null): Observable<T> {
        // https://github.com/ReactiveX/rxjs/issues/3585
        const deferAsync = (factory: () => Promise<Observable<T>>) => defer(factory).pipe(mergeAll());

        return deferAsync(async () => {
            if (!options) {
                options = this.createOptions();
            }

            const cachedResult = await this.cacheService.get<T>(url, options);

            const httpObservable = this.http.get<T>(url, options);

            let cacheOptions = useCache as CacheOptions;
            if (useCache === null || typeof useCache === "boolean") {
                cacheOptions = new CacheOptions();
            }

            if (cachedResult) {

                const cachedResultObservable = of(cachedResult);

                // Stop here, found cached value and instructed not to go further
                if (cacheOptions.stopOnCacheFound) {
                    if (!cacheOptions.pushCacheResult) {
                        console.warn("Pushing cache result when not instructed - stopOnCacheFound = true", url);
                    }

                    return cachedResultObservable;
                }

                if (cacheOptions.pushCacheResult) {

                    const obs = new Observable<T>(observer => {
                        const onSuccess = (result: T) => {
                            observer.next(result);
                            observer.complete();
                        };

                        const onError = (errorResult: Response) => {
                            if (errorResult.status === 304) {
                                // We already pushed cachedResult, so simply end the observer
                                observer.complete();
                            } else {
                                observer.error(errorResult);
                            }
                        };

                        // Instantly send the cached result
                        observer.next(cachedResult);

                        httpObservable.subscribe(onSuccess, onError);
                    });

                    return obs;

                } else {
                    const promise = new Promise<T>((resolve, reject) => {

                        const onSuccess = (result: T) => {
                            resolve(result);
                        };

                        // Push cached if not yet sent and we get a 304 result
                        const onError = (errorResult: Response) => {
                            if (errorResult.status === 304 && cacheOptions.pushCacheResultOnNotModified) {
                                resolve(cachedResult);
                            } else {
                                reject(errorResult);
                            }
                        };

                        httpObservable.subscribe(onSuccess, onError);
                    });

                    return from(promise);
                }
            }

            return httpObservable;
        });
    }

    getOther$<T>(
        urlAppend: string = null,
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = true,
        routeParams: { [key: string]: string } = null): Observable<T> {

        let url = `${this.getUrl(routeParams)}`;
        if (urlAppend) {
            while (url.endsWith("/")) {
                url = url.substr(0, url.length - 1);
            }

            if (!urlAppend.startsWith("/")) {
                urlAppend = `/${urlAppend}`;
            }

            url += `${urlAppend}`;
        }

        const options = this.createOptions(serviceRequestOptions, searchParameters);

        if (useCache) {
            return this.handleCaching(url, options, useCache);
        }

        return this.http.get<T>(url, options);
    }
}

@Injectable()
export class ApiService<T, TCreator, TUpdater> extends ApiServiceBase {

    constructor(
        http: HttpClient,
        downloadFileService: DownloadFileService,
        uploadFileService: UploadFileService,
        cacheService: CacheService,
        errorService: ErrorService,
        configurationService: ConfigurationService) {

        super(http, downloadFileService, uploadFileService, cacheService, errorService, configurationService);
    }

    search$(
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = false,
        routeParams: { [key: string]: string } = null,
        lazyLoadEvent: LazyLoadEvent = null): Observable<ISearchResult<T>> {

        const options = this.createOptions(serviceRequestOptions, searchParameters, lazyLoadEvent);
        const url = this.getUrl(routeParams);

        if (useCache) {
            return this.handleCaching(url, options, useCache);
        }

        return this.http.get<ISearchResult<T>>(url, options);
    }

    getAll$(searchParameters: SearchParameters = null, pushCacheResult = true): Observable<T[]> {
        if (!searchParameters) {
            searchParameters = new SearchParameters();
        }

        searchParameters.take = 2147483647; // Int32.MaxValue

        const cacheOptions = new CacheOptions();
        cacheOptions.pushCacheResult = pushCacheResult;
        cacheOptions.pushCacheResultOnNotModified = false;
        cacheOptions.stopOnCacheFound = false;

        let resultCount = 0;
        const obs = new Observable<T[]>(observer => {
            const onSuccess = (result: ISearchResult<T>) => {
                resultCount++;
                observer.next(result.data);

                if (resultCount >= 2) {
                    observer.complete();
                }
            };

            const onError = (errorResult: Response) => {
                resultCount++;
                if (errorResult.status === 304) {
                    // We already pushed cachedResult, so simply end the observer
                    observer.complete();
                } else {
                    observer.error(errorResult);
                }
            };

            this.search$(searchParameters, null, cacheOptions).subscribe(onSuccess, onError);
        });

        return obs;
    }

    get$(
        id: number = null,
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = false,
        routeParams: { [key: string]: string } = null): Observable<T> {

        let urlAppend: string = null;
        if (id) {
            urlAppend = `/${id}`;
        }

        return this.getOther$<T>(urlAppend, searchParameters, serviceRequestOptions, useCache, routeParams);
    }

    create$(model: TCreator, routeParams: { [key: string]: string } = null): Observable<T> {
        return this.postOther$<T>(this.getUrl(routeParams), model);
    }

    postOther$<TO>(url: string, model: any): Observable<TO> {
        return this.http.post<TO>(url, model);
    }

    update$(model: TUpdater & { id: number }, routeParams: { [key: string]: string } = null): Observable<T> {
        const url = `${this.getUrl(routeParams)}/${model.id}`;
        return this.http.put<T>(url, model);
    }

    patch$(model: TUpdater & { id: number }, routeParams: { [key: string]: string } = null): Observable<T> {
        const url = `${this.getUrl(routeParams)}/${model.id}`;
        return this.http.patch<T>(url, model);
    }

    delete$(id: number, routeParams: { [key: string]: string } = null): Observable<T> {
        const url = `${this.getUrl(routeParams)}/${id}`;

        return this.http.delete<T>(url);
    }

    createWithProgress$(model: TCreator, routeParams: { [key: string]: string } = null): Observable<IProgressCreated> {
        return this.http.post<IProgressCreated>(super.getUrl(routeParams), model);
    }

    updateWithProgress$(model: TUpdater & { id: number }, routeParams: { [key: string]: string } = null): Observable<IProgressCreated> {
        const url = `${this.getUrl(routeParams)}/${model.id}`;
        return this.http.put<IProgressCreated>(url, model);
    }
}