import { Injectable } from "@angular/core";
import { ILocationSummary } from "src/app/models/web";

/**
 * Keeps viewstate that must be preserved between navigations.
 * This is easier than storing it in sessionstorage.
 */
@Injectable()
export class ViewStateService {
    public isSearchPanelOpen = false;

    public isInStreetView = false;

    public selectedLocation?: ILocationSummary;

    public showDeviceDetails = false;
}
