import { Component, Input, ViewChild, OnChanges, SimpleChanges, ElementRef, ChangeDetectorRef } from "@angular/core";
import { TableColumn, FilterType, ColumnVisibility, TableService, LazyTableComponent } from "../table/table.component";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { ManageUserDialogComponent } from "../manage-user-dialog/manage-user.dialog";
import { IUser, UserUpdater } from "src/app/models/user";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { UserApi } from "src/app/resource/user.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { Rights } from "src/app/models/rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html"
})
export class UsersComponent extends LazyTableComponent<IUser> implements OnChanges {
    @ViewChild(ManageUserDialogComponent, { static: true }) manageUserDialog: ManageUserDialogComponent;

    @Input() organizationId: number;
    @Input() requiresInput = false;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    private organizationColumn: TableColumn;
    private roleColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly cd: ChangeDetectorRef,
        private readonly modalService: ModalService,
        private readonly userApi: UserApi,
        private readonly translate: TranslateService,
        private readonly domainDataService: DomainDataService) {

        super("users.component", elementRef, userApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";
        this.addColumn(new TableColumn("isObsolete", "", { width: 50, visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("firstName", "general.firstName", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("lastName", "general.lastName", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("email", "general.email", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("phoneNumber", "general.phoneNumber", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("gsm", "users.gsm", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact }));
        this.organizationColumn = new TableColumn("organizations", "general.organization", { filterType: FilterType.MultiSelect, sortable: false, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true });
        this.addColumn(this.organizationColumn);
        this.roleColumn = new TableColumn("roles", "general.role", { filterType: FilterType.MultiSelect, sortable: false, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, displayDropdownFilter: true });
        this.addColumn(this.roleColumn);
        // Load organizations for drop-down filter
        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            this.organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.domainDataService.get(DomainData.Roles).then(roles => {
            this.roleColumn.filterOptions = this.services.primeComponentService.createDropdownList(roles, x => x.value, x => x.label, false);
        });
        // this.addColumn(new TableColumn("roles", "general.roles", { visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("lastLogin", "users.lastLogin", { visibility: ColumnVisibility.HideCompact, width: 185, resizable: false, hidden: !this.rights?.hasBackendRight(BackendRights.EditUser) }));

        this.registerCommand({
            text: "manageUser.edit",
            icon: "edit",
            click: (user) => this.edit(user),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.EditUser)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (user) => this.delete(user),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteUser),
            rowValidFunc: (user) => !user.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (user) => this.restore(user),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteUser),
            rowValidFunc: (user) => user.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const organizationChange = changes["organizationId"];
        if (organizationChange) {
            this.updateOrganizationColumn();
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    private updateOrganizationColumn() {
        if (this.organizationId) this.removeColumn(this.organizationColumn);
        else this.addColumn(this.organizationColumn);
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.organizationId;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.organizationId) {
            searchParameters.filter.push(new FilterDescriptor("organizationId", this.organizationId));
        }
        return searchParameters.filter.length ? searchParameters : null;
    }

    onSetData() {
        if (!this.destroyed) {
            this.cd.detectChanges();
        }
    }

    edit(user: IUser) {
        this.manageUserDialog.edit(user);
    }

    delete(user: IUser) {
        const onDeleteSuccess = () => {
            user.isObsolete = true;
        };

        const onDelete = () => {
            this.userApi.delete$(user.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("users.deleteConfirmation", { userName: user.email });
        this.modalService.delete(modalBody, onDelete);
    }

    canDelete(user: IUser): boolean {
        return user.id !== this.globalEventsService.getAuthorizationInfo()?.user?.id;
    }

    restore(user: IUser) {
        const onRestoreSuccess = () => {
            user.isObsolete = false;
        };

        const restoredUser = new UserUpdater(user);
        restoredUser.isObsolete = false;

        this.userApi.update$(restoredUser).subscribe(onRestoreSuccess);
    }
}