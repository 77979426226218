import { Component, Input } from "@angular/core";
import { ITask } from "src/app/models/task";

@Component({
    selector: "app-recurring-task-preview",
    templateUrl: "./recurring-task-preview.component.html",
    styleUrls: []
})
export class RecurringTaskPreviewComponent {
    @Input() task: ITask;
    @Input() showTopLine = true;
    @Input() showBottomLine = true;

    constructor(

    ) {

    }
}
