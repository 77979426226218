<form *ngIf="modeConfigurationForm"
      [formGroup]="modeConfigurationForm"
      class="m-border-top m-overflow-auto"
      autocomplete="off">

    <div class="p-16"
         style="display:inline-grid; grid-template-columns: auto auto; grid-template-rows: 1fr 1fr; gap:8px; align-items: center;">
        <label for="code">
            {{"modeConfiguration.vmsTypeId" | translate}}
        </label>

        <div class="m-form-group"
             [ngClass]="{ 'has-error': modeConfigurationForm.get('vmsTypeId').showErrors }">
            <div>
                <p-dropdown id="vmsTypeId"
                            formControlName="vmsTypeId"
                            [options]="vmsTypes"
                            [style]="{'width':'240px', 'max-width': '100%'}"
                            placeholder="{{'modeConfiguration.vmsTypeId' | translate}}"
                            [autoDisplayFirst]="false"
                            (onChange)="onChangeVmsTypeDropdown($event.value)"
                            #vmsTypeInput>
                </p-dropdown>
                <div class="warning"
                     *ngIf="vmsTypeChanged">{{"modeConfiguration.warningChangeVmsType" | translate}}</div>
                <app-form-validation [control]="modeConfigurationForm.get('vmsTypeId')"
                                     [input]="vmsTypeInput">
                </app-form-validation>
            </div>
        </div>

        <label for="code">
            {{"modeConfiguration.defaultModeId" | translate}}
        </label>
        <div class="m-form-group"
             [ngClass]="{ 'has-error': modeConfigurationForm.get('defaultModeId').showErrors }">
            <div>
                <p-dropdown id="defaultModeId"
                            formControlName="defaultModeId"
                            [options]="modeOptions"
                            [style]="{'width':'240px', 'max-width': '100%'}"
                            placeholder="{{'modeConfiguration.defaultModeId' | translate}}"
                            [autoDisplayFirst]="false"
                            #defaultModeIdInput>
                </p-dropdown>

                <app-form-validation [control]="modeConfigurationForm.get('defaultModeId')"
                                     [input]="defaultModeIdInput">
                </app-form-validation>
            </div>
        </div>
    </div>

    <div class="flex align-center m-border-top p-16"
         [ngClass]="{'m-border-bottom': !modeForms}">
        <h3>{{"modeConfiguration.modes" | translate}}</h3>
    </div>

    <ng-container *ngIf="modeForms"
                  formGroupName="modes">
        <div id="modes_grid"
             style="display:grid; gap:16px;"
             class="px-16 pb-16">
            <div *ngFor="let modeForm of modeForms.controls; let modeIndex = index"
                 formGroupName="{{modeIndex}}">
                <div class="p-8 m-border-top m-border-left m-border-right m-mode-grid-header">
                    <div style="display:inline-grid; gap:8px; grid-auto-flow: column; align-items: center;">
                        <div>
                            <app-icon-button id="preview_button"
                                             icon="eye-solid"
                                             text="modeConfiguration.preview"
                                             class="cell"
                                             (click)="openPreviewDialog(modeForm)">
                            </app-icon-button>
                            <app-icon-button id="removeMode{{modeIndex}}"
                                             icon="delete"
                                             text="form.delete"
                                             (click)="deleteMode(modeIndex)">
                            </app-icon-button>
                        </div>

                        <div>
                            <input id="modeId{{modeIndex}}"
                                   class="p-inputtext"
                                   style="min-width: 40px; width:40px;"
                                   type="text"
                                   formControlName="id"
                                   (change)="updateModeOptions()"
                                   appAutofocus
                                   #idInput />

                            <app-form-validation [control]="modeForm.get('id')"
                                                 [input]="idInput">
                            </app-form-validation>
                        </div>

                        <div>
                            <input id="modeDescription{{modeIndex}}"
                                   class="p-inputtext"
                                   type="text"
                                   formControlName="description"
                                   #descriptionInput />

                            <app-form-validation [control]="modeForm.get('description')"
                                                 [input]="descriptionInput">
                            </app-form-validation>
                        </div>
                    </div>
                </div>

                <div formGroupName="frames"
                     class="m-border-left m-border-right m-border-bottom m-mode-grid-frames">
                    <ng-template #frameImageTemplate
                                 let-image="image"
                                 let-templateFrameIndex="index"
                                 let-templateFrameForm="frameForm">

                        <img *ngIf="image"
                             style="max-width: 100%; image-rendering: pixelated;"
                             [src]="image.url | safeHtml"
                             width="{{image.vmsType.resolutionWidth}}"
                             height="{{image.vmsType.resolutionHeight}}" />

                        <img *ngIf="!image"
                             style="max-width: 100%; width: 10rem;"
                             src="/assets/img/empty-preview.png?v=20240726" />
                    </ng-template>

                    <div style="display:flex; flex-wrap: wrap; grid-auto-flow: column; align-items: start;">
                        <div *ngFor="let frameForm of modeForm.get('frames').controls; let frameIndex = index"
                             style="border-right:2px dashed #f4f4f4;"
                             class="align-center p-16 pb-8"
                             formGroupName="{{frameIndex}}">

                            <div style="display:grid; gap:8px; justify-items: center;">
                                <ng-template [ngTemplateOutlet]="frameImageTemplate"
                                             [ngTemplateOutletContext]="{image: getVmsImageVariantFromModeForm(frameForm), index: frameIndex, frameForm: frameForm}">
                                </ng-template>
                                <div *ngIf="modeForm.get('frames').controls.length > 1"
                                     [ngClass]="{ 'has-error': frameForm.get('displayTimeMs').showErrors }">

                                    <div class="m-input-group"
                                         style="width: 110px;">
                                        <input id="{{frameIndex}}displayTimeMs"
                                               class="p-inputtext"
                                               style="min-width: 0;"
                                               type="number"
                                               [formControl]="frameForm.get('displayTimeMs')"
                                               min="100"
                                               #displayTimeMsInput>

                                        <span class="m-input-group-addon">
                                            {{"measurements.ms" | translate}}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
                                <ng-container *ngIf="isVmsTypeSelected">
                                    <app-icon-button [text]="'modeConfiguration.search' | translate"
                                                     icon="search"
                                                     (click)="selectVmsImageFromLibrary(frameForm)">
                                    </app-icon-button>
                                    <app-icon-button *ngIf="!hasVmsImage(frameForm)"
                                                     icon="edit"
                                                     [text]="'form.create' | translate"
                                                     (click)="navigateToVmsEditor(frameForm)">
                                    </app-icon-button>
                                    <app-icon-button *ngIf="isEditableVmsImage(frameForm) || hasVmsImage(frameForm)"
                                                     icon="edit"
                                                     [text]="'form.edit' | translate"
                                                     (click)="navigateToVmsEditor(frameForm)">
                                    </app-icon-button>
                                    <app-icon-button [text]="'form.delete' | translate"
                                                     icon="delete"
                                                     (click)="deleteVmsFrame(modeForm, frameIndex)"></app-icon-button>
                                </ng-container>
                            </div>

                        </div>
                        <btn class="m-btn m-btn-secondary"
                             style="padding-top: 16px;"
                             (click)="addVmsFrame(modeForm)">
                            <app-svg-icon icon="add"></app-svg-icon>
                            {{"modeConfiguration.addFrame" | translate}}
                        </btn>
                    </div>
                </div>
            </div>

            <div>
                <button class="m-btn m-btn-secondary"
                        (click)="addMode()"
                        id="add_mode_button">
                    <app-svg-icon icon="add"></app-svg-icon>
                    {{"modeConfiguration.addMode" | translate}}
                </button>
            </div>
        </div>
    </ng-container>
</form>

<div *ngIf="modeConfigurationForm"
     class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">

    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
    <app-vms-image-library-selector-dialog [vmsTypeId]="modeConfigurationForm.get('vmsTypeId').value"
                                           [ownerId]="measuringPoint.ownerId"></app-vms-image-library-selector-dialog>
    <app-vms-image-editor-dialog></app-vms-image-editor-dialog>
    <app-vms-animation-dialog></app-vms-animation-dialog>
</div>