import { Component, Input, ViewChild } from "@angular/core";
import { VmsManageDialogComponent } from "../vms-manage-dialog/vms-manage-dialog.component";
import { IVmsPinnedData } from "src/app/models/pinned-data";
import { IVmsStatus } from "src/app/models/device";

@Component({
    selector: "app-vms-pinned-data",
    templateUrl: "./vms-pinned-data.component.html",
})
export class VmsPinnedDataComponent {
    @Input() measuringPointId: number;
    @Input() data: IVmsPinnedData;
    @Input() vmsStatus: IVmsStatus;
    @Input() isExpanded: boolean;
    @ViewChild(VmsManageDialogComponent, { static: true }) modeManageDialog: VmsManageDialogComponent;

    constructor() { }

    openSetModeDialog(event: MouseEvent) {
        event.stopPropagation();
        if (!this.data.modes) return;
        this.modeManageDialog.open(this.measuringPointId, this.data.modes);
    }
}
