import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TreeNode } from "primeng/api";
import { ProjectsTreeApi } from "src/app/resource/projects-tree.api";
import { ProjectTreeUtils, SubscriptionManager } from "src/app/utilities";
import { Tree, TreeNodeSelectEvent } from "primeng/tree";
import { IProjectsTreeOrganization } from "src/app/models/projects-tree-organization";
import { FilterService } from "src/app/modules/locations/services/filter.service";

interface SelectedFilters {
    id: number;
    type: "project" | "organization";
}

@Component({
    selector: "app-projects-filter",
    templateUrl: "./projects-filter.component.html",
})
export class ProjectsFilterComponent implements OnInit, OnDestroy {
    @ViewChild("tree") tree: Tree;
    @ViewChild("searchField") searchField: ElementRef<HTMLInputElement>;

    onlyActiveProjects = true;
    onlyMeasuringPointsProjects = true;

    selected: SelectedFilters[] = [];
    searchText = "";
    nodes: TreeNode[];
    filteredNodes: TreeNode[];
    selectedNodes: TreeNode[] = [];
    loading = false;

    private readonly projectsTreeApi = inject(ProjectsTreeApi);
    private readonly filterService = inject(FilterService);
    private readonly subscriptionManager = new SubscriptionManager();

    ngOnInit(): void {
        // this.refreshProjectsTree();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    // refresh the tree, triggered by checkboxes
    refreshProjectsTree() {
        this.loadProjectsTree();
    }

    getProjectStyle(node: TreeNode) {
        return ProjectTreeUtils.getProjectStyle(node);
    }

    private loadProjectsTree() {
        this.loading = true;
        this.projectsTreeApi.get(this.onlyActiveProjects, this.onlyMeasuringPointsProjects).subscribe((organizations: IProjectsTreeOrganization[]) => {

            this.nodes = [];
            this.filteredNodes = [];
            this.nodes = ProjectTreeUtils.mapProjectsTreeToTreeNodes(organizations);
            // reapply filter
            this.onFilter(this.searchText);

            this.selected = [];
            this.selectedNodes = [];
            const filterState = this.filterService.filterState;
            if (filterState.projects.length) {
                filterState.projects.forEach((project) => {
                    this.selected.push({ id: project.id, type: "project" });
                });
            }
            if (filterState.organizations.length) {
                filterState.organizations.forEach((organization) => {
                    this.selected.push({ id: organization.id, type: "organization" });
                });
            }

            if (this.selected) {
                this.selected.forEach((selected) => {
                    this.selectedNodes.push(this.findIdInNodes(selected, this.nodes));
                });
            }
            this.loading = false;
        });
    }

    private findIdInNodes(data: SelectedFilters, nodes: TreeNode[]): TreeNode {
        for (const node of nodes) {
            if (node.data.id === data.id && node.type === data.type) {
                return node;
            }
            if (node.children) {
                const found = this.findIdInNodes(data, node.children);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    // Custom filter method for the tree
    // Based on the search input, filter the tree
    public onFilter(value: string) {
        this.loading = true;
        this.filteredNodes = ProjectTreeUtils.filterTreeNodes(this.nodes, value);
        // Rerender the tree
        this.tree.updateSerializedValue();
        this.loading = false;
    }

    public onNodeSelect(event: TreeNodeSelectEvent) {
        if (event.node.type === "project") {
            this.selected.push({ id: event.node.data.id, type: "project" });
        }
        if (event.node.type === "organization") {
            this.selected.push({ id: event.node.data.id, type: "organization" });
        }
    }

    public onNodeUnselect(event: TreeNodeSelectEvent) {
        this.selected = this.selected.filter((s) => s.id !== event.node.data.id);
    }
}
