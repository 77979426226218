<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [hidden]="hidden"
         sortField="code"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer">
        <ng-container *ngIf="total && !paginator">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-group
                 let-expanded="expanded"
                 let-index="rowIndex">

        <tr [pSelectableRow]="group"
            [pReorderableRow]="index">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'reorder'">
                    <div class="flex justify-center"
                         style="width: 100%;">
                        <i class="fa fa-bars"
                           pReorderableRowHandle></i>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'code'">
                    {{group.code}} <i>&nbsp;({{group.measuringPoints?.length ?? 0}})</i>
                </ng-container>

                <ng-container *ngSwitchCase="'expand'">
                    <a [pRowToggler]="group">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group"
                         style="margin-top: 0;">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(group)"
                                             *ngIf="command.rowValidFunc(group)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{group[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>

    <ng-template pTemplate="rowexpansion"
                 let-group
                 let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 1">
                <app-select-measuring-points [headers]="true"
                                             [footer]="false"
                                             [mini]="true"
                                             [requiresInput]="true"
                                             [group]="group"
                                             [deleteCommand]="false"
                                             [readonly]="true"
                                             [hideDirectionFilters]="hideDirectionFilters"></app-select-measuring-points>
            </td>
        </tr>
    </ng-template>
</p-table>