<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"uploads.downloadArchive" | translate}}
    </p-header>

    <form *ngIf="downloadArchiveForm"
          class="p-16"
          [formGroup]="downloadArchiveForm"
          autocomplete="off"
          novalidate>

        <div class="row">
            <div class="col-6">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': downloadArchiveForm.get('from')?.showErrors }">

                    <label class="col-4"
                           for="from">
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="from"
                                    appendTo="body"
                                    formControlName="from"
                                    [style]="{'width':'100%'}"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [maxDate]="downloadArchiveForm.get('to')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [showTime]="true"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #fromInput></p-calendar>

                        <app-form-validation [control]="downloadArchiveForm.get('from')"
                                             [input]="fromInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': downloadArchiveForm.get('to')?.showErrors }">

                    <label class="col-4"
                           for="to">
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="to"
                                    appendTo="body"
                                    formControlName="to"
                                    [style]="{'width':'100%'}"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [minDate]="downloadArchiveForm.get('from')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [showTime]="true"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #toInput></p-calendar>

                        <app-form-validation [control]="downloadArchiveForm.get('to')"
                                             [input]="toInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button id="download_archive_close_button"
                class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button id="download_archive_submit_button"
                [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>

            {{"uploads.downloadArchive" | translate}}
        </button>
    </p-footer>
</p-dialog>