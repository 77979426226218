import { Component, ViewChild, OnInit, OnDestroy, ElementRef, HostListener, ChangeDetectorRef, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, RouterOutlet } from "@angular/router";
import { ICoordinate, ILocation } from "src/app/models/location";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { MapDetailService, MapDetail } from "src/app/services/map-detail.service";
import { MapContextMenuItem } from "src/app/modules/map-basic";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { SplitMapComponentBase } from "src/app/modules/shared/components/split-map/split-map.component";
import { AssignmentsComponent } from "src/app/modules//assignments/components/assignments/assignments.component";
import { LocalStorageService } from "src/app/services/storage.service";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { NavigationService } from "src/app/services/navigation.service";
import { DevicesComponent } from "src/app/modules/shared/components/devices/devices.component";
import { GroupsComponent } from "src/app/modules/shared/components/groups/groups.component";
import { MapDataService } from "src/app/services/map-data.service";
import { ResizeService } from "src/app/services/resize.service";
import { MapEvent } from "src/app/modules/map-advanced/components/advanced-map/advanced-map.component";
import { GroupApi } from "src/app/resource/group.api";
import { MarkerContext } from "src/app/modules/map-advanced/classes/marker-context";
import { MeasuringPointsComponent } from "src/app/modules/shared/components/measuring-points/measuring-points.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthorizationInfo } from "src/app/models/user";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationApi } from "src/app/resource/organization.api";
import { MapUtils } from "src/app/utilities";
import { WebsiteService } from "src/app/services/website.service";

/**
 * This is a map component that is used for several use cases:
 * * Devices
 * * Organizations
 * * Groups
 * * Assignments
 *
 * It is implemented as a split view with a map and a table.
 *
 * Note that the locations maps is implemented differently (it does not use a split view).
 */
@Component({
    selector: "app-main-screen-map",
    templateUrl: "./main-screen-map.component.html"
})
export class MainScreenMapComponent extends SplitMapComponentBase implements OnInit, OnDestroy, IComponentCanDeactivate {
    @ViewChild(RouterOutlet, { static: false }) routerOutlet: RouterOutlet;
    @ViewChild(LocationDialogComponent, { static: false }) manageLocationComponent: LocationDialogComponent;

    isAuthenticated: boolean = null;
    showInfoPanel = true;
    restoreServiceInitialSelection = false;

    rights: Rights;
    authorizationInfo: AuthorizationInfo;
    protected readonly websiteService = inject(WebsiteService);

    constructor(
        cd: ChangeDetectorRef,
        elementRef: ElementRef,
        authenticationService: AuthenticationService,
        globalEventsService: GlobalEventsService,
        router: Router,
        navigationService: NavigationService,
        resizeService: ResizeService,
        localStorageService: LocalStorageService,
        selectionService: MapSelectionService,
        mapDataService: MapDataService,
        groupApi: GroupApi,
        translateService: TranslateService,
        readonly mapDetailService: MapDetailService,
        private readonly changeGuardService: ChangeGuardService,
        protected readonly organizationApi: OrganizationApi) {

        super(cd, elementRef, globalEventsService, authenticationService, router, navigationService, resizeService, localStorageService, selectionService, groupApi, mapDataService, translateService, organizationApi);

        this.updateReadonly();
    }


    ngOnInit() {
        super.ngOnInit();

        const currentAuthorizationInfo = this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo: AuthorizationInfo) => {
            this.authorizationInfo = authorizationInfo;

            this.updateReadonly();

        });
        this.subscriptionManager.add("currentAuthorizationInfo", currentAuthorizationInfo);

        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.subscriptionManager.add("isAuthenticatedSubscription",
            this.globalEventsService.isAuthenticated$.subscribe((value) => {
                if (value !== null) {
                    this.isAuthenticated = value;
                }
            }));

    }

    get showComponent(): boolean {
        // If not authenticated
        // OR authenticated and not readonly
        const showComponent = this.isAuthenticated !== null && (this.isAuthenticated === false || (this.isAuthenticated === true && !this.readonly));
        return showComponent;
    }

    get showSearchbutton(): boolean {
        const showSearchbutton = this.isAuthenticated !== null && this.isAuthenticated === true;
        return showSearchbutton;
    }
    protected onMapReady() {

        if (this.getCurrentMapDetail() === MapDetail.MeasuringPoints) {
            this.gmap?.setSearchParameters(this.measuringPointSearchParameters, this.getCurrentMapDetail());
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.mapDataService.unsubscribe(this.mapDataServiceKey);
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck();
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    private updateReadonly() {
        this.readonly = !this.authorizationInfo;
    }

    //#region Detail

    onRouterActivate(elementRef: any) {
        // https://stackoverflow.com/questions/39255311/can-you-use-viewchild-or-similar-with-a-router-outlet-how-if-so
        if (elementRef instanceof MeasuringPointsComponent) {
            if (this.measuringPointsComponent === elementRef) return;
            this.setMeasuringPointsComponent(elementRef);
        }

        if (elementRef instanceof GroupsComponent) {
            if (this.groupsComponent === elementRef) return;
            this.setGroupsComponent(elementRef);
        }

        if (elementRef instanceof DevicesComponent) {
            if (this.devicesComponent === elementRef) return;

            this.setDevicesComponent(elementRef);
        }

        if (elementRef instanceof AssignmentsComponent) {
            if (this.assignmentsComponent === elementRef) return;
            this.setAssignmentsComponent(elementRef);
        }
    }

    closeInfoPanel() {
        this.showInfoPanel = false;
    }

    //#endregion Detail

    //#region Map

    getCurrentMapDetail(): MapDetail {
        return this.mapDetailService.currentMapDetail;
    }

    //#endregion Map

    //#region State Measuring Points

    private createLocation(coordinate: ICoordinate) {
        this.manageLocationComponent.create(coordinate);
    }

    protected editLocation(context: MarkerContext) {
        const onUpdate = (updatedLocation: ILocation) => {
            // We just reload all the data
            // In the future, we should be able to subscribe to SignalR changes to the location,
            // and update only the relevant marker.
            this.mapDataService.loadMeasuringPointLocations();
        };

        this.manageLocationComponent.editContext(context, onUpdate);
    }

    //#endregion State Measuring Points

    //#region Assignments
    private navigateToCreateAssignment(coordinate: ICoordinate) {
        this.navigationService.createAssignmentAtLocation(coordinate);
    }

    //#endregion
    //#region Abstract implementations

    protected createMarkerContextMenu(locationClickEvent: MapEvent) {
        const buttons = new Array<MapContextMenuItem>();

        const editCommand = () => {
            this.editContext(locationClickEvent.locationMarker.context);
        };

        if (this.mapDetailService.currentMapDetail === MapDetail.Organizations) {
            if (this.rights?.hasBackendRight(BackendRights.EditOrganization)) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("manageOrganization.edit"), "edit", editCommand));
            }
        }

        if (this.mapDetailService.currentMapDetail === MapDetail.Assignments) {
            if (this.rights?.hasBackendRight(BackendRights.EditAssignment)) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("manageLocation.edit"), "edit", editCommand));
            }
        }

        const latLng = this.locationClickToLtnLng(locationClickEvent);
        this.gmap.openContextMenu(latLng, buttons);
    }

    protected createMapContextMenu(event: google.maps.MapMouseEvent) {
        const buttons = new Array<MapContextMenuItem>();

        if (this.mapDetailService.currentMapDetail === MapDetail.Organizations) {
            if (this.rights?.hasBackendRight(BackendRights.CreateOrganization)) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("manageOrganization.create"), "add", () => { this.createOrganization(event); }));
            }
        }

        if (this.mapDetailService.currentMapDetail === MapDetail.Assignments) {
            if (this.rights?.hasBackendRight(BackendRights.CreateAssignment)) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("manageAssignment.create"), "add", () => {
                    const coordinate = MapUtils.mouseEventToCoordinate(event);
                    this.navigateToCreateAssignment(coordinate);
                }));
            }
        }

        if (this.mapDetailService.currentMapDetail === MapDetail.Devices) {
            if (this.rights?.hasBackendRight(BackendRights.CreateDevice)) {
                buttons.push(new MapContextMenuItem(this.translateService.instant("manageDevice.create"), "add", () => {
                    this.navigationService.createNewDevice();
                }));
            }
        }

        this.gmap.openContextMenu(event.latLng, buttons);
    }

    //#endregion Abstract implementations
}
