import { ConfigurationService } from "src/app/services/configuration.service";
import { Injectable, inject } from "@angular/core";

export class MapTypeIds {
    static readonly openStreetMapTypeId = "OSM";
    static readonly openCycleMapTypeId = "OCM";
    static readonly openTrafficMapTypeId = "OTM";

    // We also define the Google Maps id, that way we can access them before Google Maps is loaded (which makes startup simpler)
    static readonly GoogleRoadmap = "roadmap";
    static readonly GoogleTerrain = "terrain";
    static readonly GoogleSatellite = "satellite";
    static readonly GoogleHybrid = "hybrid";
}

/**
 * Data related to each map type
 */
export class MapTypeData {
    constructor(
        public mapTypeId: string,
        public label: string,
        public iconClass: string,
        public showTrafficLayer: boolean) { }
}

/**
 * Provides additional map types in Google Maps, such as OpenStreetMap.
 */
@Injectable()
export class MapTypeProvider {

    private readonly configurationService = inject(ConfigurationService);

    public initialize(map: google.maps.Map): void {
        this.configureAdditionalMapType(map, MapTypeIds.openStreetMapTypeId, "OpenStreetMap", "https://tile.geofabrik.de/a56b80572cc14c55a59a07f8b8d868f3", "png");
        this.configureAdditionalMapType(map, MapTypeIds.openCycleMapTypeId, "OpenCycleMap", "https://tile.thunderforest.com/cycle", "png", `?apikey=${this.configurationService.configuration.openCycleMapApiKey}`);
        this.configureAdditionalMapType(map, MapTypeIds.openTrafficMapTypeId, "OpenTransportMap", "https://tile.thunderforest.com/transport", "png", `?apikey=${this.configurationService.configuration.openTrafficMapApiKey}`);
    }

    private configureAdditionalMapType(map: google.maps.Map, id: string, name: string, url: string, extension: "png" | "jpg" = "png", urlAppend: string = "") {
        const getX = (coord: google.maps.Point, zoom: number) => {
            // "Wrap" x (longitude) at 180th meridian properly
            // NB: Don't touch coord.x because coord param is by reference, and changing its x property breaks something in Google's lib
            const tilesPerGlobe = 1 << zoom;
            let x = coord.x % tilesPerGlobe;
            if (x < 0) {
                x = tilesPerGlobe + x;
            }

            return x;
            // Wrap y (latitude) in a like manner if you want to enable vertical infinite scroll
        };

        // http://wiki.openstreetmap.org/wiki/Google_Maps_Example
        map.mapTypes.set(id, new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) => {
                const x = getX(coord, zoom);
                return `${url}/${zoom}/${x}/${coord.y}.${extension}${urlAppend}`;
            },
            tileSize: new google.maps.Size(256, 256),
            name: name,
            maxZoom: 20
        }));
    }
}
