import { IVmsType } from "../models/measuring-point";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class VmsTypeApi extends ApiService<IVmsType, IVmsType, IVmsType> {
    getRoute(): string {
        return "VmsTypes";
    }

}