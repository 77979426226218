import { ApiTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ManageBikeParameterSetDialogComponent } from "../manage-bike-parameter-set-dialog/manage-bike-parameter-set.dialog";
import { BikeParameterSetApi } from "src/app/resource/bike-parameter-set.api";
import { IBikeParameterSet } from "src/app/models/bike-parameter-set";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";

@Component({
    selector: "app-bike-parameter-sets",
    templateUrl: "./bike-parameter-sets.component.html"
})
export class BikeParameterSetsComponent extends ApiTableComponent<IBikeParameterSet> implements OnInit {
    @ViewChild(ManageBikeParameterSetDialogComponent, { static: true }) manageBikeParameterSetDialog: ManageBikeParameterSetDialogComponent;

    @Input() viewCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly modalService: ModalService,
        private readonly bikeParameterSetApi: BikeParameterSetApi,
        private readonly translate: TranslateService) {

        super("bike-parameter-sets.component", elementRef, bikeParameterSetApi, tableService);

        this.stretchHeight = true;

        this.selectionMode = "";
        this.includeObsolete = true;

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        // this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        // this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "bikeParameterSets.view",
            icon: "visible",
            click: (bikeParameterSet) => this.edit(bikeParameterSet),
            validFunc: () => this.viewCommand
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    edit(bikeParameterSet: IBikeParameterSet) {
        this.manageBikeParameterSetDialog.view(bikeParameterSet);
    }
}