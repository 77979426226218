<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"drivingLanes.create" | translate}}
        </button>
    </div>

    <app-driving-lanes [editCommand]="true"
                       class="m-layout-area-body m-layout-default"
                       [deleteCommand]="true"></app-driving-lanes>
</div>

<app-manage-driving-lane-dialog></app-manage-driving-lane-dialog>