import { Component, inject, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IDeviceStatusSummary } from "src/app/models/web";

@Component({
    selector: "app-device-status-header",
    templateUrl: "./device-status-header.component.html",
    styleUrls: ["./device-status-header.component.scss"],
})
export class DeviceStatusHeaderComponent {
    @Input() data: IDeviceStatusSummary;

    protected readonly translateService = inject(TranslateService);

    protected shouldShowBatteryIcon(): boolean {
        // This method is needed because we should still show the icon if the battery is at 0.
        const value = this.data?.currentPower?.chargePercentage;
        if (value === null || value === undefined) return false;
        return true;
    }

    protected getBatteryIcon(): string {
        if (!this.shouldShowBatteryIcon()) return null;

        // We have icons for 5 states, so we divide the percentage in 5 (20%)
        if (this.data?.currentPower?.chargePercentage < 20) return "battery-empty";
        if (this.data?.currentPower?.chargePercentage < 40) return "battery-quarter";
        if (this.data?.currentPower?.chargePercentage < 60) return "battery-half";
        if (this.data?.currentPower?.chargePercentage < 80) return "battery-three-quarters";
        return "battery-full";
    }

    getSignalStrengthIcon(): string {
        if (!this.data?.currentNetwork?.signalStrength) return null;

        if (this.data?.currentNetwork?.signalStrength < -120) return "signal-bars-no-signal";
        if (this.data?.currentNetwork?.signalStrength < -105) return "signal-bars-weak";
        if (this.data?.currentNetwork?.signalStrength < -90) return "signal-bars-fair";
        if (this.data?.currentNetwork?.signalStrength < -75) return "signal-bars-good";
        return "signal-bars"; // This has all the stripes
    }
}