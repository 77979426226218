import { ApiTableComponent, FilterType, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, OnChanges, Input, ElementRef, ViewChild } from "@angular/core";
import { ManageAdminDeviceDepotDialogComponent } from "./../manage-admin-device-depot-dialog/manage-admin-device-depot.dialog";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDepotApi } from "src/app/resource/device-depot.api";
import { ModalService } from "src/app/services/modal.service";
import { IDeviceDepot } from "src/app/models/device-depot";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-admin-device-depots",
    templateUrl: "./admin-device-depots.component.html"
})
export class AdminDeviceDepotsComponent extends ApiTableComponent<IDeviceDepot> implements OnChanges {
    @ViewChild(ManageAdminDeviceDepotDialogComponent, { static: true }) manageDeviceDepotDialog: ManageAdminDeviceDepotDialogComponent;

    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;
    canCreateDeviceDepot = false;
    canDeleteDeviceDepot = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly deviceDepotApi: DeviceDepotApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService) {

        super("device-depots.component", elementRef, deviceDepotApi, tableService);
        const rights = this.globalEventsService.getCurrentRights();
        this.canCreateDeviceDepot = rights?.hasBackendRight(BackendRights.EditDeviceDepot);
        this.canDeleteDeviceDepot = rights?.hasBackendRight(BackendRights.DeleteDeviceDepot);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;

        this.selectionMode = "";

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, width: 200, resizable: false }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, width: 300, resizable: true }));

        this.registerCommand({
            text: "deviceDepots.edit",
            icon: "edit",
            click: (deviceDepot) => this.edit(deviceDepot),
            validFunc: () => this.editCommand && this.canCreateDeviceDepot
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (deviceDepot) => this.delete(deviceDepot),
            validFunc: () => this.deleteCommand && this.canDeleteDeviceDepot
        });
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        searchParameters.filter = [new FilterDescriptor("ownerId", null)];

        return searchParameters;
    }

    create() {
        const onCreate = () => {
            this.reload();
        };

        this.manageDeviceDepotDialog.create(null, onCreate);
    }

    edit(deviceDepot: IDeviceDepot) {
        const onEdit = () => {
            this.reload();
        };

        this.manageDeviceDepotDialog.edit(deviceDepot, onEdit);
    }

    delete(deviceDepot: IDeviceDepot) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.deviceDepotApi.delete$(deviceDepot.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("deviceDepots.deleteConfirmation", deviceDepot);
        this.modalService.delete(modalBody, onDelete);
    }
}