<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"deviceUpload.offsetCorrection" | translate}}
    </p-header>

    <form *ngIf="tubeOffsetForm"
          class="p-16"
          [formGroup]="tubeOffsetForm"
          novalidate>

        <div class="mb-16">
            <p [innerHtml]="'deviceUpload.tubeOffset' | translate | safeHtml">
            </p>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': tubeOffsetForm.get('difference').showErrors }">

            <label class="col-4"
                   for="difference">
                {{"deviceUpload.tubeDifference" | translate}}
            </label>

            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           id="difference"
                           class="p-inputtext"
                           formControlName="difference"
                           (change)="updateOffset()"
                           (input)="updateOffset()"
                           #differenceInput />
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}} </div>
                </div>

                <div class="mt-8">
                    {{"deviceUpload.offsetCorrection" | translate}}: {{offset}} {{"measurements.ms" | translate}}
                </div>

                <app-form-validation [control]="tubeOffsetForm.get('difference')"
                                     [input]="differenceInput">
                </app-form-validation>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>