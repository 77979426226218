<form *ngIf="vehicleConfigurationForm"
      [formGroup]="vehicleConfigurationForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      autocomplete="off">
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': vehicleConfigurationForm.get('licensePlate').showErrors }">

        <label class="col-4"
               for="code">
            {{"vehicleConfiguration.licensePlate" | translate}}
        </label>

        <div class="col-8">
            <input type="text"
                   class="p-inputtext"
                   placeholder="{{'vehicleConfiguration.licensePlate' | translate}}"
                   id="licensePlate"
                   formControlName="licensePlate"
                   appAutofocus="!isCreatingNew()"
                   #licensePlateInput />

            <app-form-validation [control]="vehicleConfigurationForm.get('licensePlate')"
                                 [input]="licensePlateInput">
            </app-form-validation>

        </div>
    </div>
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': vehicleConfigurationForm.get('vehicleIdentificationNumber').showErrors }">

        <label class="col-4"
               for="code">
            {{"vehicleConfiguration.vehicleNumber" | translate}}
        </label>

        <div class="col-8">
            <input type="text"
                   class="p-inputtext"
                   placeholder="{{'vehicleConfiguration.vehicleNumber' | translate}}"
                   id="vehicleIdentificationNumber"
                   formControlName="vehicleIdentificationNumber"
                   appAutofocus="!isCreatingNew()"
                   #vehicleIdentificationNumberInput />

            <app-form-validation [control]="vehicleConfigurationForm.get('vehicleIdentificationNumber')"
                                 [input]="vehicleIdentificationNumberInput">
            </app-form-validation>

        </div>
    </div>
    <div class="m-form-group row ">

        <label class="col-4">
            {{"general.color" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown id="color"
                        [options]="vehicleColors"
                        formControlName="color"
                        [autoDisplayFirst]="false"
                        appendTo="body"
                        [filter]="true"
                        [panelStyle]="{'width':'100%'}"
                        placeholder="{{'general.color' | translate }}"
                        #colorInput>
            </p-dropdown>
        </div>
    </div>
    <div class="m-form-group row ">

        <label class="col-4">
            {{"general.type" | translate}}
        </label>
        <div class="col-8">
            <p-dropdown id="type"
                        [options]="vehicleTypes"
                        formControlName="type"
                        [autoDisplayFirst]="false"
                        appendTo="body"
                        [filter]="true"
                        [panelStyle]="{'width':'100%'}"
                        placeholder="{{'general.type' | translate }}"
                        #typeInput>
            </p-dropdown>
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom"
     *ngIf="vehicleConfigurationForm">

    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>