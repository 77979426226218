import { IServiceModelBase } from "./servicemodelbase";
import { IDeviceSummary } from "./device";
import { IOrganization } from "./user";
import { JsonUtils } from "../utilities/json-utils";
import { Duration } from "moment";

export interface IDeviceDisplayConfiguration {
    bikeCounterDisplay: IBikeCounterDisplay;
    qLite: IQLiteDeviceDisplayConfiguration;
}

export interface IDekimoDisplayConfiguration {
    portName: string;
    lightSensor: any;
    intensity: any;
}

export interface IQLiteDeviceDisplayConfiguration {
    enableQLiteDisplay: boolean;
    qLitePortName: string;
    qLiteCounterId: number;
    qLiteCounterGroupId: number;
    qLiteCounterFormat: string;
    enableQLiteText: boolean;
    qLiteTextGroupId: number;
    qLiteTextId: number;
    qLiteRequestResponseCode: number;
    qLiteAlwaysRequestResponseCode: number;
    qLiteDelayAfterSendingByte: number;

    enableQLitePowerMonitoring: boolean;
    enableQLiteTemperatureMonitoring: boolean;
    qLitePowerStatusGroupId: number;
    qLitePowerStatusId: number;
}

export interface IDeviceDisplayPage {
    text: string;
    durationInSeconds?: number;
}

export interface IDeviceDisplayRule {
    minDate?: Date;
    maxDate?: Date;

    includeMondays?: boolean;
    includeTuesdays?: boolean;
    includeWednesdays?: boolean;
    includeThursdays?: boolean;
    includeFridays?: boolean;
    includeSaturdays?: boolean;
    includeSundays?: boolean;
    includeHolidays?: boolean;
    includeVacations?: boolean;
    includeBridgeDays?: boolean;

    minTime?: Duration;
    maxTime?: Duration;

    minTemperature?: number;
    maxTemperature?: number;

    minDayCount?: number;
    maxDayCount?: number;

    minMonthCount?: number;
    maxMonthCount?: number;

    minYearCount?: number;
    maxYearCount?: number;

    minSpeedInKmh?: number;
    maxSpeedInKmh?: number;

    durationInSeconds?: number;
    durationInCentimeters?: number;

    triggerOnScenarioActivate?: boolean;
}

export interface IScenarioPinAction {
    intervalOnInMilliseconds: number;
    intervalOffInMilliseconds: number;
}

export interface IZigbeeNotifyConfiguration {
    deviceId: number;
    distanceInCentimeter: number;
    delayOffsetInMs: number;

    // Filled in for GUI only
    deviceSummary?: IDeviceSummary;
}

export interface IScenarioZigbeeConfiguration {
    enable: true;
    notifyConfigurations: IZigbeeNotifyConfiguration[];
}

export interface IDeviceDisplayEvent extends IServiceModelBase {
    name: string;
    isActive: boolean;
    originalIsActive: boolean;
    priority: number;
    originalPriority: number;
    deviceTypeId: string;
    applyToAllDevices: boolean;
    pages: IDeviceDisplayPage[];
    rule: IDeviceDisplayRule;
    owner: IOrganization;
    devices: IDeviceSummary[];
    pinAction: IScenarioPinAction;
    zigbeeConfiguration: IScenarioZigbeeConfiguration;
}

export class DeviceDisplayEventCreator {
    name: string;
    isActive: boolean;
    priority: number;
    deviceTypeId: string;
    applyToAllDevices: boolean;
    pages: IDeviceDisplayPage[];
    rule: IDeviceDisplayRule;
    ownerId?: number;
    deviceIds: number[];
    pinAction: IScenarioPinAction;
    zigbeeConfiguration: IScenarioZigbeeConfiguration;
}

export class DeviceDisplayEventUpdater extends DeviceDisplayEventCreator {
    constructor(existing: IDeviceDisplayEvent) {
        super();

        this.id = existing.id;
        this.name = existing.name;
        this.isActive = existing.isActive;
        this.priority = existing.priority;
        this.deviceTypeId = existing.deviceTypeId;
        this.applyToAllDevices = existing.applyToAllDevices;
        this.pages = (existing.pages || []).clone();
        this.rule = JsonUtils.deepClone(existing.rule || {});
        this.ownerId = existing.owner ? existing.owner.id : null;
        this.deviceIds = (existing.devices || []).map(x => x.id);
        this.pinAction = existing.pinAction;
        this.zigbeeConfiguration = existing.zigbeeConfiguration;
    }

    id: number;
}

export interface ICountFilter {
    measuringPointIdInt: number;
    countForwardDirection: boolean;
    countReverseDirection: boolean;
}

export interface IBikeCounterDisplay {
    text: string;
    initialText: string;
    maxLines: number;
    maxCharactersPerLine: number;
    filters: ICountFilter[];
}

