import { Component, Input } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { ExternalInputType, IExternalInput } from "src/app/models/external-input-configuration";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-external-input-to-gpio-link",
    templateUrl: "./device-link-external-input-to-gpio-link.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkExternalInputToGpioLinkComponent extends DeviceLinkFormGroupComponent {
    @Input() measuringPointId: number;

    externalInputGpioPinForms: SigncoFormArray;
    showExternalInputManualWarning: boolean;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly fgd: FormGroupDirective
    ) {
        super();
        this.externalInputGpioPinForms = this.formBuilder.array([]) as SigncoFormArray;
    }

    configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (analyzerConfiguration?.externalInputToGpioLink) {
                this.measuringPointApi.get$(this.measuringPointId, null, null, false).subscribe((mp) => {
                    if (this.fgd.form.get("externalInputToGpioLink")) this.fgd.form.removeControl("externalInputToGpioLink");
                    this.externalInputGpioPinForms = this.formBuilder.array([]) as SigncoFormArray;

                    if (mp.externalInputConfiguration.type === ExternalInputType.Device) {
                        this.addInputs(mp.externalInputConfiguration.inputs);
                    } else {
                        this.showExternalInputManualWarning = true;
                    }

                    this.form = this.formBuilder.group({
                        simulationInterval: null,
                        pins: this.externalInputGpioPinForms
                    }) as SigncoFormGroup;
                    this.form.patchValue(analyzerConfiguration.externalInputToGpioLink);

                    this.fgd.form.addControl("externalInputToGpioLink", this.form);
                    resolve(true);
                });
                return;
            }

            if (this.fgd.form.get("externalInputToGpioLink")) this.fgd.form.removeControl("externalInputToGpioLink");
            this.form = null;
            resolve(false);
        });
    }

    private addInputs(inputs: IExternalInput[]) {
        if (!inputs || !inputs.length) {
            this.externalInputGpioPinForms.clear();
            return;
        }

        for (const input of inputs) {
            const existing = this.externalInputGpioPinForms.controls.find(x => x.get("inputId").value === input.id);
            if (existing) continue;

            this.externalInputGpioPinForms.push(this.formBuilder.group({
                inputId: input.id,
                inputLabel: input.id + (input.description ? ` - ${input.description}` : ""),
                pinId: [null, Validators.required],
                inverse: false
            }));
        }
    }

}
