import { NgModule } from "@angular/core";
import { ProjectTreeListComponent } from "src/app/ui/pages/administration/components/project-treelist/project-treelist.component";

import { SharedModule } from "../shared/shared.module";
import { MeasuringPointValidationComponent } from "./components/measuring-point-validation/measuring-point-validation.component";
import { ValidationComponent } from "./components/validation/validation.component";
import { MeasuringPointExpandComponent } from "./components/measuring-point-expand/measuring-point-expand.component";
import { MapBasicModule } from "../map-basic/map-basic.module";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";

@NgModule({
    imports: [
        SharedModule,
        MapBasicModule,
        MapAdvancedModule,
        ProjectTreeListComponent,
    ],
    declarations: [
        MeasuringPointValidationComponent,
        ValidationComponent,
        MeasuringPointExpandComponent,
    ]
})
export class ValidationModule { }
