import { IServiceModelBase } from "./servicemodelbase";

export interface IBikeParameterSet extends IServiceModelBase {
    code: string;
    description: string;
    parameters: IBikeParameterSetParameter;
}

export interface IBikeParameterSetParameter {
    minLengthChildrensBike: number;
    minLengthYouthBike: number;
    minLengthAdultBike: number;
    minLengthMoped: number;
    minLengthExtendedBike: number;
    maxLength: number;
    minSpeed: number;
    maxSpeedChildrensBike: number;
    maxSpeedYouthBike: number;
    maxSpeedAdultBike: number;
    maxSpeedExtendedBike: number;
    maxSpeed: number;
    speedMargin: number;
    speedMarginToReclassify: number;
    lengthMargin: number;
    lengthMarginToReclassify: number;
    minSpeedWheely: number;
    maxSpeedWheely: number;
}

export class BikeParameterSetCreator {
    code: string;
    description: string;
    parameters: BikeParametersCreator;
}

export class BikeParametersCreator {
    minLengthChildrensBike: number;
    minLengthYouthBike: number;
    minLengthAdultBike: number;
    minLengthMoped: number;
    maxLength: number;
    minSpeed: number;
    maxSpeedChildrensBike: number;
    maxSpeedYouthBike: number;
    maxSpeedAdultBike: number;
    maxSpeed: number;
    speedMargin: number;
    speedMarginToReclassify: number;
    lengthMargin: number;
    lengthMarginToReclassify: number;
    minSpeedWheely: number;
    maxSpeedWheely: number;
}