<form *ngIf="modeConfigurationForm"
      [formGroup]="modeConfigurationForm"
      class="m-border-top m-overflow-auto"
      autocomplete="off">

    <div class="p-16">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': modeConfigurationForm.get('defaultModeId').showErrors }">

            <label class="col-3"
                   for="code">
                {{"modeConfiguration.defaultModeId" | translate}}
            </label>

            <div class="col-9">
                <p-dropdown id="defaultModeId"
                            formControlName="defaultModeId"
                            [options]="modeOptions"
                            [style]="{'width':'240px'}"
                            placeholder="{{'modeConfiguration.defaultModeId' | translate}}"
                            [autoDisplayFirst]="false"
                            #defaultModeIdInput>
                </p-dropdown>

                <app-form-validation [control]="modeConfigurationForm.get('defaultModeId')"
                                     [input]="defaultModeIdInput">
                </app-form-validation>
            </div>
        </div>
    </div>

    <div class="flex align-center px-16 py-8 m-border-top m-border-top">
        <h3>{{"modeConfiguration.modes" | translate}}</h3>

        <app-icon-button id="add_mode_button"
                         icon="add"
                         class="ml-8"
                         (click)="addMode()">
        </app-icon-button>
    </div>

    <ng-container *ngIf="modeForms"
                  formGroupName="modes">
        <div id="modes_grid">
            <!-- <h4></h4>
                <h4>{{"modeConfiguration.modeId" | translate}}</h4>
                <h4>{{"general.description" | translate}}</h4>
                <h4 *ngIf="useIcon">{{"modeConfiguration.icon" | translate}}</h4> -->
            <ng-container *ngFor="let modeForm of modeForms.controls; let modeIndex = index"
                          formGroupName="{{modeIndex}}">

                <div class="px-16 py-8 flex align-center m-border-bottom"
                     [ngClass]="{'m-border-top': modeIndex == 0}">
                    <div class="cell">
                        <app-icon-button id="removeMode{{modeIndex}}"
                                         icon="delete"
                                         (click)="deleteMode(modeIndex)">
                        </app-icon-button>
                    </div>

                    <div class="cell px-8">
                        <input id="modeId{{modeIndex}}"
                               class="p-inputtext"
                               style="width:50px;"
                               type="text"
                               formControlName="id"
                               (change)="updateModeOptions()"
                               appAutofocus
                               #idInput />

                        <app-form-validation [control]="modeForm.get('id')"
                                             [input]="idInput">
                        </app-form-validation>
                    </div>

                    <div class="cell px-8 flex flex-column">
                        <input id="modeDescription{{modeIndex}}"
                               class="p-inputtext"
                               type="text"
                               formControlName="description"
                               #descriptionInput />

                        <app-form-validation [control]="modeForm.get('description')"
                                             [input]="descriptionInput">
                        </app-form-validation>
                    </div>

                    <div class="cell">
                        <div style="display:inline-grid; grid-template-columns: repeat(3, auto); align-items: center;">
                            <!-- preview -->
                            <div *ngIf="uploadFilePreview[modeIndex]">
                                <img [attr.src]="uploadFilePreview[modeIndex] | safeHtml"
                                     style="max-width:50px;" />
                            </div>

                            <app-icon-button *ngIf="uploadFilePreview[modeIndex]"
                                             icon="delete"
                                             (click)="setPhotoDeleted(modeIndex)">
                            </app-icon-button>

                            <label for="fileInput{{modeIndex}}"
                                   class="m-input-file">
                                <app-svg-icon icon="upload"></app-svg-icon>

                                <input type="file"
                                       id="fileInput{{modeIndex}}"
                                       accept="image/*"
                                       #file
                                       (change)="setFile(file, modeIndex)" />
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>

<div *ngIf="modeConfigurationForm"
     class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">

    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>