<app-page>
    <ng-container #header header>
        <app-page-header/>
    </ng-container>
    <ng-container #main main>
        <app-page-body/>
    </ng-container>
</app-page>

<app-cookie-notification></app-cookie-notification>
<app-progress></app-progress>
<app-general-dialog></app-general-dialog>
<app-galleria></app-galleria>
