<div class="m-page-content-wrapper p-16">
    <div class="row"
         *ngIf="!location">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="row m-fixed-height"
         [hidden]="!location">
        <div class="col-3"
             style="height:100%; display:grid; grid-template-rows: fit-content(75%) minmax(200px, 1fr); gap:16px;">
            <div class="m-card"
                 style="position: relative; min-height: 0; overflow-y: auto;">
                <a (click)="toMap()"
                   class="m-btn m-btn-back m-btn-back-map"
                   title="Back"
                   id="back_to_map_button">
                    <app-svg-icon icon="chevron-left"
                                  class="mr-4"></app-svg-icon>
                    {{"general.returnToMap" | translate}}
                </a>

                <app-location-detail *ngIf="location"
                                     [location]="location"
                                     [device]="device"
                                     [editCommand]="false"
                                     class="m-layout-area-body m-layout-w-actions-top"
                                     (save)="handleLocationEdit($event)"></app-location-detail>
            </div>

            <div class="m-card"
                 style="min-height: 0; display:grid; grid-template-rows: auto 1fr auto;">
                <div class="flex align-center p-16 m-border-bottom">
                    <div class="mr-16">
                        <h3>{{"devices.title" | translate}}</h3>
                    </div>

                    <label class="m-input-checkbox-block ml-auto">
                        <input type="checkbox"
                               [(ngModel)]="filterOnLocation"
                               (change)="setFilterLocation()"
                               id="filter_on_location" />
                        {{"manageDevice.filterOnLocation" | translate}}
                    </label>
                </div>

                <app-devices style="min-height: 0;"
                             [locationId]="filterLocation?.id"
                             [requiresInput]="filterOnLocation"
                             [headers]="false"
                             [paginator]="false"
                             [footer]="false"
                             [mini]="true"
                             [deleteCommand]="true"
                             [showImageColumn]="true"
                             componentStyle="m-card-body-table-content"
                             (selected)="handleOnDeviceSelect($event)"
                             (dataSet)="onDataSet()"></app-devices>

                <div class="m-btn-group m-btn-group-right m-border-top">
                    <button class="m-btn m-btn-secondary"
                            (click)="createNewDevice()"
                            [disabled]="!device?.id"
                            *ngIf="canCreateDevice && device"
                            id="create_device_button">
                        <app-svg-icon icon="add">
                        </app-svg-icon>
                        {{"manageDevice.create" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height">
            <app-device-detail-container class="m-fixed-height"
                                         [device]="device"
                                         (save)="handleOnDeviceSaved($event)"></app-device-detail-container>
            <div *ngIf="loading">
                <app-loading-spinner>
                </app-loading-spinner>
            </div>
        </div>
    </div>
</div>