export interface IExternalInputConfiguration {
    type: ExternalInputType;
    inputs: IExternalInput[];
}

export enum ExternalInputType {
    Device = "device",
    Manual = "manual"
}

export interface IExternalInput {
    id: string;
    description: string;
    trueIconUrl?: string;
    trueIconPreview?: string;
    falseIconUrl?: string;
    falseIconPreview?: string;
}
