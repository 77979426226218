<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingDrivingLane ? "drivingLanes.edit" : "drivingLanes.create") | translate}}
    </p-header>

    <form *ngIf="manageDrivingLaneForm"
          class="p-16"
          [formGroup]="manageDrivingLaneForm"
          autocomplete="off"
          novalidate>

        <div class="mt-16 mb-16"
             *ngIf="manageDrivingLaneForm.get('codeStringResource')">
            <app-model-translation-form [parentFormGroup]="manageDrivingLaneForm"
                                        formGroupName="codeStringResource"
                                        controlLabel="general.code"
                                        [multiline]="false"></app-model-translation-form>
        </div>

        <hr />

        <div class="m-form-group row mt-8"
             [ngClass]="{ 'has-error': manageDrivingLaneForm.get('description').showErrors }">

            <label class="col-4"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-8">
                <textarea rows="3"
                          placeholder="{{'general.description' | translate}}"
                          id="description"
                          class="p-inputtextarea"
                          formControlName="description"
                          #descriptionInput></textarea>

                <app-form-validation [control]="manageDrivingLaneForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>