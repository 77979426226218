import { ILocation } from "src/app/models/location";
import { IOrganization } from "src/app/models/user";
import { ILocationSummary } from "src/app/models/web";
import { MapUtils } from "src/app/utilities";

// The data that is bound to a marker. Can either be a location, or a locationSummary, or an organization
export class MarkerContext {
    public static fromLocation(location: ILocation) {
        const result = new MarkerContext(null, location);
        return result;
    }

    public static fromLocationSummary(locationSummary: ILocationSummary) {
        const result = new MarkerContext(locationSummary, null);
        return result;
    }

    public static fromOrganization(organization: IOrganization) {
        const result = new MarkerContext(null, organization.location);
        result.organization = organization;
        return result;
    }

    private constructor(
        locationSummary: ILocationSummary,
        location: ILocation) {
        if (!location && !locationSummary) throw { message: "You have to specify either location or locationSummary" };
        if (location && locationSummary) throw { message: "You have to specify either location or locationSummary" };

        this.locationSummary = locationSummary;
        this.location = location;
    }

    locationSummary?: ILocationSummary;
    location?: ILocation;
    organization?: IOrganization;

    public get id(): number {
        if (this.locationSummary) return this.locationSummary.id;
        if (this.location) return this.location.id;
    }

    public get code(): string {
        if (this.locationSummary) return this.locationSummary.code;
        if (this.location) return this.location.code;
    }

    public get latLng(): google.maps.LatLng {
        if (this.locationSummary) return MapUtils.toLatLng(this.locationSummary);
        if (this.location) return MapUtils.toLatLng(this.location.coordinate);
    };

    public updateCoordinate(lat: number, lng: number) {
        if (this.locationSummary) {
            this.locationSummary.lat = lat;
            this.locationSummary.lng = lng;
        }
        if (this.location) {
            this.location.coordinate.latitude = lat;
            this.location.coordinate.longitude = lng;
        }
    }

    public updateCode(code: string) {
        if (this.locationSummary) this.locationSummary.code = code;
        if (this.location) this.location.code = code;
    }

    public toDebugString(): string {
        if (this.locationSummary) return `locationSummary [${this.locationSummary.code}] (${this.locationSummary.id})`;
        if (this.location) return `location [${this.location.code}] (${this.location.id})`;
        if (this.organization) return `organization [${this.organization.name}] (${this.organization.id})`;
        return "unknown";

    }
}