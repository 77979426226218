import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-in-progress-note",
    templateUrl: "./in-progress-note.component.html",
    styleUrls: ["./in-progress-note.component.css"]
})
// The temporary component that will be removed once the module is fully implemented.
export class InProgressNoteComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
