import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { catchError, filter, forkJoin, of } from "rxjs";
import { Observable } from "rxjs-compat";
import { IKnowledgeBaseElement } from "src/app/models/article";
import { DocumentationApiService } from "src/app/resource/documentation/documentation-api";
import { NavigationService } from "src/app/services/navigation.service";
import { SubscriptionManager } from "src/app/utilities";

@Component({
    selector: "app-article-container",
    templateUrl: "./article-container.component.html",
})
export class ArticleContainerComponent implements OnInit, OnDestroy {
    searchName: string;
    loading = false;
    content: string;

    @ViewChild(Tree) tree: Tree;
    selectedNode: TreeNode;
    nodes: TreeNode[];
    loadingTree: boolean;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly router: Router,
        private readonly articleApi: DocumentationApiService,
        private readonly navigationService: NavigationService,
        readonly translateService: TranslateService) {
        const langChangeSubscription = translateService.onLangChange.subscribe(() => {
            this.init(decodeURI(this.router.url));
        });
        this.subscriptionManager.add("langChangeSubscription", langChangeSubscription);
    }

    ngOnInit(): void {
        this.init(decodeURI(this.router.url));

        const subscriptionNavigationEnd = this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((x => {
            const articlePath = this.extractArticlePathFromRoute(decodeURI(this.router.url));

            this.loading = true;
            this.loadArticleFromServer$(articlePath).subscribe({
                next: (result) => {
                    this.content = result;
                    this.selectArticle(articlePath);
                    this.loading = false;
                },
                error: (error) => {
                    this.loading = false;
                    this.content = "Article not found.";
                }
            });
        }));

        this.subscriptionManager.add("documentationNavigationEndSubscrption", subscriptionNavigationEnd);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    private extractArticlePathFromRoute(routeUrl: string): string {
        let parts = routeUrl.split("/");
        parts = parts.filter((value, index) => index > 1);
        parts = parts.map(x => x.toPascalCase());
        return parts.join("/");
    }

    private init(routeUrl: string) {
        const articlePath = this.extractArticlePathFromRoute(routeUrl);

        this.loadingTree = true;
        this.loading = true;
        forkJoin([this.loadTreeStructure$(), this.loadArticleFromServer$(articlePath)])
            .pipe(catchError((error) => {
                return of([[] as IKnowledgeBaseElement[], "Article not found."]);
            }))
            .subscribe((values) => {
                this.loading = false;
                this.loadingTree = false;

                const treeElements = values[0] as IKnowledgeBaseElement[];
                const content = values[1] as any;

                this.content = content;
                this.nodes = treeElements.map(x => this.mapToTreeNode(x));
                this.selectArticle(articlePath);
            });
    }

    private loadArticleFromServer$(articlePath: string): Observable<any> {
        return this.articleApi.get$(articlePath);
    }

    public hrefClicked($event: MouseEvent) {
        // When the user clicks a link, and that link is to another article, we intercept it and navigate to correct route
        const srcElement = $event.target as Element;
        let targetElement;

        if ((srcElement.nodeName.toUpperCase() === "A")) {
            targetElement = srcElement;
        } else if (srcElement.parentElement.nodeName.toUpperCase() === "A") {
            targetElement = srcElement.parentElement;
        } else {
            return;
        }

        const href = targetElement.getAttribute("href");
        const prefix = "/Documentation/";
        if (href.startsWith(prefix)) {
            // This is a link to another article.
            // We make sure to navigate to correct route
            const article = href.slice(prefix.length) as string;

            $event.stop();

            const articleParts = article.split("/").map(x => x.toCamelCase());
            this.router.navigate(["documentation", ...articleParts]);
        }
    }

    /// TREE helpers
    private loadTreeStructure$(): Observable<IKnowledgeBaseElement[]> {
        return this.articleApi.getTree$();
    }

    private mapToTreeNode(knowledgeBaseElement: IKnowledgeBaseElement): TreeNode {
        return {
            key: knowledgeBaseElement.id,
            label: knowledgeBaseElement.name,
            data: knowledgeBaseElement,
            leaf: knowledgeBaseElement.isFile,
            expanded: knowledgeBaseElement.isFile ? false : true,
            expandedIcon: !knowledgeBaseElement.isFile ? "pi pi-folder-open" : "pi pi-file",
            collapsedIcon: !knowledgeBaseElement.isFile ? "pi pi-folder" : "pi pi-file",
            children: knowledgeBaseElement.children && knowledgeBaseElement.children.length > 0 ? knowledgeBaseElement.children.map(x => this.mapToTreeNode(x)) : null
        } as TreeNode;
    }


    /**
     * Trying to select node in tree by articlePath
     * Only leaf nodes can be selected
     * @param articlePath path to article
     */
    private selectArticle(articlePath: string) {
        if (!this.nodes) return;

        for (const node of this.nodes) {
            const result = this.searchByArticlePath(node, articlePath);
            if (result) {
                this.selectedNode = result;
                return;
            }
        }
    }

    /**
     * Recursively searching current node to try to find correct article
     * @param node Node to be searched recursively
     * @param articlePath Path searched
     * @returns result or null if current node in tree does not contain searched article path
     */
    private searchByArticlePath(node: TreeNode, articlePath: string): TreeNode | null {
        const nodeData = node.data as IKnowledgeBaseElement;

        const articlePathComparison = (data: IKnowledgeBaseElement): TreeNode | null => {
            if (data.articlePath?.toUpperCase() === articlePath.toUpperCase()) {
                return node;
            }
        };

        let result = articlePathComparison(nodeData);
        if (result) return result;

        if (node.children) {
            for (const children of node.children) {
                result = this.searchByArticlePath(children, articlePath);
                if (result) return result;
            }
        }

        return null;
    }

    async handleNodeSelect(node: TreeNode) {
        const nodeData = node.data as IKnowledgeBaseElement;
        if (!nodeData.isFile) return;

        return this.navigationService.toArticle(nodeData.articlePath);
    }
}