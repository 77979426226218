<ng-container *ngIf="creatingOrEditing">
    <div class="m-card p-16 m-shadow"
         id="editGroupForm">
        <div class="flex align-center mb-16">
            <div class="mr-16"
                 (click)="toggleExpanded()">
                <h3>
                    <ng-container *ngIf="!isCreatingNew; else createGroupTitle">
                        {{"manageGroup.edit" | translate}} {{mapDataService.editGroup?.code}}
                    </ng-container>

                    <ng-template #createGroupTitle>
                        {{"manageGroup.create" | translate}}
                    </ng-template>
                </h3>
            </div>

            <div class="ml-auto">
                <a (click)="toggleExpanded()">
                    <app-svg-icon id="manage_group_expand"
                                  [icon]="expanded ? 'triangle-up' : 'triangle-down'">
                    </app-svg-icon>
                </a>
            </div>
        </div>

        <form [formGroup]="groupForm"
              class="p-16"
              autocomplete="off"
              novalidate>

            <div *ngIf="expanded">

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': groupForm.get('color').showErrors}">
                    <label class="col-4">
                        {{"general.color" | translate}}
                    </label>

                    <div class="col-8">
                        <p-colorPicker formControlName="color"
                                       [style]="{'width': '100%'}"
                                       (onChange)="setColor($event.value)">
                        </p-colorPicker>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="groupForm.get('ownerId')"
                     [ngClass]="{ 'has-error': groupForm.get('ownerId').showErrors }">

                    <label class="col-4"
                           for="ownerId">
                        {{"general.organization" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown formControlName="ownerId"
                                    id="ownerId"
                                    [panelStyle]="{'width':'100%'}"
                                    [options]="organizations"
                                    [autoDisplayFirst]="false"
                                    placeholder="{{'general.organization' | translate}}"
                                    [filter]="true"
                                    [resetFilterOnHide]="true"
                                    #ownerIdInput>
                        </p-dropdown>

                        <app-form-validation [control]="groupForm.get('ownerId')"
                                             [input]="ownerIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': groupForm.get('code').showErrors}">
                    <label class="col-4"
                           for="code">
                        {{"general.name" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               id="code"
                               class="p-inputtext"
                               placeholder="{{'general.code' | translate}}"
                               formControlName="code"
                               #groupCodeInput />

                        <app-form-validation [control]="groupForm.get('code')"
                                             [input]="groupCodeInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': groupForm.get('description').showErrors}">
                    <label class="col-4"
                           for="description">
                        {{"general.description" | translate}}
                    </label>

                    <div class="col-8">
                        <textarea rows="1"
                                  id="description"
                                  class="p-inputtextarea"
                                  placeholder="{{'general.description' | translate}}"
                                  formControlName="description"
                                  #groupDescriptionInput></textarea>

                        <app-form-validation [control]="groupForm.get('description')"
                                             [input]="groupDescriptionInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="m-btn-group m-btn-group-right mt-16">

                <button *ngIf="!isCreatingNew"
                        class="m-btn m-btn-secondary"
                        type="button"
                        [disabled]="submitting"
                        (click)="duplicate()">
                    {{"general.duplicate" | translate}}
                </button>

                <button class="m-btn m-btn-secondary"
                        type="button"
                        [disabled]="submitting"
                        (click)="cancel()"
                        id="cancelButton">
                    {{"form.cancel" | translate}}
                </button>

                <button class="m-btn m-btn-primary"
                        type="submit"
                        (click)="submit()"
                        [disabled]="submitting"
                        id="saveButton">
                    <app-loading-spinner *ngIf="submitting">
                    </app-loading-spinner>
                    {{"form.save" | translate}}
                </button>
            </div>
        </form>
    </div>
</ng-container>
