<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="bikeXPosConfiguration">
    <div class="p-16 m-border-top m-border-bottom">
        <h3>{{"uploadDetailsDialog.tubeXPosConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row">
            <label class="col-2"
                   for="devicePosition">
                {{"uploadDetailsDialog.devicePosition"| translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('devicePosition').showErrors }">
                <p-dropdown [options]="devicePositions"
                            id="devicePosition"
                            [formControl]="form.get('devicePosition')"
                            #devicePositionInput
                            [panelStyle]="{'width':'100%'}"
                            (change)="onBikeXPosDetailsChanged()"></p-dropdown>

                <app-form-validation [control]="form.get('devicePosition')"
                                     [input]="devicePositionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row">
            <label class="col-2"
                   for="tube3">
                {{"uploadDetailsDialog.connectionTube3"| translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3').showErrors }">
                <p-dropdown [options]="tubeOptionsWithEmpty"
                            id="tube3"
                            [formControl]="form.get('tube3')"
                            #tube3Input
                            [panelStyle]="{'width':'100%'}"
                            (change)="onBikeXPosDetailsChanged()"></p-dropdown>

                <app-form-validation [control]="form.get('tube3')"
                                     [input]="tube3Input">
                </app-form-validation>
            </div>

            <label class="col-2"
                   for="tube3OffsetInMilliseconds">
                {{"uploadDetailsDialog.tubeOffsetInMilliseconds" | translate}}

                <app-icon-button icon="info"
                                 text="{{'deviceUpload.tubeDifference' | translate}}"
                                 (click)="onShowOffsetPopup(3)">
                </app-icon-button>
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3OffsetInMilliseconds').showErrors }">
                <div class="m-input-group">
                    <input name="tube3OffsetInMilliseconds"
                           class="p-inputtext"
                           step="0.1"
                           type="number"
                           [max]="1000"
                           [formControl]="form.get('tube3OffsetInMilliseconds')"
                           id="tube3OffsetInMilliseconds"
                           #tube3OffsetInMillisecondsInput>
                    <div class="m-input-group-addon">{{"measurements.ms" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube3OffsetInMilliseconds')"
                                     [input]="tube3OffsetInMilliseconds">
                </app-form-validation>
            </div>

        </div>

        <div class="m-form-group row">

            <label class="col-2"
                   for="drivingLaneWidthCm">
                {{"uploadDetailsDialog.drivingLaneWidthCm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('drivingLaneWidthCm').showErrors }">
                <div class="m-input-group">
                    <input name="drivingLaneWidthCm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('drivingLaneWidthCm')"
                           id="drivingLaneWidthCm"
                           #drivingLaneWidthCmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('drivingLaneWidthCm')"
                                     [input]="drivingLaneWidthCmInput">
                </app-form-validation>
            </div>

            <label class="col-2"
                   for="tube2LengthCm">
                {{"uploadDetailsDialog.tube2LengthCm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube2LengthCm').showErrors }">
                <div class="m-input-group">
                    <input name="tube3tube2LengthCmXOffsetCm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('tube2LengthCm')"
                           id="tube2LengthCm"
                           #tube2LengthCmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube2LengthCm')"
                                     [input]="tube2LengthCmInput">
                </app-form-validation>
            </div>

        </div>

        <div class="m-form-group row">

            <label class="col-2"
                   for="tube3LengthCm">
                {{"uploadDetailsDialog.tube3LengthCm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3LengthCm').showErrors }">
                <div class="m-input-group">
                    <input name="tube3LengthCm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('tube3LengthCm')"
                           id="tube3LengthCm"
                           #tube3LengthCmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube3LengthCm')"
                                     [input]="tube3LengthCmInput">
                </app-form-validation>
            </div>

            <label class="col-2"
                   for="tube3XOffsetCm">
                {{"uploadDetailsDialog.tube3XOffsetCm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3XOffsetCm').showErrors }">
                <div class="m-input-group">
                    <input name="tube3XOffsetCm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('tube3XOffsetCm')"
                           id="tube3XOffsetCm"
                           #tube3XOffsetCmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube3XOffsetCm')"
                                     [input]="tube3XOffsetCmInput">
                </app-form-validation>
            </div>

        </div>

        <div class="m-form-group row">

            <label class="col-2"
                   for="tube3YOffset1Cm">
                {{"uploadDetailsDialog.tube3YOffset1Cm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3YOffset1Cm').showErrors }">
                <div class="m-input-group">
                    <input name="tube3YOffset1Cm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('tube3YOffset1Cm')"
                           id="tube3YOffset1Cm"
                           #tube3YOffset1CmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube3YOffset1Cm')"
                                     [input]="tube3YOffset1CmInput">
                </app-form-validation>
            </div>

            <label class="col-2"
                   for="tube3YOffset2Cm">
                {{"uploadDetailsDialog.tube3YOffset2Cm" | translate}}
            </label>

            <div class="col-4"
                 [ngClass]="{ 'has-error': form.get('tube3YOffset2Cm').showErrors }">
                <div class="m-input-group">
                    <input name="tube3YOffset2Cm"
                           class="p-inputtext"
                           type="number"
                           [formControl]="form.get('tube3YOffset2Cm')"
                           id="tube3YOffset2Cm"
                           #tube3YOffset2CmInput
                           (change)="onBikeXPosDetailsChanged()">
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="form.get('tube3YOffset2Cm')"
                                     [input]="tube3YOffset2CmInput">
                </app-form-validation>
            </div>

        </div>
        <svg width="784"
             height="840"
             viewBox="0 0 784 840"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             *ngIf="form.get('devicePosition').value == 'right'"
             style="width:50%;">
            <g clip-path="url(#clip0_605_220)">
                <path d="M784 0H0V840H784V0Z"
                      fill="white" />
                <path d="M32 20H20V820H32V20Z"
                      fill="#666666" />
                <path d="M764 20H752V820H764V20Z"
                      fill="#666666" />
                <path d="M96 99.5C104.56 99.5 111.5 92.5604 111.5 84C111.5 75.4396 104.56 68.5 96 68.5C87.4396 68.5 80.5 75.4396 80.5 84C80.5 92.5604 87.4396 99.5 96 99.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M688 739.5C696.56 739.5 703.5 732.56 703.5 724C703.5 715.44 696.56 708.5 688 708.5C679.44 708.5 672.5 715.44 672.5 724C672.5 732.56 679.44 739.5 688 739.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M96 739.5C104.56 739.5 111.5 732.56 111.5 724C111.5 715.44 104.56 708.5 96 708.5C87.4396 708.5 80.5 715.44 80.5 724C80.5 732.56 87.4396 739.5 96 739.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M688 675.5C696.56 675.5 703.5 668.56 703.5 660C703.5 651.44 696.56 644.5 688 644.5C679.44 644.5 672.5 651.44 672.5 660C672.5 668.56 679.44 675.5 688 675.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M96 675.5C104.56 675.5 111.5 668.56 111.5 660C111.5 651.44 104.56 644.5 96 644.5C87.4396 644.5 80.5 651.44 80.5 660C80.5 668.56 87.4396 675.5 96 675.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M688 611.5C696.56 611.5 703.5 604.56 703.5 596C703.5 587.44 696.56 580.5 688 580.5C679.44 580.5 672.5 587.44 672.5 596C672.5 604.56 679.44 611.5 688 611.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M111.5 659.984V660.016C111.5 660.005 111.5 659.995 111.5 659.984ZM669.793 661.5H671.207L665.989 666.717L665.989 666.718C665.794 666.913 665.478 666.913 665.283 666.718L665.282 666.717C665.087 666.522 665.087 666.206 665.282 666.011L665.283 666.011L669.793 661.5ZM114.621 659.5H669.379L669.879 660L669.379 660.5H114.621L114.121 660L114.621 659.5ZM112.793 661.5H114.207L118.717 666.011L118.718 666.011C118.913 666.206 118.913 666.522 118.718 666.717L118.717 666.718C118.522 666.913 118.206 666.913 118.011 666.718L118.011 666.717L112.793 661.5ZM113.207 660.5H112.5V659.5H113.207L113.06 659.646L112.707 660L113.06 660.354L113.207 660.5ZM114.207 658.5H112.793L118.011 653.283L118.011 653.282C118.206 653.087 118.522 653.087 118.717 653.282L118.718 653.283C118.913 653.478 118.913 653.794 118.718 653.989L118.717 653.989L114.207 658.5ZM670.94 659.646L670.793 659.5H671.5V660.5H670.793L670.94 660.354L671.293 660L670.94 659.646ZM665.989 653.283L671.207 658.5H669.793L665.283 653.989L665.282 653.989C665.087 653.794 665.087 653.478 665.282 653.283L665.283 653.282C665.478 653.087 665.794 653.087 665.989 653.282L665.989 653.283Z"
                      fill="#333333"
                      stroke="black" />
                <path d="M703.293 595.293C702.902 595.683 702.902 596.317 703.293 596.707L709.657 603.071C710.047 603.462 710.681 603.462 711.071 603.071C711.462 602.681 711.462 602.047 711.071 601.657L705.414 596L711.071 590.343C711.462 589.953 711.462 589.319 711.071 588.929C710.681 588.538 710.047 588.538 709.657 588.929L703.293 595.293ZM752.707 596.707C753.098 596.317 753.098 595.683 752.707 595.293L746.343 588.929C745.953 588.538 745.319 588.538 744.929 588.929C744.538 589.319 744.538 589.953 744.929 590.343L750.586 596L744.929 601.657C744.538 602.047 744.538 602.681 744.929 603.071C745.319 603.462 745.953 603.462 746.343 603.071L752.707 596.707ZM704 597H752V595H704V597Z"
                      fill="#333333" />
                <path d="M111.293 723.293C110.902 723.683 110.902 724.317 111.293 724.707L117.657 731.071C118.047 731.462 118.681 731.462 119.071 731.071C119.462 730.681 119.462 730.047 119.071 729.657L113.414 724L119.071 718.343C119.462 717.953 119.462 717.319 119.071 716.929C118.681 716.538 118.047 716.538 117.657 716.929L111.293 723.293ZM672.707 724.707C673.098 724.317 673.098 723.683 672.707 723.293L666.343 716.929C665.953 716.538 665.319 716.538 664.929 716.929C664.538 717.319 664.538 717.953 664.929 718.343L670.586 724L664.929 729.657C664.538 730.047 664.538 730.681 664.929 731.071C665.319 731.462 665.953 731.462 666.343 731.071L672.707 724.707ZM112 725H672V723H112V725Z"
                      fill="#333333" />
                <path d="M31.2929 787.293C30.9024 787.683 30.9024 788.317 31.2929 788.707L37.6569 795.071C38.0474 795.462 38.6805 795.462 39.0711 795.071C39.4616 794.681 39.4616 794.047 39.0711 793.657L33.4142 788L39.0711 782.343C39.4616 781.953 39.4616 781.319 39.0711 780.929C38.6805 780.538 38.0474 780.538 37.6569 780.929L31.2929 787.293ZM752.707 788.707C753.098 788.317 753.098 787.683 752.707 787.293L746.343 780.929C745.953 780.538 745.319 780.538 744.929 780.929C744.538 781.319 744.538 781.953 744.929 782.343L750.586 788L744.929 793.657C744.538 794.047 744.538 794.681 744.929 795.071C745.319 795.462 745.953 795.462 746.343 795.071L752.707 788.707ZM32 789H752V787H32V789Z"
                      fill="#333333" />
                <path d="M96.7071 100.293C96.3166 99.9024 95.6834 99.9024 95.2929 100.293L88.9289 106.657C88.5384 107.047 88.5384 107.681 88.9289 108.071C89.3195 108.462 89.9526 108.462 90.3431 108.071L96 102.414L101.657 108.071C102.047 108.462 102.681 108.462 103.071 108.071C103.462 107.681 103.462 107.047 103.071 106.657L96.7071 100.293ZM95.2929 644.707C95.6834 645.098 96.3166 645.098 96.7071 644.707L103.071 638.343C103.462 637.953 103.462 637.319 103.071 636.929C102.681 636.538 102.047 636.538 101.657 636.929L96 642.586L90.3431 636.929C89.9526 636.538 89.3194 636.538 88.9289 636.929C88.5384 637.319 88.5384 637.953 88.9289 638.343L95.2929 644.707ZM95 101V644H97V101H95Z"
                      fill="black" />
                <path d="M688.707 675.293C688.317 674.902 687.683 674.902 687.293 675.293L680.929 681.657C680.538 682.047 680.538 682.681 680.929 683.071C681.319 683.462 681.953 683.462 682.343 683.071L688 677.414L693.657 683.071C694.047 683.462 694.681 683.462 695.071 683.071C695.462 682.681 695.462 682.047 695.071 681.657L688.707 675.293ZM687.293 708.707C687.683 709.098 688.317 709.098 688.707 708.707L695.071 702.343C695.462 701.953 695.462 701.319 695.071 700.929C694.681 700.538 694.047 700.538 693.657 700.929L688 706.586L682.343 700.929C681.953 700.538 681.319 700.538 680.929 700.929C680.538 701.319 680.538 701.953 680.929 702.343L687.293 708.707ZM687 676V708H689V676H687Z"
                      fill="black" />
                <path d="M688.707 611.293C688.317 610.902 687.683 610.902 687.293 611.293L680.929 617.657C680.538 618.047 680.538 618.681 680.929 619.071C681.319 619.462 681.953 619.462 682.343 619.071L688 613.414L693.657 619.071C694.047 619.462 694.681 619.462 695.071 619.071C695.462 618.681 695.462 618.047 695.071 617.657L688.707 611.293ZM687.293 644.707C687.683 645.098 688.317 645.098 688.707 644.707L695.071 638.343C695.462 637.953 695.462 637.319 695.071 636.929C694.681 636.538 694.047 636.538 693.657 636.929L688 642.586L682.343 636.929C681.953 636.538 681.319 636.538 680.929 636.929C680.538 637.319 680.538 637.953 680.929 638.343L687.293 644.707ZM687 612V644H689V612H687Z"
                      fill="black" />
                <path d="M110.932 90.7547C110.381 90.792 109.965 91.269 110.002 91.82L110.611 100.799C110.648 101.35 111.125 101.767 111.676 101.73C112.227 101.692 112.643 101.215 112.606 100.664L112.065 92.6825L120.047 92.1417C120.598 92.1044 121.014 91.6274 120.977 91.0764C120.94 90.5254 120.463 90.1089 119.912 90.1463L110.932 90.7547ZM676.374 586.309C676.925 586.271 677.342 585.794 677.304 585.243L676.696 576.264C676.658 575.713 676.181 575.297 675.63 575.334C675.079 575.371 674.663 575.848 674.7 576.399L675.241 584.381L667.259 584.922C666.708 584.959 666.292 585.436 666.329 585.987C666.367 586.538 666.844 586.955 667.395 586.917L676.374 586.309ZM110.342 92.5057L675.649 586.064L676.964 584.558L111.658 90.9991L110.342 92.5057Z"
                      fill="black" />
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="374"
                           y="646.938">Sensor 2: {{form.get('tube2LengthCm').value}} cm</tspan>
                </text>
                <text transform="translate(103.469 391) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{form.get('tube3YOffset2Cm').value}} cm</tspan>
                </text>
                <text transform="translate(397.794 307.009) rotate(44)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">Sensor 3: {{form.get('tube3LengthCm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="374"
                           y="710.938">Sensor 1</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="374"
                           y="774.938">{{form.get('drivingLaneWidthCm').value}} cm</tspan>
                </text>
                <text transform="translate(659.469 701.5) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{tubeForm?.get('tubeDistanceCm').value}} cm</tspan>
                </text>
                <text transform="translate(659.469 637.5) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{form.get('tube3YOffset1Cm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="719"
                           y="582.938">{{form.get('tube3XOffsetCm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="608"
                           y="578.969">{{bikeXPosAlpha}} °</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="113"
                           y="579.969">{{bikeXPosGamma}} °</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="113"
                           y="148.969">{{bikeXPosBeta}} °</tspan>
                </text>
                <line x1="669"
                      y1="597.5"
                      x2="96"
                      y2="597.5"
                      stroke="black"
                      stroke-dasharray="4 4" />
                <path d="M393.768 52.2322C392.791 51.2559 391.209 51.2559 390.232 52.2322L374.322 68.1421C373.346 69.1185 373.346 70.7014 374.322 71.6777C375.299 72.654 376.882 72.654 377.858 71.6777L392 57.5355L406.142 71.6777C407.118 72.654 408.701 72.654 409.678 71.6777C410.654 70.7014 410.654 69.1185 409.678 68.1421L393.768 52.2322ZM394.5 159L394.5 54L389.5 54L389.5 159L394.5 159Z"
                      fill="black" />
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="404"
                           y="120.969">{{"measuringPoints.primaryDirection" | translate }}</tspan>
                </text>
            </g>
            <defs>
                <clipPath id="clip0_605_220">
                    <rect width="784"
                          height="840"
                          fill="white" />
                </clipPath>
            </defs>
        </svg>

        <svg width="784"
             height="840"
             viewBox="0 0 784 840"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             *ngIf="form.get('devicePosition').value == 'left'"
             style="width:50%;">
            <g clip-path="url(#clip0_605_188)">
                <path d="M0 0H784V840H0V0Z"
                      fill="white" />
                <path d="M752 20H764V820H752V20Z"
                      fill="#666666" />
                <path d="M20 20H32V820H20V20Z"
                      fill="#666666" />
                <path d="M688 99.5C679.44 99.5 672.5 92.5604 672.5 84C672.5 75.4396 679.44 68.5 688 68.5C696.56 68.5 703.5 75.4396 703.5 84C703.5 92.5604 696.56 99.5 688 99.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M96 739.5C87.4396 739.5 80.5 732.56 80.5 724C80.5 715.44 87.4396 708.5 96 708.5C104.56 708.5 111.5 715.44 111.5 724C111.5 732.56 104.56 739.5 96 739.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M688 739.5C679.44 739.5 672.5 732.56 672.5 724C672.5 715.44 679.44 708.5 688 708.5C696.56 708.5 703.5 715.44 703.5 724C703.5 732.56 696.56 739.5 688 739.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M96 675.5C87.4396 675.5 80.5 668.56 80.5 660C80.5 651.44 87.4396 644.5 96 644.5C104.56 644.5 111.5 651.44 111.5 660C111.5 668.56 104.56 675.5 96 675.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M688 675.5C679.44 675.5 672.5 668.56 672.5 660C672.5 651.44 679.44 644.5 688 644.5C696.56 644.5 703.5 651.44 703.5 660C703.5 668.56 696.56 675.5 688 675.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M96 611.5C87.4396 611.5 80.5 604.56 80.5 596C80.5 587.44 87.4396 580.5 96 580.5C104.56 580.5 111.5 587.44 111.5 596C111.5 604.56 104.56 611.5 96 611.5Z"
                      fill="#DDDDDD"
                      stroke="black" />
                <path d="M672.5 659.984V660.016C672.5 660.005 672.5 659.995 672.5 659.984ZM114.207 661.5H112.793L118.011 666.717L118.011 666.718C118.206 666.913 118.522 666.913 118.717 666.718L118.718 666.717C118.913 666.522 118.913 666.206 118.718 666.011L118.717 666.011L114.207 661.5ZM669.379 659.5H114.621L114.121 660L114.621 660.5H669.379L669.879 660L669.379 659.5ZM671.207 661.5H669.793L665.283 666.011L665.282 666.011C665.087 666.206 665.087 666.522 665.282 666.717L665.283 666.718C665.478 666.913 665.794 666.913 665.989 666.718L665.989 666.717L671.207 661.5ZM670.793 660.5H671.5V659.5H670.793L670.94 659.646L671.293 660L670.94 660.354L670.793 660.5ZM669.793 658.5H671.207L665.989 653.283L665.989 653.282C665.794 653.087 665.478 653.087 665.283 653.282L665.282 653.283C665.087 653.478 665.087 653.794 665.282 653.989L665.283 653.989L669.793 658.5ZM113.06 659.646L113.207 659.5H112.5V660.5H113.207L113.06 660.354L112.707 660L113.06 659.646ZM118.011 653.283L112.793 658.5H114.207L118.717 653.989L118.718 653.989C118.913 653.794 118.913 653.478 118.718 653.283L118.717 653.282C118.522 653.087 118.206 653.087 118.011 653.282L118.011 653.283Z"
                      fill="#333333"
                      stroke="black" />
                <path d="M80.707 595.293C81.098 595.683 81.098 596.317 80.707 596.707L74.343 603.071C73.953 603.462 73.319 603.462 72.929 603.071C72.538 602.681 72.538 602.047 72.929 601.657L78.586 596L72.929 590.343C72.538 589.953 72.538 589.319 72.929 588.929C73.319 588.538 73.953 588.538 74.343 588.929L80.707 595.293ZM31.2931 596.707C30.9021 596.317 30.9021 595.683 31.2931 595.293L37.657 588.929C38.047 588.538 38.681 588.538 39.071 588.929C39.462 589.319 39.462 589.953 39.071 590.343L33.414 596L39.071 601.657C39.462 602.047 39.462 602.681 39.071 603.071C38.681 603.462 38.047 603.462 37.657 603.071L31.2931 596.707ZM80 597H32V595H80V597Z"
                      fill="#333333" />
                <path d="M672.707 723.293C673.098 723.683 673.098 724.317 672.707 724.707L666.343 731.071C665.953 731.462 665.319 731.462 664.929 731.071C664.538 730.681 664.538 730.047 664.929 729.657L670.586 724L664.929 718.343C664.538 717.953 664.538 717.319 664.929 716.929C665.319 716.538 665.953 716.538 666.343 716.929L672.707 723.293ZM111.293 724.707C110.902 724.317 110.902 723.683 111.293 723.293L117.657 716.929C118.047 716.538 118.681 716.538 119.071 716.929C119.462 717.319 119.462 717.953 119.071 718.343L113.414 724L119.071 729.657C119.462 730.047 119.462 730.681 119.071 731.071C118.681 731.462 118.047 731.462 117.657 731.071L111.293 724.707ZM672 725H112V723H672V725Z"
                      fill="#333333" />
                <path d="M752.707 787.293C753.098 787.683 753.098 788.317 752.707 788.707L746.343 795.071C745.953 795.462 745.32 795.462 744.929 795.071C744.538 794.681 744.538 794.047 744.929 793.657L750.586 788L744.929 782.343C744.538 781.953 744.538 781.319 744.929 780.929C745.32 780.538 745.953 780.538 746.343 780.929L752.707 787.293ZM31.293 788.707C30.902 788.317 30.902 787.683 31.293 787.293L37.657 780.929C38.047 780.538 38.681 780.538 39.071 780.929C39.462 781.319 39.462 781.953 39.071 782.343L33.414 788L39.071 793.657C39.462 794.047 39.462 794.681 39.071 795.071C38.681 795.462 38.047 795.462 37.657 795.071L31.293 788.707ZM752 789H32V787H752V789Z"
                      fill="#333333" />
                <path d="M687.293 100.293C687.683 99.9024 688.317 99.9024 688.707 100.293L695.071 106.657C695.462 107.047 695.462 107.681 695.071 108.071C694.681 108.462 694.047 108.462 693.657 108.071L688 102.414L682.343 108.071C681.953 108.462 681.319 108.462 680.929 108.071C680.538 107.681 680.538 107.047 680.929 106.657L687.293 100.293ZM688.707 644.707C688.317 645.098 687.683 645.098 687.293 644.707L680.929 638.343C680.538 637.953 680.538 637.319 680.929 636.929C681.319 636.538 681.953 636.538 682.343 636.929L688 642.586L693.657 636.929C694.047 636.538 694.681 636.538 695.071 636.929C695.462 637.319 695.462 637.953 695.071 638.343L688.707 644.707ZM689 101V644H687V101H689Z"
                      fill="black" />
                <path d="M95.2931 675.293C95.6831 674.902 96.317 674.902 96.707 675.293L103.071 681.657C103.462 682.047 103.462 682.681 103.071 683.071C102.681 683.462 102.047 683.462 101.657 683.071L96 677.414L90.343 683.071C89.953 683.462 89.319 683.462 88.929 683.071C88.538 682.681 88.538 682.047 88.929 681.657L95.2931 675.293ZM96.707 708.707C96.317 709.098 95.6831 709.098 95.2931 708.707L88.929 702.343C88.538 701.953 88.538 701.319 88.929 700.929C89.319 700.538 89.953 700.538 90.343 700.929L96 706.586L101.657 700.929C102.047 700.538 102.681 700.538 103.071 700.929C103.462 701.319 103.462 701.953 103.071 702.343L96.707 708.707ZM97 676V708H95V676H97Z"
                      fill="black" />
                <path d="M95.2931 611.293C95.6831 610.902 96.317 610.902 96.707 611.293L103.071 617.657C103.462 618.047 103.462 618.681 103.071 619.071C102.681 619.462 102.047 619.462 101.657 619.071L96 613.414L90.343 619.071C89.953 619.462 89.319 619.462 88.929 619.071C88.538 618.681 88.538 618.047 88.929 617.657L95.2931 611.293ZM96.707 644.707C96.317 645.098 95.6831 645.098 95.2931 644.707L88.929 638.343C88.538 637.953 88.538 637.319 88.929 636.929C89.319 636.538 89.953 636.538 90.343 636.929L96 642.586L101.657 636.929C102.047 636.538 102.681 636.538 103.071 636.929C103.462 637.319 103.462 637.953 103.071 638.343L96.707 644.707ZM97 612V644H95V612H97Z"
                      fill="black" />
                <path d="M673.068 90.7547C673.619 90.792 674.035 91.269 673.998 91.82L673.389 100.799C673.352 101.35 672.875 101.767 672.324 101.73C671.773 101.692 671.357 101.215 671.394 100.664L671.935 92.6825L663.953 92.1417C663.402 92.1044 662.986 91.6274 663.023 91.0764C663.06 90.5254 663.537 90.1089 664.088 90.1463L673.068 90.7547ZM107.626 586.309C107.075 586.271 106.658 585.794 106.696 585.243L107.304 576.264C107.342 575.713 107.819 575.297 108.37 575.334C108.921 575.371 109.337 575.848 109.3 576.399L108.759 584.381L116.741 584.922C117.292 584.959 117.708 585.436 117.671 585.987C117.633 586.538 117.156 586.955 116.605 586.917L107.626 586.309ZM673.658 92.5057L108.351 586.064L107.036 584.558L672.342 90.9991L673.658 92.5057Z"
                      fill="black" />
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="378"
                           y="646.938">Sensor 2: {{form.get('tube2LengthCm').value}} cm</tspan>
                </text>
                <text transform="translate(661.531 391) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{form.get('tube3YOffset2Cm').value}} cm</tspan>
                </text>
                <text transform="translate(362.129 328.087) rotate(-41.2)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">Sensor 3: {{form.get('tube3LengthCm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="378"
                           y="710.938">Sensor 1</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="378"
                           y="774.938">{{form.get('drivingLaneWidthCm').value}} cm</tspan>
                </text>
                <text transform="translate(105.531 701.5) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{tubeForm?.get('tubeDistanceCm').value}} cm</tspan>
                </text>
                <text transform="translate(105.531 637.5) rotate(-90)"
                      fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="0"
                           y="14.9688">{{form.get('tube3YOffset1Cm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="49"
                           y="582.938">{{form.get('tube3XOffsetCm').value}} cm</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="154"
                           y="578.969">{{bikeXPosAlpha}} °</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="649"
                           y="579.969">{{bikeXPosGamma}} °</tspan>
                </text>
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="649"
                           y="148.969">{{bikeXPosBeta}} °</tspan>
                </text>
                <line y1="-0.5"
                      x2="573"
                      y2="-0.5"
                      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 115 597)"
                      stroke="black"
                      stroke-dasharray="4 4" />
                <path d="M390.232 52.2322C391.209 51.2559 392.791 51.2559 393.768 52.2322L409.678 68.1421C410.654 69.1185 410.654 70.7014 409.678 71.6777C408.701 72.654 407.118 72.654 406.142 71.6777L392 57.5355L377.858 71.6777C376.882 72.654 375.299 72.654 374.322 71.6777C373.346 70.7014 373.346 69.1185 374.322 68.1421L390.232 52.2322ZM389.5 159L389.5 54L394.5 54L394.5 159L389.5 159Z"
                      fill="black" />
                <text fill="black"
                      xml:space="preserve"
                      style="white-space: pre"
                      font-family="Roboto"
                      font-size="16"
                      letter-spacing="0em">
                    <tspan x="410"
                           y="120.969">{{"measuringPoints.primaryDirection" | translate }}</tspan>
                </text>
            </g>
            <defs>
                <clipPath id="clip0_605_188">
                    <rect width="784"
                          height="840"
                          fill="white"
                          transform="matrix(-1 0 0 1 784 0)" />
                </clipPath>
            </defs>
        </svg>

    </div>

</div>