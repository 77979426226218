<div class="m-btn-group m-btn-group-right m-layout-area-top"
     *ngIf="images && images.length > 0">
    <button class="m-btn m-btn-secondary"
            (click)="openPhotoGallery()">
        {{"attachments.openGallery" | translate}}
    </button>
</div>

<app-attachments [attachmentContext]="attachmentContext"
                 *ngIf="attachmentContext"
                 class="m-layout-area-body m-layout-w-actions-top"
                 (dataSet)="onDataSet()"></app-attachments>