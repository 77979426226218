import { TreeNode } from "primeng/api";
import { IOrganization } from "../models/user";

export class OrganizationUtils {

    public static addLevel(organizations: IOrganization[]): IOrganization[] {
        const rankedOrganizations: IOrganization[] = [];
        const rootOrganizations = organizations.filter(o => !o.parentOrganizationId || !organizations.find(y => y.id === o.parentOrganizationId));
        rootOrganizations.forEach(organization => {
            organization.level = 0;
            rankedOrganizations.push(organization);
            this._rankSubOrganizations(organization, rankedOrganizations, 1, organizations);

        });
        return rankedOrganizations;
    }

    private static _rankSubOrganizations(organization: IOrganization, rankedOrganizations: IOrganization[], level: number, organizations: IOrganization[]) {

        const subOrganizations = organizations.filter(o => o.parentOrganizationId === organization.id);
        if (subOrganizations && subOrganizations.length > 0) {
            subOrganizations.forEach(subOrganization => {
                subOrganization.level = level;
                rankedOrganizations.push(subOrganization);
                this._rankSubOrganizations(subOrganization, rankedOrganizations, level + 1, organizations);

            });
        }
    }

    public static getStyleClass = (organization: IOrganization) => {
        return "m-depth-" + organization.level;
    };
}