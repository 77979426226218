<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="cameraDetectionConfiguration">
    <div class="p-16">
        <div class="m-form-group row">
            <label class="col-2"
                   for="drivingLane">
                {{"uploadDetailsDialog.reverseDirection" | translate}}
            </label>

            <div class="col-4">
                <p-inputSwitch [formControl]="form.get('reverseDirection')"></p-inputSwitch>
            </div>
        </div>

        <div class="m-form-group row">
            <label class="col-2"
                   for="drivingLane">
                {{"uploadDetailsDialog.includeTraffic" | translate}}
            </label>

            <div class="col-4">
                <p-inputSwitch [formControl]="form.get('analyzeForwardDirection')"></p-inputSwitch>
            </div>
        </div>

        <div class="m-form-group row">
            <label class="col-2"
                   for="drivingLane">
                {{"uploadDetailsDialog.includeOncomingTraffic" | translate}}
            </label>

            <div class="col-4">
                <p-inputSwitch [formControl]="form.get('analyzeReverseDirection')"></p-inputSwitch>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': form.get('filter').showErrors }">

            <label class="col-2"
                   for="drivingLane">
                {{"uploadDetailsDialog.sensor" | translate}}
            </label>

            <div class="col-4">
                <input class="p-inputtext"
                       [formControl]="form.get('filter')"
                       #filterInput />

                <app-form-validation [control]="form.get('filter')"
                                     [input]="filterInput">
                </app-form-validation>
            </div>
        </div>
    </div>
</div>