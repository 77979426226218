import { Component, Input, ViewChild } from "@angular/core";
import { ITotemDisplayLiveData } from "src/app/models/totem-display-configuration";
import { TotemDisplayManageDialogComponent } from "../totem-display-manage-dialog/totem-display-manage-dialog.component";

@Component({
    selector: "app-totem-display-pinned-data",
    templateUrl: "./totem-display-pinned-data.component.html",
})
export class TotemDisplayPinnedDataComponent {
    @Input() measuringPointId: number;
    @Input() totemDisplay: ITotemDisplayLiveData;
    @Input() isExpanded: boolean;

    @ViewChild(TotemDisplayManageDialogComponent, { static: true }) modeManageDialog: TotemDisplayManageDialogComponent;

    constructor() {

    }

    openSetModeDialog(event: MouseEvent) {
        event.stopPropagation();
        this.modeManageDialog.open(this.measuringPointId);
    }
}
