import { Component, Input, HostListener, ElementRef } from "@angular/core";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { IDevice } from "src/app/models/device";

@Component({
    selector: "app-device-alarm-overview",
    templateUrl: "./device-alarm-overview.component.html"
})
export class DeviceAlarmOverviewComponent implements IComponentCanDeactivate {
    @Input() device: IDevice;

    constructor(
        elementRef: ElementRef,
        private readonly changeGuardService: ChangeGuardService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
    }

    setDevice(device: IDevice) {
        this.device = device;
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck();
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }
}