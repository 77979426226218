<div class="m-page-content-wrapper p-16">
    <div class="row m-fixed-height">
        <div class="col-3 m-fixed-height">
            <div class="m-fixed-height">
                <div class="m-card m-layout-w-actions-bottom m-fixed-height">
                    <div class="m-layout-area-body m-layout-w-actions-top">
                        <div class="m-overflow-auto m-layout-area-body m-layout-default">
                            <p-tree [value]="nodes"
                                    [loading]="loadingTree"
                                    selectionMode="single"
                                    [(selection)]="selectedNode"
                                    class="m-layout-area-body"
                                    (onNodeSelect)="handleNodeSelect($event.node)"
                                    [filter]="true"
                                    filterPlaceholder="{{'documentation.searchArticles' | translate}}">
                                <ng-template let-node
                                             pTemplate="default">

                                    <span class="mr-8">
                                        {{node.label | truncate: 45:true}}
                                    </span>
                                </ng-template>
                            </p-tree>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height">
            <div *ngIf="!loading;else loadingArticle"
                 id="body"
                 (click)="hrefClicked($event)"
                 class="m-card m-fixed-height">
                <div [innerHtml]="content"
                     class="markdown-body m-overflow-auto m-fixed-height"
                     style="padding: 20px;">
                </div>
            </div>

            <ng-template #loadingArticle>
                <div>
                    <app-loading-spinner>
                    </app-loading-spinner>
                </div>
            </ng-template>
        </div>
    </div>
</div>