import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import { SymbolSelectorDialogComponent } from "../symbol-selector-dialog/symbol-selector-dialog.component";
import { ISymbol } from "src/app/models/symbol";
import { SubscriptionManager } from "src/app/utilities";
import { VmsImageEditorService } from "../../services/vms-image-editor.service";
import { VmsImageEditorCopyFromDialogComponent } from "../vms-image-editor-copyfrom-dialog/vms-image-editor-copyfrom.dialog";
import { UploadVmsImageDialogComponent } from "../upload-vms-image-dialog/upload-vms-image.dialog";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";

export enum Tools {
    Mouse = 0,
    Text = 1,
    Square = 2,
    Circle = 3,
    Line = 5,
    Arrow = 6,
    Triangle = 7,
}
@Component({
    selector: "app-vms-image-editor-toolbar",
    templateUrl: "./vms-image-editor-toolbar.component.html",
    styleUrls: []
})
export class VmsImageEditorToolbarComponent implements OnInit, OnDestroy {
    @ViewChild(SymbolSelectorDialogComponent, { static: true }) symbolSelectorDialogComponent: SymbolSelectorDialogComponent;
    @ViewChild(VmsImageEditorCopyFromDialogComponent, { static: true }) vmsImageEditorCopyFromDialogComponent: VmsImageEditorCopyFromDialogComponent;
    @ViewChild(UploadVmsImageDialogComponent, { static: true }) uploadVmsImageDialog: UploadVmsImageDialogComponent;
    private canvas: fabric.Canvas;
    public currentTool: Tools = Tools.Mouse;
    public currentVmsVariant: IVmsImageVariant;
    public currentSelectedObject: fabric.Object;
    private readonly subscriptionManager = new SubscriptionManager();
    loading: boolean;
    constructor(
        private readonly vmsImageEditorService: VmsImageEditorService,

    ) {
    }

    ngOnInit(): void {

        this.loading = true;
        const currentCanvasObjectChangeSubscription = this.vmsImageEditorService.currentCanvas$.subscribe(async (canvas) => {
            if (canvas) {
                this.canvas = canvas;
                this.canvas.on("mouse:down", (event) => {
                    if (event.button === 1) {
                        if (!event.target && this.currentTool != Tools.Mouse) {
                            this.createFabricObject(event);
                            this.setTool(Tools.Mouse, false);
                            this.vmsImageEditorService.requestRenderPreview();
                        } else {
                            this.vmsImageEditorService.notifySelectedObjectChanged(event.target);
                        }
                    }
                });
            }
        });
        this.subscriptionManager.add("currentCanvasObjectChange", currentCanvasObjectChangeSubscription);
        const vmsImageVariantChangeSubscription = this.vmsImageEditorService.selectedVmsImageVariant$.subscribe(async (object) => {
            if (object) {
                this.currentVmsVariant = object;
                this.loading = false;
            }
        });
        this.subscriptionManager.add("vmsImageVariantChangeSubscription", vmsImageVariantChangeSubscription);

        const vmsImageChangeSubscription = this.vmsImageEditorService.selectedVmsImage$.subscribe(async (object) => {
            if (object) {
                // Clean slate when new image is selected
                this.symbolSelectorDialogComponent.reset();
            }
        });
        this.subscriptionManager.add("vmsImageChangeSubscription", vmsImageChangeSubscription);
        const currentSelectedObjectChangeSubscription = this.vmsImageEditorService.selectedObjectChange$.subscribe(async (object) => {
            this.currentSelectedObject = object;
        });
        this.subscriptionManager.add("currentSelectedObjectChange", currentSelectedObjectChangeSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    // Set the current selected tool
    setTool(tool: Tools, userTriggered: boolean = true) {
        this.currentTool = tool;
        if (tool === Tools.Mouse && userTriggered && this.currentSelectedObject?.type == "i-text") {
            // leave editing mode
            const textObj = this.currentSelectedObject as fabric.IText;
            textObj.exitEditing();
            this.canvas.renderAll();
        }
    }

    createFabricObject(e: IEvent<MouseEvent>) {
        const pointer = this.canvas.getPointer(e.e);
        // convert pointer.x and pointer.y to integer
        const roundedX = Math.round(pointer.x);
        const roundedY = Math.round(pointer.y);
        switch (this.currentTool) {
            case (Tools.Text):
                // create editable text
                const text = new fabric.IText("Text", {
                    left: roundedX,
                    top: roundedY,
                    fontFamily: "Arial",
                    fill: "#FFFFFF",
                    stroke: "#FFFFFF",
                    fontSize: 14,
                    strokeWidth: 0,
                    strokeUniform: false,
                    snapAngle: 15,
                });
                this.vmsImageEditorService.setTextControlsVisibility(text);
                this.vmsImageEditorService.addAndSetActiveObject(text);
                text.enterEditing();
                text.selectAll();
                break;
            case (Tools.Square):
                const rect = new fabric.Rect({
                    left: roundedX,
                    top: roundedY,
                    fill: "#000000",
                    stroke: "#FFFFFF",
                    snapAngle: 15,
                    strokeWidth: 2,
                    width: 20,
                    height: 20,
                });
                this.vmsImageEditorService.addAndSetActiveObject(rect);
                break;
            case (Tools.Line):
                // draw vertical line
                const line = new fabric.Line([roundedX, roundedY, roundedX, roundedY + 40], {
                    stroke: "#FFFFFF",
                    fill: "#FFFFFF",
                    strokeWidth: 2,
                    snapAngle: 15,
                    strokeUniform: false,
                });
                this.vmsImageEditorService.setControlsVisibility(line);
                this.vmsImageEditorService.addAndSetActiveObject(line);
                break;
            case (Tools.Arrow):
                // draw vertical line
                const arrowLine = new fabric.Line([roundedX, roundedY, roundedX, roundedY + 40], {
                    stroke: "#FFFFFF",
                    strokeWidth: 3,
                    strokeUniform: true,
                });
                const arrowTriangle = new fabric.Triangle({
                    left: roundedX,
                    top: roundedY + 40,
                    width: 8,
                    height: 8,
                    fill: "#FFFFFF",
                    angle: 180,
                    strokeUniform: true,
                });
                const group = new fabric.Group([arrowLine, arrowTriangle], {
                    name: "arrow",
                    left: roundedX,
                    top: roundedY,
                    angle: 270,
                    snapAngle: 15,
                    strokeUniform: true,
                    fill: "#FFFFFF",
                });
                this.vmsImageEditorService.setControlsVisibility(group);
                this.vmsImageEditorService.addAndSetActiveObject(group);
                break;
            case (Tools.Triangle):
                const triangle = new fabric.Triangle({
                    left: roundedX,
                    top: roundedY,
                    width: 10,
                    height: 10,
                    fill: "#FFFFFF",
                    stroke: "#FFFFFF",
                    angle: 0,
                    snapAngle: 15,
                    strokeWidth: 2,
                    strokeUniform: false,
                });
                this.vmsImageEditorService.addAndSetActiveObject(triangle);
                break;
            case (Tools.Circle):
                const circle = new fabric.Circle({
                    left: roundedX,
                    top: roundedY,
                    radius: 10,
                    fill: "#000000",
                    stroke: "#FFFFFF",
                    strokeWidth: 2,
                });
                this.vmsImageEditorService.addAndSetActiveObject(circle);
                break;
            default:
                break;
        }
    }
    //#region svg functions
    openSymbolSelectorDialog() {
        this.symbolSelectorDialogComponent.show(this.loadSymbol.bind(this));
    }
    loadSymbol(symbol: ISymbol) {
        fabric.loadSVGFromString(symbol.svgData, (objects, options) => this.loadSvg(objects, options));
    }
    loadSvg(objects, options) {
        const loadedObject = fabric.util.groupSVGElements(objects, options);

        loadedObject.name = "symbol";
        loadedObject["smoothed"] = false;
        this.vmsImageEditorService.setSymbolControlsVisibility(loadedObject);

        // set loadedObject to viewportcenter of canvas
        this.vmsImageEditorService.addAndSetActiveObject(loadedObject);
        loadedObject.viewportCenter();
        loadedObject.setCoords();
        this.vmsImageEditorService.requestRenderPreview();
    }
    //#endregion
    openCopyFromDialog() {
        this.vmsImageEditorCopyFromDialogComponent.create(this.currentVmsVariant.vmsType.typeId);
    }
    showCanvasTool() {
        return !(this.currentVmsVariant && (this.currentVmsVariant.url || this.currentVmsVariant.file)
            && !this.currentVmsVariant.editorData);
    }

    openUploadDialog() {
        // get current VMS type
        const currentVmsType = this.vmsImageEditorService.getCurrentVmsType();
        this.uploadVmsImageDialog.create(currentVmsType.resolutionHeight, currentVmsType.resolutionWidth);
    }
}
