import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef, NgZone, TemplateRef } from "@angular/core";
import { MapContextMenuComponent } from "../../../map-basic/components/map-context-menu/map-context-menu.component";
import { LocationMarker, MarkerContext } from "../../classes";
import { SearchParameters } from "src/app/models/search";
import { GroupMarkerComponent } from "../group-marker/group-marker.component";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { IBasicMarker, BasicMarkerOptions, IMarkerClusterer, createMapClusterer, BasicMarkerEvent, BasicMapComponent } from "src/app/modules/map-basic";
import { MapDataService } from "src/app/services/map-data.service";
import { LocationWebApi } from "src/app/resource/web";
import { IDeviceSummary } from "src/app/models/device";
import { AlarmSeverity } from "src/app/models/alarm";
import { IOrganization } from "src/app/models/user";
import { GroupPolygon } from "./group-polygon";
import { EventService } from "src/app/services/event.service";
import { environment } from "src/environments/environment";
import { TimerUtils, SubscriptionManager } from "src/app/utilities";
import { MapDetail } from "src/app/services/map-detail.service";
import { firstValueFrom } from "rxjs";
import * as lodash from "lodash";
import { MapIconService } from "src/app/services/map-icon-service";
import { LocationRealtimeService } from "src/app/services/realtime/location-realtime.service";
import { ILocationChangedArguments } from "src/app/models/location-changed-arguments";
import { MapSearchButtonComponent } from "../map-searchbutton/map-searchbutton.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { ILocationSummary, ILocationWithDevicesSummary } from "src/app/models/web";
import { IGroup } from "src/app/models/group";
import { IGroupSummary } from "src/app/models/web";
import { BackendRights } from "src/app/models/backend-rights";
import { Constants } from "src/app/constants/constants";
import { TouchService } from "src/app/services/touch.service";
import { MapContextMenuItem } from "src/app/modules/map-basic";

export class MapEvent {
    constructor(
        public locationMarker: LocationMarker,
        public event: google.maps.MapMouseEvent) {
    }
}

/**
 * This component mixes several responsibilities:
 * * Showing markers on a map, including clustering
 * * Displaying measuring points, devices, organizations, groups and assignments on a map
 * * Filtering based on search parameters
 * * Moving markers based on SignalR events
 *
 * My goal is to split this component into several smaller components, each with a single responsibility.
 * The basic map handling is already moved to the BasicMapComponent.
 */
@Component({
    selector: "app-advanced-map",
    templateUrl: "./advanced-map.component.html"
})
export class AdvancedMapComponent implements OnChanges, OnDestroy {
    @Input() style?: any = { height: "100%", width: "100%" };
    @Input() searchbuttonStyle: any;
    @Input() searchControl = false;
    @Input() showCoordinates = false;
    @Input() showMapTypeSelector = false;
    @Input() showStreetViewControl = false;
    @Input() enableLabels = true;
    @Input() ownerId: number;
    @Input() forceUpdate = false;
    @Input() searchbutton: MapSearchButtonComponent;
    @Input() saveState = false; // If true, the map state will be saved in the local storage

    @Output() mapReady = new EventEmitter<AdvancedMapComponent>();
    @Output() destroy = new EventEmitter<void>();
    @Output() mapClick = new EventEmitter<google.maps.MapMouseEvent>();
    @Output() mapRightClick = new EventEmitter<google.maps.MapMouseEvent>();
    @Output() zoom = new EventEmitter<number>();
    @Output() locationClick = new EventEmitter<MapEvent>();
    @Output() locationRightClick = new EventEmitter<MapEvent>();
    @Output() mapDragStart = new EventEmitter<google.maps.MapMouseEvent>();
    @Output() mapDragEnd = new EventEmitter<google.maps.MapMouseEvent>();
    @Output() markerDragStart = new EventEmitter<MapEvent>();
    @Output() markerDragEnd = new EventEmitter<MapEvent>();
    @Output() dataLoaded = new EventEmitter<void>();
    @Output() groupSelect = new EventEmitter<IGroupSummary>();
    @Output() groupDeselect = new EventEmitter<IGroupSummary>();
    @Output() groupMarkerClose = new EventEmitter<ILocationSummary>();

    private _basicMap: BasicMapComponent;
    get basicMap(): BasicMapComponent {
        return this._basicMap;
    }

    protected destroyed = false;

    mapDetail: MapDetail;

    private clickedContextOverlay: google.maps.OverlayView;
    map: google.maps.Map;
    markers = new Array<IBasicMarker>();

    private showingMeasuringPointLocations = false;
    private groupSummaries: IGroupSummary[];
    public get getGroupSummaries(): IGroupSummary[] {
        return this.groupSummaries;
    }
    focusedGroupIds = new Array<number>(); // highlighted groups
    groupPolygons: GroupPolygon[];

    locationMarkers = new Array<LocationMarker>();

    private loadedMeasuringPointLocations: ILocationSummary[];
    public get getLoadedMeasuringPointLocations(): ILocationSummary[] {
        return this.loadedMeasuringPointLocations;
    }

    private filterSearchParameters: SearchParameters;
    filterResult: (ILocationSummary | IGroupSummary)[];

    mapClusterer: IMarkerClusterer;

    private componentRefs: ComponentRef<GroupMarkerComponent>[] = [];
    private readonly subscriptionManager = new SubscriptionManager();
    private readonly mapDataServiceKey: string;
    private readonly mapMeasuringPointDataServiceKey: string;

    isLoading = false;

    constructor(
        readonly elementRef: ElementRef<HTMLElement>,
        private readonly mapDataService: MapDataService,
        private readonly eventService: EventService,
        private readonly selectionService: MapSelectionService,
        private readonly locationWebApi: LocationWebApi,
        private readonly zone: NgZone,
        private readonly injector: Injector,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly applicationRef: ApplicationRef,
        private readonly mapIconService: MapIconService,
        private readonly locationRealtimeService: LocationRealtimeService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly touchService: TouchService,
    ) {

        this.elementRef.nativeElement.style.height = "100%";
        this.elementRef.nativeElement.style.width = "100%";

        this.mapDataServiceKey = this.mapDataService.createKey();
        this.mapMeasuringPointDataServiceKey = this.mapDataService.createKey();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.basicMap) {
            const optionsChange = changes["options"];
            if (optionsChange) {
                this.createMapOptions();
            }

            const enableLabelsChange = changes["enableLabels"];
            if (enableLabelsChange) {
                for (const marker of this.locationMarkers) {
                    marker.marker.setLabelVisible(this.enableLabels);
                }
            }

            const ownerIdChange = changes["ownerId"];
            if (ownerIdChange) {
                this.showingMeasuringPointLocations = false;
                this.updateMapContent();
            }
        }
    }

    ngOnDestroy() {
        this.destroyed = true;
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataServiceKey);
        this.mapDataService.unsubscribe(this.mapMeasuringPointDataServiceKey);
        this.locationRealtimeService.unsubscribe();

        // Only if google namespace is loaded
        if (this.basicMap) {
            if (this.map) {
                google.maps.event.clearInstanceListeners(this.map);
            }
        }

        if (this.mapClusterer) {
            this.mapClusterer.dispose();
            this.mapClusterer = null;
        }

        this.clearComponentRefs();

        this.destroy.emit();
    }

    private clearComponentRefs() {
        for (const componentRef of this.componentRefs) {
            this.clearComponentRef(componentRef);
        }

        this.componentRefs = [];
    }

    private clearComponentRef(componentRef: ComponentRef<GroupMarkerComponent>, removeFromList?: boolean) {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();

        if (removeFromList) {
            this.componentRefs = this.componentRefs.remove(componentRef);
        }
    }

    //#region Map Logic

    handleMapReady(basicMap: BasicMapComponent) {
        this._basicMap = basicMap;

        this.map = basicMap.map;

        this.createMapOptions();
        this.initializeMapClusterer();

        this.zone.run(() => {
            this.mapReady.emit(this);
        });
    }

    clearSelected() {
        this.selectionService.clearSelected();
    }

    clearSelectedIfNoLongerPresent() {
        const selected = this.selectionService.getSelected();
        if (selected && !this.locationMarkers.contains(selected)) {
            this.selectionService.clearSelected();
        }
    }

    clearMap(forceClearMarkers = false) {
        this.removePopupContainer();
        if (!this.showingMeasuringPointLocations || forceClearMarkers) {
            this.clearMarkers();
        }

        this.clearGroupPolygons();
        this.setEditGroup(null);

        this.clearSelected();
    }

    clearLabels() {
        for (const locationMarker of this.locationMarkers) {
            locationMarker.marker.setLabelVisible(false);
            locationMarker.marker.setMapInternal(null);
        }
    }

    clearAll() {
        this.clearSelected();
        this.clearMap();
    }

    addLocationMarker(locationMarker: LocationMarker) {
        this.addMarker(locationMarker);
        this.bindMarkerEvents(locationMarker.marker); // Attach to the Marker no matter what state we're in
        this.locationMarkers.push(locationMarker);
    }

    removeContext(context: MarkerContext, redrawCluster = true): boolean {
        const locationMarker = this.getLocationMarker(context);
        if (!locationMarker) return false;

        return this.removeLocationMarker(locationMarker, redrawCluster);
    }

    removeLocationMarker(locationmarker: LocationMarker, redrawCluster = true): boolean {
        if (!locationmarker) return;

        this.removeMarker(locationmarker, redrawCluster);
        this.locationMarkers = this.locationMarkers.filter(item => item !== locationmarker);

        if (locationmarker === this.selectionService.getSelected()) {
            this.clearSelected();
        }

        return true;
    }

    handleMapClick(event: google.maps.MapMouseEvent) {
        this.clearSelected();
        this.mapClick.emit(event);
    }

    handleResize() {
        if (!this.map) return;

        const focusContext = this.selectionService.getSelected();
        if (!focusContext) return;

        this.centerOn(focusContext);
    }

    handleMapRightClick(event: google.maps.MapMouseEvent) {
        this.mapRightClick.emit(event);
    }

    handleMapDragStart(event: google.maps.MapMouseEvent) {
        this.mapDragStart.emit(event);
    }

    handleMapDragEnd(event: google.maps.MapMouseEvent) {
        this.mapDragEnd.emit(event);
    }

    handleZoomChanged() {
        const currentZoom = this.map.getZoom();
        this.zoom.emit(currentZoom);
    }

    selectContextById(id: number) {
        const locationMarker = this.getLocationMarkerById(id);
        if (!locationMarker) return;

        this.selectLocationMarker(locationMarker);
    }

    selectContext(context: MarkerContext, e?: google.maps.MapMouseEvent) {
        const locationMarker = this.getLocationMarker(context);
        if (!locationMarker) return;

        this.selectLocationMarker(locationMarker, e);
    }

    async selectLocationMarker(locationMarker: LocationMarker, e?: google.maps.MapMouseEvent) {
        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            // On click, if we're on group mode, create the GroupMarkerComponent dynamically
            if (this.mapDataService.editGroup) {
                this.createGroupMarker(locationMarker);

                if (locationMarker.groupMarker.isVisible()) {
                    locationMarker.groupMarker.hide();
                } else {
                    this.closeGroupMarkers();
                    locationMarker.groupMarker.show();
                }
            }

            return;
        }

        const mapEvent = new MapEvent(locationMarker, e);
        this.locationClick.emit(mapEvent);
    }

    toggleGroupPolygonSelect(clickedGroupPolygon: GroupPolygon) {
        if (this.mapDetail !== MapDetail.MeasuringPointGroups) return;

        if (clickedGroupPolygon.isFocused) {
            this.groupDeselect.emit(clickedGroupPolygon.group);
        } else {
            this.groupSelect.emit(clickedGroupPolygon.group);
        }
    }

    centerOnContext(context: MarkerContext) {
        const locationMarker = this.getLocationMarker(context);
        if (!locationMarker) return;

        this.centerOn(locationMarker);
    }

    centerOnContextWithMovement(context: MarkerContext, isInBounds: boolean) {
        const position = context?.latLng;
        if (!isInBounds) {
            this.basicMap.setCenter(position);
            return;
        }

        // It is in bounds but we need to check if it's behing searchbutton
        // or measuring point pop up
        const positionPoint = this.getPixelFromLatLng(position);
        let move = 0;
        if (positionPoint.x < 680 && positionPoint.x > 0) {
            move = 680 - positionPoint.x;
            positionPoint.x -= move;
            const positionLatLng = this.getLatLngFromPixel(positionPoint);

            this.basicMap.setCenter(positionLatLng);
        }
    }

    centerOn(locationMarker: LocationMarker) {
        if (!locationMarker) return;

        this.basicMap.setCenter(locationMarker.marker.getPosition());
    }

    centerOnId(id: number) {
        const locationMarker = this.getLocationMarkerById(id);
        this.centerOn(locationMarker);
    }

    handleMarkerClick(event: BasicMarkerEvent) {
        const locationMarker = this.getLocationMarkerFromMarker(event.marker);

        // console.log("marker clicked: " + locationMarker.context?.toDebugString());

        this.selectLocationMarker(locationMarker, event.event);
    }

    handleMarkerRightClick(event: BasicMarkerEvent) {
        const locationMarker = this.getLocationMarkerFromMarker(event.marker);

        this.locationRightClick.emit(new MapEvent(locationMarker, event.event));
    }

    handleMarkerDragStart(event: BasicMarkerEvent) {
        const locationMarker = this.getLocationMarkerFromMarker(event.marker);

        if (locationMarker) {
            const gmapLocationEvent = new MapEvent(locationMarker, event.event);
            this.markerDragStart.emit(gmapLocationEvent);
        }
    }

    handleMarkerDragEnd(event: BasicMarkerEvent) {
        const locationMarker = this.getLocationMarkerFromMarker(event.marker);

        if (locationMarker) {
            const gmapLocationEvent = new MapEvent(locationMarker, event.event);
            this.markerDragEnd.emit(gmapLocationEvent);
        }
    }

    updateLocationMarkerActiveStates() {
        this.zone.runOutsideAngular(() => {
            let hasChange = false;
            for (const locationMarker of this.locationMarkers) {
                const hadChange = this.setLocationMarkerActiveState(locationMarker, false);

                if (hadChange) hasChange = true;
            }

            if (this.mapClusterer && hasChange) {
                this.redrawMapClusterer();
            }
        });
    }

    get hasFilter(): boolean {
        if (this.mapDetail === MapDetail.MeasuringPoints ||
            this.mapDetail === MapDetail.Devices ||
            this.mapDetail === MapDetail.Organizations ||
            this.mapDetail === MapDetail.Assignments) {

            return !!this.filterSearchParameters;
        }

        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            return false;
        }

        return false;
    }

    private setLocationMarkerActiveState(locationMarker: LocationMarker, updateCluster = true): boolean {
        let focus = false;

        // When editing a group, markers of that group will be "active"
        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            if (this.mapDataService.editGroup) {
                focus = !!this.mapDataService.editGroup.measuringPoints.find(x => x.measuringPoint.locationId === locationMarker.context.id);
            }
        } else {
            if (this.hasFilter) {
                focus = this.filterResult && !!this.filterResult.find(x => x.id === locationMarker.context.id);
            }
        }

        let changesMade = false;

        // [KV 02/03/2023]: I've changed this to remove filtered markers completely, because it becomes very hard to see the 'active' markers
        // through all the 'filtered' markers (we have > 5000 markers at this point)
        // If we get any complaints, we can change this again (use git blame to find)
        // if (updateCluster) {
        const isCurrentlyVisible = !!locationMarker.marker.getIsVisible();
        const isVisible = !focus && this.hasFilter ? false : true;
        // if (isCurrentlyVisible !== isVisible) {
        locationMarker.marker.setIsVisible(isVisible);
        changesMade = true;
        // }
        // }

        const ignoreCluster = this.hasFilter ? !focus : false;
        locationMarker.marker.setIgnoreCluster(ignoreCluster); // We "ignore" (don't count for clustering) the unfocused filters, so we don't get clusters for 1 marker. This doesn't trigger a change to label visibility

        // Marking as focus currently only makes the label appear even if there's a filter
        if (focus !== locationMarker.marker.getFocusState()) {
            locationMarker.marker.setFocusState(focus);
            changesMade = true;
        }

        if (changesMade && updateCluster && this.mapClusterer) {
            this.mapClusterer.updateIcon(locationMarker.marker);
        }

        return changesMade;
    }

    setMapDetail(mapDetail: MapDetail) {
        this.mapDetail = mapDetail;
        this.clearComponentRefs();
        this.updateMapContent();
    }

    private removePopupContainer() {
        const nativeElement = this.elementRef.nativeElement;
        const elementWithClass = nativeElement.getElementsByClassName("popup-container");
        if (elementWithClass.length > 0) {
            elementWithClass[0]?.parentNode.removeChild(elementWithClass[0]);
        }
    }

    updateMapContent() {
        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.showMeasuringPoints();
        }

        if (this.mapDetail === MapDetail.Organizations) {
            this.showOrganizations();
        }

        if (this.mapDetail === MapDetail.Devices) {
            this.showDevices();
        }

        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.showGroups();
        }

        if (this.mapDetail === MapDetail.Assignments) {
            this.showAssignments();
        }
    }

    //#endregion Map Logic

    //#region Context Menu

    openContextMenu(latLng: google.maps.LatLng, contextMenuItems: MapContextMenuItem[]) {
        this.basicMap.openContextMenu(latLng, contextMenuItems);
    }

    //#endregion Context Menu

    //#region Location / Measuring Points Logic

    addLocation(location: ILocationSummary, updateUI = true): LocationMarker {
        let locationMarker: LocationMarker;

        this.zone.runOutsideAngular(() => {
            const context = MarkerContext.fromLocationSummary(location);

            const options: Partial<BasicMarkerOptions> = {
                enableLabel: this.enableLabels
            };
            const iconModel = this.mapIconService.getIconState(location.iconStateId);
            if (iconModel) {
                options.labelColor = iconModel.configuration.default.labelColor;
            } else {
                console.error("icons are not loaded yet"); // Maybe this is a new icon, but it is not loaded in the DomainData yet?
                options.labelColor = "#FFFFFF";
            }

            locationMarker = new LocationMarker(context, iconModel, options);
        });

        this.setLocationMarkerActiveState(locationMarker, updateUI);
        this.addLocationMarker(locationMarker);

        return locationMarker;
    }

    showMeasuringPoints() {
        // Clear all other subscriptions, only interested in locations now
        this.mapDataService.unsubscribe(this.mapDataServiceKey);

        this.mapDetail = MapDetail.MeasuringPoints;

        this.clearAll();
        this.showMeasuringPointLocations();
    }

    async setSearchParameters(searchParameters: SearchParameters, mapDetail: MapDetail) {
        // This being async makes it sometimes that it gets a call from mapDetail 0
        // While this component is already somewhere else (mapDetail 3, for example)
        // This is due to the Angular way of doing promises
        if (mapDetail !== this.mapDetail) return;

        if (!searchParameters || !searchParameters.filter) {
            searchParameters = null;
        }

        const hadParameters = !!this.filterSearchParameters;

        // Launch a new query with search params to current api (mps, device, groups)
        // Set this as "filtered", then update opacity (if in filtered list, full opacity, else see-through)
        this.filterSearchParameters = searchParameters;
        this.filterResult = null;

        if (this.filterSearchParameters) {
            this.isLoading = true;
            if (mapDetail === MapDetail.MeasuringPoints) {
                this.filterResult = (await firstValueFrom(this.locationWebApi.getMeasuringPoints$(null, searchParameters))).data;
            }

            if (mapDetail === MapDetail.Devices) {
                this.filterResult = (await firstValueFrom(this.locationWebApi.getDevices$(null, searchParameters))).data;
            }

            if (mapDetail === MapDetail.MeasuringPointGroups) {
                this.filterResult = (await firstValueFrom(this.locationWebApi.getGroups$(null, searchParameters))).data;
            }

            if (mapDetail === MapDetail.Assignments) {
                this.filterResult = (await firstValueFrom(this.locationWebApi.getAssignments$(null, searchParameters))).data;
            }
            this.isLoading = false;
        }

        const hasParameters = !!this.filterSearchParameters;
        const parametersChanged = (!hadParameters && hasParameters) || (hadParameters && !hasParameters) || (hadParameters && hasParameters);
        if (parametersChanged) {

            if (this.mapDetail === MapDetail.MeasuringPointGroups) {
                this.redrawGroupPolygons();
            } else {
                this.updateLocationMarkerActiveStates();
            }
        }
    }

    showMeasuringPointLocations() {
        if (this.showingMeasuringPointLocations) {
            this.updateLocationMarkerActiveStates();
            return;
        }

        this.showingMeasuringPointLocations = true;

        // When the user is not logged in yet, we don't subscribe to SignalR updates
        if (this.globalEventsService.getCurrentRights()?.hasBackendRight(BackendRights.ViewMeasuringPoint) !== true) {
            return;
        }

        const subscription = this.locationRealtimeService.subscribe(this.onRealtimeLocationChanged);
        this.subscriptionManager.add("realtimeLocationChanged", subscription); // This makes sure we unsubscribe when this component is destroyed

        this.mapDataService.subscribeToMeasuringPointLocations(this.mapMeasuringPointDataServiceKey, measuringPointLocations => {

            if (this.ownerId) {
                // Example usage: if selecting measuringpoints for a scenario, they should be long to the owner of the scenario
                measuringPointLocations = measuringPointLocations.filter(x => x.ownerId === this.ownerId);
            }

            // In case of subscribing with cached data + new data retrieved from servers
            // I noticed markers being added twice
            if (this.loadedMeasuringPointLocations && lodash.isEqual(this.loadedMeasuringPointLocations, measuringPointLocations)) return;

            // Groups already clears map before loading groups
            // No reason to do again
            if (this.mapDetail !== MapDetail.MeasuringPointGroups || this.loadedMeasuringPointLocations) {
                this.clearMarkers();
                this.clearSelected();
            }

            if (!measuringPointLocations.length) {
                this.clearSelected();
                this.clearMap(true);
            }

            this.loadedMeasuringPointLocations = measuringPointLocations;

            this.clearSelectedIfNoLongerPresent();

            for (const measuringPointLocation of measuringPointLocations) {
                this.addLocation(measuringPointLocation, false);
            }

            this.redrawMapClusterer();

            TimerUtils.print();

            this.searchbutton?.repredictPlaces();
            this.dataLoaded.emit();
        }, null, this.forceUpdate);
    }

    hideMeasuringPointLocations() {
        this.loadedMeasuringPointLocations = null;
        this.showingMeasuringPointLocations = false;
        this.mapDataService.unsubscribe(this.mapMeasuringPointDataServiceKey);
    }

    private onRealtimeLocationChanged = (args: ILocationChangedArguments) => {
        this.zone.runOutsideAngular(() => {
            for (const details of args.data) {
                const locationMarker = this.getLocationMarkerById(details.id);
                if (!locationMarker) return;

                // coordinate
                locationMarker?.context?.updateCoordinate(details.lat, details.lng);
                this.animateMarkerToNewPosition(locationMarker.marker, details.lat, details.lng);

                // icon
                const icon = this.mapIconService.getIconState(details.iconStateId);
                if (icon) {
                    // Two unlikely sitations:
                    // We received a SignalR event before the domain data is loaded
                    // A new icon was added to the domaindata, but we don't have it yet
                    locationMarker?.updateIcon(icon);
                    locationMarker.marker.setIcon(icon.defaultIcon);
                }

                // code
                locationMarker.context.updateCode(details.code);
                locationMarker.marker.setLabelContent(details.code);
            }
        });
    };

    private animateMarkerToNewPosition(marker: IBasicMarker, latitude: number, longitude: number) {
        // We provide a smooth animation that is less confusing for the user
        const animationDuration = 1000;
        const startLatitude = marker.getPosition().lat();
        const startLongitude = marker.getPosition().lng();

        const deltaLatitude = latitude - startLatitude;
        const deltaLongitude = longitude - startLongitude;
        let animationStartTime: number | null = null;

        // The function that will be executed for every frame of the animation
        function animateMarker(timestamp: number) {
            if (!animationStartTime) {
                animationStartTime = timestamp;
            }
            const elapsedTime = timestamp - animationStartTime;
            const progress = Math.min(elapsedTime / animationDuration, 1);

            marker.setPosition(
                new google.maps.LatLng(
                    startLatitude + progress * deltaLatitude,
                    startLongitude + progress * deltaLongitude
                )
            );

            if (progress < 1) {
                // Request the next frame to continue the animation
                requestAnimationFrame(animateMarker);
            }
        }

        // Start the animation
        requestAnimationFrame(animateMarker);
    }



    //#endregion Location / Measuring Points Logic

    //#region Group Logic

    showGroups() {
        this.mapDataService.unsubscribe(this.mapDataServiceKey);
        this.mapDetail = MapDetail.MeasuringPointGroups;

        this.clearAll();
        this.showMeasuringPointLocations();

        this.mapDataService.subscribeToGroupSummaries(this.mapDataServiceKey, groups => {
            if (this.ownerId) {
                groups = groups.filter(x => x.ownerId === this.ownerId);
            }

            if (!groups.length) {
                this.clearSelected();
                this.clearMap();
            }

            this.groupSummaries = groups;

            this.clearSelectedIfNoLongerPresent();

            if (this.mapDataService.editGroup?.id && !this.groupSummaries.map(x => x.id).contains(this.mapDataService.editGroup.id)) {
                this.setEditGroup(null);
            }

            this.clearGroupPolygons();
            this.redrawGroupPolygons();
        });

        this.mapDataService.subscribeToEditGroupUpdate(this.mapDataServiceKey, _ => {
            // This will create the group markers for any newly added measuringpoints
            this.createGroupMarkers(false);
        });
    }

    addFocusGroup(groupId: number, centerOn = false): boolean {
        let result = false;
        if (!this.focusedGroupIds.contains(groupId)) {
            this.focusedGroupIds.push(groupId);
            result = true;
        };

        const polygon = this.getGroupPolygon(groupId);
        if (polygon) {
            polygon.focus();

            if (centerOn) {
                polygon.center();
            }
        }

        return result;
    }

    removeFocusGroup(groupId: number): boolean {
        if (!this.focusedGroupIds.contains(groupId)) return false;

        this.focusedGroupIds = this.focusedGroupIds.remove(groupId);

        if (!this.mapDataService.editGroup || groupId !== this.mapDataService.editGroup.id) {
            const polygon = this.getGroupPolygon(groupId);
            if (polygon) polygon.blur();
        }

        return true;
    }

    setEditGroup(group: IGroup, centerOn = false) {
        if (this.mapDataService.editGroup === group || (this.mapDataService.editGroup && group && this.mapDataService.editGroup.id === group.id)) return;

        if (this.mapDataService.editGroup) {
            this.clearGroupMarkers();
        }

        // Run the following without change detection
        this.zone.runOutsideAngular(() => {
            // Visually deselect previous polygon
            const previousEditGroup = this.mapDataService.editGroup;
            this.mapDataService.editGroup = group;

            if (previousEditGroup) {

                // Blur if not focused nor active
                if (!this.focusedGroupIds.contains(previousEditGroup.id)) {
                    const editGroupPolygon = this.getGroupPolygon(previousEditGroup.id);
                    if (editGroupPolygon) editGroupPolygon.blur();
                }
            }

            // Visually select new polygon
            if (group) {

                if (this.groupPolygons) {
                    for (const polygon of this.groupPolygons) {
                        polygon.hide();
                    }
                }

                const groupPolygon = this.getGroupPolygon(group.id);
                if (groupPolygon) {
                    groupPolygon.show();
                    groupPolygon.focus(false);

                    if (centerOn) {
                        groupPolygon.center();
                    }
                }

            } else {
                this.redrawGroupPolygons();
            }

            this.createGroupMarkers(false);
        });
    }

    private createGroupMarkers(showOnCreate = false) {
        if (!this.mapDataService.editGroup) return;

        for (const groupMeasuringPoint of this.mapDataService.editGroup.measuringPoints) {

            const locationMarker = this.getLocationMarkerById(groupMeasuringPoint.measuringPoint.locationId);
            if (!locationMarker) {
                console.error("Group's LocationMarker not found", groupMeasuringPoint.measuringPoint);
                continue;
            }

            this.createGroupMarker(locationMarker, showOnCreate);
            // locationMarker.marker.setForceShowLabel(true);
        }
    }

    private createGroupMarker(locationMarker: LocationMarker, showOnCreate = false) {
        let created = false;

        if (!locationMarker.groupMarker) {
            // https://medium.com/@caroso1222/angular-pro-tip-how-to-dynamically-create-components-in-body-ba200cc289e6
            const groupMarkerComponentRef = this.componentFactoryResolver.resolveComponentFactory(GroupMarkerComponent).create(this.injector);
            const groupMarkerComponent = groupMarkerComponentRef.instance;

            groupMarkerComponent.gmap = this;
            groupMarkerComponent.marker = locationMarker.marker;
            groupMarkerComponent.setLocation(locationMarker.context.locationSummary as ILocationSummary);

            this.applicationRef.attachView(groupMarkerComponentRef.hostView);

            this.componentRefs.push(groupMarkerComponentRef);

            locationMarker.groupMarker = groupMarkerComponent;

            this.setLocationMarkerActiveState(locationMarker);

            created = true;
        }

        if (created && showOnCreate) {
            this.closeGroupMarkers();
            locationMarker.groupMarker.show();
        }
    }

    closeGroupMarkers() {
        const relevantMarkers = this.locationMarkers.filter(x => x.groupMarker && x.groupMarker.overlay.getMap());

        for (const locationMarker of relevantMarkers) {
            if (locationMarker.groupMarker) {
                locationMarker.groupMarker.hide();
            }
        }
    }

    clearGroupMarkers() {
        for (const locationMarker of this.locationMarkers) {
            this.removeGroupMarker(locationMarker);
            locationMarker.marker.setForceShowLabel(false);
        }

        this.clearComponentRefs();
    }

    removeGroupMarker(locationMarker: LocationMarker, removeFromComponentList = false) {
        if (!locationMarker.groupMarker) return;

        const componentRef = this.componentRefs.find(x => x.instance === locationMarker.groupMarker);
        this.clearComponentRef(componentRef, removeFromComponentList);
        delete locationMarker.groupMarker;
    }

    cleanIrrelevantGroupMarkers() {
        const func = () => {
            const irrelevantMarkers = this.locationMarkers.filter(x =>
                x.groupMarker &&
                !x.groupMarker.isInGroup &&
                !x.groupMarker.overlay.getMap()
            );

            for (const irrelevantMarker of irrelevantMarkers) {
                this.removeGroupMarker(irrelevantMarker, true);
            }
        };

        this.eventService.processEvent(this, func);
    }

    getGroupPolygon(groupId: number): GroupPolygon {
        return this.groupPolygons && this.groupPolygons.find(x => x.group.id === groupId);
    }

    private redrawGroupPolygons() {
        if (!this.groupSummaries || this.mapDetail !== MapDetail.MeasuringPointGroups) return;

        const showPolygon = (group: IGroupSummary) => {
            const groupPolygon = this.addGroupPolygon(group);

            if (!this.filterResult || this.filterResult.find(x => x.id === group.id)) {
                groupPolygon.show();
            }
        };

        const hidePolygon = (group: IGroupSummary) => {
            const groupPolygon = this.getGroupPolygon(group.id);
            if (groupPolygon) {
                groupPolygon.hide();
            }
        };

        if (this.mapDataService.editGroup) {
            const groupSummary = this.groupSummaries.find(x => x.id === this.mapDataService.editGroup.id);
            if (groupSummary) {
                showPolygon(groupSummary);
            }
        } else {
            for (const group of this.groupSummaries) {
                if (!this.filterResult || this.filterResult.find(x => x.id === group.id)) {
                    showPolygon(group);
                } else {
                    hidePolygon(group);
                }
            }
        }
    }

    addGroupPolygon(groupSummary: IGroupSummary): GroupPolygon {
        const selectedGroups = this.selectionService.getSelectedGroups();

        const existingGroupPolygon = this.getGroupPolygon(groupSummary.id);
        if (existingGroupPolygon) return existingGroupPolygon;

        let groupPolygon: GroupPolygon;

        this.zone.runOutsideAngular(() => {
            groupPolygon = new GroupPolygon(this, this.mapDataService, groupSummary, this.zone, x => { this.toggleGroupPolygonSelect(x); });
            this.groupPolygons.push(groupPolygon);

            if (this.mapDataService.editGroup && this.mapDataService.editGroup.id === groupSummary.id) {
                groupPolygon.focus(false);
            } else if (selectedGroups.find(x => x.id == groupSummary.id)) {
                // openning again map in groups context so select all groups which were previously selected
                this.addFocusGroup(groupSummary.id, false);
            }
        });

        return groupPolygon;
    }

    removeGroupPolygon(groupPolygon: GroupPolygon) {
        groupPolygon.destroy();

        this.groupPolygons = this.groupPolygons.remove(groupPolygon);
    }

    private clearGroupPolygons() {
        if (this.groupPolygons) {
            for (const groupPolygon of this.groupPolygons) {
                groupPolygon.destroy();
            }
        }

        this.groupPolygons = [];
    }

    //#endregion Group Logic

    //#region Organizations Logic

    showOrganizations() {
        // Clear all other subscriptions, only interested in organizations now
        this.mapDataService.unsubscribe(this.mapDataServiceKey);

        this.mapDetail = MapDetail.Organizations;

        this.clearLabels();
        this.hideMeasuringPointLocations();
        this.clearAll();

        this.mapDataService.subscribeToOrganizations(this.mapDataServiceKey, organizations => {
            this.clearMap();

            for (let i = 0; i < organizations.length; i++) {
                const organization = organizations[i];
                this.addOrganization(organization);
            }

            this.redrawMapClusterer();

            this.searchbutton?.repredictPlaces();
            this.dataLoaded.emit();
        }, null, this.forceUpdate);

        this.mapDataService.subscribeToOrganizationUpdate(this.mapMeasuringPointDataServiceKey, updatedOrganization => {
            this.removeContext({ id: updatedOrganization.id } as any);
            this.addOrganization(updatedOrganization);
        });
    }

    addOrganization(organization: IOrganization): LocationMarker {
        const options: Partial<BasicMarkerOptions> = {
            enableLabel: this.enableLabels
        };

        const iconModel = this.mapIconService.getOrganizationIcon();
        if (!iconModel) return; // TODO The icons are not loaded yet

        const context = MarkerContext.fromOrganization(organization);

        options.labelColor = iconModel.configuration.default.labelColor;
        const locationMarker = new LocationMarker(context, iconModel, options);

        this.addLocationMarker(locationMarker);
        this.setLocationMarkerActiveState(locationMarker);
        return locationMarker;
    }

    //#endregion Organizations Logic

    //#region Devices Logic

    showDevices() {
        // Clear all other subscriptions, only interested in devices now
        this.mapDataService.unsubscribe(this.mapDataServiceKey);

        this.mapDetail = MapDetail.Devices;

        this.clearLabels();
        this.hideMeasuringPointLocations();
        this.clearAll();

        this.mapDataService.subscribeToDeviceLocations(this.mapDataServiceKey, deviceLocations => {
            if (this.ownerId) {
                deviceLocations = deviceLocations.filter(x => x.ownerId === this.ownerId);
            }

            this.clearMap();

            for (const deviceLocation of deviceLocations) {
                this.addLocation(deviceLocation, false);
            }

            this.redrawMapClusterer();

            this.searchbutton?.repredictPlaces();
            this.dataLoaded.emit();
        }, null, this.forceUpdate);
    }

    clearSelectedMeasuringPoints() {
        this.locationMarkers?.forEach((m) => {
            m.setSelected(false);
        });
    }

    //#endregion Devices Logic

    // #region Assignments

    showAssignments() {
        // Clear all other subscriptions, only interested in assignments now
        this.mapDataService.unsubscribe(this.mapDataServiceKey);
        this.mapDetail = MapDetail.Assignments;

        this.clearLabels();
        this.hideMeasuringPointLocations();
        this.clearAll();

        this.mapDataService.subscribeToAssignmentLocations(this.mapDataServiceKey, assignmentLocations => {
            this.clearMap();
            assignmentLocations.forEach(assignmentLocation => this.addLocation(assignmentLocation, false));
            this.redrawMapClusterer();
            this.dataLoaded.emit();
        }, null, this.forceUpdate);
    }
    // #endregion Assignments

    private createMapOptions() {
        this.map.setOptions({
            disableDefaultUI: true,
            zoomControl: false,
            rotateControl: false,
            clickableIcons: false,
            gestureHandling: "greedy",
            fullscreenControl: false,
            disableDoubleClickZoom: true,
        });
    }

    //#region Helpers

    getPixelFromLatLng(latLng: google.maps.LatLng): google.maps.Point {
        this.createClickedContextOverlay();

        const overlayProjection = this.clickedContextOverlay.getProjection();
        if (!overlayProjection) return null;

        // Container Pixel = x/y in the map's container
        // If map is top-most element, we need to offset it with header height
        return overlayProjection.fromLatLngToContainerPixel(latLng);
    }

    getLatLngFromPixel(point: google.maps.Point): google.maps.LatLng {
        this.createClickedContextOverlay();

        const overlayProjection = this.clickedContextOverlay.getProjection();
        if (!overlayProjection) return null;

        return overlayProjection.fromContainerPixelToLatLng(point);
    }

    private createClickedContextOverlay() {
        if (this.clickedContextOverlay) {
            this.clickedContextOverlay.setMap(null);
            this.clickedContextOverlay = null;
        }

        this.clickedContextOverlay = new google.maps.OverlayView(); // Use to get coords
        this.clickedContextOverlay.draw = () => { };
        this.clickedContextOverlay.onAdd = () => { };
        this.clickedContextOverlay.onRemove = () => { };
        this.clickedContextOverlay.setMap(this.map);
    }

    private addMarker(locationMarker: LocationMarker) {
        if (this.mapClusterer) {
            this.mapClusterer.addMarker(locationMarker.marker);
        } else {
            this.markers = [locationMarker.marker].concat(this.markers || []);
        }
    }

    private removeMarker(locationMarker: LocationMarker, redrawCluster = true) {
        this.markers = this.markers.remove(locationMarker.marker);

        if (this.mapClusterer) {
            this.mapClusterer.removeMarker(locationMarker.marker, redrawCluster);
        }

        if (locationMarker.groupMarker) {
            locationMarker.groupMarker.hide();
        }
    }

    private clearMarkers() {
        this.closeGroupMarkers();

        this.markers = [];
        this.locationMarkers.length = 0;

        if (this.mapClusterer) {
            this.mapClusterer.clear();
        }
    }

    private getLocationMarkerFromMarker(marker: google.maps.marker.AdvancedMarkerElement): LocationMarker {
        return this.locationMarkers.find(x => x.marker === marker);
    }

    getLocationMarker(context: MarkerContext): LocationMarker {
        if (!context) return null;

        const locationId = (context as any).locationId;
        if (locationId) {
            return this.getLocationMarkerById(locationId);
        }

        const deviceSummary = (context as any) as IDeviceSummary;
        if (deviceSummary.measuringPoints) {
            const first = deviceSummary.measuringPoints.takeFirstOrDefault();
            if (first) {
                return this.getLocationMarkerById(first.locationId);
            }
        }

        return this.locationMarkers.find(x => x.context.id === context.id);
    }

    getLocationMarkerById(id: number): LocationMarker {
        return this.locationMarkers.find(x => x.context.id === id);
    }

    redrawMapClusterer() {
        if (!this.mapClusterer) return;

        const func = () => {
            this.zone.runOutsideAngular(() => {
                // Repaint draws on TOP first
                // So you don't get any "blinking" markers
                this.mapClusterer.requestRepaint();
                this.dataLoaded.emit();
            });
        };

        this.eventService.processEvent(this, func);
    }

    private initializeMapClusterer() {
        if (!this.map) return;

        if (this.mapClusterer) {
            if (!environment.production) {
                console.error("Called `createMapClusterer` with existing mapClusterer");
            }
            return;
        }

        // https://developers.google.com/maps/documentation/javascript/marker-clustering
        // Add a marker clusterer to manage the markers.
        this.zone.runOutsideAngular(() => {

            this.mapClusterer = createMapClusterer(this.map,
                this.markers,
                {
                    // It will look for imagePath + [1-5].png
                    imagePath: "/assets/img/markers/blue",
                    imagePathFunction: (markers: google.maps.marker.AdvancedMarkerElement[]) => {

                        let toReturn: string = null;

                        if (markers) {

                            if (this.mapDetail === MapDetail.Devices) {

                                for (const marker of markers) {
                                    const locationMarker = this.getLocationMarkerFromMarker(marker);

                                    const location = locationMarker.context.locationSummary as ILocationWithDevicesSummary;

                                    if (location.alarmSeverityId === AlarmSeverity.High) {
                                        toReturn = "/assets/img/markers/red";
                                    } else if (location.alarmSeverityId === AlarmSeverity.Low) {
                                        toReturn = "/assets/img/markers/orange";
                                    }
                                }
                            }
                        }

                        return toReturn;
                    },
                    maxZoom: Constants.noClusterZoom - 1, // -1, 16 noClusterZoom means 15 is the last zoom on which it should cluster,
                    unloadOutOfRangeMarkers: true,
                });
            this.mapClusterer.setGridSize(this.mapDetail === MapDetail.Organizations ? 30 : 60);
        });

        this.markers = [];
    }

    //#endregion Helpers

    //#region marker events

    /**
     * We're currently not using the events from BasicMapComponent, because the markers are not yet managed in BasicMapComponent.markers.
     * This should be refactored later.
     */
    bindMarkerEvents(marker: google.maps.marker.AdvancedMarkerElement) {
        if ((marker as any).hasAdvancedMapListeners) return;
        (marker as any).hasAdvancedMapListeners = true;

        this.zone.runOutsideAngular(() => {
            google.maps.event.clearInstanceListeners(marker);

            const onClick = (event: google.maps.MapMouseEvent) => {
                if (this.touchService.isLongTouch()) {

                    this.handleMarkerRightClick(new BasicMarkerEvent(marker, event));
                } else {

                    this.handleMarkerClick(new BasicMarkerEvent(marker, event));
                }
            };

            // Some other map events (mouse move, mouse up, drag start, etc) which will happen "at the same time"
            // as a click event will trigger a change detection. As a side effect, the marker click event will also be detected in a change detection cycle.
            // On the other hand, a label is not a part of the map stack, it belongs to a separate layer and that's why its click is handled differently.
            marker.addListener("click", (event: google.maps.MapMouseEvent) => {
                onClick(event);
            });

            // Explicitly run onClick event in the zone to make sure that change detection will be triggered.
            marker.addListener("labelclick", (event: google.maps.MapMouseEvent) => {
                this.zone.run(() => {
                    onClick(event);
                });
            });

            marker.addListener("mousedown", (event: google.maps.MapMouseEvent) => {
                this.touchService.notifyMouseDown();
            });

            marker.addListener("rightclick", (event: google.maps.MapMouseEvent) => {
                this.handleMarkerClick(new BasicMarkerEvent(marker, event));
            });

            if (marker.draggable) {
                marker.addListener("dragstart", (event: google.maps.MapMouseEvent) => {
                    this.handleMarkerDragStart(new BasicMarkerEvent(marker, event));
                });

                marker.addListener("dragend", (event: google.maps.MapMouseEvent) => {
                    this.handleMarkerDragEnd(new BasicMarkerEvent(marker, event));
                });
            }
        });
    }

    //#endregion marker events
}
