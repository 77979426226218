import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { AssignmentStatus, IAssignment } from "src/app/models/assignment";
import { AssignmentApi } from "src/app/resource/assignment.api";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { NavigationService } from "src/app/services/navigation.service";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ModalService } from "src/app/services/modal.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { IProject } from "src/app/models/project";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { ICoordinate, ILocation } from "src/app/models/location";
import { SelectItem } from "primeng/api";
import { JsonUtils } from "src/app/utilities";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { ProjectsService } from "src/app/services/projects.service";

@Component({
    selector: "app-assignments",
    templateUrl: "./assignments.component.html"
})
export class AssignmentsComponent
    extends LazyTableComponent<IAssignment>
    implements OnInit, OnChanges, IComponentCanDeactivate {

    @Input() editCommand = false;
    @Input() deleteCommand = false;
    @Input() projectId: number;
    @Input() requiresInput = false;
    @Input() showCreateAssignment = false;
    @Input() projectCoordinates: ICoordinate;

    private descriptionColumn: TableColumn;
    private projectColumn: TableColumn;
    private organizationColumn: TableColumn;
    private priorityColumn: TableColumn;
    private statusColumn: TableColumn;
    private assignmentColumn: TableColumn;
    private inactiveStatusesRemoved = false;

    private canceledSelectItem: SelectItem;
    private finishedSelectItem: SelectItem;


    canCreateAssignment = false;

    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly assignmentApi: AssignmentApi,
        readonly translateService: TranslateService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly navigationService: NavigationService,
        readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly domainDataService: DomainDataService,
        private readonly impersonationService: ImpersonationService,
        private readonly projectsService: ProjectsService
    ) {

        super("assignments.component", elementRef, assignmentApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.canCreateAssignment = this.rights?.hasBackendRight(BackendRights.CreateAssignment);

        this.priorityColumn = new TableColumn("priority", "assignment.priority", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(this.priorityColumn);

        this.assignmentColumn = new TableColumn("nameAndParentAssignmentName", "general.name", { filterType: FilterType.Text, sortable: true });
        this.addColumn(this.assignmentColumn);
        this.descriptionColumn = new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(this.descriptionColumn);

        this.projectColumn = new TableColumn("project", "general.project", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact, filterFunc: () => !this.projectId });
        this.addColumn(this.projectColumn);

        this.organizationColumn = new TableColumn("organization", "general.organization", {
            filterType: FilterType.Dropdown, sortable: true, visibility: ColumnVisibility.HideCompact,
            width: 250, resizable: false, displayDropdownFilter: true
        });
        this.addColumn(this.organizationColumn);

        this.addColumn(new TableColumn("start", "assignment.start", { sortable: true, visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("end", "assignment.end", { sortable: true, visibility: ColumnVisibility.HideCompact }));

        this.statusColumn = new TableColumn("statusId", "general.status", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(this.statusColumn);

        // TODO: Add column once planning is implemented
        // this.addColumn(new TableColumn("team", "assignment.team", { sortable: true, visibility: ColumnVisibility.HideCompact }));

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (assignment) => this.navigateToDetail(assignment),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditAssignment),
        });
        this.registerCommand({
            text: "form.view",
            icon: "eye-solid",
            click: (assignment) => this.navigateToDetail(assignment),
            validFunc: () =>
                this.editCommand && this.rights.hasBackendRight(BackendRights.ViewAssignment) && !this.rights?.hasBackendRight(BackendRights.EditAssignment),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (assignment) => this.delete(assignment),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteAssignment),
        });

        this.domainDataService.get(DomainData.AssignmentPriorityValue).then(priorities => {
            this.priorityColumn.filterOptions = priorities;
        });

        this.domainDataService.get(DomainData.AssignmentStatus).then(statuses => {
            this.statusColumn.filterOptions = statuses;
            // if projectId is not set then table for map is being loaded and there we want to see only active assignments
            if (!this.projectId) {
                this.removeInactiveStatuses();
                this.inactiveStatusesRemoved = true;
            }
        });

        this.services.mapDataService.subscribeToOrganizations(
            this.mapDataServiceFilterKey,
            (organizations) => {
                this.updateOrganizationColumn();
                this.organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                    organizations,
                    (x) => x.id,
                    (x) => x.name,
                    false
                );
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const projectIdChange = changes["projectId"];
        const inputChange = changes["requiresInput"] || projectIdChange;

        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    private removeInactiveStatuses() {
        const canceledIndex = this.statusColumn.filterOptions.findIndex(x => x.value === AssignmentStatus.Cancelled);
        if (canceledIndex > -1) {
            this.canceledSelectItem = JsonUtils.deepClone(this.statusColumn.filterOptions[canceledIndex]);
            this.statusColumn.filterOptions = this.statusColumn.filterOptions.remove(this.statusColumn.filterOptions[canceledIndex]);
        }

        const finishedIndex = this.statusColumn.filterOptions.findIndex(x => x.value === AssignmentStatus.Finished);
        if (finishedIndex > -1) {
            this.finishedSelectItem = JsonUtils.deepClone(this.statusColumn.filterOptions[finishedIndex]);
            this.statusColumn.filterOptions = this.statusColumn.filterOptions.remove(this.statusColumn.filterOptions[finishedIndex]);
        }
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    setProject(project: IProject) {
        this.projectId = project?.id;

        // then we're on project->Assignments and there we need all assignment statuses
        if (this.inactiveStatusesRemoved) {
            this.statusColumn.filterOptions.push(this.finishedSelectItem);
            this.statusColumn.filterOptions.push(this.canceledSelectItem);
        }

        this.updateRelevantColumns();
        this.reload();
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        searchParameters.filter.push({ field: "onlyLowLevelAssignments", value: true, operator: FilterOperator.equals });

        if (!this.projectId) {
            // we don't want to show non-active assignments (table on map)
            searchParameters.filter.push({ field: "onlyActiveAssignments", value: true, operator: FilterOperator.equals });
        }

        if (this.projectId) {
            searchParameters.filter.push({ field: "projectId || parentAssignment.projectId", value: this.projectId, operator: FilterOperator.equals });
        }
        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("assignment", "project");
        serviceRequestOptions.includes.add("assignment", "priority");
        serviceRequestOptions.includes.add("project", "organization");
        serviceRequestOptions.includes.add("assignment", "currentStatus");
        serviceRequestOptions.includes.add("assignment", "parentAssignment");
        serviceRequestOptions.includes.add("assignment", "location");
        return serviceRequestOptions;
    }

    private navigateToDetail(assignment: IAssignment) {
        this.navigationService.toAssignment(assignment);
    }

    private delete(assignment: IAssignment) {

        if (!this.rights?.hasBackendRight(BackendRights.DeleteAssignment)) return;

        const modalBody = this.translateService.instant("assignment.deleteConfirmation", { name: assignment.name }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(assignment));
    }

    private handleDelete(assignment: IAssignment) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        this.assignmentApi.delete$(assignment.id).subscribe(onDeleteSuccess, () => { });
    }

    createAssignment() {
        // no navigation after creation, just close the dialog
        this.manageLocationComponent.navigateAfterCreation = false;

        const onSuccessfullySelectedLocation = (newLocation: ILocation) => {
            if (!newLocation) return;
            this.projectsService.selectedProjectId = this.projectId
            this.navigationService.createAssignmentAtLocation(newLocation.coordinate);
        };

        // we are going to create a new location
        this.manageLocationComponent.create(this.projectCoordinates, onSuccessfullySelectedLocation.bind(this));
    }


    private updateProjectColumn() {
        if (!this.projectColumn) return;

        if (this.rights?.hasBackendRight(BackendRights.ViewProject)) {
            if (!this.columns.contains(this.projectColumn) && this.columns.contains(this.descriptionColumn)) {
                // To the right of assignment column
                const index = this.columns.findIndex(x => x.field === this.descriptionColumn.field);
                this.addColumn(this.projectColumn, index + 1);
            }
        } else {
            if (this.columns.contains(this.projectColumn)) this.removeColumn(this.projectColumn);
        }
    }

    private updateOrganizationColumn() {
        if (!this.organizationColumn || !this.projectColumn) return;

        const authorizationInfo = this.globalEventsService.getAuthorizationInfo();
        const belongingToMultipleOrganizations =
            (authorizationInfo?.isDomainAdministrator && !this.impersonationService.isImpersonating())
            || authorizationInfo?.user.linkedOrganizationIds.length > 1;

        if (belongingToMultipleOrganizations) {
            if (this.columns.contains(this.organizationColumn) || !this.columns.contains(this.projectColumn)) return;

            // To the left of projects column
            const index = this.columns.findIndex(x => x.field === this.projectColumn.field);
            this.addColumn(this.organizationColumn, index);
        } else {
            this.removeColumn(this.organizationColumn);
        }
    }

    updateRelevantColumns() {
        super.updateRelevantColumns();
        this.updateProjectColumn();
        this.updateOrganizationColumn();
    }
}
