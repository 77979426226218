import { Injectable } from "@angular/core";
import { ApiServiceBase } from "./api";
import { Observable } from "rxjs";
import { ILocationLog } from "../models/measuring-point-location-history";

@Injectable({
    providedIn: "root"
})
// This is the API to retrieve only the current status
export class MeasuringPointLocationHistoryApi extends ApiServiceBase {
    getRoute(): string {
        return "MeasuringPoints/{measuringPointId}/LocationHistory";
    }

    getSummary$(measuringPointId: number, from: Date, until: Date, snapCoordinates: boolean = false): Observable<ILocationLog[]> {
        const url = `${super.getUrl({ measuringPointId: measuringPointId.toString() })}/Summary?from=${from.toMetanousDateString()}&until=${until.toMetanousDateString()}&snapCoordinates=${snapCoordinates}`;
        return this.http.get<ILocationLog[]>(url);
    }
}