<div class="m-form-group"
     *ngIf="formVisible"
     formGroupName="tubeConfiguration">
    <div class="p-16 m-border-bottom">
        <h3>{{"uploadDetailsDialog.tubeConfiguration" | translate }}</h3>
    </div>

    <div class="p-16"
         style="display:inline-grid; grid-template-columns: auto min-content auto auto; gap:16px; align-items: center;">

        <!-- Settings for sensor 1-->

        <label for="tube1">
            {{"uploadDetailsDialog.connectionTube1" | translate}}
        </label>

        <div>
            <p-dropdown [options]="tubeOptions"
                        id="tube1"
                        [formControl]="form.get('tube1')"
                        [panelStyle]="{'width':'100%'}"
                        #tube1Input></p-dropdown>

            <app-form-validation [control]="form.get('tube1')"
                                 [input]="tube1Input">
            </app-form-validation>
        </div>

        <label for="tube1OffsetInMilliseconds"
               style="display:flex; align-items: center;">
            {{"uploadDetailsDialog.tubeOffsetInMilliseconds" | translate}}

            <app-icon-button icon="info"
                             text="{{'deviceUpload.tubeDifference' | translate}}"
                             (click)="onShowOffsetPopup(1)">
            </app-icon-button>
        </label>

        <div style="display:grid; grid-auto-flow: row; gap:2px;">
            <div class="m-input-group"
                 style="width:30px;">
                <input name="tube1OffsetInMilliseconds"
                       class="p-inputtext"
                       step="0.1"
                       type="number"
                       [max]="1000"
                       [formControl]="form.get('tube1OffsetInMilliseconds')"
                       id="tube1OffsetInMilliseconds"
                       #tube1OffsetInMillisecondsInput>
                <div class="m-input-group-addon">{{"measurements.ms" | translate}}</div>
            </div>

            <app-form-validation [control]="form.get('tube1OffsetInMilliseconds')"
                                 [input]="tube1OffsetInMillisecondsInput">
            </app-form-validation>

            <label class="m-input-checkbox-block"
                   [hidden]="form.get('tube1OffsetInMilliseconds').value === 0">
                <input [formControl]="form.get('tube1OffsetOnlyWhenNotClipped')"
                       type="checkbox">{{"uploadDetailsDialog.onlyWhenNotClipped" | translate }}
            </label>
        </div>

        <!-- Settings for sensor 2-->

        <label for="tube2">
            {{"uploadDetailsDialog.connectionTube2" | translate}}
        </label>

        <div>
            <p-dropdown [options]="tubeOptions"
                        id="tube2"
                        [formControl]="form.get('tube2')"
                        [panelStyle]="{'width':'100%'}"
                        #tube2Input></p-dropdown>

            <app-form-validation [control]="form.get('tube2')"
                                 [input]="tube2Input">
            </app-form-validation>
        </div>


        <label for="tube2OffsetInMilliseconds"
               style="display:flex; align-items: center;">
            {{"uploadDetailsDialog.tubeOffsetInMilliseconds" | translate}}

            <app-icon-button icon="info"
                             text="{{'deviceUpload.tubeDifference' | translate}}"
                             (click)="onShowOffsetPopup(2)">
            </app-icon-button>
        </label>

        <div style="display:grid; grid-auto-flow: row; gap:2px;">
            <div class="m-input-group"
                 style="width:30px;">
                <input name="tube2OffsetInMilliseconds"
                       class="p-inputtext"
                       step="0.1"
                       type="number"
                       [max]="1000"
                       [formControl]="form.get('tube2OffsetInMilliseconds')"
                       id="tube2OffsetInMilliseconds"
                       #tube2OffsetInMillisecondsInput>
                <div class="m-input-group-addon">{{"measurements.ms" | translate}}</div>
            </div>

            <app-form-validation [control]="form.get('tube2OffsetInMilliseconds')"
                                 [input]="tube2OffsetInMillisecondsInput">
            </app-form-validation>

            <label class="m-input-checkbox-block"
                   [hidden]="form.get('tube2OffsetInMilliseconds').value === 0">
                <input [formControl]="form.get('tube2OffsetOnlyWhenNotClipped')"
                       type="checkbox">{{"uploadDetailsDialog.onlyWhenNotClipped" | translate }}
            </label>
        </div>

        <!-- Sensor distance -->
        <label for="tubeDistanceCm">
            {{"uploadDetailsDialog.distanceTubes" | translate}}
        </label>

        <div>
            <div class="m-input-group">
                <input name="tubeDistanceCm"
                       class="p-inputtext"
                       step="0.1"
                       type="number"
                       [formControl]="form.get('tubeDistanceCm')"
                       id="tubeDistanceCm"
                       (input)="tubeDistanceChanged($event)"
                       #tubeDistanceCmInput>
                <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
            </div>
            <span *ngIf="showTubeDistanceWarning"
                  class="warning"
                  style="color: #ff6600">
                *{{"deviceLinkWarnings.tubeDistance" | translate}}
            </span>
            <app-form-validation [control]="form.get('tubeDistanceCm')"
                                 [input]="tubeDistanceCmInput">
            </app-form-validation>
        </div>
    </div>
</div>