import { ApiServiceBase } from "../api";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DownloadFileService } from "src/app/services/download-file.service";
import { UploadFileService } from "src/app/services/upload-file.service";
import { CacheService } from "src/app/services/cache.service";
import { ErrorService } from "src/app/services/error.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";

@Injectable()
export class AvsApiService<T> extends ApiServiceBase {

    constructor(
        http: HttpClient,
        downloadFileService: DownloadFileService,
        uploadFileService: UploadFileService,
        cacheService: CacheService,
        errorService: ErrorService,
        configurationService: ConfigurationService) {

        super(http, downloadFileService, uploadFileService, cacheService, errorService, configurationService);
    }

    protected getApiVersion(): string {
        return "integrations/avs/v1";
    }

    create$(model: T): Observable<void> {
        return this.http.post<void>(this.getUrl(), [model]);
    }

}