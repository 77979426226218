export class TrafficLightData {
    serialNo: string;
    batteryVoltage: number;
    latitude: number;
    longitude: number;
    status: string;
    head: string;
    role: string;
    radarDetector: string;
    simInformation: SimInformation;
}

export class SimInformation {
    provider: string;
    connState: string;
    connectionType: string;
    revision: string;
    rssi: number;
}