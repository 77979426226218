import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalEventsService } from "./global-events-service";

export enum MapDetail {
    MeasuringPoints = 0,
    Devices = 1,
    Organizations = 2,
    MeasuringPointGroups = 3,
    Assignments = 5,
    Projects = 6,
    Scenarios = 7
}

@Injectable({
    providedIn: "root"
})
export class MapDetailService {

    get currentMapDetail(): MapDetail {
        const url = window.location.pathname;
        if (!url.startsWith("/map")) return null;
        if (url.startsWith("/map/device")) return MapDetail.Devices;
        if (url.startsWith("/map/organization")) return MapDetail.Organizations;
        if (url.startsWith("/organization")) return MapDetail.Organizations;
        if (url.startsWith("/map/groups")) return MapDetail.MeasuringPointGroups;
        if (url.startsWith("/map/assignment") || url.startsWith("/map/task") || url.startsWith("/map/parkingban")) return MapDetail.Assignments;
        if (url.startsWith("/map/project")) return MapDetail.Projects;

        return MapDetail.MeasuringPoints;
    }

    constructor(
        private readonly router: Router,
        private readonly globalEventsService: GlobalEventsService
    ) {
    }

    isCurrentMapDetail(mapDetail: MapDetail) {
        return this.router.url.startsWith("/map") && this.currentMapDetail === mapDetail;
    }

    async navigateToMapDetail(mapDetail: MapDetail = null): Promise<boolean> {
        return this.router.navigate([this.getUrlForMapDetail(mapDetail || this.currentMapDetail)]);
    }

    getUrlForMapDetail(mapDetail: MapDetail): string {
        if (!mapDetail) return "/locations";

        if (mapDetail === MapDetail.Organizations && !this.globalEventsService.hasMultipleOrganizations()) {
            mapDetail = MapDetail.MeasuringPoints;
        }

        if (mapDetail === MapDetail.MeasuringPoints) return "/locations";
        if (mapDetail === MapDetail.Devices) return "/map/devices";
        if (mapDetail === MapDetail.Organizations) return "/map/organizations";
        if (mapDetail === MapDetail.MeasuringPointGroups) return "/map/groups";
        if (mapDetail === MapDetail.Assignments) return "/map/assignments";
        if (mapDetail === MapDetail.Projects) return "/map/projects";
        return "/map";
    }
}
