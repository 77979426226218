<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         scrollHeight="flex"
         sortField="name"
         dataKey="id"
         [hidden]="hidden"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-organization>
        <tr [pSelectableRow]="organization">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group"
                         style="margin-top: 0;">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(organization)"
                                             *ngIf="command.rowValidFunc(organization)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{organization[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>