<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingBatteryDischargeTable ? "batteryDischargeTables.edit" : "batteryDischargeTables.create") | translate}}
    </p-header>

    <form *ngIf="manageBatteryDischargeTableForm"
          class="p-16"
          [formGroup]="manageBatteryDischargeTableForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageBatteryDischargeTableForm.get('name').showErrors }">

            <label class="col-4"
                   for="name">
                {{"general.name" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       id="name"
                       class="p-inputtext"
                       formControlName="name"
                       appAutofocus
                       #nameInput />

                <app-form-validation [control]="manageBatteryDischargeTableForm.get('name')"
                                     [input]="nameInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             formGroupName="entries"
             style="max-height: 400px; overflow-y: auto;">
            <div class="col-4">
                <div class="flex align-center">
                    <label class="mr-16">
                        {{"batteryDischargeTables.entries" | translate}}
                    </label>

                    <div class="ml-auto">
                        <app-icon-button icon="add"
                                         text="batteryDischargeTables.addEntry"
                                         (click)="addEntry()">
                        </app-icon-button>
                    </div>
                </div>
            </div>

            <div class="col-8">
                <div class="row">
                    <div class="col-5 mb-8">
                        <label>
                            {{"batteryDischargeTables.chargePercentage" | translate}}
                        </label>
                    </div>

                    <div class="col-5 mb-8">
                        <label>
                            {{"batteryDischargeTables.voltage" | translate}}
                        </label>
                    </div>
                </div>

                <div *ngFor="let entryForm of entriesFormArray.controls; let i = index"
                     formGroupName="{{i}}"
                     class="m-form-group row">

                    <div class="col-5">
                        <div class="m-input-group">
                            <input type="number"
                                   id="chargePercentage{{i}}"
                                   class="p-inputtext"
                                   formControlName="chargePercentage"
                                   placeholder="{{'batteryDischargeTables.chargePercentage' | translate}}"
                                   #chargePercentageInput>
                            <div class="m-input-group-addon">
                                {{"measurements.percentage" | translate}}
                            </div>
                        </div>

                        <app-form-validation [control]="entryForm.get('chargePercentage')"
                                             [input]="chargePercentageInput">
                        </app-form-validation>
                    </div>

                    <div class="col-5">
                        <div class="m-input-group">
                            <input type="number"
                                   id="voltage{{i}}"
                                   class="p-inputtext"
                                   formControlName="voltage"
                                   placeholder="{{'batteryDischargeTables.voltage' | translate}}"
                                   step="0.1"
                                   #voltageInput>
                            <div class="m-input-group-addon">
                                {{"measurements.volt" | translate}}
                            </div>
                        </div>

                        <app-form-validation [control]="entryForm.get('voltage')"
                                             [input]="voltageInput">
                        </app-form-validation>
                    </div>

                    <div class="col-2">
                        <app-icon-button *ngIf="i>0"
                                         icon="delete"
                                         text="form.delete"
                                         (click)="deleteEntry(i)">
                        </app-icon-button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>