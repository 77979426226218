import { AfterViewChecked, Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FilterOperator, ISearchResult, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ISymbol } from "src/app/models/symbol";
import { ISymbolSet } from "src/app/models/symbol-set";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SymbolSetApi } from "src/app/resource/symbol-set.api";
import { ToastService } from "src/app/services/toast.service";
import { cloneDeep } from "lodash";
import { FuzzySearchInputComponent } from "src/app/modules/shared/components/fuzzy-search-input/fuzzy-search-input.component";
import { SymbolApi } from "src/app/resource/symbol.api";
import { forkJoin } from "rxjs";

@Component({
    selector: "app-symbol-selector-dialog",
    templateUrl: "./symbol-selector-dialog.component.html"
})
export class SymbolSelectorDialogComponent extends DialogComponentBase implements AfterViewChecked {
    @ViewChild(FuzzySearchInputComponent, { static: false }) fuzzySearchInputComponent: FuzzySearchInputComponent;

    submitting: boolean;
    searchText: string;
    fetchedSymbolSets: ISymbolSet[];
    symbolSets: ISymbolSet[];
    selectedSymbol: ISymbol;
    refreshView = false;
    cachedSymbols: ISymbol[] = [];
    cachedSymbolSets: { [symbolSetId: number]: ISymbol[] } = {};
    filteredCachedSymbols: ISymbol[] = [];
    constructor(
        private readonly symbolSetApi: SymbolSetApi,
        private readonly symbolApi: SymbolApi,
    ) {
        super();
        this.symbolSets = [];
        // load symbol sets
        const buildSymbolSetHierarchy = (result: ISearchResult<ISymbolSet>) => {
            // check if there's any symbolset
            if (result.data.length > 0) {
                // set fetched symbolsets
                this.fetchedSymbolSets = cloneDeep(result.data);
                // build symbolset hierarchy
                this.buildSymbolSetHierarchy(cloneDeep(result.data));
            }

        };
        this.symbolSetApi.search$().subscribe(buildSymbolSetHierarchy);

    }

    reset() {
        this.fuzzySearchInputComponent.clearFilter();
    }

    private buildSymbolSetHierarchy(symbolSets: ISymbolSet[]) {
        // get all symbolsets with no parent
        this.symbolSets = symbolSets.filter(x => !x.parentSymbolSetId);
        let foundSymbolSets = this.symbolSets;
        // as long as there's still a symbolset that's not in this.symbolSets, keep looping
        while (symbolSets.filter(x => !foundSymbolSets.map(x => x.id).contains(x.id)).length > 0) {
            // get all symbolsets that are not in this.symbolSets
            const symbolSetsNotInSymbolSets = symbolSets.filter(x => this.symbolSets.map(x => x.id).contains(x.parentSymbolSetId));
            // add them to found list
            foundSymbolSets = foundSymbolSets.concat(symbolSetsNotInSymbolSets);
            // loop through all symbolsets that are not in this.symbolSets
            symbolSetsNotInSymbolSets.forEach(symbolSetNotInSymbolSets => {
                // get the parent symbolset
                const parentSymbolSet = this.symbolSets.find(x => x.id === symbolSetNotInSymbolSets.parentSymbolSetId);
                // add the child symbolset to the parent symbolset
                if (!parentSymbolSet.children) parentSymbolSet.children = [];
                parentSymbolSet.children.push(symbolSetNotInSymbolSets);
            });
        }
    }


    callback: (symbol: ISymbol) => void;
    openFirstLevelDetails() {
        // get all details elements that are not nested
        const detailsElements = document.querySelectorAll("details");
        detailsElements.forEach((element) => {
            const details = element as HTMLDetailsElement;
            if (details.classList.contains("is-toplevel")) {
                details.open = true;
            }
        });
    }
    show(callback: (symbol: ISymbol) => void) {
        this.callback = callback;
        this.openDialog();
    }
    protected override afterShow(): void {
        // query selector: get input under class fuzzy-search-input
        const input = document.querySelector(".fuzzy-search-input input") as HTMLInputElement;
        input.focus();
        input.select();
    }

    override onShow(): void {
        super.onShow();
        this.openFirstLevelDetails();
    }


    submit() {
        this.submitting = true;
        this.callback(this.selectedSymbol);
        this.close();
        this.selectedSymbol = null;

        this.submitting = false;
    }

    encodeSvg(svg: string): string {
        return encodeURIComponent(svg);
    }

    isSelected(symbol: ISymbol): boolean {
        if (!this.selectedSymbol) return false;
        return this.selectedSymbol.id === symbol.id;
    }
    selectSymbol(symbol: ISymbol) {
        this.selectedSymbol = symbol;
    }
    handleFuzzySearchStart(value: string) {
        if (value === this.searchText) return;
        this.searchText = value;

        if (!this.searchText || this.searchText.length == 0) {
            this.buildSymbolSetHierarchy(cloneDeep(this.fetchedSymbolSets));
            this.refreshView = true;
            return;
        } else {
            // search for symbols in api call
            const searchParams = new SearchParameters();
            searchParams.filter = [{ field: "code", operator: FilterOperator.contains, value: this.searchText }];
            forkJoin({
                symbolSets: this.symbolSetApi.search$(searchParams),
                symbols: this.symbolApi.search$(searchParams)
            }).subscribe((result) => {
                // build symbolset hierarchy
                this.buildSymbolSetHierarchy(cloneDeep(result.symbolSets.data));
                this.filteredCachedSymbols = result.symbols.data;
                this.refreshView = true;
            });
        }

    }

    ngAfterViewChecked() {
        if (!this.refreshView) return;
        this.refreshView = false;
        if (this.searchText && this.searchText.length > 0) {
            const detailsElements = document.querySelectorAll("details");

            detailsElements.forEach((element) => {
                const details = element as HTMLDetailsElement;
                details.open = true;

            });
        } else {
            this.openFirstLevelDetails();
        }
    }

    loadSymbols(id: number) {
        if (!this.searchText || this.searchText.length == 0) {
            // check if it exists in cached symbolsets
            const cachedSymbolSet = this.cachedSymbolSets[id];
            if (cachedSymbolSet) return;
            // if not, do api call
            const searchParams = new SearchParameters();
            searchParams.filter = [{ field: "symbolSetId", operator: FilterOperator.equals, value: id }];
            this.symbolApi.search$(searchParams).subscribe((result) => {
                // add to cached symbolsets
                this.cachedSymbolSets[id] = result.data;
            });

        }
    }

    getSymbols(id: number) {
        if (!this.searchText || this.searchText.length == 0) {
            // check if it exists in cached symbolsets
            const cachedSymbolSet = this.cachedSymbolSets[id];
            if (cachedSymbolSet) return cachedSymbolSet;

        } else {
            return this.filteredCachedSymbols.filter(x => x.symbolSetId === id);
        }
    }
}
