<div class="m-layout-area-body m-layout-w-actions-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top"
         *ngIf="showCreateAssignment && canCreateAssignment">
        <button class="m-btn m-btn-secondary"
                (click)="createAssignment()"
                id="create_assignment_button">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"manageAssignment.create" | translate}}
        </button>
    </div>
    <p-table [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             class="m-layout-area-body"
             [styleClass]="styleClass"
             [loading]="loading"
             [selectionMode]="selectionMode"
             [resizableColumns]="resizable"
             [columnResizeMode]="columnResizeMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             [customSort]="selectionBox"
             (sortFunction)="customSort($event)"
             [lazy]="isLazy"
             (onLazyLoad)="loadTableRows($event)"
             [totalRecords]="total"
             sortField="name"
             dataKey="id"
             #tableRef
             id="assignments_table">
        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>
        <ng-template pTemplate="summary"
                     *ngIf="footer && !paginator">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>
        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable"
                    pResizableColumn
                    [pResizableColumnDisabled]="!resizable || !column.resizable"
                    [ngSwitch]="column.field">
                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field">
                            </p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body"
                     let-assignment>
            <tr #row
                [pSelectableRow]="assignment"
                [pSelectableRowDisabled]="!canSelectRow(assignment)"
                [attr.id]="assignment[table.dataKey]">
                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field"
                    [ngClass]="resizable ? 'ui-resizable-column' : ''">
                    <ng-container *ngSwitchCase="'nameAndParentAssignmentName'">
                        {{assignment | assignmentName}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'project'">
                        {{assignment.parentAssignment ? assignment.parentAssignment.project.name : assignment.project.name}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'description'">
                        {{assignment.parentAssignment ? assignment.parentAssignment.description : assignment.description}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'organization'">
                        <app-navigator [organizationId]="assignment.parentAssignment ? assignment.parentAssignment.project.organization.id : assignment.project.organization.id"
                                       [enabled]="true"></app-navigator>
                    </ng-container>
                    <ng-container *ngSwitchCase="'start'">
                        {{assignment.start| momentDate:false:translateService.currentLang }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'end'">
                        {{assignment.end | momentDate:false:translateService.currentLang }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'statusId'">
                        <app-domain-data-field *ngIf="assignment.currentStatus"
                                               [enumKey]="assignment.currentStatus.statusId"
                                               domainDataType="assignmentStatus">
                        </app-domain-data-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'priority'">
                        <ng-container *ngIf="assignment.parentAssignment ? assignment.parentAssignment.priority : assignment.priority">
                            <div class="flex align-center"
                                 style="width: 100%;">
                                <span class="m-circle m-circle-xs mr-4"
                                      [style.background-color]="assignment.parentAssignment ? assignment.parentAssignment.priority.color : assignment.priority.color"></span>
                                <app-domain-data-field [enumKey]="assignment.parentAssignment ? assignment.parentAssignment.priority.priorityId : assignment.priority.priorityId"
                                                       domainDataType="assignmentPriorityValue">
                                </app-domain-data-field>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(assignment)"
                                                 *ngIf="command.rowValidFunc(assignment)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{assignment.parentAssignment ? assignment.parentAssignment[column.field] : assignment[column.field]}}
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-location-dialog [isCodeRequired]="false"></app-location-dialog>