import { Injectable } from "@angular/core";
import { DatePeriodCreator, IDatePeriod } from "../models/date-period-set";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class DatePeriodApi extends ApiService<IDatePeriod, DatePeriodCreator, DatePeriodCreator> {
    getRoute(): string {
        return "DatePeriods";
    }
}