<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingAttachment ? "attachments.editTitle" : "attachments.uploadTitle") | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm">

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('typeId')?.showErrors }">
            <label class="col-2"
                   for="typeId">
                {{"general.type" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown id="typeId"
                            appAutofocus
                            formControlName="typeId"
                            [options]="attachmentTypes"
                            [panelStyle]="{'width':'100%'}"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            placeholder="{{'attachments.selectType' | translate }}"
                            #typeInput></p-dropdown>

                <app-form-validation [control]="dialogForm.get('typeId')"
                                     [input]="typeInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.submitted && !uploadFile }">

            <label class="col-2">
                {{"general.file" | translate}}
            </label>

            <div class="col-10">
                <label for="fileInput"
                       class="p-inputtext m-input-file m-input-group-w-icon">

                    <span class="label mr-16">
                        <ng-container *ngIf="uploadFile">
                            {{uploadFile.name}}
                        </ng-container>

                        <ng-container *ngIf="!uploadFile">
                            {{"general.selectFile" | translate}}
                        </ng-container>
                    </span>

                    <app-svg-icon icon="upload"
                                  class="ml-auto">
                    </app-svg-icon>

                    <input type="file"
                           id="fileInput"
                           #file
                           (change)="setUploadFileFromInput(file)" />
                </label>

                <div *ngIf="dialogForm.submitted && !uploadFile"
                     class="error">
                    {{"validation.required" | translate}}
                </div>
            </div>

        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('name')?.showErrors }">

            <label class="col-2"
                   for="name">
                {{"general.name" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="name"
                       formControlName="name"
                       class="p-inputtext"
                       #nameInput />

                <app-form-validation [control]="dialogForm.get('name')"
                                     [input]="nameInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('description').showErrors }">

            <label class="col-2"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="description"
                       formControlName="description"
                       class="p-inputtext"
                       #descriptionInput />

                <app-form-validation [control]="dialogForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="uploadFilePreview">

            <div class="col-2"
                 style="text-align: right;">

                <app-icon-button icon="rotate-right"
                                 (click)="rotatePhoto()"
                                 [iconStyle]="{'margin-right': 0}"></app-icon-button>

                <app-icon-button icon="rotate-left"
                                 (click)="rotatePhoto(false)"
                                 [iconStyle]="{'margin-right': 0}"></app-icon-button>
            </div>

            <div class="col-10">
                <div style="width: 300px; max-height: 300px;">
                    <img [attr.src]="uploadFilePreview | safeHtml"
                         style="max-height: 300px; max-width: 100%; border: 1px solid grey;" />
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button type="button"
                [disabled]="submitting"
                class="m-btn m-btn-link"
                (click)="close()">
            {{"form.cancel" | translate}}
        </button>

        <button tabindex="99"
                [disabled]="submitting"
                (click)="submit()"
                class="m-btn m-btn-primary"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{(existingAttachment ? "form.save" : "form.upload") | translate}}
        </button>
    </p-footer>
</p-dialog>