import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { DeviceHardwareConfigurationDialogComponent } from "./components/device-hardware-configuration-dialog/device-hardware-configuration.dialog";
import { DeviceOtaConfigurationComponent } from "./components/device-ota-configuration/device-ota-configuration.component";
import { DeviceCameraComponent } from "./components/device-camera/device-camera.component";
import { DeviceAnprComponent } from "./components/device-anpr/device-anpr.component";
import { DeviceCommandsComponent } from "./components/device-commands/device-commands.component";
import { ManageDeviceCommandDialogComponent } from "./components/manage-device-command-dialog/manage-device-command.dialog";
import { DeviceAlarmOverviewComponent } from "./components/device-alarm-overview/device-alarm-overview.component";
import { DeviceAlarmsComponent } from "./components/device-alarms/device-alarms.component";
import { DeviceIgnoreRulesComponent } from "./components/device-ignore-rules/device-ignore-rules.component";
import { DeviceVehicleConfigurationComponent } from "./components/device-vehicle-configuration/device-vehicle-configuration.component";
import { DeviceAvsConfigurationComponent } from "./components/device-avs-configuration/device-avs-configuration.component";
import { DeviceDekimoDisplayConfigurationComponent } from "./components/device-dekimo-display-configuration/device-dekimo-display-configuration.component";
import { DeviceLinksComponent } from "./components/device-links/device-links.component";
import { DeviceRealtimeAnalyzerConfigurationComponent } from "./components/device-realtime-analyzer-configuration/device-realtime-analyzer-configuration.component";
import { DeviceTmsRadarConfigurationComponent } from "./components/device-tms-radar-configuration/device-tms-radar-configuration.component";
import { DownloadArchiveDialogComponent } from "./components/download-archive-dialog/download-archive.dialog";
import { DeviceUploadComponent } from "./components/device-upload/device-upload.component";
import { DeviceUploadContainerComponent } from "./components/device-upload-container/device-upload-container.component";
import { DeviceZigbeeConfigurationComponent } from "./components/device-zigbee-configuration/device-zigbee-configuration.component";
import { DeviceStatusComponent } from "./components/device-status/device-status.component";
import { DeviceStatusContainerComponent } from "./components/device-status-container/device-status-container.component";
import { DeviceStatusOverviewComponent } from "./components/device-status-overview/device-status-overview.component";
import { DeviceRelayConfigurationComponent } from "./components/device-relay-configuration/device-relay-configuration.component";
import { DeviceDisplayComponent } from "./components/device-display/device-display.component";
import { DeviceDisplayContainerComponent } from "./components/device-display-container/device-display-container.component";
import { DeviceDetailComponent } from "./components/device-detail/device-detail.component";
import { DeviceDetailContainerComponent } from "./components/device-detail-container/device-detail-container.component";
import { DeviceLocationComponent } from "./components/device-location/device-location.component";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { LocationSharedModule } from "../location-shared";

@NgModule({
    imports: [
        SharedModule,
        MapAdvancedModule,
        LocationSharedModule,
    ],
    declarations: [
        DeviceHardwareConfigurationDialogComponent,
        DeviceOtaConfigurationComponent,
        DeviceCameraComponent,
        DeviceAnprComponent,
        DeviceCommandsComponent,
        ManageDeviceCommandDialogComponent,
        DeviceAlarmOverviewComponent,
        DeviceAlarmsComponent,
        DeviceIgnoreRulesComponent,
        DeviceVehicleConfigurationComponent,
        DeviceAvsConfigurationComponent,
        DeviceDekimoDisplayConfigurationComponent,
        DeviceLinksComponent,
        DeviceRealtimeAnalyzerConfigurationComponent,
        DeviceTmsRadarConfigurationComponent,
        DownloadArchiveDialogComponent,
        DeviceUploadComponent,
        DeviceUploadContainerComponent,
        DeviceZigbeeConfigurationComponent,
        DeviceStatusComponent,
        DeviceStatusContainerComponent,
        DeviceStatusOverviewComponent,
        DeviceRelayConfigurationComponent,
        DeviceDisplayComponent,
        DeviceDisplayContainerComponent,
        DeviceDetailComponent,
        DeviceDetailContainerComponent,
        DeviceLocationComponent,
    ],
    exports: [
        DeviceHardwareConfigurationDialogComponent,
        DeviceOtaConfigurationComponent,
        DeviceCameraComponent,
        DeviceAnprComponent,
        DeviceCommandsComponent,
        ManageDeviceCommandDialogComponent,
        DeviceAlarmOverviewComponent,
        DeviceAlarmsComponent,
        DeviceIgnoreRulesComponent,
        DeviceVehicleConfigurationComponent,
        DeviceAvsConfigurationComponent,
        DeviceDekimoDisplayConfigurationComponent,
        DeviceLinksComponent,
        DeviceRealtimeAnalyzerConfigurationComponent,
        DeviceTmsRadarConfigurationComponent,
        DownloadArchiveDialogComponent,
        DeviceUploadComponent,
        DeviceUploadContainerComponent,
        DeviceZigbeeConfigurationComponent,
        DeviceStatusComponent,
        DeviceStatusContainerComponent,
        DeviceStatusOverviewComponent,
        DeviceRelayConfigurationComponent,
        DeviceDisplayComponent,
        DeviceDisplayContainerComponent,
        DeviceDetailComponent,
        DeviceDetailContainerComponent,
        DeviceLocationComponent,
    ]
})
export class DevicesModule { }