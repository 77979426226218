import { SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { CacheOptions, ApiServiceBase } from "../api";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DownloadFileService } from "src/app/services/download-file.service";
import { UploadFileService } from "src/app/services/upload-file.service";
import { CacheService } from "src/app/services/cache.service";
import { ErrorService } from "src/app/services/error.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class NavigatorWebApiService<T> extends ApiServiceBase {

    constructor(
        http: HttpClient,
        downloadFileService: DownloadFileService,
        uploadFileService: UploadFileService,
        cacheService: CacheService,
        errorService: ErrorService,
        configurationService: ConfigurationService) {

        super(http, downloadFileService, uploadFileService, cacheService, errorService, configurationService);
    }

    protected getApiVersion(): string {
        return "web";
    }

    get$(
        id: number = null,
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = true): Promise<T> {

        let urlAppend: string = null;
        if (id) {
            urlAppend = `/${id}`;
        }

        return this.getOther$<T>(urlAppend, searchParameters, serviceRequestOptions, useCache).toPromise();
    }
}