import { firstValueFrom } from "rxjs";
import { Component, OnInit, OnChanges, ElementRef, Input, SimpleChanges, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertHistoryApi } from "src/app/resource/alert-history.api";
import { IAlertHistory } from "src/app/models/alert-history";
import { IProject } from "src/app/models/project";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { DomainDataService } from "src/app/services/domain-data.service";
import { FilterDescriptor, FilterOperator, SearchParameters } from "src/app/models/search";
import { Constants } from "src/app/constants/constants";
import { ColorUtils, SubscriptionManager } from "src/app/utilities";
import { TimelineConfig, TimelineData } from "../timeline-activity/timeline-activity.component";
import { SigncoFormGroup } from "src/app/models/form";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";

@Component({
    selector: "app-alert-history",
    templateUrl: "./alert-history.component.html",
    styles: [`.alert-history-status-dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #777;
}
`]
})
export class AlertHistoryComponent implements OnInit, OnChanges, OnDestroy {
    @Input() measuringPoint: IMeasuringPoint;
    @Input() project: IProject;

    @Input() allowWithoutInput = false;

    protected timelineConfig: TimelineConfig;
    protected alertHistory: IAlertHistory[];

    protected submitting: boolean;
    protected alertHistoryForm: SigncoFormGroup;
    protected calendarSettings: CalendarSettings;
    protected subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        readonly translateService: TranslateService,
        private readonly alertHistoryApi: AlertHistoryApi,
        private readonly domainDataService: DomainDataService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.createForm();
    }

    ngOnInit() {
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        const projectChange = changes["project"];
        if (projectChange) {
            this.setProject(this.project);
        }
    }

    ngOnDestroy() {
        this.subscriptionManager?.clear();
    }

    private createForm() {
        const fromControl = this.formBuilder.control(new Date().addDays(-14).toMidnight(), [Validators.required]);
        const toControl = this.formBuilder.control(null);

        this.subscriptionManager.add("fromControlValueChange", fromControl.valueChanges.subscribe(() => {
            this.loadData();
        }));

        this.subscriptionManager.add("toControlValueChange", toControl.valueChanges.subscribe(() => {
            this.loadData();
        }));

        this.alertHistoryForm = this.formBuilder.group({
            from: fromControl,
            to: toControl
        }) as SigncoFormGroup;
    }

    private clear() {
        this.measuringPoint = null;
        this.project = null;
        this.alertHistory = null;
        this.timelineConfig = null;
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.clear();
        this.measuringPoint = measuringPoint;
        this.loadData();
    }

    setProject(project: IProject) {
        this.clear();
        this.project = project;
        this.loadData()
    }

    getRouteParams(): { [index: string]: string } {
        if (this.measuringPoint) return { type: "measuringPointId", id: this.measuringPoint.id.toString() };
        if (this.project) return { type: "projectId", id: this.project.id.toString() };
        return null;
    }

    private getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {};
        searchParameters.filter = [];

        if (this.project) {
            searchParameters.queryParams["projectId"] = this.project.id;
        }

        if (this.measuringPoint) {
            searchParameters.queryParams["measuringPointId"] = this.measuringPoint.id;
        }

        const alertFrom = this.alertHistoryForm?.get("from")?.value;
        if (alertFrom) {
            searchParameters.filter.push(new FilterDescriptor("alertTimestamp", alertFrom, FilterOperator.greaterThanOrEqualTo));
        }

        const alertUntil = this.alertHistoryForm?.get("to")?.value;
        if (alertUntil) {
            searchParameters.filter.push(new FilterDescriptor("alertTimestamp", alertUntil, FilterOperator.lessThanOrEqualTo));
        }

        return searchParameters;
    }

    async canLoad(): Promise<boolean> {
        return (this.allowWithoutInput || !!this.measuringPoint || !!this.project) && await this.formValidationService.checkValidity(this.alertHistoryForm);
    }

    protected getStatusStyle(alertHistory: IAlertHistory): object {
        const data = { backgroundColor: null, color: null };

        if (alertHistory.alertLevelId) {
            data.backgroundColor = Constants.getAlertColor(alertHistory.alertLevelId);
            data.color = ColorUtils.getMarkerTextColorHex(data.backgroundColor);
        }

        return data;
    }

    private async loadData() {
        if (!this.canLoad()) return;

        this.alertHistory = null;
        this.timelineConfig = null;

        const alertHistorySearchResult = await firstValueFrom(this.alertHistoryApi.search$(this.getSearchParameters()));
        this.alertHistory = alertHistorySearchResult.data;
        this.createTimeline();
    }

    //#region Timeline

    private createTimeline() {
        if (!this.measuringPoint) return;
        if (!this.alertHistory?.length) return;

        const timelineConfig = new TimelineConfig();
        timelineConfig.data = [];

        const createTimelineData = (alertHistory: IAlertHistory): TimelineData => {
            const timelineData = new TimelineData();
            alertHistory.alertTypeId ??= alertHistory.alertConfiguration?.alertTypeId; // To Be Removed - for older models during testing
            timelineData.description = this.domainDataService.translateEnum("alertType", alertHistory.alertTypeId);
            // timelineData.groupField = alertHistory.alertConfiguration.id + "-" + timelineData.description;
            timelineData.startDate = alertHistory.alertTimestamp;
            timelineData.color = alertHistory.alertLevelId ? Constants.getAlertColor(alertHistory.alertLevelId) : null;

            return timelineData;
        };

        for (const groupedAlert of this.alertHistory.groupByFunc(x => x.alertTypeId)) {
            let timelineData: TimelineData = null;

            const sortedAlertHistoryByDate = groupedAlert.members.sortBy(x => x.alertTimestamp.getTime());
            for (const alertHistory of sortedAlertHistoryByDate) {
                if (!timelineData) {
                    if (!alertHistory.alertLevelId) continue;
                    timelineData = createTimelineData(alertHistory);

                } else {
                    timelineData.endDate = alertHistory.alertTimestamp;
                    timelineConfig.data.push(timelineData);

                    if (!alertHistory.alertLevelId) {
                        // Alarm cleared
                        timelineData = null;
                    } else {
                        // Alarm changed priority, instantly create a new item in the row
                        timelineData = createTimelineData(alertHistory);
                    }
                }
            }

            if (timelineData) {
                timelineData.endDate = new Date();
                timelineConfig.data.push(timelineData);
            }
        }

        this.timelineConfig = timelineConfig;
    }

    //#endregion Timeline
}
