import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { SigncoFormGroup } from "src/app/models/form";
import { SigncoFormArray } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { FileUtils } from "src/app/utilities";
import { ILightMode } from "src/app/models/mode-configuration";
import { DownloadFileService } from "src/app/services/download-file.service";
import { TranslateService } from "@ngx-translate/core";
import { DomainDataService } from "src/app/services/domain-data.service";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";

@Component({
    selector: "app-measuring-point-light-mode-configuration",
    templateUrl: "./measuring-point-light-mode-configuration.component.html"
})
export class MeasuringPointLightModeConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;
    @Output() save = new EventEmitter<IMeasuringPoint>();

    submitting = false;
    modeForms: SigncoFormArray;
    modeConfigurationForm: SigncoFormGroup;
    modeOptions: SelectItem[];

    uploadFile: File[] = [];
    uploadFilePreview: string[] = [];

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly downloadFileService: DownloadFileService,
        private readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.modeConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint) return;

        this.uploadFilePreview = [];
        this.uploadFile = [];

        this.modeForms = this.formBuilder.array([]) as SigncoFormArray;

        this.modeConfigurationForm = this.formBuilder.group({
            defaultModeId: null,
            modes: this.modeForms
        }) as SigncoFormGroup;

        if (this.measuringPoint.lightModeConfiguration) {
            for (const _ of this.measuringPoint.lightModeConfiguration.modes) {
                await this.addMode(_);
            }
        }

        this.modeConfigurationForm.patchValue(this.measuringPoint.lightModeConfiguration);
        this.updateModeOptions();

        this.modeConfigurationForm.markAsPristine();
    }

    async reset() {
        this.uploadFile = [];
        this.uploadFilePreview = [];
        this.initialize();
    }

    //#region Modes

    async addMode(mode: ILightMode = null) {
        const modeFormGroup = this.formBuilder.group({
            id: [null, Validators.required],
            description: null,
        });

        this.modeForms.push(modeFormGroup);

        if (mode?.iconUrl) {
            const file = await this.downloadFileService.downloadBlob(mode.iconUrl);
            this.uploadFile.push(file.file);
            this.uploadFilePreview.push(await FileUtils.toBase64(file.file));
        } else {
            this.uploadFile.push(null);
            this.uploadFilePreview.push(null);
        }
    }

    deleteMode(index: number) {
        this.modeForms.removeAt(index);
        this.uploadFile.splice(index, 1);
        this.uploadFilePreview.splice(index, 1);
    }

    updateModeOptions() {
        if (!this.modeForms) return;

        const ids = this.modeForms.controls.map(x => x.get("id").value as string).filter(x => !!x);
        this.modeOptions = this.primeComponentService.createDropdownList(ids, x => x, x => x, false);
    }

    //#endregion Modes

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.modeConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.uploadFilePreview = [];
            this.uploadFile = [];
            this.initialize();
            this.modeConfigurationForm.markAsPristine();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        Object.assign(measuringPointUpdater.lightModeConfiguration, this.modeConfigurationForm.value);

        this.submitting = true;

        const filesToBeUploaded: { name: string, file: File }[] = [];
        if (measuringPointUpdater.lightModeConfiguration.modes) {
            for (let i = 0; i < this.uploadFile.length; i++) {
                if (this.uploadFile[i]) {
                    // Form data parameter name should be in this format (handling on BE)
                    filesToBeUploaded.push({ name: `${measuringPointUpdater.lightModeConfiguration.modes[i].id}`, file: this.uploadFile[i] });
                }
            }
        }
        this.measuringPointApi.updateWithFormData$(measuringPointUpdater, filesToBeUploaded).subscribe(onSuccess, onError);

    }

    async setFile(event: { files: FileList }, modeIndex: number) {
        if (!event || event.files.length <= 0) return;

        if (event.files[0].size > 2097152) {
            this.toastService.warning(this.translateService.instant("general.fileTooBig", { fileSize: "2MB" }));
            return;
        }

        this.uploadFile[modeIndex] = event.files[0];
        this.uploadFilePreview[modeIndex] = await FileUtils.toBase64(event.files[0]);
    }

    setPhotoDeleted(index: number) {
        this.uploadFile[index] = null;
        this.uploadFilePreview[index] = null;
    }
}