import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { forkJoin, map, Observable } from "rxjs";
import { SigncoFormGroup } from "src/app/models/form";
import { SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";
import { ITask, TaskStatus, WorkerTaskCreator } from "src/app/models/task";
import { IWorker } from "src/app/models/worker";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { TaskApi } from "src/app/resource/task.api";
import { WorkerApi } from "src/app/resource/worker.api";
import { FormValidationService } from "src/app/services/form-validation.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { TaskService } from "src/app/services/task.service";
import { JsonUtils } from "src/app/utilities";

@Component({
    selector: "app-schedule-schedulable-tasks-dialog",
    templateUrl: "./schedule-schedulable-tasks-dialog.component.html",
    styleUrls: []
})
export class ScheduleSchedulableTasksDialogComponent extends DialogComponentBase {
    private callback: () => void;

    calendarSettings: CalendarSettings;

    assignmentId: number;
    workers: SelectItem[] = [];
    currentDate: Date;
    submitting = false;

    tasksToBeScheduled: ITask[] = new Array<ITask>();

    scheduledTaskForm: SigncoFormGroup;

    constructor(
        readonly primeComponentService: PrimeComponentService,
        private readonly taskService: TaskService,
        private readonly taskApi: TaskApi,
        private readonly workerApi: WorkerApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formValidationService: FormValidationService
    ) {
        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.currentDate = new Date();
    }

    open(assignmentId: number, tasksToBeScheduled: ITask[], callback?: () => void) {
        if (!tasksToBeScheduled || tasksToBeScheduled.length === 0) return;

        this.tasksToBeScheduled = JsonUtils.deepClone(tasksToBeScheduled);
        this.assignmentId = assignmentId;
        this.initializeDropdown();
        this.initializeForm();

        this.callback = callback;
        this.openDialog();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(
            this.scheduledTaskForm
        );
        if (!isValid) return;

        this.submitting = true;
        const observables$ = new Array<Observable<ITask>>();

        for (const taskToBeScheduled of this.tasksToBeScheduled) {
            const updater = this.taskService.getUpdaterFromServiceModel(taskToBeScheduled);

            updater.currentStatus.availableFrom = this.scheduledTaskForm.get("availableFrom").value;

            updater.start = undefined;
            updater.end = undefined;
            updater.currentStatus.taskStatusId = TaskStatus.Scheduled;
            updater.name = `${taskToBeScheduled.copiedTask.name}_recurring`;

            // if there are selected workers add them
            if (this.scheduledTaskForm.get("workerIds")?.value && (<number[]>this.scheduledTaskForm.get("workerIds").value)?.length > 0) {
                updater.currentStatus.workerTasks = (<number[]>this.scheduledTaskForm.get("workerIds").value).map((workerId: number) => {
                    return {
                        workerId: workerId
                    } as WorkerTaskCreator;
                });
            } else {
                updater.currentStatus.workerTasks = null;
            }

            observables$.push(this.taskApi.update$(updater));
        }

        forkJoin(observables$).subscribe({
            next: (responses) => {
                this.submitting = false;
                if (this.callback) this.callback();
                this.close();
            },
            error: (error) => {
                this.submitting = false;
                if (this.callback) this.callback();
                this.close();
            }
        });
    }

    initializeDropdown(): void {
        const searchParameters = new SearchParameters();
        searchParameters.sort = [];
        searchParameters.sort.push(new SortDescriptor(SortDirection.ascending, "FirstName"));

        this.workerApi.getAll$(searchParameters).pipe(map((workers: IWorker[], index: number) => {
            return workers.map((worker) => {
                return {
                    label: `${worker.firstName} ${worker.lastName}`,
                    value: worker.id
                } as SelectItem;
            });
        })).subscribe({
            next: (workers) => {
                this.workers = workers;
            },
            error: (error) => {
                this.workers = [];
            }
        });
    }

    initializeForm(): void {
        this.scheduledTaskForm = this.formBuilder.group({
            availableFrom: [this.currentDate, Validators.required],
            workerIds: [null],
        }) as SigncoFormGroup;
    }
}
