<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"datePeriodSet.create" | translate}}
        </button>
    </div>

    <p-table [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-border-top"
             [loading]="loading"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             dataKey="id"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer">
            <ng-container *ngIf="total && !paginator">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-datePeriodSet
                     let-expanded="expanded">
            <tr [pSelectableRow]="datePeriodSet">
                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <ng-container *ngSwitchCase="'expand'">
                        <a [pRowToggler]="datePeriodSet">
                            <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(datePeriodSet)"
                                                 *ngIf="command.rowValidFunc(datePeriodSet)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{datePeriodSet[column.field]}}
                    </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion"
                     let-datePeriodSet
                     let-columns="columns">
            <tr>
                <td [attr.colspan]="columns.length">
                    <div style="margin-bottom: 50px;">
                        <app-date-periods [datePeriodSet]="datePeriodSet"
                                          [createCommand]="createCommand"
                                          [editCommand]="editCommand"
                                          [deleteCommand]="deleteCommand"
                                          [rowCount]="10"
                                          [filter]="filter"
                                          [sortable]="sortable"></app-date-periods>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-manage-date-period-set-dialog></app-manage-date-period-set-dialog>