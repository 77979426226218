import { ILocation, LocationCreator, LocationUpdater } from "src/app/models/location";
import { IServiceModelBase } from "src/app/models/servicemodelbase";
import { IUserSummary } from "src/app/models/user";

export interface IDeviceDepot extends IServiceModelBase {
    code: string;
    description: string;
    ownerId?: number;
    location: ILocation;
    creator: IUserSummary;
}

export interface IDeviceDepotNavigator {
    id: number;
    code: string;
}

export class DeviceDepotCreator {
    code: string;
    description: string;
    ownerId?: number;
    location: LocationCreator;
}

export class DeviceDepotUpdater extends DeviceDepotCreator {
    id: number;

    constructor(existing: IDeviceDepot) {
        super();
        this.id = existing.id;
        this.code = existing.code;
        this.description = existing.description;
        this.ownerId = existing.ownerId;

        if (existing.location) {
            this.location = new LocationUpdater(existing.location);
        }
    }
}