import { IUpload, UploadCreator } from "../models/upload";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { DownloadedFile } from "../services/download-file.service";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UploadApi extends ApiService<IUpload, UploadCreator, UploadCreator> {
    getRoute(): string {
        return "Uploads";
    }

    downloadAnalysis$(upload: IUpload): Promise<DownloadedFile> {
        const url = `${this.getUrl()}/${upload.id}/analysis`;
        return this.downloadFileService.downloadBlob(url);
    }

    uploadBulk$(file: File): Promise<IUpload[]> {
        const url = `${super.getUrl()}/Upload`;
        const formData = new FormData();
        formData.append("file", file);

        return new Promise<IUpload[]>((resolve, reject) => {

            const onSuccess = (uploads: IUpload[]) => {
                resolve(uploads);
            };

            const onError = async (error: HttpErrorResponse) => {
                reject(error);
            };

            this.http
                .post<IUpload[]>(url, formData)
                .subscribe(onSuccess, onError);
        });
    }
}