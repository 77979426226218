import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ISafetyQuestion } from "src/app/models/safety-question";
import { ServiceRequestOptions } from "src/app/models/search";
import { ApiTableComponent, ColumnVisibility, FilterType, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { SafetyQuestionApi } from "src/app/resource/safety-question.api";
import { ModalService } from "src/app/services/modal.service";
import { NavigationService } from "src/app/services/navigation.service";
import { ManageSafetyQuestionDialogComponent } from "../manage-safety-question-dialog/manage-safety-question-dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-safety-questions",
    templateUrl: "./safety-questions.component.html"
})
export class SafetyQuestionsComponent extends ApiTableComponent<ISafetyQuestion> implements OnChanges {
    @ViewChild(ManageSafetyQuestionDialogComponent, { static: true }) manageSafetyQuestionDialogComponent: ManageSafetyQuestionDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;
    @Input() createCommand = true;

    private ownerColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly safetyQuestionApi: SafetyQuestionApi,
        private readonly navigationService: NavigationService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService
    ) {

        super("safetyQuestion.component", elementRef, safetyQuestionApi, tableService);

        this.stretchHeight = true;
        this.footer = false;

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");

        this.addColumn(new TableColumn("question", "safetyQuestion.question", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("validFrom", "safetyQuestion.validFrom", { filterType: FilterType.Date, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("validTo", "safetyQuestion.validTo", { filterType: FilterType.Date, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.ownerColumn = new TableColumn("ownerId", "safetyQuestion.owner", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideMini });
        this.addColumn(this.ownerColumn);
        this.addColumn(new TableColumn("isActive", "safetyQuestion.isActive", { filterType: FilterType.Boolean, width: 120, resizable: false, sortable: true, visibility: ColumnVisibility.HideMini }));

        this.services.mapDataService.subscribeToOrganizations(
            this.mapDataServiceFilterKey,
            (organizations) => {
                this.ownerColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                    organizations,
                    (x) => x.id,
                    (x) => x.name,
                    false
                );
            }
        );

        this.registerCommand({
            text: "safetyQuestion.edit",
            icon: "edit",
            click: (safetyQuestion) => this.editSafetyQuestion(safetyQuestion),
            validFunc: () =>
                this.editCommand &&
                this.rights?.hasBackendRight(BackendRights.EditSafetyQuestion),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (safetyQuestion) => this.delete(safetyQuestion),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteSafetyQuestion),
        });
    }

    editSafetyQuestion(safetyQuestion: ISafetyQuestion): void {
        this.manageSafetyQuestionDialogComponent.edit(safetyQuestion, () => {
            this.reload();
        });
    }

    private delete(safetyQuestion: ISafetyQuestion): void {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteSafetyQuestion) || !safetyQuestion) return;

        const modalBody = this.translateService.instant("safetyQuestion.deleteConfirmation", { question: `${safetyQuestion.question}` }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(safetyQuestion));
    }

    create() {
        this.navigationService.createNewSafetyQuestion();
    }

    private handleDelete(safetyQuestion: ISafetyQuestion) {
        const onDeleteSuccess = () => {
            this.reload();
        };
        this.safetyQuestionApi.delete$(safetyQuestion.id).subscribe(onDeleteSuccess, () => { });
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("safetyQuestion", "owner");
        return serviceRequestOptions;
    }
}
