<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingDeviceDepot ? "deviceDepots.editTitle" : "deviceDepots.create")| translate:existingDeviceDepot}}
    </p-header>

    <form *ngIf="manageDeviceDepotForm"
          class="p-16"
          [formGroup]="manageDeviceDepotForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageDeviceDepotForm.get('code').showErrors }">

            <label class="col-2"
                   for="code">
                {{"general.code" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="code"
                       class="p-inputtext"
                       formControlName="code"
                       appAutofocus
                       #codeInput />

                <app-form-validation [control]="manageDeviceDepotForm.get('code')"
                                     [input]="codeInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageDeviceDepotForm.get('description').showErrors }">

            <label class="col-2"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-10">
                <textarea rows="3"
                          id="description"
                          class="p-inputtextarea"
                          formControlName="description"
                          #descriptionInput></textarea>

                <app-form-validation [control]="manageDeviceDepotForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>