<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{'width': '500px'}">

    <p-header>
        {{"vmsImageEditor.copyFromTitle" | translate}}
    </p-header>
    <div class="p-16">

        <div class="m-form-group row">
            <label class="col-4"
                   for="copyFromVmsTypeId">
                {{"vmsImageEditor.copyFrom" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown id="copyFromVmsTypeId"
                            [panelStyle]="{'width':'100%'}"
                            [(ngModel)]="selectedVmsTypeId"
                            [ngModelOptions]="{standalone: true}"
                            [options]="vmsTypeValues"
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            placeholder="{{'vmsImageEditor.selectVmsType' | translate }}">
                </p-dropdown>
            </div>
        </div>



    </div>
    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting || !selectedVmsTypeId"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>

            {{"vmsImageEditor.copy" | translate}}
        </button>
    </p-footer>
</p-dialog>