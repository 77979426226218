import { MeasuringPointStatusApi } from "src/app/resource/measuring-point-status.api";
import { IMeasuringPointStatus, IMeasuringPointStatusData } from "src/app/models/measuring-point-status";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { ToastService } from "src/app/services/toast.service";
import { Component } from "@angular/core";
import { IVmsMode } from "src/app/models/mode-configuration";

class VmsModeViewModel {
    public mode: IVmsMode;
}

@Component({
    selector: "app-vms-manage-dialog",
    templateUrl: "./vms-manage-dialog.component.html"
})
export class VmsManageDialogComponent extends DialogComponentBase {

    measuringPointId: number;
    viewModels: VmsModeViewModel[];
    isSaving = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly measuringPointStatusApi: MeasuringPointStatusApi,
    ) {
        super();
    }

    public async open(measuringPointId: number, modes: IVmsMode[]) {
        this.measuringPointId = measuringPointId;
        this.viewModels = [];
        for (const mode of modes) {
            const vm = new VmsModeViewModel();
            vm.mode = mode;

            this.viewModels.push(vm);
        }

        this.openDialog();
    }

    async onSelect(mode: VmsModeViewModel) {
        const onSuccess = async (_: IMeasuringPointStatus) => {
            this.toastService.saveSuccess();
            this.isSaving = false;
            this.close();
        };

        const onError = () => {
            this.isSaving = false;
        };

        this.isSaving = true;

        const data = {
            currentMode: mode.mode
        } as IMeasuringPointStatusData;
        const status = {
            measuringPointId: this.measuringPointId,
            timestamp: new Date(),
            data: data
        } as IMeasuringPointStatus;

        this.measuringPointStatusApi.create$(status, { measuringPointId: this.measuringPointId.toString() })
            .subscribe({
                next: onSuccess,
                error: onError
            });
    }
}