<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"deviceCommands.create" | translate}}
    </p-header>

    <form *ngIf="deviceCommandCreatorForm"
          class="p-16"
          [formGroup]="deviceCommandCreatorForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('typeId').showErrors }">

            <label class="col-4"
                   for="type">
                {{"general.type" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown id="type"
                            [options]="deviceCommandTypes"
                            [panelStyle]="{'width':'100%'}"
                            formControlName="typeId"
                            appendTo="body"
                            (onChange)="adjustForm()"
                            #typeInput>
                </p-dropdown>

                <app-form-validation [control]="deviceCommandCreatorForm.get('typeId')"
                                     [input]="typeInput">
                </app-form-validation>
            </div>
        </div>

        <ng-container *ngIf="getCommandTypeId() === 'resetCounters'">

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('dayCounter').showErrors }">

                <label class="col-4"
                       for="dayCounter">
                    {{'deviceCommands.resetCounters.dayCounter' | translate}}
                </label>

                <div class="col-8">
                    <div class="m-input-group">
                        <input type="checkbox"
                               (change)="toggleControlEnabled(deviceCommandCreatorForm.get('dayCounter'))" />

                        <input name="dayCounter"
                               type="number"
                               formControlName="dayCounter"
                               class="p-inputtext"
                               id="dayCounter"
                               #dayCounterInput>
                    </div>

                    <app-form-validation [control]="deviceCommandCreatorForm.get('dayCounter')"
                                         [input]="dayCounterInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('monthCounter').showErrors }">

                <label class="col-4"
                       for="monthCounter">
                    {{'deviceCommands.resetCounters.monthCounter' | translate}}
                </label>

                <div class="col-8">
                    <div class="m-input-group">
                        <input type="checkbox"
                               (change)="toggleControlEnabled(deviceCommandCreatorForm.get('monthCounter'))" />

                        <input name="monthCounter"
                               type="number"
                               class="p-inputtext"
                               formControlName="monthCounter"
                               id="monthCounter"
                               #monthCounterInput>
                    </div>

                    <app-form-validation [control]="deviceCommandCreatorForm.get('monthCounter')"
                                         [input]="monthCounterInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('yearCounter').showErrors }">

                <label class="col-4"
                       for="yearCounter">
                    {{'deviceCommands.resetCounters.yearCounter' | translate}}
                </label>

                <div class="col-8">
                    <div class="m-input-group">
                        <input type="checkbox"
                               (change)="toggleControlEnabled(deviceCommandCreatorForm.get('yearCounter'))" />

                        <input name="yearCounter"
                               type="number"
                               formControlName="yearCounter"
                               class="p-inputtext"
                               id="yearCounter"
                               #yearCounterInput>
                    </div>

                    <app-form-validation [control]="deviceCommandCreatorForm.get('yearCounter')"
                                         [input]="yearCounterInput">
                    </app-form-validation>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="getCommandTypeId() === 'enableModem'">

            <div class="m-form-group">
                <label>{{"deviceCommands.enableModem.info" | translate}}</label>
            </div>

            <ng-container formGroupName="enabled">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('enabled').get('from').showErrors || deviceCommandCreatorForm.get('enabled').get('until').showErrors }">

                    <div class="col-6">
                        <div class="row">
                            <div class="col-4">
                                <label for="from"
                                       class="m-input-checkbox">
                                    <input type="checkbox"
                                           (change)="toggleEnableModemEnabled()" />
                                    {{'deviceCommands.enableModem.enabled' | translate}}
                                </label>
                            </div>

                            <div class="col-8">
                                <p-calendar id="from"
                                            appendTo="body"
                                            formControlName="from"
                                            selectionMode="single"
                                            [style]="{'width':'100%'}"
                                            autoWidth="false"
                                            [showIcon]="true"
                                            [showSeconds]="false"
                                            [hourFormat]="calendarSettings.hourFormat"
                                            [dateFormat]="calendarSettings.dateFormat"
                                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                            [timeOnly]="true"
                                            showButtonBar="true"
                                            todayButtonStyleClass="hidden"
                                            #enabledFromInput></p-calendar>

                                <app-form-validation [control]="deviceCommandCreatorForm.get('enabled').get('from')"
                                                     [input]="enabledFromInput">
                                </app-form-validation>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="row">
                            <div class="col-4">
                                <label for="until">{{'deviceCommands.enableModem.until' | translate}}</label>
                            </div>

                            <div class="col-8">
                                <p-calendar id="until"
                                            appendTo="body"
                                            formControlName="until"
                                            selectionMode="single"
                                            [style]="{'width':'100%'}"
                                            autoWidth="false"
                                            [showIcon]="true"
                                            [showSeconds]="false"
                                            [hourFormat]="calendarSettings.hourFormat"
                                            [dateFormat]="calendarSettings.dateFormat"
                                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                            [timeOnly]="true"
                                            showButtonBar="true"
                                            todayButtonStyleClass="hidden"
                                            #enabledUntilInput></p-calendar>

                                <app-form-validation [control]="deviceCommandCreatorForm.get('enabled').get('until')"
                                                     [input]="enabledUntilInput">
                                </app-form-validation>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container formGroupName="valid">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': deviceCommandCreatorForm.get('valid').get('from').showErrors || deviceCommandCreatorForm.get('valid').get('until').showErrors }">

                    <div class="col-6">
                        <div class="row">
                            <div class="col-4">
                                <label for="from"
                                       class="m-input-checkbox">
                                    <input type="checkbox"
                                           [checked]="deviceCommandCreatorForm.get('valid').enabled"
                                           [disabled]="deviceCommandCreatorForm.get('enabled').disabled"
                                           (change)="toggleControlEnabled(deviceCommandCreatorForm.get('valid'))" />
                                    {{'deviceCommands.enableModem.valid' | translate}}
                                </label>
                            </div>

                            <div class="col-8">
                                <p-calendar id="from"
                                            appendTo="body"
                                            formControlName="from"
                                            selectionMode="single"
                                            [style]="{'width':'100%'}"
                                            autoWidth="false"
                                            [showIcon]="true"
                                            [dateFormat]="calendarSettings.dateFormat"
                                            [hourFormat]="calendarSettings.hourFormat"
                                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                            [maxDate]="deviceCommandCreatorForm.get('valid').get('until').value"
                                            showButtonBar="true"
                                            #enabledFromInput></p-calendar>

                                <app-form-validation [control]="deviceCommandCreatorForm.get('valid').get('from')"
                                                     [input]="enabledFromInput">
                                </app-form-validation>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="row">
                            <div class="col-4">
                                <label for="until">{{'deviceCommands.enableModem.until' | translate}}</label>
                            </div>

                            <div class="col-8">
                                <p-calendar id="until"
                                            appendTo="body"
                                            formControlName="until"
                                            selectionMode="single"
                                            [style]="{'width':'100%'}"
                                            autoWidth="false"
                                            [showIcon]="true"
                                            [hourFormat]="calendarSettings.hourFormat"
                                            [dateFormat]="calendarSettings.dateFormat"
                                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                            [minDate]="deviceCommandCreatorForm.get('valid').get('from').value"
                                            showButtonBar="true"
                                            #enabledUntilInput></p-calendar>

                                <app-form-validation [control]="deviceCommandCreatorForm.get('valid').get('until')"
                                                     [input]="enabledUntilInput">
                                </app-form-validation>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="error"
             *ngIf="errorCode">
            {{errorCode | translate}}
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                id="close_manage_device_command_dialog"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="submit_manage_device_command_dialog">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.send" | translate}}
        </button>
    </p-footer>
</p-dialog>