<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{width: 'auto'}">

    <p-header>
        {{"liveTiles.vms.dialogTitle" | translate}}
    </p-header>

    <app-loading-spinner *ngIf="loading"></app-loading-spinner>

    <div class="m-mode-select p-16">
        <ng-container *ngFor="let mode of modes">
            <div class="m-mode-select-tile p-8">
                <h3 style="text-align:center;">{{mode.id}}: {{mode.description}}</h3>
                <app-totem-display-preview [mode]="mode"
                                           [configuration]="measuringPoint.totemDisplayConfiguration.fields"
                                           [isExpanded]="true">
                </app-totem-display-preview>

                <div class="m-mode-select-tile-content">
                    <button class="m-mode-select-button m-btn m-btn-secondary m-btn-100"
                            (click)="onSelect(mode)"
                            [disabled]="isSaving">
                        <app-svg-icon icon="checkmark"></app-svg-icon>
                        {{"form.select" | translate}}</button>
                </div>
            </div>
        </ng-container>
    </div>


    <div *ngIf="isSaving"
         style="position: absolute; top:0; bottom:0; left:0; right:0; display: grid; justify-content: center; align-content: center; background-color: #0006;">
        <div>
            <app-loading-spinner></app-loading-spinner>
        </div>
    </div>
</p-dialog>