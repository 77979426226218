import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { HistoryPageComponent } from "./components/history-page/history-page.component";
import { ReportHistoryComponent } from "./components/report-history/report-history.component";
import { UploadComponent } from "./components/upload/upload.component";

import { HistoryRoutingModule } from "./history-routing.module";

@NgModule({
    imports: [
        SharedModule,
        HistoryRoutingModule
    ],
    declarations: [
        HistoryPageComponent,
        ReportHistoryComponent,
        UploadComponent
    ]
})
export class HistoryModule { }