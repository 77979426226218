import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener, inject } from "@angular/core";
import { LocationUpdater } from "src/app/models/location";
import { SigncoFormGroup, SigncoFormControl } from "src/app/models/form";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { FormValidationService } from "src/app/services/form-validation.service";
import { ProjectsService } from "src/app/services/projects.service";
import { MeasuringPointUtils, MapUtils, SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { IProject } from "src/app/models/project";
import { MultiSelect } from "primeng/multiselect";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AnalysisType, IMeasuringPoint, MeasuringPointCreator, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { IBasicMarker, ArrowMarker, BasicMapComponent } from "src/app/modules/map-basic";
import { FilterService } from "../../..";
import { TypesFilterDialogComponent } from "../../search-panel/filters/types-filter-dialog/types-filter-dialog.component";
import { AnalysisTypeView } from "src/app/modules/shared/components/analysis-type-view/analysis-type-view.component";

@Component({
    selector: "app-measuring-point-detail",
    templateUrl: "./measuring-point-detail.component.html",
    host: { class: "m-layout-area-body" }
})
export class MeasuringPointDetailComponent implements OnDestroy, OnChanges, IComponentCanDeactivate, IChangeGuard {
    codeInput: ElementRef<HTMLInputElement>;
    @ViewChild("codeInput", { static: false }) set setCodeInput(codeInput: ElementRef<HTMLInputElement>) {
        this.codeInput = codeInput;
    }
    @ViewChild("projectIdsInput", { static: false }) projectsMultiSelect: MultiSelect;
    @ViewChild(TypesFilterDialogComponent, { static: true }) private typeFilterDialog: TypesFilterDialogComponent;

    @Input() measuringPoint: IMeasuringPoint;
    @Input() allProjects: IProject[];
    @Input() projects: SelectItem[];

    @Output() save = new EventEmitter<IMeasuringPoint>();

    analysisTypeView = AnalysisTypeView.AllowedAnalysisTypes;
    gmap: BasicMapComponent;
    measuringPointMarker: IBasicMarker;

    submitting = false;
    measuringPointForm: SigncoFormGroup;
    drivingLanes: SelectItem[];

    showHeading: boolean;

    onlyActiveProjects = false;
    rights: Rights;

    private readonly filterService = inject(FilterService);

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly navigationService: NavigationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly projectsService: ProjectsService) {

        this.domainModelFilterService.getDrivingLanes$().then(drivingLanes => {
            this.drivingLanes = drivingLanes;
            this.setDrivingLane();
        });

        const rightsSubscription = this.globalEventsService.currentRights$.subscribe(rights => {
            this.rights = rights;
        });
        this.subscriptionManager.add("rightsSubscription", rightsSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        // We use `/x` to navigate to the "first available measuring point of this location"
        // This happens mid-navigation
        return window.location.href.endsWith("/x/details") || !this.measuringPointForm || (this.measuringPointForm.pristine && !this.isCreatingNew());
    }

    onDeactivate() {
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint || !this.gmap) return;

        this.measuringPointForm = this.formBuilder.group({
            code: ["", Validators.required],
            description: "",
            analysisTypeId: [null, Validators.required],
            heading: [this.measuringPoint.heading, Validators.required],
            from: "",
            to: "",
            drivingLaneId: [null, Validators.required],
            projectIds: null,
            onlyActiveProjects: false // we need to add this to remove warning in console
        }) as SigncoFormGroup;

        this.measuringPointForm.patchValue(this.measuringPoint);
        this.measuringPointForm.markAsPristine();
        this.adjustForm();

        if (this.isCreatingNew() && (!this.measuringPoint.projects || this.measuringPoint.projects.length === 0)) {
            // when creating a new and when that new does not have configured projects -> we configure currently filtered project (if present)
            // if we're creating a copy or clone of mp which have configured project, this won't be executed
            const projectId = this.filterService.filterState.projects?.takeFirstOrDefault()?.id;
            if (projectId) {
                this.measuringPointForm.get("projectIds").patchValue([+projectId]);
            }
        } else if (this.measuringPoint.projects) {
            this.measuringPointForm.get("projectIds").patchValue(this.measuringPoint.projects.map(x => x.projectId));
        }

        if (this.codeInput) {
            this.codeInput.nativeElement.focus();
        }

        this.gmap.setCenter(MapUtils.toLatLng(this.measuringPoint.location.coordinate));
        this.gmap.setZoom(18);

        this.drawMapIcon();
    }

    private setDrivingLane() {
        if (this.measuringPointForm && this.drivingLanes && this.drivingLanes.length > 0 && this.measuringPoint) {
            const drivingLaneControl = this.measuringPointForm.get("drivingLaneId");
            if (drivingLaneControl && !drivingLaneControl.value) {

                if (this.measuringPoint.drivingLane) {
                    drivingLaneControl.patchValue(this.measuringPoint.drivingLane.id);
                }
            }
        }
    }

    adjustForm() {
        if (!this.measuringPointForm) return;

        if (!this.rights.hasBackendRight(BackendRights.EditMeasuringPoint)) {
            this.measuringPoint.readonly = true;
        }

        if (this.measuringPoint.readonly) {
            this.measuringPointForm.disable();
        } else {
            this.measuringPointForm.enable();
        }

        const analysisTypeId = this.measuringPointForm.get("analysisTypeId").value;
        this.showHeading = MeasuringPointUtils.analysisTypeSupportsDirectionalRotation(analysisTypeId);

        const disableAndHide = (control: SigncoFormControl) => {
            control.disable();
            control.hidden = true;
        };

        const enableAndShow = (control: SigncoFormControl) => {
            if (this.measuringPointForm.enabled) {
                control.enable();
            }
            control.hidden = false;
        };

        const requiresDrivingLane = MeasuringPointUtils.analysisTypeRequiresDrivingLane(analysisTypeId);

        if (!requiresDrivingLane) {
            disableAndHide(this.measuringPointForm.get("from"));
            disableAndHide(this.measuringPointForm.get("to"));
            disableAndHide(this.measuringPointForm.get("drivingLaneId"));
        } else {
            enableAndShow(this.measuringPointForm.get("from"));
            enableAndShow(this.measuringPointForm.get("to"));
            enableAndShow(this.measuringPointForm.get("drivingLaneId"));
        }

        this.setDrivingLane();
    }

    private drawMapIcon() {
        // Remove previous. This happens when the user switches between measuring points
        if (this.measuringPointMarker) {
            this.gmap.markers.clear();
        }

        const heading = this.measuringPointForm.get("heading").value as number;
        const coordinate = MapUtils.toLatLng(this.measuringPoint.location.coordinate);
        this.measuringPointMarker = ArrowMarker.create(coordinate, heading, this.showHeading);
        this.gmap.markers.add(this.measuringPointMarker);
    }

    updateHeading(heading: number) {
        const headingControl = this.measuringPointForm.get("heading");

        // [0, 360[
        heading = Math.min(359, Math.max(0, heading));

        headingControl.patchValue(heading);
        headingControl.markAsDirty();

        ArrowMarker.rotate(this.measuringPointMarker, heading);
    }

    protected openAnalysisTypeDialog() {
        const currentValue = this.measuringPointForm.get("analysisTypeId").value;
        this.typeFilterDialog.openFilterDialog(currentValue ? [currentValue] : []);
    }

    protected setAnalysisType(selected: AnalysisType[]) {
        this.measuringPointForm.get("analysisTypeId").setValue(selected.takeFirstOrDefault());
        this.adjustForm();
    }

    handleMapReady(gmap: BasicMapComponent) {
        this.gmap = gmap;
        this.initialize();
    }

    createReverse() {
        if (this.isCreatingNew()) return;

        this.navigationService.createReverseMeasuringPoint(this.measuringPoint);
    }

    createCopy() {
        if (this.isCreatingNew()) return;

        this.navigationService.createCopy(this.measuringPoint);
    }

    reset() {
        this.projects = this.allProjects?.map(x => {
            return {
                value: x.id,
                label: x.name
            } as SelectItem;
        }) ?? [];

        if (!this.isCreatingNew()) {
            this.initialize();
        } else {
            if (this.isCreatingNewLocation()) {
                this.navigationService.toMeasuringPoints();
            } else {
                // This will default to the first location if it exists
                this.navigationService.toDefaultMeasuringPointLocation(this.measuringPoint.location.id);
            }
        }
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.measuringPointForm);
        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNewMeasuringPoint();
        } else {
            this.saveMeasuringPoint();
        }
    }

    private createNewMeasuringPoint() {
        const measuringPointCreator = Object.assign(new MeasuringPointCreator(), this.measuringPointForm.value) as MeasuringPointCreator;

        if (this.isCreatingNewLocation()) {
            measuringPointCreator.location = new LocationUpdater(this.measuringPoint.location);
        } else {
            measuringPointCreator.locationId = this.measuringPoint.location.id;
        }

        const onSuccess = async (newMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            this.measuringPointForm.markAsPristine();
            this.submitting = false;
            this.measuringPoint = newMeasuringPoint;
            this.save.emit(newMeasuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        delete measuringPointCreator["onlyActiveProjects"]; // don't send unnecesseary property
        this.submitting = true;
        this.measuringPointApi.create$(measuringPointCreator).subscribe(onSuccess, onError);
    }

    private saveMeasuringPoint() {
        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.measuringPoint = savedMeasuringPoint;
            this.measuringPointForm.markAsPristine();
            this.toastService.saveSuccess();
            this.submitting = false;
            this.save.emit(savedMeasuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        // Remove circular reference
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);
        Object.assign(measuringPointUpdater, this.measuringPointForm.value);

        delete measuringPointUpdater["onlyActiveProjects"]; // don't send unnecesseary property
        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }

    isCreatingNew(): boolean {
        return this.measuringPoint && !this.measuringPoint.id; // check if id is falsy
    }

    isCreatingNewLocation(): boolean {
        return this.measuringPoint && !this.measuringPoint.location.id; // check if id is falsy
    }


    onlyActiveProjectsChanged(event: any) {
        this.onlyActiveProjects = event.target.checked;
        if (this.onlyActiveProjects) {
            const allActiveProjects = this.projectsService.filterActiveProjects(this.allProjects) as IProject[];

            this.projects = allActiveProjects
                .map(x => {
                    return {
                        label: x.name,
                        value: x.id
                    } as SelectItem;
                });

            const currentlySelectedProjectIds = (this.measuringPointForm.get("projectIds").value ?? []) as Array<number>;
            const projectsIdsPresentInFilter = currentlySelectedProjectIds.filter(activeId => allActiveProjects.find(x => x.id === activeId));
            this.measuringPointForm.get("projectIds").setValue(projectsIdsPresentInFilter);
        } else {
            this.projects = this.allProjects.map(x => {
                return {
                    label: x.name,
                    value: x.id
                } as SelectItem;
            });
        }

        this.projectsMultiSelect.filterValue = ""; // clear entered filter so everything can remain consistent
    }

    selectedProject(projectId: number): IProject {
        return this.allProjects.find(x => x.id === projectId);
    }
}
