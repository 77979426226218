<div class="m-form-group"
     *ngIf="formVisible && form"
     formGroupName="modeToGpioLink">
    <ng-container formGroupName="modes">
        <ng-container *ngFor="let modeToPinStateForm of modeToPinStatesForms.controls; let modeToPinStateIndex = index"
                      formGroupName="{{modeToPinStateIndex}}">
            <div class="px-16 py-8 flex align-center m-border-bottom"
                 [ngClass]="{'m-border-top': modeToPinStateIndex > 0}">
                <h3>{{"modeConfiguration.title" | translate}} {{modeToPinStateForm.get("modeLabel").value}}</h3>

                <app-icon-button icon="add"
                                 class="ml-8"
                                 (click)="addGpioPinState(modeToPinStateForm)"
                                 text="{{'modeConfiguration.addGpioPinState' | translate}}">
                </app-icon-button>
            </div>

            <ng-container formGroupName="pinStates">
                <div class="p-16">
                    <div *ngFor="let pinStateForm of modeToPinStateForm.get('pinStates').controls; let pinStateIndex = index"
                         class="m-form-group row"
                         formGroupName="{{pinStateIndex}}">

                        <div class="col-6">
                            <div class="row">
                                <label class="col-4"
                                       for="pinId{{modeToPinStateIndex}}{{pinStateIndex}}">
                                    {{"externalInputConfiguration.pinId" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="pinId{{modeToPinStateIndex}}{{pinStateIndex}}"
                                           type="number"
                                           class="p-inputtext"
                                           [formControl]="pinStateForm.get('pinId')"
                                           appAutofocus
                                           #pinIdInput />

                                    <app-form-validation [control]="pinStateForm.get('pinId')"
                                                         [input]="pinIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="flex align-center">
                                <label class="mr-16"
                                       for="state{{modeToPinStateIndex}}{{pinStateIndex}}">
                                    {{"modeConfiguration.state" | translate}}
                                </label>

                                <p-inputSwitch id="state{{modeToPinStateIndex}}{{pinStateIndex}}"
                                               class="mr-8"
                                               [formControl]="pinStateForm.get('state')"></p-inputSwitch>

                                <app-icon-button icon="delete"
                                                 class="ml-auto"
                                                 (click)="deleteGpioPinState(modeToPinStateForm, pinStateIndex)"></app-icon-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>