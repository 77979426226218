import { Component, ViewChild, NgZone, ElementRef } from "@angular/core";
import { MapContextMenuItem } from "../..";

@Component({
    selector: "app-map-context-menu",
    templateUrl: "./map-context-menu.component.html"
})
export class MapContextMenuComponent {
    private contextMenuOverlay: google.maps.OverlayView;
    contextMenuItems: MapContextMenuItem[] = [];
    style = { left: "", top: "" };
    @ViewChild("contextMenuDiv", { static: true }) div: ElementRef<HTMLDivElement>;

    constructor(private readonly ngZone: NgZone) { }

    public openContextMenu(map: google.maps.Map, latLng: google.maps.LatLng, contextMenuItems: MapContextMenuItem[]) {
        this.close();

        this.contextMenuItems = contextMenuItems || [];
        this.contextMenuOverlay = new google.maps.OverlayView();

        this.contextMenuOverlay.onAdd = () => {
            this.setPosition(latLng);

            const pane = this.contextMenuOverlay.getPanes().floatPane; // Floatpane is on top and can receive DOM events
            pane.appendChild(this.div.nativeElement);

            // Trigger google maps redraw
            google.maps.event.trigger(map, "resize");
        };

        this.contextMenuOverlay.draw = () => {
            this.setPosition(latLng);
        };

        this.contextMenuOverlay.onRemove = () => {
            this.contextMenuOverlay.setMap(null);
            this.contextMenuOverlay = null;
        };

        this.contextMenuOverlay.setMap(map);
    }

    public close() {
        this.resetPosition();

        if (this.contextMenuOverlay) {
            this.contextMenuOverlay.onRemove();
        }
    }

    trackByFn(index: number, item: MapContextMenuItem) {
        return index;
    }

    // Stops seeing glimpses of ye olde position
    private resetPosition() {
        this.style = { left: "-10000px", top: "-100000px" };
    }

    private setPosition(latLng: google.maps.LatLng) {
        const overlayProjection = this.contextMenuOverlay.getProjection();
        const clickLocationInPx = overlayProjection.fromLatLngToDivPixel(latLng);

        this.ngZone.run(() => {
            this.style.left = clickLocationInPx.x + "px";
            this.style.top = clickLocationInPx.y + "px";
        });
    }

    onContextMenuItemClick(contextMenuItem: MapContextMenuItem, e: Event) {
        e.stopPropagation();

        if (contextMenuItem.onClick) {
            // Since we're in dirty javascript with callbacks and dynamic DOM
            // it's required to be run in an ngZone block
            // Which makes sure Angular is tracking any binding changes
            this.ngZone.run(() => {
                contextMenuItem.onClick();
            });
        }

        this.close();
        return false;
    }
}
