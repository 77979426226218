import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective } from "@angular/forms";
import { SigncoFormControl, SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-filter-configuration",
    templateUrl: "./device-link-filter-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkFilterConfigurationComponent extends DeviceLinkFormGroupComponent {
    showCarFilterDetails = true;
    showBikeFilterDetails = true;

    showCarFilter = true;
    showBikeFilter = true;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.filterConfiguration) {
            if (this.fgd.form.get("filterConfiguration")) this.fgd.form.removeControl("filterConfiguration");
            this.showCarFilter = analyzerConfiguration.canDetectCars;
            this.showBikeFilter = analyzerConfiguration.canDetectBikes;

            this.form = this.formBuilder.group({
                excludeLight: false,
                excludeMedium: false,
                excludeHeavy: false,
                excludeBike: false,
                excludeMoped: false,
                excludeCarForward: false,
                excludeCarReverse: false,
                excludeBikeForward: false,
                excludeBikeReverse: false
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.filterConfiguration);
            this.fgd.form.addControl("filterConfiguration", this.form);
            return true;
        }

        if (this.fgd.form.get("filterConfiguration")) this.fgd.form.removeControl("filterConfiguration");
        this.form = null;
        return false;
    }

    invertCheckboxInputValue(formControl: SigncoFormControl, e: MouseEvent) {
        formControl.patchValue(!(e.target as HTMLInputElement).checked);
    }

    onFilterChanged() {
        if (this.form.get("excludeCarForward").value === true
            && this.form.get("excludeCarReverse").value === true) {
            this.showCarFilterDetails = false;
        } else {
            this.showCarFilterDetails = true;
        }

        if (this.form.get("excludeBikeForward").value === true
            && this.form.get("excludeBikeReverse").value === true) {
            this.showBikeFilterDetails = false;
        } else {
            this.showBikeFilterDetails = true;
        }
    }
}
