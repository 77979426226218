<div class="m-layout-w-actions-top m-fixed-height m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="canEditSafetyQuestion"
                id="createUserButton">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"safetyQuestion.create" | translate}}
        </button>
    </div>

    <app-safety-questions [paginator]="true"
                          class="m-layout-area-body m-border-top"
                          [deleteCommand]="true"
                          [editCommand]="true"></app-safety-questions>
</div>
<app-manage-safety-question-dialog></app-manage-safety-question-dialog>