<div class="container-detail">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="m-card"
                     style="padding: 15px; margin: 15px;">

                    <h1>
                        {{(online ? "serviceStatus.text" : "serviceStatus.userOffline") | translate : {productName: websiteService.getProductName()} }}
                    </h1>

                    <div>
                        <p [translate]="'serviceStatus.nextRetry'"
                           [translateParams]="{seconds: pollTimer}">
                        </p>

                        <app-loading-spinner *ngIf="pollTimer === 0"></app-loading-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>