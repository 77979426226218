<div class="m-cookie m-card m-shadow"
     *ngIf="visible">

    <div class="m-cookie-body p-16">
        <p>
            {{"cookieNotification.text" | translate}}

            <a href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies+and+similar+technologies"
               target="_blank"
               class="m-navigator">
                {{"cookieNotification.learnMore" | translate}}
            </a>
        </p>
    </div>

    <div class="m-btn-group m-cookie-actions px-16 pb-16">
        <a class="m-btn m-btn-primary"
           (click)="acceptCookies()"
           id="acceptCookiesButton">
            {{"cookieNotification.accept" | translate}}
        </a>
    </div>
</div>