import { CustomTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { AlarmSeverity, IAlarm } from "src/app/models/alarm";
import { TranslateService } from "@ngx-translate/core";
import { IDevice } from "src/app/models/device";

@Component({
    selector: "app-device-alarms",
    templateUrl: "./device-alarms.component.html"
})
export class DeviceAlarmsComponent extends CustomTableComponent<IAlarm> implements OnChanges {
    @Input() device: IDevice;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translateService: TranslateService) {

        super("device-alarm.component", elementRef, tableService);

        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;

        this.addColumn(new TableColumn("timeStamp", "general.date", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true }));
        // this.addColumn(new TableColumn("rule", "deviceAlarms.rule", { filterType: FilterType.Text, sortable: true }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.reload(false);
        }

        super.ngOnChanges(changes);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.reload(false);
    }

    canLoad(): boolean {
        return !!this.device;
    }

    loadTableRows() {
        this.setData(this.device.alarms);
    }

    getTextColor(alarm: IAlarm): string {
        if (alarm.severityId === AlarmSeverity.High) return "red";
        if (alarm.severityId === AlarmSeverity.Low) return "orange";

        return "black";
    }
}