import { Component, OnInit, OnDestroy, OnChanges, Input } from "@angular/core";
import { DomainDataService } from "src/app/services/domain-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { Subscription } from "rxjs";

@Component({
    selector: "app-domain-data-field",
    templateUrl: "./domain-data-field.component.html"
})
export class DomainDataFieldComponent implements OnInit, OnDestroy, OnChanges {
    @Input() enumKey: string;
    @Input() domainDataType: string;
    @Input() ngStyle: {};

    private isInitialized: boolean;
    private domainDataReloadSubscription: Subscription;
    enumContent: ViewModelEnum;

    constructor(private readonly domainDataService: DomainDataService) { }

    ngOnInit() {
        this.setContent();

        this.domainDataReloadSubscription = this.domainDataService.uponReload.subscribe(() => {
            this.setContent();
        });

        this.isInitialized = true;
    }

    ngOnChanges() {
        if (this.isInitialized) {
            this.setContent();
        }
    }

    private async setContent() {
        if (!this.enumKey || !this.domainDataType) {
            this.enumContent = null;
            return;
        }

        this.enumContent = await this.domainDataService.getViewModelEnum(this.domainDataType, this.enumKey);
    }

    ngOnDestroy() {
        if (this.domainDataReloadSubscription) {
            this.domainDataReloadSubscription.unsubscribe();
        }
    }
}