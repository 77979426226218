import { HttpRequest, HttpResponse, HttpParams } from "@angular/common/http";
import { HttpOptions, ApiServiceBase } from "../resource/api";
import { NgForageCache } from "ngforage";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Constants } from "../constants/constants";
import { JsonUtils } from "../utilities/json-utils";
import * as Flatted from "flatted";

@Injectable({
    providedIn: "root"
})
export class CacheService {
    constructor(
        private readonly cache: NgForageCache) {
    }

    async get<T>(url: string, options: HttpOptions): Promise<T> {
        let cachedResult: T = null;

        const cacheKey = this.getCacheKey(url, options.params);
        const cachedItem = await this.cache.getCached<T>(cacheKey);

        const removeCache = () => {
            cachedResult = null;
            this.cache.removeItem(cacheKey);
        };

        try {
            if (cachedItem && cachedItem.hasData) {
                if (cachedItem.expired) {
                    removeCache();
                } else {
                    let item = cachedItem.data;

                    if (typeof item === "string") {
                        item = Flatted.parse(item, JsonUtils.decode);
                    }

                    const eTag = await this.cache.getCached<string>(this.getETagCacheKey(cacheKey));
                    if (eTag.hasData) {
                        // set eTag in our headers
                        options.headers = options.headers.append("If-None-Match", eTag.data);
                    }

                    cachedResult = item;
                }
            }
        } catch (e) {
            // Invalid cache
            removeCache();
        }

        return cachedResult;
    }

    async set(request: HttpRequest<any>, response: HttpResponse<any>): Promise<void> {
        if (request.method !== "GET") return;

        // Workaround to set eTag property when result is an array
        // JSON.stringify() removes meta properties on Array
        if (response.body) {
            const key = this.getCacheKey(request.url, request.params);
            const cacheDuration = Constants.cacheDurationInMinutes * 60 * 1000;

            if (response.headers.has("etag")) {
                const eTag = response.headers.get("etag");
                this.cache.setCached(this.getETagCacheKey(key), eTag);
            }

            try {
                await this.cache.setCached(key, response.body, cacheDuration);
            } catch (ex) {
                try {
                    if (!environment.production) {
                        // Ex will be a string with stack trace
                        // We don't want the entire stack trace
                        if (ex.stack) {
                            const exceptionString = ex.stack as string;
                            const indexOfAt = exceptionString.indexOf("at http");
                            const indexOfLastBreak = exceptionString.lastIndexOf("\n", indexOfAt);

                            console.warn("Flatting cache", request.url, exceptionString.substr(0, indexOfLastBreak));
                        } else {
                            console.warn("Flatting cache", request.url, ex);
                        }
                    }

                    await this.cache.setCached(key, Flatted.stringify(response.body), cacheDuration);
                } catch (ex) {
                    if (!environment.production) {
                        console.error("Couldn't set cache", key, response.body, ex);
                    }
                }
            }
        }
    }

    getCacheKey(url: string, params: HttpParams): string {
        // Cloned HttpParams get their `updates` property filled in
        // init() transforms them to `map`
        // No idea why it works the way it does, but okay.
        // https://github.com/angular/angular/blob/7.2.14/packages/common/http/src/params.ts#L92-L233
        (params as any).init();
        return `${url}${JSON.stringify(Array.from((params as any).map.entries()))}`;
    }

    private getETagCacheKey(cacheKey: string): string {
        return `${cacheKey}_ETag`;
    }

    async clearForApi(api: ApiServiceBase): Promise<void> {
        const path = api.getUrl();

        const keys = await this.cache.keys();
        for (const key of keys) {
            if (key.contains(path)) {
                this.cache.removeItem(key);
            }
        }
    }

    async clear(): Promise<void> {
        await this.cache.clear();
    }
}