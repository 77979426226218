import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "src/app/guards/auth.guard";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";

import { DataTabComponent } from "./components/data-tab.component";

import { ValidationComponent } from "../validation/components/validation/validation.component";
import { MeasuringPointValidationComponent } from "../validation/components/measuring-point-validation/measuring-point-validation.component";
import { SharedKeysComponent } from "../share-data/components/shared-keys/shared-keys.component";
import { ImportedKeysComponent } from "../share-data/components/imported-keys/imported-keys.component";

const routes: Routes = [
    {
        path: "", component: DataTabComponent, children: [
            { path: "", redirectTo: "validation", pathMatch: "full" },
            { path: "validation", component: ValidationComponent, canActivate: [AuthGuard], data: { name: "dataValidation.title" } },
            { path: "prediction", loadChildren: () => import("../prediction/prediction.module").then(m => m.PredictionModule), canActivate: [AuthGuard] },
            { path: "bulkupload", loadChildren: () => import("../bulk-upload/bulk-upload.module").then(m => m.BulkUploadModule), canActivate: [AuthGuard] },
            { path: "keys", component: SharedKeysComponent, canActivate: [AuthGuard], data: { name: "sharedKeys.title" } },
            { path: "imported", component: ImportedKeysComponent, canActivate: [AuthGuard], data: { name: "importedSharedKeys.title" } }
        ]
    },
    { path: "validation/:measuringPointId", component: MeasuringPointValidationComponent, canDeactivate: [PendingChangesGuard], canActivate: [AuthGuard], data: { name: "dataValidation.title" } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DataRoutingModule { }
