<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingDatePeriod ? "datePeriod.edit" : "datePeriod.create") | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm"
          autocomplete="off"
          novalidate>

        <div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': dialogForm.get('name').showErrors }">

                <label class="col-4"
                       for="name">
                    {{"general.name" | translate}}
                </label>

                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           id="name"
                           formControlName="name"
                           appAutofocus
                           #nameInput />

                    <app-form-validation [control]="dialogForm.get('name')"
                                         [input]="nameInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': dialogForm.get('typeId').showErrors }">

                <label class="col-4"
                       for="type">
                    {{"general.type" | translate}}
                </label>

                <div class="col-8">
                    <p-dropdown id="type"
                                [options]="datePeriodTypes"
                                appendTo="body"
                                formControlName="typeId"
                                [panelStyle]="{'width':'100%'}"
                                placeholder="{{'datePeriod.selectType' | translate}}"
                                [autoDisplayFirst]="false"
                                #typeInput>
                    </p-dropdown>

                    <app-form-validation [control]="dialogForm.get('typeId')"
                                         [input]="typeInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': dialogForm.get('dates').showErrors }">

                <label class="col-4"
                       for="dates">
                    {{"general.dates" | translate}}
                </label>

                <div class="col-8">
                    <p-calendar #calendar
                                id="dates"
                                formControlName="dates"
                                autoWidth="false"
                                appendTo="body"
                                [showIcon]="true"
                                [hourFormat]="calendarSettings.hourFormat"
                                [dateFormat]="calendarSettings.dateFormat"
                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                [monthNavigator]="false"
                                [yearNavigator]="false"
                                [selectionMode]="datesControl?.selectionMode"
                                [yearRange]="primeComponentService.calendarYearRangeFuture"
                                #datesInput>
                    </p-calendar>

                    <a *ngIf="datesControl.enabled && datesControl.calendar"
                       (click)="datesControl.toggleDateSelectionMode()"
                       style="font-size: small;">
                        {{(datesControl.selectionMode === "range" ? "calendar.dateSelectionRange" : "calendar.dateSelectionMultiple") | translate}}
                    </a>

                    <app-form-validation [control]="dialogForm.get('dates')"
                                         [input]="datesInput">
                    </app-form-validation>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>