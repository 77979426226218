<form [formGroup]="cameraConfigurationForm"
      class="m-layout-area-body m-layout-w-actions-bottom m-border-top"
      *ngIf="device"
      autocomplete="off">
    <div class="p-16 m-layout-area-body m-overflow-auto">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cameraConfigurationForm.get('url').showErrors }">

            <label class="col-4"
                   for="text">
                {{"cameraConfiguration.url" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       class="p-inputtext"
                       id="url"
                       formControlName="url"
                       appAutofocus
                       #urlInput>

                <app-form-validation [control]="cameraConfigurationForm.get('url')"
                                     [input]="urlInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cameraConfigurationForm.get('username').showErrors }">

            <label class="col-4"
                   for="text">
                {{"cameraConfiguration.username" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       class="p-inputtext"
                       id="username"
                       formControlName="username"
                       #usernameInput>

                <app-form-validation [control]="cameraConfigurationForm.get('username')"
                                     [input]="usernameInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cameraConfigurationForm.get('password').showErrors }">

            <label class="col-4"
                   for="text">
                {{"cameraConfiguration.password" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       class="p-inputtext"
                       id="password"
                       formControlName="password"
                       #passwordInput>

                <app-form-validation [control]="cameraConfigurationForm.get('password')"
                                     [input]="passwordInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cameraConfigurationForm.get('refreshDelayInSeconds').showErrors }">

            <label class="col-4"
                   for="refreshDelayInSeconds">
                {{"cameraConfiguration.refreshDelayInSeconds" | translate}}
            </label>

            <div class="col-8">
                <div class="m-input-group">
                    <input id="refreshDelayInSeconds"
                           type="number"
                           formControlName="refreshDelayInSeconds"
                           class="p-inputtext"
                           min="0"
                           #refreshDelayInSecondsInput>
                    <div class="m-input-group-addon">{{"measurements.seconds" | translate}}
                    </div>
                </div>

                <app-form-validation [control]="cameraConfigurationForm.get('refreshDelayInSeconds')"
                                     [input]="refreshDelayInSecondsInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': cameraConfigurationForm.get('supportsLivestream').showErrors }">

            <label class="col-4"
                   for="supportsLivestream">
                {{"cameraConfiguration.supportsLivestream" | translate}}
            </label>

            <div class="col-8">
                <p-inputSwitch id="supportsLivestream"
                               formControlName="supportsLivestream"
                               #supportsLivestreamInput>
                </p-inputSwitch>
            </div>

            <app-form-validation [control]="cameraConfigurationForm.get('supportsLivestream')"
                                 [input]="supportsLivestreamInput">
            </app-form-validation>
        </div>

        <div class="m-form-group row">
            <div class="col-4"></div>
            <div class="col-8">
                <app-camera-controls *ngIf="device"
                                     [deviceId]="device.id"
                                     [refreshDelayInMs]="5000"
                                     [editPresets]="true"></app-camera-controls>
            </div>
        </div>
    </div>

    <div class="m-btn-group m-btn-group-right m-border-top m-layout-area-bottom px-16 py-8">
        <button class="m-btn m-btn-link"
                (click)="reset()"
                type="button"
                id="camera_tab_reset_button">
            {{"form.reset" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="camera_tab_submit_button">
            <app-loading-spinner id="camera_tab_spinner"
                                 *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </div>
</form>