import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from "@angular/core";
import { MapTypeData, MapTypeIds } from "../../services/map-type-provider";

/**
 * Allow the user to switch between map types such as Google Maps, OpenStreetMap, etc.
 */
@Component({
    selector: "app-map-type-selector",
    templateUrl: "./map-type-selector.component.html",
})
export class MapTypeSelectorComponent {

    private mapTypeDropdown: HTMLDivElement;
    @ViewChild("mapTypeDropdown", { static: false }) set setMapTypeDropdown(mapTypeDropdown: ElementRef) {
        this.mapTypeDropdown = mapTypeDropdown ? mapTypeDropdown.nativeElement : null;
    }

    @Output() selectionChanged: EventEmitter<MapTypeData> = new EventEmitter();

    protected mapTypes: Array<MapTypeData>;
    protected selectedMapType: MapTypeData;

    public initialize() {
        if (this.mapTypes) return; // Already initialized

        this.mapTypes = [];
        this.mapTypes.push(new MapTypeData(MapTypeIds.openStreetMapTypeId, "mapTypes.openStreetMap", "icon-osm", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.openCycleMapTypeId, "mapTypes.openCycleMap", "icon-osm-bike", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.openTrafficMapTypeId, "mapTypes.openTransportMap", "icon-osm-transport", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.GoogleRoadmap, "mapTypes.roadmap", "icon-gm", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.GoogleRoadmap, "mapTypes.traffic", "icon-gm", true));
        this.mapTypes.push(new MapTypeData(MapTypeIds.GoogleTerrain, "mapTypes.terrain", "icon-gm", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.GoogleSatellite, "mapTypes.satellite", "icon-gm-satellite", false));
        this.mapTypes.push(new MapTypeData(MapTypeIds.GoogleHybrid, "mapTypes.hybrid", "icon-gm-satellite", false));
    }

    protected toggleMapTypeDropdown(event: Event) {
        this.mapTypeDropdown.classList.toggle("open");
    }

    protected closeMapTypeDropdown() {
        this.mapTypeDropdown.classList.remove("open");
    }

    public updateSelectedMapTypeId(mapTypeId: string) {
        if (!this.mapTypes) return;

        this.selectedMapType = this.mapTypes.find(x => x.mapTypeId === mapTypeId);
    }

    protected setSelectedMapType(data: MapTypeData) {
        this.selectedMapType = data;
        this.closeMapTypeDropdown();
        this.selectionChanged.emit(data);
    }

    protected mapTypesTrackByFn(index: number, item: MapTypeData) {
        return index;
    }
}