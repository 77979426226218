<div class="m-map-overlay m-map-overlay-left-top mt-16 ml-16"
     *ngIf="mapDetailService.currentMapDetail !== 0 && splitMap?.gmap">
    <div class="m-map-overlay-form flex align-center">
        <div class="m-input-group-w-icon"
             *ngIf="searchControl">

            <p-autoComplete id="googleSearchAutoComplete"
                            field="label"
                            scrollHeight="500px"
                            [style]="{'width':'320px', 'height': '36px'}"
                            [inputStyle]="{'height':'36px'}"
                            dataKey="label"
                            placeholder="{{'map.search' | translate}}"
                            [showTransitionOptions]="'0ms'"
                            [hideTransitionOptions]="'0ms'"
                            [minLength]="0"
                            [delay]="300"
                            [suggestions]="placePredictions"
                            (completeMethod)="predictPlaces($event.query)"
                            (onSelect)="handleSelect($event)"
                            (onFocus)="showPlacePredictions()"
                            (onKeyUp)="selectFirstPlace($event)"
                            #placesAutoComplete>

                <ng-template let-placePrediction
                             pTemplate="item">
                    <div class="flex align-center">
                        <div class="mr-8">
                            <app-svg-icon [icon]="placePrediction.icon">
                            </app-svg-icon>
                        </div>
                        <span [innerHTML]="placePrediction.htmlLabel | safeHtml"></span>
                    </div>
                </ng-template>

            </p-autoComplete>

            <app-svg-icon class="m-input-group-icon m-input-group-icon-right"
                          icon="navigate">
            </app-svg-icon>
        </div>

        <div class="ml-8"
             *ngIf="loaded && mapDetailService.currentMapDetail === 1">
            <app-icon-button class=""
                             [text]="'map.scan' | translate"
                             [tooltipPosition]="'left'"
                             [icon]="'qr-code'"
                             [styleClass]="'m-btn m-btn-primary m-btn-square'"
                             (click)="openScanner()"></app-icon-button>
        </div>

        <div *ngIf="!readonly">
            <ng-container *ngIf="loaded && mapDetailService.currentMapDetail === 3">
                <div class="m-map-overlay-group-form ml-8"
                     id="groupOverlay"
                     [hidden]="!manageGroupComponent?.creatingOrEditing">
                    <app-manage-group-component></app-manage-group-component>
                </div>

                <ng-container *ngIf="!manageGroupComponent?.creatingOrEditing && canCreateGroup">
                    <button class="m-btn m-btn-secondary ml-8"
                            [disabled]="!mapDataService.canCreateGroup"
                            (click)="createGroup()"
                            id="createGroupButton">
                        <app-svg-icon icon="add">
                        </app-svg-icon>
                        {{"manageGroup.create" | translate}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<app-scanner-dialog></app-scanner-dialog>