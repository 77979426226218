import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { ApiTableComponent, TableService, TableColumn, FilterType, ColumnVisibility } from "src/app/modules/shared/components/table/table.component";
import { ServiceRequestOptions, SearchParameters, FilterDescriptor } from "src/app/models/search";
import { ApplicationCreateResultDialogComponent } from "../application-create-result-dialog/application-create-result.dialog";
import { IApplication, IClientCreatedResult } from "src/app/models/application";
import { ManageApplicationDialogComponent } from "../manage-application-dialog/manage-application.dialog";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationApi } from "src/app/resource/application.api";
import { IOrganization } from "src/app/models/user";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ModalService } from "src/app/services/modal.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-applications",
    templateUrl: "./applications.component.html"
})
export class ApplicationsComponent extends ApiTableComponent<IApplication> implements OnInit, OnChanges, OnDestroy {
    @ViewChild(ManageApplicationDialogComponent, { static: true }) manageApplicationDialog: ManageApplicationDialogComponent;
    @ViewChild(ApplicationCreateResultDialogComponent, { static: true }) createResultDialog: ApplicationCreateResultDialogComponent;

    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;
    @Input() organization: IOrganization;

    canCreateApplication = false;
    rightViewModels: ViewModelEnum[];
    groupedRights: { key: string, label: string, rights: ViewModelEnum[] }[] = [];

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly applicationApi: ApplicationApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly domainDataService: DomainDataService,
        private readonly accessibilityService: AccessibilityService,
        private readonly globalEventsService: GlobalEventsService) {

        super("applications.component", elementRef, applicationApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;
        this.footer = false;
        this.selectionMode = "";

        const organizationColumn = new TableColumn("user.organization.name", "general.user", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations() });
        organizationColumn.displayDropdownFilter = true;
        this.addColumn(organizationColumn);

        // Load Organizations for drop-down filter
        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.addColumn(new TableColumn("clientId", "applications.clientId", { filterType: FilterType.Text, sortable: true, width: 400, resizable: false }));
        this.addColumn(new TableColumn("b2CClientId", "applications.b2CClientId", { filterType: FilterType.Text, sortable: true, width: 400, resizable: false }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, resizable: false }));

        const rightsColumn = new TableColumn("rights", "general.rights", { filterType: FilterType.MultiSelect, sortable: false, visibility: ColumnVisibility.HideMini, width: 300 });
        this.addColumn(rightsColumn);

        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
            this.canCreateApplication = this.rights.hasBackendRight(BackendRights.EditApplication);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.domainDataService.get(DomainData.Rights).then(rightViewModels => {
            this.rightViewModels = rightViewModels;
            rightsColumn.filterOptions = rightViewModels;

            this.loadTableRows();
        });

        this.registerCommand({
            text: "applications.edit",
            icon: "edit",
            click: (application) => this.edit(application),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.EditApplication)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (application) => this.delete(application),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteApplication)
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    ngOnChanges(changes: SimpleChanges) {
        const organizationChange = changes["organization"];
        if (organizationChange) {
            this.setOrganization(this.organization);
        }
    }

    ngOnDestroy() {
        this.services.mapDataService.unsubscribe(this.mapDataServiceFilterKey);
    }

    setOrganization(organization: IOrganization) {
        this.organization = organization;
        this.reload();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        if (this.organization) {
            searchParameters.filter = [new FilterDescriptor("organizationId", this.organization.id)];
        }
        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("application", "user");
        options.includes.add("application", "organization");

        return options;
    }

    canLoad(): boolean {
        return !!this.rightViewModels;
    }

    async processLoadedData(data: IApplication[]): Promise<IApplication[]> {
        for (const application of data) {
            application.rightViewModels = this.rightViewModels.filter(x => application.rights.contains(x.value));
        }

        return data;
    }

    create() {
        const onCreate = (clientCreatedResult: IClientCreatedResult) => {
            this.reload();
            this.createResultDialog.open(clientCreatedResult);
        };

        this.manageApplicationDialog.create(onCreate);
    }

    edit(application: IApplication) {
        const onEdit = () => {
            this.reload();
        };

        this.manageApplicationDialog.edit(application, onEdit);
    }

    delete(application: IApplication) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.applicationApi.delete$(application.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("applications.deleteConfirmation", application);
        this.modalService.delete(modalBody, onDelete);
    }

    copyToClipboard(application: IApplication) {
        this.accessibilityService.copyToClipboard(application.clientId, "applications.copiedClientIdToClipboard");
    }
    copyB2CToClipboard(application: IApplication) {
        this.accessibilityService.copyToClipboard(application.b2CClientId, "applications.copiedClientIdToClipboard");
    }
}
