<div class="m-card"
     *ngIf="measuringPoint">
    <div class="row">
        <div class="col-4">
            <div class="m-map m-map-md">
                <app-basic-map [showMapTypeSelector]="true"
                               (mapReady)="handleMapReady($event)"></app-basic-map>

            </div>
        </div>

        <div class="col-8">
            <div class="py-16">
                <h2>{{measuringPoint.code}}</h2>

                <dl class="m-dl-hor"
                    style="max-width: 640px;">
                    <dt *ngIf="measuringPoint.description">
                        {{"general.description" | translate}}
                    </dt>
                    <dd *ngIf="measuringPoint.description">
                        {{measuringPoint.description}}
                    </dd>

                    <ng-container *ngIf="measuringPoint.from && measuringPoint.to">
                        <dt>
                            {{"measuringPoints.fromTo" | translate}}
                        </dt>
                        <dd>
                            {{measuringPoint.from}} → {{measuringPoint.to}}
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="measuringPoint.location.address">
                        <dt>
                            {{"general.address" | translate}}
                        </dt>
                        <dd>
                            {{measuringPoint.location.address.line1}}
                            <br /> {{measuringPoint.location.address.zipCode}}
                            {{measuringPoint.location.address.city}}
                            <br /> {{measuringPoint.location.address.country}}
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="measuringPoint.location.coordinate">
                        <dt>
                            {{"general.coordinates" | translate}}
                        </dt>
                        <dd>
                            {{measuringPoint.location.coordinate.latitude | number:'1.0-6'}} -
                            {{measuringPoint.location.coordinate.longitude | number:'1.0-6'}}
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="measuringPoint.location.officialId?.type || measuringPoint.location.officialId?.number">
                        <dt>
                            {{"officialId.title" | translate}}
                        </dt>
                        <dd>
                            {{measuringPoint.location.officialId.type}}{{measuringPoint.location.officialId.number}}
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="measuringPoint.location.officialId?.milestone">
                        <dt>
                            {{"officialId.milestone" | translate}}
                        </dt>
                        <dd>
                            {{measuringPoint.location.officialId.milestone}}
                        </dd>
                    </ng-container>

                    <ng-container *ngIf="canCreateMeasuringPointForOthers && measuringPoint.location.ownerId">
                        <dt>
                            {{"general.organization" | translate}}
                        </dt>
                        <dd>
                            <app-navigator [organizationId]="measuringPoint.location.ownerId"></app-navigator>
                        </dd>
                    </ng-container>
                </dl>
            </div>
        </div>
    </div>
</div>