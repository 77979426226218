import { Duration } from "moment";

export class LightAction {
    priority: number;
    content: LightContent;
    temporaryContentParameters: TemporaryContentParameters;
    ownerId: string;
}

export class LightContent {
    modeId: string;
    animation: LightContentFrame[];
    modeDescription: string;
}

export class LightContentFrame {
    modeId: string;
    duration: Duration;
    modeDescription: string;
}

export class TemporaryContentParameters {
    startTime: Date;
    endTime: Date;
}