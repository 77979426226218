<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="createCommand">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"sharedKeys.create" | translate}}
        </button>
    </div>

    <p-table [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-border-top"
             [loading]="loading"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             dataKey="id"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer">
            <ng-container *ngIf="total && !paginator">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-sharedKey>
            <tr [pSelectableRow]="sharedKey">

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <ng-container *ngSwitchCase="'key'">
                        <a (click)="copyToClipboard(sharedKey)">
                            <app-svg-icon icon="copy"
                                          style="float: left; margin-top: -5px;"></app-svg-icon>
                            {{sharedKey.key}}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="'owner.name'">
                        <app-navigator [organizationId]="sharedKey.owner.id"></app-navigator>
                    </ng-container>

                    <ng-container *ngSwitchCase="'allowValidation'">
                        <input type="checkbox"
                               [ngModel]="sharedKey[column.field]"
                               [disabled]="true" />
                    </ng-container>

                    <ng-container *ngSwitchCase="'shareAllMeasuringPoints'">
                        <input type="checkbox"
                               [ngModel]="sharedKey[column.field]"
                               [disabled]="true" />
                    </ng-container>

                    <ng-container *ngSwitchCase="'measuringPoints'">
                        <ul *ngIf="sharedKey.measuringPoints">
                            <ng-template #measuringPointTemplate
                                         let-measuringPoint>
                                <app-navigator [measuringPoint]="measuringPoint"
                                               [ngStyle]="{'font-size': 'small'}">
                                </app-navigator>
                            </ng-template>

                            <app-show-more [list]="sharedKey.measuringPoints"
                                           [template]="measuringPointTemplate"></app-show-more>
                        </ul>
                    </ng-container>

                    <ng-container *ngSwitchCase="'createDate'">
                        {{sharedKey.createDate | momentDateTime:false}}
                    </ng-container>

                    <ng-container *ngSwitchCase="'importedBy'">
                        <ul *ngIf="sharedKey.importedBy">
                            <li *ngFor="let organization of sharedKey.importedBy; trackBy: serviceModelTrackByFn">
                                <app-navigator [organizationId]="organization.id"
                                               [ngStyle]="{'font-size': 'small'}">
                                </app-navigator>
                            </li>
                        </ul>
                    </ng-container>

                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(sharedKey)"
                                                 *ngIf="command.rowValidFunc(sharedKey)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{sharedKey[column.field]}}
                    </ng-container>
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>