import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AssignmentDetailContainerComponent } from "../assignment-detail-container/assignment-detail-container.component";
import { AssignmentStatus, IAssignment } from "src/app/models/assignment";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { ServiceRequestOptions } from "src/app/models/search";
import { SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { MapDetailService } from "src/app/services/map-detail.service";
import { AssignmentApi } from "src/app/resource/assignment.api";
import { ILocation } from "src/app/models/location";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { firstValueFrom } from "rxjs";

export enum SelectionMode {
    LocationInfo = 0,
    Markings = 1,
    ParkingBans = 2,
    SubAssignments = 3
}

@Component({
    selector: "app-assignment-location",
    templateUrl: "./assignment-location.component.html"
})
export class AssignmentLocationComponent implements OnInit, OnDestroy {

    @ViewChild(AssignmentDetailContainerComponent, { static: false }) assignmentDetailContainer: AssignmentDetailContainerComponent;

    assignment: IAssignment;
    selectedProjectName: string;

    private readonly subscriptionManager = new SubscriptionManager();
    private readonly queryParamNameAssignmentId = "assignmentId";
    private parentAssignmentId: number;
    canCreateAssignment = false;
    canEditAssignment = false;

    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        readonly domainModelFilterService: DomainModelFilterService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly assignmentApi: AssignmentApi,
        private readonly navigationService: NavigationService,
        private readonly route: ActivatedRoute,
        private readonly mapDetailService: MapDetailService) {

        elementRef.nativeElement.classList.add("container-detail");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateAssignment = rights?.hasBackendRight(BackendRights.CreateAssignment);
            this.canEditAssignment = rights?.hasBackendRight(BackendRights.EditAssignment);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }

    ngOnInit() {
        this.subscribeToRouterEvents();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    editAssignment() {
        this.navigationService.toAssignmentDetails(this.assignment.id);
    }

    toMap() {
        this.mapDetailService.navigateToMapDetail(this.mapDetailService.currentMapDetail);
    }

    createNewSubAssignment() {
        this.navigateToCreateSubtask((assignmentId: number) => this.navigationService.createSubAssignment(assignmentId));
    }

    handleOnAssignmentSaved(savedAssignment: IAssignment) {
        if (this.assignment && this.assignment.id === savedAssignment.id) {
            this.reload();
            this.assignmentDetailContainer.updateTitle();
            return;
        }

        this.navigationService.toAssignment(savedAssignment);
    }

    handleLastSubAssignmentDeleted() {
        // The last sub assignment has been deleted.
        // we will need to re-fetch assignment because some options maybe won't be available anymore
        if (!this.parentAssignmentId) {
            this.reload();
        }
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        // needed for validation -> don't allow addition of sub-assignments when parent assignment is containing tasks/parking bans
        serviceRequestOptions.includes.add("assignment", "tasks");
        serviceRequestOptions.includes.add("assignment", "parkingBans");

        serviceRequestOptions.includes.add("assignment", "project");
        if (!this.parentAssignmentId) {
            serviceRequestOptions.includes.add("assignment", "subAssignments");
        } else {
            serviceRequestOptions.includes.add("assignment", "parentAssignment");
        }

        serviceRequestOptions.includes.add("assignment", "location");
        serviceRequestOptions.includes.add("assignment", "currentStatus");
        serviceRequestOptions.includes.add("assignment", "supervisor");
        return serviceRequestOptions;
    }

    get isAssignmentFinished() {
        return this.assignment && this.assignment.currentStatus && this.assignment.currentStatus.statusId === AssignmentStatus.Finished;
    }

    get subAssignmentsAvailable() {
        return (!this.assignment.parentAssignmentId && !(this.assignment.tasks && this.assignment.tasks.length > 0) && !(this.assignment.parkingBans && this.assignment.parkingBans.length > 0));
    }

    private subscribeToRouterEvents() {
        const routeQueryParamSubscription = this.route.params.subscribe(
            async (params) => {
                await this.setFromParams(params);
            }
        );
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    private async reload() {
        if (this.assignment?.id) {
            this.assignment = await this.fetchAssignment(this.assignment.id);
        }
    }

    private async setFromParams(params: Params) {

        if (this.isCreateMode(params)) {
            this.setEmptyAssignment();
            return;
        }

        const assignmentId = +params[this.queryParamNameAssignmentId];
        if (!assignmentId || assignmentId <= 0) {
            this.setEmptyAssignment();
            return;
        }

        try {
            this.parentAssignmentId = null;
            let assignment: IAssignment = await this.fetchAssignment(assignmentId);
            if (assignment.parentAssignmentId) {
                this.parentAssignmentId = assignment.parentAssignmentId;
                assignment = await this.fetchAssignment(assignmentId);
            }
            this.assignment = assignment;
        } catch {
            this.navigationService.toHome();
        }
    }

    private async setEmptyAssignment() {
        const assignment: IAssignment = {} as IAssignment;

        if (this.navigationService.parentAssignmentId) {
            this.parentAssignmentId = this.navigationService.parentAssignmentId;
            assignment.parentAssignmentId = this.navigationService.parentAssignmentId;
            // fetch parent assignment
            assignment.parentAssignment = await this.fetchAssignment(this.navigationService.parentAssignmentId);

            // no navigation after creation, just close the dialog
            this.manageLocationComponent.navigateAfterCreation = false;

            const onSuccessfullySelectedLocation = (newLocation: ILocation) => {
                if (!newLocation) {
                    return;
                }

                assignment.location = { coordinate: newLocation.coordinate } as ILocation;
            };

            // we are going to create a new location
            this.manageLocationComponent.create(assignment.parentAssignment.location.coordinate, onSuccessfullySelectedLocation.bind(this));
        } else {
            assignment.location = { coordinate: this.navigationService.newAssignmentCoordinate } as ILocation;
        }

        this.assignment = assignment;
        this.navigationService.newAssignmentCoordinate = null;
        this.navigationService.parentAssignmentId = null;
    }

    private async fetchAssignment(assignmentId: number): Promise<IAssignment> {
        return firstValueFrom(this.assignmentApi.get$(assignmentId, null, this.getServiceRequestOptions(), false));
    }

    private isCreateMode(params: Params) {
        return params[this.queryParamNameAssignmentId] === "new";
    }

    private navigateToCreateSubtask(navigateTo: (assignmentId: number) => Promise<boolean>) {
        if (!this.assignment) return;

        navigateTo(this.assignment.id);
    }

    private getParamValueFromChildRoute(paramName: string) {
        return this.route.children && this.route.children.length > 0 ? this.route.children[0].snapshot.params[paramName] : undefined;
    }

    handleLastTaskOrParkingBanRemoved() {
        this.reload();
    }
}
