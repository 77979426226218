import { DeviceType, IDeviceFeatures, IDeviceStatus } from "./device";
import { IServiceModelBase } from "./servicemodelbase";

export enum AlarmSeverity {
    Low = "low",
    High = "high"
}

export interface IAlarm extends IServiceModelBase {
    expression: string;
    createDate: Date;
    severityId: AlarmSeverity;
    rule: IRule;
}

export interface IRule extends IServiceModelBase {
    code: string;
    warningExpression: string;
    errorExpression: string;
    isAdminOnly: boolean;

    onlyForLinkedDevices: boolean;
    features: IDeviceFeatures;
    deviceTypes: IDeviceTypeRule[];
}

export interface IDeviceTypeRule {
    id: number;
    rule: IRule;
    deviceTypeId: DeviceType;
}

export class RuleCreator {
    warningExpression: string;
    errorExpression: string;
    isAdminOnly: boolean;
    onlyForLinkedDevices: boolean;

    deviceTypes: DeviceType[];
    features: IDeviceFeatures;
}

export class RuleUpdater extends RuleCreator {
    constructor(existingRule: IRule) {
        super();

        this.id = existingRule.id;
        this.warningExpression = existingRule.warningExpression;
        this.errorExpression = existingRule.errorExpression;
        this.isAdminOnly = existingRule.isAdminOnly;
        this.deviceTypes = existingRule.deviceTypes.map(x => x.deviceTypeId);
        this.onlyForLinkedDevices = existingRule.onlyForLinkedDevices;
        this.features = existingRule.features;
    }

    id: number;
}

export class RuleTester {
    status: IDeviceStatus;
    warningExpression: string;
    errorExpression: string;
}

export class TestResult {
    didTriggerWarning: boolean;
    didTriggerError: boolean;
    error: string;
}