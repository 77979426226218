import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, OnDestroy, OnInit, inject } from "@angular/core";
import { LocationDialogComponent } from "../location-dialog/location.dialog";
import { ILocation } from "src/app/models/location";
import { MapDataService } from "src/app/services/map-data.service";
import { AuthorizationInfo, IOrganization } from "src/app/models/user";
import { IDevice } from "src/app/models/device";
import { IAssignment } from "src/app/models/assignment";
import { MapIconService } from "src/app/services/map-icon-service";
import { IMapIconState } from "src/app/models/map-icon";
import { ITask } from "src/app/models/task";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager, MapUtils } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { BasicMapComponent, BasicMarkerOptions, createBasicMarker } from "src/app/modules/map-basic";
import { LocationApi } from "src/app/resource/location.api";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";

@Component({
    selector: "app-location-detail",
    templateUrl: "./location-detail.component.html"
})
export class LocationDetailComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;

    @Input() location: ILocation;
    @Input() measuringPoint: IMeasuringPoint;
    @Input() device: IDevice;
    @Input() assignment: IAssignment;
    @Input() showParentAssignmentInfo = false;
    @Input() editCommand = false;
    @Input() showOwner = true;
    @Input() task: ITask;

    @Output() save = new EventEmitter<ILocation>();

    gmap: BasicMapComponent;
    owner: IOrganization;
    mapIcon: IMapIconState;

    rights: Rights;
    canEditLocation = false;
    canCreateMeasuringPointForOthers = false;
    authorizationInfo: AuthorizationInfo;
    private readonly subscriptionManager = new SubscriptionManager();

    private readonly locationApi = inject(LocationApi);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly mapDataService = inject(MapDataService);
    private readonly mapIconService = inject(MapIconService);
    private readonly navigationService = inject(NavigationService);
    private readonly translateService = inject(TranslateService);
    private readonly modalService = inject(ModalService);

    ngOnInit(): void {
        const currentAuthorizationInfo = this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo: AuthorizationInfo) => {
            this.authorizationInfo = authorizationInfo;
            this.canCreateMeasuringPointForOthers = this.globalEventsService.hasMultipleOrganizations();
        });
        this.subscriptionManager.add("currentAuthorizationInfo", currentAuthorizationInfo);

    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
    ngOnChanges(changes: SimpleChanges) {
        const locationChange = changes["location"] || changes["locationSummary"];
        if (locationChange) {
            this.initialize();
        }

        const measuringPointChange = changes["measuringPoint"] || changes["device"];
        if (measuringPointChange) {
            this.createLocationMarker();
        }
    }

    initialize() {
        if (this.assignment && !this.location && this.assignment.location) {
            this.location = this.assignment.location;
        }

        if (!this.location || !this.gmap) return;

        this.gmap.map.setOptions({
            zoom: 18,
            clickableIcons: false,
            center: MapUtils.toLatLng(this.location.coordinate)
        });

        this.mapDataService.getOrganization$(x => x.id === this.location.ownerId).then(owner => {
            this.owner = owner;
        });


        if (!this.measuringPoint) {
            this.createLocationMarker();
        }

        this.mapIcon = this.mapIconService.getIconState(this.location.mapIconStateId)?.configuration;
    }

    createLocationMarker() {
        if (!this.location || !this.gmap) return;

        this.gmap.markers.clear();

        const markerOptions = new BasicMarkerOptions(MapUtils.toLatLng(this.location.coordinate));
        markerOptions.enableLabel = false;
        markerOptions.draggable = false;
        markerOptions.disableCluster = true;

        const marker = createBasicMarker(markerOptions);
        this.gmap.markers.add(marker);
    }

    handleMapReady(gmap: BasicMapComponent) {
        this.gmap = gmap;

        this.initialize();
        this.createLocationMarker();
    }

    protected editLocation() {
        this.manageLocationComponent.edit(this.location, () => {
            this.initialize();
            this.createLocationMarker();
            this.save.next(this.location);
        });
    }

    protected deleteLocation() {
        const onDelete = () => {
            const onSuccess = () => {
                this.navigationService.toHome();
            };

            this.locationApi.delete$(this.location.id).subscribe(onSuccess);
        };

        const modalBody = this.translateService.instant("locations.deleteConfirmation", { code: this.location.code }) as string;
        this.modalService.delete(modalBody, onDelete);
    }

    public get locationFormatted(): string {
        if (!this.location?.address) return null;

        const parts = [];
        if (this.location.address.line1) parts.push(this.location.address.line1.trim());
        if (this.location.address.city) parts.push(`${this.location.address.zipCode?.trim() ?? ""} ${this.location.address.city.trim()}`.trim());
        if (this.location.address.country) parts.push(this.location.address.country.trim());

        const filteredParts = parts.filter(part => part !== "");
        const result = filteredParts.join(", ");
        return result;
    }
}
