<div class="m-map-filters-dialog-body">
    @if (isLoading) {
    <app-loading-spinner />
    } @else {
    @if (allPulseDetectionTypes?.length > 0) {
    <div>
        <h3 class="mb-4">{{ "mapFilters.pulseDetection" | translate }}</h3>

        <div class="m-map-filters-chips">
            @for (type of allPulseDetectionTypes; track type) {
            <button [id]="'button' + type"
                    [disabled]="isDisabled(type)"
                    [class.is-selected]="isSelected(type)"
                    class="m-map-filters-chip"
                    (click)="toggleSelection(type)">
                <app-svg-icon [icon]="type">
                </app-svg-icon>
                {{ type | translateEnum:"analysisType" }}
            </button>
            }
        </div>
    </div>
    }
    @if (allPositionDetectionTypes?.length > 0) {
    <div>
        <h3 class="mb-4">{{ "mapFilters.positionDetection" | translate }}</h3>

        <div class="m-map-filters-chips">
            @for (type of allPositionDetectionTypes; track type) {
            <button [id]="'button' + type"
                    [disabled]="isDisabled(type)"
                    [class.is-selected]="isSelected(type)"
                    class="m-map-filters-chip"
                    (click)="toggleSelection(type)">
                <app-svg-icon [icon]="type">
                </app-svg-icon>
                {{ type | translateEnum:"analysisType" }}
            </button>
            }
        </div>
    </div>
    }

    @if (allOtherDetectionTypes?.length > 0) {
    <div>
        <h3 class="mb-4">{{ "mapFilters.otherDetection" | translate }}</h3>

        <div class="m-map-filters-chips">
            @for (type of allOtherDetectionTypes; track type) {
            <button [id]="'button' + type"
                    [disabled]="isDisabled(type)"
                    [class.is-selected]="isSelected(type)"
                    class="m-map-filters-chip"
                    (click)="toggleSelection(type)">
                <app-svg-icon [icon]="type">
                </app-svg-icon>
                {{ type | translateEnum:"analysisType" }}
            </button>
            }
        </div>
    </div>
    }

    @if (allTrafficControlTypes?.length > 0) {
    <div>
        <h3 class="mb-4">{{ "mapFilters.trafficControl" | translate }}</h3>

        <div class="m-map-filters-chips">
            @for (type of allTrafficControlTypes; track type) {
            <button [id]="'button' + type"
                    [disabled]="isDisabled(type)"
                    [class.is-selected]="isSelected(type)"
                    class="m-map-filters-chip"
                    (click)="toggleSelection(type)">
                <app-svg-icon [icon]="type">
                </app-svg-icon>
                {{ type | translateEnum:"analysisType" }}
            </button>
            }
        </div>
    </div>
    }

    @if (allOtherTypes?.length > 0) {
    <div>
        <h3 class="mb-4">{{ "mapFilters.other" | translate }}</h3>

        <div class="m-map-filters-chips">
            @for (type of allOtherTypes; track type) {
            <button [id]="'button' + type"
                    [disabled]="isDisabled(type)"
                    [class.is-selected]="isSelected(type)"
                    class="m-map-filters-chip"
                    (click)="toggleSelection(type)">
                <app-svg-icon [icon]="type">
                </app-svg-icon>
                {{ type | translateEnum:"analysisType" }}
            </button>
            }
        </div>
    </div>
    }
    }
</div>
