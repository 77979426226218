import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { SigncoFormGroup } from "src/app/models/form";
import { IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
    selector: "app-measuring-point-counting-zone-configuration",
    templateUrl: "./measuring-point-counting-zone-configuration.component.html",
    host: { class: "m-layout-area-body m-layout-default" }
})
export class MeasuringPointCountingZoneConfigurationComponent implements IComponentCanDeactivate, IChangeGuard, OnChanges {
    measuringPoint: IMeasuringPoint;
    countingZoneConfigurationForm: SigncoFormGroup;
    submitting = false;


    constructor(
        private readonly changeGuardService: ChangeGuardService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formValidationService: FormValidationService,
        private readonly toastService: ToastService,
        private readonly measuringPointApi: MeasuringPointApi
    ) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.countingZoneConfigurationForm?.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint?.countingZoneConfiguration) return;

        this.countingZoneConfigurationForm = this.formBuilder.group({
            isSecured: this.measuringPoint.countingZoneConfiguration.isSecured,
        }) as SigncoFormGroup;
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.countingZoneConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.initialize();
            // this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        Object.assign(measuringPointUpdater.countingZoneConfiguration, this.countingZoneConfigurationForm.value);

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }
}