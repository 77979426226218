import { Injectable } from "@angular/core";
import { AvsApiService } from "./avs-api";
import { RetroTrafficLightData } from "src/app/models/avs/retro-traffic-light-data";

@Injectable({
    providedIn: "root"
})
export class RetroTrafficLightDataAvsApi extends AvsApiService<RetroTrafficLightData> {
    getRoute(): string {
        return "retroTrafficLightData";
    }
}