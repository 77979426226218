<app-loading-spinner *ngIf="!loaded; else main"></app-loading-spinner>

<ng-template #main>
    <form autocomplete="off">
        <div class="m-form-group row">
            <div class="col-4">
                <div class="flex align-center">
                    <p-inputSwitch [(ngModel)]="allEnabled"
                                   [ngModelOptions]="{standalone: true}"
                                   (onChange)="setAllEnabled($event.checked)"
                                   [disabled]="readonly"
                                   id="enableAll">
                    </p-inputSwitch>

                    <label class="ml-8">{{"deviceIgnoreRules.toggleAll" | translate}}</label>
                </div>
            </div>
        </div>

        <div class="m-form-group row"
             *ngFor="let ignoredRule of ignoredRules; trackBy:  trackByFn">
            <div class="col-4">
                <div class="flex align-center">
                    <p-inputSwitch [ngModel]="!ignoredRule.ignored" (ngModelChange)="ignoredRule.ignored = $event"
                                   [ngModelOptions]="{standalone: true}"
                                   (onChange)="onChange(ignoredRule, $event.checked)"
                                   [disabled]="readonly"
                                   [id]="ignoredRule.rule.code">
                    </p-inputSwitch>

                    <label class="ml-8">{{ignoredRule.rule.code}}</label>
                </div>
            </div>

            <div class="col-4">
                <input type="text"
                       [(ngModel)]="ignoredRule.comment"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="!ignoredRule.ignored && readonly"
                       (change)="onChange()"
                       class="p-inputtext"
                       [id]="ignoredRule.code"
                       placeholder="{{'general.comment' | translate}}">
            </div>

            <div class="col-4">
                <p-calendar id="updateInterval"
                            appendTo="body"
                            selectionMode="single"
                            [(ngModel)]="ignoredRule.until"
                            [ngModelOptions]="{standalone: true}"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [showTime]="true"
                            [showSeconds]="false"
                            [hourFormat]="24"
                            [dateFormat]="calendarSettings.dateFormat"
                            [disabled]="!ignoredRule.ignored && readonly"
                            (change)="onChange()"
                            (onSelect)="onChange()"
                            placeholder="{{'general.until' | translate}}"
                            showButtonBar="false"
                            todayButtonStyleClass="hidden"
                            #updateIntervalInput></p-calendar>
            </div>
        </div>
    </form>
</ng-template>
