<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"uploadKeyholeMarkup.title" | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm"
          [hidden]="showResult">

        <div class="m-form-group row"
             *ngIf="dialogForm.get('ownerId')"
             [ngClass]="{ 'has-error': dialogForm.get('ownerId').showErrors }">

            <label class="col-2"
                   for="ownerId">
                {{"general.organization" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown formControlName="ownerId"
                            id="ownerId"
                            appAutofocus
                            appendTo="body"
                            [panelStyle]="{'min-width':'100%'}"
                            [options]="organizations"
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            [autoDisplayFirst]="false"
                            placeholder="{{'general.organization' | translate}}"
                            #ownerIdInput>
                </p-dropdown>

                <app-form-validation [control]="dialogForm.get('ownerId')"
                                     [input]="ownerIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('projectIds').showErrors }">
            <label class="col-2"
                   for="projectIds">
                {{"general.project" | translate}}
            </label>
            <div class="col-10">
                <p-multiSelect formControlName="projectIds"
                               appendTo="body"
                               placeholder="{{'assignment.selectProject' | translate }}"
                               [style]="{'width':'100%'}"
                               [options]="projects"
                               [filter]="true"
                               [showHeader]="true"
                               [showToggleAll]="false"
                               panelStyleClass="measuring-point-projects"
                               #projectIdsInput>

                    <ng-template pTemplate="header">
                        <div style="display: flex; flex-direction:row; justify-content: end; width: 100%; margin-bottom: 5px; grid-column: span 2">
                            <label class="mr-8">
                                {{"projects.onlyActive" | translate}}
                            </label>
                            <label class="m-input-checkbox"
                                   style="align-items:center!important">
                                <input type="checkbox"
                                       id="only_active_projects_checkbox"
                                       formControlName="onlyActiveProjects"
                                       (change)="onlyActiveProjectsChanged($event)" />
                            </label>
                        </div>
                    </ng-template>
                </p-multiSelect>

                <app-form-validation [control]="dialogForm.get('projectIds')"
                                     [input]="projectIdsInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('analysisTypeId')?.showErrors }">
            <label class="col-2"
                   for="analysisTypeId">
                {{"measuringPoints.analysisType" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown id="analysisTypeId"
                            formControlName="analysisTypeId"
                            appendTo="body"
                            [options]="analysisTypes"
                            [panelStyle]="{'min-width':'100%'}"
                            [autoDisplayFirst]="false"
                            placeholder="{{'measuringPointDetailTab.selectAnalysisType' | translate }}"
                            #analysisTypeIdInput></p-dropdown>

                <app-form-validation [control]="dialogForm.get('analysisTypeId')"
                                     [input]="analysisTypeIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="dialogForm.get('drivingLaneId')"
             [ngClass]="{ 'has-error': dialogForm.get('drivingLaneId')?.showErrors }">
            <label class="col-2"
                   for="drivingLaneId">
                {{"measuringPoints.drivingLane" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown id="drivingLaneId"
                            [formControl]="drivingLaneControl"
                            appendTo="body"
                            [options]="drivingLanes"
                            [panelStyle]="{'min-width':'100%'}"
                            [autoDisplayFirst]="false"
                            placeholder="{{'measuringPointDetailTab.selectDrivingLane' | translate }}"
                            #drivingLaneIdInput></p-dropdown>

                <app-form-validation [control]="dialogForm.get('drivingLaneId')"
                                     [input]="drivingLaneIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="dialogForm.get('prefix')"
             [ngClass]="{ 'has-error': dialogForm.get('prefix').showErrors }">

            <label class="col-2"
                   for="prefix">
                {{"words.prefix" | translate}}
            </label>

            <div class="col-10">

                <input id="prefix"
                       type="text"
                       class="p-inputtext"
                       formControlName="prefix"
                       #prefixInput>

                <app-form-validation [control]="dialogForm.get('prefix')"
                                     [input]="prefixInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.submitted && !uploadFile }">

            <label class="col-2">
                {{"general.file" | translate}}
            </label>

            <div class="col-10">
                <label for="fileInput"
                       class="p-inputtext m-input-file m-input-group-w-icon">
                    <span class="label mr-16">
                        <ng-container *ngIf="uploadFile">
                            {{uploadFile.name}}
                        </ng-container>

                        <ng-container *ngIf="!uploadFile">
                            {{"general.selectFile" | translate}}
                        </ng-container>
                    </span>

                    <app-svg-icon icon="upload"
                                  class="ml-auto">
                    </app-svg-icon>

                    <input type="file"
                           id="fileInput"
                           accept=".kml, .kmz"
                           #file
                           (change)="setUploadFile(file)" />
                </label>

                <div *ngIf="dialogForm.submitted && !uploadFile"
                     class="error">
                    {{"validation.required" | translate}}
                </div>
            </div>

        </div>
    </form>

    <div *ngIf="showResult"
         class="dialog-content-table">
        <app-kml-result [kmlResult]="result"></app-kml-result>
    </div>

    <p-footer>
        <ng-container *ngIf="!showResult; else resultButtonsTemplate">
            <button type="button"
                    [disabled]="submitting"
                    class="m-btn m-btn-link"
                    (click)="close()">
                {{"form.cancel" | translate}}
            </button>

            <button tabindex="99"
                    [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit">
                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>
                {{"form.upload" | translate}}
            </button>
        </ng-container>

        <ng-template #resultButtonsTemplate>
            <button type="button"
                    class="m-btn m-btn-link"
                    (click)="hideResult()">
                {{"form.back" | translate}}
            </button>

            <button type="button"
                    class="m-btn m-btn-primary"
                    (click)="close()">
                {{"form.close" | translate}}
            </button>
        </ng-template>
    </p-footer>
</p-dialog>