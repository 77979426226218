import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubscriptionManager } from "src/app/utilities";
import { VmsImageEditorService } from "../../services/vms-image-editor.service";
import { Gradient, Pattern } from "fabric/fabric-impl";

export enum AlignOptions {
    Left = 0,
    Right = 1,
    Top = 2,
    Bottom = 3,
    CenterH = 4,
    CenterV = 5
}


@Component({
    selector: "app-vms-image-editor-properties",
    templateUrl: "./vms-image-editor-properties.component.html",
    styleUrls: []
})
export class VmsImageEditorPropertiesComponent implements OnInit, OnDestroy {

    canvas: fabric.Canvas;
    currentSelectedObject: fabric.Object;
    private readonly subscriptionManager = new SubscriptionManager();

    colorPalette: Array<any> = [
        "#FFFFFF",
        "#FF0000",
        "#008000",
        "#0000FF",
        "#FFFF00",
        "#000000"
    ];

    // So, you want to add another custom font?
    // 1. Add the font to the assets/fonts folder
    // 2. Add the font to Assets/Fonts folder in the API project
    // 3. Add font-face in style.css
    // 4. Add an empty div with fontfamily: "fontname" in the html-page (otherwise, it will not be loaded in the browser on first try!)
    // 5. Add the font name to the array below
    fontFamilies: Array<any> = [
        "Arial",
        "Arial Narrow",
        "Lucida Sans",
        "Verdana",
    ];
    constructor(private readonly vmsImageEditorService: VmsImageEditorService) { }

    ngOnInit(): void {

        const currentSelectedObjectChangeSubscription = this.vmsImageEditorService.selectedObjectChange$.subscribe(async (object) => {
            this.currentSelectedObject = object;
        });
        this.subscriptionManager.add("currentSelectedObjectChange", currentSelectedObjectChangeSubscription);

        const currentCanvasObjectChangeSubscription = this.vmsImageEditorService.currentCanvas$.subscribe(async (canvas) => {
            this.canvas = canvas;
            if (this.canvas) {
                // Selection cleared: remove current selected object
                this.canvas.on("selection:cleared", (e) => {
                    if (this.currentSelectedObject != null && this.currentSelectedObject.type == "i-text") {
                        const textObject = this.currentSelectedObject as fabric.IText;
                        if (textObject.text == "") {
                            this.canvas.remove(this.currentSelectedObject);
                            this.canvas.renderAll();
                        }
                    }
                    this.vmsImageEditorService.notifySelectedObjectChanged(null);
                });

                // Object modified: refresh preview
                this.canvas.on("object:modified", (e) => {
                    this.vmsImageEditorService.setIsCurrentVmsImageChanged(true);
                    // if I-text => remove vmsTextAlign
                    if (this.currentSelectedObject != null && this.currentSelectedObject.type == "i-text") {
                        const textObject = this.currentSelectedObject as fabric.IText;
                        textObject["vmsTextAlign"] = null;
                    }
                    this.vmsImageEditorService.requestRenderPreview();
                });
                this.canvas.on("text:changed", (e) => {
                    this.vmsImageEditorService.setIsCurrentVmsImageChanged(true);
                    console.log("text changed");
                    this.vmsImageEditorService.requestRenderPreview();
                });
                // Object scaling: adjust scaling arrow (only line, not triangle)
                this.canvas.on("object:scaling", (e) => {
                    if (e.target.name == "arrow") {
                        this._scaleArrow(e.target);
                    } else if (e.target.type == "rect") {
                        this._scaleRect(e.target);
                    }

                });
                this.canvas.on("object:scaled", (e) => {
                    if (e.target.name == "arrow") {
                        this._scaleArrow(e.target);
                    }
                });
            }
        });
        this.subscriptionManager.add("currentCanvasObjectChange", currentCanvasObjectChangeSubscription);
    }
    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    alignText(type: AlignOptions) {
        const activeObj = this.canvas.getActiveObject() as fabric.IText;
        switch (type) {
            case AlignOptions.Left:
                activeObj.set({
                    textAlign: "left"
                });
                break;
            case AlignOptions.Right:
                activeObj.set({
                    textAlign: "right"
                });
                break;
            case AlignOptions.CenterH:
                activeObj.set({
                    textAlign: "center"
                });
                break;
        }
        activeObj.setCoords();
        this._refreshCanvas();
    }

    /* Align the selected object */
    align(type: AlignOptions) {
        const activeObj = this.canvas.getActiveObject();
        if (activeObj) {
            const boundingRect = activeObj.getBoundingRect(true, true);
            switch (type) {
                case AlignOptions.Left:
                    activeObj.set({
                        left: activeObj.left - boundingRect.left
                    });
                    // if type of activeObj is i-text, set textAlign to left
                    if (activeObj.type == "i-text") {
                        const textObject = activeObj as fabric.IText;
                        textObject.set({
                            textAlign: "left"
                        });
                        textObject["vmsTextAlign"] = "left";
                    }
                    break;
                case AlignOptions.Right:
                    activeObj.set({
                        left: this.canvas.vptCoords.br.x - boundingRect.width / 2
                    });
                    // if type of activeObj is i-text, set textAlign to right
                    if (activeObj.type == "i-text") {
                        const textObject = activeObj as fabric.IText;
                        textObject.set({
                            textAlign: "right"
                        });
                        textObject["vmsTextAlign"] = "right";
                    }
                    break;
                case AlignOptions.Top:
                    activeObj.set({
                        top: activeObj.top - boundingRect.top
                    });
                    break;
                case AlignOptions.Bottom:
                    activeObj.set({
                        top: this.canvas.vptCoords.br.y - boundingRect.height / 2
                    });
                    break;
                case AlignOptions.CenterH:
                    activeObj.viewportCenterH();
                    // if type of activeObj is i-text, set textAlign to center
                    if (activeObj.type == "i-text") {
                        const textObject = activeObj as fabric.IText;
                        textObject.set({
                            textAlign: "center"
                        });
                        textObject["vmsTextAlign"] = "center";
                    }
                    break;
                case AlignOptions.CenterV:
                    activeObj.viewportCenterV();
                    break;
            }
            activeObj.setCoords();
            this._refreshCanvas();
        }
    }
    // Update fill color and refresh object
    updateFillColor(color) {
        if (color) {
            this._updateFillColor(color);
            this._refreshCanvas();
        }
    }

    // Update stroke color and refresh object
    updateStrokeColor(color) {
        if (color) {
            this._updateStrokeColor(color);
            this._refreshCanvas();
        }
    }

    // Update stroke and fill color and refresh object
    updateColor(color) {
        if (color) {
            this._updateFillColor(color);
            this._updateStrokeColor(color);
            this._refreshCanvas();
        }
    }
    setStrokeWidth(width: string) {
        if (this.currentSelectedObject) {
            this.currentSelectedObject.set({
                strokeWidth: parseInt(width),
            });
            this._refreshCanvas();
        }
    }
    setFontSize(size: string) {
        if (this.currentSelectedObject) {
            const textObject = this.currentSelectedObject as fabric.IText;
            textObject.set({
                fontSize: parseInt(size),
            });
            this._refreshCanvas();
        }
    }
    setFontFamily(fontFamily: string) {
        if (this.currentSelectedObject) {
            const textObject = this.currentSelectedObject as fabric.IText;
            textObject.set({
                fontFamily: fontFamily,
            });
            this._refreshCanvas();
        }
    }
    setObjectDashed(dashed: boolean) {
        if (this.currentSelectedObject) {
            if (this.currentSelectedObject.type == "group") {
                const selectedGroup = this.currentSelectedObject as fabric.Group;
                selectedGroup.getObjects().forEach(element => {
                    element.set({
                        strokeDashArray: dashed ? [3, 2] : [],
                    });
                });
            } else {
                this.currentSelectedObject.set({
                    strokeDashArray: dashed ? [3, 2] : [],
                });
            }
            this._refreshCanvas();
        }
    }

    toggleBold() {
        if (this.currentSelectedObject) {
            const textObject = this.currentSelectedObject as fabric.IText;
            textObject.fontWeight = textObject.fontWeight == "bold" ? "normal" : "bold";
            this._refreshCanvas();
        }
    }
    get isBold() {
        if (this.currentSelectedObject) {
            const textObject = this.currentSelectedObject as fabric.IText;
            return textObject.fontWeight == "bold";
        }
        return false;
    }

    setRoundedCorners(round: boolean) {
        if (this.currentSelectedObject) {
            const rectObject = this.currentSelectedObject as fabric.Rect;
            rectObject.set({
                rx: round ? 5 : 0,
                ry: round ? 5 : 0,
            });
            this._refreshCanvas();
        }
    }
    get isRoundedCorners() {
        if (this.currentSelectedObject) {
            const rectObject = this.currentSelectedObject as fabric.Rect;
            return rectObject.rx > 0 && rectObject.ry > 0;
        }
        return false;
    }


    setObjectSmoothed(smoothed: boolean) {
        if (this.currentSelectedObject) {
            this.currentSelectedObject["vmsSmoothed"] = smoothed;
            this._refreshCanvas();
        }
    }

    get isObjectSmoothed() {
        if (this.currentSelectedObject) {
            return this.currentSelectedObject["vmsSmoothed"];
        }
        return false;
    }

    get isObjectDashed() {
        if (this.currentSelectedObject) {
            return this.currentSelectedObject.strokeDashArray != null && this.currentSelectedObject.strokeDashArray.length > 0;
        }
        return false;
    }

    private _updateFillColor(color) {
        // set color
        if (this.currentSelectedObject.type == "group") {
            const selectedGroup = this.currentSelectedObject as fabric.Group;
            selectedGroup.getObjects().forEach(element => {
                element.set({
                    fill: color,
                });
            });
        } else {
            this.currentSelectedObject.set({
                fill: color,
            });
        }
    }

    private _updateStrokeColor(color) {
        // set color
        if (this.currentSelectedObject.type == "group") {
            const selectedGroup = this.currentSelectedObject as fabric.Group;
            selectedGroup.getObjects().forEach(element => {
                element.set({
                    stroke: color,
                });
            });
        } else {
            this.currentSelectedObject.set({
                stroke: color,
            });
        }
    }

    private _scaleRect(target: fabric.Object) {
        const rectObject = target as fabric.Rect;
        rectObject.set({
            width: rectObject.width * rectObject.scaleX,
            height: rectObject.height * rectObject.scaleY,
            scaleX: 1,
            scaleY: 1,
        });
        this._refreshCanvas();
    }

    private _scaleArrow(target: fabric.Object) {
        const group = target as fabric.Group;
        this._adjustArrowsSize(group._objects, group.get("scaleY"));

    }
    private _adjustArrowsSize(arrowObjects, objectScaleY) {
        let triangleCount = 0;
        const triangles = [];

        arrowObjects.forEach(function (object) {
            if (object.get("type") == "triangle") {
                triangles[triangleCount] = object;
                triangleCount++;
                const ratio = 1 / objectScaleY;
                object.set("scaleY", ratio);
            }
        });

        this._refreshCanvas();
    }

    get arrowLineWidth() {
        if (this.currentSelectedObject && this.currentSelectedObject.type == "group" && this.currentSelectedObject.name == "arrow") {
            const group = this.currentSelectedObject as fabric.Group;
            const line = group._objects[0] as fabric.Line;
            return line.strokeWidth;
        }
        return 0;
    }

    setArrowLineWidth(width: string) {
        if (this.currentSelectedObject && this.currentSelectedObject.type == "group" && this.currentSelectedObject.name == "arrow") {
            const group = this.currentSelectedObject as fabric.Group;
            const line = group._objects[0] as fabric.Line;
            line.set({
                strokeWidth: parseInt(width),
            });
            this._refreshCanvas();
        }
    }

    get arrowTriangleWidth() {
        if (this.currentSelectedObject && this.currentSelectedObject.type == "group" && this.currentSelectedObject.name == "arrow") {
            const group = this.currentSelectedObject as fabric.Group;
            const triangle = group._objects[1] as fabric.Triangle;
            return triangle.width;
        }
        return 0;
    }

    setArrowTriangleWidth(width: string) {
        if (this.currentSelectedObject && this.currentSelectedObject.type == "group" && this.currentSelectedObject.name == "arrow") {
            const group = this.currentSelectedObject as fabric.Group;
            const triangle = group._objects[1] as fabric.Triangle;
            triangle.set({
                width: parseInt(width),
            });
            this._refreshCanvas();
        }
    }

    updateFillColorSymbol(oldColor: string, color: string) {
        if (this.currentSelectedObject.type == "group") {
            const objects = (this.currentSelectedObject as fabric.Group)._objects;
            objects.forEach(element => {
                if (element.fill == oldColor) {
                    element.set({
                        fill: color
                    });
                }
                if (element.strokeWidth > 1 && element.stroke == oldColor) {
                    element.set({
                        stroke: color
                    });
                }
            });
        } else if (this.currentSelectedObject.type == "path") {
            if (this.currentSelectedObject.fill == oldColor) {
                this.currentSelectedObject.set({
                    fill: color
                });
            }
            if (this.currentSelectedObject.strokeWidth > 1 && this.currentSelectedObject.stroke == oldColor) {
                this.currentSelectedObject.set({
                    stroke: color
                });
            }
        }
        this._refreshCanvas();
    }

    _refreshCanvas() {
        this.canvas.requestRenderAll();
        this.vmsImageEditorService.requestRenderPreview();
    }

    get uniqueColorsOfSelectedObject(): (string | Pattern | Gradient)[] {
        if (this.currentSelectedObject.type == "group") {
            const objects = (this.currentSelectedObject as fabric.Group)._objects;
            // Now get all unique fill colors
            const uniqueFillColors = objects.filter(x => x.fill != "").map(x => x.fill)
                .filter((value, index, self) => self.indexOf(value) === index);
            const uniqueStrokeColors = objects.filter(x => x.strokeWidth > 1)
                .map(x => x.stroke)
                .filter((value, index, self) => self.indexOf(value) === index);
            const mergedColors = [...uniqueFillColors, ...uniqueStrokeColors];
            return mergedColors.filter((color, index) => mergedColors.indexOf(color) === index);
        } else if (this.currentSelectedObject.type == "path") {
            // return fill color of path
            // optionally: stroke color if stroke width > 1
            const path = this.currentSelectedObject as fabric.Path;
            const uniqueFillColors = [path.fill];
            if (path.strokeWidth > 1 && path.stroke != path.fill) {
                uniqueFillColors.push(path.stroke);
            }
            return uniqueFillColors;
        }
    }
}