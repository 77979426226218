import { Component, Input, Output, EventEmitter, SimpleChanges, HostListener, OnChanges, ElementRef } from "@angular/core";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { AnalysisType, IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { IVehicleAnalysisTypeConfiguration } from "src/app/models/vehicle-analysis-type-configuration";
import { SelectItem } from "primeng/api";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-measuring-point-vehicle-configuration",
    templateUrl: "./measuring-point-vehicle-configuration.component.html"
})
export class MeasuringPointVehicleConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;
    @Output() save = new EventEmitter<IMeasuringPoint>();

    submitting = false;
    vehicleConfigurationForm: SigncoFormGroup;
    vehicleColors: SelectItem[];
    vehicleTypes: SelectItem[];

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly domainDataService: DomainDataService,
        private readonly changeGuardService: ChangeGuardService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");

        this.domainDataService.get(DomainData.VehicleColor).then(colors => {
            this.vehicleColors = colors;
        });

        this.domainDataService.get(DomainData.AnalysisVehicleType).then(types => {
            this.vehicleTypes = types;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.vehicleConfigurationForm && this.vehicleConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint || this.measuringPoint.analysisTypeId !== AnalysisType.Vehicle) return;

        this.vehicleConfigurationForm = this.formBuilder.group({
            licensePlate: ["", Validators.maxLength(20)],
            vehicleIdentificationNumber: ["", Validators.maxLength(30)],
            color: [null],
            type: [null]
        }) as SigncoFormGroup;

        if (this.measuringPoint.vehicleConfiguration) {
            this.vehicleConfigurationForm.patchValue(this.measuringPoint.vehicleConfiguration);
        }

        this.vehicleConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.vehicleConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        if (!measuringPointUpdater.vehicleConfiguration) {
            measuringPointUpdater.vehicleConfiguration = {} as IVehicleAnalysisTypeConfiguration;
        }

        Object.assign(measuringPointUpdater.vehicleConfiguration, this.vehicleConfigurationForm.value);

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }
}