import { IVmsImage, VmsImageCreator, VmsImageFromEditorCreator, VmsImageFromEditorUpdater, VmsImageUpdater } from "src/app/models/vms-image";
import { ExistsValue, ExistsResult } from "src/app/models/search";
import { DownloadedFile } from "src/app/services/download-file.service";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class VmsImageApi extends ApiService<IVmsImage, VmsImageCreator, VmsImageUpdater> {
    getRoute(): string {
        return "VmsImages";
    }
    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        if (!existsValue.ownerId) return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });

        return this.http.get<ExistsResult>(url, { params: { code: existsValue.value, ownerId: existsValue.ownerId.toString() } });
    }

    createFromEditor$(creator: VmsImageFromEditorCreator): Observable<IVmsImage> {
        return this.http.post<IVmsImage>(`${super.getUrl()}/editor`, creator);
    }
    updateFromEditor$(id: number, updater: VmsImageFromEditorUpdater): Observable<IVmsImage> {
        return this.http.put<IVmsImage>(`${super.getUrl()}/editor/${id}`, updater);
    }
}