<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingSignScan ? "manageSignScan.edit" : "manageSignScan.create") | translate}}
    </p-header>

    <form *ngIf="manageSignScanForm"
          class="p-16"
          [formGroup]="manageSignScanForm"
          novalidate>
        <app-location-form [parentFormGroup]="locationForm"
                           [coordinate]="existingSignScan?.location?.coordinate"
                           [location]="existingSignScan?.location"></app-location-form>
        <!--name-->
        <div class="m-form-group row mt-8"
             [ngClass]="{ 'has-error': manageSignScanForm.get('name').showErrors }">
            <label class="col-2"
                   for="name">
                {{"general.name" | translate}}
            </label>
            <div class="col-10">
                <input type="text"
                       placeholder="{{'general.name' | translate}}"
                       id="name"
                       class="p-inputtext"
                       formControlName="name"
                       appAutofocus
                       #nameInput />

                <app-form-validation [control]="manageSignScanForm.get('name')"
                                     [input]="nameInput">
                </app-form-validation>
            </div>
        </div>
        <!--signCode-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageSignScanForm.get('signCode').showErrors }">
            <label class="col-2"
                   for="signCode">
                {{"signScan.signCode" | translate}}
            </label>
            <div class="col-10">
                <input type="text"
                       placeholder="{{'signScan.signCode' | translate}}"
                       id="signCode"
                       class="p-inputtext"
                       formControlName="signCode"
                       appAutofocus
                       #signCodeInput />

                <app-form-validation [control]="manageSignScanForm.get('signCode')"
                                     [input]="signCodeInput">
                </app-form-validation>
            </div>
        </div>
        <!--checkIn-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageSignScanForm.get('checkIn').showErrors }">
            <label class="col-2"
                   for="checkIn">
                {{"signScan.checkIn" | translate}}
            </label>
            <div class="col-10">
                <p-calendar formControlName="checkIn"
                            id="checkIn"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [showTime]="false"
                            [maxDate]="manageSignScanForm.get('checkOut')?.value"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            appendTo="body"
                            #checkInInput></p-calendar>

                <app-form-validation [control]="manageSignScanForm.get('checkIn')"
                                     [input]="checkInInput">
                </app-form-validation>
            </div>
        </div>
        <!--end-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageSignScanForm.get('checkOut').showErrors }">
            <label class="col-2"
                   for="checkOut">
                {{"signScan.checkOut" | translate}}
            </label>
            <div class="col-10">
                <p-calendar formControlName="checkOut"
                            id="checkOut"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [minDate]="manageSignScanForm.get('checkIn')?.value"
                            [showIcon]="true"
                            [showTime]="false"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            appendTo="body"
                            #checkOutInput></p-calendar>
                <app-form-validation [control]="manageSignScanForm.get('checkOut')"
                                     [input]="checkOutInput">
                </app-form-validation>
            </div>
        </div>
        <!--lost-->
        <div class="m-form-group row mt-8"
             [ngClass]="{ 'has-error': manageSignScanForm.get('lost').showErrors }">
            <label class="col-2"
                   for="lost">
                {{"signScan.isLost" | translate}}
            </label>
            <div class="col-10">
                <p-calendar formControlName="lost"
                            id="lost"
                            [style]="{'width':'100%'}"
                            autoWidth="false"
                            [minDate]="manageSignScanForm.get('checkIn')?.value"
                            [showIcon]="true"
                            [showTime]="false"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            appendTo="body"
                            #lostInput></p-calendar>

                <app-form-validation [control]="manageSignScanForm.get('lost')"
                                     [input]="lostInput">
                </app-form-validation>
            </div>
        </div>
        <!-- photo -->
        <div class="m-form-group">
            <app-photo-input [attachment]="photo"
                             [submitted]="manageSignScanForm.submitted"></app-photo-input>
        </div>
    </form>
    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>
        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>