import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AdminGuard } from "src/app/guards/admin.guard";

import { ManagementComponent } from "./components/management/management.component";

import { AdminAttachmentTypesComponent } from "./components/admin-attachment-types/admin-attachment-types.component";
import { AdminBatteryDischargeTablesComponent } from "./components/admin-battery-discharge-tables/admin-battery-discharge-tables.component";
import { AdminBikeParameterSetsComponent } from "./components/admin-bike-parameter-sets/admin-bike-parameter-sets.component";
import { AdminDatePeriodSetsComponent } from "./components/admin-date-period-sets/admin-date-period-sets.component";
import { AdminDrivingLanesComponent } from "./components/admin-driving-lanes/admin-driving-lanes.component";
import { AdminLoginNotificationsComponent } from "./components/admin-login-notifications/admin-login-notifications.component";
import { AdminReleasesComponent } from "./components/admin-releases/admin-releases.component";
import { AdminRulesComponent } from "./components/admin-rules/admin-rules.component";
import { PlaygroundComponent } from "./components/playground/playground.component";
import { VmsImagesComponent } from "./components/vms-images/vms-images.component";
import { UserManagementComponent } from "../shared/components/user-management/user-management.component";
import { ApplicationsComponent } from "../shared/components/applications/applications.component";
import { AdminDeviceDepotsComponent } from "./components/admin-device-depots/admin-device-depots.component";
import { AdminJournalCategoriesComponent } from "./components/admin-journal-categories/admin-journal-categories.component";
import { WorkerManagementComponent } from "./components/worker-management/worker-management.component";
import { SafetyQuestionManagementComponent } from "./components/safety-question-management/safety-question-management.component";
import { VmsImageEditorComponent } from "../vms-image-editor/components/vms-image-editor/vms-image-editor.component";
import { AdminMapIconsComponent } from "./components/admin-map-icons/admin-map-icons.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    { path: "vms-images/:vmsImageId", component: VmsImageEditorComponent, canActivate: [AdminGuard], data: { name: "vmsImages.title" } }
    ,
    {
        path: "", component: ManagementComponent, data: { name: "management.title" }, children: [
            { path: "", redirectTo: "users", pathMatch: "full" },

            { path: "users", component: UserManagementComponent, data: { name: "users.title" }, canActivate: [AuthGuard] },
            { path: "workers", component: WorkerManagementComponent, data: { name: "worker.title" }, canActivate: [AuthGuard] },
            { path: "applications", component: ApplicationsComponent, data: { name: "applications.title" }, canActivate: [AdminGuard] },
            {
                path: "vms-images", component: VmsImagesComponent, data: { name: "vmsImages.title", canActivate: [AdminGuard] },
            },

            { path: "icons", component: AdminMapIconsComponent, data: { name: "mapIcons.title" }, canActivate: [AdminGuard] },
            { path: "rules", component: AdminRulesComponent, canActivate: [AdminGuard], data: { name: "rules.title" } },
            { path: "safetyQuestions", component: SafetyQuestionManagementComponent, canActivate: [AuthGuard], data: { name: "safetyQuestion.title" } },
            { path: "drivinglanes", component: AdminDrivingLanesComponent, canActivate: [AdminGuard], data: { name: "drivingLanes.title" } },
            { path: "attachmenttypes", component: AdminAttachmentTypesComponent, canActivate: [AdminGuard], data: { name: "attachmentTypes.title" } },
            { path: "batterydischargetables", component: AdminBatteryDischargeTablesComponent, canActivate: [AdminGuard], data: { name: "batteryDischargeTables.title" } },
            { path: "bikeparametersets", component: AdminBikeParameterSetsComponent, canActivate: [AdminGuard], data: { name: "bikeParameterSets.title" } },
            { path: "periodsets", component: AdminDatePeriodSetsComponent, canActivate: [AdminGuard], data: { name: "datePeriodSet.title" } },
            { path: "categories", component: AdminJournalCategoriesComponent, canActivate: [AdminGuard], data: { name: "categories.title" } },
            { path: "releases", component: AdminReleasesComponent, canActivate: [AdminGuard], data: { name: "releases.title" } },
            { path: "depots", component: AdminDeviceDepotsComponent, canActivate: [AdminGuard], data: { name: "deviceDepots.title" } },
            { path: "loginnotifications", component: AdminLoginNotificationsComponent, canActivate: [AdminGuard], data: { name: "loginNotifications.title" } },

            { path: "playground", component: PlaygroundComponent, canActivate: [AdminGuard], data: { name: "playground.title" } }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManagementRoutingModule { }
