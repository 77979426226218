/**
 * A marker that can be shown in Google Maps that consists of:
 * * A icon that is shown in the correct location
 * * A label that is shown on top of the icon
 *
 * This file encapsulates all implementation logic. Use `createBasicMarker` to retrieve an interface that represents the marker.
 * https://www.npmjs.com/package/markerwithlabel
 */

import { Constants } from "src/app/constants/constants";
import { BasicMarkerOptions } from "./basic-marker-options";
import { ILocationSummary } from "src/app/models/web";

export function createBasicMarker(options?: BasicMarkerOptions): IBasicMarker {
    if (!loaded) loadTypes();

    return new BasicMarker(options);
}

// Be really careful when naming methods
// For instance, setMap() exists, dispose() exists
// And if you define these, you'll overwrite Google behavior
// And their dispose() gets called everytime map is set to null
// Whereas we set map to null all the time to save resources
// ... to then re-add to the map.
// Google's Dispose is not what we consider dispose, clearly
export interface IBasicMarker extends google.maps.marker.AdvancedMarkerElement {
    setOptions(options: BasicMarkerOptions): void;
    isActive(): boolean;
    getIsVisible(): boolean;
    setIsVisible(visible: boolean);
    getIcon(): google.maps.Icon;
    setIcon(defaultIcon: google.maps.Icon | google.maps.Symbol): void;
    setDisableUnload(disableUnload: boolean): void;
    getDisableUnload(): boolean;
    setDisableCluster(disableCluster: boolean): void;
    getDisableCluster(): boolean;
    setIgnoreCluster(ignoreCluster: boolean): void;
    getIgnoreCluster(): boolean;
    getMarkerContent(): IMarkerContent;
    getMarkerLabel(): IMarkerLabel;
    getLabelVisible(): boolean;
    setLabelVisible(visible: boolean): void;
    getLabelContent(): string;
    setLabelContent(content: string): void;
    getFocusState(): boolean;
    setFocusState(active: boolean): void;
    getForceShowLabel(): boolean;
    setForceShowLabel(forceShowLabel: boolean): void;
    setBackgroundColor(color: string): void;
    setTextColor(color: string): void;
    setColors(color: string, textColor: string): void;
    getLabelColor(): string;
    getTextColor(): string;
    updateLabelVisibility(): void;
    getOpacity(): number;
    setOpacity(opacity: number): void;
    getPosition(): google.maps.LatLng;
    setPosition(position: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngAltitudeLiteral);
    setZIndex(zIndex: number): void;
    getZIndex(): number;
    getDefaultZIndex(): number;
    getHovered(): boolean;
    setHovered(hovered: boolean): void;
    setLocation(location: ILocationSummary): void;
    getLocation(): ILocationSummary;
    setMapInternal(map: google.maps.Map): void;
    getMap(): google.maps.Map;
    disposeSignco(): void;
}

let loaded = false;

// we disable linting for these constructor functions, the convention is PascalCase.
/* eslint-disable @typescript-eslint/naming-convention */
let BasicMarker: new (options?: BasicMarkerOptions) => IBasicMarker;
let MarkerContent: new (marker: google.maps.marker.AdvancedMarkerElement, options: BasicMarkerOptions) => IMarkerContent;
let MarkerLabel: new (marker: google.maps.marker.AdvancedMarkerElement, content: IMarkerContent, options: BasicMarkerOptions) => IMarkerLabel;
/* eslint-enable @typescript-eslint/naming-convention */

const maxZIndex = 2147483647 - 2;

// The HTML content representing our marker
interface IMarkerContent {
    setOptions(options: BasicMarkerOptions): void;
    getHTMLContent(): HTMLDivElement;
    getLabel(): IMarkerLabel;
    setIcon(icon: google.maps.Icon | google.maps.Symbol): void;
    disposeSignco(): void;
}

// The HTML content representing our label, part of our marker
interface IMarkerLabel {
    setOptions(options: BasicMarkerOptions): void;
    getVisible(): boolean;
    setVisible(visible: boolean): void;
    getContent(): string;
    setContent(content: string);
    getBackgroundColor(): string;
    setBackgroundColor(color: string): void;
    getTextColor(): string;
    setTextColor(textColor: string): void;
    getForceShow(): boolean;
    setForceShow(forceShow: boolean): void;
    getFocusState(): boolean;
    setFocusState(focusState: boolean): void;
    setClass(cssClass: string): void;
    getClass(): string;
    updateVisibility(): void;
    disposeSignco(): void;
}

function loadTypes() {
    loaded = true;

    class CustomPopup extends google.maps.OverlayView {
        position: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngAltitudeLiteral;
        containerDiv: HTMLDivElement;

        constructor(position: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngAltitudeLiteral, content: HTMLElement, icon: google.maps.Icon) {
            super();
            this.position = position;

            content.classList.add("popup-bubble");

            // This zero-height div is positioned at the bottom of the bubble.
            const bubbleAnchor = document.createElement("div");

            bubbleAnchor.classList.add("popup-bubble-anchor");
            bubbleAnchor.style.bottom = icon.size.height - (icon.anchor?.y) + "px";
            bubbleAnchor.style.left = (icon.anchor?.x ?? 0) * -1 + "px";
            bubbleAnchor.appendChild(content);

            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement("div");
            this.containerDiv.classList.add("popup-container");
            this.containerDiv.appendChild(bubbleAnchor);

            // Optionally stop clicks, etc., from bubbling up to the map.
            // Originally we used "preventMapHitsAndGesturesFrom" but that caused this violation to be shown in the console:
            // [Violation] Added non-passive event listener to a scroll-blocking <some> event. Consider marking event handler as 'passive' to make the page more responsive. See <URL>
            CustomPopup.preventMapHitsFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }

        /** Called each frame when the popup needs to draw itself. */
        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.position
            );

            // Hide the popup when it is far out of view.
            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                    ? "block"
                    : "none";

            if (display === "block") {
                this.containerDiv.style.left = divPosition.x + "px";
                this.containerDiv.style.top = divPosition.y + "px";
            }

            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
        }
    }

    MarkerContent = class MarkerContent implements IMarkerContent {
        marker: IBasicMarker;
        label: IMarkerLabel;

        private markerDiv: HTMLDivElement;
        private imgElement: HTMLImageElement;
        private svgElement: SVGElement;
        private pinElement: HTMLElement;

        constructor(marker: IBasicMarker, options: BasicMarkerOptions) {
            this.marker = marker;

            this.markerDiv = document.createElement("div");

            this.setOptions(options);

            this.marker.content = this.markerDiv;
        }

        setOptions(options: BasicMarkerOptions) {
            this.setIcon(options.icon);

            if (options.enableLabel) {
                if (!this.label) {
                    this.label = new MarkerLabel(this.marker, this, options);
                } else {
                    this.label.setOptions(options);
                }
            } else {
                if (this.label) {
                    this.label = null;
                }
            }
        }

        setIcon(icon: google.maps.Icon | google.maps.Symbol): void {
            const clearImg = () => {
                if (this.imgElement) {
                    this.imgElement.remove();
                    this.imgElement = null;
                }
            };

            const clearSvg = () => {
                if (this.svgElement) {
                    this.svgElement.remove();
                    this.svgElement = null;
                }
            };

            const clearPin = () => {
                if (this.pinElement) {
                    this.pinElement.remove();
                    this.pinElement = null;
                }
            };

            if (!icon) {
                clearImg();
                clearSvg();
                clearPin();

                // Set up default Google Maps Icon
                const pin = new google.maps.marker.PinElement();
                this.pinElement = pin.element;
                this.markerDiv.appendChild(this.pinElement);
                return;
            }

            const googleMapsIcon = icon as google.maps.Icon;
            if (googleMapsIcon?.url) {
                clearSvg();
                clearPin();

                if (!this.imgElement) {
                    this.imgElement = document.createElement("img");
                }

                this.imgElement.src = googleMapsIcon.url;
                this.imgElement.width = googleMapsIcon.size.width;
                this.imgElement.height = googleMapsIcon.size.height;
                this.imgElement.style.marginBottom = ((googleMapsIcon.anchor?.y ?? 0) * -1) + "px";
                this.imgElement.style.marginRight = ((googleMapsIcon.anchor?.y ?? 0) * 1) + "px";

                if (!this.imgElement.parentNode) {
                    this.markerDiv.appendChild(this.imgElement);
                }

                return;
            }

            const googleMapsSymbol = icon as google.maps.Symbol;
            if (googleMapsSymbol?.path) {
                clearImg();
                clearPin();

                if (this.svgElement) {
                    this.svgElement.children[0].remove();
                } else {
                    this.svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                }

                const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");

                const baseWidth = 30;
                const baseHeight = 30;
                this.svgElement.setAttribute("viewbox", `0 0 ${baseWidth} ${baseHeight}`);
                // this.svgElement.setAttribute("transform", `rotate(${googleMapsSymbol.rotation ?? 0})`);
                this.svgElement.setAttribute("width", `${baseWidth}`);
                this.svgElement.setAttribute("height", `${baseHeight}`);
                this.svgElement.style.scale = (googleMapsSymbol.scale ?? 1) + "";
                this.svgElement.style.transform = `rotate(${googleMapsSymbol.rotation ?? 0}deg)`;

                pathElement.setAttribute("d", googleMapsSymbol.path.toString());
                pathElement.setAttribute("fill", googleMapsSymbol.fillColor);

                this.svgElement.appendChild(pathElement);

                if (!this.svgElement.parentNode) {
                    this.markerDiv.appendChild(this.svgElement);
                }

                return;
            }
        }

        getLabel(): IMarkerLabel {
            return this.label;
        }

        getHTMLContent(): HTMLDivElement {
            return this.markerDiv;
        }

        disposeSignco(): void {
            this.marker.content = null;
            this.markerDiv = null;
        }
    };

    // tslint:disable-next-line:no-shadowed-variable
    MarkerLabel = class MarkerLabel implements IMarkerLabel {
        marker: IBasicMarker;
        markerContent: IMarkerContent;
        handCursorUrl: string;
        labelDiv: HTMLDivElement;
        // crossDiv: HTMLImageElement;
        visible: boolean; // Is it visible under normal circumstances
        forceShow: boolean; // Forces showing of label under any circumstances
        focusState: boolean; // Also forces showing of label under any circumstances :^) (when filtering)

        constructor(marker: IBasicMarker, content: IMarkerContent, options: BasicMarkerOptions) {
            this.marker = marker;
            this.markerContent = content;

            this.handCursorUrl = "http" + (document.location.protocol === "https:" ? "s" : "") + "://maps.gstatic.com/intl/en_us/mapfiles/closedhand_8_8.cur";

            this.labelDiv = document.createElement("div");
            this.labelDiv.style.position = "absolute";
            this.labelDiv.style.overflow = "hidden";

            // Truly quick & dirty, but for now plenty
            // Our location markers are just huge compared to everything else

            if (options?.icon) {
                const googleMapsIcon = options.icon as google.maps.Icon;
                if (googleMapsIcon?.url) {
                    let offsetX = googleMapsIcon.size.width > 40 ? -4 : 0;
                    let offsetY = googleMapsIcon.size.height > 49 ? +4 : 0;

                    if (googleMapsIcon.anchor) {
                        offsetX -= googleMapsIcon.anchor.x;
                        offsetY += googleMapsIcon.anchor.y - 3;
                    }

                    this.labelDiv.style.marginTop = (googleMapsIcon.size.height * -1) - 20 + offsetY + "px"; // 20 is height of the label
                    this.labelDiv.style.marginLeft = (googleMapsIcon.size.width - 2) + offsetX + "px"; // -2 to make it snug
                }
            }

            this.setOptions(options);

            content.getHTMLContent().appendChild(this.labelDiv);
        }

        setOptions(options: BasicMarkerOptions) {
            this.labelDiv.className = options.labelClass;

            this.setContent(options.labelContent);
            this.setBackgroundColor(options.labelColor);
            this.setTextColor(options.textColor);
            this.setVisible(options.labelVisible);
        }

        setClass(cssClass: string): void {
            this.labelDiv.className = cssClass;
        }

        getClass(): string {
            return this.labelDiv.className;
        }

        getForceShow(): boolean {
            return this.forceShow;
        }

        setForceShow(forceShow: boolean): void {
            this.forceShow = forceShow;
            this.updateVisibility();
        }

        getFocusState(): boolean {
            return this.focusState;
        }

        setFocusState(focusState: boolean): void {
            this.focusState = focusState;
            this.updateVisibility();
        }

        getVisible(): boolean {
            return this.visible;
        }

        setVisible(visible: boolean): void {
            this.visible = visible;
            this.updateVisibility();
        }

        getBackgroundColor(): string {
            return this.labelDiv.style.backgroundColor;
        }

        setBackgroundColor(color: string): void {
            this.labelDiv.style.backgroundColor = color;
        }

        getTextColor(): string {
            return this.labelDiv.style.color;
        }

        setTextColor(textColor: string): void {
            this.labelDiv.style.color = textColor;
            this.labelDiv.style.borderColor = textColor;
        }

        setContent(content: string) {
            this.labelDiv.innerHTML = content;
        }

        getContent(): string {
            return this.labelDiv.innerHTML;
        }

        private shouldShowLabel(): boolean {
            if (!this.marker.map) return false;

            if (this.forceShow || this.focusState) return true;

            if (this.marker.getHovered()) return true;
            if (this.marker.map.getZoom() < Constants.markerLabelZoom) return false;
            if (!this.visible) return false;

            return true;
        }

        updateVisibility() {
            const shouldShow = this.shouldShowLabel();

            if (shouldShow) {
                this.labelDiv.style.display = "block";
            } else {
                this.labelDiv.style.display = "none";
            }
        }

        disposeSignco(): void {
            this.labelDiv = null;
        }
    };

    // tslint:disable-next-line:no-shadowed-variable
    BasicMarker = class BasicMarker extends google.maps.marker.AdvancedMarkerElement implements IBasicMarker {
        disableCluster: boolean;
        ignoreCluster: boolean; // Don't count as +1 for cluster
        disableUnload: boolean;
        visible: boolean;

        infoPopupVisible: boolean;
        infoPopup: CustomPopup;

        markerContent: IMarkerContent; // typed version of "content"
        label: IMarkerLabel;

        hovered: boolean;
        oldOpacity: number;
        oldZIndex: number;
        curOpacity: number;
        location: ILocationSummary;
        baseIcon: google.maps.Icon;

        listeners: Array<google.maps.MapsEventListener>;

        constructor(options?: BasicMarkerOptions) {
            super(options);

            this.visible = true;

            // const crossImage = "http" + (document.location.protocol === "https:" ? "s" : "") + "://maps.gstatic.com/intl/en_us/mapfiles/drag_cross_67_16.png";

            Object.assign(this, options);
            if (!loaded) loadTypes();

            this.gmpDraggable = options.draggable;

            // https://developers.google.com/maps/documentation/javascript/advanced-markers/accessible-markers
            this.gmpClickable = true;

            this.setZIndex(this.getDefaultZIndex());

            this.markerContent = new MarkerContent(this, options);
            this.label = this.markerContent.getLabel(); // shorthand

            this.setOpacity(1);

            this.handleListeners();
        }

        setOptions(options: BasicMarkerOptions): void {
            this.markerContent.setOptions(options);
            this.baseIcon = options.icon;
        }

        isActive(): boolean {
            return this.curOpacity === 1 && this.visible;
        }

        getIsVisible(): boolean {
            return this.visible;
        }

        setIsVisible(visible: boolean) {
            this.visible = visible;
        }

        getIcon(): google.maps.Icon {
            return this.baseIcon;
        }

        setIcon(icon: google.maps.Icon | google.maps.Symbol): void {
            if (this.markerContent) {
                this.markerContent.setIcon(icon);
            }

            const googleMapsIcon = icon as google.maps.Icon;
            if (googleMapsIcon?.url) {
                this.baseIcon = googleMapsIcon;
            }
        }

        setLocation(location: ILocationSummary): void {
            this.location = location;
        }

        getLocation(): ILocationSummary {
            return this.location;
        }

        private handleListeners() {
            if (!this.markerContent) return;

            if (!this.listeners) {
                this.listeners = [];
            }

            if (this.listeners.length) return;

            this.listeners.push(this.addListener("click", () => { })); // Enables click events

            this.markerContent.getHTMLContent().addEventListener("mouseenter", () => {
                this.handleHover(true);
            });

            this.markerContent.getHTMLContent().addEventListener("mouseleave", () => {
                this.handleHover(false);
            });
        }

        private unregisterListeners() {
            for (const listener of this.listeners) {
                google.maps.event.removeListener(listener);
            }

            this.listeners = [];

            if (this.markerContent) {
                this.markerContent.getHTMLContent().removeAllListeners();
            }
        }

        handleHover(hover = false) {
            if (this.getHovered() === hover) return;

            this.setHovered(hover);

            // calling marker setZIndex will in turn trigger label's setZIndex
            if (hover) {
                this.oldOpacity = this.curOpacity;
                this.oldZIndex = this.zIndex || this.getDefaultZIndex();
                this.setOpacity(1);
                this.setZIndex(maxZIndex);
            } else {
                if (this.oldOpacity) {
                    this.setOpacity(this.oldOpacity);
                    this.oldOpacity = null;
                }

                if (this.oldZIndex && !this.infoPopupVisible) {
                    this.setZIndex(this.oldZIndex);
                    this.oldZIndex = null;
                }
            }

            if (this.label) {
                this.label.updateVisibility();
            }
        }

        getHovered(): boolean {
            return this.hovered;
        }

        setHovered(hovered: boolean): void {
            this.hovered = hovered;
        }

        setBackgroundColor(color: string) {
            if (!this.label) return null;
            this.label.setBackgroundColor(color);
        }

        setTextColor(color: string) {
            if (!this.label) return null;
            this.label.setTextColor(color);
        }

        setColors(backgroundColor: string, textColor: string) {
            if (!this.label) return null;
            this.label.setBackgroundColor(backgroundColor);
            this.label.setTextColor(textColor);
        }

        getLabelColor(): string {
            if (!this.label) return null;
            return this.label.getBackgroundColor();
        }

        getTextColor(): string {
            if (!this.label) return null;
            return this.label.getTextColor();
        }

        updateLabelVisibility() {
            if (!this.label) return null;
            this.label.updateVisibility();
        }

        setDisableCluster(disableCluster: boolean) {
            this.disableCluster = disableCluster;
        }

        getDisableCluster(): boolean {
            return this.disableCluster;
        }

        setIgnoreCluster(ignoreCluster: boolean) {
            this.ignoreCluster = ignoreCluster;
        }

        getIgnoreCluster(): boolean {
            return this.ignoreCluster;
        }

        setDisableUnload(disableUnload: boolean) {
            this.disableUnload = disableUnload;
        }

        getDisableUnload(): boolean {
            return this.disableUnload;
        }

        getMarkerContent(): IMarkerContent {
            return this.markerContent;
        }

        getMarkerLabel(): IMarkerLabel {
            return this.label;
        }

        getFocusState(): boolean {
            if (!this.label) return null;
            return this.label.getFocusState();
        }

        private prevBackgroundColor: string;
        setFocusState(focusState: boolean) {
            if (!this.label) return null;

            this.label.setFocusState(focusState);

            if (focusState) {
                this.prevBackgroundColor = this.label.getBackgroundColor();
            }

            this.setBackgroundColor(focusState ? window.getComputedStyle(document.documentElement).getPropertyValue('--ramuddenYellow') : this.prevBackgroundColor);
            this.setZIndex(focusState ? 5000 : this.getDefaultZIndex());
            this.setIgnoreCluster(focusState);
        }

        getForceShowLabel(): boolean {
            if (!this.label) return null;
            return this.label.getForceShow();
        }

        setForceShowLabel(forceShowLabel: boolean) {
            if (!this.label) return null;
            this.label.setForceShow(forceShowLabel);
        }

        getLabelContent(): string {
            if (!this.label) return null;
            return this.label.getContent();
        }

        setLabelContent(content: string) {
            if (!this.label) return null;
            this.label.setContent(content);
        }

        getLabelVisible(): boolean {
            if (!this.label) return null;
            return this.label.getVisible();
        }

        setLabelVisible(visible: boolean): void {
            if (!this.label) return null;
            this.label.setVisible(visible);
        }

        getOpacity(): number {
            return this.curOpacity;
        }

        setOpacity(opacity: number) {
            const htmlContent = this.markerContent.getHTMLContent();
            if (!htmlContent) return null;
            htmlContent.style.opacity = opacity + "";
            this.curOpacity = opacity;
        }

        getPosition(): google.maps.LatLng {
            return new google.maps.LatLng(this.position);
        }

        setPosition(position: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngAltitudeLiteral) {
            this.position = position;

            if (this.location) {
                this.location.lat = (position.lat instanceof Function) ? position.lat() : position.lat;
                this.location.lng = (position.lng instanceof Function) ? position.lng() : position.lng;
            }

            if (this.infoPopup) {
                this.infoPopup.position = position;
            }
        }

        setZIndex(zIndex: number): void {
            if (this.getFocusState() && zIndex === this.getDefaultZIndex()) return;
            this.zIndex = zIndex;
        }

        getZIndex(): number {
            return this.zIndex;
        }

        getMap(): google.maps.Map {
            return this.map;
        }

        // If we rename this to simply "GetMap" google will hijack our code into an infinite loop
        setMapInternal(map: google.maps.Map) {
            if (this.map == map) return; // double ==, can be null or undefined

            this.map = map;
            this.handleListeners();

            if (this.label) {
                this.label.updateVisibility();
            }

            if (!this.map && this.hovered) {
                this.handleHover(false);
            }
        }

        getDefaultZIndex(): number {
            // When markers overlap, the marker that is closer South is shown on top. We use the latitude for that
            const positiveLat = this.getPosition().lat() + 100; // zIndex does not work with negative numbers
            return Math.round(positiveLat * -1000000); // We want to be able to cut off the comma's and still have wildly different values for every marker
        }

        disposeSignco(): void {
            this.map = null;
            this.unregisterListeners();
            google.maps.event.clearInstanceListeners(this);

            if (this.label) {
                this.label.disposeSignco();
                delete this.label;
            }
        }
    };

    // Required or you'll get a vague error
    // New HTML elements need to be defined
    // AdvancedMarker extends HTMLElement
    // and we extend AdvancedMarker
    customElements.define("metanous-basic-marker", BasicMarker);
}
