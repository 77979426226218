import { Injectable } from "@angular/core";
import { ITaskStatusComparison, TaskStatusComparisonCreator } from "../models/task-status-comparison";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class TasksStatusesComparisonApi extends ApiService<ITaskStatusComparison, TaskStatusComparisonCreator, TaskStatusComparisonCreator> {
    getRoute(): string {
        return "TasksStatusesComparison";
    }
}
