import { Component, HostListener, Input, ViewChild } from "@angular/core";
import { ICountingZoneStatus } from "src/app/models/pinned-data";
import { CountingZoneManageDialogComponent } from "../counting-zone-manage-dialog/counting-zone-manage-dialog.component";

@Component({
    selector: "app-counting-zone-pinned-data",
    templateUrl: "./counting-zone-pinned-data.component.html",
})
export class CountingZonePinnedDataComponent {
    @Input() countingZone: ICountingZoneStatus;
    @Input() measuringPointId: number;

    @ViewChild(CountingZoneManageDialogComponent, { static: true }) manageVehiclesDialog: CountingZoneManageDialogComponent;

    @HostListener("wheel", ["$event"])
    onMouseWheel(event: WheelEvent) {
        // The mousewheel should scroll in the popup, not in the Google Maps that is beneath it
        event.stopPropagation();
    }

    manageVehicles() {
        this.manageVehiclesDialog.open(this.measuringPointId, this.countingZone);
    }

}