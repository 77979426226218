<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingWorker ? "worker.edit" : "worker.create") | translate}}
    </p-header>

    <form [formGroup]="workerForm"
          class="p-16 m-layout-area-body m-border-top"
          *ngIf="workerForm"
          autocomplete="off">
        <!--organization-->
        <div class="m-form-group row"
             *ngIf="displayOrganizations"
             [ngClass]="{ 'has-error': workerForm.get('organizationId').showErrors }">
            <label class="col-2"
                   for="organizationId">
                {{"general.organization" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown id="organizationId"
                            [options]="organizations"
                            [panelStyle]="{'width':'100%'}"
                            formControlName="organizationId"
                            [autoDisplayFirst]="false"
                            [filter]="true"
                            appendTo="body"
                            placeholder="{{'assignment.selectOrganization' | translate }}"
                            #organizationIdInput>
                    <ng-template let-organization
                                 pTemplate="item">
                        <span [ngClass]="organization.styleClass">{{organization.label}}</span>
                    </ng-template>
                </p-dropdown>

                <app-form-validation [control]="workerForm.get('organizationId')"
                                     [input]="organizationIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row">
            <div class="col-6">
                <!--firstName-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('firstName').showErrors }">

                    <label class="col-4"
                           for="firstName">
                        {{"general.firstName" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="firstName"
                               formControlName="firstName"
                               #firstNameInput />

                        <app-form-validation [control]="workerForm.get('firstName')"
                                             [input]="firstNameInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--lastName-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('lastName').showErrors }">

                    <label class="col-4"
                           for="lastName">
                        {{"general.lastName" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               id="lastName"
                               class="p-inputtext"
                               formControlName="lastName"
                               #lastNameInput />

                        <app-form-validation [control]="workerForm.get('lastName')"
                                             [input]="lastNameInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--phoneNumberWork-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('phoneNumberWork').showErrors }">

                    <label class="col-4"
                           for="phoneNumberWork">
                        {{"worker.phoneNumberWork" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="phoneNumberWork"
                               formControlName="phoneNumberWork"
                               #phoneNumberWorkInput />

                        <app-form-validation [control]="workerForm.get('phoneNumberWork')"
                                             [input]="phoneNumberWorkInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--phoneNumberHome-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('phoneNumberHome').showErrors }">

                    <label class="col-4"
                           for="phoneNumberHome">
                        {{"worker.phoneNumberHome" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="phoneNumberHome"
                               formControlName="phoneNumberHome"
                               #phoneNumberHomeInput />

                        <app-form-validation [control]="workerForm.get('phoneNumberHome')"
                                             [input]="phoneNumberHomeInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--email-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('email').showErrors }">

                    <label class="col-4"
                           for="email">
                        {{"general.email" | translate}}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="email"
                               formControlName="email"
                               #emailInput />

                        <app-form-validation [control]="workerForm.get('email')"
                                             [input]="emailInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--nationalNumber-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('nationalNumber').showErrors }">

                    <label class="col-4"
                           for="nationalNumber">
                        {{"worker.nationalNumber" | translate}}
                    </label>
                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="nationalNumber"
                               formControlName="nationalNumber"
                               #nationalNumberInput />

                        <app-form-validation [control]="workerForm.get('nationalNumber')"
                                             [input]="nationalNumberInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--address-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('address').showErrors }">

                    <label class="col-4"
                           for="address">
                        {{"general.address" | translate}}
                    </label>
                    <div class="col-8">
                        <input type="text"
                               class="p-inputtext"
                               id="address"
                               formControlName="address"
                               #addressInput />

                        <app-form-validation [control]="workerForm.get('address')"
                                             [input]="addressInput">
                        </app-form-validation>
                    </div>
                </div>

                <!-- photo -->
                <div class="m-form-group">
                    <app-photo-input [labelColSize]="4"
                                     [colSize]="8"
                                     [photoColSize]="34"
                                     [attachment]="photo"
                                     [required]="false"
                                     [submitted]="workerForm.submitted"
                                     [deleteImageMessageToTranslate]="'worker.deleteImageConfirmation'"
                                     (photoDeleted)="reloadAfterPhotoDeleted()"></app-photo-input>
                </div>
            </div>
            <div class="col-6">
                <!--InServiceFrom-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('inServiceFrom').showErrors }">
                    <label class="col-4"
                           for="inServiceFrom">
                        {{"worker.inServiceFrom" | translate}}
                    </label>
                    <div class="col-8">
                        <p-calendar formControlName="inServiceFrom"
                                    id="inServiceFrom"
                                    [style]="{'width':'100%'}"
                                    appendTo="body"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [showTime]="false"
                                    [maxDate]="workerForm.get('outOfServiceFrom')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [monthNavigator]="true"
                                    [yearNavigator]="true"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #inServiceFromInput></p-calendar>

                        <app-form-validation [control]="workerForm.get('inServiceFrom')"
                                             [input]="inServiceFromInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--OutOfServiceFrom-->
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': workerForm.get('outOfServiceFrom').showErrors }">
                    <label class="col-4"
                           for="outOfServiceFrom">
                        {{"worker.outOfServiceFrom" | translate}}
                    </label>
                    <div class="col-8">
                        <p-calendar formControlName="outOfServiceFrom"
                                    id="outOfServiceFrom"
                                    [style]="{'width':'100%'}"
                                    appendTo="body"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [showTime]="false"
                                    [minDate]="workerForm.get('inServiceFrom')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [monthNavigator]="true"
                                    [yearNavigator]="true"
                                    [yearRange]="primeComponentService.calendarYearRangeFutureAmountOfYears(15)"
                                    #outOfServiceFromInput></p-calendar>

                        <app-form-validation [control]="workerForm.get('outOfServiceFrom')"
                                             [input]="outOfServiceFromInput">
                        </app-form-validation>
                    </div>
                </div>

                <!--is hasCode95-->
                <div class="m-form-group row">
                    <label class="col-4"
                           for="hasCode95">
                        {{"worker.hasCode95" | translate}}
                    </label>
                    <div class="col-8">
                        <input class="group-checkbox"
                               type="checkbox"
                               formControlName="hasCode95"
                               (change)="hasCode95Changed($event)" />
                    </div>
                </div>
                <ng-container *ngIf="workerForm.get('hasCode95').value === true">
                    <!--Code95StartDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('code95StartDate').showErrors }">
                        <label class="col-4"
                               for="code95StartDate">
                            {{"worker.code95StartDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="code95StartDate"
                                        id="code95StartDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [maxDate]="workerForm.get('code95EndDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        appendTo="body"
                                        #code95StartDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('code95StartDate')"
                                                 [input]="code95StartDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                    <!--Code95EndDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('code95EndDate').showErrors }">
                        <label class="col-4"
                               for="code95EndDate">
                            {{"worker.code95EndDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="code95EndDate"
                                        id="code95EndDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [minDate]="workerForm.get('code95StartDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRangeFutureAmountOfYears(15)"
                                        appendTo="body"
                                        #code95EndDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('code95EndDate')"
                                                 [input]="code95EndDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                </ng-container>

                <!--hasVCACertificate-->
                <div class="m-form-group row">
                    <label class="col-4"
                           for="hasVCACertificate">
                        {{"worker.hasVCACertificate" | translate}}
                    </label>
                    <div class="col-8">
                        <input class="group-checkbox"
                               type="checkbox"
                               formControlName="hasVCACertificate"
                               (change)="hasVCACertificateChanged($event)" />
                    </div>
                </div>
                <ng-container *ngIf="workerForm.get('hasVCACertificate').value === true">
                    <!--vcaStartDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('vcaStartDate').showErrors }">
                        <label class="col-4"
                               for="vcaStartDate">
                            {{"worker.vcaStartDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="vcaStartDate"
                                        id="vcaStartDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [maxDate]="workerForm.get('vcaEndDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        appendTo="body"
                                        #vcaStartDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('vcaStartDate')"
                                                 [input]="vcaStartDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                    <!--vcaEndDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('vcaEndDate').showErrors }">
                        <label class="col-4"
                               for="vcaEndDate">
                            {{"worker.vcaEndDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="vcaEndDate"
                                        id="vcaEndDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [minDate]="workerForm.get('vcaStartDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        appendTo="body"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRangeFutureAmountOfYears(15)"
                                        #vcaEndDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('vcaEndDate')"
                                                 [input]="vcaEndDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                </ng-container>

                <!--hasADRCertificate-->
                <div class="m-form-group row">
                    <label class="col-4"
                           for="hasADRCertificate">
                        {{"worker.hasADRCertificate" | translate}}
                    </label>
                    <div class="col-8">
                        <input class="group-checkbox"
                               type="checkbox"
                               formControlName="hasADRCertificate"
                               (change)="hasADRCertificateChanged($event)" />
                    </div>
                </div>
                <ng-container *ngIf="workerForm.get('hasADRCertificate').value === true">
                    <!--adrStartDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('adrStartDate').showErrors }">
                        <label class="col-4"
                               for="adrStartDate">
                            {{"worker.adrStartDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="adrStartDate"
                                        id="adrStartDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [maxDate]="workerForm.get('adrEndDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        #adrStartDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('adrStartDate')"
                                                 [input]="adrStartDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                    <!--adrEndDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('adrEndDate').showErrors }">
                        <label class="col-4"
                               for="adrEndDate">
                            {{"worker.adrEndDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="adrEndDate"
                                        id="adrEndDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [minDate]="workerForm.get('adrStartDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        appendTo="body"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRangeFutureAmountOfYears(15)"
                                        #adrEndDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('adrEndDate')"
                                                 [input]="adrEndDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                </ng-container>

                <!--medicalFitnessChecked-->
                <div class="m-form-group row">
                    <label class="col-4"
                           for="medicalFitnessChecked">
                        {{"worker.medicalFitnessChecked" | translate}}
                    </label>
                    <div class="col-8">
                        <input class="group-checkbox"
                               type="checkbox"
                               formControlName="medicalFitnessChecked"
                               (change)="hasMedicalFitnessChanged($event)" />
                    </div>
                </div>
                <ng-container *ngIf="workerForm.get('medicalFitnessChecked').value === true">
                    <!--MedicalFitnessStartDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('medicalFitnessStartDate').showErrors }">
                        <label class="col-4"
                               for="medicalFitnessStartDate">
                            {{"worker.medicalFitnessStartDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="medicalFitnessStartDate"
                                        id="medicalFitnessStartDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [maxDate]="workerForm.get('medicalFitnessEndDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        appendTo="body"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        #medicalFitnessStartDateInput></p-calendar>

                            <app-form-validation [control]="workerForm.get('medicalFitnessStartDate')"
                                                 [input]="medicalFitnessStartDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                    <!--MedicalFitnessEndDate-->
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': workerForm.get('medicalFitnessEndDate').showErrors }">
                        <label class="col-4"
                               for="medicalFitnessEndDate">
                            {{"worker.medicalFitnessEndDate" | translate}}
                        </label>
                        <div class="col-8">
                            <p-calendar formControlName="medicalFitnessEndDate"
                                        id="medicalFitnessEndDate"
                                        [style]="{'width':'100%'}"
                                        autoWidth="false"
                                        [showIcon]="true"
                                        [showTime]="false"
                                        [minDate]="workerForm.get('medicalFitnessStartDate')?.value"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        appendTo="body"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRangeFutureAmountOfYears(15)"
                                        #medicalFitnessEndDateInput></p-calendar>
                            <app-form-validation [control]="workerForm.get('medicalFitnessEndDate')"
                                                 [input]="medicalFitnessEndDateInput">
                            </app-form-validation>
                        </div>
                    </div>
                </ng-container>

                <!--can do control-->
                <div class="m-form-group row">
                    <label class="col-4"
                           for="hasCode95">
                        {{"worker.canDoControl" | translate}}
                    </label>
                    <div class="col-8">
                        <input class="group-checkbox"
                               type="checkbox"
                               formControlName="canDoControl" />
                    </div>
                </div>

            </div>

        </div>

    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancelButton">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="saveButton">
            <app-loading-spinner *ngIf="submitting"
                                 id="manage_worker_spinner">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>