import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SplitMapComponentBase } from "src/app/modules/shared/components/split-map/split-map.component";
import { Component, OnDestroy } from "@angular/core";
import { SigncoFormGroup } from "src/app/models/form";
import { ExistsValidator } from "src/app/validators/exists.validator";
import { MapDataService } from "src/app/services/map-data.service";
import { ToastService } from "src/app/services/toast.service";
import { ExistsValue } from "src/app/models/search";
import { SelectItem } from "primeng/api";
import { GroupApi } from "src/app/resource/group.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { OrganizationUtils, SubscriptionManager } from "src/app/utilities";
import { GroupCreator, GroupUpdater, IGroup } from "src/app/models/group";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-manage-group-component",
    templateUrl: "./manage-group.component.html"
})
export class ManageGroupComponent implements OnDestroy, IChangeGuard {
    submitting: boolean;
    expanded: boolean;
    creatingOrEditing: boolean;
    callback: (res: IGroup) => void;
    cancelCallback: () => void;
    groupForm: SigncoFormGroup;

    initialColor: string;
    splitMap: SplitMapComponentBase;

    organizations: SelectItem[];

    private cancelling = false;
    private readonly mapDataKey: string;
    rights: Rights;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        readonly mapDataService: MapDataService,
        private readonly toast: ToastService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly groupApi: GroupApi) {

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name, false, "", OrganizationUtils.getStyleClass);
        });
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }

    ngOnDestroy() {
        this.mapDataService.unsubscribe(this.mapDataKey);
        this.subscriptionManager.clear();
    }

    canDeactivateCheck(): boolean {
        if (!this.groupForm.pristine) return false;

        const group = this.mapDataService.editGroup;
        return !group;
    }

    onDeactivate() {
        this.cancel();
    }

    private initialize() {
        if (!this.canCreate()) {
            this.close();
            return;
        }

        this.changeGuardService.setComponent(this);

        this.initialColor = (this.mapDataService.editGroup ? this.mapDataService.editGroup.color : null) || "#001F3F";

        this.groupForm = this.formBuilder.group({
            code: ["", Validators.required],
            description: "",
            color: this.initialColor
        }) as SigncoFormGroup;

        // If user has the right to create measuringPoints for others
        // toss in the ownerId field
        // and add ExistsValidators for code that merges with selected organization
        // (no organization === logged in user's organization)
        if (this.globalEventsService.hasMultipleOrganizations()) {
            const defaultOrganization = this.globalEventsService.getDefaultOrganization();
            this.groupForm.addControl("ownerId", this.formBuilder.control(defaultOrganization ? defaultOrganization.id : null, Validators.required));
        }

        this.groupForm.patchValue({
            code: this.mapDataService.editGroup.code,
            description: this.mapDataService.editGroup.description,
            color: this.mapDataService.editGroup.color,
            ownerId: this.mapDataService.editGroup.ownerId
        });

        // Only apply group code logic / organization select logic
        // when there's no prior existing group
        // If group already exists, we can't input code / organization anyway
        if (this.isCreatingNew) {
            const ownerControl = this.groupForm.get("ownerId");

            const groupCodeExistsValidator = ExistsValidator.create(
                (value: ExistsValue) => this.groupApi.exists$(value),
                () => {
                    if (!ownerControl) return null;

                    return { ownerId: ownerControl.value };
                },
                () => {
                    // Only trigger code check if either the group is being newly created, or has been changed from the initial value
                    return !this.mapDataService.editGroup || this.groupForm.get("code").value !== this.mapDataService.editGroup.code;
                }
            );

            this.groupForm.get("code").setAsyncValidators(groupCodeExistsValidator);

            if (ownerControl) {
                ownerControl.valueChanges.subscribe(() => {
                    this.groupForm.get("code").updateValueAndValidity();
                });
            }
        }

        this.submitting = false;
        this.creatingOrEditing = true;
    }

    edit(group: IGroup, callback?: (res: IGroup) => void, cancelCallback?: () => void) {
        this.mapDataService.editGroup = group;
        this.callback = callback;
        this.cancelCallback = cancelCallback;
        this.expanded = false;
        this.initialize();
    }

    create(group: IGroup, callback?: (res: IGroup) => void, cancelCallback?: () => void) {
        this.mapDataService.editGroup = group;
        this.callback = callback;
        this.cancelCallback = cancelCallback;
        this.expanded = true;
        this.initialize();
    }

    toggleExpanded() {
        this.expanded = !this.expanded;
    }

    canCreate(): boolean {
        return this.rights?.hasBackendRight(BackendRights.EditGroup);
    }

    private get group(): IGroup {
        return this.mapDataService.editGroup;
    }

    setColor(color: string) {
        this.groupForm.get("color").patchValue(color);
        this.groupForm.markAsDirty();

        this.group.color = color;
        this.mapDataService.triggerEditGroupUpdate();
    }

    duplicate() {
        if (!this.splitMap) {
            console.error("Splitmap not set");
            return;
        }

        const groupToClone = this.group;
        this.cancel();
        this.splitMap.searchbutton.createGroup(groupToClone);
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.groupForm);
        if (!isValid) {
            this.expanded = true;
            return;
        }

        this.submitting = true;

        const onSuccess = async (updatedGroup: IGroup) => {
            this.toast.saveSuccess();
            this.initialColor = updatedGroup.color;
            this.submitting = false;
            this.groupForm.markAsPristine();
            Object.assign(this.mapDataService.editGroup, updatedGroup);
            this.mapDataService.loadGroupSummaries();

            if (this.callback) {
                this.callback(this.mapDataService.editGroup);
            }

            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (this.isCreatingNew) {
            const groupCreator = Object.assign(new GroupCreator(this.mapDataService.editGroup.measuringPoints), this.groupForm.value) as GroupCreator;
            groupCreator.measuringPoints.forEach((element, index) => {
                element.sortOrder = index + 1;
            });
            this.groupApi.create$(groupCreator).subscribe(onSuccess, onError);
        } else {
            const groupUpdater = Object.assign(new GroupUpdater(this.mapDataService.editGroup), this.groupForm.value) as GroupUpdater;
            this.groupApi.update$(groupUpdater).subscribe(onSuccess, onError);
        }
    }

    cancel() {
        if (this.cancelling || !this.creatingOrEditing) return;

        this.cancelling = true;

        if (this.cancelCallback) {
            this.cancelCallback();
        }

        this.close();
        this.cancelling = false;
    }

    close() {
        this.mapDataService.editGroup = null;
        this.changeGuardService.clearComponent();
        this.creatingOrEditing = false;
    }

    get isCreatingNew(): boolean {
        return this.mapDataService.editGroup && !this.mapDataService.editGroup.id;
    }
}
