<div class="m-layout-area-body m-layout-w-actions-bottom m-border-top"
     #column1>
    <div class="m-layout-area-body m-layout-default">
        <div class="m-layout-area-body"
             [hidden]="isMapOpen">
            <div class="m-half-height m-layout-w-actions-top">
                <div class="flex align-center px-16 py-8 m-layout-area-top m-border-bottom">
                    <div class="mr-16">
                        <h3>{{"measuringPoints.primaryDirection" | translate}}
                            {{measuringPoint?.from}}
                            <span class="m-arrow-right"
                                  *ngIf="measuringPoint?.from || measuringPoint?.to"></span>
                            {{measuringPoint?.to}}
                        </h3>
                    </div>

                    <div class="ml-auto"
                         #addMeasuringPointsIcon>
                        <app-icon-button *ngIf="!measuringPoint?.readonly"
                                         icon="add"
                                         text="sharedKeys.addMeasuringPoints"
                                         [styleClass]="(isMapOpen && editingDirection === 'forward') ? 'm-btn m-btn-square hover' : 'm-btn m-btn-square'"
                                         tooltipPosition="left"
                                         (click)="toggleAddMeasuringPoints('forward')">
                        </app-icon-button>
                    </div>
                </div>

                <app-measuring-point-aggregation-direction #mainDirectionAggregation
                                                           [measuringPoint]="measuringPoint"
                                                           [duplicateCommand]="duplicateCommand"
                                                           [deleteCommand]="deleteCommand"
                                                           [scrollHeight]="scrollHeight"
                                                           [max]="max"
                                                           class="m-layout-area-body m-layout-default">
                </app-measuring-point-aggregation-direction>
            </div>

            <div class="m-half-height m-layout-w-actions-top">
                <div class="flex align-center px-16 py-8 m-layout-area-top m-border-bottom m-border-top">
                    <div class="mr-16">
                        <h3>{{"measuringPoints.reverseDirection" | translate}}
                            {{measuringPoint?.to}}
                            <span class="m-arrow-right"
                                  *ngIf="measuringPoint?.from || measuringPoint?.to"></span>
                            {{measuringPoint?.from}}
                        </h3>
                    </div>

                    <div class="ml-auto"
                         #addMeasuringPointsIcon>
                        <app-icon-button *ngIf="!measuringPoint?.readonly"
                                         icon="add"
                                         text="sharedKeys.addMeasuringPoints"
                                         [styleClass]="(isMapOpen && editingDirection === 'reverse') ? 'm-btn m-btn-square hover' : 'm-btn m-btn-square'"
                                         (click)="toggleAddMeasuringPoints('reverse')">
                        </app-icon-button>
                    </div>
                </div>

                <app-measuring-point-aggregation-direction #reverseDirectionAggregation
                                                           [measuringPoint]="measuringPoint"
                                                           [duplicateCommand]="duplicateCommand"
                                                           [deleteCommand]="deleteCommand"
                                                           [scrollHeight]="scrollHeight"
                                                           [max]="max"
                                                           class="m-layout-area-body m-layout-default">
                </app-measuring-point-aggregation-direction>
            </div>
        </div>

        <div class="m-layout-area-body m-layout-default"
             *ngIf="isMapOpen">

            <app-map-selector [mapDetail]="mapDetail"
                              (detailComponentSet)="handleMapComponentLoad()"
                              (mapReady)="handleMapReady()"
                              class="m-layout-area-body m-layout-default">
            </app-map-selector>
        </div>
    </div>

    <div class="m-layout-area-bottom"
         *ngIf="!measuringPoint?.readonly">

        <div class="m-border-top p-16"
             *ngIf="showProgressBar">
            <div class="flex">
                <app-svg-icon icon="info"
                              class="m-btn-baseline mr-8"></app-svg-icon>

                <span>{{"measuringPointAggregation.pleaseWait" | translate}}</span>
            </div>

            <div class="mt-8">
                <p-progressBar [value]="progressCounter"></p-progressBar>
            </div>
        </div>

        <ng-template #mapOpenButtonsTemplate>
            <button class="m-btn m-btn-primary"
                    type="button"
                    (click)="closeMap()">

                {{"map.close" | translate}}
            </button>
        </ng-template>

        <ng-container *ngIf="!isMapOpen; else mapOpenButtonsTemplate">
            <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top">
                <button [disabled]="isSaving || !(mainDirectionAggregation.isDirty || reverseDirectionAggregation.isDirty)"
                        class="m-btn m-btn-primary"
                        type="button"
                        (click)="save()">
                    <app-loading-spinner *ngIf="isSaving">
                    </app-loading-spinner>
                    {{"form.save" | translate}}
                </button>
            </div>
        </ng-container>
    </div>
</div>