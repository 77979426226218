import { DatePeriodSetApi } from "src/app/resource/date-period-set.api";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { DatePeriodSetCreator, IDatePeriodSet } from "src/app/models/date-period-set";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-date-period-set-dialog",
    templateUrl: "./manage-date-period-set.dialog.html"
})
export class ManageDatePeriodSetDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IDatePeriodSet) => void;
    dialogForm: SigncoFormGroup;

    existingDatePeriodSet: IDatePeriodSet;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly datePeriodSetApi: DatePeriodSetApi,
        private readonly domainModelFilterService: DomainModelFilterService) {

        super();
    }

    create(callback?: (res: IDatePeriodSet) => void) {
        this.callback = callback;
        this.existingDatePeriodSet = null;

        this.openDialog();
    }

    edit(existingDatePeriodSet: IDatePeriodSet, callback?: (res: IDatePeriodSet) => void) {
        this.callback = callback;
        this.existingDatePeriodSet = existingDatePeriodSet;

        this.openDialog();
    }

    protected onOpen() {
        this.dialogForm = this.formBuilder.group({
            name: ["", Validators.required]
        }) as SigncoFormGroup;

        if (this.existingDatePeriodSet) {
            this.dialogForm.patchValue(this.existingDatePeriodSet);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.dialogForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (updatedDatePeriodSet: IDatePeriodSet) => {
            if (this.existingDatePeriodSet) {
                Object.assign(this.existingDatePeriodSet, updatedDatePeriodSet);
            }

            if (this.callback) {
                this.callback(updatedDatePeriodSet);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const creator = this.dialogForm.value as DatePeriodSetCreator;
        if (!this.existingDatePeriodSet) {
            this.datePeriodSetApi.create$(creator).subscribe(onSuccess, onError);
        } else {
            creator.id = this.existingDatePeriodSet.id;
            this.datePeriodSetApi.update$(creator).subscribe(onSuccess, onError);
        }
    }
}