import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { fabric } from "fabric";
import { VmsImageApi } from "src/app/resource/vms-image.api";
import { VmsTypeApi } from "src/app/resource/vms-type.api";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { Observable, Subject, combineLatest, debounceTime, forkJoin, take, zip } from "rxjs";
import { SubscriptionManager, FileUtils, OrganizationUtils } from "src/app/utilities";
import { ImageDialogComponent } from "src/app/modules/shared/components/image-dialog/image-dialog.component";
import { IVmsImage, VmsImageFromEditorCreator, VmsImageFromEditorUpdater } from "src/app/models/vms-image";
import { ToastService } from "src/app/services/toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MapDetailService } from "src/app/services/map-detail.service";
import { NavigationService } from "src/app/services/navigation.service";
import { ServiceRequestOptions } from "src/app/models/search";
import { IVmsImageVariant, VmsImageVariantCreator, VmsImageVariantUpdater, VmsImageVariantUploadCreator } from "src/app/models/vms-image-variant";
import { VmsImageVariantApi } from "src/app/resource/vms-image-variant.api";
import { VmsImageEditorService } from "../../services/vms-image-editor.service";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { ModalService } from "src/app/services/modal.service";
import { cloneDeep } from "lodash";
import { SelectItem } from "primeng/api";
import { MapDataService } from "src/app/services/map-data.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IVmsType } from "src/app/models/measuring-point";

@Component({
    selector: "app-vms-image-editor",
    templateUrl: "./vms-image-editor.component.html",
    styleUrls: []
})
export class VmsImageEditorComponent implements OnInit, OnDestroy, IChangeGuard {
    @Input() inPopup = false;
    @ViewChild(ImageDialogComponent, { static: true }) imageDialogComponent: ImageDialogComponent;

    vmsImage: IVmsImage;
    vmsImageVariants: IVmsImageVariant[];
    private vmsTypeWidth: number;
    private vmsTypeHeight: number;
    private previewImage: string;
    private initialVmsImage: IVmsImage;
    private filePreview: string;

    private canvas: fabric.Canvas;
    public currentSelectedObject: fabric.Object;

    public vmsTypes: IVmsType[];
    public vmsTypeValues: ViewModelEnum[];
    public selectedVmsTypeValue: string;
    public currentVmsVariant: IVmsImageVariant;
    private readonly subscriptionManager = new SubscriptionManager();
    private dropdownValuesFetched: Subject<boolean> = new Subject<boolean>();
    organizations: SelectItem[];
    selectedOrganization: number;
    code: string;
    submitting: boolean;

    mapDataKey: string;

    cancelCallback: () => void;
    saveCallback: (vmsImage: IVmsImage) => void;

    public loading: boolean;
    contextMenuVisible = false;
    contextMenuX = 0;
    contextMenuY = 0;

    constructor(
        private readonly vmsImageApi: VmsImageApi,
        private readonly vmsImageVariantApi: VmsImageVariantApi,
        private readonly vmsTypeApi: VmsTypeApi,
        private readonly domainDataService: DomainDataService,
        private readonly toastrService: ToastService,
        private readonly route: ActivatedRoute,
        private readonly mapDetailService: MapDetailService,
        private readonly navigationService: NavigationService,
        private readonly vmsImageEditorService: VmsImageEditorService,
        private readonly router: Router,
        private readonly changeGuardService: ChangeGuardService,
        private readonly modalService: ModalService,
        private readonly mapDataService: MapDataService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly globalEventsService: GlobalEventsService

    ) {
        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(
            this.mapDataKey,
            (organizations) => {
                this.organizations = this.primeComponentService.createDropdownList(
                    OrganizationUtils.addLevel(organizations),
                    x => x.id,
                    x => x.name
                    , false, "", OrganizationUtils.getStyleClass);
            }
        );
        this.initDropdowns();
    }

    ngOnInit(): void {
        this.loading = true;
        if (!this.inPopup) {
            this._loadCanvas();
            // If we have a route param and dropdowns fetched => prepare canvas
            const forkJoinSubscription = combineLatest([
                this.route.params,
                this.dropdownValuesFetched
            ])
                .pipe(
                    take(1),
                )
                .subscribe(([params, dropdownsAreReady]) => {
                    if (dropdownsAreReady) {
                        this._setVmsImageFromParams(params);
                    }
                });
            this.subscriptionManager.add("forkJoinSubscription", forkJoinSubscription);
        } else {
            this.loading = false;
        }

        const currentSelectedObjectChangeSubscription = this.vmsImageEditorService.selectedObjectChange$.subscribe(async (object) => {
            this.saveCurrentCanvasToVariant();
            this.currentSelectedObject = object;
            if (!this.currentSelectedObject) {
                this.contextMenuVisible = false;
            }
        });
        this.subscriptionManager.add("currentSelectedObjectChange", currentSelectedObjectChangeSubscription);
        const currentVmsImageChangeSubscription = this.vmsImageEditorService.selectedVmsImage$.subscribe(async (object) => {
            this.vmsImage = object;
            if (!this.vmsImage) {
                this.vmsImageEditorService.setSelectedVmsImageVariant(null);
                this._hidePreview();
            }
        });
        this.subscriptionManager.add("currentVmsImageChangeSubscription", currentVmsImageChangeSubscription);

        const vmsImageVariantChangeSubscription = this.vmsImageEditorService.selectedVmsImageVariant$.subscribe(async (object) => {
            this.currentVmsVariant = object;
        });
        this.subscriptionManager.add("vmsImageVariantChangeSubscription", vmsImageVariantChangeSubscription);

        const vmsImageVariantsChangeSubscription = this.vmsImageEditorService.vmsImageVariants$.subscribe(async (object) => {
            this.vmsImageVariants = object;
        });
        this.subscriptionManager.add("vmsImageVariantsChangeSubscription", vmsImageVariantsChangeSubscription);
        const renderPreviewRequestSubscription = this.vmsImageEditorService.renderPreviewRequest$
            .pipe(debounceTime(200))
            .subscribe(async () => {
                this.preview();
            });
        this.subscriptionManager.add("renderPreviewRequest", renderPreviewRequestSubscription);

        const copyFromVmsTypeIdSubscription = this.vmsImageEditorService.copyFromVmsTypeId$.subscribe(async (vmsTypeId) => {
            if (vmsTypeId) {
                const variant = this.vmsImageVariants.find(x => x.vmsType.typeId == vmsTypeId);
                if (variant && variant.editorData) {
                    this._loadFromJson(variant.editorData);
                    this.vmsImageEditorService.setIsCurrentVmsImageChanged(true);
                }
            }
        });
        this.subscriptionManager.add("copyFromVmsTypeId", copyFromVmsTypeIdSubscription);

        const currentCanvasObjectChangeSubscription = this.vmsImageEditorService.currentCanvas$.subscribe(async (canvas) => {
            if (canvas) {
                this.canvas = canvas;
                // Event handlers on fabric
                // Mouse right click: show context menu
                this.canvas.on("mouse:down", (event) => {
                    if (event.button === 3 && event.target) {
                        this.contextMenuVisible = true;
                        const parentElement = this.canvas.getElement().parentElement.parentElement;
                        const canvasRect = parentElement.getBoundingClientRect();
                        this.contextMenuX = event.e.clientX - canvasRect.left + 5;
                        this.contextMenuY = event.e.clientY - canvasRect.top + 5;
                        this.canvas.setActiveObject(event.target);
                        this.canvas.requestRenderAll();
                        this.vmsImageEditorService.notifySelectedObjectChanged(event.target);
                    }

                });
            }
        });
        this.subscriptionManager.add("currentCanvasObjectChange", currentCanvasObjectChangeSubscription);

        const uploadFileSubscription = this.vmsImageEditorService.uploadFile$.subscribe(async (file) => {
            if (file) {
                this.uploadFile(file);
            }
        });
        this.subscriptionManager.add("uploadFile", uploadFileSubscription);

    }

    get displayOrganizations(): boolean {
        return this.globalEventsService.hasMultipleOrganizations();
    }

    private _loadCanvas() {
        if (!this.canvas) {
            // When importing, exporting, set this to 8
            // Why? FabricJS Gotcha: Wrong position after reloading a JSON object - NUM_FRACTION_DIGITS
            // See http://fabricjs.com/fabric-gotchas
            fabric.Object.NUM_FRACTION_DIGITS = 8;
            fabric.Object.prototype.noScaleCache = false;
            // No object caching to enhance font loading
            fabric.IText.prototype.objectCaching = false;
            // Custom SVG export for group
            // Add smoothed attribute to root element
            fabric.Group.prototype.toSVG = (function (toSVG) {
                return function () {
                    const svgString = toSVG.call(this);
                    const domParser = new DOMParser();
                    const doc = domParser.parseFromString(svgString, "image/svg+xml");
                    // set attribute on root element
                    const shapeRendering = this.vmsSmoothed ?? false ? "auto" : "crispEdges";
                    doc.documentElement.setAttribute("shape-rendering", shapeRendering);
                    return doc.documentElement.outerHTML;
                };
            })(fabric.Group.prototype.toSVG);
            fabric.Object.prototype.toSVG = (function (toSVG) {
                return function () {
                    const svgString = toSVG.call(this);
                    const domParser = new DOMParser();
                    const doc = domParser.parseFromString(svgString, "image/svg+xml");
                    // set attribute on root element
                    const shapeRendering = this.vmsSmoothed ?? false ? "auto" : "crispEdges";
                    doc.documentElement.setAttribute("shape-rendering", shapeRendering);
                    return doc.documentElement.outerHTML;
                };
            })(fabric.Object.prototype.toSVG);
            fabric.IText.prototype.toSVG = (function (toSVG) {
                return function () {
                    const svgString = toSVG.call(this);
                    const domParser = new DOMParser();
                    const doc = domParser.parseFromString(svgString, "image/svg+xml");
                    // set attribute on root element
                    const shapeRendering = this.vmsSmoothed ?? false ? "auto" : "crispEdges";
                    doc.documentElement.setAttribute("shape-rendering", shapeRendering);
                    if (this.vmsTextAlign) {
                        doc.documentElement.setAttribute("vms-text-align", this.vmsTextAlign);
                    }
                    return doc.documentElement.outerHTML;
                };
            })(fabric.IText.prototype.toSVG);
            // Override fabric transform origin to center
            // Makes it easier to align objects
            fabric.Object.prototype.set({
                originX: "center",
                originY: "center",
            });
            const newCanvas = new fabric.Canvas("imageEditorCanvas", {
                hoverCursor: "pointer",
                fireRightClick: true,
                stopContextMenu: true,
                backgroundColor: "#000000"
            });
            this.vmsImageEditorService.setCurrentCanvas(newCanvas);
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.vmsImageEditorService.clear();
        this.vmsImageEditorService.setCurrentCanvas(null);
    }

    //#region Public functions

    initDropdowns() {

        zip(
            this.domainDataService.get(DomainData.VmsTypeValue),
            this.vmsTypeApi.getAll$()
        ).subscribe(
            (
                result: [
                    ViewModelEnum[],
                    IVmsType[]
                ]
            ) => {
                this.vmsTypeValues = result[0];
                this.vmsTypes = result[1];
                this.dropdownValuesFetched.next(true);
            }
        );
    }
    private saveCurrentCanvasToVariant() {
        if (this.currentVmsVariant && this.currentVmsVariant.file == null) {
            // check if canvas has at least one object without the name backgroundline
            if (this.canvas.getObjects().some((obj: fabric.Object) => obj.name !== "backgroundLine")) {
                this.currentVmsVariant.editorData = JSON.stringify(this.canvas.toJSON(["name", "vmsTextAlign", "vmsSmoothed", "snapAngle"]));
                this.currentVmsVariant.svgData = this.canvas.toSVG({ width: this.vmsTypeWidth, height: this.vmsTypeHeight });
                this.vmsImageEditorService.setSelectedVmsImageVariant(this.currentVmsVariant);
            }
        }
    }
    async onChangeVmsTypeValue(event) {
        this.saveCurrentCanvasToVariant();
        this.selectedVmsTypeValue = event.value;
        // get corresponding vms type
        const vmsType = this.vmsTypes.find(x => x.typeId == this.selectedVmsTypeValue);
        if (vmsType) {
            this._displayCanvas(true);
            this.vmsImageEditorService.setCurrentVmsType(vmsType);
            this._clearCanvas();
            this._setCanvasResolution(vmsType.resolutionWidth, vmsType.resolutionHeight);
            this._drawBackgroundGrid();
            const variant = this.vmsImageVariants.find(x => x.vmsType.typeId == this.selectedVmsTypeValue);
            if (variant) {
                // load variant data
                this.vmsImageEditorService.setSelectedVmsImageVariant(variant);
                if (variant.editorData) {
                    this._loadFromJson(variant.editorData);
                } else if (variant.url) {
                    this._displayCanvas(false);
                    this.vmsImageEditorService.requestRenderPreview();
                } else if (variant.file) {
                    this._displayCanvas(false);
                    this.filePreview = variant.file ? await FileUtils.toBase64(variant.file) : null;
                    this.vmsImageEditorService.requestRenderPreview();

                } else {
                    this.vmsImageEditorService.requestRenderPreview();
                }
            } else {
                // new variant
                const newVariant = {
                    id: 0,
                    vmsType: vmsType,
                    vmsImageId: this.vmsImage?.id,
                    editorData: "",
                    svgData: "",
                } as IVmsImageVariant;
                this.vmsImageVariants.push(newVariant);
                this.vmsImageEditorService.setVmsImageVariants(this.vmsImageVariants);
                this.vmsImageEditorService.setSelectedVmsImageVariant(newVariant);

                this.vmsImageEditorService.requestRenderPreview();
            }
        }

    }
    private _loadFromJson(editorData: string) {
        this.canvas.loadFromJSON(editorData, () => {
            this.loadCanvasAfterJsonLoaded();
        }, (o, object) => {
            // if name is arrow, set controls
            if (object.name == "arrow" || object.type == "line") {
                this.vmsImageEditorService.setControlsVisibility(object);
            } else if (object.name == "symbol") {
                this.vmsImageEditorService.setSymbolControlsVisibility(object);
            } else if (object.type == "i-text") {
                this.vmsImageEditorService.setTextControlsVisibility(object);
            }
            object.setCoords();

        });

    }
    onImageClick() {
        this.imageDialogComponent.create("Preview", this.previewImage);
    }

    private _hidePreview() {
        const image = document.getElementById("previewImage") as HTMLImageElement;
        if (image) {
            image.src = "";
            image.style.display = "none";
        }
    }
    // Preview image
    // 1. Convert canvas to svg OR show file
    // 2. Send svg to backend and request specific format
    async preview() {
        if (this.currentVmsVariant?.url && !this.currentVmsVariant?.editorData) {
            this.previewImage = this.currentVmsVariant.url;

        } else {
            const file = this.currentVmsVariant?.file ?? await this.vmsImageVariantApi.preview$(this.canvas.toSVG({ width: this.vmsTypeWidth, height: this.vmsTypeHeight }), this.vmsTypeWidth, this.vmsTypeHeight);

            if (file) {
                this.previewImage = URL.createObjectURL(file);
            }
        }

        const image = document.getElementById("previewImage") as HTMLImageElement;
        if (image) {
            image.src = this.previewImage;
            image.style.display = "block";
        }

    }

    //#endregion

    //#region Canvas functions

    _setCanvasResolution(width: number, height: number) {
        this.vmsTypeHeight = height;
        this.vmsTypeWidth = width;
        this._calculateCanvasSize();
    }


    // Calculate the size of the canvas
    // Based on available space in the container
    // Keep aspect ratio of vms image type
    _calculateCanvasSize() {
        const clientHeight = document.getElementById("canvasContainerBody").clientHeight;
        const clientWidth = document.getElementById("canvasContainerBody").clientWidth;
        const maxHeightMultiplication = Math.floor(clientHeight / this.vmsTypeHeight);
        const maxWidthMultiplication = Math.floor(clientWidth / this.vmsTypeWidth);
        const multiplication = Math.min(maxHeightMultiplication, maxWidthMultiplication);
        this.canvas.setHeight(this.vmsTypeHeight * multiplication);
        this.canvas.setWidth(this.vmsTypeWidth * multiplication);
        this.canvas.setZoom(multiplication);
    }

    // Draw background grid
    // Currently with gap of 10
    _drawBackgroundGrid() {
        const gap = this.canvas.vptCoords.br.x / 10;
        const lines = [];
        for (let x = this.canvas.vptCoords.bl.x; x < this.canvas.vptCoords.br.x; x += gap) {
            const line = new fabric.Line(
                [x, 0, x, this.canvas.vptCoords.br.y],
                {
                    stroke: "#FFFFFF", strokeWidth: 0.1, selectable: false, evented: false, excludeFromExport: true,
                    name: "backgroundLine"
                }
            );
            this.canvas.add(line);
            lines.push(line);
        }
        for (let x = this.canvas.vptCoords.tl.y; x < this.canvas.vptCoords.br.y; x += gap) {
            const line = new fabric.Line(
                [0, x, this.canvas.vptCoords.br.x, x],
                {
                    stroke: "#FFFFFF", strokeWidth: 0.1, selectable: false, evented: false, excludeFromExport: true,
                    name: "backgroundLine",
                }
            );
            this.canvas.add(line);
            lines.push(line);
        }

        // Send background to back
        if (lines) {
            lines.forEach((obj: fabric.Object) => {
                this.canvas.sendToBack(obj);
            });
        }

    }
    //#endregion

    //#region eventlisteners
    @HostListener("window:resize", ["$event"])
    onWindowResize(event) {
        if (this.canvas && document.getElementById("canvasContainerBody")) {
            this._calculateCanvasSize();
        }
    }

    @HostListener("document:click", ["$event"])
    onDocumentClick(event: MouseEvent) {
        if (!this.contextMenuVisible) {
            return;
        }
        const contextMenu = document.querySelector(".context-menu");
        if (!contextMenu.contains(event.target as Node)) {
            this.contextMenuVisible = false;
        }
    }
    @HostListener("document:keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === "Delete") {
            this.vmsImageEditorService.deleteActiveObjects();
        }
        // if event.key is ArrowLeft, ArrowRight, ArrowUp or ArrowDown
        // move active object
        if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)) {
            this.vmsImageEditorService.moveActiveObject(event.key);
        }
    }
    //#endregion

    //#region Save
    save() {
        this.saveCurrentCanvasToVariant();
        this.submitting = true;

        if (this.vmsImage && (this.inPopup && !this.vmsImage.inLibrary || !this.inPopup)) {
            this._editVmsImage();
        } else if (this.inPopup) {
            this.code = this._generateUniqueCode();
            this._saveVmsImage();
        } else {
            this._saveVmsImage();
        }

    }
    private _generateUniqueCode(): string {
        const now = new Date();
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
    }

    private async _saveVmsImage() {
        const vmsImageCreator = {
            code: this.code,
            inLibrary: !this.inPopup,
            ownerId: this.selectedOrganization,
            variants: []
        } as VmsImageFromEditorCreator;

        // loop through vmsVariants and add them to the vmsImageCreator
        this.vmsImageVariants.forEach(vmsImageVariant => {
            if (vmsImageVariant.editorData) {
                const vmsVariantCreator = {
                    vmsTypeId: vmsImageVariant.vmsType.typeId,
                    editorData: vmsImageVariant.editorData,
                    data: vmsImageVariant.svgData,
                    width: vmsImageVariant.vmsType.resolutionWidth,
                    height: vmsImageVariant.vmsType.resolutionHeight
                } as VmsImageVariantCreator;
                vmsImageCreator.variants.push(vmsVariantCreator);
            }
        });
        const onSuccess = async (newVmsImage: IVmsImage) => {
            // check if there is any this.vmsImageVariants where file is not null
            const fileVariants = this.vmsImageVariants.filter(x => x.file);
            if (fileVariants && fileVariants.length > 0) {
                const observables$ = new Array<Observable<IVmsImageVariant>>();
                // loop through fileVariants and upload them to vmsImageVariantAPI
                fileVariants.forEach(fileVariant => {
                    const vmsVariantCreator = {
                        vmsTypeId: fileVariant.vmsType.typeId,
                        vmsImageId: newVmsImage.id,
                        ownerId: newVmsImage.ownerId
                    } as VmsImageVariantUploadCreator;

                    observables$.push(this.vmsImageVariantApi.upload$(vmsVariantCreator, fileVariant.file));
                });

                forkJoin(observables$).subscribe({
                    next: (results: IVmsImageVariant[]) => {
                        this.onSaveSucces(newVmsImage);
                    },
                    error: (error) => {
                        this.onSaveError.bind(this);
                    }
                });
            } else {
                this.onSaveSucces(newVmsImage);
            }
        };
        this.vmsImageApi.createFromEditor$(vmsImageCreator).subscribe({
            next: onSuccess,
            error: this.onSaveError.bind(this)
        });
    }

    private async _editVmsImage() {
        const vmsImageUpdater = {
            code: this.vmsImage.code,
            inLibrary: false,
            ownerId: this.vmsImage.ownerId,
            id: this.vmsImage.id,
            variants: []
        } as VmsImageFromEditorUpdater;

        const observables$ = new Array<Observable<object>>();
        // loop through vmsVariants and add them to the vmsImageCreator
        this.vmsImageVariants.forEach(vmsImageVariant => {
            if (vmsImageVariant.editorData) {
                const vmsVariantUpdater = {
                    id: vmsImageVariant.id,
                    vmsTypeId: vmsImageVariant.vmsType.typeId,
                    vmsImageId: vmsImageVariant.vmsImageId,
                    editorData: vmsImageVariant.editorData,
                    data: vmsImageVariant.svgData,
                    width: vmsImageVariant.vmsType.resolutionWidth,
                    height: vmsImageVariant.vmsType.resolutionHeight
                } as VmsImageVariantUpdater;
                vmsImageUpdater.variants.push(vmsVariantUpdater);
            } else if (vmsImageVariant.file) {
                const vmsVariantCreator = {
                    vmsTypeId: vmsImageVariant.vmsType.typeId,
                    vmsImageId: vmsImageVariant.vmsImageId,
                    ownerId: vmsImageUpdater.ownerId
                } as VmsImageVariantUploadCreator;
                observables$.push(this.vmsImageVariantApi.upload$(vmsVariantCreator, vmsImageVariant.file));
            }
        });
        observables$.push(this.vmsImageApi.updateFromEditor$(this.vmsImage.id, vmsImageUpdater));

        forkJoin(observables$).subscribe({
            next: (results: object[]) => {
                this.onSaveSucces(results[results.length - 1] as IVmsImage);
            },
            error: (error) => {
                this.onSaveError.bind(this);
            }
        });

    }

    private async onSaveSucces(savedVmsImage: IVmsImage) {

        if (this.vmsImage) {
            Object.assign(this.vmsImage, savedVmsImage);
        }
        this.toastrService.saveSuccess();
        if (this.saveCallback) {
            this.vmsImageEditorService.clear();
            this._clearCanvas();
            this.saveCallback(savedVmsImage);
        } else {
            const shouldNavigate = !this.vmsImage || this.vmsImage.id !== savedVmsImage.id;

            if (shouldNavigate) {
                this.navigationService.editVmsImage(savedVmsImage.id);

            }
            this.vmsImageEditorService.setSelectedVmsImage(savedVmsImage);
            this.vmsImageEditorService.setVmsImageVariants(savedVmsImage.variants);

        }
        this.submitting = false;
    }
    private onSaveError() {
        this.submitting = false;
    }



    //#endregion
    loadCanvasAfterJsonLoaded() {
        this._drawBackgroundGrid();
        this.canvas.renderAll();
        this.vmsImageEditorService.requestRenderPreview();
    }

    private async _setVmsImageFromParams(params: { [key: string]: any }): Promise<void> {
        if (!params) return;

        try {
            // get query params
            this.route.queryParams.subscribe(async queryParams => {
                const vmsImageIdString = decodeURI(params["vmsImageId"]);

                if (vmsImageIdString === "new") {
                    this.selectedOrganization = this.globalEventsService.getDefaultOrganization().id;
                    this.onChangeVmsTypeValue({ value: this.vmsTypeValues[0].value });
                    this.loading = false;
                    return;
                }

                const vmsImageId = Number.parseInt(vmsImageIdString, 10);

                const options = new ServiceRequestOptions();
                options.includes.add("vmsImage", "variants");
                const vmsImage = Number.isNaN(vmsImageId) ? null : await this.vmsImageApi.get$(vmsImageId, null, options).toPromise();

                if (!vmsImage) {
                    this.mapDetailService.navigateToMapDetail();
                    return;
                }
                let vmsTypeId: string = null;
                if (queryParams["vmsTypeId"]) {
                    vmsTypeId = queryParams["vmsTypeId"];
                }
                this._setVmsImage(vmsImage, vmsTypeId);
                this.loading = false;
            });



        } catch (ex) {
            this.mapDetailService.navigateToMapDetail();
        }
    }

    private _setVmsImage(vmsImage: IVmsImage, vmsTypeId?: string) {
        this.initialVmsImage = cloneDeep(vmsImage);
        this.vmsImageEditorService.setSelectedVmsImage(vmsImage);
        this.vmsImageEditorService.setVmsImageVariants(vmsImage.variants);

        if (vmsTypeId) {
            const vmsType = this.vmsTypes.find(x => x.typeId == vmsTypeId);
            if (vmsType) {
                this.onChangeVmsTypeValue({ value: vmsType.typeId });
                return;
            }
        }
        if (vmsImage.variants.length > 0) {
            this.onChangeVmsTypeValue({ value: vmsImage.variants[0].vmsType.typeId });
        } else {
            this.onChangeVmsTypeValue({ value: this.vmsTypeValues[0].value });
        }
        if (this.globalEventsService.hasMultipleOrganizations()) {
            this.selectedOrganization = vmsImage.ownerId ?? this.globalEventsService.getDefaultOrganization().id;
        } else {
            this.selectedOrganization = vmsImage.ownerId;
        }
        this.code = vmsImage.code;
    }

    async uploadFile(file: File) {
        this.currentVmsVariant.editorData = "";
        this.currentVmsVariant.svgData = "";
        this.currentVmsVariant.url = null;
        this.currentVmsVariant.file = file;

        this._displayCanvas(false);
        this.vmsImageEditorService.setSelectedVmsImageVariant(this.currentVmsVariant);
        this.filePreview = file ? await FileUtils.toBase64(file) : null;
        this.vmsImageEditorService.requestRenderPreview();
    }

    replaceImage() {
        // display canvas & set url/file to null
        this._displayCanvas(true);
        this.currentVmsVariant.url = null;
        this.currentVmsVariant.file = null;
        this.vmsImageEditorService.setSelectedVmsImageVariant(this.currentVmsVariant);

        this.vmsImageEditorService.requestRenderPreview();
    }

    private async _clearCanvas() {
        // clear canvas
        this.canvas.getObjects().forEach((obj) => {
            this.canvas.remove(obj);
        });
    }

    private async _displayCanvas(show: boolean) {
        // We MUST do it this way => otherwise fabric complains about missing canvas stuff...
        this.canvas.getElement().parentElement.hidden = !show;
    }

    public async setEditor(cancelCallback: () => void, saveCallback: (vmsImage: IVmsImage) => void,
        vmsTypeId: string, ownerId?: number, vmsImageId?: number) {
        this.saveCallback = saveCallback;
        this.cancelCallback = cancelCallback;
        // clear vmsImage and his variants
        this.vmsImageEditorService.setSelectedVmsImage(null);
        this.vmsImageEditorService.setVmsImageVariants([]);
        this.vmsImageEditorService.setIsCurrentVmsImageChanged(false);
        this._loadCanvas();
        const options = new ServiceRequestOptions();
        options.includes.add("vmsImage", "variants");
        const vmsImage = vmsImageId == null || Number.isNaN(vmsImageId) ? null : await this.vmsImageApi.get$(vmsImageId, null, options).toPromise();
        if (vmsImage) {
            this._setVmsImage(vmsImage, vmsTypeId);
        } else {
            this.onChangeVmsTypeValue({ value: vmsTypeId });
        }
    }


    close() {
        if (!this.canDeactivateCheck()) {
            this.modalService.confirm("form.unsavedWarning", this._navigateToOverview.bind(this));
            return;
        }
        this._navigateToOverview();
    }

    private _navigateToOverview() {
        // Go images overview
        this.router.navigate(["../"], { relativeTo: this.route });
    }

    cancel() {
        if (!this.canDeactivateCheck()) {
            this.modalService.confirm("form.unsavedWarning", this._cancel.bind(this));
            return;
        }
        this._cancel();
    }

    private _cancel() {
        this.vmsImageEditorService.clear();
        this._clearCanvas();
        if (this.cancelCallback) {
            this.cancelCallback();
        }
    }

    hasImageVariant(vmsTypeId: string): boolean {
        return this.vmsImageVariants.some(x => x.vmsType.typeId == vmsTypeId &&
            (x.editorData || x.file || x.url));
    }

    onVmsTypeValuesClick() {
        if (!this.vmsImageVariants || this.vmsImageVariants.length == 0) return;
        const variants = this.vmsImageVariants.filter(x => x.editorData || x.file || x.url);
        if (!variants || variants.length == 0) return;
        this.vmsTypeValues = this.vmsTypeValues.sort((a, b) => {
            const variantA = variants.find(x => x.vmsType.typeId == a.value);
            const variantB = variants.find(x => x.vmsType.typeId == b.value);
            if (variantA && variantB) {
                return 0;
            } else if (variantA) {
                return -1;
            } else if (variantB) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    canDeactivateCheck(): boolean {
        this.saveCurrentCanvasToVariant();

        return !this.vmsImageEditorService.getIsCurrentVmsImageChanged();
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }
}
