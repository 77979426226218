<form *ngIf="parkingForm"
      [formGroup]="parkingForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      autocomplete="off">
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': parkingForm.get('url').showErrors }">
        <label class="col-4"
               for="isEnabled">
            {{"parking.url" | translate}}
        </label>

        <div class="col-8">
            <input id="url"
                   type="text"
                   formControlName="url"
                   class="p-inputtext"
                   #urlInput />

            <app-form-validation [control]="parkingForm.get('url')"
                                 [input]="urlInput">
            </app-form-validation>
        </div>
    </div>

    <div class="m-form-group row">
        <div class="col-4">
            <label class="m-input-checkbox">
                <input id="simulate_vehicles_checkbox"
                       type="checkbox"
                       [checked]="this.simulation"
                       (change)="onSimulateChanged()" />
                {{"parking.simulate" | translate}}
            </label>
        </div>
        <div class="col-8"
             *ngIf="this.simulation">
            <div>
                <em>{{"parking.simulationRemark" | translate}}</em>
            </div>
            <div>
                <label>
                    {{"parking.capacity" | translate}}
                    <input type="number"
                           class="p-inputtext"
                           [(ngModel)]="this.simulation.capacity"
                           [ngModelOptions]="{standalone: true}" />
                </label>
            </div>
            <div>
                <label>
                    {{"parking.vacant" | translate}}
                    <input type="number"
                           class="p-inputtext"
                           [(ngModel)]="this.simulation.vacant"
                           [ngModelOptions]="{standalone: true}" />
                </label>
            </div>
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom"
     *ngIf="parkingForm">

    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>