<div class="m-layout-area-body m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                *ngIf="canCreateDeviceDisplayEvent && createCommand"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{'deviceDisplayEvents.create' | translate}}
        </button>

        <a class="m-btn m-btn-secondary"
           *ngIf="shortcutToManagementScreen"
           [routerLink]="['/scenarios/devicedisplayevents']"
           id="navigate_to_management_button">
            <app-svg-icon icon="navigate">
            </app-svg-icon>
            {{'deviceDisplayEvents.shortcutToManagementScreen' | translate}}
        </a>
    </div>

    <p-table id="scenarios_table"
             [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-layout-default m-border-top"
             [loading]="loading"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             [stateKey]="stateKey"
             dataKey="id"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer">
            <ng-container *ngIf="total && !paginator">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-deviceDisplayEvent>
            <tr [pSelectableRow]="deviceDisplayEvent">

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <ng-container *ngSwitchCase="'owner.name'">
                        <app-navigator [organizationId]="deviceDisplayEvent.owner.id"></app-navigator>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isActive'">
                        <p-inputSwitch [(ngModel)]="deviceDisplayEvent.isActive"
                                       (onChange)="save(deviceDisplayEvent)"></p-inputSwitch>
                    </ng-container>

                    <ng-container *ngSwitchCase="'priority'">
                        <input type="number"
                               class="p-inputtext"
                               [(ngModel)]="deviceDisplayEvent.priority"
                               (appDebounce)="save(deviceDisplayEvent)">
                    </ng-container>

                    <ng-container *ngSwitchCase="'deviceTypeId'">
                        <app-domain-data-field [enumKey]="deviceDisplayEvent.deviceTypeId"
                                               domainDataType="deviceType">
                        </app-domain-data-field>
                    </ng-container>

                    <ng-container *ngSwitchCase="'action'">
                        <div *ngIf="deviceDisplayEvent.pinAction">
                            {{"deviceDisplayEvents.enableRelay" | translate}}
                            <ng-container *ngIf="deviceDisplayEvent.pinAction.intervalOnInMilliseconds">
                                <br />
                                {{deviceDisplayEvent.pinAction.intervalOnInMilliseconds}}{{"measurements.ms" | translate}}
                                /
                                {{deviceDisplayEvent.pinAction.intervalOffInMilliseconds}}{{"measurements.ms" | translate}}
                            </ng-container>
                        </div>

                        <div *ngIf="deviceDisplayEvent.pages">
                            <div *ngFor="let page of deviceDisplayEvent.pages"
                                 class="rule-text">
                                <pre>{{page.text}}</pre>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'rule'">
                        <div [innerHTML]="deviceDisplayEvent.rule | readableDeviceDisplayRule:translateService.currentLang | safeHtml">
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'devices'">
                        <ng-container *ngIf="deviceDisplayEvent.applyToAllDevices; else deviceCountTemplate">
                            {{ 'words.all' | translate }}
                        </ng-container>

                        <ng-template #deviceCountTemplate>
                            <ul *ngIf="deviceDisplayEvent.devices">
                                <li *ngFor="let device of deviceDisplayEvent.devices; trackBy: serviceModelTrackByFn">
                                    <app-navigator [device]="device"
                                                   [ngStyle]="{'font-size': 'small'}">
                                    </app-navigator>
                                </li>
                            </ul>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(deviceDisplayEvent)"
                                                 *ngIf="command.rowValidFunc(deviceDisplayEvent)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{deviceDisplayEvent[column.field]}}
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>