import { IServiceModelBase } from "./servicemodelbase";
import { IUserSummary } from "./user";

export interface ILoginNotification extends IServiceModelBase {
    messageId: string;
    createDate: Date;
    creator: IUserSummary;
}

export class LoginNotificationCreator {
    message: { [key: string]: string };
    isObsolete?: boolean;
}

export class LoginNotificationUpdater extends LoginNotificationCreator {
    id: number;

    constructor(existing: ILoginNotification) {
        super();

        this.id = existing.id;
        this.isObsolete = existing.isObsolete;
    }
}