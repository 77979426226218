import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UploadsComponent } from "src/app/modules/shared/components/uploads/uploads.component";

@Component({
    selector: "app-upload",
    templateUrl: "./upload.component.html"
})
export class UploadComponent implements OnInit {
    @ViewChild(UploadsComponent, { static: true }) uploadsComponent: UploadsComponent;

    constructor(
        elementRef: ElementRef) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.uploadsComponent.reload();
    }

}