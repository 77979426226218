import { IBikeParameterSet, BikeParameterSetCreator } from "src/app/models/bike-parameter-set";
import { SigncoFormGroup, SigncoFormControl } from "src/app/models/form";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { BikeParameterSetApi } from "src/app/resource/bike-parameter-set.api";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { NumberUtils, StringUtils } from "src/app/utilities";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-bike-parameter-set-dialog",
    templateUrl: "./manage-bike-parameter-set.dialog.html"
})
export class ManageBikeParameterSetDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IBikeParameterSet) => void;
    manageBikeParameterSetForm: SigncoFormGroup;
    manageBikeParameterForm: SigncoFormGroup;
    existingBikeParameterSet: IBikeParameterSet;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly bikeParameterSetApi: BikeParameterSetApi) {

        super();
    }

    create(callback?: (res: IBikeParameterSet) => void) {
        this.callback = callback;
        this.existingBikeParameterSet = null;

        this.openDialog();
    }

    view(existingBikeParameterSet: IBikeParameterSet, callback?: (res: IBikeParameterSet) => void) {
        this.callback = callback;
        this.existingBikeParameterSet = existingBikeParameterSet;

        this.openDialog();
    }

    updateMinMaxLengths() {
        const childrensBikeControl = this.manageBikeParameterForm.get("minLengthChildrensBike");
        const youthBikeControl = this.manageBikeParameterForm.get("minLengthYouthBike");
        const adultBikeControl = this.manageBikeParameterForm.get("minLengthAdultBike");
        const mopedControl = this.manageBikeParameterForm.get("minLengthMoped");
        const extendedBikeControl = this.manageBikeParameterForm.get("minLengthExtendedBike");
        const maxLengthControl = this.manageBikeParameterForm.get("maxLength");

        const setMinMax = (control: SigncoFormControl, minControl: SigncoFormControl, maxControl: SigncoFormControl = null) => {
            const minValue = NumberUtils.isValid(minControl.value) ? minControl.value : 0;
            const maxValue = maxControl && NumberUtils.isValid(maxControl.value) ? maxControl.value : 100;

            control.setValidators([Validators.required, Validators.min(minValue), Validators.max(maxValue)]);

            control.updateValueAndValidity({
                onlySelf: true
            });
        };

        setMinMax(youthBikeControl, childrensBikeControl, adultBikeControl);
        setMinMax(adultBikeControl, youthBikeControl, mopedControl);
        setMinMax(mopedControl, adultBikeControl, extendedBikeControl);
        setMinMax(extendedBikeControl, mopedControl, maxLengthControl);
        setMinMax(maxLengthControl, extendedBikeControl);

        this.manageBikeParameterForm.updateValueAndValidity();
    }

    protected onOpen() {
        this.manageBikeParameterForm = this.formBuilder.group({
            minLengthChildrensBike: [null, [Validators.required, Validators.min(0)]],
            minLengthYouthBike: [null, [Validators.required, Validators.min(0)]],
            minLengthAdultBike: [null, [Validators.required, Validators.min(0)]],
            minLengthMoped: [null, [Validators.required, Validators.min(0)]],
            minLengthExtendedBike: [null, [Validators.required, Validators.min(0)]],
            maxLength: [null, [Validators.required, Validators.min(0)]],
            maxSpeedChildrensBike: [null, [Validators.required, Validators.min(0)]],
            maxSpeedYouthBike: [null, [Validators.required, Validators.min(0)]],
            maxSpeedAdultBike: [null, [Validators.required, Validators.min(0)]],
            maxSpeedExtendedBike: [null, [Validators.required, Validators.min(0)]],
            minSpeed: [null, [Validators.required, Validators.min(0)]],
            maxSpeed: [null, [Validators.required, Validators.min(0)]],
            speedMargin: [null, [Validators.required, Validators.min(0)]],
            speedMarginToReclassify: [null, [Validators.required, Validators.min(0)]],
            lengthMargin: [null, [Validators.required, Validators.min(0)]],
            lengthMarginToReclassify: [null, [Validators.required, Validators.min(0)]],
            minSpeedWheely: [7, [Validators.required, Validators.min(0)]],
            maxSpeedWheely: [35, [Validators.required, Validators.min(0)]],
        }) as SigncoFormGroup;

        const shouldCheckExisting = () => {
            return !this.existingBikeParameterSet || !StringUtils.equalsIgnoreCase(this.manageBikeParameterSetForm.get("code").value, this.existingBikeParameterSet.code);
        };

        this.manageBikeParameterSetForm = this.formBuilder.group({
            code: "",
            description: "",
            parameters: this.manageBikeParameterForm
        }) as SigncoFormGroup;

        if (this.existingBikeParameterSet) {
            this.manageBikeParameterSetForm.patchValue(this.existingBikeParameterSet);
        }

        this.updateMinMaxLengths();

        this.submitting = false;
    }

    protected onClose() {
        this.manageBikeParameterSetForm = null;
        this.manageBikeParameterForm = null;
    }
}