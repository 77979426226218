import { DownloadArchiveDialogComponent } from "../download-archive-dialog/download-archive.dialog";
import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { IDevice } from "src/app/models/device";

@Component({
    selector: "app-device-upload",
    templateUrl: "./device-upload.component.html"
})
export class DeviceUploadComponent {
    @ViewChild(DownloadArchiveDialogComponent, { static: true }) downloadArchiveDialog: DownloadArchiveDialogComponent;

    @Input() device: IDevice;

    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    setDevice(device: IDevice) {
        this.device = device;
    }

    openDownloadArchiveDialog() {
        this.downloadArchiveDialog.openForDevice(this.device);
    }
}