import { IServiceModelBase } from "./servicemodelbase";

export interface IRelease extends IServiceModelBase {
    id: number;
    version: string;
    description: string;
    sha256: string;
    packageId: string;
    fileSize: number;
    channelStatus: { [key: string]: boolean };
}

export class ReleaseCreator {
    version: string;
    description: string;
    packageId: string;
}

export class ReleaseUpdater extends ReleaseCreator {
    id: number;

    constructor(existing: IRelease) {
        super();

        this.id = existing.id;
        this.version = existing.version;
        this.description = existing.version;
        this.packageId = existing.packageId;
    }
}

export class ReleaseActivator {
    packageId: string;
    version: string;

    constructor(release: IRelease, public channelId: string) {
        this.packageId = release.packageId;
        this.version = release.version;
    }
}