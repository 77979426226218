import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions, SortDescriptor, SortDirection } from "src/app/models/search";
import { IProject, ProjectCreator, ProjectStatus, ProjectUpdater } from "src/app/models/project";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { Include, LazyTreeComponent } from "src/app/modules/shared/components/lazy-tree/lazy-tree.component";
import { SubscriptionManager } from "src/app/utilities";
import { ProjectApi } from "src/app/resource/project.api";
import { TreeNode } from "primeng/api";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { TreeModule } from "primeng/tree";
import { NgStyle } from "@angular/common";
import { SharedModule } from "src/app/modules/shared/shared.module";

@Component({
    selector: "app-project-treelist",
    templateUrl: "./project-treelist.component.html",
    imports: [
        TreeModule,
        NgStyle,
        SharedModule
    ],
    standalone: true
})
export class ProjectTreeListComponent extends LazyTreeComponent<IProject, ProjectCreator, ProjectUpdater> implements OnDestroy, OnChanges {
    @Input() onlyMeasurementProjects = false;
    @Input() onlyMaasProjects = false;

    @Output() firstLevelLoaded = new EventEmitter<void>();

    private subscriptionManager = new SubscriptionManager();
    project: IProject;
    selectedNodes = new Array<TreeNode>();

    constructor(
        private readonly selectionService: MapSelectionService,
        private readonly projectApi: ProjectApi) {

        super(projectApi);
        this.selectionService.subscribeToProjects(this.subscriptionManager, x => this.selectProjects(x), x => this.unselectProjects(x));
        this.subscriptionManager.add("firstlevelsub", this.firstLevelLoaded$.subscribe(() => {
            this.firstLevelLoaded.emit();
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["onlyMeasurementProjects"] || changes["onlyMaasProjects"]) {
            if (this.onlyMaasProjects || this.onlyMeasurementProjects) {
                this.loadFirstLevelNodes(this.getSearchParameters(), this.getServiceRequestOptions(), true, null, false, true);
            }
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    getCustomStyle(node: TreeNode) {
        const project = node.data as IProject;

        const activeProjectStatuses = [ProjectStatus.Active, ProjectStatus.InProgress, ProjectStatus.NotStarted];
        const now = new Date();
        const currentDateEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
        const currentDateStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

        if (project.currentStatus) {
            if (activeProjectStatuses.contains(project.currentStatus.statusId)) {
                return "";
            } else {
                return { color: "lightgray" };
            }
        } else if ((project.until && project.until >= currentDateEnd)
            || (!project.until && project.from && project.from <= currentDateStart)) {
            return "";
        }

        return { color: "lightgray" };
    }

    checkProject(node: TreeNode) {
        this.selectProjects([node.data]);
    }

    uncheckProject(node: TreeNode) {
        this.unselectProjects([node.data]);
    }

    async selectProjects(projects: IProject[]) {
        if (!projects) return;

        const promises = new Array<Promise<void>>();
        for (const project of projects) {
            promises.push(this.selectProject(project));
        }

        await Promise.all(promises);
        this.selectionService.addProjects(projects);
    }

    private async selectProject(project: IProject): Promise<void> {
        let nodeToSelect = this.findTreeNode(this.nodes, project.id);
        if (!nodeToSelect) {
            if (!project.hasParentProject) return;

            await this.reloadNode(project.id, true);
            nodeToSelect = this.findTreeNode(this.nodes, project.id);
            if (!nodeToSelect) return; // probably won't be triggered
        }

        if (!nodeToSelect.children && (nodeToSelect.data as IProject).hasSubProjects) {
            await this.lazyLoadNode(nodeToSelect);
        }

        if (this.selectedNodes.contains(nodeToSelect) && !nodeToSelect.children) {
            return;
        }

        if (nodeToSelect.children && nodeToSelect.children.length > 0) {
            this.selectProjects(nodeToSelect.children.map(x => x.data as IProject));
            return;
        } else {
            this.selectedNodes.push(nodeToSelect);

            setTimeout(() => {
                this.tree.propagateUp(nodeToSelect, true);
            });
        }
    }

    unselectProjects(projects: IProject[]) {
        if (!projects) return;

        const projectIds = projects.map(x => x.id);
        const nodesToRemove = projectIds.map(x => this.findTreeNode(this.nodes, x)).filter(x => !!x);

        for (const nodeToRemove of nodesToRemove) {
            if (nodeToRemove.children && nodeToRemove.children.length > 0) {
                for (const child of nodeToRemove.children) {
                    this.unselectProjects([child.data]);
                }

                return;
            }

            this.selectedNodes = this.selectedNodes.filter(x => x.key != nodeToRemove.key);

            setTimeout(() => {
                this.tree.propagateUp(nodeToRemove, false);
            });
        }

        this.selectionService.removeProjects(projects);
    }

    //#region [Inherited]

    getSubtreeRoot(nodeId: number): Promise<IProject> {
        return this.projectApi.getRoot(nodeId).toPromise();
    }

    getRootNodeFilter(): FilterDescriptor {
        return { field: "parentProject", value: null, operator: FilterOperator.isNull };
    }

    isRootNode(project: IProject): boolean {
        return project.parentProject == null;
    }

    getParentInclude(): Include {
        return { model: "project", property: "parentProject" };
    }

    getChildrenInclude(): Include {
        return { model: "project", property: "subProjects" };
    }

    mapChildren(project: IProject): TreeNode[] {
        return !project.subProjects ? [] : project.subProjects.map(p => this.mapToTreeNode(p));
    }

    getChildren(project: IProject): IProject[] {
        return project.subProjects;
    }

    mapToTreeNode(project: IProject): TreeNode {
        return {
            key: "" + project.id,
            label: project.name,
            data: project,
            leaf: !project.hasSubProjects,
            expanded: false,
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
        } as TreeNode;
    }

    getSearchParameters(): SearchParameters {
        const sp = new SearchParameters();
        sp.sort = [new SortDescriptor(SortDirection.ascending, "name")];

        if (this.onlyMeasurementProjects) {
            sp.filter = [];
            sp.filter.push({ field: "isMeasurementProject", operator: FilterOperator.equals, value: true });
            sp.filter.push({ field: "isMaasProject", operator: FilterOperator.equals, value: false });
        } else if (this.onlyMaasProjects) {
            sp.filter = [];
            sp.filter.push({ field: "isMeasurementProject", operator: FilterOperator.equals, value: false });
            sp.filter.push({ field: "isMaasProject", operator: FilterOperator.equals, value: true });
        }

        return sp;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        if (this.onlyMeasurementProjects) {
            const serviceRequestOptions = new ServiceRequestOptions();
            serviceRequestOptions.includes.add("project", "projectGroups");
            serviceRequestOptions.includes.add("projectGroup", "group");
            serviceRequestOptions.includes.add("group", "measuringPoints");
            serviceRequestOptions.includes.add("groupMeasuringPoint", "measuringPoints");
            serviceRequestOptions.includes.add("measuringPoint", "location");
            serviceRequestOptions.includes.add("project", "projectMeasuringPoints");
            serviceRequestOptions.includes.add("projectMeasuringPoint", "measuringPoint");
            serviceRequestOptions.includes.add("projectMeasuringPoint", "location");
            return serviceRequestOptions;
        }

        return super.getServiceRequestOptions();
    }

    //#endregion [Inherited]
}
