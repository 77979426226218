<div class="m-layout-w-actions-bottom m-fixed-height m-border-top">
    <div *ngIf="!externalInputConfigurationForm">
        <app-loading-spinner>
        </app-loading-spinner>
    </div>

    <div class="m-layout-area-body"
         style="overflow: hidden;display: flex;flex-flow: column;">
        <form *ngIf="externalInputConfigurationForm"
              [formGroup]="externalInputConfigurationForm"
              autocomplete="off"
              style="overflow: hidden;display: flex;flex-flow: column;">

            <div class="px-16 py-8 flex align-center m-border-bottom">
                <label class="px-8"
                       for="inputType{{inputIndex}}">
                    {{"general.type" | translate}}
                </label>

                <div>
                    <p-dropdown id="currentStatusId"
                                [options]="externalInputTypes"
                                [panelStyle]="{'width':'100%'}"
                                formControlName="type"
                                [autoDisplayFirst]="false"
                                #typeInput></p-dropdown>

                    <app-form-validation [control]="externalInputConfigurationForm.get('type')"
                                         [input]="typeInput">
                    </app-form-validation>

                </div>
            </div>

            <div class="px-16 py-8 flex align-center">
                <h3>{{"externalInputConfiguration.inputs" | translate}}</h3>
                <app-icon-button icon="add"
                                 class="ml-8 mr-16"
                                 (click)="addInput()"
                                 id="add_external_input_button">
                </app-icon-button>
            </div>

            <ng-container *ngIf="inputForms"
                          formGroupName="inputs">
                <style>
                    h4 {
                        text-align: center;
                    }

                    .cell {
                        align-self: center;
                    }
                </style>
                <div class="m-overflow-auto">
                    <div style="display:inline-grid; grid-template-columns: repeat(5, auto); gap: 16px;"
                         id="inputs_grid">
                        <h4></h4>
                        <h4>{{"externalInputConfiguration.sensorId" | translate}}</h4>
                        <h4>{{"general.description" | translate}}</h4>
                        <h4>{{"externalInputConfiguration.iconActive" | translate}}</h4>
                        <h4>{{"externalInputConfiguration.iconInactive" | translate}}</h4>

                        <ng-container *ngFor="let inputForm of inputForms.controls; let inputIndex = index"
                                      formGroupName="{{inputIndex}}">

                            <div class="cell">
                                <app-icon-button id="removeExternalInput{{inputIndex}}"
                                                 icon="delete"
                                                 (click)="deleteInput(inputIndex)">
                                </app-icon-button>
                            </div>

                            <div class="cell">
                                <input id="inputId{{inputIndex}}"
                                       type="text"
                                       class="p-inputtext"
                                       style="width:50px;"
                                       formControlName="id"
                                       (change)="updateInputOptions()"
                                       appAutofocus
                                       #idInput />

                                <app-form-validation [control]="inputForm.get('id')"
                                                     [input]="idInput">
                                </app-form-validation>
                            </div>

                            <div class="cell">
                                <input id="inputDescription{{inputIndex}}"
                                       type="text"
                                       class="p-inputtext"
                                       formControlName="description"
                                       #descriptionInput />

                                <app-form-validation [control]="inputForm.get('description')"
                                                     [input]="descriptionInput">
                                </app-form-validation>
                            </div>

                            <div class="cell"
                                 style="text-align: center;">
                                <div style="display:inline-grid; grid-template-columns: repeat(3, auto); align-items: center;">
                                    <!-- preview -->
                                    <div *ngIf="files[inputIndex]?.trueValueFilePreview">
                                        <img [attr.src]="files[inputIndex].trueValueFilePreview | safeHtml"
                                             style="max-width:50px;" />
                                    </div>

                                    <app-icon-button *ngIf="files[inputIndex]?.trueValueFilePreview"
                                                     icon="delete"
                                                     (click)="setPhotoDeleted(inputIndex, true, trueValueFile)">
                                    </app-icon-button>

                                    <label for="trueValueFileInput{{inputIndex}}"
                                           class="m-input-file">
                                        <app-svg-icon icon="upload"></app-svg-icon>

                                        <input type="file"
                                               id="trueValueFileInput{{inputIndex}}"
                                               accept="image/*"
                                               #trueValueFile
                                               (change)="setFile(trueValueFile, inputIndex, true)" />
                                    </label>
                                </div>
                            </div>

                            <div class="cell"
                                 style="text-align: center;">
                                <div style="display:inline-grid; grid-template-columns: repeat(3, auto); align-items: center;">
                                    <!-- preview -->
                                    <div *ngIf="files[inputIndex]?.falseValueFilePreview">
                                        <img [attr.src]="files[inputIndex].falseValueFilePreview | safeHtml"
                                             style="max-width:50px;" />
                                    </div>

                                    <app-icon-button *ngIf="files[inputIndex]?.falseValueFilePreview"
                                                     icon="delete"
                                                     (click)="setPhotoDeleted(inputIndex, false, falseValueFile)">
                                    </app-icon-button>

                                    <label for="falseValueFileInput{{inputIndex}}"
                                           class="m-input-file">
                                        <app-svg-icon icon="upload"></app-svg-icon>

                                        <input type="file"
                                               id="falseValueFileInput{{inputIndex}}"
                                               accept="image/*"
                                               #falseValueFile
                                               (change)="setFile(falseValueFile, inputIndex, false)" />
                                    </label>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div *ngIf="externalInputConfigurationForm"
             class="m-btn-group m-btn-group-right">

            <button [disabled]="submitting"
                    class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">

                {{"form.reset" | translate}}
            </button>

            <button [disabled]="submitting"
                    (click)="submit()"
                    class="m-btn m-btn-primary"
                    type="submit">

                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>