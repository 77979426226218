import { JournalCategorySaver } from "src/app/models/journal";
import { IJournalCategory } from "../models/journal";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class JournalCategoryApi extends ApiService<IJournalCategory, JournalCategorySaver, JournalCategorySaver> {

    getRoute(): string {
        return "JournalCategories";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}
