import { IRule, RuleCreator, RuleTester, TestResult, RuleUpdater } from "src/app/models/alarm";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { OneOfManyRequiredValidator } from "src/app/validators/one-of-many-required.validator";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { Component, OnDestroy } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { IDeviceStatus } from "src/app/models/device";
import { RuleApi } from "src/app/resource/rule.api";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-manage-rule-dialog",
    templateUrl: "./manage-rule.dialog.html"
})
export class ManageRuleDialogComponent extends DialogComponentBase implements OnDestroy {
    testResult: TestResult;
    submittingTest: boolean;
    submitting: boolean;
    callback: (res: IRule) => void;
    dialogForm: SigncoFormGroup;
    featuresForm: SigncoFormGroup;
    manageRuleForm: SigncoFormGroup;
    deviceStatusForm: SigncoFormGroup;
    powerStatusForm: SigncoFormGroup;
    controllerStatusForm: SigncoFormGroup;
    existingRule: IRule;
    calendarSettings: CalendarSettings;

    deviceTypes: ViewModelEnum[];

    constructor(
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly domainDataService: DomainDataService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly ruleApi: RuleApi) {

        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.domainDataService.get(DomainData.DeviceType).then(viewModels => {
            this.deviceTypes = viewModels;
        });
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(callback?: (res: IRule) => void) {
        this.callback = callback;
        this.existingRule = null;

        this.initialize();
        this.openDialog();
    }

    edit(existingRule: IRule, callback?: (res: IRule) => void) {
        this.callback = callback;
        this.existingRule = existingRule;

        this.initialize();
        this.openDialog();
    }

    protected onClose() {
        this.dialogForm = null;
        this.manageRuleForm = null;
        this.powerStatusForm = null;
        this.controllerStatusForm = null;
        this.deviceStatusForm = null;
        this.featuresForm = null;
    }

    private initialize() {
        this.testResult = null;
        const warningExpressionControl = this.formBuilder.control("");
        const errorExpressionControl = this.formBuilder.control("");

        this.manageRuleForm = this.formBuilder.group({
            code: ["", Validators.required],
            warningExpression: warningExpressionControl,
            errorExpression: errorExpressionControl,
            isAdminOnly: false,
            deviceTypes: [null, Validators.required],
            onlyForLinkedDevices: false,
            featuresEnabled: false,
            features: null
        }) as SigncoFormGroup;

        warningExpressionControl.setValidators([OneOfManyRequiredValidator.create([warningExpressionControl, errorExpressionControl])]);
        errorExpressionControl.setValidators([OneOfManyRequiredValidator.create([warningExpressionControl, errorExpressionControl])]);

        if (this.existingRule) {
            this.manageRuleForm.patchValue(this.existingRule);
            this.manageRuleForm.get("deviceTypes").setValue(this.existingRule.deviceTypes.map(x => x.deviceTypeId));

            if (this.existingRule.features) {
                this.manageRuleForm.get("featuresEnabled").setValue(true);

                this.featuresForm = this.featuresForm = this.formBuilder.group({
                    zigbee: false,
                    gpio: false,
                    otaUpdate: false,
                    realtimeAnalyzer: false,
                    vms: false,
                    qLiteDisplay: false,
                    dekimoDisplay: false,
                    tmsRadar: false,
                    safetyCrossing: false,
                    simulateData: false
                }) as SigncoFormGroup;

                this.featuresForm.patchValue(this.existingRule.features);
                this.manageRuleForm.setControl("features", this.featuresForm);
            }
        }

        this.manageRuleForm.get("featuresEnabled").valueChanges.subscribe((newValue) => {
            if (newValue) {
                this.featuresForm = this.featuresForm = this.formBuilder.group({
                    zigbee: false,
                    gpio: false,
                    otaUpdate: false,
                    realtimeAnalyzer: false,
                    vms: false,
                    qLiteDisplay: false,
                    dekimoDisplay: false,
                    tmsRadar: false,
                    safetyCrossing: false,
                    simulateData: false
                }) as SigncoFormGroup;

                this.manageRuleForm.setControl("features", this.featuresForm);
            } else {
                this.manageRuleForm.removeControl("features");
                this.featuresForm = null;
            }
        });

        const timestamp = new Date();
        timestamp.setUTCDate(timestamp.getUTCDate() - 2);

        this.powerStatusForm = this.formBuilder.group({
            batteryVoltage: [10, Validators.required]
        }) as SigncoFormGroup;

        this.controllerStatusForm = this.formBuilder.group({
            availableDiskSpace: [10000000, Validators.required]
        }) as SigncoFormGroup;

        this.deviceStatusForm = this.formBuilder.group({
            powerStatus: this.powerStatusForm,
            controller: this.controllerStatusForm,
            timestamp: [timestamp, Validators.required]
        }) as SigncoFormGroup;

        this.dialogForm = this.formBuilder.group({
            manageRuleForm: this.manageRuleForm,
            deviceStatusForm: this.deviceStatusForm
        }) as SigncoFormGroup;

        this.submitting = false;
        this.submittingTest = false;
    }

    async test() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid) return;

        this.submittingTest = true;

        const ruleTester = new RuleTester();
        ruleTester.status = this.deviceStatusForm.value as IDeviceStatus;
        ruleTester.warningExpression = this.manageRuleForm.get("warningExpression").value;
        ruleTester.errorExpression = this.manageRuleForm.get("errorExpression").value;

        const onSuccess = (testResult: TestResult) => {
            this.submittingTest = false;
            this.testResult = testResult;
        };

        const onError = () => {
            this.submittingTest = false;
        };

        this.ruleApi.test$(ruleTester).subscribe(onSuccess, onError);
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageRuleForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedRule: IRule) => {
            if (this.existingRule) {
                Object.assign(this.existingRule, savedRule);
            }

            if (this.callback) {
                this.callback(savedRule);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingRule) {
            const ruleCreator = Object.assign(new RuleCreator(), this.manageRuleForm.value);
            this.ruleApi.create$(ruleCreator).subscribe(onSuccess, onError);
        } else {
            const ruleUpdater = new RuleUpdater(this.existingRule);
            Object.assign(ruleUpdater, this.manageRuleForm.value);
            if (!this.featuresForm) ruleUpdater.features = null;
            this.ruleApi.update$(ruleUpdater).subscribe(onSuccess, onError);
        }
    }
}