<div class="m-card m-layout-w-actions-top">
    <ng-template #titleTemplate>
        <span class="m-circle m-circle-primary mr-8">
            <app-svg-icon icon="assignments">
            </app-svg-icon>
        </span>

        <div class="m-tab-header-title-text">
            <span class="m-tab-header-title-text-label"
                  *ngIf="assignment && !assignment.parentAssignmentId">{{"general.assignment" | translate}}</span>
            <span class="m-tab-header-title-text-label"
                  *ngIf="assignment && assignment.parentAssignmentId">{{"subAssignment.subAssignment" | translate}}</span>
            <span *ngIf="assignment">{{assignment.name}}</span>
        </div>
    </ng-template>

    <app-tabs [tabs]="tabs"
              class="m-layout-area-top"
              [selectedTab]="selectedTab"
              [relativeRouting]="true"
              [titleTemplate]="titleTemplate"
              [hidden]="!assignment"
              (selectedTabChange)="navigateTo($event);">
    </app-tabs>

    <router-outlet *ngIf="assignment"
                   (activate)="onActivate($event)"></router-outlet>
</div>