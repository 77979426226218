import { ISharedKey, SharedKeyCreator, SharedKeyUpdater } from "../models/shared-key";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class SharedKeyApi extends ApiService<ISharedKey, SharedKeyCreator, SharedKeyUpdater> {

    getRoute(): string {
        return "SharedKeys";
    }
}