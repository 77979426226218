import { IDrivingLane, DrivingLaneCreator, DrivingLaneUpdater } from "src/app/models/driving-lane";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { ExistsValidator } from "src/app/validators/exists.validator";
import { SigncoFormGroup } from "src/app/models/form";
import { DrivingLaneApi } from "src/app/resource/driving-lane.api";
import { StringUtils } from "src/app/utilities";
import { ChangeDetectorRef, Component } from "@angular/core";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-manage-driving-lane-dialog",
    templateUrl: "./manage-driving-lane.dialog.html"
})
export class ManageDrivingLaneDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IDrivingLane) => void;
    manageDrivingLaneForm: SigncoFormGroup;
    existingDrivingLane: IDrivingLane;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly drivingLaneApi: DrivingLaneApi,
        private readonly domainDataService: DomainDataService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly cd: ChangeDetectorRef) {

        super();
    }

    create(callback?: (res: IDrivingLane) => void) {
        this.callback = callback;
        this.existingDrivingLane = null;

        this.openDialog();
    }

    edit(existingDrivingLane: IDrivingLane, callback?: (res: IDrivingLane) => void) {
        this.callback = callback;
        this.existingDrivingLane = existingDrivingLane;

        this.openDialog();
    }

    protected async onOpen() {
        const translationFormGroup = await this.domainDataService.createTranslationFormGroup(this.existingDrivingLane ? this.existingDrivingLane.codeStringResourceId : null, true);

        this.manageDrivingLaneForm = this.formBuilder.group({
            codeStringResource: translationFormGroup,
            description: ""
        }) as SigncoFormGroup;

        if (this.existingDrivingLane) {
            this.manageDrivingLaneForm.patchValue(this.existingDrivingLane);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageDrivingLaneForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageDrivingLaneForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedDrivingLane: IDrivingLane) => {
            // Reload translations
            this.domainDataService.reload();

            if (this.existingDrivingLane) {
                Object.assign(this.existingDrivingLane, savedDrivingLane);
            }

            if (this.callback) {
                this.callback(savedDrivingLane);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingDrivingLane) {
            const drivingLaneCreator = Object.assign(new DrivingLaneCreator(), this.manageDrivingLaneForm.value);
            this.drivingLaneApi.create$(drivingLaneCreator).subscribe(onSuccess, onError);
        } else {
            const updatedDrivingLane = Object.assign(new DrivingLaneUpdater(this.existingDrivingLane), this.manageDrivingLaneForm.value);
            this.drivingLaneApi.update$(updatedDrivingLane).subscribe(onSuccess, onError);
        }
    }
}