import { Component, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AttachmentCreator, IAttachment } from "src/app/models/attachment";
import { SigncoFormGroup } from "src/app/models/form";
import { IParkingBan } from "src/app/models/parking-ban";
import { ISignScan, SignScanCreator, SignScanUpdater } from "src/app/models/sign-scan";
import { PhotoInputComponent } from "src/app/modules/shared/components/photo-input/photo-input.component";
import { SignScanApi } from "src/app/resource/sign-scan.api";
import { FormValidationService } from "src/app/services/form-validation.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { JsonUtils } from "src/app/utilities";
import { DialogComponentBase } from "../../../shared/components/dialog/dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-manage-sign-scan-dialog",
    templateUrl: "./manage-sign-scan-dialog.component.html"
})
export class ManageSignScanDialogComponent extends DialogComponentBase implements OnDestroy {

    submitting: boolean;
    parkingBan: IParkingBan;
    manageSignScanForm: SigncoFormGroup;
    locationForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;
    photo: IAttachment;
    existingSignScan: ISignScan;

    callback: (res: ISignScan) => void;

    @ViewChild(PhotoInputComponent, { static: false }) photoInput: PhotoInputComponent;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly signScanApi: SignScanApi,
        private readonly primeComponentService: PrimeComponentService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService) {
        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe((calendarSettings) => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(parkingBan: IParkingBan, callback?: (res: ISignScan) => void) {
        this.callback = callback;
        this.existingSignScan = null;
        this.parkingBan = parkingBan;
        this.openDialog();
    }

    edit(existingSignScan: ISignScan, callback?: (res: ISignScan) => void) {
        this.callback = callback;
        this.existingSignScan = existingSignScan;
        this.openDialog();
    }

    protected async onOpen() {
        this.locationForm = this.formBuilder.group({}) as SigncoFormGroup;

        this.manageSignScanForm = this.formBuilder.group({
            location: this.locationForm,
            name: ["", Validators.required],
            signCode: ["", Validators.required],
            checkIn: [null, Validators.required],
            checkOut: null,
            lost: [null]
        }) as SigncoFormGroup;

        const checkOutValueChangedSubscription = this.manageSignScanForm.get("checkOut").valueChanges.subscribe({
            next: (value) => {
                this.manageSignScanForm.get("lost").reset(null, { emitEvent: false });
            }
        });

        const lostValueChangedSubscription = this.manageSignScanForm.get("lost").valueChanges.subscribe({
            next: (value) => {
                this.manageSignScanForm.get("checkOut").reset(null, { emitEvent: false });
            }
        });

        this.subscriptionManager.add("checkOutValueChangedSubscription", checkOutValueChangedSubscription);
        this.subscriptionManager.add("lostValueChangedSubscription", lostValueChangedSubscription);

        if (this.existingSignScan) {
            this.manageSignScanForm.patchValue(this.existingSignScan, { emitEvent: false });
            const photo = this.getPhoto(this.existingSignScan);
            this.photo = photo ? JsonUtils.deepClone(photo) : undefined;
        }

        this.submitting = false;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageSignScanForm);
        if (!isValid || !this.photoInput.image) return;

        this.submitting = true;

        if (!this.existingSignScan) {
            this.createNew();
        } else {
            this.update();
        }
    }

    private async createNew() {
        const signScan = Object.assign({} as SignScanCreator, this.manageSignScanForm.value) as SignScanCreator;
        signScan.parkingBanId = this.parkingBan.id;
        signScan.location.ownerId = this.globalEventsService.getDefaultOrganization().id;

        const onError = () => {
            this.submitting = false;
        };

        const onSuccess = async (newSignScan: ISignScan) => await this.onSuccess(newSignScan);
        this.signScanApi.create$(signScan).subscribe(onSuccess, onError);
    }

    private async update() {
        // if sign scan was already lost or checked out it can't go again to 'inProgress' state
        // (neither lost or checked out)
        if ((this.existingSignScan.lost || this.existingSignScan.checkOut)
            && !this.manageSignScanForm.get("lost").value
            && !this.manageSignScanForm.get("checkOut").value) {

            this.toastService.error(this.translateService.instant("signScan.returningToInProgressStateWarning"));
            this.submitting = false;
            return;
        }

        const signScanUpdater = new SignScanUpdater(this.existingSignScan);
        Object.assign(signScanUpdater, this.manageSignScanForm.value);

        const onError = () => {
            this.submitting = false;
        };

        const onSuccess = async (newSignScan: ISignScan) => await this.onSuccess(newSignScan);
        this.signScanApi.update$(signScanUpdater).subscribe(onSuccess, onError);
    }


    private async onSuccess(savedSignScan: ISignScan) {
        await this.uploadPhoto(savedSignScan.id);

        if (this.existingSignScan) {
            Object.assign(this.existingSignScan, savedSignScan);
        }
        if (this.callback) {
            this.callback(savedSignScan);
        }
        this.submitting = false;
        this.close();
    }

    private getPhoto(signScan: ISignScan) {
        return signScan.photos && signScan.photos.length > 0 ? signScan.photos[0] : undefined;
    }

    private async uploadPhoto(signScanId: number) {

        const creator = new AttachmentCreator();
        creator.name = `signScan_${this.manageSignScanForm.controls["signCode"].value}`;
        creator.signScanId = signScanId;

        await this.photoInput.uploadPhoto(creator);
    }
}
