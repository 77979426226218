<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"releases.create" | translate}}
    </p-header>

    <form *ngIf="manageReleaseForm"
          class="p-16"
          [formGroup]="manageReleaseForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageReleaseForm.get('version').showErrors }">

            <label class="col-2"
                   for="version">
                {{"releases.version" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       class="p-inputtext"
                       id="version"
                       formControlName="version"
                       appAutofocus
                       #versionInput />

                <app-form-validation [control]="manageReleaseForm.get('version')"
                                     [input]="versionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageReleaseForm.get('description').showErrors }">

            <label class="col-2"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-10">
                <textarea rows="3"
                          class="p-inputtextarea"
                          placeholder="{{'general.description' | translate}}"
                          id="description"
                          formControlName="description"
                          #descriptionInput></textarea>

                <app-form-validation [control]="manageReleaseForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>

        <div *ngIf="!existingRelease"
             class="m-form-group row"
             [ngClass]="{ 'has-error': manageReleaseForm.submitted && !file }">

            <label class="col-2">
                {{"general.file" | translate}}
            </label>

            <div class="col-10">
                <label for="fileInput"
                       class="p-inputtext m-input-file m-input-group-w-icon">
                    <span class="label mr-16">
                        <ng-container *ngIf="file">
                            {{file.name}}
                        </ng-container>

                        <ng-container *ngIf="!file">
                            {{"general.selectFile" | translate}}
                        </ng-container>
                    </span>

                    <app-svg-icon icon="upload"
                                  class="ml-auto">
                    </app-svg-icon>

                    <input #fileInput
                           type="file"
                           id="fileInput"
                           (change)="setFile(fileInput)" />
                </label>

                <div *ngIf="manageReleaseForm.submitted && !file"
                     class="error">
                    {{"validation.required" | translate}}
                </div>
            </div>

        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>

            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>