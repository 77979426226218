import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { AttachmentCreator, AttachmentUpdater, IAttachment } from "src/app/models/attachment";
import { SigncoFormGroup } from "src/app/models/form";
import { IParkingBan } from "src/app/models/parking-ban";
import { FilterOperator, SearchParameters } from "src/app/models/search";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { PhotoInputComponent } from "src/app/modules/shared/components/photo-input/photo-input.component";
import { AttachmentTypeApi } from "src/app/resource/attachment-type.api";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { DownloadedFile } from "src/app/services/download-file.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { JsonUtils } from "src/app/utilities";

@Component({
    selector: "app-manage-parking-ban-image-dialog",
    templateUrl: "./manage-parking-ban-image-dialog.component.html",
    styleUrls: []
})
export class ManageParkingBanImageDialogComponent extends DialogComponentBase implements OnInit {
    submitting: boolean;
    parkingBan: IParkingBan;
    parkingBanId: number;
    existingAttachment: IAttachment;
    photo: IAttachment;
    manageAttachmentForm: SigncoFormGroup;
    callback: (res: IAttachment) => void;

    @ViewChild(PhotoInputComponent, { static: false }) photoInput: PhotoInputComponent;

    private attachmentTypePhotoId: number;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly attachmentApi: AttachmentApi,
        private readonly attachmentTypeApi: AttachmentTypeApi) {
        super();
    }

    ngOnInit(): void {
        const photoSp = new SearchParameters();
        photoSp.filter = [{ field: "code", value: "Foto", operator: FilterOperator.equals }];

        this.attachmentTypeApi.search$(photoSp).subscribe(result => {
            this.attachmentTypePhotoId = result.data && result.data.length > 0 ? result.data[0].id : undefined;
        });
    }

    create(parkingBan: IParkingBan, callback?: (res: IAttachment) => void) {
        this.callback = callback;
        this.existingAttachment = null;
        this.parkingBan = parkingBan;
        this.openDialog();
    }

    edit(existingAttachment: IAttachment, parkingBanId: number, callback?: (res: IAttachment) => void) {
        this.parkingBanId = parkingBanId;
        this.callback = callback;
        this.existingAttachment = existingAttachment;
        this.openDialog();
    }

    protected async onOpen() {
        this.manageAttachmentForm = this.formBuilder.group({
            description: [""]
        }) as SigncoFormGroup;

        if (this.existingAttachment) {
            this.manageAttachmentForm.patchValue(this.existingAttachment);

            this.photo = JsonUtils.deepClone(this.existingAttachment);
        }

        this.submitting = false;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageAttachmentForm);
        if (!isValid || !this.photoInput.image) return;

        this.submitting = true;

        if (!this.existingAttachment) {
            this.createNew();
        } else {
            this.update();
        }
    }

    private async createNew() {
        const attachment = Object.assign(new AttachmentCreator(), this.manageAttachmentForm.value) as AttachmentCreator;
        attachment.parkingBanId = this.parkingBan.id;
        attachment.name = `parkingBan_${this.parkingBan.id}_${this.photoInput.image.name}`;
        attachment.typeId = this.attachmentTypePhotoId;

        const onSuccess = async (newAttachment: IAttachment) => this.onSuccess(newAttachment);
        this.attachmentApi.upload$(attachment, this.photoInput.image).subscribe(onSuccess, this.onError.bind(this));
    }

    private async update() {
        const attachment = new AttachmentUpdater(this.existingAttachment);
        Object.assign(attachment, this.manageAttachmentForm.value);
        attachment.parkingBanId = this.parkingBanId;
        attachment.name = `parkingBan_${this.parkingBanId}_${this.photoInput.image.name}`;
        attachment.typeId = this.attachmentTypePhotoId;

        const onSuccess = async (newAttachment: IAttachment) => this.onSuccess(newAttachment);
        if (this.photoInput.image instanceof DownloadedFile) {
            this.attachmentApi.updateWithFile$(attachment, (<DownloadedFile>(this.photoInput.image)).file).subscribe(onSuccess, this.onError.bind(this));
        } else {
            this.attachmentApi.updateWithFile$(attachment, this.photoInput.image).subscribe(onSuccess, this.onError.bind(this));
        }
    }

    private async onSuccess(savedAttachment: IAttachment) {
        if (this.existingAttachment) {
            Object.assign(this.existingAttachment, savedAttachment);
        }

        if (this.callback) {
            this.callback(savedAttachment);
        }
        this.submitting = false;
        this.close();
    }

    private onError() {
        this.submitting = false;
    }
}
