import { ApiTableComponent, TableColumn, TableService, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ManageDatePeriodSetDialogComponent } from "../manage-date-period-set-dialog/manage-date-period-set.dialog";
import { TranslateService } from "@ngx-translate/core";
import { DatePeriodSetApi } from "src/app/resource/date-period-set.api";
import { IDatePeriodSet } from "src/app/models/date-period-set";
import { ModalService } from "src/app/services/modal.service";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-date-period-sets",
    templateUrl: "./date-period-sets.component.html"
})
export class DatePeriodSetsComponent extends ApiTableComponent<IDatePeriodSet> implements OnInit {
    @ViewChild(ManageDatePeriodSetDialogComponent, { static: true }) manageDatePeriodSetDialog: ManageDatePeriodSetDialogComponent;

    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly datePeriodSetApi: DatePeriodSetApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService) {

        super("date-period-sets.component", elementRef, datePeriodSetApi, tableService);

        this.stretchHeight = true;

        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("name", "general.name"));

        this.registerCommand({
            text: "datePeriodSet.edit",
            icon: "edit",
            click: (datePeriodSet) => this.edit(datePeriodSet),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.edit",
            icon: "delete",
            click: (datePeriodSet) => this.delete(datePeriodSet),
            validFunc: () => this.deleteCommand
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    create() {
        const onCreate = () => {
            this.reload();
        };

        this.manageDatePeriodSetDialog.create(onCreate);
    }

    edit(datePeriodSet: IDatePeriodSet) {
        this.manageDatePeriodSetDialog.edit(datePeriodSet, () => this.reload());
    }

    delete(datePeriodSet: IDatePeriodSet) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.datePeriodSetApi.delete$(datePeriodSet.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("datePeriodSet.deleteConfirmation", { name: datePeriodSet.name });
        this.modalService.delete(modalBody, onDelete);
    }
}