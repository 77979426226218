import { ICoordinate } from "./location";

export class LocationKmlCreator {
    prefix?: string;
    ownerId?: number;
    projectIds?: number[];
    drivingLaneId?: number;
    analysisTypeId?: string;
}

export class LocationKmlResult {
    locationCode: string;
    measuringPointCode: string;
    coordinate: ICoordinate;
    isCreated: boolean;
    failureMessage: string;
}