import { Component, ElementRef, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { SigncoFormGroup } from "src/app/models/form";
import { ISafetyQuestion, SafetyQuestionCreator } from "src/app/models/safety-question";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SafetyQuestionApi } from "src/app/resource/safety-question.api";
import { FormValidationService } from "src/app/services/form-validation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { MapDataService } from "src/app/services/map-data.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-manage-safety-question-dialog",
    templateUrl: "./manage-safety-question-dialog.component.html"
})
export class ManageSafetyQuestionDialogComponent extends DialogComponentBase implements OnDestroy {
    callback: (res: ISafetyQuestion) => void;
    submitting: boolean;
    existingSafetyQuestion: ISafetyQuestion;
    safetyQuestionForm: SigncoFormGroup;

    owners: SelectItem[];
    hasMultipleOrganizations: boolean;

    calendarSettings: CalendarSettings;
    private readonly mapDataKey: string;

    minDateValue: Date;

    constructor(
        elementRef: ElementRef,
        private readonly mapDataService: MapDataService,
        private readonly safetyQuestionApi: SafetyQuestionApi,
        private readonly toastService: ToastService,
        private readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        readonly primeComponentService: PrimeComponentService,
        readonly globalEventsService: GlobalEventsService) {
        super();

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe((calendarSettings) => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.minDateValue = new Date();

        this.mapDataKey = this.mapDataService.createKey();
        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.owners = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    create(callback?: (res: ISafetyQuestion) => void) {
        this.callback = callback;
        this.existingSafetyQuestion = {} as ISafetyQuestion;

        this.openDialog();
    }

    edit(existingSafetyQuestion: ISafetyQuestion, callback?: (res: ISafetyQuestion) => void) {
        this.callback = callback;
        this.existingSafetyQuestion = existingSafetyQuestion;

        this.openDialog();
    }

    protected onOpen() {
        this.initialize();
    }

    protected onClose() {
        this.safetyQuestionForm = null;
    }

    private async initialize() {
        this.safetyQuestionForm = this.formBuilder.group({
            question: ["", Validators.required],
            validFrom: null,
            validTo: null,
            ownerId: [""]
        }) as SigncoFormGroup;

        this.safetyQuestionForm.patchValue(this.existingSafetyQuestion);
        this.setOrganization(this.existingSafetyQuestion);
        this.safetyQuestionForm.markAsPristine();

        this.hasMultipleOrganizations = this.globalEventsService.hasMultipleOrganizations();
        if (this.hasMultipleOrganizations) {
            this.safetyQuestionForm.controls.ownerId.setValidators(Validators.required);
        }
    }

    private setOrganization(safetyQuestion: ISafetyQuestion) {
        const value = safetyQuestion && safetyQuestion.owner ? +safetyQuestion.owner.id : undefined;
        this.safetyQuestionForm.controls["ownerId"].patchValue(value);
    }

    isCreatingNew(): boolean {
        return this.existingSafetyQuestion && !this.existingSafetyQuestion.id; // check if id is falsy
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.safetyQuestionForm);

        if (!isValid) return;

        if (this.isCreatingNew()) {
            this.createNewSafetyQuestion();
        } else {
            this.saveSafetyQuestion();
        }
    }

    private createNewSafetyQuestion() {
        const onSuccess = async (newWorker: ISafetyQuestion) => this.onSaveSuccess(newWorker);
        const safetyQuestionCreator = Object.assign(new SafetyQuestionCreator(), this.safetyQuestionForm.value) as SafetyQuestionCreator;

        this.submitting = true;
        this.safetyQuestionApi
            .create$(safetyQuestionCreator)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    private saveSafetyQuestion() {
        const onSuccess = async (savedWorker: ISafetyQuestion) => this.onSaveSuccess(savedWorker);
        const safetyQuestionUpdater = Object.assign({}, this.existingSafetyQuestion, this.safetyQuestionForm.value);
        safetyQuestionUpdater.id = this.existingSafetyQuestion.id;

        this.submitting = true;
        this.safetyQuestionApi
            .update$(safetyQuestionUpdater)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    async onSaveSuccess(savedSafetyQuestion: ISafetyQuestion) {
        this.existingSafetyQuestion = savedSafetyQuestion;
        this.safetyQuestionForm.markAsPristine();
        this.toastService.saveSuccess();
        this.submitting = false;
        this.close();
        if (this.callback) {
            this.callback(savedSafetyQuestion);
        }
    }

    async onSaveError() {
        this.submitting = false;
    }
}
