<form *ngIf="locationForm"
      class="m-border-top m-layout-area-body p-16"
      [formGroup]="locationForm"
      autocomplete="off"
      novalidate>

    <div style="display: grid; gap: 16px"
         [style]="onlyShowMap ? 'grid-template-columns: 1fr' : 'grid-template-columns: 1fr 1fr'">
        @if (!onlyShowMap) {
            <div>
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': locationForm.get('code').showErrors }">

                    <label class="col-4"
                           for="code">
                        {{ "manageMeasuringPoint.locationCode" | translate }}
                    </label>

                    <div class="col-8">
                        <input type="text"
                               id="code"
                               class="p-inputtext"
                               placeholder="{{'manageMeasuringPoint.locationCode' | translate}}"
                               formControlName="code"
                               [readonly]="readonlyCode"
                               #locationCodeInput/>

                        <app-form-validation [control]="locationForm.get('code')"
                                             [input]="locationCodeInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': locationForm.get('description').showErrors }">

                    <label class="col-4"
                           for="description">
                        {{ "manageMeasuringPoint.locationDescription" | translate }}
                    </label>

                    <div class="col-8">
                    <textarea rows="2"
                              id="description"
                              class="p-inputtextarea"
                              placeholder="{{'manageMeasuringPoint.locationDescription' | translate}}"
                              formControlName="description"
                              #locationDescriptionInput></textarea>

                        <app-form-validation [control]="locationForm.get('description')"
                                             [input]="locationDescriptionInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="locationForm.get('ownerId')"
                     [ngClass]="{ 'has-error': locationForm.get('ownerId').showErrors }">

                    <label class="col-4"
                           for="ownerId">
                        {{ "general.organization" | translate }}
                    </label>

                    <div class="col-8">
                        <p-dropdown formControlName="ownerId"
                                    id="ownerId"
                                    [options]="organizations"
                                    [filter]="true"
                                    [panelStyle]="{'min-width':'100%'}"
                                    [resetFilterOnHide]="true"
                                    [autoDisplayFirst]="false"
                                    (change)="ownerIdChanged()"
                                    appendTo="body"
                                    placeholder="{{'general.organization' | translate}}"
                                    #ownerIdInput>
                        </p-dropdown>

                        <app-form-validation [control]="locationForm.get('ownerId')"
                                             [input]="ownerIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': locationForm.get('datePeriodSetId')?.showErrors }">
                    <label class="col-4"
                           for="datePeriodSetId">
                        {{ "organizations.datePeriodSets" | translate }}
                    </label>

                    <div class="col-8">
                        <p-dropdown id="datePeriodSetId"
                                    formControlName="datePeriodSetId"
                                    [options]="datePeriodSets"
                                    [panelStyle]="{'min-width':'100%'}"
                                    [autoDisplayFirst]="false"
                                    [showClear]="false"
                                    appendTo="body"
                                    placeholder="{{'manageOrganization.selectDatePeriodSet' | translate }}"
                                    #datePeriodSetIdInput>

                            <ng-template pTemplate="selectedItem">
                                <ng-template #defaultSelectedItem>
                                    {{ selectedPeriodSetName }}
                                </ng-template>

                                <ng-container
                                    *ngIf="selectedPeriodSetId === defaultPeriodSetId; else defaultSelectedItem">
                                    {{ selectedPeriodSetName }} ({{ 'general.default' | translate }})
                                </ng-container>
                            </ng-template>

                            <ng-template let-datePeriodSet
                                         pTemplate="item">
                                <ng-template #defaultItem>
                                    {{ datePeriodSet.label }}
                                </ng-template>

                                <ng-container *ngIf="datePeriodSet.value === defaultPeriodSetId; else defaultItem">
                                    {{ datePeriodSet.label }} ({{ 'general.default' | translate }})
                                </ng-container>
                            </ng-template>

                        </p-dropdown>

                        <app-form-validation [control]="locationForm.get('datePeriodSetId')"
                                             [input]="datePeriodSetIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="locationForm.get('timeZone')"
                     [ngClass]="{ 'has-error': locationForm.get('timeZone').showErrors }">

                    <label class="col-4"
                           for="timeZone">
                        {{ "calendar.timeZone" | translate }}
                    </label>

                    <div class="col-8">
                        <p-dropdown formControlName="timeZone"
                                    id="timeZone"
                                    [options]="timeZones"
                                    [filter]="true"
                                    [panelStyle]="{'min-width':'100%'}"
                                    [resetFilterOnHide]="true"
                                    [autoDisplayFirst]="false"
                                    appendTo="body"
                                    placeholder="{{'calendar.timeZone' | translate}}"
                                    #timeZoneInput>
                        </p-dropdown>

                        <app-form-validation [control]="locationForm.get('timeZone')"
                                             [input]="timeZoneInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': locationForm.get('mapIconId')?.showErrors }">
                    <label class="col-4"
                           for="mapIconId"
                           style="white-space: nowrap; display:flex; align-items: center; gap:4px;">
                        {{ "manageLocation.icon" | translate }}
                        <app-svg-icon icon="info"
                                      [pTooltip]="'manageLocation.iconMessage' | translate"
                                      tooltipPosition="bottom"></app-svg-icon>
                    </label>

                    <div class="col-8">
                        <p-dropdown id="mapIconId"
                                    formControlName="mapIconId"
                                    [options]="mapIcons"
                                    [panelStyle]="{'min-width':'100%'}"
                                    [autoDisplayFirst]="false"
                                    [showClear]="true"
                                    [filter]="true"
                                    filterBy="code"
                                    optionValue="id"
                                    optionLabel="code"
                                    appendTo="body"
                                    #mapIconIdInput>
                            <ng-template let-mapIcon
                                         pTemplate="selectedItem">
                                <div class="flex"
                                     style="gap:16px; align-items: center">
                                    <div style="width:20px; display:flex; justify-content: center;">
                                        <img [src]="mapIcon?.states[0].default.url"/>
                                    </div>
                                    {{ mapIcon?.code }}
                                </div>
                            </ng-template>
                            <ng-template let-mapIcon
                                         pTemplate="item">
                                <div class="flex"
                                     style="gap:16px; align-items: center;">
                                    <div style="width:20px; display:flex; justify-content: center;">
                                        <img [src]="mapIcon?.states[0].default.url"/>
                                    </div>
                                    {{ mapIcon?.code }}
                                </div>
                            </ng-template>
                        </p-dropdown>

                        <app-form-validation [control]="locationForm.get('mapIconId')"
                                             [input]="mapIconIdInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        }
        <div class="m-form-group"
             style="display:grid; gap:8px;">
            <div>
                <label style="display:flex; align-items:center;">
                    <input type="checkbox"
                           formControlName="isLinkedToGps"/>
                    {{ "manageLocation.linkToGps" | translate }}
                </label>
                <em *ngIf="locationForm.get('isLinkedToGps')?.value">{{ "manageLocation.linkToGpsMessage" | translate }}</em>
            </div>

            <app-location-form [parentFormGroup]="locationForm"
                               [coordinate]="initialCoordinate"
                               [location]="existingLocation"
                               [typeId]="typeId"
                               [markerLabel]="locationForm.get('code').value"
                               [isDisabled]="readonly"
                               (timeZone)="onTimeZoneChanged($event)"></app-location-form>
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right py-8 px-16 m-border-top m-layout-area-bottom"
     *ngIf="!readonly">
    <button class="m-btn m-btn-link"
            [disabled]="submitting"
            (click)="cancel.next()"
            type="button"
            id="locationFormCancelButton">
        {{ "form.cancel" | translate }}
    </button>

    <button class="m-btn m-btn-primary"
            [disabled]="submitting"
            (click)="submit()"
            type="submit"
            id="locationFormSubmitButton">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{ "form.save" | translate }}
    </button>
</div>
