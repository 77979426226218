import { LazyTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, FilterDescriptor, ServiceRequestOptions } from "src/app/models/search";
import { Component, OnChanges, SimpleChanges, Input, ElementRef } from "@angular/core";
import { IDeviceLink, IDevice } from "src/app/models/device";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { DeviceLinkApi } from "src/app/resource/device-link.api";
import { ModalService } from "src/app/services/modal.service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-links",
    templateUrl: "./links.component.html"
})
export class LinksComponent extends LazyTableComponent<IDeviceLink> implements OnChanges {
    @Input() device: IDevice;
    @Input() measuringPoint: IMeasuringPoint;
    @Input() allowWithoutInput: boolean;
    @Input() detailsCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    deviceColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translateService: TranslateService,
        private readonly modalService: ModalService,
        private readonly deviceLinkApi: DeviceLinkApi,
        private readonly navigationService: NavigationService,
        private readonly globalEventsService: GlobalEventsService) {

        super("links.component", elementRef, deviceLinkApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;
        this.footer = false;
        this.selectionMode = "";

        this.addColumn(new TableColumn("validFrom", "general.from", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("validUntil", "general.until", { filterType: FilterType.Date, sortable: true }));

        this.deviceColumn = new TableColumn("device", "general.device", { filterType: FilterType.None, sortable: true });
        this.addColumn(this.deviceColumn);

        this.addColumn(new TableColumn("measurements", "general.measuringPoints", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));

        const populateDeviceLink = async (deviceLink: IDeviceLink) => {
            if (this.device) {
                deviceLink.device = this.device;
            }
        };

        if (this.rights.hasBackendRight(BackendRights.EditDeviceLink)) {
            this.registerCommand({
                text: "form.edit",
                icon: "edit",
                click: async (deviceLink) => {
                    await populateDeviceLink(deviceLink);
                    this.navigationService.editLink(deviceLink.id, this.measuringPoint, this.device);
                },
                validFunc: () => this.editCommand,
                rowValidFunc: (deviceLink) => !deviceLink.gapfiller
            });


            this.registerCommand({
                text: "form.delete",
                icon: "delete",
                click: (deviceLink) => this.delete(deviceLink),
                validFunc: () => this.deleteCommand,
                rowValidFunc: (deviceLink) => !deviceLink.gapfiller
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        super.ngOnChanges(changes);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.device = null;
        this.deviceColumn.hidden = false;

        this.measuringPoint = measuringPoint;

        this.updateRelevantColumns();

        this.loadTableRows();
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.deviceColumn.hidden = true;

        this.measuringPoint = null;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        return options;
    }

    canLoad(): boolean {
        return this.allowWithoutInput || !!this.device || !!this.measuringPoint;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        if (this.measuringPoint) {
            searchParameters.filter = [new FilterDescriptor("MeasuringPoint", this.measuringPoint.id)];
        }

        if (this.device) {
            searchParameters.filter = [new FilterDescriptor("Device", this.device.id)];
        }

        return searchParameters;
    }

    delete(deviceLink: IDeviceLink) {
        const onDelete = () => {
            const onSuccess = () => {
                this.reload(true);
            };

            const onError = () => { };

            this.deviceLinkApi.delete$(deviceLink.id).subscribe(onSuccess, onError);
        };

        const modalBody = this.translateService.instant("links.deleteConfirmation", deviceLink);
        this.modalService.delete(modalBody, onDelete);
    }
}