import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IMapIcon, MapIconActivityState } from "src/app/models/map-icon";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { FilterType, ModelLocalizationTable, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { MapIconApi } from "src/app/resource/map-icon.api";
import { DomainDataService } from "src/app/services/domain-data.service";
import { ModalService } from "src/app/services/modal.service";
import { ManageMapIconDialogComponent } from "../manage-map-icon-dialog/manage-map-icon.dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-map-icons",
    templateUrl: "./map-icons.component.html",
})
export class MapIconsComponent extends ModelLocalizationTable<IMapIcon> implements OnInit {
    @ViewChild(ManageMapIconDialogComponent, { static: true }) manageMapIconDialog: ManageMapIconDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;
    MapIconActivityState = MapIconActivityState;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        translate: TranslateService,
        domainDataService: DomainDataService,
        readonly globalEventsService: GlobalEventsService,
        private readonly mapIconApi: MapIconApi,
        private readonly modalService: ModalService
    ) {
        super("map-icons.component", elementRef, mapIconApi, tableService, domainDataService, translate);

        this.stretchHeight = true;

        this.selectionMode = "";
        this.includeObsolete = true;
        const rights = this.globalEventsService.getCurrentRights();
        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, stringResourcePropertyName: "codeStringResourceId" }));
        this.addColumn(new TableColumn("preview", "general.preview", { filterType: FilterType.None, sortable: false, width: 200 }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "mapIcons.edit",
            icon: "edit",
            click: (mapIcon) => this.edit(mapIcon),
            validFunc: () => this.editCommand && rights.hasBackendRight(BackendRights.EditMeasuringPoint)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (mapIcon) => this.delete(mapIcon),
            validFunc: () => this.deleteCommand && rights.hasBackendRight(BackendRights.DeleteMeasuringPoint),
            rowValidFunc: (mapIcon) => !mapIcon.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (mapIcon) => this.restore(mapIcon),
            validFunc: () => this.deleteCommand && rights.hasBackendRight(BackendRights.DeleteMeasuringPoint),
            rowValidFunc: (mapIcon) => mapIcon.isObsolete,
            ignoreSpace: true
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        // we're showing only custom icons
        searchParameters.filter = [new FilterDescriptor("mapIconTypeId", "customIcon", FilterOperator.equals)];

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("mapIcon", "states");
        return serviceRequestOptions;
    }

    restore(mapIcon: IMapIcon) {
        const onRestoreSuccess = () => {
            mapIcon.isObsolete = false;
        };

        this.mapIconApi.restore$(mapIcon.id).subscribe(onRestoreSuccess);
    }

    create() {
        this.manageMapIconDialog.create(() => this.reload());
    }

    edit(mapIcon: IMapIcon) {
        this.manageMapIconDialog.edit(mapIcon, () => this.reload());
    }

    async delete(mapIcon: IMapIcon) {
        const onDeleteSuccess = () => {
            mapIcon.isObsolete = true;
        };

        const onDelete = () => {
            this.mapIconApi.delete$(mapIcon.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translateService.instant("mapIcons.deleteConfirmation", { code: this.domainDataService.translate(mapIcon.codeStringResourceId) });
        this.modalService.delete(modalBody, onDelete);
    }
}
