import { TableColumn, FilterType, ColumnType, ColumnVisibility, TableService, LazyTableComponent } from "../table/table.component";
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { DomainDataService, DomainData, ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { AlarmSeverity, IAlarm, IRule } from "src/app/models/alarm";
import { DeviceType, IDeviceSummary } from "src/app/models/device";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DeviceWebApi } from "src/app/resource/web";
import { ToastService } from "src/app/services/toast.service";
import { ModalService } from "src/app/services/modal.service";
import { CameraApi } from "src/app/resource/camera.api";
import { DeviceApi } from "src/app/resource/device.api";
import { RuleApi } from "src/app/resource/rule.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-devices",
    templateUrl: "./devices.component.html"
})
export class DevicesComponent extends LazyTableComponent<IDeviceSummary> implements OnDestroy, OnChanges, IComponentCanDeactivate {
    @Input() locationId: number;
    @Input() requiresInput = false;
    @Input() navigation = false;
    @Input() editCommand = false;
    @Input() deleteCommand = false;
    @Input() ownerId: number;
    @Input() typeId: number;
    @Input() showImageColumn = false;

    private rules: IRule[];
    private deviceTypes: ViewModelEnum[];

    private readonly alarmLevelColumn: TableColumn;
    private readonly imageColumn: TableColumn;
    private readonly typeColumn: TableColumn;
    private previousRowCount: number;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        deviceWebApi: DeviceWebApi,
        private readonly globalEventsService: GlobalEventsService,
        private readonly domainDataService: DomainDataService,
        private readonly modalService: ModalService,
        private readonly deviceApi: DeviceApi,
        private readonly ruleApi: RuleApi,
        private readonly translate: TranslateService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly navigationService: NavigationService,
        private readonly cameraApi: CameraApi,
        private readonly toastService: ToastService) {

        super("devices.component", elementRef, deviceWebApi, tableService);
        this.stretchHeight = false;

        const ruleSearchParameters = new SearchParameters();
        ruleSearchParameters.take = 9999;
        this.ruleApi.search$(ruleSearchParameters).subscribe(rules => {
            this.rules = rules.data;
        });

        this.typeColumn = new TableColumn("typeId", "", { filterType: FilterType.MultiSelect, sortable: true, type: ColumnType.Icon });
        this.addColumn(this.typeColumn);
        this.domainDataService.get(DomainData.DeviceType).then(deviceTypes => {
            this.typeColumn.filterOptions = deviceTypes;
            this.deviceTypes = deviceTypes;
        });

        const codeColumn = new TableColumn("code", "devices.code", { filterType: FilterType.Text, sortable: true });
        this.addColumn(codeColumn);
        this.setMainColumn(codeColumn);

        this.alarmLevelColumn = new TableColumn("alarmLevel", "deviceAlarms.title", { filterType: FilterType.MultiSelect, sortable: false, width: 175, resizable: false });
        this.alarmLevelColumn.filterMatchMode = "listContains";
        this.addColumn(this.alarmLevelColumn);

        const enumOptions = new ViewModelEnumOptions();
        enumOptions.includeEmpty = true;
        this.domainDataService.get(DomainData.AlarmSeverity, enumOptions).then(alarmSeverities => {
            this.alarmLevelColumn.filterOptions = alarmSeverities;
        });

        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("manufacturer", "devices.manufacturer", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact, width: 170, resizable: false }));

        if (this.rights?.hasBackendRight(BackendRights.EditDevice) && this.globalEventsService.hasMultipleOrganizations()) {
            const organizationColumn = new TableColumn("ownerId", "general.organization", { filterType: FilterType.Dropdown, sortable: true, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, displayDropdownFilter: true });
            this.addColumn(organizationColumn);

            // Load Organizations for drop-down filter
            this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
                organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                    organizations,
                    (x) => x.id,
                    (x) => x.name,
                    false
                );
            });
        }

        this.addColumn(new TableColumn("measuringPoints", "general.measuringPoints", { filterType: FilterType.Text, visibility: ColumnVisibility.HideCompact }));

        this.imageColumn = new TableColumn("image", "", { filterType: FilterType.None, sortable: false, type: ColumnType.Minimal, width: 80 });

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (device) => this.navigateToDetail(device),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.CreateDevice)
        });

        this.registerCommand({
            text: "form.view",
            icon: "eye-solid",
            click: (device) => this.navigateToDetail(device),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.ViewDevice) && !this.rights?.hasBackendRight(BackendRights.CreateDevice)
        });
        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (device) => this.delete(device),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteDevice)
        });

        this.handleImageColumn();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const locationIdChange = changes["locationId"];
        if (locationIdChange) {
            this.updateRowCount();
        }

        const inputChange = locationIdChange || changes["requiresInput"] || changes["ownerId"];
        if (inputChange) {
            this.updateRowCount();
            this.reload();
        }

        const miniChange = changes["mini"];
        const compactChange = changes["compact"];
        if (miniChange || compactChange) {
            this.updateVariableColumnWidths();
        }

        const showImageColumnChange = changes["showImageColumn"];
        if (showImageColumnChange) {
            this.handleImageColumn();
        }

        const typeIdChange = changes["typeId"];
        if (typeIdChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    async processLoadedData(data: IDeviceSummary[]): Promise<IDeviceSummary[]> {
        if (this.rules) {
            const pushAlarm = (deviceSummary: IDeviceSummary, ruleId: number, severityId: string) => {
                const rule = this.rules.find(x => x.id === ruleId);

                deviceSummary.alarms.push({
                    severityId: severityId,
                    rule: rule
                } as IAlarm);
            };

            for (const deviceSummary of data) {
                deviceSummary.alarms = [];

                if (deviceSummary.alarmsHigh) {
                    for (const alarmHighRuleId of deviceSummary.alarmsHigh) {
                        pushAlarm(deviceSummary, alarmHighRuleId, "high");
                    }
                }

                if (deviceSummary.alarmsLow) {
                    for (const alarmLowRuleId of deviceSummary.alarmsLow) {
                        pushAlarm(deviceSummary, alarmLowRuleId, "low");
                    }
                }
            }
        }

        return data;
    }

    alarmTrackByFn(index: number, item: IAlarm) {
        return item.rule.id;
    }

    private updateRowCount() {
        if (this.locationId) {
            this.previousRowCount = this.internalRowCount;
            this.setRowCount(9999);
        } else {
            if (this.previousRowCount) {
                this.setRowCount(this.previousRowCount);
            }
        }
    }

    private handleImageColumn() {
        // TODO: Image column disabled, re-enable in future (needs back-end data)
        if (false && this.showImageColumn && this.data && this.data.find(x => x.typeId === DeviceType.TrafficFleet || x.typeId === DeviceType.Camera)) {
            const index = this.columns.indexOf(this.alarmLevelColumn);
            this.addColumn(this.imageColumn, index > 0 ? index : null);
        } else {
            this.removeColumn(this.imageColumn);
        }
    }

    updateVariableColumnWidths() {
        const typeWidth = this.filter && this.headers ? 80 : 50;
        this.typeColumn.setWidth(typeWidth);

        const alarmWidth = !this.mini && !this.compact ? 175 : 50;
        this.alarmLevelColumn.setWidth(alarmWidth);
    }

    private navigateToDetail(device: IDeviceSummary) {
        this.navigationService.toDevice(device.id);
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.locationId;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.locationId) {
            searchParameters.filter.push(new FilterDescriptor("locationId", this.locationId));
        }

        if (this.ownerId) {
            searchParameters.filter.push(new FilterDescriptor("ownerId", this.ownerId));
        }

        if (this.typeId) {
            searchParameters.filter.push(new FilterDescriptor("typeId", this.typeId));
        }

        return searchParameters.filter.length ? searchParameters : null;
    }

    delete(device: IDeviceSummary) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteDevice)) return;

        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.deviceApi.delete$(device.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("devices.deleteConfirmation", { code: device.code });
        this.modalService.delete(modalBody, onDelete);
    }

    getDeviceTextColor(device: IDeviceSummary): string {
        if (device.alarmsHigh && device.alarmsHigh.length) return "red";
        if (device.alarmsLow && device.alarmsLow.length) return "orange";

        return "green";
    }

    getAlarmTextColor(alarm: IAlarm): string {
        if (alarm.severityId === AlarmSeverity.High) return "red";
        if (alarm.severityId === AlarmSeverity.Low) return "orange";

        return "black";
    }

    getDeviceTypeTranslation(deviceType: string): string {
        if (!this.deviceTypes) return null;

        return this.deviceTypes.find(x => x.value === deviceType).label;
    }
}