import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, OnDestroy, ElementRef } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager, DurationUtils } from "src/app/utilities";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-realtime-analyzer-configuration",
    templateUrl: "./device-realtime-analyzer-configuration.component.html"
})
export class DeviceRealtimeAnalyzerConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard, OnDestroy {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;

    realtimeAnalyzerConfigurationForm: SigncoFormGroup;

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.realtimeAnalyzerConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        const midnight = new Date().toMidnight();
        this.realtimeAnalyzerConfigurationForm = this.formBuilder.group({
            maxWindowTime: [1000, Validators.required],
            minTimeBetweenWheelies: [new Date(midnight), Validators.required],
            simulateVehicleOnEveryPulse: false,
        }) as SigncoFormGroup;

        if (this.device.realtimeAnalyzerConfiguration) {
            this.realtimeAnalyzerConfigurationForm.patchValue(this.device.realtimeAnalyzerConfiguration);

            this.realtimeAnalyzerConfigurationForm.patchValue({
                maxWindowTime: DurationUtils.toMilliseconds(this.device.realtimeAnalyzerConfiguration.maxWindowTime),
                minTimeBetweenWheelies: DurationUtils.toMilliseconds(this.device.realtimeAnalyzerConfiguration.minTimeBetweenWheelies)
            });
        }

        this.realtimeAnalyzerConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.realtimeAnalyzerConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // User input milliseconds, but we convert it to a Duration (= TimeSpan)
        const numberFormToTimeSpan = (formKey: string): string => {
            const numberValue = this.realtimeAnalyzerConfigurationForm.get(formKey).value as number;
            const duration = DurationUtils.fromMilliseconds(numberValue);
            return DurationUtils.toString(duration);
        };

        const deviceUpdater = new DeviceUpdater(this.device);
        Object.assign(
            deviceUpdater.realtimeAnalyzerConfiguration,
            this.realtimeAnalyzerConfigurationForm.value,
            {
                maxWindowTime: numberFormToTimeSpan("maxWindowTime"),
                minTimeBetweenWheelies: numberFormToTimeSpan("minTimeBetweenWheelies")
            }
        );

        this.submitting = true;

        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}