import { Component, Input, OnChanges, ElementRef } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-svg-icon",
    templateUrl: "./svg-icon.component.html"
})
export class SvgIconComponent implements OnChanges {
    @Input() file?: string;
    @Input() icon?: string;
    @Input() fill?: string;
    @Input() ngClass?: string;
    @Input() ngStyle?: any;
    @Input() divClass?: string;
    @Input() divStyle?: any;
    @Input() tooltip?: string;
    @Input() tooltipDisabled = false;
    @Input() tooltipPosition: "left" | "top" | "right" | "bottom" = "top";

    href: string;
    style: any;

    constructor(elementRef: ElementRef<HTMLElement>) {
        // elementRef.nativeElement.className = "icon";

        this.setHref();
        this.setFill();
    }

    ngOnChanges() {
        this.setHref();
        this.setFill();
    }

    setHref() {
        const version = environment.svgVersion;
        const file = this.file || `assets/icons.svg?v=${version}`;
        let href = `${file}`;
        if (this.icon) {
            href += `#${this.icon}`;
        }

        this.href = href;
    }

    private setFill() {
        if (!this.fill) {
            this.style = this.ngStyle;
            return;
        }

        this.style = Object.assign({}, this.ngStyle);
        this.style.fill = this.fill;
    }
}