import { UntypedFormBuilder } from "@angular/forms";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { Component, inject } from "@angular/core";
import { RetroTrafficLightDataAvsApi } from "src/app/resource/avs/retro-traffic-light-data-avs.api";
import { TrafficLightDataAvsApi } from "src/app/resource/avs/traffic-light-data-avs.api";
import { TrackerDataAvsApi } from "src/app/resource/avs/tracker-data-avs.api";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { ToastService } from "src/app/services/toast.service";
import { RetroTrafficLightData } from "src/app/models/avs/retro-traffic-light-data";
import { TrafficLightData } from "src/app/models/avs/traffic-light-data";
import { TrackerData } from "src/app/models/avs/tracker-data";

@Component({
    selector: "app-generate-device-status-dialog",
    templateUrl: "./generate-device-status.dialog.html"
})
export class GenerateDeviceStatusDialogComponent extends DialogComponentBase {
    submitting: boolean;
    generateDeviceStatusForm: SigncoFormGroup;

    deviceTypes = ["Retro Traffic Light", "MPB5000", "Generic Teltonika"];
    statuses = [
        "allRed", "automaticMode", "batteryWarning", "connectionGoneNoPower", "darkOff", "emergencyQuartzMode",
        "flashing", "generalFailureBlinkingModeActive", "green", "greenBlinking", "greenDefect", "greenGreen",
        "intervalFailure", "k1MasterDeviceRole", "k1MasterErrorSource", "k2SlaveDeviceRole", "k2SlaveErrorSource", "k3SlaveDeviceRole",
        "k3SlaveErrorSource", "k4SlaveDeviceRole", "k4SlaveErrorSource", "nA", "orange", "quartzMode", "quittingAfterReboot",
        "quittingBatteryWarning", "quittingOtherFailures", "red", "redOrange", "redDefect", "redLightDefect",
        "statusGreen", "statusYellow", "statusRed", "transmissionFailure", "yellowDefect", "impulseError", "noImpulseDetected"
    ];
    roles = ["none", "master", "slave"];
    heads = ["k1", "k2", "k3", "k4"];
    radarDetectors = ["none", "orange", "green", "blue"];

    arrowBoardDirections = ["left", "right", "cross"];
    arrowBoardPositions = ["open", "closed"];
    selectedDeviceType: string;

    private readonly formBuilder = inject(UntypedFormBuilder);
    private readonly retroTrafficLightDataAvsApi = inject(RetroTrafficLightDataAvsApi);
    private readonly trafficLightDataAvsApi = inject(TrafficLightDataAvsApi);
    private readonly trackerDataAvsApi = inject(TrackerDataAvsApi);
    private readonly toastService = inject(ToastService);

    constructor() {

        super();
    }

    open() {
        this.generateDeviceStatusForm = this.formBuilder.group({
            deviceType: [null],
            imei: [null],
            serialNo: [null],
            latitude: [null],
            longitude: [null],
            batteryVoltage: [null],
            status: [null],
            head: [null],
            headId: [null],
            role: [null],
            radarDetector: [null],
            impact: [null],
            rutSerial: [null],
            deviceUid: [null],
            simInformation: this.formBuilder.group({
                provider: ["Eplus"],
                connState: ["Connected"],
                connectionType: ["LTE"],
                revision: ["EC25EUXGAR08A05M1G_01.001.01.001"],
                rssi: [-69]
            }),
            arrowBoardStatus: this.formBuilder.group({
                direction: [null],
                position: [null],
            }),


        }) as SigncoFormGroup;

        this.openDialog();
    }


    isMPB5000() {
        return this.generateDeviceStatusForm.get("deviceType").value === "MPB5000";
    }
    isRetroTrafficLight() {
        return this.generateDeviceStatusForm.get("deviceType").value === "Retro Traffic Light";
    }

    isGenericTeltonika() {
        return this.generateDeviceStatusForm.get("deviceType").value === "Generic Teltonika";
    }

    isRetroOrGeneric() {
        return this.generateDeviceStatusForm.get("deviceType").value === "Retro Traffic Light"
            || this.generateDeviceStatusForm.get("deviceType").value === "Generic Teltonika";
    }

    isTrafficLight() {
        return this.generateDeviceStatusForm.get("deviceType").value === "Retro Traffic Light"
            || this.generateDeviceStatusForm.get("deviceType").value === "MPB5000";

    }

    isDeviceTypeSelected() {
        return this.generateDeviceStatusForm.get("deviceType").value !== null;
    }

    protected onOpen() {

        this.submitting = false;
    }

    protected onClose() {
    }

    async submit() {
        this.submitting = true;
        let result: any;
        const onSuccess = () => {
            this.toastService.success("Device status generated successfully");
            this.submitting = false;

        };
        const onError = () => {
            this.toastService.error("Failed to generate device status. Check the data and try again");
            this.submitting = false;

        };

        if (this.isRetroTrafficLight()) {
            const retroTrafficData = Object.assign(
                new RetroTrafficLightData(),
                this.generateDeviceStatusForm.value) as RetroTrafficLightData;
            this.retroTrafficLightDataAvsApi.create$(retroTrafficData).subscribe({ complete: onSuccess, error: onError });
        } else if (this.isTrafficLight()) {
            const trafficData = Object.assign(
                new TrafficLightData(),
                this.generateDeviceStatusForm.value) as TrafficLightData;

            this.trafficLightDataAvsApi.create$(trafficData).subscribe({ complete: onSuccess, error: onError });
        } else if (this.isGenericTeltonika()) {
            const genericData = Object.assign(
                new TrackerData(),
                this.generateDeviceStatusForm.value) as TrackerData;

            this.trackerDataAvsApi.create$(genericData).subscribe({ complete: onSuccess, error: onError });
        }


    }
}