import { TableColumn, FilterType, TableService, ApiTableComponent, ColumnVisibility } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, FilterDescriptor, SortDescriptor, SortDirection } from "src/app/models/search";
import { Component, OnChanges, SimpleChanges, Input, ElementRef } from "@angular/core";
import { IDeviceDisplayEvent, DeviceDisplayEventUpdater } from "src/app/models/device-display-configuration";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { DeviceDisplayEventApi } from "src/app/resource/device-display-event.api";
import { DeviceType, IDevice } from "src/app/models/device";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { CacheService } from "src/app/services/cache.service";
import { ModalService } from "src/app/services/modal.service";
import { DeviceUtils } from "src/app/utilities";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-display-events",
    templateUrl: "./device-display-events.component.html"
})
export class DeviceDisplayEventsComponent extends ApiTableComponent<IDeviceDisplayEvent> implements OnChanges {
    @Input() device?: IDevice;
    @Input() requiresInput = true;
    @Input() createCommand = true;
    @Input() cloneCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;
    @Input() shortcutToManagementScreen = false;
    canCreateDeviceDisplayEvent: boolean;
    canDeleteDeviceDisplayEvent: boolean;
    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translateService: TranslateService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly deviceDisplayEventApi: DeviceDisplayEventApi,
        private readonly navigationService: NavigationService,
        private readonly modalService: ModalService,
        private readonly cacheService: CacheService,
        private readonly domainDataService: DomainDataService) {

        super("device-display-events.component", elementRef, deviceDisplayEventApi, tableService);

        this.canCreateDeviceDisplayEvent = this.rights.hasBackendRight(BackendRights.EditDeviceDisplayEvent);
        this.canDeleteDeviceDisplayEvent = this.rights.hasBackendRight(BackendRights.DeleteDeviceDisplayEvent);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;
        this.footer = false;

        this.selectionMode = "";
        this.stateKey = "device-display-events";

        this.addColumn(new TableColumn("isActive", "deviceDisplayEvents.isActive", { width: 120, resizable: false, filterType: FilterType.Boolean, sortable: true }));
        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("priority", "deviceDisplayEvents.priority", { sortable: true, width: 120, resizable: false }));

        const deviceTypeIdColumn = new TableColumn("deviceTypeId", "general.type", { sortable: true, filterType: FilterType.Dropdown, width: 120, resizable: false });

        this.domainDataService.get(DomainData.DeviceType).then(deviceTypes => {
            deviceTypeIdColumn.filterOptions = deviceTypes.filter(x => DeviceUtils.supportsScenarios(x.value as DeviceType));
        });

        this.addColumn(deviceTypeIdColumn);

        this.addColumn(new TableColumn("action", "deviceDisplayEvents.action", { resizable: false, width: 170 }));
        this.addColumn(new TableColumn("rule", "deviceDisplayEventRules.rule", { resizable: true }));
        this.addColumn(new TableColumn("devices", "general.devices", { width: 150, resizable: false, filterFunc: () => !this.device }));

        const ownerColumn = new TableColumn("owner.name", "general.organization", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 250, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true, filterFunc: () => !this.device });
        this.addColumn(ownerColumn);

        // Load Organizations for drop-down filter
        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            ownerColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.registerCommand({
            text: "general.duplicate",
            icon: "copy",
            click: (event) => this.duplicate(event),
            validFunc: () => this.cloneCommand && this.canCreateDeviceDisplayEvent,
        });

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (event) => this.edit(event),
            validFunc: () => this.editCommand && this.canCreateDeviceDisplayEvent
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (event) => this.delete(event),
            validFunc: () => this.deleteCommand && this.canDeleteDeviceDisplayEvent
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        const inputChange = changes["requiresInput"];
        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        if (this.device) {
            searchParameters.filter = [new FilterDescriptor("device", this.device.id)];
        }

        searchParameters.sort = [new SortDescriptor(SortDirection.descending, "priority")];

        return searchParameters;
    }

    async processLoadedData(data: IDeviceDisplayEvent[]): Promise<IDeviceDisplayEvent[]> {
        for (const scenario of data) {
            scenario.originalPriority = scenario.priority;
            scenario.originalIsActive = scenario.isActive;
        }

        return data;
    }

    create() {
        this.edit(null);
    }

    duplicate(event: IDeviceDisplayEvent) {
        this.navigationService.duplicateDeviceDisplayEvent(event);
    }

    edit(event: IDeviceDisplayEvent) {
        this.navigationService.editDeviceDisplayEvent(event);
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.device;
    }

    save(scenario: IDeviceDisplayEvent) {
        // (onChange) triggers immediately after load, primeNG bug
        if (scenario.originalPriority === scenario.priority && scenario.isActive === scenario.originalIsActive) return;

        const updater = new DeviceDisplayEventUpdater(scenario);

        const onSuccess = () => {
            scenario.originalPriority = updater.priority;
            scenario.originalIsActive = updater.isActive;
            this.cacheService.clearForApi(this.deviceDisplayEventApi);
        };

        const onError = () => { };

        this.deviceDisplayEventApi.update$(updater).subscribe(onSuccess, onError);
    }

    delete(event: IDeviceDisplayEvent) {
        const onDelete = () => {
            // If no device linked, we're simply removing the event
            const onSuccess = () => {
                this.reload(true);
            };

            const onError = () => { };

            this.deviceDisplayEventApi.delete$(event.id).subscribe(onSuccess, onError);
        };

        const modalBody = this.translateService.instant("deviceDisplayEvents.deleteConfirmation", event);
        this.modalService.delete(modalBody, onDelete);
    }
}