import { SessionStorageService, LocalStorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { TranslateService } from "@ngx-translate/core";
import { MapDataService } from "./map-data.service";
import { CacheService } from "./cache.service";
import { Constants } from "src/app/constants/constants";
import { UserApi } from "src/app/resource/user.api";
import { Router } from "@angular/router";
import { filter, first } from "rxjs/operators";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { GlobalEventsService } from "./global-events-service";
import { Observable, firstValueFrom } from "rxjs";
import { WebsiteService } from "./website.service";

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {

    constructor(
        private router: Router,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private readonly translateService: TranslateService,
        private readonly sessionStorageService: SessionStorageService,
        private readonly localStorageService: LocalStorageService,
        private readonly userApi: UserApi,
        private readonly mapDataService: MapDataService,
        private readonly cacheService: CacheService,
        private readonly configurationService: ConfigurationService,
        private readonly websiteService: WebsiteService,
        private readonly globalEventsService: GlobalEventsService) {
    }
    private clearData() {
        this.mapDataService.clear();
        this.sessionStorageService.clear();
        this.cacheService.clear();
    }
    //#region MSAL

    initialize(): void {

        // console.log("initializing msal service");
        this.msalService.initialize().subscribe(() => {
            this.msalBroadcastService.msalSubject$
                .pipe(
                    first((value) =>
                        value.eventType === EventType.LOGIN_SUCCESS ||
                        value.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        value.eventType === EventType.SSO_SILENT_SUCCESS)
                )
                .subscribe((result: EventMessage) => {
                    // console.log("token succeeded!");
                    this.globalEventsService.setIsAuthenticated(true);
                    this.authorize();
                });
            this.msalBroadcastService.msalSubject$
                .pipe(
                    first((value) =>
                        value.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
                )
                .subscribe((result: EventMessage) => {
                    console.log("ACQUIRE_TOKEN_FAILURE!");
                    // How did we end up here?
                    // My guess: user is logged in on signcoserv.be
                    // Goes to dev/test.signcoserv.be
                    // Press login
                    // Service side authenticated, but local refresh token was expired
                    // So, local logout is required - this will clear the local refresh token
                    this.msalService.logoutRedirect({
                        onRedirectNavigate: (url) => {
                            // Return false if you would like to stop navigation after local logout
                            return false;
                        }
                    });
                });
            this.msalBroadcastService.msalSubject$
                .pipe(
                    first((value) =>
                        value.eventType === EventType.SSO_SILENT_FAILURE)
                )
                .subscribe((result: EventMessage) => {
                    if (!this.globalEventsService.getIsAuthenticated()) {
                        this.globalEventsService.setIsAuthenticated(false);
                    }
                });
            this.msalService.ssoSilent({
                scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
                extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() }
            }).subscribe({
                next: (result: AuthenticationResult) => {
                },
                error: (error) => {
                    // console.log(error);
                    // uncomment if we automatically want to navigate to login page if not authenticated yet
                    // this.login();
                }
            });
        });
    }

    authorize() {
        return firstValueFrom(this.userApi.getSelf$())
            .then(user => {
                if (user) {
                    this.globalEventsService.setAuthorizationInfo(user);
                    this.redirectToCallbackRoute();
                }
            })
            .catch(error => {
                const message = JSON.stringify(error);
                console.error(`An error occured while trying to authorize: ${message}`);
                this.signoutSilently();
            });
    }

    acquireTokenSilent$(): Observable<AuthenticationResult> {
        // Sets account as active account or first account
        let account: AccountInfo;
        if (!!this.msalService.instance.getActiveAccount()) {
            this.msalService.getLogger().verbose("Interceptor - active account selected");
            account = this.msalService.instance.getActiveAccount();
        } else {
            this.msalService.getLogger().verbose("Interceptor - no active account, fallback to first account");
            account = this.msalService.instance.getAllAccounts()[0];
        }
        return this.msalService.acquireTokenSilent({
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"]
            , account,
            extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() }
        });
    }

    login() {
        let language = this.translateService.currentLang;
        if (!language) {
            language = "en";
        }

        const loginRequest = {
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
            extraQueryParameters: { ui_locales: language, environment: this.websiteService.getB2cEnvironment() }
        };
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
            )
            .subscribe(() => {
                this.msalService.loginRedirect(loginRequest);
            });

    }

    signoutSilently() {

        this.logoutMsal();
        sessionStorage.removeItem(Constants.callbackRoute);
        this.clearData();
        // this.currentUser = null;
        // this.globalEventsService.setIsAuthenticated(false);
        // this.globalEventsService.setAuthorizationInfo(null);
        // this.router.navigate(["/"]);
    }

    private logoutMsal() {
        const logoutRequest = {
            account: this.msalService.instance.getActiveAccount()
        };
        this.msalService.logoutRedirect(logoutRequest);
    }

    private redirectToCallbackRoute() {
        // retrieve last route from local storage
        let callbackRoute = this.localStorageService.getItem(Constants.callbackRoute);
        this.localStorageService.removeItem(Constants.callbackRoute);
        let queryParameters = null;
        if (callbackRoute) {
            // Handle query params
            if (callbackRoute.contains("?")) {
                const splitCallback = callbackRoute.split("?");
                callbackRoute = splitCallback[0];

                // Query params example: id=5&x=y
                queryParameters = {};

                const queryParamPairs = splitCallback[1].split("&");
                for (const queryParam of queryParamPairs) {
                    const queryParamSplit = queryParam.split("=");
                    const queryParamKey = queryParamSplit[0];
                    const queryParamValue = queryParamSplit[1];

                    queryParameters[queryParamKey] = queryParamValue;
                }
            }

            // Redirect
            if (callbackRoute !== "/callback") {
                if (queryParameters) {
                    this.router.navigate([callbackRoute], { queryParams: queryParameters });
                } else {
                    this.router.navigate([callbackRoute]);
                }
            } else {
                this.router.navigate(["/"]);
            }
        }
    }
    //#endregion

}