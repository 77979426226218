<div class="m-border-top m-overflow-auto p-16">

    <div style="display: grid; grid-template-columns: auto 1fr; gap:8px; align-items: center;">
        <div>
            {{"deviceDekimoDisplay.portName" | translate}}
        </div>
        <div>
            <input [(ngModel)]="dekimoDisplayConfiguration.portName"
                   type="text"
                   required>
        </div>

        <div style="align-self: self-start;">
            Light sensor
        </div>
        <div>
            <app-json-editor [(model)]="dekimoDisplayConfiguration.lightSensor">
            </app-json-editor>
        </div>


        <div style="align-self: self-start;">
            Intensity
        </div>
        <app-json-editor [(model)]="dekimoDisplayConfiguration.intensity">
        </app-json-editor>

    </div>

</div>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">

    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}

    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>