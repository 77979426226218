import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api";
import { IWebfleetVehicle } from "../models/webfleet";

@Injectable({
    providedIn: "root"
})
export class WebfleetApi extends ApiService<IWebfleetVehicle, IWebfleetVehicle, IWebfleetVehicle> {
    getRoute(): string {
        return "Webfleet";
    }

    getAllVehicles$(): Observable<IWebfleetVehicle[]> {
        return this.http.get<IWebfleetVehicle[]>(this.getUrl());
    }
}