import { DialogComponentBase } from "../dialog/dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input } from "@angular/core";

export enum TextDisplayType {
    Text = 0,
    Code = 1,
    Json = 2,
    DomainTranslation = 3
}

@Component({
    selector: "app-text-dialog",
    templateUrl: "./text.dialog.html"
})
export class TextDialogComponent extends DialogComponentBase {
    @Input() autoWidth = false;

    title: string;
    data: any;
    type: TextDisplayType;
    callback?: () => void;

    constructor(
        readonly translate: TranslateService) {
        super();
    }

    open(title: string, data: {} | string, type: TextDisplayType = TextDisplayType.Text, onClose?: () => void) {
        this.title = title;
        this.data = data;
        this.type = type;
        this.callback = onClose;
        this.openDialog();
    }

    protected onClose() {
        if (this.callback) {
            this.callback();
        }

        this.data = null;
    }
}