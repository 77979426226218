<div class="m-page-content-wrapper m-layout-default">
    <div class="m-layout-area-body m-overflow-auto p-16">
        <div style="width: 100%; max-width: 800px; margin: 0px auto;">
            <h2 class="pb-8">{{"notifications.title" | translate }}</h2>

            <div class="m-card">
                <p-dataView #dv
                            [value]="notifications"
                            [rows]="rowCount"
                            [paginator]="true"
                            [totalRecords]="totalRecords"
                            layout="list"
                            [lazy]="true"
                            (onLazyLoad)="onLazyLoad($event)">
                    <ng-template pTemplate="list"
                                 let-notifications>
                        <div *ngFor="let notification of notifications; let first = first"
                             class="m-alert p-16"
                             [ngClass]="{'is-unread': !notification.seenOn}">
                            <div class="m-alert-level">
                                <app-svg-icon icon="circle-info"
                                              divClass="icon-16"
                                              *ngIf="notification.levelId === 'information'"
                                              [ngStyle]="{'fill': '#1387F1'}" />
                                <app-svg-icon icon="circle-exclamation"
                                              divClass="icon-16"
                                              *ngIf="notification.levelId === 'warning'"
                                              [ngStyle]="{'fill': '#F1C013'}" />
                                <app-svg-icon icon="circle-x"
                                              divClass="icon-16"
                                              *ngIf="notification.levelId === 'error'"
                                              [ngStyle]="{'fill': '#EE3535'}" />
                            </div>

                            <div>
                                <p class="m-alert-date">
                                    <small>{{notification.createDate | date:'dd/MM/yyyy HH:mm'}}</small>
                                </p>

                                <ng-container *ngIf="notification.message">
                                    <p class="m-alert-message"
                                       [innerHtml]="notification.message| translateMessage:notification.categoryId">
                                    </p>
                                    <p>
                                        <a class="m-navigator"
                                           [routerLink]="[notification.message.relativeUrl]"
                                           [queryParams]="notification.message.parsedQueryParameters">
                                            <small *ngIf="notification.categoryId != NotificationCategory.Alert">{{"notifications.readMore"|translate}}</small>
                                            <small *ngIf="notification.categoryId == NotificationCategory.Alert">{{"notifications.viewOnMap"|translate}}</small>
                                        </a>
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>
</div>