import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { IVmsImage } from "src/app/models/vms-image";
import { TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { VmsImagesComponent } from "src/app/modules/management/components/vms-images/vms-images.component";
import { VmsImageEditorComponent } from "src/app/modules/vms-image-editor/components/vms-image-editor/vms-image-editor.component";
import { ModalService } from "src/app/services/modal.service";

@Component({
    selector: "app-vms-image-editor-dialog",
    templateUrl: "./vms-image-editor.dialog.html"
})
export class VmsImageEditorDialogComponent extends DialogComponentBase {

    vmsTypeId: string;
    ownerId: number;
    vmsImageId: number;
    submitting = false;
    private callback?: (selectedImage: IVmsImage) => void;
    @ViewChild(VmsImageEditorComponent, { static: false }) editor: VmsImageEditorComponent;


    nativeElement: HTMLElement;
    constructor(
        readonly translate: TranslateService,
        private readonly modalService: ModalService,
        element: ElementRef) {
        super();
    }

    create(callback: (selectedImage: IVmsImage) => void, vmsTypeId: string, ownerId: number, vmsImageId?: number) {
        this.callback = callback;
        this.vmsTypeId = vmsTypeId;
        this.ownerId = ownerId;
        this.vmsImageId = vmsImageId;
        this.openDialog();

    }

    closePopup() {
    }
    protected afterShow(): void {
        this.editor.setEditor(() => this.close(), this.submit.bind(this), this.vmsTypeId, this.ownerId, this.vmsImageId);
    }

    onHide(): void {
    }
    onCancel() {
        this.close();
    }

    submit(vmsImage: IVmsImage) {
        if (this.callback) {
            this.callback(vmsImage);
        }
        this.close();

    }

}