import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, OnDestroy, ElementRef } from "@angular/core";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager, DurationUtils } from "src/app/utilities";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-anpr",
    templateUrl: "./device-anpr.component.html"
})
export class DeviceAnprComponent implements OnInit, OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    anprForm: SigncoFormGroup;

    calendarSettings: CalendarSettings;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly primeComponentService: PrimeComponentService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.anprForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        this.anprForm = this.formBuilder.group({
            dataUrl: ["", Validators.required],
            updateInterval: [null, [Validators.required]]
        }) as SigncoFormGroup;

        if (this.device.anprConfiguration) {
            this.anprForm.patchValue({
                dataUrl: this.device.anprConfiguration.dataUrl,
                updateInterval: DurationUtils.toDate(this.device.anprConfiguration.updateInterval)
            });
        }

        this.anprForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.anprForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);

        Object.assign(deviceUpdater.anprConfiguration, this.anprForm.value);

        // User input Date, but we convert it to a Duration (= TimeSpan) string notation
        const updateIntervalDateValue = this.anprForm.get("updateInterval").value as Date;
        const durationValue = DurationUtils.fromDate(updateIntervalDateValue);
        const stringValue = DurationUtils.toString(durationValue);
        deviceUpdater.anprConfiguration.updateInterval = stringValue as any;

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}