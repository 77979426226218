import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { Component, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { SubscriptionManager } from "src/app/utilities";
import { MapDataService } from "src/app/services/map-data.service";
import { ToastService } from "src/app/services/toast.service";
import { SelectItem } from "primeng/api";
import { MessageLevel } from "src/app/models/pinned-data";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { MessageCreator, MessageParameters, MessageTemplate } from "src/app/models/message";
import { MessageApi } from "src/app/resource/message.api";
import { NotificationLevel } from "src/app/models/notification";
import { GenerateDeviceStatusDialogComponent } from "../generate-device-status-dialog/generate-device-status.dialog";
import { FilterServiceFields } from "src/app/modules/locations";
import { AlertLevel } from "src/app/models/alert";
import { TimelineConfig } from "src/app/modules/shared/components/timeline-activity/timeline-activity.component";

@Component({
    selector: "app-playground",
    templateUrl: "./playground.component.html"
})
export class PlaygroundComponent implements OnDestroy {
    @ViewChild(GenerateDeviceStatusDialogComponent, { static: true }) generateDeviceStatusDialog: GenerateDeviceStatusDialogComponent;

    private subscriptionManager = new SubscriptionManager();

    dropdownOptions: SelectItem[];
    MessageTemplate = MessageTemplate;

    calendarSettings: CalendarSettings;
    buttonDisabled = false;
    buttonVisibleNgIf = true;
    buttonVisibleHidden = true;

    loadMeasuringPointsDuration: number;
    loadGroupsDuration: number;
    loadDevicesDuration: number;
    loadOrganizationsDuration: number;

    notificationNavigatorQueryParams = {};
    timelineConfig: TimelineConfig;

    constructor(
        elementRef: ElementRef,
        readonly primeComponentService: PrimeComponentService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly toastService: ToastService,
        private readonly mapDataService: MapDataService,
        private readonly messageApi: MessageApi) {

        elementRef.nativeElement.classList.add("m-layout-area-body");

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.domainModelFilterService.getDrivingLanes$().then(dropdownOptions => {
            this.dropdownOptions = dropdownOptions;
        });

        this.notificationNavigatorQueryParams[FilterServiceFields.alertLevels] = `${AlertLevel.info},${AlertLevel.warning},${AlertLevel.error}`;
        this.notificationNavigatorQueryParams[FilterServiceFields.projectsForUserId] = "me";

        this.initTimeline();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    //#region Toasts

    showInfoToast() {
        this.toastService.info("Info Toast");
    }

    showSuccessToast() {
        this.toastService.success("Success Toast");
    }

    showWarningToast() {
        this.toastService.warning("Warning Toast");
    }

    showErrorToast() {
        this.toastService.error("Error Toast");
    }

    //#endregion Toasts

    createMessage(template: MessageTemplate) {

        const messageCreator = {
            messageTemplateId: template,
            relativeUrl: "/planning/assignmentPlanning",
            levelId: MessageLevel.Information,
            parameters: {
                values: {
                    "team": "Robin Van den Abeele",
                    "vehicles": "BMW 3 23D",
                    "oldVehicles": "Skoda Octavia 1.6 TDI",
                    "previousTeam": "Boris Rogge, Kristof Verbiest, Robin Van den Abeele",
                    "currentTeam": "Boris Rogge, Kristof Verbiest, Robin Van den Abeele, Lennart Martens",
                    "assignment": "Painting Metanous Building",
                    "teamConfiguration": "Boris Rogge, Kristof Verbiest, Robin Van den Abeele, Lennart Martens",
                    "addedVehicles": "BMW 3 23D",
                    "removedVehicles": "Skoda Octavia 1.6 TDI",
                }
            },
            userIds: [this.globalEventsService.getAuthorizationInfo().user.id],
            notificationLevelId: NotificationLevel.Information
        } as MessageCreator;



        this.messageApi.create$(messageCreator).subscribe(message => {
            this.showSuccessToast();
        });
    }

    createTeamModifiedOnPlannedEventMessage() {

        const messageCreator = {
            messageTemplateId: MessageTemplate.TeamModifiedOnPlannedEvent,
            relativeUrl: "/planning/assignmentPlanning",
            levelId: MessageLevel.Information,
            parameters: {
                values: {
                    "teamName": "Robin Van den Abeele",
                    "vehicles": "BMW 3 23D",
                },

            } as MessageParameters,
            userIds: [this.globalEventsService.getAuthorizationInfo().user.id],
            notificationLevelId: NotificationLevel.Information
        } as MessageCreator;

        this.messageApi.create$(messageCreator).subscribe(message => {
            this.showSuccessToast();
        });
    }

    createTeamCheckedInOnPlannedEventMessage() {
        const messageCreator = {
            messageTemplateId: MessageTemplate.TeamCheckedInOnPlannedEvent,
            relativeUrl: "/planning/assignmentPlanning",
            levelId: MessageLevel.Information,
            parameters: {
                values: {
                    "teamName": "Robin Van den Abeele",
                    "vehicles": "BMW 3 23D",
                }
            },
            userIds: [this.globalEventsService.getAuthorizationInfo().user.id],
            notificationLevelId: NotificationLevel.Information
        } as MessageCreator;

        this.messageApi.create$(messageCreator).subscribe(message => {
            this.showSuccessToast();
        });
    }

    generateDeviceStatus() {
        this.generateDeviceStatusDialog.open();
    }


    //#region Map Data Profiling

    clearMapData() {
        this.mapDataService.clear();
        this.loadMeasuringPointsDuration = null;
        this.loadGroupsDuration = null;
        this.loadDevicesDuration = null;
        this.loadOrganizationsDuration = null;
    }

    async reloadMapData() {
        this.clearMapData();
        await this.loadOrganizations();
        await this.loadMeasuringPoints();
        await this.loadGroups();
        await this.loadDevices();
    }

    private async benchMark(func: () => Promise<any>): Promise<number> {
        const startTime = new Date().getTime();
        await func();
        return new Date().getTime() - startTime;
    }

    async loadMeasuringPoints() {
        this.loadMeasuringPointsDuration = await this.benchMark(async () => {
            try {
                await this.mapDataService.loadMeasuringPointLocations();
            } catch (_) {

            }
        });
    }

    async loadGroups() {
        this.loadGroupsDuration = await this.benchMark(async () => {
            try {
                await this.mapDataService.loadGroupSummaries();
            } catch (_) {

            }
        });
    }

    async loadDevices() {
        this.loadDevicesDuration = await this.benchMark(async () => {
            try {
                await this.mapDataService.loadDeviceLocations();
            } catch (_) {

            }
        });
    }

    async loadOrganizations() {
        this.loadOrganizationsDuration = await this.benchMark(async () => {
            try {
                await this.mapDataService.loadOrganizations();
            } catch (_) {

            }
        });
    }

    get isMapDataLoading(): boolean {
        return this.mapDataService.isLoading;
    }

    //#endregion Map Data Profiling

    //#region Timeline

    private initTimeline() {
        const now = new Date();
        this.timelineConfig = new TimelineConfig();
        this.timelineConfig.data = [
            {
                description: "No status update",
                startDate: now.addHours(-24),
                endDate: now,
            },
            {
                description: "No connection",
                startDate: now.addHours(-48),
                endDate: now.addHours(-12),
            },
            {
                description: "Battery low",
                startDate: now.addHours(-72),
                endDate: now.addHours(-6),
            }
        ];
    }

    //#endregion Timeline
}
