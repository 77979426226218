<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{width: '600px'}">

    <p-header>
        Manage the counting zone manually
    </p-header>

    <div class="m-16"
         style="display: grid; gap:16px;">
        <div style="display:grid; grid-template-columns: auto auto; gap:8px;">
            <app-svg-icon icon="alert"></app-svg-icon>
            <strong>Every change you make here will be applied immediately. Be very careful, this could trigger actions ( e.g. on lights and displays) and could cause dangerous situations.</strong>
        </div>

        <ng-container *ngIf="countingZone?.accessStatus">
            <h3>Force access</h3>
            <div style="display:flex; gap:8px; align-items: center;">
                <p-dropdown id="countingZoneAccessStatusId"
                            [options]="accessStatuses"
                            [(ngModel)]="selectedAccessStatus"
                            [autoDisplayFirst]="false"
                            appendTo="body"></p-dropdown>
                <button class="m-btn m-btn-secondary"
                        (click)="forceAccessStatus()">
                    <app-svg-icon icon="save"></app-svg-icon>
                    Change</button>
            </div>
        </ng-container>

        <h3>Add a vehicle</h3>

        <div style="display:flex; gap:8px; align-items: center;">
            <p-dropdown id="vehicleCategoryId"
                        [options]="vehicleCategories"
                        [(ngModel)]="selectedVehicleCategory"
                        [autoDisplayFirst]="true"
                        appendTo="body"></p-dropdown>
            <button class="m-btn m-btn-secondary"
                    (click)="addVehicle()">
                <app-svg-icon icon="add"></app-svg-icon>
                Add vehicle</button>
        </div>

        <h3>Remove a vehicle</h3>

        <div style="display:grid; grid-template-columns: min-content min-content;">
            <ng-container *ngFor="let vehicle of countingZone?.vehicles">
                <pre style="background-color: lightgray; padding:2px; margin:2px;">{{vehicle | vehicle}}</pre>
                <button class="m-btn m-btn-secondary"
                        style="padding: 0; min-height: 0;"
                        (click)="removeVehicle(vehicle)">
                    <app-svg-icon icon="delete"></app-svg-icon>
                </button>
            </ng-container>
        </div>

    </div>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.close" | translate}}
        </button>
    </p-footer>
</p-dialog>