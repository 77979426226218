import { inject, Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";

@Injectable({ "providedIn": "root" })
export class PromptUpdateService {
    private readonly swUpdate = inject(SwUpdate);
    private readonly modalService = inject(ModalService);
    private readonly translateService = inject(TranslateService);

    subscribeToUpdates() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"))
                .subscribe(() => {
                    console.info(`[${new Date().toISOString()}] A new version is available.`);

                    const onOk = () => document.location.reload();
                    const onCancel = () => console.warn(`[${new Date().toISOString()}] User declined new version.`);

                    const newVersion = this.translateService.instant("newVersion");
                    this.modalService ? this.modalService.confirm(newVersion, () => onOk(), () => onCancel()) : confirm(newVersion) ? onOk() : onCancel();
                });
        }
    }
}
