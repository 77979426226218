import { Component, ElementRef, Input, OnChanges, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IAssignment, IAssignmentStatusHistory } from "src/app/models/assignment";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions, SortDescriptor, SortDirection } from "src/app/models/search";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { AssignmentStatusHistoryApi } from "src/app/resource/assignment-status-history.api";

@Component({
    selector: "app-assignments-history",
    templateUrl: "./assignments-history.component.html",
    styleUrls: []
})
export class AssignmentsHistoryComponent
    extends LazyTableComponent<IAssignmentStatusHistory>
    implements OnChanges {

    @Input() assignment: IAssignment;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly assignmentStatusHistoryApi: AssignmentStatusHistoryApi,
        readonly translateService: TranslateService) {

        super("assignmentsHistory.component", elementRef, assignmentStatusHistoryApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.addColumn(new TableColumn("timestamp", "auditTrails.timestamp", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("status", "general.status", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("creator", "assignmentHistory.creator", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini }));
    }

    setAssignment(assignment: IAssignment) {
        this.clear();
        this.assignment = assignment;
        this.updateRelevantColumns();
        this.loadTableRows();
    }

    clear(): void {
        this.assignment = null;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.assignment) {
            searchParameters.filter.push(new FilterDescriptor("assignmentId", this.assignment.id, FilterOperator.equals));
        }

        searchParameters.sort = [
            new SortDescriptor(SortDirection.descending, "CreateDate")
        ];

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("assignmentStatusHistory", "worker");
        return serviceRequestOptions;
    }
}
