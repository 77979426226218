import { IServiceModelBase } from "./servicemodelbase";

export interface IBatteryDischargeEntry {
    chargePercentage: number;
    voltage: number;
}

export interface IBatteryDischargeTable extends IServiceModelBase {
    name: string;
    entries: IBatteryDischargeEntry[];
}

export class BatteryDischargeTableCreator {
    name: string;
    entries: IBatteryDischargeEntry[];
}

export class BatteryDischargeTableUpdater extends BatteryDischargeTableCreator {
    constructor(existing: IBatteryDischargeTable) {
        super();

        this.id = existing.id;
        this.name = existing.name;
        this.entries = existing.entries;
    }

    id: number;
}