import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { IVmsImage } from "src/app/models/vms-image";
import { TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { VmsImagesComponent } from "src/app/modules/management/components/vms-images/vms-images.component";

@Component({
    selector: "app-vms-image-library-selector-dialog",
    templateUrl: "./vms-image-library-selector.dialog.html"
})
export class VmsImageLibrarySelectorDialogComponent extends DialogComponentBase {

    selectedVmsImage: IVmsImage;
    submitting = false;
    private callback?: (selectedImage: IVmsImage) => void;
    @ViewChild(VmsImagesComponent, { static: false }) imageLibrary: VmsImagesComponent;
    @Input() vmsTypeId: string;
    @Input() ownerId: number;

    nativeElement: HTMLElement;
    constructor(
        readonly translate: TranslateService,
        element: ElementRef) {
        super();
    }

    create(callback: (res: IVmsImage) => void, currentVmsImageId?: number) {
        this.callback = callback;
        this.imageLibrary.setCurrentVmsImageId(currentVmsImageId);
        this.imageLibrary.reload();
        this.openDialog();
    }

    submit() {
        this.close();
        this.callback(this.imageLibrary.selectedVmsImage);

    }

}