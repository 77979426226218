<form [formGroup]="validationForm"
      class="m-layout-area-body"
      novalidate>
    <div class="row m-fixed-height">
        <div class="col-4 m-fixed-height">
            <div class="m-card m-fixed-height"
                 #column1>
                <app-loading-spinner *ngIf="loading"></app-loading-spinner>

                <div [hidden]="loading"
                     class="m-fixed-height m-layout-w-actions-bottom">
                    <div class="m-fixed-height m-layout-w-actions-bottom">

                        <app-resource-selector [containingFormSubmitted]="validationForm?.submitted"
                                               [isMapOpen]="isMapOpen()"
                                               (toggleMap)="toggleMap($event)"
                                               [requireAtLeastOneResourceSelected]="true"></app-resource-selector>

                        <div class="m-layout-w-actions-bottom">
                            <div *ngIf="calendarSettings"
                                 class="m-layout-area-bottom"
                                 [ngClass]="{ 'has-error': rangeFromDateControl.showErrors }">

                                <div class="p-16 m-border-bottom m-border-top">
                                    <h3>{{"general.dates" | translate}}</h3>
                                </div>


                                <div class="row p-16">
                                    <div class="col-4">
                                        <label class="calendar-label"
                                               [ngStyle]="isDatesInError() ? {'color': 'red'} : {}">
                                            {{"reports.fromTo" | translate}}
                                        </label>
                                    </div>

                                    <div class="col-8">
                                        <div>
                                            <p-calendar #rangeFromCalendar
                                                        id="rangeFromDate"
                                                        appendTo="body"
                                                        formControlName="from"
                                                        selectionMode="single"
                                                        autoWidth="false"
                                                        [showIcon]="true"
                                                        [maxDate]="rangeToDateControl.value"
                                                        [hourFormat]="calendarSettings.hourFormat"
                                                        [dateFormat]="calendarSettings.dateFormat"
                                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                        [monthNavigator]="false"
                                                        [yearNavigator]="false"
                                                        [numberOfMonths]="1"
                                                        [yearRange]="primeComponentService.calendarYearRange"
                                                        showButtonBar="true"
                                                        #rangeFromDateInput>
                                                <ng-template pTemplate="date"
                                                             let-date>
                                                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                                                        {{date.day}}
                                                    </span>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                                                </ng-template>
                                            </p-calendar>

                                            <app-form-validation [control]="rangeFromDateControl"
                                                                 [input]="rangeFromDateInput">
                                            </app-form-validation>
                                        </div>

                                        <div class="mt-8">
                                            <p-calendar #rangeToCalendar
                                                        id="rangeToDate"
                                                        appendTo="body"
                                                        formControlName="to"
                                                        selectionMode="single"
                                                        autoWidth="false"
                                                        [showIcon]="true"
                                                        [minDate]="rangeFromDateControl.value"
                                                        [hourFormat]="calendarSettings.hourFormat"
                                                        [dateFormat]="calendarSettings.dateFormat"
                                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                        [monthNavigator]="false"
                                                        [yearNavigator]="false"
                                                        [numberOfMonths]="1"
                                                        [yearRange]="primeComponentService.calendarYearRange"
                                                        showButtonBar="true"
                                                        #rangeToDateInput>
                                                <ng-template pTemplate="date"
                                                             let-date>
                                                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                                                        {{date.day}}
                                                    </span>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                                                </ng-template>
                                            </p-calendar>

                                            <app-form-validation [control]="rangeToDateControl"
                                                                 [input]="rangeToDateInput">
                                            </app-form-validation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
                        <button class="m-btn m-btn-primary"
                                (click)="submit()"
                                type="submit">
                            {{"reports.render" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="!mapOpen || projectSelectionOpen"
             class="col-8 m-fixed-height">
            <div class="m-card m-layout-w-actions-top m-fixed-height">
                <div class="m-layout-area-top flex justify-end">
                    <app-icon-button icon="close"
                                     [styleClass]="'m-btn m-btn-square m-btn-close m-btn-close-top-right'"
                                     (click)="closeMap()">
                    </app-icon-button>
                </div>

                <app-map-selector class="m-layout-area-body m-layout-default"
                                  [mapDetail]="mapDetail"
                                  [componentHeight]="mapHeight"
                                  (detailComponentSet)="handleMapComponentLoad()">
                </app-map-selector>
            </div>
        </div>

        <div [hidden]="!projectSelectionOpen || mapOpen"
             class="col-8 m-fixed-height">
            <div class="m-card m-layout-w-actions-top m-fixed-height m-overflow-auto">
                <div class="flex justify-end m-layout-area-top">
                    <app-icon-button icon="close"
                                     [styleClass]="'m-btn m-btn-square m-btn-close'"
                                     (click)="closeProjectSelection()">
                    </app-icon-button>
                </div>

                <app-project-treelist [onlyMeasurementProjects]="true"
                                      (firstLevelLoaded)="onFirstLevelLoaded()"></app-project-treelist>
            </div>
        </div>
    </div>
</form>