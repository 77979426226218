<div class="m-layout-default m-fixed-height m-border-top">
    <div class="m-layout-area-body">
        <m-split-screen mode="horizontal">
            <ng-container first-panel>
                <div class="p-24">
                    <div class="row">
                        <div class="col-3">
                            <div class="pb-8">
                                <h3>Calendar</h3>
                            </div>
                            <p-calendar autoWidth="true"
                                        [showIcon]="true"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                        [monthNavigator]="false"
                                        [yearNavigator]="false"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        showButtonBar="true">
                            </p-calendar>

                            <div class="pb-8 pt-16">
                                <h3>Calendar numberOfMonths + horizontal</h3>
                                <p>
                                    Example on
                                    <a href="https://www.primefaces.org/primeng/#/calendar"
                                       target="_blank">PrimeNG</a>
                                </p>
                            </div>

                            <p-calendar selectionMode="multiple"
                                        autoWidth="true"
                                        [showIcon]="true"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                        [numberOfMonths]="3"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        showButtonBar="true">
                            </p-calendar>

                            <div class="pb-8 pt-16">
                                <h3>Calendar with navigator + appendTo = "body"</h3>
                            </div>
                            <p-calendar autoWidth="true"
                                        appendTo="body"
                                        [showIcon]="true"
                                        [hourFormat]="calendarSettings.hourFormat"
                                        [dateFormat]="calendarSettings.dateFormat"
                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [yearRange]="primeComponentService.calendarYearRange"
                                        showButtonBar="true">
                            </p-calendar>
                        </div>

                        <div class="col-3">
                            <div class="pb-8">
                                <h3>Dropdown</h3>
                            </div>
                            <p-dropdown [options]="dropdownOptions"
                                        [autoDisplayFirst]="false"
                                        [panelStyle]="{'width':'100%'}"
                                        [showClear]="true">
                            </p-dropdown>

                            <div class="pb-8 pt-16">
                                <h3>Dropdown with filter</h3>
                            </div>
                            <p-dropdown [options]="dropdownOptions"
                                        [filter]="true"
                                        [resetFilterOnHide]="true"
                                        [autoDisplayFirst]="false"
                                        [showClear]="true"
                                        [panelStyle]="{'width':'100%'}">
                            </p-dropdown>

                            <div class="pb-8 pt-16">
                                <h3>Dropdown with filter and appendTo = "body"</h3>
                            </div>
                            <p-dropdown appendTo="body"
                                        [options]="dropdownOptions"
                                        [filter]="true"
                                        [resetFilterOnHide]="true"
                                        [autoDisplayFirst]="false"
                                        [showClear]="true"
                                        [panelStyle]="{'width':'100%'}">
                            </p-dropdown>
                        </div>

                        <div class="col-3">
                            <div class="pb-8">
                                <h3>MultiSelect</h3>
                            </div>
                            <p-multiSelect [filter]="false"
                                           [options]="dropdownOptions">
                            </p-multiSelect>

                            <div class="pb-8 pt-16">
                                <h3>MultiSelect with filter</h3>
                            </div>
                            <p-multiSelect [options]="dropdownOptions"
                                           [filter]="true"
                                           [resetFilterOnHide]="true">
                            </p-multiSelect>

                            <div class="pb-8 pt-16">
                                <h3>MultiSelect with filter and appendTo = "body"</h3>
                            </div>
                            <p-multiSelect appendTo="body"
                                           [options]="dropdownOptions"
                                           [filter]="true"
                                           [resetFilterOnHide]="true">
                            </p-multiSelect>
                        </div>

                        <div class="col-3">
                            <div class="pb-8">
                                <h3>
                                    appAutofocus tests with disabled states
                                </h3>
                            </div>

                            <div *ngIf="buttonVisibleNgIf"
                                 [hidden]="!buttonVisibleHidden">
                                <input type="text"
                                       class="p-inputtext"
                                       [disabled]="buttonDisabled"
                                       appAutofocus />
                            </div>

                            <div class="pt-16">
                                <ul>
                                    <li>
                                        <button (click)="buttonDisabled = !buttonDisabled"
                                                class="m-btn m-btn-link">Toggle enabled [{{ !buttonDisabled }}]
                                        </button>
                                    </li>

                                    <li>
                                        <button (click)="buttonVisibleNgIf = !buttonVisibleNgIf"
                                                class="m-btn m-btn-link">Toggle *ngIf [{{ buttonVisibleNgIf }}]
                                        </button>
                                    </li>

                                    <li>
                                        <button (click)="buttonVisibleHidden = !buttonVisibleHidden"
                                                class="m-btn m-btn-link">Toggle hidden [{{ !buttonVisibleHidden }}]
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="pb-8 pt-16">
                                <h3>Map Data Profiling</h3>
                            </div>
                            <ul>
                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="clearMapData()"
                                            [disabled]="isMapDataLoading">
                                        Clear map data
                                    </button>
                                </li>

                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="reloadMapData()"
                                            [disabled]="isMapDataLoading">
                                        Reload map data
                                    </button>
                                </li>

                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="loadMeasuringPoints()"
                                            [disabled]="isMapDataLoading">
                                        Load Measuring Points
                                        <ng-container *ngIf="loadMeasuringPointsDuration">
                                            ({{ loadMeasuringPointsDuration }} ms)
                                        </ng-container>
                                    </button>
                                </li>

                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="loadGroups()"
                                            [disabled]="isMapDataLoading">
                                        Load Groups
                                        <ng-container *ngIf="loadGroupsDuration">
                                            ({{ loadGroupsDuration }} ms)
                                        </ng-container>
                                    </button>
                                </li>

                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="loadDevices()"
                                            [disabled]="isMapDataLoading">
                                        Load Devices
                                        <ng-container *ngIf="loadDevicesDuration">
                                            ({{ loadDevicesDuration }} ms)
                                        </ng-container>
                                    </button>
                                </li>

                                <li>
                                    <button class="m-btn m-btn-link"
                                            (click)="loadOrganizations()"
                                            [disabled]="isMapDataLoading">
                                        Load Organizations
                                        <ng-container *ngIf="loadOrganizationsDuration">
                                            ({{ loadOrganizationsDuration }} ms)
                                        </ng-container>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div class="col-3">
                            <div class="pb-8 pt-16">
                                <h3>Toasts</h3>
                            </div>
                            <ul>
                                <li>
                                    <button (click)="showInfoToast()"
                                            class="m-btn m-btn-link">Info
                                    </button>
                                </li>

                                <li>
                                    <button (click)="showSuccessToast()"
                                            class="m-btn m-btn-link">Success
                                    </button>
                                </li>

                                <li>
                                    <button (click)="showWarningToast()"
                                            class="m-btn m-btn-link">Warning
                                    </button>
                                </li>

                                <li>
                                    <button (click)="showErrorToast()"
                                            class="m-btn m-btn-link">Error
                                    </button>
                                </li>
                            </ul>

                            <div class="pb-8 pt-16">
                                <h3>Loading spinner</h3>
                            </div>

                            <app-loading-spinner></app-loading-spinner>
                        </div>

                        <div class="col-3">
                            <div class="pb-8 pt-16">
                                <h3>Notifications</h3>
                            </div>
                            <ul>
                                <li>
                                    <button (click)="createMessage(MessageTemplate.TeamModifiedOnPlannedEvent)"
                                            class="m-btn m-btn-link">Team modified on planned event
                                    </button>
                                </li>
                                <li>
                                    <button (click)="createMessage(MessageTemplate.TeamConfigModifiedOnPlannedEvent)"
                                            class="m-btn m-btn-link">Team config modified on planned event
                                    </button>
                                </li>
                                <li>
                                    <button (click)="createMessage(MessageTemplate.TeamConfigModifiedOnPlannedAssignment)"
                                            class="m-btn m-btn-link">Team config modified on planned assignment
                                    </button>
                                </li>
                                <li>
                                    <button (click)="createMessage(MessageTemplate.VehicleConfigModifiedOnPlannedAssignment)"
                                            class="m-btn m-btn-link">Vehicle config modified on planned assignment
                                    </button>
                                </li>
                                <li>
                                    <button (click)="createMessage(MessageTemplate.TeamCheckedInOnPlannedEvent)"
                                            class="m-btn m-btn-link">Team checked in on planned event
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div class="col-3">
                            <div class="pb-8 pt-16">
                                <h3>Generate Device Status</h3>
                            </div>
                            <ul>
                                <li>
                                    <button (click)="generateDeviceStatus()"
                                            class="m-btn m-btn-link">Generate device status
                                    </button>
                                </li>
                            </ul>

                            <div class="pb-8 pt-16">
                                <h3>Notification navigator</h3>
                            </div>
                            <ul>
                                <li>
                                    <a class="m-navigator"
                                       [routerLink]="['/locations']"
                                       [queryParams]="notificationNavigatorQueryParams">
                                        <small>{{ "notifications.viewOnMap"|translate }}</small>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container second-panel>
                <div class="p-24">
                    <app-timeline-activity [config]="timelineConfig"
                                           class="p-16"></app-timeline-activity>
                </div>
            </ng-container>
        </m-split-screen>
    </div>
</div>

<app-generate-device-status-dialog></app-generate-device-status-dialog>