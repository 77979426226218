import { IServiceModelBase } from "./servicemodelbase";
import { IOrganization } from "./user";

export interface ISafetyQuestion extends IServiceModelBase {
    question: string;
    validFrom: Date;
    validTo: Date;
    isActive: boolean;
    owner: IOrganization;
}

export class SafetyQuestionCreator {
    question: string;
    validFrom: Date;
    validTo: Date;
    ownerId: number;
}