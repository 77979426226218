<div class="m-popup"
     *ngIf="splitMap.measuringPointsComponent || splitMap.groupsComponent || splitMap.devicesComponent || splitMap.assignmentsComponent"
     [ngStyle]="locationPopupCss"
     [hidden]="!show"
     style="position: absolute; max-width: 900px;"
     ngDraggable
     [handle]="header"
     [preventDefaultEvent]="true">

    <header class="m-popup-header"
            #header>
        <input type="checkbox"
               *ngIf="splitMap?.measuringPointsComponent?.selectionMode === 'multiple'"
               [checked]="isAllSelected()"
               (click)="toggleSelectAll() && $event.stopPropagation()"
               (mousedown)="$event.stopPropagation()"
               (touchstart)="toggleSelectAll() && $event.stopPropagation()"
               (touchmove)="$event.stopPropagation()">

        <h3 class="mr-8">{{locationMarker?.marker?.getLabelContent()}}</h3>

        <div class="m-btn-group ml-auto">
            <button class="m-btn m-btn-square"
                    type="button"
                    (click)="splitMap.zoomOnContext(context)">
                <app-svg-icon icon="crosshair"
                              tooltip="{{'general.zoom' | translate}}">
                </app-svg-icon>
            </button>

            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canCreateOnContext && splitMap.isMeasurementMap()"
                    (click)="splitMap.createOnContext(context)">
                <app-svg-icon icon="add"
                              tooltip="{{'form.create' | translate}}">
                </app-svg-icon>
            </button>
            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canCreateOnContext && !splitMap.isMeasurementMap()"
                    (click)="splitMap.toAssignmentDetail(context)">

                <app-svg-icon icon="info"
                              tooltip="{{'general.info' | translate}}">
                </app-svg-icon>
            </button>
            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canEditContext"
                    (click)="splitMap.editContext(context)">
                <app-svg-icon icon="edit"
                              tooltip="{{splitMap.getEditContextTooltip() | translate}}">
                </app-svg-icon>
            </button>
            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canViewContext"
                    (click)="splitMap.editContext(context)">
                <app-svg-icon icon="eye-solid"
                              tooltip="{{splitMap.getViewContextTooltip() | translate}}">
                </app-svg-icon>
            </button>

            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canNavigateToProjects"
                    (click)="toProjects(context)">
                <app-svg-icon icon="project"
                              tooltip="{{'projects.title' | translate}}">
                </app-svg-icon>
            </button>

            <button class="m-btn m-btn-square"
                    type="button"
                    *ngIf="canNavigateToJournal"
                    (click)="toJournal(context)">
                <app-svg-icon icon="history"
                              tooltip="{{'journal.title' | translate}}">
                </app-svg-icon>
            </button>

            <button class="m-btn m-btn-square m-btn-close"
                    type="button"
                    (click)="close()">
                <app-svg-icon icon="close">
                </app-svg-icon>
            </button>
        </div>
    </header>

    <div class="m-popup-body m-layout-default">
        <!-- Measuring Points Table -->
        <app-measuring-points *ngIf="context && splitMap.getCurrentMapDetail() === 0 && splitMap.measuringPointsComponent"
                              [stretchHeight]="false"
                              [locationId]="context.id"
                              [selectionMode]="splitMap.measuringPointsComponent.selectionMode"
                              [locationNavigation]="false"
                              [readQueryParams]="false"
                              [requiresInput]="true"
                              [headers]="false"
                              [footer]="false"
                              [paginator]="false"
                              [compact]="true"
                              [deviceNavigation]="false"
                              [canEditDeviceLink]="true"
                              [selectionBox]="splitMap.measuringPointsComponent.selectionBox"
                              [ownerId]="splitMap.measuringPointsComponent.ownerId"
                              (selected)="handleSelect($event)"
                              (deselected)="handleDeselect($event)"
                              (dataSet)="onDataSet()"></app-measuring-points>

        <!-- Location with device -->
        <app-devices *ngIf="context && splitMap.getCurrentMapDetail() === 1 && splitMap.devicesComponent"
                     [stretchHeight]="false"
                     [headers]="false"
                     [footer]="false"
                     [paginator]="false"
                     [compact]="true"
                     [locationId]="context.id"
                     [selectionMode]="splitMap.devicesComponent.selectionMode"
                     [requiresInput]="true"
                     [editCommand]="false"
                     [selectionBox]="splitMap.devicesComponent.selectionBox"
                     [ownerId]="splitMap.devicesComponent.ownerId"
                     [typeId]="splitMap.devicesComponent.typeId"
                     [showImageColumn]="true"
                     (selected)="handleSelect($event)"
                     (deselected)="handleDeselect($event)"
                     (dataSet)="onDataSet()"></app-devices>

        <app-users *ngIf="context && splitMap.getCurrentMapDetail() === 2 && splitMap.organizationsComponent"
                   [stretchHeight]="false"
                   [headers]="false"
                   [footer]="false"
                   [paginator]="false"
                   [compact]="true"
                   [organizationId]="context.organization.id"
                   [requiresInput]="true"
                   [editCommand]="false"
                   [deleteCommand]="false"></app-users>

        <div *ngIf="context && splitMap.getCurrentMapDetail() === 5 && splitMap.assignmentsComponent">
            <app-assignment-subtasks [context]="context"
                                     (selected)="handleSelect($event)"
                                     (deselected)="handleDeselect($event)"
                                     (dataSet)="onDataSet()">
            </app-assignment-subtasks>
        </div>
    </div>
</div>
