import { IAttachmentType, AttachmentTypeCreator } from "src/app/models/attachment";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { AttachmentTypeApi } from "src/app/resource/attachment-type.api";
import { ExistsValidator } from "src/app/validators/exists.validator";
import { SigncoFormGroup } from "src/app/models/form";
import { StringUtils } from "src/app/utilities";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-attachment-type-dialog",
    templateUrl: "./manage-attachment-type.dialog.html"
})
export class ManageAttachmentTypeDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IAttachmentType) => void;
    manageAttachmentTypeForm: SigncoFormGroup;
    existingAttachmentType: IAttachmentType;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly attachmentTypeApi: AttachmentTypeApi,
        private readonly domainModelFilterService: DomainModelFilterService) {

        super();
    }

    create(callback?: (res: IAttachmentType) => void) {
        this.callback = callback;
        this.existingAttachmentType = null;

        this.openDialog();
    }

    edit(existingAttachmentType: IAttachmentType, callback?: (res: IAttachmentType) => void) {
        this.callback = callback;
        this.existingAttachmentType = existingAttachmentType;

        this.openDialog();
    }

    protected onOpen() {
        const shouldCheckExisting = () => {
            return !this.existingAttachmentType || !StringUtils.equalsIgnoreCase(this.manageAttachmentTypeForm.get("code").value, this.existingAttachmentType.code);
        };

        this.manageAttachmentTypeForm = this.formBuilder.group({
            code: ["", Validators.required, ExistsValidator.create((value) => this.attachmentTypeApi.exists$(value), null, shouldCheckExisting)]
        }) as SigncoFormGroup;

        if (this.existingAttachmentType) {
            this.manageAttachmentTypeForm.patchValue(this.existingAttachmentType);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageAttachmentTypeForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageAttachmentTypeForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedAttachmentType: IAttachmentType) => {
            if (this.existingAttachmentType) {
                Object.assign(this.existingAttachmentType, savedAttachmentType);
            }

            if (this.callback) {
                this.callback(savedAttachmentType);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingAttachmentType) {
            const attachmentTypeCreator = Object.assign(new AttachmentTypeCreator(), this.manageAttachmentTypeForm.value);
            this.attachmentTypeApi.create$(attachmentTypeCreator).subscribe(onSuccess, onError);
        } else {
            const updatedAttachmentType = Object.assign(this.existingAttachmentType, this.manageAttachmentTypeForm.value);
            this.attachmentTypeApi.update$(updatedAttachmentType).subscribe(onSuccess, onError);
        }
    }
}