import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ElementRef, Type } from "@angular/core";
import { DeviceStatusOverviewComponent } from "../device-status-overview/device-status-overview.component";
import { DeviceStatusComponent } from "../device-status/device-status.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { IDevice } from "src/app/models/device";

type DeviceStatusComponents =
    DeviceStatusOverviewComponent |
    DeviceStatusComponent;

@Component({
    selector: "app-device-status-container",
    templateUrl: "./device-status-container.component.html"
})
export class DeviceStatusContainerComponent extends TabBase<DeviceStatusComponents> implements OnChanges {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    ngClass: string;

    constructor(
        elementRef: ElementRef,
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        elementRef.nativeElement.classList.add("m-layout-area-body");

        this.addTab({ component: DeviceStatusOverviewComponent, url: "overview", translatePath: "deviceStatus.title", icon: "data" });
        this.addTab({ component: DeviceStatusComponent, url: "status", translatePath: "data.title", icon: "calendar" });

        this.filterTabs();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filterTabs();

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.filterTabs();

        if (this.activeComponent) {
            this.activeComponent.setDevice(this.device);
        }
    }

    protected onSelectedTabChange() {
        this.activeComponent.setDevice(this.device);

        if (this.activeComponent instanceof DeviceStatusComponent) {
            this.activeComponent.downloadCommand = true;
            this.activeComponent.seeDeviceStatusJsonCommand = true;
            this.activeComponent.updateCommandColumn();
            this.activeComponent.setRowCount(50);
        }

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";
    }

    updateTitle() {
        if (!this.device || !this.selectedTab) return;
        this.titleService.setTitle(`${this.device.code} - ${this.selectedTab.label}`);
    }
}