import { IServiceModelBase } from "./servicemodelbase";
import { IMeasuringPointSummary } from "./web/measuring-point-summary";

export interface IGroup extends IServiceModelBase {
    code: string;
    description: string;
    color: string;
    measuringPoints: IGroupMeasuringPoint[];
    ownerId: number;
}

export interface IGroupMeasuringPoint {
    group: IGroup;
    measuringPoint: IMeasuringPointSummary;
    includeForwardDirection?: boolean;
    includeReverseDirection?: boolean;
    includeSum?: boolean;
    sortOrder?: number;
}

export class GroupCreator {
    constructor(groupMeasuringPoints: IGroupMeasuringPoint[]) {
        const relevantMeasuringPoints = groupMeasuringPoints.filter(x => x.includeForwardDirection || x.includeReverseDirection || x.includeSum);
        this.measuringPoints = relevantMeasuringPoints.map(x => {
            return {
                measuringPointId: x.measuringPoint.id,
                includeForwardDirection: x.includeForwardDirection,
                includeReverseDirection: x.includeReverseDirection,
                includeSum: x.includeSum,
                sortOrder: x.sortOrder
            } as MeasuringPointGroupUpdater;
        });
    }

    ownerId?: number;
    code: string;
    description: string;
    color: string;
    measuringPoints: MeasuringPointGroupUpdater[];
}

export class GroupUpdater extends GroupCreator {
    constructor(existing: IGroup) {
        super(existing.measuringPoints);

        this.id = existing.id;
        this.code = existing.code;
        this.description = existing.description;
        this.color = existing.color;
    }

    id: number;
    code: string;
    description: string;
    color: string;
    measuringPoints: MeasuringPointGroupUpdater[];
}


export class MeasuringPointGroupUpdater {
    measuringPointId: number;
    includeForwardDirection: boolean;
    includeReverseDirection: boolean;
    includeSum: boolean;
    sortOrder: number;
}