import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { NavigationService } from "src/app/services/navigation.service";
import { LinksComponent } from "../../../shared/components/links/links.component";
import { IDevice } from "src/app/models/device";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-links",
    templateUrl: "./device-links.component.html"
})
export class DeviceLinksComponent {
    @ViewChild(LinksComponent, { static: true }) linksComponent: LinksComponent;

    @Input() device: IDevice;

    canCreateDeviceLink = false;

    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        private readonly navigationService: NavigationService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const rights = this.globalEventsService.getCurrentRights();
        this.canCreateDeviceLink = rights?.hasBackendRight(BackendRights.EditDeviceLink);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.linksComponent.setDevice(device);
    }

    startMeasurement() {
        this.navigationService.createLinkForDevice(this.device.id);
    }

    moveToDepot() {
        this.navigationService.breakDeviceLink(this.device.id);
    }

    get canReplaceDevice(): boolean {
        return this.device && !!this.device.currentLink && !this.device.currentLink.depot;
    }

    replaceDevice() {
        if (!this.canReplaceDevice) return;

        this.navigationService.replaceLinkDevice(this.device.currentLink.id, null, this.device);
    }
}