import { Component, EventEmitter, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { map, Subject, zip } from "rxjs";

import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ViewModelEnum } from "src/app/models/domain-data";
import { SigncoFormGroup } from "src/app/models/form";
import { ISearchResult, SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";

import { DimensionOption, ITask, TaskCreator, TaskPropertyConfiguration, MarkingPropertyTypeOption, TaskUpdater, PrimitiveOption, SignColorOption, SignMaterial, SubstrateType, Unit, TaskStatus, WorkerTaskCreator } from "src/app/models/task";
import { ITaskRule, ISignMaterialInfo } from "src/app/models/task-rule";
import { ISignCategory } from "src/app/models/signcategory";
import { MomentDateAndTimePipe } from "src/app/modules/shared/pipes/datetime.pipe";
import { TaskRuleApi } from "src/app/resource/task-rule.api";
import { TaskApi } from "src/app/resource/task.api";
import { SignCategoryApi } from "src/app/resource/signcategory.api";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { DomainData, DomainDataService, ViewModelEnumOptions } from "src/app/services/domain-data.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { ToastService } from "src/app/services/toast.service";
import { SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { MapDataService } from "src/app/services/map-data.service";
import { LocationCreator } from "src/app/models/location";
import { WorkerApi } from "src/app/resource/worker.api";
import { IWorker } from "src/app/models/worker";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";


@Component({
    selector: "app-task-detail",
    templateUrl: "./task-detail.component.html",
    styleUrls: ["./task-detail.component.css"],
})
export class TaskDetailComponent implements OnInit, OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {

    @Input() task: ITask;

    @Output() save = new EventEmitter<ITask>();

    callback: (res: ITask) => void;
    submitting: boolean;
    loadingTeams = false;
    taskForm: SigncoFormGroup;
    taskStatusForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;

    taskStatuses: SelectItem[];
    filteredTaskStatuses: SelectItem[];
    taskTypes: SelectItem[];
    signMaterials: SelectItem[];
    signCategoryValues: SelectItem[];
    signCategoryLevel2Values: SelectItem[];
    signCategoryLevel3Values: SelectItem[];
    signCategoryLevel4Values: SelectItem[];
    allSubstrateTypes: SelectItem[];
    workers: SelectItem[];

    propertyConfiguration = new TaskPropertyConfiguration();

    selectMaterial = false;
    selectColor: boolean;
    selectIsMachineWork: boolean;
    selectCanHavePrimer: boolean;
    selectShowSubstrate: boolean;
    selectShowCategories: boolean;
    selectCanBeRefreshed: boolean;
    selectLevel2Category: boolean;
    selectLevel3Category: boolean;
    selectLevel4Category: boolean;
    selectAvailableFrom: boolean;
    generatedName: string;

    displayCustomLength: boolean;
    displayCustomWidth: boolean;
    displayCustomColoredWidth: boolean;
    displayCustomColoredLength: boolean;
    displayCustomDimension: boolean;
    displayCustomDiameter: boolean;
    displayCustomBlock1Width: boolean;
    displayCustomBlock2Width: boolean;
    displayCustomLDimension: boolean;
    displayCustomTDimension: boolean;
    displayCustomHeight: boolean;
    displayCustomText: boolean;
    displayCustomSpeed: boolean;
    displayCustomSignColor: boolean;

    hideWidthList: boolean;
    hideLengthList: boolean;
    hideHeightList: boolean;
    hideColoredWidthList: boolean;
    hideColoredLengthList: boolean;
    hideDiameterList: boolean;
    hideBlock1WidthList: boolean;
    hideBlock2WidthList: boolean;
    hideSpeedList: boolean;
    hideTextList: boolean;
    hideDimensionList: boolean;
    hideLElementDimensionList: boolean;
    hideTElementDimensionList: boolean;

    widthOptions: SelectItem[];
    lengthOptions: SelectItem[];
    coloredWidthOptions: SelectItem[];
    coloredLengthOptions: SelectItem[];
    typeOptions: SelectItem[];
    diameterOptions: SelectItem[];
    block1WidthOptions: SelectItem[];
    block2WidthOptions: SelectItem[];
    heightOptions: SelectItem[];
    textOptions: SelectItem[];
    speedOptions: SelectItem[];
    dimensionOptions: SelectItem[];
    lElementDimensionOptions: SelectItem[];
    tElementDimensionOptions: SelectItem[];
    signColorOptions: SelectItem[];

    defaultSignColor = "#ffffff";

    private allSignMaterials: SelectItem[];
    private allSignCategories: ISignCategory[];
    private allSignCategoryValues: SelectItem[];
    private allMarkingPropertyTypeValues: SelectItem[];
    private allSignColorOptions: SelectItem[];
    private allUnits: SelectItem[];

    private currentSignCategory: ISignCategory;
    private currentTaskRule: ITaskRule;
    private taskRules: ITaskRule[];

    private taskRuleIsSet: boolean;
    private dropdownValuesFetched: Subject<boolean> = new Subject<boolean>();
    private readonly subscriptionManager = new SubscriptionManager();

    currentDate: Date;
    canCreateTask: boolean;
    signColors = [
        { label: "general.white", value: "#f6f6f6" },
        { label: "general.black", value: "#1e1e1e" },
        { label: "general.yellow", value: "#f0ca00" },
        { label: "general.green", value: "#008754" },
        { label: "general.lightGreen", value: "#48a43f" },
        { label: "general.red", value: "#c1121c" },
        { label: "general.blue", value: "#0e518d" },
        { label: "general.lightBlue", value: "#3481b8" },
        { label: "general.reflectingBlue", value: "#81c0bb" },
        { label: "general.orange", value: "#e15501" },
        { label: "general.ochre", value: "#ba8f4c" },
        { label: "general.purple", value: "#992572" },
        { label: "general.grey", value: "#8f9695" },
    ];

    constructor(
        elementRef: ElementRef,
        readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        readonly translateService: TranslateService,
        private readonly navigationService: NavigationService,
        private readonly momentDateAndTimePipe: MomentDateAndTimePipe,
        private readonly taskApi: TaskApi,
        private readonly taskRulesApi: TaskRuleApi,
        private readonly signCategoryApi: SignCategoryApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainDataService: DomainDataService,
        private readonly impersonationService: ImpersonationService,
        private readonly mapDataService: MapDataService,
        private readonly workerApi: WorkerApi) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateTask = rights?.hasBackendRight(BackendRights.EditTask);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.currentDate = new Date();

        this.initDropdowns();


        const calendarSettingsSubscription = this.primeComponentService
            .calendarSettings()
            .subscribe((calendarSettings) => {
                this.calendarSettings = calendarSettings;
                this.calendarSettings.hourFormat = "24";
            });

        this.subscriptionManager.add(
            "calendarSettings",
            calendarSettingsSubscription
        );

        // I think this should be unsubscribed
        this.impersonationService.subscribeToRoleImpersonation(this.mapDataService.createKey(), () => {
            this.reset();
        });
    }

    ngOnInit() {
        const dropdownsReadySubcription = this.dropdownValuesFetched.subscribe(
            (dropdownsAreReady) => {
                if (dropdownsAreReady && !this.taskRuleIsSet && this.task) {
                    this.applyTaskRule(this.task);
                }
                this.filterTaskStatuses();
            }
        );
        this.subscriptionManager.add("dropdownsReady", dropdownsReadySubcription);
    }

    ngOnChanges(changes: SimpleChanges) {
        const taskChange = changes["task"];
        if (taskChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return (
            !this.taskForm ||
            (this.taskForm.pristine && !this.isCreatingNewTask())
        );
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setTask(task: ITask) {
        this.task = task;
        this.generatedName = task.name;
        this.initialize();
    }

    reset() {
        if (this.isCreatingNewTask()) {
            if (this.task?.assignment) {
                this.navigationService.toAssignment(this.task.assignment);
            } else {
                this.navigationService.toAssignments();
            }

            return;
        }

        this.initialize();
    }

    // Depending on the selected type,
    // display corresponding materials (or categories if no material for the type)
    handleTaskTypeChange(event) {
        const rule = this.taskRules
            ? this.taskRules.find((r) => r.taskTypeId === event.value)
            : undefined;
        if (!rule) return;

        this.refreshTaskRule(rule);

        this.setCategoryValues(undefined);

        this.resetFormControlValues(
            "signMaterialId",
            "signCategoryId",
            "signCategoryLevel2Id",
            "signCategoryLevel3Id",
            "signCategoryLevel4Id",
            "isMachineWork",
            "isNew",
            "customSignColor",
            "signColorId"
        );

        if (!this.selectShowCategories) {
            this.taskStatusForm.controls["signCategoryId"].patchValue(this.signCategoryValues[0].value);
            this.handleCategoryChange({ value: this.signCategoryValues[0].value });
        }
        this.refreshRequiredValidator("signMaterialId", () => this.selectMaterial);
    }

    handleTaskStatusChange(event) {
        const newStatus = event.value as TaskStatus;

        if (newStatus !== TaskStatus.Scheduled && this.selectAvailableFrom) {
            this.removeAvailableFromControls();
        } else if (newStatus === TaskStatus.Scheduled && !this.selectAvailableFrom) {
            this.addAvailableFromControls();
        }

        if (newStatus === TaskStatus.Finished) {
            this.addRequiredValidatorForStartAndEnd();
        } else {
            this.removeRequiredValidatorForStartAndEnd();
        }
    }

    private addAvailableFromControls() {
        this.selectAvailableFrom = true;
        this.taskStatusForm.get("workerIds").setValue(this.task?.currentStatus?.workerTasks?.map(x => x.worker.id) ?? null);
        this.taskStatusForm.get("availableFrom").setValue(this.task?.currentStatus?.availableFrom ?? new Date());
        if (!this.taskStatusForm.get("availableFrom").hasValidator(Validators.required)) {
            this.taskStatusForm.get("availableFrom").addValidators(Validators.required);
        }
    }

    private removeAvailableFromControls() {
        if (this.taskStatusForm.get("availableFrom").hasValidator(Validators.required)) {
            this.taskStatusForm.get("availableFrom").removeValidators(Validators.required);
        }
        this.taskStatusForm.get("availableFrom").reset();
        this.taskStatusForm.get("workerIds").reset();
        this.selectAvailableFrom = false;
    }

    private addRequiredValidatorForStartAndEnd() {
        if (!this.taskForm.get("start").hasValidator(Validators.required)) {
            this.taskForm.get("start").addValidators(Validators.required);
        }

        if (!this.taskForm.get("end").hasValidator(Validators.required)) {
            this.taskForm.get("end").addValidators(Validators.required);
        }

        this.taskForm.get("start").updateValueAndValidity();
        this.taskForm.get("end").updateValueAndValidity();
    }

    private removeRequiredValidatorForStartAndEnd() {
        if (this.taskForm.get("start").hasValidator(Validators.required)) {
            this.taskForm.get("start").clearValidators();
        }

        if (this.taskForm.get("end").hasValidator(Validators.required)) {
            this.taskForm.get("end").clearValidators();
        }

        this.taskForm.get("start").updateValueAndValidity();
        this.taskForm.get("end").updateValueAndValidity();
    }

    // Depending on the selected material,
    // display corresponding categories
    handleMaterialChange(event) {
        const currentMaterial = this.getMaterialInfo(event.value);
        this.setSelectIsMachineWork(currentMaterial);
        this.setSelectCanHavePrimer(currentMaterial);
        this.setSelectColor(currentMaterial);
        this.resetCategoryLevels();
        this.setCategoryValues(currentMaterial);
        this.resetFormControlValues(
            "signCategoryId",
            "isMachineWork",
            "customSignColor",
            "signColorId"
        );
    }

    // Depending on the selected category,
    // display corresponding sublevel categories
    handleCategoryChange(event, initialCall = false) {
        const currentCategory = this.allSignCategories.find(
            (m) => m.signCategoryId === event.value
        );
        this.setLevel2Category(currentCategory, initialCall);
        this.setLevel3Category(undefined, initialCall);
        this.setLevel4Category(undefined, initialCall);
    }

    handleCategoryLevel2Change(event, initialCall = false) {
        const currentCategory = this.allSignCategories.find(
            (m) => m.signCategoryId === event.value
        );
        this.setLevel3Category(currentCategory, initialCall);
        this.setLevel4Category(undefined, initialCall);
    }
    handleCategoryLevel3Change(event, initialCall = false) {
        const currentCategory = this.allSignCategories.find(
            (m) => m.signCategoryId === event.value
        );

        this.setLevel4Category(currentCategory, initialCall);
    }
    handleCategoryLevel4Change(event, initialCall = false) {
        const currentCategory = this.allSignCategories.find(
            (m) => m.signCategoryId === event.value
        );

        // if category is present immediately configure because there is no level 5
        if (currentCategory) {
            this.currentSignCategory = currentCategory;
        }
        if (currentCategory && !initialCall) {
            this.applyNewPropertyConfiguration(currentCategory, false);
        }
    }

    // Allowed sign categories are different for machine work and handwork
    handleIsMachineWorkChange() {
        const materialId = this.taskStatusForm.controls["signMaterialId"].value;
        const currentMaterial = this.currentTaskRule.signMaterialInfo.find(
            (m) => m.signMaterialId === materialId
        );
        this.setCategoryValues(currentMaterial);
        this.resetFormControlValues("signCategoryId");
        this.resetCategoryLevels();
    }

    handleSubstrateTypeChange(event) {
        if (event.value == null) {
            this.taskStatusForm.controls["substrateTypeOverride"].enable();
        } else {
            this.taskStatusForm.controls["substrateTypeOverride"].disable();
        }
        this.refreshRequiredValidator("substrateTypeOverride", () => event.value == null);
    }

    handleWidthChange(event) {
        this.displayCustomWidth = event.value == null;
    }
    handleLengthChange(event) {
        this.displayCustomLength = event.value == null;
    }
    handleHeightChange(event) {
        this.displayCustomHeight = event.value == null;
    }
    handleColoredWidthChange(event) {
        this.displayCustomColoredWidth = event.value == null;
    }
    handleColoredLengthChange(event) {
        this.displayCustomColoredLength = event.value == null;
    }
    handleDiameterChange(event) {
        this.displayCustomDiameter = event.value == null;
    }
    handleBlock1WidthChange(event) {
        this.displayCustomBlock1Width = event.value == null;
    }
    handleBlock2WidthChange(event) {
        this.displayCustomBlock2Width = event.value == null;
    }
    handleSpeedChange(event) {
        this.displayCustomSpeed = event.value == null;
    }
    handleTextChange(event) {
        this.displayCustomText = event.value == null;
    }
    handleDimensionChange(event) {
        this.displayCustomDimension = event.value == null;
    }
    handleLElementDimensionChange(event) {
        this.displayCustomLDimension = event.value == null;
    }
    handleTElementDimensionChange(event) {
        this.displayCustomTDimension = event.value == null;
    }

    //#region [Reactive form setup]
    private async initialize() {
        if (!this.task) return;

        this.taskStatusForm = this.formBuilder.group({
            taskStatusId: [null, Validators.required],
            workerIds: [null],
            availableFrom: [null, Validators.required],
            isVerified: [{ value: false, disabled: true }],
            substrateTypeOverride: [{ value: "", disabled: true }],
            substrateTypeId: [SubstrateType.Asphalt],
            taskTypeId: [null, Validators.required],
            signMaterialId: null,
            signCategoryId: [null, Validators.required],
            signCategoryLevel2Id: null,
            signCategoryLevel3Id: null,
            signCategoryLevel4Id: null,
            isMachineWork: null,
            primer: null,
            isNew: null,
            hoursCount: null,
            minutesCount: null,
            note: "",
            customSignColor: "",
            signColorId: null,
            markingsCount: null,
            length: null,
            customLength: null,
            width: null,
            customWidth: null,
            coloredLength: null,
            customColoredLength: null,
            coloredWidth: null,
            customColoredWidth: null,
            customDimensionWidth: null,
            customDimensionLength: null,
            customDimensionDescription: null,
            dropdownDimension: null,
            type: "",
            bannerCount: null,
            logoCount: null,
            diameter: null,
            customDiameter: null,
            dropdownTElementDimension: null,
            customTElementLength: null,
            customTElementWidth: null,
            dropdownLElementDimension: null,
            customLElementLength: null,
            customLElementWidth: null,
            tElementCount: null,
            lElementCount: null,
            tramElementCount: null,
            height: null,
            customHeight: null,
            text: "",
            customText: "",
            speed: null,
            customSpeed: null,
            placenumber: "",
            block1Amount: null,
            block1Width: null,
            customBlock1Width: null,
            block2Amount: null,
            block2Width: null,
            customBlock2Width: null,
            lengthA: null,
            lengthB: null,
            lengthC: null,
            lengthD: null,
            whiteSubstrateLength: null,
            whiteSubstrateWidth: null,
            whiteBandCount: null,
            redBandCount: null,
            yellowBandCount: null,
            orangeBandCount: null,
            greenBandCount: null,
            blueBandCount: null,
            purpleBandCount: null,
            bendCount: null,
            arrowHeadCount: null,
            roundedEndCount: null,
            shortPieceCount: null
        }) as SigncoFormGroup;


        this.taskForm = this.formBuilder.group({
            assignmentId: [null, Validators.required],
            name: ["", Validators.required],
            start: "",
            end: "",
            currentStatus: this.taskStatusForm
        }) as SigncoFormGroup;

        this.resetTaskRule();
        this.resetTaskProperties();

        this.taskForm.patchValue(this.task);

        if (this.task.currentStatus.taskStatusId && this.task.currentStatus.taskStatusId === TaskStatus.Scheduled) {
            this.addAvailableFromControls();
        } else {
            this.removeAvailableFromControls();
        }

        if (this.task.currentStatus.taskStatusId && this.task.currentStatus.taskStatusId === TaskStatus.Finished) {
            this.addRequiredValidatorForStartAndEnd();
        } else {
            this.removeRequiredValidatorForStartAndEnd();
        }

        this.applyTaskRule(this.task);
        this.filterTaskStatuses();
        this.patchAssignment(this.task);

        if (this.isCreatingNewTask()) {
            this.generateName();
        }

        if (this.task.assignment && this.task.assignment.id) {
            this.taskForm.get("assignmentId").disable();
        }

        this.taskForm.markAsPristine();

        // TODO: There's something fucky going on with angular form bindings when enabling / disabling
        // https://github.com/angular/angular/issues/22556
        setTimeout(() => {
            if (!this.canCreateTask) {
                this.taskForm.disable();
            } else {
                // This is needed when swapping back from User impersonation back to admin
                // Has to do with above issue, shouldn't be required (because we re-create the form), yet it is
                this.taskForm.enable();

                if (this.task.assignment && this.task.assignment.id) {
                    this.taskForm.get("assignmentId")?.disable();
                }
                this.taskStatusForm.get("isVerified")?.disable();
            }
        }, 50);
    }

    private patchPropertyLists(propertyConfiguration: TaskPropertyConfiguration) {
        // Width
        this.widthOptions = this.setPrimitivePropertyConfiguration("width", propertyConfiguration.showWidth, propertyConfiguration.widths, true,
            propertyConfiguration.widthUnit);
        if (!this.widthOptions && propertyConfiguration.showWidth) {
            this.displayCustomWidth = true;
            this.hideWidthList = true;
        }
        // Length
        this.lengthOptions = this.setPrimitivePropertyConfiguration("length", propertyConfiguration.showLength, propertyConfiguration.lengths, true,
            propertyConfiguration.lengthUnit);

        if (!this.lengthOptions && propertyConfiguration.showLength) {
            this.displayCustomLength = true;
            this.hideLengthList = true;
        }
        // Height
        this.heightOptions = this.setPrimitivePropertyConfiguration("height", propertyConfiguration.showHeight, propertyConfiguration.heights, true,
            propertyConfiguration.heightUnit);
        if (!this.heightOptions && propertyConfiguration.showHeight) {
            this.displayCustomHeight = true;
            this.hideHeightList = true;
        }
        // ColoredWidth
        this.coloredWidthOptions = this.setPrimitivePropertyConfiguration("coloredWidth", propertyConfiguration.showColoredWidth,
            propertyConfiguration.coloredWidths, true, propertyConfiguration.coloredWidthUnit);
        if (!this.coloredWidthOptions && propertyConfiguration.showColoredWidth) {
            this.displayCustomColoredWidth = true;
            this.hideColoredWidthList = true;
        }
        this.coloredLengthOptions = this.setPrimitivePropertyConfiguration("coloredLength", propertyConfiguration.showColoredLength,
            propertyConfiguration.coloredLenghts, true, propertyConfiguration.coloredLengthUnit);
        if (!this.coloredLengthOptions && propertyConfiguration.showColoredLength) {
            this.displayCustomColoredLength = true;
            this.hideColoredLengthList = true;
        }
        // Diameter
        this.diameterOptions = this.setPrimitivePropertyConfiguration("diameter", propertyConfiguration.showDiameter,
            propertyConfiguration.diameters, true, propertyConfiguration.diameterUnit);
        if (!this.diameterOptions && propertyConfiguration.showDiameter) {
            this.displayCustomDiameter = true;
            this.hideDiameterList = true;
        }
        // Speed
        this.speedOptions = this.setPrimitivePropertyConfiguration("speed", propertyConfiguration.showSpeed,
            propertyConfiguration.speeds);
        if (!this.speedOptions && propertyConfiguration.showSpeed) {
            this.displayCustomSpeed = true;
            this.hideSpeedList = true;
        }
        // Text
        this.textOptions = this.setPrimitivePropertyConfiguration("text", propertyConfiguration.showText,
            propertyConfiguration.texts);
        if (!this.textOptions && propertyConfiguration.showText) {
            this.displayCustomText = true;
            this.hideTextList = true;
        } else if (this.textOptions && this.taskStatusForm.get("customText").value) {
            this.displayCustomText = true;
        }
        // Type
        this.typeOptions = this.setMarkingPropertyTypeConfiguration("type", propertyConfiguration.showType,
            propertyConfiguration.types);
        // Dimension
        this.dimensionOptions = this.setDimensionPropertyConfiguration("dropdownDimension", this.task.currentStatus.dimensionWidth,
            this.task.currentStatus.dimensionLength, propertyConfiguration.showDimension, propertyConfiguration.dimensions, propertyConfiguration.dimensionUnit);
        if (!this.dimensionOptions && propertyConfiguration.showDimension) {
            this.displayCustomDimension = true;
            this.hideDimensionList = true;
        }
        // LElement Dimension
        this.lElementDimensionOptions = this.setDimensionPropertyConfiguration("dropdownLElementDimension", this.task.currentStatus.lElementWidth,
            this.task.currentStatus.lElementLength, propertyConfiguration.showLElementDimension, propertyConfiguration.lElementDimensions, propertyConfiguration.lElementUnit);
        if (!this.lElementDimensionOptions && propertyConfiguration.showLElementDimension) {
            this.displayCustomLDimension = true;
            this.hideLElementDimensionList = true;
        }
        // TElement Dimension
        this.tElementDimensionOptions = this.setDimensionPropertyConfiguration("dropdownTElementDimension", this.task.currentStatus.tElementWidth,
            this.task.currentStatus.tElementLength, propertyConfiguration.showTElementDimension, propertyConfiguration.tElementDimensions, propertyConfiguration.tElementUnit);
        if (!this.tElementDimensionOptions && propertyConfiguration.showTElementDimension) {
            this.displayCustomTDimension = true;
            this.hideTElementDimensionList = true;
        }
        // SignColorType
        this.signColorOptions = this.setSignColorTypeConfiguration("signColorId", propertyConfiguration.showSignColorList, propertyConfiguration.signColors);
        if (!this.signColorOptions && this.selectColor && !propertyConfiguration.showSignColorList) {
            this.displayCustomSignColor = true;
            this.setDefaultColor();
        }

        // Block1 width
        this.block1WidthOptions = this.setPrimitivePropertyConfiguration("block1Width", propertyConfiguration.showBlock1Width,
            propertyConfiguration.block1Widths, true, propertyConfiguration.blocksWidthUnit);
        if (!this.block1WidthOptions && propertyConfiguration.showBlock1Width) {
            this.displayCustomBlock1Width = true;
            this.hideBlock1WidthList = true;
        }

        // Block2 width
        this.block2WidthOptions = this.setPrimitivePropertyConfiguration("block2Width", propertyConfiguration.showBlock2Width,
            propertyConfiguration.block2Widths, true, propertyConfiguration.blocksWidthUnit);
        if (!this.block2WidthOptions && propertyConfiguration.showBlock2Width) {
            this.displayCustomBlock2Width = true;
            this.hideBlock2WidthList = true;
        }
    }
    private setMarkingPropertyTypeConfiguration(name: string, showProperty: boolean, defaultValues: MarkingPropertyTypeOption[]): SelectItem[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const options = this.allMarkingPropertyTypeValues.filter(
                (m) => defaultValues
                    .findIndex((c) => c.value === m.value) > -1
            );
            if (this.taskStatusForm.get(name).value == null) {
                // set standard value
                const standardOption = defaultValues.find(x => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }
    private setSignColorTypeConfiguration(name: string, showProperty: boolean, defaultValues: SignColorOption[]): SelectItem[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const options = this.allSignColorOptions.filter(
                (m) => defaultValues
                    .findIndex((c) => c.value === m.value) > -1
            );
            if (this.taskStatusForm.get(name).value == null) {
                // set standard value
                const standardOption = defaultValues.find(x => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }
    private setDimensionPropertyConfiguration(nameDimension: string, width: number, length: number, showProperty: boolean
        , defaultValues: DimensionOption[], unit: Unit = null): SelectItem[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const unitLabel = this.getUnit(unit);
            const options = this.primeComponentService.createDropdownList(defaultValues, x => x,
                x => x.length + " x " + x.width + (unitLabel !== "" ? " " + unitLabel : "") + (x.description ? " - " + x.description : "")
                , true, "general.other");
            if (width != null && length != null) {
                const newDimension = {
                    length: length,
                    width: width
                } as DimensionOption;
                if (!options.find(x => x.value === newDimension)) {
                    const newOption =
                        {
                            label: newDimension.length + " x " + newDimension.width + (unitLabel !== "" ? " " + unitLabel : "")
                                + (newDimension.description ? " - " + newDimension.description : ""),
                            value: newDimension
                        } as SelectItem;
                    options.push(newOption);
                    options.sortBy(x => x.value ? x.value.length : "");
                    this.taskStatusForm.get(nameDimension).patchValue(newDimension);
                }
            } else {
                // set standard value
                const standardOption = defaultValues.find(x => x.standard === true);
                this.taskStatusForm.get(nameDimension).patchValue(standardOption != null ?
                    standardOption : null);
            }
            return options;
        }
        return undefined;
    }
    private setPrimitivePropertyConfiguration(name: string, showProperty: boolean, defaultValues: PrimitiveOption[], hasEmptyOption = true, unit: Unit = null): SelectItem[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {

            const unitLabel = this.getUnit(unit);
            const options = this.primeComponentService.createDropdownList(defaultValues,
                x => x.value, x => x.value.toString() + (unitLabel !== "" ? " " + unitLabel : ""), hasEmptyOption, "general.other");
            if (this.taskStatusForm.get(name).value != null) {
                if (!options.find(x => x.value === this.taskStatusForm.get(name).value)) {
                    const newOption =
                        {
                            label: this.taskStatusForm.get(name).value.toString(),
                            value: this.taskStatusForm.get(name).value
                        } as SelectItem;
                    options.push(newOption);
                    options.sortBy(x => x.value);
                }
            } else {
                // set standard value
                const standardOption = defaultValues.find(x => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }

    private patchAssignment(task: ITask) {
        const assignmentId = task.assignment ? task.assignment.id : undefined;
        this.taskForm.controls["assignmentId"].patchValue(assignmentId);
    }

    private patchTaskRuleFormValues(task: ITask) {
        this.taskStatusForm.controls["taskTypeId"].patchValue(
            task.currentStatus.taskTypeId
        );
        this.taskStatusForm.controls["signMaterialId"].patchValue(
            task.currentStatus.signMaterialId
        );
        this.taskStatusForm.controls["signCategoryId"].patchValue(
            task.currentStatus.signCategoryId
        );
        this.taskStatusForm.controls["signCategoryLevel2Id"].patchValue(
            task.currentStatus.signCategoryLevel2Id
        );
        this.taskStatusForm.controls["signCategoryLevel3Id"].patchValue(
            task.currentStatus.signCategoryLevel3Id
        );
        this.taskStatusForm.controls["signCategoryLevel4Id"].patchValue(
            task.currentStatus.signCategoryLevel4Id
        );
        this.taskStatusForm.controls["substrateTypeId"].patchValue(task.currentStatus.substrateTypeId);
        if (this.selectShowSubstrate && task.currentStatus.substrateTypeId == null) {
            this.taskStatusForm.controls["substrateTypeOverride"].enable();
            this.refreshRequiredValidator("substrateTypeOverride", () => true);
        }

    }
    public generateName() {
        this.generatedName = this.momentDateAndTimePipe.transform(new Date());
        this.taskForm.controls["name"].setValue(this.generatedName);
    }

    public getUnit(unit: Unit) {
        if (!unit) return "";
        const foundUnit = this.allUnits.find(x => x.value === unit);
        return foundUnit ? foundUnit.label : "";
    }

    //#endregion [Reactive form setup]

    //#region [Task rule controls setup]
    private resetTaskRule() {
        this.currentTaskRule = undefined;
        this.taskRuleIsSet = false;
        this.selectMaterial = false;
        this.selectLevel2Category = false;
        this.selectLevel3Category = false;
        this.selectLevel4Category = false;
        this.selectCanBeRefreshed = false;
        this.selectIsMachineWork = false;
        this.selectCanHavePrimer = false;
        this.selectShowSubstrate = false;
        this.selectShowCategories = false;
        this.selectColor = false;
        this.signMaterials = [];
        this.signCategoryValues = [];
    }

    private filterTaskStatuses() {
        if (this.task && this.taskStatuses && !this.task.id) {
            // when creating new task -> scheduled and OnHold tasks can't be selected
            this.filteredTaskStatuses = this.taskStatuses.filter(x => x.value === TaskStatus.InProgress || x.value === TaskStatus.Finished);
        } else {
            this.filteredTaskStatuses = this.taskStatuses;
        }
    }

    private applyTaskRule(task: ITask) {
        if (
            !task.currentStatus.taskTypeId ||
            !this.taskTypes ||
            this.taskTypes.length === 0
        ) {
            this.resetTaskRule();
            return;
        }
        const rule = this.taskRules
            ? this.taskRules.find((r) => r.taskTypeId === task.currentStatus.taskTypeId)
            : undefined;
        if (!rule) {
            this.resetTaskRule();
            return;
        }

        this.refreshTaskRule(rule);

        const currentMaterial = this.getMaterialInfo(task.currentStatus.signMaterialId);
        this.setSelectIsMachineWork(currentMaterial);
        this.setSelectCanHavePrimer(currentMaterial);
        this.setSelectColor(currentMaterial);
        this.setCategoryValues(currentMaterial);
        this.handleCategoryChange({ value: task.currentStatus.signCategoryId }, true);
        this.handleCategoryLevel2Change({ value: task.currentStatus.signCategoryLevel2Id }, true);
        this.handleCategoryLevel3Change({ value: task.currentStatus.signCategoryLevel3Id }, true);
        this.handleCategoryLevel4Change({ value: task.currentStatus.signCategoryLevel4Id }, true);

        this.patchTaskRuleFormValues(this.task);
        this.propertyConfiguration = this.task.currentStatus.propertyConfiguration == null ? new TaskPropertyConfiguration() : this.task.currentStatus.propertyConfiguration;
        this.patchPropertyLists(this.propertyConfiguration);
        this.patchCustomValuesOnInit(task);
        this.taskRuleIsSet = true;
    }

    // Set custom values on init if visible and there's no standard list
    private patchCustomValuesOnInit(task: ITask) {
        if (this.displayCustomDiameter && this.hideDiameterList) {
            this.taskStatusForm.controls["customDiameter"].patchValue(task.currentStatus.diameter);
        }
        if (this.displayCustomBlock1Width && this.hideBlock1WidthList) {
            this.taskStatusForm.controls["customBlock1Width"].patchValue(task.currentStatus.block1Width);
        }
        if (this.displayCustomBlock2Width && this.hideBlock2WidthList) {
            this.taskStatusForm.controls["customBlock2Width"].patchValue(task.currentStatus.block2Width);
        }
        if (this.displayCustomHeight && this.hideHeightList) {
            this.taskStatusForm.controls["customHeight"].patchValue(task.currentStatus.height);
        }
        if (this.displayCustomColoredLength && this.hideColoredLengthList) {
            this.taskStatusForm.controls["customColoredLength"].patchValue(task.currentStatus.coloredLength);
        }
        if (this.displayCustomColoredWidth && this.hideColoredWidthList) {
            this.taskStatusForm.controls["customColoredWidth"].patchValue(task.currentStatus.coloredWidth);
        }
        if (this.displayCustomLength && this.hideLengthList) {
            this.taskStatusForm.controls["customLength"].patchValue(task.currentStatus.length);
        }
        if (this.displayCustomWidth && this.hideWidthList) {
            this.taskStatusForm.controls["customWidth"].patchValue(task.currentStatus.width);
        }
        if (this.displayCustomSpeed && this.hideSpeedList) {
            this.taskStatusForm.controls["customSpeed"].patchValue(task.currentStatus.speed);
        }
        if (this.displayCustomText && this.hideTextList) {
            this.taskStatusForm.controls["customText"].patchValue(task.currentStatus.text);
        }
    }
    private refreshTaskRule(rule: ITaskRule) {
        this.currentTaskRule = rule;
        this.setSelectMaterial(rule);
        this.setMaterialValues(rule);
        this.setSelectCanBeRefreshed(rule);
        this.setSelectShowSubstrate(rule);
        this.setSelectShowCategories(rule);
        this.resetCategoryLevels();
        this.setSelectColor(undefined);
        this.setSelectIsMachineWork(undefined);
        this.setSelectCanHavePrimer(undefined);
    }

    private setSelectIsMachineWork(material: ISignMaterialInfo) {
        this.selectIsMachineWork = material && material.canBeMachineWork;
    }
    private setSelectCanHavePrimer(material: ISignMaterialInfo) {
        this.selectCanHavePrimer = material && material.canHavePrimer;
    }
    private setSelectShowCategories(rule: ITaskRule) {
        this.selectShowCategories = rule && rule.showCategories;
    }
    private setSelectShowSubstrate(rule: ITaskRule) {
        this.selectShowSubstrate = rule && rule.showSubstrates;
    }

    private setSelectColor(material: ISignMaterialInfo) {
        this.selectColor = !!material && material.requiresColor;
        this.refreshRequiredValidator("customSignColor", () => this.selectColor && this.propertyConfiguration && !this.propertyConfiguration.showSignColorList);
        this.refreshRequiredValidator("signColorId", () => this.selectColor && this.propertyConfiguration && this.propertyConfiguration.showSignColorList);
        this.setDefaultColor();
    }

    private setDefaultColor() {
        if (this.selectColor && this.propertyConfiguration && !this.propertyConfiguration.showSignColorList && !this.taskStatusForm.controls["customSignColor"].value) {
            this.taskStatusForm.controls["customSignColor"].patchValue(
                this.propertyConfiguration.defaultCustomSignColor ? this.propertyConfiguration.defaultCustomSignColor : this.defaultSignColor);
        }
    }

    private resetCategoryLevels() {
        this.setLevel2Category(undefined);
        this.setLevel3Category(undefined);
        this.setLevel4Category(undefined);
    }

    // Category Levels
    private setLevel2Category(category: ISignCategory, initialCall = false) {
        this.selectLevel2Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel2Id", () => this.selectLevel2Category);
        this.signCategoryLevel2Values = this.getCategoryValues(this.selectLevel2Category, category);
        this.taskStatusForm.controls["signCategoryLevel2Id"].patchValue(null);
        if (category != null && !this.selectLevel2Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel2Category);
        }
    }
    private setLevel3Category(category: ISignCategory, initialCall = false) {
        this.selectLevel3Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel3Id", () => this.selectLevel3Category);
        this.signCategoryLevel3Values = this.getCategoryValues(this.selectLevel3Category, category);
        this.taskStatusForm.controls["signCategoryLevel3Id"].patchValue(null);
        if (category != null && !this.selectLevel3Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel3Category);
        }
    }
    private setLevel4Category(category: ISignCategory, initialCall = false) {
        this.selectLevel4Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel4Id", () => this.selectLevel4Category);
        this.signCategoryLevel4Values = this.getCategoryValues(this.selectLevel4Category, category);
        this.taskStatusForm.controls["signCategoryLevel4Id"].patchValue(null);
        if (category != null && !this.selectLevel4Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel4Category);
        }
    }

    /**
     * Apply configuration based on selected category structure
     * Only if it's a valid category AND there are no sublevels
     */
    private applyNewPropertyConfiguration(category: ISignCategory, hasNextLevel: boolean) {
        if (category != null && !hasNextLevel) {
            this.resetTaskProperties();
            this.propertyConfiguration = category.taskPropertyConfiguration == null ? new TaskPropertyConfiguration :
                category.taskPropertyConfiguration;
            this.resetValidatorsPropertyConfiguration();
            this.patchPropertyLists(this.propertyConfiguration);
        }
    }
    private canSelectLevelCategory(category: ISignCategory) {
        return !!category &&
            this.allSignCategories.findIndex(cs => cs.parentId === category.signCategoryId) > -1;
    }
    private getCategoryValues(selectLevelCategory: boolean, category: ISignCategory) {
        if (selectLevelCategory) {
            let filteredSignCategories = [];
            if (this.taskStatusForm.controls["isMachineWork"].value === true) {
                filteredSignCategories = this.allSignCategories
                    .filter((cs) => cs.parentId === category.signCategoryId && cs.canBeMachineWork);
            } else {
                filteredSignCategories = this.allSignCategories
                    .filter((cs) => cs.parentId === category.signCategoryId && cs.canBeHandWork);
            }
            filteredSignCategories = filteredSignCategories.sortBy(x => x.displayOrder);
            const filteredValues = this.allSignCategoryValues.filter(
                (m) => filteredSignCategories.findIndex((c) => c.signCategoryId === m.value) > -1
            );
            return filteredValues.sort(function (a, b) {
                return filteredSignCategories.findIndex(item => item.signCategoryId === a.value)
                    - filteredSignCategories.findIndex(item => item.signCategoryId === b.value);
            });
        }

    }
    private setSelectCanBeRefreshed(rule: ITaskRule) {
        this.selectCanBeRefreshed = rule && rule.canBeRefreshed;
    }

    private setSelectMaterial(rule: ITaskRule) {
        this.selectMaterial =
            !!rule && !!rule.signMaterialInfo && rule.signMaterialInfo.length > 0;
    }

    private setMaterialValues(rule: ITaskRule) {
        this.signMaterials =
            rule && rule.signMaterialInfo
                ? this.allSignMaterials.filter(
                    (m) =>
                        rule.signMaterialInfo.findIndex(
                            (smi) => smi.signMaterialId === m.value
                        ) > -1
                )
                : [];
    }

    private getMaterialInfo(selectedMaterial: SignMaterial) {
        if (!this.currentTaskRule || !this.currentTaskRule.signMaterialInfo) {
            return;
        }

        return this.currentTaskRule.signMaterialInfo.find(
            (m) => m.signMaterialId === selectedMaterial
        );
    }

    private setCategoryValues(material: ISignMaterialInfo) {
        let categories = [];
        if (!material) {
            if (this.currentTaskRule.isMarking) {
                categories = this.allSignCategories.filter(sc => !sc.parentId && sc.isMarking === this.currentTaskRule.isMarking);
            } else {
                categories = this.allSignCategories.filter(sc => !sc.parentId && sc.isMarking === this.currentTaskRule.isMarking
                    && sc.taskTypeId === this.currentTaskRule.taskTypeId);
            }
        } else {
            const isMachineWork =
                material.canBeMachineWork &&
                this.taskStatusForm.controls["isMachineWork"].value === true;

            if (isMachineWork) {
                if (this.currentTaskRule.isMarking) {
                    categories = this.allSignCategories.filter(sc => !sc.parentId && sc.canBeMachineWork
                        && sc.isMarking === this.currentTaskRule.isMarking);
                } else {
                    categories = this.allSignCategories.filter(sc => !sc.parentId && sc.canBeMachineWork
                        && sc.isMarking === this.currentTaskRule.isMarking && sc.taskTypeId === this.currentTaskRule.taskTypeId);
                }
            } else {
                if (this.currentTaskRule.isMarking) {
                    categories = this.allSignCategories.filter(sc => !sc.parentId && sc.canBeHandWork
                        && sc.isMarking === this.currentTaskRule.isMarking);
                } else {
                    categories = this.allSignCategories.filter(sc => !sc.parentId && sc.canBeHandWork
                        && sc.isMarking === this.currentTaskRule.isMarking && sc.taskTypeId === this.currentTaskRule.taskTypeId);
                }
            }
        }
        if (categories) {
            categories = categories.sortBy(x => x.displayOrder);
            const filteredValues = this.allSignCategoryValues.filter(
                (m) => categories.findIndex((c) => c.signCategoryId === m.value) > -1
            );
            this.signCategoryValues = filteredValues.sort(function (a, b) {
                return categories.findIndex(item => item.signCategoryId === a.value)
                    - categories.findIndex(item => item.signCategoryId === b.value);
            });
        } else {
            this.signCategoryValues = [];
        }
    }

    private resetTaskProperties() {
        this.resetFormControlValues(
            "note",
            "customSignColor",
            "signColorId",
            "markingsCount",
            "hoursCount",
            "minutesCount",
            "length",
            "customLength",
            "width",
            "customWidth",
            "coloredLength",
            "coloredWidth",
            "customColoredWidth",
            "dropdownDimension",
            "customDimensionLength",
            "customDimensionWidth",
            "customDimensionDescription",
            "type",
            "bannerCount",
            "logoCount",
            "diameter",
            "customDiameter",
            "customLElementWidth",
            "customLElementLength",
            "dropdownLElementDimension",
            "tElementCount",
            "lElementCount",
            "tramElementCount",
            "height",
            "customHeight",
            "text",
            "customText",
            "speed",
            "customSpeed",
            "placenumber",
            "block1Amount",
            "block1Width",
            "customBlock1Width",
            "block2Amount",
            "block2Width",
            "customBlock2Width",
            "lengthA",
            "lengthB",
            "lengthC",
            "lengthD",
            "whiteSubstrateLength",
            "whiteSubstrateWidth",
            "whiteBandCount",
            "redBandCount",
            "yellowBandCount",
            "orangeBandCount",
            "greenBandCount",
            "blueBandCount",
            "purpleBandCount",
            "bendCount",
            "arrowHeadCount",
            "roundedEndCount",
            "shortPieceCount"
        );
        this.displayCustomLength = false;
        this.hideLengthList = false;
        this.displayCustomWidth = false;
        this.hideWidthList = false;
        this.displayCustomColoredWidth = false;
        this.hideColoredWidthList = false;
        this.displayCustomColoredLength = false;
        this.hideColoredLengthList = true;
        this.displayCustomDimension = false;
        this.hideDimensionList = false;
        this.displayCustomDiameter = false;
        this.displayCustomBlock1Width = false;
        this.displayCustomBlock2Width = false;
        this.hideDiameterList = false;
        this.hideBlock1WidthList = false;
        this.hideBlock2WidthList = false;
        this.displayCustomLDimension = false;
        this.hideLElementDimensionList = false;
        this.displayCustomTDimension = false;
        this.hideTElementDimensionList = false;
        this.displayCustomHeight = false;
        this.hideHeightList = false;
        this.displayCustomText = false;
        this.hideTextList = false;
        this.displayCustomSpeed = false;
        this.displayCustomSignColor = false;
        this.hideSpeedList = false;
    }
    private resetValidatorsPropertyConfiguration() {
        this.refreshRequiredValidator("customSignColor", () => this.selectColor && this.propertyConfiguration && !this.propertyConfiguration.showSignColorList);
        this.refreshRequiredValidator("signColorId", () => this.selectColor && this.propertyConfiguration && this.propertyConfiguration.showSignColorList);
    }

    initializeWorkers(): void {
        const searchParameters = new SearchParameters();
        searchParameters.sort = [];
        searchParameters.sort.push(new SortDescriptor(SortDirection.ascending, "FirstName"));

        this.workerApi.getAll$(searchParameters).pipe(map((workers: IWorker[], index: number) => {
            return workers.map((worker) => {
                return {
                    label: `${worker.firstName} ${worker.lastName}`,
                    value: worker.id
                } as SelectItem;
            });
        })).subscribe({
            next: (workers) => {
                this.workers = workers;
            },
            error: (error) => {
                this.workers = [];
            }
        });
    }

    //#endregion [Task rule controls setup]

    //#region [Server calls]
    async submit() {
        const isValid = await this.formValidationService.checkValidity(
            this.taskForm
        );
        if (!isValid) return;

        if (this.isCreatingNewTask()) {
            this.createNew();
        } else {
            this.update();
        }
    }

    isCreatingNewTask(): boolean {
        return this.task && !this.task.id; // check if id is falsy
    }

    private createNew() {
        const taskCreator = Object.assign(
            new TaskCreator(),
            this.taskForm.getRawValue()
        ) as TaskCreator;

        taskCreator.location = {
            coordinate: this.task.location.coordinate
        } as LocationCreator;

        if (this.taskStatusForm.get("workerIds")?.value && (<number[]>this.taskStatusForm.get("workerIds").value)?.length > 0) {
            taskCreator.currentStatus.workerTasks = (<number[]>this.taskStatusForm.get("workerIds").value).map((workerId: number) => {
                return {
                    workerId: workerId
                } as WorkerTaskCreator;
            });
        } else {
            taskCreator.currentStatus.workerTasks = null;
        }
        this.setCustomValuesOnTask(taskCreator);

        const onSuccess = async (newTask: ITask) =>
            this.onSaveSuccess(newTask);
        this.submitting = true;

        this.taskApi
            .create$(taskCreator)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    private update() {
        const onSuccess = async (savedTask: ITask) =>
            this.onSaveSuccess(savedTask);
        const updatedTask = Object.assign(
            new TaskUpdater(),
            this.task,
            this.taskForm.getRawValue()
        ) as TaskUpdater;
        updatedTask.locationId = this.task.location.id;

        if (this.taskStatusForm.get("workerIds")?.value && (<number[]>this.taskStatusForm.get("workerIds").value)?.length > 0) {
            updatedTask.currentStatus.workerTasks = (<number[]>this.taskStatusForm.get("workerIds").value).map((workerId: number) => {
                return {
                    workerId: workerId
                } as WorkerTaskCreator;
            });
        } else {
            updatedTask.currentStatus.workerTasks = null;
        }

        this.setCustomValuesOnTask(updatedTask);

        this.submitting = true;

        this.taskApi
            .update$(updatedTask)
            .subscribe(onSuccess, this.onSaveError.bind(this));
    }

    public setCustomValuesOnTask(creator: TaskCreator) {
        creator.currentStatus.isNew = this.setSelectCanBeRefreshed ? creator.currentStatus.isNew : true;
        creator.currentStatus.propertyConfiguration = this.propertyConfiguration;
        creator.currentStatus.customSignColor = this.selectColor && !this.propertyConfiguration.showSignColorList ? creator.currentStatus.customSignColor : undefined;
        creator.currentStatus.signColorId = this.selectColor && this.propertyConfiguration.showSignColorList ? creator.currentStatus.signColorId : undefined;
        creator.currentStatus.width = this.propertyConfiguration.showWidth ?
            this.displayCustomWidth ? this.taskStatusForm.get("customWidth").value : creator.currentStatus.width
            : undefined;
        creator.currentStatus.widthUnitId = this.propertyConfiguration.showWidth ? this.propertyConfiguration.widthUnit : undefined;
        creator.currentStatus.length = this.propertyConfiguration.showLength ?
            this.displayCustomLength ? this.taskStatusForm.get("customLength").value : creator.currentStatus.length
            : undefined;
        creator.currentStatus.lengthUnitId = this.propertyConfiguration.showLength ? this.propertyConfiguration.lengthUnit : undefined;
        creator.currentStatus.height = this.propertyConfiguration.showHeight ?
            this.displayCustomHeight ? this.taskStatusForm.get("customHeight").value : creator.currentStatus.height
            : undefined;
        creator.currentStatus.heightUnitId = this.propertyConfiguration.showHeight ? this.propertyConfiguration.heightUnit : undefined;
        creator.currentStatus.coloredWidth = this.propertyConfiguration.showColoredWidth ?
            this.displayCustomColoredWidth ? this.taskStatusForm.get("customColoredWidth").value : creator.currentStatus.coloredWidth
            : undefined;
        creator.currentStatus.coloredWidthUnit = this.propertyConfiguration.showColoredWidth ? this.propertyConfiguration.coloredWidthUnit : undefined;
        creator.currentStatus.coloredLength = this.propertyConfiguration.showColoredLength ?
            this.displayCustomColoredLength ? this.taskStatusForm.get("customColoredLength").value : creator.currentStatus.coloredLength
            : undefined;
        creator.currentStatus.coloredLengthUnit = this.propertyConfiguration.showColoredLength ? this.propertyConfiguration.coloredLengthUnit : undefined;
        creator.currentStatus.whiteSubstrateLengthUnitId = this.propertyConfiguration.showWhiteSubstrateLength ? this.propertyConfiguration.whiteSubstrateLengthUnit : undefined;
        creator.currentStatus.whiteSubstrateWidthUnitId = this.propertyConfiguration.showWhiteSubstrateWidth ? this.propertyConfiguration.whiteSubstrateWidthUnit : undefined;

        creator.currentStatus.diameter = this.propertyConfiguration.showDiameter ?
            this.displayCustomDiameter ? this.taskStatusForm.get("customDiameter").value : creator.currentStatus.diameter
            : undefined;
        creator.currentStatus.diameterUnitId = this.propertyConfiguration.showDiameter ? this.propertyConfiguration.diameterUnit : undefined;
        creator.currentStatus.block1Width = this.propertyConfiguration.showBlock1Width ?
            this.displayCustomBlock1Width ? this.taskStatusForm.get("customBlock1Width").value : creator.currentStatus.block1Width
            : undefined;
        creator.currentStatus.block2Width = this.propertyConfiguration.showBlock2Width ?
            this.displayCustomBlock2Width ? this.taskStatusForm.get("customBlock2Width").value : creator.currentStatus.block2Width
            : undefined;
        creator.currentStatus.blocksWidthUnitId = this.propertyConfiguration.showBlock1Amount || this.propertyConfiguration.showBlock2Amount ? this.propertyConfiguration.blocksWidthUnit : undefined;
        creator.currentStatus.speed = this.propertyConfiguration.showSpeed ?
            this.displayCustomSpeed ? this.taskStatusForm.get("customSpeed").value : creator.currentStatus.speed
            : undefined;
        creator.currentStatus.text = this.propertyConfiguration.showText ?
            this.displayCustomText ? this.taskStatusForm.get("customText").value : creator.currentStatus.text
            : undefined;
        creator.currentStatus.type = this.propertyConfiguration.showType ? creator.currentStatus.type
            : undefined;
        creator.currentStatus.dimensionLength = this.propertyConfiguration.showDimension ?
            this.displayCustomDimension ? this.taskStatusForm.get("customDimensionLength").value : this.taskStatusForm.get("dropdownDimension").value.length
            : undefined;
        creator.currentStatus.dimensionWidth = this.propertyConfiguration.showDimension ?
            this.displayCustomDimension ? this.taskStatusForm.get("customDimensionWidth").value : this.taskStatusForm.get("dropdownDimension").value.width
            : undefined;
        creator.currentStatus.dimensionDescription = this.propertyConfiguration.showDimension ?
            this.displayCustomDimension ? this.taskStatusForm.get("customDimensionDescription").value : this.taskStatusForm.get("dropdownDimension").value.description
            : undefined;
        creator.currentStatus.dimensionUnitId = this.propertyConfiguration.showDimension ? this.propertyConfiguration.dimensionUnit : undefined;
        creator.currentStatus.lElementWidth = this.propertyConfiguration.showLElementDimension ?
            this.displayCustomLDimension ? this.taskStatusForm.get("customLElementWidth").value : this.taskStatusForm.get("dropdownLElementDimension").value.width
            : undefined;
        creator.currentStatus.lElementLength = this.propertyConfiguration.showLElementDimension ?
            this.displayCustomLDimension ? this.taskStatusForm.get("customLElementLength").value : this.taskStatusForm.get("dropdownLElementDimension").value.length
            : undefined;
        creator.currentStatus.lElementUnitId = this.propertyConfiguration.showLElementDimension ? this.propertyConfiguration.lElementUnit : undefined;
        creator.currentStatus.tElementLength = this.propertyConfiguration.showTElementDimension ?
            this.displayCustomTDimension ? this.taskStatusForm.get("customTElementLength").value : this.taskStatusForm.get("dropdownTElementDimension").value.length
            : undefined;
        creator.currentStatus.tElementWidth = this.propertyConfiguration.showTElementDimension ?
            this.displayCustomTDimension ? this.taskStatusForm.get("customTElementWidth").value : this.taskStatusForm.get("dropdownTElementDimension").value.width
            : undefined;
        creator.currentStatus.tElementUnitId = this.propertyConfiguration.showTElementDimension ? this.propertyConfiguration.tElementUnit : undefined;

    }

    async onSaveSuccess(savedTask: ITask) {
        this.task = savedTask;
        this.taskForm.markAsPristine();
        this.toastService.saveSuccess();
        this.submitting = false;
        this.save.emit(savedTask);
    }

    async onSaveError() {
        this.submitting = false;
    }

    private initDropdowns() {
        const substrateTypeOptions =
            { includeEmpty: true, emptyLabel: "substrateType.other", addEmptyOptionAtEnd: true, emptyOptionValue: null } as ViewModelEnumOptions;

        zip(
            this.taskRulesApi.getAll$(),
            this.domainDataService.get(DomainData.TaskType),
            this.domainDataService.get(DomainData.SignMaterial),
            this.signCategoryApi.search$(),
            this.domainDataService.get(DomainData.SignCategoryValue),
            this.domainDataService.get(DomainData.SubstrateType, substrateTypeOptions),
            this.domainDataService.get(DomainData.MarkingPropertyType),
            this.domainDataService.get(DomainData.SignColorType),
            this.domainDataService.get(DomainData.Unit),
            this.domainDataService.get(DomainData.TaskStatus),

        ).subscribe(
            (
                result: [
                    ITaskRule[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ISearchResult<ISignCategory>,
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[],
                    ViewModelEnum[]
                ]
            ) => {
                this.taskRules = result[0];
                this.taskTypes = result[1];
                this.allSignMaterials = result[2];
                this.allSignCategories = result[3].data;
                this.allSignCategoryValues = result[4];
                this.allSubstrateTypes = result[5];
                this.allMarkingPropertyTypeValues = result[6];
                this.allSignColorOptions = result[7];
                this.allUnits = result[8];
                this.taskStatuses = result[9];
                this.dropdownValuesFetched.next(true);
            });

        this.initializeWorkers();
    }
    //#endregion [Server calls]

    //#region [Helpers]
    private resetFormControlValues(...formControlNames: string[]) {
        formControlNames.forEach((controlName) => {
            if (!this.taskForm.controls[controlName]) {
                if (!this.taskStatusForm.controls[controlName]) {
                    return;
                }

                this.taskStatusForm.controls[controlName].setValue(null);
                return;
            }
            this.taskForm.controls[controlName].setValue(null);
        });
    }


    private refreshRequiredValidator(
        controlName: string,
        condition: () => boolean
    ) {
        if (condition()) {
            if (this.taskForm.controls[controlName]) {
                this.taskForm.controls[controlName].setValidators([Validators.required]);
            } else {
                this.taskStatusForm.controls[controlName].setValidators([Validators.required]);
            }
        } else {
            if (this.taskForm.controls[controlName]) {
                this.taskForm.controls[controlName].clearValidators();
            } else {
                this.taskStatusForm.controls[controlName].clearValidators();
            }
        }
        if (this.taskForm.controls[controlName]) {
            this.taskForm.controls[controlName].updateValueAndValidity();
        } else {
            this.taskStatusForm.controls[controlName].updateValueAndValidity();
        }
    }
    //#endregion [Helpers]
}
