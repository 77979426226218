import { DownloadedFile } from "../services/download-file.service";
import { ApiServiceBase } from "./api";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UploadsArchiveApi extends ApiServiceBase {
    getRoute(): string {
        return "UploadsArchive";
    }

    download$(params: HttpParams): Promise<DownloadedFile> {
        const url = this.getUrl();
        return this.downloadFileService.downloadBlob(url, null, null, params);
    }
}