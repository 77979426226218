import { PrimeComponentService, CalendarSettings, DateFormControl } from "src/app/services/prime-component.service";
import { Component, ViewChild, OnDestroy } from "@angular/core";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IDatePeriodSet, IDatePeriod, DatePeriodCreator } from "src/app/models/date-period-set";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { ViewModelEnum } from "src/app/models/domain-data";
import { Calendar } from "primeng/calendar";
import { DatePeriodApi } from "src/app/resource/date-period.api";

@Component({
    selector: "app-manage-date-period-dialog",
    templateUrl: "./manage-date-period.dialog.html"
})
export class ManageDatePeriodDialogComponent extends DialogComponentBase implements OnDestroy {
    @ViewChild("calendar", { static: false }) set setCalendar(calendar: Calendar) {
        this.datesControl.setCalendar(calendar);
    }

    submitting: boolean;
    callback: (res: IDatePeriod) => void;
    dialogForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;
    datePeriodTypes: ViewModelEnum[];

    datesControl: DateFormControl;

    datePeriodSet: IDatePeriodSet;
    existingDatePeriod: IDatePeriod;

    constructor(
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly domainDataService: DomainDataService,
        private readonly datePeriodApi: DatePeriodApi) {

        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.domainDataService.get(DomainData.DatePeriodType).then(datePeriodTypes => {
            this.datePeriodTypes = datePeriodTypes;
        });

        this.datesControl = new DateFormControl(null, null, Validators.required);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(datePeriodSet: IDatePeriodSet, callback?: (res: IDatePeriod) => void) {
        this.callback = callback;
        this.datePeriodSet = datePeriodSet;
        this.existingDatePeriod = null;

        this.openDialog();
    }

    edit(datePeriodSet: IDatePeriodSet, existingDatePeriod: IDatePeriod, callback?: (res: IDatePeriod) => void) {
        this.callback = callback;
        this.datePeriodSet = datePeriodSet;
        this.existingDatePeriod = existingDatePeriod;

        this.openDialog();
    }

    protected onOpen() {
        this.datesControl.patchValue(null);

        this.dialogForm = this.formBuilder.group({
            name: ["", Validators.required],
            typeId: [null, Validators.required],
            dates: this.datesControl
        }) as SigncoFormGroup;

        if (this.existingDatePeriod) {
            this.dialogForm.patchValue(this.existingDatePeriod);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.dialogForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (datePeriod: IDatePeriod) => {
            if (this.existingDatePeriod) {
                Object.assign(this.existingDatePeriod, datePeriod);
            }

            if (this.callback) {
                this.callback(datePeriod);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const creator = this.dialogForm.value as DatePeriodCreator;
        creator.dates = this.datesControl.getValueAsMultiple().map(x => x.toMetanousDateString());
        creator.datePeriodSetId = this.datePeriodSet.id;

        if (this.existingDatePeriod) {
            creator.id = this.existingDatePeriod.id;
            this.datePeriodApi.update$(creator).subscribe({ next: onSuccess, error: onError });
        } else {
            this.datePeriodApi.create$(creator).subscribe({ next: onSuccess, error: onError });
        }
    }
}