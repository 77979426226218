import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";

import { SharePageComponent } from "./components/share-page/share-page.component";
import { SharedKeysComponent } from "./components/shared-keys/shared-keys.component";
import { ImportedKeysComponent } from "./components/imported-keys/imported-keys.component";
import { ManageSharedKeyComponent } from "./components/manage-shared-key/manage-shared-key.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    {
        path: "", component: SharePageComponent, canActivate: [AuthGuard], children: [
            { path: "", redirectTo: "keys", pathMatch: "full" },
            { path: "keys", component: SharedKeysComponent, data: { name: "sharedKeys.title" } },
            { path: "imported", component: ImportedKeysComponent, data: { name: "importedSharedKeys.title" } },
        ]
    },
    { path: "keys/:key", component: ManageSharedKeyComponent, canActivate: [AuthGuard], data: { name: "sharedKeys.title" }, canDeactivate: [PendingChangesGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ShareDataRoutingModule { }
