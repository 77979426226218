import { Component, Input, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { IParkingBan } from "src/app/models/parking-ban";
import { ManageSignScanDialogComponent } from "src/app/modules/assignments/components/manage-sign-scan-dialog/manage-sign-scan-dialog.component";
import { SignScansComponent } from "src/app/modules/assignments/components/sign-scans/sign-scans.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-sign-scans-container",
    templateUrl: "./sign-scans-container.component.html"
})
export class SignScansContainerComponent implements OnDestroy {

    @Input() parkingBan: IParkingBan;

    @ViewChild(ManageSignScanDialogComponent, { static: true }) manageSignScanComponent: ManageSignScanDialogComponent;
    @ViewChild(SignScansComponent, { static: false }) signScansComponent: SignScansComponent;

    canCreateSignScan = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateSignScan = rights?.hasBackendRight(BackendRights.EditTask);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);


    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    create() {
        if (!this.parkingBan) return;

        const callback = () => this.signScansComponent.reload();
        this.manageSignScanComponent.create(this.parkingBan, callback);
    }

    setParkingBan(parkingBan: IParkingBan) {
        this.parkingBan = parkingBan;
    }
}
