import { AnalysisType, IMeasuringPoint, IMeasuringPointNavigator } from "../models/measuring-point";
import { ILocationSummary } from "../models/web";
import { IMeasuringPointSummary } from "../models/web";

export class MeasuringPointUtils {
    static supportsUpload(measuringPoint: IMeasuringPoint | IMeasuringPointSummary): boolean {
        return measuringPoint &&
            measuringPoint.analysisTypeId !== AnalysisType.Intersection &&
            measuringPoint.analysisTypeId !== AnalysisType.Aggregated;
    }

    static supportsAggregation(measuringPoint: IMeasuringPoint | IMeasuringPointSummary): boolean {
        return measuringPoint &&
            measuringPoint.analysisTypeId !== AnalysisType.Intersection &&
            measuringPoint.analysisTypeId !== AnalysisType.Aggregated;
    }

    static supportsValidation(measuringPoint: IMeasuringPoint): boolean {
        return measuringPoint &&
            measuringPoint.analysisTypeId !== AnalysisType.Intersection &&
            (
                !measuringPoint.sharedKey ||
                measuringPoint.sharedKey.allowValidation
            );
    }

    static supportsSharing(measuringPoint: IMeasuringPoint): boolean {
        return measuringPoint &&
            !measuringPoint.sharedKey;
    }

    static supportsDirectionalRotation(measuringPoint: IMeasuringPoint | IMeasuringPointSummary): boolean {
        return measuringPoint && this.analysisTypeSupportsDirectionalRotation(measuringPoint.analysisTypeId);
    }

    static analysisTypeSupportsDirectionalRotation(analysisTypeId: string): boolean {
        return analysisTypeId !== AnalysisType.Intersection && analysisTypeId !== AnalysisType.Parking && analysisTypeId !== AnalysisType.Tracker;
    }

    static analysisTypeRequiresDrivingLane(analysisTypeId: string): boolean {
        return analysisTypeId !== AnalysisType.Intersection &&
            analysisTypeId !== AnalysisType.ExternalInput &&
            analysisTypeId !== AnalysisType.Light &&
            analysisTypeId !== AnalysisType.Vms &&
            analysisTypeId !== AnalysisType.Parking &&
            analysisTypeId !== AnalysisType.RailTransport &&
            analysisTypeId !== AnalysisType.Tracker &&
            analysisTypeId !== AnalysisType.CountingZone &&
            analysisTypeId !== AnalysisType.TrafficLight &&
            analysisTypeId !== AnalysisType.Vehicle &&
            analysisTypeId !== AnalysisType.ArrowBoard;
    }

    static toNavigator(measuringPoint: IMeasuringPoint | IMeasuringPointSummary): IMeasuringPointNavigator {
        if (!measuringPoint) return null;

        return {
            id: measuringPoint.id,
            locationId:
                (measuringPoint as IMeasuringPoint).location ?
                    (measuringPoint as IMeasuringPoint).location.id :
                    (measuringPoint as IMeasuringPointSummary).locationId || null,
            code: measuringPoint.code,
            isObsolete: false
        } as IMeasuringPointNavigator;
    }

    static toSummary(measuringPoint: IMeasuringPoint): IMeasuringPointSummary {
        if (!measuringPoint) return null;

        const result = {
            id: measuringPoint.id,
            code: measuringPoint.code,
            locationId: measuringPoint.locationId,
            description: measuringPoint.description,
            analysisTypeId: measuringPoint.analysisTypeId,
            drivingLaneId: measuringPoint.drivingLane ? measuringPoint.drivingLane.id : null,
            from: measuringPoint.from,
            to: measuringPoint.to,
            fromTo: measuringPoint.fromTo,
            ownerId: measuringPoint.ownerId
        } as unknown as IMeasuringPointSummary;

        if (measuringPoint.location) {
            result.location = {
                id: measuringPoint.location.id,
                code: measuringPoint.location.code,
                iconStateId: measuringPoint.location.mapIconStateId ?? 0,
                lat: measuringPoint.location.coordinate.latitude,
                lng: measuringPoint.location.coordinate.longitude,
                ownerId: measuringPoint.location.ownerId,
            } as ILocationSummary;
        }
        return result;
    }
}
