import { TableColumn, ApiTableComponent, FilterType, ColumnVisibility, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ElementRef, OnInit } from "@angular/core";
import { ImportedSharedKeyApi } from "src/app/resource/imported-shared-key.api";
import { IImportedSharedKey } from "src/app/models/imported-shared-key";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { ToastService } from "src/app/services/toast.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-imported-keys",
    templateUrl: "./imported-keys.component.html"
})
export class ImportedKeysComponent extends ApiTableComponent<IImportedSharedKey> implements OnInit {
    @Input() deleteCommand = true;

    importKey: string;
    submitting = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly importedSharedKeyApi: ImportedSharedKeyApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService) {

        super("imported-keys.component", elementRef, importedSharedKeyApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;
        this.footer = false;
        this.selectionMode = "";

        const organizationColumn = new TableColumn("organization.name", "sharedKeys.importedBy",
            {
                filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false,
                hidden: true, displayDropdownFilter: true
            });

        this.addColumn(organizationColumn);

        this.globalEventsService.currentRights$.subscribe(rights => {
            this.rights = rights;
            organizationColumn.hidden = !this.globalEventsService.hasMultipleOrganizations() && !this.rights.hasBackendRight(BackendRights.EditImportedSharedKey);
        });

        this.addColumn(new TableColumn("key", "sharedKeys.key", { filterType: FilterType.Text, sortable: true, width: 300 }));
        this.addColumn(new TableColumn("allowValidation", "sharedKeys.allowValidationHeader", { filterType: FilterType.Boolean, sortable: true, visibility: ColumnVisibility.HideMini, width: 145 }));

        const ownerColumn = new TableColumn("owner.name", "sharedKeys.owner", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 250, resizable: false });
        this.addColumn(ownerColumn);

        this.addColumn(new TableColumn("shareAllMeasuringPoints", "sharedKeys.shareAllMeasuringPointsHeader", { filterType: FilterType.Boolean, sortable: true, visibility: ColumnVisibility.HideMini, width: 125 }));
        this.addColumn(new TableColumn("measuringPoints", "general.measuringPoints", { visibility: ColumnVisibility.HideCompact }));

        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            const filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
            organizationColumn.filterOptions = filterOptions;
            ownerColumn.filterOptions = filterOptions;
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (importedSharedKey) => this.delete(importedSharedKey),
            validFunc: () => this.deleteCommand && this.rights.hasBackendRight(BackendRights.DeleteImportedSharedKey)
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    delete(importedSharedKey: IImportedSharedKey) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.importedSharedKeyApi.delete$(importedSharedKey.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("importedSharedKeys.deleteConfirmation", { key: importedSharedKey.key });
        this.modalService.delete(modalBody, onDelete);
    }

    import() {
        const onSuccess = async () => {
            this.importKey = "";
            this.toastService.clearErrors();
            this.submitting = false;

            // reload measuring points
            await this.services.mapDataService.loadMeasuringPointLocations();

            this.reload();
        };

        const onError = () => {
            this.submitting = false;
            this.toastService.error("importedSharedKeys.invalidKey");

            // Suppress the "Ok" error from the call
            this.toastService.suppressErrors = true;
            setTimeout(() => {
                this.toastService.suppressErrors = false;
            });
        };

        this.submitting = true;
        this.importedSharedKeyApi.import$(this.importKey).subscribe(onSuccess, onError);
    }
}