import { ChangeDetectorRef, ElementRef, Component, OnDestroy, Type, Input, EventEmitter, Output, OnChanges, SimpleChanges } from "@angular/core";
import { IParkingBan } from "src/app/models/parking-ban";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { ParkingBanDetailComponent } from "../parking-ban-detail/parking-ban-detail.component";
import { ParkingBanExceptionsContainerComponent } from "../parking-ban-exceptions-container/parking-ban-exceptions-container.component";
import { ParkingBanImagesContainerComponent } from "../parking-ban-images-container/parking-ban-images-container.component";
import { SignScansContainerComponent } from "../sign-scans-container/sign-scans-container.component";
import { ParkingBanDataService } from "../../services/parking-ban-data.service";

type ParkingBanDetailContainerComponents =
    ParkingBanDetailComponent |
    SignScansContainerComponent |
    ParkingBanExceptionsContainerComponent |
    ParkingBanImagesContainerComponent;

@Component({
    selector: "app-parking-ban-detail-container",
    templateUrl: "./parking-ban-detail-container.component.html"
})
export class ParkingBanDetailContainerComponent extends TabBase<ParkingBanDetailContainerComponents> implements OnDestroy, OnChanges {

    @Input() parkingBan: IParkingBan;

    @Output() save = new EventEmitter<IParkingBan>();

    loading: boolean;

    constructor(
        elementRef: ElementRef,
        cd: ChangeDetectorRef,
        tabService: TabService,
        private readonly parkingBanDataService: ParkingBanDataService) {
        super(tabService, cd);

        this.addTab({ component: ParkingBanDetailComponent, url: "details", translatePath: "parkingBanDetailTab.title", icon: "info" });
        this.addTab({ component: ParkingBanImagesContainerComponent, url: "photos", translatePath: "parkingBan.photos", icon: "picture" });
        this.addTab({ component: SignScansContainerComponent, url: "signscans", translatePath: "signScansTab.title", icon: "qr-code" });
        this.addTab({ component: ParkingBanExceptionsContainerComponent, url: "parkingbanexceptions", translatePath: "parkingBanExceptionsTab.title", icon: "license-plate" });

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.updateActiveComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisabled();
        this.filterTabs();
        this.updateActiveComponent();

        const parkingBanChange = changes["parkingBan"];
        if (parkingBanChange) {
            this.onSelectedTabChange();
            this.updateTitle();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected onSelectedTabChange() {
        if (!this.activeComponent) return;

        this.subscriptionManager.remove("save");
        this.activeComponent.setParkingBan(this.parkingBan);
        this.subscribeToParkingBanDetailChanges();
        this.updateDisabled();
    }

    private subscribeToParkingBanDetailChanges() {
        if (this.activeComponent instanceof ParkingBanDetailComponent) {
            const saveSubscription = this.activeComponent.save.subscribe((parkingBan: IParkingBan) => {
                this.parkingBan = parkingBan;
                this.filterTabs();
                this.updateTitle();
                this.save.emit(parkingBan);
            });

            this.subscriptionManager.add("save", saveSubscription);
        }


        const parkingBanChangeSubscription = this.parkingBanDataService.parkingBanChange$.subscribe(async (parkingBan) => {
            this.parkingBan = parkingBan;
        });
        this.subscriptionManager.add("parkingBanChange", parkingBanChangeSubscription);

    }

    private updateDisabled() {
        const disabledExpression = (type: Type<ParkingBanDetailContainerComponents>) => {
            if (type instanceof ParkingBanDetailComponent) return false;

            // Can't navigate to other tabs when creating a new parking ban
            return !this.parkingBan || !this.parkingBan.id;
        };

        this.setTabsDisabledState(disabledExpression);
    }
}
