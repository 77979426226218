import { CustomTableComponent, TableColumn, TableService, ColumnType } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnInit, ElementRef } from "@angular/core";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";

@Component({
    selector: "app-release-packages",
    templateUrl: "./release-packages.component.html"
})
export class ReleasePackagesComponent extends CustomTableComponent<ViewModelEnum> implements OnInit {
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() downloadCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly domainDataService: DomainDataService) {

        super("release-packages.component", elementRef, tableService);

        this.stretchHeight = true;
        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("label", "releases.package"));
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    loadTableRows() {
        this.domainDataService.get(DomainData.Package).then(packageViewModels => {
            this.setData(packageViewModels);
        });
    }
}