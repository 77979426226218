<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         sortField="sourceMeasuringPoint.code"
         dataKey="sourceMeasuringPoint.id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                [ngSwitch]="column.field">

                <div class="m-column-header">

                    <span class="m-column-title"
                          style="text-align: center;"
                          *ngSwitchCase="'primaryDirection'">

                        <app-svg-icon icon="arrow-right"
                                      tooltip="{{'measuringPoints.primaryDirection' | translate}}">
                        </app-svg-icon>
                    </span>


                    <span class="m-column-title"
                          style="text-align: center;"
                          *ngSwitchCase="'reverseDirection'">

                        <app-svg-icon icon="arrow-left"
                                      tooltip="{{'measuringPoints.reverseDirection' | translate}}">
                        </app-svg-icon>
                    </span>

                    <span *ngSwitchCase="'commands'"
                          class="m-column-title"
                          style="text-align: center;">

                        <div class="m-btn-group">
                            <app-icon-button *ngIf="duplicateCommand"
                                             [style]="{'min-height': '24px'}">

                            </app-icon-button>

                            <app-icon-button *ngIf="data?.length > 0 && deleteCommand"
                                             text="form.delete"
                                             icon="delete"
                                             [style]="{'min-height': '24px'}"
                                             (click)="clearData()">
                            </app-icon-button>
                        </div>

                    </span>

                    <ng-container *ngSwitchDefault>
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field">
                            </p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </ng-container>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-measuringPointAggregation
                 let-index="rowIndex">

        <tr [pSelectableRow]="measuringPointAggregation">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'code'">
                    <app-navigator [measuringPoint]="measuringPointAggregation.sourceMeasuringPoint"
                                   [enabled]="false"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'fromTo'">
                    <ng-container *ngIf="isForwardDirection(measuringPointAggregation); else reverseDirectionFromToTemplate">
                        {{measuringPointAggregation.sourceMeasuringPoint.from}}
                        <span class="m-arrow-right"
                              *ngIf="measuringPointAggregation.sourceMeasuringPoint.from || measuringPointAggregation.sourceMeasuringPoint.to"></span>
                        {{measuringPointAggregation.sourceMeasuringPoint.to}}
                    </ng-container>

                    <ng-template #reverseDirectionFromToTemplate>
                        {{measuringPointAggregation.sourceMeasuringPoint.to}}
                        <span class="m-arrow-right"
                              *ngIf="measuringPointAggregation.sourceMeasuringPoint.from || measuringPointAggregation.sourceMeasuringPoint.to"></span>
                        {{measuringPointAggregation.sourceMeasuringPoint.from}}
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'primaryDirection'">
                    <label class="m-input-radio">
                        <input type="radio"
                               [checked]="isForwardDirection(measuringPointAggregation)"
                               (click)="toggleReverseDirection(measuringPointAggregation)" />
                    </label>
                </ng-container>

                <ng-container *ngSwitchCase="'reverseDirection'">
                    <label class="m-input-radio">
                        <input type="radio"
                               [checked]="!isForwardDirection(measuringPointAggregation)"
                               (click)="toggleReverseDirection(measuringPointAggregation)" />
                    </label>
                </ng-container>

                <ng-container *ngSwitchCase="'factor'">
                    <input type="number"
                           step="0.01"
                           class="p-inputtext"
                           [(ngModel)]="measuringPointAggregation.factor"
                           (blur)="updateIsDirty()">
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(measuringPointAggregation)"
                                             *ngIf="command.rowValidFunc(measuringPointAggregation)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{measuringPointAggregation[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>