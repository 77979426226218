import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MeasuringPointsComponent } from "src/app/modules/shared/components/measuring-points/measuring-points.component";
import { SplitMapComponentBase } from "src/app/modules/shared/components/split-map/split-map.component";
import { LocalStorageService } from "src/app/services/storage.service";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { NavigationService } from "src/app/services/navigation.service";
import { DevicesComponent } from "src/app/modules/shared/components/devices/devices.component";
import { TranslateService } from "@ngx-translate/core";
import { GroupsComponent } from "src/app/modules/shared/components/groups/groups.component";
import { MapDataService } from "src/app/services/map-data.service";
import { IOrganization } from "src/app/models/user";
import { ResizeService } from "src/app/services/resize.service";
import { MapDetail } from "src/app/services/map-detail.service";
import { GroupApi } from "src/app/resource/group.api";
import { IDevice } from "src/app/models/device";
import { Router } from "@angular/router";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { IGroup } from "src/app/models/group";
import { OrganizationApi } from "src/app/resource/organization.api";

@Component({
    selector: "app-map-selector",
    templateUrl: "./map-selector.component.html",
})
export class MapSelectorComponent extends SplitMapComponentBase implements OnInit, OnDestroy {
    //#region Properties

    @Input() mapDetail: MapDetail;
    @Input() componentHeight: number;
    @Input() compact = false;
    @Input() mini = false;
    @Input() deviceTypeId: string;

    @Output() selected = new EventEmitter<IMeasuringPoint[] | IGroup[] | IDevice[] | IOrganization[]>();
    @Output() deselected = new EventEmitter<IMeasuringPoint[] | IGroup[] | IDevice[] | IOrganization[]>();

    @ViewChild(MeasuringPointsComponent, { static: false }) set measuringPointsComponentSetter(measuringPointsComponent: MeasuringPointsComponent) {
        if (!measuringPointsComponent) return;

        setTimeout(() => {
            this.setMeasuringPointsComponent(measuringPointsComponent);
        });
    }

    @ViewChild(DevicesComponent, { static: false }) set devicesComponentSetter(devicesComponent: DevicesComponent) {
        if (!devicesComponent) return;

        setTimeout(() => {
            this.setDevicesComponent(devicesComponent);
        });
    }

    @ViewChild(GroupsComponent, { static: false }) set groupsComponentSetter(groupsComponent: GroupsComponent) {
        if (!groupsComponent) return;

        setTimeout(() => {
            this.setGroupsComponent(groupsComponent);
        });
    }

    protected rowCount = 20;
    protected enableTableCommands = false;
    protected clearSelectionOnDetailChange = false;
    restoreServiceInitialSelection = true;

    //#endregion Properties

    //#region Lifecycle

    constructor(
        cd: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        globalEventsService: GlobalEventsService,
        authenticationService: AuthenticationService,
        router: Router,
        navigationService: NavigationService,
        resizeService: ResizeService,
        localStorageService: LocalStorageService,
        selectionService: MapSelectionService,
        groupApi: GroupApi,
        mapDataService: MapDataService,
        translateService: TranslateService,
        organizationApi: OrganizationApi) {

        super(cd, elementRef, globalEventsService, authenticationService, router, navigationService, resizeService, localStorageService, selectionService, groupApi, mapDataService, translateService, organizationApi);

        this.readonly = true;
        this.zoomOnSelect = false;
        this.disableNavigationOnSelect = true;
        this.showSelectedIcons = true;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    handleSelect(selected: IMeasuringPoint[] | IGroup[] | IDevice[] | IOrganization[]) {
        if (!Array.isArray(selected)) {
            selected = [selected];
        }
        this.selected.emit(selected);
    }

    handleDeselect(deselected: IMeasuringPoint[] | IGroup[] | IDevice[] | IOrganization[]) {
        if (!Array.isArray(deselected)) {
            deselected = [deselected];
        }
        this.deselected.emit(deselected);
    }

    //#endregion Lifecycle

    //#region Abstract implementations

    protected calculateComponentHeight() {
        // Do nothing
    }

    getCurrentMapDetail(): MapDetail {
        return this.mapDetail;
    }

    //#endregion Abstract implementations
}
