<ng-container *ngIf="!parkingBan">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top">
    <button class="m-btn m-btn-secondary"
            (click)="create()"
            *ngIf="parkingBan && canCreateParkingBanException">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"manageParkingBanException.create" | translate}}
    </button>
</div>

<app-parking-ban-exceptions *ngIf="parkingBan"
                            class="m-layout-area-body m-layout-default m-border-top"
                            [parkingBanId]="parkingBan?.id"
                            [editCommand]="true"
                            [deleteCommand]="true"></app-parking-ban-exceptions>

<app-manage-parking-ban-exception-dialog></app-manage-parking-ban-exception-dialog>