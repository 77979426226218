import { SearchParameters, ServiceRequestOptions, ISearchResult, FilterDescriptor } from "src/app/models/search";
import { IServiceModelBase } from "src/app/models/servicemodelbase";
import { ApiService } from "src/app/resource/api";
import { Directive, ViewChild } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { Subject } from "rxjs";

export interface Include {
    model: string;
    property: string;
}

@Directive()
export abstract class LazyTreeComponent<T extends IServiceModelBase, TCreator, TUpdater> {
    @ViewChild(Tree) tree: Tree;

    loading = false;
    nodes: TreeNode[];
    selectedNode: TreeNode;

    public firstLevelLoaded$ = new Subject();

    constructor(protected readonly api: ApiService<T, TCreator, TUpdater>) { }

    public handleNodeSelect(node: TreeNode) {
        if (!node || node === this.selectedNode) { return; }
        this.selectedNode = node;
    }

    public lazyLoadNode(node: TreeNode): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!node || node.leaf || !node.data) {
                resolve();
                return;
            }

            const childrenInclude = this.getChildrenInclude();
            if (!childrenInclude) {
                resolve();
                return;
            }

            const options = this.getServiceRequestOptions();
            options.includes.add(childrenInclude.model, childrenInclude.property);

            this.api.get$(node.data.id, null, options).subscribe(fetchedNode => {
                node.children = this.mapChildren(fetchedNode);
                resolve();
            });
        });
    }

    public lazyLoadNodeWithSearchParameters(node: TreeNode, searchParameters: SearchParameters, useChilderInclude: boolean = true): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!node || node.leaf || !node.data) {
                resolve();
                return;
            }

            const childrenInclude = this.getChildrenInclude();
            if (useChilderInclude && !childrenInclude) {
                resolve();
                return;
            }

            const options = this.getServiceRequestOptions();
            if (useChilderInclude) {
                options.includes.add(childrenInclude.model, childrenInclude.property);
            }

            this.api.get$(node.data.id, searchParameters ? searchParameters : null, options).subscribe(fetchedNode => {
                node.children = this.mapChildren(fetchedNode);
                resolve();
            });
        });
    }

    protected loadFirstLevelNodes(searchParams?: SearchParameters, options?: ServiceRequestOptions,
        useCache = true, setSelection?: (nodes: TreeNode[]) => void, restoreSelection = true, emitFirstLevelLoaded: boolean = true) {
        this.loading = true;

        if (!searchParams) {
            searchParams = new SearchParameters();
        }
        if (!searchParams.filter) {
            searchParams.filter = [];
        }

        const rootNodeFilter = this.getRootNodeFilter();
        if (rootNodeFilter) {
            searchParams.filter.push(rootNodeFilter);
        }

        this.api.search$(searchParams, options, useCache).subscribe((result) => {
            this.onFirstLevelRetrieved(result);
            if (setSelection) {
                setSelection(this.nodes);
            } else {
                // try to restore last selected
                if (restoreSelection) {
                    const toReselectId = this.selectedNode ? this.selectedNode.data.id : undefined;
                    this.restoreSelection(toReselectId);
                }
            }
            if (emitFirstLevelLoaded) this.firstLevelLoaded$.next(emitFirstLevelLoaded);
        });
    }

    protected async reloadSubtree(id: number) {
        const subtreeRoot = await this.getSubtreeRoot(id);

        const refreshSubtreeNode = (model: T, subtreeNodes: TreeNode[]): TreeNode => {
            const node = this.findTreeNode(subtreeNodes, model.id);
            if (!node) return;

            this.refreshExistingNode(node, model);
            node.expanded = node.children && node.children.length > 0;

            return node;
        };

        const recursivelyRefreshSubtree = (model: T, subtreeNodes: TreeNode[]) => {
            const node = refreshSubtreeNode(model, subtreeNodes);
            if (!node) return;

            const children = this.getChildren(model);

            if (children) {
                for (let i = 0; i < children.length; i++) {
                    recursivelyRefreshSubtree(children[i], node.children);
                }
            }
        };
        recursivelyRefreshSubtree(subtreeRoot, this.nodes);
    }

    protected async reloadNode(id: number, expanded: boolean = false) {
        const currentNodeRef = this.findTreeNode(this.nodes, id);

        if (!currentNodeRef) {
            // Node is probably a descendant of a node that is not expanded.
            await this.reloadSubtree(id);
            return;
        }

        const currentChildrenState = currentNodeRef.children;
        const options = this.getServiceRequestOptions();
        const parentInclude = this.getParentInclude();
        const childrenInclude = this.getChildrenInclude();

        if (parentInclude) {
            options.includes.add(parentInclude.model, parentInclude.property);
        }
        if (childrenInclude && expanded) {
            options.includes.add(childrenInclude.model, childrenInclude.property);
        }

        this.loading = true;
        await this.api.get$(id, null, options).toPromise().then(refreshedData => {
            this.refreshExistingNode(currentNodeRef, refreshedData, expanded);
            this.restoreExpandedNodes(currentChildrenState, currentNodeRef.children);

            if (expanded) {
                // ensure sub-root is expanded
                this.expandSelfAndAllAncestors(currentNodeRef);
            }

            this.loading = false;
        });
    }

    protected selectNode(id: number) {
        const node = this.findTreeNode(this.nodes, id);
        if (!node) return;

        this.handleNodeSelect(node);
    }

    protected selectNodeUsingReference(node: TreeNode) {
        if (!node) return;

        this.handleNodeSelect(node);
    }

    protected expandSelfAndAllAncestors(node: TreeNode) {
        node.expanded = true;
        if (node.parent) {
            this.expandSelfAndAllAncestors(node.parent);
        }
    }

    protected expandSelfAndAllDescendants(node: TreeNode) {
        if (node.children && node.children.length > 0) {
            node.expanded = true;
            for (const descendant of node.children) {
                this.expandSelfAndAllDescendants(descendant);
            }
        }
    }

    private onFirstLevelRetrieved(result: ISearchResult<T>) {
        if (!result || !result.data) {
            this.nodes = [];
            return;
        }

        this.loading = false;
        const nodesState = this.nodes ? [...this.nodes] : undefined;
        this.nodes = result.data.map(d => this.mapToTreeNode(d));
        this.restoreExpandedNodes(nodesState, this.nodes);
    }

    private restoreSelection(lastSelectedId: number) {
        if (!this.nodes || this.nodes.length < 1) return;

        const node = this.findTreeNode(this.nodes, lastSelectedId);
        if (node) {
            this.handleNodeSelect(node);
        } else {
            this.handleNodeSelect(this.nodes[0]);
        }
    }

    protected refreshExistingNode(currentNodeRef: TreeNode, refreshedData: T, expanded = false) {
        if (!currentNodeRef) return;

        const refreshedNode = this.mapToTreeNode(refreshedData);
        currentNodeRef.label = refreshedNode.label;
        currentNodeRef.leaf = refreshedNode.leaf;
        currentNodeRef.expanded = expanded;
        currentNodeRef.children = this.mapChildren(refreshedData);
    }

    //#region [To implement/override]
    abstract getRootNodeFilter(): FilterDescriptor;
    abstract getSubtreeRoot(nodeId: number): Promise<T>;
    abstract isRootNode(node: T): boolean;

    abstract getParentInclude(): Include;
    abstract getChildrenInclude(): Include;
    abstract mapChildren(entity: T): TreeNode[];
    abstract getChildren(entity: T): T[];

    createNodeWithAllOfItsDescendants(entity: T): TreeNode {
        return this.mapToTreeNode(entity);
    }

    getSearchParameters(): SearchParameters {
        return null;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        return new ServiceRequestOptions();
    }

    mapToTreeNode(entity: T): TreeNode {
        return {
            label: "---",
            data: entity,
        } as TreeNode;
    }

    //#endregion [To implement/override]

    //#region [Helpers]
    protected restoreExpandedNodes(previousStates: TreeNode[], refreshedNodes: TreeNode[]) {
        if (!previousStates || !refreshedNodes) return;

        refreshedNodes.forEach(node => {
            const nodeState = previousStates.find(n => n.data.id === node.data.id);
            if (nodeState) {
                node.expanded = nodeState.expanded;
                node.children = nodeState.children;
            }
        });
    }

    protected findTreeNode(nodes: TreeNode[], id: number): TreeNode {
        if (!nodes || !id) return;

        let node: TreeNode;

        for (let i = 0; i < nodes.length; i++) {
            node = this.getChildNodeOrSelf(nodes[i], id);
            if (node) {
                break;
            }
        }
        return node;
    }

    protected getChildNodeOrSelf(root: TreeNode, id: number): TreeNode {
        if (root.data.id === id) return root;

        let node: TreeNode;
        if (root.children) {
            for (let i = 0; i < root.children.length; i++) {
                node = this.getChildNodeOrSelf(root.children[i], id);
                if (node) {
                    break;
                }
            }
        }
        return node;
    }
    //#endregion [Helpers]
}
