import { Component, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AttachmentCreator, IAttachment } from "src/app/models/attachment";
import { SigncoFormGroup } from "src/app/models/form";
import { IParkingBan } from "src/app/models/parking-ban";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { PhotoInputComponent } from "src/app/modules/shared/components/photo-input/photo-input.component";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { ParkingBanExceptionApi } from "src/app/resource/parking-ban-exception.api";
import { FormValidationService } from "src/app/services/form-validation.service";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { JsonUtils } from "src/app/utilities";
import { IParkingBanException, ParkingBanExceptionCreator, ParkingBanExceptionUpdater } from "src/app/models/parking-ban-exception";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { IAddress, ILocation, LocationCreator } from "src/app/models/location";

@Component({
    selector: "app-manage-parking-ban-exception-dialog",
    templateUrl: "./manage-parking-ban-exception-dialog.component.html"
})
export class ManageParkingBanExceptionDialogComponent extends DialogComponentBase implements OnDestroy {

    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;

    submitting: boolean;
    parkingBan: IParkingBan;
    photo: IAttachment;
    existingParkingBanException: IParkingBanException;
    newLocation: ILocation;
    manageParkingBanExceptionForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;

    callback: (res: IParkingBanException) => void;

    @ViewChild(PhotoInputComponent, { static: false }) photoInput: PhotoInputComponent;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly parkingBanExceptionApi: ParkingBanExceptionApi,
        private readonly attachmentApi: AttachmentApi,
        private readonly primeComponentService: PrimeComponentService) {
        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe((calendarSettings) => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(parkingBan: IParkingBan, callback?: (res: IParkingBanException) => void) {
        this.callback = callback;
        this.existingParkingBanException = null;
        this.parkingBan = parkingBan;
        this.openDialog();
    }

    edit(existingParkingBanException: IParkingBanException, callback?: (res: IParkingBanException) => void) {
        this.callback = callback;
        this.existingParkingBanException = existingParkingBanException;
        this.openDialog();
    }

    protected async onOpen() {
        this.manageParkingBanExceptionForm = this.formBuilder.group({
            licensePlate: ["", Validators.required],
            timestamp: [null, Validators.required],
            address: ["", Validators.required]
        }) as SigncoFormGroup;

        if (this.existingParkingBanException) {
            this.manageParkingBanExceptionForm.patchValue(this.existingParkingBanException);

            const address = this.existingParkingBanException.location?.address;
            this.manageParkingBanExceptionForm.get("address").setValue(this.getAddressString(address));

            this.photo = this.hasPhoto(this.existingParkingBanException) ?
                JsonUtils.deepClone(this.existingParkingBanException.photos[0]) :
                undefined;
        }

        this.submitting = false;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageParkingBanExceptionForm);
        if (!isValid || !this.photoInput.image) return;

        this.submitting = true;

        if (!this.existingParkingBanException) {
            this.createNew();
        } else {
            this.update();
        }
    }

    private async createNew() {
        const parkingBanException = Object.assign(new ParkingBanExceptionCreator(), this.manageParkingBanExceptionForm.value) as ParkingBanExceptionCreator;
        parkingBanException.parkingBanId = this.parkingBan.id;
        parkingBanException.location = Object.assign(new LocationCreator(), this.newLocation);

        const onSuccess = async (newParkingBanException: IParkingBanException) => this.onSuccess(newParkingBanException);
        this.parkingBanExceptionApi.create$(parkingBanException).subscribe(onSuccess, this.onError.bind(this));
    }

    private async update() {
        const parkingBanException = new ParkingBanExceptionUpdater(this.existingParkingBanException);
        Object.assign(parkingBanException, this.manageParkingBanExceptionForm.value);
        parkingBanException.locationId = this.existingParkingBanException.location.id;

        const onSuccess = async (newParkingBanException: IParkingBanException) => this.onSuccess(newParkingBanException);
        this.parkingBanExceptionApi.update$(parkingBanException).subscribe(onSuccess, this.onError.bind(this));
    }


    private async onSuccess(savedParkingBanException: IParkingBanException) {
        await this.uploadPhoto(savedParkingBanException.id);

        if (this.existingParkingBanException) {
            Object.assign(this.existingParkingBanException, savedParkingBanException);
        }

        if (this.callback) {
            this.callback(savedParkingBanException);
        }
        this.submitting = false;
        this.close();
    }

    private onError() {
        this.submitting = false;
    }

    private hasPhoto(parkingBanException: IParkingBanException) {
        return parkingBanException.photos && parkingBanException.photos.length > 0;
    }

    private async uploadPhoto(parkingBanExcId: number) {

        const creator = new AttachmentCreator();
        creator.name = `parkingBanException${this.manageParkingBanExceptionForm.controls["licensePlate"].value}`;
        creator.parkingBanExceptionId = parkingBanExcId;
        await this.photoInput.uploadPhoto(creator);
    }

    editLocation() {
        // no navigation after creation, just close the dialog
        this.manageLocationComponent.navigateAfterCreation = false;

        const onSuccessfullySelectedLocation = (newLocation: ILocation) => {
            if (!newLocation) {
                return;
            }

            this.newLocation = newLocation;
            this.manageParkingBanExceptionForm.get("address").setValue(this.getAddressString(newLocation.address));
        };

        if (!this.existingParkingBanException) {
            // in that case we are going to create a new location
            this.manageLocationComponent.create(this.parkingBan.location.coordinate, onSuccessfullySelectedLocation);
        } else {
            // edit current location
            this.manageLocationComponent.edit(this.existingParkingBanException.location, onSuccessfullySelectedLocation);
        }
    }

    private getAddressString(address: IAddress): string {
        if (address != null) {
            return `${address.line1}, ${address.zipCode} ${address.city}, ${address.country}`;
        }
        return null;
    }
}
