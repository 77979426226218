import * as moment from "moment";
import { ILocationLog, LocationLogType } from "src/app/models/measuring-point-location-history";
import { BasicMarkerOptions, IBasicMarker, IBasicMarkerOptions, createBasicMarker } from "src/app/modules/map-basic";
import { MapUtils } from "src/app/utilities";

export enum ViewStates {
    Normal = "normal",
    Selected = "selected",
    Hidden = "hidden"
}

export class LocationLogViewModel {

    constructor(
        public map: google.maps.Map,
        public model: ILocationLog) {
        this.id = LocationLogViewModel.globalId++;

        if (this.isStop) {
            const newCoordinate = MapUtils.toLatLng(this.model.records[0].coordinate);

            const basicMarkerOptions = new BasicMarkerOptions(newCoordinate);
            basicMarkerOptions.icon = {
                url: "assets/img/markers/parkingstop.png",
                scaledSize: new google.maps.Size(20, 20),
                size: new google.maps.Size(20, 20),
                anchor: new google.maps.Point(10, 10),
            };
            basicMarkerOptions.labelVisible = true;
            basicMarkerOptions.labelColor = "#FFF";
            basicMarkerOptions.textColor = "#000";
            basicMarkerOptions.labelContent = moment(this.from).format("DD/MM/yyyy HH:mm") + " → " + moment(this.until).format("DD/MM/yyyy HH:mm");

            const marker = createBasicMarker(basicMarkerOptions);

            this.marker = marker;
            this.marker.setMapInternal(map);
        } else if (this.isTrip) {
            const coordinates = new Array<google.maps.LatLng>();

            if (this.model.snappedCoordinates) {
                for (const snappedCoordinate of this.model.snappedCoordinates) {
                    const newCoordinate = MapUtils.toLatLng(snappedCoordinate);
                    coordinates.push(newCoordinate);
                }
            } else {
                for (const record of this.model.records) {
                    const newCoordinate = MapUtils.toLatLng(record.coordinate);
                    coordinates.push(newCoordinate);
                }
            }

            const polyline = new google.maps.Polyline({
                path: coordinates,
                map: this.map,
                clickable: true,
            });
            this.polyLine = polyline;
        } else if (this.isUnknown) {
            const coordinates = new Array<google.maps.LatLng>();
            for (const record of this.model.records) {
                if (!record.coordinate) continue; // Unknown logs sometimes don't have coordinates
                const newCoordinate = MapUtils.toLatLng(record.coordinate);
                coordinates.push(newCoordinate);
            }

            if (coordinates.length > 1) {
                const polyline = new google.maps.Polyline({
                    path: coordinates,
                    map: this.map,
                    clickable: true,
                });
                this.polyLine = polyline;
            }
        }

        this.setViewState(ViewStates.Normal);
    }

    addListener(eventName: string, handler: Function): google.maps.MapsEventListener {
        if (this.marker !== null) return this.marker.addListener(eventName, handler);
        if (this.polyLine !== null) return this.polyLine.addListener(eventName, handler);
    }

    public dispose() {
        if (this.marker !== null) {
            this.marker.map = null;
        }

        if (this.polyLine !== null) {
            this.polyLine.setMap(null);
        }

        google.maps.event.removeListener(this.eventListener);
    }

    static globalId = 0;
    public id: number;
    private viewState: ViewStates;
    private polyLine: google.maps.Polyline = null;
    private marker: IBasicMarker = null;
    private eventListener: google.maps.MapsEventListener;

    recordMarkers: google.maps.marker.AdvancedMarkerElement[] = null; // When a log is selected, we show all the individual location records

    public get isSelected(): boolean { return this.viewState === ViewStates.Selected; }

    public get isTrip(): boolean { return this.model.type === LocationLogType.Trip; }
    public get isStop(): boolean { return this.model.type === LocationLogType.Stop; }
    public get isUnknown(): boolean { return this.model.type === LocationLogType.Unknown; }

    public get distance(): number { return Math.floor(this.model.distance / 1000); }

    public get from(): Date { return this.model.records[0].timestamp; }
    public get until(): Date { return this.model.records[this.model.records.length - 1].timestamp; }

    public get isSingleDay(): boolean {
        const from = this.from;
        const until = this.until;
        return from.getFullYear() === until.getFullYear() && from.getMonth() === until.getMonth() && from.getDay() === until.getDay();
    }

    public get icon(): string {
        switch (this.model.type) {
            case LocationLogType.Stop: return "parking";
            case LocationLogType.Trip: return "arrow-right";
            case LocationLogType.Unknown: return "question";
        }
    }

    public get duration(): string {
        const from = this.model.records[0].timestamp;
        const until = this.model.records[this.model.records.length - 1].timestamp;
        const duration = moment.duration(until.valueOf() - from.valueOf());

        if (duration.hours() < 1) return duration.minutes() + " min";
        if (duration.days() < 1) return duration.hours() + " hr " + duration.minutes() + " min";
        return duration.days() + " d " + duration.hours() + " hr " + duration.minutes() + " min";
    }

    public setViewState(viewState: ViewStates) {
        if (this.viewState === viewState) return;
        this.viewState = viewState;

        switch (this.viewState) {
            case ViewStates.Normal: this.setNormal(); break;
            case ViewStates.Selected: this.setSelected(); break;
            case ViewStates.Hidden: this.setHidden(); break;
            default: throw { message: "Unknown viewState: " + this.viewState };
        }
    }

    private setNormal() {
        if (this.marker !== null) {
            this.marker.setZIndex(1);
            this.marker.setOpacity(1);
            this.marker.setIcon({
                url: "assets/img/markers/parkingstop.png",
                scaledSize: new google.maps.Size(20, 20),
                size: new google.maps.Size(20, 20),
                anchor: new google.maps.Point(10, 10),
            });
        }

        if (this.polyLine !== null) {
            this.polyLine.setOptions({
                strokeColor: this.isUnknown ? "#F80" : "#01579B",
                zIndex: 0,
                icons: [{
                    icon: { path: google.maps.SymbolPath.FORWARD_OPEN_ARROW },
                    offset: "50px",
                    repeat: "350px"
                }],
                strokeOpacity: 1,
                strokeWeight: 3,
            });
        }

        this.removeRecordMarkers();
    }

    private setSelected() {
        if (this.marker !== null) {
            this.marker.setZIndex(1);
            this.marker.setOpacity(1);
            this.marker.setIcon({
                url: "assets/img/markers/parkingstop_selected.png",
                scaledSize: new google.maps.Size(20, 20),
                size: new google.maps.Size(20, 20),
                anchor: new google.maps.Point(10, 10),
            });
        }

        if (this.polyLine !== null) {
            this.polyLine.setOptions({
                strokeColor: this.isUnknown ? "#F80" : "#01579B",
                zIndex: 1,
                icons: [{
                    icon: { path: google.maps.SymbolPath.FORWARD_OPEN_ARROW },
                    offset: "50px",
                    repeat: "100px"
                }],
                strokeOpacity: 1,
                strokeWeight: 4,
            });
        }

        this.createRecordMarkers();
        this.centerOnMap();
    }

    private setHidden() {
        if (this.marker !== null) {
            this.marker.setZIndex(0);
            this.marker.setOpacity(0.5);
            this.marker.setIcon({
                url: "assets/img/markers/parkingstop.png",
                scaledSize: new google.maps.Size(20, 20),
                size: new google.maps.Size(20, 20),
                anchor: new google.maps.Point(10, 10),
            });
        }

        if (this.polyLine !== null) {
            this.polyLine.setOptions({
                strokeColor: "#FFF",
                zIndex: 0,
                icons: null,
                strokeOpacity: 1,
                strokeWeight: 4,
            });
        }

        this.removeRecordMarkers();
    }

    private removeRecordMarkers() {
        if (this.recordMarkers === null) return;
        for (const marker of this.recordMarkers) {
            marker.map = null;
        }
        this.recordMarkers.length = 0; // Without doing this, the markers were not always removed. Strange.
        this.recordMarkers = null;
    }

    private createRecordMarkers() {
        if (this.recordMarkers !== null) throw { message: "RecordMarkers should not exist" };

        this.recordMarkers = [];
        let index = 0;
        for (const record of this.model.records) {
            if (!record.coordinate) continue;
            const isFirstOrLast = index === 0 || index === this.model.records.length - 1;
            index++;
            const coordinate = MapUtils.toLatLng(record.coordinate);

            const basicMarkerOptions = new BasicMarkerOptions(coordinate);
            basicMarkerOptions.icon = {
                url: "assets/img/markers/green.png",
                scaledSize: new google.maps.Size(10, 10),
                size: new google.maps.Size(10, 10),
                anchor: new google.maps.Point(5, 5),
            };
            basicMarkerOptions.zIndex = isFirstOrLast ? 2 : 1;
            basicMarkerOptions.labelVisible = true;
            basicMarkerOptions.labelColor = "#FFF";
            basicMarkerOptions.textColor = "#000";
            basicMarkerOptions.labelContent = moment(record.timestamp).format("DD/MM/yyyy HH:mm:ss");

            const marker = createBasicMarker(basicMarkerOptions);
            marker.setMapInternal(this.map);
            this.recordMarkers.push(marker);
        }
    }

    private centerOnMap() {
        // Automatic panning and zooming of the map can be quite annoying for the user, we only do this if the selected item
        // really isn't visible

        const currentBounds = this.map.getBounds();

        if (this.isStop) {
            if (currentBounds.contains(this.marker.position)) return;
            this.map.panTo(this.marker.position);
            return;
        }

        if (this.isTrip || this.isUnknown) {
            const bounds = new google.maps.LatLngBounds();
            for (const record of this.model.records) {
                if (!record.coordinate) continue;
                bounds.extend(MapUtils.toLatLng(record.coordinate));
            }

            if (currentBounds.intersects(bounds)) return; // A part of the path is visible

            bounds.union(currentBounds); // We extend the bounds, but the stuff that the user is looking at remains visible
            this.map.fitBounds(bounds, 0);
        }
    }
}
