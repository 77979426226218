<div class="m-form-group"
     *ngIf="formVisible && form"
     formGroupName="carXPosConfiguration">
    <div class="p-16 m-border-top m-border-bottom">
        <h3>{{"uploadDetailsDialog.carXPosConfiguration" | translate }}</h3>
    </div>

    <div class="p-16">
        <div class="m-form-group row"
             formGroupName="wheelWidthCm"
             [ngClass]="{ 'has-error': carCategoryControl.control.showErrors }"
             *ngFor="let carCategoryControl of carCategoryControls; trackBy: carCategoryControlTrackByFn; let i = index">
            <label class="col-2"
                   for="carCategory{{carCategoryControl.name}}">
                {{carCategoryControl.label}}
            </label>

            <div class="col-4">
                <div class="m-input-group">
                    <input [formControl]="carCategoryControl.control"
                           class="p-inputtext"
                           id="carCategory{{carCategoryControl.name}}"
                           type="number"
                           #wheelWidthCmInput>
                    <div class="m-input-group-addon">{{"measurements.cm" | translate}}</div>
                </div>

                <app-form-validation [control]="carCategoryControl.control"
                                     [input]="wheelWidthCmInput">
                </app-form-validation>
            </div>
        </div>
    </div>
</div>