import { IParkingConfiguration } from "src/app/models/parking-configuration";
import { Component, Input, Output, EventEmitter, SimpleChanges, HostListener, OnChanges, ElementRef } from "@angular/core";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { UntypedFormBuilder } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { AnalysisType, IMeasuringPoint, MeasuringPointUpdater } from "src/app/models/measuring-point";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { ParkingData } from "src/app/models/measuring-point-status";

@Component({
    selector: "app-measuring-point-parking",
    templateUrl: "./measuring-point-parking.component.html"
})
export class MeasuringPointParkingComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() measuringPoint: IMeasuringPoint;
    @Output() save = new EventEmitter<IMeasuringPoint>();

    submitting = false;
    parkingForm: SigncoFormGroup;
    simulation: ParkingData;

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.parkingForm && this.parkingForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.initialize();
    }

    async initialize() {
        if (!this.measuringPoint || this.measuringPoint.analysisTypeId !== AnalysisType.Parking) return;

        this.parkingForm = this.formBuilder.group({
            url: "",
        }) as SigncoFormGroup;

        if (this.measuringPoint.parkingConfiguration) {
            this.parkingForm.patchValue(this.measuringPoint.parkingConfiguration);
        }

        this.parkingForm.markAsPristine();
        this.simulation = this.measuringPoint.parkingConfiguration.simulation;
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.parkingForm);
        if (!isValid) return;

        const onSuccess = async (savedMeasuringPoint: IMeasuringPoint) => {
            this.toastService.saveSuccess();
            Object.assign(this.measuringPoint, savedMeasuringPoint);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.measuringPoint);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing measuringPoint with form
        const measuringPointUpdater = new MeasuringPointUpdater(this.measuringPoint);

        if (!measuringPointUpdater.parkingConfiguration) {
            measuringPointUpdater.parkingConfiguration = {} as IParkingConfiguration;
        }

        Object.assign(measuringPointUpdater.parkingConfiguration, this.parkingForm.value);
        measuringPointUpdater.parkingConfiguration.simulation = this.simulation;

        this.submitting = true;
        this.measuringPointApi.update$(measuringPointUpdater).subscribe(onSuccess, onError);
    }

    onSimulateChanged() {
        if (this.simulation) {
            this.simulation = null;
        } else {
            this.simulation = new ParkingData();
            this.simulation.capacity = 500;
            this.simulation.vacant = 300;
        }
    }
}