import { Component, HostListener, Input } from "@angular/core";
import { ITrafficLightStatus } from "src/app/models/device";

@Component({
    selector: "app-traffic-light-pinned-data",
    templateUrl: "./traffic-light-pinned-data.component.html",
})
export class TrafficLightPinnedDataComponent {
    @Input() status: ITrafficLightStatus;
    @Input() measuringPointId: number;

    @HostListener("wheel", ["$event"])
    onMouseWheel(event: WheelEvent) {
        // The mousewheel should scroll in the popup, not in the Google Maps that is beneath it
        event.stopPropagation();
    }
}