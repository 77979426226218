import { TableColumn, FilterType, ColumnType, TableService, LazyTableComponent } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ViewChild, ElementRef, OnInit } from "@angular/core";
import { ManageDatePeriodDialogComponent } from "../manage-date-period-dialog/manage-date-period.dialog";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IDatePeriodSet, IDatePeriod } from "src/app/models/date-period-set";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { DatePeriodApi } from "src/app/resource/date-period.api";
import { FilterOperator, SearchParameters } from "src/app/models/search";

@Component({
    selector: "app-date-periods",
    templateUrl: "./date-periods.component.html"
})
export class DatePeriodsComponent extends LazyTableComponent<IDatePeriod> implements OnInit {
    @ViewChild(ManageDatePeriodDialogComponent, { static: true }) manageDatePeriodDialog: ManageDatePeriodDialogComponent;

    @Input() datePeriodSet: IDatePeriodSet;
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly domainDataService: DomainDataService,
        private readonly datePeriodApi: DatePeriodApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService) {

        super("date-periods.component", elementRef, datePeriodApi, tableService);

        this.selectionMode = "";

        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));

        const typeColumn = new TableColumn("typeId", "general.type", { filterType: FilterType.MultiSelect, sortable: true, type: ColumnType.Data, width: 100, resizable: false });
        this.addColumn(typeColumn);
        this.domainDataService.get(DomainData.DatePeriodType).then(datePeriodTypes => {
            typeColumn.filterOptions = datePeriodTypes;
        });

        this.addColumn(new TableColumn("dates", "general.dates", { filterType: FilterType.Text, sortable: true }));

        this.registerCommand({
            text: "datePeriod.edit",
            icon: "edit",
            click: (datePeriod) => this.edit(datePeriod),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (datePeriod) => this.delete(datePeriod),
            validFunc: () => this.deleteCommand
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.datePeriodSet) {
            searchParameters.filter.push({ field: "datePeriodSetId", value: this.datePeriodSet.id, operator: FilterOperator.equals });
        }

        return searchParameters;
    }


    create() {
        this.manageDatePeriodDialog.create(this.datePeriodSet, () => {
            this.reload();
        });
    }

    edit(datePeriod: IDatePeriod) {
        this.manageDatePeriodDialog.edit(this.datePeriodSet, datePeriod, () => { this.reload(); });
    }

    delete(datePeriod: IDatePeriod) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.datePeriodApi.delete$(datePeriod.id).subscribe({
                next: onDeleteSuccess,
                error: () => { }
            });
        };

        const modalBody = this.translate.instant("datePeriod.deleteConfirmation", { name: datePeriod.name });
        this.modalService.delete(modalBody, onDelete);
    }
}