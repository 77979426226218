import { IUpload, UploadParameters, IUploadDailyMetric } from "src/app/models/upload";
import { IPredictionModel, IPredictDaysData, IPredictionRequestValidation } from "src/app/models/prediction";
import { SearchParameters, ISearchResult } from "src/app/models/search";
import { VehicleMeasurementDataDeleter } from "src/app/models/vehicle-overview";
import { ApiService } from "./api";
import { DownloadedFile } from "../services/download-file.service";
import { IAuditTrail } from "src/app/models/audit-trail";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { IJournal } from "src/app/models/journal";
import { IMeasuringPoint, MeasuringPointCreator, MeasuringPointUpdater } from "../models/measuring-point";
import { IDateInfoCollection } from "../models/date-info";
import { MeasuringPointExceptionalDayUpdater } from "../models/measuring-point-exceptional-day";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointApi extends ApiService<IMeasuringPoint, MeasuringPointCreator, MeasuringPointUpdater> {

    getRoute(): string {
        return "MeasuringPoints";
    }

    getDefaultUploadParameters$(measuringPointId: number): Observable<UploadParameters> {
        const url = `${super.getUrl()}/${measuringPointId}/DefaultUploadParameters`;
        return this.http.get<UploadParameters>(url);
    }

    getDataDates$(measuringPointIds: number[]): Observable<IDateInfoCollection> {
        const url = `${super.getUrl()}/Dates`;
        return this.postOther$(url, measuringPointIds);
    }

    updateWithFormData$(
        updater: MeasuringPointUpdater,
        files: { name: string, file: File }[]): Observable<IMeasuringPoint> {

        const url = `${super.getUrl()}/${updater.id}/Form`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(updater));

        for (const file of files) {
            formData.append(file.name, file.file);
        }

        return this.http.put<IMeasuringPoint>(url, formData);
    }

    upload$(
        measuringPointId: number,
        uploadParameters: UploadParameters,
        file: File): Promise<IUpload> {

        const url = `${super.getUrl()}/${measuringPointId}/Upload`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(uploadParameters));
        formData.append("data", file);

        return this.uploadFileService.upload$(url, formData, file);
    }

    uploadHistoricalData$(measuringPointId: number, file: File): Promise<IUpload> {
        const url = `${super.getUrl()}/${measuringPointId}/UploadHistoricalData`;
        const formData = new FormData();
        formData.append("data", file);

        return this.uploadFileService.upload$(url, formData, file);
    }

    validate$(id: number, file: File): Observable<void> {
        const url = `${super.getUrl()}/${id}/UploadValidate`;
        return this.http.post<void>(url, { filename: file.name });
    }

    removeVehicles$(measuringPointId: number, vehicleDeleters: VehicleMeasurementDataDeleter[], deviceId: number = null): Observable<void> {
        const url = `${super.getUrl()}/${measuringPointId}/DeleteVehicles`;
        const requestData = { deleters: vehicleDeleters, deviceId: deviceId };

        return this.http.post<void>(url, requestData);
    }

    getExceptionalDays$(measuringPointId: number): Observable<MeasuringPointExceptionalDayUpdater[]> {
        const url = `${super.getUrl()}/${measuringPointId}/ExceptionalDay`;
        return this.http.get<MeasuringPointExceptionalDayUpdater[]>(url);
    }

    updateExceptionalDays$(measuringPointId: number, updaters: MeasuringPointExceptionalDayUpdater[]): Observable<void> {
        const url = `${super.getUrl()}/${measuringPointId}/ExceptionalDay`;
        return this.http.post<void>(url, updaters);
    }

    validatePrediction$(measuringPointId: number, predictDaysData: IPredictDaysData): Observable<IPredictionRequestValidation> {
        const url = `${super.getUrl()}/${measuringPointId}/ValidatePrediction`;
        return this.http.post<IPredictionRequestValidation>(url, predictDaysData);
    }

    createPredictionModel$(measuringPointId: number, predictDaysData: IPredictDaysData): Observable<IPredictionModel> {
        const url = `${super.getUrl()}/${measuringPointId}/PredictionModels`;
        return this.http.post<IPredictionModel>(url, predictDaysData);
    }

    getEventOverview$(measuringPointId: number, from: Date, until: Date): Observable<{ audits: IAuditTrail[], journals: IJournal[] }> {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {
            from: from,
            until: until
        };

        return this.getOther$<{ audits: IAuditTrail[], journals: IJournal[] }>(`/${measuringPointId}/EventOverview`, searchParameters);
    }

    getDailyUploadMetrics$(measuringPointId: number, skip: number, take: number): Observable<ISearchResult<IUploadDailyMetric>> {
        const urlAppend = `/${measuringPointId}/DailyUploadMetrics`;

        const searchParameters = new SearchParameters();
        searchParameters.skip = skip;
        searchParameters.take = take;

        return this.getOther$<ISearchResult<IUploadDailyMetric>>(urlAppend, searchParameters, null, false);
    }

    getFloatingCarSegmentPreview$(measuringPointId: number): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/${measuringPointId}/FloatingCarSegmentPreview`;

        return this.downloadFileService.downloadBlob(url);
    }

    previewTotemDisplay$(text: string, lineCount: number = null, lineLength: number = null): Observable<{text: string}> {
        const url = `${super.getUrl()}/PreviewTotemDisplay`;
        return this.postOther$<{text: string}>(url, { text, lineCount, lineLength });
    }
}
