import { AdminBatteryDischargeTablesComponent } from "../admin-battery-discharge-tables/admin-battery-discharge-tables.component";
import { Component, ChangeDetectorRef, Type } from "@angular/core";
import { AdminLoginNotificationsComponent } from "../admin-login-notifications/admin-login-notifications.component";
import { AdminBikeParameterSetsComponent } from "../admin-bike-parameter-sets/admin-bike-parameter-sets.component";
import { AdminJournalCategoriesComponent } from "./../admin-journal-categories/admin-journal-categories.component";
import { AdminAttachmentTypesComponent } from "../admin-attachment-types/admin-attachment-types.component";
import { AdminDatePeriodSetsComponent } from "../admin-date-period-sets/admin-date-period-sets.component";
import { AdminDeviceDepotsComponent } from "./../admin-device-depots/admin-device-depots.component";
import { AdminDrivingLanesComponent } from "../admin-driving-lanes/admin-driving-lanes.component";
import { UserManagementComponent } from "src/app/modules/shared/components/user-management/user-management.component";
import { AdminReleasesComponent } from "../admin-releases/admin-releases.component";
import { ApplicationsComponent } from "src/app/modules/shared/components/applications/applications.component";
import { AdminRulesComponent } from "../admin-rules/admin-rules.component";
import { PlaygroundComponent } from "../playground/playground.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { VmsImagesComponent } from "../vms-images/vms-images.component";
import { WorkerManagementComponent } from "../worker-management/worker-management.component";
import { SafetyQuestionManagementComponent } from "../safety-question-management/safety-question-management.component";
import { AdminMapIconsComponent } from "../admin-map-icons/admin-map-icons.component";
import { BackendRights } from "src/app/models/backend-rights";
import { DomainDataService } from "src/app/services/domain-data.service";

type ManagementContainerComponents =
    ApplicationsComponent |
    VmsImagesComponent |
    UserManagementComponent |
    WorkerManagementComponent |

    AdminMapIconsComponent |
    AdminDrivingLanesComponent |
    AdminBikeParameterSetsComponent |
    AdminRulesComponent |
    SafetyQuestionManagementComponent |
    AdminDatePeriodSetsComponent |
    AdminAttachmentTypesComponent |
    AdminJournalCategoriesComponent |
    AdminReleasesComponent |
    AdminLoginNotificationsComponent |
    AdminDeviceDepotsComponent |
    AdminBatteryDischargeTablesComponent |
    PlaygroundComponent;

@Component({
    selector: "app-management-container",
    templateUrl: "./management-container.component.html"
})
export class ManagementContainerComponent extends TabBase<ManagementContainerComponents> {

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService,
        private readonly domainDataService: DomainDataService,
    ) {

        super(tabService, cd);

        this.addTab({ component: UserManagementComponent, url: "users", translatePath: "users.title", icon: "profile" });
        this.addTab({ component: WorkerManagementComponent, url: "workers", translatePath: "worker.title", icon: "worker" });
        this.addTab({ component: ApplicationsComponent, url: "applications", translatePath: "applications.title", icon: "share" });
        this.addTab({ component: VmsImagesComponent, url: "vms-images", translatePath: "vmsImages.title", icon: "device-trafficFleet" });

        this.addTab({ component: AdminDrivingLanesComponent, url: "drivinglanes", translatePath: "drivingLanes.title", icon: "crossroad" });
        this.addTab({ component: AdminMapIconsComponent, url: "icons", translatePath: "mapIcons.title", icon: "map-icons" });
        this.addTab({ component: AdminBikeParameterSetsComponent, url: "bikeparametersets", translatePath: "bikeParameterSets.title", icon: "bike" });
        this.addTab({ component: AdminRulesComponent, url: "rules", translatePath: "rules.title", icon: "alarm" });
        this.addTab({ component: SafetyQuestionManagementComponent, url: "safetyQuestions", translatePath: "safetyQuestion.title", icon: "overview" });
        this.addTab({ component: AdminDatePeriodSetsComponent, url: "periodsets", translatePath: "datePeriodSet.title", icon: "hourglass" });
        this.addTab({ component: AdminAttachmentTypesComponent, url: "attachmenttypes", translatePath: "attachmentTypes.title", icon: "upload" });
        this.addTab({ component: AdminJournalCategoriesComponent, url: "categories", translatePath: "categories.title", icon: "categories" });
        this.addTab({ component: AdminReleasesComponent, url: "releases", translatePath: "releases.title", icon: "device-bikeCounter" });
        this.addTab({ component: AdminDeviceDepotsComponent, url: "depots", translatePath: "deviceDepots.title", icon: "depot" });
        this.addTab({ component: AdminBatteryDischargeTablesComponent, url: "batterydischargetables", translatePath: "batteryDischargeTables.title", icon: "battery" });
        this.addTab({ component: AdminLoginNotificationsComponent, url: "loginnotifications", translatePath: "loginNotifications.title", icon: "alert" });
        this.addTab({ component: PlaygroundComponent, url: "playground", translatePath: "playground.title", icon: "web" });

        this.filterTabs();

        this.domainDataService.reload(true);
    }

    filterTabs() {
        const tabsToFilter = new Array<Type<ManagementContainerComponents>>();
        const authorizationInfo = this.globalEventsService.getAuthorizationInfo();
        if (!authorizationInfo?.isDomainAdministrator) {
            tabsToFilter.push(ApplicationsComponent);
            tabsToFilter.push(AdminDrivingLanesComponent);
            tabsToFilter.push(AdminBikeParameterSetsComponent);
            tabsToFilter.push(AdminRulesComponent);
            tabsToFilter.push(AdminDatePeriodSetsComponent);
            tabsToFilter.push(AdminAttachmentTypesComponent);
            tabsToFilter.push(AdminJournalCategoriesComponent);
            tabsToFilter.push(AdminReleasesComponent);
            tabsToFilter.push(AdminLoginNotificationsComponent);
            tabsToFilter.push(AdminDeviceDepotsComponent);
            tabsToFilter.push(AdminBatteryDischargeTablesComponent);
            tabsToFilter.push(PlaygroundComponent);
            tabsToFilter.push(VmsImagesComponent);
            tabsToFilter.push(AdminMapIconsComponent);
        }
        const rights = this.globalEventsService.getCurrentRights();
        if (!rights.hasBackendRight(BackendRights.EditPlanning)) {
            tabsToFilter.push(WorkerManagementComponent);
            tabsToFilter.push(SafetyQuestionManagementComponent);
        }

        this.hideTabs(tabsToFilter);
    }
}
