import { FilterDescriptor, FilterOperator, ISearchResult, SearchParameters, SortDescriptor, SortDirection } from "../models/search";
import { IJournalCategory, IJournalSubCategory } from "../models/journal";
import { BatteryDischargeTableApi } from "./../resource/battery-discharge-table.api";
import { IProject, ProjectStatus } from "./../models/project";
import { IBatteryDischargeTable } from "../models/battery-discharge-table";
import { PrimeComponentService } from "./prime-component.service";
import { JournalSubCategoryApi } from "../resource/journal-sub-category.api";
import { BikeParameterSetApi } from "../resource/bike-parameter-set.api";
import { JournalCategoryApi } from "../resource/journal-category.api";
import { IBikeParameterSet } from "../models/bike-parameter-set";
import { AttachmentTypeApi } from "../resource/attachment-type.api";
import { DatePeriodSetApi } from "../resource/date-period-set.api";
import { IAttachmentType } from "../models/attachment";
import { IDatePeriodSet } from "../models/date-period-set";
import { DrivingLaneApi } from "../resource/driving-lane.api";
import { AssignmentApi } from "../resource/assignment.api";
import { IDrivingLane } from "../models/driving-lane";
import { CacheService } from "./cache.service";
import { IUser, Roles } from "../models/user";
import { ProjectApi } from "./../resource/project.api";
import { ApiService } from "../resource/api";
import { SelectItem } from "primeng/api";
import { Injectable } from "@angular/core";
import { UserApi } from "../resource/user.api";
import { LocalizedModelViewModel } from "../models/domain-data";
import { DomainDataService } from "./domain-data.service";
import { TranslateService } from "@ngx-translate/core";
import { ProjectTreeUtils } from "../utilities";


/**
 * Currently it is on model level because we're translating only 1 property per model
 * But as soon as we get up to more properties translated per model, this should be refactored
 */
enum LocalizedModels {
    DrivingLane,
    JournalCategory,
    JournalSubCategory
}

@Injectable({
    providedIn: "root"
})
export class DomainModelFilterService {
    private cachedLocalizedModelViewModels: { [key: string]: LocalizedModelViewModel[] } = {};

    constructor(
        private readonly cacheService: CacheService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly userApi: UserApi,
        private readonly attachmentTypeApi: AttachmentTypeApi,
        private readonly datePeriodSetApi: DatePeriodSetApi,
        private readonly drivingLaneApi: DrivingLaneApi,
        private readonly journalCategoryApi: JournalCategoryApi,
        private readonly journalSubCategoryApi: JournalSubCategoryApi,
        private readonly bikeParameterSetApi: BikeParameterSetApi,
        private readonly projectApi: ProjectApi,
        private readonly assignmentApi: AssignmentApi,
        private readonly batteryDischargeTableApi: BatteryDischargeTableApi,
        private readonly domainDataService: DomainDataService,
        private readonly translateService: TranslateService) {

        translateService.onLangChange.subscribe(async () => {
            await this.updateTranslationsAfterLanguageChange();
        });
    }

    async clearCache$(): Promise<void> {
        const apis = [
            this.userApi,
            this.attachmentTypeApi,
            this.datePeriodSetApi,
            this.drivingLaneApi,
            this.journalCategoryApi,
            this.journalSubCategoryApi,
            this.bikeParameterSetApi,
            this.projectApi,
            this.assignmentApi,
            this.batteryDischargeTableApi
        ];

        for (const api of apis) {
            await this.cacheService.clearForApi(api);
        }
    }

    createUserDropdownList(users: IUser[]): SelectItem[] {
        return this.primeComponentService.createDropdownList(
            users,
            x => x.id,
            x => {
                const labelParts = new Array<string>();

                if (x.firstName || x.lastName) {
                    labelParts.push(`${x.firstName || ""} ${x.lastName || ""}`);
                }

                labelParts.push(x.email);

                if (x.userOrganizations) {
                    for (const organization of x.userOrganizations.map(userOrg => userOrg.organization)) {
                        labelParts.push(organization.name);
                    }
                }

                return labelParts.join(" - ");
            },
            false);
    }

    getUsers$(organizationId: number = null, roles: Roles[] = null, searchParameters: SearchParameters = null): Promise<SelectItem[]> {
        const toDropdownList = (users: IUser[]) => {
            if (organizationId) {
                users = users.filter(u => u.userOrganizations.find(x => x.organization.id === organizationId));
            }

            if (roles) {
                users = users.filter(u => u.userRoles.hasAny(roles));
            }

            return this.createUserDropdownList(users);
        };

        return this.createPromise(this.userApi, toDropdownList, searchParameters);
    }

    getAttachmentTypes$(): Promise<SelectItem[]> {
        const toDropdownList = (attachmentTypes: IAttachmentType[]) => {
            return this.primeComponentService.createDropdownList(attachmentTypes, x => x.id, x => x.code, false);
        };
        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "code")];
        return this.createPromise(this.attachmentTypeApi, toDropdownList, searchParameters);
    }

    getDatePeriodSets$(): Promise<SelectItem[]> {
        const toDropdownList = (datePeriodSets: IDatePeriodSet[]) => {
            return this.primeComponentService.createDropdownList(datePeriodSets, x => x.id, x => x.name, false);
        };
        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "name")];
        return this.createPromise(this.datePeriodSetApi, toDropdownList, searchParameters);
    }

    getDrivingLanes$(): Promise<SelectItem[]> {
        const toDropdownList = async (drivingLanes: IDrivingLane[]) => {
            const result = await this.modelsToLocalizedModelViewModels<IDrivingLane>(LocalizedModels.DrivingLane, drivingLanes, (dl) => dl.id, (dl) => dl.codeStringResourceId);
            return result;
        };

        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "code")];
        return this.createPromise(this.drivingLaneApi, toDropdownList, searchParameters);
    }

    getJournalCategories$(contextTypeId: string = null, isMaas = false): Promise<SelectItem[]> {
        const toDropdownList = async (categories: IJournalCategory[]) => {
            if (contextTypeId) {
                categories = categories.filter(x => x.contextTypeId === contextTypeId && x.isMaas === isMaas);
            }

            const result = await this.modelsToLocalizedModelViewModels<IJournalCategory>(LocalizedModels.JournalCategory, categories, (category) => category.id, (category) => category.codeStringResourceId);
            return result;
        };

        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "code")];
        return this.createPromise(this.journalCategoryApi, toDropdownList, searchParameters);
    }

    getJournalSubCategories$(journalCategoryId: number = null): Promise<SelectItem[]> {
        const toDropdownList = async (journalSubCategories: IJournalSubCategory[]) => {
            if (journalCategoryId) {
                journalSubCategories = journalSubCategories.filter(x => x.category.id === journalCategoryId);
            }

            const result = await this.modelsToLocalizedModelViewModels<IJournalSubCategory>(LocalizedModels.JournalSubCategory, journalSubCategories, (subCategory) => subCategory.id, (subCategory) => subCategory.codeStringResourceId);
            return result;
        };

        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "code")];
        return this.createPromise(this.journalSubCategoryApi, toDropdownList, searchParameters);
    }

    getBikeParameterSets$(): Promise<SelectItem[]> {
        const toDropdownList = (bikeParameterSets: IBikeParameterSet[]) => {
            return this.primeComponentService.createDropdownList(bikeParameterSets, x => x, x => x.code, false);
        };
        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "code")];
        return this.createPromise(this.bikeParameterSetApi, toDropdownList, searchParameters);
    }

    getProjects$(organizationId: number = null, includeEmpty = false, onlyActiveProjects: boolean = false): Promise<SelectItem[]> {
        const toDropdownList = (projects: IProject[]) => {
            const getStyleClass = (project: IProject) => {
                const isActive = ProjectTreeUtils.isActiveProject(project);
                return isActive ? "" : "p-disabled";
            };

            return this.primeComponentService.createDropdownList(projects, x => x.id, x => x.name, includeEmpty, "form.empty", getStyleClass);
        };
        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.descending, "from")];
        if (organizationId) {
            searchParameters.filter = [new FilterDescriptor("organizationId", organizationId)];
        }

        if (onlyActiveProjects) {
            if (!searchParameters.filter) {
                searchParameters.filter = [];
            }

            searchParameters.filter.push({ field: "onlyActiveProjects", value: onlyActiveProjects, operator: FilterOperator.equals });
        }

        return this.createPromise(this.projectApi, toDropdownList, searchParameters);
    }


    getBatteryDischargeTables$(): Promise<SelectItem[]> {
        const toDropdownList = (batteryDischargeTables: IBatteryDischargeTable[]) => {
            return this.primeComponentService.createDropdownList(batteryDischargeTables, x => x.id, x => x.name, false);
        };
        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "name")];
        return this.createPromise(this.batteryDischargeTableApi, toDropdownList, searchParameters);
    }

    private createPromise<T>(
        api: ApiService<T, any, any>,
        toDropdownList: (result: T[]) => SelectItem[] | Promise<SelectItem[]>,
        searchParameters: SearchParameters = null,
        pushCacheResult = true): Promise<SelectItem[]> {

        return new Promise<SelectItem[]>((resolve, reject) => {
            const onSuccess = async (result: T[]) => {
                const dropdownList = await toDropdownList(result);
                resolve(dropdownList);
            };

            const onError = (error: Error) => {
                reject(error);
            };

            api.getAll$(searchParameters, pushCacheResult).subscribe(onSuccess, onError);
        });
    }

    private async updateTranslationsAfterLanguageChange() {
        const currentLanguage = this.translateService.currentLang;

        for (const key of Object.keys(LocalizedModels)) {
            if (!this.cachedLocalizedModelViewModels[key]) {
                continue;
            }

            // for cached things we update translations
            for (const currentTranslation of this.cachedLocalizedModelViewModels[key]) {
                currentTranslation.label = this.domainDataService.translate(currentTranslation.stringResourceId, currentLanguage);
                currentTranslation.language = currentLanguage;
            }
        }
    }

    private async modelsToLocalizedModelViewModels<T>(modelType: LocalizedModels, items: T[], getValue: (obj: T) => any, getStringResourceId: (obj: T) => string): Promise<LocalizedModelViewModel[]> {
        const result = new Array<LocalizedModelViewModel>();
        this.cachedLocalizedModelViewModels[LocalizedModels[modelType]] = result;

        for (const item of items) {
            const currentTranslation = this.domainDataService.translate(getStringResourceId(item));
            const localizedModelViewModel = new LocalizedModelViewModel(getValue(item), currentTranslation, getStringResourceId(item), this.translateService.currentLang);
            result.push(localizedModelViewModel);
        }

        return result.map(x => x).orderBy(x => x.label);
    }
}
