import { Observable } from "rxjs";
import { IMeasuringPointStatus, IMeasuringPointStatusCreator } from "../models/measuring-point-status";
import { ApiServiceBase } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointStatusApi extends ApiServiceBase {
    getRoute(): string {
        return "MeasuringPoints/{measuringPointId}/Status";
    }

    // Get current status
    get$(measuringPointId: number) {
        return this.getOther$<IMeasuringPointStatus>("", null, null, false, { measuringPointId: measuringPointId.toString() });
    }

    // Set new status
    create$(model: IMeasuringPointStatusCreator, routeParams: { [key: string]: string } = null): Observable<IMeasuringPointStatus> {
        return this.http.post<IMeasuringPointStatus>(this.getUrl(routeParams), model);
    }
}