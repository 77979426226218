<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="show()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"loginNotifications.show" | translate}}
        </button>

        <button class="m-btn m-btn-secondary"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"loginNotifications.create" | translate}}
        </button>
    </div>

    <app-login-notifications [editCommand]="true"
                             class="m-layout-area-body m-layout-default m-border-top"
                             [deleteCommand]="true"></app-login-notifications>
</div>

<app-manage-login-notification-dialog></app-manage-login-notification-dialog>