import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { ILocation } from "src/app/models/location";
import { DeviceDetailContainerComponent } from "../device-detail-container/device-detail-container.component";
import { IDevice, IDeviceSummary } from "src/app/models/device";
import { ServiceRequestOptions } from "src/app/models/search";
import { SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { MapDetailService } from "src/app/services/map-detail.service";
import { DevicesComponent } from "src/app/modules/shared/components/devices/devices.component";
import { MapDataService } from "src/app/services/map-data.service";
import { ActivatedRoute } from "@angular/router";
import { DeviceApi } from "src/app/resource/device.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { ILocationWithDevicesSummary } from "src/app/models/web";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-location",
    templateUrl: "./device-location.component.html"
})
export class DeviceLocationComponent implements OnInit, OnDestroy {
    @ViewChild(DevicesComponent, { static: true }) devicesComponent: DevicesComponent;
    @ViewChild(DeviceDetailContainerComponent, { static: true }) deviceDetailContainer: DeviceDetailContainerComponent;

    loading: boolean;
    location: ILocation;
    locationSummary: ILocationWithDevicesSummary;

    canCreateDevice = false;

    // Used in devices component to set location, if set it filters to show only devices from that location
    filterLocation: ILocation;
    filterOnLocation = true;

    device: IDevice;

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef<HTMLElement>,
        private readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly mapDataService: MapDataService,
        private readonly mapDetailService: MapDetailService,
        private readonly navigationService: NavigationService,
        private readonly deviceApi: DeviceApi) {

        elementRef.nativeElement.classList.add("container-detail");
        const rights = this.globalEventsService.getCurrentRights();
        this.canCreateDevice = rights?.hasBackendRight(BackendRights.CreateDevice);
    }

    ngOnInit() {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            // Attempt navigation to what the params specified
            await this.setDeviceFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    private async setDeviceFromParams(params: { [key: string]: any }): Promise<void> {
        if (!params) return;

        const deviceIdString = decodeURI(params["deviceId"]);

        if (deviceIdString === "new") {

            this.route.queryParams.subscribe(queryParams => {
                const newDevice = this.getNewDevice(queryParams["type"]);
                this.setDevice(newDevice);
            });

            return;
        }

        this.loading = true;

        const deviceId = Number.parseInt(deviceIdString, 10);

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("device", "activeRelease");
        serviceRequestOptions.includes.add("device", "attachment");
        serviceRequestOptions.includes.add("device", "workerDriver");

        try {
            const device = Number.isNaN(deviceId) ? null : await this.deviceApi.get$(deviceId, null, serviceRequestOptions).toPromise();

            if (!device) {
                this.toMap();
                return;
            }

            this.loading = false;

            this.setDevice(device);
        } catch (ex) {
            this.toMap();
        }
    }

    createNewDevice() {
        this.devicesComponent.clearSelection(true);
        this.navigationService.createNewDevice();
    }

    private getNewDevice(type?: string): IDevice {
        const newDevice = {
            code: "",
            description: "",
            manufacturer: "",
            typeId: type || "",
            features: {},
            ownerId: this.globalEventsService.getDefaultOrganization().id,
            currentLocation: this.globalEventsService.getDefaultOrganization().location
        } as IDevice;

        if (!newDevice.currentLocation.devices) {
            newDevice.currentLocation.devices = [];
        }

        return newDevice;
    }

    private async setLocation() {
        if (!this.device) {
            this.location = null;
            return;
        }

        this.locationSummary = await this.mapDataService.getDeviceLocation(x => x.id === this.device.currentLocation.id);
        this.location = this.device.currentLocation || (await this.mapDataService.getOrganization$(x => x.id === this.device.ownerId)).location;
        this.setFilterLocation();
    }

    setFilterLocation() {
        this.filterLocation = this.filterOnLocation ? this.location : null;
    }

    private setDevice(device?: IDevice) {
        if (!device) {
            const deviceSummary = this.devicesComponent.data.takeFirstOrDefault();

            if (deviceSummary) {
                this.handleOnDeviceSelect(deviceSummary);
            } else {
                this.toMap();
            }

            return;
        }

        if (this.device && this.device.id === device.id) return;

        const isInitialSelect = !this.device;
        this.device = device;

        this.selectDeviceInComponent(isInitialSelect);

        this.setLocation();
    }

    selectDeviceInComponent(scroll = false) {
        if (!this.devicesComponent || !this.device) return;

        const deviceSummary = this.devicesComponent.data.find(x => x.id === this.device.id);
        this.devicesComponent.selectRow(deviceSummary);

        if (scroll) {
            this.devicesComponent.scrollTo(deviceSummary);
        }
    }

    onDataSet() {
        if (this.isCreatingNew) return;

        const deviceSummary = this.devicesComponent.data.find(x => x.id === this.device.id);
        if (deviceSummary) {
            this.selectDeviceInComponent(true);
        } else {
            this.devicesComponent.clearSelection();
            this.device = null;
        }

        if (!this.device) {
            this.selectFirstDevice();
        }
    }

    private selectFirstDevice() {
        let device: IDeviceSummary;

        if (this.devicesComponent) {
            device = this.devicesComponent.data.takeFirstOrDefault();
        }

        if (device) {
            this.navigationService.toDevice(device.id);
        } else {
            this.toMap();
        }
    }

    async handleOnDeviceSelect(selectedDevice: IDeviceSummary) {
        if (!selectedDevice) {
            this.toMap();
            return;
        }

        if (!this.deviceDetailContainer || !this.deviceDetailContainer.selectedTab) return;
        if (this.device && selectedDevice.id === this.device.id) return;

        const urlIndexStart = window.location.href.indexOf(`/${this.deviceDetailContainer.selectedTab.url}`);
        const urlAppend = window.location.href.substring(urlIndexStart + 1);

        const didNavigate = await this.navigationService.toDevice(selectedDevice.id, urlAppend);

        if (!didNavigate) {
            this.devicesComponent.selectRow(selectedDevice);
        }

        if (!this.location || selectedDevice.currentLocationId !== this.location.id) {
            this.setLocation();
        }
    }

    handleLocationEdit(location: ILocation) {
        // TODO (maybe) - renew device detail
    }

    // When a new measuring point gets created
    // We want to set it selected in our devices component
    // Also refresh title if code has been changed
    // So we pretty much the refresh the component
    handleOnDeviceSaved(savedDevice: IDevice) {
        this.devicesComponent.reload();

        const shouldNavigate = !this.device || this.device.id !== savedDevice.id;

        if (!shouldNavigate) {
            // displayConfiguration null on server means it's deleted
            Object.assign(this.device, { displayConfiguration: null } as IDevice, savedDevice);
            this.deviceDetailContainer.filterTabs();
            this.deviceDetailContainer.updateTitle();
        } else {
            this.navigationService.toDevice(savedDevice.id);
        }
    }

    toMap() {
        this.mapDetailService.navigateToMapDetail();
    }

    private get isCreatingNew(): boolean {
        return this.device && !this.device.id; // check if id is falsy
    }
}