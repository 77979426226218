import { ManageAttachmentTypeDialogComponent } from "../manage-attachment-type-dialog/manage-attachment-type.dialog";
import { AttachmentTypesComponent } from "../attachment-types/attachment-types.component";
import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { IAttachmentType } from "src/app/models/attachment";

@Component({
    selector: "app-admin-attachment-types",
    templateUrl: "./admin-attachment-types.component.html"
})
export class AdminAttachmentTypesComponent implements OnDestroy {
    @ViewChild(ManageAttachmentTypeDialogComponent, { static: true }) manageAttachmentTypeDialog: ManageAttachmentTypeDialogComponent;
    @ViewChild(AttachmentTypesComponent, { static: true }) attachmentTypesComponent: AttachmentTypesComponent;


    constructor(
        elementRef: ElementRef,
    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }
    ngOnDestroy(): void {
    }
    create() {
        this.manageAttachmentTypeDialog.create(() => {
            this.attachmentTypesComponent.reload();
        });
    }

    edit(attachmentType: IAttachmentType) {
        this.manageAttachmentTypeDialog.edit(attachmentType);
    }
}