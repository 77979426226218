<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{width: 'auto'}">

    <p-header>
        Select which data to display
    </p-header>

    <div class="p-16"
         style="width:500px;"
         *ngIf="viewModel">

        <div style="display: flex; gap:8px; align-items: flex-start;">
            <div *ngFor="let pinnedDataOption of dataOptions"
                 style="flex: 1 1 0px;">
                <div class="flex align-center">
                    <label class="m-input-checkbox-block m-form-group">
                        <input type="checkbox"
                               (click)="togglePinnedDataOption(pinnedDataOption)"
                               [checked]="isPinnedDataOptionChecked(pinnedDataOption)"
                               (change)="dataOptionChanged()">
                        {{pinnedDataOption.option | translateEnum:"pinnedDataOption" }}
                    </label>

                    <app-expando [target]="pinnedDataOptionBody"
                                 [expanded]="true">
                    </app-expando>
                </div>

                <div #pinnedDataOptionBody>
                    <div class="m-form-group"
                         *ngFor="let dataSetType of pinnedDataOption.dataSetTypes; trackBy: trackByFn">
                        <label class="m-input-checkbox-block ml-16">
                            <input [(ngModel)]="dataSetType.isSelected"
                                   (change)="dataOptionChanged()"
                                   type="checkbox"
                                   [id]="'include_' + dataSetType.dataSetType"> {{dataSetType.dataSetType | dataSetName:translateService.currentLang:pinnedDataOption.option | async}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="warning mt-8">{{validationWarning}}</div>

        <hr>
        <div style="display:inline-grid; grid-template-columns: auto 1fr; grid-auto-rows: auto; gap:8px; align-items: center;">
            <label class="m-input-checkbox">
                <input type="checkbox"
                       [(ngModel)]="viewModel.showLiveData"
                       (change)="previewTypeChanged()">
                {{'liveTiles.measuredData.live' | translate}}
            </label>
            <div>
                <p-dropdown *ngIf="!configuration?.defaultLiveDataRangeOption"
                            [options]="liveDataRangeOptions"
                            [(ngModel)]="viewModel.liveDataRange"
                            [autoDisplayFirst]="false"
                            class="dd-small"
                            appendTo="body">
                </p-dropdown>
            </div>

            <label class="m-input-checkbox"
                   *ngIf="configuration?.supportedOptions && configuration?.supportedOptions.length > 0">
                <input type="checkbox"
                       [(ngModel)]="viewModel.showHistoricalData"
                       (change)="previewTypeChanged()">
                {{'liveTiles.measuredData.graph' | translate}}
            </label>
            <div class="dd-small"
                 style="margin-top:-5px;">
                <app-two-level-select [(ngModel)]="viewModel.historicalDataRange"
                                      [configuration]="rangeTwoLevelSelectOptions"
                                      [firstLevelOptions]="rangeSelectItems"
                                      [secondLevelOptions]="aggregationTypes"
                                      [wordBetweenOptionsTranslation]="'liveTiles.measuredData.by'"
                                      class="twolevel-select-small twolevel-select-expanded-full-height twolevel-select-right-float">
                </app-two-level-select>
            </div>

            <div class="warning mt-8"
                 style="grid-column:span 2"
                 *ngIf="historicalAndLiveDataNotSelected">
                {{'liveTiles.measuredData.atLeastOneOption' | translate}}
            </div>
        </div>
    </div>

    <p-footer>
        <button class="m-btn m-btn-link"
                (click)="close()"
                type="button"
                id="cancel_button">
            {{"form.cancel" | translate}}
        </button>

        <button class="m-btn m-btn-primary"
                [disabled]="validationWarning || historicalAndLiveDataNotSelected"
                (click)="submit()"
                type="submit"
                id="save_button">
            {{"form.ok" | translate}}
        </button>
    </p-footer>
</p-dialog>