import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit } from "@angular/core";
import { DateFormControl, PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { NavigationStart, Router } from "@angular/router";
import { FormValidationService } from "src/app/services/form-validation.service";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { SubscriptionManager } from "src/app/utilities";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { ValidationContext } from "src/app/models/validation-context";
import { ValidationService } from "src/app/services/validation.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { DataDaysService } from "src/app/services/data-days.service";
import { UntypedFormBuilder } from "@angular/forms";
import { MapDetail } from "src/app/services/map-detail.service";
import { Calendar } from "primeng/calendar";
import { filter } from "rxjs/operators";
import { ToastService } from "src/app/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ResourceSelectorComponent } from "src/app/modules/shared/components/resource-selector/resource-selector.component";


@Component({
    selector: "app-validation",
    templateUrl: "./validation.component.html"
})
export class ValidationComponent implements AfterViewInit, OnDestroy {
    @ViewChild("column1", { static: true }) column1: ElementRef<HTMLDivElement>;

    @ViewChild("rangeFromCalendar", { static: false }) set setRangeFromCalendar(calendar: Calendar) {
        this.rangeFromDateControl.setCalendar(calendar);
    }

    @ViewChild("rangeToCalendar", { static: false }) set setRangeToCalendar(calendar: Calendar) {
        this.rangeToDateControl.setCalendar(calendar);
    }

    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        this.map = mapSelector;
    }

    @ViewChild(ResourceSelectorComponent, { static: true }) resourceSelectorComponent: ResourceSelectorComponent;

    projectSelectionOpen: boolean;

    loading = true;
    mapHeight: number;
    mapOpen: boolean;
    mapDetail: MapDetail;

    validationForm: SigncoFormGroup;

    rangeFromDateControl: DateFormControl;
    rangeToDateControl: DateFormControl;
    calendarSettings: CalendarSettings;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        readonly dataDaysService: DataDaysService,
        private readonly router: Router,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly navigationService: NavigationService,
        private readonly validationService: ValidationService,
        private readonly selectionService: MapSelectionService,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService) {

        this.dataDaysService.clear();

        const updateDataDaysMeasuringPoints = () => {
            this.dataDaysService.setMeasuringPoints(this.selectionService.getSelectedMeasuringPoints().map(x => x.id));
        };

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, updateDataDaysMeasuringPoints, updateDataDaysMeasuringPoints);
        this.selectionService.subscribeToGroups(this.subscriptionManager, x => this.dataDaysService.addGroups(x), x => this.dataDaysService.removeGroups(x));
        this.selectionService.subscribeToProjects(this.subscriptionManager, x => this.dataDaysService.addProjects(x), x => this.dataDaysService.removeProjects(x));

        this.createForm();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const navigationEndSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.saveContext();
            });
        this.subscriptionManager.add("navigationEnd", navigationEndSubscription);
    }

    ngAfterViewInit() {
        this.restoreContext();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    private createForm() {
        const fromDate = new Date().toMidnight().addDays(-7);

        this.rangeFromDateControl = new DateFormControl(null, fromDate);
        this.rangeFromDateControl.setDateSelectionMode("single");

        this.rangeToDateControl = new DateFormControl(null, new Date().toMidnight());
        this.rangeToDateControl.setDateSelectionMode("single");

        this.validationForm = this.formBuilder.group({
            from: this.rangeFromDateControl,
            to: this.rangeToDateControl
        }) as SigncoFormGroup;
    }

    async onFirstLevelLoaded() {
        const context = await this.validationService.getContext();
        if (context) {
            // only measurement project -> there can be a situation that MaaS projects are cached from reporting e.g
            this.selectionService.setProjects(context.projects?.filter(x => x.isMeasurementProject) ?? []);
        }
    }

    private async restoreContext() {
        this.loading = true;

        const context = await this.validationService.getContext();
        if (context) {
            this.validationForm.patchValue({
                from: context.from,
                to: context.to
            });

            this.selectionService.setGroups(context.groups);
            this.selectionService.setMeasuringPoints(context.measuringPoints);
        }

        this.loading = false;
    }

    private saveContext() {
        const groups = this.resourceSelectorComponent.groupsComponent.data;
        const measuringPoints = this.resourceSelectorComponent.measuringPointsComponent.data;
        const projects = this.resourceSelectorComponent.projectsComponent.data;

        this.validationService.setContext(new ValidationContext(
            this.rangeFromDateControl.value as Date,
            (this.rangeToDateControl.value as Date),
            groups,
            measuringPoints,
            projects
        ));
    }

    //#region Map

    isMapOpen() {
        return this.mapOpen || this.projectSelectionOpen;
    }

    private openMap(detail: MapDetail) {
        this.updateMapHeight();
        this.mapDetail = detail;
        this.mapOpen = true;
    }

    closeMap() {
        this.mapOpen = false;
    }

    private updateMapHeight() {
        this.mapHeight = this.column1.nativeElement.offsetHeight - 44;
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.map.measuringPointsComponent.selectionMode = "multiple";
        }

        if (this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.map.groupsComponent.selectionMode = "multiple";
        }
    }

    toggleMap(mapDetail: MapDetail) {
        if (mapDetail === null || mapDetail === undefined) return;

        if (mapDetail === MapDetail.MeasuringPointGroups) this.toggleAddGroups();
        else if (mapDetail === MapDetail.Projects) this.toggleAddProjects();
        else this.toggleAddMeasuringPoints();
    }

    //#endregion Map

    //#region Measuring Point

    toggleAddMeasuringPoints() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPoints) {
            this.closeMap();
            return;
        }

        this.projectSelectionOpen = false;
        this.openMap(MapDetail.MeasuringPoints);
    }

    //#endregion Measuring Point

    //#region Groups

    toggleAddGroups() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPointGroups) {
            this.closeMap();
            return;
        }

        this.projectSelectionOpen = false;
        this.openMap(MapDetail.MeasuringPointGroups);
    }

    //#endregion Groups

    //#region Projects

    toggleAddProjects() {
        if (this.projectSelectionOpen) {
            this.closeProjectSelection();
            return;
        }

        this.closeMap();
        this.projectSelectionOpen = true;
    }

    closeProjectSelection() {
        this.projectSelectionOpen = false;
    }

    //#endregion Projects

    //#region Error handling

    isDatesInError(): boolean {
        if (!this.validationForm.submitted) return false;

        return !this.rangeFromDateControl.value || !this.rangeToDateControl.value;
    }

    //#endregion Error handling

    //#region Submit

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.validationForm);
        if (!isValid) return;

        // Can't be checked by default form validity, goes beyond the normal scope
        // So we check it properly here
        if (this.isDatesInError() || this.resourceSelectorComponent.isInputInError(this.validationForm.submitted)) return;

        this.saveContext();

        const context = await this.validationService.getContext();
        const firstMeasuringPoint = context.allMeasuringPoints.takeFirstOrDefault();

        if (firstMeasuringPoint) {
            this.navigationService.toMeasuringPointValidation(firstMeasuringPoint);
        } else {
            this.toastService.warning(this.translateService.instant("dataValidation.noMeasuringPointsSelected"));
        }
    }

    //#endregion Submit
}