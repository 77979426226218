import { IRule, RuleCreator, RuleTester, TestResult, RuleUpdater } from "../models/alarm";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class RuleApi extends ApiService<IRule, RuleCreator, RuleUpdater> {
    getRoute(): string {
        return "Rules";
    }

    test$(ruleTester: RuleTester): Observable<TestResult> {
        const url = `${super.getUrl()}/TestExpression`;
        return this.http.post<TestResult>(url, ruleTester);
    }
}