import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ISignScan } from "src/app/models/sign-scan";
import { SignScanApi } from "src/app/resource/sign-scan.api";
import { ModalService } from "src/app/services/modal.service";
import { ManageSignScanDialogComponent } from "../manage-sign-scan-dialog/manage-sign-scan-dialog.component";
import { FilterType, LazyTableComponent, TableColumn, TableService } from "../../../shared/components/table/table.component";
import { DownloadFileService } from "src/app/services/download-file.service";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ParkingBanDataService } from "../../services/parking-ban-data.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-sign-scans",
    templateUrl: "./sign-scans.component.html"
})
export class SignScansComponent extends LazyTableComponent<ISignScan> implements OnChanges {

    @ViewChild(ManageSignScanDialogComponent, { static: true }) manageSignScanComponent: ManageSignScanDialogComponent;

    @Input() parkingBanId: number;
    @Input() requiresInput = false;
    @Input() editCommand = false;
    @Input() deleteCommand = false;

    private loadingPhotos = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly signScanApi: SignScanApi,
        readonly translateService: TranslateService,
        private readonly parkingBanDataService: ParkingBanDataService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly downloadFileService: DownloadFileService,
        private readonly modalService: ModalService) {

        super("signScans.component", elementRef, signScanApi, tableService);

        this.addColumn(new TableColumn("photo", "", { filterType: FilterType.None, sortable: false, resizable: false, width: 128 }));
        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("location", "general.location", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("signCode", "signScan.signCode", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("checkIn", "signScan.checkIn", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("checkOut", "signScan.checkOut", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("lost", "signScan.isLost", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (signScan) => this.edit(signScan),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditTask),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (signScan) => this.delete(signScan),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteTask),
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const parkingBanIdChange = changes["parkingBanId"];
        const inputChange = changes["requiresInput"] || parkingBanIdChange;

        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    async processLoadedData(data: ISignScan[]): Promise<ISignScan[]> {
        if (this.loadingPhotos) return data;
        this.loadingPhotos = true;

        for (const signScan of data) {
            if (signScan.photos && signScan.photos.length > 0 && !signScan.photoPreview) {
                const photo = await this.downloadFileService.downloadBlob(signScan.photos[0].url);
                signScan.photoPreview = photo.toResourceUrl();
            }
        }

        this.loadingPhotos = false;
        return data;
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.parkingBanId) {
            searchParameters.filter.push({ field: "parkingBanId", value: this.parkingBanId, operator: FilterOperator.equals });
        }
        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("signScan", "photos");
        serviceRequestOptions.includes.add("signScan", "coordinate");
        serviceRequestOptions.includes.add("signScan", "parkingBan");
        serviceRequestOptions.includes.add("signScan", "location");
        return serviceRequestOptions;
    }

    private edit(signScan: ISignScan) {
        this.manageSignScanComponent.edit(signScan, (result) => {
            this.reload();
            this.parkingBanDataService.notifyParkingBanChanged(result.parkingBan);
        });
    }

    private delete(signScan: ISignScan) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteTask)) return;

        const modalBody = this.translateService.instant("signScan.deleteConfirmation", { code: signScan.signCode }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(signScan));
    }

    private handleDelete(signScan: ISignScan) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        this.signScanApi.delete$(signScan.id).subscribe(onDeleteSuccess, () => { });
    }
}
