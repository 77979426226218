<div style="display:grid; gap:8px; justify-items: center;">
    <button *ngIf="isExpanded"
            class="m-btn m-btn-mode-select"
            (click)="openSetModeDialog($event)">
        Mode {{data?.currentAction?.content?.modeId}}: {{data?.currentAction?.content?.modeDescription}}
        <app-svg-icon icon="edit"
                      divClass="icon-16"
                      class="ml-4"></app-svg-icon>
    </button>

    <div>
        <img *ngIf="iconPreview"
             [attr.src]="iconPreview | safeHtml"
             style="max-width:25px;max-height:25px;"
             [pTooltip]="data?.currentAction?.content?.modeDescription"
             [tooltipDisabled]="isExpanded" />
    </div>
</div>

<app-light-manage-dialog></app-light-manage-dialog>