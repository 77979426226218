<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingAttachmentType ? "attachmentTypes.edit" : "attachmentTypes.create")| translate}}
    </p-header>

    <form *ngIf="manageAttachmentTypeForm"
          class="p-16"
          [formGroup]="manageAttachmentTypeForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageAttachmentTypeForm.get('code').showErrors }">

            <label class="col-4"
                   for="code">
                {{"general.code" | translate}}
            </label>

            <div class="col-8">
                <input type="text"
                       id="code"
                       class="p-inputtext"
                       formControlName="code"
                       appAutofocus
                       #codeInput />

                <app-form-validation [control]="manageAttachmentTypeForm.get('code')"
                                     [input]="codeInput">
                </app-form-validation>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>