<div class="m-card m-layout-default m-fixed-height">
    <div class="m-layout-area-body m-layout-w-actions-top">
        <div class="p-16 m-layout-area-top m-border-bottom">
            <input type="text"
                   (appDebounce)="onFilter($event)"
                   placeholder="{{'projects.searchProjects' | translate}}"
                   [(ngModel)]="searchText"
                   #searchField
                   [disabled]="loading"
                   id="search_field"
                   class="p-inputtext"
                   [appAutofocus]="true" />

            <label class="m-input-checkbox mt-16">
                <input type="checkbox"
                       [disabled]="loading"
                       [(ngModel)]="onlyActiveProjects"
                       id="only_active_projects_checkbox"
                       (change)="refreshProjectsTree()" />
                {{"projects.onlyActive" | translate}}
            </label>
        </div>
        <div class="m-overflow-auto m-layout-area-body m-layout-default">
            <p-tree #tree
                    [value]="filteredNodes"
                    selectionMode="multiple"
                    [(selection)]="selectedNodes"
                    class="m-layout-area-body"
                    [loading]="loading"
                    (onNodeSelect)="onNodeSelect($event)"
                    (onNodeUnselect)="onNodeUnselect($event)">

                <ng-template let-node
                             pTemplate="organization">
                    {{node.label}}
                </ng-template>
                <ng-template let-node
                             pTemplate="project">
                    <!-- node.data holds IProjectsTreeProject -->
                    <span class="mr-8"
                          id="tree-node-key-{{node.key}}"
                          [ngStyle]="getProjectStyle(node)">
                        {{node.label}}

                        @if(node.data.projectStatus){
                        &nbsp;({{node.data.projectStatus | translateEnum:"projectStatus"}})
                        }@else if(node.data.from && node.data.until){
                        <dd style="margin: 0;">&nbsp;({{ {from:node.data.from, until:node.data.until, format:'ddd L'} | dateRange }})</dd>
                        }
                    </span>
                </ng-template>
            </p-tree>
        </div>
    </div>
</div>