import { Component } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective } from "@angular/forms";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-pedestrian-configuration",
    templateUrl: "./device-link-pedestrian-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkPedestrianConfigurationComponent extends DeviceLinkFormGroupComponent {

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.pedestrianConfiguration) {
            if (this.fgd.form.get("pedestrianConfiguration")) this.fgd.form.removeControl("pedestrianConfiguration");

            this.form = this.formBuilder.group({
                analyzeForwardDirection: [true],
                analyzeReverseDirection: [false],
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.pedestrianConfiguration);
            this.fgd.form.addControl("pedestrianConfiguration", this.form);
            return true;
        }

        if (this.fgd.form.get("pedestrianConfiguration")) this.fgd.form.removeControl("pedestrianConfiguration");
        this.form = null;
        return false;
    }
}
