<div class="location-popup__tile-container">
    <div style="display:grid; grid-template-columns: 1fr 1fr; gap:10px;">
        <dl class="location-popup__description-list">
            <dt>{{ "general.device" | translate }}</dt>
            <dd>{{ data.deviceCode }}</dd>
        </dl>
        <dl class="location-popup__description-list">
            <dt>{{ "general.imei" | translate }}</dt>
            <dd>{{ data.imei }}</dd>
        </dl>
        @if (data.qrCode) {
            <dl class="location-popup__description-list">
                <dt>{{ "general.qrCode" | translate }}</dt>
                <dd>{{ data.qrCode }}</dd>
            </dl>
        }
        @if (data.currentNetwork?.technology) {
            <dl class="location-popup__description-list">
                <dt>{{ "deviceStatus.technology" | translate }}</dt>
                <dd>{{ data.currentNetwork.technology }}</dd>
            </dl>
        }
        @if (data.currentPower?.batteryVoltage) {
            <dl class="location-popup__description-list">
                <dt>{{ "deviceStatus.batteryVoltage" | translate }}</dt>
                <dd>{{ data.currentPower.batteryVoltage | number:'1.1-1' }} V</dd>
            </dl>
        }
        @if (data.currentNetwork?.signalStrength) {
            <dl class="location-popup__description-list">
                <dt>{{ "deviceStatus.signalStrength" | translate }}</dt>
                <dd>{{ data.currentNetwork?.signalStrength }} dB</dd>
            </dl>
        }
    </div>
    @if (data.powerHistory) {
        <app-chart *ngIf="chartConfiguration"
                   [configuration]="chartConfiguration"
                   [height]="120">
        </app-chart>
    }
</div>
