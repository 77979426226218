<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        Generate Device Status
    </p-header>
    <form [formGroup]="generateDeviceStatusForm"
          *ngIf="generateDeviceStatusForm"
          class="m-layout-area-body p-16"
          autocomplete="off">
        <div class="m-form-group row">
            <label class="col-4">
                Device Type
            </label>
            <div class="col-8">
                <p-dropdown id="deviceType"
                            [options]="deviceTypes"
                            formControlName="deviceType"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select device type"
                            #deviceTypeInput>
                </p-dropdown>
            </div>
        </div>
        <div class="m-form-group row">
            <p class="col-12"
               *ngIf="isRetroOrGeneric()">Only IMEI required (identification of device)</p>
            <p class="col-12"
               *ngIf="isRetroTrafficLight()">
                <b>Warning: this will NOT trigger an error if the IMEI is unknown!</b>

            </p>
            <p class="col-12"
               *ngIf="isMPB5000()">Only SerialNo required (identification of device)</p>
        </div>
        <div class="m-form-group row"
             *ngIf="isRetroOrGeneric()">
            <label class="col-4">
                IMEI
            </label>
            <div class="col-8">
                <input type="text"
                       class="p-inputtext"
                       formControlName="imei"
                       #imeiInput>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isMPB5000()">
            <label class="col-4">
                SerialNo
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       formControlName="serialNo"
                       #serialNoInput>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isTrafficLight()">
            <label class="col-4">
                Status
            </label>
            <div class="col-8">
                <p-dropdown id="statusId"
                            [options]="statuses"
                            [filter]="true"
                            formControlName="status"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select status"
                            #statusIdInput>
                </p-dropdown>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isDeviceTypeSelected()">
            <label class="col-4">
                Latitude & Longitude
            </label>
            <div class="col-8">
                <input type="number"
                       formControlName="latitude"
                       class="p-inputtext"
                       #latitudeInput>
                <input type="number"
                       class="p-inputtext"
                       formControlName="longitude"
                       #longitudeInput>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isDeviceTypeSelected()">
            <label class="col-4">
                Battery Voltage </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       formControlName="batteryVoltage"
                       #batteryVoltageInput>


            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isRetroOrGeneric()">
            <label class="col-4">
                Impact? </label>
            <div class="col-8">
                <input class="group-checkbox"
                       type="checkbox"
                       formControlName="impact" />
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="isMPB5000()">
            <label class="col-4">
                Head
            </label>
            <div class="col-8">
                <p-dropdown id="headId"
                            [options]="heads"
                            [filter]="true"
                            formControlName="head"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select head"
                            #headIdInput>
                </p-dropdown>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isRetroTrafficLight()">
            <label class="col-4">
                Head
            </label>
            <div class="col-8">
                <p-dropdown id="headId"
                            [options]="heads"
                            [filter]="true"
                            formControlName="headId"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select head"
                            #headIdInput>
                </p-dropdown>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isMPB5000()">
            <label class="col-4">
                Role
            </label>
            <div class="col-8">
                <p-dropdown id="roleId"
                            [options]="roles"
                            [filter]="true"
                            formControlName="role"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select role"
                            #roleIdInput>
                </p-dropdown>
            </div>
        </div>
        <div class="m-form-group row"
             *ngIf="isMPB5000()">
            <label class="col-4">
                Radar Detector
            </label>
            <div class="col-8">
                <p-dropdown id="radarDetectorId"
                            [options]="radarDetectors"
                            [filter]="true"
                            formControlName="radarDetector"
                            [autoDisplayFirst]="false"
                            appendTo="body"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="Select radar detector"
                            #radarDetectorIdInput>
                </p-dropdown>
            </div>
        </div>
        <ng-container formGroupName="simInformation"
                      *ngIf="isMPB5000()">
            <h4 class="mb-8">Sim Information</h4>
            <div class="m-form-group row">
                <label class="col-4">
                    Provider</label>
                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           formControlName="provider"
                           #providerInput>
                </div>
            </div>
            <div class="m-form-group row">
                <label class="col-4">
                    Connection State</label>
                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           formControlName="connState"
                           #connStateInput>
                </div>
            </div>
            <div class="m-form-group row">
                <label class="col-4">
                    Connection Type</label>
                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           formControlName="connectionType"
                           #connectionTypeInput>
                </div>
            </div>
            <div class="m-form-group row">
                <label class="col-4">
                    Revision</label>
                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           formControlName="revision"
                           #revisionInput>
                </div>
            </div>
            <div class="m-form-group row">
                <label class="col-4">
                    Rssi</label>
                <div class="col-8">
                    <input type="number"
                           class="p-inputtext"
                           formControlName="rssi"
                           #rssiInput>
                </div>
            </div>
        </ng-container>
        <ng-container formGroupName="arrowBoardStatus"
                      *ngIf="isGenericTeltonika()">
            <h4 class="mb-8">Arrow Board Status</h4>
            <div class="m-form-group row">
                <label class="col-4">
                    Direction</label>
                <div class="col-8">
                    <p-dropdown id="directionId"
                                [options]="arrowBoardDirections"
                                [filter]="true"
                                formControlName="direction"
                                [autoDisplayFirst]="false"
                                appendTo="body"
                                [panelStyle]="{'width':'100%'}"
                                placeholder="Select direction"
                                #directionIdInput>
                    </p-dropdown>
                </div>
            </div>
            <div class="m-form-group row">
                <label class="col-4">
                    Position</label>
                <div class="col-8">
                    <p-dropdown id="positionId"
                                [options]="arrowBoardPositions"
                                [filter]="true"
                                formControlName="position"
                                [autoDisplayFirst]="false"
                                appendTo="body"
                                [panelStyle]="{'width':'100%'}"
                                placeholder="Select position"
                                #positionIdInput>
                    </p-dropdown>
                </div>
            </div>
        </ng-container>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>