import { Component, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, HostListener } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-zigbee-configuration",
    templateUrl: "./device-zigbee-configuration.component.html"
})
export class DeviceZigbeeConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    zigbeeConfigurationForm: SigncoFormGroup;
    zigbeeRoles: ViewModelEnum[];

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly domainDataService: DomainDataService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.zigbeeConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        this.zigbeeRoles = await this.domainDataService.get(DomainData.ZigbeeRole);

        this.zigbeeConfigurationForm = this.formBuilder.group({
            port: [null, Validators.required],
            channel: [0, Validators.required],
            roleId: [this.zigbeeRoles.takeFirstOrDefault(), Validators.required],
            resetPin: this.device.zigbeeConfiguration.resetPin || 119
        }) as SigncoFormGroup;

        this.zigbeeConfigurationForm.patchValue(this.device.zigbeeConfiguration);
        this.zigbeeConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.zigbeeConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);

        Object.assign(deviceUpdater.zigbeeConfiguration, this.zigbeeConfigurationForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}