<div class="totemDisplay"
     style="display:grid; gap:8px;">
    <div *ngIf="isExpanded">
        <button class="m-btn m-btn-mode-select"
                (click)="openSetModeDialog($event)">
            Mode {{totemDisplay.currentMode.id}}: {{totemDisplay.currentMode.description}}
            <app-svg-icon icon="edit"
                          divClass="icon-16"
                          class="ml-4"></app-svg-icon>
        </button>
    </div>
    <app-totem-display-preview [mode]="totemDisplay.currentMode"
                               [configuration]="totemDisplay.fieldConfigurations"
                               [isExpanded]="isExpanded"
                               [tooltipDisabled]="isExpanded"
                               [pTooltip]="totemDisplay.currentMode?.description">
    </app-totem-display-preview>
</div>

<app-totem-display-manage-dialog></app-totem-display-manage-dialog>