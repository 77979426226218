<div class="m-form-group"
     *ngIf="formVisible && form"
     formGroupName="externalInputToGpioLink">
    <div class="p-16 m-border-bottom">
        <h3>{{"externalInputConfiguration.link" | translate }}</h3>
    </div>

    <div class="p-16"
         formGroupName="pins">
        <div *ngIf="showExternalInputManualWarning">
            {{"externalInputConfiguration.showExternalInputManualWarning" | translate }}
        </div>
        <div *ngFor="let externalInputGpioPinForm of externalInputGpioPinForms.controls; let externalInputGpioPinIndex = index"
             class="m-form-group row"
             formGroupName="{{externalInputGpioPinIndex}}">

            <label class="col-2">
                {{"externalInputConfiguration.input" | translate}} {{externalInputGpioPinForm.get('inputLabel').value}}
            </label>

            <div class="col-10">
                <div class="m-form-group row">
                    <label class="col-3"
                           for="pinId{{externalInputGpioPinIndex}}">
                        {{"externalInputConfiguration.pinId" | translate}}
                    </label>

                    <div class="col-9">
                        <input id="pinId{{externalInputGpioPinIndex}}"
                               type="number"
                               class="p-inputtext"
                               [formControl]="externalInputGpioPinForm.get('pinId')"
                               appAutofocus
                               #pinIdInput />

                        <app-form-validation [control]="externalInputGpioPinForm.get('pinId')"
                                             [input]="pinIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row">
                    <label class="col-3"
                           for="inverse{{externalInputGpioPinIndex}}">
                        {{"externalInputConfiguration.inverse" | translate}}
                    </label>

                    <div class="col-9">
                        <p-inputSwitch id="inverse{{externalInputGpioPinIndex}}"
                                       [formControl]="externalInputGpioPinForm.get('inverse')"></p-inputSwitch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>