<form *ngIf="floatingCarForm"
      [formGroup]="floatingCarForm"
      class="p-16 m-border-top m-overflow-auto m-layout-area-body"
      autocomplete="off">
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': floatingCarForm.get('isEnabled').showErrors }">
        <label class="col-4"
               for="isEnabled">
            {{"floatingCar.isEnabled" | translate}}
        </label>

        <div class="col-8">
            <p-inputSwitch id="isEnabled"
                           formControlName="isEnabled"
                           #isEnabledInput>
            </p-inputSwitch>

            <app-form-validation [control]="floatingCarForm.get('isEnabled')"
                                 [input]="isEnabledInput">
            </app-form-validation>
        </div>
    </div>

    <div class="py-8">
        <h3>{{"floatingCar.segment" | translate}}</h3>
    </div>

    <div class="m-form-group row">
        <div class="col-4"></div>
        <div class="col-8">
            <img id="segment_image"
                 *ngIf="floatingCarSegmentPreview"
                 [src]="floatingCarSegmentPreview | safeHtml">
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom"
     *ngIf="floatingCarForm">

    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>