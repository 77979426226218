import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { ViewModelEnum } from "src/app/models/domain-data";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-bike-xpos-configuration",
    templateUrl: "./device-link-bike-xpos-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkBikeXPosConfigurationComponent extends DeviceLinkFormGroupComponent {
    @Input() tubeOptions: SelectItem[];
    @Input() tubeOptionsWithEmpty: SelectItem[];

    @Output() showOffsetPopup = new EventEmitter<number>();


    bikeXPosAlpha: number;
    bikeXPosBeta: number;
    bikeXPosGamma: number;

    devicePositions: ViewModelEnum[];

    get tubeForm(): SigncoFormGroup {
        return this.fgd.form.get("tubeConfiguration") as SigncoFormGroup;
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly domainDataService: DomainDataService,
        private readonly fgd: FormGroupDirective
    ) {
        super();

        this.domainDataService.get(DomainData.DevicePosition).then(devicePositions => {
            this.devicePositions = devicePositions;
        });
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration.bikeXPosConfiguration) {
            if (this.fgd.form.get("bikeXPosConfiguration")) this.fgd.form.removeControl("bikeXPosConfiguration");

            this.form = this.formBuilder.group({
                tube3: this.tubeOptions[2].value,
                devicePosition: null,
                drivingLaneWidthCm: [null, [Validators.required, Validators.min(1)]],
                tube2LengthCm: null,
                tube3LengthCm: null,
                tube3XOffsetCm: null,
                tube3YOffset1Cm: null,
                tube3YOffset2Cm: null,
                tube3OffsetInMilliseconds: [null, Validators.required]
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.bikeXPosConfiguration);
            this.fgd.form.addControl("bikeXPosConfiguration", this.form);
            this.onBikeXPosDetailsChanged();
            return true;
        }

        if (this.fgd.form.get("bikeXPosConfiguration")) this.fgd.form.removeControl("bikeXPosConfiguration");
        this.form = null;
        return false;
    }

    onBikeXPosDetailsChanged() {
        const a = parseFloat(this.form.get("tube3YOffset2Cm").value) - parseFloat(this.form.get("tube3YOffset1Cm").value);
        const b = this.form.get("tube2LengthCm").value;
        const c = this.form.get("tube3LengthCm").value;

        const alpha = Math.acos((b * b + c * c - a * a) / (2 * b * c)) * 180 / Math.PI;
        const beta = Math.acos((a * a + c * c - b * b) / (2 * a * c)) * 180 / Math.PI;
        const gamma = Math.acos((a * a + b * b - c * c) / (2 * a * b)) * 180 / Math.PI;

        this.bikeXPosAlpha = Math.round(alpha * 100) / 100;
        this.bikeXPosBeta = Math.round(beta * 100) / 100;
        this.bikeXPosGamma = Math.round(gamma * 100) / 100;
    }

    onShowOffsetPopup(value: number) {
        this.showOffsetPopup.emit(value);
    }
}
