import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlContainer, UntypedFormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";
import { AnalysisType } from "src/app/models/measuring-point";

@Component({
    selector: "app-device-link-tube-configuration",
    templateUrl: "./device-link-tube-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkTubeConfigurationComponent extends DeviceLinkFormGroupComponent {
    @Input() tubeOptions: SelectItem[];

    @Output() showOffsetPopup = new EventEmitter<number>();

    showTubeDistanceWarning: boolean;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    async configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        if (analyzerConfiguration?.tubeConfiguration) {
            if (this.fgd.form.get("tubeConfiguration")) this.fgd.form.removeControl("tubeConfiguration");

            this.form = this.formBuilder.group({
                tube1: [this.tubeOptions[0].value, Validators.required],
                tube2: [this.tubeOptions[1].value, Validators.required],
                tubeDistanceCm: [null, Validators.required],
                tube1OffsetInMilliseconds: [null, Validators.required],
                tube1OffsetOnlyWhenNotClipped: [false, Validators.required],
                tube2OffsetInMilliseconds: [null, Validators.required],
                tube2OffsetOnlyWhenNotClipped: [false, Validators.required],
            }) as SigncoFormGroup;

            this.form.patchValue(analyzerConfiguration.tubeConfiguration);
            this.fgd.form.addControl("tubeConfiguration", this.form);
            return true;
        }

        if (this.fgd.form.get("tubeConfiguration")) this.fgd.form.removeControl("tubeConfiguration");
        this.form = null;
        return false;
    }


    tubeDistanceChanged() {
        if ((this.analyzerConfiguration.type === AnalysisType.CarAndBike || this.analyzerConfiguration.type === AnalysisType.Bike || this.analyzerConfiguration.type === AnalysisType.Car || this.analyzerConfiguration.type === AnalysisType.CarXPos)
            && (!this.form.get("tubeDistanceCm").value && this.form.get("tubeDistanceCm").value !== 0)) {
            this.showTubeDistanceWarning = false;
            return;
        }
        if ((this.analyzerConfiguration.type === AnalysisType.CarAndBike || this.analyzerConfiguration.type === AnalysisType.Bike || this.analyzerConfiguration.type === AnalysisType.Car || this.analyzerConfiguration.type === AnalysisType.CarXPos) &&
            (this.form.get("tubeDistanceCm").value < 20 || this.form.get("tubeDistanceCm").value > 30)) {

            this.showTubeDistanceWarning = true;
            return;
        } else if (this.analyzerConfiguration.type === AnalysisType.Pedestrian && this.form.get("tubeDistanceCm").value > 10) {
            this.showTubeDistanceWarning = true;
            return;
        }

        this.showTubeDistanceWarning = false;
    }

    onShowOffsetPopup(value: number) {
        this.showOffsetPopup.emit(value);
    }
}
