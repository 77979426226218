<form *ngIf="device && deviceVehicleForm"
      [formGroup]="deviceVehicleForm"
      class="m-layout-area-body m-layout-w-actions-bottom m-border-top"
      autocomplete="off">

    <div class="p-16 m-layout-area-body m-overflow-auto">
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': deviceVehicleForm.get('workerDriverId').showErrors }">

            <label class="col-4"
                   for="workerDriverId">
                {{"vehicleConfiguration.workerDriver" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown [options]="workers"
                            id="workerDriverId"
                            formControlName="workerDriverId"
                            [panelStyle]="{'width':'100%'}"
                            [autoDisplayFirst]="false"
                            placeholder="{{'vehicleConfiguration.selectWorkerDriver' | translate }}"
                            [filter]="true"
                            [showHeader]="true"
                            [showToggleAll]="false"
                            appAutofocus
                            #workerDriverId> </p-dropdown>

                <app-form-validation [control]="deviceVehicleForm.get('workerDriverId')"
                                     [input]="workerDriverId">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': deviceVehicleForm.get('isGeoDynamics').showErrors }">

            <label class="col-4"
                   for="isGeoDynamics">
                {{"vehicleConfiguration.isGeoDynamicsVehicle" | translate}}
            </label>

            <div class="col-8">
                <p-inputSwitch id="isGeoDynamics"
                               formControlName="isGeoDynamics"
                               (onChange)="adjustGeoDynamics($event.checked)">
                </p-inputSwitch>
            </div>
        </div>


        <ng-container *ngIf="deviceVehicleForm.get('isGeoDynamics').value">
            <div class="m-form-group row"
                 *ngIf="deviceVehicleForm.contains('id')"
                 [ngClass]="{ 'has-error': deviceVehicleForm.get('id').showErrors }">

                <label class="col-4"
                       for="id">
                </label>

                <div class="col-8">
                    <p-dropdown [options]="geoDynamicsVehicles"
                                id="id"
                                formControlName="id"
                                [panelStyle]="{'width':'100%'}"
                                [autoDisplayFirst]="false"
                                placeholder="{{'vehicleConfiguration.selectGeoDynamicsVehicle' | translate }}"
                                [filter]="true"
                                [showHeader]="true"
                                [showToggleAll]="false"
                                #idInput>
                    </p-dropdown>

                    <app-form-validation [control]="deviceVehicleForm.get('id')"
                                         [input]="idInput">
                    </app-form-validation>
                </div>
            </div>
        </ng-container>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': deviceVehicleForm.get('isWebfleet').showErrors }">

            <label class="col-4"
                   for="isWebfleet">
                {{"vehicleConfiguration.isWebfleetVehicle" | translate}}
            </label>

            <div class="col-8">
                <p-inputSwitch id="isWebfleet"
                               formControlName="isWebfleet"
                               (onChange)="adjustWebfleet($event.checked)">
                </p-inputSwitch>
            </div>
        </div>
        <ng-container *ngIf="deviceVehicleForm.get('isWebfleet').value">
            <div class="m-form-group row"
                 *ngIf="deviceVehicleForm.contains('webfleetId')"
                 [ngClass]="{ 'has-error': deviceVehicleForm.get('webfleetId').showErrors }">

                <label class="col-4"
                       for="webfleetId">
                </label>

                <div class="col-8">
                    <p-dropdown [options]="webfleetVehicles"
                                id="webfleetId"
                                formControlName="webfleetId"
                                [panelStyle]="{'width':'100%'}"
                                [autoDisplayFirst]="false"
                                placeholder="{{'vehicleConfiguration.selectWebfleetVehicle' | translate }}"
                                [filter]="true"
                                [showHeader]="true"
                                [showToggleAll]="false"
                                #webfleetIdInput>
                    </p-dropdown>

                    <app-form-validation [control]="deviceVehicleForm.get('webfleetId')"
                                         [input]="webfleetIdInput">
                    </app-form-validation>
                </div>
            </div>
        </ng-container>

        <div class="m-form-group">
            <app-photo-input [labelColSize]="4"
                             [colSize]="8"
                             [photoColSize]="34"
                             [attachment]="photo"
                             [required]="false"
                             [submitted]="deviceVehicleForm.submitted"
                             [deleteImageMessageToTranslate]="'vehicleConfiguration.deleteImageConfirmation'"></app-photo-input>
        </div>
    </div>

    <div class="m-btn-group m-btn-group-right m-border-top m-layout-area-bottom px-16 py-8">
        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button"
                    id="geoDynamics_tab_reset_button">
                {{"form.reset" | translate}}
            </button>

            <button [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit"
                    id="geodyanmics_tab_submit_button">
                <app-loading-spinner id="geoDynamics_tab_spinner"
                                     *ngIf="submitting">
                </app-loading-spinner>
                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</form>