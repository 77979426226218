<div class="m-layout-w-actions-top m-fixed-height m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="canCreateUser"
                id="createUserButton">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"manageUser.create" | translate}}
        </button>

        <div class="flex align-center gap-8">
            <p-inputSwitch inputId="includeObsoleteSwitch"
                           class="m-switch"
                           *ngIf="canDeleteUser"
                           [(ngModel)]="usersComponent.includeObsolete"
                           (onChange)="updateComponent()"></p-inputSwitch>
            <label class="m-switch-label"
                   *ngIf="canDeleteUser"
                   for="includeObsoleteSwitch">{{"manageUser.includeObsolete" | translate}}</label>
        </div>
    </div>

    <app-users [editCommand]="true"
               [deleteCommand]="true"
               [organizationId]="organization?.id"
               class="m-layout-area-body m-border-top"
               stateKey="admin-users.component.html"></app-users>
</div>

<app-manage-user-dialog></app-manage-user-dialog>