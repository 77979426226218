import { Pipe, PipeTransform } from "@angular/core";
import { IDeviceTypeRule } from "src/app/models/alarm";
import { DomainDataService } from "src/app/services/domain-data.service";

@Pipe({
    name: "translateDeviceTypeRules"
})
export class TranslateDeviceTypeRulesPipe implements PipeTransform {
    constructor(private readonly domainDataService: DomainDataService) {
    }

    transform(value: IDeviceTypeRule[], language: string): string {
        if (!value || value.length === 0) return "";
        const translations: string[] = [];
        for (const deviceTypeRule of value) {
            translations.push(this.domainDataService.translateEnum("deviceType", deviceTypeRule.deviceTypeId));
        }
        const result = translations.sort((a, b) => a.localeCompare(b)).join(", ");
        return result;
    }
}
