import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ServiceRequestOptions } from "src/app/models/search";
import { ITask } from "src/app/models/task";
import { TasksComponent } from "src/app/modules/shared/components/tasks/tasks.component";
import { TaskApi } from "src/app/resource/task.api";
import { MapDetailService } from "src/app/services/map-detail.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SubscriptionManager } from "src/app/utilities";
import { TaskDetailContainerComponent } from "../task-detail-container/task-detail-container.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthorizationInfo } from "src/app/models/user";
import { BackendRights } from "src/app/models/backend-rights";
import { Rights } from "src/app/models/rights";

@Component({
    selector: "app-task-location",
    templateUrl: "./task-location.component.html",
    styleUrls: []
})
export class TaskLocationComponent implements OnInit {
    @ViewChild(TaskDetailContainerComponent, { static: true }) taskDetailContainerComponent: TaskDetailContainerComponent;
    @ViewChild(TasksComponent, { static: false }) tasksComponent: TasksComponent;

    loading: boolean;

    task: ITask;

    private readonly subscriptionManager = new SubscriptionManager();

    private canEditTask = false;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly mapDetailService: MapDetailService,
        private readonly navigationService: NavigationService,
        private readonly taskApi: TaskApi
    ) {
        elementRef.nativeElement.classList.add("container-detail");
    }

    ngOnInit() {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            // Attempt navigation to what the params specified
            await this.setTaskFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
        const rightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canEditTask = rights.hasBackendRight(BackendRights.EditTask);
        });
        this.subscriptionManager.add("authorizationInfoSubscription", rightsSubscription);

    }

    private async setTaskFromParams(params: { [key: string]: any }): Promise<void> {
        if (!params) return;

        this.loading = true;

        const taskIdString = decodeURI(params["taskId"]);

        if (taskIdString === "new") {
            this.task = this.navigationService.getNewTask();
        } else {
            const taskId = Number.parseInt(taskIdString, 10);
            this.task = Number.isNaN(taskId) ? null : await this.taskApi.get$(taskId, null, this.getServiceRequestOptions()).toPromise();
        }

        if (!this.task) {
            this.toMap();
            return;
        }

        this.loading = false;
    }

    toMap() {
        this.mapDetailService.navigateToMapDetail();
    }

    get canEditLocation(): boolean {
        return this.task && this.task.id && this.canEditTask;
    }

    handleOnTaskSaved(savedTask: ITask) {
        const shouldNavigate = !this.task || this.task.id !== savedTask.id;

        if (!shouldNavigate) {
            Object.assign(this.task, savedTask);
        } else {
            this.navigationService.toTask(savedTask.id);
        }
    }

    private getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("task", "location");
        options.includes.add("task", "assignment");
        options.includes.add("task", "currentStatus");
        options.includes.add("task", "copiedTask");
        options.includes.add("taskStatusHistory", "workerTasks");
        options.includes.add("workerTask", "worker");
        options.includes.add("assignment", "parentAssignment");
        options.includes.add("assignment", "project");
        return options;
    }

    async handleOnTaskSelect(task: ITask) {
        if (!task) {
            this.toMap();
            return;
        }

        if (this.task && task.id === this.task.id) return;
        if (!this.taskDetailContainerComponent || !this.taskDetailContainerComponent.selectedTab) return;

        const urlIndexStart = window.location.href.indexOf(`/${this.taskDetailContainerComponent.selectedTab.url}`);
        const urlAppend = window.location.href.substring(urlIndexStart + 1);

        await this.navigationService.toTask(task.id, urlAppend);
    }

    backToDetailedTasksRepresentation() {
        this.navigationService.toTasks(this.task.assignment.id);
    }
}
