import { CustomTableComponent, TableColumn, FilterType, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { Direction } from "src/app/models/direction";
import { IMeasuringPointAggregation } from "src/app/models/measuring-point-aggregation";
import { MapDetail } from "src/app/services/map-detail.service";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { IMeasuringPointSummary } from "src/app/models/web";

@Component({
    selector: "app-measuring-point-aggregation-direction",
    templateUrl: "./measuring-point-aggregation-direction.component.html"
})
export class MeasuringPointAggregationDirectionComponent extends CustomTableComponent<IMeasuringPointAggregation> implements OnChanges {

    @Input() measuringPoint: IMeasuringPoint;
    @Input() duplicateCommand = true;
    @Input() deleteCommand = true;
    @Input() max: number;

    isMapOpen = false;
    mapDetail: MapDetail;

    isDirty = false;
    isSaving = false;
    canDuplicate = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService) {

        super("measuring-point-aggregation-direction.component", elementRef, tableService);

        this.enableInitialData = true;
        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;
        this.stretchHeight = true;

        this.addColumn(new TableColumn("code", "measuringPoints.code", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("fromTo", "measuringPoints.fromTo", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("primaryDirection", "", { sortable: true, type: ColumnType.Icon, width: 60 }));
        this.addColumn(new TableColumn("reverseDirection", "", { sortable: true, type: ColumnType.Icon, width: 60 }));
        this.addColumn(new TableColumn("factor", "measuringPointAggregation.factor", { width: 100, resizable: false }));

        this.registerCommand({
            text: "general.duplicate",
            icon: "copy",
            click: (measuringPointAggregation) => this.duplicate(measuringPointAggregation),
            disabledFunc: () => !this.canDuplicate
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (measuringPointAggregation) => this.removeData([measuringPointAggregation]),
            validFunc: () => this.deleteCommand
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.reload(false);
        }

        super.ngOnChanges(changes);
    }

    loadTableRows() {
        this.setLoading(false);
    }

    isForwardDirection(aggregation: IMeasuringPointAggregation): boolean {
        return aggregation && aggregation.sourceMeasuringPointDirectionId === Direction.Forward;
    }

    toggleReverseDirection(aggregation: IMeasuringPointAggregation, triggerUpdate = true) {
        aggregation.sourceMeasuringPointDirectionId = this.isForwardDirection(aggregation) ?
            Direction.Reverse :
            Direction.Forward;

        if (triggerUpdate) {
            this.updateIsDirty();
        }
    }

    duplicate(aggregation: IMeasuringPointAggregation) {
        if (!this.canDuplicate) return;

        const newAggregation = this.createAggregationFromMeasuringPoint(aggregation.sourceMeasuringPoint);
        this.toggleReverseDirection(newAggregation);

        const currentIndex = this.data.indexOf(aggregation);
        this.insertData(newAggregation, currentIndex + 1);
    }

    private createAggregationFromMeasuringPoint(measuringPoint: IMeasuringPointSummary): IMeasuringPointAggregation {
        return {
            sourceMeasuringPoint: measuringPoint,
            factor: 1
        } as IMeasuringPointAggregation;
    }

    updateIsDirty() {
        this.isDirty = !this.isInitialData();
    }

    onSetData() {
        this.updateIsDirty();
        this.canDuplicate = !this.max || this.getCurrentData().length < this.max;
    }
}