import { IImportedSharedKey } from "../models/imported-shared-key";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ImportedSharedKeyApi extends ApiService<IImportedSharedKey, void, void> {

    getRoute(): string {
        return "SharedKeys/Import";
    }

    import$(key: string): Observable<void> {
        return this.http.post<void>(`${this.getUrl()}/${key}`, null);
    }
}