import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, ScaleOptionsByType, TooltipItem } from "chart.js";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DataSetType, IHistoricalData, ILiveData, ILiveVehiclesGroupPerOption, ILiveVehiclesLastValue, IVehicleHistoryDataSet, LiveDataRangeOption, PinnedDataOption } from "src/app/models/pinned-data";
import { MeasuringPointPinnedHistoryDataSearchParameters, MeasuringPointPinnedLiveDataSearchParameters, SelectedDataSetType } from "src/app/resource/web";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { PinnedDataService } from "src/app/services/pinned-data.service";
import { ToastService } from "src/app/services/toast.service";
import { JsonUtils } from "src/app/utilities";
import { MomentDatePipe, MomentDateTimePipe } from "../../../shared/pipes/datetime.pipe";
import { ChartComponent } from "../../../shared/components/signco-chart/signco-chart.component";
import { TwoLevelSelectValue } from "../../../shared/components/two-level-select/two-level-select.component";
import { Constants } from "src/app/constants/constants";
import { LiveTileViewModel, LiveTilesService } from "../../services/live-tiles.service";
import { MeasuredDataManageDialogComponent } from "../measured-data-manage-dialog/measured-data-manage-dialog.component";

const colorPerDistancePercentile: { [key: string]: string } = {
    "a05": "rgba(0, 255, 0, 1.0)",
    "a15": "rgba(255, 136, 114, 1.0)",
    "a50": "rgba(255, 0, 0, 1.0)"
};

const colorPerSpeedPercentile: { [key: string]: string } = {
    "v50": "rgba(47, 63, 212, 1.0)",
    "v85": "rgba(146, 47, 212, 1.0)",
    "v95": "rgba(230, 12, 237, 1.0)"
};

const colorPerParkingOccupancyDataSetForChart: { [key: string]: string } = {
    "parkingOccupancyOccupiedPlaces": "rgba(237,125,49,1.0)",
    "parkingOccupancyTotalCapacity": "rgba(0,255,255,1.0)"
};

const colorPerParkingOccupancyDataSetForLastValues: { [key: string]: string } = {
    "parkingOccupancyAvailablePlaces": "rgba(121, 235, 117,1.0)",
};

const colorForSpeed = "rgba(47, 63, 212, 1.0)";

const distanceAxeID = "distanceAxe";
const speedAxeID = "speedAxe";
const occupancyAxeID = "occupancyAxe";
const intensityAxeID = "intensityAxe";

export class MeasuredDataDisplayOptions {
    public showLiveData: true;
    public showHistoricalData: true;
    public liveDataRange: LiveDataRangeOption;
    public historicalDataRange: TwoLevelSelectValue;

    public dataOptions: SelectedDataSetType[];
}

// This is option1 of the TwoLevelSelectValue. The user can select a number of hours or a number of days
class RangeSelectItemValue {
    lastHoursCount?: number;
    lastDaysCount?: number;
}

@Component({
    selector: "app-measured-data-pinned-data",
    templateUrl: "./measured-date-pinned-data.component.html",
})
export class MeasuredDataPinnedDataComponent implements OnChanges, OnDestroy {
    @Input() viewModel: LiveTileViewModel;

    @Output() onResize = new EventEmitter();
    @Output() liveDataUpdate = new EventEmitter<ILiveData>();

    @ViewChild("resizableDiv", { static: false }) resizableDiv: ElementRef<HTMLDivElement>;
    @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;
    @ViewChild(MeasuredDataManageDialogComponent, { static: true }) manageDialog: MeasuredDataManageDialogComponent;

    chartConfiguration: ChartConfiguration;
    private disposed = false;

    vehicleCategoryViewModels: ViewModelEnum[];

    liveData: ILiveData;
    historicalData: IHistoricalData;
    liveDataLoading = false;
    historicalDataLoading = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private readonly domainDataService: DomainDataService,
        private readonly pinnedDataService: PinnedDataService,
        private readonly liveTileService: LiveTilesService
    ) {
        this.domainDataService.get(DomainData.VehicleCategory, { orderBy: null }).then((result) => {
            this.vehicleCategoryViewModels = result;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const viewModelChange = changes["viewModel"];

        if (viewModelChange) {
            if (this.viewModel?.displayOptions?.measuredData) {
                this.OnViewModelChanged();
            } else {
                // When a new live tile is shown, we open the dialog so the user can set the options
                this.openManageDialog();
            }
        }
    }

    ngOnDestroy() {
        this.disposed = true;
    }

    private loadLiveData() {
        this.liveDataLoading = true;

        this.pinnedDataService.loadLiveData$(this.viewModel.measuringPoint.id, this.createMeasuringPointPinnedLiveDataSearchParameters(), false)
            .subscribe({
                next: async (result) => {
                    const liveData = result;

                    this.liveData = liveData;
                    this.liveDataUpdate.emit(liveData);
                    this.liveDataLoading = false;

                    this.cd.detectChanges();
                },
                error: (error) => {
                    this.liveDataLoading = false;
                }
            });
    }

    private loadHistoricalData() {
        this.historicalDataLoading = true;
        this.pinnedDataService.loadHistoricalData$(this.viewModel.measuringPoint.id,
            this.createMeasuringPointPinnedHistoryDataSearchParameters())
            .subscribe({
                next: async (result) => {
                    this.historicalData = result;
                    this.historicalDataLoading = false;
                    this.cd.detectChanges();

                    setTimeout(() => {
                        this.initializeChart();
                    });
                },
                error: (error) => {
                    this.historicalDataLoading = false;
                }
            });
    }

    /**
     * Chart configuration
     */
    private initializeChart() {
        if (this.disposed) return;

        const clearSpeed = () => {
            this.chartConfiguration = null;
        };

        const clear = () => {
            this.chartConfiguration = null;
        };

        if (!this.historicalData) {
            clear();
        }

        if (this.historicalData) {
            if (this.historicalData.dataSets && this.historicalData.dataSets.length > 0) {

                // Chart
                // if dataSets.length > 0 then subDataSets must be present
                const dates = this.historicalData.dataSets[0].history
                    .map(x => x.timestamp)
                    .distinct();

                const times = this.historicalData.dataSets[0].history
                    .map(x => x.timestamp.getTime())
                    .distinct();

                const rangeAndAggregation = this.viewModel.displayOptions.measuredData.historicalDataRange;
                const range = rangeAndAggregation.option1 as RangeSelectItemValue;
                const aggregation = rangeAndAggregation.option2;

                const minTime = times.min();
                const maxTime = times.max();
                let interval = (maxTime - times.filter(x => x !== maxTime).max()) / 1000 / 60;
                if (aggregation.value === "hour") {
                    interval = interval / 60;
                } else if (aggregation.value === "day") {
                    interval = interval / 60 / 24;
                }

                const chartOptions = {
                    showLines: true,
                    plugins: {},
                } as ChartOptions;

                chartOptions.plugins.legend = {
                    display: false
                };

                chartOptions.scales = {};
                this.createYAxis(chartOptions.scales);

                chartOptions.plugins.tooltip = {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        title: (tooltipItems: TooltipItem<"line">[]) => {
                            // example: 2018-12-30T20:00:00.000
                            const currentDateTime = new Date(tooltipItems[0].raw["x"]);

                            if (aggregation) {
                                const dateTimePipe = new MomentDateTimePipe();
                                return dateTimePipe.transform(currentDateTime, true, true);
                            } else {
                                const datePipe = new MomentDatePipe();
                                return datePipe.transform(currentDateTime);
                            }
                        }
                    }
                };

                const xAxes = {} as ScaleOptionsByType<"time">;
                xAxes.type = "time";
                xAxes.min = minTime;
                xAxes.max = maxTime;
                xAxes.stacked = true;

                xAxes.time = {} as any;

                xAxes.time.unit =
                    aggregation.value === "day" ?
                        xAxes.time.unit = "day" :
                        (aggregation.value === "hour" ?
                            xAxes.time.unit = "hour" :
                            xAxes.time.unit = "minute");

                xAxes.time.tooltipFormat = this.translateService.currentLang === "nl" ? "ddd DD/MM/YYYY HH:mm" : "ddd MM/DD/YYYY HH:mm";

                xAxes.time.displayFormats = {};
                xAxes.time.displayFormats.minute = range.lastDaysCount ? (this.translateService.currentLang === "nl" ? "DD/MM HH:mm" : "MM/DD HH:mm A") : (this.translateService.currentLang === "nl" ? "HH:mm" : "H:mm A");
                xAxes.time.displayFormats.hour = range.lastDaysCount ? (this.translateService.currentLang === "nl" ? "DD/MM HH:mm" : "MM/DD HH:mm A") : (this.translateService.currentLang === "nl" ? "HH:mm" : "H:mm A");
                xAxes.time.displayFormats.day = "DD. MMM";

                xAxes.ticks = {} as any;
                xAxes.ticks.stepSize = interval;
                xAxes.ticks.maxRotation = 0;
                xAxes.ticks.autoSkip = true;
                xAxes.ticks.autoSkipPadding = 50;
                xAxes.ticks.minRotation = 0;
                xAxes.ticks.maxRotation = 0;
                xAxes.ticks.sampleSize = 5;
                // xAxes.ticks.maxTicksLimit = 30;

                chartOptions.scales.x = xAxes;

                // Disable animations
                chartOptions.animation = {};
                chartOptions.animation.duration = 0;
                chartOptions.animation.duration = 0;

                chartOptions.hover = {};
                chartOptions.hover.mode = "index";
                chartOptions.hover.intersect = false;


                const chartData = {
                    labels: dates.map(x => x.toISOString()),
                    datasets: []
                } as ChartData;

                for (const dataSet of this.historicalData.dataSets) {
                    if (!dataSet.history || dataSet.history.length === 0) {
                        // skip empty datasets
                        continue;
                    }

                    const chartDataSet = this.createChartDataSet(dataSet.option, dataSet, dataSet.option === PinnedDataOption.Intensity || dataSet.option === PinnedDataOption.ParkingOccupancy);
                    if (chartDataSet) {
                        chartData.datasets.push(chartDataSet);
                    }
                }

                chartOptions.backgroundColor = "rgba(255, 255, 255, 1.0)"; // required for custom plugin (to color canvas background)
                chartOptions.responsive = true;
                chartOptions.maintainAspectRatio = false;

                this.chartConfiguration = {
                    type: "line",
                    data: chartData,
                    options: chartOptions
                } as ChartConfiguration;
            } else {
                clearSpeed();
            }
        }

        this.cd.detectChanges();

        this.liveTileService.recalculateFloatingPositions();
    }

    private createYAxis(scales: any) {
        const kmhLabel = this.translateService.instant("measurements.kmh");
        const vehiclesLabel = this.translateService.instant("uploads.vehicleCount").toLowerCase();
        const distanceLabel = "m";

        const yAxe = {} as ScaleOptionsByType<"linear">;
        yAxe.type = "linear";
        yAxe.stacked = false;
        yAxe.beginAtZero = true;
        yAxe.ticks = {} as any;
        yAxe.ticks.minRotation = 0;
        yAxe.ticks.maxRotation = 0;
        yAxe.ticks.precision = 0;
        yAxe.title = {
            text: "",
            display: true,
            align: "center",
            color: "rgb(0,0,0)",
            font: {
                size: 13,
                lineHeight: Chart.defaults.font.lineHeight,
                family: "Fira Sans",
                weight: "normal",
                style: Chart.defaults.font.style
            },
            padding: 4
        };

        const scalesList: ScaleOptionsByType<"linear">[] = [];

        const createCopyOfYAxe = (): ScaleOptionsByType<"linear"> => {
            const yAxeCopy = JsonUtils.deepClone(yAxe);
            yAxeCopy.position = scalesList.length > 0 ? "right" : "left";
            return yAxeCopy;
        };

        const options = this.viewModel.displayOptions.measuredData.dataOptions.map(x => x.option).distinct();
        for (const supportedOption of options) {
            const yAxeCopy = createCopyOfYAxe();

            switch (supportedOption) {
                case PinnedDataOption.Intensity:
                    yAxeCopy.position = "left";
                    yAxeCopy.title.text = vehiclesLabel;
                    yAxeCopy.stacked = true;
                    scalesList.push(yAxeCopy);
                    scales[intensityAxeID] = yAxeCopy;
                    break;

                case PinnedDataOption.Speed:
                    yAxeCopy.title.text = kmhLabel;
                    scalesList.push(yAxeCopy);
                    scales[speedAxeID] = yAxeCopy;
                    break;

                case PinnedDataOption.Distance:
                    yAxeCopy.title.text = distanceLabel;
                    scalesList.push(yAxeCopy);
                    scales[distanceAxeID] = yAxeCopy;
                    break;

                default:
                    yAxeCopy.title.text = vehiclesLabel;
                    scalesList.push(yAxe);
                    scales[occupancyAxeID] = yAxeCopy;
                    break;
            }
        }
    }

    /**
     * Creating ChartDataset based on data set which needs to be represented on it
     * @param option Type of data set which is being represented
     * @param dataSet Data set to be represented on chart
     * @param fill ChartDataSet fill option
     * @returns ChartDataSet
     */
    private createChartDataSet(option: PinnedDataOption, dataSet: IVehicleHistoryDataSet, fill: boolean): ChartDataset {
        if (!dataSet) {
            return null;
        }

        switch (option) {
            case PinnedDataOption.Intensity:
                return this.createConcreteChartDataSet(dataSet, intensityAxeID, fill,
                    this.vehicleCategoryViewModels.find(x => x.value === dataSet.vehicleCategory).label,
                    Constants.colorPerVehicleCategory[dataSet.vehicleCategory],
                    this.vehicleCategoryViewModels.map(x => x.value).indexOf(dataSet.vehicleCategory));

            case PinnedDataOption.Distance:
                return this.createConcreteChartDataSet(dataSet, distanceAxeID, fill,
                    dataSet.dataSetType === DataSetType.DistanceA05 ? "A05" : (dataSet.dataSetType === DataSetType.DistanceA15 ? "A15" : "A50"),
                    colorPerDistancePercentile[dataSet.dataSetType],
                    Number.MIN_SAFE_INTEGER);

            case PinnedDataOption.Speed:
                return this.createConcreteChartDataSet(dataSet, speedAxeID, fill,
                    dataSet.dataSetType === DataSetType.FloatingCarSpeed ? this.translateService.instant("task.speed") : (dataSet.dataSetType === DataSetType.SpeedV50 ? "V50" : (dataSet.dataSetType === DataSetType.SpeedV85 ? "V85" : "V95")),
                    dataSet.dataSetType === DataSetType.FloatingCarSpeed ? colorForSpeed : colorPerSpeedPercentile[dataSet.dataSetType],
                    Number.MIN_SAFE_INTEGER);

            case PinnedDataOption.ParkingOccupancy:
                return this.createConcreteChartDataSet(dataSet, occupancyAxeID, fill,
                    this.translateService.instant(`liveTiles.measuredData.${dataSet.dataSetType}`), colorPerParkingOccupancyDataSetForChart[dataSet.dataSetType],
                    Number.MIN_SAFE_INTEGER);
        }
    }

    /**
     * Creates ChartDataSet based on more specific configuration
     * @param dataSet
     * @param yAxisID
     * @param fill
     * @param label
     * @param borderColor
     * @param order
     * @returns
     */
    private createConcreteChartDataSet(dataSet: IVehicleHistoryDataSet, yAxisID: string, fill: boolean, label: string, borderColor: string, order: number): ChartDataset {
        const newDataSet = {} as ChartDataset<"line">;
        newDataSet.type = "line";
        newDataSet.label = label;

        if (order != null && order !== undefined) {
            newDataSet.order = order;
        }

        newDataSet.yAxisID = yAxisID;
        newDataSet.stack = "default";
        newDataSet.fill = fill;
        newDataSet.pointRadius = 0;
        newDataSet.borderColor = borderColor;
        newDataSet.backgroundColor = newDataSet.borderColor;

        newDataSet.data = dataSet.history.map((point) => {
            return { y: point.value, x: point.timestamp.getTime() };
        });

        return newDataSet;
    }


    /**
     * Returns search parameters based on form value
     */
    private createMeasuringPointPinnedHistoryDataSearchParameters(): MeasuringPointPinnedHistoryDataSearchParameters {
        const rangeAndAggregation = this.viewModel.displayOptions.measuredData.historicalDataRange;
        const range = rangeAndAggregation.option1 as RangeSelectItemValue;
        const aggregation = rangeAndAggregation.option2;

        const searchParameters = {
            lastHoursCount: range.lastHoursCount,
            lastDaysCount: range.lastDaysCount,
            timeAggregationType: aggregation,
            selectedOptions: this.viewModel.displayOptions.measuredData.dataOptions
        } as MeasuringPointPinnedHistoryDataSearchParameters;

        return searchParameters;
    }

    /**
     * Returns search parameters based on form value
     */
    private createMeasuringPointPinnedLiveDataSearchParameters(): MeasuringPointPinnedLiveDataSearchParameters {
        const range = this.viewModel.displayOptions.measuredData.liveDataRange;

        const searchParameters = {
            onlyCurrentValue: false,
            range: range,
            selectedOptions: this.viewModel.displayOptions.measuredData.dataOptions
        } as MeasuringPointPinnedLiveDataSearchParameters;

        return searchParameters;
    }

    trackByFunctionForLastValuesGroup(index: number, element: ILiveVehiclesGroupPerOption) {
        return element.option;
    }

    trackByFunctionForLastValue(index: number, element: ILiveVehiclesLastValue) {
        return element.dataSetType;
    }

    public getColorPerDataSetTypeAndVehicleCategory(lastValuesGroupPerOption: ILiveVehiclesGroupPerOption, lastValue: ILiveVehiclesLastValue) {
        switch (lastValuesGroupPerOption.option) {
            case PinnedDataOption.Intensity: {
                return Constants.colorPerVehicleCategory[this.pinnedDataService.getVehicleCategoryBasedOnDataSetType(lastValue.dataSetType)];
            }

            case PinnedDataOption.Distance: {
                return colorPerDistancePercentile[lastValue.dataSetType];
            }

            case PinnedDataOption.Speed: {
                return lastValue.dataSetType === DataSetType.FloatingCarSpeed ? colorForSpeed : colorPerSpeedPercentile[lastValue.dataSetType];
            }

            case PinnedDataOption.ParkingOccupancy: {
                return colorPerParkingOccupancyDataSetForChart[lastValue.dataSetType] ?? colorPerParkingOccupancyDataSetForLastValues[lastValue.dataSetType];
            }
        }
    }

    public openManageDialog() {
        this.manageDialog.open(this.viewModel.pinnedDataConfiguration, this.viewModel.displayOptions.measuredData, (options) => {
            this.viewModel.displayOptions.measuredData = options;
            this.OnViewModelChanged();
        }, () => {
            // if after openning live-tile submit is not pressed on dialog
            // close live-tile completely
            if (!this.viewModel?.displayOptions?.measuredData?.showHistoricalData && !this.viewModel?.displayOptions?.measuredData?.showLiveData) {
                this.liveTileService.removeLiveTileId(this.viewModel.measuringPoint.id);
            }
        });
    }

    private OnViewModelChanged(): void {
        this.liveTileService.saveToLocalStorage();

        // reload the data and show spinners in the meantime

        this.liveData = null;
        if (this.viewModel.displayOptions.measuredData.showLiveData) {
            this.liveDataLoading = true;
            this.loadLiveData();
        }

        this.historicalData = null;
        if (this.viewModel.displayOptions.measuredData.showHistoricalData) {
            this.historicalDataLoading = true;
            this.loadHistoricalData();
        }
    }

    public onDataChanged(): void {
        // We reload everything, but we don't show the spinners

        this.liveData = null;
        if (this.viewModel.displayOptions.measuredData.showLiveData) {
            this.loadLiveData();
        }

        this.historicalData = null;
        if (this.viewModel.displayOptions.measuredData.showHistoricalData) {
            this.loadHistoricalData();
        }
    }
}
