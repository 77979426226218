import { IServiceModelBase } from "./servicemodelbase";

export interface IDrivingLane extends IServiceModelBase {
    code: string; // used by model localization table
    codeStringResourceId: string;

    description: string;
}

export class DrivingLaneCreator {
    codeStringResource: { [key: string]: string };
    description: string;
    isObsolete?: boolean;
}

export class DrivingLaneUpdater extends DrivingLaneCreator {
    id: number;

    constructor(existing: IDrivingLane) {
        super();

        this.id = existing.id;
        this.description = existing.description;
    }
}