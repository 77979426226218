import { TableService, TableColumn, FilterType, ColumnVisibility, LazyTableComponent } from "src/app/modules/shared/components/table/table.component";
import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, SimpleChanges, OnChanges } from "@angular/core";
import { IVmsImage } from "src/app/models/vms-image";
import { VmsImageApi } from "src/app/resource/vms-image.api";
import { FilterOperator, SearchParameters, ServiceRequestOptions, SortDescriptor, SortDirection } from "src/app/models/search";
import { ModalService } from "src/app/services/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "src/app/services/navigation.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { Rights } from "src/app/models/rights";

@Component({
    selector: "app-vms-images",
    templateUrl: "./vms-images.component.html"
})
export class VmsImagesComponent extends LazyTableComponent<IVmsImage> implements OnInit, OnChanges {
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;
    @Input() downloadCommand = true;
    // We're using selectCommand for selecting one image in vms mode
    // No need to show the edit and delete commands in this case
    // Also, hide the variants column and collapse stuff
    @Input() selectCommand = false;
    @Input() vmsTypeId: string;
    @Input() ownerId: number;

    canCreateVmsImage = false;
    canDeleteVmsImage = false;

    searchCodeOrOrganisation: string = null;
    public selectedVmsImage: IVmsImage;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly vmsImageApi: VmsImageApi,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly navigationService: NavigationService) {

        super("vms-images.component", elementRef, vmsImageApi, tableService);
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.stretchHeight = true;


    }
    ngOnChanges(changes: SimpleChanges) {
        const vmsTypeIdChange = changes["vmsTypeId"];
        const ownerIdChange = changes["ownerId"];

        if (vmsTypeIdChange || ownerIdChange) {
            this.reload();
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectionMode = "";
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateVmsImage = rights?.hasBackendRight(BackendRights.EditVmsImages);
            this.canDeleteVmsImage = rights?.hasBackendRight(BackendRights.DeleteVmsImages);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        if (!this.selectCommand) {
            const organizationColumn = new TableColumn("ownerId", "general.organization", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, resizable: false, width: 400 });
            organizationColumn.displayDropdownFilter = true;
            this.addColumn(organizationColumn);
            // Load Organizations for drop-down filter
            this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
                organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                    organizations,
                    (x) => x.id,
                    (x) => x.name,
                    false
                );
            });

        }

        this.addColumn(new TableColumn("image", "vmsImages.image", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.None, sortable: true, width: 150 }));
        if (!this.selectCommand) {
            this.addColumn(new TableColumn("variants", "vmsImages.variants", { filterType: FilterType.None, sortable: false }));
        }


        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (vmsImage) => this.edit(vmsImage),
            validFunc: () =>
                this.editCommand &&
                this.canCreateVmsImage,
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (vmsImage) => this.delete(vmsImage),
            validFunc: () =>
                this.deleteCommand &&
                this.canDeleteVmsImage,
        });

        this.updateRelevantColumns();
        this.loadTableRows();
    }


    edit(vmsImage: IVmsImage) {
        this.navigationService.editVmsImage(vmsImage.id);
    }

    delete(vmsImage: IVmsImage) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.vmsImageApi.delete$(vmsImage.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translateService.instant("vmsImages.deleteConfirmation", vmsImage);
        this.modalService.delete(modalBody, onDelete);
    }

    getVariantImage(vmsImage: IVmsImage) {
        if (this.vmsTypeId) {
            const variant = vmsImage.variants.find(x => x.vmsType.typeId === this.vmsTypeId);
            return variant?.url;
        }
        return vmsImage.variants[0].url;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("vmsImage", "variants");
        return serviceRequestOptions;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        searchParameters.sort = [
            new SortDescriptor(SortDirection.ascending, "code")
        ];
        searchParameters.filter.push({ field: "inLibrary", value: true, operator: FilterOperator.equals });

        if (this.searchCodeOrOrganisation) {
            searchParameters.filter.push({ field: "codeOrOrganisation", value: this.searchCodeOrOrganisation, operator: FilterOperator.contains });
        }
        if (this.ownerId) {
            searchParameters.filter.push({ field: "ownerId", value: this.ownerId, operator: FilterOperator.equals });
        }
        if (this.vmsTypeId) {
            searchParameters.filter.push({ field: "vmsTypeId", value: this.vmsTypeId, operator: FilterOperator.equals });
        }
        return searchParameters;
    }

    createFromEditor() {
        this.navigationService.createVmsImage();
    }

    onSearch(event: any) {
        this.reload();
    }

    setCurrentVmsImageId(vmsImageId?: number) {
        if (vmsImageId) {
            this.selectedVmsImage = this.data.find(x => x.id === vmsImageId);
        }
    }

}