import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ISingleModification } from "src/app/models/task-status-comparison";

@Component({
    selector: "app-task-status-differences",
    templateUrl: "./task-status-differences.component.html"
})
export class TaskStatusDifferencesComponent implements OnInit {

    @Input() modifications: ISingleModification[];

    constructor(
        readonly translateService: TranslateService
    ) {

    }

    ngOnInit(): void {

    }

    preparePropertyNameForTranslation(propertyName: string): string {
        const startOfId = propertyName.indexOf("Id");
        if (startOfId >= 0) {
            propertyName = propertyName.substring(0, startOfId); // should always be on end of propertyName
        }
        propertyName = propertyName.toCamelCase();
        return "task." + propertyName;
    }

    isBoolean(value: any) {
        return typeof value === "boolean";
    }

    isDate(value: any) {
        return value instanceof Date;
    }
}
