<ng-container *ngIf="!parkingBanForm">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<form [formGroup]="parkingBanForm"
      class="m-layout-area-body m-overflow-auto m-border-top p-16"
      *ngIf="parkingBanForm"
      autocomplete="off">
    <!--assignment-->
    <div class="m-form-group row">
        <label class="col-4">
            {{"general.assignment" | translate}}
        </label>

        <div class="col-8">
            <label>
                {{parkingBan.assignment.name}}
            </label>
        </div>
    </div>
    <!--name-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': parkingBanForm.get('name').showErrors }">
        <label class="col-4"
               for="name">
            {{"general.name" | translate}}
        </label>
        <div class="col-8">
            <input type="text"
                   placeholder="{{'general.name' | translate}}"
                   id="name"
                   class="p-inputtext"
                   formControlName="name"
                   appAutofocus
                   #nameInput />
            <app-form-validation [control]="parkingBanForm.get('name')"
                                 [input]="nameInput">
            </app-form-validation>
        </div>
    </div>
    <!--description-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': parkingBanForm.get('description').showErrors }">
        <label class="col-4"
               for="description">
            {{"general.description" | translate}}
        </label>
        <div class="col-8">
            <textarea id="description"
                      class="p-inputtextarea"
                      placeholder="{{'general.description' | translate}}"
                      rows="2"
                      formControlName="description"
                      #descriptionInput>
                    </textarea>
            <app-form-validation [control]="parkingBanForm.get('description')"
                                 [input]="descriptionInput">
            </app-form-validation>
        </div>
    </div>
    <!--is completed-->
    <div class="m-form-group row">
        <label class="col-4"
               for="isCompleted">
            {{"parkingBan.isCompleted" | translate}}
        </label>
        <div class="col-8">
            <label class="m-input-checkbox">
                <input type="checkbox"
                       formControlName="isCompleted" />
            </label>
        </div>
    </div>
    <div class="m-form-group row">
        <label class="col-4"
               for="start">
            {{"parkingBan.start" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="start"
                        appendTo="body"
                        id="start"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [showIcon]="true"
                        [showTime]="false"
                        [maxDate]="parkingBanForm.get('end')?.value"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'parkingBan.start' | translate}}"
                        #startInput></p-calendar>

            <app-form-validation [control]="parkingBanForm.get('start')"
                                 [input]="startInput">
            </app-form-validation>
        </div>
    </div>
    <!--end-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': parkingBanForm.get('end').showErrors }">
        <label class="col-4"
               for="end">
            {{"parkingBan.end" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="end"
                        id="end"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [minDate]="parkingBanForm.get('start')?.value"
                        [showIcon]="true"
                        [showTime]="false"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [locale]="calendarSettings.localeSettings"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'parkingBan.end' | translate}}"
                        #endInput></p-calendar>
            <app-form-validation [control]="parkingBanForm.get('end')"
                                 [input]="endInput">
            </app-form-validation>
        </div>
    </div>
</form>

<div class="m-btn-group m-btn-group-right m-border-top px-16 py-8 m-layout-area-bottom">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        <ng-container *ngIf="!isCreatingNew(); else cancelText">
            {{"form.reset" | translate}}
        </ng-container>
        <ng-template #cancelText>
            {{"form.cancel" | translate}}
        </ng-template>
    </button>
    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>