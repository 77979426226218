import { DeviceLinkCreator, DeviceLinkUpdater, IDeviceLink, IDeviceLinkConflict, IDeviceLinkConflicts, IDeviceNavigator, ILinkMeasurement } from "src/app/models/device";
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, HostListener, ChangeDetectorRef, inject } from "@angular/core";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { SigncoFormGroup, SigncoFormArray } from "src/app/models/form";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { SelectLinkMeasurementsComponent } from "src/app/modules/link/components/select-link-measurements/select-link-measurements.component";
import { IProgress, IProgressCreated } from "src/app/models/progress";
import { DeviceDepotNavigatorWebApi } from "src/app/resource/web";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DeviceNavigatorWebApi } from "src/app/resource/web";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { SubscriptionManager, DeviceUtils, JsonUtils, OrganizationUtils } from "src/app/utilities";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { MapDataService } from "src/app/services/map-data.service";
import { IDeviceSummary } from "src/app/models/device";
import { ActivatedRoute } from "@angular/router";
import { DeviceLinkApi } from "src/app/resource/device-link.api";
import { ResizeService } from "src/app/services/resize.service";
import { ISearchResult } from "src/app/models/search";
import { ModalService } from "src/app/services/modal.service";
import { ToastService } from "src/app/services/toast.service";
import { ProgressApi } from "src/app/resource/progress.api";
import { SelectItem } from "primeng/api";
import { MapDetail } from "src/app/services/map-detail.service";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { AnalyzerConfiguration } from "src/app/models/upload";
import { VehicleSubType } from "src/app/models/vehicle";
import { ScanMode, ScannerDialogComponent } from "src/app/modules/shared/components/scanner-dialog/scanner-dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPointSummary } from "src/app/models/web";
import { AnalysisType } from "src/app/models/measuring-point";
import { AutoCompleteSelectEvent } from "primeng/autocomplete";
import { WebsiteService } from "src/app/services/website.service";

class MeasurementFormWithModel {
    model: ILinkMeasurement;
    form: SigncoFormGroup;
    new: boolean;
}

@Component({
    selector: "app-manage-link",
    templateUrl: "./manage-link.component.html"
})
export class ManageLinkComponent implements OnInit, AfterViewInit, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @ViewChild("column1", { static: true }) column1: ElementRef<HTMLDivElement>;
    @ViewChild("columnConfig", { static: true }) columnConfig: ElementRef<HTMLDivElement>;
    @ViewChild("addMeasuringPointsIcon", { static: false }) addMeasuringPointsIcon: ElementRef;
    @ViewChild(ScannerDialogComponent, { static: false }) scannerDialog: ScannerDialogComponent;

    private selectMeasurementsComponent: SelectLinkMeasurementsComponent;
    @ViewChild(SelectLinkMeasurementsComponent, { static: false }) set setSelectMeasurementsComponent(selectMeasurementsComponent: SelectLinkMeasurementsComponent) {
        if (this.selectMeasurementsComponent === selectMeasurementsComponent) return;

        this.selectMeasurementsComponent = selectMeasurementsComponent;

        if (this.selectMeasurementsComponent && this.existingLink) {
            this.addMeasurementsToSelectComponent();
            this.cd.detectChanges();
        }
    }

    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        this.map = mapSelector;
    }

    get showDevice(): boolean {
        if (this.isCreatingNew) {
            return !this.deviceFromUrl;
        }

        return true;
    }

    get showMeasuringPoints(): boolean {
        if (this.isCreatingNew) {
            return this.isStartingMeasurement || this.isReplacingDevice;
        }

        return this.existingLink && this.existingLink.measurements && this.existingLink.measurements.length > 0;
    }

    get showDepot(): boolean {
        if (this.isCreatingNew) {
            return this.isBreakingLink;
        }

        return this.existingLink && !!this.existingLink.depot;
    }

    get showReplacingDepot(): boolean {
        return this.isReplacingDevice && !!this.existingLink;
    }

    get selectedMeasurement(): ILinkMeasurement {
        if (!this.selectMeasurementsComponent) return undefined;
        return this.selectMeasurementsComponent.singleSelected;
    }

    get title(): string {
        const device = this.existingLink ? this.existingLink.device : (this.deviceFromUrl || this.device);

        const translateObject = {
            code: device ? device.code : ""
        };

        const translationKey =
            this.isBreakingLink ? "toDepotTitle" :
                this.isReplacingDevice ? "replaceDeviceTitle" :
                    this.isEditing ? "title" :
                        "startMeasurementTitle";

        return this.translateService.instant(`manageLink.${translationKey}`, translateObject);
    }

    loading = true;
    setColumnHeights = false;
    mapHeight: number;
    mapPolygonOffset = 0;
    mapOpen: boolean;
    mapDetail: MapDetail;
    scrollHeight: string;

    hasPreviewed = false;
    device: IDeviceNavigator;
    deviceSuggestions: IDeviceNavigator[];
    deviceDepotOptions: SelectItem[];
    deviceReplaceDepotOptions: SelectItem[];
    replaceDepotOrganizationId: number;
    measurementForms: SigncoFormArray;
    linkForm: SigncoFormGroup;
    existingLink: IDeviceLink;
    submitting = false;
    measurementFormsWithModel = new Array<MeasurementFormWithModel>();
    progress: IProgress;
    deviceLinkConflicts: IDeviceLinkConflicts;

    calendarSettings: CalendarSettings;
    organizations: SelectItem[];

    isCreatingNew = false;
    isEditing = false;
    isStartingMeasurement = false;
    isReplacingDevice = false;
    isBreakingLink = false;
    deviceFromUrl: IDeviceNavigator;
    mpFromUrl: IMeasuringPointSummary;

    private subscriptionManager = new SubscriptionManager();
    private readonly mapDataKey: string;

    private copiedConfiguration: string;

    readonly formValidationService = inject(FormValidationService);
    readonly primeComponentService = inject(PrimeComponentService);
    readonly translateService = inject(TranslateService);
    private readonly route = inject(ActivatedRoute);
    private readonly formBuilder = inject(UntypedFormBuilder);
    private readonly deviceLinkApi = inject(DeviceLinkApi);
    private readonly progressApi = inject(ProgressApi);
    private readonly modalService = inject(ModalService);
    private readonly toastService = inject(ToastService);
    private readonly resizeService = inject(ResizeService);
    private readonly mapDataService = inject(MapDataService);
    private readonly navigationService = inject(NavigationService);
    private readonly measuringPointWebApi = inject(MeasuringPointWebApi);
    private readonly deviceNavigatorWebApi = inject(DeviceNavigatorWebApi);
    private readonly deviceDepotNavigatorWebApi = inject(DeviceDepotNavigatorWebApi);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly changeGuardService = inject(ChangeGuardService);
    private readonly selectionService = inject(MapSelectionService);
    private readonly accessibilityService = inject(AccessibilityService);
    private readonly cd = inject(ChangeDetectorRef);
    private readonly websiteService = inject(WebsiteService);


    constructor(
    ) {

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
        });

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        const resizeSubscription = this.resizeService.onResize.subscribe(() => {
            this.updateScrollHeight();
            this.positionMapArrowPolygon();
        });
        this.subscriptionManager.add("resize", resizeSubscription);

        this.linkForm = this.formBuilder.group({
            validFrom: [new Date(), Validators.required],
            validUntil: null,
            deviceId: [null, Validators.required]
        }) as SigncoFormGroup;

        this.linkForm.valueChanges.subscribe(() => {
            this.clearConflicts();
        });
    }

    ngOnInit() {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            await this.setLinkFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    ngAfterViewInit() {
        this.updateScrollHeight();
    }

    ngOnDestroy() {
        this.navigationService.linkDeviceId = null;
        this.navigationService.linkMeasuringPoint = null;
        this.navigationService.cameFromMap = false;
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        if (this.submitting || !this.linkForm) {
            // We're here because we've submitted or not initialized => allow it
            return true;
        }

        // Creating new, always warn
        if (this.isCreatingNew) return false;

        return this.linkForm.pristine;
    }

    onDeactivate() {

    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    get hasMultipleOrganizations(): boolean {
        return this.globalEventsService.hasMultipleOrganizations();
    }

    private async setLinkFromParams(params: { [key: string]: any }) {
        if (!params) return;

        this.loading = true;

        let linkIdString: string;
        let deviceIdString: string;
        let mpIdString: string;

        const editLinkIdString = params["editLinkId"];
        if (editLinkIdString) {
            this.isEditing = true;
            linkIdString = editLinkIdString;
        } else {

            // Are we creating new? Check for mpId / deviceId
            mpIdString = params["mpId"];
            deviceIdString = params["deviceId"];
            if (mpIdString || deviceIdString) {
                this.isCreatingNew = true;
                this.isStartingMeasurement = true;
            } else {

                // Are we breaking?
                const breakDeviceId = params["breakDeviceId"];
                if (breakDeviceId) {
                    deviceIdString = breakDeviceId;
                    this.isCreatingNew = true;
                    this.isBreakingLink = true;
                } else {

                    // Are we replacing?
                    const replaceLinkId = params["replaceLinkId"];
                    if (replaceLinkId) {
                        linkIdString = replaceLinkId;
                        this.isCreatingNew = true;
                        this.isReplacingDevice = true;
                    } else {
                        // Apparently we're not doing anything valid - go back
                        this.back();
                        return;
                    }
                }
            }
        }

        let link: IDeviceLink = null;

        if (linkIdString) {
            const linkId = Number.parseInt(linkIdString, 10);
            if (!Number.isNaN(linkId)) {
                link = await this.deviceLinkApi.get$(linkId, null, null, false).toPromise();
            }

            if (!link) {
                this.back();
                return;
            }
        }

        if (deviceIdString) {
            const deviceId = Number.parseInt(deviceIdString, 10);
            if (!Number.isNaN(deviceId)) {
                this.deviceFromUrl = await this.deviceNavigatorWebApi.get$(deviceId, null, null, false);
            }

            if (!this.deviceFromUrl) {
                this.back();
                return;
            }
        }

        if (mpIdString) {
            const mpId = Number.parseInt(mpIdString, 10);
            if (!Number.isNaN(mpId)) {
                this.mpFromUrl = await this.measuringPointWebApi.get(mpId);
            }

            if (!this.mpFromUrl) {
                this.back();
                return;
            }
        }

        const actionString = params["action"];
        if (actionString) {
            if (actionString === "break") {
                this.isBreakingLink = true;
            }

            if (actionString === "replace") {
                this.isReplacingDevice = true;
            }
        }

        await this.setLink(link);

        setTimeout(() => {
            this.updateScrollHeight();
        });
    }

    async setLink(deviceLink: IDeviceLink) {
        this.measurementForms = this.formBuilder.array([]) as SigncoFormArray;

        this.existingLink = deviceLink;

        if (this.showMeasuringPoints) {
            this.linkForm.addControl("measurements", this.measurementForms);
        } else if (this.existingLink) {
            delete this.existingLink.measurements;
        }

        if (this.showDepot) {
            this.linkForm.addControl("depotId", this.formBuilder.control(null, Validators.required));
        } else if (this.existingLink) {
            delete this.existingLink.depot;
        }

        if (this.showReplacingDepot) {
            this.linkForm.addControl("replaceDepotId", this.formBuilder.control(null, Validators.required));
            this.loadReplaceDepotOptions();
        }

        if (this.existingLink) {
            this.linkForm.patchValue({
                validFrom: this.isBreakingLink || this.isReplacingDevice ? new Date() : this.existingLink.validFrom,
                validUntil: this.existingLink.validUntil,
                depotId: this.existingLink.depot ? this.existingLink.depot.id : null,
            });

            // Fill measurements
            if (this.existingLink.measurements) {
                this.addMeasurements(this.existingLink.measurements);
                this.addMeasurementsToSelectComponent();
            }

            if (!this.isReplacingDevice) {
                this.setDevice(this.existingLink.device);
            } else {
                this.replaceDepotOrganizationId = this.existingLink.device.ownerId;
                this.loadReplaceDepotOptions();
            }

        } else if (this.deviceFromUrl) {
            this.setDevice(this.deviceFromUrl);
        }

        // Important to set here, or linkMeasuringPoint won't be added as form
        this.loading = false;

        // setTimeout to make sure the loading false has propogated to children (and select-link-measurements is now propogating events)
        setTimeout(() => {
            if (this.mpFromUrl) {
                this.selectionService.addMeasuringPoints(this.mpFromUrl);
            }
        });
    }

    private addMeasurementsToSelectComponent() {
        if (!this.existingLink || !this.selectMeasurementsComponent) return;

        this.selectMeasurementsComponent.setData(this.existingLink.measurements);
    }

    back() {
        const link = this.existingLink;
        const deviceId = this.navigationService.linkDeviceId || (this.deviceFromUrl ? this.deviceFromUrl.id : null) || (link && link.device ? link.device.id : null);
        const mp = this.navigationService.linkMeasuringPoint || this.mpFromUrl;

        if (this.navigationService.cameFromMap) {
            this.navigationService.toMeasuringPoints();
            return;
        }

        if (mp) {
            this.navigationService.toMeasuringPointLocation(mp.id, mp.locationId, "links");
        } else if (deviceId) {
            this.navigationService.toDevice(deviceId, "links");
        } else {
            this.navigationService.toMeasuringPoints();
        }
    }

    private updateScrollHeight() {
        if (!this.column1 || this.loading) return;

        // We have 1 grid: linkMeasurements
        const toDivide = 1;

        setTimeout(() => {
            // takes into account the margins, the title, allowValidation checkbox, shareAllMeasuringPoints checkbox, ...
            this.columnConfig.nativeElement.style.maxHeight = this.column1.nativeElement.offsetHeight + "px";
            this.scrollHeight = ((this.column1.nativeElement.clientHeight - 420) / toDivide) + "px";
            this.updateMapHeight();
            this.setColumnHeights = true;
        });
    }

    //#region Device

    queryDevices(event: { query: string }) {
        if (!event || !event.query) {
            this.deviceSuggestions = null;
            return;
        }

        const onDevicesFetch = (result: ISearchResult<IDeviceSummary>) => {
            this.deviceSuggestions = result.data;

            const correspondingDevice = this.deviceSuggestions.filter(x => x.code.toUpperCase() === event.query.toUpperCase());
            if (correspondingDevice.length === 1) {
                this.setDevice(correspondingDevice[0]);
            }
        };

        this.deviceNavigatorWebApi.search$(event.query).subscribe(onDevicesFetch);
    }

    protected handleSelect(ev: AutoCompleteSelectEvent) {
        this.setDevice(ev.value as IDeviceNavigator);
    }

    setDevice(deviceNavigator: IDeviceNavigator) {
        this.device = deviceNavigator || undefined;

        const newValue = this.device ? this.device.id : null;
        const curValue = this.linkForm.get("deviceId").value || null;

        this.linkForm.patchValue({
            deviceId: newValue
        });

        this.clearConflicts();

        // When replacing a device, depots depend on the current link's device, not the selected device
        if (!this.isReplacingDevice) {
            this.loadDeviceDepotOptions();
        }
    }

    loadDeviceDepotOptions() {
        const device = this.isReplacingDevice ? this.existingLink.device : this.device;

        if (device) {
            this.deviceDepotNavigatorWebApi.search$(device.ownerId).subscribe(deviceDepotNavigators => {
                this.deviceDepotOptions = this.primeComponentService.createDropdownList(deviceDepotNavigators.data, x => x.id, x => x.code, false);
            });
        } else {
            this.deviceDepotOptions = new Array<SelectItem>();
        }
    }

    clearDevice() {
        this.setDevice(undefined);
    }

    onReplaceDepotOrganizationChange() {
        this.loadReplaceDepotOptions();
    }

    private loadReplaceDepotOptions() {
        if (!this.replaceDepotOrganizationId) {
            this.deviceReplaceDepotOptions = [];
            return;
        }

        this.deviceDepotNavigatorWebApi.search$(this.replaceDepotOrganizationId).subscribe(deviceDepotNavigators => {
            this.deviceReplaceDepotOptions = this.primeComponentService.createDropdownList(deviceDepotNavigators.data, x => x.id, x => x.code, false);
        });
    }

    openScanner() {
        this.scannerDialog.show(ScanMode.ScanDevice, (result) => {
            this.deviceNavigatorWebApi.getByQrCode$(result).subscribe({
                next: (device) => {
                    this.setDevice(device);

                },
                error: () => {
                    this.toastService.error(this.translateService.instant("device.notFound"));
                }
            });
        });
    }

    //#endregion

    //#region Measurements

    addMeasurements(measurements: ILinkMeasurement[]) {
        for (const measurement of measurements) {
            this.addMeasurement(measurement);
        }
    }

    deleteMeasurements(measurements: ILinkMeasurement[]) {
        for (const measurement of measurements) {
            this.deleteMeasurement(measurement);
        }
    }

    addMeasurement(existing: ILinkMeasurement) {
        const alreadyExists = this.measurementFormsWithModel.find(x => x.model.measuringPoint.id === existing.measuringPoint.id);
        if (alreadyExists) return;

        // Handled in upload-form.component.ts
        const analyzerConfigurationForm = this.formBuilder.group({});

        const measurementFormGroup = this.formBuilder.group({
            measuringPointId: [existing.measuringPoint.id, Validators.required],
            analyzerConfiguration: analyzerConfigurationForm
        }) as SigncoFormGroup;

        this.measurementForms.push(measurementFormGroup);

        const newlyCreated = !this.existingLink || !this.existingLink.measurements.contains(existing);

        this.measurementFormsWithModel.push({
            model: existing,
            form: measurementFormGroup,
            new: newlyCreated
        });
    }

    deleteMeasurement(measurement: ILinkMeasurement) {
        const measurementFormWithModel = this.measurementFormsWithModel.find(x => x.model === measurement);
        if (!measurementFormWithModel) return;

        this.measurementFormsWithModel = this.measurementFormsWithModel.remove(measurementFormWithModel);

        const measurementFormIndex = this.measurementForms.controls.indexOf(measurementFormWithModel.form);
        this.measurementForms.removeAt(measurementFormIndex);

        if (measurement === this.selectedMeasurement) {
            this.selectMeasurementsComponent.clearSelection(true);
        }
    }

    updateTubes(measurementFormWithModel: MeasurementFormWithModel, tubes: string[]) {
        if (!!measurementFormWithModel.model.analyzerConfiguration?.cameraDetectionConfiguration) return;

        measurementFormWithModel.model.tubes = tubes;
        this.cd.detectChanges(); // required here to prevent ExpressionChangedAfterItHasBeenCheckedError (because multi-no-duplicate-validator causing a lot event emits)
    }

    //#endregion Measurements

    //#region Map

    isMapOpen(detail: MapDetail = null) {
        return this.mapOpen && ((!detail && detail !== 0) || this.mapDetail === detail);
    }

    private openMap(detail: MapDetail) {
        this.updateMapHeight();
        this.mapDetail = detail;
        this.mapOpen = true;
        this.positionMapArrowPolygon();
    }

    closeMap() {
        this.mapOpen = false;
    }

    private updateMapHeight() {
        this.mapHeight = this.column1.nativeElement.offsetHeight - 44;
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.map.measuringPointsComponent.selectionMode = "multiple";
        }
    }

    private positionMapArrowPolygon() {
        if (!this.mapOpen) return;

        let icon: ElementRef<HTMLDivElement>;

        if (this.mapDetail === MapDetail.MeasuringPoints) icon = this.addMeasuringPointsIcon;

        // offsetParent is the very first <div> in the html because the layout is fluid
        // So to get offsetParent from the current column, we take offsetTop minus column offsetTop
        this.mapPolygonOffset = icon.nativeElement.offsetTop - this.column1.nativeElement.offsetTop + 16;
    }

    //#endregion Map

    //#region MeasuringPoints

    toggleAddMeasuringPoints() {
        if (this.mapOpen && this.mapDetail === MapDetail.MeasuringPoints) {
            this.closeMap();
            return;
        }

        this.openMap(MapDetail.MeasuringPoints);
    }

    //#endregion MeasuringPoints

    //#region Error handling

    isInputInError(): boolean {
        if (!this.linkForm.submitted) return false;
        if (!this.showMeasuringPoints) return false;

        return !this.selectMeasurementsComponent.data.length;
    }

    //#endregion Error handling

    //#region Conflicts

    getConflicts() {
        this.submitting = true;

        if (this.selectMeasurementsComponent) {
            this.selectMeasurementsComponent.clearSelection();
        }

        this.closeMap();

        let deviceLinkCreator: DeviceLinkCreator;
        if (this.isCreatingNew) {
            deviceLinkCreator = new DeviceLinkCreator();
        } else {
            deviceLinkCreator = new DeviceLinkUpdater(this.existingLink);
        }

        const formValue = this.prepareCreatorOrUpdaterObject(this.linkForm.value);
        Object.assign(deviceLinkCreator, formValue);

        deviceLinkCreator.preview = true;

        const onSuccess = (result: IDeviceLinkConflicts) => {
            if (result.conflicts) {
                for (const conflict of result.conflicts) {
                    if (!conflict.changed) continue;

                    conflict.showChangedFrom = conflict.changed.from.getTime() !== conflict.original.validFrom.getTime();
                    conflict.showChangedUntil =
                        (!!conflict.changed.until !== !!conflict.original.validUntil) ||
                        (conflict.changed.until && conflict.changed.until.getTime() !== conflict.original.validUntil.getTime());
                }
            }

            this.deviceLinkConflicts = result;
            this.submitting = false;
            this.hasPreviewed = true;
        };

        const onError = () => {
            this.submitting = false;
        };

        this.deviceLinkApi.preview$(deviceLinkCreator).subscribe(onSuccess, onError);
    }

    clearConflicts() {
        this.deviceLinkConflicts = null;
        this.hasPreviewed = false;
    }

    trackByFn(index: number, item: IDeviceLinkConflict) {
        return index;
    }

    //#endregion Conflicts

    async onSubmit() {
        const isValid = await this.formValidationService.checkValidity(this.linkForm) && !this.isInputInError();
        if (!isValid) return;

        if (!this.hasPreviewed) {
            this.getConflicts();
            return;
        }

        const device = this.device || this.existingLink?.device;
        if (this.showDepot && DeviceUtils.supportsDeepSleep(device?.typeId)) {
            const onSuccess = () => {
                this.submitInternal();
            };

            this.modalService.confirm(this.translateService.instant("manageLink.depotSleepWarning", { productName: this.websiteService.getProductName() }), onSuccess, null, "alert");
        } else {
            this.submitInternal();
        }
    }

    private async submitInternal() {
        this.submitting = true;

        const onProgress = (progress: IProgress) => {
            // Doing this in 2 steps, halve the progress
            if (this.isReplacingDevice) {
                progress.progress = progress.progress / 2;
            }

            this.progress = progress;
        };

        const onComplete = () => {
            if (this.isReplacingDevice) {
                this.submitReplaceDepot();
            } else {
                this.toastService.saveSuccess();
                this.back();
            }
        };

        const onSuccess = (progressCreated: IProgressCreated) => {
            this.progressApi.pollProgress(progressCreated.progressId, onProgress).then(() => { onComplete(); }, () => { onError(); });
        };

        const onError = () => {
            this.submitting = false;
        };

        const formValue = this.prepareCreatorOrUpdaterObject(this.linkForm.value);
        if (this.isCreatingNew) {
            const creator = new DeviceLinkCreator();
            Object.assign(creator, formValue);
            this.deviceLinkApi.createWithProgress$(creator).subscribe(onSuccess, onError);
        } else {
            const updater = new DeviceLinkUpdater(this.existingLink);
            Object.assign(updater, formValue);
            this.deviceLinkApi.updateWithProgress$(updater).subscribe(onSuccess, onError);
        }
    }


    /**
     * Prepares creator or updater object for sending to BE
     * Some of the nested form groups does not return value in correct form
     * @param formValue
     * @returns adjusted object
     */
    private prepareCreatorOrUpdaterObject(formValue: any): any {
        if (!formValue) return {} as DeviceLinkCreator;

        const copyOfFormValue = JsonUtils.deepClone(formValue);

        if (formValue.measurements) {
            for (const measurement of copyOfFormValue.measurements) {
                if (!measurement.analyzerConfiguration?.collectRConfiguration) continue;
                if (!measurement.analyzerConfiguration.collectRConfiguration.mappingEnabled) {
                    measurement.analyzerConfiguration.collectRConfiguration.mapping = null;
                    continue;
                }

                const mapping = {} as { [classId: number]: VehicleSubType };
                for (const classConfiguration of measurement.analyzerConfiguration.collectRConfiguration.mapping) {
                    mapping[classConfiguration.classId] = classConfiguration.vehicleSubType;
                }

                measurement.analyzerConfiguration.collectRConfiguration.mapping = mapping;
            }
        }

        return copyOfFormValue;
    }

    // When replacing, this is step 2 - move the old device to a selected depot
    private async submitReplaceDepot() {
        const onProgress = (progress: IProgress) => {
            // This is step 2, halve the progress and add 0.5 (already halfway done)
            progress.progress = 0.5 + (progress.progress / 2);

            this.progress = progress;
        };

        const onComplete = () => {
            this.toastService.saveSuccess();
            this.back();
        };

        const onSuccess = (progressCreated: IProgressCreated) => {
            this.progressApi.pollProgress(progressCreated.progressId, onProgress).then(() => { onComplete(); }, () => { onError(); });
        };

        const onError = () => {
            this.submitting = false;
        };

        const creator = new DeviceLinkCreator();
        creator.deviceId = this.existingLink.device.id;
        creator.depotId = this.linkForm.get("replaceDepotId").value as number;
        creator.validFrom = this.linkForm.get("validFrom").value as Date;

        this.deviceLinkApi.createWithProgress$(creator).subscribe(onSuccess, onError);
    }

    copyConfiguration() {
        if (!this.selectedMeasurement?.measuringPoint?.id) return;

        let formValue = this.linkForm.value;
        formValue = this.prepareCreatorOrUpdaterObject(formValue);
        const creator = formValue as DeviceLinkCreator;

        const analyzerConfigurationToBeCopied = creator.measurements?.find(x => x.measuringPointId === this.selectedMeasurement.measuringPoint.id)?.analyzerConfiguration;
        if (!analyzerConfigurationToBeCopied) return;

        const relevantFormModel = this.measurementFormsWithModel.find(x => x.model.measuringPoint.id === this.selectedMeasurement.measuringPoint.id);
        if (!relevantFormModel) return;

        const configurations = this.getFormValueAsJsonString(analyzerConfigurationToBeCopied, relevantFormModel);
        this.accessibilityService.copyToClipboard(configurations, "manageLink.configurationCopiedSuccessful", "manageLink.configurationNotCopiedSuccessful");
    }

    pasteConfiguration() {
        const configurationNotApplied = () => {
            this.toastService.error(this.translateService.instant("manageLink.configurationNotApplied"));
        };

        const relevantForm = this.measurementFormsWithModel.find(x => x.model.measuringPoint.id === this.selectedMeasurement.measuringPoint.id);
        if (!relevantForm) {
            return;
        }

        const analyzerConfiguration = relevantForm.form.get("analyzerConfiguration");
        if (!analyzerConfiguration) {
            return;
        }

        const permissionName = "clipboard-read" as PermissionName;
        navigator.permissions.query({ name: permissionName }).then((res: PermissionStatus) => {
            if (res.state === "denied") {
                configurationNotApplied();
                return;
            } else if (res.state === "granted" || res.state === "prompt") {
                navigator.clipboard.readText().then((configuration: string) => {
                    try {
                        const parsedConfiguration = JsonUtils.parse<AnalyzerConfiguration>(configuration);
                        if (parsedConfiguration.type !== relevantForm.model.analyzerConfiguration.type) {
                            this.toastService.error(this.translateService.instant("manageLink.configurationNotAppliedTypeDifferent"));
                            return;
                        }

                        if (parsedConfiguration.type === AnalysisType.ExternalInput || parsedConfiguration.type === AnalysisType.Light) {
                            // each light and external input has its own inputs/lights defined on MP level
                            this.toastService.error(this.translateService.instant("manageLink.configurationNotApplied"));
                            return;
                        }

                        relevantForm.model.analyzerConfiguration = parsedConfiguration; // to force change detection do its work
                    } catch (exception) {
                        configurationNotApplied();
                    }
                }, (error) => {
                    configurationNotApplied();
                });
            }
        }, (error) => {
            // in this case browser doesn't support paste operation
            // (e.g. MozilaFirefox doesn't have yet clipboard-read permissions so we're going to try past from persisted array of objects)
            if (this.copiedConfiguration) {
                const parsedConfiguration = JsonUtils.parse<AnalyzerConfiguration>(this.copiedConfiguration);
                if (parsedConfiguration.type !== relevantForm.model.analyzerConfiguration.type) {
                    this.toastService.error(this.translateService.instant("manageLink.configurationNotAppliedTypeDifferent"));
                    return;
                }

                relevantForm.model.analyzerConfiguration = parsedConfiguration; // to force change detection do its work
            }
        });
    }

    private getFormValueAsJsonString(analyzerConfiguration: AnalyzerConfiguration, relevantFormModel: MeasurementFormWithModel): string {
        if (!analyzerConfiguration) {
            return;
        }

        analyzerConfiguration.canDetectBikes = relevantFormModel.model.analyzerConfiguration.canDetectBikes;
        analyzerConfiguration.canDetectCars = relevantFormModel.model.analyzerConfiguration.canDetectCars;

        this.copiedConfiguration = JsonUtils.stringify(analyzerConfiguration);
        return JsonUtils.stringify(analyzerConfiguration);
    }
}
