import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { VmsImagesComponent } from "../management/components/vms-images/vms-images.component";
import { VmsImageVariantsComponent } from "./components/vms-image-variants/vms-image-variants.component";
import { VmsImageEditorComponent } from "./components/vms-image-editor/vms-image-editor.component";
import { UploadVmsImageDialogComponent } from "./components/upload-vms-image-dialog/upload-vms-image.dialog";
import { VmsImageEditorCopyFromDialogComponent } from "./components/vms-image-editor-copyfrom-dialog/vms-image-editor-copyfrom.dialog";
import { VmsImageEditorPropertiesComponent } from "./components/vms-image-editor-properties/vms-image-editor-properties.component";
import { VmsImageEditorToolbarComponent } from "./components/vms-image-editor-toolbar/vms-image-editor-toolbar.component";
import { TranslateVmsTypeVariantsPipe } from "./pipes/translate-vms-type-variants.pipe";
import { VmsImageEditorService } from "./services/vms-image-editor.service";
import { CommonModule } from "@angular/common";
import { SymbolSelectorDialogComponent } from "./components/symbol-selector-dialog/symbol-selector-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        VmsImagesComponent,
        VmsImageVariantsComponent,
        VmsImageEditorComponent,
        VmsImageEditorPropertiesComponent,
        VmsImageEditorToolbarComponent,
        VmsImageEditorCopyFromDialogComponent,
        UploadVmsImageDialogComponent,
        TranslateVmsTypeVariantsPipe,
        SymbolSelectorDialogComponent,
    ],
    providers: [
        VmsImageEditorService
    ],
    exports: [
        VmsImagesComponent,
        VmsImageEditorComponent,
    ],
})
export class vmsImageEditorModule { }