import { NgModule } from "@angular/core";
import { SplitScreenComponent } from "src/app/ui/components/split-screen/split-screen.component";

import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { SharedModule } from "../shared/shared.module";

import { ManagementRoutingModule } from "./management-routing.module";

import { ManagementComponent } from "./components/management/management.component";
import { ManagementContainerComponent } from "./components/management-container/management-container.component";

import { ManageAdminDeviceDepotDialogComponent } from "./components/manage-admin-device-depot-dialog/manage-admin-device-depot.dialog";
import { ManageAttachmentTypeDialogComponent } from "./components/manage-attachment-type-dialog/manage-attachment-type.dialog";
import { ManageBatteryDischargeTableDialogComponent } from "./components/manage-battery-discharge-table-dialog/manage-battery-discharge-table.dialog";
import { ManageBikeParameterSetDialogComponent } from "./components/manage-bike-parameter-set-dialog/manage-bike-parameter-set.dialog";
import { ManageDatePeriodDialogComponent } from "./components/manage-date-period-dialog/manage-date-period.dialog";
import { ManageDatePeriodSetDialogComponent } from "./components/manage-date-period-set-dialog/manage-date-period-set.dialog";
import { ManageDrivingLaneDialogComponent } from "./components/manage-driving-lane-dialog/manage-driving-lane.dialog";
import { ManageJournalCategoryDialogComponent } from "./components/manage-journal-category-dialog/manage-journal-category-dialog.component";
import { ManageJournalSubCategoryDialogComponent } from "./components/manage-journal-sub-category-dialog/manage-journal-sub-category-dialog.component";
import { ManageLoginNotificationDialogComponent } from "./components/manage-login-notification-dialog/manage-login-notification.dialog";
import { ManageReleaseDialogComponent } from "./components/manage-release-dialog/manage-release.dialog";
import { ManageRuleDialogComponent } from "./components/manage-rule-dialog/manage-rule.dialog";

import { AdminAttachmentTypesComponent } from "./components/admin-attachment-types/admin-attachment-types.component";
import { AdminBatteryDischargeTablesComponent } from "./components/admin-battery-discharge-tables/admin-battery-discharge-tables.component";
import { AdminBikeParameterSetsComponent } from "./components/admin-bike-parameter-sets/admin-bike-parameter-sets.component";
import { AdminDatePeriodSetsComponent } from "./components/admin-date-period-sets/admin-date-period-sets.component";
import { AdminDeviceDepotsComponent } from "./components/admin-device-depots/admin-device-depots.component";
import { AdminDrivingLanesComponent } from "./components/admin-driving-lanes/admin-driving-lanes.component";
import { AdminJournalCategoriesComponent } from "./components/admin-journal-categories/admin-journal-categories.component";
import { AdminJournalSubCategoriesComponent } from "./components/admin-journal-sub-categories/admin-journal-sub-categories.component";
import { AdminLoginNotificationsComponent } from "./components/admin-login-notifications/admin-login-notifications.component";
import { AdminReleasesComponent } from "./components/admin-releases/admin-releases.component";
import { AdminRulesComponent } from "./components/admin-rules/admin-rules.component";
import { AttachmentTypesComponent } from "./components/attachment-types/attachment-types.component";
import { BikeParameterSetsComponent } from "./components/bike-parameter-sets/bike-parameter-sets.component";
import { DatePeriodsComponent } from "./components/date-periods/date-periods.component";
import { DatePeriodSetsComponent } from "./components/date-period-sets/date-period-sets.component";
import { DrivingLanesComponent } from "./components/driving-lanes/driving-lanes.component";
import { JournalCategoriesComponent } from "./components/journal-categories.component.ts/journal-categories.component";
import { LoginNotificationsComponent } from "./components/login-notifications/login-notifications.component";
import { PlaygroundComponent } from "./components/playground/playground.component";
import { ReleasePackagesComponent } from "./components/release-packages/release-packages.component";
import { ReleasesComponent } from "./components/releases/releases.component";
import { RulesComponent } from "./components/rules/rules.component";
import { WorkerManagementComponent } from "./components/worker-management/worker-management.component";
import { WorkersComponent } from "./components/workers/workers.component";
import { ManageWorkerDialogComponent } from "./components/manage-worker-dialog/manage-worker-dialog.component";
import { SafetyQuestionsComponent } from "./components/safety-questions/safety-questions.component";
import { SafetyQuestionManagementComponent } from "./components/safety-question-management/safety-question-management.component";
import { ManageSafetyQuestionDialogComponent } from "./components/manage-safety-question-dialog/manage-safety-question-dialog.component";
import { AdminMapIconsComponent } from "./components/admin-map-icons/admin-map-icons.component";
import { MapIconsComponent } from "./components/map-icons/map-icons.component";
import { ManageMapIconDialogComponent } from "./components/manage-map-icon-dialog/manage-map-icon.dialog.component";
import { vmsImageEditorModule } from "../vms-image-editor/vms-image-editor.module";
import { GenerateDeviceStatusDialogComponent as GenerateDeviceStatusDialogComponent } from "./components/generate-device-status-dialog/generate-device-status.dialog";

@NgModule({
    imports: [
        SharedModule,
        MapAdvancedModule,
        ManagementRoutingModule,
        vmsImageEditorModule,
        SplitScreenComponent,
    ],
    declarations: [
        ManagementComponent,
        ManagementContainerComponent,
        AdminAttachmentTypesComponent,
        AdminBatteryDischargeTablesComponent,
        AdminBikeParameterSetsComponent,
        AdminDatePeriodSetsComponent,
        AdminDeviceDepotsComponent,
        AdminDrivingLanesComponent,
        AdminJournalCategoriesComponent,
        AdminJournalSubCategoriesComponent,
        AdminLoginNotificationsComponent,
        AdminReleasesComponent,
        AdminRulesComponent,
        AttachmentTypesComponent,
        BikeParameterSetsComponent,
        DatePeriodsComponent,
        DatePeriodSetsComponent,
        DrivingLanesComponent,
        JournalCategoriesComponent,
        LoginNotificationsComponent,
        ManageAdminDeviceDepotDialogComponent,
        ManageAttachmentTypeDialogComponent,
        ManageBatteryDischargeTableDialogComponent,
        ManageBikeParameterSetDialogComponent,
        ManageJournalCategoryDialogComponent,
        ManageJournalSubCategoryDialogComponent,
        ManageDrivingLaneDialogComponent,
        ManageLoginNotificationDialogComponent,
        ManageReleaseDialogComponent,
        ManageRuleDialogComponent,
        ManageDatePeriodDialogComponent,
        ManageDatePeriodSetDialogComponent,
        PlaygroundComponent,
        ReleasePackagesComponent,
        ReleasesComponent,
        RulesComponent,
        WorkerManagementComponent,
        WorkersComponent,
        ManageWorkerDialogComponent,
        SafetyQuestionsComponent,
        SafetyQuestionManagementComponent,
        ManageSafetyQuestionDialogComponent,
        AdminMapIconsComponent,
        MapIconsComponent,
        ManageMapIconDialogComponent,
        GenerateDeviceStatusDialogComponent
    ],
    providers: [
    ],
    exports: [
    ],
})
export class ManagementModule { }
