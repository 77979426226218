<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingRule ? "manageRule.edit" : "manageRule.create") | translate}}
    </p-header>

    <p class="p-16">
        <i>{{"manageRule.guide" | translate}}</i>
    </p>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm"
          autocomplete="off"
          novalidate>

        <div [formGroup]="manageRuleForm">
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('code').showErrors }">

                <label class="col-4"
                       for="code">
                    {{"general.code" | translate}}
                </label>

                <div class="col-8">
                    <input type="text"
                           id="code"
                           class="p-inputtext"
                           formControlName="code"
                           appAutofocus
                           #codeInput />

                    <app-form-validation [control]="manageRuleForm.get('code')"
                                         [input]="codeInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('deviceTypes').showErrors }">

                <label class="col-4"
                       for="deviceTypes">
                    {{"rules.deviceTypes" | translate}}
                </label>

                <div class="col-8">
                    <p-multiSelect appendTo="body"
                                   id="deviceTypes"
                                   placeholder="{{'rules.selectDeviceTypes' | translate}}"
                                   [style]="{'width':'100%'}"
                                   [options]="deviceTypes"
                                   [filter]="true"
                                   [showHeader]="true"
                                   [showToggleAll]="false"
                                   formControlName="deviceTypes"
                                   #deviceTypesInput>
                    </p-multiSelect>

                    <app-form-validation [control]="manageRuleForm.get('deviceTypes')"
                                         [input]="deviceTypesInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('onlyForLinkedDevices').showErrors }">

                <label class="col-4"
                       for="onlyForLinkedDevices">
                    {{"rules.onlyForLinkedDevices" | translate}}
                </label>

                <div class="col-8">
                    <p-inputSwitch id="onlyForLinkedDevices"
                                   formControlName="onlyForLinkedDevices"
                                   #onlyForLinkedDevicesInput>
                    </p-inputSwitch>

                    <app-form-validation [control]="manageRuleForm.get('onlyForLinkedDevices')"
                                         [input]="onlyForLinkedDevicesInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('featuresEnabled').showErrors }">

                <label class="col-4"
                       for="featuresEnabled">
                    {{"rules.onlyForDevicesContainingFeatures" | translate}}
                </label>

                <div class="col-8">
                    <p-inputSwitch id="featuresEnabled"
                                   formControlName="featuresEnabled"
                                   #featuresEnabledInput>
                    </p-inputSwitch>

                    <app-form-validation [control]="manageRuleForm.get('featuresEnabled')"
                                         [input]="featuresEnabledInput">
                    </app-form-validation>
                </div>
            </div>

            <ng-container *ngIf="featuresForm"
                          formGroupName="features">
                <hr />

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('otaUpdate').showErrors }">

                    <label class="col-4"
                           for="otaUpdate">
                        {{"deviceFeatures.otaUpdate" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="otaUpdate"
                                       formControlName="otaUpdate"
                                       #otaUpdateInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('otaUpdate')"
                                             [input]="otaUpdateInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('realtimeAnalyzer').showErrors }">

                    <label class="col-4"
                           for="realtimeAnalyzer">
                        {{"deviceFeatures.realtimeAnalyzer" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="realtimeAnalyzer"
                                       formControlName="realtimeAnalyzer"
                                       #realtimeAnalyzerInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('realtimeAnalyzer')"
                                             [input]="realtimeAnalyzerInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('zigbee').showErrors }">

                    <label class="col-4"
                           for="zigbee">
                        {{"deviceFeatures.zigbee" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="zigbee"
                                       formControlName="zigbee"
                                       #zigbeeInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('zigbee')"
                                             [input]="zigbeeInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('gpio').showErrors }">

                    <label class="col-4"
                           for="gpio">
                        {{"deviceFeatures.gpio" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="gpio"
                                       formControlName="gpio"
                                       #gpioInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('gpio')"
                                             [input]="gpioInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('vms').showErrors }">

                    <label class="col-4"
                           for="vms">
                        {{"deviceFeatures.vms" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="vms"
                                       formControlName="vms"
                                       #vmsInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('vms')"
                                             [input]="vmsInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('qLiteDisplay').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.qLiteDisplay" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="qLiteDisplay"
                                       formControlName="qLiteDisplay"
                                       #qLiteDisplayInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('qLiteDisplay')"
                                             [input]="qLiteDisplayInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('dekimoDisplay').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.dekimoDisplay" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="dekimoDisplay"
                                       formControlName="dekimoDisplay"
                                       #dekimoDisplayInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('dekimoDisplay')"
                                             [input]="dekimoDisplayInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('tmsRadar').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.tmsRadar" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="tmsRadar"
                                       formControlName="tmsRadar"
                                       #tmsRadarInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('tmsRadar')"
                                             [input]="tmsRadarInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('safetyCrossing').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.safetyCrossing" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="safetyCrossing"
                                       formControlName="safetyCrossing"
                                       #safetyCrossingInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('safetyCrossing')"
                                             [input]="safetyCrossingInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('simulateData').showErrors }">

                    <label class="col-4"
                           for="simulateData">
                        {{"deviceFeatures.simulateData" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="simulateData"
                                       formControlName="simulateData"
                                       #simulateData>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('simulateData')"
                                             [input]="simulateData">
                        </app-form-validation>
                    </div>
                </div>

                <hr />
            </ng-container>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('warningExpression').showErrors }">

                <label class="col-4"
                       for="warningExpression">
                    {{"rules.warningExpression" | translate}}
                </label>

                <div class="col-8">
                    <textarea id="warningExpression"
                              formControlName="warningExpression"
                              class="p-inputtext"
                              #warningExpressionInput
                              rows="3"
                              style="font-family: monospace; font-size: small;">

                            </textarea>

                    <app-form-validation [control]="manageRuleForm.get('warningExpression')"
                                         [input]="warningExpressionInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('errorExpression').showErrors }">

                <label class="col-4"
                       for="errorExpression">
                    {{"rules.errorExpression" | translate}}
                </label>

                <div class="col-8">
                    <textarea id="errorExpression"
                              formControlName="errorExpression"
                              class="p-inputtext"
                              #errorExpressionInput
                              rows="3"
                              style="font-family: monospace; font-size: small;">

                            </textarea>

                    <app-form-validation [control]="manageRuleForm.get('errorExpression')"
                                         [input]="errorExpressionInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': manageRuleForm.get('isAdminOnly').showErrors }">

                <label class="col-4"
                       for="isAdminOnly">
                    {{"rules.isAdminOnly" | translate}}
                </label>

                <div class="col-8">
                    <input type="checkbox"
                           formControlName="isAdminOnly">
                </div>
            </div>
        </div>

        <h3>{{"manageRule.testHeader" | translate}}</h3>
        <div [formGroup]="deviceStatusForm">
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceStatusForm.get('timestamp').showErrors }">

                <label class="col-4"
                       for="timestamp">
                    {{"general.date" | translate}}
                </label>

                <div class="col-8">
                    <p-calendar id="timestamp"
                                formControlName="timestamp"
                                [style]="{'width':'100%'}"
                                autoWidth="false"
                                appendTo="body"
                                [showIcon]="true"
                                [hourFormat]="calendarSettings.hourFormat"
                                [dateFormat]="calendarSettings.dateFormat"
                                [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                [monthNavigator]="false"
                                [yearNavigator]="false"
                                [yearRange]="primeComponentService.calendarYearRange"
                                #timestampInput></p-calendar>

                    <app-form-validation [control]="deviceStatusForm.get('timestamp')"
                                         [input]="timestampInput">
                    </app-form-validation>
                </div>

            </div>

            <div class="m-form-group row"
                 [formGroup]="powerStatusForm"
                 [ngClass]="{ 'has-error': powerStatusForm.get('batteryVoltage').showErrors }">

                <label class="col-4"
                       for="expression">
                    {{"deviceStatus.batteryVoltage" | translate}}
                </label>

                <div class="col-8">
                    <input type="number"
                           id="batteryVoltage"
                           class="p-inputtext"
                           formControlName="batteryVoltage"
                           #batteryVoltageInput />

                    <app-form-validation [control]="powerStatusForm.get('batteryVoltage')"
                                         [input]="batteryVoltageInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [formGroup]="controllerStatusForm"
                 [ngClass]="{ 'has-error': controllerStatusForm.get('availableDiskSpace').showErrors }">

                <label class="col-4"
                       for="availableDiskSpace">
                    {{"deviceStatus.availableDiskSpace" | translate}}
                </label>

                <div class="col-8">
                    <input type="number"
                           id="availableDiskSpace"
                           class="p-inputtext"
                           formControlName="availableDiskSpace"
                           #availableDiskSpaceInput />

                    <app-form-validation [control]="controllerStatusForm.get('availableDiskSpace')"
                                         [input]="availableDiskSpaceInput">
                    </app-form-validation>
                </div>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="testResult">

            <div *ngIf="testResult.error"
                 class="error">
                {{testResult.error}}
            </div>

            <div *ngIf="!testResult.error">
                <p>
                    <ng-container *ngIf="testResult.didTriggerWarning; else didNotTriggerWarning">
                        <span style="color: red">
                            {{"manageRule.triggeredTestWarning" | translate}}
                        </span>
                    </ng-container>

                    <ng-template #didNotTriggerWarning>
                        <span style="color: green">
                            {{"manageRule.didNotTriggeredTestWarning" | translate}}
                        </span>
                    </ng-template>
                </p>

                <p>
                    <ng-container *ngIf="testResult.didTriggerError; else didNotTriggerError">
                        <span style="color: red">
                            {{"manageRule.triggeredTestError" | translate}}
                        </span>
                    </ng-container>

                    <ng-template #didNotTriggerError>
                        <span style="color: green">
                            {{"manageRule.didNotTriggeredTestError" | translate}}
                        </span>
                    </ng-template>
                </p>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button class="m-btn m-btn-default"
                type="button"
                (click)="test()"
                [disabled]="submitting || submittingTest">
            <app-loading-spinner *ngIf="submittingTest">
            </app-loading-spinner>
            {{"manageRule.test" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>