import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { IAlertHistory } from "../models/alert-history";

@Injectable({
    providedIn: "root"
})
export class AlertHistoryApi extends ApiService<IAlertHistory, null, null> {

    getRoute(): string {
        return "AlertHistory";
    }
}