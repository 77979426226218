import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ILightPinnedData } from "src/app/models/pinned-data";
import { DownloadFileService } from "src/app/services/download-file.service";
import { FileUtils } from "src/app/utilities";
import { LightManageDialogComponent } from "../light-manage-dialog/light-manage-dialog.component";

@Component({
    selector: "app-light-pinned-data",
    templateUrl: "./light-pinned-data.component.html",
})
export class LightPinnedDataComponent implements OnChanges {

    @Input() measuringPointId: number;
    @Input() data: ILightPinnedData;
    @Input() isExpanded: boolean;
    @ViewChild(LightManageDialogComponent, { static: true }) modeManageDialog: LightManageDialogComponent;

    iconPreview?: string;

    constructor(
        private readonly downloadFileService: DownloadFileService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const lightActionIconChange = changes["data"];

        if (lightActionIconChange) {
            this.loadLightActionIcon();
        }
    }

    private async loadLightActionIcon() {
        if (this.data.iconUrl) {
            const file = await this.downloadFileService.downloadBlob(this.data.iconUrl);
            this.iconPreview = await FileUtils.toBase64(file.file);
        } else {
            this.iconPreview = null;
        }
    }

    openSetModeDialog(event: MouseEvent) {
        event.stopPropagation();
        if (!this.data.modes) return;
        this.modeManageDialog.open(this.measuringPointId, this.data.modes);
    }
}
