import { ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { ILocationStatusSummary, IMeasuringPointStatusSummary } from "src/app/models/web";
import { LiveTilesService } from "src/app/modules/live-tiles/services/live-tiles.service";

// This tile is shown for measuringpoints where we don't have a dedicated tile
@Component({
    selector: "app-generic-tile",
    templateUrl: "./generic-tile.component.html",
    styleUrls: ["./generic-tile.component.scss"],
})
export class GenericTileComponent {
    @Input() locationStatusSummary: ILocationStatusSummary;
    @Input() data: IMeasuringPointStatusSummary;

    private readonly liveTilesService = inject(LiveTilesService);

    openLiveTile() {
        this.liveTilesService.addLiveTileFromId(this.data.measuringPointId);
    }
}