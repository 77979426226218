import { ManageDrivingLaneDialogComponent } from "../manage-driving-lane-dialog/manage-driving-lane.dialog";
import { DrivingLanesComponent } from "../driving-lanes/driving-lanes.component";
import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { IDrivingLane } from "src/app/models/driving-lane";

@Component({
    selector: "app-admin-driving-lanes",
    templateUrl: "./admin-driving-lanes.component.html"
})
export class AdminDrivingLanesComponent implements OnDestroy {
    @ViewChild(ManageDrivingLaneDialogComponent, { static: true }) manageDrivingLaneDialog: ManageDrivingLaneDialogComponent;
    @ViewChild(DrivingLanesComponent, { static: true }) drivingLanesComponent: DrivingLanesComponent;


    constructor(
        elementRef: ElementRef
    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");


    }
    ngOnDestroy(): void {
    }

    create() {
        this.manageDrivingLaneDialog.create(() => {
            this.drivingLanesComponent.reload();
        });
    }

    edit(drivingLane: IDrivingLane) {
        this.manageDrivingLaneDialog.edit(drivingLane);
    }
}