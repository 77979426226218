import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataSetType, PinnedDataOption } from "src/app/models/pinned-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { PinnedDataService } from "src/app/services/pinned-data.service";

@Pipe({
    name: "dataSetName"
})
export class DataSetNamePipe implements PipeTransform {

    constructor(private translateService: TranslateService,
        private domainDataService: DomainDataService,
        private pinnedDataService: PinnedDataService) {
    }

    transform(dataSetType: DataSetType, language: string, option: PinnedDataOption): Promise<string> {
        return this.domainDataService.get(DomainData.VehicleCategory, { orderBy: null }).then((vehicleCategories) => {
            switch (option) {
                case PinnedDataOption.Speed: {
                    if (dataSetType !== DataSetType.FloatingCarSpeed) {
                        switch (dataSetType) {
                            case DataSetType.SpeedV50:
                                return "V50";

                            case DataSetType.SpeedV85:
                                return "V85";

                            case DataSetType.SpeedV95:
                                return "V95";
                        }
                        break;
                    } else {
                        return this.translateService.instant("liveTiles.measuredData.currentSpeed");
                    }
                }

                case PinnedDataOption.Distance: {
                    if (dataSetType) {
                        switch (dataSetType) {
                            case DataSetType.DistanceA05:
                                return "A05";

                            case DataSetType.DistanceA15:
                                return "A15";

                            case DataSetType.DistanceA50:
                                return "A50";
                        }
                    }
                    break;
                }

                case PinnedDataOption.Intensity: {
                    const vehicleCategory = this.pinnedDataService.getVehicleCategoryBasedOnDataSetType(dataSetType);
                    if (vehicleCategory) {
                        return vehicleCategories.find(x => x.value === vehicleCategory).label;
                    }
                    break;
                }

                case PinnedDataOption.ParkingOccupancy: {
                    if (dataSetType) {
                        return this.translateService.instant(`liveTiles.measuredData.${dataSetType}`);
                    }
                    break;
                }
            }
        }).catch((error) => {
            return "";
        });
    }
}
