<div style="display: grid; gap:8px;">
    <div style="display:grid; grid-template-columns: 1fr auto;">
        <h4>{{data.analysisType | translateEnum:"analysisType"}}</h4>
        <div style="display:flex; flex-wrap: wrap; gap: 4px; justify-content: end;">
            <app-alert-badge *ngFor="let alert of data.alerts"
                             [locationStatusSummary]="locationStatusSummary"
                             [measuringPointStatusSummary]="data"
                             [data]="alert"></app-alert-badge>
        </div>
    </div>

    <div style="display: grid; grid-template-columns: auto 1fr ;gap:16px;">
        <div class="location-popup__tile-icon">
            <img [src]="getIconUrl()" />
        </div>
        <div style="display:grid; grid-template-columns: 1fr 1fr; gap:8px;">
            <dl class="location-popup__description-list">
                <dt>{{"liveTiles.tracker.lastLocationUpdate" | translate}}</dt>
                <dd pTooltip="{{data.currentStatus?.timestamp | momentDateTime:true:true:translateService.currentLang}}">{{data.currentStatus?.timestamp | momentRelativeTime:translateService.currentLang}}</dd>
            </dl>
        </div>
    </div>
</div>