<div class="m-form-group"
     *ngIf="formVisible && form"
     formGroupName="collectRConfiguration">
    <div class="p-16 m-border-top">
        <div class="row">
            <label class="m-input-checkbox">
                <input type="checkbox"
                       [formControl]="form.get('mappingEnabled')" />
                {{"collectRConfiguration.configureCollectRMapping" | translate}}
            </label>
        </div>
    </div>

    <div *ngIf="showMapping"
         class="p-16"
         formGroupName="mapping">
        <h3 class="mb-8">{{"collectRConfiguration.vehicleCategories" | translate }}</h3>
        <div *ngFor="let classIdForm of mappingFormArray.controls; let classIdIndex = index"
             class="m-form-group row"
             formGroupName="{{classIdIndex}}">

            <div style="width:auto"
                 class="col-1">
                <input type="number"
                       class="p-inputtext"
                       style="width:70px;min-width:70px"
                       [formControl]="classIdForm.get('classId')"
                       #vehicleClassIdInput />

                <app-form-validation [control]="classIdForm.get('classId')"
                                     [input]="vehicleClassIdInput">
                </app-form-validation>
            </div>

            <div class="col-3">
                <p-dropdown [options]="vehicleSubTypes"
                            id="vehicleSubType"
                            [formControl]="classIdForm.get('vehicleSubType')"
                            #vehicleSubTypeInput></p-dropdown>

                <app-form-validation [control]="classIdForm.get('vehicleSubType')"
                                     [input]="vehicleSubTypeInput">
                </app-form-validation>
            </div>

            <div class="col-1 row"
                 style="align-items: center; cursor: pointer">
                <app-svg-icon icon="delete"
                              (click)="removeMapping(classIdIndex)">

                </app-svg-icon>
            </div>
        </div>

        <div class="mt-8 row col-4"
             style="cursor: pointer"
             (click)="addMapping()">
            <app-svg-icon icon="add">

            </app-svg-icon>

            <p style="line-height: 24px; vertical-align: middle;">
                {{'collectRConfiguration.addMapping' | translate}}
            </p>
        </div>
    </div>
</div>