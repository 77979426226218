import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { GlobalEventsService } from "../services/global-events-service";
import { LocalStorageService } from "../services/storage.service";
import { Constants } from "../constants/constants";
import { AuthenticationService } from "../services/authentication.service";
import { Observable, catchError, filter, first, map, switchMap } from "rxjs";

@Injectable()
export class AuthGuard {

    constructor(
        private readonly router: Router,
        private readonly globalEventsService: GlobalEventsService,
        private readonly localStorageService: LocalStorageService,
        private readonly authenticationService: AuthenticationService,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authenticationService.acquireTokenSilent$()
            .pipe(
                switchMap((e) => {
                    return this.handleActivate(route, state)
                        .pipe(
                            map((result) => {
                                return result;
                            })
                        );
                }),
                catchError((error) => {
                    console.error("acquireTokenSilent$ failed", error);
                    return this.handleActivate(route, state)
                        .pipe(
                            map((result) => {
                                return result;
                            })
                        );
                })
            );
    }

    private handleActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const attemptToLogin = () => {
            let returnUrl = state.url;
            if (!returnUrl) {
                returnUrl = this.router.url;
            }
            if (returnUrl === "/") {
                this.localStorageService.removeItem(Constants.callbackRoute);
            } else {
                this.localStorageService.setItem(Constants.callbackRoute, returnUrl);
            }

            this.authenticationService.login();
        };

        // Wait for isAuthenticated
        return this.globalEventsService.isAuthenticated$.pipe(
            filter(isAuthenticated => isAuthenticated !== null),
            first(),
            map((isAuthenticated) => {
                // if not authenticated, store the requested url in local storage and let the authorization process start
                // once authenticated, we will navigate back to the requested url
                if (!isAuthenticated) {
                    console.error("unable to authenticate user, redirecting to login page.");
                    attemptToLogin();
                    return false;
                }
                return true;
            })
        );

    }
}