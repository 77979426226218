interface Array<T> {
    takeFirstOrDefault(): T;
    takeLastOrDefault(): T;
    takeSingleOrDefault(): T;
    groupBy(prop: string): Group<T>[];
    groupByFunc(prop: (src: T) => any): Group<T>[];
    sortBy(prop: (src: T) => number): T[];
    sortByDescending(prop: (src: T) => number): T[];
    orderBy(prop: (src: T) => string): T[];
    contains(element: T): boolean;
    insert(element: T, index: number): void;
    remove(element: T): T[];
    replace(oldElement: T, newElement: T): T[];
    hasAny(elements: T[]): boolean;
    distinct(): T[];
    distinctFunc(prop: (src: T) => any): T[];
    selectMany<TIn, TOut>(selectListFn: (t: TIn) => TOut[]): TOut[];
    clone(): T[];
    indexesOf(element: T): number[];
    all(prop: (src: T) => boolean): boolean;
    sum(): number;
    min(): number;
    max(): number;
    chunk(chunkSize: number): T[][];
    areContentsEqual(otherList: T[], getKeyFunc: (model: T) => any);
}

Array.prototype.selectMany = function <TIn, TOut>(selectListFn: (t: TIn) => TOut[]): TOut[] {
    return this.reduce((out: TOut[], inx: TIn) => {
        out.push(...selectListFn(inx));
        return out;
    }, new Array<TOut>());
};

Array.prototype.takeFirstOrDefault = function () {
    if (!this) return null;
    if (this.length > 0) return this[0];
    return null;
};

Array.prototype.takeLastOrDefault = function () {
    if (!this) return null;
    if (this.length > 0) return this[this.length - 1];
    return null;
};

Array.prototype.takeSingleOrDefault = function () {
    if (!this) return null;
    if (this.length === 1) return this[0];
    if (this.length > 1) throw { message: "Found multiple matches in takeSingleOrDefault", array: this };
    return null;
};

Array.prototype.sortBy = function (prop) {
    return this.sort((a: any, b: any) => {
        return prop(a) - prop(b);
    });
};

Array.prototype.sortByDescending = function (prop) {
    return this.sort((a: any, b: any) => {
        return prop(b) - prop(a);
    });
};

Array.prototype.orderBy = function (prop) {
    return this.sort((a: any, b: any) => {
        const propA = prop(a);
        const propB = prop(b);
        return (propA || "").localeCompare(propB);
    });
};

Array.prototype.groupBy = function (prop) {
    return groupBy(this, x => deepValue(x, prop));
};

Array.prototype.groupByFunc = function (prop) {
    return groupBy(this, prop);
};

Array.prototype.contains = function (element) {
    return this.indexOf(element) > - 1;
};

Array.prototype.insert = function (element, index) {
    this.splice(index, 0, element);
};

Array.prototype.remove = function (element) {
    return this.filter((x: any) => x !== element);
};

Array.prototype.replace = function (oldElement, newElement) {
    const indexOf = this.indexOf(oldElement);
    if (indexOf >= 0) {
        this[indexOf] = newElement;
    }

    return this;
};

Array.prototype.hasAny = function (elements) {
    if (!elements) return false;

    return this.some((x: any) => elements.contains(x));
};

Array.prototype.distinct = function () {
    return this.filter((value: any, index: number) => this.indexOf(value) === index);
};

Array.prototype.distinctFunc = function (prop) {
    const funcList = this.map((x: any) => prop(x));
    return this.filter((value: any, index: number) => funcList.indexOf(prop(value)) === index);
};

Array.prototype.clone = function () {
    return this.map((x: any) => x);
};

Array.prototype.indexesOf = function (element) {
    const a = new Array<number>();
    for (let i = this.length; i--;) if (this[i] === element) a.push(i);
    return a;
};

Array.prototype.all = function (prop) {
    return this.filter((x: any) => prop(x)).length === this.length;
};

Array.prototype.sum = function () {
    return (this as Array<number>).reduce((a: number, b: number) => a + b, 0);
};

Array.prototype.min = function () {
    return Math.min.apply(Math, this as Array<number>);
};

Array.prototype.max = function () {
    return Math.max.apply(Math, this as Array<number>);
};

Array.prototype.chunk = function <T>(chunkSize: number) {
    const toReturn = new Array<T[]>();
    for (let i = 0; i < this.length; i += chunkSize) {
        toReturn.push(this.slice(i, i + chunkSize));
    }
    return toReturn;
};

Array.prototype.areContentsEqual = function <T>(otherList: T[], getKeyFunc: (model: T) => any) {
    if (!otherList) return false;
    if (otherList.length !== this.length) return false;

    const keys = this.map(x => getKeyFunc(x));
    const otherKeys = otherList.map(x => getKeyFunc(x));
    return otherKeys.all(x => keys.contains(x));
}

class Group<T> {
    key: any;
    members: T[] = [];
    constructor(key: any) {
        this.key = key;
    }
}

// Source: https://stackoverflow.com/questions/42136098/array-groupby-in-typescript
function groupBy<T>(list: T[], func: (x: T) => any): Group<T>[] {
    const result: Group<T>[] = [];
    let group: Group<T> = null;

    list.forEach(o => {
        const groupedBy = func(o);

        if (groupedBy instanceof Date && group.key instanceof Date) {
            group = result.find(x => x.key instanceof Date && x.key.getTime() === groupedBy.getTime());
        }
        else {
            group = result.find(x => x.key === groupedBy);
        }

        if (!group) {
            group = new Group<T>(groupedBy);
            group.members = [];
            result.push(group);
        }

        group.members.push(o);
    });

    return result;
}

// Source: https://stackoverflow.com/questions/8817394/javascript-get-deep-value-from-object-by-passing-path-to-it-as-string
function deepValue(object: any, path: string) {
    const pathArray = path.split(".") as string[];

    for (let i = 0; i < pathArray.length; i++) {
        object = object[pathArray[i]];
    }

    return object;
}