import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, HostListener, OnDestroy } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager } from "src/app/utilities";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";
import { ICameraConfiguration } from "src/app/models/camera";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-device-camera",
    templateUrl: "./device-camera.component.html"
})
export class DeviceCameraComponent implements OnInit, OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    cameraConfigurationForm: SigncoFormGroup;

    private readonly subscriptionManager = new SubscriptionManager();
    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        // TODO(r): Dirty but works just fine
        this.toastService.suppressErrors = true;

    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.cameraConfigurationForm.pristine;
    }

    onDeactivate() {
        this.toastService.suppressErrors = false;
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        this.cameraConfigurationForm = this.formBuilder.group({
            url: ["", Validators.required],
            username: ["", Validators.required],
            password: ["", Validators.required],
            refreshDelayInSeconds: [300, [Validators.required, Validators.min(0)]],
            supportsLivestream: false
        }) as SigncoFormGroup;

        if (this.device.cameraConfiguration) {
            this.cameraConfigurationForm.patchValue(this.device.cameraConfiguration);
        }

        this.cameraConfigurationForm.markAsPristine();
    }



    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.cameraConfigurationForm);
        if (!isValid) return;

        this.cameraConfigurationForm.disable();

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.cameraConfigurationForm.enable();
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
            this.cameraConfigurationForm.enable();
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);

        if (!deviceUpdater.cameraConfiguration) deviceUpdater.cameraConfiguration = {} as ICameraConfiguration;
        Object.assign(deviceUpdater.cameraConfiguration, this.cameraConfigurationForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}