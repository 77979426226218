import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef } from "@angular/core";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IDevice, DeviceUpdater } from "src/app/models/device";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SigncoFormGroup } from "src/app/models/form";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";

@Component({
    selector: "app-device-tms-radar-configuration",
    templateUrl: "./device-tms-radar-configuration.component.html"
})
export class DeviceTmsRadarConfigurationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    submitting = false;
    tmsRadarConfigurationForm: SigncoFormGroup;

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-bottom");
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.tmsRadarConfigurationForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    async initialize() {
        if (!this.device) return;

        this.tmsRadarConfigurationForm = this.formBuilder.group({
            port: [null, Validators.required],
            angle: [0, [Validators.min(0), Validators.max(90)]],
            delayBetweenMeasures: [13, [Validators.min(0), Validators.max(255)]],
            detectionZoneLengthInMeters: [null, [Validators.min(0)]],
            speedDeltaInKmh: [null, [Validators.min(0)]],
            analyzeForwardDirection: false,
            analyzeReverseDirection: false
        }) as SigncoFormGroup;

        this.tmsRadarConfigurationForm.patchValue(this.device.tmsRadarConfiguration);
        this.tmsRadarConfigurationForm.markAsPristine();
    }

    async reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.tmsRadarConfigurationForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            Object.assign(this.device, savedDevice);
            this.submitting = false;
            this.initialize();
            this.save.emit(this.device);
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);

        Object.assign(deviceUpdater.tmsRadarConfiguration, this.tmsRadarConfigurationForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}