import { ManageLoginNotificationDialogComponent } from "../manage-login-notification-dialog/manage-login-notification.dialog";
import { LoginNotificationsComponent } from "../login-notifications/login-notifications.component";
import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { ILoginNotification } from "src/app/models/login-notification";
import { GlobalEventsService } from "src/app/services/global-events-service";

@Component({
    selector: "app-admin-login-notifications",
    templateUrl: "./admin-login-notifications.component.html"
})
export class AdminLoginNotificationsComponent implements OnDestroy {
    @ViewChild(ManageLoginNotificationDialogComponent, { static: true }) manageLoginNotificationDialog: ManageLoginNotificationDialogComponent;
    @ViewChild(LoginNotificationsComponent, { static: true }) loginNotificationsComponent: LoginNotificationsComponent;


    constructor(
        elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        // rights subscription


    }

    ngOnDestroy(): void {
    }

    create() {
        this.manageLoginNotificationDialog.create(() => {
            this.loginNotificationsComponent.reload();
        });
    }

    edit(loginNotification: ILoginNotification) {
        this.manageLoginNotificationDialog.edit(loginNotification);
    }

    show() {
        this.globalEventsService.showLoginNotifications(false);
    }
}