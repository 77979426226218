<ng-container *ngIf="isLoading && !isLoadingInfiniteData">
    <app-loading-spinner></app-loading-spinner>
</ng-container>

<div (scroll)="handleScroll($event)"
     class="result-container__body">
    <app-search-result *ngFor="let item of viewModels; trackBy: trackLocation"
                       [viewModel]="item"
                       (click)="onSelect(item)"></app-search-result>
</div>

<div *ngIf="isLoadingInfiniteData"
     class="result-container__spinner">
    <app-loading-spinner></app-loading-spinner>
</div>