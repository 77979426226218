<div class="m-layout-w-actions-top m-fixed-height m-border-top"
     [ngClass]="ngClass">
    <app-tabs [tabs]="tabs"
              class="m-layout-area-top"
              [selectedTab]="selectedTab"
              [relativeRouting]="true"
              [hidden]="!measuringPoint"
              (selectedTabChange)="navigateTo($event);">
    </app-tabs>

    <router-outlet *ngIf="measuringPoint"
                   (activate)="onActivate($event)"></router-outlet>
</div>