import { Component, OnChanges, SimpleChanges, Input, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CustomTableComponent, TableColumn, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { MeasuringPointExceptionalDayUpdater } from "src/app/models/measuring-point-exceptional-day";
import { UploadResult } from "src/app/models/upload";

@Component({
    selector: "app-bulk-upload-result",
    templateUrl: "./bulk-upload-result.component.html"
})
export class BulkUploadResultComponent extends CustomTableComponent<UploadResult> implements OnChanges {
    @Input() results: UploadResult[];

    defaultExceptionDays: MeasuringPointExceptionalDayUpdater[];
    hasConflicts = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly cd: ChangeDetectorRef) {

        super("upload-results.component", elementRef, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.selectionMode = "";
        this.paginator = false;
        this.footer = false;
        this.stretchHeight = true;

        this.addColumn(new TableColumn("success", "", { type: ColumnType.Icon }));
        this.addColumn(new TableColumn("filename", "general.file", { width: 400, resizable: true }));
        this.addColumn(new TableColumn("error", "form.error"));
    }

    ngOnChanges(changes: SimpleChanges) {
        const resultsChange = changes["results"];
        if (resultsChange) {
            this.setData(this.results);
        }

        super.ngOnChanges(changes);
    }

    loadTableRows() {

    }

    onSetData() {
        this.cd.detectChanges();
    }
}