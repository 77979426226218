<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-border-top"
         [loading]="loading"
         [selectionMode]="selectionMode"
         [resizableColumns]="resizable"
         [columnResizeMode]="columnResizeMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [customSort]="selectionBox"
         (sortFunction)="customSort($event)"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [totalRecords]="total"
         [(selection)]="selectedTasks"
         sortField="name"
         dataKey="id"
         #tableRef>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>
    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [ngSwitch]="column.field">
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <ng-container *ngSwitchCase="'commands'">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </ng-container>

                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field">
                        </p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-task>
        <tr #row
            [pSelectableRow]="task"
            [pSelectableRowDisabled]="!canSelectRow(task)"
            [attr.id]="task[table.dataKey]">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [ngClass]="resizable ? 'ui-resizable-column' : ''">

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             [iconClass]="command.icon === 'duplicate' ? 'duplicate' : ''"
                                             (click)="command.click(task)"
                                             *ngIf="command.rowValidFunc(task)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'isCompleted'">
                    <app-svg-icon *ngIf="task.currentStatus.taskStatusId === 'finished';else notCompleted"
                                  icon="checkmark"
                                  ngClass="fill-success"
                                  tooltip="{{'task.completed' | translate}}"></app-svg-icon>
                    <ng-template #notCompleted>
                        <app-svg-icon icon="pending"
                                      ngClass="fill-warning"
                                      tooltip="{{'task.notCompleted' | translate}}"></app-svg-icon>
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'assignment'">
                    {{task.assignment ? task.assignment.name : ""}}
                </ng-container>

                <div *ngSwitchCase="'currentStatus.taskTypeId'"
                     [ngStyle]="{'font-size': mini ? '10px' : 'inherit' }">
                    <app-domain-data-field [enumKey]="task.currentStatus.taskTypeId"
                                           domainDataType="taskType">
                    </app-domain-data-field>

                    <ng-container *ngIf="mini">
                        <br />
                        <app-domain-data-field [enumKey]="task.currentStatus.signMaterialId"
                                               domainDataType="signMaterial">
                        </app-domain-data-field> -
                        <app-domain-data-field [enumKey]="task.currentStatus.signCategoryLevel4Id || task.currentStatus.signCategoryLevel3Id || task.currentStatus.signCategoryLevel2Id || task.currentStatus.signCategoryId"
                                               domainDataType="signCategoryValue"></app-domain-data-field>
                    </ng-container>
                </div>

                <ng-container *ngSwitchCase="'currentStatus.signMaterialId'">
                    <app-domain-data-field [enumKey]="task.currentStatus.signMaterialId"
                                           domainDataType="signMaterial">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.signCategoryId'">
                    <app-domain-data-field [enumKey]="task.currentStatus.signCategoryLevel4Id || task.currentStatus.signCategoryLevel3Id || task.currentStatus.signCategoryLevel2Id || task.currentStatus.signCategoryId"
                                           domainDataType="signCategoryValue">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.taskStatusId'">
                    <app-domain-data-field [enumKey]="task.currentStatus.taskStatusId"
                                           domainDataType="taskStatus">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'location'">
                    {{task.location?.address | address}}
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.availableFrom'">
                    {{task.currentStatus.availableFrom | date:'dd/MM/yyyy'}}
                </ng-container>
                <ng-container *ngSwitchCase="'currentStatus.team'">
                    {{task.currentStatus.team}}
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{task[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>