<div class="m-page-content-wrapper p-16">
    <div class="m-card m-fixed-height m-layout-w-actions-bottom"
         #contentDiv>

        <ng-template #loadingTemplate>
            <app-loading-spinner></app-loading-spinner>
        </ng-template>

        <ng-container *ngIf="mode !== undefined; else loadingTemplate">
            <!-- Report -->
            <div class="m-layout-area-body"
                 *ngIf="mode === 0">
                <div class="p-16 m-border-bottom">
                    <h3>{{"qr.reportIssue" | translate}}</h3>
                </div>

                <form [formGroup]="reportForm"
                      class="p-16"
                      autocomplete="off">

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': reportForm.get('message').showErrors }">

                        <label class="col-4"
                               for="message">
                            {{"loginNotifications.message" | translate}}
                        </label>

                        <div class="col-8">
                            <textarea id="message"
                                      class="p-inputtextarea"
                                      formControlName="message"
                                      rows="5"
                                      appAutofocus
                                      #messageInput></textarea>

                            <app-form-validation [control]="reportForm.get('message')"
                                                 [input]="messageInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': reportForm.get('contact').showErrors }">

                        <label class="col-4"
                               for="message">
                            {{"qr.contact" | translate}}
                        </label>

                        <div class="col-8">
                            <input type="text"
                                   class="p-inputtext"
                                   id="contact"
                                   formControlName="contact" />

                            <app-form-validation [control]="reportForm.get('contact')"
                                                 [input]="contactInput">
                            </app-form-validation>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Register QR -->
            <div class="m-layout-area-body m-layout-w-actions-top"
                 *ngIf="mode === 1">
                <div class="p-16 m-layout-area-top m-border-bottom">
                    <h3>{{"qr.registerQr" | translate}}</h3>
                </div>

                <app-map-selector [mapDetail]="1"
                                  [componentHeight]="mapHeight"
                                  (detailComponentSet)="handleMapComponentLoad()"
                                  class="m-layout-area-body m-layout-default">
                </app-map-selector>
            </div>

            <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
                <button *ngIf="multipleModes"
                        class="m-btn m-btn-secondary"
                        (click)="swapMode()"
                        type="button">
                    {{('qr.' + (mode === 1 ? 'reportIssue' : 'registerQr')) | translate}}
                </button>

                <button [disabled]="submitting"
                        class="m-btn m-btn-primary"
                        (click)="submit()"
                        type="submit">

                    <app-loading-spinner *ngIf="submitting">
                    </app-loading-spinner>

                    {{"form.submit" | translate}}
                </button>
            </div>
        </ng-container>
    </div>
</div>