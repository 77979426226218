import { NgModule } from "@angular/core";
import { BasicMapComponent } from "./components/basic-map/basic-map.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { MapTypeSelectorComponent } from "./components/map-type-selector/map-type-selector.component";
import { MapTypeProvider } from "./services/map-type-provider";
import { GeolocationService } from "./services/geolocation.service";
import { MapContextMenuComponent } from "./components/map-context-menu/map-context-menu.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [
        MapTypeProvider,
        GeolocationService,
    ],
    declarations: [
        BasicMapComponent,
        MapTypeSelectorComponent,
        MapContextMenuComponent,
    ],
    exports: [
        BasicMapComponent,
    ],
})
export class MapBasicModule { }