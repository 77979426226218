<div class="m-page-content-wrapper p-16">
    <div class="row"
         *ngIf="!parkingBan">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="row m-fixed-height"
         [hidden]="!parkingBan">
        <div class="col-3 m-fixed-height">
            <div class="m-half-height m-layout-default">
                <div class="m-card m-layout-area-body m-layout-default mb-8"
                     style="position: relative;">
                    <a (click)="toMap()"
                       class="m-btn m-btn-back m-btn-back-map"
                       title="Back">
                        <app-svg-icon icon="chevron-left"
                                      class="mr-4"></app-svg-icon>
                        {{"general.returnToMap" | translate}}
                    </a>

                    <app-location-detail *ngIf="parkingBan"
                                         [location]="parkingBan.location"
                                         [assignment]="parkingBan.assignment"
                                         [editCommand]="canEditLocation"
                                         class="m-layout-area-body m-layout-w-actions-top"></app-location-detail>
                </div>
            </div>

            <div class="m-half-height m-layout-default">
                <div class="m-card m-layout-area-body mt-8 m-layout-w-actions-top">
                    <div class="p-16 m-layout-area-top m-border-bottom">
                        <h3>{{"parkingBan.allParkingBans" | translate}}</h3>
                    </div>

                    <div class="m-layout-area-body m-layout-w-actions-bottom">
                        <app-parking-bans [parkingBanIdSelected]="parkingBan?.id"
                                          [assignmentId]="parkingBan?.assignment?.id"
                                          [requiresInput]="true"
                                          [headers]="false"
                                          [footer]="false"
                                          [paginator]="false"
                                          [rowCount]="9999"
                                          [mini]="true"
                                          [selectionMode]="'single'"
                                          [deleteCommand]="false"
                                          [editCommand]="false"
                                          (selected)="handleOnParkingBanSelect($event)"
                                          componentStyle="m-card-body-table-content"
                                          class="m-layout-area-body m-layout-default">
                        </app-parking-bans>

                        <div class="m-layout-area-bottom">
                            <div class="m-btn-group m-btn-group-right m-border-top">
                                <button class="m-btn m-btn-secondary"
                                        (click)="backToDetailedParkingBansRepresentation()"
                                        [disabled]="!parkingBan?.id">
                                    {{"parkingBanDetailTab.backToParkingBans" | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height">
            <app-parking-ban-detail-container [parkingBan]="parkingBan"
                                              class="m-fixed-height"
                                              (save)="handleOnTaskSaved($event)">
            </app-parking-ban-detail-container>

            <div *ngIf="loading">
                <app-loading-spinner>
                </app-loading-spinner>
            </div>
        </div>
    </div>
</div>