import { Pipe, PipeTransform } from "@angular/core";
import { DomainDataService } from "src/app/services/domain-data.service";

@Pipe({ name: "translateModel" })
export class TranslateModelPipe implements PipeTransform {
    constructor(private readonly domainDataService: DomainDataService) {
    }

    transform(value: string, language: string, update: any): string {
        return this.domainDataService.translate(value, language);
    }
}