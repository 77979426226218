import { SearchParameters, ServiceRequestOptions, ISearchResult, FilterDescriptor } from "src/app/models/search";
import { CacheOptions, ApiServiceBase } from "../api";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DownloadFileService } from "src/app/services/download-file.service";
import { UploadFileService } from "src/app/services/upload-file.service";
import { CacheService } from "src/app/services/cache.service";
import { ErrorService } from "src/app/services/error.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, firstValueFrom } from "rxjs";
import { LazyLoadEvent } from "primeng/api";

@Injectable()
export class WebApiService<T> extends ApiServiceBase {

    constructor(
        http: HttpClient,
        downloadFileService: DownloadFileService,
        uploadFileService: UploadFileService,
        cacheService: CacheService,
        errorService: ErrorService,
        configurationService: ConfigurationService) {

        super(http, downloadFileService, uploadFileService, cacheService, errorService, configurationService);
    }

    protected getApiVersion(): string {
        return "web";
    }

    search$(
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = true,
        routeParams: { [key: string]: string } = null,
        lazyLoadEvent: LazyLoadEvent = null): Observable<ISearchResult<T>> {

        const options = this.createOptions(serviceRequestOptions, searchParameters, lazyLoadEvent);
        const url = this.getUrl(routeParams) + "/Untyped";

        // KV I have a bad feeling about this caching, I have disabled it for now.
        // This is how the cache works:
        // * This method returns an Observable
        // * If the value exists in the cache, the observable is invoked immediately with the cached value
        // * Then, the network call is done
        // * When the server data arrives, the cache is updated and the observable is invoked *a second time*

        // The problems with that are:
        // * If it is implemented well, the user first sees the cached value, and after a second or so the correct value. That is confusing.
        // * However, very often it is not implemented well, and the user only sees the cached value. This happens if the observable
        //   is converted to a promise, which we often do to use the async/await pattern. However, a promise will only process the first value,
        //   which is the cached value.
        // * Even if it is implemented well, it can put a lot of stress on the FE. For instance, when loading the markers, they will have to be drawn twice
        //   (first with the cached data, then with the server data)
        // So for now the lines below are commented out. I am currently in doubt if we should remove the caching mechanism altogether.

        // if (useCache) {
        //     return this.handleCaching(url, options, useCache);
        // }

        return this.http.get<ISearchResult<T>>(url, options);
    }

    async get(id: number = null): Promise<T> {
        // Instead of retrieving the data directly by the id, we do a search on the id.
        // That seems very inefficient, we should probably redesign it to do a get on the id
        // However, to do that we need to add the endpoints on the server.

        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        searchParameters.filter.push(new FilterDescriptor("id", id));

        const data = await firstValueFrom(this.search$(searchParameters));
        if (!data.data || !data.data.length) return null;

        return data.data.takeFirstOrDefault();
    }
}