import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

interface DateRange {
    from?: Date;
    until?: Date;
    format?: string;
}

@Pipe({
    name: "dateRange"
})
export class DateRangePipe implements PipeTransform {

    transform(value: DateRange): string {
        return DateRangePipe.transform(value);
    }

    static transform(value: DateRange): string {
        if (!value) {
            return null;
        }
        if (!value.from && !value.until) {
            return null;
        }

        const fromDateString = this.formatDate(value.from, value.format);
        const untilDateString = this.formatDate(value.until, value.format);

        return `${fromDateString} → ${untilDateString}`;
    }

    private static formatDate(date: Date, format?: string): string {
        if (!date) return "...";

        const result = moment(date).format(format ?? "ddd L LT");
        return result;
    }
}