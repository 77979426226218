import { IDeviceDepot, DeviceDepotCreator, DeviceDepotUpdater } from "src/app/models/device-depot";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { Component, OnDestroy } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { DeviceDepotApi } from "src/app/resource/device-depot.api";

@Component({
    selector: "app-manage-admin-device-depot-dialog",
    templateUrl: "./manage-admin-device-depot.dialog.html"
})
export class ManageAdminDeviceDepotDialogComponent extends DialogComponentBase implements OnDestroy {
    submitting: boolean;
    callback: (res: IDeviceDepot) => void;
    manageDeviceDepotForm: SigncoFormGroup;
    existingDeviceDepot: IDeviceDepot;
    ownerId: number;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly deviceDepotApi: DeviceDepotApi) {

        super();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(organizationId: number, callback?: (res: IDeviceDepot) => void) {
        this.ownerId = organizationId;
        this.callback = callback;
        this.existingDeviceDepot = null;

        this.openDialog();
    }

    edit(existingDeviceDepot: IDeviceDepot, callback?: (res: IDeviceDepot) => void) {
        this.callback = callback;
        this.existingDeviceDepot = existingDeviceDepot;
        this.ownerId = existingDeviceDepot.ownerId;

        this.openDialog();
    }

    protected onOpen() {
        this.manageDeviceDepotForm = this.formBuilder.group({
            code: ["", Validators.required],
            description: "",
            ownerId: this.ownerId
        }) as SigncoFormGroup;

        if (this.existingDeviceDepot) {
            this.manageDeviceDepotForm.patchValue(this.existingDeviceDepot);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageDeviceDepotForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageDeviceDepotForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedDeviceDepot: IDeviceDepot) => {
            if (this.existingDeviceDepot) {
                Object.assign(this.existingDeviceDepot, savedDeviceDepot);
            }

            if (this.callback) {
                this.callback(savedDeviceDepot);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingDeviceDepot) {
            const deviceDepotCreator = Object.assign(new DeviceDepotCreator(), this.manageDeviceDepotForm.value);
            this.deviceDepotApi.create$(deviceDepotCreator).subscribe(onSuccess, onError);
        } else {
            const updatedDeviceDepot = Object.assign(new DeviceDepotUpdater(this.existingDeviceDepot), this.manageDeviceDepotForm.value);
            this.deviceDepotApi.update$(updatedDeviceDepot).subscribe(onSuccess, onError);
        }
    }
}