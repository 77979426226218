import { Component, ElementRef, ViewChild } from "@angular/core";
import { MapIconsComponent } from "../map-icons/map-icons.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-admin-map-icons",
    templateUrl: "./admin-map-icons.component.html",
})
export class AdminMapIconsComponent {
    @ViewChild(MapIconsComponent, { static: true }) mapIconsComponent: MapIconsComponent;
    canCreateMeasuringPoint = false;
    constructor(
        elementRef: ElementRef,
        readonly globalEventsService: GlobalEventsService) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
        this.canCreateMeasuringPoint = this.globalEventsService.getCurrentRights().hasBackendRight(BackendRights.EditMeasuringPoint);

    }

    create() {
        this.mapIconsComponent.create();
    }
}
