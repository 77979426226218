<div class="m-page-content-wrapper p-16">
    <app-loading-spinner *ngIf="loading"></app-loading-spinner>

    <form class="m-fixed-height"
          [formGroup]="linkForm"
          (ngSubmit)="onSubmit()"
          [hidden]="loading"
          autocomplete="off"
          novalidate>

        <div class="row m-fixed-height">
            <div class="col-3 m-fixed-height"
                 #column1>
                <div class="m-card m-fixed-height m-layout-w-actions-bottom">
                    <div class="m-layout-area-body m-layout-w-actions-top m-overflow-auto">
                        <div class="m-layout-area-top">
                            <div class="p-16 m-border-bottom">
                                <h3>{{title}}</h3>
                            </div>

                            <div class="p-16">
                                <div class="m-form-group row"
                                     [ngClass]="{ 'has-error': linkForm.get('validFrom').showErrors }">
                                    <label class="col-4"
                                           for="validFrom">
                                        {{"general.from" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-calendar formControlName="validFrom"
                                                    id="validFrom"
                                                    [style]="{'width':'100%'}"
                                                    autoWidth="false"
                                                    [showIcon]="true"
                                                    [showTime]="true"
                                                    [maxDate]="linkForm.get('validUntil')?.value"
                                                    [hourFormat]="calendarSettings.hourFormat"
                                                    [dateFormat]="calendarSettings.dateFormat"
                                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                    [monthNavigator]="false"
                                                    [yearNavigator]="false"
                                                    [yearRange]="primeComponentService.calendarYearRange"
                                                    #validFromInput></p-calendar>

                                        <app-form-validation [control]="linkForm.get('validFrom')"
                                                             [input]="validFromInput">
                                        </app-form-validation>
                                    </div>
                                </div>

                                <div class="m-form-group row"
                                     [ngClass]="{ 'has-error': linkForm.get('validUntil').showErrors }">
                                    <label class="col-4"
                                           for="validUntil">
                                        {{"general.until" | translate}}
                                        <span style="font-size: x-small; margin-top: 5px; margin-left: 2px;">({{"form.optional"
                                            |
                                            translate }})</span>
                                    </label>

                                    <div class="col-8">
                                        <p-calendar formControlName="validUntil"
                                                    id="validUntil"
                                                    [style]="{'width':'100%'}"
                                                    autoWidth="false"
                                                    [showIcon]="true"
                                                    [minDate]="linkForm.get('validFrom')?.value"
                                                    [hourFormat]="calendarSettings.hourFormat"
                                                    [dateFormat]="calendarSettings.dateFormat"
                                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                    [monthNavigator]="false"
                                                    [yearNavigator]="false"
                                                    [yearRange]="primeComponentService.calendarYearRangeFuture"
                                                    [showTime]="true"
                                                    #validUntilInput></p-calendar>

                                        <app-form-validation [control]="linkForm.get('validUntil')"
                                                             [input]="validUntilInput">
                                        </app-form-validation>
                                    </div>
                                </div>

                                <div class="m-form-group row"
                                     *ngIf="showDevice">
                                    <label class="col-4"
                                           for="device">
                                        {{"general.device" | translate}}
                                    </label>
                                    <div class="col-8">
                                        <div class="flex align-center">
                                            <p-autoComplete [(ngModel)]="device"
                                                            [ngModelOptions]="{standalone: true}"
                                                            field="code"
                                                            id="device"
                                                            placeholder="{{'general.code' | translate}}"
                                                            [style]="{'width':'100%'}"
                                                            [inputStyle]="{'width':'100%'}"
                                                            [suggestions]="deviceSuggestions"
                                                            [minLength]="2"
                                                            [forceSelection]="true"
                                                            (onKeyUp)="clearDevice($event)"
                                                            (completeMethod)="queryDevices($event)"
                                                            (onSelect)="handleSelect($event)"
                                                            dataKey="id"
                                                            #selectedDeviceInput>

                                                <ng-template let-device
                                                             pTemplate="item">
                                                    <div class="clearfix"
                                                         style="border-bottom: 1px solid #D5D5D5">
                                                        {{device.code}}
                                                        <br />
                                                        <span style="font-size: x-small; font-style: italic;">
                                                            {{device.description}}
                                                        </span>
                                                    </div>
                                                </ng-template>
                                            </p-autoComplete>

                                            <div class="ml-8">
                                                <app-icon-button [text]="'map.scan' | translate"
                                                                 [tooltipPosition]="'left'"
                                                                 [icon]="'qr-code'"
                                                                 [styleClass]="'m-btn m-btn-primary m-btn-square'"
                                                                 (click)="openScanner()"></app-icon-button>
                                            </div>
                                        </div>

                                        <app-form-validation [control]="linkForm.get('deviceId')"
                                                             [input]="selectedDeviceInput">
                                        </app-form-validation>
                                    </div>
                                </div>

                                <div class="m-form-group row pb-16"
                                     *ngIf="showDepot && linkForm.get('depotId')">

                                    <label class="col-4"
                                           for="deviceDepot">
                                        {{"general.deviceDepot" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-dropdown formControlName="depotId"
                                                    id="depotId"
                                                    [panelStyle]="{'width':'100%'}"
                                                    [options]="deviceDepotOptions"
                                                    placeholder="{{'general.deviceDepot' | translate}}"
                                                    [autoDisplayFirst]="false"
                                                    #deviceDepotInput>
                                        </p-dropdown>

                                        <app-form-validation [control]="linkForm.get('depotId')"
                                                             [input]="deviceDepotInput">
                                        </app-form-validation>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-layout-area-body m-layout-w-actions-bottom">
                            <div class="m-layout-area-body">
                                <div class="m-layout-w-actions-top"
                                     [ngClass]="showReplacingDepot && showMeasuringPoints ? 'm-half-height' : 'm-fixed-height'"
                                     *ngIf="showMeasuringPoints">
                                    <div class="flex align-center px-16 py-8 m-layout-area-top m-border-bottom m-border-top"
                                         *ngIf="showMeasuringPoints && linkForm.get('measurements')">
                                        <div class="mr-16">
                                            <h3>{{"measuringPoints.title" | translate}}</h3>
                                        </div>

                                        <div class="ml-auto"
                                             #addMeasuringPointsIcon>
                                            <app-icon-button icon="add"
                                                             text="sharedKeys.addMeasuringPoints"
                                                             [styleClass]="isMapOpen(0) ? 'm-btn m-btn-square hover' : 'm-btn m-btn-square'"
                                                             [fill]="isInputInError() ? 'red' : ''"
                                                             (click)="toggleAddMeasuringPoints()">
                                            </app-icon-button>
                                        </div>
                                    </div>

                                    <app-select-link-measurements [footer]="false"
                                                                  [scrollHeight]="scrollHeight"
                                                                  [emptyMessage]="false"
                                                                  [editCommand]="!submitting"
                                                                  [deleteCommand]="!submitting"
                                                                  [sendOutput]="!loading"
                                                                  (add)="addMeasurements($event)"
                                                                  (selected)="clearConflicts();"
                                                                  (delete)="deleteMeasurements($event)"
                                                                  class="m-layout-area-body m-layout-default">
                                    </app-select-link-measurements>
                                </div>

                                <div [ngClass]="showReplacingDepot && showMeasuringPoints ? 'm-half-height' : 'm-fixed-height'"
                                     *ngIf="showReplacingDepot">
                                    <div class="p-16">
                                        <h3>{{'manageLink.replaceDepot' | translate:existingLink.device}}</h3>
                                    </div>

                                    <div class="px-16">
                                        <div class="m-form-group row"
                                             *ngIf="hasMultipleOrganizations">

                                            <label class="col-4"
                                                   for="ownerId">
                                                {{"general.organization" | translate}}
                                            </label>

                                            <div class="col-8">
                                                <p-dropdown id="ownerId"
                                                            [panelStyle]="{'width':'100%'}"
                                                            [(ngModel)]="replaceDepotOrganizationId"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (onChange)="onReplaceDepotOrganizationChange()"
                                                            [options]="organizations"
                                                            [filter]="true"
                                                            [resetFilterOnHide]="true"
                                                            [autoDisplayFirst]="false"
                                                            placeholder="{{'general.organization' | translate }}">
                                                </p-dropdown>
                                            </div>
                                        </div>

                                        <div class="m-form-group row"
                                             *ngIf="linkForm.get('replaceDepotId')">

                                            <label class="col-4"
                                                   for="deviceDepot">
                                                {{"general.deviceDepot" | translate}}
                                            </label>

                                            <div class="col-8">
                                                <p-dropdown formControlName="replaceDepotId"
                                                            id="replaceDepotId"
                                                            [panelStyle]="{'width':'100%'}"
                                                            [options]="deviceReplaceDepotOptions"
                                                            placeholder="{{'general.deviceDepot' | translate}}"
                                                            [autoDisplayFirst]="false"
                                                            #replaceDepotIdInput>
                                                </p-dropdown>

                                                <app-form-validation [control]="linkForm.get('replaceDepotId')"
                                                                     [input]="replaceDepotIdInput">
                                                </app-form-validation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-layout-area-bottom">
                                <ng-container *ngIf="!submitting && progress?.hasError">
                                    <div class="p-16">
                                        <div class="alert alert-error">
                                            {{progress.message}}
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="submitting">
                                    <div class="p-16">
                                        <span *ngIf="progress?.isPending">
                                            {{"progress.pending" | translate}}
                                        </span>

                                        <p-progressBar [value]="progress?.progress || 0"></p-progressBar>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
                        <button class="m-btn m-btn-link"
                                [disabled]="submitting"
                                (click)="back()"
                                id="cancel_button"
                                type="button">
                            {{"form.cancel" | translate}}
                        </button>

                        <button class="m-btn m-btn-primary"
                                [disabled]="submitting"
                                type="submit"
                                id="preview_button">
                            <app-loading-spinner *ngIf="submitting">
                            </app-loading-spinner>
                            {{(hasPreviewed ? "form.save" : "general.preview") | translate}}
                        </button>
                    </div>

                </div>
            </div>

            <div class="col-9 m-fixed-height"
                 [hidden]="!setColumnHeights || mapOpen"
                 #columnConfig>

                <div class="m-card m-fixed-height m-layout-w-actions-top"
                     [hidden]="!selectedMeasurement && !deviceLinkConflicts">
                    <div class="m-layout-area-top"
                         *ngIf="measurementFormsWithModel">
                        <div *ngFor="let measurementFormWithModel of measurementFormsWithModel"
                             [hidden]="!selectedMeasurement || selectedMeasurement.measuringPoint.id !== measurementFormWithModel.model.measuringPoint.id">

                            <div class="m-tab-header px-16 m-border-bottom">
                                <div class="m-tab-header-title-text flex align-center">
                                    <span class="m-circle m-circle-primary mr-8">
                                        <app-svg-icon [icon]="measurementFormWithModel.model.analyzerConfiguration.type">
                                        </app-svg-icon>
                                    </span>

                                    {{measurementFormWithModel.model.measuringPoint.code}}
                                </div>

                                <div class="m-btn-group m-btn-group-right">
                                    <button class="m-btn m-btn-secondary"
                                            (click)="copyConfiguration()"
                                            type="button"
                                            id="copy_button">
                                        <app-svg-icon icon="copy">
                                        </app-svg-icon>
                                        Copy
                                    </button>

                                    <button class="m-btn m-btn-secondary"
                                            (click)="pasteConfiguration()"
                                            type="button"
                                            id="paste_button">
                                        <app-svg-icon icon="paste">
                                        </app-svg-icon>
                                        Paste
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-layout-area-body m-overflow-auto">
                        <ng-container *ngIf="measurementFormsWithModel">
                            <div *ngFor="let measurementFormWithModel of measurementFormsWithModel"
                                 [hidden]="!selectedMeasurement || selectedMeasurement.measuringPoint.id !== measurementFormWithModel.model.measuringPoint.id">
                                <app-device-link-detail [measuringPointId]="measurementFormWithModel.model.measuringPoint.id"
                                                        [deviceId]="device?.id"
                                                        [analyzerConfiguration]="measurementFormWithModel.model.analyzerConfiguration"
                                                        [parentFormGroup]="measurementFormWithModel.form.get('analyzerConfiguration')"
                                                        [createNew]="measurementFormWithModel.new"
                                                        (tubesChanged)="updateTubes(measurementFormWithModel, $event)">
                                </app-device-link-detail>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="deviceLinkConflicts && !selectedMeasurement">
                            <ng-template #noConflictsTemplate>
                                <div class="p-16">
                                    {{"manageLink.noConflicts" | translate}}
                                </div>
                            </ng-template>

                            <ng-container *ngIf="deviceLinkConflicts.conflicts.length; else noConflictsTemplate">
                                <ng-container *ngFor="let conflict of deviceLinkConflicts.conflicts; trackBy: trackByFn; let i = index">
                                    <div class="flex p-16"
                                         [ngClass]="i > 0 ? 'm-border-top' : ''">
                                        <div class="mr-8">
                                            <app-svg-icon icon="info">
                                            </app-svg-icon>
                                        </div>

                                        <div style="width: 100%;">
                                            <h3 class="mb-8">
                                                <ng-container *ngIf="conflict.changed">
                                                    {{'manageLink.existingLinkWillBeChanged' | translate}}
                                                </ng-container>

                                                <ng-container *ngIf="!conflict.changed">
                                                    {{'manageLink.existingLinkWillBeRemoved' | translate}}
                                                </ng-container>
                                            </h3>

                                            <table style="width: 100%; max-width: 640px; border-spacing: 0; border-collapse: collapse;">
                                                <tr>
                                                    <td style="width: 50%;">
                                                        {{"general.from" | translate}}:
                                                        {{conflict.original.validFrom | date:'dd/MM/yyyy HH:mm'}}
                                                    </td>

                                                    <td style="width: 50%;">
                                                        <span *ngIf="conflict.showChangedFrom"
                                                              class="warning">
                                                            {{"manageLink.newFrom" | translate}}:
                                                            {{conflict.changed.from | momentDateTime:true:false:translateService.currentLang}}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style="width: 50%;">
                                                        {{"general.until" | translate}}:
                                                        <ng-container *ngIf="conflict.original.validUntil">
                                                            {{conflict.original.validUntil | momentDateTime:true:false:translateService.currentLang}}
                                                        </ng-container>

                                                        <ng-container *ngIf="!conflict.original.validUntil">
                                                            -
                                                        </ng-container>
                                                    </td>

                                                    <td style="width: 50%;">
                                                        <span *ngIf="conflict.showChangedUntil"
                                                              class="warning">
                                                            {{"manageLink.newUntil" | translate}}:
                                                            <ng-container *ngIf="conflict.changed.until">
                                                                {{conflict.changed.until | momentDateTime:true:false:translateService.currentLang}}
                                                            </ng-container>

                                                            <ng-container *ngIf="!conflict.changed.until">
                                                                -
                                                            </ng-container>
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colspan="2">
                                                        {{"general.device" | translate}}:
                                                        <app-navigator [device]="conflict.original.device"
                                                                       [newTab]="true"
                                                                       [ngStyle]="{'display': 'initial'}">
                                                        </app-navigator>

                                                        <ng-container *ngIf="conflict.original.depot">
                                                            <div class="flex align-center mt-8">
                                                                <div class="mr-16">
                                                                    <app-svg-icon icon="depot"></app-svg-icon>
                                                                </div>

                                                                <div>
                                                                    {{conflict.original.depot.code}}
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="conflict.original.measurements">
                                                            <div class="flex align-center mt-8"
                                                                 *ngFor="let measurement of conflict.original.measurements">
                                                                <div class="mr-8">
                                                                    <app-svg-icon [icon]="measurement.analyzerConfiguration.type">
                                                                    </app-svg-icon>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <app-navigator [measuringPoint]="measurement.measuringPoint">
                                                                        </app-navigator>
                                                                    </div>

                                                                    <div class="col-6">
                                                                        {{measurement.tubes.join(" - ")}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                    </div>
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="mapOpen"
                 class="col-9 m-fixed-height">
                <div class="m-card m-layout-w-actions-top m-fixed-height">
                    <div class="m-layout-area-top flex justify-end">
                        <app-icon-button icon="close"
                                         [styleClass]="'m-btn m-btn-square m-btn-close m-btn-close-top-right'"
                                         (click)="closeMap()"></app-icon-button>
                    </div>

                    <!-- <div class="clip-polygon" [ngStyle]="{'margin-top': mapPolygonOffset + 'px'}"></div> -->

                    <app-map-selector class="m-layout-area-body m-layout-default"
                                      [mapDetail]="mapDetail"
                                      [componentHeight]="mapHeight"
                                      (detailComponentSet)="handleMapComponentLoad()">
                    </app-map-selector>
                </div>
            </div>
        </div>
    </form>
</div>
<app-scanner-dialog></app-scanner-dialog>