<div class="m-page-content-wrapper">
    <div class="landing-page">
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

        <div *ngIf="!isLoading"
             class="landing-page__wrapper">

            @if(websiteService.isBerghausConnect()) {
            <img class="landing-page__logo"
                 src="/assets/img/berghaus_logo.svg"
                 alt="Berghaus" />
            }
            @else {
            <img class="landing-page__logo"
                 src="/assets/img/fero_logo.png"
                 alt="Fero" />

            <img class="landing-page__logo"
                 src="/assets/img/signco_logo.png"
                 alt="Signco" />
            }

            <a class="m-btn m-btn-primary landing-page__button"
               (click)="
               login()">{{"general.login" | translate}}</a>
        </div>
    </div>
</div>