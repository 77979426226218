import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { MeasuringPointRealtimeService } from "src/app/services/realtime/measuring-point-realtime.service";
import { AngularDraggableDirective } from "angular2-draggable";
import { CameraControlsComponent } from "../../../shared/components/camera-controls/camera-controls.component";
import { AdvancedMapComponent } from "src/app/modules/map-advanced/components/advanced-map/advanced-map.component";
import { TranslateService } from "@ngx-translate/core";
import { ScreenshotService } from "src/app/services/screenshot.service";
import { Options } from "html2canvas";
import { ILiveData } from "src/app/models/pinned-data";
import { IDataChangedArguments } from "src/app/models/data-changed-arguments";
import { MeasuredDataPinnedDataComponent } from "../measured-data-pinned-data/measured-data-pinned-data.component";
import { PinnedDataService } from "src/app/services/pinned-data.service";
import { MeasuringPointPinnedLiveDataSearchParameters } from "src/app/resource/web";
import { LiveTileViewModel, LiveTilesService, Position } from "../../services/live-tiles.service";
import { Tooltip } from "primeng/tooltip";
import { AnalysisType } from "src/app/models/measuring-point";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";
import { MapUtils } from "src/app/utilities";

@Component({
    selector: "app-live-tile",
    templateUrl: "./live-tile.component.html"
})
export class LiveTileComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChildren(Tooltip) tooltips: QueryList<Tooltip>;
    @ViewChild(AngularDraggableDirective, { static: true }) draggable: AngularDraggableDirective;

    cameraControlsComponent: CameraControlsComponent;
    @ViewChild(CameraControlsComponent, { static: false }) set setCameraControlsComponent(cameraControlsComponent: CameraControlsComponent) {
        this.cameraControlsComponent = cameraControlsComponent;

        setTimeout(() => {
            this.cd.detectChanges();
        }, 1000);
    }

    @ViewChild("headerElement", { static: true }) headerElement: ElementRef<HTMLDivElement>;
    @ViewChild("pinDivElement", { static: true }) divElement: ElementRef<HTMLDivElement>;
    @ViewChild("dialogContentDiv", { static: false }) dialogContentDiv: ElementRef<HTMLDivElement>;
    @ViewChild("measuredData", { static: false }) measuredDataPinnedDataComponent: MeasuredDataPinnedDataComponent;
    @ViewChild("titleElement", { static: false }) titleElement: ElementRef<HTMLDivElement>;

    @Input() gmap: AdvancedMapComponent;
    @Input() viewModel: LiveTileViewModel;

    ngStyle: { [key: string]: string };
    positionModified: boolean;

    overlay: google.maps.OverlayView;
    overlayLine: google.maps.Polyline;

    liveData: ILiveData;
    showCamera = false;
    isCctv = false;
    hasCctvRights = false;
    shortTitle = "...";

    private realtimeKey: string;
    private resizeObserver: ResizeObserver;

    rights: Rights;
    loading = false;


    get showRefreshCameraImage(): boolean {
        return this.showCamera && !this.cameraControlsComponent?.livestream && !this.cameraControlsComponent?.galleryImage && !this.cameraControlsComponent?.info?.isDekimoCctv;
    }

    constructor(
        public readonly translateService: TranslateService, // Used by the template
        public readonly pinnedDataService: PinnedDataService, // Used by the template
        private readonly cd: ChangeDetectorRef,
        private readonly zone: NgZone,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly liveTilesService: LiveTilesService,
        private readonly measuringPointRealtimeService: MeasuringPointRealtimeService,
        private readonly screenshotService: ScreenshotService,
        private readonly globalEventsService: GlobalEventsService
    ) {
    }

    ngOnInit() {
        this.liveTilesService.addComponent(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["viewModel"];
        if (measuringPointChange) {
            this.isCctv = this.viewModel.measuringPoint.analysisTypeId === AnalysisType.Cctv;
            this.hasCctvRights = this.globalEventsService.getCurrentRights()?.hasBackendRight(BackendRights.ViewCctv);
            this.showCamera = this.isCctv && this.hasCctvRights;
            this.initialLoad();
            this.initializeTile();

            if (!this.pinnedDataService.isHistoricalDataAvailable(this.viewModel?.pinnedDataConfiguration)) {
                // there is no historical data for this type of mp so fetch only live data which will return only currentData what is needed

                // load only current live data
                this.loading = true;
                this.loadLiveData(false);
            }

            this.createShortTitle();
        }

        const gmapChange = changes["gmap"];
        if (gmapChange) {
            this.initializeTile();
        }
    }

    ngAfterViewInit() {
        this.liveTilesService.recalculateFloatingPositions();
        this.createShortTitle();

        this.resizeObserver = new ResizeObserver(entries => {
            this.createShortTitle();
        });

        // Start observing the element for size changes
        this.resizeObserver.observe(this.headerElement.nativeElement);
    }

    ngOnDestroy() {
        this.close(false);
        this.liveTilesService.removeComponent(this);
        this.disconnectRealtime();

        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.elementRef.nativeElement);
            this.resizeObserver.disconnect();
        }
    }

    private connectRealtime() {
        this.disconnectRealtime();

        const onDataUpdate = (data: IDataChangedArguments) => {
            if (this.pinnedDataService.isHistoricalDataAvailable(this.viewModel?.pinnedDataConfiguration)) {
                this.measuredDataPinnedDataComponent.onDataChanged();
            } else {
                this.loadLiveData(false); // don't use cache cause new data is coming..
            }
        };

        this.measuringPointRealtimeService.subscribeToDataUpdate(this.realtimeKey, this.viewModel.measuringPoint.id, onDataUpdate);
    }


    private loadLiveData(useCache: boolean) {
        this.pinnedDataService.loadLiveData$(this.viewModel.measuringPoint.id, { onlyCurrentValue: true } as MeasuringPointPinnedLiveDataSearchParameters, useCache)
            .subscribe({
                next: (liveData) => {
                    this.liveData = liveData;
                    this.loading = false;
                },
                error: (error) => {
                    this.loading = false;
                }
            });
    }

    private disconnectRealtime() {
        if (!this.realtimeKey) return;

        this.measuringPointRealtimeService.unsubscribe(this.realtimeKey, this.viewModel.measuringPoint.id);
    }

    stop(event: Event) {
        if (!event) return;

        event.stop();
    }

    onDragStop() {
        if (!this.draggable) return;

        const curPosition = this.draggable.getCurrentOffset();
        if (!curPosition) return;

        this.positionModified = curPosition.x !== this.draggable.position.x ||
            curPosition.y !== this.draggable.position.y;

        if (this.viewModel.displayOptions.position.x != curPosition.x || this.viewModel.displayOptions.position.y != curPosition.y) {
            this.viewModel.displayOptions.position = new Position(curPosition.x, curPosition.y);
            this.liveTilesService.saveToLocalStorage();
        }
    }

    resetPosition() {
        this.positionModified = false;
        this.viewModel.displayOptions.position = new Position(0, 0);

        try {
            this.draggable.resetPosition();
        } catch (e) {
            // ignored
        }

        this.liveTilesService.recalculateFloatingPositions();
        this.cd.detectChanges();

        if (this.overlayLine) {
            this.overlayLine.setOptions({
                path: this.getPathForLine()
            });
        }
    }

    private async initialLoad() {
        if (!this.viewModel || !this.viewModel.pinnedDataConfiguration) {
            this.clear();
            return;
        }

        this.realtimeKey = `measuring-point-status${this.viewModel.measuringPoint.id}`;

        this.connectRealtime();
    }

    private clear() {
        this.viewModel = null;
        this.showCamera = false;
        this.disconnectRealtime();
    }

    refreshCameraImage() {
        if (!this.showCamera || !this.cameraControlsComponent) return;

        this.cameraControlsComponent.refreshCameraImage();
    }

    takeScreenshot() {
        if (!this.dialogContentDiv?.nativeElement || !this.viewModel) {
            return;
        }

        const incrementSizeFor = 5;

        // not good aproach but probably html2canvas doesn't support all of our styles so created image doesn't look 100% as it should
        // with this function we're trying to make screenshot to look as much prettier as possible
        // it is chained to DOM and to classes so if something changes in DOM associated to this
        // this function should be changed as well...
        const onCloneFunction = (document: Document, element: HTMLElement) => {
            const dataSetNameDivs = element.getElementsByClassName("last-value-datasetname-div");
            const lastValuesDivs = element.getElementsByClassName("last-value-div");

            for (let index = 0; index < dataSetNameDivs.length; index++) {
                const div = dataSetNameDivs.item(index) as HTMLDivElement;
                div.style.paddingBottom = "12px";
            }

            for (let index = 0; index < lastValuesDivs.length; index++) {
                const div = lastValuesDivs.item(index) as HTMLDivElement;
                div.style.paddingBottom = "20px";
            }
        };

        const maxRowsPerDataSet = this.liveData?.lastValuesGroups.map(x => x.lastValues.length).max();

        const options = {
            allowTaint: true,
            height: this.dialogContentDiv.nativeElement.clientHeight + maxRowsPerDataSet * incrementSizeFor,
            onclone: onCloneFunction
        } as Partial<Options>;

        this.screenshotService.takeScreenshotAndDownloadImage(this.dialogContentDiv.nativeElement, `${this.viewModel.measuringPoint.code}.png`, options);
    }

    private initializeTile(): void {
        if (this.viewModel.displayOptions.overlay === true) {
            this.toOverlay();
        } else {
            this.toFloating();
        }
    }

    toOverlay() {
        this.closeTooltipForMpCodeIfPresent();
        if (this.overlay || !this.gmap || !this.gmap.map) return;
        // if gmap is not defined yet, we will be notified again in by the mapReady event. This code will be executed again.

        if (this.viewModel.displayOptions.overlay !== true) {
            this.resetPosition();
            this.viewModel.displayOptions.overlay = true;
            this.liveTilesService.saveToLocalStorage();
        }

        this.overlay = new google.maps.OverlayView();
        this.overlay.onAdd = () => {

            if (this.viewModel.displayOptions.position.x === 0 && this.viewModel.displayOptions.position.y === 0) {
                // When opening new tiles on the same location, we waht to prevent that multiple overlays end up on the same spot.
                let existingOnSameLocation = 0;
                for (const tile of this.liveTilesService.getLiveTileViewModels()) {
                    if (tile.measuringPoint.id === this.viewModel.measuringPoint.id) continue;
                    if (tile.measuringPoint.locationId == this.viewModel.measuringPoint.locationId) existingOnSameLocation++;
                }

                if (existingOnSameLocation !== 0) {
                    this.viewModel.displayOptions.position = new Position(existingOnSameLocation * 25, existingOnSameLocation * 25);
                }
            }

            const pane = this.overlay.getPanes().floatPane;
            pane.appendChild(this.divElement.nativeElement);
            this.cd.detectChanges();
        };

        this.overlay.draw = () => {
            this.zone.run(() => {
                this.updateStyle();
            });

            setTimeout(() => {
                // only first time to create overlay for line
                if (!this.overlayLine) {
                    this.overlayLine = new google.maps.Polyline({
                        path: this.getPathForLine(),
                        geodesic: true,
                        strokeColor: "#000000",
                        strokeOpacity: 1.0,
                        strokeWeight: 2
                    });

                    this.overlayLine.setMap(this.gmap.map);
                } else {
                    // otherwise only update path for line
                    this.overlayLine.setOptions({
                        path: this.getPathForLine()
                    });
                }
            });
        };

        this.overlay.onRemove = () => {
            this.closeOverlays();
        };

        this.overlay.setMap(this.gmap.map);
    }

    toFloating() {
        this.closeTooltipForMpCodeIfPresent();
        if (this.viewModel.displayOptions.overlay !== false) {
            this.resetPosition();
            this.viewModel.displayOptions.overlay = false;
            this.liveTilesService.saveToLocalStorage();
        }

        this.closeOverlays();
        this.elementRef.nativeElement.appendChild(this.divElement.nativeElement);
        this.liveTilesService.recalculateFloatingPositions();
    }

    private getPathForLine(): { lat: number, lng: number }[] {
        if (!this.overlay || !this.divElement || !this.gmap || !this.viewModel) {
            return;
        }

        const projection = this.overlay.getProjection();
        const boundingRectOfDialog = this.divElement.nativeElement.getBoundingClientRect();
        const boudingRectOfMap = this.gmap.map.getDiv().getBoundingClientRect();

        const latLngTopLeft = projection.fromContainerPixelToLatLng({
            x: boundingRectOfDialog.x + this.divElement.nativeElement.clientWidth / 2,
            y: boundingRectOfDialog.y - boudingRectOfMap.y + this.divElement.nativeElement.clientHeight / 2
        } as google.maps.Point);

        const path = [
            { lat: this.viewModel.measuringPoint.location.lat, lng: this.viewModel.measuringPoint.location.lng },
            { lat: latLngTopLeft.lat(), lng: latLngTopLeft.lng() }
        ];

        return path;
    }

    private closeTooltipForMpCodeIfPresent() {
        // the reason why we're doing this in timeout
        // is because it happens that tooltip is immediately removed while live-tile didn't change its position yet
        // so if user in that delta time moves cursor, tooltip will appear again
        // with timeout we are sure that tooltip will be removed at point when live-tile already has changed its position
        setTimeout(() => {
            if (!this.viewModel?.measuringPoint?.code) {
                return;
            }

            const tooltips = this.tooltips?.toArray();
            if (!tooltips) {
                return;
            }

            if (tooltips.find(x => x.tooltipText === this.viewModel.measuringPoint.code)) {
                tooltips.find(x => x.tooltipText === this.viewModel.measuringPoint.code).hide();
            }
        }, 100);
    }

    private updateStyle() {
        const overlayProjection = this.overlay.getProjection();
        if (!overlayProjection) return;

        const latLng = MapUtils.toLatLng(this.viewModel.measuringPoint.location);
        const markerLocationInPx = overlayProjection.fromLatLngToDivPixel(latLng);

        this.ngStyle = {
            left: markerLocationInPx.x + "px",
            top: markerLocationInPx.y + "px",
            zIndex: "99999999",
            marginTop: "0px",
            marginLeft: "20px"
        };
    }

    closeOverlays() {
        if (this.divElement.nativeElement.parentNode) {
            this.divElement.nativeElement.parentNode.removeChild(this.divElement.nativeElement);
        }

        this.ngStyle = null;

        if (this.overlay) {
            this.overlay.setMap(null);
            this.overlay = null;
        }

        if (this.overlayLine) {
            this.overlayLine.setMap(null);
            this.overlayLine = null;
        }
    }

    close(removeFromSelectionService = true) {
        this.closeOverlays();

        // Needed for killing the stream
        // Can't do it in ngOnDestroy
        // Because there the img gets removed before it can update the livestream url
        // Which will keep the stream in memory
        if (this.cameraControlsComponent) {
            this.cameraControlsComponent.livestreamUrl = "";
            this.cd.detectChanges();
        }

        if (this.viewModel && removeFromSelectionService) {
            this.liveTilesService.removeLiveTileId(this.viewModel.measuringPoint.id);
        }
    }

    private redrawOverlayLine(): void {
        // If the size or position of the live tile has changed, we redraw the line on the map
        if (this.overlayLine) {
            this.overlayLine.setOptions({
                path: this.getPathForLine()
            });
        }
    }

    onMove() {
        this.redrawOverlayLine();
    }

    onResize() {
        this.redrawOverlayLine();
        this.createShortTitle();
    }

    toggleExpand(): void {
        this.viewModel.displayOptions.isExpanded = !this.viewModel.displayOptions.isExpanded;
        setTimeout(() => {
            this.liveTilesService.saveToLocalStorage();
            this.redrawOverlayLine();
            this.createShortTitle();
        }, 100);
        this.cd.detectChanges();
    }

    onLiveDataFetched(liveData: ILiveData) {
        this.liveData = liveData;
    }


    private debounceTimer: ReturnType<typeof setTimeout> | null = null;

    debounce(func: () => void, delay: number): void {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        this.debounceTimer = setTimeout(() => {
            func();
            this.debounceTimer = null;
        }, delay);
    }

    private createShortTitle() {
        // We wait a bit, we first let the browser do its layout so we can get the final available width
        this.debounce(() => {
            if (!this.titleElement) return;
            if (this.viewModel.displayOptions.isExpanded) {
                // The full title is displayed, no need to calculate a short title
                // this.shortTitle = "...";
                return;
            }

            // This is a very crude way to guess how many characters will fit in the available space.
            const width = this.titleElement.nativeElement.offsetWidth;
            const maxChars = Math.floor(width / 5);

            this.shortTitle = this.shorten(this.viewModel.measuringPoint.code, maxChars);
        }, 100);
    }

    private shorten(input: string, maxLength: number): string {
        if (input.length <= maxLength) return input;
        if (maxLength <= 5) return "...";

        const halfLength = Math.floor((maxLength - 3) / 2);
        const firstPart = input.slice(0, halfLength);
        const secondPart = input.slice(-halfLength);

        const result = `${firstPart}...${secondPart}`;
        return result;
    }
}
