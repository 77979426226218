import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration } from "src/app/models/upload";

@Directive()
export abstract class DeviceLinkFormGroupComponent implements OnChanges {
    @Input() enabled: boolean;
    @Input() analyzerConfiguration: AnalyzerConfiguration;

    private configureSubject = new Subject<void>();
    public get configure$() {
        return this.configureSubject.asObservable();
    }

    form: SigncoFormGroup;
    formVisible = false;

    constructor() {
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const analyzerConfigurationChange = changes["analyzerConfiguration"];

        if (analyzerConfigurationChange) {
            await this.configure(this.analyzerConfiguration);
            this.configureSubject.next();
        }

        const enabledChange = changes["enabled"];
        if (enabledChange && this.form) {
            if (this.enabled) {
                this.form.enable();
            } else {
                this.form.disable();
            }
        }
    }

    protected async configure(analyzerConfiguration: AnalyzerConfiguration) {
        this.formVisible = await this.configureForm(analyzerConfiguration);

        if (!this.formVisible) return;

        if (this.enabled) {
            this.form.enable();
        } else {
            this.form.disable();
        }
    }

    abstract configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean>;
}