import { Component, ElementRef, inject, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants/constants";
import { AlertLevel, AlertType } from "src/app/models/alert";
import { TrackerData } from "src/app/models/avs/tracker-data";
import { ILocationStatusSummary, IMeasuringPointStatusSummary } from "src/app/models/web";
import { IAlertSummary } from "src/app/models/web/alert-summary";
import { TrackerDataAvsApi } from "src/app/resource/avs/tracker-data-avs.api";
import { ColorUtils } from "src/app/utilities";

@Component({
    selector: "app-alert-badge",
    templateUrl: "./alert-badge.component.html",
    styleUrls: ["./alert-badge.component.scss", "../../../location-popup.component.scss"],
})
export class AlertBadgeComponent {
    @Input() data: IAlertSummary;
    @Input() locationStatusSummary: ILocationStatusSummary;
    @Input() measuringPointStatusSummary: IMeasuringPointStatusSummary;
    @ViewChild("popup") protected popup: ElementRef;

    protected readonly translateService = inject(TranslateService);
    protected readonly trackerDataAvsApi = inject(TrackerDataAvsApi);

    protected getBadgeStyle(): any {
        if (!this.data) return;
        const backgroundColor = Constants.getAlertColor(this.data.level);
        const foregroundColor = ColorUtils.getMarkerTextColorHex(backgroundColor);

        return {
            backgroundColor: backgroundColor,
            color: foregroundColor
        };
    }

    protected closePopup() {
        this.popup.nativeElement.open = false;
    }

    protected getDescription(): string {
        switch (this.data.type) {
            case AlertType.batteryStatus:
                return this.getDescriptionForBattery();
            case AlertType.noNewStatusUpdate:
                return this.getDescriptionForNoNewStatusUpdate();
            default:
                return this.translateService.instant("alertBadge." + this.data.type + ".description");
        }
    }

    private getDescriptionForBattery() {
        const key = `alertBadge.batteryStatus.description${this.data.configuration.isPercentage ? "Percentage" : "Voltage"}`;

        const threshold = this.getBatteryTreshold();

        const result = this.translateService.instant(key, { value: threshold.toFixed(1) });
        return result;
    }

    private getBatteryTreshold() {
        switch (this.data.level) {
            case AlertLevel.error: return this.data.configuration.thresholdError;
            case AlertLevel.warning: return this.data.configuration.thresholdWarning;
            case AlertLevel.info: return this.data.configuration.thresholdInfo;
            default: return null;
        }
    }

    private getDescriptionForNoNewStatusUpdate() {
        const key = `alertBadge.noNewStatusUpdate.description`;

        const threshold = this.getNoNewStatusTreshold();

        const result = this.translateService.instant(key, { value: threshold?.toFixed(0) ?? 0 });
        return result;
    }

    private getNoNewStatusTreshold() {
        switch (this.data.level) {
            case AlertLevel.error: return this.data.configuration.thresholdHoursUntilLastStatusError;
            case AlertLevel.warning: return this.data.configuration.thresholdHoursUntilLastStatusWarning;
            case AlertLevel.info: return this.data.configuration.thresholdHoursUntilLastStatusInfo;
            default: return null;
        }
    }

    // TODO - This was for demo purposes
    protected deleteAlert() {
        if (!this.data || !this.measuringPointStatusSummary || !this.locationStatusSummary) return;

        const clearTrackerData = new TrackerData();
        clearTrackerData.imei = this.locationStatusSummary.devices.find(x => x.imei)?.imei;

        if (this.locationStatusSummary?.location != null) {
            clearTrackerData.latitude = this.locationStatusSummary.location.coordinate.latitude;
            clearTrackerData.longitude = this.locationStatusSummary.location.coordinate.longitude;
        }

        if (this.data.type == AlertType.impactDetected) {
            clearTrackerData.impact = false; // If impact, no more impact
        }

        if (this.data.type == AlertType.batteryStatus) {
            clearTrackerData.batteryVoltage = 14; // If no battery voltage, all the voltages
        }

        const onSuccess = () => {
        };
        const onError = () => { };

        this.trackerDataAvsApi.create$(clearTrackerData).subscribe({ complete: onSuccess, error: onError });

        // Don't wait for reply, delete
        this.measuringPointStatusSummary.alerts = this.measuringPointStatusSummary.alerts.remove(this.data);
    }
}