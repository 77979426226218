<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{width: 'auto'}">

    <p-header>
        {{"liveTiles.externalInput.title" | translate}}
    </p-header>

    <form *ngIf="manualExternalInputForm"
          class="p-16"
          [formGroup]="manualExternalInputForm"
          autocomplete="off"
          novalidate>

        <div class="mb-8"
             style="min-width:300px; width:min-content;"
             *ngIf="externalInput?.type !== 'manual'">
            <app-svg-icon icon="alert"></app-svg-icon>{{"liveTiles.externalInput.overrideWarning" | translate}}
        </div>

        <ng-container *ngIf="externalInputForms"
                      formGroupName="externalInputs">
            <table>
                <tr *ngFor="let externalInputForm of externalInputForms.controls; let externalInputIndex = index"
                    formGroupName="{{externalInputIndex}}">

                    <td>
                        <p-inputSwitch formControlName="value"></p-inputSwitch>
                    </td>
                    <td>
                        <label class="ml-8">{{externalInputForm.get('externalInputDescription').value}}</label>
                    </td>
                    <td>
                        <ng-container *ngIf="(externalInputForm.get('value').value === true && externalInput.inputs[externalInputIndex].trueIconPreview) || (externalInputForm.get('value').value === false && externalInput.inputs[externalInputIndex].falseIconPreview); else defaultPreview">
                            <img [attr.src]="externalInputForm.get('value').value === true ? (externalInput.inputs[externalInputIndex].trueIconPreview | safeHtml) : (externalInput.inputs[externalInputIndex].falseIconPreview | safeHtml)"
                                 style="width:25px;height:25px;"
                                 class="ml-8" />
                        </ng-container>
                        <ng-template #defaultPreview>
                            <strong *ngIf="externalInputForm.get('value').value === true"
                                    class="ml-8">{{"form.yes" | translate | uppercase}}</strong>
                            <strong *ngIf="externalInputForm.get('value').value === false"
                                    class="ml-8">{{"form.no" | translate | uppercase}}</strong>
                        </ng-template>
                    </td>
                </tr>
            </table>
        </ng-container>

    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancel_button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="save_button">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.ok" | translate}}
        </button>
    </p-footer>
</p-dialog>