<div class="search-result__item"
     [ngClass]="{'search-result--selected': viewModel.isSelected}"
     style="display:grid; grid-template-columns: 30px 1fr; gap:16px; align-items: center;">
    <img [src]="getIconUrl()" />
    <div>
        <h4>{{viewModel.location.code}}</h4>
        <small>{{viewModel.location.address | address}}</small>

        <div class="row"
             style="margin:0">
            <ng-container *ngIf="viewModel.location.analysisTypes">
                <app-svg-icon *ngFor="let analysisType of viewModel.location.analysisTypes"
                              [icon]="analysisType"
                              [tooltip]="analysisType | translateEnum:'analysisType'"
                              [divStyle]="{width: '20px', height: '20px'}"
                              fill="#555"
                              style="margin-right: 10px;">
                </app-svg-icon>
            </ng-container>
        </div>

        <ng-container *ngIf="viewModel.location.projects">
            <app-navigator *ngFor="let project of viewModel.location.projects"
                           [project]="project"
                           [enabled]="false"
                           [showIcon]="true"></app-navigator>
        </ng-container>
    </div>
</div>