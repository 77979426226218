import { Pipe, PipeTransform } from "@angular/core";
import { ICoordinate } from "src/app/models/location";

@Pipe({
    name: "coordinate"
})
export class CoordinatePipe implements PipeTransform {

    transform(coordinate: ICoordinate): string {
        if (!coordinate) return null;

        return `(${coordinate.latitude?.toFixed(6)}, ${coordinate.longitude?.toFixed(6)})`;
    }
}
