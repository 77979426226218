import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { MeasuringPointStatusApi } from "src/app/resource/measuring-point-status.api";
import { IMeasuringPointStatus } from "src/app/models/measuring-point-status";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { ToastService } from "src/app/services/toast.service";
import { UntypedFormBuilder } from "@angular/forms";
import { Component } from "@angular/core";
import { IExternalInputPinnedData, InputData } from "src/app/models/pinned-data";

@Component({
    selector: "app-external-input-manage-dialog",
    templateUrl: "./external-input-manage-dialog.component.html"
})
export class ExternalInputManageDialogComponent extends DialogComponentBase {

    submitting: boolean;
    measuringPointId: number;
    externalInput: IExternalInputPinnedData;

    callback: () => void;
    manualExternalInputForm: SigncoFormGroup;
    externalInputForms: SigncoFormArray;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly measuringPointStatusApi: MeasuringPointStatusApi) {

        super();
    }

    open(measuringPointId: number, externalInput: IExternalInputPinnedData, callback?: () => void) {
        this.callback = callback;
        this.measuringPointId = measuringPointId;
        this.externalInput = externalInput;

        this.openDialog();
    }

    protected onOpen() {
        this.externalInputForms = this.formBuilder.array([]) as SigncoFormArray;

        this.manualExternalInputForm = this.formBuilder.group({
            externalInputs: this.externalInputForms
        }) as SigncoFormGroup;

        for (const input of this.externalInput.inputs) {
            const inputFormGroup = this.formBuilder.group({
                externalInputId: input.id,
                externalInputDescription: input.description,
                value: input.value ?? false
            }) as SigncoFormGroup;

            this.externalInputForms.push(inputFormGroup);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manualExternalInputForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manualExternalInputForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = async (_: IMeasuringPointStatus) => {
            if (this.callback) {
                this.callback();
            }

            this.toastService.saveSuccess();
            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const status = {
            measuringPointId: this.measuringPointId,
            timestamp: new Date(),
            data: this.manualExternalInputForm.value
        } as IMeasuringPointStatus;

        this.measuringPointStatusApi.create$(status, { measuringPointId: this.measuringPointId.toString() }).subscribe(onSuccess, onError);
    }
}