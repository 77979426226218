import { Component, ElementRef, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LazyLoadEvent } from "primeng/api";
import { INotification, NotificationCategory } from "src/app/models/notification";
import { FilterOperator, SearchParameters } from "src/app/models/search";
import { FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { NotificationApi } from "src/app/resource/notification.api";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html"
})
export class NotificationsComponent implements OnInit {

    totalRecords: number;
    NotificationCategory = NotificationCategory;
    rowCount = 10;
    notifications: INotification[] = [];
    constructor(
        elementRef: ElementRef,
        readonly notificationApi: NotificationApi,
        readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService) {

    }

    ngOnInit() {

    }

    onLazyLoad(event: LazyLoadEvent) {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        this.notificationApi.search$(searchParameters, null, false, null, event).subscribe(searchResult => {
            // fill parsedQueryParameters
            searchResult.data.forEach((notification) => {
                try {
                    notification.message.parsedQueryParameters = JSON.parse(notification.message.queryParameters);
                } catch {
                    notification.message.parsedQueryParameters = {};
                }
            });
            this.notifications = searchResult.data;
            this.totalRecords = searchResult.total;
        });

    }


}