<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="{width: 'auto'}">

    <p-header>
        {{"liveTiles.light.dialogTitle" | translate}}
    </p-header>

    <div class="m-mode-select p-16">
        <ng-container *ngFor="let vm of viewModels">
            <div class="m-mode-select-tile p-8">
                <h3 style="text-align:center;">{{vm.mode.id}}: {{vm.mode.description}}</h3>
                <div style="display:grid; justify-content: center;">
                    <img *ngIf="vm.iconPreview"
                         [attr.src]="vm.iconPreview | safeHtml"
                         style="max-width:25px;max-height:25px;" />
                </div>
                <div class="m-mode-select-tile-content">
                    <button class="m-mode-select-button m-btn m-btn-secondary m-btn-100"
                            (click)="onSelect(vm)"
                            [disabled]="isSaving">
                        {{"form.select" | translate}}
                        <app-svg-icon icon="circle-check"
                                      class="ml-8"></app-svg-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="isSaving"
         style="position: absolute; top:0; bottom:0; left:0; right:0; display: grid; justify-content: center; align-content: center; background-color: #0006;">
        <div>
            <app-loading-spinner></app-loading-spinner>
        </div>
    </div>
</p-dialog>