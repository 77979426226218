<div class="m-map m-map-teaser m-layout-area-top">
    <app-basic-map [showMapTypeSelector]="true"
                   (mapReady)="handleMapReady($event)"></app-basic-map>
</div>

<ng-container *ngIf="location">
    <div class="m-layout-area-body m-overflow-auto p-16 m-border-top">
        <div class="flex align-center m-layout-area-top"
             style="gap:8px;">
            <img *ngIf="mapIcon"
                 style="max-width:20px;"
                 [src]="mapIcon.default.url" />
            <h3>
                {{location.code}}
            </h3>

            <div class="m-btn-group ml-auto">
                <app-icon-button text="manageLocation.edit"
                                 icon="edit"
                                 (click)="editLocation()"
                                 [style]="{'min-height': '24px'}"
                                 *ngIf="editCommand">
                </app-icon-button>

                <app-icon-button text="manageLocation.delete"
                                 icon="delete"
                                 (click)="deleteLocation()"
                                 [style]="{'min-height': '24px'}"
                                 *ngIf="editCommand">
                </app-icon-button>
            </div>
        </div>

        <small *ngIf="location.description">{{location.description}}</small>

        <div>
            <small>
                <ng-container>{{locationFormatted}}&#32;</ng-container>
                <span style="white-space: nowrap; color: darkgray;">
                    ({{location.coordinate.latitude | number:'1.0-6'}}, {{location.coordinate.longitude | number:'1.0-6'}})
                </span>
            </small>
        </div>

        <dl class="m-dl-hor">
            <ng-container *ngIf="assignment">
                <dt>
                    {{"general.project" | translate}}
                </dt>
                <dd *ngIf="assignment.parentAssignmentId">
                    <app-navigator [project]="assignment.parentAssignment.project"
                                   class="mr-16"></app-navigator>
                </dd>
                <dd *ngIf="!assignment.parentAssignmentId">
                    <app-navigator [project]="assignment.project"
                                   class="mr-16"></app-navigator>
                </dd>
            </ng-container>
            <ng-container *ngIf="showOwner && owner && (canCreateMeasuringPointForOthers || location.ownerId !== authorizationInfo?.user?.organization?.id)">
                <dt>
                    {{"general.organization" | translate}}
                </dt>
                <dd>
                    <app-navigator [organizationId]="owner.id"></app-navigator>
                </dd>
            </ng-container>


            <ng-container *ngIf="assignment">
                <ng-container *ngIf="assignment.parentAssignmentId">
                    <dt>
                        {{"assignment.assignmentName" | translate}}
                    </dt>
                    <dd>
                        <app-navigator [assignment]="assignment.parentAssignment"
                                       class="mr-16"></app-navigator>
                    </dd>
                </ng-container>
                <dt>
                    <ng-container *ngIf="assignment.parentAssignmentId">
                        {{"subAssignment.subAssignment" | translate}}
                    </ng-container>
                    <ng-container *ngIf="!assignment.parentAssignmentId">
                        {{"assignment.assignmentName" | translate}}
                    </ng-container>
                </dt>
                <dd class="flex">
                    <app-navigator [assignment]="assignment"
                                   class="mr-16"></app-navigator>
                </dd>

            </ng-container>
            <ng-container *ngIf="task">
                <dt>
                    {{"general.task" | translate}}
                </dt>
                <dd>
                    {{task.name}}
                </dd>

            </ng-container>
        </dl>
    </div>
</ng-container>

<app-location-dialog></app-location-dialog>