import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Dropdown, DropdownModule } from "primeng/dropdown";
import { NgForOf, NgIf, NgTemplateOutlet, UpperCasePipe } from "@angular/common";
import { NotificationsModule } from "src/app/modules/notifications/notifications.module";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { SelectItem } from "primeng/api";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NavMenuLink } from "src/app/models/nav-menu-link";
import { SubscriptionManager } from "src/app/utilities";
import { AuthorizationInfo, IOrganization, Roles } from "src/app/models/user";
import { Rights } from "src/app/models/rights";
import { environment } from "src/environments/environment";
import { ImpersonationService } from "src/app/services/impersonation.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MapDetail, MapDetailService } from "src/app/services/map-detail.service";
import { ValidationService } from "src/app/services/validation.service";
import { NavigationService } from "src/app/services/navigation.service";
import { UploadFileService } from "src/app/services/upload-file.service";
import { ProgressService } from "src/app/services/progress.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { MapDataService } from "src/app/services/map-data.service";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { DocumentEventService } from "src/app/services/document-event.service";
import { ResizeService } from "src/app/services/resize.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { UserApi } from "src/app/resource/user.api";
import { filter } from "rxjs/operators";
import { BackendRights } from "src/app/models/backend-rights";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { WebsiteService } from "src/app/services/website.service";

const assignmentsRoute = "/map/assignments";

@Component({
    selector: "app-page-header",
    standalone: true,
    imports: [
        DropdownModule,
        NgForOf,
        NgIf,
        NgTemplateOutlet,
        NotificationsModule,
        RouterLink,
        SharedModule,
        SharedModule,
        TranslateModule,
        UpperCasePipe,
        SharedModule
    ],
    templateUrl: "./page-header.component.html",
    styleUrl: "./page-header.component.scss"
})
export class PageHeaderComponent implements OnInit, OnDestroy {

    @ViewChild("nav", { static: true }) navElementRef: ElementRef<HTMLDivElement>;
    impersonationOrganizations: SelectItem[];
    roles: SelectItem[];
    languages: SelectItem[];
    isAuthenticated: boolean | null = null;
    isAuthorized: boolean;
    availableNavLinks: NavMenuLink[] = [];
    authorizationInfo: AuthorizationInfo;
    rights: Rights;
    private navbar: HTMLDivElement;
    private navDropdown: HTMLLIElement;
    private organizationLinkIndex: number;

    private subscriptionManager = new SubscriptionManager();
    private readonly mapDataKey: string;


    constructor(
        readonly impersonationService: ImpersonationService,
        readonly translate: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly mapDetailService: MapDetailService,
        readonly router: Router,
        protected readonly websiteService: WebsiteService,
        private readonly validationService: ValidationService,
        private readonly navigationService: NavigationService,
        private readonly uploadFileService: UploadFileService,
        private readonly progressService: ProgressService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly mapDataService: MapDataService,
        private readonly domainDataService: DomainDataService,
        private readonly documentEventService: DocumentEventService,
        private readonly resizeService: ResizeService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly userApi: UserApi) {

        this.mapDataKey = this.mapDataService.createKey();
        this.roles = this.primeComponentService.createDropdownList(Object.keys(Roles), x => Roles[x], x => x, false);
    }

    @ViewChild("navbar", { static: true }) set setNavbar(navbar: ElementRef<HTMLDivElement>) {
        this.navbar = navbar ? navbar.nativeElement : null;
    }

    @ViewChild("navdropdown", { static: false }) set setNavDropdown(navDropdown: ElementRef<HTMLLIElement>) {
        this.navDropdown = navDropdown ? navDropdown.nativeElement : null;
    }

    get showMockProgress(): boolean {
        return this.authorizationInfo?.isActualDomainAdministrator && !environment.production;
    }

    get isMobile(): boolean {
        return this.resizeService.isMobile;
    }

    get isDesktop(): boolean {
        return this.resizeService.isDesktop;
    }

    get organization(): IOrganization {
        return this.globalEventsService.getDefaultOrganization();
    }

    get showImpersonationDropdown(): boolean {
        return this.impersonationService.isImpersonating() || this.globalEventsService.hasMultipleOrganizations();
    }

    get showRoleImpersonationDropdown(): boolean {
        return this.globalEventsService.hasAccessToRoleImpersonation();
    }

    get currentLanguage(): string {
        return this.translate.currentLang;
    }

    ngOnInit() {

        const loadOrganisations = () => {
            this.mapDataService.subscribeToOrganizations(this.mapDataKey, allOrganizations => {
                const previousImpersonatedOrganization = this.impersonationService.organization;

                // Users see their own orgs + orgs that share data - only show own
                // Admins can be anything
                const relevantOrganizations = !this.authorizationInfo?.user ? [] :
                    this.authorizationInfo.user ? allOrganizations : this.globalEventsService.getOrganizations();

                this.impersonationOrganizations = this.primeComponentService.createDropdownList(relevantOrganizations, x => x, x => x.name, false);

                if (previousImpersonatedOrganization) {
                    const impersonatedOrganization = this.impersonationOrganizations.find(x => x.value && x.value.id === previousImpersonatedOrganization.id);

                    if (impersonatedOrganization) {
                        this.impersonationService.setOrganization(impersonatedOrganization ? impersonatedOrganization.value as IOrganization : null);
                    } else {
                        this.impersonationService.loadedOrganizationId = previousImpersonatedOrganization.id;
                    }
                }
            });
        };

        const onAuthorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe(authorizationInfo => {
            this.authorizationInfo = authorizationInfo;
            if (authorizationInfo) {
                loadOrganisations();
                this.updateOrganizationRouterLink();
            } else {
                this.impersonationService.clear(false);
            }
        });

        this.subscriptionManager.add("onAuthorizationInfoSubscription", onAuthorizationInfoSubscription);
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
            if (this.rights) {
                this.updateNavLinks(this.router.url);
            }
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.impersonationService.subscribeToOrganizationImpersonation("nav-menu", () => {
            this.updateOrganizationRouterLink();
        });

        this.impersonationService.subscribeToRoleImpersonation("nav-menu", () => {
            this.updateNavLinks(this.router.url, true);
            this.updateOrganizationRouterLink();
        });

        this.domainDataService.get(DomainData.Language).then(languages => {
            this.languages = languages;
        });

        const onNavigationEndSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.updateNavLinks(event.urlAfterRedirects);
            });

        this.subscriptionManager.add("onNavigationEndSubscription", onNavigationEndSubscription);
        this.subscriptionManager.add("isAuthenticatedSubscription",
            this.globalEventsService.isAuthenticated$.subscribe((value) => {
                if (value !== null) {
                    this.isAuthenticated = value;
                }
            }));

        this.subscriptionManager.add("isAuthorizedSubscription",
            this.globalEventsService.isAuthorized$.subscribe((value) => {
                if (value !== null) {
                    this.isAuthorized = value;
                }
            }));
        // rights subscription

        this.updateNavLinks(this.router.url);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.impersonationService.unsubscribe("nav-menu");
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    private async updateNavLinks(url: string, forcefullyRedirectWhenDeemedRequired = false) {
        const setInitialIfRequired = async () => {
            if (!this.rights || this.rights.backendRights.length == 0) return;

            this.availableNavLinks = this.getNavigationLinks(this.rights);
            this.updateOrganizationRouterLink();

            if (forcefullyRedirectWhenDeemedRequired) {
                const curNavLink = this.availableNavLinks.filter(x => url.contains(x.route));
                if (!curNavLink.length) {
                    const firstPossibleRoute = this.availableNavLinks.takeFirstOrDefault();

                    if (firstPossibleRoute) {
                        // Currently in window where we lost rights to, go to first
                        await this.router.navigate([firstPossibleRoute.route]);
                    }
                }
            }
        };

        // about and documentation pages are a special cases, they're placed in the login dropdown menu or they are not present as option at all (documentation)
        if (!url || url === "/about" || url.contains("/documentation")) {
            await setInitialIfRequired();
            return;
        }

        await setInitialIfRequired();
    }

    toggleNavbar() {
        this.navbar.classList.toggle("show");
    }

    toggleDropdown() {
        if (!this.navDropdown) {
            return;
        }

        const documentServiceKey = "closeNavDropdown";

        if (this.navDropdown.classList.contains("open")) {
            this.navDropdown.classList.remove("open");
            this.documentEventService.removeOnClick(documentServiceKey);
        } else {
            this.navDropdown.classList.add("open");
            this.documentEventService.addOnClick(documentServiceKey, this.navDropdown, () => this.toggleDropdown());
        }
    }

    closeMenus() {
        if (this.navbar && this.navbar.classList.contains("show")) this.toggleNavbar();
        if (this.navDropdown && this.navDropdown.classList.contains("open")) this.toggleDropdown();
    }

    setLanguage(language: string) {
        this.domainDataService.loadLanguage(language).subscribe((domainData) => {
            this.domainDataService.domainData = domainData;
            this.translate.use(language);
            // update user api language
            if (this.authorizationInfo?.user) {
                this.userApi.updateSelfLanguage$(language).subscribe();
            }
        });
    }

    mockProgress() {
        this.uploadFileService.mock();
        this.progressService.mock();
    }

    async setImpersonation(organization: IOrganization) {
        this.validationService.setContext(null);
        await this.navigationService.toHome();
        this.impersonationService.setOrganization(organization);
    }

    async setRoleImpersonation(role: Roles) {
        this.validationService.setContext(null);
        // await this.navigationService.toHome();
        this.impersonationService.role = role;
    }

    dropdownOnClick(dropdown: Dropdown, clickEvent: MouseEvent) {
        if (dropdown.overlayVisible) {
            dropdown.clear(clickEvent);
        }

        if (clickEvent) {
            clickEvent.stopPropagation();
        }
    }

    login() {
        this.authenticationService.login();
    }

    logout() {
        this.authenticationService.signoutSilently();
    }

    isActive(link: NavMenuLink) {
        if (this.router.url.contains("task/") && link.route === assignmentsRoute) { // Assignments option active when looking task
            return true;
        } else if (this.router.url.contains("parkingban/") && link.route === assignmentsRoute) { // Assignments option active when looking parkingban
            return true;
        } else if (this.router.url.contains("organizations") && link.route === "/map/organizations") {
            return true;
        } else {
            return this.mapDetailService.currentMapDetail === link.mapDetail || this.router.isActive(link.route, {
                paths: "subset",
                queryParams: "ignored",
                fragment: "ignored",
                matrixParams: "ignored"
            });
        }
    }

    private getNavigationLinks(rights: Rights): NavMenuLink[] {
        const links = [];

        if (rights.hasBackendRight(BackendRights.ViewMeasuringPoint)) {
            links.push({
                title: "location-map.title", route: "/locations", icon: "map-icons",
                screenName: "LocationMapScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewGroup)) {
            links.push({
                title: "groups.title", route: "/map/groups", icon: "groups",
                screenName: "GroupsScreen", mapDetail: MapDetail.MeasuringPointGroups
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewAssignment)) {
            links.push({
                title: "assignment.title", route: assignmentsRoute, icon: "assignments",
                screenName: "AssignmentsScreen", mapDetail: MapDetail.Assignments
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewDevice)) {
            links.push({
                title: "devices.title", route: "/map/devices", icon: "hardware",
                screenName: "DevicesScreen", mapDetail: MapDetail.Devices
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewOrganization) || rights.hasBackendRight(BackendRights.ViewProject)) {
            links.push({
                title: "administration.title", route: "/administration", icon: "projects", screenName: "AdministrationScreen"
            })
        }
        if (rights.hasBackendRight(BackendRights.ViewVehicleDayOverviews)) {
            links.push({
                title: "data.title", route: "/data", icon: "data",
                screenName: "DataScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewReport)) {
            links.push({
                title: "reports.title", route: "/reports", icon: "reports",
                screenName: "ReportsScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewScenario) ||
            rights.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)) {
            links.push({
                title: "scenarios.title", route: "/scenarios", icon: "calendar",
                screenName: "ScenariosScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewPlanning)) {
            links.push({
                title: "assignmentPlanning.title", route: "/planning", icon: "calendar",
                screenName: "AssignmentPlanningScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewWorklistItems)) {
            links.push({
                title: "worklists.title", route: "/worklists", icon: "list",
                screenName: "WorklistsScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.ViewUpload)) {
            links.push({
                title: "history.title", route: "/history/uploads", icon: "hourglass",
                screenName: "HistoryScreen"
            });
        }
        if (rights.hasBackendRight(BackendRights.EditWorker) ||
            rights.hasBackendRight(BackendRights.ViewSafetyQuestion)) {
            links.push({
                title: "admin.title", route: "/management/users", icon: "settings",
                screenName: "ManagementScreen"
            });
        }

        // find position of organization link
        this.organizationLinkIndex = links.findIndex(x => x.screenName === "OrganizationsScreen");

        return links;
    }

    private updateOrganizationRouterLink() {
        // Only update the organization link if it exists
        if (this.organizationLinkIndex > -1) {
            if (this.globalEventsService.hasMultipleOrganizations()) {
                this.setOrganizationLinkForMultipleOrganizations();
                return;
            }

            this.setOrganizationLinkForSingleOrganization();
        }
    }

    private setOrganizationLinkForMultipleOrganizations() {
        this.availableNavLinks[this.organizationLinkIndex].route = "/map/organizations";
        this.availableNavLinks[this.organizationLinkIndex].title = "organizations.title";
    }

    private setOrganizationLinkForSingleOrganization() {
        const organization = this.globalEventsService.getDefaultOrganization();
        if (!organization) {
            this.availableNavLinks[this.organizationLinkIndex].route = "/";
            return;
        }

        this.availableNavLinks[this.organizationLinkIndex].route = `administration/organization/${organization.id}/details`;
        this.availableNavLinks[this.organizationLinkIndex].title = "general.organization";

        if (window.location.href.endsWith("/map/organizations")) {
            this.navigationService.toOrganizations();
        }
    }
}
