<ng-container *ngIf="!parkingBan">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<div class="m-btn-group m-btn-group-right m-border-top m-layout-area-top">
    <button class="m-btn m-btn-secondary"
            (click)="create()"
            *ngIf="parkingBan && canCreateSignScan">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"manageSignScan.create" | translate}}
    </button>
</div>

<app-sign-scans *ngIf="parkingBan"
                class="m-border-top m-layout-area-body m-layout-default"
                [parkingBanId]="parkingBan?.id"
                [editCommand]="true"
                [deleteCommand]="true"></app-sign-scans>

<app-manage-sign-scan-dialog></app-manage-sign-scan-dialog>