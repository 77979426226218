import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";

import { BulkUploadComponent } from "./components/bulk-upload/bulk-upload.component";
import { AuthGuard } from "src/app/guards/auth.guard";

const routes: Routes = [
    { path: "", component: BulkUploadComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard], data: { name: "bulkUpload.title" } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BulkUploadRoutingModule { }