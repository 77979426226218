import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { IAssignment } from "src/app/models/assignment";
import { ILocation } from "src/app/models/location";
import { ITask, TaskStatus } from "src/app/models/task";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { TasksComponent } from "src/app/modules/shared/components/tasks/tasks.component";
import { NavigationService } from "src/app/services/navigation.service";
import { CreateRecurringTasksDialogComponent } from "../create-recurring-tasks-dialog/create-recurring-tasks-dialog.component";
import { ScheduleSchedulableTasksDialogComponent } from "../schedule-schedulable-tasks-dialog/schedule-schedulable-tasks-dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-tasks-container",
    templateUrl: "./tasks-container.component.html",
    styleUrls: []
})
export class TasksContainerComponent implements OnDestroy {
    @Input() assignment: IAssignment;

    @Output() lastTaskRemoved = new EventEmitter<void>();

    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;
    @ViewChild(CreateRecurringTasksDialogComponent, { static: true }) createRecurringTasksDialogComponent: CreateRecurringTasksDialogComponent;
    @ViewChild(ScheduleSchedulableTasksDialogComponent, { static: true }) scheduleSchedulableTasksDialogComponent: ScheduleSchedulableTasksDialogComponent;
    @ViewChild(TasksComponent, { static: false }) tasksComponent: TasksComponent;

    selectedTasks: ITask[];
    rights: Rights;
    canCreateTask = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();


    get isAnyInProgressOrFinishedTaskSelected(): boolean {
        return this.selectedTasks && this.selectedTasks.filter(x => x.currentStatus
            && (x.currentStatus.taskStatusId === TaskStatus.InProgress || x.currentStatus.taskStatusId === TaskStatus.Finished)).length > 0;
    }

    get isAnyOnHoldOrScheduledTaskSelected(): boolean {
        return this.selectedTasks &&
            this.selectedTasks.filter(x => x.currentStatus && (x.currentStatus.taskStatusId === TaskStatus.OnHold || x.currentStatus.taskStatusId === TaskStatus.Scheduled)).length > 0;
    }

    get isAnyTaskSelected(): boolean {
        return this.selectedTasks && this.selectedTasks.length > 0;
    }

    constructor(elementRef: ElementRef,
        private readonly globalEventsService: GlobalEventsService,
        private readonly navigationService: NavigationService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
            this.canCreateTask = rights?.hasBackendRight(BackendRights.EditTask);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
    setAssignment(assignment: IAssignment) {
        this.assignment = assignment;
    }

    create() {
        // no navigation after creation, just close the dialog
        this.manageLocationComponent.navigateAfterCreation = false;

        const onSuccessfullySelectedLocation = async (newLocation: ILocation) => {
            if (!newLocation) return;

            this.navigationService.newTaskCoordinate = newLocation.coordinate;
            this.navigationService.newTaskAssignment = this.assignment;
            await this.navigationService.createNewTask();
        };

        // we are going to create a new location
        this.manageLocationComponent.create(this.assignment?.location?.coordinate ?? this.assignment?.parentAssignment?.location?.coordinate, onSuccessfullySelectedLocation.bind(this));
    }

    onSelectionChanged(selectedTasks: ITask[]): void {
        this.selectedTasks = selectedTasks;
    }

    createRecurringTasksFromSelection() {
        if (!this.selectedTasks || this.selectedTasks.filter(x => x.currentStatus
            && (x.currentStatus.taskStatusId === TaskStatus.InProgress || x.currentStatus.taskStatusId === TaskStatus.Finished)).length === 0) return;

        this.createRecurringTasksDialogComponent.open(this.assignment.id, this.selectedTasks.filter(x => x.currentStatus
            && (x.currentStatus.taskStatusId === TaskStatus.InProgress || x.currentStatus.taskStatusId === TaskStatus.Finished)),
            () => {
                // reload table
                this.tasksComponent.reload();
            });
    }

    scheduleSchedulableTasks() {
        if (!this.selectedTasks
            || this.selectedTasks.length === 0
            || this.selectedTasks.filter(x => x.currentStatus
                && (x.currentStatus.taskStatusId === TaskStatus.OnHold || x.currentStatus.taskStatusId === TaskStatus.Scheduled)).length === 0) {
            return;
        }

        this.scheduleSchedulableTasksDialogComponent.open(
            this.assignment.id,
            this.selectedTasks.filter(x => x.currentStatus && (x.currentStatus.taskStatusId === TaskStatus.OnHold || x.currentStatus.taskStatusId === TaskStatus.Scheduled)),
            () => {
                // reload table
                this.tasksComponent.reload();
            });
    }

    handleLastTaskDeleted() {
        this.lastTaskRemoved.next();
    }

    exportToExcel(fileName: string) {
        const selectedTasksIds = this.selectedTasks.map(x => x.id);

        this.tasksComponent.exportExcel(fileName, (row) => {
            return selectedTasksIds.contains(row.id);
        });
    }
}
