import { TableService, TableColumn, FilterType, LazyTableComponent } from "src/app/modules/shared/components/table/table.component";
import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { IVmsImage } from "src/app/models/vms-image";
import { FilterDescriptor, SearchParameters } from "src/app/models/search";
import { ModalService } from "src/app/services/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";
import { VmsImageVariantApi } from "src/app/resource/vms-image-variant.api";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-vms-image-variants",
    templateUrl: "./vms-image-variants.component.html"
})
export class VmsImageVariantsComponent extends LazyTableComponent<IVmsImageVariant> implements OnInit {
    @Input() vmsImage: IVmsImage;


    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly vmsImageVariantApi: VmsImageVariantApi,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService) {

        super("vms-image-variants.component", elementRef, vmsImageVariantApi, tableService);

        this.selectionMode = "";

        this.createColumns();
        this.registerCommands();
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        // add the parent category Id as search filter
        searchParameters.filter = [new FilterDescriptor("vmsImageId", this.vmsImage.id)];

        return searchParameters;
    }

    private createColumns() {

        this.clearColumns();
        this.addColumn(new TableColumn("image", "vmsImages.image", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("type", "vmsImages.type", { filterType: FilterType.None, sortable: false }));
        this.addColumn(new TableColumn("resolution", "vmsImages.resolution", { filterType: FilterType.None, sortable: false }));
    }

    private registerCommands() {
        this.commands = [];

        this.registerCommand({
            text: "general.download",
            icon: "download",
            click: (vmsImageVariant) => this.download(vmsImageVariant),
        });
        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (vmsImageVariant) => this.delete(vmsImageVariant),
        });

    }

    download(vmsImageVariant: IVmsImageVariant) {
        window.location.href = vmsImageVariant.url;
    }

    async delete(vmsImageVariant: IVmsImageVariant) {

        const onDeleteSuccess = () => {
            this.reload();

        };

        const onDelete = () => {
            this.vmsImageVariantApi.delete$(vmsImageVariant.id).subscribe(onDeleteSuccess, () => { });
        };

        const typeMessage = await this.domainDataService.translateEnum("vmsTypeValue", vmsImageVariant.vmsType.typeId);

        const modalBody = this.translateService.instant(
            "vmsImages.variantDeleteConfirmation",
            { code: this.vmsImage.code, vmsType: typeMessage });

        this.modalService.delete(modalBody, onDelete);
    }
}