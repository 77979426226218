import { Injectable } from "@angular/core";
import { Observable, first, firstValueFrom } from "rxjs";
import { AlarmSeverity } from "../models/alarm";
import { IMapIcon, MapIconCreator } from "../models/map-icon";
import { DownloadedFile } from "../services/download-file.service";
import { ApiService } from "./api";
import { ServiceRequestOptions } from "../models/search";
import { AlertLevel } from "../models/alert";

@Injectable({
    providedIn: "root"
})
export class MapIconApi extends ApiService<IMapIcon, IMapIcon, IMapIcon> {
    getRoute(): string {
        return "MapIcons";
    }

    getMapIconStatePreview$(file: File, level?: AlertLevel): Promise<DownloadedFile> {
        const url = `${this.getUrl()}/IconStatePreview/${level ?? ""}`;

        const formData = new FormData();
        formData.append("data", file);

        return this.downloadFileService.downloadBlob(url, formData, null, null);
    }

    postWithFormData$(creator: MapIconCreator, iconFile: File): Observable<IMapIcon> {
        const url = super.getUrl();

        const formData = new FormData();
        formData.append("config", JSON.stringify(creator));
        formData.append("data", iconFile);

        return this.http.post<IMapIcon>(url, formData);
    }

    putWithFormData$(id: number, updater: MapIconCreator, iconFile: File): Observable<IMapIcon> {
        const url = `${super.getUrl()}/${id}`;

        const formData = new FormData();
        formData.append("config", JSON.stringify(updater));
        formData.append("data", iconFile);

        return this.http.put<IMapIcon>(url, formData);
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }

    public async getAll(): Promise<IMapIcon[]> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("mapIcon", "states");

        const obs = this.search$(null, serviceRequestOptions, false);
        const result = await firstValueFrom(obs);
        return result.data;
    }
}