<div class="m-page-content-wrapper p-16">
    <div class="row"
         *ngIf="!task">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="row m-fixed-height"
         [hidden]="!task">
        <div class="col-3"
             style="height:100%; display:grid; grid-template-rows: fit-content(75%) minmax(200px, 1fr); gap:16px;">
            <div class="m-card"
                 style="position: relative; min-height: 0; overflow-y: auto;">
                <a (click)="toMap()"
                   class="m-btn m-btn-back m-btn-back-map"
                   title="Back">
                    <app-svg-icon icon="chevron-left"
                                  class="mr-4"></app-svg-icon>
                    {{"general.returnToMap" | translate}}
                </a>

                <app-location-detail *ngIf="task"
                                     [task]="task"
                                     [location]="task.location"
                                     [assignment]="task.assignment"
                                     [editCommand]="canEditLocation"
                                     class="m-layout-area-body m-layout-w-actions-top"></app-location-detail>
            </div>

            <div class="m-card"
                 style="min-height: 0; display:grid; grid-template-rows: auto 1fr auto;">
                <div class="p-16">
                    <h3>{{"task.allTasks" | translate}}</h3>
                </div>

                <app-tasks style="min-height: 0; grid-area: auto;"
                           [taskIdSelected]="task?.id"
                           [assignmentId]="task?.assignment?.id"
                           [requiresInput]="true"
                           [headers]="false"
                           [footer]="false"
                           [paginator]="false"
                           [rowCount]="9999"
                           [mini]="true"
                           [selectionMode]="'single'"
                           [deleteCommand]="false"
                           [editCommand]="false"
                           (selected)="handleOnTaskSelect($event)"
                           componentStyle="m-card-body-table-content">
                </app-tasks>

                <div class="m-btn-group m-btn-group-right m-border-top">
                    <button class="m-btn m-btn-secondary"
                            (click)="backToDetailedTasksRepresentation()"
                            [disabled]="!task?.id">
                        {{"taskDetailTab.backToTasks" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height">
            <app-task-detail-container [task]="task"
                                       class="m-fixed-height"
                                       (save)="handleOnTaskSaved($event)">
            </app-task-detail-container>

            <div *ngIf="loading">
                <app-loading-spinner>
                </app-loading-spinner>
            </div>
        </div>
    </div>
</div>