import { IArrowBoardStatus } from "../device";
import { SimInformation } from "./traffic-light-data";

export class TrackerData {
    imei: string;
    latitude: number;
    longitude: number;
    batteryVoltage: number;
    impact: boolean;
    simInformation: SimInformation;
    arrowBoardStatus: IArrowBoardStatus;
}