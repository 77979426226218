<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-layout-default"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowReorder)="reorder()"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [sortField]="useDefaultSort ? 'code' : ''"
         [hidden]="hidden"
         dataKey="id"
         #tableRef>

  <ng-template pTemplate="emptymessage">
    <tr *ngIf="!loading && emptyMessage">
      <td [attr.colspan]="relevantColumns.length"
          class="m-table-empty-message">
        {{"table.empty" | translate}}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <ng-container *ngIf="total">
      {{"table.total" | translate}}: {{total}}
    </ng-container>
  </ng-template>

  <ng-template pTemplate="summary"
               *ngIf="footer">
    <ng-container *ngIf="total && !paginator">
      {{"table.total" | translate}}: {{total}}
    </ng-container>
  </ng-template>

  <ng-template pTemplate="header"
               *ngIf="headers">
    <tr>
      <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn;let i=index;"
          [ngStyle]="column.ngStyle"
          [pSortableColumn]="column.field"
          [pSortableColumnDisabled]="!sortable || !column.sortable"
          [ngSwitch]="column.field">

        <div class="m-column-header">
          <span class="m-column-title"
                style="text-align: center;"
                *ngSwitchCase="'includeForwardDirection'">

            <app-svg-icon icon="arrow-right"
                          tooltip="{{'measuringPoints.primaryDirection' | translate}}">
            </app-svg-icon>

            <input type="checkbox"
                   [(ngModel)]="isAllForwardSelected"
                   (click)="stopEvent($event)"
                   (change)="bulkSelectChanged('forward')" />
          </span>


          <span class="m-column-title"
                style="text-align: center;"
                *ngSwitchCase="'includeReverseDirection'">

            <app-svg-icon icon="arrow-left"
                          tooltip="{{'measuringPoints.reverseDirection' | translate}}">
            </app-svg-icon>

            <input type="checkbox"
                   [(ngModel)]="isAllReverseSelected"
                   (click)="stopEvent($event)"
                   (change)="bulkSelectChanged('reverse')" />
          </span>

          <span class="m-column-title"
                style="text-align: center;"
                *ngSwitchCase="'includeSum'">

            <app-svg-icon icon="arrow-sum"
                          tooltip="{{'measuringPoints.sumDirection' | translate}}">
            </app-svg-icon>

            <input type="checkbox"
                   [(ngModel)]="isAllSumSelected"
                   (click)="stopEvent($event)"
                   (change)="bulkSelectChanged('sum')" />
          </span>

          <span class="m-column-title"
                style="text-align: center;"
                *ngSwitchCase="'commands'">

            <div class="m-btn-group"
                 style="margin-top: 0;">
              <app-icon-button *ngIf="deleteCommand && data?.length > 0"
                               text="form.delete"
                               icon="delete"
                               [style]="{'min-height': '24px'}"
                               (click)="clearData()">
              </app-icon-button>
            </div>
          </span>

          <ng-container *ngSwitchDefault>
            <div class="m-column-header-wrapper">
              <span class="m-column-title">
                {{column.header | translate}}
              </span>

              <span *ngIf="i==1"
                    class="m-column-title"
                    style="text-align: center;">
                <a (click)="alphabeticalSorting()">
                  <app-svg-icon icon="a-z-sortIcon"
                                tooltip="{{'measuringPoints.a-zSorting' | translate}}">
                  </app-svg-icon>
                </a>
              </span>

              <p-sortIcon *ngIf="sortable && column.sortable"
                          [field]="column.field">
              </p-sortIcon>

              <app-table-filter *ngIf="filter"
                                class="m-column-filter"
                                [column]="column"
                                [table]="table">
              </app-table-filter>
            </div>

            <app-table-filter-preview [column]="column"></app-table-filter-preview>
          </ng-container>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body"
               let-measuringPoint
               let-index="rowIndex">

    <tr [pSelectableRow]="measuringPoint"
        [pReorderableRow]="index">

      <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
          [ngStyle]="column.ngStyle"
          [ngSwitch]="column.field">

        <ng-container *ngSwitchCase="'reorder'">
          <div class="flex justify-center"
               style="width: 100%;">
            <i class="fa fa-bars"
               pReorderableRowHandle></i>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'measuringPoint.code'">
          {{measuringPoint.code}}
        </ng-container>

        <ng-container *ngSwitchCase="'includeForwardDirection'">
          <input type="checkbox"
                 [disabled]="readonly"
                 [(ngModel)]="measuringPoint.includeForwardDirection"
                 (change)="updateIsAllForwardSelected()" />
        </ng-container>

        <ng-container *ngSwitchCase="'includeReverseDirection'">
          <input type="checkbox"
                 [disabled]="readonly"
                 [(ngModel)]="measuringPoint.includeReverseDirection"
                 (change)="updateIsAllReverseSelected()" />
        </ng-container>

        <ng-container *ngSwitchCase="'includeSum'">
          <input type="checkbox"
                 [disabled]="readonly"
                 [(ngModel)]="measuringPoint.includeSum"
                 (change)="updateIsAllSumSelected()" />
        </ng-container>

        <ng-container *ngSwitchCase="'commands'">
          <div class="m-btn-group"
               style="margin-top: 0;">
            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
              <app-icon-button [text]="command.text"
                               [icon]="command.icon"
                               [disabled]="command.disabledFunc()"
                               (click)="command.click(measuringPoint)"
                               *ngIf="command.rowValidFunc(measuringPoint)">
              </app-icon-button>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{measuringPoint[column.field]}}
        </ng-container>
      </td>
    </tr>

  </ng-template>
</p-table>