import { ILocation } from "../location";
import { IDeviceStatusSummary, IMeasuringPointStatusSummary } from "./";
import { WeatherForecastSummary } from "src/app/models/web/weather-forecast-summary";

export interface ILocationStatusSummary {
    location: ILocation;
    measuringPoints: IMeasuringPointStatusSummary[];
    devices: IDeviceStatusSummary[];
    lastUpdate: Date;
    forecasts: WeatherForecastSummary[];
}
