import { IAttachment } from "./attachment";
import { ILocation, LocationCreator, LocationUpdater } from "./location";
import { IParkingBan } from "./parking-ban";
import { IServiceModelBase } from "./servicemodelbase";

export interface ISignScan extends IServiceModelBase {
    name: string;
    signCode: string;
    checkIn: Date;
    checkOut?: Date;
    lost?: Date;

    photoPreview: string; // FE only
    photos: IAttachment[];
    location: ILocation;
    locationId: number;
    parkingBan: IParkingBan;
}

export class SignScan {
    name: string;
    signCode: string;
    checkIn: Date;
    checkOut?: Date;
    lost?: Date;
    parkingBanId?: number;

    constructor(signScan: ISignScan) {
        this.name = signScan.name;
        this.signCode = signScan.signCode;
        this.checkIn = signScan.checkIn;
        this.checkOut = signScan.checkOut;
        this.parkingBanId = signScan.parkingBan ? signScan.parkingBan.id : undefined;
        this.lost = signScan.lost;
    }
}

export class SignScanUpdater extends SignScan {
    id: number;
    locationId?: number;
    location?: LocationUpdater;

    constructor(signScan: ISignScan) {
        super(signScan);

        this.id = signScan.id;
        this.locationId = signScan.locationId;
    }
}

export class SignScanCreator extends SignScan {
    location: LocationCreator;
}