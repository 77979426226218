import { Component, Input, OnChanges, SimpleChanges, ViewChild, HostListener, ElementRef, OnDestroy } from "@angular/core";
import { IMeasuringPointAggregation, MeasuringPointAggregationCreator } from "src/app/models/measuring-point-aggregation";
import { Direction } from "src/app/models/direction";
import { MeasuringPointAggregationDirectionComponent } from "../measuring-point-aggregation-direction/measuring-point-aggregation-direction.component";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { MeasuringPointAggregationApi } from "src/app/resource/measuring-point-aggregation.api";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { MapSelectorComponent } from "src/app/modules/map-advanced/components/map-selector/map-selector.component";
import { SubscriptionManager, NumberUtils } from "src/app/utilities";
import { MapSelectionService } from "src/app/services/map-selection.service";
import { IProgress, IProgressCreated } from "src/app/models/progress";
import { ProgressAction } from "src/app/services/progress.service";
import { ResizeService } from "src/app/services/resize.service";
import { ErrorService } from "src/app/services/error.service";
import { ProgressApi } from "src/app/resource/progress.api";
import { MapDetail } from "src/app/services/map-detail.service";
import { IMeasuringPoint, IMeasuringPointNavigator } from "src/app/models/measuring-point";

export type AggregationToDirection = "forward" | "reverse";

@Component({
    selector: "app-measuring-point-aggregation",
    templateUrl: "./measuring-point-aggregation.component.html"
})
export class MeasuringPointAggregationComponent implements OnChanges, IComponentCanDeactivate, IChangeGuard, OnDestroy {
    map: MapSelectorComponent;
    @ViewChild(MapSelectorComponent, { static: false }) set setMapSelector(mapSelector: MapSelectorComponent) {
        console.log("MapSelector", mapSelector);
        this.map = mapSelector;
    }

    @ViewChild("column1", { static: true }) column1: ElementRef<HTMLDivElement>;
    @ViewChild("mainDirectionAggregation", { static: true }) mainDirectionAggregation: MeasuringPointAggregationDirectionComponent;
    @ViewChild("reverseDirectionAggregation", { static: true }) reverseDirectionAggregation: MeasuringPointAggregationDirectionComponent;

    @Input() measuringPoint: IMeasuringPoint;
    @Input() duplicateCommand = true;
    @Input() deleteCommand = true;

    isMapOpen = false;
    mapDetail: MapDetail;

    isSaving = false;
    editingDirection: AggregationToDirection;
    scrollHeight: string;
    max = 8;

    currentTaskId: string;
    progressCounter: number;

    get showProgressBar(): boolean {
        return NumberUtils.isValid(this.progressCounter);
    }

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        readonly elementRef: ElementRef,
        private readonly api: MeasuringPointAggregationApi,
        private readonly changeGuardService: ChangeGuardService,
        private readonly selectionService: MapSelectionService,
        private readonly resizeService: ResizeService,
        private readonly progressApi: ProgressApi,
        private readonly errorService: ErrorService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const resizeSubscription = this.resizeService.onResize.subscribe(() => {
            this.updateScrollHeight();
        });
        this.subscriptionManager.add("resize", resizeSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.loadTableRows();
        }
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.mainDirectionAggregation.isDirty && !this.reverseDirectionAggregation.isDirty;
    }

    onDeactivate() {

    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
        this.loadTableRows();
    }

    private updateScrollHeight() {
        if (!this.column1) return;

        setTimeout(() => {
            // 123 takes into account the margins, the title, ...
            this.scrollHeight = (this.column1.nativeElement.clientHeight / 2) - 123 + "px";
        });
    }

    loadTableRows() {
        this.api.get$(this.measuringPoint).subscribe(aggregations => {
            this.mainDirectionAggregation.setData(aggregations.filter(x => x.aggregatedMeasuringPointDirectionId === Direction.Forward));
            this.reverseDirectionAggregation.setData(aggregations.filter(x => x.aggregatedMeasuringPointDirectionId === Direction.Reverse));
        });
    }

    save() {
        this.isSaving = true;

        const updaters = new Array<MeasuringPointAggregationCreator>();

        for (const aggregation of this.mainDirectionAggregation.data) {
            const updater = new MeasuringPointAggregationCreator(aggregation);
            updater.aggregatedMeasuringPointDirectionId = "forward";
            updaters.push(updater);
        }

        for (const aggregation of this.reverseDirectionAggregation.data) {
            const updater = new MeasuringPointAggregationCreator(aggregation);
            updater.aggregatedMeasuringPointDirectionId = "reverse";
            updaters.push(updater);
        }

        this.progressCounter = 0;

        // for polling
        const onError = (error: Response) => {
            this.progressCounter = null;
            this.isSaving = false;
        };

        // for polling
        const onComplete = (progressAction: ProgressAction) => {
            this.progressCounter = null;
            this.loadTableRows();
            this.isSaving = false;
        };

        // for creating progress(first call)
        const onProgressCreated = (progressCreated: IProgressCreated) => {
            this.currentTaskId = progressCreated.progressId;
            this.addProgressCreated(progressCreated, onComplete, onError);
        };

        // for creating progress(first call)
        const handleErrorProgressCreated = (error: Response) => {
            this.errorService.handleError(error);
            this.isSaving = false;
            onError(error);
        };

        this.api.updateWithProgress$(this.measuringPoint, updaters).subscribe(onProgressCreated, handleErrorProgressCreated);
    }

    private addProgressCreated(progressCreated: IProgressCreated, onComplete: (action: ProgressAction) => void, onError: (error: Response) => void) {
        const action = new ProgressAction(progressCreated.progressId, progressCreated.name);
        action.onComplete = onComplete;
        action.onError = onError;

        this.startPolling(action);
    }

    private startPolling(action: ProgressAction) {

        const onProgress = (progress: IProgress) => {
            action.lastProgress = progress;
            this.progressCounter = action.lastProgress.progress;
        };

        const onComplete = () => {
            if (action.onComplete) {
                action.onComplete(action);
            }
        };

        const onError = (error: Response) => {
            if (action.onError) {
                action.onError(error);
            }

            this.errorService.handleError(error);
        };

        this.progressApi.pollProgress(action.id, onProgress).then(onComplete, onError);
    }
    //#region Map

    toggleAddMeasuringPoints(editingDirection: AggregationToDirection) {
        if (this.editingDirection !== editingDirection) {
            this.setEditingDirection(editingDirection);

            if (this.isMapOpen) {
                return;
            }
        }

        if (this.isMapOpen) {
            this.closeMap();
            return;
        }

        this.openMap(MapDetail.MeasuringPoints);
    }

    private setEditingDirection(editingDirection: AggregationToDirection) {
        this.selectionService.unsubscribeFromMeasuringPoints(this.subscriptionManager);

        this.editingDirection = editingDirection;

        this.selectionService.setMeasuringPoints(this.getAggregationDirectionComponent().data.map(x => x.sourceMeasuringPoint).distinct());

        this.selectionService.subscribeToMeasuringPoints(this.subscriptionManager, x => this.addMeasuringPoints(x), x => this.removeMeasuringPoints(x));
    }

    private openMap(detail: MapDetail) {
        this.mapDetail = detail;
        this.isMapOpen = true;
        this.updateScrollHeight();
    }

    closeMap() {
        this.isMapOpen = false;
        this.updateScrollHeight();
    }

    handleMapReady() {
        const marker = this.map.gmap.getLocationMarkerById(this.measuringPoint.location.id);
        this.map.gmap.centerOn(marker);
    }

    handleMapComponentLoad() {
        if (!this.map) return;

        if (this.mapDetail === MapDetail.MeasuringPoints) {
            this.map.measuringPointsComponent.selectionMode = "multiple";
            this.map.measuringPointsComponent.selectionMax = this.max;
        }
    }

    //#region Measuring Points

    addMeasuringPoints(measuringPoints: IMeasuringPointNavigator[]) {
        const aggregations = measuringPoints.map(x => this.createAggregationFromMeasuringPoint(x));

        this.getAggregationDirectionComponent().addData(aggregations);
    }

    removeMeasuringPoints(removedMeasuringPoints: IMeasuringPointNavigator[]) {
        const aggregationDirectionComponent = this.getAggregationDirectionComponent();
        const dataToRemove = aggregationDirectionComponent.data.filter(x => removedMeasuringPoints.contains(x.sourceMeasuringPoint));

        aggregationDirectionComponent.removeData(dataToRemove);
    }

    private createAggregationFromMeasuringPoint(measuringPoint: IMeasuringPointNavigator): IMeasuringPointAggregation {
        return {
            sourceMeasuringPoint: measuringPoint,
            sourceMeasuringPointDirectionId: Direction.Forward,
            aggregatedMeasuringPointDirectionId: this.editingDirection,
            factor: 1
        } as IMeasuringPointAggregation;
    }

    private getAggregationDirectionComponent(): MeasuringPointAggregationDirectionComponent {
        return this.editingDirection === Direction.Forward ? this.mainDirectionAggregation : this.reverseDirectionAggregation;
    }

    //#endregion Measuring Points

    //#endregion Map
}