import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IParkingBan } from "src/app/models/parking-ban";
import { ServiceRequestOptions } from "src/app/models/search";
import { ParkingBansComponent } from "src/app/modules/shared/components/parking-bans/parking-bans.component";
import { ParkingBanApi } from "src/app/resource/parking-ban.api";
import { MapDetailService } from "src/app/services/map-detail.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SubscriptionManager } from "src/app/utilities";
import { ParkingBanDetailContainerComponent } from "../parking-ban-detail-container/parking-ban-detail-container.component";
import { AuthorizationInfo } from "src/app/models/user";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-parking-ban-location",
    templateUrl: "./parking-ban-location.component.html",
    styleUrls: []
})
export class ParkingBanLocationComponent implements OnInit {
    @ViewChild(ParkingBanDetailContainerComponent, { static: true }) parkingBanDetailContainerComponent: ParkingBanDetailContainerComponent;
    @ViewChild(ParkingBansComponent, { static: false }) parkingBansComponent: ParkingBansComponent;

    loading: boolean;

    parkingBan: IParkingBan;

    private readonly subscriptionManager = new SubscriptionManager();
    private rights: Rights;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        private readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly mapDetailService: MapDetailService,
        private readonly navigationService: NavigationService,
        private readonly parkingBanApi: ParkingBanApi
    ) {
        elementRef.nativeElement.classList.add("container-detail");

        const currentRights = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentAuthorizationInfo", currentRights);
    }

    ngOnInit(): void {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            // Attempt navigation to what the params specified
            await this.setParkingBanFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
    }

    private async setParkingBanFromParams(params: { [key: string]: any }): Promise<void> {
        if (!params) return;

        this.loading = true;

        const parkingBanIdString = decodeURI(params["parkingBanId"]);

        if (parkingBanIdString === "new") {
            this.parkingBan = this.navigationService.getNewParkingBan();
        } else {
            const parkingBanId = Number.parseInt(parkingBanIdString, 10);
            this.parkingBan = Number.isNaN(parkingBanId) ? null : await this.parkingBanApi.get$(parkingBanId, null, this.getServiceRequestOptions()).toPromise();
        }

        if (!this.parkingBan) {
            this.toMap();
            return;
        }

        this.loading = false;
    }

    toMap() {
        this.mapDetailService.navigateToMapDetail();
    }

    get canEditLocation(): boolean {
        return this.parkingBan && this.parkingBan.id && this.rights.hasBackendRight(BackendRights.EditParkingBan);
    }

    handleOnTaskSaved(savedParkingBan: IParkingBan) {
        const shouldNavigate = !this.parkingBan || this.parkingBan.id !== savedParkingBan.id;

        if (!shouldNavigate) {
            Object.assign(this.parkingBan, savedParkingBan);
        } else {
            this.navigationService.toParkingBan(savedParkingBan.id);
        }
    }


    private getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("parkingBan", "location");
        options.includes.add("parkingBan", "assignment");
        options.includes.add("assignment", "parentAssignment");
        options.includes.add("assignment", "project");
        return options;
    }

    async handleOnParkingBanSelect(parkingBan: IParkingBan) {
        if (!parkingBan) {
            this.toMap();
            return;
        }

        if (this.parkingBan && parkingBan.id === this.parkingBan.id) return;
        if (!this.parkingBanDetailContainerComponent || !this.parkingBanDetailContainerComponent.selectedTab) return;

        const urlIndexStart = window.location.href.indexOf(`/${this.parkingBanDetailContainerComponent.selectedTab.url}`);
        const urlAppend = window.location.href.substring(urlIndexStart + 1);

        await this.navigationService.toParkingBan(parkingBan.id, urlAppend);
    }

    backToDetailedParkingBansRepresentation() {
        this.navigationService.toParkingBans(this.parkingBan.assignment.id);
    }
}
