import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Angulartics2GoogleTagManager } from "angulartics2";
import { AutoComplete, AutoCompleteSelectEvent } from "primeng/autocomplete";
import { BackendRights } from "src/app/models/backend-rights";
import { IGroup } from "src/app/models/group";
import { AnalysisType } from "src/app/models/measuring-point";
import { FilterDescriptor, SearchParameters } from "src/app/models/search";
import { IGroupSummary } from "src/app/models/web";
import { MarkerContext } from "src/app/modules/map-advanced/classes/marker-context";
import { ManageGroupComponent } from "src/app/modules/map-advanced/components/manage-group/manage-group.component";
import { ScanMode, ScannerDialogComponent } from "src/app/modules/shared/components/scanner-dialog/scanner-dialog.component";
import { SplitMapComponentBase } from "src/app/modules/shared/components/split-map/split-map.component";
import { DeviceWebApi } from "src/app/resource/web";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { MapDataService } from "src/app/services/map-data.service";
import { MapDetail, MapDetailService } from "src/app/services/map-detail.service";
import { NavigationService } from "src/app/services/navigation.service";
import { ToastService } from "src/app/services/toast.service";

class PlacePrediction {
    id?: number;
    markerContext?: MarkerContext;
    group?: IGroupSummary;
    placeId?: string;
    label: string;
    htmlLabel: string;
    icon: string;
}

// This is the search-button that is shown on the topleft of the maps, when the full filter is not used.
@Component({
    selector: "app-map-searchbutton",
    templateUrl: "./map-searchbutton.component.html",
})
export class MapSearchButtonComponent {
    @Input() searchControl = true;
    @Input({ required: true }) loaded = false;
    @Input({ required: true }) readonly = false;
    @Input({ required: true }) splitMap: SplitMapComponentBase;

    placePredictions: PlacePrediction[];
    @ViewChild("placesAutoComplete", { static: false }) placesAutoComplete: AutoComplete;
    @ViewChild(ScannerDialogComponent, { static: false }) scannerDialog: ScannerDialogComponent;


    // ** used in map-detail-popup, keeps track of selected place's context without messing with selectionService */
    selectedPlace: PlacePrediction;

    manageGroupComponent: ManageGroupComponent;
    canCreateGroup = false;
    @ViewChild(ManageGroupComponent, { static: false }) set setManageGroupComponent(manageGroupComponent: ManageGroupComponent) {
        this.manageGroupComponent = manageGroupComponent;

        if (this.manageGroupComponent) {
            this.manageGroupComponent.splitMap = this.splitMap;
        }
    }

    constructor(
        private readonly cd: ChangeDetectorRef,
        readonly mapDetailService: MapDetailService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private readonly measuringPointWebApi: MeasuringPointWebApi,
        private readonly deviceWebApi: DeviceWebApi,
        private readonly mapDataService: MapDataService,
        private readonly translateService: TranslateService,
        private readonly toastService: ToastService,
        private readonly navigationService: NavigationService,
    ) {

        const rights = this.globalEventsService.getCurrentRights();
        this.canCreateGroup = rights?.hasBackendRight(BackendRights.EditGroup);
    }

    //#region autocomplete
    async predictPlaces(input: string) {
        if (!input) {
            this.placePredictions = [];
            return;
        }

        this.angulartics2GoogleTagManager.eventTrack("GooglePlacesQuery", input);

        const matchDdCoordinate = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.exec(input);
        if (matchDdCoordinate) {
            const coordinates = input.split(",").map(x => Number.parseFloat(x));
            this.splitMap.gmap.basicMap.setCenter({ lat: coordinates[0], lng: coordinates[1] });
            this.placesAutoComplete.loading = false;
            return;
        }

        const autocompleteCallback = async (autocompletePredictions: google.maps.places.AutocompletePrediction[]) => {
            // On bad or no result, this can be null
            // Fill it in anyway so we query our internal data (MPs, devices, ...)
            if (!autocompletePredictions) autocompletePredictions = [];

            const formatLabel = (label: string): string => {
                const partToBoldIndex = label.toLocaleLowerCase().indexOf(input.toLowerCase());
                if (partToBoldIndex < 0) return label;
                return `${label.substr(0, partToBoldIndex)}<b>${label.substr(partToBoldIndex, input.length)}</b>${label.substr(partToBoldIndex + input.length)}`;
            };

            let placePredictions = new Array<PlacePrediction>();

            if ((this.splitMap.gmap.mapDetail === MapDetail.MeasuringPoints || this.splitMap.gmap.mapDetail === MapDetail.MeasuringPointGroups) && this.splitMap.gmap.getLoadedMeasuringPointLocations) {

                // fetch from API
                const searchParameters = new SearchParameters();
                searchParameters.filter = [new FilterDescriptor("code", input)];
                searchParameters.take = 5;

                const measuringPointPredictions = await this.measuringPointWebApi.search$(searchParameters, null, true).toPromise();

                for (const measuringPoint of measuringPointPredictions.data) {
                    const analysisTypes = [measuringPoint.analysisTypeId];
                    let icon = "marker";

                    const order: { key: AnalysisType, iconOverride?: string }[] = [
                        { key: AnalysisType.BikeXPos },
                        { key: AnalysisType.CarXPos },
                        { key: AnalysisType.Anpr },
                        { key: AnalysisType.CarAndBike },
                        { key: AnalysisType.Bike },
                        { key: AnalysisType.Car },
                        { key: AnalysisType.Intersection },
                        { key: AnalysisType.Aggregated },
                        { key: AnalysisType.Vms },
                        { key: AnalysisType.Light },
                        { key: AnalysisType.Cctv },
                        { key: AnalysisType.TotemDisplay },
                        { key: AnalysisType.ExternalInput },
                        { key: AnalysisType.FloatingCar },
                        { key: AnalysisType.Parking },
                        { key: AnalysisType.Radar },
                        { key: AnalysisType.Pedestrian },
                        { key: AnalysisType.RailTransport },
                        { key: AnalysisType.Tracker },
                    ];

                    for (const typeOrder of order) {
                        if (analysisTypes.contains(typeOrder.key)) {
                            icon = typeOrder.iconOverride || typeOrder.key.toCamelCase();
                            break;
                        }
                    }

                    const locationMarker = this.splitMap.gmap.getLocationMarkerById(measuringPoint.locationId);
                    if (!locationMarker) continue;

                    placePredictions.push({
                        id: measuringPoint.id,
                        markerContext: locationMarker.context,
                        label: measuringPoint.code,
                        htmlLabel: formatLabel(measuringPoint.code),
                        icon: icon
                    });
                }
            }

            if (this.splitMap.gmap.mapDetail === MapDetail.MeasuringPointGroups && this.splitMap.gmap.getGroupSummaries) {
                const groupPredictions = this.splitMap.gmap.getGroupSummaries
                    .filter(x => x.code && x.code.contains(input))
                    .slice(0, 3);

                for (const group of groupPredictions) {
                    placePredictions.push({
                        id: group.id,
                        group: group,
                        label: group.code,
                        htmlLabel: formatLabel(group.code),
                        icon: "groups"
                    });
                }
            }

            if (this.splitMap.gmap.mapDetail === MapDetail.Devices) {
                // fetch from API
                const searchParameters = new SearchParameters();
                searchParameters.filter = [new FilterDescriptor("code", input)];
                searchParameters.take = 5;

                const devicePredictions = await this.deviceWebApi.search$(searchParameters, null, true).toPromise();

                for (const device of devicePredictions.data) {

                    const locationMarker = this.splitMap.gmap.getLocationMarkerById(device.currentLocationId);
                    if (!locationMarker) continue;

                    placePredictions.push({
                        id: device.id,
                        markerContext: locationMarker.context,
                        label: device.code,
                        htmlLabel: formatLabel(device.code),
                        icon: `device-${device.typeId}`
                    });
                }
            }

            if (this.splitMap.gmap.mapDetail === MapDetail.Organizations) {
                const organizationPredictions = this.mapDataService.organizations.filter(x => x.name.contains(input));

                for (const organization of organizationPredictions) {
                    const locationMarker = this.splitMap.gmap.getLocationMarkerById(organization.location.id);
                    if (!locationMarker) continue;

                    placePredictions.push({
                        id: organization.id,
                        markerContext: locationMarker.context,
                        label: organization.name,
                        htmlLabel: formatLabel(organization.name),
                        icon: "organizations"
                    });
                }
            }

            // We order on length
            // This means more relevant results will be on top
            // Ex: looking for "As", it makes sense if the shortest hit ("As") is on top
            placePredictions = placePredictions.sortBy(x => x.label.length);

            for (const autocompletePrediction of autocompletePredictions.slice(0, 3)) {
                placePredictions.push({
                    placeId: autocompletePrediction.place_id,
                    label: autocompletePrediction.description,
                    htmlLabel: formatLabel(autocompletePrediction.description),
                    icon: "marker"
                });
            }

            this.placePredictions = placePredictions;
            this.cd.detectChanges();
        };

        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({
            input: input,
        }, autocompleteCallback);
    }

    public repredictPlaces() {
        if (!this.placesAutoComplete || !this.placesAutoComplete.overlayVisible) return;

        this.predictPlaces(this.placesAutoComplete.inputEL.nativeElement.value);
        this.showPlacePredictions();
    }

    showPlacePredictions() {
        if (this.placePredictions && this.placePredictions.length) {
            this.placesAutoComplete.suggestions = this.placePredictions;
            this.placesAutoComplete.overlayVisible = true;
        }
    }

    selectFirstPlace(e: KeyboardEvent = null) {
        if (e && e.key !== "Enter") return;

        // If we have an option highlighted, the control selected it
        // We're purely trying to select the first if none other are selected
        if (this.placesAutoComplete.highlightOption) return;
        if (!this.placePredictions) return;

        this.selectPlace(this.placePredictions.takeFirstOrDefault());

        (this.placesAutoComplete.inputEL.nativeElement as HTMLElement).blur();
        this.placesAutoComplete.hide();
    }

    protected handleSelect(ev: AutoCompleteSelectEvent) {
        this.selectPlace(ev.value as PlacePrediction);
    }

    selectPlace(placePrediction: PlacePrediction) {
        if (!placePrediction) return null;

        if (placePrediction.markerContext) {
            this.splitMap.gmap.basicMap.setZoom(17);
            this.splitMap.gmap.centerOnContext(placePrediction.markerContext);

            // When selecting a place, we don't use selectionService
            // We simply navigate to the marker, and keep track of the context
            // This is then used in map-detail-popup to scroll the popup if required
            this.selectedPlace = placePrediction;
            this.splitMap.gmap.selectContext(placePrediction.markerContext);

            return;
        }

        if (placePrediction.group) {
            this.splitMap.gmap.groupSelect.emit(placePrediction.group);
            return;
        }

        if (placePrediction.placeId) {
            const placesCallback = (place: google.maps.places.PlaceResult) => {
                if (!place || !place.geometry) return;

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    this.splitMap.gmap.basicMap.fitBounds(place.geometry.viewport);
                } else {
                    this.splitMap.gmap.basicMap.setCenter(place.geometry.location);
                    this.splitMap.gmap.basicMap.zoomToNoCluster();
                }
            };

            const placesService = new google.maps.places.PlacesService(this.splitMap.gmap.map);

            placesService.getDetails({
                placeId: placePrediction.placeId
            }, placesCallback);

            return;
        }
    }

    //#endregion autocomplete


    //#region groups

    createGroup(toClone: IGroup = null) {
        const groupForMeasuringPoints = {
            description: toClone?.description,
            color: toClone?.color ?? "#00FF7F",
            measuringPoints: toClone?.measuringPoints.clone() ?? [],
            code: toClone ? `${toClone.code} - ${this.translateService.instant("words.copy")}` : "",
            ownerId: toClone?.ownerId
        } as IGroup;

        const groupSummary = {
            color: groupForMeasuringPoints.color
        } as IGroupSummary;

        const groupPolygon = this.splitMap.gmap.addGroupPolygon(groupSummary);
        groupPolygon.show();

        const onCreate = (group: IGroup) => {
            this.splitMap.groupsComponent.reload();
            this.splitMap.gmap.setEditGroup(group);
        };

        const onCancel = () => {
            this.splitMap.gmap.removeGroupPolygon(groupPolygon);
            this.splitMap.gmap.setEditGroup(null);
        };

        this.splitMap.gmap.setEditGroup(groupForMeasuringPoints);
        this.manageGroupComponent.create(groupForMeasuringPoints, onCreate, onCancel);
    }

    async editGroup(group: IGroup) {
        // Activating the "groupForMeasuringPoints" will bring us here
        // We need to stop it, we're not editing; we're creating.
        if (!group.id) return;

        if (this.mapDataService.editGroup && this.mapDataService.editGroup.id !== group.id) {
            this.manageGroupComponent.cancel();
        }

        // group = await this.groupApi.get$(group.id).toPromise();

        this.splitMap.gmap.setEditGroup(group, true);

        const onEdit = () => {
            this.splitMap.gmap.clearGroupMarkers();
            this.splitMap.removeFromCacheGroup(group);
            this.splitMap.groupsComponent.deselectRow(group);
            this.splitMap.groupsComponent.reload();
        };

        const onCancel = () => {
            this.splitMap.gmap.clearGroupMarkers();
            this.splitMap.gmap.setEditGroup(null);
            this.splitMap.groupsComponent.deselectRow(group);
        };

        this.manageGroupComponent.edit(group, onEdit, onCancel);
    }

    //#endregion groups

    openScanner() {
        this.scannerDialog.show(ScanMode.ScanDevice, (result) => {
            this.deviceWebApi.getByQrCode$(result).subscribe({
                next: (device) => {
                    this.navigationService.toDevice(device.id);

                },
                error: () => {
                    this.toastService.error(this.translateService.instant("device.notFound"));
                }
            });
        });
    }
}
