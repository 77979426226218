import { TableColumn, FilterType, ColumnType, ColumnVisibility, TableService, LazyTableComponent } from "../table/table.component";
import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef, OnDestroy } from "@angular/core";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { MeasuringPointWebApi } from "src/app/resource/web";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ViewModelEnum } from "src/app/models/domain-data";
import { ModalService } from "src/app/services/modal.service";
import { Rights } from "src/app/models/rights";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IMeasuringPointSummary } from "src/app/models/web";
import { IGroup, IGroupMeasuringPoint } from "src/app/models/group";
import { AnalysisType } from "src/app/models/measuring-point";
import { firstValueFrom } from "rxjs";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-measuring-points",
    templateUrl: "./measuring-points.component.html"
})
export class MeasuringPointsComponent extends LazyTableComponent<IMeasuringPointSummary> implements OnInit, OnChanges, IComponentCanDeactivate, OnDestroy {
    @Input() locationNavigation = true;
    @Input() organizationNavigation = true;
    @Input() deviceNavigation = true;
    @Input() canEditDeviceLink = true;
    @Input() groupNavigation = true;
    @Input() readQueryParams = true;
    @Input() locationId: number;
    @Input() group: IGroup;
    @Input() requiresInput = false;
    @Input() deleteCommand = false;
    @Input() ownerId: number;
    @Input() projectId: number;

    measuringPointsInGroup = new Array<string>();

    private analysisTypes: ViewModelEnum[];
    private readonly organizationColumn: TableColumn;

    private previousRowCount: number;
    private navigatingToLocationId: number;

    rights: Rights;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        measuringPointWebApi: MeasuringPointWebApi,
        private readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly modalService: ModalService,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService,
        private readonly changeGuardService: ChangeGuardService) {

        super("measuring-points.component", elementRef, measuringPointWebApi, tableService);

        this.stretchHeight = false;
        // this.footer = false;
        this.rights = this.globalEventsService.getCurrentRights();

        const analysisTypeIdColumn = new TableColumn("analysisTypeIds", "", { filterType: FilterType.MultiSelect, sortable: true, type: ColumnType.Icon });
        this.addColumn(analysisTypeIdColumn);
        this.addColumn(new TableColumn("locationCode", "measuringPoints.location", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact }));

        const codeColumn = new TableColumn("code", "measuringPoints.code", { filterType: FilterType.Text, sortable: true });
        this.addColumn(codeColumn);
        this.setMainColumn(codeColumn);

        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("fromTo", "measuringPoints.fromTo", { filterType: FilterType.None, visibility: ColumnVisibility.HideMini }));

        this.addColumn(new TableColumn("device", "general.device", { filterType: FilterType.None, sortable: false, visibility: ColumnVisibility.HideMini, width: 150, resizable: true }));
        this.organizationColumn = new TableColumn("ownerId", "general.organization", { filterType: FilterType.Dropdown, sortable: true, visibility: ColumnVisibility.HideCompact, width: 250, resizable: false, displayDropdownFilter: true });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (measuringPoint) => this.delete(measuringPoint),
            validFunc: () => this.deleteCommand && this.rights.hasBackendRight(BackendRights.DeleteMeasuringPoint)
        });

        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            this.updateOrganizationColumn();
            this.organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.domainDataService.get(DomainData.AnalysisType).then(analysisTypes => {
            analysisTypeIdColumn.filterOptions = analysisTypes;
            this.analysisTypes = analysisTypes;
        });
    }

    private updateOrganizationColumn() {
        if (!this.organizationColumn) return;

        // Shared data - possible to see MPs from multiple organizations
        // If so, we show the organization column
        // if retrieved organizations > 1, that means the organization has ties to others (through sharing)
        const hasSharedData = this.services.mapDataService.organizations &&
            this.services.mapDataService.organizations.length > 1;
        if (this.globalEventsService.hasMultipleOrganizations() || hasSharedData) {
            if (this.columns.contains(this.organizationColumn)) return;

            // To the left of attachments column
            const index = this.columns.length - (this.columns.contains(this.commandsColumn) ? 2 : 1);
            this.addColumn(this.organizationColumn, index);
        } else {
            this.removeColumn(this.organizationColumn);
        }
    }

    updateRelevantColumns() {
        super.updateRelevantColumns();
        this.updateOrganizationColumn();
    }

    ngOnInit() {
        super.ngOnInit();

        // The setter of location triggers before ngOnInit
        // so we don't need this when location is filled in, it calls load on itself
        // if (!this.locationId && !this.group) {
        //     this.loadTableRows();
        // }

        if (this.readQueryParams) {
            const sub = this.route
                .queryParams
                .subscribe(params => {
                    this.navigatingToLocationId = params["locationId"];
                    this.navigateToQueryParam();
                });

            this.subscriptionManager.add("queryParamsSub", sub);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const locationIdChange = changes["locationId"];
        if (locationIdChange) {
            this.updateRowCount();
        }

        const inputChange = locationIdChange || changes["group"] || changes["requiresInput"] || changes["ownerId"] || changes["projectId"];
        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    private updateRowCount() {
        if (this.locationId) {
            this.previousRowCount = this.internalRowCount;
            this.setRowCount(9999);
        } else {
            if (this.previousRowCount) {
                this.setRowCount(this.previousRowCount);
            }
        }
    }

    canLoad(): boolean {
        return !this.requiresInput || !!this.locationId || !!this.group;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.locationId) {
            searchParameters.filter.push(new FilterDescriptor("locationId", this.locationId));
        }

        if (this.ownerId) {
            searchParameters.filter.push(new FilterDescriptor("ownerId", this.ownerId));
        }

        if (this.group) {
            searchParameters.filter.push(new FilterDescriptor("groupId", this.group.id));
        }

        if (this.projectId) {
            searchParameters.filter.push(new FilterDescriptor("projectId", this.projectId));
        }

        return searchParameters.filter.length ? searchParameters : null;
    }

    onSetData() {
        this.updateOrganizationColumn();
        this.navigateToQueryParam();
    }

    navigateToQueryParam() {
        if (this.data && this.navigatingToLocationId) {
            const location = this.data.find(x => x.locationId === this.navigatingToLocationId);

            if (location) {
                setTimeout(() => {
                    this.selectRow(location);
                }, 100);
            }

            this.navigatingToLocationId = null;
        }
    }

    delete(measuringPoint: IMeasuringPointSummary) {
        if (!this.rights.hasBackendRight(BackendRights.DeleteMeasuringPoint)) return;

        const modalBody = this.translateService.instant("measuringPoints.deleteConfirmation", { code: measuringPoint.code }) as string;
        this.modalService.delete(modalBody, async () => await this.forceDelete(measuringPoint));
    }

    private async forceDelete(measuringPoint: IMeasuringPointSummary) {
        await firstValueFrom(this.measuringPointApi.delete$(measuringPoint.id));
        // Single mp delete, we don't know whether to remove location or not
        // Reload them
        this.services.mapDataService.loadMeasuringPointLocations();
        this.reload();
    }

    getMeasuringPointAnalysisTypeTranslation(analysisTypeId: string): string {
        if (!this.analysisTypes) return null;

        const analysisType = this.analysisTypes.find(x => x.value === analysisTypeId);
        return analysisType ? analysisType.label : "";
    }

    groupTrackByFn(index: number, item: IGroupMeasuringPoint) {
        return item.group.id;
    }

    filterByAnalysisType(analysisTypes: AnalysisType[]) {
        this.relevantColumns.find(x => x.field === "analysisTypeId").filterComponent.filter(analysisTypes, "inside");
    }

    getAnalysisTypeFilter(): AnalysisType[] {
        return this.relevantColumns.find(x => x.field === "analysisTypeId").filterComponent.currentFilter as AnalysisType[];
    }
}
