<!-- <div class="row"
         *ngIf="!location">
        <app-loading-spinner></app-loading-spinner>
    </div> -->

<div class="m-card m-layout-area-body m-layout-w-actions-top">
    <ng-template #titleTemplate>
        <div class="m-tab-header-title-text">
            <span class="m-tab-header-title-text-label">{{"general.task" | translate}}</span>
            <span *ngIf="task">{{task.name}}</span>
        </div>
    </ng-template>

    <app-tabs [tabs]="tabs"
              class="m-layout-area-top"
              [selectedTab]="selectedTab"
              [relativeRouting]="true"
              [titleTemplate]="titleTemplate"
              [hidden]="!task"
              (selectedTabChange)="navigateTo($event);">
    </app-tabs>

    <router-outlet *ngIf="task"
                   (activate)="onActivate($event)"></router-outlet>
</div>

<div *ngIf="loading">
    <app-loading-spinner>
    </app-loading-spinner>
</div>