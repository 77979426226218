import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { from, map, Observable } from "rxjs";
import { LocationCreator } from "../models/location";
import { IPlannedEvent } from "../models/planned-event";
import { FilterOperator, ISearchResult, SearchParameters, ServiceRequestOptions } from "../models/search";
import { ITask, TaskCreator, TaskUpdater } from "../models/task";
import { PlannedEventApi } from "../resource/planned-event-api";
import { JsonUtils } from "../utilities/json-utils";

@Injectable({
    providedIn: "root"
})
export class TaskService {

    constructor(
        private readonly plannedEventApi: PlannedEventApi
    ) {

    }

    getCreatorFromServiceModel(taskToBeDuplicated: ITask, newName: string): TaskCreator {
        const task = JsonUtils.deepClone(taskToBeDuplicated); // to prevent modifications on passed task

        task.currentStatus.id = undefined;
        task.id = undefined;

        const taskCreator = Object.assign(new TaskCreator(), task) as TaskCreator;
        taskCreator.assignmentId = task.assignment.id;

        taskCreator.locationId = undefined;
        taskCreator.location = Object.assign(new LocationCreator(), task.location) as LocationCreator;

        taskCreator.name = newName;
        taskCreator.location.code = newName;

        return taskCreator;
    }

    getUpdaterFromServiceModel(taskToBeDuplicated: ITask): TaskUpdater {
        const task = JsonUtils.deepClone(taskToBeDuplicated); // to prevent modifications on passed task

        task.currentStatus.id = undefined;

        const taskUpdater = Object.assign(new TaskUpdater(), task) as TaskUpdater;
        taskUpdater.assignmentId = task.assignment.id;

        return taskUpdater;
    }
}
