import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { UploadsComponent } from "src/app/modules/shared/components/uploads/uploads.component";
import { IMeasuringPoint } from "src/app/models/measuring-point";

@Component({
    selector: "app-measuring-point-upload",
    templateUrl: "./measuring-point-upload.component.html"
})
export class MeasuringPointUploadComponent {
    @ViewChild(UploadsComponent, { static: true }) uploadsComponent: UploadsComponent;

    @Input() measuringPoint: IMeasuringPoint;

    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.measuringPoint = measuringPoint;
    }
}