<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          id="editLocationDialog">

    <p-header>
        {{(existingLocation ? "manageLocation.edit" : "manageLocation.create") | translate}}
    </p-header>

    <app-location-dialog-content *ngIf="visible"
                                 [onlyShowMap]="onlyShowMap"
                                 [initialCoordinate]="initialCoordinate"
                                 [existingLocation]="existingLocation"
                                 [manageOrganization]="manageOrganization"
                                 [requiresInput]="true"
                                 [isCodeRequired]="isCodeRequired"
                                 (created)="handleCreated($event)"
                                 (updated)="handleUpdated($event)"
                                 (cancel)="close()"></app-location-dialog-content>
</p-dialog>
