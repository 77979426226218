import { Component, Input } from "@angular/core";
import { IScenario } from "src/app/models/scenario";

@Component({
    selector: "app-scenarios-pinned-data",
    templateUrl: "./scenarios-pinned-data.component.html",
})
export class ScenariosPinnedDataComponent {

    @Input() scenarios: IScenario[];

    constructor() { }
}
