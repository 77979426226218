<div class="m-page-content-wrapper p-16">
    <form [formGroup]="sharedKeyForm"
          class="row m-fixed-height"
          novalidate>

        <div class="col-4 m-fixed-height">
            <div class="m-card m-fixed-height"
                 #column1>
                <app-loading-spinner *ngIf="loading"></app-loading-spinner>

                <div class="m-fixed-height m-layout-w-actions-bottom"
                     [hidden]="loading">
                    <div class="m-layout-area-body m-layout-w-actions-top">
                        <div class="m-layout-area-top">
                            <div class="p-16 m-border-bottom">
                                <h3>{{"sharedKeys.title" | translate}}</h3>
                            </div>

                            <div class="p-16">
                                <div class="m-form-group row"
                                     *ngIf="sharedKeyForm.get('ownerId')"
                                     [ngClass]="{ 'has-error': sharedKeyForm.get('ownerId').showErrors }">

                                    <label class="col-4"
                                           for="ownerId">
                                        {{"general.organization" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-dropdown id="ownerId"
                                                    formControlName="ownerId"
                                                    [options]="organizations"
                                                    [filter]="true"
                                                    [resetFilterOnHide]="true"
                                                    [autoDisplayFirst]="false"
                                                    placeholder="{{'general.organization' | translate }}"
                                                    [panelStyle]="{'width':'100%'}"
                                                    #ownerIdInput>
                                        </p-dropdown>

                                        <app-form-validation [control]="sharedKeyForm.get('ownerId')"
                                                             [input]="ownerIdInput">
                                        </app-form-validation>
                                    </div>
                                </div>

                                <div class="m-form-group row"
                                     [ngClass]="{ 'has-error': sharedKeyForm.get('allowValidation').showErrors }">
                                    <label class="col-4"
                                           for="allowValidation">
                                        {{"sharedKeys.allowValidation" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-inputSwitch id="allowValidation"
                                                       formControlName="allowValidation"
                                                       #allowValidationInput>
                                        </p-inputSwitch>
                                    </div>
                                </div>

                                <div class="m-form-group row"
                                     [ngClass]="{ 'has-error': sharedKeyForm.get('shareAllMeasuringPoints').showErrors }">
                                    <label class="col-4"
                                           for="shareAllMeasuringPoints">
                                        {{"sharedKeys.shareAllMeasuringPoints" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-inputSwitch id="shareAllMeasuringPoints"
                                                       formControlName="shareAllMeasuringPoints"
                                                       #shareAllMeasuringPointsInput>
                                        </p-inputSwitch>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-layout-area-body m-border-top">
                            <app-resource-selector [containingFormSubmitted]="sharedKeyForm?.submitted"
                                                   [projectSelection]="false"
                                                   [isMapOpen]="isMapOpen()"
                                                   [hidden]="sharedKeyForm.get('shareAllMeasuringPoints').value"
                                                   (toggleMap)="toggleMap($event)"
                                                   [requireAtLeastOneResourceSelected]="true"></app-resource-selector>

                        </div>
                    </div>

                    <div class="m-btn-group m-btn-group-right m-border-top px-16 py-8 m-layout-area-bottom">
                        <button class="m-btn m-btn-link"
                                [disabled]="submitting"
                                (click)="back()"
                                type="button">
                            {{"form.cancel" | translate}}
                        </button>

                        <button class="m-btn m-btn-primary"
                                [disabled]="submitting"
                                (click)="submit()"
                                type="submit">
                            <app-loading-spinner *ngIf="submitting">
                            </app-loading-spinner>
                            {{(existingSharedKey ? "sharedKeys.edit" : "sharedKeys.create") | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="!mapOpen || sharedKeyForm.get('shareAllMeasuringPoints').value"
             class="col-8 m-fixed-height m-layout-default">
            <div class="m-card m-layout-area-body m-layout-w-actions-top">
                <div class="flex justify-end m-layout-area-top">
                    <app-icon-button icon="close"
                                     [styleClass]="'m-btn m-btn-square m-btn-close'"
                                     (click)="closeMap()"></app-icon-button>
                </div>

                <app-map-selector class="m-layout-area-body"
                                  [mapDetail]="mapDetail"
                                  [componentHeight]="mapHeight"
                                  (detailComponentSet)="handleMapComponentLoad()"
                                  [ownerId]="ownerId">
                </app-map-selector>
            </div>
        </div>
    </form>
</div>