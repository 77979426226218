<form [formGroup]="predictionForm"
      autocomplete="off"
      class="m-layout-area-body"
      novalidate>

    <div class="row m-fixed-height">
        <div class="col-4 m-fixed-height">

            <div class="m-card m-fixed-height"
                 #column1>

                <app-loading-spinner *ngIf="loading"></app-loading-spinner>

                <div [hidden]="loading"
                     class="m-fixed-height m-layout-w-actions-bottom">
                    <div class="m-fixed-height m-layout-w-actions-bottom">

                        <app-resource-selector [containingFormSubmitted]="predictionForm?.submitted"
                                               [isMapOpen]="isMapOpen()"
                                               (toggleMap)="toggleMap($event)"
                                               [requireAtLeastOneResourceSelected]="true"></app-resource-selector>

                        <div class="m-layout-area-bottom">
                            <div *ngIf="calendarSettings"
                                 [ngClass]="{ 'has-error': rangeFromDateControl.showErrors }">

                                <div class="p-16 m-border-bottom m-border-top">
                                    <h3>{{"general.dates" | translate}}</h3>
                                </div>

                                <div class="row px-16 pt-16">

                                    <div class="col-4">
                                        <label class="calendar-label"
                                               [ngStyle]="isDatesInError() ? {'color': 'red'} : {}">
                                            {{"reports.fromTo" | translate}}
                                        </label>
                                    </div>

                                    <div class="col-8">
                                        <div>
                                            <p-calendar #rangeFromCalendar
                                                        id="rangeFromDate"
                                                        formControlName="from"
                                                        selectionMode="single"
                                                        [style]="{'width':'100%'}"
                                                        autoWidth="false"
                                                        [showIcon]="true"
                                                        [maxDate]="rangeToDateControl.value"
                                                        [hourFormat]="calendarSettings.hourFormat"
                                                        [dateFormat]="calendarSettings.dateFormat"
                                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                        [monthNavigator]="false"
                                                        [yearNavigator]="false"
                                                        [numberOfMonths]="1"
                                                        [yearRange]="primeComponentService.calendarYearRange"
                                                        showButtonBar="true"
                                                        #rangeFromDateInput>
                                                <ng-template pTemplate="date"
                                                             let-date>
                                                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                                                        {{date.day}}
                                                    </span>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                                                </ng-template>
                                            </p-calendar>

                                            <app-form-validation [control]="rangeFromDateControl"
                                                                 [input]="rangeFromDateInput">
                                            </app-form-validation>
                                        </div>
                                        <div class="mt-8">
                                            <p-calendar #rangeToCalendar
                                                        id="rangeToDate"
                                                        formControlName="until"
                                                        selectionMode="single"
                                                        [style]="{'width':'100%'}"
                                                        autoWidth="false"
                                                        [showIcon]="true"
                                                        [minDate]="rangeFromDateControl.value"
                                                        [maxDate]="maxDate"
                                                        [hourFormat]="calendarSettings.hourFormat"
                                                        [dateFormat]="calendarSettings.dateFormat"
                                                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                                        [monthNavigator]="false"
                                                        [yearNavigator]="false"
                                                        [numberOfMonths]="1"
                                                        [yearRange]="primeComponentService.calendarYearRange"
                                                        showButtonBar="true"
                                                        #rangeToDateInput>
                                                <ng-template pTemplate="date"
                                                             let-date>
                                                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                                                        {{date.day}}
                                                    </span>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                                                </ng-template>
                                            </p-calendar>

                                            <app-form-validation [control]="rangeToDateControl"
                                                                 [input]="rangeToDateInput">
                                            </app-form-validation>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="px-16 py-8"
                                 [ngClass]="{ 'has-error': predictionForm.get('predictExceptionalDays').showErrors }">
                                <div class="row">
                                    <label class="col-4"
                                           for="predictExceptionalDays">
                                        {{"prediction.predictExceptionalDays" | translate}}
                                    </label>

                                    <div class="col-8">
                                        <p-inputSwitch id="predictExceptionalDays"
                                                       formControlName="predictExceptionalDays"
                                                       #predictExceptionalDaysInput>
                                        </p-inputSwitch>
                                    </div>
                                </div>
                            </div>

                            <div class="px-16 py-8"
                                 [ngClass]="{ 'has-error': predictionForm.get('predictPredictedDays').showErrors }">
                                <div class="row">
                                    <label class="col-4"
                                           for="predictPredictedDays">
                                        {{"prediction.predictPredictedDays" | translate}}
                                    </label>

                                    <div class="col-8">

                                        <p-inputSwitch id="predictPredictedDays"
                                                       formControlName="predictPredictedDays"
                                                       #predictPredictedDaysInput>
                                        </p-inputSwitch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-layout-area-bottom">
                        <div class="px-16 mt-16">
                            <p-progressBar *ngIf="showProgressBar"
                                           [value]="progressCounter"></p-progressBar>
                        </div>

                        <div class="m-btn-group m-btn-group-right px-16 py-8 mt-16 m-border-top">
                            <button class="m-btn m-btn-primary"
                                    [disabled]="showProgressBar || showApplyProgressBar"
                                    (click)="submit()"
                                    type="submit">
                                {{"prediction.preview" | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="!mapOpen || projectSelectionOpen"
             class="col-8 m-fixed-height">
            <div class="m-card m-layout-w-actions-top m-fixed-height">
                <div class="flex justify-end m-layout-area-top">
                    <app-icon-button icon="close"
                                     [styleClass]="'m-btn m-btn-square m-btn-close'"
                                     (click)="closeMap()"></app-icon-button>
                </div>


                <app-map-selector class="m-layout-area-body"
                                  [mapDetail]="mapDetail"
                                  (detailComponentSet)="handleMapComponentLoad()">
                </app-map-selector>
            </div>
        </div>

        <div [hidden]="!projectSelectionOpen || mapOpen"
             class="col-8 m-fixed-height">
            <div class="m-card m-layout-w-actions-top m-fixed-height m-overflow-auto">
                <div class="flex justify-end m-layout-area-top">
                    <app-icon-button icon="close"
                                     [styleClass]="'m-btn m-btn-square m-btn-close'"
                                     (click)="closeProjectSelection()">
                    </app-icon-button>
                </div>

                <app-project-treelist [onlyMeasurementProjects]="true"
                                      (firstLevelLoaded)="onFirstLevelLoaded()"></app-project-treelist>
            </div>
        </div>

        <div [hidden]="!showPredictionModelQualities"
             class="col-8 m-fixed-height">
            <pre>{{applyMessage}}</pre>
            <div class="m-card m-fixed-height m-layout-w-actions-bottom">
                <app-prediction-request-validations class="m-layout-area-body m-layout-default"></app-prediction-request-validations>

                <div class="m-layout-area-bottom">
                    <div class="m-border-top p-16">
                        <div class="flex">
                            <app-svg-icon icon="info"
                                          class="m-btn-baseline mr-8"></app-svg-icon>

                            <span>{{(showApplyProgressBar ? "prediction.pleaseWait" : "prediction.info") | translate}}</span>
                        </div>


                        <div class="mt-8"
                             *ngIf="showApplyProgressBar">
                            <p-progressBar *ngIf="showApplyProgressBar"
                                           [value]="applyCounter || 0"></p-progressBar>
                        </div>
                    </div>

                    <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top">
                        <button class="m-btn m-btn-primary"
                                type="button"
                                [disabled]="showProgressBar || showApplyProgressBar"
                                (click)="applyPredictions()"
                                [innerHTML]="(selectionLength === 1 ? 'prediction.submitSingle' : 'prediction.submit') | translate:{ count : selectionLength} | safeHtml">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>