import { IAttachment } from "./attachment";
import { IServiceModelBase } from "./servicemodelbase";
import { IOrganization } from "./user";

export interface IWorkerBase extends IServiceModelBase {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumberWork: string;
}
export interface IWorker extends IWorkerBase {
    phoneNumberHome: string;
    nationalNumber: string;
    address: string;
    inServiceFrom: Date;
    outOfServiceFrom: Date;
    driverLicenseNumber: string;
    hasCode95: boolean;
    code95StartDate: Date;
    code95EndDate: Date;
    hasADRCertificate: boolean;
    adrStartDate: Date;
    adrEndDate: Date;
    hasVCACertificate: boolean;
    vcaStartDate: Date;
    vcaEndDate: Date;
    medicalFitnessChecked: boolean;
    medicalFitnessStartDate: Date;
    medicalFitnessEndDate: Date;
    contractTypeId: WorkerContractType;
    organizationId: number;
    organization: IOrganization;
    photos: IAttachment[];
    photoPreview: string; // FE only
    hasNightShift: boolean; // FE only - it's on assignmentcalendarevent
}

export class WorkerCreator {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumberWork: string;
    phoneNumberHome: string;
    nationalNumber: string;
    address: string;
    inServiceFrom: Date;
    outOfServiceFrom: Date;
    driverLicenseNumber: string;
    hasCode95: boolean;
    code95StartDate: Date;
    code95EndDate: Date;
    hasADRCertificate: boolean;
    aDRStartDate: Date;
    aDREndDate: Date;
    hasVCACertificate: boolean;
    vCAStartDate: Date;
    vCAEndDate: Date;
    medicalFitnessChecked: boolean;
    medicalFitnessStartDate: Date;
    medicalFitnessEndDate: Date;
    contractTypeId: WorkerContractType;
    canDoControl: boolean;
    organizationId: number;
    // photos: IAttachment[];
}

export enum WorkerContractType {
    Temporary = "temporary",
    Permanent = "permanent",
    IBO = "iBO",
    Interim = "interim"
}
