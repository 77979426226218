<div class="m-layout-area-body m-overflow-auto">
    <form [formGroup]="statusForm"
          class="p-16"
          *ngIf="statusForm"
          autocomplete="off"
          style="display:grid; grid-template-columns: repeat(4, min-content); gap:16px; align-items: center;">

        <label for="from">
            {{"general.from" | translate}}
        </label>

        <div style="min-width: 120px; margin-right: 16px;">
            <p-calendar id="rangeFromDate"
                        formControlName="from"
                        selectionMode="single"
                        autoWidth="false"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [dateFormat]="calendarSettings.dateFormat"
                        [yearRange]="primeComponentService.calendarYearRange"
                        [showIcon]="true"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [maxDate]="maxFromDate"
                        [numberOfMonths]="1"
                        (onSelect)="submit()"
                        (onClearClick)="submit()"
                        showButtonBar="true"
                        #rangeFromDateInput>
                <ng-template pTemplate="date"
                             let-date>
                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                        {{date.day}}
                    </span>
                </ng-template>
                <ng-template pTemplate="footer">
                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                </ng-template>
            </p-calendar>
            <app-form-validation [control]="rangeFromDateControl"
                                 [input]="rangeFromDateInput">
            </app-form-validation>
        </div>

        <label for="to">
            {{"general.until" | translate}}
        </label>

        <div style="min-width: 120px; margin-right: 16px;">
            <p-calendar id="rangeUntilDate"
                        formControlName="until"
                        selectionMode="single"
                        autoWidth="false"
                        [showIcon]="true"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [dateFormat]="calendarSettings.dateFormat"
                        [yearRange]="primeComponentService.calendarYearRange"
                        [numberOfMonths]="1"
                        (onSelect)="submit()"
                        (onClearClick)="submit()"
                        showButtonBar="true"
                        #rangeUntilDateInput>
                <ng-template pTemplate="date"
                             let-date>
                    <span [ngClass]="dataDaysService.getCssStyle(date)">
                        {{date.day}}
                    </span>
                </ng-template>
                <ng-template pTemplate="footer">
                    <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                </ng-template>
            </p-calendar>
            <app-form-validation [control]="rangeUntilDateControl"
                                 [input]="rangeUntilDateInput">
            </app-form-validation>
        </div>
    </form>


    <div class="p-16">
        <h2>{{"deviceStatus.batteryHistory" | translate}}</h2>
    </div>
    <app-loading-spinner id="status_status_tab_spinner"
                         *ngIf="loading"></app-loading-spinner>
    <app-chart *ngIf="chartConfiguration; else noPersistedBatteryHistory"
               class="p-8"
               [configuration]="chartConfiguration"
               [height]="150">
    </app-chart>

    <ng-template #noPersistedBatteryHistory>
        <p *ngIf="!loading"
           class="ml-16"
           id="no-persisted-status-message">{{"deviceStatus.noPersistedBatteryHistory" | translate}}</p>
    </ng-template>

    <ng-container>
        <div class="p-16">
            <h2>{{"deviceStatus.locationHistory" | translate}}</h2>
        </div>

        <div class="p-16">
            <app-loading-spinner *ngIf="loading"></app-loading-spinner>
            <div style="display: inline-flex; width: 100%;">
                <img *ngIf="!loading && locationHistoryImagePreview"
                     [attr.src]="locationHistoryImagePreview | safeHtml"
                     style="max-width: 500px; max-height: 500px"
                     class="col-8">
                <div *ngIf="lastLocationsCompared && showComparation"
                     class="col-6">

                    <div *ngIf="lastLocationsCompared.gpsLocation">
                        <div class="col-12">
                            <h3>{{"deviceStatus.lastGPSLocation" | translate}} ({{lastLocationsCompared.timestampGps | momentDateTime:false:true:translateService.currentLang}})</h3>
                        </div>
                        <div class="col-12">
                            <a [href]="'http://maps.google.com/maps?z=12&t=m&q=loc:'+lastLocationsCompared.gpsLocation.coordinate.latitude+'+'+lastLocationsCompared.gpsLocation.coordinate.longitude"
                               target="_blank"
                               style="color:#1F497D"><u>
                                    ({{lastLocationsCompared.gpsLocation.coordinate.latitude.toFixed(6)}} , {{lastLocationsCompared.gpsLocation.coordinate.longitude.toFixed(6)}})
                                </u></a>
                        </div>
                        <div class="col-12">{{lastLocationsCompared.gpsAddress}}</div>
                    </div>

                    <div *ngIf="lastLocationsCompared.linkedLocation">
                        <div class="col-12">
                            <h3>{{"deviceStatus.linkedLocation" | translate}}</h3>
                        </div>
                        <div class="col-12">
                            <a [href]="'http://maps.google.com/maps?z=12&t=m&q=loc:'+lastLocationsCompared.linkedLocation.coordinate.latitude+'+'+lastLocationsCompared.linkedLocation.coordinate.longitude"
                               target="_blank"
                               style="color:#1F497D"><u>
                                    ({{lastLocationsCompared.linkedLocation.coordinate.latitude.toFixed(6)}} , {{lastLocationsCompared.linkedLocation.coordinate.longitude.toFixed(6)}})
                                </u></a>
                        </div>
                        <div class="col-12"
                             *ngIf="lastLocationsCompared.linkedLocation.address">{{lastLocationsCompared.linkedLocation.address?.line1}},<br>
                            {{lastLocationsCompared.linkedLocation.address?.zipCode}} {{lastLocationsCompared?.linkedLocation.address?.city}},<br>
                            {{lastLocationsCompared.linkedLocation.address?.country}}
                        </div>
                    </div>

                    <div *ngIf="lastLocationsCompared.distanceInMeter">
                        <div class="col-12">
                            <h3>{{"deviceStatus.distanceBetweenLinkedAndLastGPS" | translate}}</h3>
                        </div>
                        <div class="col-12">
                            <h3 [ngStyle]="{'color':lastLocationsCompared.distanceInMeter>150 ? 'red' : (lastLocationsCompared.distanceInMeter>50 ?'orange':'green')}">
                                {{(lastLocationsCompared.distanceInMeter/1000).toFixed(2)}} km</h3>
                        </div>
                    </div>

                    <div *ngIf="lastLocationsCompared.cellTowerLocation">
                        <div class="col-12">
                            <h3>{{"deviceStatus.cellTowerLocation" | translate}}</h3>
                        </div>
                        <div class="col-12">
                            <a [href]="'http://maps.google.com/maps?z=12&t=m&q=loc:'+lastLocationsCompared.cellTowerLocation.coordinate.latitude+'+'+lastLocationsCompared.cellTowerLocation.coordinate.longitude"
                               target="_blank"
                               style="color:#1F497D"><u>
                                    ({{lastLocationsCompared.cellTowerLocation.coordinate.latitude.toFixed(6)}} , {{lastLocationsCompared.cellTowerLocation.coordinate.longitude.toFixed(6)}})
                                </u></a>
                        </div>
                        <div class="col-12"> {{lastLocationsCompared.cellTowerLocation.address?.line1}},
                            {{lastLocationsCompared.cellTowerLocation.address?.zipCode}} {{lastLocationsCompared.cellTowerLocation.address?.city}},
                            {{lastLocationsCompared.cellTowerLocation.address?.country}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>