import { AttachmentCreator, AttachmentUpdater, IAttachment } from "src/app/models/attachment";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DownloadFileService } from "src/app/services/download-file.service";
import { GalleriaService } from "src/app/services/galleria.service";
import { IGalleriaImage } from "../galleria/galleria.component";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { SelectItem } from "primeng/api";
import { FileUtils } from "src/app/utilities";

@Component({
    selector: "app-attachment-form",
    templateUrl: "./attachment-form.component.html"
})
export class AttachmentFormComponent implements OnChanges {
    @Input() parentFormGroup: SigncoFormGroup;
    @Input() context: { attachments: IAttachment[] };
    @Input() readonly = false;

    attachmentForms: SigncoFormArray;
    attachmentTypes: SelectItem[];
    editAttachmentFormGroup: SigncoFormGroup;
    deletedAttachments: IAttachment[];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly attachmentApi: AttachmentApi,
        private readonly galleriaService: GalleriaService,
        private readonly downloadFileService: DownloadFileService,
        private readonly domainModelFilterService: DomainModelFilterService) {

        this.domainModelFilterService.getAttachmentTypes$().then(attachmentTypes => {
            this.attachmentTypes = attachmentTypes;
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        const contextChange = changes["context"];
        const parentFormGroupChange = changes["parentFormGroup"];
        if (contextChange || parentFormGroupChange) {
            await this.initialize();
        }
    }

    private async initialize() {
        if (!this.parentFormGroup) return;

        this.deletedAttachments = [];
        this.attachmentForms = this.formBuilder.array([]) as SigncoFormArray;

        this.parentFormGroup.removeControl("attachments");
        this.parentFormGroup.addControl("attachments", this.attachmentForms);

        if (this.context && this.context.attachments) {
            for (const attachment of this.context.attachments) {
                this.addAttachmentForm(attachment);
            }
        }
    }

    addAttachmentForm(existingAttachment: IAttachment = null): SigncoFormGroup {
        const defaultType = this.attachmentTypes?.takeFirstOrDefault();

        const formGroup = this.formBuilder.group({
            id: null,
            name: ["", Validators.required],
            description: "",
            typeId: [defaultType?.value, Validators.required],
            file: null,
            isPhoto: false,
            isPreviewable: false,
            url: null,
            preview: null,
            edited: false,
            fileEdited: false
        }) as SigncoFormGroup;

        if (existingAttachment) {
            const isPhoto = FileUtils.isPhotoUrl(existingAttachment.url);
            const isPreviewable = FileUtils.isPreviewableInGalleria(existingAttachment.url);

            formGroup.patchValue({
                id: existingAttachment.id,
                name: existingAttachment.name,
                description: existingAttachment.description,
                typeId: existingAttachment.type.id,
                url: existingAttachment.url,
                preview: this.getPreview(existingAttachment),
                isPhoto: isPhoto,
                isPreviewable: isPreviewable
            });

            if (isPhoto) {
                // Instantly set the preview, then download the real thing in the background
                // This enables editing / rotation

                this.downloadFileService.downloadBlob(existingAttachment.url).then(async (downloadedFile) => {
                    // Don't overwrite if already changed
                    if (formGroup.get("preview").value === existingAttachment.url) {
                        formGroup.patchValue({
                            file: downloadedFile,
                            preview: await FileUtils.toBase64(downloadedFile.file)
                        });
                    }
                });
            }
        }

        this.attachmentForms.push(formGroup);

        if (this.readonly) {
            formGroup.disable();
        }

        return formGroup;
    }

    private getPreview(attachment: IAttachment): string {
        if (FileUtils.isPhotoUrl(attachment.url)) {
            return attachment.url;
        }

        return FileUtils.getPreviewFromFilename(attachment.url);
    }

    async addAttachmentFormsFromInput(event: { files: FileList }) {
        if (this.readonly || !event || event.files.length < 0) return;

        for (let i = 0; i < event.files.length; i++) {
            const file = event.files.item(i);

            const formGroup = this.addAttachmentForm();

            const isPhoto = FileUtils.isPhoto(file);
            formGroup.patchValue({
                file: file,
                name: FileUtils.getNameWithoutExtension(file.name),
                description: FileUtils.getNameWithoutExtension(file.name),
                isPhoto: isPhoto,
                preview: isPhoto ? await FileUtils.toBase64(file) : FileUtils.getPreviewFromFilename(file.name)
            });
        }
    }

    markAttachmentAsEdited(attachmentForm: SigncoFormGroup) {
        attachmentForm.patchValue({
            edited: true
        });
    }

    deleteAttachment(index: number) {
        const id = this.attachmentForms.controls[index].get("id").value as number;
        this.attachmentForms.removeAt(index);

        if (this.context) {
            const attachment = this.context.attachments.find(x => x.id === id);
            this.deletedAttachments.push(attachment);
        }
    }

    downloadAttachment(attachmentForm: SigncoFormGroup) {
        const url = attachmentForm.get("url").value as string;

        this.downloadFileService.downloadBlob(url).then(downloadedFile => {
            downloadedFile.save();
        });
    }

    editAttachmentImage(attachmentForm: SigncoFormGroup) {
        // If readonly, open galleria for pdfs / pictures
        if (this.readonly) {
            if (attachmentForm.get("isPreviewable").value !== true) return;

            const attachmentId = attachmentForm.get("id").value as number;
            const attachment = this.context.attachments.find(x => x.id === attachmentId);
            const attachments = this.context.attachments.filter(x => FileUtils.isPreviewableInGalleria(x.url)).sortBy(x => x.id);
            const attachmentIndex = attachments.indexOf(attachment);

            const images = attachments.map(x => ({
                source: x.url,
                previewImageSrc: x.url,
                title: x.name,
                alt: x.description
            } as IGalleriaImage));

            this.galleriaService.open(images, attachmentIndex);
            return;
        }

        // If not readonly, we edit pictures (rotate)
        if (attachmentForm.get("isPhoto").value !== true) return;
        this.editAttachmentFormGroup = attachmentForm;
    }

    stopEditingAttachmentImage() {
        this.editAttachmentFormGroup = null;
    }

    async rotatePhoto(clockwise = true) {
        const oldFile = this.editAttachmentFormGroup.get("file").value as File;
        let image = new Image();
        image.src = await FileUtils.toBase64(oldFile);

        // Needed to let image.width & image.height calculate
        setTimeout(async () => {
            image = FileUtils.rotateImage(image, oldFile.type, clockwise ? 90 : -90);
            const file = FileUtils.imageToFile(image, oldFile.name, oldFile.lastModified);
            const preview = await FileUtils.toBase64(file);

            this.editAttachmentFormGroup.patchValue({
                file: file,
                preview: preview,
                fileEdited: true
            });
        });
    }

    getFiles(): File[] {
        const files = new Array<File>();

        for (const attachmentForm of this.attachmentForms.controls) {
            const file = attachmentForm.get("file").value as File;
            files.push(file);
        }

        return files;
    }

    async uploadAttachments(context: { id: number, attachments: IAttachment[] }, creatorIdField: string) {
        const cleanupCreator = (creator: AttachmentCreator) => {

            if (creatorIdField) {
                creator[creatorIdField] = context.id;
            }

            delete (creator as any).file;
            delete (creator as any).preview;
        };

        if (this.deletedAttachments) {
            for (const deletedAttachment of this.deletedAttachments) {
                await this.attachmentApi.delete$(deletedAttachment.id).toPromise();
            }
        }

        for (const attachmentForm of this.attachmentForms.controls) {
            const id = attachmentForm.get("id").value as number;
            const file = attachmentForm.get("file").value as File;

            // New attachment
            if (!id) {
                const creator = new AttachmentCreator();
                Object.assign(creator, attachmentForm.value);
                cleanupCreator(creator);

                await this.attachmentApi.upload$(creator, file).toPromise();
            } else {
                // Unchanged, continue
                const edited = attachmentForm.get("edited").value as boolean;
                const fileEdited = attachmentForm.get("fileEdited").value as boolean;
                if (!edited && !fileEdited) continue;

                const existingAttachment = this.context.attachments.find(x => x.id === id);
                const updater = new AttachmentUpdater(existingAttachment);
                Object.assign(updater, attachmentForm.value);
                cleanupCreator(updater);

                if (!fileEdited) {
                    await this.attachmentApi.update$(updater).toPromise();
                } else {
                    await this.attachmentApi.updateWithFile$(updater, file).toPromise();
                }
            }
        }
    }

    onFileDropped(fileList: FileList) {
        this.addAttachmentFormsFromInput({ files: fileList });
    }
}
