import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";

import { BulkUploadRoutingModule } from "./bulk-upload-routing.module";

import { BulkUploadComponent } from "./components/bulk-upload/bulk-upload.component";
import { BulkUploadResultComponent } from "./components/bulk-upload-result/bulk-upload-result.component";

@NgModule({
    imports: [
        BulkUploadRoutingModule,
        SharedModule,
        MapAdvancedModule
    ],
    declarations: [
        BulkUploadComponent,
        BulkUploadResultComponent
    ]
})
export class BulkUploadModule { }