import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, OnDestroy, ElementRef } from "@angular/core";
import { IDevice, IDeviceLink, DeviceUpdater, ILinkMeasurement } from "src/app/models/device";
import { SearchParameters, FilterDescriptor, ISearchResult } from "src/app/models/search";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { UntypedFormBuilder, UntypedFormArray, Validators } from "@angular/forms";
import { IChangeGuard, ChangeGuardService } from "src/app/services/change-guard.service";
import { SigncoFormGroup } from "src/app/models/form";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager } from "src/app/utilities";
import { DeviceLinkApi } from "src/app/resource/device-link.api";
import { ToastService } from "src/app/services/toast.service";
import { DeviceApi } from "src/app/resource/device.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { AuthorizationInfo } from "src/app/models/user";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-device-display",
    templateUrl: "./device-display.component.html",
    host: { class: "m-layout-area-body m-layout-w-actions-bottom" }
})
export class DeviceDisplayComponent implements OnInit, OnChanges, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @Input() device: IDevice;

    submitting = false;
    linksLoaded = false;
    calendarSettings: CalendarSettings;
    filterForm: UntypedFormArray;
    displayForm: SigncoFormGroup;
    bikeCounterDisplayForm: SigncoFormGroup;
    qLiteConfigurationForm: SigncoFormGroup;

    isReadOnly = false;
    rights: Rights;
    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly toastService: ToastService,
        private readonly changeGuardService: ChangeGuardService,
        private readonly deviceLinkApi: DeviceLinkApi,
        private readonly primeComponentService: PrimeComponentService) {

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe(rights => {
            this.rights = rights;
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    ngOnInit() {
        this.initialize();
    }


    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return this.displayForm.pristine;
    }

    onDeactivate() { }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.initialize();
    }

    initialize() {
        this.clear();
        if (!this.device) return;

        this.bikeCounterDisplayForm = this.formBuilder.group({
            text: "",
            initialText: ""
        }) as SigncoFormGroup;

        this.qLiteConfigurationForm = this.formBuilder.group({
            enableQLiteDisplay: false,
            qLitePortName: "/dev/ttymxc3",
            qLiteCounterId: [0, Validators.required],
            qLiteCounterGroupId: [0, Validators.required],
            qLiteCounterFormat: null,
            enableQLiteText: false,
            qLiteTextId: [1, Validators.required],
            qLiteTextGroupId: [0, Validators.required]
        }) as SigncoFormGroup;

        const onLinksRetrieved = (linksResult: ISearchResult<IDeviceLink>) => {
            this.linksLoaded = false;
            const filterForm = this.formBuilder.array([]);
            const loadedMeasuringPointIds = new Array<number>();

            const measuringPointCodes = linksResult.data.selectMany<IDeviceLink, ILinkMeasurement>(x => x.measurements).map(x => x.measuringPoint).orderBy(x => x.code);

            for (const measuringPoint of measuringPointCodes) {
                if (loadedMeasuringPointIds.contains(measuringPoint.id)) continue;

                loadedMeasuringPointIds.push(measuringPoint.id);

                const existingFilter = (this.device.displayConfiguration?.bikeCounterDisplay?.filters || []).find(x => x.measuringPointIdInt === measuringPoint.id);

                const formGroup = this.formBuilder.group({
                    measuringPoint: measuringPoint,
                    measuringPointIdInt: measuringPoint.id,
                    countForwardDirection: existingFilter ? existingFilter.countForwardDirection : true,
                    countReverseDirection: existingFilter ? existingFilter.countReverseDirection : true
                }) as SigncoFormGroup;

                filterForm.push(formGroup);
            }

            this.bikeCounterDisplayForm.removeControl("filters");
            this.filterForm = filterForm;
            this.bikeCounterDisplayForm.addControl("filters", this.filterForm);
            if (this.isReadOnly) {
                this.filterForm.disable();
            }
            this.linksLoaded = true;
        };

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("Device", this.device.id)];
        this.deviceLinkApi.search$(searchParameters).subscribe(onLinksRetrieved);

        this.displayForm = this.formBuilder.group({
            bikeCounterDisplay: this.bikeCounterDisplayForm,
            qLite: this.qLiteConfigurationForm
        }) as SigncoFormGroup;

        this.displayForm.submitted = true; // instantly show errors

        this.displayForm.patchValue(this.device.displayConfiguration);
        this.displayForm.updateValueAndValidity();
        this.displayForm.markAsPristine();

        if (!this.rights.hasBackendRight(BackendRights.EditDevice)) {
            this.displayForm.disable();
            this.isReadOnly = true;
        }
    }

    clear() {
        this.bikeCounterDisplayForm = null;
        this.displayForm = null;
        this.filterForm = null;
        this.qLiteConfigurationForm = null;
    }

    reset() {
        this.initialize();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.displayForm);
        if (!isValid) return;

        const onSuccess = async (savedDevice: IDevice) => {
            this.toastService.saveSuccess();
            this.device = savedDevice;
            this.submitting = false;
            this.initialize();
        };

        const onError = () => {
            this.submitting = false;
        };

        // Merge existing device with form
        const deviceUpdater = new DeviceUpdater(this.device);
        Object.assign(deviceUpdater.displayConfiguration, this.displayForm.value);

        this.submitting = true;
        this.deviceApi.update$(deviceUpdater).subscribe(onSuccess, onError);
    }
}