import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { ChangeGuardService, IChangeGuard } from "src/app/services/change-guard.service";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { IUpload, UploadResult } from "src/app/models/upload";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager } from "src/app/utilities";
import { TranslateService } from "@ngx-translate/core";
import { SigncoFormGroup } from "src/app/models/form";
import { FilesComponent } from "src/app/modules/shared/components/files/files.component";
import { ResizeService } from "src/app/services/resize.service";
import { ErrorService } from "src/app/services/error.service";
import { ToastService } from "src/app/services/toast.service";
import { UntypedFormBuilder } from "@angular/forms";
import { UploadApi } from "src/app/resource/upload.api";

@Component({
    selector: "app-bulk-upload",
    templateUrl: "./bulk-upload.component.html"
})
export class BulkUploadComponent implements AfterViewInit, OnDestroy, IComponentCanDeactivate, IChangeGuard {
    @ViewChild(FilesComponent, { static: false }) filesComponent: FilesComponent;
    @ViewChild("column1", { static: true }) column1: ElementRef<HTMLDivElement>;

    submitting = false;
    showResult = false;
    destroyed = false;
    bulkUploadForm: SigncoFormGroup;
    successes = 0;
    warnings = 0;
    errors = 0;

    uploadResults: UploadResult[];

    private subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef,
        readonly formValidationService: FormValidationService,
        private readonly errorService: ErrorService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly uploadApi: UploadApi,
        private readonly toastService: ToastService,
        private readonly resizeService: ResizeService,
        private readonly translateService: TranslateService,
        private readonly changeGuardService: ChangeGuardService) {

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.bulkUploadForm = this.formBuilder.group({
        }) as SigncoFormGroup;
    }

    ngAfterViewInit() {
        this.errorService.redirectToStatusOnOffline = false;
    }

    ngOnDestroy() {
        this.destroyed = true;
        this.errorService.redirectToStatusOnOffline = true;
        this.toastService.suppressErrors = false;
    }

    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        return this.changeGuardService.canDeactivateCheck(this);
    }

    canDeactivateCheck(): boolean {
        return !this.submitting;
    }

    onDeactivate() {
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate(this);
    }

    fixLayout() {
        this.resizeService.trigger();
    }

    addFiles() {
        this.filesComponent.openFileDialog();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.bulkUploadForm);
        if (!isValid) return;

        if (!this.files) return;

        this.toastService.suppressErrors = true;
        this.submitting = true;
        this.fixLayout();

        this.uploadResults = [];

        const addUploadResult = (uploadResult: UploadResult) => {
            this.uploadResults = this.uploadResults.concat([uploadResult]);

            this.successes = this.uploadResults ? this.uploadResults.filter(x => x.success).length : 0;
            this.warnings = this.uploadResults ? this.uploadResults.filter(x => x.warning).length : 0;
            this.errors = this.uploadResults ? this.uploadResults.filter(x => x.error).length : 0;

            this.fixLayout();
        };

        for (const file of this.files) {

            if (this.destroyed) break;

            let uploadResult: UploadResult;

            let attemptCount = 0;
            const maxAttempts = 3;

            while (!uploadResult) {
                try {
                    uploadResult = await new Promise<UploadResult>((resolve, reject) => {

                        const onSuccess = (uploads: IUpload[]) => {
                            let vehicleCount;
                            uploads.forEach((upload) => {
                                if (upload.filename.contains(".license-plates.txt")) {
                                    resolve(new UploadResult(file.name, null, null));
                                }
                                if (!upload.vehicleCount) {
                                    return;
                                }

                                if (!vehicleCount) {
                                    vehicleCount = upload.vehicleCount;
                                } else {
                                    vehicleCount += upload.vehicleCount;
                                }
                            });

                            resolve(new UploadResult(file.name, null, !vehicleCount ? this.translateService.instant("deviceUpload.noVehiclesWarning") : null));
                        };

                        this.uploadApi.uploadBulk$(file).then(onSuccess, reject);
                    });

                } catch (ex) {

                    attemptCount++;

                    const notFound = (ex as HttpErrorResponse).status === 404;
                    if (attemptCount >= maxAttempts || notFound) {
                        const errorString = notFound ? this.translateService.instant("bulkUpload.noDeviceFound") : (await this.errorService.getErrorString(ex));
                        uploadResult = new UploadResult(file.name, errorString);
                    }
                }
            }

            addUploadResult(uploadResult);
        }

        this.toastService.suppressErrors = false;
        this.submitting = false;
        this.showResult = true;
    }

    getUploadResult(file: File): UploadResult {
        return this.uploadResults ? this.uploadResults.find(x => x.filename === file.name) : null;
    }

    get progress(): number {
        return this.uploadResults ? Number((this.uploadResults.length / this.files.length * 100).toFixed(1)) : 0;
    }

    get filesInError(): boolean {
        return this.bulkUploadForm.submitted && !this.files.length;
    }

    get files(): File[] {
        if (!this.filesComponent) return [];
        return this.filesComponent.data;
    }
}
