import { IMessage, MessageCreator } from "../models/message";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class MessageApi extends ApiService<IMessage, MessageCreator, MessageCreator> {
    getRoute(): string {
        return "Messages";
    }
}