import { HttpClient, HttpEvent, HttpEventType, HttpErrorResponse } from "@angular/common/http";
import { SubscriptionManager } from "../utilities/subscription-manager";
import { ErrorService } from "./error.service";
import { Injectable } from "@angular/core";
import { IUpload } from "../models/upload";
import { Subject } from "rxjs";

export class UploadedFile {

    constructor(public name: string) { }

    promise: Promise<IUpload>; // Promise that triggers base action promise (queued)
    promiseCreator: () => Promise<IUpload>; // Base action promise
    progress = 0; // Progress in %
    completed: boolean;
    error: HttpErrorResponse;
    errorString: string;
}

@Injectable({
    providedIn: "root"
})
export class UploadFileService {

    private readonly subscriptionManager = new SubscriptionManager();

    currentFile: UploadedFile;
    private files: UploadedFile[] = [];
    private readonly filesSubject = new Subject<UploadedFile[]>();
    private readonly progressSubject = new Subject<UploadedFile>();
    private readonly errorSubject = new Subject<UploadedFile>();

    subscribeFiles(key: string, callback?: (res: UploadedFile[]) => void) {
        const subscription = this.filesSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}files`, subscription);
    }

    subscribeProgress(key: string, callback?: (res: UploadedFile) => void) {
        const subscription = this.progressSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}progress`, subscription);
    }

    subscribeError(key: string, callback?: (res: UploadedFile) => void) {
        const subscription = this.errorSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}error`, subscription);
    }

    unsubscribe(key: string) {
        this.subscriptionManager.remove(key);
    }

    constructor(
        private readonly httpClient: HttpClient,
        private readonly errorService: ErrorService) {
    }

    mock() {
        const file1 = new UploadedFile("Test1.txt");
        file1.completed = true;
        file1.progress = 100;
        this.files.push(file1);

        const file2 = new UploadedFile("Test2.txt");
        file2.error = new HttpErrorResponse({});
        file2.errorString = "Foutmelding";
        file2.progress = 50;
        this.files.push(file2);

        const file3 = new UploadedFile("Test3.txt");
        file3.progress = 100;
        this.files.push(file3);

        this.currentFile = file3;

        this.filesSubject.next(this.files);
    }

    upload$(url: string, formData: FormData, file: File): Promise<IUpload> {
        const uploadedFile = new UploadedFile(file.name);

        uploadedFile.promiseCreator = () => this.createPromise(uploadedFile, url, formData);

        this.queueFile(uploadedFile);

        return uploadedFile.promise;
    }

    retry(uploadedFile: UploadedFile) {
        if (!uploadedFile.error) return;

        uploadedFile.progress = 0;
        uploadedFile.completed = false;
        uploadedFile.error = null;
        uploadedFile.errorString = null;

        this.queueFile(uploadedFile);
    }

    private queueFile(uploadedFile: UploadedFile) {
        if (this.isUploadInProgress()) {
            const onPreviousPromiseCompleted = () => {
                return uploadedFile.promiseCreator();
            };

            const lastFileInQueue = this.files[this.files.length - 1];

            // When mocking, this would otherwise throw error
            if (!lastFileInQueue) return;

            uploadedFile.promise = lastFileInQueue.promise.then<IUpload, IUpload>(onPreviousPromiseCompleted, onPreviousPromiseCompleted);
        } else {
            uploadedFile.promise = uploadedFile.promiseCreator();
        }

        if (!this.files.contains(uploadedFile)) {
            this.files.push(uploadedFile);
            this.filesSubject.next(this.files);
        }
    }

    isUploadInProgress(): boolean {
        return this.currentFile != null;
    }

    removeFile(file: UploadedFile) {
        this.files = this.files.remove(file);
        this.filesSubject.next(this.files);
    }

    clearCompletedFiles() {
        this.files = this.files.filter(x => !x.completed && !x.error);
        this.filesSubject.next(this.files);
    }

    private createPromise(uploadedFile: UploadedFile, url: string, formData: FormData): Promise<IUpload> {
        return new Promise<IUpload>((resolve, reject) => {

            const onSuccess = (event: HttpEvent<IUpload>) => {

                this.currentFile = uploadedFile;

                switch (event.type) {
                    case HttpEventType.UploadProgress:

                        uploadedFile.progress = Math.round(100 * event.loaded / event.total);
                        this.progressSubject.next(uploadedFile);

                        break;

                    case HttpEventType.Response:

                        uploadedFile.progress = 100;
                        uploadedFile.completed = true;
                        this.progressSubject.next(uploadedFile);

                        this.currentFile = null;

                        this.clearCompletedFiles();

                        resolve(event.body);
                        break;
                }
            };

            const onError = (error: HttpErrorResponse) => {
                if (error.status === 415 || error.status === 404) {
                    this.removeFile(uploadedFile);
                } else {
                    uploadedFile.error = error;
                    this.errorService.getErrorString(error).then(errorString => {
                        uploadedFile.errorString = errorString;
                    });
                    this.errorSubject.next(uploadedFile);
                }

                this.currentFile = null;
                reject();
            };

            return this.httpClient
                .post<IUpload>(url, formData, { reportProgress: true, observe: "events" })
                .subscribe(onSuccess, onError);
        });
    }
}