import { IBatteryDischargeEntry } from "./../../../../models/battery-discharge-table";
import { IBatteryDischargeTable, BatteryDischargeTableCreator, BatteryDischargeTableUpdater } from "src/app/models/battery-discharge-table";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { BatteryDischargeTableApi } from "src/app/resource/battery-discharge-table.api";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-battery-discharge-table-dialog",
    templateUrl: "./manage-battery-discharge-table.dialog.html"
})
export class ManageBatteryDischargeTableDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: IBatteryDischargeTable) => void;
    entriesFormArray: SigncoFormArray;
    manageBatteryDischargeTableForm: SigncoFormGroup;
    existingBatteryDischargeTable: IBatteryDischargeTable;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly batteryDischargeTableApi: BatteryDischargeTableApi,
        private readonly domainModelFilterService: DomainModelFilterService) {

        super();
    }

    create(callback?: (res: IBatteryDischargeTable) => void) {
        this.callback = callback;
        this.existingBatteryDischargeTable = null;

        this.openDialog();
    }

    edit(existingBatteryDischargeTable: IBatteryDischargeTable, callback?: (res: IBatteryDischargeTable) => void) {
        this.callback = callback;
        this.existingBatteryDischargeTable = existingBatteryDischargeTable;

        this.openDialog();
    }

    protected onOpen() {
        this.entriesFormArray = this.formBuilder.array([]) as SigncoFormArray;

        this.manageBatteryDischargeTableForm = this.formBuilder.group({
            name: ["", Validators.required],
            entries: this.entriesFormArray
        }) as SigncoFormGroup;

        if (this.existingBatteryDischargeTable) {
            for (const entry of this.existingBatteryDischargeTable.entries) {
                this.addEntry(entry);
            }

            this.manageBatteryDischargeTableForm.patchValue(this.existingBatteryDischargeTable);
        } else {
            // Add empty entry
            this.addEntry();
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageBatteryDischargeTableForm = null;
    }

    addEntry(existing: IBatteryDischargeEntry = null) {
        const entryFormGroup = this.formBuilder.group({
            chargePercentage: [null, Validators.required],
            voltage: [null, Validators.required]
        }) as SigncoFormGroup;

        if (existing) {
            entryFormGroup.patchValue(existing);
        }

        this.entriesFormArray.push(entryFormGroup);
    }

    deleteEntry(index: number) {
        this.entriesFormArray.removeAt(index);
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageBatteryDischargeTableForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedBatteryDischargeTable: IBatteryDischargeTable) => {
            if (this.existingBatteryDischargeTable) {
                Object.assign(this.existingBatteryDischargeTable, savedBatteryDischargeTable);
            }

            if (this.callback) {
                this.callback(savedBatteryDischargeTable);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingBatteryDischargeTable) {
            const batteryDischargeTableCreator = Object.assign(new BatteryDischargeTableCreator(), this.manageBatteryDischargeTableForm.value);
            this.batteryDischargeTableApi.create$(batteryDischargeTableCreator).subscribe(onSuccess, onError);
        } else {
            const batteryDischargeTableUpdater = Object.assign(new BatteryDischargeTableUpdater(this.existingBatteryDischargeTable), this.manageBatteryDischargeTableForm.value);
            this.batteryDischargeTableApi.update$(batteryDischargeTableUpdater).subscribe(onSuccess, onError);
        }
    }
}