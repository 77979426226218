import { LocationKmlCreator, LocationKmlResult } from "src/app/models/kml";
import { SigncoFormGroup, SigncoFormControl } from "src/app/models/form";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { PrimeComponentService } from "src/app/services/prime-component.service";
import { Component, inject, OnDestroy, ViewChild } from "@angular/core";
import { ProjectsService } from "src/app/services/projects.service";
import { MeasuringPointUtils, OrganizationUtils } from "src/app/utilities";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { MapDataService } from "src/app/services/map-data.service";
import { ViewModelEnum } from "src/app/models/domain-data";
import { LocationApi } from "src/app/resource/location.api";
import { SelectItem } from "primeng/api";
import { MultiSelect } from "primeng/multiselect";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IProject } from "src/app/models/project";
import { ILocationSummary } from "src/app/models/web";
import { FilterService } from "../..";
import { ProjectApi } from "src/app/resource/project.api";

@Component({
    selector: "app-upload-kml-dialog",
    templateUrl: "./upload-kml.dialog.html"
})
export class UploadKmlDialogComponent extends DialogComponentBase implements OnDestroy {
    allProjects: IProject[];
    projects: SelectItem[];
    onlyActiveProjects: boolean;
    @ViewChild("projectIdsInput", { static: false }) projectsMultiSelect: MultiSelect;

    drivingLaneControl: SigncoFormControl;
    dialogForm: SigncoFormGroup;
    uploadFile: File;
    submitting = false;
    analysisTypes: ViewModelEnum[];
    drivingLanes: SelectItem[];
    organizations: SelectItem[];
    result: LocationKmlResult[];
    showResult: boolean;
    canCreateMeasuringPointForOthers = false;
    callback: (result: ILocationSummary[]) => void;

    private readonly mapDataKey: string;
    private readonly filterService = inject(FilterService);

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        readonly formValidationService: FormValidationService,
        private readonly mapDataService: MapDataService,
        private readonly domainDataService: DomainDataService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly locationApi: LocationApi,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly projectsService: ProjectsService,
        private readonly projectApi: ProjectApi) {

        super();

        this.mapDataKey = this.mapDataService.createKey();

        this.domainModelFilterService.getDrivingLanes$().then(drivingLanes => {
            this.drivingLanes = drivingLanes;
        });

        this.mapDataService.subscribeToOrganizations(this.mapDataKey, organizations => {
            this.organizations = this.primeComponentService.createDropdownList(
                OrganizationUtils.addLevel(organizations),
                x => x.id,
                x => x.name
                , false, "", OrganizationUtils.getStyleClass);
        });

        this.domainDataService.get(DomainData.AnalysisType).then(analysisTypeViewModels => {
            this.analysisTypes = analysisTypeViewModels;
        });

        this.canCreateMeasuringPointForOthers = this.globalEventsService.hasMultipleOrganizations();
    }

    ngOnDestroy() {
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    protected onOpen() {
        this.submitting = false;
        this.uploadFile = null;
        this.hideResult();
        this.result = null;

        this.drivingLaneControl = this.formBuilder.control(null, Validators.required) as SigncoFormControl;

        this.dialogForm = this.formBuilder.group({
            prefix: "",
            projectIds: null,
            analysisTypeId: [null, Validators.required],
            onlyActiveProjects: false // we need to add this to remove warning in console
        }) as SigncoFormGroup;

        // If the user filtered on projects, we take the first project and automatically select that
        const selectedProjectId = this.filterService.filterState.projects?.takeSingleOrDefault()?.id;
        if (selectedProjectId) this.dialogForm.get("projectIds").setValue([selectedProjectId]);

        this.dialogForm.get("analysisTypeId").valueChanges.subscribe(value => {
            this.handleAnalysisTypeChange(value);
        });

        if (this.canCreateMeasuringPointForOthers) {
            const defaultOrganization = this.globalEventsService.getDefaultOrganization();
            this.dialogForm.addControl("ownerId", this.formBuilder.control(null, Validators.required));
            this.dialogForm.get("ownerId").valueChanges.subscribe(() => this.onOwnerChange());

            // select currently selected project -> this means that we have to configure correct owner
            if (selectedProjectId) {
                const currentlySelectedProject = this.allProjects.find(x => x.id === selectedProjectId);
                if (currentlySelectedProject) this.dialogForm.get("ownerId").setValue(currentlySelectedProject.organizationId);
            } else if (defaultOrganization?.id) {
                this.dialogForm.get("ownerId").setValue(defaultOrganization.id);
            }
        }
    }

    protected onClose() {
        this.dialogForm = null;
    }

    handleAnalysisTypeChange(analysisTypeId: any) {
        const requiresDrivingLane = MeasuringPointUtils.analysisTypeRequiresDrivingLane(analysisTypeId);

        if (requiresDrivingLane) {
            this.dialogForm.addControl("drivingLaneId", this.drivingLaneControl);
        } else {
            this.dialogForm.removeControl("drivingLaneId");
        }
    }

    async open(callback?: (result: ILocationSummary[]) => void): Promise<void> {

        const allProjects = await this.projectApi.getAll();

        this.allProjects = allProjects.data;
        this.projects = this.allProjects.map(x => {
            return {
                label: x.name,
                value: x.id
            } as SelectItem;
        });

        this.callback = callback;
        this.openDialog();
    }

    setUploadFile(event: { files: FileList }) {
        if (!event || event.files.length < 0) return;
        const file = event.files[0];

        const upperCaseName = file.name.toUpperCase();
        if (!upperCaseName.endsWith(".KMZ") && !upperCaseName.endsWith(".KML")) return;

        this.uploadFile = file;
    }

    hideResult() {
        this.showResult = false;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid || !this.uploadFile) return;

        const kmlCreator = new LocationKmlCreator();
        Object.assign(kmlCreator, this.dialogForm.value);
        delete kmlCreator["onlyActiveProjects"];

        this.submitting = true;

        const onSuccess = async (kmlResults: LocationKmlResult[]) => {
            this.submitting = false;

            this.result = kmlResults;
            this.showResult = true;

            // Refresh MeasuringPoints
            await this.mapDataService.loadMeasuringPointLocations();

            if (this.callback) {
                const locations = new Array<ILocationSummary>();

                for (const kmlResult of kmlResults) {
                    // We don't get id, but lat/lng will usually do
                    const location = await this.mapDataService.getMeasuringPointLocation$(x => x.code === kmlResult.locationCode && x.lat === kmlResult.coordinate?.latitude && x.lng === kmlResult.coordinate?.longitude);
                    if (location) {
                        locations.push(location);
                    }
                }

                this.callback(locations);
            }
        };

        const onError = () => {
            this.submitting = false;
        };

        this.locationApi.uploadKeyholeMarkupFile$(kmlCreator, this.uploadFile).subscribe(onSuccess, onError);
    }

    onlyActiveProjectsChanged(event: any) {
        this.onlyActiveProjects = event.target.checked;
        const currentlySelectedOrg = this.dialogForm.get("ownerId")?.value;

        if (this.onlyActiveProjects) {
            let allActiveProjects = this.projectsService.filterActiveProjects(this.allProjects) as IProject[];
            if (currentlySelectedOrg) allActiveProjects = allActiveProjects.filter(x => x.organizationId === +currentlySelectedOrg);

            this.projects = allActiveProjects
                .filter(x => currentlySelectedOrg ? x.organizationId === +currentlySelectedOrg : true)
                .map(x => {
                    return {
                        label: x.name,
                        value: x.id
                    } as SelectItem;
                });

            const currentlySelectedProjectIds = (this.dialogForm.get("projectIds").value ?? []) as Array<number>;
            const projectsIdsPresentInFilter = currentlySelectedProjectIds.filter(activeId => allActiveProjects.find(x => x.id === activeId));
            this.dialogForm.get("projectIds").setValue(projectsIdsPresentInFilter);
        } else {
            this.projects = this.allProjects.filter(x => currentlySelectedOrg ? x.organizationId === +currentlySelectedOrg : true).map(x => {
                return {
                    label: x.name,
                    value: x.id
                } as SelectItem;
            });
        }

        this.projectsMultiSelect.filterValue = ""; // clear entered filter so everything can remain consistent
    }

    onOwnerChange() {
        const currentOwnerId = this.dialogForm.get("ownerId").value;
        if (!currentOwnerId) return;

        const currentlySelectedProjects = (this.dialogForm.get("projectIds").value ?? []) as number[];
        const currentlySelectedProjectsBelongingToOrganization = this.allProjects.filter(x =>
            x.organizationId == currentOwnerId
            && currentlySelectedProjects.find(y => y === x.id));
        this.dialogForm.get("projectIds").setValue(currentlySelectedProjectsBelongingToOrganization.map(x => x.id));

        let allProjectsBelongingToOrganization = this.allProjects.filter(x => x.organizationId === +currentOwnerId);
        if (this.onlyActiveProjects) allProjectsBelongingToOrganization = this.projectsService.filterActiveProjects(allProjectsBelongingToOrganization) as IProject[];

        this.projects = allProjectsBelongingToOrganization.map((x) => {
            return {
                value: x.id,
                label: x.name
            } as SelectItem;
        });
    }
}
