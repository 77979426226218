import { IDeviceDepot, DeviceDepotCreator, DeviceDepotUpdater } from "../models/device-depot";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DeviceDepotApi extends ApiService<IDeviceDepot, DeviceDepotCreator, DeviceDepotUpdater> {

    getRoute(): string {
        return "DeviceDepots";
    }
}