import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";

import { PredictionRoutingModule } from "./prediction-routing.module";

import { PredictionComponent } from "./components/prediction/prediction.component";
import { PredictionRequestValidationsComponent } from "./components/prediction-request-validations/prediction-request-validations.component";
import { ProjectTreeListComponent } from "src/app/ui/pages/administration/components/project-treelist/project-treelist.component";

@NgModule({
    imports: [
        PredictionRoutingModule,
        SharedModule,
        MapAdvancedModule,
        ProjectTreeListComponent
    ],
    declarations: [
        PredictionComponent,
        PredictionRequestValidationsComponent
    ]
})
export class PredictionModule { }
