import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ManageWorkerDialogComponent } from "../manage-worker-dialog/manage-worker-dialog.component";
import { WorkersComponent } from "../workers/workers.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { Rights } from "src/app/models/rights";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-worker-management",
    templateUrl: "./worker-management.component.html",
})
export class WorkerManagementComponent implements OnDestroy {
    @ViewChild(ManageWorkerDialogComponent, { static: true }) manageWorkerDialog: ManageWorkerDialogComponent;
    @ViewChild(WorkersComponent, { static: true }) workersComponent: WorkersComponent;

    canEditWorker = false;
    private subscriptionManager: SubscriptionManager = new SubscriptionManager();

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly elementRef: ElementRef
    ) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canEditWorker = rights?.hasBackendRight(BackendRights.EditWorker);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    create() {
        this.manageWorkerDialog.create((worker) => {
            this.workersComponent.reload();
        });
    }
    updateComponent() {
        this.workersComponent.reload();
    }
    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
}
