<div>
    <details #popup
             (clickOutside)="closePopup()">
        <summary class="m-dropdown-summary">
            <div class="alert-badge"
                 style="display: inline-block;"
                 [ngStyle]="getBadgeStyle()"><i class="fa fa-info-circle"></i>&nbsp;{{"alertBadge." + this.data.type + ".shortTitle" | translate}}</div>
            <!--<app-svg-icon icon="delete"
                          style="display: inline-block;"
                          (click)="deleteAlert();$event.stop();"></app-svg-icon>-->
        </summary>
        <div class="m-dropdown-content"
             style="width: 210px">
            <div class="m-card m-shadow p-16">
                <dl class="location-popup__description-list">
                    <dt>{{"alertBadge.timestamp" | translate}}</dt>
                    <dd>{{data.timestamp | momentDateTime:true:true:translateService.currentLang}} ({{data.timestamp | momentRelativeTime:translateService.currentLang}})</dd>

                    <dt>{{"notifications.level" | translate}}</dt>
                    <dd>{{data.level | translateEnum:"alertLevel"}}</dd>

                    <dt>{{"general.description" | translate}}</dt>
                    <dd>{{getDescription()}}</dd>
                </dl>
            </div>
        </div>
    </details>
</div>