import { TableService, TableColumn, ColumnType, FilterType, ApiTableComponent, ModelLocalizationTable } from "src/app/modules/shared/components/table/table.component";
import { IJournalCategory, IJournalSubCategory } from "src/app/models/journal";
import { SearchParameters, FilterDescriptor } from "src/app/models/search";
import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { ManageJournalSubCategoryDialogComponent } from "./../manage-journal-sub-category-dialog/manage-journal-sub-category-dialog.component";
import { JournalSubCategoryApi } from "src/app/resource/journal-sub-category.api";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { DomainDataService } from "src/app/services/domain-data.service";

@Component({
    selector: "app-admin-journal-sub-categories",
    templateUrl: "./admin-journal-sub-categories.component.html"
})
export class AdminJournalSubCategoriesComponent extends ModelLocalizationTable<IJournalSubCategory> implements OnInit {
    @ViewChild(ManageJournalSubCategoryDialogComponent, { static: true }) manageSubCategoryDialog: ManageJournalSubCategoryDialogComponent;

    @Input() parentCategory: IJournalCategory;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translateService: TranslateService,
        readonly domainDataService: DomainDataService,
        private readonly journalSubCategoryApi: JournalSubCategoryApi,
        private readonly modalService: ModalService) {

        super("journal-sub-categories.component", elementRef, journalSubCategoryApi, tableService, domainDataService, translateService);

        this.selectionMode = "";
        this.includeObsolete = true;

        this.createColumns();
        this.registerCommands();
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        // add the parent category Id as search filter
        searchParameters.filter = [new FilterDescriptor("categoryId", this.parentCategory.id)];

        return searchParameters;
    }

    private createColumns() {

        this.clearColumns();

        // show/hide row details
        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, stringResourcePropertyName: "codeStringResourceId" }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("isObsolete", "general.isObsolete", { filterType: FilterType.Boolean, sortable: true, width: 150 }));
    }

    private registerCommands() {
        this.commands = [];

        this.registerCommand({
            text: "categories.edit",
            icon: "edit",
            click: (subCategory) => this.edit(subCategory)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (subCategory) => this.delete(subCategory),
            rowValidFunc: (subCategory) => !subCategory.isObsolete
        });

        this.registerCommand({
            text: "form.restore",
            icon: "undo",
            click: (subCategory) => this.restore(subCategory),
            rowValidFunc: (subCategory) => subCategory.isObsolete,
            ignoreSpace: true
        });
    }

    create() {
        this.manageSubCategoryDialog.create(this.parentCategory, () => {
            this.reload();
        });
    }

    edit(subCategory: IJournalSubCategory) {
        this.manageSubCategoryDialog.edit(subCategory);
    }

    async delete(subCategory: IJournalSubCategory) {

        const onDeleteSuccess = () => {
            subCategory.isObsolete = true;
        };

        const onDelete = () => {
            this.journalSubCategoryApi.delete$(subCategory.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translateService.instant(
            "categories.subcategoryDeleteconfirmation",
            { code: this.domainDataService.translate(subCategory.category.codeStringResourceId), subcategoryCode: this.domainDataService.translate(subCategory.codeStringResourceId) });

        this.modalService.delete(modalBody, onDelete);
    }

    // restore means: undelete
    restore(subCategory: IJournalSubCategory) {
        const onRestoreSuccess = () => {
            subCategory.isObsolete = false;
        };

        this.journalSubCategoryApi.restore$(subCategory.id).subscribe(onRestoreSuccess);
    }
}
