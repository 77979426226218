import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IGeoDynamicsVehicle } from "../models/geodynamics";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class GeoDynamicsApi extends ApiService<IGeoDynamicsVehicle, IGeoDynamicsVehicle, IGeoDynamicsVehicle> {
    getRoute(): string {
        return "GeoDynamics";
    }

    getAllVehicles$(): Observable<IGeoDynamicsVehicle[]> {
        return this.http.get<IGeoDynamicsVehicle[]>(this.getUrl());
    }
}