<div class="m-map-overlay-datatable"
     style="position: absolute;"
     [ngStyle]="style"
     (click)="stopEvent($event)"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     #div>

    <ng-template #loadingTemplate>
        <app-loading-spinner></app-loading-spinner>
    </ng-template>

    <ng-container *ngIf="groupMeasuringPoints; else loadingTemplate">
        <table>
            <thead>
                <tr>
                    <th>
                        <div class="flex flex-column align-start">
                            <app-svg-icon icon="arrow-right"
                                          tooltip="{{'measuringPoints.primaryDirection' | translate}}">
                            </app-svg-icon>

                            <label class="m-input-checkbox">
                                <input type="checkbox"
                                       id="forward_checkbox"
                                       [(ngModel)]="isAllForwardSelected"
                                       (change)="bulkSelectChanged('forward')" />
                            </label>
                        </div>
                    </th>
                    <th>
                        <div class="flex flex-column align-start">
                            <app-svg-icon icon="arrow-left"
                                          tooltip="{{'measuringPoints.reverseDirection' | translate}}">
                            </app-svg-icon>

                            <label class="m-input-checkbox">
                                <input type="checkbox"
                                       id="reverse_checkbox"
                                       [(ngModel)]="isAllReverseSelected"
                                       (change)="bulkSelectChanged('reverse')" />
                            </label>
                        </div>
                    </th>
                    <th>
                        <div class="flex flex-column align-start">
                            <app-svg-icon icon="add"
                                          tooltip="{{'measuringPoints.sumDirection' | translate}}">
                            </app-svg-icon>

                            <label class="m-input-checkbox">
                                <input type="checkbox"
                                       [(ngModel)]="isAllSumSelected"
                                       id="sum_checkbox"
                                       (change)="bulkSelectChanged('sum')" />
                            </label>
                        </div>
                    </th>
                    <th>
                        {{"general.measuringPoint" | translate}}
                    </th>
                    <th style="width: 1px;">
                        <app-icon-button (click)="close()"
                                         icon="close"></app-icon-button>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let groupMeasuringPoint of groupMeasuringPoints; trackBy: trackByFn">
                    <td>
                        <label class="m-input-checkbox">
                            <input type="checkbox"
                                   [(ngModel)]="groupMeasuringPoint.includeForwardDirection"
                                   (change)="updateMeasuringPointInGroup('forward')" />
                        </label>
                    </td>

                    <td>
                        <label class="m-input-checkbox">
                            <input type="checkbox"
                                   [(ngModel)]="groupMeasuringPoint.includeReverseDirection"
                                   (change)="updateMeasuringPointInGroup('reverse')" />
                        </label>
                    </td>

                    <td>
                        <label class="m-input-checkbox">
                            <input type="checkbox"
                                   [(ngModel)]="groupMeasuringPoint.includeSum"
                                   (change)="updateMeasuringPointInGroup('sum')" />
                        </label>
                    </td>

                    <td colspan="2">
                        <app-navigator [measuringPoint]="groupMeasuringPoint.measuringPoint"
                                       [enabled]="false">
                        </app-navigator>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>

</div>