<div class="m-layout-w-actions-bottom m-fixed-height m-border-top">
    <div class="p-16 m-overflow-auto m-layout-area-body">
        <div class="row"
             *ngIf="!measuringPointForm">
            <app-loading-spinner>
            </app-loading-spinner>
        </div>

        <div class="row"
             [hidden]="!measuringPointForm">

            <div class="col-3">
                <div class="m-map m-map-teaser">
                    <app-basic-map [hidden]="!measuringPoint"
                                   [saveState]="false"
                                   (mapReady)="handleMapReady($event)"></app-basic-map>
                </div>

                <div class="m-form-group mt-16"
                     *ngIf="showHeading">
                    <label class="m-input-round-slider-label"
                           for="heading">
                        {{"measuringPoints.heading" | translate}}
                    </label>

                    <div class="m-input-round-slider">
                        <input type="number"
                               id="heading"
                               class="p-inputtext hide-spinner"
                               step="1"
                               min="0"
                               max="359"
                               placeholder="{{'measuringPoints.heading' | translate}}"
                               [ngModel]="this.measuringPointForm.get('heading').value"
                               [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="updateHeading($event)"
                               [disabled]="measuringPoint.readonly"
                               #headingInput />

                        <app-round-slider #sliderElem
                                          [radius]="50"
                                          [value]="this.measuringPointForm.get('heading').value"
                                          [min]="0"
                                          [max]="360"
                                          [disabled]="measuringPoint.readonly"
                                          (change)="updateHeading($event);">
                        </app-round-slider>

                    </div>
                </div>
            </div>

            <div class="col-9">
                <form [formGroup]="measuringPointForm"
                      class="p-16"
                      *ngIf="measuringPoint && measuringPointForm"
                      autocomplete="off">

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': measuringPointForm.get('code').showErrors }">

                        <label class="col-4"
                               for="code">
                            {{"general.code" | translate}}
                        </label>

                        <div class="col-8">
                            <input type="text"
                                   class="p-inputtext"
                                   placeholder="{{'general.code' | translate}}"
                                   id="code"
                                   formControlName="code"
                                   appAutofocus="!isCreatingNew()"
                                   #codeInput />

                            <app-form-validation [control]="measuringPointForm.get('code')"
                                                 [input]="codeInput">
                            </app-form-validation>

                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': measuringPointForm.get('description').showErrors }">

                        <label class="col-4"
                               for="description">
                            {{"general.description" | translate}}
                        </label>

                        <div class="col-8">
                            <textarea id="description"
                                      class="p-inputtextarea"
                                      placeholder="{{'general.description' | translate}}"
                                      rows="1"
                                      formControlName="description"
                                      #descriptionInput></textarea>

                            <app-form-validation [control]="measuringPointForm.get('description')"
                                                 [input]="descriptionInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': measuringPointForm.get('analysisTypeId').showErrors }">

                        <label class="col-4"
                               for="analysisTypeId">
                            {{"measuringPoints.analysisType" | translate}}
                        </label>

                        <div class="col-8 m-map-filters-chips">
                            <button class="m-map-filters-chip"
                                    (click)="openAnalysisTypeDialog()"
                                    id="buttonSelectAnalysisType"
                            >
                                @if(measuringPointForm.get('analysisTypeId').value){
                                <app-svg-icon [icon]="measuringPointForm.get('analysisTypeId').value">
                                </app-svg-icon>
                                {{ measuringPointForm.get('analysisTypeId').value | translateEnum:"analysisType" }}
                                }@else {
                                {{'measuringPointDetailTab.selectAnalysisType' | translate }}
                                }
                            </button>

                            <app-form-validation [control]="measuringPointForm.get('analysisTypeId')"
                                                 [input]="analysisTypeIdInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row  has-arrow"
                         *ngIf="!measuringPointForm.get('from').hidden">

                        <label class="col-4"
                               for="from">
                            {{"general.from" | translate}}
                        </label>

                        <div class="col-8">
                            <input type="text"
                                   placeholder="{{'general.from' | translate}}"
                                   id="from"
                                   class="p-inputtext"
                                   [ngClass]="{ 'has-error': measuringPointForm.get('from').showErrors }"
                                   formControlName="from"
                                   #fromInput />

                            <app-form-validation [control]="measuringPointForm.get('from')"
                                                 [input]="fromInput">
                            </app-form-validation>
                        </div>

                        <label class="col-4 mt-8"
                               for="to">
                            {{"general.to" | translate}}
                        </label>

                        <div class="col-8 mt-8">
                            <input type="text"
                                   placeholder="{{'general.to' | translate}}"
                                   id="to"
                                   class="p-inputtext"
                                   [ngClass]="{ 'has-error': measuringPointForm.get('to').showErrors }"
                                   formControlName="to"
                                   #toInput />

                            <app-form-validation [control]="measuringPointForm.get('to')"
                                                 [input]="toInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         *ngIf="!measuringPointForm.get('drivingLaneId').hidden"
                         [ngClass]="{ 'has-error': measuringPointForm.get('drivingLaneId').showErrors }">

                        <label class="col-4"
                               for="drivingLaneId">
                            {{"measuringPoints.drivingLane" | translate}}
                        </label>

                        <div class="col-8">
                            <p-dropdown id="drivingLaneId"
                                        [options]="drivingLanes"
                                        [panelStyle]="{'width':'100%'}"
                                        formControlName="drivingLaneId"
                                        [autoDisplayFirst]="false"
                                        placeholder="{{'measuringPointDetailTab.selectDrivingLane' | translate }}"
                                        #drivingLaneIdInput>
                            </p-dropdown>

                            <app-form-validation [control]="measuringPointForm.get('drivingLaneId')"
                                                 [input]="drivingLaneIdInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': measuringPointForm.get('projectIds').showErrors }">
                        <label class="col-4"
                               for="projectIds">
                            {{"general.project" | translate}}
                        </label>
                        <div class="col-8">
                            <p-multiSelect formControlName="projectIds"
                                           appendTo="body"
                                           placeholder="{{'assignment.selectProject' | translate }}"
                                           [style]="{'width':'100%'}"
                                           [options]="projects"
                                           [filter]="true"
                                           [showHeader]="true"
                                           [showToggleAll]="false"
                                           panelStyleClass="measuring-point-projects"
                                           #projectIdsInput>

                                <ng-template pTemplate="header">
                                    <div style="display: flex; flex-direction:row; justify-content: end; width: 100%; margin-bottom: 5px; grid-column: span 2">
                                        <label class="mr-8">
                                            {{"projects.onlyActive" | translate}}
                                        </label>
                                        <label class="m-input-checkbox"
                                               style="align-items:center!important">
                                            <input type="checkbox"
                                                   id="only_active_projects_checkbox"
                                                   formControlName="onlyActiveProjects"
                                                   (change)="onlyActiveProjectsChanged($event)" />
                                        </label>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="selectedItems"
                                             let-items>
                                    <div *ngIf="allProjects">
                                        <ng-container *ngFor="let selectedProjectId of items;let i = index">
                                            <p>{{selectedProject(selectedProjectId)?.name}}<span *ngIf="i !== items.length - 1">,</span></p>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </p-multiSelect>

                            <app-form-validation [control]="measuringPointForm.get('projectIds')"
                                                 [input]="projectIdsInput">
                            </app-form-validation>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div *ngIf="!measuringPoint?.readonly"
             class="m-btn-group m-btn-group-right">

            <button class="m-btn m-btn-link"
                    (click)="createCopy()"
                    type="button"
                    *ngIf="!isCreatingNew()"
                    id="createCopyOfMeasuringPointButton">
                <app-svg-icon icon="copy"> </app-svg-icon>
                {{"measuringPointDetailTab.duplicate" | translate}}
            </button>

            <button class="m-btn m-btn-link"
                    (click)="createReverse()"
                    type="button"
                    *ngIf="!isCreatingNew()"
                    id="createReverseButton">
                {{"manageMeasuringPoint.reverse" | translate}}
            </button>

            <button class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">

                <ng-container *ngIf="!isCreatingNew(); else cancelText">
                    {{"form.reset" | translate}}
                </ng-container>

                <ng-template #cancelText>
                    {{"form.cancel" | translate}}
                </ng-template>
            </button>

            <button [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit"
                    id="submitButton">

                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>

<app-types-filter-dialog [multiSelection]="false"
                         [analysisTypeView]="analysisTypeView"
                         (saved)="setAnalysisType($event)"></app-types-filter-dialog>
