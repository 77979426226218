
import { Injectable } from "@angular/core";
import { GlobalEventsService } from "../services/global-events-service";
import { Observable, catchError, map } from "rxjs";


@Injectable()
export class MultipleOrganizationsGuard {

    constructor(
        private readonly globalEventsService: GlobalEventsService) {
    }
    canActivate(): Observable<boolean> {
        return this.globalEventsService.authorizationInfo$
            .pipe(
                map((e) => {
                    if (e != null) {
                        const canActivate = this.globalEventsService.hasMultipleOrganizations();
                        return canActivate;
                    }
                })
            );
    }
}