import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, Type, SimpleChanges } from "@angular/core";
import { MeasuringPointExternalInputConfigurationComponent } from "../measuring-point-external-input-configuration/measuring-point-external-input-configuration.component";
import { TotemDisplayConfigurationComponent } from "../measuring-point-totem-display/totem-display-configuration/totem-display-configuration.component";
import { MeasuringPointUploadContainerComponent } from "../measuring-point-upload-container/measuring-point-upload-container.component";
import { MeasuringPointFloatingCarComponent } from "../measuring-point-floating-car/measuring-point-floating-car.component";
import { MeasuringPointParkingComponent } from "../measuring-point-parking/measuring-point-parking.component";
import { MeasuringPointDetailComponent } from "../measuring-point-detail/measuring-point-detail.component";
import { MeasuringPointLinksComponent } from "../measuring-point-links/measuring-point-links.component";
import { MeasuringPointValidationComponent } from "../measuring-point-validation/measuring-point-validation.component";
import { AuditTrailsComponent } from "src/app/modules/shared/components/audit-trails/audit-trails.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { JournalComponent } from "src/app/modules/shared/components/journal/journal.component";
import { TranslateService } from "@ngx-translate/core";
import { MeasuringPointStatusComponent } from "../measuring-point-status/measuring-point-status.component";
import { MeasuringPointCountingZoneConfigurationComponent } from "../measuring-point-counting-zone-configuration/measuring-point-counting-zone-configuration.component";
import { MeasuringPointLightModeConfigurationComponent } from "../measuring-point-light-mode-configuration/measuring-point-light-mode-configuration.component";
import { SelectItem } from "primeng/api";
import { IProject } from "src/app/models/project";
import { MeasuringPointCctvConfigurationComponent } from "../measuring-point-cctv-configuration/measuring-point-cctv-configuration.component";
import { AnalysisType, IMeasuringPoint } from "src/app/models/measuring-point";
import { BackendRights } from "src/app/models/backend-rights";
import { MeasuringPointVmsModeConfigurationComponent } from "../measuring-point-vms-mode-configuration/measuring-point-vms-mode-configuration.component";
import { AlertHistoryComponent } from "src/app/modules/shared/components/alert-history/alert-history.component";
import { MeasuringPointArrowBoardConfigurationComponent } from "../measuring-point-arrow-board-configuration/measuring-point-arrow-board-configuration.component";
import { MeasuringPointVehicleConfigurationComponent } from "../measuring-point-vehicle-configuration/measuring-point-vehicle-configuration.component";
import { MeasuringPointAggregationComponent } from "src/app/modules/measuring-point-aggregation/components/measuring-point-aggregation/measuring-point-aggregation.component";

type MeasuringPointDetailContainerComponents =
    MeasuringPointAggregationComponent |
    MeasuringPointDetailComponent |
    MeasuringPointLightModeConfigurationComponent |
    MeasuringPointVmsModeConfigurationComponent |
    MeasuringPointExternalInputConfigurationComponent |
    MeasuringPointFloatingCarComponent |
    MeasuringPointParkingComponent |
    MeasuringPointUploadContainerComponent |
    MeasuringPointLinksComponent |
    MeasuringPointValidationComponent |
    TotemDisplayConfigurationComponent |
    MeasuringPointStatusComponent |
    MeasuringPointCountingZoneConfigurationComponent |
    MeasuringPointArrowBoardConfigurationComponent |
    MeasuringPointCctvConfigurationComponent |
    MeasuringPointVehicleConfigurationComponent |
    AuditTrailsComponent |
    AlertHistoryComponent |
    JournalComponent;

@Component({
    selector: "app-measuring-point-detail-container",
    templateUrl: "./measuring-point-detail-container.component.html"
})
export class MeasuringPointDetailContainerComponent extends TabBase<MeasuringPointDetailContainerComponents> implements OnChanges {

    @Input() measuringPoint: IMeasuringPoint;
    @Input() projects: IProject[];
    @Input() loading = false;
    @Output() save = new EventEmitter<IMeasuringPoint>();

    ngClass: string;

    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService,
        private readonly translateService: TranslateService) {

        super(tabService, cd);

        this.addTab({ component: MeasuringPointDetailComponent, url: "details", translatePath: "measuringPointDetailTab.title", icon: "info" });
        this.addTab({ component: MeasuringPointStatusComponent, url: "status", translatePath: "measuringPointStatusTab.title", icon: "error" });
        this.addTab({ component: MeasuringPointAggregationComponent, url: "aggregation", translatePath: "measuringPointAggregation.title", icon: "aggregated" });
        this.addTab({ component: MeasuringPointCctvConfigurationComponent, url: "cctv", translatePath: "cctvConfiguration.title", icon: "device-camera" });
        this.addTab({ component: MeasuringPointVehicleConfigurationComponent, url: "vehicle", translatePath: "vehicleConfiguration.title", icon: "vehicle" });
        this.addTab({ component: MeasuringPointVmsModeConfigurationComponent, url: "vms", translatePath: "modeConfiguration.title", icon: "vms" });
        this.addTab({ component: MeasuringPointLightModeConfigurationComponent, url: "light", translatePath: "modeConfiguration.title", icon: "light" });
        this.addTab({ component: MeasuringPointExternalInputConfigurationComponent, url: "externalinput", translatePath: "externalInputConfiguration.title", icon: "car" });
        this.addTab({ component: MeasuringPointCountingZoneConfigurationComponent, url: "countingzone", translatePath: "countingZoneConfigurationTab.title", icon: "countingZone" });
        this.addTab({ component: MeasuringPointArrowBoardConfigurationComponent, url: "arrowboard", translatePath: "arrowBoardConfigurationTab.title", icon: "arrowBoard" });
        this.addTab({ component: MeasuringPointFloatingCarComponent, url: "floatingcar", translatePath: "floatingCar.title", icon: "floatingCar" });
        this.addTab({ component: MeasuringPointParkingComponent, url: "parking", translatePath: "parking.title", icon: "parking" });
        this.addTab({ component: TotemDisplayConfigurationComponent, url: "totemdisplay", translatePath: "totemDisplayConfiguration.title", icon: "hardware" });
        this.addTab({ component: MeasuringPointValidationComponent, url: "validation", translatePath: "measuringPointDataTab.title", icon: "data" });
        this.addTab({ component: MeasuringPointUploadContainerComponent, url: "upload", translatePath: "uploads.title", icon: "uploads" });
        this.addTab({ component: MeasuringPointLinksComponent, url: "links", translatePath: "measuringPointLinksTab.title", icon: "links" });
        this.addTab({ component: AlertHistoryComponent, url: "alertHistory", translatePath: "alertHistory.title", icon: "history" });
        this.addTab({ component: JournalComponent, url: "journal", translatePath: "journal.title", icon: "history" });
        this.addTab({ component: AuditTrailsComponent, url: "audit", translatePath: "auditTrails.title", icon: "log" });

        this.filterTabs();
        this.updateActiveComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisabled();
        this.filterTabs();
        this.updateActiveComponent();

        const measuringPointOrProjectsChange = changes["measuringPoint"] || changes["projects"];
        if (measuringPointOrProjectsChange) {
            this.onSelectedTabChange();
            this.updateTitle();
        }
    }

    filterTabs() {
        if (!this.measuringPoint) return;

        const tabsToFilter = new Array<Type<MeasuringPointDetailContainerComponents>>();

        if (this.measuringPoint.sharedKey) {
            tabsToFilter.push(MeasuringPointUploadContainerComponent);
            tabsToFilter.push(MeasuringPointLinksComponent);
        }

        if (this.measuringPoint.analysisTypeId === AnalysisType.Aggregated) {
            tabsToFilter.push(MeasuringPointLinksComponent);
            tabsToFilter.push(MeasuringPointUploadContainerComponent);
        } else {
            tabsToFilter.push(MeasuringPointAggregationComponent);
        }

        if (this.measuringPoint.analysisTypeId === AnalysisType.Intersection) {
            tabsToFilter.push(MeasuringPointUploadContainerComponent);
            tabsToFilter.push(MeasuringPointValidationComponent);
            tabsToFilter.push(MeasuringPointLinksComponent);
        }

        // Don't show validation & upload
        if (this.measuringPoint.analysisTypeId === AnalysisType.ExternalInput ||
            this.measuringPoint.analysisTypeId === AnalysisType.Light ||
            this.measuringPoint.analysisTypeId === AnalysisType.Vms ||
            this.measuringPoint.analysisTypeId === AnalysisType.Cctv ||
            this.measuringPoint.analysisTypeId === AnalysisType.Parking ||
            this.measuringPoint.analysisTypeId === AnalysisType.Tracker ||
            this.measuringPoint.analysisTypeId === AnalysisType.CountingZone ||
            this.measuringPoint.analysisTypeId === AnalysisType.Vehicle ||
            this.measuringPoint.analysisTypeId === AnalysisType.ArrowBoard ||
            this.measuringPoint.analysisTypeId === AnalysisType.TrafficLight) {

            tabsToFilter.push(MeasuringPointUploadContainerComponent);
            tabsToFilter.push(MeasuringPointValidationComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.Vms) {
            tabsToFilter.push(MeasuringPointVmsModeConfigurationComponent);
        }
        if (this.measuringPoint.analysisTypeId !== AnalysisType.Light) {
            tabsToFilter.push(MeasuringPointLightModeConfigurationComponent);
        }
        if (this.measuringPoint.analysisTypeId !== AnalysisType.ArrowBoard) {
            tabsToFilter.push(MeasuringPointArrowBoardConfigurationComponent);
        }
        if (this.measuringPoint.analysisTypeId !== AnalysisType.TotemDisplay) {
            tabsToFilter.push(TotemDisplayConfigurationComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.FloatingCar) {
            tabsToFilter.push(MeasuringPointFloatingCarComponent);
        } else {
            tabsToFilter.push(MeasuringPointUploadContainerComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.Parking) {
            tabsToFilter.push(MeasuringPointParkingComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.ExternalInput) {
            tabsToFilter.push(MeasuringPointExternalInputConfigurationComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.Tracker
            && this.measuringPoint.analysisTypeId !== AnalysisType.TrafficLight
            && this.measuringPoint.analysisTypeId !== AnalysisType.Vehicle
            && this.measuringPoint.analysisTypeId !== AnalysisType.ArrowBoard
        ) {
            // This shows the location history
            tabsToFilter.push(MeasuringPointStatusComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.Cctv) {
            tabsToFilter.push(MeasuringPointCctvConfigurationComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.CountingZone) {
            tabsToFilter.push(MeasuringPointCountingZoneConfigurationComponent);
        }

        if (this.measuringPoint.analysisTypeId === AnalysisType.Parking
            || this.measuringPoint.analysisTypeId === AnalysisType.CountingZone) {
            tabsToFilter.push(MeasuringPointLinksComponent);
        }

        if (!this.globalEventsService.getCurrentRights()?.hasBackendRight(BackendRights.ViewInstallationAuditTrail)) {
            tabsToFilter.push(AuditTrailsComponent);
        }

        if (this.measuringPoint.analysisTypeId !== AnalysisType.Vehicle) {
            tabsToFilter.push(MeasuringPointVehicleConfigurationComponent);
        }

        this.hideTabs(tabsToFilter);
    }

    private updateDisabled() {
        const disabledExpression = (type: Type<MeasuringPointDetailContainerComponents>) => {
            if (type instanceof MeasuringPointDetailComponent) return false;

            // Can't navigate to other tabs when creating a new MeasuringPoint
            return !this.measuringPoint || !this.measuringPoint.id;
        };

        this.setTabsDisabledState(disabledExpression);
    }

    protected onSelectedTabChange() {
        if (!this.activeComponent) return;

        this.subscriptionManager.remove("save");
        this.activeComponent.setMeasuringPoint(this.measuringPoint);

        if (this.activeComponent instanceof MeasuringPointDetailComponent) {
            const saveSubscription = this.activeComponent.save.subscribe((measuringPoint: IMeasuringPoint) => {
                this.measuringPoint = measuringPoint;
                this.filterTabs();
                this.updateTitle();
                this.save.next(measuringPoint);
            });

            this.activeComponent.allProjects = this.projects;
            this.activeComponent.projects = this.projects?.map(x => {
                return {
                    label: x.name,
                    value: x.id
                } as SelectItem;
            });
            this.subscriptionManager.add("save", saveSubscription);
        }

        if (this.activeComponent instanceof AuditTrailsComponent) {
            this.activeComponent.setRowCount(50);
        }

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";

        this.updateDisabled();
    }

    updateTitle() {
        if (!this.measuringPoint || !this.selectedTab) return;
        this.titleService.setTitle(`${this.measuringPoint.code || this.translateService.instant("manageMeasuringPoint.create")} - ${this.selectedTab.label}`);
    }
}
