import { IRelease, ReleaseCreator, ReleaseActivator } from "../models/release";
import { DownloadedFile } from "../services/download-file.service";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ReleaseApi extends ApiService<IRelease, ReleaseCreator, void> {
    getRoute(): string {
        return "Releases";
    }

    create$(model: ReleaseCreator, routeParams: { [key: string]: string } = null): Observable<IRelease> {
        throw new Error("Use upload$");
    }

    upload$(
        creator: ReleaseCreator,
        file: File): Observable<IRelease> {

        const url = `${super.getUrl()}`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(creator));
        formData.append("data", file);

        return this.http.post<IRelease>(url, formData);
    }

    activate$(activator: ReleaseActivator): Observable<void> {
        const url = `${super.getUrl()}/Activate`;
        return this.http.post<void>(url, activator);
    }

    download$(id: number): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/${id}/Download`;
        return this.downloadFileService.downloadBlob(url);
    }
}