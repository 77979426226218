<div class="m-tab-header"
     [ngClass]="divClass"
     [hidden]="hidden">

    <div class="m-tab-header-title px-16"
         *ngIf="titleTemplate">
        <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
    </div>

    <div class="m-tab-header-body px-16">
        <app-svg-icon *ngIf="showChevronStart"
                      divClass="tab-header-chevron-left mr-16"
                      icon="chevron-left"
                      (click)="scrollToStart()"></app-svg-icon>

        <nav #scrollElement
             class="m-nav-tabs"
             (scroll)="updateChevrons()"
             appScrollDrag>
            <ul class="m-nav-list">
                <li role="presentation"
                    class="m-nav-list-item"
                    *ngFor="let tab of tabs; trackBy: tabTrackByFn">

                    <ng-template #contentTemplate>
                        <app-svg-icon *ngIf="tab.icon"
                                      [icon]="tab.icon">
                        </app-svg-icon>

                        {{tab.label}}
                    </ng-template>

                    <a *ngIf="!useRouting || tab.disabled; else routingTemplate"
                       class="m-nav-link"
                       [ngClass]="{'is-active': selectedTab === tab, 'is-disabled': tab.disabled}"
                       draggable="false"
                       (click)="handleSelect(tab, $event)">
                        <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
                    </a>

                    <ng-template #routingTemplate>
                        <a *ngIf="!tab.disabled; else contentTemplate"
                           [ngClass]="{'is-active': selectedTab === tab, 'is-disabled': tab.disabled}"
                           class="m-nav-link"
                           [routerLink]="tab.routerLink"
                           draggable="false"
                           id="tab_{{tab.url}}">
                            <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
                        </a>
                    </ng-template>
                </li>
            </ul>
        </nav>

        <app-svg-icon *ngIf="showChevronEnd"
                      divClass="tab-header-chevron-right ml-16"
                      icon="chevron-right"
                      (click)="scrollToEnd()"></app-svg-icon>
    </div>
</div>