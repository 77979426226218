import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { DeviceUploadComponent } from "../device-upload/device-upload.component";
import { UploadMetricComponent } from "../../../shared/components/upload-metric/upload-metric.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { IDevice } from "src/app/models/device";

type DeviceUploadComponents =
    DeviceUploadComponent |
    UploadMetricComponent;

@Component({
    selector: "app-device-upload-container",
    templateUrl: "./device-upload-container.component.html"
})
export class DeviceUploadContainerComponent extends TabBase<DeviceUploadComponents> implements OnChanges {
    @Input() device: IDevice;

    @Output() save = new EventEmitter<IDevice>();

    ngClass: string;

    constructor(
        elementRef: ElementRef,
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        elementRef.nativeElement.classList.add("m-layout-area-body");

        this.addTab({ component: UploadMetricComponent, url: "metric", translatePath: "uploadMetric.title", icon: "calendar" });
        this.addTab({ component: DeviceUploadComponent, url: "uploads", translatePath: "deviceUpload.title", icon: "uploads" });

        this.filterTabs();
    }

    ngOnChanges(changes: SimpleChanges) {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }
    }

    setDevice(device: IDevice) {
        this.device = device;

        if (this.activeComponent) {
            this.activeComponent.setDevice(this.device);
        }
    }

    protected onSelectedTabChange() {
        this.activeComponent.setDevice(this.device);

        this.ngClass = this.selectedTab ? this.selectedTab.url : "";
    }

    updateTitle() {
        if (!this.device || !this.selectedTab) return;
        this.titleService.setTitle(`${this.device.code} - ${this.selectedTab.label}`);
    }
}