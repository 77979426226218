<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-border-top"
         [loading]="loading"
         [totalRecords]="total"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         dataKey="id"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-status>
        <tr [pSelectableRow]="status">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'timestamp'">
                    {{status.timestamp | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'powerStatus.batteryVoltage'">
                    <ng-container *ngIf="isValidNumber(status.powerStatus?.batteryVoltage)">
                        {{status.powerStatus?.batteryVoltage | number:'1.1-1'}}{{"measurements.volt" | translate}}
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'powerStatus.chargePercentage'">
                    <ng-container *ngIf="isValidNumber(status.powerStatus?.chargePercentage)">
                        {{status.powerStatus?.chargePercentage}}%
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'counterStatus.dayCounter'">
                    {{status.counterStatus?.dayCounter}}
                </ng-container>

                <ng-container *ngSwitchCase="'counterStatus.monthCounter'">
                    {{status.counterStatus?.monthCounter}}
                </ng-container>

                <ng-container *ngSwitchCase="'counterStatus.yearCounter'">
                    {{status.counterStatus?.yearCounter}}
                </ng-container>

                <ng-container *ngSwitchCase="'controller.versions'">
                    <ng-container *ngIf="status.controller?.versions">
                        <ul>
                            <li *ngFor="let version of status.controller.versions">
                                {{version.packageId}} {{version.version}}
                            </li>
                        </ul>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'networkStatus.signalStrength'">
                    <ng-container *ngIf="status.networkStatus?.signalStrength">
                        {{status.networkStatus.signalStrength}} dB
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="m-btn-group">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button [text]="command.text"
                                             [icon]="command.icon"
                                             [disabled]="command.disabledFunc()"
                                             (click)="command.click(status)"
                                             *ngIf="command.rowValidFunc(status)">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{status[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>

<app-text-dialog></app-text-dialog>