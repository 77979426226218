<div class="m-layout-area-body m-overflow-auto">
    <form [formGroup]="relayConfigurationForm"
          class="p-16 m-border-top m-layout-area-top"
          *ngIf="device"
          autocomplete="off">

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': relayConfigurationForm.get('typeId').showErrors }">

            <label class="col-4"
                   for="relayTypeId">
                {{"general.type" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown id="relayTypeId"
                            [options]="relayTypes"
                            [panelStyle]="{'width':'100%'}"
                            formControlName="typeId"
                            [autoDisplayFirst]="false"
                            placeholder="{{'general.type' | translate}}"
                            #relayTypeIdInput></p-dropdown>

                <app-form-validation [control]="relayConfigurationForm.get('typeId')"
                                     [input]="relayTypeIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': relayConfigurationForm.get('pin').showErrors }">

            <label class="col-4"
                   for="text">
                {{"deviceDisplayEvents.pin" | translate}}
            </label>

            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       id="pin"
                       formControlName="pin"
                       #pinInput />

                <app-form-validation [control]="relayConfigurationForm.get('pin')"
                                     [input]="pinInput">
                </app-form-validation>
            </div>
        </div>
    </form>

    <div class="m-layout-area-body m-layout-w-actions-top">
        <div class="m-border-top px-16 py-8 m-layout-area-top">
            <h3>{{"deviceDisplayEvents.title" | translate}}</h3>
        </div>

        <app-device-display-events [device]="device"
                                   [createCommand]="false"
                                   [editCommand]="false"
                                   [cloneCommand]="false"
                                   [deleteCommand]="false"
                                   [shortcutToManagementScreen]="true"
                                   class="m-layout-area-body m-layout-default"></app-device-display-events>
    </div>
</div>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>