import { Component, ElementRef, inject, input, NgZone, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ToastService } from "src/app/services/toast.service";
import { SharedModule } from "src/app/modules/shared/shared.module";

@Component({
    selector: "m-split-screen",
    standalone: true,
    imports: [SharedModule],
    templateUrl: "./split-screen.component.html",
    styleUrl: "./split-screen.component.scss"
})
export class SplitScreenComponent implements OnInit {
    mode = input.required<"horizontal" | "vertical">();
    startValueFirst = input<number>(50);
    startValueSecond = input<number>(50);

    get isVertical() {
        return this.mode() === "vertical";
    }

    isDragging = false;

    @ViewChild("container", { static: true }) container!: ElementRef;
    @ViewChild("firstPanel", { static: true }) firstPanel!: ElementRef;
    @ViewChild("secondPanel", { static: true }) secondPanel!: ElementRef;
    @ViewChild("divider", { static: true }) divider!: ElementRef;

    private renderer = inject(Renderer2);
    private ngZone = inject(NgZone);
    private toastService = inject(ToastService);

    ngOnInit() {
        if (this.startValueFirst() + this.startValueSecond() !== 100) {
            this.toastService.error("The sum of the start values must be 100.");
            this.renderer.setStyle(this.firstPanel.nativeElement, this.isVertical ? "width" : "height", "50%");
            this.renderer.setStyle(this.secondPanel.nativeElement, this.isVertical ? "width" : "height", "50%");
        }

        this.renderer.setStyle(this.firstPanel.nativeElement, this.isVertical ? "width" : "height", `${this.startValueFirst()}%`);
        this.renderer.setStyle(this.secondPanel.nativeElement, this.isVertical ? "width" : "height", `${this.startValueSecond()}%`);
    }

    onDragStart(event: MouseEvent) {
        this.isDragging = true;
        event.preventDefault();
        this.divider.nativeElement.classList.add("is-dragged");

        this.ngZone.runOutsideAngular(() => {
            const moveHandler = (e: MouseEvent) => this.onMouseMove(e);
            const stopDragHandler = () => this.onMouseUp(moveHandler);

            document.addEventListener("mousemove", moveHandler);
            document.addEventListener("mouseup", stopDragHandler);
        });
    }

    onMouseMove(event: MouseEvent) {
        if (!this.isDragging) return;

        const containerRect = this.container.nativeElement.getBoundingClientRect();

        if (this.isVertical) {
            const offsetX = event.clientX - containerRect.left;
            const leftPanelWidth = (offsetX / containerRect.width) * 100;
            const rightPanelWidth = 100 - leftPanelWidth;

            this.renderer.setStyle(this.firstPanel.nativeElement, "width", `${leftPanelWidth}%`);
            this.renderer.setStyle(this.secondPanel.nativeElement, "width", `${rightPanelWidth}%`);
        } else {
            const offsetY = event.clientY - containerRect.top;
            const leftPanelHeight = (offsetY / containerRect.height) * 100;
            const rightPanelHeight = 100 - leftPanelHeight;

            this.renderer.setStyle(this.firstPanel.nativeElement, "height", `${leftPanelHeight}%`);
            this.renderer.setStyle(this.secondPanel.nativeElement, "height", `${rightPanelHeight}%`);
        }
    }

    onMouseUp(moveHandler: (e: MouseEvent) => void) {
        this.isDragging = false;
        this.divider.nativeElement.classList.remove("divider--dragging");

        // Remove the mousemove and mouseup event listeners after drag ends
        document.removeEventListener("mousemove", moveHandler);
        // document.removeEventListener("mouseup", this.onMouseUp);
    }
}
