import { NgModule } from "@angular/core";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { SharedModule } from "../shared/shared.module";

import { LinkRoutingModule } from "./link-routing.module";
import { ManageLinkComponent } from "./components/manage-link/manage-link.component";
import { DeviceLinkDetailComponent } from "./components/device-link-detail/device-link-detail.component";
import { TubeOffsetDialogComponent } from "./components/tube-offset-dialog/tube-offset.dialog";
import { DeviceLinkTubeConfigurationComponent } from "./components/device-link-tube-configuration/device-link-tube-configuration.component";
import { DeviceLinkTubeXPosConfigurationComponent } from "./components/device-link-tube-xpos-configuration/device-link-tube-xpos-configuration.component";
import { DeviceLinkBikeXPosConfigurationComponent } from "./components/device-link-bike-xpos-configuration/device-link-bike-xpos-configuration.component";
import { DeviceLinkCarConfigurationComponent } from "./components/device-link-car-configuration/device-link-car-configuration.component";
import { DeviceLinkCarAndBikeConfigurationComponent } from "./components/device-link-car-and-bike-configuration/device-link-car-and-bike-configuration.component";
import { DeviceLinkPedestrianConfigurationComponent } from "./components/device-link-pedestrian-configuration/device-link-pedestrian-configuration.component";
import { DeviceLinkBikeConfigurationComponent } from "./components/device-link-bike-configuration/device-link-bike-configuration.component";
import { DeviceLinkFilterConfigurationComponent } from "./components/device-link-filter-configuration/device-link-filter-configuration.component";
import { DeviceLinkCameraConfigurationComponent } from "./components/device-link-camera-configuration/device-link-camera-configuration.component";
import { DeviceLinkCarXPosConfigurationComponent } from "./components/device-link-car-xpos-configuration/device-link-car-xpos-configuration.component";
import { DeviceLinkExternalInputToGpioLinkComponent } from "./components/device-link-external-input-to-gpio-link/device-link-external-input-to-gpio-link.component";
import { DeviceLinkModeToGpioLinkComponent } from "./components/device-link-mode-to-gpio-link/device-link-mode-to-gpio-link.component";
import { SelectLinkMeasurementsComponent } from "./components/select-link-measurements/select-link-measurements.component";
import { DeviceLinkCollectRConfigurationComponent } from "./components/device-link-collect-r-configuration/device-link-collect-r-configuration.component";
import { DeviceLinkDekimoDisplayLinkComponent } from "./components/device-link-dekimo-display-link/device-link-dekimo-display-link.component";
import { DeviceLinkTrafficLightLinkComponent } from "./components/device-link-traffic-light-link/device-link-traffic-light-link.component";

@NgModule({
    imports: [
        LinkRoutingModule,
        SharedModule,
        MapAdvancedModule
    ],
    exports: [
        ManageLinkComponent
    ],
    declarations: [
        ManageLinkComponent,
        DeviceLinkDetailComponent,
        TubeOffsetDialogComponent,
        DeviceLinkTubeConfigurationComponent,
        DeviceLinkTubeXPosConfigurationComponent,
        DeviceLinkBikeXPosConfigurationComponent,
        DeviceLinkCarConfigurationComponent,
        DeviceLinkCarAndBikeConfigurationComponent,
        DeviceLinkPedestrianConfigurationComponent,
        DeviceLinkBikeConfigurationComponent,
        DeviceLinkFilterConfigurationComponent,
        DeviceLinkCameraConfigurationComponent,
        DeviceLinkCarXPosConfigurationComponent,
        DeviceLinkExternalInputToGpioLinkComponent,
        DeviceLinkModeToGpioLinkComponent,
        SelectLinkMeasurementsComponent,
        DeviceLinkCollectRConfigurationComponent,
        DeviceLinkDekimoDisplayLinkComponent,
        DeviceLinkTrafficLightLinkComponent,
    ]
})
export class LinkModule { }