import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArticleContainerComponent } from "./components/article-container/article-container.component";
import { DocumentationRoutingModule } from "./documentation-routing.module";
import { SharedModule } from "../shared/shared.module";
import { TreeModule } from "primeng/tree";



@NgModule({
    declarations: [
        ArticleContainerComponent
    ],
    imports: [
        DocumentationRoutingModule,
        SharedModule,
        TreeModule,
        CommonModule
    ]
})
export class DocumentationModule { }
