<div [ngStyle]="style"
     [class]="styleClass">
    <!-- Google Maps will be displayed in this div -->
</div>

<div class="m-map-overlay m-map-overlay-right-top"
     [ngClass]="{'m-map-overlay-right-top-moved': showStreetViewControl === true}"
     *ngIf="showMapTypeSelector"
     [hidden]="isInStreetView">
    <app-map-type-selector (selectionChanged)="handleMapTypeChanged($event)"></app-map-type-selector>
</div>

<div class="m-map-overlay m-map-overlay-right-bottom flex align-center gap-8"
     *ngIf="showCoordinates">
    <button class="m-btn m-btn-square m-btn-secondary"
            *ngIf="isGeolocationSupported"
            type="button"
            (click)="toggleGeolocation()">
        <app-svg-icon icon="headshot"
                      [fill]="trackingGeoLocation ? 'red' : 'black'">
        </app-svg-icon>
    </button>

    <div class="m-map-coordinates">
        <button class="m-map-coordinates-btn"
                type="button"
                [ngClass]="{'is-active': showCrosshair}"
                (click)="toggleCrosshair()">
            <app-svg-icon icon="crosshair">
            </app-svg-icon>
        </button>

        <span class="block pr-8"
              *ngIf="state.center"
              id="mapCoordinates">{{state.center.lat | number: '1.4-4'}}, {{state.center.lng | number: '1.4-4'}}</span>
    </div>
</div>

<div *ngIf="state.showCrosshair"
     style="pointer-events: none;">
    <!-- This draws the actual crosshair -->
    <div style="position: absolute; top: 50%; left: 0; width: 100%; height: 2px; background: black"></div>
    <div style="position: absolute; top: 0; left: 50%; width: 2px; height: 100%; background: black"></div>
</div>

<div
    [hidden]="!isLoading"
    id="basicMapLoader"
     style="position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%);">
    <app-loading-spinner [divStyle]="{'width': '50px', 'height': '50px'}"></app-loading-spinner>
</div>

<app-map-context-menu></app-map-context-menu>
