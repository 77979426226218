import { Component, HostListener, Input } from "@angular/core";
import { IWorkzoneStatus } from "src/app/models/pinned-data";

@Component({
    selector: "app-workzone-pinned-data",
    templateUrl: "./workzone-pinned-data.component.html",
})
export class WorkzonePinnedDataComponent {
    @Input() workzone: IWorkzoneStatus;
    @Input() measuringPointId: number;

    @HostListener("wheel", ["$event"])
    onMouseWheel(event: WheelEvent) {
        // The mousewheel should scroll in the popup, not in the Google Maps that is beneath it
        event.stopPropagation();
    }
}