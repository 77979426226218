import { LazyTableComponent, TableColumn, FilterType, ColumnType, TableService } from "src/app/modules/shared/components/table/table.component";
import { Component, OnChanges, SimpleChanges, Input, ElementRef, ViewChild } from "@angular/core";
import { SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";
import { TextDialogComponent, TextDisplayType } from "src/app/modules/shared/components/text-dialog/text.dialog";
import { ManageDeviceCommandDialogComponent } from "../manage-device-command-dialog/manage-device-command.dialog";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { TranslateService } from "@ngx-translate/core";
import { DeviceCommandApi } from "src/app/resource/device-command.api";
import { IDeviceCommand } from "src/app/models/device-command";
import { ProcessStatus } from "src/app/models/upload";
import { IDevice } from "src/app/models/device";

@Component({
    selector: "app-device-commands",
    templateUrl: "./device-commands.component.html"
})
export class DeviceCommandsComponent extends LazyTableComponent<IDeviceCommand> implements OnChanges {
    @ViewChild(ManageDeviceCommandDialogComponent, { static: true }) manageDeviceCommandDialog: ManageDeviceCommandDialogComponent;
    @ViewChild(TextDialogComponent, { static: true }) textDialog: TextDialogComponent;

    @Input() device: IDevice;
    @Input() sendCommand = false;

    constructor(
        elementRef: ElementRef,
        deviceCommandApi: DeviceCommandApi,
        tableService: TableService,
        readonly translateService: TranslateService,
        private readonly domainDataService: DomainDataService) {

        super("device-commands.component", elementRef, deviceCommandApi, tableService);

        this.selectionMode = "";

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");

        this.addColumn(new TableColumn("createDate", "general.date", { filterType: FilterType.Date, sortable: true }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false }));

        const statusIdColumn = new TableColumn("statusId", "general.status", { filterType: FilterType.MultiSelect, sortable: true, resizable: false, width: 120 });
        this.addColumn(statusIdColumn);

        const typeColumn = new TableColumn("typeId", "general.type", { filterType: FilterType.MultiSelect, sortable: true, resizable: true });
        this.addColumn(typeColumn);

        this.addColumn(new TableColumn("command", "deviceCommands.command", { type: ColumnType.Icon, width: 100 }));
        this.addColumn(new TableColumn("processMessage", "general.processMessage", { filterType: FilterType.Text, resizable: true }));

        this.domainDataService.get(DomainData.ProcessStatus).then(processStatuses => {
            statusIdColumn.filterOptions = processStatuses;
        });

        this.domainDataService.get(DomainData.DeviceCommandType).then(deviceCommandTypes => {
            typeColumn.filterOptions = deviceCommandTypes;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.loadTableRows();
        }

        super.ngOnChanges(changes);
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        searchParameters.sort = [new SortDescriptor(SortDirection.descending, "timestamp")];

        return searchParameters;
    }

    openDeviceCommandDialog() {
        const onSend = () => {
            this.loadTableRows(this.lastLazyLoadEvent, true);
        };

        this.manageDeviceCommandDialog.create(this.device, onSend);
    }

    openCommandJson(status: IDeviceCommand) {
        this.textDialog.open("general.json", status.command, TextDisplayType.Json, null);
    }

    canLoad(): boolean {
        return !!this.device;
    }

    getRouteParams(): { [index: string]: string } {
        return { deviceId: this.device.id.toString() };
    }

    getCommandStatusColor(command: IDeviceCommand) {
        if (command.statusId === ProcessStatus.Error) return "red";
        if (command.statusId === ProcessStatus.Success) return "green";
        return "inherit";
    }

    getCommandStatusIcon(command: IDeviceCommand) {
        if (command.statusId === ProcessStatus.Error) return "error";
        if (command.statusId === ProcessStatus.Success) return "checkmark";
        return "hourglass";
    }
}