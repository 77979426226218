<div class="m-layout-area-body m-layout-w-actions-top m-border-top">
    <div class="flex align-center m-layout-area-top">
        <div class="px-16">
            <div class="m-filter-group py-8">
                <span class="m-filter-title mr-16">
                    {{"table.filters" | translate}}
                </span>

                <app-table-filter *ngIf="!project"
                                  class="mr-8"
                                  [column]="projectColumn"
                                  [table]="table"
                                  [isOutsideTable]="true"></app-table-filter>

                <app-table-filter class="mr-8"
                                  [column]="categoriesColumn"
                                  [table]="table"
                                  [isOutsideTable]="true"></app-table-filter>

                <app-table-filter class="mr-8"
                                  [column]="timestampColumn"
                                  [table]="table"
                                  [isOutsideTable]="true"></app-table-filter>

                <app-table-filter *ngIf="project"
                                  [column]="includeAllColumn"
                                  [table]="table"
                                  [isOutsideTable]="true"></app-table-filter>

            </div>
        </div>

        <div class="ml-auto">
            <div class="m-btn-group m-btn-group-right">
                <button class="m-btn m-btn-secondary"
                        (click)="addNew()"
                        *ngIf="createCommand && !readonly"
                        id="add_log_button">
                    <app-svg-icon icon="add">
                    </app-svg-icon>
                    {{'journal.add' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="m-layout-area-body m-layout-default m-table-card m-border-top file-drop-zone"
         appFileDragAndDrop
         (fileDropped)="createCommand && !readonly && onFileDropped($event)"
         style="position: relative;">
        <div [ngClass]="['file-drop-zone-overlay', (data.length > 0 ? 'file-drop-zone-overlay-image' : '')]">

        </div>
        <p-table id="journal_table"
                 [value]="data"
                 [columns]="relevantColumns"
                 [rows]="internalRowCount"
                 [styleClass]="styleClass"
                 [loading]="loading"
                 [totalRecords]="total"
                 [paginator]="paginator"
                 [pageLinks]="pageLinks"
                 [scrollable]="scrollable"
                 scrollHeight="flex"
                 [lazy]="isLazy"
                 (onRowSelect)="onRowSelect($event)"
                 (onRowUnselect)="onRowDeselect($event)"
                 (onLazyLoad)="loadTableRows($event)"
                 [selectionMode]="selectionMode"
                 [metaKeySelection]="true"
                 dataKey="id"
                 sortField="timestamp"
                 [sortOrder]="-1"
                 #tableRef>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="!loading && emptyMessage"
                    class="m-table-empty-message-row">
                    <td [attr.colspan]="relevantColumns.length"
                        class="m-table-empty-message-cell">
                        <div>
                            <img class="m-table-empty-message-image"
                                 src="../../../../../assets/icons/drop.svg">
                        </div>
                        <div class="mt-8">
                            {{"general.dropFilesHere" | translate}}
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="paginatorleft">
                <ng-container *ngIf="total">
                    {{"table.total" | translate}}: {{total}}
                </ng-container>
            </ng-template>

            <ng-template pTemplate="summary"
                         *ngIf="footer">
                <ng-container *ngIf="total && !paginator">
                    {{"table.total" | translate}}: {{total}}
                </ng-container>
            </ng-template>

            <!--    <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!column.sortable"
                    [pTooltip]="column.tooltip | translate"
                    [tooltipDisabled]="!column.tooltip">

                    <div class="m-column-header">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          [column]="column"
                                          [table]="table"></app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column">
                    </app-table-filter-preview>
                </th>
            </tr>
        </ng-template> -->

            <ng-template pTemplate="body"
                         let-journal
                         let-rowIndex="rowIndex"
                         class="test">

                <tr [pSelectableRow]="journal"
                    [pSelectableRowIndex]="rowIndex">
                    <td [attr.colspan]="relevantColumns.length">
                        <div class="m-card"
                             style="width: 100%;">
                            <div class="p-16">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <p class="col-4"><strong>{{'general.createdOn' | translate}}</strong></p>
                                            <p *ngIf="journal.timestamp"
                                               class="col-8"
                                               style="margin-top:0">
                                                {{ journal.timestamp | momentDateTime:false:false:translateService.currentLang }}
                                                <span *ngIf="journal.creator">
                                                    (<app-navigator [user]="journal.creator"></app-navigator>)
                                                </span>
                                            </p>
                                        </div>

                                        <div class="row mt-8"
                                             *ngIf="journal.lastModificationDateTime">
                                            <p class="col-4"><strong>{{'general.modifiedOn' | translate}}</strong></p>
                                            <p class="col-8"
                                               style="margin-top:0">
                                                {{ journal.lastModificationDateTime | momentDateTime:false:false:translateService.currentLang }}
                                                <span *ngIf="journal.lastModifiedBy">
                                                    (<app-navigator [user]="journal.lastModifiedBy"></app-navigator>)
                                                </span>
                                            </p>
                                        </div>

                                        <div class="row mt-8"
                                             *ngIf="canSetProject">
                                            <p class="col-4"><strong>{{'journal.project' | translate}}:</strong></p>
                                            <p class="col-8"
                                               style="margin-top:0"
                                               *ngIf="canSetProject">{{ journal.project?.name || ' ' }}</p>
                                        </div>


                                        <div class="row mt-8"
                                             *ngIf="journal.category || journal.subCategory">
                                            <p class="col-4"><strong>{{'journal.category' | translate}}:</strong></p>
                                            <p class="col-8"
                                               style="margin-top:0">{{ journal.category.codeStringResourceId | translateModel:translateService.currentLang }}<ng-container *ngIf="journal.category && journal.subCategory"> - {{ journal.subCategory.codeStringResourceId | translateModel:translateService.currentLang }}</ng-container>
                                            </p>
                                        </div>

                                        <p *ngIf="journal.isAdminOnly && canManageAdminJournal"
                                           class="mt-8">
                                            <app-svg-icon icon="key-admin"
                                                          tooltip="Admin only">
                                            </app-svg-icon>
                                        </p>
                                    </div>

                                    <div class="col-3">
                                        <p><strong>{{'journal.remarks' | translate}}</strong></p>
                                        <p>{{ (journal.remarks ? journal.remarks : '/') }}</p>
                                    </div>

                                    <div class="col-3"
                                         *ngIf="canManageAdminJournal">
                                        <p><strong>{{'journal.adminRemarks' | translate}}</strong></p>
                                        <p>{{ (journal.adminRemarks ? journal.adminRemarks : '/') }}</p>
                                    </div>

                                    <div class="col-2">
                                        <div class="m-btn-group m-btn-group-right">
                                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                                <app-icon-button [text]="command.text"
                                                                 [icon]="command.icon"
                                                                 [disabled]="command.disabledFunc()"
                                                                 (click)="command.click(journal)"
                                                                 *ngIf="command.rowValidFunc(journal)">
                                                </app-icon-button>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-16">
                                        <app-attachment-overview [context]="journal"></app-attachment-overview>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<app-journal-dialog></app-journal-dialog>