import { IClientCreatedResult } from "src/app/models/application";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { Component } from "@angular/core";

@Component({
    selector: "app-application-create-result-dialog",
    templateUrl: "./application-create-result.dialog.html"
})
export class ApplicationCreateResultDialogComponent extends DialogComponentBase {
    clientCreatedResult: IClientCreatedResult;
    copiedSecret: boolean;

    constructor(
        private readonly accessibilityService: AccessibilityService) {

        super();

        this.dismissOnClickOutside = false;
    }

    open(clientCreatedResult: IClientCreatedResult) {
        this.clientCreatedResult = clientCreatedResult;

        this.openDialog();
    }

    protected onClose() {
        this.copiedSecret = false;
        this.clientCreatedResult = null;
    }

    copyIdToClipboard() {
        this.accessibilityService.copyToClipboard(this.clientCreatedResult.id, "applications.copiedClientIdToClipboard");
    }

    copySecretToClipboard() {
        this.copiedSecret = true;
        this.accessibilityService.copyToClipboard(this.clientCreatedResult.secret, "applications.copiedClientSecretToClipboard");
    }
}