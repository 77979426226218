import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { IExternalInputPinnedData } from "src/app/models/pinned-data";
import { DownloadFileService } from "src/app/services/download-file.service";
import { FileUtils } from "src/app/utilities";
import { ExternalInputManageDialogComponent } from "../external-input-manage-dialog/external-input-manage-dialog.component";

@Component({
    selector: "app-external-input-pinned-data",
    templateUrl: "./external-input-pinned-data.component.html",
})
export class ExternalInputPinnedDataComponent implements OnChanges {

    @Input() externalInput: IExternalInputPinnedData;
    @Input() measuringPointId: number;
    @Input() isExpanded: boolean;
    @ViewChild(ExternalInputManageDialogComponent, { static: true }) externalInputManageDialog: ExternalInputManageDialogComponent;

    constructor(
        private readonly downloadFileService: DownloadFileService
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        const inputChange = changes["externalInput"];

        if (inputChange) {
            this.loadExternalInputs();
        }
    }

    private async loadExternalInputs() {
        if (this.externalInput?.inputs && this.externalInput.inputs.length > 0) {
            for (const input of this.externalInput.inputs) {
                if (input.trueIconUrl) {
                    const file = await this.downloadFileService.downloadBlob(input.trueIconUrl);
                    input.trueIconPreview = await FileUtils.toBase64(file.file);
                }

                if (input.falseIconUrl) {
                    const file = await this.downloadFileService.downloadBlob(input.falseIconUrl);
                    input.falseIconPreview = await FileUtils.toBase64(file.file);
                }
            }
        }
    }

    openExternalInputManageDialog(event: MouseEvent) {
        event.stopPropagation();
        if (!this.externalInput?.inputs) return;
        this.externalInputManageDialog.open(this.measuringPointId, this.externalInput);
    }
}