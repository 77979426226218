<header #nav
        class="m-header">
    <div class="m-header-navbar">
        <div class="m-header-navbar-left">
            <a [routerLink]="['/']"
               class="m-org"
               id="navHomeAnchor">
                <img [attr.src]="organization?.logoUrl ? organization.logoUrl : '/assets/img/brand-icon.png'"
                     alt="logo"
                     class="m-org__logo" />

                <div *ngIf="isDesktop"
                     class="m-org__info">
                    <span class="m-org__name"
                          *ngIf="isAuthenticated !== null && isAuthenticated === true">
                        {{ organization?.name }}</span>
                    <span class="m-org__product">{{ "poweredBy" | translate : {productName: websiteService.getProductName()} }}</span>
                </div>
            </a>
        </div>

        <div class="m-header-navbar-right">
            <nav #navbar
                 class="m-nav-primary">

                <ul class="m-nav-list">
                    <!-- currentUser means we're authenticated, user means we know who we are.
                        After login we know we're authenticated, but don't know yet who we are for a little while -->
                    <ng-container *ngIf="isAuthorized">
                        <ng-container *ngIf="isAuthorized">
                            <ng-template [ngTemplateOutletContext]="{$implicit: availableNavLinks}"
                                         [ngTemplateOutlet]="loggedInTemplate">
                            </ng-template>
                            <ng-template *ngIf="isMobile"
                                         [ngTemplateOutletContext]="{cssClass: 'dropdown-mobile-only'}"
                                         [ngTemplateOutlet]="logoutDropdown"></ng-template>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isAuthenticated !== null && isAuthenticated === false">
                        <ng-template [ngTemplateOutlet]="notLoggedInTemplate"></ng-template>
                    </ng-container>

                    <ng-template #loggedInTemplate
                                 let-navLinks>
                        <ng-container *ngFor="let link of navLinks">
                            <li class="m-nav-list-item">
                                <a (click)="closeMenus()"
                                   [id]="'nav'+link.screenName+'MenuAnchor'"
                                   [ngClass]="{'is-active': isActive(link)}"
                                   [routerLink]="[link.route]"
                                   class="m-nav-link">
                                    <app-svg-icon [icon]="link.icon">
                                    </app-svg-icon>
                                    <span> {{ link.title | translate }}</span>
                                </a>

                            </li>
                        </ng-container>
                    </ng-template>

                    <ng-template #notLoggedInTemplate>
                        <li [routerLinkActive]="['link-active']"
                            class="m-nav-list-item">
                            <a (click)="closeMenus()"
                               [routerLink]="['/about']"
                               class="m-nav-link"
                               id="navMenuAboutAnchor">
                                <app-svg-icon icon="info"></app-svg-icon>
                                {{ "about.title" | translate : {productName: websiteService.getProductName()} }}
                            </a>
                        </li>

                        <li class="m-nav-list-item">
                            <a (click)="closeMenus(); login()"
                               class="m-nav-link"
                               id="navMenuLogin">
                                <app-svg-icon icon="profile"></app-svg-icon>
                                {{ "general.login" | translate }}
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </nav>

            <ng-template #logoutDropdown
                         let-cssClass="cssClass">
                <div #navdropdown
                     [ngClass]="cssClass"
                     class="nav-dropdown m-dropdown m-account-dropdown"
                     id="navMenuDropdown">
                    <a (click)="toggleDropdown()"
                       class="m-dropdown-toggle">
                        <!-- <app-svg-icon icon="profile">
                            </app-svg-icon> -->
                        <span class="m-account-item has-background">
                            {{ authorizationInfo && authorizationInfo.user.firstName.charAt(0) | uppercase }}{{ authorizationInfo && authorizationInfo.user.lastName.charAt(0) | uppercase }}
                        </span>
                    </a>

                    <ul class="m-dropdown-menu">
                        <li *ngIf="!isDesktop"
                            style="color:white">
                            <h3 style="color:white; padding:10px">{{ authorizationInfo && authorizationInfo.user.email }}</h3>

                        </li>
                        <li *ngIf="showImpersonationDropdown">
                            <p-dropdown #impersonateOrganizationDropdown
                                        (onChange)="setImpersonation($event.value);"
                                        [autoDisplayFirst]="false"
                                        [filter]="true"
                                        [ngModel]="impersonationService.organization"
                                        [options]="impersonationOrganizations"
                                        [resetFilterOnHide]="true"
                                        [showClear]="true"
                                        [style]="{'width': '100%'}"
                                        appendTo="body"
                                        dataKey="id"
                                        id="navMenuImpersonateOrganization"
                                        placeholder="{{'admin.impersonate' | translate}}"
                                        styleClass="impersonate-dropdown">
                            </p-dropdown>
                        </li>
                        <li *ngIf="showRoleImpersonationDropdown">
                            <p-dropdown #impersonateRoleDropdown
                                        [(ngModel)]="impersonationService.role"
                                        [autoDisplayFirst]="false"
                                        [filter]="true"
                                        [options]="roles"
                                        [resetFilterOnHide]="true"
                                        [showClear]="true"
                                        [style]="{'width': '100%'}"
                                        appendTo="body"
                                        id="navMenuImpersonateRole"
                                        placeholder="{{'admin.impersonateRole' | translate}}"></p-dropdown>
                        </li>
                        <li *ngIf="showMockProgress">
                            <a (click)="mockProgress()"
                               id="navMenuMockProgress">Mock progress</a>
                        </li>
                        <li>
                            <a (click)="closeMenus()"
                               [routerLinkActive]="['link-active']"
                               [routerLink]="['/about']"
                               id="navMenuAboutAnchor">
                                {{ "about.title" | translate : {productName: websiteService.getProductName()} }}
                            </a>
                        </li>
                        <li class="language-picker">
                            <ng-template #languageTemplate
                                         let-language>
                                <div class="flex align-center">
                                    <app-svg-icon [icon]="language"
                                                  file="assets/flag-icons.svg"
                                                  fill="black"
                                                  style="width:32px;" />
                                    {{ language | translateEnum:"language" }}
                                </div>
                            </ng-template>

                            <p-dropdown #languageDropdown
                                        (ngModelChange)="setLanguage($event)"
                                        *ngIf="languages"
                                        [autoDisplayFirst]="false"
                                        [ngModel]="currentLanguage"
                                        [options]="languages"
                                        [style]="{'width': '100%'}"
                                        appendTo="body"
                                        id="navMenuLanguage">

                                <ng-template let-language
                                             pTemplate="selectedItem">
                                    <ng-template [ngTemplateOutletContext]="{$implicit: language?.value}"
                                                 [ngTemplateOutlet]="languageTemplate">
                                    </ng-template>
                                </ng-template>
                                <ng-template let-language
                                             pTemplate="item">
                                    <div>
                                        <ng-template [ngTemplateOutletContext]="{$implicit: language?.value}"
                                                     [ngTemplateOutlet]="languageTemplate">
                                        </ng-template>
                                    </div>
                                </ng-template>

                            </p-dropdown>
                        </li>
                        <li>
                            <a (click)="closeMenus(); logout();"
                               id="navMenuLogout">
                                {{ "general.logout" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-template>

            <div *ngIf="isAuthorized"
                 class="m-account">

                <div *ngIf="authorizationInfo"
                     class="m-nav-list-item">
                    <a class="m-nav-link"
                       id="navHelpAnchor"
                       routerLink="/documentation/viewer/introduction"
                       title="{{'documentation.link' | translate}}">
                        <span class="m-account-item">
                            <app-svg-icon [ngStyle]="{'fill': '#173232'}"
                                          icon="circle-question" />
                        </span>
                    </a>
                </div>

                <div *ngIf="authorizationInfo"
                     class="m-nav-list-item">
                    <app-notification-bubble></app-notification-bubble>
                </div>

                <ng-container *ngTemplateOutlet="logoutDropdown"></ng-container>
            </div>

            <div *ngIf="isAuthenticated !==null && isAuthenticated === false"
                 class="m-language">
                <div class="m-language-dropdown">
                    <ng-template #languageTemplate
                                 let-language>
                        <app-svg-icon [icon]="language"
                                      [ngStyle]="{'fill': '#000'}"
                                      file="assets/flag-icons.svg"></app-svg-icon>
                        {{ language | translateEnum:"language" }}
                    </ng-template>

                    <p-dropdown #languageDropdown
                                (ngModelChange)="setLanguage($event)"
                                *ngIf="languages"
                                [autoDisplayFirst]="false"
                                [ngModel]="translate.currentLang"
                                [options]="languages"
                                id="navMenuLanguage">

                        <ng-template let-language
                                     pTemplate="selectedItem">
                            <ng-template [ngTemplateOutletContext]="{$implicit: language?.value}"
                                         [ngTemplateOutlet]="languageTemplate">
                            </ng-template>
                        </ng-template>

                        <ng-template let-language
                                     pTemplate="item">
                            <div>
                                <ng-template [ngTemplateOutletContext]="{$implicit: language.value}"
                                             [ngTemplateOutlet]="languageTemplate">
                                </ng-template>
                            </div>
                        </ng-template>

                    </p-dropdown>
                </div>
            </div>
        </div>
    </div>
</header>