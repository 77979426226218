import { IDeviceStatus } from "../models/device";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DeviceStatusApi extends ApiService<IDeviceStatus, IDeviceStatus, IDeviceStatus> {
    getRoute(): string {
        return "Devices/{deviceId}/DeviceStatuses";
    }
}