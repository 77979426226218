<div class="measuring-point-status">
    <div class="measuring-point-status__controls px-16 py-8">
        <form [formGroup]="statusForm"
              style="display:grid; grid-template-columns: repeat(6, min-content); gap:16px; align-items: center;">
            <label for="from">
                {{"general.from" | translate}}
            </label>

            <div style="min-width: 120px; margin-right: 16px;">
                <p-calendar id="rangeFromDate"
                            formControlName="from"
                            selectionMode="single"
                            autoWidth="true"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [dateFormat]="calendarSettings.dateFormat"
                            [yearRange]="primeComponentService.calendarYearRange"
                            [showIcon]="true"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [maxDate]="maxFromDate"
                            [numberOfMonths]="1"
                            (onSelect)="submit()"
                            (onClearClick)="submit()"
                            showButtonBar="true"
                            #rangeFromDateInput>
                    <ng-template pTemplate="date"
                                 let-date>
                        <span [ngClass]="dataDaysService.getCssStyle(date)">
                            {{date.day}}
                        </span>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                    </ng-template>
                </p-calendar>
                <app-form-validation [control]="rangeFromDateControl"
                                     [input]="rangeFromDateInput">
                </app-form-validation>
            </div>

            <label for="to">
                {{"general.until" | translate}}
            </label>

            <div style="min-width: 120px;">
                <p-calendar id="rangeUntilDate"
                            formControlName="until"
                            selectionMode="single"
                            autoWidth="false"
                            [showIcon]="true"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [dateFormat]="calendarSettings.dateFormat"
                            [yearRange]="primeComponentService.calendarYearRange"
                            [numberOfMonths]="1"
                            (onSelect)="submit()"
                            (onClearClick)="submit()"
                            showButtonBar="true"
                            #rangeUntilDateInput>
                    <ng-template pTemplate="date"
                                 let-date>
                        <span [ngClass]="dataDaysService.getCssStyle(date)">
                            {{date.day}}
                        </span>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                    </ng-template>
                </p-calendar>
                <app-form-validation [control]="rangeUntilDateControl"
                                     [input]="rangeUntilDateInput">
                </app-form-validation>
            </div>

            <!-- <label for="snapCoordinates"
                   style="min-width: 160px;">
                <input type="checkbox"
                       id="snapCoordinates"
                       formControlName="snapCoordinates"
                       (change)="submit(false)">
                {{"measuringPointStatusTab.snapToRoad" | translate}}
            </label> -->
        </form>
    </div>

    <div class="measuring-point-status__overview">
        <div class="location-logs m-shadow">
            <h3 class="p-16">{{"measuringPointStatusTab.locationHistoryTitle" | translate}}</h3>

            <app-loading-spinner *ngIf="isTableLoading"></app-loading-spinner>

            <ng-container *ngIf="!isTableLoading">
                <div class="m-border-top m-overflow-auto">
                    <div *ngFor="let record of locationHistory"
                         class="location-log"
                         [ngClass]="record.isSelected ? 'is-selected' : record.isUnknown ? 'is-unknown' : ''"
                         (click)="onViewModelClick(record)"
                         [id]="'record_' + record.id">

                        <div class="location-log__icon m-circle">
                            <app-svg-icon [icon]="record.icon">
                            </app-svg-icon>
                        </div>

                        <div class="location-log__timestamp">
                            <ng-container *ngIf="record.isSingleDay"><strong>{{record.from | date:'dd/MM/yyyy'}}</strong>
                                <br>
                                {{record.from | date:'HH:mm'}} → {{record.until | date:'HH:mm'}}
                            </ng-container>

                            <ng-container *ngIf="!record.isSingleDay"><strong>{{record.from | date:'dd/MM/yyyy'}}</strong>
                                <br>
                                {{record.from | date:'HH:mm'}} → <strong>{{record.until | date:'dd/MM/yyyy'}}</strong> {{record.until | date:'HH:mm'}}
                            </ng-container>
                        </div>

                        <div class="location-log__data">
                            <div>{{record.duration}}</div>
                            <div *ngIf="record.isTrip"><span *ngIf="record.distance !==  0">&gt; </span>{{record.distance}} km</div>
                            <div *ngIf="record.isUnknown"
                                 class="flex align-center gap-4">
                                <span class="m-circle m-circle-xs"
                                      style="background-color: var(--error);"></span>
                                {{"measuringPointStatusTab.positionUnknown" | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="location-map">
            <app-basic-map [showMapTypeSelector]="true"
                           (mapReady)="handleMapReady($event)"></app-basic-map>
        </div>
    </div>

    @if(showArrowBoardTimeline){
    <div class="measuring-point-status__alarms p-16">
        <app-measuring-point-arrow-board-timeline [measuringPoint]="measuringPoint"
                                                  [from]="statusForm.get('from').value"
                                                  [until]="statusForm.get('until').value"></app-measuring-point-arrow-board-timeline>
    </div>
    }
</div>