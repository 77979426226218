import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from "@angular/core";
import { NumberUtils } from "src/app/utilities";


@Directive({
    selector: "[appScrollDrag]",
    host: {
        "[style.user-select]": "'none'"
    }
})
export class ScrollDragDirective {
    @Input() scrollDirection: "Horizontal" | "Vertical" = "Horizontal";
    @Input() disableDrag = true;
    @Input() disableWheel = false;
    @Input() disableWheelWhenCtrlIsPressed = true;

    private startDragY?: number;
    private startDragX?: number;
    private isDragging = false;
    private readonly element: HTMLElement;
    private hasScrolled = false;

    constructor(private elemRef: ElementRef) {
        this.element = this.elemRef.nativeElement;
    }

    @HostListener("mousedown", ["$event"])
    startDrag(ev: MouseEvent): void {
        if (this.disableDrag) return;

        if (this.scrollDirection === "Vertical") {
            this.startDragY = ev.clientY + this.element.scrollTop;
        } else {
            this.startDragX = ev.clientX + this.element.scrollLeft;
        }

        ev.stop();

        this.hasScrolled = false;
        this.isDragging = true;
    }

    @HostListener("mousemove", ["$event"])
    drag(ev: MouseEvent): void {
        if (this.disableDrag) return;

        const isStillPressingButton = ev.buttons === 1;
        if (this.isDragging) {
            if (isStillPressingButton) {
                this.hasScrolled = true;
                if (this.scrollDirection === "Vertical") {
                    this.element.scrollTop = (this.startDragY ? this.startDragY - ev.clientY : 0);
                } else {
                    this.element.scrollLeft = (this.startDragX ? this.startDragX - ev.clientX : 0);
                }
            } else {
                this.stopDrag(ev);
            }
        }
    }

    @HostListener("mouseleave", ["$event"])
    leave(ev: MouseEvent): void {
        if (this.disableDrag) return;

        if (this.isDragging) {
            this.stopDrag(null);
        }
    }

    @HostListener("mouseup", ["$event"])
    stopDrag(ev: MouseEvent): void {
        if (this.disableDrag) return;

        if (this.hasScrolled) {
            ev?.stop();
            this.hasScrolled = false;
        }

        this.isDragging = null;
    }

    @HostListener("wheel", ["$event"])
    @HostListener("scroll", ["$event"])
    onScroll(ev: WheelEvent): void {
        if (this.disableWheel || (this.disableWheelWhenCtrlIsPressed && ev.ctrlKey)) return;

        if (NumberUtils.isValid(ev.deltaY)) {
            this.scroll(ev.deltaY);
        }
    }

    scroll(scrollOffset: number): void {
        const draggedElm = this.element;
        if (this.scrollDirection === "Vertical") {
            draggedElm.scrollTop = draggedElm.scrollTop + scrollOffset;
        } else {
            draggedElm.scrollLeft = draggedElm.scrollLeft + scrollOffset;
        }
    }
}