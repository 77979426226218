@if(data.lastUpdate){
<!--
 If there is no data, we don't show the status header for this device.
 The user can still expand the header and see some details, e.g. to see the code of the linked device.
 -->

<div class="flex align-center justify-between gap-16">
    <small pTooltip="{{data.lastUpdate | momentDateTime:true:true:translateService.currentLang}}">{{"liveTiles.lastUpdate" | translate}}: {{data.lastUpdate | momentRelativeTime:translateService.currentLang}}</small>

    <div class="flex align-center gap-16">
        <div class="flex align-center gap-8"
             *ngIf="data.currentPower">
            <app-svg-icon *ngIf="shouldShowBatteryIcon()"
                          [icon]="getBatteryIcon()"></app-svg-icon>

            <div *ngIf="shouldShowBatteryIcon()">{{(data.currentPower?.chargePercentage | number:'1.0-0') ?? "? "}}%</div>
            <div *ngIf="!shouldShowBatteryIcon()">{{data.currentPower?.batteryVoltage | number:'1.1-1'}}V</div>
        </div>

        <app-svg-icon *ngIf="data.currentNetwork?.signalStrength"
                      [icon]="getSignalStrengthIcon()"
                      fill="#26990f"></app-svg-icon>
    </div>
</div>
}