<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                [disabled]="isUpdatingAlarms"
                (click)="updateAlarms()">
            <app-loading-spinner *ngIf="isUpdatingAlarms">
            </app-loading-spinner>
            <app-svg-icon icon="alarm">
            </app-svg-icon>
            {{"deviceAlarms.update" | translate}}
        </button>

        <button class="m-btn m-btn-secondary"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"manageRule.create" | translate}}
        </button>
    </div>

    <app-rules></app-rules>
</div>