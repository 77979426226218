<ng-container *ngIf="currentSelectedObject">
    <div class="m-vms-designer-property p-16">
        <h3>{{"vmsImageEditor.alignment" | translate}}</h3>

        <div class="mt-8 flex flex-wrap">
            <a (click)="align(0)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignLeft' | translate}}">
                <app-svg-icon icon="align-left"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="align(4)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignCenterHorizontal' | translate}}">
                <app-svg-icon icon="align-center-horizontal"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="align(1)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignRight' | translate}}">
                <app-svg-icon icon="align-right"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="align(2)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignTop' | translate}}">
                <app-svg-icon icon="align-top"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="align(5)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignCenterVertical' | translate}}">
                <app-svg-icon icon="align-center-vertical"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="align(3)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignBottom' | translate}}">
                <app-svg-icon icon="align-bottom"
                              divClass="icon-16">
                </app-svg-icon>
            </a>


        </div>
    </div>
    <div class="m-vms-designer-property p-16">
        <h3 class="mt-8">{{'vmsImageEditor.display' | translate}}</h3>
        <label class="m-input-checkbox mt-8">
            <input type="checkbox"
                   id="smoothingSymbol"
                   [ngModel]="isObjectSmoothed"
                   (change)="setObjectSmoothed($event.target.checked)" />
            {{'vmsImageEditor.smoothen' | translate}}
        </label>
    </div>

    <div class="m-vms-designer-property p-16"
         *ngIf="currentSelectedObject.get('type') == 'i-text'">
        <h3>Tekst</h3>
        <!-- <h4 class="mt-8">{{"vmsImageEditor.alignment" | translate}}</h4>

        <div class="mt-8 flex flex-wrap">
            <a (click)="alignText(0)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignLeft' | translate}}">
                <app-svg-icon icon="align-left"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="alignText(4)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignCenterHorizontal' | translate}}">
                <app-svg-icon icon="align-center-horizontal"
                              divClass="icon-16">
                </app-svg-icon>
            </a>

            <a (click)="alignText(1)"
               class="m-btn m-btn-square"
               aria-hidden="true"
               title="{{'vmsImageEditor.alignRight' | translate}}">
                <app-svg-icon icon="align-right"
                              divClass="icon-16">
                </app-svg-icon>
            </a>
        </div> -->
        <div class="mt-8">
            <button (click)="toggleBold()"
                    title="{{'vmsImageEditor.bold' | translate}}"
                    class="m-vms-designer-icon-btn"
                    [ngClass]="{'is-selected': isBold}">
                <app-svg-icon icon="bold">
                </app-svg-icon>
            </button>
        </div>
        <div class="mt-16">
            <p-dropdown id="fontFamily"
                        [options]="fontFamilies"
                        [ngModel]="currentSelectedObject.fontFamily"
                        (onChange)="setFontFamily($event.value)"
                        [filter]="true"
                        [resetFilterOnHide]="true"
                        [autoDisplayFirst]="true"
                        [showClear]="false"
                        appendTo="body"
                        #fontFamilyDropdown>
            </p-dropdown>
        </div>
        <div class="m-input-slider-value mt-16">
            <p-slider id="zoomSlider"
                      [ngModel]="currentSelectedObject.fontSize"
                      [min]="9"
                      [max]="80"
                      [step]="1"
                      (onSlideEnd)="setFontSize($event.value)"
                      [debounceTime]="250">
            </p-slider>

            <input type="number"
                   [ngModel]="currentSelectedObject.fontSize"
                   min="9"
                   max="80"
                   (input)="setFontSize($event.target.value)"
                   class="p-inputtext" />
        </div>

        <div class="mt-16 flex align-center">
            <ngx-colors ngx-colors-trigger
                        [palette]="colorPalette"
                        [ngModel]="currentSelectedObject.fill"
                        (input)="updateFillColor($event)"></ngx-colors>
            <label class="ml-8">{{currentSelectedObject.fill}}</label>
        </div>
    </div>

    <ng-container *ngIf="currentSelectedObject.name != 'symbol'">
        <div class="m-vms-designer-property p-16"
             *ngIf="
                 currentSelectedObject.get('type') == 'rect' ||
                 currentSelectedObject.get('type') == 'circle' ||
                 currentSelectedObject.get('type') == 'triangle'">
            <h3>{{'vmsImageEditor.fill' | translate}}</h3>
            <div class="mt-8 flex align-center">
                <ngx-colors ngx-colors-trigger
                            [palette]="colorPalette"
                            [ngModel]="currentSelectedObject.fill"
                            (input)="updateFillColor($event)"></ngx-colors>
                <label class="ml-8">{{currentSelectedObject.fill}}</label>
            </div>
        </div>

        <div class="m-vms-designer-property p-16"
             *ngIf="
                 currentSelectedObject.get('type') == 'rect' ||
                 currentSelectedObject.get('type') == 'circle' ||
                 currentSelectedObject.get('type') == 'triangle' ||
                 currentSelectedObject.name == 'arrow' ||
                 currentSelectedObject.get('type') == 'line'">
            <h3>{{'vmsImageEditor.line' | translate}}</h3>

            <ng-container *ngIf="
                currentSelectedObject.get('type') == 'rect' ||
                currentSelectedObject.get('type') == 'circle' ||
                currentSelectedObject.get('type') == 'triangle'">
                <div class="mt-8 flex align-center">
                    <ngx-colors ngx-colors-trigger
                                [palette]="colorPalette"
                                [ngModel]="currentSelectedObject.stroke"
                                (input)="updateStrokeColor($event)"></ngx-colors>
                    <label class="ml-8">{{currentSelectedObject.stroke}}</label>
                </div>
            </ng-container>

            <ng-container *ngIf="
                currentSelectedObject.name == 'arrow' ||
                currentSelectedObject.get('type') == 'line'">
                <div class="mt-8 flex align-center">
                    <ngx-colors ngx-colors-trigger
                                [palette]="colorPalette"
                                [ngModel]="currentSelectedObject.fill"
                                (input)="updateColor($event)"></ngx-colors>
                    <label class="ml-8">{{currentSelectedObject.fill}}</label>
                </div>
            </ng-container>
            <ng-container *ngIf="currentSelectedObject.get('type') == 'rect'">
                <label class="mt-8 flex align-center">
                    <input type="checkbox"
                           id="roundedCorners"
                           [ngModel]="isRoundedCorners"
                           (change)="setRoundedCorners($event.target.checked)" />
                    {{'vmsImageEditor.roundedCorners' | translate}}
                </label>
            </ng-container>
            <ng-container *ngIf="currentSelectedObject.name != 'arrow'">
                <label class="mt-8 flex align-center">
                    <input type="checkbox"
                           id="dashedLine"
                           [ngModel]="isObjectDashed"
                           (change)="setObjectDashed($event.target.checked)" />
                    {{'vmsImageEditor.dottedLine' | translate}}
                </label>
            </ng-container>
            <h4 class="mt-16">{{'vmsImageEditor.thickness' | translate}}</h4>

            <ng-container *ngIf="
                currentSelectedObject.get('type') == 'rect' ||
                currentSelectedObject.get('type') == 'circle' ||
                currentSelectedObject.get('type') == 'triangle' ||
                currentSelectedObject.get('type') == 'line'">
                <div class="m-input-slider-value mt-8">
                    <p-slider id="strokeWidth"
                              [style]="{'width': '100%'}"
                              [ngModel]="currentSelectedObject.strokeWidth"
                              [min]="2"
                              [max]="10"
                              [step]="1"
                              (onSlideEnd)="setStrokeWidth($event.value)"
                              [debounceTime]="250">
                    </p-slider>

                    <input type="number"
                           [ngModel]="currentSelectedObject.strokeWidth"
                           min="2"
                           max="10"
                           (input)="setStrokeWidth($event.target.value)"
                           class="p-inputtext" />
                </div>
            </ng-container>

            <ng-container *ngIf="currentSelectedObject.name == 'arrow'">
                <div class="m-input-slider-value mt-8">
                    <p-slider id="arrowLineWidth"
                              [style]="{'width': '100%'}"
                              [ngModel]="arrowLineWidth"
                              [min]="1"
                              [max]="10"
                              [step]="1"
                              (onSlideEnd)="setArrowLineWidth($event.value)"
                              [debounceTime]="250">
                    </p-slider>

                    <input type="number"
                           class="p-inputtext"
                           [ngModel]="arrowLineWidth"
                           min="1"
                           max="10"
                           (input)="setArrowLineWidth($event.target.value)" />
                </div>

                <h4 class="mt-16">{{'vmsImageEditor.arrow' | translate}}</h4>

                <div class="m-input-slider-value mt-8">
                    <p-slider id="arrowTriangleWidth"
                              [style]="{'width': '100%'}"
                              [ngModel]="arrowTriangleWidth"
                              [min]="1"
                              [max]="10"
                              [step]="1"
                              (onSlideEnd)="setArrowTriangleWidth($event.value)"
                              [debounceTime]="250"></p-slider>

                    <input type="number"
                           class="p-inputtext"
                           [ngModel]="arrowTriangleWidth"
                           min="1"
                           max="10"
                           (input)="setArrowTriangleWidth($event.target.value)" />
                </div>
            </ng-container>

        </div>
    </ng-container>

    <div class="m-vms-designer-property p-16"
         *ngIf="
        currentSelectedObject.name == 'symbol'">
        <h3 class="mt-8">{{'vmsImageEditor.colors' | translate}}</h3>
        <ng-container *ngFor="let color of uniqueColorsOfSelectedObject">
            <div class="mt-8 flex align-center">
                <ngx-colors ngx-colors-trigger
                            [palette]="colorPalette"
                            [ngModel]="color"
                            (input)="updateFillColorSymbol(color, $event)"></ngx-colors>
                <label class="ml-8">{{color}}</label>
            </div>
        </ng-container>
    </div>
</ng-container>