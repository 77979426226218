import { TableColumn, FilterType, ColumnType, ColumnVisibility, TableService, LazyTableComponent } from "../table/table.component";
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { SearchParameters, FilterDescriptor, ServiceRequestOptions } from "src/app/models/search";
import { GroupMeasuringPointsComponent } from "../group-measuring-points/group-measuring-points.component";
import { IComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { ChangeGuardService } from "src/app/services/change-guard.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import { GroupApi } from "src/app/resource/group.api";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { IGroup } from "src/app/models/group";
import { BackendRights } from "src/app/models/backend-rights";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-groups",
    templateUrl: "./groups.component.html"
})
export class GroupsComponent extends LazyTableComponent<IGroup> implements OnInit, OnChanges, OnDestroy, IComponentCanDeactivate {
    @ViewChild(GroupMeasuringPointsComponent, { static: true }) measuringPointsComponent: GroupMeasuringPointsComponent;

    @Input() readQueryParams = true;
    @Input() showMeasuringPointsOnSelect = true;
    @Input() deleteCommand = false;
    @Input() navigation = true;
    @Input() ownerId: number;

    groupToShowMeasuringPointsFor: IGroup;

    private navigatingToId: number;
    canDeleteGroup: boolean;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly modalService: ModalService,
        private readonly groupApi: GroupApi,
        private readonly translate: TranslateService,
        private readonly changeGuardService: ChangeGuardService) {

        super("groups.component", elementRef, groupApi, tableService);

        this.stretchHeight = true;
        this.canDeleteGroup = this.rights?.hasBackendRight(BackendRights.DeleteGroup);
        const codeColumn = new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, width: 300 });
        this.addColumn(codeColumn);
        this.setMainColumn(codeColumn);

        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.None, sortable: true, visibility: ColumnVisibility.HideCompact, width: 400 }));
        this.addColumn(new TableColumn("color", "general.color", { sortable: true, type: ColumnType.Icon }));

        const organizationColumn = new TableColumn("ownerId", "general.organization", { filterType: FilterType.Dropdown, sortable: true, visibility: ColumnVisibility.HideMini, width: 300, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true });
        this.addColumn(organizationColumn);

        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.services.mapDataService.subscribeToEditGroupUpdate(this.mapDataServiceKey, editGroup => {
            this.groupToShowMeasuringPointsFor = editGroup;
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (group) => this.delete(group),
            validFunc: () => this.deleteCommand && this.canDeleteGroup
        });
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.readQueryParams) {
            const sub = this.route
                .queryParams
                .subscribe(params => {
                    this.navigatingToId = params["id"];
                });

            this.subscriptionManager.add("queryParamsSub", sub);
        }

        this.loadTableRows();
    }

    ngOnChanges(changes: SimpleChanges) {
        const showMeasuringPointsOnSelectChange = changes["showMeasuringPointsOnSelect"];
        if (showMeasuringPointsOnSelectChange) {
            if (!this.showMeasuringPointsOnSelect && this.groupToShowMeasuringPointsFor) {
                this.groupToShowMeasuringPointsFor = null;
            }
        }

        const ownerIdChange = changes["ownerId"];
        if (ownerIdChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    canDeactivate(): Promise<boolean> {
        return this.changeGuardService.canDeactivate();
    }

    onSelectionChange() {
        if (!this.showMeasuringPointsOnSelect) {
            this.groupToShowMeasuringPointsFor = null;
            return;
        }

        this.groupToShowMeasuringPointsFor = this.getSelection().takeFirstOrDefault();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.ownerId) {
            searchParameters.filter.push(new FilterDescriptor("ownerId", this.ownerId));
        }

        return searchParameters.filter.length ? searchParameters : null;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("group", "measuringPoints");

        return serviceRequestOptions;
    }

    onSetData() {
        if (this.navigatingToId) {
            const group = this.data.find(x => x.id === this.navigatingToId);

            if (group) {
                setTimeout(() => {
                    this.selectRow(group);
                }, 100);
            }

            this.navigatingToId = null;
        }
    }

    delete(group: IGroup) {
        if (!this.canDeleteGroup) return;

        const onDeleteSuccess = () => {
            if (this.isSelected(group)) {
                this.deselectRow(group);
                this.selectRow(this.data.takeFirstOrDefault());
            }

            this.removeData([group]);
        };

        const onDelete = () => {
            this.groupApi.delete$(group.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("groups.deleteConfirmation", { code: group.code });
        this.modalService.delete(modalBody, onDelete);
    }
}