<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [resizable]="false">

    <p-header>
        {{"vmsImageEditor.symbols" | translate}}
    </p-header>

    <div class="p-16">
        <ng-container *ngIf="symbolSets">
            <div class="mb-16">
                <app-fuzzy-search-input class="fuzzy-search-input"
                                        [placeholder]="'symbolSelector.search'"
                                        (fuzzySearchStart)="handleFuzzySearchStart($event)"></app-fuzzy-search-input>
            </div>

            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: symbolSets }"></ng-container>
        </ng-container>
    </div>

    <p-footer style="display: flex; width: 100%">
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                style="margin-left: auto">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting || !selectedSymbol"
                class="m-btn m-btn-primary"
                (click)="submit() "
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"symbolSelector.insert" | translate}}
        </button>
    </p-footer>
</p-dialog>

<ng-template #recursiveList
             let-symbolSets>
    <details class="m-details"
             *ngFor="let symbolSet of symbolSets"
             [ngClass]="{'is-toplevel': !symbolSet.parentSymbolSetId}"
             (toggle)="loadSymbols(symbolSet.id)">
        <summary class="m-details-summary">
            {{symbolSet.name}}

            <app-svg-icon icon="chevron-down"
                          divClass="icon-16">
            </app-svg-icon>
        </summary>

        <div class="m-details-content">
            <ul class="m-vms-designer-symbols">
                <li *ngFor="let symbol of getSymbols(symbolSet.id)"
                    class="m-vms-designer-symbol">
                    <!--Load SVG-->
                    <a class="m-vms-designer-symbol-link"
                       (click)="selectSymbol(symbol)"
                       [ngClass]="{'is-selected': isSelected(symbol)}">
                        <img class="m-vms-designer-symbol-image"
                             [src]="'data:image/svg+xml;charset=utf-8,' + encodeSvg(symbol.svgData)">

                        <span class="m-vms-designer-symbol-label">{{symbol.code}}</span>

                        <div class="m-vms-designer-symbol-checked"
                             *ngIf="isSelected(symbol)">
                            <app-svg-icon icon="circle-check"
                                          fill="#26990f"
                                          divClass="icon-20">
                            </app-svg-icon>
                        </div>
                    </a>
                </li>
            </ul>

            <ng-container *ngIf="symbolSet.children">
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: symbolSet.children }"></ng-container>
            </ng-container>
        </div>
    </details>
</ng-template>