<div class="m-page-content-wrapper p-16">
    <div class="m-card m-fixed-height m-layout-w-actions-top">
        <app-tabs [tabs]="tabs"
                  class="m-layout-area-top"
                  [selectedTab]="selectedTab"
                  [relativeRouting]="true"
                  (selectedTabChange)="navigateTo($event);">
        </app-tabs>

        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
</div>