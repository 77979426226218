import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ResultContainerComponent } from "./results/result-container/result-container.component";
import { FilterContainerComponent } from "./filters/filter-container/filter-container.component";
import { SearchBoxComponent } from "./search-box/search-box.component";
import { FilterService } from "../../services/filter.service";
import { SubscriptionManager } from "src/app/utilities";
@Component({
    selector: "app-search-panel",
    templateUrl: "./search-panel.component.html",
    styleUrls: ["./search-panel.component.scss"],
})
export class SearchPanelComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild(ResultContainerComponent) public resultContainer: ResultContainerComponent;
    @ViewChild(FilterContainerComponent) public filterContainer: FilterContainerComponent;
    @ViewChild(SearchBoxComponent) public searchBox: SearchBoxComponent;

    // Hijack hidden property so we can listen to it
    // This component isn't managed through API, but rather through properties
    @Input() hidden = true;
    @Output() hiddenSet = new EventEmitter<boolean>();
    @Output() public onClose: EventEmitter<void> = new EventEmitter();

    private readonly subscriptionManager = new SubscriptionManager();
    private readonly filterService = inject(FilterService); // Used to share the search parameters between components

    constructor(private readonly element: ElementRef<HTMLElement>) {
        const sub = this.filterService.onFilterStateChanged$.subscribe(async () => {
            if (!this.filterService.filterState.isEmpty() && this.hidden) {
                // open search panel if not open, we've added a filter
                // gotta make this clear to the user somehow
                // and because it's property-wise due to some viewmodel
                // we gotta pass the "hidden" property value
                setTimeout(() => {
                    this.hiddenSet.next(false);
                });
            }

            await this.loadData();
        });
        this.subscriptionManager.add("onFilterStateChanged", sub);

        this.updateHidden();
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        // if (!this.filterService.filterState.isEmpty()) {
        //     setTimeout(() => {
        //         this.filterService.setQueryParametersInUrl();
        //     })
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        const hiddenChange = changes["hidden"];
        if (hiddenChange) {
            this.updateHidden();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.filterService.hideQueryParametersFromUrl();
    }

    updateSearchBoxFocus() {
        setTimeout(() => {
            this.searchBox?.inputElement?.nativeElement?.focus();
        }, 1);
    }

    private updateHidden() {
        if (!this.hidden) {
            setTimeout(() => { // JavaScript, I've missed you.
                this.searchBox?.inputElement?.nativeElement?.focus();
            }, 1);
        }

        this.element.nativeElement.hidden = this.hidden;
    }

    protected close() {
        this.onClose.emit();
    }

    public async loadData() {
        // It's possible we set data earlier than the UI exists (when parsing query params)
        // That's okay, no need to wait for the UI for us to set our data,
        // But it does trigger this; so only load if UI exists
        // If it doesn't, this function will be triggered later in the normal lifecycle
        if (!this.resultContainer || this.filterService.parsingFromQueryParams) return;

        const searchParameters = this.filterService.getSearchParameters();
        await this.resultContainer.loadResults(searchParameters);
    }

    protected async handleCreateFilterClicked(searchTerm: string) {
        this.filterContainer.setSearchTermFilter(searchTerm);
    }


}

