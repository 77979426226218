import { ITotemDisplayLiveData } from "./totem-display-configuration";
import { IVmsMode } from "./mode-configuration";
import { LightAction, LightContent, LightContentFrame } from "./lightAction";
import { DurationUtils } from "src/app/utilities";
import { IArrowBoardStatus, IPowerStatus, ITrafficLightStatus } from "./device";
import { MessageLevel } from "./pinned-data";

export class ParkingData {
    open: boolean;
    capacity: number;
    vacant: number;
    isSimulated: boolean;
}

export interface IExternalInputStatus {
    externalInputId: string;
    value: boolean;
}

export interface IMeasuringPointStatusData {
    externalInputs: IExternalInputStatus[];
    currentMode: IVmsMode;
    lightAction: LightAction;
    totemDisplay: ITotemDisplayLiveData;
    power: IPowerStatus;
    trafficLight: ITrafficLightStatus;
    userMessages: IMessageAndLevel[];
    arrowBoardStatus: IArrowBoardStatus;

}

export interface IMessageAndLevel {
    level: MessageLevel;
    message: string; // A value from the UserMessage enum from the BE. The DomainDataService can be used to get the translation
    formatParameters: string[];
}

// Some C# classes use the [MessagePackObject] and [Key] attributes because that makes the data more compact
// (which is important when using Zigbee).
// However in TypeScript this data is deserialized as arrays instead of objects.
// This method is a dirty hack to fix this.
export class MeasuringPointStatusConverter {
    public static convertFromMessagePack(status: IMeasuringPointStatus) {
        if (status.data.lightAction === null) return;
        if (status.data.lightAction instanceof LightAction) return; // No conversion needed

        const rawData = status.data.lightAction; // An array, the order is based on the [Key] attributes in C#
        status.data.lightAction = new LightAction();
        status.data.lightAction.priority = rawData[0];
        status.data.lightAction.content = MeasuringPointStatusConverter.getLightContent(rawData[1]);
        status.data.lightAction.ownerId = rawData[3];
    }

    private static getLightContent(rawData: any): LightContent {
        if (rawData === null) return null;
        const result = new LightContent();
        result.modeId = rawData[0];
        result.animation = this.getAnimation(rawData[1]);
        result.modeDescription = rawData[2];
        return result;
    }

    private static getAnimation(rawData: any): LightContentFrame[] {
        if (rawData === null) return null;
        const result = [];
        for (const frameData of rawData) {
            const frame = new LightContentFrame();
            frame.modeId = frameData[0];
            frame.duration = DurationUtils.fromSeconds(frameData[1] / 10000000); // This is the way MessagePack sends durations...
            frame.modeDescription = frameData[2];
            result.push(frame);
        }
        return result;
    }
}

export interface IMeasuringPointStatus {
    measuringPointId: number;
    timestamp: Date;
    data: IMeasuringPointStatusData;
}

export interface IMeasuringPointStatusCreator {
    timestamp: Date;
    data: IMeasuringPointStatusData;
}