<p-table [value]="data"
         (onRowSelect)="emitSelect($event)"
         (onRowUnselect)="emitDeselect($event)"
         styleClass="ui-table-compact"
         selectionMode="multiple"
         [(selection)]="selectedSubtasks"
         [loading]="loading"
         class="m-layout-area-body m-layout-default">
    <ng-template pTemplate="body"
                 let-subtask>
        <tr [pSelectableRow]="subtask">
            <td style="width: 10%">
                <span class="m-circle m-circle-grey center">
                    <app-svg-icon *ngIf="subtask.type === subtaskTaskType;else parkingBan"
                                  icon="task"
                                  tooltip="{{'assignmentSubtasks.taskTooltip' | translate}}">
                    </app-svg-icon>
                    <ng-template #parkingBan>
                        <app-svg-icon icon="parking-ban"
                                      tooltip="{{'assignmentSubtasks.parkingBanTooltip' | translate}}">
                        </app-svg-icon>
                    </ng-template>
                </span>
            </td>
            <td>{{subtask.name}}</td>
            <td>{{subtask.assignmentName}}</td>
            <td>
                <div class="flex align-center">
                    <app-svg-icon icon="location"
                                  tooltip="{{'general.location' | translate}}">
                    </app-svg-icon>

                    <span class="ml-8">
                        {{subtask.locationCode}}
                    </span>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage"
                 let-columns>
        <tr class="emptyTableMessage"
            *ngIf="!loading">
            <td translate="{{ 'table.empty' }}"></td>
        </tr>
    </ng-template>
</p-table>