import { ManageBikeParameterSetDialogComponent } from "../manage-bike-parameter-set-dialog/manage-bike-parameter-set.dialog";
import { BikeParameterSetsComponent } from "../bike-parameter-sets/bike-parameter-sets.component";
import { Component, ViewChild, ElementRef } from "@angular/core";

@Component({
    selector: "app-admin-bike-parameter-sets",
    templateUrl: "./admin-bike-parameter-sets.component.html"
})
export class AdminBikeParameterSetsComponent {
    @ViewChild(ManageBikeParameterSetDialogComponent, { static: true }) manageBikeParameterSetDialog: ManageBikeParameterSetDialogComponent;
    @ViewChild(BikeParameterSetsComponent, { static: true }) drivingLanesComponent: BikeParameterSetsComponent;

    constructor(
        elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");
    }

    create() {
        this.manageBikeParameterSetDialog.create(() => {
            this.drivingLanesComponent.reload();
        });
    }
}