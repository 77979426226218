import { environment } from "src/environments/environment";
import { Component, inject, OnDestroy } from "@angular/core";
import { MetaApi } from "src/app/resource/meta.api";
import { ConfigurationService } from "src/app/services/configuration.service";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { SubscriptionManager } from "src/app/utilities";
import { IUser } from "src/app/models/user";
import { WebsiteService } from "src/app/services/website.service";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html"
})
export class AboutComponent implements OnDestroy {
    apiVersion: string;
    guiVersion: string;
    apiUrl: string;
    isAdmin: boolean;
    user: IUser;

    private subscriptionManager = new SubscriptionManager();

    protected readonly websiteService = inject(WebsiteService);
    private readonly metaApi = inject(MetaApi);
    private readonly configurationService = inject(ConfigurationService);
    public readonly globalEventsService = inject(GlobalEventsService);

    constructor(

    ) {
        const authorizationInfoSubscriprtion = this.globalEventsService.authorizationInfo.subscribe(result => {
            this.isAdmin = result?.isDomainAdministrator;
            this.user = result?.user;
        });
        this.subscriptionManager.add("authorizationInfoSubscriprtion", authorizationInfoSubscriprtion);

        this.guiVersion = environment.version;
        const url = this.configurationService.configuration.url;
        this.apiUrl = url.substring(0, url.lastIndexOf("/"));
        this.metaApi.version$().subscribe(result => {
            this.apiVersion = result.version;
        });
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }
}