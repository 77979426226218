import { TableColumn, ApiTableComponent, FilterType, ColumnVisibility, TableService } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";
import { Component, Input, ElementRef, OnInit } from "@angular/core";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { NavigationService } from "src/app/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { SharedKeyApi } from "src/app/resource/shared-key.api";
import { ISharedKey } from "src/app/models/shared-key";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { OrganizationUtils } from "src/app/utilities";

@Component({
    selector: "app-shared-keys",
    templateUrl: "./shared-keys.component.html"
})
export class SharedKeysComponent extends ApiTableComponent<ISharedKey> implements OnInit {
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    importKey: string;
    submitting = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly sharedKeyApi: SharedKeyApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly accessibilityService: AccessibilityService,
        private readonly navigationService: NavigationService) {

        super("shared-keys.component", elementRef, sharedKeyApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;
        this.footer = false;
        this.selectionMode = "";

        const ownerColumn = new TableColumn("owner.name", "general.organization", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 200, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true });
        this.addColumn(ownerColumn);

        // Load Organizations for drop-down filter
        this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
            ownerColumn.filterOptions = this.services.primeComponentService.createDropdownList(
                organizations,
                (x) => x.id,
                (x) => x.name,
                false
            );
        });

        this.addColumn(new TableColumn("key", "sharedKeys.key", { filterType: FilterType.Text, sortable: true, width: 300, resizable: false }));
        this.addColumn(new TableColumn("allowValidation", "sharedKeys.allowValidation", { filterType: FilterType.Boolean, sortable: true, visibility: ColumnVisibility.HideMini, width: 145 }));
        this.addColumn(new TableColumn("shareAllMeasuringPoints", "sharedKeys.shareAllMeasuringPointsHeader", { filterType: FilterType.Boolean, sortable: true, visibility: ColumnVisibility.HideMini, width: 120 }));
        this.addColumn(new TableColumn("measuringPoints", "general.measuringPoints", { visibility: ColumnVisibility.HideCompact }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true, width: 150 }));
        this.addColumn(new TableColumn("importedBy", "sharedKeys.importedBy", { visibility: ColumnVisibility.HideCompact }));

        this.registerCommand({
            text: "sharedKeys.edit",
            icon: "edit",
            click: (sharedKey) => this.edit(sharedKey),
            validFunc: () => this.editCommand
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (sharedKey) => this.delete(sharedKey),
            validFunc: () => this.deleteCommand
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadTableRows();
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();

        searchParameters.sort = [new SortDescriptor(SortDirection.descending, "createDate")];

        return searchParameters;
    }

    create() {
        this.navigationService.createNewSharedKey();
    }

    edit(sharedKey: ISharedKey) {
        this.navigationService.toSharedKey(sharedKey);
    }

    delete(sharedKey: ISharedKey) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.sharedKeyApi.delete$(sharedKey.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("sharedKeys.deleteConfirmation", { key: sharedKey.key });
        this.modalService.delete(modalBody, onDelete);
    }

    copyToClipboard(sharedKey: ISharedKey) {
        this.accessibilityService.copyToClipboard(sharedKey.key, "sharedKeys.copiedToClipboard");
    }
}