export interface ILightMode {
    id: string;
    description: string;
    iconUrl?: string;
}

export interface ILightModeConfiguration {
    modes: ILightMode[];
    defaultModeId: string;
}


export interface IVmsMode {
    id: string;
    description: string;
    frames: IVmsFrame[];
}

export interface IVmsModeConfiguration {
    modes: IVmsMode[];
    defaultModeId: string;
}
export interface IVmsFrame {
    displayTimeMs: number;
    vmsImageId: number;
    vmsImageVariantId: number;
}
