import { ErrorHandler, Injector, Injectable, ApplicationRef } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastService } from "../services/toast.service";
import { environment } from "src/environments/environment";
import { ErrorApi } from "../resource/error.api";
import { Router } from "@angular/router";
import * as StackTrace from "stacktrace-js";
import * as Bowser from "bowser";
import { AuthError } from "@azure/msal-browser";

@Injectable()
export class SigncoErrorHandler implements ErrorHandler {
    private errorApi: ErrorApi;
    private toastService: ToastService;
    private appRef: ApplicationRef;
    private router: Router;

    private readonly hiddenHttpErrorCodes = [
        0, // Server offline
        304, // Not Modified
        401, // Unauthorized
        403, // Forbidden
        504 // gateway timeout (openstreetmap tiles)
    ];

    private readonly dontSendToErrorApiHttpErrorCodes = [
        500 // Internal Server Error
    ];

    private readonly hiddenToastErrors = [
        "ViewDestroyedError",
        "Unauthorized",
        "Loading chunk",
        "downloadable font"
    ];

    private previousStackString: string;

    constructor(private readonly injector: Injector) {
    }

    static getBrowser(): string {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return `${browser.getBrowserName()} ${browser.getBrowserVersion()} - ${browser.getOSName()} ${browser.getOSVersion()}`;
    }

    handleError(error: Error) {
        let sendToErrorApi = true;
        let displayToast = true;

        if (!this.errorApi) {
            this.errorApi = this.injector.get(ErrorApi);
        }

        if (!this.toastService) {
            this.toastService = this.injector.get(ToastService);
        }

        if (!this.appRef) {
            this.appRef = this.injector.get(ApplicationRef);
        }

        if (!this.router) {
            this.router = this.injector.get(Router);
        }

        if ((error as any).rejection) {
            error = (error as any).rejection;
        }

        if (error instanceof AuthError) {
            console.warn("AuthError", error);
            return;
        }
        if (error instanceof HttpErrorResponse) {
            if (this.hiddenHttpErrorCodes.contains(error.status)) {
                // console.error(`Hidding HTTP error ${error.status}`, error);
                return;
            }

            displayToast = error.status !== 404;
            sendToErrorApi = !this.dontSendToErrorApiHttpErrorCodes.contains(error.status);
        }

        if (sendToErrorApi) {
            if (this.errorApi) {
                const onSuccess = () => { };
                const onError = () => { };

                const userAgent = `Browser: ${SigncoErrorHandler.getBrowser()}`;
                const version = `SigncoWeb v${environment.version}`;

                try {
                    StackTrace.fromError(error).then(stackFrames => {
                        const stackString =
                            stackFrames
                                .splice(0, 20)
                                .map(sf => sf.toString())
                                .join("\n");

                        if (this.previousStackString === stackString) return;
                        this.previousStackString = stackString;

                        const errorString = `${this.router.url}\n${error.name}\n${error.message}\n${stackString}\n${userAgent}\n${version}`;
                        this.errorApi.create$(errorString).subscribe(onSuccess, onError);
                    }, () => {
                        throw error;
                    });
                } catch (e) {
                    const errorString = `StackTrace-js couldn't parse error! ${error.name}\n${error.message}\n${error.stack}\n${userAgent}\n${version}`;
                    this.errorApi.create$(errorString).subscribe(onSuccess, onError);
                }
            }
        }

        if (error.message) {
            for (const hiddenToastError of this.hiddenToastErrors) {
                if (error.message.contains(hiddenToastError)) {
                    displayToast = false;
                    break;
                }
            }

            if (displayToast) {
                if (this.toastService) {
                    this.toastService.error("errors.general");
                }
            }
        }

        if (!(error instanceof HttpErrorResponse)) {
            // this.appRef.tick();
            // setTimeout(null);
        }

        if (!environment.production) {
            console.error(error);
        }

        // throw error;
    }
}