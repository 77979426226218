
import { MeasuringPointStatusApi } from "src/app/resource/measuring-point-status.api";
import { IMeasuringPointStatus, IMeasuringPointStatusData } from "src/app/models/measuring-point-status";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { ToastService } from "src/app/services/toast.service";
import { Component } from "@angular/core";
import { ILightMode, IVmsMode } from "src/app/models/mode-configuration";
import { DownloadFileService } from "src/app/services/download-file.service";
import { FileUtils } from "src/app/utilities";
import { LightAction, LightContent } from "src/app/models/lightAction";

class LightModeViewModel {
    public mode: ILightMode;
    public iconPreview?: string;
}

@Component({
    selector: "app-light-manage-dialog",
    templateUrl: "./light-manage-dialog.component.html"
})
export class LightManageDialogComponent extends DialogComponentBase {

    measuringPointId: number;
    viewModels: LightModeViewModel[];
    isSaving = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly measuringPointStatusApi: MeasuringPointStatusApi,
        private readonly downloadFileService: DownloadFileService
    ) {
        super();
    }

    public async open(measuringPointId: number, modes: ILightMode[]) {
        this.measuringPointId = measuringPointId;
        this.viewModels = [];
        for (const mode of modes) {
            const vm = new LightModeViewModel();
            vm.mode = mode;
            if (vm.mode.iconUrl) {
                const file = await this.downloadFileService.downloadBlob(vm.mode.iconUrl);
                vm.iconPreview = await FileUtils.toBase64(file.file);
            }
            this.viewModels.push(vm);
        }

        this.openDialog();
    }

    async onSelect(mode: LightModeViewModel) {
        const onSuccess = async (_: IMeasuringPointStatus) => {
            this.toastService.saveSuccess();
            this.isSaving = false;
            this.close();
        };

        const onError = () => {
            this.isSaving = false;
        };

        this.isSaving = true;

        const action = new LightAction();
        action.content = new LightContent();
        action.content.modeId = mode.mode.id;
        action.content.modeDescription = mode.mode.description;

        const data = {
            lightAction: action
        } as IMeasuringPointStatusData;
        const status = {
            measuringPointId: this.measuringPointId,
            timestamp: new Date(),
            data: data
        } as IMeasuringPointStatus;

        this.measuringPointStatusApi.create$(status, { measuringPointId: this.measuringPointId.toString() })
            .subscribe({
                next: onSuccess,
                error: onError
            });
    }
}