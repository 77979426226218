<ng-container *ngIf="device">
    <form [formGroup]="displayForm"
          class="m-border-top m-overflow-auto m-layout-area-body"
          autocomplete="off">

        <ng-container *ngIf="bikeCounterDisplayForm"
                      formGroupName="bikeCounterDisplay">
            <div class="p-16">
                <div class="m-form-group mb-16">
                    <p>{{"deviceDisplay.guide" | translate:device.displayConfiguration.bikeCounterDisplay}}</p>
                    <p [innerHTML]="'deviceDisplay.guide2' | translate"></p>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': bikeCounterDisplayForm.get('text').showErrors }">

                    <label class="col-2"
                           for="text">
                        {{"deviceStatus.text" | translate}}
                    </label>

                    <div class="col-10">
                        <textarea id="text"
                                  class="p-inputtextarea"
                                  formControlName="text"
                                  appAutofocus
                                  appTextFieldValidator
                                  #textInput></textarea>

                        <app-form-validation [control]="bikeCounterDisplayForm.get('text')"
                                             [input]="textInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': bikeCounterDisplayForm.get('initialText').showErrors }">

                    <label class="col-2"
                           for="initialText">
                        {{"deviceStatus.initialText" | translate}}
                    </label>

                    <div class="col-10">
                        <textarea id="initialText"
                                  class="p-inputtextarea"
                                  formControlName="initialText"
                                  appTextFieldValidator
                                  #initialTextInput></textarea>

                        <app-form-validation [control]="bikeCounterDisplayForm.get('initialText')"
                                             [input]="initialTextInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="qLiteConfigurationForm">
            <ng-container formGroupName="qLite">
                <div class="p-16 m-border-top m-border-bottom">
                    <h3>{{"deviceStatus.qLite" | translate}}</h3>
                </div>

                <div class="p-16">
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': qLiteConfigurationForm.get('enableQLiteDisplay').showErrors }">

                        <label class="col-2"
                               for="enableQLiteDisplay">
                            {{"deviceDisplay.enableQLiteDisplay" | translate}}
                        </label>

                        <div class="col-10">
                            <p-inputSwitch id="enableQLiteDisplay"
                                           formControlName="enableQLiteDisplay"
                                           #enableQLiteDisplayInput>
                            </p-inputSwitch>
                        </div>
                    </div>

                    <div class="m-form-group row">
                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLitePortName').showErrors }">

                                <label class="col-4"
                                       for="qLitePortName">
                                    {{"deviceDisplay.qLitePortName" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLitePortName"
                                           class="p-inputtext"
                                           formControlName="qLitePortName"
                                           type="text"
                                           #qLitePortNameInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLitePortName')"
                                                         [input]="qLitePortNameInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLiteCounterFormat').showErrors }">

                                <label class="col-4"
                                       for="qLiteCounterFormat">
                                    {{"deviceDisplay.qLiteCounterFormat" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLiteCounterFormat"
                                           class="p-inputtext"
                                           formControlName="qLiteCounterFormat"
                                           type="text"
                                           #qLiteCounterFormatInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLiteCounterFormat')"
                                                         [input]="qLiteCounterFormatInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-form-group row">
                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLiteCounterId').showErrors }">

                                <label class="col-4"
                                       for="qLiteCounterId">
                                    {{"deviceDisplay.qLiteCounterId" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLiteCounterId"
                                           class="p-inputtext"
                                           formControlName="qLiteCounterId"
                                           type="number"
                                           #qLiteCounterIdInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLiteCounterId')"
                                                         [input]="qLiteCounterIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLiteCounterGroupId').showErrors }">

                                <label class="col-4"
                                       for="qLiteCounterGroupId">
                                    {{"deviceDisplay.qLiteCounterGroupId" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLiteCounterGroupId"
                                           class="p-inputtext"
                                           formControlName="qLiteCounterGroupId"
                                           type="number"
                                           #qLiteCounterGroupIdInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLiteCounterGroupId')"
                                                         [input]="qLiteCounterGroupIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': qLiteConfigurationForm.get('enableQLiteText').showErrors }">

                        <label class="col-2"
                               for="enableQLiteText">
                            {{"deviceDisplay.enableQLiteText" | translate}}
                        </label>

                        <div class="col-10">
                            <p-inputSwitch id="enableQLiteText"
                                           formControlName="enableQLiteText"
                                           #enableQLiteTextInput>
                            </p-inputSwitch>
                        </div>
                    </div>

                    <div class="m-form-group row">
                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLiteTextId').showErrors }">

                                <label class="col-4"
                                       for="qLiteTextId">
                                    {{"deviceDisplay.qLiteTextId" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLiteTextId"
                                           class="p-inputtext"
                                           formControlName="qLiteTextId"
                                           type="number"
                                           #qLiteTextIdInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLiteTextId')"
                                                         [input]="qLiteTextIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="m-form-group row"
                                 [ngClass]="{ 'has-error': qLiteConfigurationForm.get('qLiteTextGroupId').showErrors }">

                                <label class="col-4"
                                       for="qLiteTextGroupId">
                                    {{"deviceDisplay.qLiteTextGroupId" | translate}}
                                </label>

                                <div class="col-8">
                                    <input id="qLiteTextGroupId"
                                           class="p-inputtext"
                                           formControlName="qLiteTextGroupId"
                                           type="number"
                                           #qLiteTextGroupIdInput>

                                    <app-form-validation [control]="qLiteConfigurationForm.get('qLiteTextGroupId')"
                                                         [input]="qLiteTextGroupIdInput">
                                    </app-form-validation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="linksLoaded && bikeCounterDisplayForm"
                      formGroupName="bikeCounterDisplay">
            <div class="p-16 m-border-bottom m-border-top">
                <h3>{{"deviceStatus.filters" | translate}}</h3>
            </div>

            <div class="p-16">
                <div class="m-form-group row"
                     formGroupName="filters">

                    <label class="col-2">
                        {{"deviceDisplay.linkFilter" | translate}}
                    </label>

                    <div class="col-10">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width: 100%;">
                                        <div class="m-column-header">
                                            <span class="m-column-title">
                                                {{"general.measuringPoint" | translate}}
                                            </span>
                                        </div>
                                    </th>

                                    <th>
                                        <app-svg-icon icon="arrow-right"
                                                      tooltip="{{'measuringPoints.primaryDirection' | translate}}"
                                                      class="icon-arrow">
                                        </app-svg-icon>
                                    </th>

                                    <th>
                                        <app-svg-icon icon="arrow-left"
                                                      tooltip="{{'measuringPoints.reverseDirection' | translate}}"
                                                      class="icon-arrow">
                                        </app-svg-icon>
                                    </th>
                                </tr>
                            </thead>

                            <tbody *ngIf="filterForm">
                                <tr *ngFor="let filterFormGroup of filterForm.controls; let i = index;"
                                    formGroupName="{{i}}">
                                    <td>
                                        {{filterFormGroup.get("measuringPoint").value.code}}
                                    </td>
                                    <td>
                                        <input type="checkbox"
                                               formControlName="countForwardDirection">
                                    </td>
                                    <td>
                                        <input type="checkbox"
                                               formControlName="countReverseDirection">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </ng-container>


    </form>

    <div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom"
         *ngIf="!isReadOnly">
        <button class="m-btn m-btn-link"
                (click)="reset()"
                type="button"
                id="reset_button">
            {{"form.reset" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </div>
</ng-container>