import { Component, inject, input, OnInit, output } from "@angular/core";
import { OrganizationApi } from "src/app/resource/organization.api";
import { AnalysisType, AnalysisTypeGroup } from "src/app/models/measuring-point";
import { NgClass } from "@angular/common";
import { SharedModule } from "src/app/modules/shared/shared.module";

export enum AnalysisTypeView {
    "HighlightAllowedAnalysisTypes",
    "InUseAnalysisTypes",
    "AllowedAnalysisTypes"
}

@Component({
    selector: "app-analysis-type-view",
    templateUrl: "./analysis-type-view.component.html",
    standalone: true,
    imports: [
        NgClass,
        SharedModule
    ]
})
export class AnalysisTypeViewComponent implements OnInit {
    // Inputs
    canDeselectExistingOptions = input<boolean>(true);
    activeFilterTypes = input<AnalysisType[]>([]);
    analysisTypeView = input.required<AnalysisTypeView>();
    organizationId = input.required<number>();
    multiSelection = input<boolean>(true);
    disabled = input<boolean>(false);

    // Outputs
    select = output<AnalysisType>();

    // Injects
    organizationsApi = inject(OrganizationApi);

    // Variables
    isLoading: boolean = false;
    protected allOtherDetectionTypes: AnalysisType[];
    protected allOtherTypes: AnalysisType[];
    protected allPositionDetectionTypes: AnalysisType[];
    protected allPulseDetectionTypes: AnalysisType[];
    protected allTrafficControlTypes: AnalysisType[];
    public selectedDialogTypes: AnalysisType[] = [];
    canNotDeselectTypes: AnalysisType[] = [];

    ngOnInit() {
        this.loadData()
    }

    private loadData() {
        switch (this.analysisTypeView()) {
            case AnalysisTypeView.HighlightAllowedAnalysisTypes:
                this.highlightAllowedAnalysisTypes();
                break;
            case AnalysisTypeView.InUseAnalysisTypes:
                this.loadInUseAnalysisTypes();
                break;
            case AnalysisTypeView.AllowedAnalysisTypes:
                this.loadAllowedAnalysisTypes();
                break;
        }

        if (!this.canDeselectExistingOptions()) {
            this.canNotDeselectTypes = [...this.activeFilterTypes()];
        }
    }

    isSelected(option: AnalysisType): boolean {
        return this.selectedDialogTypes?.contains(option);
    }

    isDisabled(option: AnalysisType): boolean {
        return this.disabled() || this.canNotDeselectTypes.contains(option);
    }

    toggleSelection(option: AnalysisType) {
        const index = this.selectedDialogTypes.findIndex(
            (opt) => opt === option
        );

        if (index !== -1) {
            this.selectedDialogTypes.splice(index, 1);
        } else {
            if (!this.multiSelection()) {
                this.selectedDialogTypes.length = 0;
            }

            this.selectedDialogTypes.push(option);
        }
    }

    private setAnalysisTypes(relevantTypes: AnalysisType[]) {
        this.allPositionDetectionTypes = relevantTypes.filter(x => AnalysisTypeGroup.POSITION_DETECTIONS.some(y => y === x));
        this.allPulseDetectionTypes = relevantTypes.filter(x => AnalysisTypeGroup.PULSE_DETECTIONS.some(y => y === x));
        this.allOtherDetectionTypes = relevantTypes.filter(x => AnalysisTypeGroup.OTHER_DETECTIONS.some(y => y === x));
        this.allTrafficControlTypes = relevantTypes.filter(x => AnalysisTypeGroup.TRAFFIC_CONTROL.some(y => y === x));
        this.allOtherTypes = relevantTypes.filter(x => AnalysisTypeGroup.OTHER.some(y => y === x));

        this.selectedDialogTypes = [...this.activeFilterTypes()]; // Shallow copy
    }

    private convertAnalysisTypes(types: string[]): AnalysisType[] {
        const values = types.map(type => <AnalysisType>type.toCamelCase());
        return values.filter(type => type !== undefined);
    }

    reset() {
        this.selectedDialogTypes = [...this.activeFilterTypes()];
        this.loadData();
    }

    private highlightAllowedAnalysisTypes() {
        this.isLoading = true;
        this.setAnalysisTypes(Object.values(AnalysisType));
        this.organizationsApi.getAllowedAnalysisTypes$(this.organizationId()).subscribe({
            next: types => {
                const convertedTyped = this.convertAnalysisTypes(types);
                this.selectedDialogTypes.push(...convertedTyped);
                this.isLoading = false;
            },
        });
    }

    private loadInUseAnalysisTypes() {
        this.isLoading = true;
        this.organizationsApi.getInUseAnalysisTypes$(this.organizationId()).subscribe({
            next: types => {
                const convertedTyped = this.convertAnalysisTypes(types);
                this.setAnalysisTypes(convertedTyped);
                this.isLoading = false
            }
        });
    }

    private loadAllowedAnalysisTypes() {
        this.isLoading = true;
        this.organizationsApi.getAllowedAnalysisTypes$(this.organizationId()).subscribe({
            next: types => {
                const convertedTyped = this.convertAnalysisTypes(types);
                this.setAnalysisTypes(convertedTyped);
                this.isLoading = false
            }
        });
    }
}
