import { ISearchResult, SearchParameters } from "src/app/models/search";
import { NavigatorWebApiService } from "./navigator-web-api";
import { IDeviceNavigator } from "src/app/models/device";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DeviceNavigatorWebApi extends NavigatorWebApiService<IDeviceNavigator> {
    getRoute(): string {
        return "DeviceNavigators";
    }

    search$(code: string, skip: number = null, take: number = null): Observable<ISearchResult<IDeviceNavigator>> {
        const url = this.getUrl();

        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {
            code: code,
            skip: skip,
            take: take
        };

        const options = this.createOptions(null, searchParameters, null);
        return this.http.get<ISearchResult<IDeviceNavigator>>(url, options);
    }

    getByQrCode$(qrCode: string): Observable<IDeviceNavigator> {
        const url = this.getUrl();
        const urlAppend = `/qrCode/${qrCode}`;

        return this.http.get<IDeviceNavigator>(url + urlAppend);
    }
}