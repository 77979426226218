import { ILoginNotification, LoginNotificationCreator, LoginNotificationUpdater } from "src/app/models/login-notification";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { LoginNotificationApi } from "src/app/resource/login-notification.api";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { SigncoFormGroup } from "src/app/models/form";
import { Component } from "@angular/core";

@Component({
    selector: "app-manage-login-notification-dialog",
    templateUrl: "./manage-login-notification.dialog.html"
})
export class ManageLoginNotificationDialogComponent extends DialogComponentBase {
    submitting: boolean;
    callback: (res: ILoginNotification) => void;
    manageLoginNotificationForm: SigncoFormGroup;
    existingLoginNotification: ILoginNotification;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly domainDataService: DomainDataService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly loginNotificationApi: LoginNotificationApi) {

        super();
    }

    create(callback?: (res: ILoginNotification) => void) {
        this.callback = callback;
        this.existingLoginNotification = null;

        this.openDialog();
    }

    edit(existingLoginNotification: ILoginNotification, callback?: (res: ILoginNotification) => void) {
        this.callback = callback;
        this.existingLoginNotification = existingLoginNotification;

        this.openDialog();
    }

    protected async onOpen() {
        this.manageLoginNotificationForm = this.formBuilder.group({
            message: await this.domainDataService.createTranslationFormGroup(this.existingLoginNotification ? this.existingLoginNotification.messageId : null)
        }) as SigncoFormGroup;

        if (this.existingLoginNotification) {
            this.manageLoginNotificationForm.patchValue(this.existingLoginNotification);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageLoginNotificationForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageLoginNotificationForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedLoginNotification: ILoginNotification) => {
            // Reload translations
            this.domainDataService.reload();

            if (this.existingLoginNotification) {
                Object.assign(this.existingLoginNotification, savedLoginNotification);
            }

            if (this.callback) {
                this.callback(savedLoginNotification);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingLoginNotification) {
            const loginNotificationCreator = Object.assign(new LoginNotificationCreator(), this.manageLoginNotificationForm.value);
            this.loginNotificationApi.create$(loginNotificationCreator).subscribe(onSuccess, onError);
        } else {
            const updatedLoginNotification = Object.assign(new LoginNotificationUpdater(this.existingLoginNotification), this.manageLoginNotificationForm.value);
            this.loginNotificationApi.update$(updatedLoginNotification).subscribe(onSuccess, onError);
        }
    }
}