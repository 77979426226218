<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingBikeParameterSet ? "bikeParameterSets.edit" : "bikeParameterSets.create") | translate}}
    </p-header>

    <form *ngIf="manageBikeParameterSetForm"
          class="p-16"
          [formGroup]="manageBikeParameterSetForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageBikeParameterSetForm.get('code').showErrors }">

            <label class="col-3"
                   for="code">
                {{"general.code" | translate}}
            </label>

            <div class="col-9">
                <input type="text"
                       class="p-inputtext"
                       id="code"
                       formControlName="code"
                       appAutofocus
                       #codeInput />

                <app-form-validation [control]="manageBikeParameterSetForm.get('code')"
                                     [input]="codeInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageBikeParameterSetForm.get('description').showErrors }">

            <label class="col-3"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-9">
                <textarea rows="3"
                          placeholder="{{'general.description' | translate}}"
                          id="description"
                          class="p-inputtextarea"
                          formControlName="description"
                          #descriptionInput></textarea>

                <app-form-validation [control]="manageBikeParameterSetForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             formGroupName="parameters">
            <div class="col-6">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minLengthChildrensBike').showErrors }">

                    <label class="col-6"
                           for="minLengthChildrensBike">
                        {{'bikeParameter.minLengthChildrensBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minLengthChildrensBike"
                                   type="number"
                                   formControlName="minLengthChildrensBike"
                                   id="minLengthChildrensBike"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #minLengthChildrensBikeInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minLengthChildrensBike')"
                                             [input]="minLengthChildrensBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minLengthYouthBike').showErrors }">

                    <label class="col-6"
                           for="minLengthYouthBike">
                        {{'bikeParameter.minLengthYouthBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minLengthYouthBike"
                                   type="number"
                                   formControlName="minLengthYouthBike"
                                   id="minLengthYouthBike"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #minLengthYouthBikeInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minLengthYouthBike')"
                                             [input]="minLengthYouthBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minLengthAdultBike').showErrors }">

                    <label class="col-6"
                           for="minLengthAdultBike">
                        {{'bikeParameter.minLengthAdultBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minLengthAdultBike"
                                   type="number"
                                   formControlName="minLengthAdultBike"
                                   id="minLengthAdultBike"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #minLengthAdultBikeInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minLengthAdultBike')"
                                             [input]="minLengthAdultBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minLengthMoped').showErrors }">

                    <label class="col-6"
                           for="minLengthMoped">
                        {{'bikeParameter.minLengthMoped' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minLengthMoped"
                                   type="number"
                                   formControlName="minLengthMoped"
                                   id="minLengthMoped"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #minLengthMopedInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minLengthMoped')"
                                             [input]="minLengthMopedInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minLengthExtendedBike').showErrors }">

                    <label class="col-6"
                           for="minLengthExtendedBike">
                        {{'bikeParameter.minLengthExtendedBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minLengthExtendedBike"
                                   type="number"
                                   formControlName="minLengthExtendedBike"
                                   id="minLengthExtendedBike"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #minLengthExtendedBikeInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minLengthExtendedBike')"
                                             [input]="minLengthExtendedBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxLength').showErrors }">

                    <label class="col-6"
                           for="maxLength">
                        {{'bikeParameter.maxLength' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxLength"
                                   type="number"
                                   formControlName="maxLength"
                                   id="maxLength"
                                   class="p-inputtext"
                                   (input)="updateMinMaxLengths()"
                                   #maxLengthInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxLength')"
                                             [input]="maxLengthInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('lengthMargin').showErrors }">

                    <label class="col-6"
                           for="lengthMargin">
                        {{'bikeParameter.lengthMargin' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="lengthMargin"
                                   type="number"
                                   formControlName="lengthMargin"
                                   id="lengthMargin"
                                   class="p-inputtext"
                                   #lengthMarginInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('lengthMargin')"
                                             [input]="lengthMarginInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('lengthMarginToReclassify').showErrors }">

                    <label class="col-6"
                           for="lengthMarginToReclassify">
                        {{'bikeParameter.lengthMarginToReclassify' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="lengthMarginToReclassify"
                                   type="number"
                                   formControlName="lengthMarginToReclassify"
                                   id="lengthMarginToReclassify"
                                   class="p-inputtext"
                                   #lengthMarginToReclassifyInput>
                            <div class="m-input-group-addon">{{"measurements.meter" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('lengthMarginToReclassify')"
                                             [input]="lengthMarginToReclassifyInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeedChildrensBike').showErrors }">

                    <label class="col-6"
                           for="maxSpeedChildrensBike">
                        {{'bikeParameter.maxSpeedChildrensBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeedChildrensBike"
                                   type="number"
                                   formControlName="maxSpeedChildrensBike"
                                   id="maxSpeedChildrensBike"
                                   class="p-inputtext"
                                   #maxSpeedChildrensBikeInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeedChildrensBike')"
                                             [input]="maxSpeedChildrensBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeedYouthBike').showErrors }">

                    <label class="col-6"
                           for="maxSpeedYouthBike">
                        {{'bikeParameter.maxSpeedYouthBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeedYouthBike"
                                   type="number"
                                   formControlName="maxSpeedYouthBike"
                                   id="maxSpeedYouthBike"
                                   class="p-inputtext"
                                   #maxSpeedYouthBikeInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeedYouthBike')"
                                             [input]="maxSpeedYouthBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeedAdultBike').showErrors }">

                    <label class="col-6"
                           for="maxSpeedAdultBike">
                        {{'bikeParameter.maxSpeedAdultBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeedAdultBike"
                                   type="number"
                                   formControlName="maxSpeedAdultBike"
                                   id="maxSpeedAdultBike"
                                   class="p-inputtext"
                                   #maxSpeedAdultBikeInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeedAdultBike')"
                                             [input]="maxSpeedAdultBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeedExtendedBike').showErrors }">

                    <label class="col-6"
                           for="maxSpeedExtendedBike">
                        {{'bikeParameter.maxSpeedExtendedBike' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeedExtendedBike"
                                   type="number"
                                   formControlName="maxSpeedExtendedBike"
                                   id="maxSpeedExtendedBike"
                                   class="p-inputtext"
                                   #maxSpeedExtendedBikeInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeedExtendedBike')"
                                             [input]="maxSpeedExtendedBikeInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minSpeed').showErrors }">

                    <label class="col-6"
                           for="minSpeed">
                        {{'bikeParameter.minSpeed' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minSpeed"
                                   type="number"
                                   formControlName="minSpeed"
                                   id="minSpeed"
                                   class="p-inputtext"
                                   #minSpeedInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minSpeed')"
                                             [input]="minSpeedInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeed').showErrors }">

                    <label class="col-6"
                           for="maxSpeed">
                        {{'bikeParameter.maxSpeed' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeed"
                                   type="number"
                                   formControlName="maxSpeed"
                                   id="maxSpeed"
                                   class="p-inputtext"
                                   #maxSpeedInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeed')"
                                             [input]="maxSpeedInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('speedMargin').showErrors }">

                    <label class="col-6"
                           for="speedMargin">
                        {{'bikeParameter.speedMargin' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="speedMargin"
                                   type="number"
                                   formControlName="speedMargin"
                                   id="speedMargin"
                                   class="p-inputtext"
                                   #speedMarginInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('speedMargin')"
                                             [input]="speedMarginInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('speedMarginToReclassify').showErrors }">

                    <label class="col-6"
                           for="speedMarginToReclassify">
                        {{'bikeParameter.speedMarginToReclassify' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="speedMarginToReclassify"
                                   type="number"
                                   formControlName="speedMarginToReclassify"
                                   id="speedMarginToReclassify"
                                   class="p-inputtext"
                                   #speedMarginToReclassifyInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('speedMarginToReclassify')"
                                             [input]="speedMarginToReclassifyInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('minSpeedWheely').showErrors }">

                    <label class="col-6"
                           for="minSpeedWheely">
                        {{'bikeParameter.minSpeedWheely' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="minSpeedWheely"
                                   type="number"
                                   formControlName="minSpeedWheely"
                                   id="minSpeedWheely"
                                   class="p-inputtext"
                                   #minSpeedWheelyInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('minSpeedWheely')"
                                             [input]="minSpeedWheelyInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6 mt-8">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageBikeParameterForm.get('maxSpeedWheely').showErrors }">

                    <label class="col-6"
                           for="maxSpeedWheely">
                        {{'bikeParameter.maxSpeedWheely' | translate}}
                    </label>

                    <div class="col-6">
                        <div class="m-input-group">
                            <input name="maxSpeedWheely"
                                   type="number"
                                   formControlName="maxSpeedWheely"
                                   id="maxSpeedWheely"
                                   class="p-inputtext"
                                   #maxSpeedWheelyInput>
                            <div class="m-input-group-addon">{{"measurements.kmh" | translate}}</div>
                        </div>

                        <app-form-validation [control]="manageBikeParameterForm.get('maxSpeedWheely')"
                                             [input]="maxSpeedWheelyInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.close" | translate}}
        </button>
    </p-footer>
</p-dialog>