<div class="m-layout-w-actions-bottom m-fixed-height m-border-top">
    <div *ngIf="!countingZoneConfigurationForm">
        <app-loading-spinner>
        </app-loading-spinner>
    </div>

    <div class="m-layout-area-body"
         style="overflow: hidden;display: flex;flex-flow: column;">
        <form *ngIf="countingZoneConfigurationForm"
              [formGroup]="countingZoneConfigurationForm"
              autocomplete="off"
              style="overflow: hidden;display: flex;flex-flow: column; margin:16px;">

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': countingZoneConfigurationForm.get('isSecured').showErrors }">

                <label class="col-4"
                       for="isSecured">
                    {{"countingZoneConfigurationTab.isSecured" | translate}}
                </label>

                <div class="col-8">

                    <p-inputSwitch id="isSecured"
                                   formControlName="isSecured"
                                   #isSecured>
                    </p-inputSwitch>

                    <app-form-validation [control]="countingZoneConfigurationForm.get('isSecured')"
                                         [input]="isSecured">
                    </app-form-validation>
                </div>
            </div>

        </form>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div *ngIf="countingZoneConfigurationForm"
             class="m-btn-group m-btn-group-right">

            <button [disabled]="submitting"
                    class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">

                {{"form.reset" | translate}}
            </button>

            <button [disabled]="submitting"
                    (click)="submit()"
                    class="m-btn m-btn-primary"
                    type="submit">

                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>