<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"attachmentTypes.create" | translate}}
        </button>
    </div>

    <app-attachment-types [editCommand]="true"
                          class="m-layout-area-body m-layout-default m-border-top"
                          [deleteCommand]="true"></app-attachment-types>
</div>

<app-manage-attachment-type-dialog></app-manage-attachment-type-dialog>