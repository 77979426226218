import { IDataChangedArguments } from "src/app/models/data-changed-arguments";
import { Observable, Subject } from "rxjs";
import { SubscriptionManager } from "src/app/utilities";
import { Injectable } from "@angular/core";
import { RealtimeConnection } from "./realtime-connection";
import { SignalRService } from "./signalR.service";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointRealtimeService {
    private connections = new Array<RealtimeConnection>();
    private readonly dataUpdateSubject = new Subject<IDataChangedArguments>();

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly signalRService: SignalRService) {
    }

    private subscribeTo(measuringPointId: number): RealtimeConnection {
        let connection = this.connections.find(x => x.contextId === measuringPointId);

        if (!connection) {
            connection = this.signalRService.CreateConnection(measuringPointId, "/v1/realtime/MeasuringPoint");
            this.connections.push(connection);

            connection.onRegisterEvents = c => {
                c.connection.on("dataChanged", (id: number, data: IDataChangedArguments) => {
                    this.dataUpdateSubject.next(this.fixSignalRJson(data));
                });
            };

            connection.onDetachEvents = c => {
                c.connection.off("dataChanged");
            };

            this.signalRService.startConnection(connection);
        }

        return connection;
    }

    private removeConnection(connection: RealtimeConnection) {
        this.signalRService.stopConnection(connection);

        this.connections = this.connections.remove(connection);
    }

    // SignalR uses MessagePack, and the serialization is somewhat different from JSON. We try to fix that.
    private fixSignalRJson(obj: any) {
        if (!obj) return obj;

        if (Array.isArray(obj)) {
            return obj.map((i) => {
                return this.fixSignalRJson(i);
            });
        } else {
            if (obj instanceof Date) {
                return obj;
            }

            if (typeof obj === "object") {
                const n = {};

                Object.keys(obj)
                    .forEach((key) => {
                        let value = obj[key];
                        if (Array.isArray(value)) {
                            if (value.length === 2 &&
                                value[0] instanceof Date &&
                                Number.isInteger(value[1])) {

                                // SignalR fucks up Date json - because it's a DateTimeOffset, probably
                                // We get an array with in [0] the date, and [1] as offset.
                                value = (value[0] as Date).addMinutes(value[1] * -1);
                            }
                        }

                        n[key.toCamelCase()] = this.fixSignalRJson(value);
                    });

                return n;
            }
        }

        return obj;
    }

    //#region Data

    private onDataUpdate(): Observable<IDataChangedArguments> {
        return this.dataUpdateSubject.asObservable();
    }

    subscribeToDataUpdate(key: string, measuringPointId: number, callback?: (res: IDataChangedArguments) => void) {
        const onSuccess = (data: IDataChangedArguments) => {
            if (data.measuringPointId === measuringPointId && callback) {
                callback(data);
            }
        };

        const onError = () => { };

        const subscription = this.onDataUpdate().subscribe(onSuccess, onError);

        this.subscribeTo(measuringPointId);

        this.subscriptionManager.add(`${key}//${measuringPointId}//dataUpdate`, subscription);
    }

    //#endregion Data

    unsubscribe(key: string, measuringPointId: number = null) {
        const startsWith = `${key}//${measuringPointId ? measuringPointId : ""}`;
        const keysToRemove = Object.keys(this.subscriptionManager.subscriptions).filter(x => x.startsWith(startsWith));

        for (const keyToRemove of keysToRemove) {
            this.subscriptionManager.remove(keyToRemove);
        }

        // Get all ids we are still tracking
        const ids = Object.keys(this.subscriptionManager.subscriptions).map(x => Number(x.split("//")[1]));

        // Clear out signalR connections that are no longer relevant
        for (const hubConnection of this.connections.clone()) {
            if (!ids.contains(hubConnection.contextId)) {
                this.removeConnection(hubConnection);
            }
        }
    }

    getConnections(key: string = null): string[] {
        let subscriptionKeys = Object.keys(this.subscriptionManager.subscriptions);

        if (key) {
            subscriptionKeys = subscriptionKeys.filter(x => x.startsWith(key));
        }

        return subscriptionKeys;
    }
}