import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { IDekimoCloudConfiguration } from "src/app/models/dekimo-gui-configuration";
import { IDevice, } from "src/app/models/device";
import { SigncoFormArray, SigncoFormGroup } from "src/app/models/form";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";

@Component({
    selector: "app-device-hardware-configuration-dialog",
    templateUrl: "./device-hardware-configuration.dialog.html"
})
export class DeviceHardwareConfigurationDialogComponent extends DialogComponentBase {
    device: IDevice;
    hardwareConfiguration: string;
    jsonError: boolean;
    protected cloudSyncTimesFormArray: SigncoFormArray;
    protected cloudSyncSettingsForm: SigncoFormGroup;
    protected metalyzerShellForm: SigncoFormGroup;
    protected hardwareConfigurationForm: SigncoFormGroup;
    protected dekimoCloudConfigurationForm: SigncoFormGroup;

    constructor(
        private readonly formBuilder: UntypedFormBuilder) {
        super();
    }

    open(device: IDevice) {
        this.device = device;

        this.hardwareConfiguration = this.device.hardwareConfiguration ?? "{}";

        this.createForm();

        this.openDialog();
    }

    private createForm() {
        this.cloudSyncTimesFormArray = this.formBuilder.array([]) as SigncoFormArray;

        this.cloudSyncSettingsForm = this.formBuilder.group({
            clockSyncTimes: this.cloudSyncTimesFormArray,
            modemAlwaysOn: true
        }) as SigncoFormGroup;

        this.metalyzerShellForm = this.formBuilder.group({
            verbosity: 2
        }) as SigncoFormGroup;

        this.hardwareConfigurationForm = this.formBuilder.group({
            cloudSyncSettings: this.cloudSyncSettingsForm,
            metalyzerShell: this.metalyzerShellForm,
            totemCase: false
        }) as SigncoFormGroup;

        this.dekimoCloudConfigurationForm = this.formBuilder.group({
            dekExtraboardConfig: this.hardwareConfigurationForm
        }) as SigncoFormGroup;

        try {
            const dekimoCloudConfigurationModel = JSON.parse(this.hardwareConfiguration) as IDekimoCloudConfiguration;

            if (dekimoCloudConfigurationModel?.dekExtraboardConfig?.cloudSyncSettings?.clockSyncTimes) {
                for (const _ of dekimoCloudConfigurationModel.dekExtraboardConfig.cloudSyncSettings.clockSyncTimes) {
                    this.addCloudSyncTime();
                }
            }

            this.dekimoCloudConfigurationForm.patchValue(dekimoCloudConfigurationModel);
        } catch {
            // Not parsable, can't use controls, fix using json
            this.dekimoCloudConfigurationForm.disable();
        }

        const valueChangeSubscription = this.dekimoCloudConfigurationForm.valueChanges.subscribe(() => {
            this.assignFormValueToHardwareConfiguration();
        });
        this.subscriptionManager.add("valueChange", valueChangeSubscription);

        if (this.hardwareConfiguration === "{}") {
            this.assignFormValueToHardwareConfiguration();
        }
    }

    private assignFormValueToHardwareConfiguration() {
        try {
            const totalConfigurationModel = JSON.parse(this.hardwareConfiguration) as IDekimoCloudConfiguration;

            //totalConfigurationModel.dekExtraboardConfig = { ...totalConfigurationModel.dekExtraboardConfig, ... this.hardwareConfigurationForm.value };
            totalConfigurationModel.dekExtraboardConfig = this.deepMergeObjects(totalConfigurationModel.dekExtraboardConfig, this.hardwareConfigurationForm.value);

            this.hardwareConfiguration = JSON.stringify(totalConfigurationModel, null, 2);
        } catch {
            // ignored, invalid json
        }
    }

    private deepMergeObjects<T>(obj1: object, obj2: object): T {
        const result = {};

        for (const key in obj1) {
            if (obj1.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
                if (obj1[key] && typeof obj1[key] === "object" && !Array.isArray(obj1[key])) {
                    result[key] = this.deepMergeObjects(obj1[key], {});
                } else {
                    result[key] = obj1[key];
                }
            }
        }

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj2[key] === null || obj2[key] == undefined) {
                    result[key] = obj2[key];
                    continue;
                }
                if (typeof obj2[key] === "object" && !Array.isArray(obj2[key]) && obj1.hasOwnProperty(key) && typeof obj1[key] === "object") {
                    result[key] = this.deepMergeObjects(obj1[key], obj2[key]);
                } else {
                    result[key] = obj2[key];
                }
            }
        }

        return result as T;
    }

    protected setHardwareConfigurationFromGuiJson(hardwareConfiguration: string) {
        try {
            JSON.parse(hardwareConfiguration) as IDekimoCloudConfiguration;
            // it worked!
            this.hardwareConfiguration = hardwareConfiguration;
            this.createForm();

        } catch {
            this.dekimoCloudConfigurationForm.disable();
        }
    }

    protected addCloudSyncTime() {
        const cloudSyncTimeControl = this.formBuilder.control("00:00:00");

        if (this.dekimoCloudConfigurationForm.disabled) {
            cloudSyncTimeControl.disable();
        }

        this.cloudSyncTimesFormArray.push(cloudSyncTimeControl);
    }

    protected deleteCloudSyncTime(index: number) {
        this.cloudSyncTimesFormArray.removeAt(index);
    }

    protected onClose() {
        this.hardwareConfiguration = null;
        this.jsonError = false;
    }

    async submit() {
        try {
            JSON.parse(this.hardwareConfiguration); // validate the the json is valid
            this.device.hardwareConfiguration = this.hardwareConfiguration;
            this.close();
        } catch {
            this.jsonError = true;
        }
    }
}