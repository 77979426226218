<div class="m-page-content-wrapper p-16">
    <div *ngIf="!assignment">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="row m-fixed-height"
         [hidden]="!assignment">
        <div class="col-3 m-fixed-height"
             *ngIf="assignment">

            <div [ngClass]="subAssignmentsAvailable ? ['m-half-height', 'm-layout-default'] : ['m-fixed-height', 'm-layout-default', 'm-layout-w-actions-bottom']">
                <div [ngClass]="['m-card', 'm-layout-area-body', 'm-layout-default', (subAssignmentsAvailable ? 'mb-8' : '')]"
                     style="position: relative;">
                    <a (click)="toMap()"
                       class="m-btn m-btn-back m-btn-back-map"
                       title="Back">
                        <app-svg-icon icon="chevron-left"
                                      class="mr-4"></app-svg-icon>
                        {{"general.returnToMap" | translate}}
                    </a>
                    <app-location-detail *ngIf="assignment.location?.coordinate || assignment.parentAssignment?.location?.coordinate"
                                         [location]="assignment?.location ?? assignment?.parentAssignment?.location"
                                         class="m-layout-area-body m-layout-w-actions-top"
                                         [editCommand]="canEditAssignment && !!(assignment.location?.id)"
                                         [assignment]="assignment">
                    </app-location-detail>
                </div>
            </div>


            <div class="m-half-height m-layout-default"
                 *ngIf="subAssignmentsAvailable">
                <div class="m-card m-layout-area-body mt-8 m-layout-w-actions-top">
                    <div class="p-16 m-layout-area-top m-border-bottom">
                        <h3>{{"subAssignment.title" | translate}}</h3>
                    </div>

                    <div class="m-layout-area-body m-layout-w-actions-bottom">
                        <app-sub-assignments [assignmentId]="assignment?.id"
                                             [parentAssignmentId]="parentAssignmentId"
                                             [requiresInput]="true"
                                             [headers]="false"
                                             [footer]="false"
                                             [paginator]="false"
                                             [rowCount]="9999"
                                             [mini]="true"
                                             [deleteCommand]="true"
                                             [editCommand]="true"
                                             componentStyle="m-card-body-table-content"
                                             class="m-layout-area-body m-layout-default"
                                             (lastRowDeleted)="handleLastSubAssignmentDeleted()">
                        </app-sub-assignments>

                        <div class="m-layout-area-bottom">
                            <div class="m-btn-group m-btn-group-right m-border-top"
                                 *ngIf="canCreateAssignment && subAssignmentsAvailable && !isAssignmentFinished">
                                <button class="m-btn m-btn-secondary"
                                        (click)="createNewSubAssignment()"
                                        [disabled]="!assignment?.id">
                                    <app-svg-icon icon="add">
                                    </app-svg-icon>
                                    {{"subAssignment.create" | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9 m-fixed-height m-layout-default">
            <app-assignment-detail-container [assignment]="assignment"
                                             (save)="handleOnAssignmentSaved($event)"
                                             (lastParkingBanRemoved)="handleLastTaskOrParkingBanRemoved()"
                                             (lastTaskRemoved)="handleLastTaskOrParkingBanRemoved()">
            </app-assignment-detail-container>
        </div>
    </div>
</div>

<app-location-dialog [isCodeRequired]="false"></app-location-dialog>