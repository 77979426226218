import { Component } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, UntypedFormBuilder, Validators } from "@angular/forms";
import { ViewModelEnum } from "src/app/models/domain-data";
import { SigncoFormArray, SigncoFormControl, SigncoFormGroup } from "src/app/models/form";
import { AnalyzerConfiguration, CollectRConfiguration } from "src/app/models/upload";
import { VehicleCategory } from "src/app/models/vehicle";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { FormUtils } from "src/app/utilities";
import { MultiNoDuplicateValidator } from "src/app/validators/multi-no-duplicate.validator";
import { DeviceLinkFormGroupComponent } from "../device-link-form-group.component";

@Component({
    selector: "app-device-link-collect-r-configuration",
    templateUrl: "./device-link-collect-r-configuration.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective },
    ],
})
export class DeviceLinkCollectRConfigurationComponent extends DeviceLinkFormGroupComponent {
    mappingFormArray: SigncoFormArray;
    vehicleSubTypes: ViewModelEnum[];

    classIdControls: FormControl[] = [];
    vehicleSubTypeControls: FormControl[] = [];

    get showMapping(): boolean {
        return this.form.get("mappingEnabled").value;
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly domainDataService: DomainDataService,
        private readonly fgd: FormGroupDirective
    ) {
        super();
    }

    configureForm(analyzerConfiguration: AnalyzerConfiguration): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (analyzerConfiguration?.collectRConfiguration) {
                this.domainDataService.get(DomainData.VehicleSubType).then(vehicleSubTypes => {
                    this.vehicleSubTypes = vehicleSubTypes;

                    if (this.fgd.form.get("collectRConfiguration")) this.fgd.form.removeControl("collectRConfiguration");

                    const vehicleClasses = analyzerConfiguration.collectRConfiguration.mapping ? Object.keys(analyzerConfiguration.collectRConfiguration.mapping) : null;

                    let listOfSubTypeControls = [] as SigncoFormControl[];
                    let listOfClassIdControls = [] as SigncoFormControl[];

                    this.mappingFormArray = this.formBuilder.array([]) as SigncoFormArray;
                    if (analyzerConfiguration.collectRConfiguration.mappingEnabled) {
                        for (const key of vehicleClasses) {
                            const controlSubType = this.formBuilder.control(this.analyzerConfiguration.collectRConfiguration.mapping[key]?.toCamelCase(), [Validators.required]);
                            listOfSubTypeControls = [...listOfSubTypeControls, controlSubType as SigncoFormControl];

                            const controlClassId = this.formBuilder.control(parseInt(key), [Validators.required]);
                            listOfClassIdControls = [...listOfClassIdControls, controlClassId as SigncoFormControl];

                            const nestedFormGroup = this.formBuilder.group({
                                classId: controlClassId,
                                vehicleSubType: controlSubType
                            }) as SigncoFormGroup;

                            this.mappingFormArray.push(nestedFormGroup);
                        }

                        this.classIdControls = listOfClassIdControls;
                        this.vehicleSubTypeControls = listOfSubTypeControls;

                        this.configureValidators();
                    }

                    // value of the form is not in the format which BE expects
                    // so in ManageLinkComponent we're converting value to value which BE expects before sending the creator/updater object
                    this.form = this.formBuilder.group({
                        mappingEnabled: analyzerConfiguration.collectRConfiguration?.mappingEnabled,
                        mapping: this.mappingFormArray
                    }) as SigncoFormGroup;

                    this.fgd.form.addControl("collectRConfiguration", this.form);
                    resolve(true);
                });
                return;
            }

            if (this.fgd.form.get("collectRConfiguration")) this.fgd.form.removeControl("collectRConfiguration");
            this.form = null;
            return resolve(false);
        });
    }


    private configureValidators() {
        const multiNoDuplicateForSubTypeValidator = MultiNoDuplicateValidator.create(this.vehicleSubTypeControls);
        for (const control of this.vehicleSubTypeControls) {
            control.clearValidators();
            FormUtils.addValidator(control, multiNoDuplicateForSubTypeValidator);
            FormUtils.addValidator(control, Validators.required);
            control.updateValueAndValidity();
        }

        const multiNoDuplicateForClassIdValidator = MultiNoDuplicateValidator.create(this.classIdControls);
        for (const control of this.classIdControls) {
            control.clearValidators();
            FormUtils.addValidator(control, multiNoDuplicateForClassIdValidator);
            FormUtils.addValidator(control, Validators.required);
            control.updateValueAndValidity();
        }
    }

    addMapping() {
        const key = this.mappingFormArray.length + 1;

        const controlSubType = this.formBuilder.control(this.vehicleSubTypes.takeFirstOrDefault()?.value, [Validators.required]);
        this.vehicleSubTypeControls = [...this.vehicleSubTypeControls, controlSubType as SigncoFormControl];

        const controlClassId = this.formBuilder.control(key, [Validators.required]);
        this.classIdControls = [...this.classIdControls, controlClassId as SigncoFormControl];

        const nestedFormGroup = this.formBuilder.group({
            classId: controlClassId,
            vehicleSubType: controlSubType
        }) as SigncoFormGroup;

        this.mappingFormArray.push(nestedFormGroup);

        this.configureValidators();
    }

    removeMapping(index: number) {
        this.mappingFormArray.removeAt(index);
    }
}
