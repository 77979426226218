import { CdkDragDrop, CDK_DRAG_CONFIG, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, OnDestroy } from "@angular/core";
import { SelectItem } from "primeng/api";
import { forkJoin, map, Observable, scheduled } from "rxjs";
import { ITask, TaskStatus, WorkerTaskCreator } from "src/app/models/task";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { TaskApi } from "src/app/resource/task.api";
import { CalendarSettings, PrimeComponentService } from "src/app/services/prime-component.service";
import { TaskService } from "src/app/services/task.service";
import { JsonUtils } from "src/app/utilities";
import { WorkerApi } from "src/app/resource/worker.api";
import { IWorker } from "src/app/models/worker";
import { SearchParameters, SortDescriptor, SortDirection } from "src/app/models/search";

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 10000
};

@Component({
    selector: "app-create-recurring-tasks-dialog",
    templateUrl: "./create-recurring-tasks-dialog.component.html",
    styleUrls: [],
    providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class CreateRecurringTasksDialogComponent extends DialogComponentBase implements OnDestroy {
    private callback: () => void;

    calendarSettings: CalendarSettings;

    scheduledTasks: ITask[] = new Array<ITask>();
    get isScheduledTasksListEmpty(): boolean {
        return !this.scheduledTasks || this.scheduledTasks.length === 0;
    }

    get isValid(): boolean {
        if (!this.scheduledTasks || this.scheduledTasks.length === 0) {
            return true;
        }

        return !!this.availableFromDate;
    }

    onHoldTasks: ITask[] = new Array<ITask>();
    get isOnHoldTasksListEmpty(): boolean {
        return !this.onHoldTasks || this.onHoldTasks.length === 0;
    }

    assignmentId: number;
    availableFromDate: Date;
    workerIds: number[];
    workers: SelectItem[] = [];
    currentDate: Date;

    submitting = false;

    constructor(
        readonly primeComponentService: PrimeComponentService,
        private readonly taskService: TaskService,
        private readonly taskApi: TaskApi,
        private readonly workerApi: WorkerApi
    ) {
        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.availableFromDate = new Date();
        this.currentDate = new Date();
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    protected override onClose(): void {
        this.onHoldTasks = [];
        this.scheduledTasks = [];
        this.availableFromDate = new Date();
        this.workerIds = null;
    }

    override onShow(): void {
        super.onShow();

        // this hack is implemented to prevent automatic resizing of dialog when drag-and-drop is happening for first time (if dialog is not resized manually before)
        // without this, when element is dragged from first list into second, when element enters second list the whole dialog is being resized
        // when height is configured, that is not happening
        const contentElement = this.dialog.container.getElementsByClassName("p-dialog-content");
        if (contentElement && contentElement.length > 0) {
            (contentElement[0] as HTMLElement).style.height = contentElement[0].clientHeight + "px";
        }
    }

    open(assignmentId: number, tasksToBeCopied: ITask[], callback?: () => void) {
        if (!tasksToBeCopied || tasksToBeCopied.length === 0) return;

        this.scheduledTasks = JsonUtils.deepClone(tasksToBeCopied);
        this.assignmentId = assignmentId;
        this.initializeDropdown();

        this.callback = callback;
        this.openDialog();
    }

    drop(event: CdkDragDrop<ITask[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    trackByFn(index: number, item: ITask) {
        return item.id;
    }

    submit() {
        if (!this.isValid) return;

        this.submitting = true;

        const observables$ = new Array<Observable<ITask>>();

        if (this.scheduledTasks && this.scheduledTasks.length > 0) {
            for (const scheduledTask of this.scheduledTasks) {
                const creator = this.taskService.getCreatorFromServiceModel(scheduledTask, `${scheduledTask.name}_recurring`);

                creator.copiedTaskId = scheduledTask.id;
                creator.isRecurring = true;
                creator.start = undefined;
                creator.end = undefined;
                creator.currentStatus.taskStatusId = TaskStatus.Scheduled;
                creator.currentStatus.availableFrom = this.availableFromDate;

                if (this.workerIds && this.workerIds.length > 0) {
                    creator.currentStatus.workerTasks = this.workerIds.map((workerId: number) => {
                        return {
                            workerId: workerId
                        } as WorkerTaskCreator;
                    });
                } else {
                    creator.currentStatus.workerTasks = null;
                }

                observables$.push(this.taskApi.create$(creator));
            }
        }

        if (this.onHoldTasks && this.onHoldTasks.length > 0) {
            for (const onHoldTask of this.onHoldTasks) {
                const creator = this.taskService.getCreatorFromServiceModel(onHoldTask, `${onHoldTask.name}_onHold`);

                creator.copiedTaskId = onHoldTask.id;
                creator.isRecurring = true;
                creator.start = undefined;
                creator.end = undefined;
                creator.currentStatus.taskStatusId = TaskStatus.OnHold;
                creator.currentStatus.availableFrom = undefined;
                creator.currentStatus.workerTasks = null;

                observables$.push(this.taskApi.create$(creator));
            }
        }

        forkJoin(observables$).subscribe({
            next: (results: ITask[]) => {
                this.submitting = false;
                if (this.callback) this.callback();
            },
            error: (error) => {
                this.submitting = false;
                if (this.callback) this.callback();
            },
            complete: () => {
                this.close();
            }
        });
    }

    initializeDropdown(): void {
        const searchParameters = new SearchParameters();
        searchParameters.sort = [];
        searchParameters.sort.push(new SortDescriptor(SortDirection.ascending, "FirstName"));

        this.workerApi.getAll$(searchParameters).pipe(map((workers: IWorker[], index: number) => {
            return workers.map((worker) => {
                return {
                    label: `${worker.firstName} ${worker.lastName}`,
                    value: worker.id
                } as SelectItem;
            });
        })).subscribe({
            next: (workers) => {
                this.workers = workers;
            },
            error: (error) => {
                this.workers = [];
            }
        });
    }
}
