import { ProcessStatus } from "./upload";

export enum DeviceCommandType {
    // IGT-20
    ResetCounters = "resetCounters",
    EnableModem = "enableModem",

    // Dekimo
    StartRawSampleCapture = "startRawSampleCapture",
    ClearSlavesErrorLog = "clearslaveserrorlog",
    ForceReboot = "forceReboot",
    ForceModemRestart = "forceModemRestart",
    ClearAllBaseboardPulses = "clearAllBaseboardPulses",
    GoToDeepSleep = "goToDeepSleep",
    GoToDeepSleepUponNextSleep = "goToDeepSleepUponNextSleep",
    ClearGPSData = "clearGPSData"
}

export interface IDeviceCommand {
    id: number;
    timestamp: Date;
    typeId: DeviceCommandType;
    command: DeviceCommandBody;
    processMessage: string;
    statusId: ProcessStatus;
}

class DeviceCommandBody {
}

export class ResetCountersCommandBody implements DeviceCommandBody {
    dayCounter?: number;
    monthCounter?: number;
    yearCounter?: number;
}

export class DeviceCommandCreator {
    command: DeviceCommandBody;
}

export class DeviceCommandStatus {
    statusId: string;
    processsMessage: string;
}