<ng-container *ngIf="!parkingBan">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top">
    <button class="m-btn m-btn-secondary"
            (click)="create()"
            *ngIf="parkingBan && canCreateParkingBan">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"manageParkingBanPhotos.add" | translate}}
    </button>
</div>

<app-parking-ban-images *ngIf="parkingBan"
                        class="m-layout-area-body m-layout-default m-border-top"
                        [parkingBanId]="parkingBan?.id"
                        [editCommand]="true"
                        [deleteCommand]="true"></app-parking-ban-images>
<app-manage-parking-ban-image-dialog></app-manage-parking-ban-image-dialog>