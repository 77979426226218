import { Component, ChangeDetectorRef, Type } from "@angular/core";
import { ReportHistoryComponent } from "../report-history/report-history.component";
import { AuditTrailsComponent } from "src/app/modules/shared/components/audit-trails/audit-trails.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { UploadComponent } from "./../upload/upload.component";

type HistoryPageComponents =
    UploadComponent |
    ReportHistoryComponent |
    AuditTrailsComponent;

@Component({
    selector: "app-history-page",
    templateUrl: "./history-page.component.html"
})
export class HistoryPageComponent extends TabBase<HistoryPageComponents> {
    constructor(
        cd: ChangeDetectorRef,
        tabService: TabService) {

        super(tabService, cd);

        this.addTab({ component: UploadComponent, url: "uploads", translatePath: "uploads.title", icon: "uploads" });
        this.addTab({ component: ReportHistoryComponent, url: "reports", translatePath: "reports.title", icon: "reports" });
        this.addTab({ component: AuditTrailsComponent, url: "audit", translatePath: "auditTrails.title", icon: "log" });

        this.filterTabs();
    }

    filterTabs() {
        super.filterTabs();

        const tabsToFilter = new Array<Type<HistoryPageComponents>>();
        this.hideTabs(tabsToFilter);
    }

    protected onSelectedTabChange() {
        if (this.activeComponent instanceof AuditTrailsComponent) {
            this.activeComponent.setRowCount(50);
            this.activeComponent.allowWithoutInput = true;
            this.activeComponent.loadTableRows();
        }
    }
}