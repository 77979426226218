import { NgModule } from "@angular/core";
import { MapAdvancedModule } from "../map-advanced/map-advanced.module";
import { AssignmentsRoutingModule } from "./assignments-routing.module";
import { AssignmentsComponent } from "./components/assignments/assignments.component";
import { AssignmentLocationComponent } from "./components/assignment-location/assignment-location.component";
import { SharedModule } from "../shared/shared.module";
import { AssignmentDetailComponent } from "./components/assignment-detail/assignment-detail.component";
import { AssignmentDetailContainerComponent } from "./components/assignment-detail-container/assignment-detail-container.component";
import { TaskDetailComponent } from "./components/task-detail/task-detail.component";
import { TaskDetailContainerComponent } from "./components/task-detail-container/task-detail-container.component";
import { ParkingBanDetailContainerComponent } from "./components/parking-ban-detail-container/parking-ban-detail-container.component";
import { ParkingBanDetailComponent } from "./components/parking-ban-detail/parking-ban-detail.component";
import { SignScansContainerComponent } from "./components/sign-scans-container/sign-scans-container.component";
import { SignScansComponent } from "./components/sign-scans/sign-scans.component";
import { ManageSignScanDialogComponent } from "./components/manage-sign-scan-dialog/manage-sign-scan-dialog.component";
import { ParkingBanExceptionsComponent } from "./components/parking-ban-exceptions/parking-ban-exceptions.component";
import { ParkingBanExceptionsContainerComponent } from "./components/parking-ban-exceptions-container/parking-ban-exceptions-container.component";
import { ManageParkingBanExceptionDialogComponent } from "./components/manage-parking-ban-exception-dialog/manage-parking-ban-exception-dialog.component";
import { InProgressNoteComponent } from "./components/in-progress-note/in-progress-note.component";
import { TaskAttachmentsComponent } from "./components/task-attachments/task-attachments.component";
import { AssignmentAttachmentsComponent } from "./components/assignment-attachments/assignment-attachments.component";
import { MomentDateAndTimePipe } from "../shared/pipes/datetime.pipe";
import { ParkingBanImagesContainerComponent } from "./components/parking-ban-images-container/parking-ban-images-container.component";
import { ParkingBanImagesComponent } from "./components/parking-ban-images/parking-ban-images.component";
import { ManageParkingBanImageDialogComponent } from "./components/manage-parking-ban-image-dialog/manage-parking-ban-image-dialog.component";
import { SubAssignmentsComponent } from "./components/sub-assignments/sub-assignments.component";
import { TasksHistoryComponent } from "./components/task-history/tasks-history.component";
import { TaskStatusDifferencesComponent } from "./components/task-status-differences/task-status-differences.component";
import { AssignmentsHistoryComponent } from "./components/assignments-history/assignments-history.component";
import { TasksContainerComponent } from "./components/tasks-container/tasks-container.component";
import { ParkingBansContainerComponent } from "./components/parking-bans-container/parking-bans-container.component";
import { TaskLocationComponent } from "./components/task-location/task-location.component";
import { ParkingBanLocationComponent } from "./components/parking-ban-location/parking-ban-location.component";
import { CreateRecurringTasksDialogComponent } from "./components/create-recurring-tasks-dialog/create-recurring-tasks-dialog.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { RecurringTaskPreviewComponent } from "./components/recurring-task-preview/recurring-task-preview.component";
import { ScheduleSchedulableTasksDialogComponent } from "./components/schedule-schedulable-tasks-dialog/schedule-schedulable-tasks-dialog.component";
import { AttachmentsModule } from "../attachments/attachments.module";
import { ParkingBanDataService } from "./services/parking-ban-data.service";
import { LocationSharedModule } from "../location-shared";

@NgModule({
    imports: [
        SharedModule,
        MapAdvancedModule,
        AssignmentsRoutingModule,
        AttachmentsModule,
        DragDropModule,
        LocationSharedModule,
    ],
    declarations: [
        AssignmentsComponent,
        AssignmentLocationComponent,
        AssignmentDetailComponent,
        AssignmentDetailContainerComponent,
        TaskDetailComponent,
        TaskDetailContainerComponent,
        ParkingBanLocationComponent,
        ParkingBanDetailContainerComponent,
        ParkingBanDetailComponent,
        SignScansContainerComponent,
        SignScansComponent,
        ManageSignScanDialogComponent,
        ParkingBanExceptionsComponent,
        ParkingBanExceptionsContainerComponent,
        ManageParkingBanExceptionDialogComponent,
        InProgressNoteComponent,
        TaskLocationComponent,
        TaskAttachmentsComponent,
        AssignmentAttachmentsComponent,
        ParkingBanImagesContainerComponent,
        ParkingBanImagesComponent,
        ManageParkingBanImageDialogComponent,
        SubAssignmentsComponent,
        TasksHistoryComponent,
        TaskStatusDifferencesComponent,
        AssignmentsHistoryComponent,
        TasksContainerComponent,
        ParkingBansContainerComponent,
        CreateRecurringTasksDialogComponent,
        RecurringTaskPreviewComponent,
        ScheduleSchedulableTasksDialogComponent,
    ],
    exports: [
        AssignmentsComponent,
        AssignmentLocationComponent,
        InProgressNoteComponent
    ],
    providers: [MomentDateAndTimePipe,
        ParkingBanDataService]
})
export class AssignmentsModule { }
