import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { ILocation } from "src/app/models/location";
import { MeasuringPointDetailContainerComponent } from "../measuring-point-detail-container/measuring-point-detail-container.component";
import { MeasuringPointsComponent } from "src/app/modules/shared/components/measuring-points/measuring-points.component";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { SubscriptionManager } from "src/app/utilities";
import { NavigationService } from "src/app/services/navigation.service";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { MapDetailService } from "src/app/services/map-detail.service";
import { ActivatedRoute } from "@angular/router";
import { LocationApi } from "src/app/resource/location.api";
import { ProjectApi } from "src/app/resource/project.api";
import { IProject } from "src/app/models/project";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { Rights } from "src/app/models/rights";
import { IMeasuringPoint } from "src/app/models/measuring-point";
import { IMeasuringPointSummary } from "src/app/models/web";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-measuring-point-location",
    templateUrl: "./measuring-point-location.component.html"
})
export class MeasuringPointLocationComponent implements OnInit, OnDestroy {
    @ViewChild(MeasuringPointsComponent, { static: false }) measuringPointsComponent: MeasuringPointsComponent;
    @ViewChild(MeasuringPointDetailContainerComponent, { static: true }) measuringPointDetailContainer: MeasuringPointDetailContainerComponent;

    loading: boolean;
    location: ILocation;
    measuringPoint: IMeasuringPoint;
    projects: IProject[];
    canCreateMeasuringPoint: boolean;

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        elementRef: ElementRef<HTMLElement>,
        private readonly globalEventsService: GlobalEventsService,
        private readonly route: ActivatedRoute,
        private readonly mapDetailService: MapDetailService,
        private readonly navigationService: NavigationService,
        private readonly locationApi: LocationApi,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly projectApi: ProjectApi) {

        elementRef.nativeElement.classList.add("container-detail");
    }

    ngOnInit() {
        const routeQueryParamSubscription = this.route.params.subscribe(async params => {
            // Attempt navigation to what the params specified
            await this.setLocationFromParams(params);
        });
        this.subscriptionManager.add("routeQueryParams", routeQueryParamSubscription);
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canCreateMeasuringPoint = rights?.hasBackendRight(BackendRights.EditMeasuringPoint);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    private async setLocationFromParams(params: { [key: string]: any }) {
        if (!params) return;

        const locationIdString = decodeURI(params["locationId"]);
        if (!locationIdString) return;

        this.loading = true;

        const fetchLocation = async () => {
            const locationId = Number.parseInt(locationIdString, 10);
            this.location = await this.locationApi.get$(locationId).toPromise();
        };

        if (locationIdString === "new") {
            this.location = this.navigationService.newLocation;
            this.navigationService.newLocation = null;

            if (!this.location) {
                this.toMap();
                return;
            }
        }

        const measuringPointIdString = decodeURI(params["measuringPointId"]);
        let measuringPoint: IMeasuringPoint;

        if (measuringPointIdString === "new") {
            if (!this.location) {
                await fetchLocation();
            }

            measuringPoint = this.getNewMeasuringPoint();
            measuringPoint.location = this.location;

        } else {
            const measuringPointId = Number.parseInt(measuringPointIdString, 10);

            if (Number.isNaN(measuringPointId)) {
                // Fetch location, select first on dataSet
                await fetchLocation();
                this.selectFirstMeasuringPoint();
                return;
            }

            const serviceRequestOptions = new ServiceRequestOptions();
            serviceRequestOptions.includes.add("measuringPoint", "location");
            serviceRequestOptions.includes.add("measuringPoint", "projects");

            try {
                measuringPoint = await this.measuringPointApi.get$(measuringPointId, null, serviceRequestOptions).toPromise();

                this.location = measuringPoint ? measuringPoint.location : null;
                if (measuringPoint) {
                    measuringPoint.readonly = !!measuringPoint.sharedKey;
                }
            } catch (ex) {
                this.toMap();
                return;
            }
        }

        // all mps are going to belong to the same location which is linked to the same organization
        // so load only projects belonging to that location only once
        if (!this.projects && this.location) {
            const ownerId = this.location.ownerId;
            const projectSearchParameters = new SearchParameters();
            projectSearchParameters.filter = [];
            projectSearchParameters.filter.push(new FilterDescriptor("isMeasurementProject", true, FilterOperator.equals));
            projectSearchParameters.filter.push(new FilterDescriptor("organizationId", ownerId, FilterOperator.equals));
            this.projectApi.getAll$(projectSearchParameters).subscribe((projects) => {
                this.projects = projects;
            });
        }

        this.loading = false;
        this.setMeasuringPoint(measuringPoint);
    }

    private setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        if (this.measuringPoint && this.measuringPoint.id === measuringPoint.id) return;

        const isInitialSelect = !this.measuringPoint;
        this.measuringPoint = measuringPoint;

        this.selectMeasuringPointInComponent(isInitialSelect);
    }

    selectMeasuringPointInComponent(scroll = false) {
        if (!this.measuringPointsComponent || !this.measuringPoint) return;

        const measuringPointSummary = this.measuringPointsComponent.data.find(x => x.id === this.measuringPoint.id);
        this.measuringPointsComponent.selectRow(measuringPointSummary, false);

        if (scroll) {
            this.measuringPointsComponent.scrollTo(measuringPointSummary);
        }
    }

    createNewMeasuringPoint() {
        this.measuringPointsComponent.clearSelection();
        this.navigationService.createNewMeasuringPoint(this.location.id);
    }

    onDataSet() {
        if (this.isCreatingNew) return;

        const measuringPointSummary = this.measuringPointsComponent.data.find(x => x.id === this.measuringPoint.id);
        if (measuringPointSummary) {
            this.selectMeasuringPointInComponent(true);
        } else {
            this.measuringPointsComponent.clearSelection();
            this.measuringPoint = null;
        }

        if (!this.measuringPoint) {
            this.selectFirstMeasuringPoint();
        }
    }

    handleLocationEdit(location: ILocation) {
        // TODO (maybe) - renew measuring point detail
    }

    private selectFirstMeasuringPoint() {
        let measuringPoint: IMeasuringPointSummary;

        if (this.measuringPointsComponent) {
            measuringPoint = this.measuringPointsComponent.data.takeFirstOrDefault();
        }

        if (measuringPoint) {
            this.navigationService.toMeasuringPoint(measuringPoint);
        } else {
            this.toMap();
        }
    }

    private getNewMeasuringPoint(): IMeasuringPoint {
        const newMeasuringPoint = {
            heading: 0
        } as IMeasuringPoint;
        newMeasuringPoint.location = this.location;

        if (this.navigationService.clonedMeasuringPoint) {
            Object.assign(newMeasuringPoint, this.navigationService.clonedMeasuringPoint);
            this.navigationService.clonedMeasuringPoint = null;
        }

        return newMeasuringPoint;
    }

    async handleOnMeasuringPointSelect(measuringPointSummary: IMeasuringPointSummary) {
        if (!measuringPointSummary) {
            this.toMap();
            return;
        }

        if (this.measuringPoint && measuringPointSummary.id === this.measuringPoint.id) return;
        if (!this.measuringPointDetailContainer || !this.measuringPointDetailContainer.selectedTab) return;

        const urlIndexStart = window.location.href.indexOf(`/${this.measuringPointDetailContainer.selectedTab.url}`);
        const urlAppend = window.location.href.substring(urlIndexStart + 1);

        const didNavigate = await this.navigationService.toMeasuringPoint(measuringPointSummary, urlAppend);
        if (!didNavigate) {
            this.measuringPointsComponent.selectRow(measuringPointSummary);
        }
    }

    // When a new measuring point gets created
    // We want to set it selected in our measuringPoints component
    handleOnMeasuringPointSaved(savedMeasuringPoint: IMeasuringPoint) {
        this.measuringPointsComponent.reload();

        if (this.measuringPoint && this.measuringPoint.id === savedMeasuringPoint.id) {
            this.measuringPoint = savedMeasuringPoint;
            this.measuringPointDetailContainer.filterTabs();
            this.measuringPointDetailContainer.updateTitle();
            return;
        }

        this.navigationService.toMeasuringPointLocation(savedMeasuringPoint.id, savedMeasuringPoint.location.id);
    }

    toMap() {
        this.mapDetailService.navigateToMapDetail(this.mapDetailService.currentMapDetail);
    }

    private get isCreatingNew(): boolean {
        return this.measuringPoint && !this.measuringPoint.id; // check if id is falsy
    }
}