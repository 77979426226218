<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [closeOnEscape]="false"
          [closable]="false"
          styleClass="m-vms-image-editor-dialog"
          [style]="{ height: '85vh', width: '70vw', 'max-width':'1500px' }">
    <p-header>
        <div class="p-dialog-header-icons">
            <a (click)="editor.cancel()"
               class="ml-auto">
                <app-svg-icon icon="cancel"
                              tooltip="{{'form.cancel' | translate}}">
                </app-svg-icon>
            </a>
        </div>
    </p-header>

    <app-vms-image-editor [inPopup]="true"></app-vms-image-editor>

</p-dialog>