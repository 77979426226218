import { ResizeService } from "src/app/services/resize.service";
import { Input, ViewChild, OnDestroy, Directive, inject } from "@angular/core";
import { Dialog } from "primeng/dialog";
import { SubscriptionManager } from "src/app/utilities";

@Directive()
export abstract class DialogComponentBase implements OnDestroy {
    @ViewChild(Dialog, { static: true }) dialog: Dialog;

    @Input() blockBackground = true;
    @Input() draggable = true;
    @Input() dismissOnClickOutside = false;
    @Input() minX = -600;
    @Input() minY = -400;
    @Input() contentStyle: any = {};
    @Input() appendTo: "body" | any = "body";

    protected subscriptionManager = new SubscriptionManager();
    resizeService: ResizeService = inject(ResizeService);

    body: HTMLDivElement;
    visible: boolean;

    constructor() {
        const resizeSub = this.resizeService.onResize.subscribe(_ => {
            if (!this.visible || !this.dialog || !this.dialog.container) return;
            this.dialog.center();
        });
        this.subscriptionManager.add("resizeSub", resizeSub);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected canOpen(): boolean {
        return true;
    }

    protected onOpen() {

    }

    protected afterShow() {

    }

    // PrimeNG trigger upon visible = true
    onShow() {
        this.body = this.dialog.el.nativeElement.getElementsByClassName("p-dialog-content")[0];
        this.afterShow();
    }

    // PrimeNG trigger upon visible = false
    onHide() {
        this.onClose();
        this.body = null;
    }

    protected openDialog() {
        if (!this.canOpen()) return;

        this.onOpen();
        this.visible = true;
    }

    protected onClose() {

    }

    close() {
        this.visible = false;
    }
}