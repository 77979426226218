import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, Output, inject } from "@angular/core";
import { AnalysisType, IMeasuringPointNavigator } from "src/app/models/measuring-point";
import { ILocationStatusSummary, ILocationSummary } from "src/app/models/web";
import { LocationWebApi } from "src/app/resource/web";
import { ViewStateService } from "../../services/view-state.service";
import { LocationRealtimeService } from "src/app/services/realtime/location-realtime.service";
import { ILocationChangedArguments } from "src/app/models/location-changed-arguments";
import { SubscriptionManager } from "src/app/utilities";
import { interval } from "rxjs";
import { NavigationService } from "src/app/services/navigation.service";
import { DomainDataService } from "src/app/services/domain-data.service";
import * as moment from "moment";

@Component({
    selector: "app-location-popup",
    templateUrl: "./location-popup.component.html",
    styleUrls: ["./location-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationPopupComponent implements OnDestroy {
    @Output() onClose: EventEmitter<void> = new EventEmitter();

    private readonly locationWebApi = inject(LocationWebApi);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly viewStateService = inject(ViewStateService);
    private readonly locationRealtimeService = inject(LocationRealtimeService);
    private readonly navigationService = inject(NavigationService);
    private readonly domainDataService = inject(DomainDataService);

    protected location: ILocationSummary;
    protected locationStatus: ILocationStatusSummary;
    protected measuringPointNavigator: IMeasuringPointNavigator;
    protected AnalysisType = AnalysisType; // In Angular, this is how enums are made available in the template

    private subscriptionManager = new SubscriptionManager();

    constructor(readonly ele: ElementRef<HTMLElement>) {
        this.ele.nativeElement.hidden = true;

        const realtimeSub = this.locationRealtimeService.subscribe(args => this.onRealtimeLocationChanged(args));
        this.subscriptionManager.add("realtimeSub", realtimeSub);

        // Even if we don't get SignalR events, we refresh every minute
        // This is because not all changes will result in a location SignalR message. Maybe we need to look at the MeasuringPoint SignalR messages?
        const timerSubscription = interval(60 * 1000).subscribe(async () => {
            await this.refresh();
        });
        this.subscriptionManager.add("timerSubscription", timerSubscription);
    }

    getWeatherIcon(weatherConditionType: string): string {
        return `weather-${weatherConditionType}`;
    }

    getWeatherTranslation(weatherConditionType: string): string {
        return this.domainDataService.translateEnum("weatherConditionType", weatherConditionType);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    /**
     * Open the popup and load the location status
     */
    public async open(location: ILocationSummary) {
        if (this.location?.id === location.id) return; // already open
        this.viewStateService.showDeviceDetails = false;
        try {

            this.viewStateService.selectedLocation = location;
            this.ele.nativeElement.hidden = false;
            this.location = location;
            this.locationStatus = null;
            this.cdr.detectChanges(); // onPush change detection
            const status = await this.locationWebApi.getLocationStatusSummary(location.id);
            if (status.location.id !== this.location?.id) {
                // race condition, the user has clicked multiple locations in quick succession and we are getting data that is no longer relevant
                return;
            }
            this.locationStatus = status;

            const measuringPoint = this.locationStatus.measuringPoints[0];
            this.measuringPointNavigator = {
                locationId: this.location.id,
                code: measuringPoint.code,
                id: measuringPoint.measuringPointId,
                analysisTypeId: measuringPoint.analysisType,
            };

            this.cdr.detectChanges(); // onPush change detection
        } catch (error) {
            // This can happen if the location was just deleted
            this.close();
        }
    }

    public close() {
        if (!this.isOpen) return;
        this.viewStateService.selectedLocation = null;
        this.location = null;
        this.locationStatus = null;
        this.ele.nativeElement.hidden = true;
        this.onClose.emit();
        this.cdr.detectChanges(); // onPush change detection
    }

    protected get showDeviceDetails(): boolean {
        return this.viewStateService.showDeviceDetails;
    }

    get isOpen(): boolean {
        return !this.ele.nativeElement.hidden;
    }

    protected shouldShowStatusHeader(): boolean {
        // It makes no sense to show the status header if we have no devices
        return this.locationStatus && this.locationStatus.devices.length > 0;
    }

    private async onRealtimeLocationChanged(args: ILocationChangedArguments) {
        if (!this.location) return;
        if (!args.data) return;

        const shouldRefresh = args.data.filter(x => x.id === this.location.id).length > 0;
        if (!shouldRefresh) return;

        this.refresh();
    }

    private async refresh() {
        if (!this.location) return;

        // We reload the data, but we don't show a spinner
        const status = await this.locationWebApi.getLocationStatusSummary(this.location.id);
        if (status.location.id !== this.location?.id) {
            // race condition, the user has clicked multiple locations in quick succession and we are getting data that is no longer relevant
            return;
        }
        this.locationStatus = status;
        this.cdr.detectChanges(); // onPush change detection
    }

    protected getGoogleMapsUrl(): string {
        return `https://www.google.com/maps/place/${this.location.lat},${this.location.lng}/@${this.location.lat},${this.location.lng},17z`;
    }

    protected async navigateToMeasuringPoint(event: MouseEvent): Promise<void> {
        if (!this.locationStatus || !this.measuringPointNavigator) return;

        const newTab = event?.button === 1 || event.ctrlKey;
        await this.navigationService.toMeasuringPointLocation(this.measuringPointNavigator.id, this.measuringPointNavigator.locationId, null, newTab);
    }

    protected getDateStringFormat(date: string): string {
        const dateObj = moment(date);

        if (dateObj.isSame(moment().add(0, 'days'), 'days')) {
            return "date.today"
        }
        else if (dateObj.isSame(moment().add(1, 'days'), 'days')) {
            return "date.tomorrow"
        }
        else if (dateObj.isSame(moment().add(2, 'days'), 'days')) {
            return "date.in_two_days"
        } else {
            return dateObj.format("YYYY-MM-DD");
        }
    }
}
