<div class="m-layout-w-actions-bottom m-border-top m-fixed-height">
    <div class="m-layout-area-body m-overflow-auto">
        <div class="p-16">
            <h3>{{"deviceAlarms.subTitle" | translate}}</h3>
        </div>

        <div>
            <app-device-alarms [device]="device"></app-device-alarms>
        </div>

        <div class="p-16">
            <h3>{{"deviceIgnoreRules.title" | translate}}</h3>
        </div>

        <div class="px-16 pb-16">
            <app-device-ignore-rules #deviceIgnoreRulesComponent
                                     [device]="device"></app-device-ignore-rules>
        </div>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-link"
                    [disabled]="deviceIgnoreRulesComponent.submitting || !deviceIgnoreRulesComponent.hasChanges"
                    (click)="deviceIgnoreRulesComponent.reset()"
                    type="button">

                <ng-container>
                    {{"form.reset" | translate}}
                </ng-container>

            </button>

            <button [disabled]="deviceIgnoreRulesComponent.submitting || !deviceIgnoreRulesComponent.hasChanges"
                    class="m-btn m-btn-primary"
                    id="alarms_tab_submit_button"
                    (click)="deviceIgnoreRulesComponent.submit()"
                    type="submit">

                <app-loading-spinner id="alarms_tab_spinner"
                                     *ngIf="deviceIgnoreRulesComponent.submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>