<p-table [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         class="m-layout-area-body m-border-top"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="scrollHeight"
         dataKey="value"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <span class="m-column-title">
                        {{column.header | translate}}
                    </span>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-contextType
                 let-expanded="expanded">
        <tr [pSelectableRow]="contextType">
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'expand'">
                    <a [pRowToggler]="contextType">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{contextType[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion"
                 let-contextType
                 let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length">
                <div style="margin-bottom: 50px;">
                    <app-journal-categories [contextTypeId]="contextType.jsonValue">
                    </app-journal-categories>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>