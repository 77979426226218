import { AttachmentCreator, AttachmentContext, AttachmentUpdater, IAttachment } from "src/app/models/attachment";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { FileUtils } from "src/app/utilities";
import { DownloadFileService } from "src/app/services/download-file.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { Input, Output } from "@angular/core";
import { SelectItem } from "primeng/api";

@Component({
    selector: "app-attachment-dialog",
    templateUrl: "./attachment.dialog.html"
})
export class AttachmentDialogComponent extends DialogComponentBase implements OnDestroy {
    @Input() attachedTo: AttachmentContext;
    @Output() newUpload = new EventEmitter<IAttachment>();

    existingAttachment: IAttachment;
    dialogForm: SigncoFormGroup;
    uploadFile: File;
    uploadFilePreview: string;
    uploadFileIsPhoto = false;
    submitting = false;
    attachmentTypes: SelectItem[];
    calendarSettings: CalendarSettings;
    editedFile = false;

    constructor(
        readonly formValidationService: FormValidationService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly attachmentApi: AttachmentApi,
        private readonly downloadFileService: DownloadFileService,
        private readonly domainModelFilterService: DomainModelFilterService) {

        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.domainModelFilterService.getAttachmentTypes$().then(attachmentTypes => {
            this.attachmentTypes = attachmentTypes;
        });
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected onOpen() {
        this.editedFile = false;
        this.submitting = false;
        this.uploadFile = null;
        this.uploadFilePreview = null;
        this.uploadFileIsPhoto = false;

        this.dialogForm = this.formBuilder.group({
            name: ["", Validators.required],
            description: "",
            typeId: [null, Validators.required],
        }) as SigncoFormGroup;

        if (this.existingAttachment) {
            this.uploadFileIsPhoto = FileUtils.isPhotoUrl(this.existingAttachment.url);

            if (this.uploadFileIsPhoto) {
                // Instantly set the preview, then download the real thing in the background
                // This enables editing / rotation
                this.uploadFilePreview = this.existingAttachment.url;

                this.downloadFileService.downloadBlob(this.existingAttachment.url).then((downloadedFile) => {
                    // Don't overwrite if already set in the meantime
                    if (!this.uploadFile) {
                        this.setUploadFile(downloadedFile.file);
                    }
                });
            }

            this.dialogForm.patchValue(this.existingAttachment);

            this.dialogForm.patchValue({
                typeId: this.existingAttachment.type.id,
            });
        }
    }

    protected onClose() {
        this.dialogForm = null;
    }

    open(attachmentContext: AttachmentContext = null) {
        this.existingAttachment = null;
        this.attachedTo = attachmentContext;

        this.openDialog();
    }

    edit(attachment: IAttachment, attachmentContext: AttachmentContext = null) {
        this.existingAttachment = attachment;
        this.attachedTo = attachmentContext;

        this.openDialog();
    }

    async setUploadFileFromInput(event: { files: FileList }) {
        if (!event || event.files.length <= 0) return;
        this.setUploadFile(event.files[0]);
        this.editedFile = true;
    }

    async setUploadFile(file: File) {
        this.uploadFile = file;

        const nameControl = this.dialogForm.get("name");
        if (this.uploadFile && !nameControl.value) {
            nameControl.patchValue(FileUtils.getNameWithoutExtension(this.uploadFile.name));
        }

        this.uploadFileIsPhoto = FileUtils.isPhoto(this.uploadFile);

        this.createPreview();
    }

    async rotatePhoto(clockwise = true) {
        this.editedFile = true;
        let image = new Image();
        image.src = await FileUtils.toBase64(this.uploadFile);

        image = FileUtils.rotateImage(image, this.uploadFile.type, clockwise ? 90 : -90);

        this.uploadFile = FileUtils.imageToFile(image, this.uploadFile.name, this.uploadFile.lastModified);
        this.createPreview();
    }

    private async createPreview() {
        if (this.uploadFileIsPhoto) {
            this.uploadFilePreview = await FileUtils.toBase64(this.uploadFile);
        } else {
            this.uploadFilePreview = null;
        }
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid) return;

        // We're either uploading a new file, or editing an existing attachment
        if (!this.uploadFile && !this.existingAttachment) return;

        this.submitting = true;

        const onSuccess = (attachment: IAttachment) => {
            this.submitting = false;
            this.uploadFile = null;
            this.uploadFilePreview = null;
            this.newUpload.emit(attachment);

            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const setAttachedTo = (creator: AttachmentCreator) => {
            if (!this.attachedTo) return;
            creator[`${this.attachedTo.contextType}Id`] = this.attachedTo.contextEntityId;
        };

        if (!this.existingAttachment) {
            const creator = new AttachmentCreator();
            Object.assign(creator, this.dialogForm.value);
            setAttachedTo(creator);

            this.attachmentApi
                .upload$(creator, this.uploadFile)
                .subscribe(onSuccess, onError);

        } else {
            const updater = new AttachmentUpdater(this.existingAttachment);
            Object.assign(updater, this.dialogForm.value);
            setAttachedTo(updater);

            // If file is unchanged, don't upload stream
            if (!this.editedFile) {
                this.attachmentApi
                    .update$(updater)
                    .subscribe(onSuccess, onError);
            } else {
                this.attachmentApi
                    .updateWithFile$(updater, this.uploadFile)
                    .subscribe(onSuccess, onError);
            }
        }
    }
}
