import { Subject, Observable } from "rxjs";
import { IParkingBan } from "src/app/models/parking-ban";

export class ParkingBanDataService {

    private parkingBanChangeSubject$: Subject<IParkingBan> = new Subject<IParkingBan>();
    public parkingBanChange$: Observable<IParkingBan> = this.parkingBanChangeSubject$.asObservable();

    notifyParkingBanChanged(parkingBan: IParkingBan) {
        this.parkingBanChangeSubject$.next(parkingBan);
    }
}