import { TableColumn, FilterType, TableService, LazyTableComponent } from "src/app/modules/shared/components/table/table.component";
import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { ManageRuleDialogComponent } from "../manage-rule-dialog/manage-rule.dialog";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "src/app/services/modal.service";
import { RuleApi } from "src/app/resource/rule.api";
import { IRule } from "src/app/models/alarm";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-rules",
    templateUrl: "./rules.component.html"
})
export class RulesComponent extends LazyTableComponent<IRule> {
    @ViewChild(ManageRuleDialogComponent, { static: true }) manageRuleDialog: ManageRuleDialogComponent;

    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        readonly translate: TranslateService,
        private readonly ruleApi: RuleApi,
        private readonly modalService: ModalService,
        private readonly domainDataService: DomainDataService) {

        super("rules.component", elementRef, ruleApi, tableService);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.selectionMode = "";

        this.addColumn(new TableColumn("code", "general.code", { filterType: FilterType.Text, sortable: true, width: 200 }));

        const deviceTypesColumn = new TableColumn("deviceTypes", "rules.deviceTypes", { filterType: FilterType.MultiSelect, sortable: false, width: 300 });
        this.addColumn(deviceTypesColumn);

        this.addColumn(new TableColumn("warningExpression", "rules.warningExpression", { filterType: FilterType.Text, sortable: true, width: 700 }));
        this.addColumn(new TableColumn("errorExpression", "rules.errorExpression", { filterType: FilterType.Text, sortable: true, width: 700 }));
        this.addColumn(new TableColumn("isAdminOnly", "rules.isAdminOnly", { filterType: FilterType.Boolean, sortable: true, width: 125 }));
        this.addColumn(new TableColumn("creator", "general.creator", { filterType: FilterType.None, sortable: false, width: 200 }));
        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true }));

        this.registerCommand({
            text: "manageRule.edit",
            icon: "edit",
            click: (rule) => this.edit(rule),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.EditRule)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (rule) => this.delete(rule),
            validFunc: () => this.deleteCommand
        });

        this.domainDataService.get(DomainData.DeviceType).then((viewModels) => {
            deviceTypesColumn.filterOptions = viewModels;
        });
    }

    create() {
        const onCreate = () => {
            this.reload();
        };

        this.manageRuleDialog.create(onCreate);
    }

    edit(rule: IRule) {
        const onEdit = () => {
            this.reload();
        };

        this.manageRuleDialog.edit(rule, onEdit);
    }

    delete(rule: IRule) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.ruleApi.delete$(rule.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("rules.deleteConfirmation", { code: rule.code });
        this.modalService.delete(modalBody, onDelete);
    }
}