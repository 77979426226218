import { NumberUtils } from "./../../../../../../utilities/number-utils";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ChartConfiguration, ChartData, ChartOptions, ScaleOptionsByType, TooltipItem } from "chart.js";
import { IDeviceStatusSummary } from "src/app/models/web";

@Component({
    selector: "app-device-status",
    templateUrl: "./device-status.component.html",
    styleUrls: ["./device-status.component.scss", "../../location-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceStatusComponent implements OnInit {
    @Input() data: IDeviceStatusSummary;

    private readonly translateService = inject(TranslateService);
    private readonly cdr = inject(ChangeDetectorRef);

    protected chartConfiguration: ChartConfiguration;

    ngOnInit(): void {
        if (!this.data) return;

        this.initChartOptions();
    }

    private initChartOptions() {
        if (!this.data.powerHistory || this.data.powerHistory.length === 0) return;

        const hasChargePercentageData = this.data.powerHistory.find(x => NumberUtils.isValid(x.data.chargePercentage));
        if (!hasChargePercentageData) return;

        setTimeout(() => {
            const dates = new Array<Date>();
            const chargePercentages = new Array<number>();

            this.data.powerHistory.forEach(x => {
                dates.push(x.timestamp);
                chargePercentages.push(x.data.chargePercentage);
            });

            const chartData = {
                labels: dates.map(x => x.toISOString()),
                datasets: []
            } as ChartData;

            chartData.datasets.push({
                label: "%",
                data: chargePercentages,
                backgroundColor: "rgba(59, 196, 105, 0.3)",
                fill: "origin",
                showLine: false,
                pointBackgroundColor: "rgba(59, 196, 105, 1)",
                pointRadius: 0,
                yAxisID: "yChargePercentage"
            });

            const chartOptions = {} as ChartOptions<"line">;
            chartOptions.showLine = true;
            chartOptions.scales = {};
            chartOptions.plugins = {
                legend: {
                    display: false
                }
            };
            chartOptions.animation = {
                duration: 0
            };
            chartOptions.plugins.tooltip = {};
            chartOptions.plugins.tooltip.enabled = true;
            chartOptions.plugins.tooltip.mode = "index";
            chartOptions.plugins.tooltip.intersect = false;
            chartOptions.plugins.tooltip.callbacks = {
                title: (tooltipItems: TooltipItem<"line">[]) => {
                    return tooltipItems[0].label.toString();
                },
                label: (tooltipItem: TooltipItem<"line">) => {
                    const powerHistory = this.data.powerHistory[tooltipItem.dataIndex];
                    const tooltipBatteryVoltage = powerHistory.data.batteryVoltage?.toFixed(1);
                    return tooltipBatteryVoltage + this.translateService.instant("measurements.volt");
                }
            };

            chartOptions.hover = {};
            chartOptions.hover.mode = "index";
            chartOptions.hover.intersect = false;

            const percentageAxis = {} as ScaleOptionsByType<"linear">;
            percentageAxis.type = "linear";
            percentageAxis.position = "left";
            percentageAxis.beginAtZero = true;
            percentageAxis.min = 0;
            percentageAxis.max = 100;
            percentageAxis.ticks = {} as any;
            percentageAxis.ticks.stepSize = 25;
            percentageAxis.ticks.minRotation = 0;
            percentageAxis.ticks.maxRotation = 0;
            percentageAxis.ticks.precision = 0;
            percentageAxis.ticks.padding = 8;
            percentageAxis.ticks.callback = (value, index, values) => {
                return `${value}%`;
            };

            percentageAxis.grid = {} as any;
            percentageAxis.grid.tickLength = 0;

            chartOptions.scales.yChargePercentage = percentageAxis;

            const xAxes = {} as ScaleOptionsByType<"time">;
            xAxes.type = "time";
            xAxes.stacked = false;

            xAxes.time = {} as any;
            xAxes.time.unit = "day";
            xAxes.time.tooltipFormat = this.translateService.currentLang === "nl" ? "ddd DD/MM/YYYY HH:mm" : "ddd MM/DD/YYYY HH:mm";
            xAxes.time.displayFormats = {};
            xAxes.time.displayFormats.minute = "HH:mm";
            xAxes.time.displayFormats.hour = "MMM D, H";
            xAxes.time.displayFormats.day = "MMM D";

            xAxes.ticks = {} as any;
            xAxes.ticks.stepSize = 1;
            xAxes.ticks.maxRotation = 0;
            xAxes.ticks.autoSkip = true;
            xAxes.ticks.autoSkipPadding = 50;
            xAxes.ticks.minRotation = 0;
            xAxes.ticks.maxRotation = 0;
            xAxes.ticks.sampleSize = 5;
            xAxes.ticks.padding = 0;

            xAxes.grid = {} as any;
            xAxes.grid.display = false;

            chartOptions.scales.x = xAxes;

            this.chartConfiguration = {
                type: "line",
                data: chartData,
                options: chartOptions
            } as ChartConfiguration;

            this.cdr.detectChanges();
        }, 1);
    }
}