import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IAttachment } from "src/app/models/attachment";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "src/app/models/search";
import { ImageDialogComponent } from "src/app/modules/shared/components/image-dialog/image-dialog.component";
import { FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { DownloadFileService } from "src/app/services/download-file.service";
import { ModalService } from "src/app/services/modal.service";
import { ManageParkingBanImageDialogComponent } from "../manage-parking-ban-image-dialog/manage-parking-ban-image-dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-parking-ban-images",
    templateUrl: "./parking-ban-images.component.html",
    styleUrls: []
})
export class ParkingBanImagesComponent extends LazyTableComponent<IAttachment> implements OnChanges {

    @ViewChild(ImageDialogComponent, { static: true }) imageDialogComponent: ImageDialogComponent;
    @ViewChild(ManageParkingBanImageDialogComponent, { static: true }) manageParkingBanImageDialogComponent: ManageParkingBanImageDialogComponent;

    @Input() parkingBanId: number;
    @Input() requiresInput = false;
    @Input() editCommand = false;
    @Input() deleteCommand = false;

    private loadingPhotos = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly attachmentApi: AttachmentApi,
        private readonly globalEventsService: GlobalEventsService,
        public readonly translateService: TranslateService,
        private readonly modalService: ModalService,
        private readonly downloadFileService: DownloadFileService) {
        super("parkingBanImages.component", elementRef, attachmentApi, tableService);

        this.addColumn(new TableColumn("photo", "", { filterType: FilterType.None, sortable: false, resizable: false, width: 128 }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.Text, sortable: false }));

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (attachment) => this.edit(attachment),
            validFunc: () =>
                this.editCommand && this.rights?.hasBackendRight(BackendRights.EditParkingBan),
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (attachment) => this.delete(attachment),
            validFunc: () =>
                this.deleteCommand &&
                this.rights?.hasBackendRight(BackendRights.DeleteParkingBan),
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const parkingBanIdChange = changes["parkingBanId"];
        const inputChange = changes["requiresInput"] || parkingBanIdChange;

        if (inputChange) {
            this.reload();
        }

        super.ngOnChanges(changes);
    }

    getSearchParameters() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.parkingBanId) {
            searchParameters.filter.push({ field: "parkingBanId", value: this.parkingBanId, operator: FilterOperator.equals });
        }
        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        return serviceRequestOptions;
    }

    async processLoadedData(data: IAttachment[]): Promise<IAttachment[]> {
        if (this.loadingPhotos) return data;
        this.loadingPhotos = true;

        for (const attachment of data) {
            if (!attachment.preview) {
                attachment.preview = (await this.downloadFileService.downloadBlob(attachment.url)).toResourceUrl();
            }
        }
        this.loadingPhotos = false;
        return data;
    }

    private edit(attachment: IAttachment) {
        this.manageParkingBanImageDialogComponent.edit(attachment, this.parkingBanId, () => {
            this.reload();
        });
    }

    private delete(attachment: IAttachment) {
        if (!this.rights?.hasBackendRight(BackendRights.DeleteParkingBan)) return;

        const modalBody = this.translateService.instant("parkingBan.deletePhotoConfirmation", { fileName: attachment.name }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(attachment));
    }

    private handleDelete(attachment: IAttachment) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        this.attachmentApi.delete$(attachment.id).subscribe(onDeleteSuccess, () => { });
    }

    onImageClick(attachment: IAttachment) {
        if (attachment.preview) {
            this.imageDialogComponent.create(attachment.name, attachment.preview);
        }
    }
}
