import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions, SortDescriptor, SortDirection } from "src/app/models/search";
import { IWorker } from "src/app/models/worker";
import { ColumnVisibility, FilterType, LazyTableComponent, TableColumn, TableService } from "src/app/modules/shared/components/table/table.component";
import { ModalService } from "src/app/services/modal.service";
import { WorkerApi } from "../../../../resource/worker.api";
import { ManageWorkerDialogComponent } from "../manage-worker-dialog/manage-worker-dialog.component";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";
import { Rights } from "src/app/models/rights";

@Component({
    selector: "app-workers",
    templateUrl: "./workers.component.html"
})
export class WorkersComponent extends LazyTableComponent<IWorker> {
    @ViewChild(ManageWorkerDialogComponent, { static: true }) manageWorkerDialog: ManageWorkerDialogComponent;

    @Input() editCommand = true;
    @Input() deleteCommand = true;

    showOutOfService = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        public readonly workerApi: WorkerApi,
        private readonly globalEventsService: GlobalEventsService,
        private readonly modalService: ModalService,
        private readonly translateService: TranslateService
    ) {
        super("workers.component", elementRef, workerApi, tableService);
        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.stretchHeight = true;

        this.addColumn(new TableColumn("firstName", "general.firstName", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("lastName", "general.lastName", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("email", "general.email", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("phoneNumber", "general.phoneNumber", { filterType: FilterType.Text, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("inServiceFrom", "worker.inServiceFrom", { filterType: FilterType.Date, sortable: true, visibility: ColumnVisibility.HideMini }));
        this.addColumn(new TableColumn("outOfServiceFrom", "worker.outOfServiceFrom", { filterType: FilterType.Date, sortable: true, visibility: ColumnVisibility.HideMini }));

        this.registerCommand({
            text: "worker.edit",
            icon: "edit",
            click: (worker) => this.editWorker(worker),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.EditWorker)
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (worker) => this.delete(worker),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteWorker)
        });
    }
    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.includeObsolete = false;


        searchParameters.sort = [
            new SortDescriptor(SortDirection.ascending, "FirstName")
        ];

        if (!this.showOutOfService) {
            if (!searchParameters.filter) searchParameters.filter = [];
            const now = new Date();
            searchParameters.filter.push({ field: "inServiceAtPeriod", operator: FilterOperator.equalTo, value: `${now.toMetanousDateString()}|${now.toMetanousDateString()}` });
        }

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("worker", "photos");
        return serviceRequestOptions;
    }

    private editWorker(worker: IWorker) {
        this.manageWorkerDialog.edit(worker, () => {
            this.reload();
        });
    }

    private delete(worker: IWorker) {
        if (!this.rights?.hasBackendRight(BackendRights.EditWorker) || !worker) return;

        const modalBody = this.translateService.instant("worker.deleteConfirmation", { name: `${worker.firstName} ${worker.lastName}` }) as string;
        this.modalService.delete(modalBody, () => this.handleDelete(worker));
    }

    private handleDelete(worker: IWorker) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        this.workerApi.delete$(worker.id).subscribe(onDeleteSuccess, () => { });
    }
}
