<div class="m-layout-w-actions-bottom m-fixed-height m-border-top">
    <div *ngIf="!arrowBoardConfigurationForm">
        <app-loading-spinner>
        </app-loading-spinner>
    </div>

    <div class="m-layout-area-body"
         style="overflow: hidden;display: flex;flex-flow: column;">
        <form *ngIf="arrowBoardConfigurationForm"
              [formGroup]="arrowBoardConfigurationForm"
              autocomplete="off"
              style="overflow: hidden;display: flex;flex-flow: column; margin:16px;">

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': arrowBoardConfigurationForm.get('vehicleIdentificationNumber').showErrors }">

                <label class="col-4"
                       for="vehicleIdentificationNumber">
                    {{"arrowBoardConfigurationTab.vehicleIdentificationNumber" | translate}}
                </label>

                <div class="col-8">

                    <input type="text"
                           class="p-inputtext"
                           id="vehicleIdentificationNumber"
                           formControlName="vehicleIdentificationNumber"
                           #vehicleIdentificationNumber />


                    <app-form-validation [control]="arrowBoardConfigurationForm.get('vehicleIdentificationNumber')"
                                         [input]="vehicleIdentificationNumber">
                    </app-form-validation>
                </div>
            </div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': arrowBoardConfigurationForm.get('licensePlate').showErrors }">

                <label class="col-4"
                       for="licensePlate">
                    {{"arrowBoardConfigurationTab.licensePlate" | translate}}
                </label>

                <div class="col-8">

                    <input type="text"
                           class="p-inputtext"
                           id="licensePlate"
                           formControlName="licensePlate"
                           #licensePlate />


                    <app-form-validation [control]="arrowBoardConfigurationForm.get('licensePlate')"
                                         [input]="licensePlate">
                    </app-form-validation>
                </div>
            </div>

        </form>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div *ngIf="arrowBoardConfigurationForm"
             class="m-btn-group m-btn-group-right">

            <button [disabled]="submitting"
                    class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">

                {{"form.reset" | translate}}
            </button>

            <button [disabled]="submitting"
                    (click)="submit()"
                    class="m-btn m-btn-primary"
                    type="submit">

                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>