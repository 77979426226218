<div class="m-layout-w-actions-bottom m-border-top m-fixed-height">
    <div class="p-16 m-overflow-auto m-layout-area-body">
        <form [formGroup]="anprForm"
              *ngIf="device"
              autocomplete="off">

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': anprForm.get('dataUrl').showErrors }">

                <label class="col-4"
                       for="dataUrl">
                    {{"devices.dataUrl" | translate}}
                </label>

                <div class="col-8">
                    <input type="text"
                           id="dataUrl"
                           class="p-inputtext"
                           formControlName="dataUrl"
                           appAutofocus
                           #dataUrlInput />

                    <app-form-validation [control]="anprForm.get('dataUrl')"
                                         [input]="dataUrlInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': anprForm.get('updateInterval').showErrors }">

                <label class="col-4"
                       for="updateInterval">
                    {{"devices.updateInterval" | translate}}
                </label>

                <div class="col-8">

                    <p-calendar id="updateInterval"
                                formControlName="updateInterval"
                                selectionMode="single"
                                [style]="{'width':'100%'}"
                                autoWidth="false"
                                [showIcon]="true"
                                [showSeconds]="true"
                                [hourFormat]="24"
                                [dateFormat]="calendarSettings.dateFormat"
                                [timeOnly]="true"
                                showButtonBar="false"
                                todayButtonStyleClass="hidden"
                                #updateIntervalInput></p-calendar>

                    <app-form-validation [control]="anprForm.get('updateInterval')"
                                         [input]="updateIntervalInput">
                    </app-form-validation>
                </div>
            </div>
        </form>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom">
        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-link"
                    (click)="reset()"
                    type="button">

                {{"form.reset" | translate}}

            </button>

            <button id="anpr_tab_submit_button"
                    [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit">

                <app-loading-spinner id="anpr_tab_spinner"
                                     *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>