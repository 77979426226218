<div class="m-layout-area-body m-layout-w-actions-top">
    <div class="m-btn-group m-btn-group-right m-border-top m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="openDownloadArchiveDialog()"
                id="download_archive_button">
            <app-svg-icon icon="download">
            </app-svg-icon>
            {{'uploads.downloadArchive' | translate}}
        </button>
    </div>

    <app-uploads [device]="device"
                 class="m-layout-area-body"
                 [deleteCommand]="true"></app-uploads>
</div>

<app-download-archive-dialog></app-download-archive-dialog>