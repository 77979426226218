import { ChangeDetectorRef, ElementRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, Type } from "@angular/core";
import { AssignmentDetailComponent } from "../assignment-detail/assignment-detail.component";
import { AuditTrailsComponent } from "src/app/modules/shared/components/audit-trails/audit-trails.component";
import { TabBase, TabService } from "src/app/modules/shared/components/tabs/tabs.component";
import { JournalComponent } from "src/app/modules/shared/components/journal/journal.component";
import { TranslateService } from "@ngx-translate/core";
import { IAssignment } from "src/app/models/assignment";
import { TasksHistoryComponent } from "../task-history/tasks-history.component";
import { AssignmentsHistoryComponent } from "../assignments-history/assignments-history.component";
import { TasksContainerComponent } from "../tasks-container/tasks-container.component";
import { ParkingBansContainerComponent } from "../parking-bans-container/parking-bans-container.component";
import { BackendRights } from "src/app/models/backend-rights";

type AssignmentDetailContainerComponents =
    AssignmentDetailComponent |
    TasksContainerComponent |
    ParkingBansContainerComponent |
    AuditTrailsComponent |
    JournalComponent |
    AssignmentsHistoryComponent |
    TasksHistoryComponent;

@Component({
    selector: "app-assignment-detail-container",
    templateUrl: "./assignment-detail-container.component.html"
})
export class AssignmentDetailContainerComponent extends TabBase<AssignmentDetailContainerComponents> implements OnDestroy, OnChanges {

    @Input() assignment: IAssignment;

    @Output() save = new EventEmitter<IAssignment>();

    @Output() lastParkingBanRemoved = new EventEmitter<void>();
    @Output() lastTaskRemoved = new EventEmitter<void>();

    constructor(
        elementRef: ElementRef,
        cd: ChangeDetectorRef,
        tabService: TabService,
        private readonly translateService: TranslateService) {

        super(tabService, cd);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        this.addTab({ component: AssignmentDetailComponent, url: "details", translatePath: "assignmentDetailTab.title", icon: "info" });
        this.addTab({ component: TasksContainerComponent, url: "tasks", translatePath: "assignmentTasksTab.title", icon: "task" });
        this.addTab({ component: TasksHistoryComponent, url: "workHistory", translatePath: "taskStatusHistory.tasksHistory", icon: "modifications-history" });
        this.addTab({ component: ParkingBansContainerComponent, url: "parkingbans", translatePath: "assignmentParkingBansTab.title", icon: "parking-ban" });
        this.addTab({ component: JournalComponent, url: "journal", translatePath: "journal.title", icon: "history" });
        this.addTab({ component: AssignmentsHistoryComponent, url: "history", translatePath: "assignmentHistory.title", icon: "modifications-history" });
        // this.addTab({ component: AuditTrailsComponent, url: "audit", translatePath: "auditTrails.title", icon: "log" });


        this.filterTabs();
        this.updateActiveComponent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateActiveComponent();
        this.filterTabs();

        const assignmentChange = changes["assignment"];
        if (assignmentChange) {
            this.onSelectedTabChange();
            this.updateTitle();
        }
    }

    filterTabs() {
        if (!this.assignment) return;

        const tabsToFilter = new Array<Type<AssignmentDetailContainerComponents>>();
        const rights = this.globalEventsService.getCurrentRights();
        if ((this.assignment.subAssignments && this.assignment.subAssignments.length > 0) || !rights?.hasBackendRight(BackendRights.ViewTask)) {
            tabsToFilter.push(TasksHistoryComponent);
            tabsToFilter.push(TasksContainerComponent);
        }

        if ((this.assignment.subAssignments && this.assignment.subAssignments.length > 0) || !rights?.hasBackendRight(BackendRights.ViewParkingBan)) {
            tabsToFilter.push(ParkingBansContainerComponent);
        }

        if (!rights?.hasBackendRight(BackendRights.ViewAssignmentAuditTrail)) {
            tabsToFilter.push(AuditTrailsComponent);
        }
        if (!rights?.hasBackendRight(BackendRights.EditAssignment)) {
            tabsToFilter.push(AssignmentsHistoryComponent);
        }

        if (!rights?.hasBackendRight(BackendRights.EditTask)) {
            tabsToFilter.push(TasksHistoryComponent);
        }

        this.hideTabs(tabsToFilter);
    }

    updateTitle() {
        if (!this.assignment || !this.selectedTab) return;
        this.titleService.setTitle(`${this.assignment?.name || this.translateService.instant("manageAssignment.create")}`);
    }

    protected onSelectedTabChange() {
        if (!this.activeComponent) return;

        this.activeComponent.setAssignment(this.assignment);
        this.subscribeToAssignmentDetailChanges();
        this.subscribeToLastTaskRemoved();
        this.subscribeToLastParkingBanRemoved();
        this.updateDisabled();
    }

    private subscribeToAssignmentDetailChanges() {
        this.subscriptionManager.remove("save");

        if (this.activeComponent instanceof AssignmentDetailComponent) {
            const saveSubscription = this.activeComponent.save.subscribe((assignment: IAssignment) => {
                this.assignment = assignment;
                this.updateTitle();
                this.filterTabs();
                this.save.next(assignment);
            });
            this.subscriptionManager.add("save", saveSubscription);
        }
    }

    private subscribeToLastTaskRemoved() {
        this.subscriptionManager.remove("lastTaskRemoved");

        if (this.activeComponent instanceof TasksContainerComponent) {
            const saveSubscription = this.activeComponent.lastTaskRemoved.subscribe((assignment: IAssignment) => {
                this.lastTaskRemoved.next();
            });
            this.subscriptionManager.add("lastTaskRemoved", saveSubscription);
        }
    }

    private subscribeToLastParkingBanRemoved() {
        this.subscriptionManager.remove("lastParkingBanRemoved");

        if (this.activeComponent instanceof ParkingBansContainerComponent) {
            const saveSubscription = this.activeComponent.lastParkingBanRemoved.subscribe(() => {
                this.lastParkingBanRemoved.next();
            });
            this.subscriptionManager.add("lastParkingBanRemoved", saveSubscription);
        }
    }

    private updateDisabled() {
        const disabledExpression = (type: Type<AssignmentDetailContainerComponents>) => {
            if (type instanceof AssignmentDetailComponent) return false;

            // Can't navigate to other tabs when creating a new assignment
            return !this.assignment || !this.assignment.id;
        };

        this.setTabsDisabledState(disabledExpression);
    }
}
