<ng-container *ngIf="!taskForm">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<form [formGroup]="taskForm"
      class="m-layout-area-body m-border-top m-overflow-auto p-16"
      *ngIf="taskForm"
      autocomplete="off">
    <!--assignment-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': taskForm.get('assignmentId').showErrors }">
        <label class="col-4">
            {{"general.assignment" | translate}}
        </label>

        <div class="col-8">
            <label>
                {{task.assignment.name}}
            </label>
        </div>
    </div>
    <!--name-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': taskForm.get('name').showErrors }">
        <label class="col-4"
               for="name">
            {{"general.name" | translate}}
        </label>

        <div class="col-8">
            <input type="text"
                   placeholder="{{'general.name' | translate}}"
                   id="name"
                   class="p-inputtext"
                   formControlName="name"
                   appAutofocus
                   #nameInput />
            <app-form-validation [control]="taskForm.get('name')"
                                 [input]="nameInput">
            </app-form-validation>
        </div>
    </div>

    <!--copiedTask-->
    <div class="m-form-group row"
         *ngIf="task?.copiedTask">
        <label class="col-4">
            {{"task.copyOf" | translate}}
        </label>

        <div class="col-8">
            <app-navigator [task]="task.copiedTask"
                           [enabled]="true"></app-navigator>
        </div>
    </div>

    <!-- start -->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': taskForm.get('start').showErrors }">
        <label class="col-4"
               for="start">
            {{"assignment.start" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="start"
                        appendTo="body"
                        id="start"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [showIcon]="true"
                        [showTime]="true"
                        [maxDate]="taskForm.get('end')?.value"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.start' | translate}}"
                        #startInput></p-calendar>

            <app-form-validation [control]="taskForm.get('start')"
                                 [input]="startInput">
            </app-form-validation>
        </div>
    </div>
    <!--end-->
    <div class="m-form-group row"
         [ngClass]="{ 'has-error': taskForm.get('end').showErrors }">
        <label class="col-4"
               for="end">
            {{"assignment.end" | translate}}
        </label>

        <div class="col-8">
            <p-calendar formControlName="end"
                        id="end"
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        autoWidth="false"
                        [minDate]="taskForm.get('start')?.value"
                        [showIcon]="true"
                        [showTime]="true"
                        [hourFormat]="calendarSettings.hourFormat"
                        [dateFormat]="calendarSettings.dateFormat"
                        [locale]="calendarSettings.localeSettings"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="primeComponentService.calendarYearRange"
                        placeholder="{{'assignment.end' | translate}}"
                        #endInput></p-calendar>
            <app-form-validation [control]="taskForm.get('end')"
                                 [input]="endInput">
            </app-form-validation>
        </div>
    </div>
    <form formGroupName="currentStatus"
          *ngIf="taskStatusForm"
          autocomplete="off">
        <!-- IsVerified -->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('isVerified').showErrors }">
            <label class="col-4"
                   for="isVerified">
                {{"task.isVerified" | translate}}
            </label>

            <div class="col-8">
                <label class="m-input-checkbox">
                    <input type="checkbox"
                           formControlName="isVerified">
                </label>
            </div>
        </div>
        <!--is new-->
        <div class="m-form-group row"
             *ngIf="selectCanBeRefreshed">
            <label class="col-4"
                   for="isNew">
                {{"task.isNew" | translate}}
            </label>

            <div class="col-8">
                <label class="m-input-checkbox">
                    <input type="checkbox"
                           formControlName="isNew" />
                </label>
            </div>
        </div>
        <!--taskStatus-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('taskStatusId').showErrors }">
            <label class="col-4"
                   for="taskStatusId">
                {{"general.status" | translate}}
            </label>

            <div class="col-8">
                <p-dropdown formControlName="taskStatusId"
                            id="taskStatusId"
                            [panelStyle]="{'width':'100%'}"
                            [options]="filteredTaskStatuses"
                            [autoDisplayFirst]="false"
                            placeholder="{{'taskDetailTab.selectTaskStatus' | translate }}"
                            (onChange)="handleTaskStatusChange($event)"
                            #taskStatusIdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('taskStatusId')"
                                     [input]="taskStatusIdInput">
                </app-form-validation>
            </div>
        </div>

        <!-- AvailableFrom - for recurring tasks -->
        <div class="m-form-group row"
             *ngIf="selectAvailableFrom"
             [ngClass]="{ 'has-error': taskStatusForm.get('availableFrom').showErrors }">
            <label class="col-4"
                   for="availableFrom">
                {{"task.availableFrom" | translate}}
            </label>

            <div class="col-8">
                <p-calendar appendTo="body"
                            id="availableFrom"
                            [panelStyle]="{'width':'100%'}"
                            autoWidth="false"
                            [showIcon]="true"
                            [minDate]="currentDate"
                            [hourFormat]="calendarSettings.hourFormat"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            [yearRange]="primeComponentService.calendarYearRange"
                            placeholder="{{'task.availableFrom' | translate}}"
                            formControlName="availableFrom"
                            #availableFromInput></p-calendar>

                <app-form-validation [control]="taskStatusForm.get('availableFrom')"
                                     [input]="availableFromInput">
                </app-form-validation>
            </div>
        </div>

        <!-- Team - for recurring tasks -->
        <div class="m-form-group row"
             *ngIf="selectAvailableFrom"
             [ngClass]="{ 'has-error': taskStatusForm.get('workerIds').showErrors }">
            <label class="col-4"
                   for="workerIds">
                {{"task.workerTasks" | translate}}
            </label>

            <div class="col-8">
                <p-multiSelect appendTo="body"
                               id="workerIds"
                               placeholder="{{'recurringTasks.selectResponsibleWorkers' | translate}}"
                               [style]="{'width':'100%'}"
                               [options]="workers"
                               [filter]="true"
                               [showHeader]="true"
                               [showToggleAll]="false"
                               formControlName="workerIds"
                               #selectedWorkersInput>
                </p-multiSelect>

                <app-form-validation [control]="taskStatusForm.get('workerIds')"
                                     [input]="selectedWorkersInput">
                </app-form-validation>
            </div>
        </div>

        <!--task type-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('taskTypeId').showErrors }">
            <label class="col-4"
                   for="taskTypeId">
                {{"task.taskType" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="taskTypeId"
                            id="taskTypeId"
                            [panelStyle]="{'width':'100%'}"
                            [options]="taskTypes"
                            [autoDisplayFirst]="false"
                            (onChange)="handleTaskTypeChange($event)"
                            placeholder="{{'taskDetailTab.selectTaskType' | translate }}"
                            #taskTypeIdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('taskTypeId')"
                                     [input]="taskTypeIdInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign material-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signMaterialId').showErrors }"
             *ngIf="selectMaterial">
            <label class="col-4"
                   for="signMaterialId">
                {{"task.signMaterial" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signMaterialId"
                            id="signMaterialId"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signMaterials"
                            (onChange)="handleMaterialChange($event)"
                            placeholder="{{'taskDetailTab.selectSignMaterial' | translate }}"
                            #signMaterialIdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('signMaterialId')"
                                     [input]="signMaterialIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="selectIsMachineWork">
            <label class="col-4"
                   for="isMachineWork">
                {{"task.isMachineWork" | translate}}
            </label>

            <div class="col-8">
                <label class="m-input-checkbox">
                    <input type="checkbox"
                           (change)="handleIsMachineWorkChange($event)"
                           formControlName="isMachineWork" />
                </label>
            </div>
        </div>
        <!--is primer-->
        <div class="m-form-group row"
             *ngIf="selectCanHavePrimer">
            <label class="col-4"
                   for="primer">
                {{"task.primer" | translate}}
            </label>
            <div class="col-8">
                <label class="m-input-checkbox">
                    <input type="checkbox"
                           formControlName="primer" />
                </label>
            </div>
        </div>
        <!--substrate-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('substrateTypeId').showErrors }"
             *ngIf="selectShowSubstrate">
            <label class="col-4"
                   for="substrateTypeId">
                {{"task.substrateType" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="substrateTypeId"
                            id="substrateTypeId"
                            [panelStyle]="{'width':'100%'}"
                            [options]="allSubstrateTypes"
                            [autoDisplayFirst]="false"
                            placeholder="{{'taskDetailTab.selectSubstrateType' | translate }}"
                            (onChange)="handleSubstrateTypeChange($event)"
                            #substrateTypeIdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('substrateTypeId')"
                                     [input]="substrateTypeIdInput">
                </app-form-validation>
            </div>
        </div>
        <!--substrate override-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('substrateTypeOverride').showErrors }"
             *ngIf="selectShowSubstrate && taskStatusForm.value.substrateTypeId == null">
            <label class="col-4"
                   for="substrateTypeOverride">
                {{"task.substrateTypeOverride" | translate}}
            </label>
            <div class="col-8">
                <input type="text"
                       id="substrateTypeOverride"
                       class="p-inputtext"
                       formControlName="substrateTypeOverride"
                       #substrateTypeOverrideInput />
                <app-form-validation [control]="taskStatusForm.get('substrateTypeOverride')"
                                     [input]="substrateTypeOverrideInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign category-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryId').showErrors }"
             *ngIf="selectShowCategories">
            <label class="col-4"
                   for="signCategoryId">
                {{"task.signCategory" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signCategoryId"
                            id="signCategoryId"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signCategoryValues"
                            [autoDisplayFirst]="false"
                            (onChange)="handleCategoryChange($event)"
                            placeholder="{{'taskDetailTab.selectSignCategory' | translate }}"
                            #signCategoryIdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('signCategoryId')"
                                     [input]="signCategoryIdInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign category level 2-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel2Id').showErrors }"
             *ngIf="selectLevel2Category">
            <label class="col-4"
                   for="signCategoryLevel2Id">
                {{"task.signCategoryLevel2" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signCategoryLevel2Id"
                            id="signCategoryLevel2Id"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signCategoryLevel2Values"
                            [autoDisplayFirst]="false"
                            (onChange)="handleCategoryLevel2Change($event)"
                            placeholder="{{'taskDetailTab.selectSignCategory' | translate }}"
                            #signCategoryLevel2IdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('signCategoryLevel2Id')"
                                     [input]="signCategoryLevel2IdInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign category level 3-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel3Id').showErrors }"
             *ngIf="selectLevel3Category">
            <label class="col-4"
                   for="signCategoryLevel3Id">
                {{"task.signCategoryLevel3" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signCategoryLevel3Id"
                            id="signCategoryLevel3Id"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signCategoryLevel3Values"
                            [autoDisplayFirst]="false"
                            (onChange)="handleCategoryLevel3Change($event)"
                            placeholder="{{'taskDetailTab.selectSignCategory' | translate }}"
                            #signCategoryLevel3IdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('signCategoryLevel3Id')"
                                     [input]="signCategoryLevel3IdInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign category level 4-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel4Id').showErrors }"
             *ngIf="selectLevel4Category">
            <label class="col-4"
                   for="signCategoryLevel4Id">
                {{"task.signCategoryLevel4" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signCategoryLevel4Id"
                            id="signCategoryLevel4Id"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signCategoryLevel4Values"
                            [autoDisplayFirst]="false"
                            (onChange)="handleCategoryLevel4Change($event)"
                            placeholder="{{'taskDetailTab.selectSignCategory' | translate }}"
                            #signCategoryLevel4IdInput>
                </p-dropdown>

                <app-form-validation [control]="taskStatusForm.get('signCategoryLevel4Id')"
                                     [input]="signCategoryLevel4IdInput">
                </app-form-validation>
            </div>
        </div>

        <!--Image that is explaining the options-->
        <div class="m-form-group row"
             *ngIf="propertyConfiguration && propertyConfiguration.showImage">
            <label class="col-4">

            </label>
            <div class="col-8">
                <img [src]="'/assets/img/sign-categories/' + propertyConfiguration.imageName"
                     class="col-4" />
            </div>
        </div>

        <!--type-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('type').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showType">
            <label class="col-4"
                   for="type">
                {{"task.type" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="type"
                            id="type"
                            [panelStyle]="{'width':'100%'}"
                            [options]="typeOptions"
                            [autoDisplayFirst]="false"
                            placeholder="{{'taskDetailTab.selectType' | translate }}"
                            #typeInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('type')"
                                     [input]="typeInput">
                </app-form-validation>
            </div>
        </div>
        <!--sign color-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customSignColor').showErrors }"
             *ngIf="selectColor && displayCustomSignColor">
            <label class="col-4"
                   for="customSignColor">
                {{"task.signColor" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown id="customSignColor"
                            [options]="signColors"
                            formControlName="customSignColor"
                            [autoDisplayFirst]="false"
                            [filter]="true"
                            [panelStyle]="{'width':'100%'}"
                            placeholder="{{'task.selectColor' | translate }}"
                            #signColorInput>
                    <ng-template let-signColor
                                 pTemplate="item">
                        <span>{{signColor.label | translate}}</span>
                    </ng-template>
                    <ng-template let-signColor
                                 pTemplate="selectedItem">
                        <span>{{signColor.label | translate}}</span>
                    </ng-template>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('customSignColor')"
                                     [input]="signColorInput">
                </app-form-validation>
            </div>
        </div>
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('signColorId').showErrors }"
             *ngIf="selectColor && propertyConfiguration && propertyConfiguration.showSignColorList">
            <label class="col-4"
                   for="signColor">
                {{"task.signColor" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="signColorId"
                            id="signColor"
                            [panelStyle]="{'width':'100%'}"
                            [options]="signColorOptions"
                            [autoDisplayFirst]="false"
                            placeholder="{{'taskDetailTab.selectSignColor' | translate }}"
                            #signColorInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('signColorId')"
                                     [input]="signColorInput">
                </app-form-validation>
            </div>
        </div>
        <!--Length-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('length').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showLength && !hideLengthList">
            <label class="col-4"
                   for="length">
                {{currentSignCategory.lengthLabel | translateModel:translateService.currentLang | safeHtml | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="length"
                            id="length"
                            [panelStyle]="{'width':'100%'}"
                            [options]="lengthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleLengthChange($event)"
                            placeholder="{{'taskDetailTab.selectLength' | translate }}"
                            #lengthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('length')"
                                     [input]="lengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--length-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customLength').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showLength && displayCustomLength">
            <label class="col-4"
                   for="customLength">
                {{currentSignCategory.lengthLabel | translateModel:translateService.currentLang | safeHtml | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{currentSignCategory.lengthLabel | translateModel:translateService.currentLang | safeHtml | translate }}"
                           id="customLength"
                           formControlName="customLength"
                           #customLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lengthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customLength')"
                                     [input]="customLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--width-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('width').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showWidth && !hideWidthList">
            <label class="col-4"
                   for="width">
                {{ currentSignCategory.widthLabel | translateModel:translateService.currentLang | safeHtml | translate }}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="width"
                            id="width"
                            [panelStyle]="{'width':'100%'}"
                            [options]="widthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleWidthChange($event)"
                            placeholder="{{'taskDetailTab.selectWidth' | translate }}"
                            #widthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('width')"
                                     [input]="widthInput">
                </app-form-validation>
            </div>
        </div>
        <!--width-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customWidth').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showWidth && displayCustomWidth">
            <label class="col-4"
                   for="customWidth">
                {{ currentSignCategory.widthLabel | translateModel:translateService.currentLang | safeHtml | translate }}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{currentSignCategory.widthLabel | translateModel:translateService.currentLang | safeHtml | translate}}"
                           id="customWidth"
                           formControlName="customWidth"
                           #customWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.widthUnit)">
                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customWidth')"
                                     [input]="customWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--Short Piece Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('shortPieceCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showShortPieceCount">
            <label class="col-4"
                   for="shortPieceCount">
                {{"task.shortPieceCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.shortPieceCount' | translate}}"
                       id="shortPieceCount"
                       formControlName="shortPieceCount"
                       #shortPieceCountInput />
                <app-form-validation [control]="taskStatusForm.get('shortPieceCount')"
                                     [input]="shortPieceCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Rainbow-->
        <!--White Substrate length-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('whiteSubstrateLength').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showWhiteSubstrateLength">
            <label class="col-4"
                   for="whiteSubstrateLength">
                {{"task.whiteSubstrateLength" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.whiteSubstrateLength' | translate}}"
                           id="whiteSubstrateLength"
                           formControlName="whiteSubstrateLength"
                           #whiteSubstrateLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.whiteSubstrateLengthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('whiteSubstrateLength')"
                                     [input]="whiteSubstrateLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--White Substrate width-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('whiteSubstrateWidth').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showWhiteSubstrateWidth">
            <label class="col-4"
                   for="whiteSubstrateWidth">
                {{"task.whiteSubstrateWidth" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.whiteSubstrateWidth' | translate}}"
                           id="whiteSubstrateWidth"
                           formControlName="whiteSubstrateWidth"
                           #whiteSubstrateWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.whiteSubstrateWidthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('whiteSubstrateWidth')"
                                     [input]="whiteSubstrateWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--White Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('whiteBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showWhiteBandCount">
            <label class="col-4"
                   for="whiteBandCount">
                {{"task.whiteBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.whiteBandCount' | translate}}"
                       id="whiteBandCount"
                       formControlName="whiteBandCount"
                       #whiteBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('whiteBandCount')"
                                     [input]="whiteBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Red Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('redBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showRedBandCount">
            <label class="col-4"
                   for="redBandCount">
                {{"task.redBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.redBandCount' | translate}}"
                       id="redBandCount"
                       formControlName="redBandCount"
                       #redBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('redBandCount')"
                                     [input]="redBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Yellow Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('yellowBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showYellowBandCount">
            <label class="col-4"
                   for="yellowBandCount">
                {{"task.yellowBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.yellowBandCount' | translate}}"
                       id="yellowBandCount"
                       formControlName="yellowBandCount"
                       #yellowBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('yellowBandCount')"
                                     [input]="yellowBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--orange Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('orangeBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showOrangeBandCount">
            <label class="col-4"
                   for="orangeBandCount">
                {{"task.orangeBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.orangeBandCount' | translate}}"
                       id="orangeBandCount"
                       formControlName="orangeBandCount"
                       #orangeBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('orangeBandCount')"
                                     [input]="orangeBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Green Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('greenBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showGreenBandCount">
            <label class="col-4"
                   for="greenBandCount">
                {{"task.greenBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.greenBandCount' | translate}}"
                       id="greenBandCount"
                       formControlName="greenBandCount"
                       #greenBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('greenBandCount')"
                                     [input]="greenBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Blue Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('blueBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlueBandCount">
            <label class="col-4"
                   for="blueBandCount">
                {{"task.blueBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.blueBandCount' | translate}}"
                       id="blueBandCount"
                       formControlName="blueBandCount"
                       #blueBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('blueBandCount')"
                                     [input]="blueBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Purple Band Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('purpleBandCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showPurpleBandCount">
            <label class="col-4"
                   for="purpleBandCount">
                {{"task.purpleBandCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.purpleBandCount' | translate}}"
                       id="purpleBandCount"
                       formControlName="purpleBandCount"
                       #purpleBandCountInput />
                <app-form-validation [control]="taskStatusForm.get('purpleBandCount')"
                                     [input]="purpleBandCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--height-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('height').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showHeight && !hideHeightList">
            <label class="col-4"
                   for="height">
                {{"task.height" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="height"
                            id="height"
                            [panelStyle]="{'width':'100%'}"
                            [options]="heightOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleHeightChange($event)"
                            placeholder="{{'taskDetailTab.selectHeight' | translate }}"
                            #heightInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('height')"
                                     [input]="heightInput">
                </app-form-validation>
            </div>
        </div>
        <!--height-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customHeight').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showHeight && displayCustomHeight">
            <label class="col-4"
                   for="customHeight">
                {{"task.customHeight" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.customHeight' | translate}}"
                           id="customHeight"
                           formControlName="customHeight"
                           #customHeightInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.heightUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customHeight')"
                                     [input]="customHeightInput">
                </app-form-validation>
            </div>
        </div>
        <!--coloredLength-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('coloredLength').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showColoredLength && !hideColoredLengthList">
            <label class="col-4"
                   for="coloredLength">
                {{currentSignCategory.coloredLengthLabel | translateModel:translateService.currentLang | safeHtml | translate | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="coloredLength"
                            id="coloredLength"
                            [panelStyle]="{'width':'100%'}"
                            [options]="coloredLengthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleColoredLengthChange($event)"
                            placeholder="{{'taskDetailTab.selectLength' | translate }}"
                            #coloredLengthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('coloredLength')"
                                     [input]="coloredLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--coloredLength-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customColoredLength').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showColoredLength && displayCustomColoredLength">
            <label class="col-4"
                   for="customColoredLength">
                {{currentSignCategory.coloredLengthLabel | translateModel:translateService.currentLang | safeHtml | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{currentSignCategory.coloredLengthLabel | translateModel:translateService.currentLang | safeHtml | translate}}"
                           id="customColoredLength"
                           formControlName="customColoredLength"
                           #customColoredLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.coloredLengthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customColoredLength')"
                                     [input]="customColoredLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--coloredWidth-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('coloredWidth').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showColoredWidth && !hideColoredWidthList">
            <label class="col-4"
                   for="coloredWidth">
                {{ currentSignCategory.coloredWidthLabel | translateModel:translateService.currentLang | safeHtml}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="coloredWidth"
                            id="coloredWidth"
                            [panelStyle]="{'width':'100%'}"
                            [options]="coloredWidthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleColoredWidthChange($event)"
                            placeholder="{{'taskDetailTab.selectWidth' | translate }}"
                            #coloredWidthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('coloredWidth')"
                                     [input]="coloredWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--coloredWidth-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customColoredWidth').showErrors }"
             *ngIf="currentSignCategory && propertyConfiguration && propertyConfiguration.showColoredWidth && displayCustomColoredWidth">
            <label class="col-4"
                   for="customColoredWidth">
                {{currentSignCategory.coloredWidthLabel | translateModel:translateService.currentLang | safeHtml}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{currentSignCategory.coloredWidthLabel  | translateModel:translateService.currentLang | safeHtml}}"
                           id="customColoredWidth"
                           formControlName="customColoredWidth"
                           #customColoredWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.coloredWidthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customColoredWidth')"
                                     [input]="customColoredWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--text-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('text').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showText && !hideTextList">
            <label class="col-4"
                   for="text">
                {{"task.text" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="text"
                            id="text"
                            [panelStyle]="{'width':'100%'}"
                            [options]="textOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleTextChange($event)"
                            placeholder="{{'taskDetailTab.selectText' | translate }}"
                            #textInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('text')"
                                     [input]="textInput">
                </app-form-validation>
            </div>
        </div>
        <!--text-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customText').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showText && displayCustomText">
            <label class="col-4"
                   for="customText">
                {{"task.customText" | translate}}
            </label>
            <div class="col-8">
                <textarea id="customText"
                          class="p-inputtextarea"
                          placeholder="{{'task.customText' | translate}}"
                          rows="1"
                          formControlName="customText"
                          #customTextInput>
                    </textarea>
                <app-form-validation [control]="taskStatusForm.get('customText')"
                                     [input]="customTextInput">
                </app-form-validation>
            </div>
        </div>
        <!--diameter-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('diameter').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDiameter && !hideDiameterList">
            <label class="col-4"
                   for="diameter">
                {{"task.diameter" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="diameter"
                            id="diameter"
                            [panelStyle]="{'width':'100%'}"
                            [options]="diameterOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleDiameterChange($event)"
                            placeholder="{{'taskDetailTab.selectDiameter' | translate }}"
                            #diameterInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('diameter')"
                                     [input]="diameterInput">
                </app-form-validation>
            </div>
        </div>
        <!--diameter-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customDiameter').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDiameter && displayCustomDiameter">
            <label class="col-4"
                   for="customDiameter">
                {{"task.customDiameter" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.customDiameter' | translate}}"
                           id="customDiameter"
                           formControlName="customDiameter"
                           #customDiameterInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customDiameter')"
                                     [input]="customDiameterInput">
                </app-form-validation>

            </div>
        </div>
        <!--placenumber-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('placenumber').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showPlacenumber">
            <label class="col-4"
                   for="placenumber">
                {{"task.placenumber" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.placenumber' | translate}}"
                       id="placenumber"
                       formControlName="placenumber"
                       #placenumberInput />
                <app-form-validation [control]="taskStatusForm.get('placenumber')"
                                     [input]="placenumberInput">
                </app-form-validation>
            </div>
        </div>
        <!--speed-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('speed').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showSpeed && !hideSpeedList">
            <label class="col-4"
                   for="text">
                {{"task.speed" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="speed"
                            id="speed"
                            [panelStyle]="{'width':'100%'}"
                            [options]="speedOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleSpeedChange($event)"
                            placeholder="{{'taskDetailTab.selectSpeed' | translate }}"
                            #speedInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('speed')"
                                     [input]="speedInput">
                </app-form-validation>
            </div>
        </div>
        <!--speed-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customSpeed').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showSpeed && displayCustomSpeed">
            <label class="col-4"
                   for="customSpeed">
                {{"task.customSpeed" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.customSpeed' | translate}}"
                       id="customSpeed"
                       formControlName="customSpeed"
                       #customSpeedInput />
                <app-form-validation [control]="taskStatusForm.get('customSpeed')"
                                     [input]="customSpeedInput">
                </app-form-validation>
            </div>
        </div>
        <!--block1Amount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('block1Amount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock1Amount">
            <label class="col-4"
                   for="block1Amount">
                {{"task.block1Amount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.block1Amount' | translate}}"
                       id="block1Amount"
                       formControlName="block1Amount"
                       #block1AmountInput />
                <app-form-validation [control]="taskStatusForm.get('block1Amount')"
                                     [input]="block1AmountInput">
                </app-form-validation>
            </div>
        </div>
        <!-- block1Width -->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('block1Width').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock1Width && !hideBlock1WidthList">
            <label class="col-4"
                   for="block1Width">
                {{"task.block1Width" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="block1Width"
                            id="block1Width"
                            [panelStyle]="{'width':'100%'}"
                            [options]="block1WidthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleBlock1WidthChange($event)"
                            placeholder="{{'taskDetailTab.block1Width' | translate }}"
                            #block1WidthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('block1Width')"
                                     [input]="block1WidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--customBlock1Width-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customBlock1Width').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock1Width && displayCustomBlock1Width">
            <label class="col-4"
                   for="customBlock1Width">
                {{"task.block1Width" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.block1Width' | translate }}"
                           id="customBlock1Width"
                           formControlName="customBlock1Width"
                           #customBlock1WidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.blocksWidthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customBlock1Width')"
                                     [input]="customBlock1WidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--block2Amount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('block2Amount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock2Amount">
            <label class="col-4"
                   for="block2Amount">
                {{"task.block2Amount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.block2Amount' | translate}}"
                       id="block2Amount"
                       formControlName="block2Amount"
                       #block2AmountInput />
                <app-form-validation [control]="taskStatusForm.get('block2Amount')"
                                     [input]="block2AmountInput">
                </app-form-validation>
            </div>
        </div>
        <!-- block2Width -->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('block2Width').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock2Width && !hideBlock2WidthList">
            <label class="col-4"
                   for="block2Width">
                {{"task.block2Width" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="block2Width"
                            id="block2Width"
                            [panelStyle]="{'width':'100%'}"
                            [options]="block2WidthOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleBlock2WidthChange($event)"
                            placeholder="{{'taskDetailTab.block2Width' | translate }}"
                            #block2WidthInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('block2Width')"
                                     [input]="block2WidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--customBlock2Width-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customBlock2Width').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBlock2Width && displayCustomBlock2Width">
            <label class="col-4"
                   for="customBlock2Width">
                {{"task.block2Width" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.block2Width' | translate }}"
                           id="customBlock2Width"
                           formControlName="customBlock2Width"
                           #customBlock2WidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.blocksWidthUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customBlock2Width')"
                                     [input]="customBlock2WidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--lengthA-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('lengthA').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLengthA">
            <label class="col-4"
                   for="lengthA">
                {{"task.lengthA" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lengthA' | translate}}"
                           id="lengthA"
                           formControlName="lengthA"
                           #lengthAInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('lengthA')"
                                     [input]="lengthAInput">
                </app-form-validation>
            </div>
        </div>
        <!--lengthB-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('lengthB').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLengthB">
            <label class="col-4"
                   for="lengthB">
                {{"task.lengthB" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lengthB' | translate}}"
                           id="lengthB"
                           formControlName="lengthB"
                           #lengthBInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('lengthB')"
                                     [input]="lengthBInput">
                </app-form-validation>
            </div>
        </div>
        <!--lengthC-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('lengthC').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLengthC">
            <label class="col-4"
                   for="lengthC">
                {{"task.lengthC" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lengthC' | translate}}"
                           id="lengthC"
                           formControlName="lengthC"
                           #lengthCInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('lengthC')"
                                     [input]="lengthCInput">
                </app-form-validation>
            </div>
        </div>
        <!--lengthD-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('lengthD').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLengthD">
            <label class="col-4"
                   for="lengthD">
                {{"task.lengthD" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lengthD' | translate}}"
                           id="lengthD"
                           formControlName="lengthD"
                           #lengthDInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('lengthD')"
                                     [input]="lengthDInput">
                </app-form-validation>
            </div>
        </div>
        <!--dimension-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('dropdownDimension').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDimension && !hideDimensionList">
            <label class="col-4"
                   for="text">
                {{"task.dimension" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="dropdownDimension"
                            id="dropdownDimension"
                            [panelStyle]="{'width':'100%'}"
                            [options]="dimensionOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleDimensionChange($event)"
                            placeholder="{{'taskDetailTab.selectDimension' | translate }}"
                            #dropdownDimensionInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('dropdownDimension')"
                                     [input]="dropdownDimensionInput">
                </app-form-validation>
            </div>
        </div>
        <!--dimensionLength-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customDimensionLength').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDimension && displayCustomDimension">
            <label class="col-4"
                   for="customDimensionLength">
                {{"task.dimensionLength" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.dimensionLength' | translate}}"
                           id="customDimensionLength"
                           formControlName="customDimensionLength"
                           #customDimensionLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customDimensionLength')"
                                     [input]="customDimensionLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--dimensionWidth-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customDimensionWidth').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDimension && displayCustomDimension">
            <label class="col-4"
                   for="customDimensionWidth">
                {{"task.dimensionWidth" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.dimensionWidth' | translate}}"
                           id="customDimensionWidth"
                           formControlName="customDimensionWidth"
                           #customDimensionWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customDimensionWidth')"
                                     [input]="customDimensionWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--dimensionDescription-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customDimensionDescription').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showDimension && displayCustomDimension">
            <label class="col-4"
                   for="customDimensionDescription">
                {{"task.dimensionDescription" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.dimensionDescription' | translate}}"
                       id="customDimensionDescription"
                       formControlName="customDimensionDescription"
                       #customDimensionDescriptionInput />
                <app-form-validation [control]="taskStatusForm.get('customDimensionDescription')"
                                     [input]="customDimensionDescriptionInput">
                </app-form-validation>
            </div>
        </div>
        <!--lElement dimension-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('dropdownLElementDimension').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLElementDimension && !hideLElementDimensionList">
            <label class="col-4"
                   for="text">
                {{"task.lElementDimension" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="dropdownLElementDimension"
                            id="dropdownLElementDimension"
                            [panelStyle]="{'width':'100%'}"
                            [options]="lElementDimensionOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleLElementDimensionChange($event)"
                            placeholder="{{'taskDetailTab.selectLElementDimension' | translate }}"
                            #dropdownLElementDimensionInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('dropdownLElementDimension')"
                                     [input]="dropdownLElementDimensionInput">
                </app-form-validation>
            </div>
        </div>
        <!--lElementLength-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customLElementLength').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLElementDimension && displayCustomLDimension">
            <label class="col-4"
                   for="customLElementLength">
                {{"task.lElementLength" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lElementLength' | translate}}"
                           id="customLElementLength"
                           formControlName="customLElementLength"
                           #customLElementLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lElementUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customLElementLength')"
                                     [input]="customLElementLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--lElementWidth-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customLElementWidth').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLElementDimension && displayCustomLDimension">
            <label class="col-4"
                   for="customLElementWidth">
                {{"task.lElementWidth" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.lElementWidth' | translate}}"
                           id="customLElementWidth"
                           formControlName="customLElementWidth"
                           #customLElementWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.lElelmentUnit)">
                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customLElementWidth')"
                                     [input]="customLElementWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--lElementCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('lElementCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLElementCount">
            <label class="col-4"
                   for="lElementCount">
                {{"task.lElementCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.lElementCount' | translate}}"
                       id="lElementCount"
                       formControlName="lElementCount"
                       #tasksLElementCountInput />
                <app-form-validation [control]="taskStatusForm.get('lElementCount')"
                                     [input]="tasksLElementCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--tElement dimension-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('dropdownTElementDimension').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showTElementDimension && !hideTElementDimensionList">
            <label class="col-4"
                   for="text">
                {{"task.tElementDimension" | translate}}
            </label>
            <div class="col-8">
                <p-dropdown formControlName="dropdownTElementDimension"
                            id="dropdownTElementDimension"
                            [panelStyle]="{'width':'100%'}"
                            [options]="tElementDimensionOptions"
                            [autoDisplayFirst]="false"
                            (onChange)="handleTElementDimensionChange($event)"
                            placeholder="{{'taskDetailTab.selectTElementDimension' | translate }}"
                            #dropdownTElementDimensionInput>
                </p-dropdown>
                <app-form-validation [control]="taskStatusForm.get('dropdownTElementDimension')"
                                     [input]="dropdownTElementDimensionInput">
                </app-form-validation>
            </div>
        </div>
        <!--tElementLength-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customTElementLength').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showTElementDimension && displayCustomTDimension">
            <label class="col-4"
                   for="customTElementLength">
                {{"task.tElementLength" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.tElementLength' | translate}}"
                           id="customTElementLength"
                           formControlName="customTElementLength"
                           #customTElementLengthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.tElementUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customTElementLength')"
                                     [input]="customTElementLengthInput">
                </app-form-validation>
            </div>
        </div>
        <!--tElementWidth-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('customTElementWidth').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showTElementDimension && displayCustomTDimension">
            <label class="col-4"
                   for="customTElementWidth">
                {{"task.tElementWidth" | translate}}
            </label>
            <div class="col-8">
                <div class="m-input-group">
                    <input type="number"
                           class="p-inputtext"
                           min="0"
                           placeholder="{{'task.tElementWidth' | translate}}"
                           id="customTElementWidth"
                           formControlName="customTElementWidth"
                           #customTElementWidthInput />
                    <div class="m-input-group-addon"
                         [innerHtml]="getUnit(propertyConfiguration.tElementUnit)">

                    </div>
                </div>
                <app-form-validation [control]="taskStatusForm.get('customTElementWidth')"
                                     [input]="customTElementWidthInput">
                </app-form-validation>
            </div>
        </div>
        <!--tElementCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('tElementCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showTElementCount">
            <label class="col-4"
                   for="tElementCount">
                {{"task.tElementCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.tElementCount' | translate}}"
                       id="tElementCount"
                       formControlName="tElementCount"
                       #tasksTElementCountInput />
                <app-form-validation [control]="taskStatusForm.get('tElementCount')"
                                     [input]="tasksTElementCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--tramElementCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('tramElementCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showTRAMElementCount">
            <label class="col-4"
                   for="tramElementCount">
                {{"task.tramElementCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.tramElementCount' | translate}}"
                       id="tramElementCount"
                       formControlName="tramElementCount"
                       #tramElementCountInput />
                <app-form-validation [control]="taskStatusForm.get('tramElementCount')"
                                     [input]="tramElementCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--logoCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('logoCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showLogoCount">
            <label class="col-4"
                   for="logoCount">
                {{"task.logoCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.logoCount' | translate}}"
                       id="logoCount"
                       formControlName="logoCount"
                       #tasksLogoCountInput />
                <app-form-validation [control]="taskStatusForm.get('logoCount')"
                                     [input]="tasksLogoCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--bannerCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('bannerCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBannerCount">
            <label class="col-4"
                   for="bannerCount">
                {{"task.bannerCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.bannerCount' | translate}}"
                       id="bannerCount"
                       formControlName="bannerCount"
                       #tasksBannerCountInput />
                <app-form-validation [control]="taskStatusForm.get('bannerCount')"
                                     [input]="tasksBannerCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('markingsCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showMarkingsCount">
            <label class="col-4"
                   for="markingsCount">
                {{"task.markingsCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.count' | translate}}"
                       id="markingsCount"
                       formControlName="markingsCount"
                       #markingsCountInput />
                <app-form-validation [control]="taskStatusForm.get('markingsCount')"
                                     [input]="markingsCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--hoursCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('hoursCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showHoursCount">
            <label class="col-4"
                   for="hoursCount">
                {{"task.hoursCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.hoursCount' | translate}}"
                       id="hoursCount"
                       formControlName="hoursCount"
                       #hoursCountInput />
                <app-form-validation [control]="taskStatusForm.get('hoursCount')"
                                     [input]="hoursCountInput">
                </app-form-validation>
            </div>
        </div>

        <!--minutesCount-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('minutesCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showMinutesCount">
            <label class="col-4"
                   for="minutesCount">
                {{"task.minutesCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.minutesCount' | translate}}"
                       id="minutesCount"
                       formControlName="minutesCount"
                       #minutesCountInput />
                <app-form-validation [control]="taskStatusForm.get('minutesCount')"
                                     [input]="minutesCountInput">
                </app-form-validation>
            </div>
        </div>

        <!--Dropzone-->
        <!--Bend Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('bendCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showBendCount">
            <label class="col-4"
                   for="bendCount">
                {{"task.bendCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.bendCount' | translate}}"
                       id="bendCount"
                       formControlName="bendCount"
                       #bendCountInput />
                <app-form-validation [control]="taskStatusForm.get('bendCount')"
                                     [input]="bendCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Arrowhead Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('arrowHeadCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showArrowHeadCount">
            <label class="col-4"
                   for="arrowHeadCount">
                {{"task.arrowHeadCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.arrowHeadCount' | translate}}"
                       id="arrowHeadCount"
                       formControlName="arrowHeadCount"
                       #arrowHeadCountInput />
                <app-form-validation [control]="taskStatusForm.get('arrowHeadCount')"
                                     [input]="arrowHeadCountInput">
                </app-form-validation>
            </div>
        </div>
        <!--Rounded end Count-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('roundedEndCount').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showRoundedEndCount">
            <label class="col-4"
                   for="roundedEndCount">
                {{"task.roundedEndCount" | translate}}
            </label>
            <div class="col-8">
                <input type="number"
                       class="p-inputtext"
                       min="0"
                       placeholder="{{'task.roundedEndCount' | translate}}"
                       id="roundedEndCount"
                       formControlName="roundedEndCount"
                       #roundedEndCountInput />
                <app-form-validation [control]="taskStatusForm.get('roundedEndCount')"
                                     [input]="roundedEndCountInput">
                </app-form-validation>
            </div>
        </div>


        <!--note-->
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': taskStatusForm.get('note').showErrors }"
             *ngIf="propertyConfiguration && propertyConfiguration.showNote">
            <label class="col-4"
                   for="note">
                {{"task.note" | translate}}
            </label>
            <div class="col-8">
                <textarea id="note"
                          class="p-inputtext"
                          placeholder="{{'task.note' | translate}}"
                          rows="2"
                          formControlName="note"
                          #noteInput>
                    </textarea>
                <app-form-validation [control]="taskStatusForm.get('note')"
                                     [input]="noteInput">
                </app-form-validation>
            </div>
        </div>
    </form>
</form>

<div class="m-btn-group m-btn-group-right m-border-top px-16 py-8"
     *ngIf="canCreateTask">
    <button class="m-btn m-btn-link"
            (click)="reset()"
            type="button">
        <ng-container *ngIf="!isCreatingNewTask(); else cancelText">
            {{"form.reset" | translate}}
        </ng-container>
        <ng-template #cancelText>
            {{"form.cancel" | translate}}
        </ng-template>
    </button>
    <button [disabled]="submitting"
            class="m-btn m-btn-primary"
            (click)="submit()"
            type="submit">
        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>
        {{"form.save" | translate}}
    </button>
</div>