import { IJournalCategory, IJournalSubCategory, JournalSubCategorySaver } from "src/app/models/journal";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { UntypedFormBuilder } from "@angular/forms";
import { JournalSubCategoryApi } from "src/app/resource/journal-sub-category.api";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { SigncoFormGroup } from "src/app/models/form";
import { AfterViewInit, ChangeDetectorRef, Component } from "@angular/core";
import { DomainDataService } from "src/app/services/domain-data.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-manage-journal-sub-category-dialog",
    templateUrl: "./manage-journal-sub-category-dialog.component.html"
})
export class ManageJournalSubCategoryDialogComponent extends DialogComponentBase {

    submitting: boolean;
    callback: (res: IJournalSubCategory) => void;
    manageSubCategoryForm: SigncoFormGroup;
    existingSubCategory: IJournalSubCategory;

    parentCategory: IJournalCategory;

    constructor(
        readonly formValidationService: FormValidationService,
        readonly translateService: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly journalSubCategoryApi: JournalSubCategoryApi,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly domainDataService: DomainDataService) {

        super();
    }

    create(parentCategory: IJournalCategory, callback?: (result: IJournalSubCategory) => void) {
        this.callback = callback;
        this.existingSubCategory = null;
        this.parentCategory = parentCategory;

        this.openDialog();
    }

    edit(existingSubCategory: IJournalSubCategory, callback?: (result: IJournalSubCategory) => void) {
        this.callback = callback;
        this.existingSubCategory = existingSubCategory;
        this.parentCategory = null;

        this.openDialog();
    }

    protected async onOpen() {
        const translationFormGroup = await this.domainDataService.createTranslationFormGroup(this.existingSubCategory ? this.existingSubCategory.codeStringResourceId : null, true);

        this.manageSubCategoryForm = this.formBuilder.group({
            codeStringResource: translationFormGroup,
            description: [""]
        }) as SigncoFormGroup;

        if (this.existingSubCategory) {
            this.manageSubCategoryForm.patchValue(this.existingSubCategory);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageSubCategoryForm = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageSubCategoryForm);
        if (!isValid) {
            return;
        }

        this.submitting = true;

        const onSuccess = (savedCategory: IJournalSubCategory) => {
            // Reload translations
            this.domainDataService.reload();

            if (this.existingSubCategory) {
                Object.assign(this.existingSubCategory, savedCategory);
            }

            if (this.callback) {
                this.callback(savedCategory);
            }

            this.domainModelFilterService.clearCache$();

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingSubCategory) {
            // new
            const newSubcategory = Object.assign({}, this.manageSubCategoryForm.value) as JournalSubCategorySaver;
            newSubcategory.categoryId = this.parentCategory.id;
            this.journalSubCategoryApi.create$(newSubcategory).subscribe(onSuccess, onError);
        } else {
            // update
            const updatedSubCategory = Object.assign(this.existingSubCategory, this.manageSubCategoryForm.value) as JournalSubCategorySaver;
            updatedSubCategory.categoryId = this.existingSubCategory.category.id;
            this.journalSubCategoryApi.update$(updatedSubCategory).subscribe(onSuccess, onError);
        }
    }
}
