import { ChangeDetectorRef, Component, SimpleChanges } from "@angular/core";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { MeasuredDataDisplayOptions } from "../measured-data-pinned-data/measured-data-pinned-data.component";
import { cloneDeep } from "lodash";
import { DataSetType, IPinnedDataConfigurationPerAnalysisType, LiveDataRangeOption, PinnedDataOption } from "src/app/models/pinned-data";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { TwoLevelSelectOption, TwoLevelSelectValue } from "src/app/modules/shared/components/two-level-select/two-level-select.component";
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { SelectedDataSetType } from "src/app/resource/web";

const lastHours = [1, 2, 3, 6, 12];
const lastDays = [1, 3, 7];

class RangeSelectItemValue {
    lastHoursCount?: number;
    lastDaysCount?: number;

    public equals(other: RangeSelectItemValue): boolean {
        return (
            this.lastHoursCount === other.lastHoursCount &&
            this.lastDaysCount === other.lastDaysCount
        );
    }
}

class PinnedDataOptionSelection {
    constructor(option: PinnedDataOption, dataSetTypes: DataSetType[]) {
        this.option = option;

        for (const availableDataSetType of dataSetTypes) {
            this.dataSetTypes.push(new DataSetTypeOptionSelection(availableDataSetType));
        }
    }

    option: PinnedDataOption;
    dataSetTypes: DataSetTypeOptionSelection[] = [];

    get checked(): boolean {
        return this.dataSetTypes?.all(x => x.isSelected);
    }
}

class DataSetTypeOptionSelection {
    constructor(dataSetType: DataSetType) {
        this.dataSetType = dataSetType;
    }

    dataSetType: DataSetType;
    isSelected = false;
}

@Component({
    selector: "app-measured-data-manage-dialog",
    templateUrl: "./measured-data-manage-dialog.component.html"
})
export class MeasuredDataManageDialogComponent extends DialogComponentBase {

    public configuration: IPinnedDataConfigurationPerAnalysisType;
    public viewModel: MeasuredDataDisplayOptions;
    private callback: (options: MeasuredDataDisplayOptions) => void;
    private onFirstCloseWithoutSubmit: () => void;

    public dataOptions: PinnedDataOptionSelection[];

    public liveDataRangeOptions: ViewModelEnum[];

    rangeTwoLevelSelectOptions: TwoLevelSelectOption[];
    rangeSelectItems: SelectItem[];
    aggregationTypes: ViewModelEnum[];

    validationWarning: string;
    historicalAndLiveDataNotSelected = false;

    firstTimeClosing = true;
    submitPressed: boolean;

    constructor(
        private readonly domainDataService: DomainDataService,
        private readonly translateService: TranslateService,
        private readonly changeDetector: ChangeDetectorRef) {
        super();
    }

    public async open(configuration: IPinnedDataConfigurationPerAnalysisType, viewModel: MeasuredDataDisplayOptions, callback?: (options: MeasuredDataDisplayOptions) => void,
        onFirstCloseWithoutSubmit?: () => void) {
        this.validationWarning = null;
        this.historicalAndLiveDataNotSelected = false;

        this.configuration = configuration;
        this.callback = callback;
        this.onFirstCloseWithoutSubmit = onFirstCloseWithoutSubmit;

        await this.initializeControls();

        if (!viewModel) {
            // We create some default options
            this.viewModel = new MeasuredDataDisplayOptions();
            this.viewModel.showLiveData = true;
            this.viewModel.showHistoricalData = true;
        } else {
            // We work on a clone. That way, if the user cancels, we can forget about the results.
            this.viewModel = cloneDeep(viewModel);
        }

        // We fill in missing data
        if (!this.viewModel.liveDataRange) {
            this.viewModel.liveDataRange = this.liveDataRangeOptions[0].value as LiveDataRangeOption;
        }

        if (!this.viewModel.historicalDataRange) {
            const range = this.rangeTwoLevelSelectOptions?.at(2); // Default is 3 hours
            const rangeAsSelectItem = this.rangeSelectItems.find(x => x.value === range.value);
            const aggregation = this.rangeTwoLevelSelectOptions?.takeFirstOrDefault()?.relations?.takeLastOrDefault(); // taking last to have shortest aggregation bucket

            this.viewModel.historicalDataRange = new TwoLevelSelectValue();
            this.viewModel.historicalDataRange.option1 = rangeAsSelectItem.value;
            this.viewModel.historicalDataRange.option2 = aggregation.value;
        } else {
            // This is a hack because the two-way data binding does not work well.
            // The control is bound to this.viewModel.historicalDataRange, but it will not update itself correctly.
            // I think this is because option1 is an object itself, and the system does reference comparison instead of value comparison
            const typedOption = Object.assign(new RangeSelectItemValue(), this.viewModel.historicalDataRange.option1);
            const option1 = this.rangeSelectItems.find(x => typedOption.equals(x.value)).value;
            const option2 = this.aggregationTypes.find(x => x.value == this.viewModel.historicalDataRange.option2).value;

            this.viewModel.historicalDataRange = new TwoLevelSelectValue();
            this.viewModel.historicalDataRange.option1 = option1;
            this.viewModel.historicalDataRange.option2 = option2;
        }

        if (!this.viewModel.dataOptions || this.viewModel.dataOptions.length === 0) {
            this.viewModel.dataOptions = [];
            this.viewModel.dataOptions.push(...this.configuration.supportedOptions[0].availableDataSetTypes.map(x => {
                return {
                    option: this.configuration.supportedOptions[0].option,
                    dataSetType: x as DataSetType
                } as SelectedDataSetType;
            }));
        }

        for (const selectedDataSetType of this.viewModel.dataOptions) {
            const uiDataSetTypeOption = this.dataOptions.find(x => x.option === selectedDataSetType.option).dataSetTypes.find(x => x.dataSetType === selectedDataSetType.dataSetType);
            uiDataSetTypeOption.isSelected = true;
        }

        this.openDialog();
    }

    private async initializeControls() {
        this.dataOptions = [];
        for (const option of this.configuration.supportedOptions) {
            this.dataOptions.push(new PinnedDataOptionSelection(option.option, option.availableDataSetTypes));
        }

        this.liveDataRangeOptions = await this.domainDataService.get(DomainData.LiveDataRangeOption, { orderBy: null });

        this.rangeTwoLevelSelectOptions = [];

        for (const hour of lastHours) {
            const value = {
                lastHoursCount: hour
            } as RangeSelectItemValue;

            this.rangeTwoLevelSelectOptions.push({
                value: value,
                label: hour === 1 ? this.translateService.instant("liveTiles.measuredData.lastHour") : this.translateService.instant("liveTiles.measuredData.lastHours", { hour: hour })
            } as TwoLevelSelectOption);
        }

        for (const day of lastDays) {
            const value = {
                lastDaysCount: day
            } as RangeSelectItemValue;

            this.rangeTwoLevelSelectOptions.push({
                value: value,
                label: day === 1 ? this.translateService.instant("liveTiles.measuredData.lastDay") : this.translateService.instant("liveTiles.measuredData.lastDays", { day: day })
            } as TwoLevelSelectOption);
        }

        this.aggregationTypes = await this.domainDataService.get(DomainData.TimeAggregationType, { orderBy: null });

        for (const rangeSelectItem of this.rangeTwoLevelSelectOptions) {
            rangeSelectItem.relations = [];
            if ((<RangeSelectItemValue>(rangeSelectItem.value)).lastDaysCount) {
                rangeSelectItem.relations.push(...this.aggregationTypes);
            } else if ((<RangeSelectItemValue>(rangeSelectItem.value)).lastHoursCount) {
                if ((<RangeSelectItemValue>(rangeSelectItem.value)).lastHoursCount > 1) {
                    rangeSelectItem.relations.push(...this.aggregationTypes.filter(x => x.value !== "day"));
                } else {
                    rangeSelectItem.relations.push(...this.aggregationTypes.filter(x => x.value !== "day" && x.value !== "hour"));
                }
            }
        }

        this.rangeSelectItems = this.rangeTwoLevelSelectOptions.map(x => x as SelectItem);
    }

    dataOptionChanged(changes: SimpleChanges): void {
        const selected = this.dataOptions.filter(x => x.dataSetTypes.filter(x => x.isSelected).length > 0).length;

        if (selected === 0) this.validationWarning = this.translateService.instant("liveTiles.measuredData.atLeastOneOption");
        else if (selected > 2) this.validationWarning = this.translateService.instant("liveTiles.measuredData.twoPinnedDataOptionsOnly");
        else this.validationWarning = null;

        this.changeDetector.detectChanges();
    }

    previewTypeChanged(): void {
        this.historicalAndLiveDataNotSelected = !this.viewModel.showHistoricalData && !this.viewModel.showLiveData;
        this.changeDetector.detectChanges();
    }

    public submit() {
        this.submitPressed = true;
        this.close();

        this.viewModel.dataOptions = [];
        for (const option of this.dataOptions.filter(x => x.dataSetTypes.filter(x => x.isSelected).length > 0)) {
            this.viewModel.dataOptions.push(...option.dataSetTypes.filter(x => x.isSelected).map(x => {
                return {
                    option: option.option,
                    dataSetType: x.dataSetType
                } as SelectedDataSetType;
            }));
        }

        this.callback(this.viewModel);
    }

    protected override onClose(): void {
        // if after openning live-tile submit is not pressed on dialog
        // close live-tile completely
        // if this is triggered by submit button -> do nothing
        if (this.firstTimeClosing && !this.submitPressed && this.onFirstCloseWithoutSubmit) {
            this.onFirstCloseWithoutSubmit();
        }

        this.firstTimeClosing = false;
    }

    togglePinnedDataOption(pinnedDataOption: PinnedDataOptionSelection) {
        const newState = !this.isPinnedDataOptionChecked(pinnedDataOption);

        for (const dataSetType of pinnedDataOption.dataSetTypes) {
            dataSetType.isSelected = newState;
        }
    }

    isPinnedDataOptionChecked(pinnedDataOption: PinnedDataOptionSelection): boolean {
        return pinnedDataOption.checked;
    }
}
