import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { Component, OnDestroy } from "@angular/core";
import { ViewModelEnum } from "src/app/models/domain-data";
import { DomainData, DomainDataService } from "src/app/services/domain-data.service";
import { VmsImageEditorService } from "../../services/vms-image-editor.service";
import { IVmsImageVariant } from "src/app/models/vms-image-variant";
import { VmsTypeValue } from "src/app/models/measuring-point";

@Component({
    selector: "app-vms-image-editor-copyfrom-dialog",
    templateUrl: "./vms-image-editor-copyfrom.dialog.html"
})
export class VmsImageEditorCopyFromDialogComponent extends DialogComponentBase implements OnDestroy {
    submitting: boolean;
    callback: (res: string) => void;
    selectedVmsTypeId: string;
    vmsImageVariants: IVmsImageVariant[];

    vmsTypeValues: ViewModelEnum[];
    allVmsTypeValues: ViewModelEnum[];
    constructor(private readonly domainDataService: DomainDataService,
        private readonly vmsImageEditorService: VmsImageEditorService) {
        super();
        this.domainDataService.get(DomainData.VmsTypeValue).then(x => {
            this.allVmsTypeValues = x;
            this.vmsTypeValues = [];
        });
        const vmsImageVariantsChangeSubscription = this.vmsImageEditorService.vmsImageVariants$.subscribe(async (vmsImageVariants) => {
            this.vmsImageVariants = vmsImageVariants;
        });
        this.subscriptionManager.add("vmsImageVariantsChangeSubscription", vmsImageVariantsChangeSubscription);

    }
    ngOnDestroy() {
        this.subscriptionManager.clear();
    }
    create(currentVmsTypeValue: VmsTypeValue) {
        this.selectedVmsTypeId = null;
        this.vmsTypeValues = [];
        this.allVmsTypeValues.forEach(x => {
            if (this.vmsImageVariants && this.vmsImageVariants.some(y => y.vmsType.typeId === x.value && y.editorData && y.editorData !== "")
                && x.value !== currentVmsTypeValue) {
                const viewModelEnum = new ViewModelEnum(x.type, x.value, x.label, x.language);
                this.vmsTypeValues.push(viewModelEnum);
            }
        });
        this.openDialog();
    }
    protected onClose() {
        this.submitting = false;
    }

    async submit() {
        this.submitting = true;
        this.vmsImageEditorService.setCopyFromVmsTypeId(this.selectedVmsTypeId);
        this.close();
        this.submitting = false;
    }
}