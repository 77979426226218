import { MeasuringPointStatusApi } from "src/app/resource/measuring-point-status.api";
import { IMeasuringPointStatus, IMeasuringPointStatusData } from "src/app/models/measuring-point-status";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { ToastService } from "src/app/services/toast.service";
import { Component } from "@angular/core";
import { ITotemDisplayMode } from "src/app/models/totem-display-configuration";
import { MeasuringPointApi } from "src/app/resource/measuring-point.api";
import { IMeasuringPoint } from "src/app/models/measuring-point";

// class VmsModeViewModel {
//     public mode: IVmsMode;
// }

@Component({
    selector: "app-totem-display-manage-dialog",
    templateUrl: "./totem-display-manage-dialog.component.html"
})
export class TotemDisplayManageDialogComponent extends DialogComponentBase {

    measuringPointId: number;
    measuringPoint: IMeasuringPoint;
    modes: ITotemDisplayMode[];
    isSaving = false;
    loading = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly measuringPointStatusApi: MeasuringPointStatusApi,
        private readonly measuringPointApi: MeasuringPointApi
    ) {
        super();
    }

    public async open(measuringPointId: number) {
        this.measuringPointId = measuringPointId;
        this.openDialog();

        this.loading = true;
        this.measuringPoint = await this.measuringPointApi.get$(this.measuringPointId).toPromise();
        this.modes = this.measuringPoint.totemDisplayConfiguration.modeConfiguration.modes;
        this.loading = false;
    }

    async onSelect(mode: ITotemDisplayMode) {
        const onSuccess = async (_: IMeasuringPointStatus) => {
            this.toastService.saveSuccess();
            this.isSaving = false;
            this.close();
        };

        const onError = () => {
            this.isSaving = false;
        };

        this.isSaving = true;

        const data = {
            totemDisplay: {
                currentMode: mode,
                fieldConfigurations: this.measuringPoint.totemDisplayConfiguration.fields
            }
        } as IMeasuringPointStatusData;
        const status = {
            measuringPointId: this.measuringPointId,
            timestamp: new Date(),
            data: data
        } as IMeasuringPointStatus;

        this.measuringPointStatusApi.create$(status, { measuringPointId: this.measuringPointId.toString() })
            .subscribe({
                next: onSuccess,
                error: onError
            });
    }
}