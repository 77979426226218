<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingLoginNotification ? "loginNotifications.edit" : "loginNotifications.create") | translate}}
    </p-header>

    <form *ngIf="manageLoginNotificationForm"
          class="p-16"
          [formGroup]="manageLoginNotificationForm"
          autocomplete="off"
          novalidate>

        <app-model-translation-form [parentFormGroup]="manageLoginNotificationForm"
                                    formGroupName="message"
                                    controlLabel="loginNotifications.message"
                                    multiline="true"
                                    autofocus="true"></app-model-translation-form>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>