import { ChartConfiguration, ChartData, ChartOptions, ScaleOptionsByType, TooltipItem } from "chart.js";
import { Component, Input, OnChanges, SimpleChanges, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { CalendarSettings, DateFormControl, PrimeComponentService } from "src/app/services/prime-component.service";
import { IDeviceLastLocationsCompared } from "src/app/models/device";
import { IBatteryHistory, IDevice } from "src/app/models/device";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidationService } from "src/app/services/form-validation.service";
import { SubscriptionManager } from "src/app/utilities";
import { TranslateService } from "@ngx-translate/core";
import { SigncoFormGroup } from "src/app/models/form";
import { DataDaysService } from "src/app/services/data-days.service";
import { MinMaxValidator } from "src/app/validators/min-max.validator";
import { ChartComponent } from "src/app/modules/shared/components/signco-chart/signco-chart.component";
import { DownloadedFile } from "src/app/services/download-file.service";
import { IDateFilter } from "src/app/models/report-type";
import { DeviceApi } from "src/app/resource/device.api";
import { Calendar } from "primeng/calendar";


@Component({
    selector: "app-device-status-overview",
    templateUrl: "./device-status-overview.component.html"
})
export class DeviceStatusOverviewComponent implements OnChanges, OnChanges, OnDestroy {
    @Input() device: IDevice;
    @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;


    chartConfiguration: ChartConfiguration;
    locationHistoryImage: File;
    locationHistoryImagePreview: string;
    lastLocationsCompared: IDeviceLastLocationsCompared;
    showComparation: boolean;
    statusForm: SigncoFormGroup;
    loading: boolean;

    calendarSettings: CalendarSettings;
    rangeFromDateControl: DateFormControl;
    rangeUntilDateControl: DateFormControl;
    maxFromDate: Date = new Date();

    @ViewChild("rangeFromDateInput", { static: false }) set setRangeFromCalendar(calendar: Calendar) {
        this.rangeFromDateControl.setCalendar(calendar);
    }

    @ViewChild("rangeUntilDateInput", { static: false }) set setRangeUntilCalendar(calendar: Calendar) {
        this.rangeUntilDateControl.setCalendar(calendar);
    }

    private subscriptionManager = new SubscriptionManager();


    constructor(
        elementRef: ElementRef,
        readonly translateService: TranslateService,
        private readonly deviceApi: DeviceApi,
        private readonly formBuilder: UntypedFormBuilder,
        readonly primeComponentService: PrimeComponentService,
        readonly dataDaysService: DataDaysService,
        readonly formValidationService: FormValidationService) {

        this.dataDaysService.clear();

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-default");

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);

        this.rangeFromDateControl = new DateFormControl(null, null);
        this.rangeFromDateControl.setDateSelectionMode("single");
        this.rangeFromDateControl.setValidators(Validators.required);

        this.rangeUntilDateControl = new DateFormControl(null, null);
        this.rangeUntilDateControl.setDateSelectionMode("single");

        MinMaxValidator.create(this.rangeFromDateControl, this.rangeUntilDateControl);

        this.statusForm = this.formBuilder.group({
            from: this.rangeFromDateControl,
            until: this.rangeUntilDateControl
        }) as SigncoFormGroup;

        this.reset();
    }

    ngOnChanges(changes: SimpleChanges) {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(deviceChange.currentValue);
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.lastLocationsCompared = null;
        // we are doing this to fetch always the last sent location even when we're changing from one device to another
        this.reset();
        this.dataDaysService.addDevices([device.id]);
        this.initialize();
    }

    private initialize() {
        this.loadData();
    }

    private loadData() {
        this.setLoading(true);
        this.loadChart();
        this.loadLocationHistoryImage();
    }

    private getDateFilter(): IDateFilter {
        return {
            include: {
                range: {
                    from: (this.rangeFromDateControl.value as Date).toMetanousDateString(),
                    until: (this.rangeUntilDateControl.value as Date)?.toMetanousDateString()
                }
            }
        } as IDateFilter;
    }

    private loadLocationHistoryImage(): void {
        const dateFilter = this.getDateFilter();
        this.showComparation = false;

        const onSuccess = (downloadedFile: DownloadedFile) => {
            this.locationHistoryImage = downloadedFile.file;
            this.locationHistoryImagePreview = downloadedFile.toResourceUrl();



            let until = this.rangeUntilDateControl.value as Date;
            if (until) {
                until = new Date(until.getFullYear(), until.getMonth(), until.getDate(), 23, 59, 59);
            }

            // if location history map is loaded and range until is not set, we need to show comparation
            if ((!this.lastLocationsCompared) || (!until) || until.getTime() >= new Date().getTime()
                || until.getTime() >= this.lastLocationsCompared.timestampGps.getTime()) {
                // no sense to send request everytime
                if (!this.lastLocationsCompared) {
                    const onSuccessComparation = (comparation: IDeviceLastLocationsCompared) => {
                        this.lastLocationsCompared = comparation;
                        this.showComparation = true;
                        this.setLoading(false);
                    };

                    this.deviceApi.getLastLocationsCompared$(this.device.id, dateFilter).subscribe({ next: onSuccessComparation, error: onError });
                    return;
                }

                this.showComparation = true;
                this.setLoading(false);
                return;
            }

            this.showComparation = false;
            this.setLoading(false);
        };

        const onError = () => {
            this.showComparation = false;
            this.setLoading(false);
        };

        this.deviceApi.getLocationHistoryImage$(this.device.id, dateFilter).then(onSuccess).catch(onError);
    }

    private setLoading(loading: boolean) {
        this.loading = loading;

        if (this.loading) {
            this.rangeFromDateControl.disable();
            this.rangeUntilDateControl.disable();
        } else {
            this.rangeFromDateControl.enable();
            this.rangeUntilDateControl.enable();
        }
    }

    private loadChart() {
        if (!this.device) {
            this.clear();
            return;
        }

        const onSuccess = (batteryHistory: IBatteryHistory[]) => {
            const dates = batteryHistory.map(x => x.timestamp);

            const voltages = batteryHistory.map(x => x.batteryVoltage);
            const showVoltage = voltages.length > 0;
            const maxVoltage = Math.ceil(voltages.max());

            const charges = batteryHistory.map(x => x.chargePercentage);
            const showChargePercentage = charges.length > 0;

            if (!showVoltage && !showChargePercentage) {
                this.chartConfiguration = null;
                return; // don't create chart if there is no data to show
            }

            const chartData = {
                labels: dates.map(x => x.toISOString()),
                datasets: [
                ]
            } as ChartData;

            if (showVoltage) {
                chartData.datasets.push({
                    label: this.translateService.instant("measurements.volt"),
                    data: voltages,
                    borderColor: "rgba(255, 0, 0, 1)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgba(255, 0, 0, 1)",
                    pointRadius: 0,
                    yAxisID: "yVoltage"
                });
            }

            if (showChargePercentage) {
                chartData.datasets.push({
                    label: "%",
                    data: charges,
                    borderColor: "rgba(0, 255, 0, 1)",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgba(0, 255, 0, 1)",
                    pointRadius: 0,
                    yAxisID: "yPercentage"
                });
            }

            const chartOptions = {} as ChartOptions<"line">;
            chartOptions.showLine = true;
            chartOptions.scales = {};
            chartOptions.plugins = {
                legend: {
                    display: false
                }
            };

            chartOptions.plugins.tooltip = {};
            chartOptions.plugins.tooltip.enabled = true;
            chartOptions.plugins.tooltip.mode = "index";
            chartOptions.plugins.tooltip.intersect = false;
            chartOptions.plugins.tooltip.callbacks = {
                title: (tooltipItems: TooltipItem<"line">[]) => {
                    // example: 2018-12-30T20:00:00.000
                    return tooltipItems[0].label.toString().replaceAll("-", "/").replace("T", " ").replace(":00.000", "");
                },
                label: (tooltipItem: TooltipItem<"line">) => {
                    return ((tooltipItem.raw as number)?.toFixed(2) ?? "") + tooltipItem.dataset.label;
                }
            };

            chartOptions.hover = {};
            chartOptions.hover.mode = "index";
            chartOptions.hover.intersect = false;

            if (showVoltage) {
                const voltageAxes = {} as ScaleOptionsByType<"linear">;
                voltageAxes.type = "linear";
                voltageAxes.position = "left";
                voltageAxes.beginAtZero = true;
                voltageAxes.min = 0;
                voltageAxes.max = maxVoltage;
                voltageAxes.ticks = {} as any;
                voltageAxes.ticks.stepSize = 1;
                voltageAxes.ticks.minRotation = 0;
                voltageAxes.ticks.maxRotation = 0;
                voltageAxes.ticks.precision = 0;
                voltageAxes.ticks.callback = (value, index, values) => {
                    return `${value}V`;
                };

                chartOptions.scales.yVoltage = voltageAxes;
            }

            if (showChargePercentage) {
                const percentageAxes = {} as ScaleOptionsByType<"linear">;
                percentageAxes.type = "linear";
                percentageAxes.position = showVoltage ? "right" : "left";
                percentageAxes.beginAtZero = true;
                percentageAxes.min = 0;
                percentageAxes.max = 100;
                percentageAxes.ticks = {} as any;
                percentageAxes.ticks.stepSize = 10;
                percentageAxes.ticks.minRotation = 0;
                percentageAxes.ticks.maxRotation = 0;
                percentageAxes.ticks.precision = 0;
                percentageAxes.ticks.callback = (value, index, values) => {
                    return `${value}%`;
                };

                chartOptions.scales.yPercentage = percentageAxes;
            }

            const xAxes = {} as ScaleOptionsByType<"time">;
            xAxes.type = "time";
            xAxes.stacked = false;

            xAxes.time = {} as any;
            xAxes.time.unit = "day";
            xAxes.time.tooltipFormat = this.translateService.currentLang === "nl" ? "ddd DD/MM/YYYY HH:mm" : "ddd MM/DD/YYYY HH:mm";
            xAxes.time.displayFormats = {};
            xAxes.time.displayFormats.minute = "HH:mm";
            xAxes.time.displayFormats.hour = "MMM D, H";
            xAxes.time.displayFormats.day = "MMM D";

            xAxes.ticks = {} as any;
            xAxes.ticks.stepSize = 1;
            xAxes.ticks.maxRotation = 0;
            xAxes.ticks.autoSkip = true;
            xAxes.ticks.autoSkipPadding = 50;
            xAxes.ticks.minRotation = 0;
            xAxes.ticks.maxRotation = 0;
            xAxes.ticks.sampleSize = 5;

            chartOptions.scales.x = xAxes;

            this.chartConfiguration = {
                type: "line",
                data: chartData,
                options: chartOptions
            } as ChartConfiguration;
        };

        const onError = () => { };

        this.deviceApi.getBatteryHistory$(this.device.id, this.getDateFilter()).subscribe(onSuccess, onError);
    }

    private clear() {
        this.chartConfiguration = null;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.statusForm);
        if (!isValid) {
            return;
        }

        this.loadData();
    }

    reset(): void {
        this.rangeFromDateControl.setValue(new Date().addDays(-31));
        this.rangeUntilDateControl.reset();
    }
}
