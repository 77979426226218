import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QrComponent } from "./components/qr/qr.component";

const routes: Routes = [
    {
        path: ":code", component: QrComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class QrRoutingModule { }