import { IMeasuringPointSummary } from "src/app/models/web";
import { WebApiService } from "./web-api";
import { Injectable } from "@angular/core";
import { ISearchResult } from "src/app/models/search";
import { firstValueFrom } from "rxjs";
import { MeasuringPointSearchParameters } from "src/app/models/web";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointWebApi extends WebApiService<IMeasuringPointSummary> {
    getRoute(): string {
        return "MeasuringPoints";
    }

    // Typed implementation of the base method that can be awaited
    async search(parameters: MeasuringPointSearchParameters): Promise<ISearchResult<IMeasuringPointSummary>> {
        const url = this.getUrl();
        const params = parameters.getHttpParams();
        const obs = this.http.get<ISearchResult<IMeasuringPointSummary>>(url, { params: params });
        const result = await firstValueFrom(obs);
        return result;
    }
}
