<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="canCreateMeasuringPoint">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"mapIcons.create" | translate}}
        </button>
    </div>

    <app-map-icons class="m-layout-area-body m-layout-default"
                   [editCommand]="true"
                   [deleteCommand]="true"></app-map-icons>
</div>