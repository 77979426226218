<div class="m-layout-w-actions-bottom m-border-top m-fixed-height">
    <div class="p-16 m-overflow-auto m-layout-area-body">
        <div class="row"
             *ngIf="!deviceForm">
            <app-loading-spinner>
            </app-loading-spinner>
        </div>

        <form [formGroup]="deviceForm"
              *ngIf="deviceForm"
              autocomplete="off">

            <div class="m-form-group row row"
                 [ngClass]="{ 'has-error': deviceForm.get('code').showErrors }">

                <label class="col-4"
                       for="code">
                    {{"general.code" | translate}}
                </label>

                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           id="code"
                           formControlName="code"
                           appAutofocus="!isCreatingNew()"
                           #codeInput />

                    <app-form-validation [control]="deviceForm.get('code')"
                                         [input]="codeInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('typeId').showErrors }">

                <label class="col-4"
                       for="deviceTypeId">
                    {{"general.type" | translate}}
                </label>

                <div class="col-8">
                    <p-dropdown id="deviceTypeId"
                                [options]="deviceTypes"
                                [panelStyle]="{'width':'100%'}"
                                formControlName="typeId"
                                [autoDisplayFirst]="false"
                                #deviceTypeIdInput></p-dropdown>

                    <app-form-validation [control]="deviceForm.get('typeId')"
                                         [input]="deviceTypeIdInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 *ngIf="deviceForm.get('ownerId')"
                 [ngClass]="{ 'has-error': deviceForm.get('ownerId').showErrors }">

                <label class="col-4"
                       for="ownerId">
                    {{"general.organization" | translate}}
                </label>

                <div class="col-8">
                    <p-dropdown id="ownerId"
                                formControlName="ownerId"
                                [panelStyle]="{'width':'100%'}"
                                [options]="organizations"
                                [filter]="true"
                                [resetFilterOnHide]="true"
                                [autoDisplayFirst]="false"
                                placeholder="{{'general.organization' | translate }}"
                                #ownerIdInput>
                        <ng-template let-organization
                                     pTemplate="item">
                            <span [ngClass]="organization.styleClass">{{organization.label}}</span>
                        </ng-template>
                    </p-dropdown>

                    <app-form-validation [control]="deviceForm.get('ownerId')"
                                         [input]="ownerIdInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('manufacturer').showErrors }">

                <label class="col-4"
                       for="manufacturer">{{"devices.manufacturer" | translate}}</label>

                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           id="manufacturer"
                           formControlName="manufacturer"
                           #manufacturerInput />

                    <app-form-validation [control]="deviceForm.get('manufacturer')"
                                         [input]="manufacturerInput">
                    </app-form-validation>
                </div>
            </div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('imei').showErrors }">

                <label class="col-4"
                       for="imei">{{"general.imei" | translate}}</label>

                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           id="imei"
                           formControlName="imei"
                           #imeiInput />

                    <app-form-validation [control]="deviceForm.get('imei')"
                                         [input]="imeiInput">
                    </app-form-validation>
                </div>
            </div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('qrCode').showErrors }">

                <label class="col-4"
                       for="qrCode">{{"general.qrCode" | translate}}</label>

                <div class="col-8">
                    <input type="text"
                           class="p-inputtext"
                           id="qrCode"
                           formControlName="qrCode"
                           #qrCodeInput />

                    <app-form-validation [control]="deviceForm.get('qrCode')"
                                         [input]="qrCodeInput">
                    </app-form-validation>
                </div>
            </div>
            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('description').showErrors }">

                <label class="col-4"
                       for="description">
                    {{"general.description" | translate}}
                </label>

                <div class="col-8">
                    <textarea rows="3"
                              class="p-inputtextarea"
                              id="description"
                              formControlName="description"
                              #descriptionInput></textarea>

                    <app-form-validation [control]="deviceForm.get('description')"
                                         [input]="descriptionInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 *ngIf="deviceForm.get('batteryDischargeTableId')"
                 [ngClass]="{ 'has-error': deviceForm.get('batteryDischargeTableId').showErrors }">

                <label class="col-4"
                       for="batteryDischargeTableId">
                    {{"batteryDischargeTables.input" | translate}}
                </label>

                <div class="col-8">
                    <p-dropdown id="batteryDischargeTableId"
                                formControlName="batteryDischargeTableId"
                                [panelStyle]="{'width':'100%'}"
                                [options]="batteryDischargeTables"
                                [showClear]="true"
                                [autoDisplayFirst]="false"
                                placeholder="{{'batteryDischargeTables.input' | translate }}"
                                #batteryDischargeTableIdInput>
                    </p-dropdown>

                    <app-form-validation [control]="deviceForm.get('batteryDischargeTableId')"
                                         [input]="batteryDischargeTableIdInput">
                    </app-form-validation>
                </div>
            </div>

            <div class="m-form-group row"
                 *ngIf="deviceForm.get('useRealtimeUpdates').enabled"
                 [ngClass]="{ 'has-error': deviceForm.get('useRealtimeUpdates').showErrors }">

                <label class="col-4"
                       for="useRealtimeUpdates">
                    {{"deviceStatus.useRealtimeUpdates" | translate}}
                </label>

                <div class="col-8">
                    <p-inputSwitch id="useRealtimeUpdates"
                                   formControlName="useRealtimeUpdates"
                                   #useRealtimeUpdatesInput>
                    </p-inputSwitch>
                </div>
            </div>

            <div class="m-form-group row"
                 [ngClass]="{ 'has-error': deviceForm.get('isOffline').showErrors }">

                <label class="col-4"
                       for="isOffline">
                    {{"devices.isOffline" | translate}}
                </label>

                <div class="col-8">
                    <p-inputSwitch id="isOffline"
                                   formControlName="isOffline"
                                   #isOfflineInput>
                    </p-inputSwitch>

                    <app-form-validation [control]="deviceForm.get('isOffline')"
                                         [input]="isOfflineInput">
                    </app-form-validation>
                </div>
            </div>

            <ng-container *ngIf="featuresForm"
                          formGroupName="features">
                <div class="py-16">
                    <h3>{{"deviceFeatures.title" | translate}}</h3>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('otaUpdate').showErrors }">

                    <label class="col-4"
                           for="otaUpdate">
                        {{"deviceFeatures.otaUpdate" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="otaUpdate"
                                       formControlName="otaUpdate"
                                       #otaUpdateInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('otaUpdate')"
                                             [input]="otaUpdateInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('realtimeAnalyzer').showErrors }">

                    <label class="col-4"
                           for="realtimeAnalyzer">
                        {{"deviceFeatures.realtimeAnalyzer" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="realtimeAnalyzer"
                                       formControlName="realtimeAnalyzer"
                                       #realtimeAnalyzerInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('realtimeAnalyzer')"
                                             [input]="realtimeAnalyzerInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('zigbee').showErrors }">

                    <label class="col-4"
                           for="zigbee">
                        {{"deviceFeatures.zigbee" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="zigbee"
                                       formControlName="zigbee"
                                       #zigbeeInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('zigbee')"
                                             [input]="zigbeeInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('gpio').showErrors }">

                    <label class="col-4"
                           for="gpio">
                        {{"deviceFeatures.gpio" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="gpio"
                                       formControlName="gpio"
                                       #gpioInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('gpio')"
                                             [input]="gpioInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('vms').showErrors }">

                    <label class="col-4"
                           for="vms">
                        {{"deviceFeatures.vms" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="vms"
                                       formControlName="vms"
                                       #vmsInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('vms')"
                                             [input]="vmsInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('qLiteDisplay').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.qLiteDisplay" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="qLiteDisplay"
                                       formControlName="qLiteDisplay"
                                       #qLiteDisplayInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('qLiteDisplay')"
                                             [input]="qLiteDisplayInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('dekimoDisplay').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.dekimoDisplay" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="dekimoDisplay"
                                       formControlName="dekimoDisplay"
                                       #dekimoDisplayInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('dekimoDisplay')"
                                             [input]="dekimoDisplayInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('tmsRadar').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.tmsRadar" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="tmsRadar"
                                       formControlName="tmsRadar"
                                       #tmsRadarInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('tmsRadar')"
                                             [input]="tmsRadarInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('safetyCrossing').showErrors }">

                    <label class="col-4"
                           for="qLiteDisplay">
                        {{"deviceFeatures.safetyCrossing" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="safetyCrossing"
                                       formControlName="safetyCrossing"
                                       #safetyCrossingInput>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('safetyCrossing')"
                                             [input]="safetyCrossingInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': featuresForm.get('simulateData').showErrors }">

                    <label class="col-4"
                           for="simulateData">
                        {{"deviceFeatures.simulateData" | translate}}
                    </label>

                    <div class="col-8">

                        <p-inputSwitch id="simulateData"
                                       formControlName="simulateData"
                                       #simulateData>
                        </p-inputSwitch>

                        <app-form-validation [control]="featuresForm.get('simulateData')"
                                             [input]="simulateData">
                        </app-form-validation>
                    </div>
                </div>

            </ng-container>
        </form>
    </div>

    <div class="m-border-top px-16 py-8 m-layout-area-bottom"
         *ngIf="!isReadOnly">
        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-link"
                    [disabled]="submitting"
                    (click)="reset()"
                    type="button"
                    id="reset_button">

                <ng-container *ngIf="!isCreatingNew()">
                    {{"form.reset" | translate}}
                </ng-container>

                <ng-container *ngIf="isCreatingNew()">
                    {{"form.cancel" | translate}}
                </ng-container>
            </button>

            <button [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit"
                    id="submit_button">

                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>

                {{"form.save" | translate}}
            </button>
        </div>
    </div>
</div>