import { Injectable } from "@angular/core";
import { IParkingBanException, ParkingBanExceptionCreator, ParkingBanExceptionUpdater } from "../models/parking-ban-exception";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class ParkingBanExceptionApi extends ApiService<IParkingBanException, ParkingBanExceptionCreator, ParkingBanExceptionUpdater> {

    getRoute(): string {
        return "ParkingBanExceptions";
    }
}