import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { IMeasuringPointArrowBoardHistory } from "../models/measuring-point-arrow-board-history";

@Injectable({
    providedIn: "root"
})
export class MeasuringPointArrowBoardHistoryApi extends ApiService<IMeasuringPointArrowBoardHistory, null, null> {

    getRoute(): string {
        return "MeasuringPoints/{measuringPointId}/ArrowBoardHistory";
    }
}