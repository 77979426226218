<div class="m-layout-w-actions-top m-border-top">
    <div class="m-btn-group m-btn-group-right m-layout-area-top">
        <button class="m-btn m-btn-secondary"
                (click)="create()"
                *ngIf="createCommand && canCreateApplication"
                id="create_api_client_button">
            <app-svg-icon icon="add">
            </app-svg-icon>
            {{"applications.create" | translate}}
        </button>
    </div>

    <p-table [value]="data"
             [columns]="relevantColumns"
             [rows]="internalRowCount"
             [styleClass]="styleClass"
             class="m-layout-area-body m-border-top"
             [loading]="loading"
             [selectionMode]="selectionMode"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowDeselect($event)"
             (onPage)="onPage($event)"
             [paginator]="paginator"
             [pageLinks]="pageLinks"
             [scrollable]="scrollable"
             [scrollHeight]="scrollHeight"
             dataKey="id"
             id="applications_table"
             #tableRef>

        <ng-template pTemplate="emptymessage">
            <tr *ngIf="!loading && emptyMessage">
                <td [attr.colspan]="relevantColumns.length"
                    class="m-table-empty-message">
                    {{"table.empty" | translate}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <ng-container *ngIf="total">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="summary"
                     *ngIf="footer">
            <ng-container *ngIf="total && !paginator">
                {{"table.total" | translate}}: {{total}}
            </ng-container>
        </ng-template>

        <ng-template pTemplate="header"
                     *ngIf="headers">
            <tr>
                <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [pSortableColumn]="column.field"
                    [pSortableColumnDisabled]="!sortable || !column.sortable">

                    <div class="m-column-header">
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{column.header | translate}}
                            </span>

                            <p-sortIcon *ngIf="sortable && column.sortable"
                                        [field]="column.field"></p-sortIcon>

                            <app-table-filter *ngIf="filter"
                                              class="m-column-filter"
                                              [column]="column"
                                              [table]="table">
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-application>
            <tr [pSelectableRow]="application">

                <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                    [ngStyle]="column.ngStyle"
                    [ngSwitch]="column.field">

                    <ng-container *ngSwitchCase="'user.organization.name'">
                        <app-navigator [user]="application.user"></app-navigator> -
                        <app-navigator [organizationId]="application.organization?.id"></app-navigator>
                    </ng-container>

                    <ng-container *ngSwitchCase="'clientId'">
                        <a (click)="copyToClipboard(application)">
                            <app-svg-icon icon="copy"
                                          style="float: left; margin-top: -5px;"></app-svg-icon>
                            {{application.clientId}}
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'b2CClientId'">
                        <a (click)="copyB2CToClipboard(application)">
                            <app-svg-icon icon="copy"
                                          style="float: left; margin-top: -5px;"></app-svg-icon>
                            {{application.b2CClientId}}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="'rights'">
                        <ul>
                            <li *ngFor="let right of application.rightViewModels | slice:0:5; let last = last; let i = index; trackBy: selectItemTrackByFn">
                                <span style="font-size: x-small;">{{right.label}}</span>
                            </li>

                            <li *ngIf="application.rightViewModels.length > 5">...</li>
                        </ul>
                    </ng-container>

                    <ng-container *ngSwitchCase="'commands'">
                        <div class="m-btn-group">
                            <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                <app-icon-button [text]="command.text"
                                                 [icon]="command.icon"
                                                 [disabled]="command.disabledFunc()"
                                                 (click)="command.click(application)"
                                                 *ngIf="command.rowValidFunc(application)">
                                </app-icon-button>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{application[column.field]}}
                    </ng-container>
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>

<app-manage-application-dialog></app-manage-application-dialog>
<app-application-create-result-dialog></app-application-create-result-dialog>