import { UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from "@angular/forms";
import { IDeviceCommand, DeviceCommandCreator, DeviceCommandType } from "src/app/models/device-command";
import { PrimeComponentService, CalendarSettings } from "src/app/services/prime-component.service";
import { DomainDataService, DomainData } from "src/app/services/domain-data.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormValidationService } from "src/app/services/form-validation.service";
import { DialogComponentBase } from "src/app/modules/shared/components/dialog/dialog.component";
import { DurationUtils } from "src/app/utilities";
import { DeviceCommandApi } from "src/app/resource/device-command.api";
import { SigncoFormGroup } from "src/app/models/form";
import { ViewModelEnum } from "src/app/models/domain-data";
import { IDevice } from "src/app/models/device";

@Component({
    selector: "app-manage-device-command-dialog",
    templateUrl: "./manage-device-command.dialog.html"
})
export class ManageDeviceCommandDialogComponent extends DialogComponentBase implements OnInit, OnDestroy {
    errorCode: string;
    submitting: boolean;
    device: IDevice;
    callback: (res: IDeviceCommand) => void;
    deviceCommandCreatorForm: SigncoFormGroup;
    deviceCommandTypes: ViewModelEnum[];
    calendarSettings: CalendarSettings;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly domainDataService: DomainDataService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly deviceCommandApi: DeviceCommandApi) {

        super();

        const calendarSettingsSubscription = this.primeComponentService.calendarSettings().subscribe(calendarSettings => {
            this.calendarSettings = calendarSettings;
        });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    async ngOnInit() {
        this.deviceCommandTypes = await this.domainDataService.get(DomainData.DeviceCommandType);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    create(device: IDevice, callback?: (res: IDeviceCommand) => void) {
        this.callback = callback;
        this.device = device;

        this.openDialog();
    }

    protected onOpen() {
        const defaultType = (this.deviceCommandTypes || []).takeFirstOrDefault();

        this.deviceCommandCreatorForm = this.formBuilder.group({
            typeId: [defaultType ? defaultType.value : null, Validators.required]
        }) as SigncoFormGroup;

        this.adjustForm();

        this.submitting = false;
    }

    protected onClose() {
        this.deviceCommandCreatorForm = null;
    }

    adjustForm() {
        const deviceCommandTypeId = this.getCommandTypeId();
        if (!deviceCommandTypeId) return;

        if (deviceCommandTypeId === DeviceCommandType.ResetCounters) {
            this.deviceCommandCreatorForm.addControl("dayCounter", this.formBuilder.control({ value: null, disabled: true }, [Validators.required, Validators.min(0)]));
            this.deviceCommandCreatorForm.addControl("monthCounter", this.formBuilder.control({ value: null, disabled: true }, [Validators.required, Validators.min(0)]));
            this.deviceCommandCreatorForm.addControl("yearCounter", this.formBuilder.control({ value: null, disabled: true }, [Validators.required, Validators.min(0)]));
        } else {
            this.deviceCommandCreatorForm.removeControl("dayCounter");
            this.deviceCommandCreatorForm.removeControl("monthCounter");
            this.deviceCommandCreatorForm.removeControl("yearCounter");
        }

        if (deviceCommandTypeId === DeviceCommandType.EnableModem) {
            const enabledForm = this.formBuilder.group({
                from: [null, Validators.required],
                until: [null, Validators.required]
            });
            enabledForm.disable();
            this.deviceCommandCreatorForm.addControl("enabled", enabledForm);

            const validForm = this.formBuilder.group({
                from: [null, Validators.required],
                until: [null, Validators.required]
            });
            validForm.disable();
            this.deviceCommandCreatorForm.addControl("valid", validForm);
        } else {
            this.deviceCommandCreatorForm.removeControl("enabled");
            this.deviceCommandCreatorForm.removeControl("valid");
        }

        this.deviceCommandCreatorForm.updateValueAndValidity();
    }

    toggleControlEnabled(control: AbstractControl) {
        if (control.disabled) {
            control.enable();
        } else {
            control.disable();

            if (control instanceof UntypedFormControl) {
                control.setValue(null);
            }
        }

        control.markAsUntouched();
        control.markAsPristine();
    }

    toggleEnableModemEnabled() {
        const enabledForm = this.deviceCommandCreatorForm.get("enabled");
        this.toggleControlEnabled(enabledForm);

        const validForm = this.deviceCommandCreatorForm.get("valid");

        // If we enabled enabledForm, also enable validForm
        if (enabledForm.enabled && validForm.disabled) {
            this.toggleControlEnabled(validForm);

            const fromControl = validForm.get("from");
            const untilControl = validForm.get("until");

            if (!fromControl.value && !untilControl.value) {
                fromControl.setValue(new Date().toMidnight());
                untilControl.setValue(new Date().toMidnight());
            }
        }

        // if we disabled enabledForm, also disable validForm
        if (enabledForm.disabled && validForm.enabled) {
            this.toggleControlEnabled(validForm);
        }
    }

    getCommandTypeId(): DeviceCommandType {
        return this.deviceCommandCreatorForm.get("typeId").value;
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.deviceCommandCreatorForm);
        if (!isValid) return;

        const deviceCommandCreator = Object.assign(new DeviceCommandCreator(), { command: this.deviceCommandCreatorForm.value });

        this.errorCode = null;

        const deviceCommandTypeId = this.getCommandTypeId();
        if (deviceCommandTypeId === DeviceCommandType.ResetCounters) {

            // Count the amount of enabled controls for validation
            let controlCount = 0;
            for (const controlKey in this.deviceCommandCreatorForm.controls) {
                if (this.deviceCommandCreatorForm.controls.hasOwnProperty(controlKey)) {
                    const control = this.deviceCommandCreatorForm.controls[controlKey];

                    if (control.enabled) {
                        controlCount++;
                    }
                }
            }

            if (controlCount === 1) {
                this.errorCode = "deviceCommands.resetCounters.noBodyError";
                return;
            }
        }

        if (deviceCommandTypeId === DeviceCommandType.EnableModem) {
            const enabledHasValue = !!this.deviceCommandCreatorForm.get("enabled").get("from").value || this.deviceCommandCreatorForm.get("enabled").get("until").value;
            const validHasValue = !!this.deviceCommandCreatorForm.get("valid").get("from").value || this.deviceCommandCreatorForm.get("valid").get("until").value;

            if (!enabledHasValue && validHasValue) {
                this.errorCode = "deviceCommands.enableModem.validOnlyValueError";
                return;
            }

            if (enabledHasValue) {
                deviceCommandCreator.command.enabled.from = DurationUtils.toString(deviceCommandCreator.command.enabled.from as Date);
                deviceCommandCreator.command.enabled.until = DurationUtils.toString(deviceCommandCreator.command.enabled.until as Date);
            }
        }

        this.submitting = true;

        const onSuccess = (newDeviceCommand: IDeviceCommand) => {
            if (this.callback) {
                this.callback(newDeviceCommand);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        this.deviceCommandApi.create$(deviceCommandCreator, { deviceId: this.device.id.toString() }).subscribe(onSuccess, onError);
    }
}