<div class="mt-0 mb-0"
     *ngIf="task">
    <hr class="mt-0 mb-0"
        *ngIf="showTopLine">
    <div class="row mt-16 mb-16">
        <span class="m-circle m-circle-grey ml-8">
            <app-svg-icon icon="task"
                          [ngStyle]="{'fill': '#000', 'display': 'inline-block', 'vertical-align':'middle'}">
            </app-svg-icon>
        </span>
        <div class="ml-16">
            <h4>{{task.name}}</h4>
            <span>
                <app-domain-data-field *ngIf="task.currentStatus?.taskTypeId"
                                       [enumKey]="task.currentStatus.taskTypeId"
                                       domainDataType="taskType">
                </app-domain-data-field>
                -
                <app-domain-data-field *ngIf="task.currentStatus?.signCategoryId"
                                       [enumKey]="task.currentStatus.signCategoryId"
                                       domainDataType="signCategoryValue">
                </app-domain-data-field>
            </span>
        </div>
    </div>
    <hr class="mt-0 mb-0"
        *ngIf="showBottomLine">
</div>