import "./app/extensions/object-extensions";
import "./app/extensions/array-extensions";
import "./app/extensions/date-extensions";
import "./app/extensions/string-extensions";
import "./app/extensions/event-extensions";
import "hammerjs";
import "prismjs";
import "prismjs/components/prism-json";
import "prismjs/components/prism-csharp";

import { enableProdMode, ApplicationRef } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { enableDebugTools } from "@angular/platform-browser";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
        if (!environment.production) {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];

            // allows to run `ng.profiler.timeChangeDetection();`
            enableDebugTools(componentRef);
        }
    })
    .catch(err => console.error(err));
