import { Component, OnChanges, SimpleChanges, Input, ViewChild, ElementRef } from "@angular/core";
import { LazyTableComponent, TableColumn, FilterType, TableService } from "src/app/modules/shared/components/table/table.component";
import { SearchParameters, FilterDescriptor, ServiceRequestOptions } from "src/app/models/search";
import { AttachmentDialogComponent } from "../attachment-dialog/attachment.dialog";
import { DomainModelFilterService } from "src/app/services/domain-model-filter.service";
import { AccessibilityService } from "src/app/services/accessibility.service";
import { TranslateService } from "@ngx-translate/core";
import { AttachmentApi } from "src/app/resource/attachment.api";
import { ModalService } from "src/app/services/modal.service";
import { AttachmentContext, IAttachment } from "src/app/models/attachment";
import { ILocation } from "src/app/models/location";
import { LocationDialogComponent } from "src/app/modules/location-shared";
import { GlobalEventsService } from "src/app/services/global-events-service";
import { BackendRights } from "src/app/models/backend-rights";

@Component({
    selector: "app-attachments",
    templateUrl: "./attachments.component.html"
})
export class AttachmentsComponent extends LazyTableComponent<IAttachment> implements OnChanges {
    @ViewChild(AttachmentDialogComponent, { static: true }) attachmentDialog: AttachmentDialogComponent;
    @ViewChild(LocationDialogComponent, { static: true }) manageLocationComponent: LocationDialogComponent;

    @Input() allowWithoutInput: boolean;
    @Input() attachmentContext: AttachmentContext;
    @Input() createCommand = true;
    @Input() editCommand = true;
    @Input() deleteCommand = true;
    @Input() downloadCommand = true;

    readonly = false;
    canCreateTask = false;
    canCreateAttachment = false;

    private locationColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly attachmentApi: AttachmentApi,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly accessibilityService: AccessibilityService,
        private readonly domainModelFilterService: DomainModelFilterService) {

        super("attachments.component", elementRef, attachmentApi, tableService);

        this.stretchHeight = true;
        this.canCreateAttachment = this.rights?.hasBackendRight(BackendRights.EditAttachment);
        this.canCreateTask = this.rights?.hasBackendRight(BackendRights.EditTask);

        elementRef.nativeElement.classList.add("m-layout-area-body");
        elementRef.nativeElement.classList.add("m-layout-w-actions-top");

        this.selectionMode = "";

        this.addColumn(new TableColumn("createDate", "general.createDate", { filterType: FilterType.Date, sortable: true, width: 180 }));
        this.addColumn(new TableColumn("creatorId", "general.uploader", { filterType: FilterType.None, sortable: true, width: 180 }));
        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("description", "general.description", { filterType: FilterType.None, width: 200, resizable: true }));

        const typeColumn = new TableColumn("type", "general.type", { filterType: FilterType.MultiSelect, sortable: true });
        this.addColumn(typeColumn);

        this.locationColumn = new TableColumn("location", "general.location", { filterType: FilterType.None, sortable: false });

        this.domainModelFilterService.getAttachmentTypes$().then(attachmentTypes => {
            typeColumn.filterOptions = attachmentTypes;
        });

        this.registerCommand({
            text: "general.copyToClipboard",
            icon: "copy",
            click: (attachment) => this.accessibilityService.copyToClipboard(attachment.url),
            validFunc: () => this.downloadCommand && this.rights?.hasBackendRight(BackendRights.ViewAttachment) && !this.readonly
        });

        this.registerCommand({
            text: "general.download",
            icon: "download",
            click: (attachment) => this.download(attachment),
            validFunc: () => this.downloadCommand && this.rights?.hasBackendRight(BackendRights.ViewAttachment) && !this.readonly
        });

        this.registerCommand({
            text: "form.edit",
            icon: "edit",
            click: (attachment) => this.edit(attachment),
            validFunc: () => this.editCommand && this.rights?.hasBackendRight(BackendRights.EditAttachment) && !this.readonly
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (attachment) => this.delete(attachment),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteAttachment) && !this.readonly
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const attachmentContextChange = changes["attachmentContext"];

        if (attachmentContextChange) {
            this.reload();
            this.updateLocationColumn();
        }

        super.ngOnChanges(changes);
    }

    create() {
        this.attachmentDialog.open(this.attachmentContext);
    }

    edit(attachment: IAttachment) {
        this.attachmentDialog.edit(attachment, this.attachmentContext);
    }

    canLoad(): boolean {
        return this.allowWithoutInput || !!this.attachmentContext;
    }

    getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.attachmentContext) {
            searchParameters.filter.push(new FilterDescriptor(this.attachmentContext.contextType, this.attachmentContext.contextEntityId));
        }

        return searchParameters;
    }

    getServiceRequestOptions(): ServiceRequestOptions {
        const serviceRequestOptions = new ServiceRequestOptions();

        if (this.attachmentContext && this.attachmentContext.contextType === "task") {
            serviceRequestOptions.includes.add("attachment", "location");
        }

        return serviceRequestOptions;
    }

    download(attachment: IAttachment) {
        window.location.href = attachment.url;
    }

    delete(attachment: IAttachment) {
        const onDeleteSuccess = () => {
            this.reload();
        };

        const onDelete = () => {
            this.attachmentApi.delete$(attachment.id).subscribe(onDeleteSuccess, () => { });
        };

        const modalBody = this.translate.instant("attachments.deleteConfirmation", attachment);
        this.modalService.delete(modalBody, onDelete);
    }

    private updateLocationColumn() {
        // when looking at attachments in context of tasks
        // location column needs to be shown

        if (this.attachmentContext.contextType === "task") {
            if (!this.columns.contains(this.locationColumn)) {
                this.addColumn(this.locationColumn);
            }
        } else {
            if (this.columns.contains(this.locationColumn)) {
                this.removeColumn(this.locationColumn);
            }
        }
    }

    editLocation(location: ILocation) {
        this.manageLocationComponent.edit(location, () => {
            this.reload();
            this.updateLocationColumn();
        });
    }
}