import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { GlobalEventsService } from "../services/global-events-service";
import { BackendRights } from "../models/backend-rights";

@Injectable()
export class RootRedirectGuard {

    private readonly defaultRoot = "measuringpoints";
    private readonly assignmentRoot = "assignments";


    constructor(
        private readonly router: Router,
        private readonly globalEventsService: GlobalEventsService) {

    }


    canActivate(route: ActivatedRouteSnapshot) {

        return this.globalEventsService.authorizationInfo$.subscribe(authorizationInfo => {

            if (authorizationInfo) {
                const rights = this.globalEventsService.getCurrentRights();
                const redirect = (newRoute: string) => {
                    this.router.navigate([newRoute]);
                    return false;
                };
                const url = route.routeConfig.path;
                if (url.contains(this.defaultRoot) && rights.hasBackendRight(BackendRights.ViewMeasuringPoint)) {
                    return true;
                }
                if (url.contains(this.assignmentRoot)) {
                    if (!rights.hasBackendRight(BackendRights.ViewAssignment)) {
                        redirect(`/${this.defaultRoot}`);
                    } else {
                        return true;
                    }
                } else if (rights.hasBackendRight(BackendRights.ViewPlanning) && !rights.hasBackendRight(BackendRights.ViewMeasuringPoint)) {
                    if (url.contains(this.defaultRoot) && authorizationInfo.isDomainAdministrator) {
                        return true;
                    } else {
                        redirect(`/map/${this.assignmentRoot}`);
                    }
                } else {
                    return true;
                }
            }

        });

    }
}