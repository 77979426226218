import { Component, Input, inject } from "@angular/core";
import { MapIconService } from "src/app/services/map-icon-service";
import { SearchResultViewModel } from "./search-result-view-model";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-search-result",
    templateUrl: "./search-result.component.html",
    styleUrls: ["./search-result.component.scss"],
})
export class SearchResultComponent {

    @Input() viewModel: SearchResultViewModel;

    private readonly mapIconService = inject(MapIconService);

    protected getIconUrl(): string {
        const iconState = this.mapIconService.getLocationIcon(this.viewModel.location);
        if (!iconState?.defaultIcon) {
            if (!environment.production) {
                console.error("Couldn't fetch iconState", this.viewModel.location.id, this.viewModel.location.code, this.viewModel.location.iconStateId);
            }
            return "";
        }
        return iconState.defaultIcon.url;
    }
}