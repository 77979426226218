<div class="m-border-top m-overflow-auto" style="display:grid; gap:16px; grid-auto-rows:min-content ;">
    <div style="display: grid; gap:8px;" class="py-16">
        <!--region:Header-->
        <div style="display: flex; align-items: center; gap:8px;"
             class="px-16 pb-8">
            {{ "modeConfiguration.defaultModeId" | translate }}
            <p-dropdown id="dropdownDefaultTotemMode"
                        [(ngModel)]="totemDisplayConfiguration.modeConfiguration.defaultModeId"
                        [options]="getModeOptions()"
                        [style]="{'width':'150px'}">
                <ng-template let-id pTemplate="item">
                    {{ id }} - {{ getMode(id)?.description }}
                </ng-template>
                <ng-template let-id pTemplate="selectedItem">
                    {{ id }} - {{ getMode(id)?.description }}
                </ng-template>
            </p-dropdown>
            <button class="m-btn m-btn-secondary"
                    (click)="configureFields()"
                    style="margin-left: auto;">
                <app-svg-icon icon="edit"></app-svg-icon>
                {{"totemDisplayConfiguration.configureFieldsTitle" | translate}}
            </button>
        </div>
        <!--endregion:Header-->

        <div class="flex align-center m-border-top px-16 pt-16 pb-8">
            <h3>{{"modeConfiguration.modes" | translate}}</h3>
        </div>

        <div class="m-totem-overview">
            @if (totemDisplayConfiguration?.modeConfiguration) {
                @for (mode of this.totemDisplayConfiguration?.modeConfiguration?.modes; track mode.id) {
                    <div class="m-totem-overview-mode">
                        <!--mode:Header-->
                        <div class="m-totem-overview-mode__header">
                            <div>
                                <app-icon-button icon="delete" text="form.delete" (click)="removeMode(mode)"/>
                                <input [(ngModel)]="mode.id" class="p-inputtext" type="text" required (change)="hasChanged = true" />
                                <input [(ngModel)]="mode.description" class="p-inputtext" type="text" (change)="hasChanged = true" />
                            </div>
                        </div>

                        <!--mode:Content-->
                        <div class="m-totem-overview-mode__row">
                            @for (fieldContent of mode.fields; track fieldContent.fieldId) {
                                <div class="m-mode">
                                    <div>
                                        <h4 class="mb-8">
                                            {{ getField(fieldContent.fieldId).name }} ({{ getField(fieldContent.fieldId).lineCount }} x {{ getField(fieldContent.fieldId).lineLength }})
                                        </h4>
                                        <div class="m-mode-display">
                                            @for (frame of fieldContent.frames; track frame.text) {
                                                <div class="m-mode-display__frames">
                                                    <textarea #input="ngModel"
                                                              #textarea
                                                              [style]="appTextFieldAnimation.isLoading ? 'pointer-events: none;' : ''"
                                                              (change)="inputChanged(mode.id, fieldContent, appTextFieldAnimation)"
                                                              [(ngModel)]="frame.text"
                                                              [rows]="getFieldHeight(fieldContent.fieldId)"
                                                              [textFieldConfig]="getField(fieldContent.fieldId)"
                                                              appTextFieldValidator
                                                              class="totem-display-text-field">
                                                    </textarea>
                                                    <div class="form-validation-error">{{ input.errors | translateValidation }}</div>

                                                    <div style="display:flex; align-items: center; gap:4px;">
                                                        @if (fieldContent.frames.length > 1) {
                                                            <button [disabled]="appTextFieldAnimation.isLoading"
                                                                    (click)="removeFrame(fieldContent, frame, appTextFieldAnimation, mode.id);"
                                                                    class="m-btn m-btn-secondary">
                                                                <app-svg-icon icon="delete"/>
                                                            </button>

                                                            <div
                                                                 class="m-input-group"
                                                                 style="width: 110px;">
                                                                <input [(ngModel)]="frame.displayTimeMs"
                                                                       [disabled]="appTextFieldAnimation.isLoading"
                                                                       style="min-width: 0;"
                                                                       class="p-inputtext"
                                                                       type="number"
                                                                       (change)="inputChanged(mode.id, fieldContent, appTextFieldAnimation)"/>
                                                                <span class="m-input-group-addon">
                                                                    {{ "measurements.ms" | translate }}
                                                                </span>
                                                            </div>
                                                        }
                                                        <button class="m-btn m-btn-secondary"
                                                                style="padding:0; min-height: 0;"
                                                                [disabled]="appTextFieldAnimation.isLoading"
                                                                (click)="openContextMenu($event, fieldContent, frame, textarea)">
                                                            <app-svg-icon icon="magic-wand" />
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            <button [disabled]="appTextFieldAnimation.isLoading"
                                                    class="m-btn m-btn-secondary m-mode-display__frames"
                                                    (click)="addFrame(fieldContent, appTextFieldAnimation, mode.id)">
                                                <app-svg-icon icon="add"/>
                                                {{ "modeConfiguration.addText" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="m-mode-preview">
                                        <app-text-field-animation [content]="getContent(mode.id, fieldContent.fieldId)"
                                                                  #appTextFieldAnimation
                                                                  [configuration]="getConfiguration(fieldContent)"
                                                                  [isExpanded]="false">
                                        </app-text-field-animation>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
        <div>
            <button class="m-btn m-btn-secondary"
                    (click)="addMode()">
                <app-svg-icon icon="add"></app-svg-icon>
                {{"modeConfiguration.addMode" | translate}}
            </button>
        </div>

    </div>
</div>

<div class="m-btn-group m-btn-group-right px-16 py-8 m-border-top m-layout-area-bottom">
    <button [disabled]="submitting"
            class="m-btn m-btn-link"
            (click)="reset()"
            type="button">

        {{"form.reset" | translate}}
    </button>

    <button [disabled]="submitting || submitDisabled"
            (click)="submit()"
            class="m-btn m-btn-primary"
            type="submit">

        <app-loading-spinner *ngIf="submitting">
        </app-loading-spinner>

        {{"form.save" | translate}}
    </button>
</div>

<app-totem-display-fields-dialog></app-totem-display-fields-dialog>

<div #contextMenu class="p-dropdown-panel context-menu">
    <div style="display:grid; gap:8px;">
        <h4>{{"totemDisplayConfiguration.dynamicTextTitle" | translate}}</h4>
        <small style="max-width: 400px;">{{"totemDisplayConfiguration.dynamicTextDescription" | translate}}</small>
        <div style="display:grid; grid-template-columns: repeat(3, 1fr); gap:8px;">
            <button class="m-btn m-btn-secondary"
                    (click)="addDate()">{{"totemDisplayConfiguration.dynamicTextDate" | translate}}</button>
            <button class="m-btn m-btn-secondary"
                    (click)="addTime()">{{"totemDisplayConfiguration.dynamicTextTime" | translate}}</button>
            <button class="m-btn m-btn-secondary"
                    (click)="addTemperature()">{{"totemDisplayConfiguration.dynamicTextTemp" | translate}}</button>
            <button class="m-btn m-btn-secondary"
                    (click)="addDayCounter()">{{"totemDisplayConfiguration.dynamicTextDayCounter" | translate}}</button>
            <button class="m-btn m-btn-secondary"
                    (click)="addMonthCounter()">{{"totemDisplayConfiguration.dynamicTextMonthCounter" | translate}}</button>
            <button class="m-btn m-btn-secondary"
                    (click)="addYearCounter()">{{"totemDisplayConfiguration.dynamicTextYearCounter" | translate}}</button>
        </div>
    </div>
</div>
