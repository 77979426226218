<ng-container *ngIf="!assignment">
    <app-loading-spinner>
    </app-loading-spinner>
</ng-container>

<div class="m-btn-group m-btn-group-right m-layout-area-top m-border-top">
    <button *ngIf="assignment && canCreateTask && isAnyTaskSelected"
            type="button"
            class="m-btn m-btn-secondary"
            (click)="exportToExcel('worklists')">
        <app-svg-icon icon="excel">
        </app-svg-icon>
        {{"recurringTasks.exportSelected" | translate}}
    </button>

    <button class="m-btn m-btn-secondary"
            (click)="scheduleSchedulableTasks()"
            *ngIf="assignment && canCreateTask && isAnyOnHoldOrScheduledTaskSelected && assignment.currentStatus?.statusId !== 'finished' && assignment.currentStatus?.statusId !== 'cancelled'">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"recurringTasks.scheduleTasks" | translate}}
    </button>

    <button class="m-btn m-btn-secondary"
            (click)="createRecurringTasksFromSelection()"
            *ngIf="assignment && canCreateTask && isAnyInProgressOrFinishedTaskSelected && assignment.currentStatus?.statusId !== 'finished' && assignment.currentStatus?.statusId !== 'cancelled'">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"recurringTasks.createFromSelection" | translate}}
    </button>

    <button class="m-btn m-btn-secondary"
            (click)="create()"
            *ngIf="assignment && canCreateTask && assignment.currentStatus?.statusId !== 'finished' && assignment.currentStatus?.statusId !== 'cancelled'">
        <app-svg-icon icon="add">
        </app-svg-icon>
        {{"task.create" | translate}}
    </button>
</div>

<app-tasks *ngIf="assignment"
           class="m-layout-area-body m-layout-default m-border-top"
           [assignmentId]="assignment?.id"
           [selectionMode]="'multiple'"
           [editCommand]="true"
           [deleteCommand]="true"
           [mini]="false"
           (selectionChanged)="onSelectionChanged($event)"
           (lastRowDeleted)="handleLastTaskDeleted()"></app-tasks>

<app-location-dialog [isCodeRequired]="false"></app-location-dialog>
<app-create-recurring-tasks-dialog></app-create-recurring-tasks-dialog>
<app-schedule-schedulable-tasks-dialog></app-schedule-schedulable-tasks-dialog>